/* eslint-disable */
import React, { useState, useEffect } from "react";
import axios from "axios";
import './adminunilever.css';
import AdminLayout from "../../components/adminLayout/AdminLayout";
import SearchIcon from "@mui/icons-material/Search";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import MoreVertOutlinedIcon from '@mui/icons-material/MoreVertOutlined';
import Paper from '@mui/material/Paper';
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import SwapVertSharpIcon from '@mui/icons-material/SwapVertSharp';
import { TableFooter } from "@mui/material";
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { CSVLink } from "react-csv";
import AdminNavBar from "../../components/adminNavbar/AdminNavBar";




export default function AdminUnilever() {
  const [selectedRow, setSelectedRow] = useState(null);
  const [popup, setPopup] = useState(false);
  const [data, setData] = useState([]);
  const [page, setPage] = useState(0);
  const [students, setStudents] = useState();
  const [mentors, setMentors] = useState();
  const [selectedMentor, setSelectedMentor] = useState();
  const [search, setSearch] = useState("");
  const [userFind, setUserFind] = useState([]);


  useEffect(() => {
    axios.get(`https://findally-backend.herokuapp.com/api/unilever/admin/students?skip=${page}`)
      .then((res) => {
        setData(res.data)
      })
  }, [page]);

  useEffect(() => {
    axios.get(`https://findally-backend.herokuapp.com/api/unilever/admin/mentors?limit=1000`)
      .then((res) => {
        setMentors(res.data.response)
      })
  }, [])

  useEffect(() => {
    if (selectedRow?.mentorvsstudent_relation?._id) {
      setSelectedMentor(selectedRow?.mentorvsstudent_relation?._id)
    }
  }, [selectedRow])


  const handleBefore = () => {
    if (page > 0) {
      setPage(page - 1)
    }
  }

  const handleNext = () => {
    setPage(page + 1)
  }


  const handleOnClickSearch = () => {
    axios.post(`https://findally-backend.herokuapp.com/api/unilever/users/find`, { name: search })
      .then((res) => {
        setUserFind(res.data.data)
      })
      .catch((err) => {
        console.log(err)
      })
  }

  useEffect(() => {
    if (!search) {
      setUserFind([])
    }
  }, [search])




  function CardEstudiante(props) {
    return (
      <div style={{ display: 'flex', width: '100%', flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-start', textAlign: 'left' }}>
        <span style={{ color: '#697a8d', fontSize: '20px' }}>{props.nombre}</span>
        <span style={{
          color: '#b2bcc6',
          fontSize: '14px',
        }}>{props.area}</span>
      </div>
    )
  }

  function CardMentor(props) {
    return (
      <div style={{ display: 'flex', width: '100%', flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-start', textAlign: 'left' }}>
        <span style={{ color: '#697a8d', fontSize: '20px' }}>{props.nombre ? props.nombre : 'Sin asignar'}</span>
        <span style={{
          color: '#b2bcc6',
          fontSize: '14px',
        }}>
          {props.area ? props.area : ''}
        </span>
      </div>
    )
  }

  function CardStatus(props) {
    return (
      <div style={{ display: 'flex', width: '100%', flexDirection: 'row', justifyContent: 'center', alignItems: 'flex-start', backgroundColor: props.status === 'PENDING' ? '#feec9a' : props.status === 'DONE' ? '#e8f9df' : 'ffe0db', borderRadius: '8px' }}>
        <span style={{ color: props.status === 'PENDING' ? '#ffc000' : props.status === 'DONE' ? '#9de776' : '#ff3e1d', fontSize: '20px', margin: '0 10px' }}>{
          props.status === 'PENDING' ? 'Pendiente' : props.status === 'DONE' ? 'Match' : 'Cancelado'
        }</span>
      </div>
    )
  }


  //TODO: REFACTORIZAR ESTE CODIGO CUANDO SE CONECTE CON LA API
  const rows = userFind?.users?.length ? userFind.users.map((item, index) => {
    return {
      estudiante: <CardEstudiante nombre={(item.name && item.lastName) ? item.name + ' ' + item.lastName : item.Coallier_user.username} area={item.skills[0]} />,
      mentor: <CardMentor nombre={item.mentorvsstudent_relation ? item.mentorvsstudent_relation?.name + ' ' + item?.mentorvsstudent_relation?.lastName : 'Sin asignar'} area={item.mentor ? item.mentor : ''} />,
      status: <CardStatus status={item.status_match} />,
      actions: <MoreVertOutlinedIcon style={{ color: '#fe7c7c', cursor: 'pointer' }} onClick={() => openSelectedRow(item)} />
    }
  })
    : data.response && data.response.map((item, index) => {
      return {
        estudiante: <CardEstudiante nombre={(item.name && item.lastName) ? item.name + ' ' + item.lastName : item.Coallier_user.username} area={item.skills[0]} />,
        mentor: <CardMentor nombre={item.mentorvsstudent_relation ? item.mentorvsstudent_relation?.name + ' ' + item?.mentorvsstudent_relation?.lastName : 'Sin asignar'} area={item.mentor ? item.mentor : ''} />,
        status: <CardStatus status={item.status_match} />,
        actions: <MoreVertOutlinedIcon style={{ color: '#fe7c7c', cursor: 'pointer' }} onClick={() => openSelectedRow(item)} />
      }
    })

  const openSelectedRow = (item) => {
    setPopup(true);
    setSelectedRow(item);
  }

  const closeSelectedRow = () => {
    setSelectedRow(null);
    setPopup(false);
  }

  const handlePreMatch = () => {
    axios.post('https://findally-backend.herokuapp.com/api/unilever/prematch')
      .then((res) => {
        console.log(res)
        window.location.reload();
      })
  }

  const handleOnClickMatch = () => {
    console.log(selectedMentor)
    axios.put('https://findally-backend.herokuapp.com/api/unilever/confirm/match', {
      mentor: selectedMentor,
      student: selectedRow._id
    })
      .then((res) => {
        console.log(res)
        window.location.reload();
      })
  }


  //Descargar Estudiantes

  useEffect(() => {
    axios.get(`https://findally-backend.herokuapp.com/api/unilever/admin/students?limit=1000`)
      .then((res) => {
        setStudents(res.data)
      })
  }, [])




  const headers = [
    { label: "Fecha de nacimiento", key: "Date_Birth" },
    { label: "Honores", key: "Honor" },
    { label: "_id", key: "_id" },
    { label: "Nivel academico", key: "academic_level" },
    { label: "Direccion", key: "address" },
    { label: "Carreras", key: "careers" },
    { label: "Ciudad", key: "city" },
    { label: "Trabajo comunitario", key: "cominitary_work" },
    { label: "extracurricular", key: "extracurricular" },
    { label: "genero", key: "gender" },
    { label: "intereses", key: "interest" },
    { label: "apellido", key: "lastName" },
    { label: "nombre", key: "name" },
    { label: "pregunta motivacional", key: "motivational_question" },
    { label: "objetivos a largo plazo", key: "objetives" },
    { label: "ocupacion", key: "occupation" },
    { label: "organizacion", key: "" },
    { label: "porque quieres ser parte del programa", key: "part_program" },
    { label: "telefono", key: "phone" },
    { label: "area de sector", key: "sector_area" },
    { label: "habilidades  a desarrollar", key: "skills" },
    { label: "habilidades personales", key: "skills_personal" },
    { label: "nivel social", key: "social_level" },
    { label: "tiempo para graduarte", key: "time_graduate" },
    { label: "type_user_unilever", key: "type_user_unilever" },
    { label: "university", key: "university" },
    { label: "nombre de usuario", key: "Coallier_user.username" },
    { label: "email", key: "Coallier_user.email" },
  ]

  const csvReport = {
    filename: 'Reporte de Estudiantes.csv',
    headers: headers,
    data: students?.response ? students?.response : []
  };


  return (
    <AdminLayout location={'admin'}>
      <div className="BodyWeb"
        style={{
          padding: "0rem 2rem",
          position: "absolute",
          right: "0",
        }}>
        <AdminNavBar location={'admin'} />
        <div className="admin-unilever-container">
          <div className="admin-unilever-card-container">
            <div className="Profesional-Home-unilever-container-top" style={{ margin: '0' }}>
              <SearchIcon className={'lupa'} style={{ color: '#fe7c7c' }} />
              <input
                className={'Profesional-Home-Search-admin-unilever'}
                style={{
                  border: 'none',
                  marginRight: 0
                }}
                onChange={(e) => setSearch(e.target.value)}
                placeholder='Buscar estudiante' />
              <button style={{
                backgroundColor: '#fe7c7c',
                height: '33px',
                width: '118px',
                borderRadius: '5px',
                color: '#FFFFFF',
                textAlign: 'center',
                marginRight: '45px',
              }}
                onClick={handleOnClickSearch}>
                Buscar
              </button>
              <div>
                <button style={{
                  backgroundColor: '#fe7c7c',
                  height: '33px',
                  width: '118px',
                  borderRadius: '5px',
                  color: '#FFFFFF',
                  textAlign: 'center',
                  marginRight: '45px',
                }} onClick={handlePreMatch} >
                  Prematch
                </button>
              </div>
            </div>
            {data?.response?.length ? <TableContainer component={Paper} style={{ boxShadow: 'none', paddingTop: '2%', backgroundColor: '#f9f9f9', width: '100%', height: '100%' }}>
              <Table sx={{ minWidth: 650, backgroundColor: '#f9f9f9' }} aria-label="simple table">
                <TableHead style={{ borderBottom: '1px solid #697a8d' }}>
                  <TableRow>
                    <TableCell align="left" style={{ color: '#697a8d', fontSize: '20px', backgroundColor: '#f9f9f9' }}>Estudiante</TableCell>
                    <TableCell align="left" style={{ color: '#697a8d', fontSize: '20px', backgroundColor: '#f9f9f9' }}>Mentor</TableCell>
                    <TableCell align="left" style={{ color: '#697a8d', fontSize: '20px', backgroundColor: '#f9f9f9' }}>Status</TableCell>
                    <TableCell align="center" style={{ color: '#697a8d', fontSize: '20px', backgroundColor: '#f9f9f9' }}>Acciones</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {userFind?.total === 0 ?
                    <div>No se encontraron resultados</div>
                    :
                    rows.map((row, index) => (
                      <TableRow
                        key={index}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        style={{ border: 'none', backgroundColor: '#f9f9f9' }}
                      >
                        <TableCell component="th" scope="row" align="center" style={{ border: 'none', backgroundColor: '#f9f9f9' }}>
                          {row.estudiante}
                        </TableCell>
                        <TableCell align="center" style={{ border: 'none', backgroundColor: '#f9f9f9' }}>{row.mentor}</TableCell>
                        <TableCell align="center" style={{ border: 'none', backgroundColor: '#f9f9f9' }}>{row.status}</TableCell>
                        <TableCell align="center" style={{ border: 'none', backgroundColor: '#f9f9f9' }}>{row.actions}</TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer> : null}
            <div className="pagination-admin">
              <div className="container-download-info">
                <h3>Total Estudiantes: {userFind?.total >= 0 ? userFind?.total : students?.totalElements}</h3>
                <CSVLink {...csvReport} style={{ textDecoration: 'none' }}>
                  <button
                    style={{
                      backgroundColor: '#fe7c7c',
                      height: '33px',
                      borderRadius: '5px',
                      color: '#FFFFFF',
                      textAlign: 'center',
                      marginRight: '45px',
                    }}
                  >
                    Descargar estudiantes
                  </button>
                </CSVLink>
              </div>
              {userFind?.total >= 0 ? null :
                <div className="info-pagination-admin">
                  <button className={'home-unilever-arrow-button'} onClick={handleBefore}>
                    <NavigateBeforeIcon />
                  </button>
                  <h3>{page}</h3>
                  <h3>-</h3>
                  <h3>{data.totalPage}</h3>
                  {page !== data.totalPage ?
                    <button className={'home-unilever-arrow-button'} onClick={handleNext}>
                      <NavigateNextIcon />
                    </button>
                    : null
                  }
                </div>
              }
            </div>
          </div>
          {
            popup && (
              <Dialog
                style={{}}
                open={popup}
                onClose={() => closeSelectedRow()}
                // scroll={scroll}
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
              >
                <DialogTitle id="scroll-dialog-title">Confirmar Match</DialogTitle>
                <DialogContent>
                  <div className="container-info-PopUP">
                    <h3 className="info-text-popup">{selectedRow.Coallier_user.username}</h3>
                    <SwapVertSharpIcon />
                    <select onChange={(e) => setSelectedMentor(e.target.value)} className="info-text-popup">
                      {selectedRow.mentorvsstudent_relation ?
                        <option defaultValue value={selectedRow?.mentorvsstudent_relation?._id}>
                          {selectedRow.mentorvsstudent_relation.name + ' ' + selectedRow.mentorvsstudent_relation.lastName + ' - ' + selectedRow.mentorvsstudent_relation.mentoring_area + ' - ' + selectedRow.mentorvsstudent_relation.themes_interest}
                        </option> : null
                      }
                      <option>Mentores</option>
                      {
                        mentors?.map((mentor, index) => (
                          <option key={index} value={mentor._id}>{mentor.name} {mentor.lastName} - {mentor.mentoring_area} - {mentor.themes_interest}</option>
                        ))
                      }
                    </select>
                  </div>
                </DialogContent>
                <DialogActions>
                  <button style={{
                    backgroundColor: '#fe7c7c',
                    height: '33px',
                    width: '118px',
                    borderRadius: '5px',
                    color: '#FFFFFF',
                    textAlign: 'center',
                    marginRight: '45px',
                  }}
                    onClick={handleOnClickMatch}
                  >
                    Aceptar</button>
                  <Button onClick={() =>
                    closeSelectedRow()
                  }>
                    Cerrar
                  </Button>
                </DialogActions>
              </Dialog>
            )
          }
        </div>
      </div>
    </AdminLayout >
  );
}