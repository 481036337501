import React, { useContext } from "react";
import Loader from "../../../../empresa/Loader/Loader";
import "../../../../empresa/home/homeEmpresav2.css";
import Sidebar from "../../../components/sidebar/Sidebar";
import Navbar from "../../../components/navbar/Navbar";
import { useHomeCompanyData } from "./utils/useHomeCompanyData";
import { ComponentWrapper, BodyWebPapa } from "../../../styles/GlobalStyles";
import { CustomizableTitle } from "../../../styles/TitleStyles";
import { SchneiderContext } from "../../../context/SchneiderContext";
import { Bar, BarChart, Legend, Tooltip, XAxis } from "recharts";
import notFound from "../../../../assets/icons/notFound.svg";
import CompanyOpportunityCard from "../../../components/cards/oportunitycard/companycards/CompanyOpportunityCard";
// @ts-ignore
import { CSVLink } from "react-csv";
import RenderPaginate from "../../professional/oportunities/partials/renderPaginate";
import {DownloadLabelButton} from "../../../components/buttons/ButtonStyles";
import TourCompany from "../../../components/tour/TourCompany";
const CompanyHome = () => {
  const { schneider } = useContext(SchneiderContext);
  const {
    projectsCsv,
    userProjects,
    statistics,
    page,
    loading,
    handlePageClick,
    lang,
    csvReport,
  } = useHomeCompanyData();

  const renderCustomBarLabel = ({
    payload,
    x,
    y,
    width,
    height,
    value,
  }: {
    payload?: any;
    x?: number;
    y?: number;
    width?: number;
    height?: number;
    value?: number;
  }) => {
    return (
      <text x={x + width / 2} y={y} fill="#666" textAnchor="middle" dy={-6}>
        {`${value}`}
      </text>
    );
  };

  return (
    <ComponentWrapper>
      <TourCompany/>
      <Sidebar />
      <BodyWebPapa>
        <Navbar />
        <CustomizableTitle
          color={schneider.color.primary}
          size={"40px"}
          margin={"0 0 2rem 0"}
          weight={"700"}
          id="home"
        >
          {lang === "es" ? "¡Bienvenido a Schneider!" : "Welcome to Schneider!"}
        </CustomizableTitle>
        <div className="new-empresa-home-container">
          <div className="new-empresa-home-body">
            <div className="container-formacion-oportunidades-new">
              <div className="home-published-opportunities">
                <div className="container-grid-list">
                  <CustomizableTitle
                    color={schneider.color.primary}
                    size={"24px"}
                    weight={"700"}
                    margin={"5px 0 0 0"}
                  >
                    {lang === "es"
                      ? "Oportunidades Publicadas"
                      : "Posted Opportunities"}
                  </CustomizableTitle>
                </div>
                <div
                  style={{
                    width: "100%",
                    paddingRight: "15px",
                  }}
                  className={"container-cards-grid"}
                  id="projects"
                >
                  {!userProjects.length && (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "100%",
                        marginTop: "5%",
                      }}
                    >
                      <Loader
                        render={loading}
                        array={userProjects}
                        altura={15}
                      />
                    </div>
                  )}
                  {userProjects?.map((e: any, i: number) => {
                    return (
                      <div key={i + "c"}>
                        <CompanyOpportunityCard
                          id={e._id}
                          titulo={e.NombreOportunidad}
                          titulo2={e.nombre}
                          imagen={e.imageUrl}
                          ciudad={e.municipio ? e.municipio : null}
                          pais={e.country ? e.country : null}
                          aplicantes={e.aplicantes}
                          habilidades={
                            e.habilidadesTecnicas
                              ? e.habilidadesTecnicas.slice(0, 1)
                              : e.habilidadesTecnicas.slice(0, 1)
                          }
                        />
                      </div>
                    );
                  })}
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "100%",
                    height: "10%",
                  }}
                >
                  <div>
                    {page.totalPage > 1 && (
                      <RenderPaginate
                        projects={userProjects}
                        handlePageClick={handlePageClick}
                        page={page}
                        loading={loading}
                      />
                    )}
                  </div>
                </div>
              </div>
              <div className="new-home-empresa-statistics-container">
                <div className="new-home-empresa-statistics-title">
                  <h2
                    style={{
                      fontFamily: "Poppins",
                      fontWeight: "700",
                      fontSize: "14px",
                      color: schneider.color.primary
                    }}
                  >
                    {lang === "es"
                      ? "Estadísticas de los aplicantes"
                      : "Applicants statistics"}
                  </h2>
                </div>
                <div className="new-home-empresa-statistics-body">
                  {statistics.length ? (
                    <>
                      <div className="new-home-empresa-statistics-body-title">
                        {/*<h2
                          style={{
                            fontFamily: "Poppins",
                            fontWeight: "700",
                            fontSize: "14px",
                            color: "#151515FC",
                          }}
                        ></h2>*/}
                        {/*<div className="new-home-empresa-statistics-arrows">
                          <div>
                            <NavigateBeforeOutlinedIcon className="navigate-arrow-statistics" />
                          </div>
                          <div>
                            <NavigateNextOutlinedIcon className="navigate-arrow-statistics" />
                          </div>
                        </div>*/}
                      </div>

                      <div className="new-home-empresa-statistics-body-chart">
                        <BarChart
                          width={250}
                          height={300}
                          data={statistics}
                          margin={{
                            top: 5,
                            right: 30,
                            left: 20,
                            bottom: 5,
                          }}
                        >
                          <XAxis dataKey="nombre" />
                          <Tooltip />
                          <Legend />
                          <Bar
                            dataKey="aplicantes"
                            label={renderCustomBarLabel}
                            fill={schneider.color.primary}
                          />
                        </BarChart>
                      </div>
                    </>
                  ) : (
                    statistics.length === 0 && (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          width: "100%",
                          height: "100%",
                          flexDirection: "column",
                        }}
                      >
                        <img src={notFound} alt="no encontrado" />
                        <h2
                          style={{
                            fontFamily: "Poppins",
                            fontWeight: "700",
                            fontSize: "14px",
                            color: "#151515FC",
                          }}
                        >
                          {lang === "es"
                            ? "No hay datos para mostrar"
                            : "No data to show"}
                        </h2>
                      </div>
                    )
                  )}
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                    paddingTop: "20%",
                  }}
                >
                  {projectsCsv?.length > 0 ? (
                    <DownloadLabelButton color={schneider.color.primary}>
                      <CSVLink
                        {...csvReport}
                        style={{
                          textDecoration: "none",
                          color: "white",
                          textAlign: "center",
                        }}
                      >
                        {lang === "es"
                          ? "Descargar oportunidades"
                          : "Download opportunities"}
                      </CSVLink>
                    </DownloadLabelButton>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      </BodyWebPapa>
    </ComponentWrapper>
  );
};

export default CompanyHome;
