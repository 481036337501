/* eslint-disable */
import React, { useEffect, useState, useContext } from "react";
import {useParams, useHistory, Link} from "react-router-dom";
import counterpart from "counterpart";
import en from "../../../lang/en-US";
import es from "../../../lang/es-COL"
import Translate from "react-translate-component";
// import Lock from "../../assets/icons/lock-alt.svg"

import {
  faltainfoinitial,
  filterSelectionProcess,
  mirarfilterSelectionProcess,
} from "../../../actions";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import { CVdocument} from "../../../utils/CvCreator";
import { PDFDownloadLink } from "@react-pdf/renderer";
import './professionalProfileV2.css'
import 'react-toastify/dist/ReactToastify.css';
import UniversitiesNavbar from "../../components/navbar/UniversitiesNavbar";
import UniversitiesSideBar from "../../components/sidebar/UniversitiesSideBar";
import larrow from "../../../assets/icons/l-arrow.svg";
import ProfileCard from "./profileCard/ProfileCard";
import OportunidadIdiomaCard from "../../../profesionalpag/PerfilProfesional/sideCards/oportunidadIdiomaCard";
import download from "../../../assets/icons/download-icon.svg";
import work from "../../../assets/icons/work-image.svg";
import {UniversityContext} from "../../context/UniversitiesContext";
export default function ProfessionalProfileCustom() {
  const {university} = useContext(UniversityContext);
  const { id } = useParams();
  const dispatch = useDispatch();
  const plan = useSelector((state) => state.userPlan);
  const candidatestate = useSelector((state) => state.filterSelectionProcess);
  const [candidate, setcandidate] = useState("");

  const info = useSelector((state) => state.faltainformacion);
  const user = useSelector((state) => state.user);
  const lang = useSelector((state) => state.lang);
  const history = useHistory();
  const [open, setOpen] = useState(false);
  const [data, setData] = useState("");
  const [applicantData, setApplicantData] = useState("");
  const [readyForUse, setReadyForUse] = useState(false);
  useEffect(() => {
    setcandidate(candidatestate);
    return () => {
      setcandidate("");
    }
  }, [candidatestate]);


  console.log(plan)
  const handleClick = () => {
    setOpen(!open);
  };

  useEffect(() => {
    dispatch(faltainfoinitial());
    dispatch(mirarfilterSelectionProcess());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, id]);


  useEffect(() => {
    if (info === "si") {
      axios
        .get("https://findally-backend.herokuapp.com/user/CVprofile/" + id)
        .then((e) => {
           setcandidate(e?.data?.cv_file);
          if(e?.data?.cv_file?.usuario){
            axios.get(`https://findally-backend.herokuapp.com/user/${e?.data?.cv_file?.usuario}`)
            .then(res => {
              setApplicantData(res.data)
              return setReadyForUse(true)
            }).catch(err => {
              console.log(err)
              return setReadyForUse(true)
            })
          }
          return setReadyForUse(true)
        });
    }
    if (info === "no") {
      dispatch(filterSelectionProcess(id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [info]);

  if (!localStorage.getItem('langlocal')) {
    localStorage.setItem('langlocal', 'es')
  }

  useEffect(() => {
    axios.get(`https://findally-backend.herokuapp.com/user/${id}`)
      .then(res => {
        return setData(res.data)
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  counterpart.registerTranslations('en', en)
  counterpart.registerTranslations('es', es)
  counterpart.setLocale(localStorage.getItem('langlocal'));

  data && console.log(data)

  const handleGoBack = () => {
    if(history.length > 1){
      history.goBack()
    } else {
      history.push(`/${university.route}/company/home`)
    }
  }

  return (
    <>
      {/* <ToastContainer/> */}
      { university && university.color &&
      <div className={"contenedor-oportunidad"}>
        <UniversitiesSideBar location={'home'}/>
        <div className="BodyWeb"
             style={{
               padding: "0rem 2rem", position: "absolute", right: "0",
             }}>
          <UniversitiesNavbar locate={'home'}/>
          {/*head perfil: los (links under the hood son un div)*/}
          <span className={'perfil-prof-header'} style={{cursor: 'pointer'}}  onClick={handleGoBack}>
            <img src={larrow} alt={''}/>
            <h1 className={'perfil-prof-titulo'} style={{
              color: university && university.color ? university.color.secondary : '#000',
            }}>{lang === 'es' ? 'Perfil' : 'Profile'}</h1>
          </span>
          {/*body perfil*/}
          <div className={'perfil-prof-body'}>
            <div className={'perfil-prof-body-izq'}>
              {/*acá van tus componentes y sections @nahuel*/}
              <ProfileCard user={data} cv={candidate} lang={lang}/>
              <OportunidadIdiomaCard user={user} cv={candidate} lang={lang}/>
              {/*<PortfolioCard user={user} cv={cv} lang={lang}/>*/}
            </div>
            <div className={'perfil-prof-body-der'}>
              <div>
                <h1
                  className={'perfil-prof-nombre'}>{candidate?.info_personal?.nombre ? candidate?.info_personal?.nombre : ''}</h1>
                <h2
                  className={'perfil-prof-empleo'}>{candidate?.info_personal?.profesion_actual ? candidate?.info_personal?.profesion_actual : ''}</h2>
              </div>
              <div className={'perfil-prof-sub-cont'}>
                <h2 className={'perfil-prof-sub-subtitulo'}>{lang === 'es' ? 'Acerca de' : 'About'}</h2>
                <p
                  className={'perfil-prof-textos'}>{candidate?.extracto ? candidate?.extracto : ''}</p>
                <hr/>
                <h2 className={'perfil-prof-sub-subtitulo'}>{lang === 'es' ? 'Hoja de vida' : 'Resume'}</h2>
                <div className={'perfil-cv-input-contenedor-global'}>
                  {readyForUse && applicantData?.urlCVPersonalized ?
                    <a href={applicantData.urlCVPersonalized} target={"_blank"} rel={"noopener noreferrer"}>
                    <label className={'perfil-cv-input-contenedor'}>
                    <div className={'perfil-cv-input-subcontenedor'} style={{background: university && university.color ? university.color.secondary : '#000'}}>
                      <img src={download} alt={'img-upload'}/>
                    </div>
                    <div>
                      <h3
                        className={'perfil-cv-input-title'} style={{marginLeft:'5px'}}>{lang === 'es' ? 'Descargar hoja de vida' : 'Download resume'}</h3>
                      <h3 className={'perfil-cv-input-subtitle'} style={{marginLeft:'5px'}}>PDF</h3>
                    </div>
                  </label></a> :
                    <label className={'perfil-cv-input-contenedor'} onClick={() => {document.getElementById('download').click()}}>
                      <div className={'perfil-cv-input-subcontenedor'} style={{background: university && university.color ? university.color.secondary : '#000'}}>
                        <img src={download} alt={'img-upload'}/>
                      </div>
                      <div>
                        <h3
                          className={'perfil-cv-input-title'} style={{marginLeft:'5px'}}>{lang === 'es' ? 'Descargar hoja de vida' : 'Download resume'}</h3>
                        <h3 className={'perfil-cv-input-subtitle'} style={{marginLeft:'5px'}}>PDF</h3>
                      </div>
                    </label>}
                  <div style={{ display: 'none' }}>
                    <PDFDownloadLink
                      document={<CVdocument user={{ cv_file: candidate }} />}
                      fileName={`Cv-${candidate?.info_personal?.nombre}.pdf`}
                    >
                      <button
                        id="download"
                        style={{
                          backgroundColor: "transparent",
                          height: "35px",
                          borderRadius: "5px",
                          borderStyle: "solid",
                          borderWidth: "2px",
                        }}
                      ></button>
                    </PDFDownloadLink>
                  </div>
                </div>
              </div>
              <div className={'perfil-prof-sub-cont'}>
                <h2 className={'perfil-prof-sub-subtitulo'}>{lang === 'es' ? 'Educación' : 'Education'}</h2>
                {candidate?.educacion && candidate.educacion.length > 0 && candidate.educacion[0].NombreInstitucion !== "" ? candidate?.educacion?.map((e, i) => {
                  return (<div className={'perfil-edu-container'} key={i}>
                    {e.NombreInstitucion ? <h3 className={'perfil-prof-sub-subtitulo-edu-trabajo'}>{e.NombreInstitucion}</h3> : null}
                    {e.Titulo_Certificacion ? <h3 className={'perfil-prof-sub-subtitulo-edu-trabajo2'}>{e.Titulo_Certificacion}</h3> : null}
                    {e.TipoEduacion ? <h3 className={'perfil-prof-sub-subtitulo-edu-trabajo2'}>{e.TipoEduacion}</h3> : null}
                    {e.fecha_finalizacion ? <h3 className={'perfil-prof-sub-subtitulo-edu-trabajo2'}>{e.fecha_finalizacion}</h3> : null}
                    {e.duracion ? <h3 className={'perfil-prof-sub-subtitulo-edu-trabajo3'}>{e.duracion}</h3> : null}
                    {(i + 1) < candidate?.experiencia?.length ? <hr/> : null}
                  </div>)
                }) : null}
                <h2
                  className={'perfil-prof-sub-subtitulo'}>{lang === 'es' ? 'Experiencia laboral' : 'Work experience'}</h2>
                {candidate?.experiencia && candidate.experiencia.length > 0 && candidate.experiencia[0].Nombreempresa !== "" ? candidate?.experiencia?.map((e, i) => {
                  console.log(candidate?.experiencia && candidate.experiencia.length > 0 && candidate.experiencia[0].Nombreempresa !== "")
                  return (<div key={i}>
                    <div className={'perfil-trab-container'}>
                      <div>
                        <img src={work} alt={'asd'}/>
                      </div>
                      <div>
                        { e.Nombreempresa ? <h3 className={'perfil-prof-sub-subtitulo-edu-trabajo'}>{e.Nombreempresa}</h3> : null}
                        {e?.cargos?.length ? e.cargos.map((c, i) => {
                          return (<div key={i}>
                            {c.nombrecargo ? <h3
                              className={'perfil-prof-sub-subtitulo-edu-trabajo2'}>{lang === 'es' ? `Cargo ${i + 1}: ` : `Charge ${i + 1}: `} {c.nombrecargo}</h3> : null}

                            {c.duracion ?  <h3
                              className={'perfil-prof-sub-subtitulo-edu-trabajo3'}>{c.duracion.replace(/\s*\(.*?\)\s*/g, '')}</h3> : null}
                             <h3
                              className={'perfil-prof-sub-subtitulo-edu-trabajo2'}>{lang === 'es' ? `Descripción: ` : `Description: `} {c.descripcion ? c.descripcion : 'N/A'}</h3>
                          </div>)
                        }) : null}
                        {/* <h3
                          className={'perfil-prof-sub-subtitulo-edu-trabajo3'}>{lang === 'es' ? 'Duración' : 'Duration'} {e.totalDuracion}</h3> */}
                      </div>
                    </div>
                    {(i + 1) < candidate?.experiencia?.length ? <hr/> : null}
                  </div>)
                }) : <p
                  className={'perfil-prof-textos'}>{lang === 'es' ? 'Sin experiencia laboral' : 'No work experience'}</p>}
              </div>
            </div>
          </div>
        </div>
      </div>
      }
    </>
  )
}
