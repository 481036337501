/* eslint-disable*/
import React, {useState, useEffect, useContext} from "react";
import { useSelector, useDispatch } from "react-redux";
import { changeLang, otpRegister, otpVerify } from "../../actions";
import {UniversityContext} from "../context/UniversitiesContext";
import './newOtp.css'

export default function VerificationOtp() {
  const {university} = useContext(UniversityContext);
  const dispatch = useDispatch();
  const userVerif = useSelector((state) => state.userToVerify);
  const [otp, setOtp] = useState(new Array(6).fill(''));
  const [locallang, setLocallang] = useState(localStorage.getItem("langlocal"));

  useEffect(()=>{
    if(!userVerif.id){
      if(localStorage.getItem('userToVerify')){
        const user = JSON.parse(localStorage.getItem('userToVerify'))
        user.id && dispatch(otpRegister(user, locallang))
      }
    }
    userVerif.id && dispatch(otpRegister(userVerif, locallang))
    userVerif.id && window.localStorage.setItem('userToVerify', JSON.stringify(userVerif))
  },[userVerif, dispatch])

  const handleChange = (e, index) => {
    console.log(otp.join(''));
    if(isNaN(e.value)) return false;

    setOtp([...otp.map((d,idx)=>(idx===index ? e.value : d))]);

    if(e.nextSibling) e.nextSibling.focus();

  }
  const handleSubmit = () => {
    if(otp.join('').length !== 6) return false;
    const data = {
      email: userVerif.email,
      otp: otp.join('')
    }
    window.localStorage.removeItem('userToVerify')
    dispatch(otpVerify(data, `/${university.route}`))
  }

  const cambiarlanges = (camlang) => {
    dispatch(changeLang(camlang))
    localStorage.setItem("langlocal", camlang);
    setLocallang(localStorage.getItem("langlocal"));
  };

  return (
    <>
      {university && university.color &&
        <div style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        backgroundColor: '#f6f6f6',
        flexDirection: 'column'
      }}>
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '90px' }}>
            <img src={university.logo} alt="logo" style={{ width: '234px', height: '90px' }} />
          </div>
        <div style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: 'white',
          height: '400px',
          width: '442px',
          borderRadius: '8px',
          boxShadow: '4px rgba(0, 0, 0, 0.25)',
        }}>
          <div style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%',
            height: '100%',
            flexDirection: 'column'
          }}>
            <div style={{
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'flex-start',
              width: '100%',
              paddingBottom: '50px'
            }}>
              <div
                style={{
                  display: "flex",
                  alignItems: "flex-end",
                  justifyContent: 'flex-end',
                  color: "#C5C5C5",
                  width: '100%',
                  paddingRight: '30px'
                }}
              >
                <h3
                  style={
                    localStorage.getItem("langlocal") === "es"
                      ? {
                        margin: "0.3rem",
                        fontSize: "18px",
                        fontFamily: "Poppins",
                        cursor: "pointer",
                        color: university.color.primary ? university.color.primary : "#202256",
                        fontWeight: "700",
                        userSelect: "none",
                      }
                      : {margin: "0.3rem", cursor: "pointer", fontWeight: '300', fontSize: '18px'}
                  }
                  onClick={() => cambiarlanges("es")}
                >
                  SP
                </h3>
                <h3
                  style={
                    localStorage.getItem("langlocal") === "en"
                      ? {
                        margin: "0.3rem",
                        fontSize: "18px",
                        fontFamily: "Poppins",
                        cursor: "pointer",
                        color: university.color.primary ? university.color.primary : "#202256",
                        fontWeight: "700",
                        userSelect: "none",
                      }
                      : {margin: "0.3rem", cursor: "pointer", fontWeight: '300', fontSize: '18px'}
                  }
                  onClick={() => cambiarlanges("en")}
                >
                  EN
                </h3>
              </div>
            </div>
            <div style={{width: '80%', paddingBottom: '50px'}}>
              <h1 style={{fontFamily: 'Poppins', fontSize: '24px', color: '#393939'}}>{
                locallang === 'es' ? "Te hemos enviado un correo, por favor introduce el codigo para verificarte." : "We have sent you an email, please enter the code to verify you."
              }</h1>
            </div>
            <div className="otp">
              {otp.map((d, idx) => {
                return (
                  <input
                    key={idx}
                    maxLength='1'
                    name="otp"
                    type="text"
                    value={d}
                    onChange={(e) => handleChange(e.target, idx)}
                    onFocus={(e) => e.target.select()}
                  />
                )
              })}
            </div>
            <div>
              <button className='otp-button-new'
                      onClick={() => handleSubmit()} style={{
                        background: university.color.primary ? university.color.primary : '',
              }}>{locallang === 'es' ? 'Verificarse' : 'Verify'}</button>
            </div>
          </div>
        </div>
      </div>}
    </>
  )
}
