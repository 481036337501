import React from "react";
import './styles/TyC.css';

// import { Link } from 'react-router-dom';
// import Footer from "../components/Footer/Footer";
import en from "../lang/en-US";
import es from "../lang/es-COL"
import counterpart from "counterpart";
import Translate from "react-translate-component";
import Headertop from "../empresa/headertop/Headertop";
import Header from "../empresa/header/Headerprofesional";

function Faq(props) {

    counterpart.registerTranslations('en', en)
    counterpart.registerTranslations('es', es)
    counterpart.setLocale(localStorage.getItem('langlocal'));
    return (
        <div>
            <div >
                <Header></Header>

                <div  style={{ padding: "0rem 2rem", width: "calc(99vw - 237px)", position: "absolute", right: "0" }}>
                    <Headertop></Headertop>
                    <h1 style={{
                        margin: '0',
                        fontSize: "40px",
                        fontFamily: 'Poppins',
                        fontWeight: '700',
                        color: '#202256',
                        marginBottom: '27px'
                    }}>Preguntas Frecuentes</h1>
                    {/* <Link className="volver-leg-icon" to="/consultor/home">
                        {" "}
                        🠸 {<Translate content="ATRÁS" component="span" />}
                    </Link> */}
                    {/* <p style={{ display: 'flex', margin: '50px', marginLeft: '300px', fontSize: '30px' }}>{<Translate content="FrequentlyAskedQuestion" component="span" />}</p> */}
                    <div className="tyc">
                        <form className="tyc_form">
                            <div className="section">
                                <div class="box">
                                    <div class="box-item">
                                        <p class="title" style={{fontSize: '26px'}}>{<Translate content="Cómopagamos" component="span" />}</p>
                                        <ul class="box-item-content">
                                            <li>{<Translate content="Somoscompletamente" component="span" />}</li>
                                        </ul>
                                    </div>
                                    <div class="box-item">
                                        <p class="title" style={{fontSize: '26px'}}>{<Translate content="Cómofuncionalacontratación" component="span" />}</p>
                                        <ul class="box-item-content">
                                            <li>{<Translate content="Contratoporprestacióndeservicios" component="span" />}</li>
                                        </ul>
                                    </div>
                                    {/* <div class="box-item ">
                                    <p class="title">{<Translate content="CómofuncionaTpaga" component="span" />}</p>
                                    <ul class="box-item-content">
                                        <li>{<Translate content="Tpagaeslaplataforma" component="span" />}</li>
                                        </ul>
                                    </div> */}
                                    <div class="box-item">
                                        <p class="title" style={{fontSize: '26px'}}>{<Translate content="Recibimosproyectosinternacionales" component="span" />}</p>
                                        <ul class="box-item-content">
                                            <li>{<Translate content="Sirecibimospagosporpaypal" component="span" />}</li>
                                        </ul>
                                    </div>
                                    <div class="box-item">
                                        <p class="title" style={{fontSize: '26px'}}>{<Translate content="Decuantotiemposonlosproyectos" component="span" />}</p>
                                        <ul class="box-item-content">
                                            <li>{<Translate content="Nuestrosproyectossuelen" component="span" />}</li>
                                        </ul>
                                    </div>
                                    <div class="box-item">
                                        <p class="title" style={{fontSize: '26px'}}>{<Translate content="Cómohacemoslaselección" component="span" />}</p>
                                        <ul class="box-item-content">
                                            <li>{<Translate content="Coallydesarrollaunproceso" component="span" />}</li>
                                        </ul>
                                    </div>
                                    {/* <div class="box-item">
                                    <p class="title">{<Translate content="Encuantotiempomeavisansimeaceptaron" component="span" />}</p>
                                    <ul class="box-item-content">
                                    <li></li>
                                    </ul>
                                </div> */}
                                    {/* <div class="box-item">
                                    <p class="title">{<Translate content="MiproductoperteneceaCoally" component="span" />}</p>
                                    <ul class="box-item-content">
                                    <li></li>
                                    </ul>
                                </div> */}
                                    {/* <div class="box-item">
                                    <p class="title">{<Translate content="Cómohagoseguimientodemiequipo" component="span" />}</p>
                                    <ul class="box-item-content">
                                    <li></li>
                                    </ul>
                                </div> */}
                                    {/* <div class="box-item">
                                    <p class="title">{<Translate content="Puedocontrataraalgúnmiembrodelequipodirectamente" component="span" />}</p>
                                    <ul class="box-item-content">
                                    <li>{<Translate content="Sideberáspagarelfeederetenciónde300USD" component="span" />}</li>
                                    </ul>
                                </div> */}
                                    <div class="box-item">
                                        <p class="title" style={{fontSize: '26px'}}>{<Translate content="Enquémomentodebopagar" component="span" />}</p>
                                        <ul class="box-item-content">
                                            <li>{<Translate content="Elpagoserealizaantesdepublicartuproyecto" component="span" />}</li>
                                        </ul>
                                    </div>
                                    <div class="box-item">
                                        <p class="title" style={{fontSize: '26px'}}>{<Translate content="Quénosdiferenciadeotrasplataformasfreelancesyconsultoras" component="span" />}</p>
                                        <ul class="box-item-content">
                                            <li>{<Translate content="SomosunaPlataformaEdtech" component="span" />}</li>
                                        </ul>
                                    </div>
                                    <div class="box-item">
                                        <p class="title" style={{fontSize: '26px'}}>{<Translate content="Quéserviciosprestamos" component="span" />}</p>
                                        <ul class="box-item-content">
                                            <li>{<Translate content="DESARROLLODESOFTWAREYBIGDATA" component="span" />}</li>
                                            <li>{<Translate content="MARKETINGDIGITALYESTUDIOSDEMERCADO" component="span" />}</li>
                                            <li>{<Translate content="INNOVACIÓNEINTRAEMPRENDIMIENTO" component="span" />}</li>
                                        </ul>
                                    </div>
                                    <div class="box-item">
                                        <p class="title" style={{fontSize: '26px'}}>{<Translate content="Quédebohacersinoestoydeacuerdoconunentregable" component="span" />}</p>
                                        <ul class="box-item-content">
                                            <li>{<Translate content="Deberásenviarnosunemailacoally3gmailcom" component="span" />}</li>
                                        </ul>
                                    </div>
                                    <div class="box-item">
                                        <p class="title" style={{fontSize: '26px'}}>{<Translate content="CuáleselmargendeCoally" component="span" />}</p>
                                        <ul class="box-item-content">
                                            <li>{<Translate content="Coallygenerael20demargenporproyecto" component="span" />}</li>
                                        </ul>
                                    </div>
                                </div>

                                {/* <p className="content">
                            <h2 className="titulo-preguntas">¿Cómo pagamos?</h2><br></br>
                            Somos completamente digitales, por esto, nuestro pago se hace por PSE, tarjetas de crédito.
                            <br></br>
                            <br></br>
                            <h2 className="titulo-preguntas">¿Cómo funciona la contratación?</h2><br></br>
                            Contrato por prestación de servicios
                            <br></br>
                            <br></br>
                            <h2 className="titulo-preguntas">¿Cómo funciona Tpaga?</h2><br></br>
                            Tpaga es la plataforma por medio de la cual recibimos nuestros pagos. Cuando pagas nuestro fee, se queda congelado y no lo recibimos hasta que verifiques que estés contento con nuestro resultado. Esto te da la confianza de tu inversión en nuestros servicios, y nos asegura un pago a tiempo.
                            <br></br>
                            <br></br>
                            <h2 className="titulo-preguntas">¿Recibimos proyectos internacionales?</h2><br></br>
                            Si, recibimos pagos por paypal
                            <br></br>
                            <br></br>
                            <h2 className="titulo-preguntas">¿De cuanto tiempo son los proyectos?</h2><br></br>
                            Nuestros proyectos suelen ser de 5 semanas, y ponemos todos nuestro esfuerzo por cumplir con estos tiempos.
                            Sin embargo, al publicar tu proyecto hacemos un análisis de cuánto tiempo nos va a tomar resolver tu reto,
                            y depende de la complejidad del mismo.
                            <br></br>
                            <br></br>
                            <h2 className="titulo-preguntas">¿Cómo hacemos la selección ?</h2>
                            <br></br>
                            Coally desarrolla un proceso automático en donde evalúa, entre otras cosas, las habilidades del joven y su fit con el proyecto.
                            <br></br>
                            <br></br>
                            <h2 className="titulo-preguntas">¿En cuanto tiempo me avisan si me aceptaron?</h2>
                            <br></br>
                            <br></br>
                            <h2 className="titulo-preguntas">¿Mi producto pertenece a Coally?</h2><br></br>
                            <br></br>
                            <h2 className="titulo-preguntas">¿Cómo hago seguimiento de mi equipo ?</h2>
                            <br></br>
                            <h2 className="titulo-preguntas">¿Puedo contratar a algún miembro del equipo directamente?</h2>
                            <br></br>
                            Si, deberás pagar el fee de retención de 300 USD<br></br>
                            <br></br>
                            <br></br>
                            <h2 className="titulo-preguntas">¿En qué momento debo pagar?</h2>
                            <br></br>
                            El pago se realiza antes de publicar tu proyecto.<br></br>
                            <br></br>
                            <br></br>
                            <h2 className="titulo-preguntas">¿Qué nos diferencia de otras plataformas freelances y consultoras?</h2>
                            <br></br>
                            Somos una Plataforma Edtech y freelance que resuelve los problemas de tu negocio
                            ¿Cómo? , Conectamos empresas con equipos de trabajo de calidad que brindan soluciones direccionadas por las metodologías de Coally
                            <br></br>
                            <br></br>
                            <h2 className="titulo-preguntas">¿Qué servicios prestamos?</h2>
                            <br></br>
                            DESARROLLO DE SOFTWARE Y BIG DATA<br></br>
                            MARKETING DIGITAL Y ESTUDIOS DE MERCADO<br></br>
                            INNOVACIÓN E INTRAEMPRENDIMIENTO<br></br>
                            <br></br>
                            <h2 className="titulo-preguntas">¿Qué debo hacer si no estoy de acuerdo con un entregable?</h2>
                            <br></br>
                            Deberás enviarnos un email a coally3@gmail.com<br></br>
                            <br></br>
                            <h2 className="titulo-preguntas">¿Cuál es el margen de Coally ?</h2>
                            <br></br>
                            Coally genera el 20% de margen por proyecto.<br></br>
                        </p> */}

                            </div>
                        </form>

                    </div>
                    {/* <Footer /> */}
                </div>
            </div>
        </div>
    );
}

export default (Faq);