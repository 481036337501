import React, {useState, useEffect} from "react";
import "./CrearOportunidadNew.css";
import list from "./habilidadesList";
// import ChipInput from "material-ui-chip-input";
import {useDispatch} from "react-redux";
import {saveOportunityForm} from "../../actions";
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const mapId = [50, 51, 52, 53, 54];

export default function Habilities(props) {
  
  const {
    form,
    step,
    setform,
    handleStep,
    handleBackStep,
    lang,
    oportunityForm
  } = props;
  const [button, setbutton] = useState(true);
  const dispatch = useDispatch();
  useEffect(() => {
    if (oportunityForm?.habilidadesRequeridas?.length ? oportunityForm?.habilidadesRequeridas.length > 0 : form?.habilidadesRequeridas.length > 0) {
      setbutton(false);
    }
    //eslint-disable-next-line
  }, [form.habilidadesRequeridas]);

  const handleHability = (e, index) => {
    const position = parseInt(index)
    const selection = e.target.name
    console.log(e.target)
    let allSelection = oportunityForm?.habilidadesRequeridas ? oportunityForm?.habilidadesRequeridas : form[selection]
    if (allSelection.includes(e.target.value)) {
      e.target.value = 'null'
      return toast.error('Ya seleccionaste esta habilidad', {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
    }
      // else if (allSelection.length > 5) {
      //   return toast.error('Solo puedes seleccionar 5 habilidades', {
      //     position: "top-center",
      //     autoClose: 5000,
      //     hideProgressBar: false,
      //     closeOnClick: true,
      //     pauseOnHover: true,
      //     draggable: true,
      //     progress: undefined,
      //   })
    // }
    else {
      allSelection[position] = e.target.value
      setform({
        ...form,
        habilidadesRequeridas: allSelection,
      });
      dispatch(saveOportunityForm({...oportunityForm, habilidadesRequeridas: allSelection}, step))
      return setbutton(false)
    }
  }
  const handleGoBack = () => {
    handleBackStep()
  }
  // const handleSkillsChange = (chip) => {
  //   const position = parseInt(index)
  //   const selection = chip
  //   console.log(index)
  //   let allSelection = oportunityForm?.habilidadesRequeridas ? oportunityForm?.habilidadesRequeridas : form[selection]
  //   // let resultado = new Set(form.habilidadesRequeridas.concat(chip));
  //   if (allSelection.includes(chip)) {
  //     return toast.error('Ya seleccionaste esta habilidad', {
  //       position: "top-center",
  //       autoClose: 5000,
  //       hideProgressBar: false,
  //       closeOnClick: true,
  //       pauseOnHover: true,
  //       draggable: true,
  //       progress: undefined,
  //     })
  //   }
  //   if (allSelection.length > 5) {
  //     return toast.error('Solo puedes seleccionar 5 habilidades', {
  //       position: "top-center",
  //       autoClose: 5000,
  //       hideProgressBar: false,
  //       closeOnClick: true,
  //       pauseOnHover: true,
  //       draggable: true,
  //       progress: undefined,
  //     })
  //   } else {
  //     allSelection[position] = chip
  //     setform({
  //       ...form,
  //       habilidadesRequeridas: allSelection,
  //     });
  //     return dispatch(saveOportunityForm({
  //       ...oportunityForm,
  //       habilidadesRequeridas: [...oportunityForm.habilidadesRequeridas, ...allSelection]
  //     }, step))
  //   }
  // }
  //
  // const handleSkillsDeleteChip = (chipToDelete) => {
  //   setform({
  //     ...form,
  //     habilidadesRequeridas: form.habilidadesRequeridas.filter((chip) => chip.key !== chipToDelete.key),
  //   });
  // }
  return (
    <>
      <div className="contenedorPresupuesto" style={{alignItems: "center"}}>
        <div
          className="contenedorHabilidad">
          <h3
            style={{
              fontFamily: "Poppins",
              fontWeight: "700",
              fontSize: "14px",
              marginBottom: "20px",
              marginLeft: "-10px",
            }}
          >
            {lang === "es"
              ? "¿Qué habilidades requieres para la oportunidad? (Max 5)"
              : "What skills do you need for the opportunity (Max 5)"}
          </h3>

          {(oportunityForm?.SeleccionaCarrera === "Agronomía, Veterinaria y afines" || form?.SeleccionaCarrera === "Agronomía, Veterinaria y afines") &&
            mapId.map((id, index) => (
              <select
                className="selectCurrency"
                key={id}
                onChange={(e) => handleHability(e, index)}
                value={oportunityForm?.habilidadesRequeridas?.length ? oportunityForm?.habilidadesRequeridas[index] : form?.habilidadesRequeridas[index]}
                name='habilidadesRequeridas'
              >
                <option key={'vacío'} value={null}></option>
                {lang === "es"
                  ? list.Agronomia_Veterinaria.map((item, index) => {
                    return (
                      <option key={index} value={item}>
                        {item}
                      </option>
                    );
                  })
                  : list.Agronomia_Veterinaria_EN.map((item, index) => {
                    return (
                      <option key={index} value={item}>
                        {item}
                      </option>
                    );
                  })}
              </select>
            ))}
          {(oportunityForm?.SeleccionaCarrera === "Bellas Artes" || form?.SeleccionaCarrera === "Bellas Artes") &&
            mapId.map((id, index) => (
              <select
                className="selectCurrency"
                key={id}
                onChange={(e) => handleHability(e, index)}
                value={oportunityForm?.habilidadesRequeridas?.length ? oportunityForm?.habilidadesRequeridas[index] : form?.habilidadesRequeridas[index]}
                name='habilidadesRequeridas'
              >
                <option key={'vacío'} value={'null'}></option>
                {lang === "es"
                  ? list.Bellas_Artes.map((item, index) => {
                    return (
                      <option key={index} value={item}>
                        {item}
                      </option>
                    );
                  })
                  : list.Bellas_Artes_EN.map((item, index) => {
                    return (
                      <option key={index} value={item}>
                        {item}
                      </option>
                    );
                  })}
              </select>
            ))}
          {(oportunityForm?.SeleccionaCarrera === "Ciencias de la educación" || form?.SeleccionaCarrera === "Ciencias de la educación") &&
            mapId.map((id, index) => (
              <select
                className="selectCurrency"
                key={id}
                onChange={(e) => handleHability(e, index)}
                value={oportunityForm?.habilidadesRequeridas?.length ? oportunityForm?.habilidadesRequeridas[index] : form?.habilidadesRequeridas[index]}
                name='habilidadesRequeridas'
              >
                <option key={'vacío'} value={null}></option>
                {lang === "es"
                  ? list.Educacion.map((item, index) => {
                    return (
                      <option key={index} value={item}>
                        {item}
                      </option>
                    );
                  })
                  : list.Educacion_EN.map((item, index) => {
                    return (
                      <option key={index} value={item}>
                        {item}
                      </option>
                    );
                  })}
              </select>
            ))}
          {(oportunityForm?.SeleccionaCarrera === "Ciencias de la salud" || form?.SeleccionaCarrera === "Ciencias de la salud") &&
            mapId.map((id, index) => (
              <select
                className="selectCurrency"
                key={id}
                onChange={(e) => handleHability(e, index)}
                value={oportunityForm?.habilidadesRequeridas?.length ? oportunityForm?.habilidadesRequeridas[index] : form?.habilidadesRequeridas[index]}
                name='habilidadesRequeridas'
              >
                <option key={'vacío'} value={null}></option>
                {lang === "es"
                  ? list.Salud.map((item, index) => {
                    return (
                      <option key={index} value={item}>
                        {item}
                      </option>
                    );
                  })
                  : list.Salud_EN.map((item, index) => {
                    return (
                      <option key={index} value={item}>
                        {item}
                      </option>
                    );
                  })}
              </select>
            ))}
          {(oportunityForm?.SeleccionaCarrera === "Ciencias sociales y humanas" || form?.SeleccionaCarrera === "Ciencias sociales y humanas") &&
            mapId.map((id, index) => (
              <select
                className="selectCurrency"
                key={id}
                onChange={(e) => handleHability(e, index)}
                value={oportunityForm?.habilidadesRequeridas?.length ? oportunityForm?.habilidadesRequeridas[index] : form?.habilidadesRequeridas[index]}
                name='habilidadesRequeridas'
              >
                <option key={'vacío'} value={null}></option>
                {lang === "es"
                  ? list.CS_Humanas.map((item, index) => {
                    return (
                      <option key={index} value={item}>
                        {item}
                      </option>
                    );
                  })
                  : list.CS_Humanas_EN.map((item, index) => {
                    return (
                      <option key={index} value={item}>
                        {item}
                      </option>
                    );
                  })}
              </select>
            ))}
          {(oportunityForm?.SeleccionaCarrera === "Economía, administración, contaduría y afines" || form?.SeleccionaCarrera === "Economía, administración, contaduría y afines") &&
            mapId.map((id, index) => (
              <select
                className="selectCurrency"
                key={id}
                onChange={(e) => handleHability(e, index)}
                value={oportunityForm?.habilidadesRequeridas?.length ? oportunityForm?.habilidadesRequeridas[index] : form?.habilidadesRequeridas[index]}
                name='habilidadesRequeridas'
              >
                <option key={'vacío'} value={null}></option>
                {lang === "es"
                  ? list.Economia.map((item, index) => {
                    return (
                      <option key={index} value={item}>
                        {item}
                      </option>
                    );
                  })
                  : list.Economia_EN.map((item, index) => {
                    return (
                      <option key={index} value={item}>
                        {item}
                      </option>
                    );
                  })}
              </select>
            ))}
          {(oportunityForm?.SeleccionaCarrera === "Ingeniería, arquitectura, urbanismo y afines" || form?.SeleccionaCarrera === "Ingeniería, arquitectura, urbanismo y afines") &&
            mapId.map((id, index) => (
              <select
                className="selectCurrency"
                key={id}
                onChange={(e) => handleHability(e, index)}
                value={oportunityForm?.habilidadesRequeridas?.length ? oportunityForm?.habilidadesRequeridas[index] : form?.habilidadesRequeridas[index]}
                name='habilidadesRequeridas'
              >
                <option key={'vacío'} value={null}></option>
                {lang === "es"
                  ? list.Ingenieria.map((item, index) => {
                    return (
                      <option key={index} value={item}>
                        {item}
                      </option>
                    );
                  })
                  : list.Ingenieria_EN.map((item, index) => {
                    return (
                      <option key={index} value={item}>
                        {item}
                      </option>
                    );
                  })}
              </select>
            ))}
          {(oportunityForm?.SeleccionaCarrera === "Matemáticas y ciencias naturales" || form?.SeleccionaCarrera === "Matemáticas y ciencias naturales") &&
            mapId.map((id, index) => (
              <select
                className="selectCurrency"
                key={id}
                onChange={(e) => handleHability(e, index)}
                value={oportunityForm?.habilidadesRequeridas?.length ? oportunityForm?.habilidadesRequeridas[index] : form?.habilidadesRequeridas[index]}
                name='habilidadesRequeridas'
              >
                <option key={'vacío'} value={null}></option>
                {lang === "es"
                  ? list.Matematicas.map((item, index) => {
                    return (
                      <option key={index} value={item}>
                        {item}
                      </option>
                    );
                  })
                  : list.Matematicas_EN.map((item, index) => {
                    return (
                      <option key={index} value={item}>
                        {item}
                      </option>
                    );
                  })}
              </select>
            ))}

          {(oportunityForm?.SeleccionaCarrera === "Otro" || form?.SeleccionaCarrera === "Otro" || oportunityForm?.SeleccionaCarrera !== "Agronomía, Veterinaria y afines" || form?.SeleccionaCarrera !== "Agronomía, Veterinaria y afines" ||
          oportunityForm?.SeleccionaCarrera !== "Bellas Artes" || form?.SeleccionaCarrera !== "Bellas Artes" || oportunityForm?.SeleccionaCarrera !== "Ciencias de la educación" || form?.SeleccionaCarrera !== "Ciencias de la educación" ||
          oportunityForm?.SeleccionaCarrera !== "Ciencias de la salud" || form?.SeleccionaCarrera !== "Ciencias de la salud" || oportunityForm?.SeleccionaCarrera !== "Ciencias sociales y humanas" || form?.SeleccionaCarrera !== "Ciencias sociales y humanas" ||
          oportunityForm?.SeleccionaCarrera !== "Economía, administración, contaduría y afines" || form?.SeleccionaCarrera !== "Economía, administración, contaduría y afines" ||
          oportunityForm?.SeleccionaCarrera !== "Ingeniería, arquitectura, urbanismo y afines" || form?.SeleccionaCarrera !== "Ingeniería, arquitectura, urbanismo y afines" || oportunityForm?.SeleccionaCarrera !== "Matemáticas y ciencias naturales" || form?.SeleccionaCarrera !== "Matemáticas y ciencias naturales") && 
            mapId.map((id, index) => (
              <select
                className="selectCurrency"
                key={id}
                onChange={(e) => handleHability(e, index)}
                value={oportunityForm?.habilidadesRequeridas?.length ? oportunityForm?.habilidadesRequeridas[index] : form?.habilidadesRequeridas[index]}
                name='habilidadesRequeridas'
              >
                <option key={'vacío'} value={null}></option>
                {lang === "es"
                  ? list.Otro.map((item, index) => {
    
                    return (
                      <option key={index} value={item}>
                        {item}
                      </option>
                    );
                  })
                  : list.Otro_EN.map((item, index) => {
                    return (
                      <option key={index} value={item}>
                        {item}
                      </option>
                    );
                  })}
              </select>
            ))}
          {/*<ChipInput*/}
          {/*  onChange={(chip, index) => handleSkillsChange(chip, index)}*/}
          {/*  onDelete={(chip, index) => handleSkillsDeleteChip(chip, index)}*/}
          {/*  style={{width: "70%"}}*/}
          {/*  placeholder={*/}
          {/*    lang === "es"*/}
          {/*      ? "Introduce una habilidad y luego enter"*/}
          {/*      : "Introduce a skill and then enter"*/}
          {/*  }*/}
          {/*  fullWidthInput={false}*/}
          {/*  disableUnderline={true}*/}
          {/*  alwaysShowPlaceholder={false}*/}
          {/*  allowDuplicates={false}*/}
          {/*  disabled={oportunityForm?.habilidadesRequeridas?.length >= 5 ? true : false}*/}
          {/*/>*/}
        </div>
      </div>
      <div
        style={{
          display: "flex",
          width: "100%",
          justifyContent: "space-between",
          padding: "1rem 3rem 0rem 3rem",
          alignItems: "center",
        }}
      >
        <button className="button_siguiente_create_B" onClick={handleGoBack}>
          {lang === "es" ? "Anterior" : "Previous"}
        </button>
        <h3 className="stepCounter">{`${step}/14`}</h3>
        <button className="button_siguiente_create" onClick={() => handleStep('habilidadesRequeridas')}
                disabled={button}>
          {lang === "es" ? "Siguiente" : "Next"}
        </button>
      </div>
    </>
  );
}
