import React from 'react'

const MissTest = ({ user, lang }) => {
  return (
    <>
      <h3 
      className='dashop-tab-job2'>
        {
          user?.personalityTest?.length < 1 && user?.social_skills?.length < 1 ? lang === 'es' ? 'En espera de' : 'Waiting for' : null
        }
      </h3>
      <p 
      className='dashop-entrev2'
      >
        {
          user?.personalityTest?.length < 1 ? lang === 'es' ? 'Test de personalidad' : 'Personality test' : null
        }
      </p>
      <p 
      className='dashop-entrev2'>
        {
          user?.social_skills?.length < 1 ? lang === 'es' ? 'Test de habilidades sociales' : 'Social skills test' : null
        }
      </p>
    </>
  )
}

export default MissTest