import React, { Fragment, useState } from "react";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button";
import en from "../../lang/en-US";
import es from "../../lang/es-COL"
import counterpart from "counterpart";
import Translate from "react-translate-component";

// Destructuring props
const FirstStep = ({
  handleNext,
  handleChange,
  values: { nombre, ciudad, metricas, problema_empresa, causa_empresa, objetivos },
  formErrors
}) => {
  // Check if all values are not empty or if there are some error
  const isValid =
    nombre.length > 0 &&
    !formErrors.nombre &&
    ciudad.length > 0 &&
    !formErrors.ciudad &&
    metricas.length > 0 &&
    !formErrors.metricas &&
    causa_empresa.length > 0 &&
    !formErrors.causa_empresa &&
    objetivos.length > 0 &&
    !formErrors.objetivos &&
    problema_empresa.length > 0 &&
    !formErrors.problema_empresa;

    const [otroCausa, setotroCausa] = useState("")
    //const lang = useSelector(state => state.lang)

  
    counterpart.registerTranslations('en',en) 
    counterpart.registerTranslations('es',es)  
    counterpart.setLocale(localStorage.getItem('langlocal'));

    const Ciudaddelproyecto =counterpart.translate('Ciudaddelproyecto')

  return (
    <Fragment>
      <Grid container spacing={2} noValidate>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label={<Translate content="QUENOMBRELEPONDRAS" component="span"/>}
            name="nombre"
            placeholder=""
            margin="normal"
            value={nombre || ""}
            onChange={handleChange}
            error={!!formErrors.nombre}
            helperText={formErrors.nombre}
            required
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label={<Translate content="ciudad" component="span"/>}
            name="ciudad"
            placeholder={Ciudaddelproyecto}
            margin="normal"
            value={ciudad || ""}
            onChange={handleChange}
            error={!!formErrors.ciudad}
            helperText={formErrors.ciudad}
            required
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label={<Translate content="CUALESSONLASMETRICASQUEQUIERES" component="span"/>}
            name="metricas"
            placeholder=""
            margin="normal"
            value={metricas || ""}
            onChange={handleChange}
            error={!!formErrors.metricas}
            helperText={formErrors.metricas}
            required
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label={<Translate content="DESCRIBETUPROBLEMAENUNAORACIÓN" component="span"/>}
            name="problema_empresa"
            placeholder=""
            value={problema_empresa || ""}
            onChange={handleChange}
            margin="normal"
            error={!!formErrors.problema_empresa}
            helperText={formErrors.problema_empresa}
            required
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label={<Translate content="CUALESSONLOSOBJETIVOSQUEQUIERES" component="span"/>}
            name="objetivos"
            placeholder=""
            type=""
            value={objetivos || ""}
            onChange={handleChange}
            margin="normal"
            required
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth  margin="normal">
            <InputLabel>{<Translate content="Cuálescreesquesonlascausasdeesteproblema" component="span"/>}</InputLabel>
            <Select value={causa_empresa} onChange={handleChange} name="causa_empresa">
              <MenuItem value={"Me hace falta capital"}>{<Translate content="capital" component="span"/>}</MenuItem>
              <MenuItem value={"La pandemia acabó con mi negocio"}>{<Translate content="pandemia" component="span"/>}</MenuItem>
              <MenuItem value={"El personal no está trabajando como esperaba"}>{<Translate content="Elpersonalnoestátrabajandocomoesperaba" component="span"/>}</MenuItem>
              <MenuItem value={"Siento que estamos estancados"}>{<Translate content="Sientoqueestamosestancados" component="span"/>}</MenuItem>
              <MenuItem value={"Quiero reestructrar las bases de mi negocio"}>{<Translate content="Quieroreestructrarlasbasesdeminegocio" component="span"/>}</MenuItem>
              <MenuItem value={otroCausa} onClick={()=>setotroCausa("otro")} >{<Translate content="Otro" component="span"/>}</MenuItem>
            </Select>
            { otroCausa==='otro' ? 
               <TextField
               fullWidth
               label={<Translate content="Otro" component="span"/>}
               name="causa_empresa"
               placeholder=""
               margin="normal"
               value={causa_empresa || ""}
               onChange={handleChange}
               error={!!formErrors.causa_empresa}
               helperText={formErrors.causa_empresa}
               
             />: null}
          </FormControl>
        </Grid>
      </Grid>
      <div
        style={{ display: "flex", marginTop: 50, justifyContent: "flex-end" }}
      >
        <Button
          variant="contained"
          disabled={!isValid}
          color="primary"
          onClick={isValid ? handleNext : null}
        >
          {<Translate content="siguienteee" component="span"/>}
        </Button>
      </div>
    </Fragment>
  );
};

export default FirstStep;
