export const modalidadEs = [
  { value: 'Presencial', label: 'Presencial' },
  { value: 'Remoto', label: 'Remoto' },
  { value: 'Híbrida', label: 'Híbrida' },
]

export const modalidadEn = [
  { value: 'Presencial', label: 'Presencial' },
  { value: 'Remote', label: 'Remote' },
  { value: 'Hybrid', label: 'Hybrid' },
]

export const jornadas = [
  { value: 'Tiempo completo', label: 'Tiempo completo' },
  { value: 'Tiempo parcial', label: 'Tiempo parcial' },
  { value: 'Por horas', label: 'Por horas' },
]

export const schedules = [
  { value: 'Full time', label: 'Full time' },
  { value: 'Part time', label: 'Part time' },
  { value: 'By hours', label: 'By hours' },
]
export const modalidadContratacion = [
  { value: 'Término indefinido', label: 'Término indefinido' },
  { value: 'Término fijo', label: 'Término fijo' },
  { value: 'Por proyecto', label: 'Por proyecto' },
  { value: 'Prestación de servicios', label: 'Prestación de servicios' },
  { value: 'Contrato por hora o labor', label: 'Contrato por hora o labor' },
  { value: 'Otro', label: 'Otro' },
]

export const hiringModality = [
  { value: 'Indefinite', label: 'Indefinite term' },
  { value: 'Fixed term', label: 'Fixed term' },
  { value: 'Project', label: 'Project' },
  { value: 'Contractor provision of services', label: 'Contractor provision of services' },
  { value: 'Contract by hour or labor', label: 'Contract by hour or labor' },
  { value: 'Other', label: 'Other' },
]

export const seleccionLenguajes = [
  { value: 'Español', label: 'Español' },
  { value: 'Inglés', label: 'Inglés' },
  // { value: 'Chino', label: 'Chino' },
  // { value: 'Alemán', label: 'Alemán' },
  // { value: 'Francés', label: 'Francés' },
  // { value: 'Portugués', label: 'Portugués' },  
  // { value: 'Italiano', label: 'Italiano' }
];

export const selectionLanguages = [
  { value: 'Spanish', label: 'Spanish' },
  { value: 'English', label: 'English' },
  // { value: 'Chinese', label: 'Chinese' },
  // { value: 'German', label: 'German' },
  // { value: 'French', label: 'French' },
  // { value: 'Portuguese', label: 'Portuguese' },  
  // { value: 'Italian', label: 'Italian' }
];



