/* eslint-disable */
import React, { useEffect, useState, useContext } from 'react'
import UniversitiesNavbar from "../../components/navbar/UniversitiesNavbar";
import UniversitiesSideBar from "../../components/sidebar/UniversitiesSideBar";
import { Link, useHistory } from "react-router-dom";
import { useSelector } from 'react-redux';
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import './Styles/CompanyProfile.css';
import Badge from '@mui/material/Badge';
import Avatar from '@mui/material/Avatar';
import homeCoallier from '../../../assets/imagenes/home-coallier.jpg';
import { styled } from '@mui/material/styles';
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DriveFileRenameOutlineOutlinedIcon from "@mui/icons-material/DriveFileRenameOutlineOutlined";
import CardInfoCompany from './CardInfoCompany';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import PopUpEdit from './PopUpEdit';
import { toast } from "react-toastify";
import camara from "../../../assets/icons/camera.svg"
import axios from 'axios';
import PopUpLegal from "./PopUpLegal";
import { useDispatch } from "react-redux";
import { getUserInfo } from "../../../actions"
import {UniversityContext} from "../../context/UniversitiesContext";

function CompanyProfileCustom() {
  const {university} = useContext(UniversityContext);
  const history = useHistory()
  const { user } = useSelector((state) => state);
  const { userPlan } = useSelector((state) => state);
  const lang = useSelector((state) => state.lang);
  const [dataUser, setDataUser] = useState()
  const [changeAvatar, setChangeAvatar] = useState(false);
  const [avatar, setAvatar] = useState(null);
  const [preview, setPreview] = useState(null);
  const [openTest, setOpenTest] = useState(false);
  const [openTestCulturalResult, setOpenTestCulturalResult] = useState(false);
  const [open, setOpen] = useState(false);
  const [openLegal, setOpenLegal] = useState(false);
  const dispatch = useDispatch();
  const handleClose = () => {
    setOpen(false);
  };
  const handleClose2 = () => {
    setOpenLegal(false);
  };
  const handleOpen = () => setOpen(true);
  const handleOpen2 = () => setOpenLegal(true);
  const handleCloseTest = () => setOpenTest(false);
  const handleOpenTestCulturalResult = () => {
    setOpenTest(false);
    setOpenTestCulturalResult(true);
  };
  const handleCloseTestCulturalResult = () => setOpenTestCulturalResult(false);

  /*  useEffect(() => {
     dispatch(getUserInfo(user.id))
   }, [dispatch, user.id])
  */
  useEffect(() => {
    axios.get(`https://findally-backend.herokuapp.com/api/user/Companyform/${user.id}`)
      .then((res) => {
        console.log(res.data.Company_Form[0])
        setDataUser(res.data.Company_Form[0])
      })
      .catch((err) => {
        console.log(err)
      })
  }, [user])
  useEffect(() => {
    if (!avatar) {
      setPreview(undefined)
      return
    }

    const objectUrl = URL.createObjectURL(avatar)
    setPreview(objectUrl)

    // free memory when ever this component is unmounted
    return () => URL.revokeObjectURL(objectUrl)
  }, [avatar])

  const handleFile = (e) => {
    setAvatar(e.target.files[0]);
  }

  const SmallAvatar = styled(Avatar)(({ theme }) => ({
    width: 35,
    height: 35,
    backgroundColor: "#bdbdbd",
    cursor: "pointer",
  }));

  const handleAvatarClose = () => {
    setChangeAvatar(false);
    setAvatar(null)
  };

  const handleAvatarSubmit = () => {
    const formData = new FormData();
    formData.append("upload_avatar", avatar);
    axios.post(`https://findally-backend.herokuapp.com/api/user/uploadAvatarCompany/${user.id}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }).then(res => {
      setChangeAvatar(false);
      dispatch(getUserInfo(user.id))
    }).catch(err => {
      console.log(err)
      toast.error('error cargando el avatar', {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
    })
  }


  //PopUpEdit
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '574px',
    height: '95vh',
    bgcolor: '#FFFFFF',
    borderRadius: '8px',
    overflow: 'auto',
  };
  const styleTestCultural = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '721px',
    height: '85vh',
    bgcolor: '#FFFFFF',
    borderRadius: '8px',
    overflow: 'auto',
  }
  const styleTestCulturalResult = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 772,
    height: 603,
    bgcolor: '#FFFFFF',
    border: '2px solid #000',
    borderRadius: '8px',
    boxShadow: 24,
    overflow: 'auto'
  }

  const tests = [{
    id: 1,
    name: 'Test de cultura organizacional',
    description: 'Realice este test gratuito para saber más sobre sí mismo y sus puntos fuertes.',
  }]

  const testsENG = [{
    id: 1,
    name: 'Organizational culture test',
    description: 'Take this free trial to learn more about yourself and your strengths.',
  }]

  const planLanguage = (plan) => {
    return plan === 'Plan empresarial' ? 'Business plan' : plan === 'Plan Basico' ? 'Standard plan' : 'Free plan'
  }



  return (
    <div>
      {/* <ToastContainer /> */}
      {university && university.color &&
      <div>
        <UniversitiesSideBar />
        <div className={'profile-empresa-global-cont'}>
          {/*<HeadertopV2/>*/}
          <UniversitiesNavbar locate={'oportunidades'} />
          <div>
            <div onClick={() => history.goBack()} className={'profile-title-cont'}>
              <ArrowBackIosIcon />
              <h1 style={{
                fontFamily: "Poppins", color: university && university.color ? university.color.secondary : '#000', fontSize: "40px", fontWeight: "700", marginLeft: '20px'
              }}>
                {dataUser?.nombre_empresa ? dataUser?.nombre_empresa : user?.name}
              </h1>
            </div>
            <div className={'subcont-profile-empresa'}>
              {/*info principal*/}
              <div className={'profle-empresa-infoprincipal-cont'}>
                <div className={'resp-image'}>
                  <div>
                    <Badge overlap="circular" anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                      badgeContent={
                        <SmallAvatar alt="Remy Sharp" src={camara} onClick={() => setChangeAvatar(true)} />
                      } style={{ zIndex: 0 }}>
                      <Avatar src={user.avatar ? user.avatar : homeCoallier} sx={{ width: "158px", height: "158px" }} />
                    </Badge>
                  </div>
                  <div>
                    <Dialog
                      open={changeAvatar}
                      onClose={handleAvatarClose}
                      aria-labelledby="alert-dialog-title"
                      aria-describedby="alert-dialog-description"
                      fullWidth={true}
                      maxWidth="sm"
                    /* sx={ {height: "50%", maxHeight: '50%'} } */
                    >
                      <DialogTitle id="alert-dialog-title">
                        {
                          lang === "es" ? "Cambiar Avatar" : "Change Avatar"
                        }
                      </DialogTitle>
                      <DialogContent sx={{ height: "300px" }}>
                        {/* make 2 inputs  */}
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "space-between",
                            alignItems: "center",
                            height: "100%",
                          }}
                        >
                          <div style={{ width: '200px', height: '200px', display: 'flex', alignItems: 'center' }}>
                            <img src={preview} alt='profile-avatar-new' style={{ width: '100%', height: '100%', display: !preview ? 'hidden' : null }} />
                          </div>
                          <div>
                            <input
                              style={{
                                width: "100%",
                              }}
                              onChange={(e) => handleFile(e)}
                              type="file" />
                          </div>
                          <div>
                            <button
                              onClick={() => handleAvatarSubmit()}
                              style={{
                                backgroundColor: "#202256",
                                color: "white",
                                borderRadius: "5px",
                                fontFamily: "Poppins",
                                height: "2rem",
                                width: "15.62rem",
                                marginTop: "20px",
                                marginBottom: "5px",
                                textAlign: "center",
                                fontSize: "14px",
                              }}
                            >
                              Enviar
                            </button>
                          </div>
                        </div>
                      </DialogContent>
                    </Dialog>
                  </div>
                  <div>
                    <button
                      style={{
                        backgroundColor: "transparent",
                        height: "35px",
                        marginTop: "2em",
                        display: "flex",
                        justifyContent: 'space-between',
                        width: '100%',
                      }}
                      onClick={handleOpen}
                    >
                      {lang === "es" ? "Editar perfil" : "Edit profile"}
                      <DriveFileRenameOutlineOutlinedIcon />
                    </button>
                    <button
                      style={{
                        backgroundColor: "#F07130",
                        height: "35px",
                        display: "flex",
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '100%',
                        borderRadius: '5px',
                        color: '#FFFFFF'
                      }}
                      onClick={handleOpen2}
                    >
                      {lang === "es" ? "Información legal" : "Legal information"}
                    </button>
                    <div style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                      marginTop: '20px',
                      width: '100%'
                    }}>
                      <span style={{
                        color: '#202256',
                        fontWeight: 700,
                        fontSize: '14px',
                        marginBottom: '10px',
                        textTransform: 'capitalize'
                      }}>{planLanguage(userPlan?.nombre_paquete)}</span>
                      <Link to='/company/pricing'>
                        <button style={{
                          background: '#F9F9F9',
                          border: '0.5px solid #202256',
                          borderRadius: '5px',
                          width: '100%',
                        }}>
                          <span
                            style={{ color: '#202256', width: '100%' }}>{lang === 'es' ? 'Mejora tu cuenta' : 'Upgrade your account'}</span>
                        </button>
                      </Link>
                    </div>
                    <Modal
                      open={open}
                      onClose={handleClose}
                      aria-labelledby="modal-modal-title"
                      aria-describedby="modal-modal-description"
                    >
                      <Box sx={style}>
                        <PopUpEdit user={user.id} handleClose={handleClose} dataUser={dataUser} />
                      </Box>
                    </Modal>
                    <Modal
                      open={openLegal}
                      onClose={handleClose2}
                      aria-labelledby="modal-modal-title"
                      aria-describedby="modal-modal-description"
                    >
                      <Box sx={style}>
                        <PopUpLegal user={user.id} handleClose2={handleClose2} dataUser={dataUser} />
                      </Box>
                    </Modal>
                  </div>
                </div>
                {/*info empresa*/}
                <div className={'subcont-profile-empresa-summary'}>
                  <h1 style={{
                    fontWeight: '700',
                    fontSize: '25px',
                    color: '#393939',
                    marginBottom: '25px'
                  }}>{lang === 'es' ? 'Resumen' : 'Summary'}</h1>
                  <p>
                    {dataUser?.descripcion ? dataUser.descripcion : lang === "es" ? "Agrega una descripción" : "Add a description"}
                  </p>
                  <hr />
                  <h3 style={{
                    fontWeight: '700',
                    fontSize: '20px',
                    color: '#393939'
                  }}>{lang === 'es' ? 'Persona jurídica' : 'Legal person'}</h3>
                  <p>Nit: {dataUser?.nit ? dataUser.nit : 'N/A'}</p>
                  <p>{lang === 'es' ? 'Domicilio: ' : 'Address: '} {dataUser?.direccion ? dataUser?.direccion : 'N/A'}</p>
                  <p>{lang === 'es' ? 'Dirección de notificación: ' : 'Notification address: '} {dataUser?.direccion_notificacion ? dataUser?.direccion_notificacion : 'N/A'}</p>
                  <p>{lang === 'es' ? 'Teléfono de contacto : ' : 'Contact phone : '} {dataUser?.telefono[0]?.telefono1 ? dataUser?.telefono[0]?.telefono1 : 'N/A'}</p>
                  {/*<p>{lang === 'es' ? 'Teléfono de contacto 2: ' : 'Contact phone 2: '}</p>*/}
                  <hr />
                  <h3 style={{
                    fontWeight: '700',
                    fontSize: '20px',
                    color: '#393939'
                  }}>{lang === 'es' ? 'Representante legal' : 'Legal representative'}</h3>
                  <p>{lang === 'es' ? 'Nombres y apellidos: ' : 'Name and last name: '} {dataUser?.rep_legal ? dataUser?.rep_legal : 'N/A'}</p>
                  <p>{lang === 'es' ? 'Teléfono de contacto : ' : 'Contact phone : '} {dataUser?.telefono[0]?.telefono2 ? dataUser?.telefono[0]?.telefono2 : 'N/A'}</p>
                  {/*<p>{lang === 'es' ? 'Teléfono de contacto 2: ' : 'Contact phone 2: '}</p>*/}
                  <p>{lang === 'es' ? 'Tipo de Identification: ' : 'Id type: '} {dataUser?.tipo_ident_rep ? dataUser?.tipo_ident_rep : 'N/A'}</p>
                  <p>{lang === 'es' ? 'Número de identificación: ' : 'Id number: '} {dataUser?.identificacion_rep ? dataUser?.identificacion_rep : 'N/A'}</p>
                </div>
              </div>
              {/*barra info*/}
              <div className={'cont-card-info'}>
                <CardInfoCompany user={user.id} dataUser={dataUser} />
              </div>
            </div>
          </div>
        </div>
      </div>
      }
    </div>
    // <div>
    //   <div>
    //     <Header></Header>
    //     <div
    //       className="BodyWeb"
    //       style={{
    //         padding: "0rem 2rem 0rem 6rem",
    //         position: "absolute",
    //         right: "0",
    //       }}
    //     >
    //       <HeadertopV2/>
    //     </div>
    //   </div>
    //   <div className="container-profile"
    //        style={{
    //          flexDirection: "row",
    //          minWidth: "calc(99vw - 237px)",
    //          maxWidth: "calc(99vw - 237px)",
    //          height: "50vh",
    //          position: "absolute",
    //          right: "0",
    //          paddingLeft: "5rem",
    //          justifyContent: "space-evenly",
    //        }}>
    //     <div style={{display: "flex", flexDirection: "column"}}>
    //       <div
    //         className="group1"
    //         style={{
    //           display: "flex",
    //           justifyContent: "flex-start",
    //           width: "100%",
    //           alignItems: "center",
    //           marginTop: "5%",
    //         }}
    //       >
    //         <div onClick={() => history.goBack()} style={{cursor: "pointer"}}>
    //           <ArrowBackIosIcon/>
    //         </div>
    //         <h1
    //           style={{
    //             fontFamily: "Poppins",
    //             fontStyle: "normal",
    //             color: "#202256",
    //             fontSize: "40px",
    //             fontWeight: "700",
    //             marginLeft: "0.5em",
    //             marginBottom: "0",
    //           }}
    //         >
    //           {dataUser?.nombre_empresa ? dataUser?.nombre_empresa : user?.name}
    //         </h1>
    //       </div>
    //       <div className={'group2-company'}
    //            style={{
    //              width: "95%",
    //              marginBottom: "1em",
    //              marginTop: '5%'
    //            }}
    //       >
    //         <div className='responsive-info-company'
    //              style={{display: 'flex', justifyContent: 'space-between'}}
    //         >
    //           <div className={'profile-company'} style={{display: 'flex', justifyContent: "flex-start",}}>
    //
    //             <div className={'profile-company-pic'} style={{marginTop: '2em'}}>
    //               <Badge
    //                 overlap="circular"
    //                 anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
    //               >
    //                 <Avatar
    //                   src={homeCoallier}
    //                   sx={{width: "158px", height: "158px"}}
    //                 />
    //               </Badge>
    //               <div>
    //                 <button
    //                   style={{
    //                     backgroundColor: "transparent",
    //                     height: "35px",
    //                     marginTop: "2em",
    //                     display: "flex",
    //                     justifyContent: 'space-between',
    //                     width: '100%',
    //                   }}
    //                   onClick={handleOpen}
    //                 >
    //                   {
    //                     lang === "es" ? "Editar perfil" : "Edit profile"
    //                   }
    //                   <DriveFileRenameOutlineOutlinedIcon/>
    //                 </button>
    //                 <button
    //                   style={{
    //                     backgroundColor: "#F07130",
    //                     height: "35px",
    //                     display: "flex",
    //                     justifyContent: 'center',
    //                     alignItems: 'center',
    //                     width: '100%',
    //                     borderRadius: '5px',
    //                     color: '#FFFFFF'
    //                   }}
    //                   onClick={handleOpen2}
    //                 >
    //                   {
    //                     lang === "es" ? "Información legal" : "legal information"
    //                   }
    //                 </button>
    //                 <Modal
    //                   open={open}
    //                   onClose={handleClose}
    //                   aria-labelledby="modal-modal-title"
    //                   aria-describedby="modal-modal-description"
    //                 >
    //                   <Box sx={style}>
    //                     <PopUpEdit user={user.id} handleClose={handleClose} dataUser={dataUser}/>
    //                   </Box>
    //                 </Modal>
    //                 <Modal
    //                   open={openLegal}
    //                   onClose={handleClose2}
    //                   aria-labelledby="modal-modal-title"
    //                   aria-describedby="modal-modal-description"
    //                 >
    //                   <Box sx={style}>
    //                     <PopUpLegal user={user.id} handleClose2={handleClose2} dataUser={dataUser}/>
    //                   </Box>
    //                 </Modal>
    //               </div>
    //               <div
    //                 style={{
    //                   display: 'flex',
    //                   flexDirection: 'column',
    //                   flexWrap: 'wrap',
    //                   marginTop: '2em',
    //                   width: '100%'
    //                 }}>
    //                 <span style={{
    //                   color: '#202256',
    //                   fontWeight: 700,
    //                   fontSize: '14px',
    //                   paddingLeft: '6px'
    //                 }}>{userPlan?.nombre_paquete}</span>
    //                 <Link to='/company/pricing'>
    //                   <button style={{
    //                     background: '#F9F9F9',
    //                     border: '0.5px solid #202256',
    //                     borderRadius: '5px',
    //                     minWidth: '191px',
    //                     height: '28px',
    //                     marginTop: '20px'
    //                   }}>
    //                 <span
    //                   style={{color: '#202256'}}>{lang === 'es' ? 'Mejora tu cuenta' : 'Upgrade your account'}</span>
    //                   </button>
    //                 </Link>
    //               </div>
    //             </div>
    //             <div className='resume-profile'>
    //               <h1>{lang === 'es' ? 'Resumen' : 'Resume'}</h1>
    //               <div style={{marginTop: '1em'}}>
    //                 <p>
    //                   {dataUser?.descripcion ? dataUser.descripcion : lang === "es" ? "Agrega una descripción" : "Add a description"}
    //                 </p>
    //               </div>
    //             </div>
    //           </div>
    //           <div style={{height: 'auto', backgroundColor: "red", width: '30%'}}>
    //             <CardInfoCompany user={user.id} dataUser={dataUser}/>
    //           </div>
    //         </div>
    //       </div>
    //     </div>
    //   </div>
    // </div>
  )
}

export default CompanyProfileCustom

//     < div
//   className = {'Company-Test-Container'} >
//   < h1 > {lang === 'es' ? 'Pruebas' : 'Test'
// } </h1>
//   <div style={{marginTop: '10%'}}>
//     {lang === 'es'
//       ? tests.map((test) => {
//         return (
//           test.name === 'Test de cultura organizacional' ?
//             <TestCardCompany id={test.id} name={test.name} description={test.description} user={user}
//                              setOpenTest={setOpenTest}/>
//             : <TestCardCompany id={test.id} name={test.name} description={test.description} user={user}/>
//         )
//       })
//       : testsENG.map((test) => {
//         return (
//           test.name === 'Organizational culture test' ?
//             <TestCardCompany id={test.id} name={test.name} description={test.description} user={user}
//                              setOpenTest={setOpenTest}/>
//             : <TestCardCompany id={test.id} name={test.name} description={test.description} user={user}/>
//         )
//       })
//     }
//   </div>
//   <>
//     <Modal
//       open={openTest}
//       onClose={handleCloseTest}
//     >
//       <Box sx={styleTestCultural}>
//         <TestCompanyCultural handleOpenTestCulturalResult={handleOpenTestCulturalResult}/>
//       </Box>
//     </Modal>
//   </>
//   <>
//     <Modal
//       open={openTestCulturalResult}
//       onClose={handleCloseTestCulturalResult}
//     >
//       <Box sx={styleTestCulturalResult}>
//         <TestCompanyCulturalResult handleCloseTestCulturalResult={handleCloseTestCulturalResult}/>
//       </Box>
//     </Modal>
//   </>
// </div>
