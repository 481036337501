import axios from 'axios'
import React, {useState} from 'react'
import {useSelector} from 'react-redux'
import './Styles/PopUpEdit.css'
import { toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function PopUpEdit({user, handleClose2, dataUser}) {
  const {lang} = useSelector(state => state)
  const [legal, setLegal] = useState({
    nit: dataUser?.nit ? dataUser?.nit : '',
    direccion: dataUser?.direccion ? dataUser?.direccion : '',
    direccion_notificacion: dataUser?.direccion_notificacion ? dataUser?.direccion_notificacion : '',
    telefono: [{telefono1: dataUser?.telefono[0]?.telefono1 ? dataUser?.telefono[0]?.telefono1 : '', telefono2: dataUser?.telefono[0]?.telefono2 ? dataUser?.telefono[0]?.telefono2 : ''}],
    rep_legal: dataUser?.rep_legal ? dataUser?.rep_legal : '',
    tipo_ident_rep: dataUser?.tipo_ident_rep ? dataUser?.tipo_ident_rep : '',
    identificacion_rep: dataUser?.identificacion_rep ? dataUser?.identificacion_rep : ''
  })
  // const [tel1, setTel1] = useState('')
  // const [tel2, setTel2] = useState('')


  const handleEdit = (e) => {
    setLegal({
      ...legal, [e.target.name]: e.target.value
    })
    console.log(legal)
  }

  const handleTel = (e, n) => {
    if (n === 1) {
      setLegal({
        ...legal, telefono: [{telefono1: e.target.value, telefono2: legal.telefono[0].telefono2}]
      })
    }
    if (n === 3) {
      setLegal({
        ...legal, telefono: [{telefono1: legal.telefono[0].telefono1, telefono2: e.target.value}]
      })
    }
    console.log(legal)
  }

  console.log(user)
  const handleOnSubmit = (e) => {
    e.preventDefault()
    console.log(legal)
    if (legal.nit === '' || legal.direccion === '' || legal.direccion_notificacion === '' || legal.telefono[0].telefono1 === '' || legal.telefono[0].telefono2 === '' || legal.rep_legal === '' || legal.tipo_ident_rep === '' || legal.identificacion_rep === '') {
      toast.error('Todos los campos son obligatorios',{
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
    } else {
      if (!dataUser) {
        axios.post(`https://findally-backend.herokuapp.com/api/Company_Form/`, {...legal, usuario: user})
          .then(res => {
            console.log(res)
            handleClose2()
          })
          .then(res => {
            window.location.reload()
          })
          .catch(err => {
            console.log(err)
          })
      } else {
        axios.put(`https://findally-backend.herokuapp.com/api/Company_Form/updatebyuser/${user}`, legal)
          .then(res => {
            console.log(res)
            handleClose2()
          })
          .then(res => {
            window.location.reload()
          })
          .catch(err => console.log(err))
      }
    }
  }

  return (<div>
    <form onSubmit={handleOnSubmit} style={{
      display: 'flex', flexDirection: 'column', justifyContent: 'center', width: '100%', alignItems: 'center',
    }} className={'form-edit-profile-Company'}>
      {/* <ToastContainer /> */}
      <div style={{display: 'flex', alignItems: 'flex-start', width: '100%'}}>
        <button style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          color: '#202256',
          borderRadius: '5px',
          width: '20px',
          height: '20px',
          marginTop: '9px',
          marginLeft: '11px',
          backgroundColor: "transparent",
          border: '2px solid #202256'
        }} onClick={handleClose2}>
          x
        </button>
      </div>
      <div className={'container-input-edit-profile-Company'}
           style={{
             display: 'flex',
             flexDirection: 'column',
             justifyContent: 'center',
             padding: '10px',
             width: '60%',
             minWidth: '500px',
           }}>
        <div style={{
          display: 'flex', flexDirection: 'column', marginTop: '5%'
        }}>
          <h2 style={{
            color: '#393939', fontSize: '24px', fontWeight: 700
          }}>{lang === 'es' ? 'Agregar información legal' : 'Add legal information'} </h2>
          <hr/>
          <p>{lang === 'es' ? '* El asterisco indica que es obligatorio' : '* The asterisk indicates that it is mandatory'}</p>
          <div style={{}}>
            <label style={{fontWeight: "bold"}}>{lang === 'es' ? 'Persona jurídica' : 'Legal person'}</label>
            <hr/>
            <label>NIT *</label>
            <input type="text" style={{width: '100%', marginBottom: '1em'}} onChange={handleEdit} defaultValue={dataUser?.nit ? dataUser?.nit : ''} name='nit'/>
            <label>{lang === 'es' ? 'Domicilio *' : 'Residence *'}</label>
            <input type="text" style={{width: '100%', marginBottom: '2em'}} onChange={handleEdit} defaultValue={dataUser?.direccion ? dataUser?.direccion : ''} name='direccion'/>
            <label>{lang === 'es' ? 'Dirección de notificación *' : 'Notification address *'}</label>
            <input type="text" style={{width: '100%', marginBottom: '2em'}} onChange={handleEdit} defaultValue={dataUser?.direccion_notificacion ? dataUser?.direccion_notificacion : ''}
                   name='direccion_notificacion'/>
            <label>{lang === 'es' ? 'Teléfono de contacto *' : 'Telephone contact *'}</label>
            <input type="text" style={{width: '100%', marginBottom: '2em'}} onChange={(e) => handleTel(e, 1)} defaultValue={dataUser?.telefono[0]?.telefono1 ? dataUser?.telefono[0]?.telefono1 : ''}
                   name='telefono'/>
            <label
              style={{fontWeight: "bold"}}>{lang === 'es' ? 'Representante legal' : 'Legal representative'}</label>
            <hr/>
            <label>{lang === 'es' ? 'Nombres y apellidos *' : 'First and Last Names *'}</label>
            <input type="text" style={{width: '100%', marginBottom: '2em'}} onChange={handleEdit} defaultValue={dataUser?.rep_legal ? dataUser?.rep_legal : ''} name='rep_legal'/>
            <label>{lang === 'es' ? 'Teléfonos de contacto *' : 'Telephone contacts *'}</label>
            <input type="text" style={{width: '100%', marginBottom: '2em'}} onChange={(e) => handleTel(e, 3)} defaultValue={dataUser?.telefono[0]?.telefono2 ? dataUser?.telefono[0]?.telefono2 : ''}
                   name='telefono'/>
            <label>{lang === 'es' ? 'Tipo de Identificación *' : 'ID type *'}</label>
            <input type="text" style={{width: '100%', marginBottom: '2em'}} onChange={handleEdit} defaultValue={dataUser?.tipo_ident_rep ? dataUser?.tipo_ident_rep : ''}
                   name='tipo_ident_rep'/>
            <label>{lang === 'es' ? 'Número de identificación *' : 'ID number *'}</label>
            <input type="text" style={{width: '100%', marginBottom: '2em'}} onChange={handleEdit}  defaultValue={dataUser?.identificacion_rep ? dataUser?.identificacion_rep : ''}
                   name='identificacion_rep'/>
          </div>
          <div className="buttonGuardar">
            <button><span style={{fontSize: '14px', display: 'flex', justifyContent: 'center'}}>
                {lang === 'es' ? 'Guardar' : 'Save'}
              </span></button>
          </div>
        </div>
      </div>
    </form>
  </div>)
}

export default PopUpEdit