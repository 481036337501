import styled from "styled-components";

const OpportunitiesMainContainer = styled.div`
  width: 100%;
  padding-inline: 2.5%;

  @media (max-width: 1400px) {
    padding-left: 0;
    padding-right: 0;
  }
`;

const OpportunitiesContent = styled.div`
  margin: 40px auto 0 auto;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const OpportunitiesBody = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: 32px;

  @media (max-width: 1400px) {
    flex-direction: column;
    justify-content: center;
  }
`;

const OpportunitiesSearchContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 70%;
  background-color: white;
  padding: 15px;
`;

const OpportunitiesInputSearch = styled.input`
  background-color: transparent;
  height: 35px;
  width: 100%;
  border-radius: 5px;
  text-indent: 25px;
  margin-left: 13px;
  border: none;
  margin-right: 0;
  outline: none;
`;

const OpportunitiesCardsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  padding-bottom: 5%;
`;

const OpportunitiesSmallCardsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: 504px;
  align-self: flex-start;
  width: 100%;
  padding-right: 30px;

  @media (max-width: 950px) {
    width: 100%;
    max-width: 100%;
    padding-right: 0;
  }
`;

const OpportunitiesBigCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  align-self: flex-start;
  max-height: 700px;
  width: calc(100% - 504px);

  @media (max-width: 950px) {
    display: none;
  }
`;

export {
  OpportunitiesMainContainer,
  OpportunitiesContent,
  OpportunitiesBody,
  OpportunitiesSearchContainer,
  OpportunitiesInputSearch,
  OpportunitiesCardsContainer,
  OpportunitiesSmallCardsContainer,
  OpportunitiesBigCardContainer,
};
