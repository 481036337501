import React, {useState } from 'react'
import img from "./footer.png"
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import LaptopIcon from '@mui/icons-material/Laptop';
import { connect, useSelector } from "react-redux";
import { Link } from "react-router-dom"
import "./Headerprofesional.css"
import homesvg from "../../assets/icons/home.svg";
import documentsvg from "../../assets/icons/document.svg";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import { Circle } from "@mui/icons-material";
import { logoutUser, changeview } from "../../actions";
import counterpart from "counterpart";
import en from "../../lang/en-US";
import es from "../../lang/es-COL"
import Translate from "react-translate-component";
import configuracion from '../../assets/icons/configuracion.svg';
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

const Headerprofesional = (props) => {
  const handleLogout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    props.logoutUser({}, "/");
  };

  let [formatoCirculo1, setFormatoCirculo1] = useState('circle')
  let [formatoCirculo2, setFormatoCirculo2] = useState('circle')

  let [formatoItem1, setFormatoItem1] = useState('h2-header-empresa')
  let [formatoItem2, setFormatoItem2] = useState('h2-header-empresa')

  let [formatoImagen1, setFormatoImagen1] = useState('imagenes-color')
  let [formatoImagen2, setFormatoImagen2] = useState('imagenes-gris')
  let [formatoImagen3, setFormatoImagen3] = useState('imagenes-gris')

  let [formatoTexto1, setFormatoTexto1] = useState('h2-header-empresa-active')
  let [formatoTexto2, setFormatoTexto2] = useState('h2-header-empresa')
  let [formatoTexto3, setFormatoTexto3] = useState('h2-header-empresa')


  const handleOnClick1 = () => {
    setFormatoCirculo2(formatoCirculo2 = 'circle')
    setFormatoCirculo1(formatoCirculo1 = 'circle-active')
    setFormatoItem1(formatoItem1 = 'h2-header-empresa-active')
    setFormatoItem2(formatoItem2 = 'h2-header-empresa')
    return props.setTab(1)
  }

  const handleOnClick2 = () => {
    setFormatoCirculo2(formatoCirculo2 = 'circle-active')
    setFormatoCirculo1(formatoCirculo1 = 'circle')
    setFormatoItem1(formatoItem1 = 'h2-header-empresa')
    setFormatoItem2(formatoItem2 = 'h2-header-empresa-active')
    return props.setTab(2)
  }

  const handleOnClick3 = () => {
    setFormatoImagen1('imagenes-color')
    setFormatoImagen2('imagenes-gris')

    setFormatoTexto1('h2-header-empresa-active')
    setFormatoTexto2('h2-header-empresa')
  }
  const handleOnClick4 = () => {
    setFormatoImagen2('imagenes-color')
    setFormatoImagen1('imagenes-gris')

    setFormatoTexto2('h2-header-empresa-active')
    setFormatoTexto1('h2-header-empresa')

    setFormatoItem1('h2-header-empresa')
    setFormatoItem2('h2-header-empresa')

    setFormatoCirculo1(formatoCirculo1 = 'circle')
    setFormatoCirculo2(formatoCirculo2 = 'circle')

    return props.setTab ? props.setTab(0) : null
  }
  const handleOnClick5 = () => {
    setFormatoImagen1('imagenes-gris')
    setFormatoImagen2('imagenes-gris')
    setFormatoImagen3('imagenes-color')

    setFormatoTexto1('h2-header-empresa')
    setFormatoTexto2('h2-header-empresa')
    setFormatoTexto3('h2-header-empresa-active')
  }

  const handleOnMouseLeave = () => {
    if (window.location.href.includes("/consultor/projects")) {
      setFormatoImagen2('imagenes-color')
      setFormatoImagen1('imagenes-gris')

      setFormatoTexto2('h2-header-empresa-active')
      setFormatoTexto1('h2-header-empresa')

      setFormatoItem1('h2-header-empresa')
      setFormatoItem2('h2-header-empresa')
    }
  }

  const { lang } = useSelector(state => state)


  const [popup2, setpopup2] = useState(false)
  const [file2, setFile2] = useState(null)

  const fileHandler2 = (e) => {
    if (e.target.files[0].type === "application/pdf") {
      setFile2(e.target.files[0])
    } else {
      toast.error("El archivo no es un PDF",{
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
      return e.target.value = null
    }
  }

  const submitHandler = (e) => {
    e.preventDefault()
    if (!file2) {
      return toast.error("Seleccione un archivo",{
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
    }
    toast.success("Se ha cargado la hoja de vida!",{
      position: "top-center",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    })
    console.log('archivo', file2)
    return setpopup2(false)
  }

  if (!localStorage.getItem('langlocal')) {
    localStorage.setItem('langlocal', 'es')
}
counterpart.registerTranslations('en', en)
counterpart.registerTranslations('es', es)
counterpart.setLocale(localStorage.getItem('langlocal'));
  return (<div className='contenedor'>
    {/* <ToastContainer /> */}
    {popup2 && <div className='popupcalifacion'>
      <div style={{
        zIndex: '100',
        height: '100vh',
        width: '100vw',
        position: 'absolute',
        display: "flex",
        justifyContent: 'center',
        alignItems: "center",
        backgroundColor: 'rgba(125, 125, 125, 0.72)',
        mixBlendMode: 'normal',
        backdropFilter: 'blur(31px)'
      }} onClick={() => setpopup2(false)}>
      </div>
      <div
        style={{
          backgroundColor: '#fff',
          borderRadius: '10px',
          padding: '20px',
          display: 'flex',
          flexDirection: 'column', // justifyContent: 'center',
          // alignItems: 'center',
          position: 'fixed',
          zIndex: '200',
          top: '25%',
          left: '25%',
          width: '50%',
          height: '50%',
        }}>
        <div>

  <button onClick={() => setpopup2(false)}>x</button>
        </div >
        <div style={{
          width: '100%',
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: "center",
          justifyContent: 'center'
        }}>
          <h2><Translate className="mediumfont" content="Cargatuhojadevidav1" component="span" /></h2>
          <input type="file" style={{ marginTop: '5%' }} onChange={(e) => fileHandler2(e)} />
        </div>
        <div style={{ display: "flex", justifyContent: 'end' }}>
          <button
            style={{
              backgroundColor: "#202256",
              borderRadius: "5px",
              fontSize: "14px",
              color: "white",
              fontWeight: "400",
              width: "111px",
              height: "28px",
              marginRight: "80px",
            }}
            onClick={(e) => submitHandler(e)}><Translate className="mediumfont" content="Subirv1" component="span" />
          </button>
        </div>
      </div >
    </div >
   }

{/* {
  popup && !userr.calificaion_aptitudes && <div className='popupcalifacion'>
    <div className='fondopopupcalificacion' onClick={() => setpopup(false)}>
      <div className='popupcalifacionsub '>

        <img style={{ position: 'absolute' }} src={buttonclose} alt="#" onClick={() => setpopup(false)}></img>
        <div className='popupcalifacionsubpop'>

          <img src={imagenpopup} alt="dd"></img>
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
            <p style={{ textAlign: 'center' }}><Translate className="mediumfont" content="RecuerdacompletartuperfilconelTestdePersonalidadv1" component="span" /></p>
            <button className='buttonirahora' onClick={handleOpen}><Translate className="mediumfont" content="irahorav1" component="span" /></button>
            <Modal
              open={open}
              onClose={handleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={style}>
                <PopUpTest handleOpen2={handleOpen2}/>
              </Box>
            </Modal>
            <Modal
              open={open2}
              onClose={handleClose2}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={styles2}>
                <PopUpResultado resultTest={resultTest}/>
                <div style={{ display: 'flex', justifyContent: 'space-around', marginTop: '40px' }}>
                  <button style={{ backgroundColor: 'transparent', height: '28px', width: '141px', borderRadius: '5px', borderStyle: 'solid', borderWidth: '1px', fontSize: '14px', fontWeight: '400', color: '#202256' }} onClick=''><Translate className="mediumfont" content="MásTestv1" component="span" /></button>


                  <button style={{ backgroundColor: 'transparent', height: '28px', width: '141px', borderRadius: '5px', borderStyle: 'solid', borderWidth: '1px', fontSize: '14px', fontWeight: '400', color: '#202256' }} onClick={mandarpersonalizad}><Translate className="mediumfont" content="Guardarv1" component="span" /></button>
                </div>
              </Box>
            </Modal>
            <hr />
          </div>
        </div>
      </div>
    </div>
  </div>
} */}
    <Link to={'/consultor/home'}>
      <img src={img} alt="" style={{ marginTop: '2rem' }} />
    </Link>
    <div style={{
      bottom: '0',
      width: '100%',
      position: 'absolute',
      height: '70vh',
      display: 'flex',
      justifyContent: 'space-between',
      flexDirection: 'column'
    }}>
      <div style={{ width: '100%', paddingLeft: '2vw' }}>
        <Link to={"/consultor/home"}
          className={window.location.href.includes("/consultor/home") ? "link-header-color" : "link-header"}>
          <h2 className={formatoTexto1} onClick={() => handleOnClick3()}><img src={homesvg} className={formatoImagen1}
            alt="#"
            style={{ marginRight: '1rem' }}></img> <Translate className="mediumfont" content="Iniciov1" component="span" />
          </h2>
        </Link>

        <Link to={"/consultor/projects"} style={{ display: 'flex', flexDirection: "column" }}
          className={window.location.href.includes("/consultor/projects") ? "link-header-color" : "link-header"}>
          <div style={{ display: 'flex' }}>
            <h2 className={formatoTexto2}
              onClick={() => handleOnClick4()} onMouseLeave={() => handleOnMouseLeave()}><img src={documentsvg}
                alt="#"
                className={formatoImagen2}
                style={{ marginRight: '1rem' }}></img> <Translate className="mediumfont" content="Oportunidadesv1" component="span" /><KeyboardArrowDownIcon
                  className='h2-header-empresa'></KeyboardArrowDownIcon></h2>
          </div>
          <div style={{ marginTop: '15px' }}>

            {window.location.href.includes("/consultor/projects") &&
              <div style={{ display: 'flex', flexDirection: "row" }}>
                <div style={{
                  marginTop: '20px',
                  height: '55px',
                  backgroundColor: '#C5C5C5',
                  width: '3px',
                  display: "flex",
                  justifyContent: 'center',
                  marginBottom: '-7px',
                  marginRight: '20px',
                  marginLeft: '40px'
                }}>
                  <Circle className={formatoCirculo1} style={{ fontSize: '15px', zIndex: '1', marginTop: '-5px' }} />
                  {/*<h2 style={{color: '#C5C5C5', marginTop:'-5px', marginBottom:'-5px'}}>|</h2>*/}
                </div>
                <h2 style={{ marginBottom: '30px', marginTop: '10px' }} className={formatoItem1}
                  onClick={() => handleOnClick1()}><Translate className="mediumfont" content="Enesperav1" component="span" /></h2>
              </div>}
            {window.location.href.includes("/consultor/projects") &&
              <div style={{ display: 'flex', flexDirection: "row" }}>
                <div style={{
                  height: '0',
                  width: '3px',
                  backgroundColor: '#C5C5C5',
                  display: "flex",
                  justifyContent: 'center',
                  marginRight: '20px',
                  marginLeft: '40px'
                }}>
                  <Circle className={formatoCirculo2} style={{ fontSize: '15px' }} />
                </div>
                <h2 style={{ marginTop: '-2px' }} className={formatoItem2}
                  onClick={() => handleOnClick2()}><Translate className="mediumfont" content="Finalizadosv1" component="span" /></h2>
              </div>}
          </div>
        </Link>
        {window.location.href.split("/").includes("consultor") &&
          <Link to={"/consultor/formacion"} style={{ textDecoration: 'none', display: 'flex', cursor: 'pointer' }} onClick={() => handleOnClick5()}>
            <h2 className={formatoTexto3}>
              <LaptopIcon className={formatoImagen3} style={{ marginRight: '1rem' }} />
              <Translate className="mediumfont" content="Formaciónv1" component="span" />
            </h2>
          </Link>}
      </div>
      <div>
        {/*<button onClick={() => setpopup2(true)}*/}
        {/*  style={{*/}
        {/*    textDecoration: 'none', display: 'flex', marginLeft: '2vw', marginBottom: '1vw'*/}
        {/*  }}>*/}
        {/*  <Translate className="mediumfont" content="Cargatuhojadevidav1" component="span" />*/}
        {/*</button>*/}
        <Link to="/consultor/config"
          style={{
            textDecoration: 'none', display: 'flex', cursor: 'pointer', paddingLeft: '2vw', paddingBottom: '6vw'
          }}>
          <img src={configuracion} alt='configuracion' style={{ marginRight: '1rem' }} />
          <h2 className='h2-header-empresa'>
            <span className="mediumfont">{lang === 'es' ? 'Configuración' : 'Settings'}</span>
          </h2>
        </Link>

        <Link to="#" onClick={handleLogout}
          style={{
            textDecoration: 'none', display: 'flex', cursor: 'pointer', paddingLeft: '2vw', paddingBottom: '100px'
          }}>
          <ExitToAppIcon className='h2-header-empresa' style={{ marginRight: '1rem' }}></ExitToAppIcon>
          <h2 className='h2-header-empresa'><Translate className="mediumfont" content="Cerrarsesiónv1" component="span" /></h2>
        </Link>
      </div>
    </div>
  </div >)
}
const mapDispatchToProps = {
  logoutUser, changeview,
};
export default connect(null, mapDispatchToProps)(Headerprofesional);