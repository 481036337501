let list = {
    Agronomia_Veterinaria: [
      
      "Desarrollar labores de campo",
      "Relacionarse con el sector agrícola",
      "Sistemas de producción agrícola y pecuaria",
      "Diseñar y dirigir procesos",
      "Conocimiento en plaguicidas de uso ambiental",
      "Manejo  de recursos naturales y agropecuarios",
      "Evaluación de suelos",
      "Gestión de proyectos",
      "Preparación del suelo",
      "Planificación de cultivos",
      "Conocimiento ecológico y biológico",
      "Manejo de instrumental científico y maquinaria",
      "Control del funcionamiento",
      "Pasión y aprecio hacia todos los animales",
      "Resolver problemas relacionados con la salud y la producción animal",
      "Utilización y manejo de equipo veterinario",
      "Capacidad de comunicación",
      "Gestión del tiempo",
      "Gestión financiera",
      "Pensamiento crítico",
      "Atención al detalle",
      "Planificación",
      "flexibilidad y adaptabilidad",
      "Toma de decisiones",
      "Liderazgo y trabajo en equipo",
      "Resolución de problemas",
      "Comunicación asertiva",
      "Manejo de la frustración",
      "Responsabilidad",
      "Trabajo en equipo"
    ],
    Agronomia_Veterinaria_EN: [
      
      "Developing field work",
      "Relationship with the agricultural sector",
      "Agricultural and veterinary systems",
      "Design and direct production processes",
      "Knowledge in ambiental pesticides",
      "Natural and agropecuarial resources management",
      "Soil evaluation",
      "Project management",
      "Soil preparation",
      "Cultivation planning",
      "Ecological and biologic knowledge",
      "Scientific and machine tools management",
      "Control of operation",
      "Passion and appreciation for all animals",
      "Solving problems related to health and animal production",
      "Use and management of veterinary equipment",
      "Communication ability",
      "Time management",
      "Financial management",
      "Critical thinking",
      "Detail attention",
      "Planning",
      "Flexibility and adaptability",
      "Decision making",
      "Leadership and team work",
      "Resolution of problems",
      "Assertive communication",
      "Handling of frustration",
      "Responsibility",
      "Team work"
    ],
    Bellas_Artes: [
      
      "Creatividad",
      "Imaginación",
      "Perfeccionismo",
      "Pragmatismo",
      "Concentración",
      "Composición",
      "Conocimientos informáticos",
      "Comprensión de la perspectiva",
      "Dibujo realista",
      "Dibujo constructivo",
      "Capacidad de comunicación",
      "Gestión del tiempo",
      "Gestión financiera",
      "Pensamiento crítico",
      "Atención al detalle",
      "Planificación",
      "Flexibilidad y adaptabilidad",
      "Toma de decisiones",
      "Liderazgo y trabajo en equipo",
      "Resolución de problemas",
      "Comunicación asertiva",
      "Manejo de la frustración",
      "Responsabilidad",
      "Trabajo en equipo"
    ],
    Bellas_Artes_EN: [
      
      "Creativity",
      "Imagination",
      "Perfectionism",
      "Pragmatism",
      "Concentration",
      "Composition",
      "Computer knowledge",
      "Comprehension of the perspective",
      "Realist drawing",
      "Constructive drawing",
      "Communication ability",
      "Time management",
      "Financial management",
      "Critical thinking",
      "Detail attention",
      "Planning",
      "Flexibility and adaptability",
      "Decision making",
      "Leadership and team work",
      "Resolution of problems",
      "Assertive communication",
      "Handling of frustration",
      "Responsibility",
      "Team work"
    ],
    Educacion: [
      
      "Escucha activa",
      "Desarrollo de proyectos ",
      "Uso de material tecnológico ",
      "Manejo de herramientas digitales",
      "Enseñanza didáctica",
      "Comunicación asertiva",
      "Capacidad de comunicación",
      "Gestión del tiempo",
      "Gestión financiera",
      "Pensamiento crítico",
      "Atención al detalle",
      "Planificación",
      "Flexibilidad y adaptabilidad",
      "Toma de decisiones",
      "Liderazgo y trabajo en equipo",
      "Resolución de problemas",
      "Comunicación asertiva",
      "Manejo de la frustración",
      "Responsabilidad",
      "Trabajo en equipo"
    ],
    Educacion_EN: [
      
      "Active listening",
      "Project development",
      "Use of technological materials",
      "Digital tools management",
      "Educational teaching",
      "Assertive communication",
      "Communication ability",
      "Time management",
      "Financial management",
      "Critical thinking",
      "Detail attention",
      "Planning",
      "Flexibility and adaptability",
      "Decision making",
      "Leadership and team work",
      "Resolution of problems",
      "Assertive communication",
      "Handling of frustration",
      "Responsibility",
      "Team work"
    ],
    Salud: [
      
      "Capacidad para trabajar en equipo",
      "Conocimiento de terminología y procedimientos médicos",
      "Apoyo al paciente",
      "Vocación de servicio",
      "Inquietud por aprender",
      "Capacidad de comunicación",
      "Gestión del tiempo",
      "Gestión financiera",
      "Pensamiento crítico",
      "Atención al detalle",
      "Planificación",
      "Flexibilidad y adaptabilidad",
      "Toma de decisiones",
      "Liderazgo y trabajo en equipo",
      "Resolución de problemas",
      "Comunicación asertiva",
      "Manejo de la frustración",
      "Responsabilidad",
      "Trabajo en equipo"
    ],
    Salud_EN: [
      
      "Teamwork",
      "Knowledge of terminology and medical procedures",
      "Support to the patient",
      "Vocation of service",
      "Inquietud for learning",
      "Communication ability",
      "Time management",
      "Financial management",
      "Critical thinking",
      "Detail attention",
      "Planning",
      "Flexibility and adaptability",
      "Decision making",
      "Leadership and team work",
      "Resolution of problems",
      "Assertive communication",
      "Handling of frustration",
      "Responsibility"
    ],
    CS_Humanas: [
      
      "Orientación a resultados ",
      "Habilidad optima comunicación oral y escrita",
      "Razonamiento de datos",
      "Atención al detalle",
      "Capacidad de comunicación",
      "Gestión del tiempo",
      "Gestión financiera",
      "Pensamiento crítico",
      "Atención al detalle",
      "Planificación",
      "Flexibilidad y adaptabilidad",
      "Toma de decisiones",
      "Liderazgo y trabajo en equipo",
      "Resolución de problemas",
      "Comunicación asertiva",
      "Manejo de la frustración",
      "Responsabilidad",
      "Trabajo en equipo"
    ],
    CS_Humanas_EN: [
      
      "Orientation to results",
      "Optimal communication oral and written",
      "Reasoning of data",
      "Detail attention",
      "Communication ability",
      "Time management",
      "Financial management",
      "Critical thinking",
      "Detail attention",
      "Planning",
      "Flexibility and adaptability",
      "Decision making",
      "Leadership and team work",
      "Resolution of problems",
      "Assertive communication",
      "Handling of frustration",
      "Responsibility",
      "Team work"
    ],
    Economia: [
      
      "Pago y elaboración de impuestos",
      "Gestión de ingresos",
      "Análisis y creación de balance",
      "Organización",
      "Administración de cuentas",
      "Análisis financiero",
      "Gestión del pago",
      "Declaración",
      "Habilidades escritas",
      "Negociación",
      "Solución de problemas complejos",
      "Capacidad de comunicación",
      "Gestión del tiempo",
      "Gestión financiera",
      "Pensamiento crítico",
      "Atención al detalle",
      "Planificación",
      "Flexibilidad y adaptabilidad",
      "Toma de decisiones",
      "Liderazgo y trabajo en equipo",
      "Resolución de problemas",
      "Comunicación asertiva",
      "Manejo de la frustración",
      "Responsabilidad",
      "Trabajo en equipo"
    ],
    Economia_EN: [
      
      "Tax payment and elaboration of taxes",
      "Income management",
      "Analysis and creation of balance",
      "Organization",
      "Accounting",
      "Financial analysis",
      "Payment management",
      "Declaration",
      "Written skills",
      "Negotiation",
      "Complex problem solving",
      "Communication ability",
      "Time management",
      "Financial management",
      "Critical thinking",
      "Detail attention",
      "Planning",
      "Flexibility and adaptability",
      "Decision making",
      "Leadership and team work",
      "Resolution of problems",
      "Assertive communication",
      "Handling of frustration",
      "Responsibility",
      "Team work"
    ],
    Ingenieria: [
      
      "Pensamiento lógico",
      "Visión espacial",
      "Habilidad estética",
      "Amplio criterio",
      "Autoaprendizaje",
      "Gestión de riesgos, cambios y crisis",
      "Atención al detalle",
      "Pensamiento estratégico",
      "Planificación urbana estratégica",
      "Planificación maestra",
      "Capacidad de comunicación",
      "Gestión del tiempo",
      "Gestión financiera",
      "Pensamiento crítico",
      "Atención al detalle",
      "Planificación",
      "Flexibilidad y adaptabilidad",
      "Toma de decisiones",
      "Liderazgo y trabajo en equipo",
      "Resolución de problemas",
      "Comunicación asertiva",
      "Manejo de la frustración",
      "Responsabilidad",
      "Trabajo en equipo"
    ],
    Ingenieria_EN: [
      
      "Logic thinking",
      "Spatial vision",
      "Asthetics",
      "Wide criteria",
      "Self-learning",
      "Risk management, changes and crises",
      "Detail attention",
      "Strategic thinking",
      "Urban strategic planning",
      "Master planning",
      "Communication ability",
      "Time management",
      "Financial management",
      "Critical thinking",
      "Detail attention",
      "Planning",
      "Flexibility and adaptability",
      "Decision making",
      "Leadership and team work",
      "Resolution of problems",
      "Assertive communication",
      "Handling of frustration",
      "Responsibility",
      "Team work"
    ],
    Matematicas: [
      
      "Resolver problemas",
      "Argumentar y comunicar",
      "Modelar",
      "Habilidades digitales",
      "Medición",
      "Datos y probabilidades",
      "Trabajo ordenado y metódico",
      "Analizar",
      "Clasificar",
      "Usar instrumentos",
      "Investigar",
      "Observar",
      "Capacidad de comunicación",
      "Gestión del tiempo",
      "Gestión financiera",
      "Pensamiento crítico",
      "Atención al detalle",
      "Planificación",
      "Flexibilidad y adaptabilidad",
      "Toma de decisiones",
      "Liderazgo y trabajo en equipo",
      "Resolución de problemas",
      "Comunicación asertiva",
      "Manejo de la frustración",
      "Responsabilidad",
      "Trabajo en equipo"
    ],
    Matematicas_EN: [
      
      "Solving problems",
      "Argumentation and communication",
      "Modeling",
      "Digital skills",
      "Measurement",
      "Data and probabilities",
      "Ordered work and methodological",
      "Analyze",
      "Classify",
      "Use instruments",
      "Investigate",
      "Observe",
      "Communication ability",
      "Time management",
      "Financial management",
      "Critical thinking",
      "Detail attention",
      "Planning",
      "Flexibility and adaptability",
      "Decision making",
      "Leadership and team work",
      "Resolution of problems",
      "Assertive communication",
      "Handling of frustration",
      "Responsibility",
      "Team work"
    ],
    Otro: [
      
      "Capacidad de comunicación",
      "Gestión del tiempo",
      "Gestión financiera",
      "Pensamiento crítico",
      "Atención al detalle",
      "Planificación",
      "Flexibilidad y adaptabilidad",
      "Toma de decisiones",
      "Liderazgo y trabajo en equipo",
      "Resolución de problemas",
      "Comunicación asertiva",
      "Manejo de la frustración",
      "Responsabilidad",
      "Trabajo en equipo"
    ],
    Otro_EN: [
      
      "Communication ability",
      "Time management",
      "Financial management",
      "Critical thinking",
      "Detail attention",
      "Planning",
      "Flexibility and adaptability",
      "Decision making",
      "Leadership and team work",
      "Resolution of problems",
      "Assertive communication",
      "Handling of frustration",
      "Responsibility",
      "Team work"
    ]
  }

  export default list