/* eslint-disable  */
import React, {
  useEffect,
  useState
} from 'react';
import './Oportunidadescompany.css'
import { connect, useSelector } from "react-redux";
import { ConsultorProjects, resetReq, projecTryOut } from "../../actions/index";
import en from "../../lang/en-US";
import es from "../../lang/es-COL"
import counterpart from "counterpart";
import SearchIcon from "@mui/icons-material/Search";
import HeadertopV2 from "../headertop/HeadertopV2";
import { useHistory } from "react-router-dom";
import Header from "../header/Headerprofesional";
import proyectoimagen from "../detalleoportunidad/proyectos.webp"
import axios from 'axios';
import check from "../../assets/icons/check.svg"
import { Link, useParams } from "react-router-dom";
import CompanyNewCreateProject2 from '../../pages/Companies/CompanyNewCreateProject2';
import Skeleton from '@mui/material/Skeleton';
import Translate from "react-translate-component";
import IconButton from "@mui/material/IconButton";
import trashcan from "../../assets/logos/trashcan.png";
import Loader2 from '../Loader/Loader2';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'
import { AvatarGroup } from '@mui/material';
import { Avatar } from '../home/AvatarUser';
import Sidebar from "../../components/Sidebar/Sidebar";
import NotFound from "../../assets/icons/notFound.svg"
import Navbar from "../../components/Navbar/Navbar";
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import Lapiz from "../../assets/icons/lapiz.svg"
const Oportunidadescompany = (props) => {
  const { id } = useParams();
  const userR = useSelector(state => state.user)
  const { lang } = useSelector(state => state)
  const history = useHistory()
  const [proyectoactivate, setproyectoactivate] = useState(false)
const [proyectosdeluser2 , setproyectosdeluser2] = useState([])
  const [empty, setempty] = useState(true)
  const redirect = () => {
    history.push("/")
  }
  useEffect(() => {
    if (id) {
      history.push(`/company/project`)
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    let error = setTimeout(() => {
      if (!proyectosdeluser.length) setempty(false)
    }, 1000)
    return () => clearTimeout(error)
  })

  const [page, setPage] = useState({
    currentPage: 0,
    totalPage: 0,
  });

  const handlePageClick = (evento, value) => {
    setproyectosdeluser([]);
    axios.get("https://findally-backend.herokuapp.com/api/user/projects/Company/" + props.user.id + `?skip=${value-1}`).then((el) => {
      setproyectosdeluser(el.data.projects);
      setPage({
        ...page,
        currentPage: value,
        totalPage: el.data.totalPag,
      });
    });
  };

  const [tab, setTab] = useState(4);
  const [filtro, setfiltro] = useState("")
  // eslint-disable-next-line
  const [filtro2, setfiltro2] = useState("")
  const [filtro3, setfiltro3] = useState("")

  counterpart.registerTranslations('en', en)
  counterpart.registerTranslations('es', es)
  counterpart.setLocale(localStorage.getItem('langlocal'));

  useEffect(() => {
    const getUser = () => {
      if (!props.done) {
        props.ConsultorProjects(props.user.projects);
      }
    };
    // eslint-disable-next-line
    getUser();
    // eslint-disable-next-line
  }, []);

  if (props.view === 'company') {
    window.location.reload()
  }
  const [proyectosdeluser, setproyectosdeluser] = useState([])
  useEffect(() => {
    axios.get("https://findally-backend.herokuapp.com/api/user/projects/Company/" + props.user.id).then(e =>{
      console.log(e)
      setPage({
        ...page,
        currentPage: page.currentPage + 1,
        totalPage: e.data.totalPag,
      });
      setproyectosdeluser(e.data.projects)
      setproyectosdeluser2(e.data.projects)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.user.id])

  const [filtroopor, setfiltroopor] = useState("all")

  const onChangeHandler = (e) => {
    setfiltro(e.target.value)
    setfiltro2(e.target.value)
    setfiltro3(e.target.value)
  }

  const deleteProject = async (id) => {
    await axios.delete("https://findally-backend.herokuapp.com/api/Project/delete/" + id)
      .then(d =>
        toast.success('Project delete successfully', {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        })
      )
      .then(() => {
        return window.location.reload()
      })
  }

  return (
    <div>
      {/* <ToastContainer /> */}
      {proyectoactivate &&
        <CompanyNewCreateProject2 setproyectoactivate={setproyectoactivate} />
      }
      {userR && userR.roles === "company" ? !proyectosdeluser.length ? (
        <div>
          <Sidebar location={'oportunidades'} />
          <div className="BodyWeb"
            style={{ padding: "0rem 2rem", position: "absolute", right: "0" }}>
            {/*<HeadertopV2 sitio="Oportunidades"></HeadertopV2>*/}
            <Navbar locate={'oportunidades'} />
            <h1 className="font-top">{<Translate content="Oportunidades" component="span" />}</h1>
            <div>
              <Loader2 altura={50} render={empty} />
            </div>
          </div>
        </div>) : (
        <div>
          <Sidebar location={'oportunidades'} />
          <div
            className="BodyWeb"
            style={{ padding: "0rem 2rem", position: "absolute", right: "0" }}>
            <Navbar locate={'oportunidades'} />
            <h1 className="font-top">{<Translate content="Oportunidades" component="span" />}</h1>
            {(tab === 4 || tab === 0 || tab === 1) && <div>
              <div className="section-one">
                <div style={{ flex: 1 }}>
                  <h5 className='fuenteestadoproyecto'>{lang === 'es' ? 'Publicadas' : 'Posted'}</h5>
                </div>
                <div style={{ display: "flex", alignItems: 'center' }}>
                  <SearchIcon style={{
                    fontSize: '30px',
                    color: '#B3B6B6',
                    position: 'absolute',
                    marginBottom: '10px',
                    marginLeft: '2px'
                  }} />
                  <input className='input-card-v2-op'
                    type={'search'}
                    placeholder={lang === 'es' ? 'Buscar' : 'Search'}
                    onChange={(e) => onChangeHandler(e)} />
                </div>
              </div>
              <div className="sectioncards-cards">
                <div className="sectioncards-card2-empresa"> {/* section 1 */}
                  {proyectosdeluser && proyectosdeluser && proyectosdeluser.length > 0 && proyectosdeluser.filter(e => e.status !== "Finalizado" && e.isVisible).filter(g => g.NombreOportunidad?.toLowerCase().includes(filtro.toLowerCase()) || g.nombreProyecto?.toLowerCase().includes(filtro.toLowerCase()) || g.nombre?.toLowerCase().includes(filtro.toLowerCase())).map((e, index) =>
                    <>
                      <div
                        // to={`/company/CompanyDashboard/${e._id}`} ACa nico
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'space-between',
                          width: '100%',
                          height: '144px',
                          backgroundColor: '#FFFFFF',
                          marginTop: '29px',
                          boxShadow: '0px 7px 32px rgba(0, 0, 0, 0.1)',
                          borderRadius: '8px'
                        }}
                      >
                        {/*imagen y texto y vermas*/}
                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: '100%' }}>
                          <div>
                            <img src={e.imageUrl ? e.imageUrl : proyectoimagen} alt={e.nombre} className='card-v2-resposive-image' />
                          </div>
                          <div style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            width: '100%'
                          }}>
                            <Link to={`/company/CompanyDashboard/${e._id}`} style={{ marginLeft: '22px', width: '100%' }}>
                              <h2 style={{
                                fontSize: '16px',
                                fontWeight: '700',
                                color: '#000000',
                                fontFamily: 'Poppins'
                              }}>
                                {e.nombre && e.nombre !== "Unnamed Oportunity" ? e.nombre : e.nombreProyecto && e.nombreProyecto !== "Unnamed Oportunity" ? e.nombreProyecto : e.NombreOportunidad && e.NombreOportunidad !== "Unnamed Oportunity" ? e.NombreOportunidad : ""}
                              </h2>
                              <p className='card-v2-resposive-desc'
                                style={{
                                  fontSize: '14px',
                                  fontWeight: '300',
                                  color: '#5D5D5D',
                                  fontFamily: 'Poppins'
                                }}>
                                {
                                  e?.habilidades_requeridas?.length ?
                                    lang === 'es' ? `Profesionales con conocimiento en: ${e?.habilidadesRequeridas?.slice(0,3)?.join(', ')}` : `Professionals with knowledge in: ${e?.habilidadesRequeridas?.slice(0,3)?.join(', ')}`
                                    : null
                                }
                                {/*{<Translate content="Profesionalesconconocimientoen" component="span" />}:*/}
                                {/*/!*  Economía, Administración, Contaduría y afines *!/*/}
                                {/*{' ' + (e.habilidades_requeridas || e.habilidadesRequeridas.join(', '))}*/}
                              </p>
                              <Link to={`/company/CompanyDashboard/${e._id}`}
                                style={{
                                  color: '#C5C5C5',
                                  backgroundColor: 'transparent',
                                  marginRight: '35px'
                                }}
                              >{<Translate content="Vermas" component="span" />}</Link>
                            </Link>
                            <div className='card-v2-resposive-applicant'>
                              <AvatarGroup max={4}>
                                {e?.aplicantes && e?.aplicantes?.length > 0
                                  && e.aplicantes.map(n => {
                                    return (
                                      <Avatar style={{ fontSize: '25px', marginLeft: '-18px' }} name={n.username} />

                                    )
                                  })}
                              </AvatarGroup>
                            </div>
                            <div onClick={() => deleteProject(e._id)}>
                              <IconButton aria-label="delete" sx={{ color: '#202256' }}
                              >
                                <img style={{ height: '25px' }} src={trashcan} alt={trashcan} />
                              </IconButton>
                            </div>
                            <Link to={`/company/create/${e._id}`}>
                              <img src={Lapiz} alt={'edit'} className="boton-editar-card-oportunidad" />
                            </Link>
                          </div>

                        </div>
                        {/*botón y aplicantes*/}
                        <div style={{
                          display: 'flex',
                          alignItems: "center",
                          flexDirection: 'column',
                          height: '100%',
                          marginRight: '22px'
                        }}>
                          <div style={{ display: 'flex', alignItems: "center", height: '100%' }}>
                          </div>
                        </div>
                      </div>
                    </>
                  )}

                  {
                    proyectosdeluser.length < 1 &&
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        width: '100%',
                        height: '144px',
                        backgroundColor: '#FFFFFF',
                        marginTop: '29px',
                        boxShadow: '0px 7px 32px rgba(0, 0, 0, 0.1)',
                        borderRadius: '8px'
                      }}
                    >
                      {/*imagen y texto y vermas*/}
                      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        <div>
                          <Skeleton variant="rectangular"
                            style={{
                              width: '214px',
                              height: '144px',
                              borderRadius: '8px 0 0 8px',
                              objectFit: 'cover'
                            }} />
                        </div>
                        <div style={{ marginLeft: '22px' }}>
                          <h2 style={{
                            fontSize: '16px',
                            fontWeight: '700',
                            color: '#000000',
                            fontFamily: 'Poppins'
                          }}>
                            <Skeleton variant="text" sx={{ fontSize: '1rem', width: '200px' }} />
                          </h2>
                          <p style={{
                            fontSize: '14px',
                            fontWeight: '300',
                            color: '#5D5D5D',
                            fontFamily: 'Poppins'
                          }}><Skeleton variant="text" sx={{ fontSize: '1rem', width: '200px' }} />
                            {/*  Economía, Administración, Contaduría y afines */}
                            {' '}</p>
                          <div
                            style={{
                              color: '#C5C5C5',
                              backgroundColor: 'transparent',
                              marginRight: '35px'
                            }}
                          ><Skeleton variant="text" sx={{ fontSize: '1rem', width: '86px' }} /></div>
                        </div>
                      </div>
                      {/*botón y aplicantes*/}
                      <div style={{
                        display: 'flex',
                        alignItems: "center",
                        flexDirection: 'column',
                        height: '100%',
                        marginRight: '22px'
                      }}>
                        <div style={{ display: 'flex', alignItems: "center", height: '100%' }}>
                        </div>
                        <div style={{ display: 'flex', alignItems: "center", height: '100%', marginRight: '70px' }}>

                        </div>
                      </div>
                    </div>
                  }
                </div>
                {/* aca termina la section 1 */}
              </div>
              <div  style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            width: "100%",
                            height: "10%",
                          }}
                        >
                          <div>
                          <Stack spacing={2}>
                            <Pagination count={page.totalPage}  page={page.currentPage} onChange={handlePageClick}/>
                           </Stack>
                          </div>
                      </div>
            </div>}
            {(tab === 4 || tab === 2) && <div>

              <div className="section-one">
                <div className="section-one">
                  <div style={{ flex: 1 }}>
                    <h5 className='fuenteestadoproyecto'>{<Translate content="Finalizadas" component="span" />}</h5>
                  </div>

                </div>
                {tab === 4 ? null : <div style={{ display: "flex", alignItems: 'center' }}>
                  <SearchIcon style={{
                    fontSize: '30px',
                    color: '#B3B6B6',
                    position: 'absolute',
                    marginBottom: '10px',
                    marginLeft: '2px'
                  }} />
                  <input className='input-card-v2-op' type={'search'} placeholder={'Buscar...'} onChange={(e) => setfiltro3(e.target.value)} />
                </div>}

              </div>
              <div className="sectioncards-cards">
                <div className="sectioncards-card2-empresa">
                  {proyectosdeluser2 && proyectosdeluser2.filter(e => e.status === "Finalizado" && e.isVisible).length === 0 && < div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}>
                    <img src={NotFound} alt="no encontrado" />
                    <span style={{ color: '#7D7D7D' }}>{lang === 'es' ? 'Aún no tienes oportunidades finalizadas' : `You don't have finished opportunities yet`}</span>
                  </div>}
                  {proyectosdeluser2 && proyectosdeluser2?.length > 0 && proyectosdeluser2.filter(e => e.status === "Finalizado" && e.isVisible).filter(g => g.NombreOportunidad?.toLowerCase().includes(filtro3.toLowerCase()) || g.nombreProyecto?.toLowerCase().includes(filtro3.toLowerCase()) || g.nombre?.toLowerCase().includes(filtro3.toLowerCase())).map((e, index) =>
                    <Link style={{ width: '100%' }}
                      to={`/company/CompanyDashboard/${e._id}`}
                    >
                      <div key={index} className="card-v2-op">
                        <div className='card-v2-op-div'>
                          <div>
                            <img src={e.imageUrl ? e.imageUrl : proyectoimagen} alt={'proyecto'}
                              className="card-v2-op-img" />
                          </div>
                          <div style={{ marginLeft: '28px', marginTop: '13px' }}>
                            <h2 className={'card-v2-title'}>
                              {e.nombre && e.nombre !== "Unnamed Oportunity" ? e.nombre : e.nombreProyecto && e.nombreProyecto !== "Unnamed Oportunity" ? e.nombreProyecto : e.NombreOportunidad && e.NombreOportunidad !== "Unnamed Oportunity" ? e.NombreOportunidad : ""}
                            </h2>
                            <div style={{ display: 'flex', flexDirection: 'row', marginTop: '-12px' }}>
                              <img src={check} alt="check" />
                              <p className={'card-v2-subtitle-green'}>{e.status === "Finalizado" && lang === "es" ? "Finalizado" : "Finished"}</p>
                            </div>
                          </div>
                        </div>
                        <div>

                          <Link className={'card-v2-detalle'}
                            to={`/company/CompanyDashboard/${e._id}`}
                          >{lang === 'es' ? 'Ver detalles' : 'See details'}
                          </Link>

                        </div>
                        <div>
                          <p onClick={async (i) => {
                            await axios.put(`https://findally-backend.herokuapp.com/api/Project/${e._id}?repost=true`)
                          }} className={'card-v2-subtitle-green'}>{lang === "es" ? "Re-postear" : "Re-post"}</p>
                        </div>
                      </div>
                    </Link>)}
                  {proyectosdeluser.length < 1 &&
                    <div className="card-v2-op">
                      <div className='card-v2-op-div'>
                        <div>
                          <Skeleton variant="rectangular"
                            className="card-v2-op-img" />
                        </div>
                        <div style={{ marginLeft: '28px', marginTop: '13px' }}>
                          <h2 className={'card-v2-title'}>
                            <Skeleton variant="text" sx={{ fontSize: '1rem', width: '200px' }} />
                          </h2>
                          <div style={{ display: 'flex', flexDirection: 'row', marginTop: '-12px' }}>
                            <div style={{ display: 'flex', alignItems: 'center', width: '24px', height: '55px' }}>
                              <Skeleton variant="rectangular" width={24} height={24} style={{ borderRadius: '5px' }} />
                            </div>
                            <p className={'card-v2-subtitle-green'}><Skeleton variant="text"
                              sx={{ fontSize: '1rem', width: '68px' }} />
                            </p>
                          </div>
                        </div>
                      </div>
                      <div>
                        <div className={'card-v2-detalle'}
                        ><Skeleton variant="text" sx={{ fontSize: '1rem', width: '82px' }} />
                        </div>
                      </div>
                    </div>
                  }
                </div>
              </div>
            </div>}
          </div>
        </div>) : redirect()
      }
    </div>
  );
}

function mapStateToProps(state) {
  return {
    user: state.user,
    project: state.projects,
    userProjects: state.user.projects,
    done: state.doneUserProject,
    view: state.view
  };
}

const mapDispatchToProps = {
  ConsultorProjects,
  resetReq,
  projecTryOut,
};
export default connect(mapStateToProps, mapDispatchToProps)(Oportunidadescompany);
