import React, {useContext} from "react";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import HomeWorkOutlinedIcon from "@mui/icons-material/HomeWorkOutlined";
import HandshakeOutlinedIcon from "@mui/icons-material/HandshakeOutlined";
import AttachMoneyOutlinedIcon from "@mui/icons-material/AttachMoneyOutlined";
import {DashboardCardInfoContainer} from "./DashboardCardInfoStyles";
import {SchneiderContext} from "../../../../context/SchneiderContext";

function DashboardCardInfo({ data, lang }:any) {
  const {schneider} = useContext(SchneiderContext)
  return (
    <DashboardCardInfoContainer color={schneider.color.primary}>
      <div className='Subcontainer-Card-Oportunidad'>

        <h3 className='Title-Card-Oportunidad'>{lang === 'es' ? 'Habilidades:' : 'Skills:'}</h3>
        {data?.habilidadesTecnicas?.slice(0, 5).map((habilidad: any, index: any) => {
          return (
            <li className='List-Habilidades-Info' key={index}>
              {habilidad.charAt(0).toUpperCase() + habilidad.slice(1).toLowerCase()}
            </li>
          )
        })}


        <h3 className='Title-Card-Oportunidad' style={{marginTop:'1em'}}>
          <LocationOnOutlinedIcon />
          <span>{lang === 'es' ? 'Idiomas:' : 'Languages:'}</span>
        </h3>
        {data?.Lenguaje?.map((idioma: any, index: any) => {
          return (
            <li className='List-Habilidades-Info' key={index}>
              {idioma}
            </li>
          )
        })}
        <hr className='H-erre' />
        <h3 className='Title-Card-Oportunidad'>
          <HomeWorkOutlinedIcon />
          <span>{lang === 'es' ? 'Modalidad:' : 'Modality:'}</span>
        </h3>
        <p className='Text-Habilidades-Info'>
          {data?.modalidadDeTrabajo}
        </p>
        <hr className='H-erre' />
        <h3 className='Title-Card-Oportunidad'>
          <HandshakeOutlinedIcon />
          <span>{lang === 'es' ? 'Tipo de contrato:' : 'Type of contract:'}</span>
        </h3>
        <p className='Text-Habilidades-Info'>
          {data?.modalidadDeContratacion}
        </p>
        <hr className='H-erre' />
        <h3 className='Title-Card-Oportunidad'>
          <AttachMoneyOutlinedIcon />
          <span>{lang === 'es' ? 'Salario:' : 'Salary:'}</span>
        </h3>
        <p className='Text-Habilidades-Info'>
          <span style={{ color: '#F07130' }}>{data?.presupuesto}</span> /{lang === 'es' ? 'mensual' : 'monthly'}
        </p>

      </div>
    </DashboardCardInfoContainer>
  )
}

export default DashboardCardInfo
