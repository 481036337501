import disenio from '../../../../../assets/imagenes/diseño.png';
import desarrolloProductos from '../../../../../assets/imagenes/desarrolloProductos.png';
import finanzasCard from '../../../../../assets/imagenes/finanzasCard.png';

const infoHabilidades = [
  {
    image: disenio,
    title_es: 'Diseño gráfico / Web',
    title_en: 'Graphic Design / Web',
    cant: 23,
    link: '/schneider/formacion/design'
  },
  {
    image: desarrolloProductos,
    title_es: 'Desarrollo de productos',
    title_en: 'Product Development',
    cant: 14,
    link: '/schneider/formacion/product'
  },
  {
    image: finanzasCard,
    title_es: 'Finanzas',
    title_en: 'Finance',
    cant: 21,
    link: '/schneider/formacion/financial'
  }
];

export default infoHabilidades;
