import React, {useState} from 'react'
import Sidebar from "../../../components/Sidebar/Sidebar";
import Navbar from "../../../components/Navbar/Navbar";
import MasterclassCards from './MasterclassCards';
import {useSelector} from 'react-redux';
// import ReactPlayer from 'react-player';
// import welcomeVideo from './Bootcamp-Sostenibilidad.mp4';
import './MasterclassEmpresa.css';

const MasterclassEmpresa = () => {
  //eslint-disable-next-line
  const [course, setCourse] = useState('');
  const {lang} = useSelector(state => state)


  const titles = [
    {
      title: 'Capacitación – Nivelación de conceptos',
      image: 'https://images.unsplash.com/photo-1515378791036-0648a3ef77b2?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80',
      video: 'https://youtu.be/a0L9lVKCyQA'
    }, {
      title: 'Mitigación y adaptación al cambio climático - Herramientas de política pública',
      image: 'https://images.unsplash.com/photo-1517245386807-bb43f82c33c4?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80',
      video: 'https://youtu.be/Z5RsPkQdPl0'
    }, {
      title: 'Colombia en las negociaciones a nivel inernacional',
      image: 'https://images.pexels.com/photos/2566581/pexels-photo-2566581.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',
      video: 'https://youtu.be/xUo5AfGoJ2I'
    }, {
      title: 'Empleos verdes y transición justa en colombia',
      image: 'https://images.unsplash.com/photo-1487611459768-bd414656ea10?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80',
      video: 'https://youtu.be/7c69KnupqpM'
    }, {
      title: 'Impactos de la transición verde al mercado laboral colombiano',
      image: 'https://images.unsplash.com/photo-1487611459768-bd414656ea10?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80',
      video: 'https://youtu.be/2Ckc1R-hdiE'
    }, {
      title: 'Transformación hacia la sostenibilidad - Empresas sostenibles',
      image: 'https://images.unsplash.com/photo-1487611459768-bd414656ea10?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80',
      video: 'https://youtu.be/nYnc_LhB7u8'
    }, {
      title: 'Plataformas de consulta y enlaces empleos verdes',
      image: 'https://images.unsplash.com/photo-1487611459768-bd414656ea10?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80',
      video: 'https://youtu.be/iOqe3WSqYoM'
    }
  ]

  return (
    <div>
      <Sidebar location={'unilever masterclass'}/>
      <div
        className="BodyWeb"
        style={{
          padding: "0rem 2rem",
          position: "absolute",
          right: "0",
        }}>
        <Navbar/>
        <div className={'bootcamp-home-administracion-banner'}>
          <h1 style={{
            fontSize: '2em',
            fontWeight: 'bold',
            color: '#fff',
            fontFamily: 'Roboto'
          }}>{lang === 'es' ? 'Empresas' : 'Companies'}</h1>
        </div>
        <div className={'bootcampSoste-home-welcome-video'}>
          {/*<ReactPlayer url={welcomeVideo} controls={true}/>*/}
        </div>
        <div style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
        }}>
          <div className={'bootcamp-home-administracion-container'}>

            <div className={'bootcamp-home-administracion-videos-cards'}>
              {
                titles.map((e, index) => {
                  return (
                    <MasterclassCards
                      title={e.title}
                      setCourse={setCourse}
                      key={index}
                      course={e.video}
                      image={e.image}/>
                  )
                })
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default MasterclassEmpresa
