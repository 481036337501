import React, { useEffect, useState } from 'react'
import { saveOportunityForm } from '../../actions';
import { useDispatch } from 'react-redux';

function DocuCompany(props) {
    const { form, handleStep, handleBackStep, step, lang, handleForm, oportunityForm } = props;
    const [bottom, setbottom] = useState(true)
    const dispatch = useDispatch();
    useEffect(() => {
        if (form.sector_economico !== '' && form.tipo_documento !== '' && form.documento !== '') {
          setbottom(false)
        }
        return () => {
          setbottom(true)
        }
      }, [form])

    const handleChange = (e) => {
        handleForm({
            ...form,
            [e.target.name]: e.target.value
        })
        dispatch(saveOportunityForm({
            ...oportunityForm,
            [e.target.name]: e.target.value
        }, step))
    }

    return (
        <>
          <div className='contenedorCompanyInfo'>
            <div style={{ marginBottom: '20px' }}>
              <h3 className='contenedorCompanyInfo_title'>{lang === 'es' ? '¿Cuál es el sector económico de la oportunidad?' : 'What is the economic sector of the opportunity?'}</h3>
              <input type="text" name="sector_economico" onChange={handleChange} value={form.sector_economico}
                className="contenedorCompanyInfo_input" ></input>
            </div>
    
            <div style={{ marginBottom: '20px' }}>
              <h3 className='contenedorCompanyInfo_title'>{lang === 'es' ? '¿Cuál es el tipo de documento?' : 'What is the type of document?'}</h3>
              <select type="text" name="tipo_documento" onChange={handleChange} className="contenedorCompanyInfo_input" defaultValue={form.tipo_documento}>
                <option value="">{lang === 'es' ? 'Seleccione' : 'Select'}</option>
                <option value="C.C">{lang === 'es' ? 'Cédula de ciudadanía' : 'Citizenship card'}</option>
                <option value="NIT">NIT</option>
                <option value="Pasaporte">{lang === 'es' ? 'Pasaporte' : 'Passport'}</option>
              </select>
            </div>
    
            <div style={{ marginBottom: '20px' }}>
              <h3 className='contenedorCompanyInfo_title'>{lang === 'es' ? '¿Cuál es su número de identificación?' : 'What is your identification number?'} </h3>
              <input type="text" maxLength={12} name="documento" onChange={handleChange}
                className="contenedorCompanyInfo_input" value={form.documento}></input>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "space-between",
              padding: "1rem 3rem 0rem 3rem",
              alignItems: "center",              
            }}
          >
            <button className='button_siguiente_create_B' onClick={handleBackStep}>{lang === 'es' ? 'Anterior' : 'Previous'}</button>
            <h3 className='stepCounter'>{`${step}/14`}</h3>
            <button className='button_siguiente_create'
              onClick={handleStep} disabled={bottom}>{lang === 'es' ? 'Siguiente' : 'Next'}</button>
          </div>
        </>
      )
}

export default DocuCompany