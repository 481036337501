import { keyframes } from "styled-components";

const FromTop = keyframes`
  from {
    opacity: 0;
    transform: translateY(-50%);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const ToTop = keyframes`
  from {
    opacity: 1;
    transform: translateY(0);
  } 
  to {
    opacity: 0;
    transform: translateY(-50%);
  }
`;

const FromRight = keyframes`
  from {
    opacity: 0;
    transform: translateX(50%);
  } 
  to {
    opacity: 1;
    transform: translateX(0);
  }
`;

const ToRight = keyframes`
  from {
    opacity: 1;
    transform: translateX(0);
  } 
  to {
    opacity: 0;
    transform: translateX(50%);
  }
`;

const FromLeft = keyframes`
  from {
    opacity: 0;
    transform: translateX(-50%);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
`;

const ToLeft = keyframes`
  from {
    opacity: 1;
    transform: translateX(0);
  }
  to {
    opacity: 0;
    transform: translateX(-50%);
  }
`;

const OpacityOn = keyframes`
  from {
    opacity: 0;
  } 
  to {
    opacity: 1;
  }
`;

const OpacityOff = keyframes`
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
`;

const FromBottom = keyframes`
  from { 
    opacity: 0;
    transform: translateY(50%);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const ToBottom = keyframes`
  from {
    opacity: 1;
    transform: translateY(0);
  }
  to {
    opacity: 0;
    transform: translateY(50%);
  }
`;

export {
  FromTop,
  ToTop,
  FromRight,
  ToRight,
  FromLeft,
  ToLeft,
  OpacityOff,
  OpacityOn,
  FromBottom,
  ToBottom,
};
