/*eslint-disable*/
import React, {useEffect, useState} from 'react';
import './calendarioMentorUnilever.css';
import Sidebar from "../../../components/Sidebar/Sidebar";
import Navbar from "../../../components/Navbar/Navbar";
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import camera from "../../../assets/icons/cameraEvent.png";
import {useSelector, useDispatch} from 'react-redux';
import BannerUnileverHome from "../../../components/unilever/banner/BannerUnileverHome";
import axios from "axios";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import DialogContent from "@mui/material/DialogContent";
import {Link} from "react-router-dom";
import {format} from "date-fns";
import InsertInvitationIcon from '@mui/icons-material/InsertInvitation';
import SelectHorarioUnilever from "../../home/SelectHorarioUnilever";
import SelectHorarioUnileverProf from "../../home/SelectHorarioUnileverProf";
import {setDataUnilever, setErrorMessageUnilever} from "../../../actions";
import Calendar from 'react-calendar';
import dayjs from "dayjs";
import 'dayjs/locale/es-mx';
// import {useParams} from "react-router";
import {toast} from "react-toastify";
import BannerBancolombia from "../../bancolombiaComponentes/BannerBancolombia";
// http://localhost:3001/unilever/calendario/
// https://www.figma.com/file/bRCsb0kTVy7fsHDPrWlxTn/Coally?node-id=4891%3A29277&t=NSSyGMouvDiuRKle-4


export default function CalendarioMentorUnilever() {
  const dispatch = useDispatch()
  const lang = useSelector(state => state.lang)
  const user = useSelector(state => state.user)
  const [id] = useState(JSON.parse(localStorage.getItem('unilever_id')))
  const [rol] = useState(JSON.parse(localStorage.getItem('unilever_rol')))
  const [datesMentor, setDatesMentor] = useState([]);
  const [datesProf, setDatesProf] = useState([]);
  const [date] = useState(format(new Date(), 'yyyy-MM-dd'))
  const [eventDate, setEventDate] = useState()
  const [eventos, setEventos] = useState([])
  const [popup, setPopup] = useState(false)
  const [idUnilever] = useState(JSON.parse(localStorage.getItem('unilever_id')));
  const [match, setMatch] = useState([]);
  const [unileverUserData, setUnileverUserData] = useState([]);
  const [ready2, setReady2] = useState(false);
  const [ready, setReady] = useState(false);
  const [message, setMessage] = useState('');
  const [popup2, setPopup2] = useState(false);
  const [rescheduleStep, setRescheduleStep] = useState(0);
  const [reagendar, setReagendar] = useState(false);
  const [newDates, setNewDates] = useState('');
  const [hourClassnames, setHourClassnames] = useState([])

  useEffect(() => {
    axios.get(`https://findally-backend.herokuapp.com/api/unilever/find/match/user/${idUnilever}`)
      .then((res) => {
        setMatch(res.data.data.mentorvsstudent_relation);
        dispatch(setDataUnilever(res.data.data));
        setUnileverUserData(res.data.data);
        setReady2(true);
      })
  }, []);

  useEffect(() => {
    if (rol === 'ESTUDIANTE' && match?._id) {
      axios.get(`https://findally-backend.herokuapp.com/api/unilever/scheldule/${match._id}`)
        .then(res => {
          setDatesProf(res.data.data)
          setDatesMentor(res.data.data)
          console.log(res.data.data)
          setReady(true)
        }).catch(err => {
        console.log(err)
        dispatch(setErrorMessageUnilever('No hay horarios disponibles'))
        setReady(true)
      })
    }
  }, [match]);

  useEffect(() => {
    if (rol === 'ESTUDIANTE') {
      axios.get(`https://findally-backend.herokuapp.com/api/unilever/reservation/student/${id}`)
        .then(res => {
          console.log(res)
          res.data.data.map(item => {
            const evento = {
              title: item.name,
              start: item.date,
              extendedProps:
                {
                  mentoring: item._id,
                  hour: item?.date?.slice(11, 16),
                  mentor: item?.mentor?.name,
                  mentor_id: item?.mentor?._id
                },
              description: item.description,
              link: item?.meet_link
            }
            console.log(evento, "evento")
            return setEventos(eventos => [...eventos, evento])
          })
        }).catch(err => console.log(err))
    } else {
      axios.get(`https://findally-backend.herokuapp.com/api/unilever/reservation/mentor/${id}`)
        .then(res => {
          console.log(res)
          res.data.data.map(item => {
            const evento = {
              title: item.name,
              start: item.date,
              extendedProps:
                {
                  mentoring: item._id,
                  hour: item?.date?.slice(11, 16),
                  student: item?.student?.name,
                  student_id: item?.student?._id
                },
              description: item.description,
              link: item?.meet_link
            }
            return setEventos(eventos => [...eventos, evento])
          })
        })
    }
    //   eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user.id])

  const [open, setOpen] = useState(false);
  const [eventDetail, setEventDetail] = useState({});

  const rescheduleOpen = () => {
    if (reagendar) {
      setReagendar(false)
    } else {
      setReagendar(true)
    }
  }

  const nextStep = () => {
    if (rescheduleStep === 0 && newDates === '') {
      return toast.error('Debes seleccionar una fecha')
    }
    setRescheduleStep(rescheduleStep + 1)
  }

  const prevStep = () => {
    setRescheduleStep(rescheduleStep - 1)
  }

  const isDisabled = ({activeStartDate, date, view}) => {
    return !datesMentor.some(d => dayjs(date).isSame(dayjs(d), 'day'))
  }
  const isDisabled2 = ({activeStartDate, date, view}) => {
    return date.getTime() < new Date().getTime()
  }
  const hours = [
    {hour: '8:00', value: '8:00'},
    {hour: '9:00', value: '9:00'},
    {hour: '10:00', value: '10:00'},
    {hour: '11:00', value: '11:00'},
    {hour: '12:00', value: '12:00'},
    {hour: '13:00', value: '13:00'},
    {hour: '14:00', value: '14:00'},
    {hour: '15:00', value: '15:00'},
    {hour: '16:00', value: '16:00'},
    {hour: '17:00', value: '17:00'},
    {hour: '18:00', value: '18:00'},
    {hour: '19:00', value: '19:00'},
    {hour: '20:00', value: '20:00'},
  ]

  const selectHour = (hour, index) => {
    if (hourClassnames.includes(hour)) {
      return setHourClassnames(hourClassnames.filter(item => item !== hour))
    } else {
      setHourClassnames([hour])
    }
  }

  const handleOnClosePopUp = () => {
    setOpen(false)
    setHourClassnames([])
    setReagendar(false)
    setRescheduleStep(0)
    setNewDates('')
  }

  const reScheduleMethod = () => {
    if (hourClassnames.length === 0) {
      return toast.error('Debes seleccionar una hora')
    }
    const hourSplitted = hourClassnames[0].split(':')
    axios.put(`https://findally-backend.herokuapp.com/api/unilever/reservation/${eventDetail.extendedProps.mentoring}`, {
      date: new Date(newDates.setHours(hourSplitted[0], hourSplitted[1])),
      student: eventDetail.extendedProps.student_id,
    }).then(res => {
      console.log(res)
      toast.success('Se ha reagendado la mentoría')
      setTimeout(() => {
        window.location.reload()
      }, 1500)
    }).catch(err => {
      console.log(err)
      toast.error('Hubo un error, intenta de nuevo')
    })
  }

  const sendFeedbackEmail = (id) => {
    axios.get(`https://findally-backend.herokuapp.com/api/unilever/feedback/${id}`)
      .then(res => {
        console.log(res)
      })
  }

  return (
    <div>
      {rol === 'MENTOR' ? <SelectHorarioUnilever popup={popup} setPopup={setPopup} idUnilever={idUnilever}
                                                 datesMentor={datesMentor}/> : null}
      {rol === 'ESTUDIANTE' ?
        <SelectHorarioUnileverProf popup={popup2} setPopup={setPopup2} message={message} datesProf={datesProf}
                                   mentor={match?.name} datesMentor={datesMentor}/> : null}
      {open
        ? <Dialog
          open={open}
          style={{}}
          onClose={handleOnClosePopUp}
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description">
          <DialogTitle id="scroll-dialog-title">
            <div style={{
              minWidth: '400px',
              height: '100%',
              borderBottom: '1px solid #D6D6D6',
              textAlign: 'left'
            }}>
                <span style={{
                  fontSize: '20px',
                  fontWeight: 'bold',
                  color: '#393939',
                }}>
                  {lang === 'es' ? 'Detalle de la reserva' : 'Reservation detail'}
                </span>
            </div>
          </DialogTitle>
          <DialogContent>
            <div style={{
              width: '100%',
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
              justifyContent: 'center',
              marginTop: '15px'
            }}>
              <h1 className={'titlePrincipadetalleUni-acceso-meet'}>{eventDetail?.title}</h1>
              {rol === 'ESTUDIANTE'
                ? <h1 className={'titlePrincipadetalleUni-acceso-meet'}>
                  {lang === 'es' ? 'Tu mentor es ' : 'Your mentor is '}{eventDetail?.extendedProps?.mentor}
                </h1>
                : <h1 className={'titlePrincipadetalleUni-acceso-meet'}>
                  {lang === 'es' ? 'Tu mentee es ' : 'Your mentee is '}{eventDetail?.extendedProps?.student}
                </h1>}
              <h1
                className={'titledetalleUni-acceso-meet'}>{lang === 'es' ? 'Horario: ' : 'Schedule: '}{eventDetail?.extendedProps?.hour}</h1>
              <h1
                className={'titledetalleUni-acceso-meet'}>{lang === 'es' ? 'Fecha: ' : 'Date: '}{eventDetail?.startStr?.slice(0, 10)}</h1>
              <h1
                className={'titledetalleUni-acceso-meet'}>{lang === 'es' ? 'Descripción: ' : 'Description: '}{eventDetail?.extendedProps?.description}</h1>
              <a style={{width: "100%"}}
                 href={eventDetail?.extendedProps?.link ? eventDetail?.extendedProps?.link : ''}
                 target={"_blank"}
                 rel={"noopener noreferrer"}>
                <button className={'buttonUni-acceso-meet'} onClick={() => {
                  if (rol === 'ESTUDIANTE') {
                    sendFeedbackEmail(eventDetail?.extendedProps?.mentoring)
                  }
                }}>
                  {lang === 'es' ? 'Ingresar a la reunión' : 'Join the meeting'}
                </button>
                {rol === 'ESTUDIANTE' && (date === eventDate) ? <Link
                  to={`/unilever/profesional/feedback/${eventDetail?.extendedProps?.mentor_id}/${eventDetail?.extendedProps?.mentoring}`}>
                  <button className={'buttonUni-acceso-feedback'}>
                    {lang === 'es' ? 'Realizar feedback' : 'Give feedback'}
                  </button>
                </Link> : null}
              </a>
              {rol === 'MENTOR' && <button className={'buttonUni-acceso-feedback'} onClick={rescheduleOpen}>
                {lang === 'es' ? 'Reagendar reunión' : 'Reschedule meeting'}
              </button>}
              <div style={{
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                marginTop: '2%'
              }}>
                {/*reagendar && rescheduleStep === 0 && rol === 'ESTUDIANTE' ?
                        <div style={{width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column'}}>
                            <Calendar
                                value={newDates}
                                locale={lang === 'es' ? 'es-mx' : 'en'}
                                onChange={setNewDates}
                                tileDisabled={isDisabled}
                            />
                            <button className={'buttonUni-acceso-meet'} onClick={nextStep}>
                                {lang === 'es' ? 'Siguiente' : 'Next'}
                            </button>
                        </div>
                        :*/ reagendar && rescheduleStep === 0 && rol === 'MENTOR' ?
                  <div style={{
                    width: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    flexDirection: 'column'
                  }}>
                    <Calendar
                      value={newDates}
                      locale={lang === 'es' ? 'es-mx' : 'en'}
                      onChange={setNewDates}
                      tileDisabled={isDisabled2}
                    />
                    <button className={'buttonUni-acceso-meet'} onClick={nextStep}>
                      {lang === 'es' ? 'Siguiente' : 'Next'}
                    </button>
                  </div>
                  : null}
                {/*reagendar && rescheduleStep === 1 && newDates && rol === 'ESTUDIANTE' ?
                        <div className="unilever-buttons-hours-grid" style={{paddingTop: '5%'}}>
                            {
                            datesProf?.length > 0 && datesProf?.map((h, i) => {
                                return (
                                    <button className={hourClassnames === h ? 'unilever-select-button-hour-active' : 'unilever-select-button-hour'} key={i} onClick={()=>selectHour(h)} style={{display: 'flex', alignItems: 'center', justifyContent: 'center', alignSelf: 'flex-start'}}>
                                        {h}
                                    </button>
                                )
                            })
                        }
                        </div> :*/
                  reagendar && rescheduleStep === 1 && newDates && rol === 'MENTOR' ?
                    <div style={{
                      width: '100%',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      flexDirection: 'column',
                      height: '100%'
                    }}>
                      <div className="unilever-buttons-hours-grid" style={{paddingTop: '5%'}}>
                        {hours.map((hour, index) => {
                          return (
                            <button key={index} className={
                              hourClassnames.includes(hour.value) ? 'unilever-select-button-hour-active' : 'unilever-select-button-hour'
                            } onClick={() => selectHour(hour.value, index)}>
                              {hour.hour}
                            </button>
                          )
                        })}
                      </div>
                      <div style={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                      }}>
                        <button className={'buttonUni-acceso-feedback'} onClick={prevStep}>
                          {lang === 'es' ? 'Atrás' : 'Back'}
                        </button>
                        <button className={'buttonUni-acceso-meet'} onClick={reScheduleMethod}>
                          {lang === 'es' ? 'Reagendar' : 'Reschedule'}
                        </button>
                      </div>
                    </div>
                    : null}
              </div>
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpen(false)}>
              {lang === 'es' ? 'Cerrar' : 'Close'}
            </Button>
          </DialogActions>
        </Dialog>
        : null}
      <Sidebar/>
      <div
        className="BodyWeb"
        style={{
          padding: "0rem 2rem",
          position: "absolute",
          right: "0",
        }}
      >
        <Navbar/>
        <div className='ContenedorUni-banner'>
          <BannerBancolombia/>
        </div>
        {/*<div className="ContenedorUni-Grande">*/}
        <div style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          marginTop: '20px'
        }}>
          {rol === 'MENTOR' ? <div className='ContenedorUni-Search-Button-1'>
            <button className='buttonUni-busqueda-mentor' style={{
              height: '50px',
              width: '300px',
              fontSize: '18px',
            }} onClick={() => {
              setPopup(true)
            }}>
              <InsertInvitationIcon style={{marginRight: '10px'}}/>
              {lang === 'es' ? 'Propone días de mentoría' : "Propose mentoring day"}
            </button>
          </div> : rol === 'ESTUDIANTE' ? <div className='ContenedorUni-Search-Button-1'>
            <button className='buttonUni-busqueda-mentor' onClick={() => {
              setPopup2(true)
            }}>
              <InsertInvitationIcon style={{marginRight: '10px'}}/>
              {lang === 'es' ? 'Agendar mentoría' : "Schedule mentoring"}
            </button>
          </div> : null}

          <div style={{width: "90%", paddingBottom: "2%"}}>
            <FullCalendar
              plugins={[dayGridPlugin]}
              headerToolbar={{start: "prev", center: "title", end: "next"}}
              height={"auto"}
              width={"auto"}
              dayHeaderFormat={{weekday: 'long'}}
              eventContent={renderEventContent}
              eventClick={function (info) {
                setOpen(true)
                setEventDetail(info.event)
                setEventDate(format(new Date(info?.event?.startStr), 'yyyy-MM-dd'))
              }}
              initialView={"dayGridMonth"}
              events={eventos}
              locale={lang === 'es' ? 'es' : "en"}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

function renderEventContent(eventInfo) {
  const {event} = eventInfo;
  if (window.innerWidth < 600) {
    return (
      <div className='event-background'>
        <div className="camera-event-background">
          <p className='event-hour'>{event.extendedProps.hour}</p>
        </div>
      </div>
    );
  } else {
    return (
      <div className='event-background'>
        <div className="camera-event-background">
          <p className='event-hour'>{event.extendedProps.hour}</p>
          <img src={camera} alt="camera" width="18" height="18" className='event-img'></img>
        </div>
        <p className='event-title'>{event.title}</p>
        {event.extendedProps.mentor ? <p className='event-mentor'>{event.extendedProps.mentor}</p> :
          <p className='event-mentor'>{event.extendedProps.student}</p>}
      </div>
    );
  }
}
