import React from 'react';
import './CrearOportunidadNew.css'


export default function Finished(props) {
const {form, handleSubmit, handleBackStep, step, lang} = props;

    return (
      <>
        <div className="contenedorChooseType">
          {form.editable === 0?(<button
            className="button_finish_project"
            onClick={(e) => {
              handleSubmit(e);
            }}
          >
            {<span>{lang === 'es' ? 'Crear Oportunidad' : 'Create Opportunity'}</span>}
          </button>):(<button
            className="button_finish_project"
            onClick={(e) => {
              handleSubmit(e);
            }}
          >
            {<span>{lang === 'es' ? 'Actualizar oportunidad' : 'Update Opportunity'}</span>}
          </button>)}
        </div>
        <div className="chooseTypeButton">
          {
            <button
              className="button_siguiente_create"
              onClick={handleBackStep}
            >
              {lang === "es" ? "Volver" : "Back"}
            </button>
          }
          <h3
            style={{
              fontFamily: "Poppins",
              fontWeight: "400",
              fontSize: "12px",
            }}
          >{`${step}/14`}</h3>
          <div className="button_siguiente_create_C"></div>
        </div>
      </>
    );
}