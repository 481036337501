import React, { useEffect } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import SchneiderProvider from "../context/SchneiderProvider";
import { PopupProvider, usePopupContext } from "../context/popups/PopupContext";
import Login from "../pages/auth/login/Login";
import Register from "../pages/auth/register/Register";
import ConfirmOtp from "../pages/auth/confirm/ConfirmOtp";
import RecoverPassword from "../pages/auth/recover/RecoverPassword";
import ChangePassOtp from "../pages/auth/changePass/ChangePassOtp";
import Home from "../pages/professional/home/Home";
import EducationPage from "../pages/professional/education/EducationPage";
import CoursesPage from "../pages/professional/education/CoursesPage";
import Opportunities from "../pages/professional/oportunities/Oportunities";
import ProfileSchneider from "../pages/professional/profile/Profile";
import Opportunity from "../pages/professional/oportunities/opportunity/Opportunity";
import CvForm from "../pages/professional/cvform/CvForm";
import { Dashboard } from "../pages/admin/Dashboard";
import FirstFilterForm from "../pages/professional/firstfilter/FirstFilterForm";
import CompanyHome from "../pages/company/home/CompanyHome";
import {CompanyDashboard} from "../pages/company/dashboard/CompanyDashboard";
import ApplicantProfile from "../pages/company/applicant/profile/ApplicantProfile";
import CreateOpportunity from "../pages/company/createopportunity/CreateOpportunity";
interface WithProviderProps {
  children: React.ReactNode;
}

const SchneiderRoutes = () => {
  return (
    <Switch>
      <Route
        exact
        path="/schneider"
        render={() => (
          <WithProviderNoAuth>
            <Login />
          </WithProviderNoAuth>
        )}
      />
      <Route
        exact
        path="/schneider/login"
        render={() => (
          <WithProviderNoAuth>
            <Login />
          </WithProviderNoAuth>
        )}
      />
      <Route
        exact
        path="/schneider/register"
        render={() => (
          <WithProviderNoAuth>
            <Register />
          </WithProviderNoAuth>
        )}
      />
      <Route
        exact
        path="/schneider/confirm/user"
        render={() => (
          <WithProviderNoAuth>
            <ConfirmOtp />
          </WithProviderNoAuth>
        )}
      />
      <Route
        exact
        path="/schneider/recover"
        render={() => (
          <WithProviderNoAuth>
            <RecoverPassword />
          </WithProviderNoAuth>
        )}
      />
      <Route
        exact
        path="/schneider/change/password"
        render={() => (
          <WithProviderNoAuth>
            <ChangePassOtp />
          </WithProviderNoAuth>
        )}
      />
      <Route
        exact
        path="/schneider/home"
        render={() => (
          <WithProviderAuth>
            <Home />
          </WithProviderAuth>
        )}
      />
      <Route
        exact
        path="/schneider/projects"
        render={() => (
          <WithProviderAuth>
            <Opportunities />
          </WithProviderAuth>
        )}
      />
      <Route
        exact
        path="/schneider/cv"
        render={() => (
          <WithProviderAuth>
            <CvForm />
          </WithProviderAuth>
        )}
      />
      <Route
        exact
        path="/schneider/dashboard"
        render={() => (
          <WithProviderNoAuth>
            <Dashboard />
          </WithProviderNoAuth>
        )}
      />
      <Route
        exact
        path="/schneider/formacion"
        render={() => (
          <WithProviderAuth>
            <EducationPage />
          </WithProviderAuth>
        )}
      />
      <Route
        exact
        path="/schneider/company/home"
        render={() => (
          <WithProviderAuth>
            <CompanyHome />
          </WithProviderAuth>
        )}
      />
      <Route
        exact
        path="/schneider/company/dashboard/:id"
        render={() => (
          <WithProviderAuth>
            <CompanyDashboard />
          </WithProviderAuth>
        )}/>
      <Route
        exact
        path="/schneider/company/applicant/:id"
        render={() => (
          <WithProviderAuth>
            <ApplicantProfile />
          </WithProviderAuth>
        )}
        />
      <Route
        exact
        path="/schneider/company/create/:id"
        render={() => (
          <WithProviderAuth>
            <CreateOpportunity/>
          </WithProviderAuth>
        )}/>
      <Route
        exact
        path="/schneider/complete/form"
        render={() => (
          <WithProviderAuth>
            <FirstFilterForm />
          </WithProviderAuth>
        )}
      />
      <Route
        exact
        path="/schneider/cv/:idUser"
        render={() => (
          <WithProviderAuth>
            <CvForm />
          </WithProviderAuth>
        )}
      />
      <Route
        exact
        path="/schneider/project/:id"
        render={() => (
          <WithProviderAuth>
            <Opportunity />
          </WithProviderAuth>
        )}
      />
      <Route
        exact
        path="/schneider/profile/:id"
        render={() => (
          <WithProviderAuth>
            <ProfileSchneider />
          </WithProviderAuth>
        )}
      />
      <Route
        exact
        path="/schneider/formacion/:sector"
        render={() => (
          <WithProviderAuth>
            <CoursesPage />
          </WithProviderAuth>
        )}
      />
    </Switch>
  );
};

const WithProviderAuth = ({ children }: WithProviderProps) => {
  const user = JSON.parse(localStorage.getItem("user") || "{}");
  const { removeAllPopups } = usePopupContext();

  useEffect(() => {
    return () => {
      removeAllPopups();
    };
  }, [removeAllPopups]);

  if (!user || !user.id) {
    return <Redirect to="/schneider/login" />;
  }

  return (
    <SchneiderProvider>
      <PopupProvider>{children}</PopupProvider>
    </SchneiderProvider>
  );
};

const WithProviderNoAuth = ({ children }: WithProviderProps) => {
  return <SchneiderProvider>{children}</SchneiderProvider>;
};

export default SchneiderRoutes;
