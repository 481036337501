import React, { Fragment } from 'react';
import DataTable from 'react-data-table-component'
import { supportDB, supportColumns } from '../data/support-ticket';
import '../Styles/manage.css';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Badge from '@material-ui/core/Badge';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Link from '@material-ui/core/Link';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import { mainListItems } from '../../../components/Dashboard/lisItems';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import GroupIcon from '@material-ui/icons/Group';
import AccountTreeIcon from '@material-ui/icons/AccountTree';
import AddIcon from '@material-ui/icons/Add';
import "../../../components/Dashboard/Styles/styles.css";


const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    toolbar: {
        paddingRight: 24, // keep right padding when drawer closed
    },
    toolbarIcon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
        ...theme.mixins.toolbar,
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginRight: 36,
    },
    menuButtonHidden: {
        display: 'none',
    },
    title: {
        flexGrow: 1,
    },
    drawerPaper: {
        position: 'relative',
        whiteSpace: 'nowrap',
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerPaperClose: {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(9),
        },
    },
    appBarSpacer: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        height: '100vh',
        overflow: 'auto',
    },
    container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    fixedHeight: {
        width: 1100,
        height: 640,
    },
}));

export default function SupportTicket(props) {

    const handleLogout = () => {
        document.cookie =
            "ActualProject=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/";
        document.cookie =
            "ActualProjec=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/";
        document.cookie = "Aemail=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/";
        document.cookie = "Aname=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/";
        document.cookie = "Aid=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/";
        document.cookie = "Atoken=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/";

        document.cookie =
            "Aprojects=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/";

        document.cookie = "Aform=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/";

        document.cookie = "Aroles=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/";

        props.logoutUser({}, "/");
    };

    const classes = useStyles();
    const [open, setOpen] = React.useState(true);
    const handleDrawerOpen = () => {
        setOpen(true);
    };
    const handleDrawerClose = () => {
        setOpen(false);
    };
    const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);

    return (
        <div className={classes.root}>
            <CssBaseline />
            <AppBar position="absolute" className={clsx(classes.appBar, open && classes.appBarShift)}>
                <Toolbar className={classes.toolbar}>
                    <IconButton
                        edge="start"
                        color="inherit"
                        aria-label="open drawer"
                        onClick={handleDrawerOpen}
                        className={clsx(classes.menuButton, open && classes.menuButtonHidden)}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Typography component="h1" variant="h6" color="inherit" noWrap className={classes.title}>
                        Dashboard
          </Typography>
                    <IconButton color="inherit">
                        <Badge color="secondary">
                            <li className="header__icons__group-li">
                                <GroupIcon style={{ color: '#FFFFFF' }} />
                                <p>Administración</p>
                                <ul className="header__display-menu_admin">
                                    <li className="header__display__menu-perfil-ver-perfil">
                                        <AccountCircleIcon style={{ color: '#540E87', fontSize: '40px' }} />
                                        <div className="header__display__menu__perfil-data">
                                            {/*<p>Username</p>*/}
                                            <p className="header-display-name-coallier">ADMIN</p>
                                            <Link to="/admin/managment/users">
                                                <p className="header-display-see-profile">Managment</p></Link>
                                        </div>
                                    </li>

                                    <Link to="/admin/managment/users">
                                        <li className="header__display__menu-item">
                                            <GroupIcon style={{ color: '#540E87', fontSize: '20px' }} />
                                            <p className="menu-text">Manage users</p>
                                        </li>
                                    </Link>
                                    <Link to="/admin/managment/projects">
                                        <li className="header__display__menu-item">
                                            <AccountTreeIcon style={{ color: '#540E87', fontSize: '20px' }} />
                                            <p className="menu-text">Manage projects</p>
                                        </li>
                                    </Link>
                                    <Link to="/admin/match">
                                        <li className="header__display__menu-item">
                                            <AddIcon style={{ color: '#540E87', fontSize: '20px' }} />
                                            <p className="menu-text">Match</p>
                                        </li>
                                    </Link>

                                    <Link to="#" onClick={handleLogout}>
                                        <li className="header__display__menu-item-cerrar">
                                            <ExitToAppIcon style={{ color: '#540E87', fontSize: '20px' }} />
                                            <p className="menu-text">Cerrar sesion</p>
                                        </li>
                                    </Link>

                                    <p className="header__display_copy">© 2021 CoAlly | Powered by CoAlly</p>
                                </ul>
                            </li>
                        </Badge>
                    </IconButton>
                </Toolbar>
            </AppBar>
            <Drawer
                variant="permanent"
                classes={{
                    paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
                }}
                open={open}
            >
                <div className={classes.toolbarIcon}>
                    <IconButton onClick={handleDrawerClose}>
                        <ChevronLeftIcon />
                    </IconButton>
                </div>
                <Divider />
                <List>{mainListItems}</List>
                <Divider />
            </Drawer>
            <main className={classes.content}>
                <div className={classes.appBarSpacer} />
                <Container maxWidth="lg" className={classes.container}>
                    <Grid container spacing={3}>
                        {/* Chart */}
                        <Grid item xs={12} md={8} lg={9}>
                            <Paper className={fixedHeightPaper}>
                                <Fragment>
                                    <div className="table-proyects">
                                        <div className="row">
                                            <div className="col-sm-12">
                                                <div className="card">
                                                    <div className="card-body">
                                                        <div className="table-responsive support-table">
                                                            <DataTable
                                                                columns={supportColumns}
                                                                data={supportDB}
                                                                striped={true}
                                                                center={true}
                                                                pagination
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Fragment>
                            </Paper>
                        </Grid>
                    </Grid>
                </Container>
            </main>
        </div>
    );
}