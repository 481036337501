import React from 'react';
import user from "../../../assets/logos/ingresar logro x5.png";

export const supportDB  = [
        {
            image: <img src={user} className="img-support-user" alt="" />,
            nombre: "Juan ortiz",
            rol: "coallier",
            descripcion: "no puedo ver la metodologia",
            categoria: "tecnico",
            status: "En espera",
            email: "t.nixon@coally.net"
        },

        {
            image: <img src={user} className="img-support-user" alt="" height="100px" width="100px" />,
            nombre: "Daniel Gomez",
            rol: "company",
            descripcion: "no puedo ver la metodologia",
            categoria: "tecnico",
            status: "En espera",
            email: "a.cox@coally.net"
        },

        {
            image: <img src={user} className="img-support-user" alt="" height="100px" width="100px" />,
            nombre: "Andres joya",
            rol: "coallier",
            descripcion: "no puedo ver la metodologia",
            categoria: "tecnico",
            status: "En espera",
            email: "b.greer@coally.net"
        }
    ]
export const supportColumns = [
    {
        name: 'Image',
        selector: 'image',
        sortable: true,
        center:true,
    },
    {
        name: 'Nombre',
        selector: 'nombre',
        sortable: true,
        center:true,
    },
    {
        name: 'Rol',
        selector: 'rol',
        sortable: true,
        center:true,
     
    },
    {
        name: 'Descripcion',
        selector: 'descripcion',
        sortable: true,
        center:true,
    },
    {
        name: 'Categoria',
        selector: 'categoria',
        sortable: true,
        center:true,
    },
    {
        name: 'Status',
        selector: 'status',
        sortable: true,
        center:true,
    },
    {
        name: 'Email',
        selector: 'email',
        sortable: true,
        center:true,
    }
  ];
