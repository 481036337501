import React, { useEffect, useState } from 'react';
import SearchIcon from "@mui/icons-material/Search";
import { useSelector} from 'react-redux';
import { useHistory } from 'react-router-dom';

const SearchOportunity = () => {
  const { lang } = useSelector(state => state);
  const history = useHistory();
  const [search, setSearch] = useState('');

  const handleSearchChange = (value, name) => {      
    const currentQuery = new URLSearchParams(window.location.search);
    if (value) {
      currentQuery.set(name, value);
    } else {
      currentQuery.delete(name);
    }
    history.push(`?${currentQuery.toString()}`);
  }

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleSearchChange(search, 'search');
    }
  }

  useEffect(() => {
    const currentQuery = new URLSearchParams(window.location.search);
    const searchDefault = currentQuery.get('search') || null;
    setSearch(searchDefault);
  }, []);
  

  return (
    <>
      <div className="container-search-oportunities">
        <SearchIcon className={'lupa'} />
        <input
          className={'search-oportunities-input-coally'}
          style={{
            border: 'none',
            marginRight: 0
          }}
          placeholder={lang === 'es' ? 'Buscar oportunidades' : 'Search opportunities'}
          value={search ? search : ''}
          onChange={(e) => setSearch(e.target.value)}
          onBlur={() => handleSearchChange(search, 'search')}
          onKeyDown={handleKeyPress}          
        />

        <button
          style={{
            backgroundColor: '#202256',
            height: '33px',
            width: '118px',
            borderRadius: '5px',
            color: '#FFFFFF',
            textAlign: 'center',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
          onClick={() => handleSearchChange(search, 'search')}
        >
          {lang === 'es' ? 'Buscar' : 'Search'}
        </button>
      </div>
    </>
  )
}

export default SearchOportunity;
