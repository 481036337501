import React,{useState, useEffect, useContext} from "react";
import {useParams} from "react-router";
import {Link} from "react-router-dom";
import {videoSwitcher, video} from "./videosGenerator";
import './Formacion.css'
import {useSelector} from "react-redux";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AccordionDetails from "@mui/material/AccordionDetails";
import alarm from "../../assets/icons/alarm.svg"
import SnakeProgress from "../../profesionalpag/Snake/SnakeProgress";
import {UniversityContext} from "../context/UniversitiesContext";
import UniversitiesSideBar from "../components/sidebar/UniversitiesSideBar";
import UniversitiesNavbar from "../components/navbar/UniversitiesNavbar";
export const SectorVideosUniversities = () => {
    const {university} = useContext(UniversityContext);
    const {sector} = useParams<{sector: string}>();
    const [videos, setVideos] = useState<video[]>([]);
    const lang = useSelector((state: any) => state.lang);
    useEffect(() => {
        if(sector){
            const video = videoSwitcher(sector);
            setVideos(Array.isArray(video) ? video : [video]);
        }

    }, [sector]);


    return (
        <>
            { university && university.color && <div className="contenedor-formacion">
                <div style={{backgroundColor: '#F5F5F5'}}>
                    <UniversitiesSideBar location={'formación' as any} style={undefined}/>
                    <div className="BodyWeb"
                         style={{padding: "0rem 2rem", position: "absolute", right: "0"}}>
                        <UniversitiesNavbar locate={'formación'}/>
                        <div style={{
                            paddingInline: '2.5%',
                            display: "flex",
                            justifyContent: "flex-start",
                            width: "100%",
                            flexDirection: "column",
                            alignItems: "flex-start"
                        }}>
                            {videos?.length &&
                              <div style={{display: 'flex', alignItems: 'center'}}>
                                <Link to={`/${university.route}/formacion`}>
                                  <div style={{cursor: "pointer"}}>
                                    <ArrowBackIosIcon/>
                                  </div>
                                </Link>
                                <h1 className="formacion-title" style={{color: university && university.color ? university.color.secondary : ''}}>
                                    {lang === 'es' ? videos[0].title : videos[0].title_en}
                                </h1>
                              </div>
                            }
                            <div style={{display: "flex", flexDirection: 'column', marginTop: '4%', width: '100%'}}>
                                {videos?.map((video, index) => {
                                    return (<div key={video.title}>
                                            <div style={{width: '100%'}}>
                                                <Accordion
                                                    style={{
                                                        backgroundColor: 'transparent',
                                                        width: '100%',
                                                        boxShadow: 'none',
                                                        borderBottom: '1px solid #d6d6d7',
                                                        borderRadius: '0px'
                                                    }}>
                                                    <AccordionSummary
                                                        expandIcon={<ExpandMoreIcon/>}
                                                        aria-controls="panel1a-content"
                                                        id="panel1a-header"
                                                    >
                                                        <img
                                                            style={{
                                                                width: '55px',
                                                                height: '55px',
                                                                marginRight: '20px',
                                                                backgroundColor: 'white',
                                                                borderRadius: '50%'
                                                            }}
                                                            src={'https://d3puay5pkxu9s4.cloudfront.net/pages/edutin-isotype-positive.svg'}
                                                            alt={'logo'}/>
                                                        <div>
                                                            <h1 style={{
                                                                fontFamily: "Poppins",
                                                                fontWeight: "700",
                                                                fontSize: "24px",
                                                                color: "#393939"
                                                            }}>
                                                                {lang === 'es' ? video.title : video.title_en}
                                                            </h1>
                                                            <div style={{
                                                                display: 'flex',
                                                                flexDirection: 'row',
                                                                alignItems: 'center'
                                                            }}>
                                                                <img src={alarm} alt={'alarm-icon'}
                                                                     style={{height: 'auto'}}/>
                                                                <h2 style={{
                                                                    marginLeft: '5px',
                                                                    fontFamily: "Poppins",
                                                                    fontWeight: "700",
                                                                    marginTop: '8px',
                                                                    fontSize: "16px",
                                                                    color: "#202256"
                                                                }}>
                                                                    {video.duration}
                                                                </h2>
                                                            </div>
                                                        </div>
                                                    </AccordionSummary>
                                                    <AccordionDetails>
                                                        <div style={{marginTop: '45px'}}>
                                                            <SnakeProgress data={video.subModules}/>
                                                        </div>
                                                    </AccordionDetails>
                                                </Accordion>
                                            </div>
                                            {
                                                video.subModules2 && <div style={{width: '100%'}}>
                                                <Accordion
                                                  style={{
                                                      backgroundColor: 'transparent',
                                                      width: '100%',
                                                      boxShadow: 'none',
                                                      borderBottom: '1px solid #d6d6d7',
                                                      borderRadius: '0px'
                                                  }}>
                                                  <AccordionSummary
                                                    expandIcon={<ExpandMoreIcon/>}
                                                    aria-controls="panel1a-content"
                                                    id="panel1a-header"
                                                  >
                                                    <img
                                                      style={{
                                                          width: '55px',
                                                          height: '55px',
                                                          marginRight: '20px',
                                                          backgroundColor: 'white',
                                                          borderRadius: '50%'
                                                      }}
                                                      src={'https://d3puay5pkxu9s4.cloudfront.net/pages/edutin-isotype-positive.svg'}
                                                      alt={'logo'}/>
                                                    <div>
                                                      <h1 style={{
                                                          fontFamily: "Poppins",
                                                          fontWeight: "700",
                                                          fontSize: "24px",
                                                          color: "#393939"
                                                      }}>
                                                          {lang === 'es' ? video.title2 : video.title2_en}
                                                      </h1>
                                                      <div style={{
                                                          display: 'flex',
                                                          flexDirection: 'row',
                                                          alignItems: 'center'
                                                      }}>
                                                        <img src={alarm} alt={'alarm-icon'} style={{height: 'auto'}}/>
                                                        <h2 style={{
                                                            marginLeft: '5px',
                                                            fontFamily: "Poppins",
                                                            fontWeight: "700",
                                                            marginTop: '8px',
                                                            fontSize: "16px",
                                                            color: "#202256"
                                                        }}>
                                                            {video.duration2}
                                                        </h2>
                                                      </div>
                                                    </div>
                                                  </AccordionSummary>
                                                  <AccordionDetails>
                                                    <div style={{marginTop: '45px'}}>
                                                      <SnakeProgress data={video.subModules2}/>
                                                    </div>
                                                  </AccordionDetails>
                                                </Accordion>
                                              </div>
                                            }
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            </div>}
        </>
    )
}
