import styled from "styled-components";
import { Link } from "react-router-dom";
import {
  FromTop,
  ToTop,
  FromRight,
  ToRight,
} from "../../../styles/AnimationStyles";

const UserInfoContainer = styled.div.attrs({
  "data-custom-label": "UserInfoContainer",
})<{ isClosing: boolean }>`
  background: #ffffff;
  box-shadow: 0 7px 32px rgba(0, 0, 0, 0.1);
  width: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 30px;
  overflow: scroll;
  padding-bottom: 30px;
  border-radius: 5px;
  animation: ${(props) => (props.isClosing ? ToTop : FromTop)} 0.5s ease;

  @media (max-width: 700px) {
    width: 100%;
    animation: ${(props) => (props.isClosing ? ToRight : FromRight)} 0.5s ease;
  }
  @media (max-width: 425px) {
    width: 100%;
  }
`;

const UserInfoImageContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 140px;
  height: 140px;
  border-radius: 50%;
  margin-bottom: 30px;

  div div {
    margin-left: 0.1em;
  }
`;

const CompanyInfoImage = styled.img`
  border-radius: 50%;
  width: 140px;
  height: 140px;
  background-color: #f07130;
`;

const ViewProfileLinkContainer = styled(Link)`
  min-width: 105px;
  color: #202256;
  filter: grayscale(100%);
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    filter: grayscale(0%);
    color: #202256;
    font-weight: 500;
    stroke-width: 1.5px;
  }

  svg {
    stroke: #202256;
  }
`;

const ViewProfileText = styled.span`
  padding-right: 5px;
`;

const UserInfoName = styled.h2`
  margin-top: 15px;
  font-weight: 700;
  font-size: 16px;
  color: red;
`;

const UserCompanyPlanContainer = styled.div`
  width: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const UserInfoSeparator = styled.div`
  margin-top: 15px;
  margin-bottom: 15px;
  width: 90%;
  height: 1px;
  background-color: #e5e5e5;
`;

const UserCompanyPlan = styled.h3`
  font-weight: 700;
  font-size: 14px;
  color: #202256;
`;

const UpgradeYourPLanLink = styled(Link)`
  color: #202256;
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background: #ffffff;
  border: 1px solid #202256;
  border-radius: 5px;
  width: 190px;

  &:hover {
    background: #202256;
    color: #ffffff;
  }
`;

export {
  UserInfoContainer,
  UserInfoImageContainer,
  CompanyInfoImage,
  ViewProfileLinkContainer,
  ViewProfileText,
  UserInfoName,
  UserCompanyPlanContainer,
  UserInfoSeparator,
  UserCompanyPlan,
  UpgradeYourPLanLink,
};
