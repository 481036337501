import React, { useState, useEffect, useCallback } from 'react';
import { styled } from '@mui/material/styles';
import { useDispatch, useSelector } from 'react-redux';
import { getUserInfo } from '../../../actions';
import axios from 'axios';
import { toast } from "react-toastify";
import { Dialog, DialogActions } from '@mui/material';
import { DialogTitle } from '@material-ui/core';
import DialogContent from "@mui/material/DialogContent";
import Badge from '@mui/material/Badge';
import Avatar from "@mui/material/Avatar";
import phone from '../../../assets/icons/phone.svg'
import mail from '../../../assets/icons/mail.svg'
import camara from '../../../assets/icons/camera.svg'
import CircularFrame from '../../../components/CircularFrame/CircularFrame';
import DriveFileRenameOutlineOutlinedIcon from "@mui/icons-material/DriveFileRenameOutlineOutlined";
import PopUpEdit from '../PopUpEdit';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import '../Styles/PerfilCardCompany.css'
import Cropper from 'react-easy-crop';
import getCroppedImg from "../../../../src/pages/Auth/NewRegister/cropImage";
import { resizeImage } from '../../../pages/Auth/NewRegister/resizeImage';
import Button from "@mui/material/Button";


const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '574px',
  height: '95vh',
  bgcolor: '#FFFFFF',
  borderRadius: '8px',
  overflow: 'auto',
};

const PerfilCardCompany = ({ userInfo, dataUser, lang }) => {
  const dispatch = useDispatch()
  const { user } = useSelector((state) => state)
  const [progress, setProgress] = useState(0)
  const [changeAvatar, setChangeAvatar] = useState(false)
  const [avatar, setAvatar] = useState(null)
  const [preview, setPreview] = useState(null)
  const [open, setOpen] = useState(false)
  const [crop, setCrop] = useState({ x: 0, y: 0 })
  const [zoom, setZoom] = useState(1)
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)
  const [openPopUpCropper, setOpenPopUpCropper] = useState(false)
  const [croppedImage, setCroppedImage] = useState(null)


  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels)
  }, [])

  const showCroppedImage = useCallback(async (croppedArea, croppedAreaPixel) => {
    try {
      const croppedImage = await getCroppedImg(
        preview,
        croppedAreaPixels,
        0
      )
      setCroppedImage(croppedImage)
      setOpenPopUpCropper(false)
    } catch (e) {
      console.error(e)
    }
  }, [croppedAreaPixels , preview])



  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const SmallAvatar = styled(Avatar)(({ theme }) => ({
    width: 35,
    height: 35,
    backgroundColor: "#bdbdbd",
    cursor: "pointer",
  }));


  const handleFile = (e) => {
    e.preventDefault()
    const file = e.target.files[0];
    const reader = new FileReader();
    if (file.size > 4000000) {     
        toast.error( lang === 'es' ? 'El tamaño de la imagen no puede superar los 4MB' : 'The size of the image cannot exceed 4MB', {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }) 
      return;
    }
    // Load file as data URL
    reader.readAsDataURL(file);
    // Create a new image object to get the image dimensions
    const image = new Image();
    image.src = URL.createObjectURL(file);
    image.onload = () => {
      // Set the selected image and preview image
      setAvatar(file);
      setPreview(image.src)
      setOpenPopUpCropper(true)
    }
    // Limit image format to JPEG, PNG, or GIF
    if (file.type !== 'image/jpeg' && file.type !== 'image/png' && file.type !== 'image/gif') {
      return toast.error(lang === 'es' ? 'El formato de la imagen debe ser JPG, PNG o GIF' : 'The image format must be JPG, PNG or GIF', {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
    }
  }

  const handleAvatarClose = () => {
    setChangeAvatar(false);
    setAvatar(null)
  };

  const handleAvatarSubmit = async () => {
    const response = await fetch(croppedImage);
    const blob = await response.blob();
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onload = async () => {
      const dataUrl = reader.result;
      const image = new Image();
      image.src = dataUrl;
      image.onload = async () => {
        const resizedBlob = await resizeImage(image, 200, 200);
        const file = new File([resizedBlob], 'avatar.png', { type: 'image/png' })
        const formData = new FormData();

        formData.append("upload_avatar", file);
        axios.post(`https://findally-backend.herokuapp.com/api/user/uploadAvatarCompany/${userInfo?.id}`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }).then(res => {
          setChangeAvatar(false);
          dispatch(getUserInfo(userInfo?.id))
        }).catch(err => {
          console.log(err)
          toast.error('error cargando el avatar', {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          })
        })
      }
    }

  }

  useEffect(() => {
    if (!avatar) {
      setPreview(undefined)
      return
    }

    const objectUrl = URL.createObjectURL(avatar)
    setPreview(objectUrl)

    // free memory when ever this component is unmounted
    return () => URL.revokeObjectURL(objectUrl)
  }, [avatar])

  useEffect(() => {
    let count = 0;
    if (dataUser?.sector?.length > 0) count += 10;
    if (dataUser?.descripcion?.length > 0) count += 10;
    if (dataUser?.telefono?.length > 0) count += 10;
    if (dataUser?.especialidades?.length > 0) count += 10;
    if (dataUser?.sitio_web?.length > 0) count += 10;
    if (dataUser?.rep_legal?.length > 0) count += 10;
    if (userInfo?.avatar?.length > 0) count += 10;
    if (dataUser?.ciudad_fundacion?.length > 0) count += 10;
    if (dataUser?.tamanio?.length > 0) count += 10;
    if (dataUser?.fecha_fundacion?.length > 0) count += 10;
    setProgress(count)
  }, [dataUser, userInfo])


  return (
    <div className='Perfil-company-container-cards'>

      <div className='porcentaje-company-container'>
        <CircularFrame userInfo={userInfo} progressCompany={progress} />
        <div>
          <Badge
            overlap="circular"
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            badgeContent={
              user?.id === userInfo?.id &&
              <SmallAvatar
                sx={{ width: "30px", height: "30px", background: '#ec7332' }}
                alt="Remy Sharp" src={camara} onClick={() => setChangeAvatar(true)} />
            }
            style={{ zIndex: 0 }}
          >
          </Badge>
        </div>
        <div>
          <Dialog
            open={changeAvatar}
            onClose={handleAvatarClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            fullWidth={true}
            maxWidth="sm"
          /* sx={ {height: "50%", maxHeight: '50%'} } */
          >
            <DialogTitle id="alert-dialog-title">
              {
                lang === "es" ? "Cambiar Avatar" : "Change Avatar"
              }
            </DialogTitle>
            <DialogContent sx={{ height: "300px" }}>
              {/* make 2 inputs  */}
              <div className='container-avatar-company'>
                <div style={{ width: '200px', height: '200px', display: 'flex', alignItems: 'center' }}>
                  <img src={croppedImage ? croppedImage : user.avatar} alt='profile-avatar-new'
                    style={{ width: '100%', height: '100%', display: !preview ? 'hidden' : null }} />
                  <div>
                    <Dialog
                      open={openPopUpCropper}
                    >
                      <DialogTitle id="scroll-dialog-title">{
                        lang === 'es' ? 'Selecciona y recorta tu logo' : 'Select and crop your logo'
                      } </DialogTitle>
                      <DialogContent>
                        <div className={'cropContainer'} style={{
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'center',
                          alignItems: 'center',
                          marginBottom: '16px'
                        }}>
                          <Cropper
                            image={preview}
                            crop={crop}
                            zoom={zoom}
                            onCropChange={setCrop}
                            onCropComplete={onCropComplete}
                            onZoomChange={setZoom}
                            aspect={1 / 1}
                          />
                        </div>
                      </DialogContent>
                      <DialogActions>
                        <Button onClick={showCroppedImage}>
                          {lang === 'es' ? 'Guardar' : 'Save'}
                        </Button>
                      </DialogActions>
                    </Dialog>
                  </div>

                </div>
                <div>
                  <input
                    style={{
                      width: "100%",
                    }}
                    onChange={(e) => handleFile(e)}
                    type="file" />
                </div>
                <div>
                  <button
                    onClick={() => handleAvatarSubmit()}
                    className='button-edit-avatar-company'
                  >
                    {lang === "es" ? "Guardar" : "Save"}
                  </button>
                </div>
              </div>
            </DialogContent>
          </Dialog>
        </div>
        {user?.id === userInfo?.id &&
          <>
            <div className='porcentaje-complete-company'>
              <p className='porcentaje-p-company'>{progress}%</p>
              <p className='porcentaje-text-company'> {lang === 'es' ? 'Completa la información.' : 'Completed information.'}</p>
            </div>
            <div style={{
              display: 'flex',
              justifyContent: 'center',
              width: '100%'
            }}>
              <button
                className='button-edit-perfil-company'
                onClick={handleOpen}
              >
                {lang === "es" ? "Editar perfil" : "Edit profile"}
                <DriveFileRenameOutlineOutlinedIcon />
              </button>
              <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box sx={style}>
                  <PopUpEdit user={userInfo?.id} handleClose={handleClose} dataUser={dataUser} />
                </Box>
              </Modal>
            </div>
          </>
        }
        <hr className='herre-company' />
      </div>
      <div className='contact-data-company'>
        <p><img src={phone} alt='phone' className='icon-cards-company' />{userInfo?.phoneNumber ? userInfo?.phoneNumber : ''}</p>
        <p><img src={mail} alt='mail' className='icon-cards-company' />{userInfo?.email}</p>
      </div>
    </div>
  )
}

export default PerfilCardCompany
