import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { ReduxStateInterface } from "../../../../types/reduxInterface";
import { ConsultorData, getPostedProjects, tourStatus } from "../../../../../actions";

export const useHomeCompanyData = () => {
  const { user, lang, tourViewedCompany } = useSelector((state: ReduxStateInterface) => state);
  const dispatch = useDispatch();
  const [projectsCsv, setProjectsCsv] = useState([]);
  const [userProjects, setUserProjects] = useState([]);
  const [statistics, setStatistics] = useState([]);
  const [page, setPage] = useState({
    currentPage: 0,
    totalPage: 0,
  });
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    axios
      .get(
        `https://findally-backend.herokuapp.com/api/user/projects/Company/${user.id}`
      )
      .then((res: any) => {
        setProjectsCsv(res.data.projects);
      });
  }, [user.id]);

  useEffect(() => {
    dispatch(ConsultorData(user.id) as any);
    dispatch(getPostedProjects() as any);
    axios
      .get(
        "https://findally-backend.herokuapp.com/api/user/projects/Company/" +
        user.id +
        `?skip=${page.currentPage}`
      )
      .then((e: any) => {
        setUserProjects(e.data.projects);
        //order by aplicantes length
        setStatistics(
          e.data?.projects?.map((e: any) => {
            return {
              nombre:
                e.nombre && e.nombre !== "Unnamed Oportunity"
                  ? e.nombre
                  : e.nombreProyecto &&
                    e.nombreProyecto !== "Unnamed Oportunity"
                    ? e.nombreProyecto
                    : e.NombreOportunidad &&
                      e.NombreOportunidad !== "Unnamed Oportunity"
                      ? e.NombreOportunidad
                      : "",
              aplicantes: e.aplicantes.length ? e.aplicantes.length : 0,
            };
          })
        );
        setPage({ ...page, totalPage: e.data.totalPag });
      })
      .finally(() => setLoading(false));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, user.id]);

  const handlePageClick = (evento: any) => {
    setUserProjects([]);
    axios
      .get(
        "https://findally-backend.herokuapp.com/api/user/projects/Company/" +
        user.id +
        `?skip=${evento.selected}`
      )
      .then((el: any) => {
        setUserProjects(el.data.projects);
        setPage({
          ...page,
          currentPage: page.currentPage + 1,
          totalPage: el.data.totalPage,
        });
      });
  };

  const headers = [
    { label: "Nombre", key: "NombreOportunidad" },
    { label: "Estado", key: "status" },
    { label: "Tipo de servicio deseado", key: "tipoDeServicioDeseado" },
    { label: "Modalidad de contratación", key: "modalidadDeContratacion " },
    { label: "Anonimo", key: "Anonimo" },
    { label: "Profesionales", key: "profesionales" },
    { label: "Carrera", key: "SeleccionaCarrera" },
    { label: "Duración", key: "estimadoDuración" },
    { label: "Ciudad", key: "ciudad" },
    { label: "Fecha de publicación", key: "fecha_inicio" },
    { label: "Nivel de inglés", key: "nivel_ingles" },
    { label: "Presupuesto", key: "presupuesto" },
    { label: "Descripcion", key: "DescribeProyecto" },
    { label: "Area de conocimiento", key: "empleos_alternativos" },
    { label: "Habilidades", key: "habilidadesTecnicas" },
    { label: "Habilidades blandas", key: "habilidadesBlandas" },
    { label: "Responsabilidades", key: "responsabilidadYfunciones" },
  ];

  const csvReport = {
    filename: "Oportunidades.csv",
    headers: headers,
    data: projectsCsv,
  };

  useEffect(() => {
    if (tourViewedCompany === true) {
      return;
    }
    setTimeout(() => {
      dispatch(tourStatus({ run: true }));
    }, 1500);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  return {
    projectsCsv,
    userProjects,
    statistics,
    page,
    setPage,
    loading,
    handlePageClick,
    lang,
    csvReport,
  };
};
