// @ts-nocheck
// eslint-disable
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import { ReduxStateInterface } from "../../../../types/reduxInterface";
import { getUserProjects } from "../../../../../actions";

export interface Project {
  _id: string;
  DescribeProyecto?: string;
  descripcionEmpresa?: string;
  experienciaAnos?: number;
  habilidadesTecnicas?: string[];
  habilidadesBlandas?: string[];
  Lenguaje?: string[];
  presupuesto?: number;
  listadoBeneficios?: string[];
  modalidadDeContratacion?: string;
  modalidadDeTrabajo?: string;
  fit?: number;
  municipio?: string;
  habilidadesRequeridas?: string[];
  usuario?: any;
  imageUrl?: string;
  NombreOportunidad?: string;
  nombre?: string;
  NombreEmpresa?: string;
  responsabilidadYfunciones?: string;
  fecha_inicio?: string;
  aplicantes?: any;
  descripcionComision?: any;
  descripcionBeneficios?: any;
}

interface Page {
  currentPage: number;
  totalPage: number;
}

interface Cargo {
  nombrecargo?: string;
  duracion?: string;
  ubicacion?: string;
  descripcion?: string;
}

interface Education {
  Titulo_Certificacion: string;
}

interface Language {
  Language: string;
}

interface CV {
  _id: string;
  extracto?: string;
  experiencia?: {
    cargos?: Cargo[];
  }[];
  educacion?: Education[];
  aptitudes_principales?: string[];
  languages?: Language[];
  aspiracion_salarial?: number;
}

interface EmpleoData {
  ID: string;
  Descripcion: string;
  Descripcion_empresa: string;
  Experiencia: string;
  Habilidades_tecnicas: string;
  Habilidades_Blandas: string;
  Idiomas: string;
  Salario: number;
  Beneficios: string;
  Tipo_de_empleo: string;
  Modalidad: string;
}

interface AplicantesData {
  ID: string;
  Descripcion: string;
  Experiencia: Cargo[];
  Estudios: string;
  Habilidades_tecnicas: string;
  Habilidades_Blandas: string;
  Logros: string;
  Trabajo_Social: string;
  Universidad: string;
  Idiomas: string;
  Salario: number;
  Tipo_de_empleo: string;
  Modalidad: string;
  Puntaje_hr: number;
}

interface PostData {
  empleo: EmpleoData;
  aplicantes: AplicantesData[];
}

interface UseCustomHookReturn {
  ready?: boolean;
  loading?: boolean;
  projects?: Project[];
  selectedOportunity?: Project | null;
  handlePageClick?: (selected: { selected: number }) => void;
  handleClickOportunity?: (oportunity: Project) => void;
  filtro?: string;
  setfiltro?: React.Dispatch<React.SetStateAction<string>>;
  searching?: boolean;
  handleKeyPress?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  handleOnClickSearch?: () => void;
  lang?: string;
  width?: number;
  page?: Page;
  setaplicable?: React.Dispatch<React.SetStateAction<boolean>>;
  aplicable?: boolean;
  setRefresh?: React.Dispatch<React.SetStateAction<boolean>>;
  countrySelected?: string;
}

export const useOpportunitiesData = (): UseCustomHookReturn => {
  const { lang, user, tourViewed, cv } = useSelector(
    (state: ReduxStateInterface) => state
  );
  const projectsUser = useSelector(
    (state: ReduxStateInterface) => state.userProjects
  );
  const [ready, setReady] = useState(false);
  const [loading, setLoading] = useState(false);
  const [aplicable, setaplicable] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [projects, setProjects] = useState([]);
  const [selectedOportunity, setSelectedOportunity] = useState(null);
  const [width, setWidth] = useState(window.innerWidth);
  const [height, setHeight] = useState(window.innerHeight);
  const [filtro, setfiltro] = useState("");
  const [searching, setSearching] = useState(false);
  const cancel = new AbortController();
  const [page, setPage] = useState({
    currentPage: 0,
    totalPage: 0,
  });
  const [countrySelected, setCountrySelected] = useState("");
  const dispatch = useDispatch();

  const updateWidthAndHeight = () => {
    setWidth(window.innerWidth);
    setHeight(window.innerHeight);
  };



  useEffect(() => {
    window.addEventListener("resize", updateWidthAndHeight);
    return () => window.removeEventListener("resize", updateWidthAndHeight);
  }, [width, height]);

  useEffect(() => {
    const currentQuery = new URLSearchParams(window.location.search);
    const countryFilter = currentQuery.get("country") || null;
    setCountrySelected(countryFilter);
    // eslint-disable-next-line
  }, [window.location.search]);

  useEffect(() => {
    if (countrySelected) {
      setLoading(true);
      axios
        .post(
          `https://findally-backend.herokuapp.com/api/university/oportunities`,
          {
            name: "schneider",
          }
        )
        .then((r) => {
          setProjects(
            r.data.elements.filter(
              (e: any) => e.country.toLowerCase() === countrySelected
            )
          );
          setSelectedOportunity(r.data.elements[0] ? r.data.elements[0] : null);
          if (cv && r.data.elements[0]) {
            const empleoData = {
              ID: r.data.elements[0]._id,
              Descripcion: r.data.elements[0]?.DescribeProyecto || "",
              Descripcion_empresa: r.data.elements[0]?.descripcionEmpresa || "",
              Experiencia: r.data.elements[0]?.experienciaAnos
                ? `${r.data.elements[0].experienciaAnos} años`
                : "",
              Habilidades_tecnicas:
                r.data.elements[0]?.habilidadesTecnicas?.join(", ") || "",
              Habilidades_Blandas:
                r.data.elements[0]?.habilidadesBlandas?.join(", ") || "",
              Idiomas: r.data.elements[0]?.Lenguaje?.join(", ") || "",
              Salario: r.data.elements[0]?.presupuesto || "",
              Beneficios:
                r.data.elements[0]?.listadoBeneficios?.join(", ") || "",
              Tipo_de_empleo: r.data.elements[0]?.modalidadDeContratacion || "",
              Modalidad: r.data.elements[0]?.modalidadDeTrabajo || "",
            };
            const aplicantesData = {
              ID: cv._id,
              Descripcion: cv.extracto || "",
              Experiencia:
                cv.experiencia?.flatMap((exp) => {
                  return (
                    exp.cargos?.map((cargo) => {
                      return {
                        nombre_cargo: cargo.nombrecargo
                          ? cargo.nombrecargo
                          : "",
                        duracion: cargo.duracion ? cargo.duracion : "",
                        ubicacion: cargo.ubicacion ? cargo.ubicacion : "",
                        descripcion: cargo.descripcion ? cargo.descripcion : "",
                      };
                    }) || []
                  );
                }) || [],
              Estudios:
                cv.educacion
                  ?.map((edu) => edu.Titulo_Certificacion)
                  .join(", ") || "",
              Habilidades_tecnicas: cv.aptitudes_principales?.join(", ") || "",
              Habilidades_Blandas: "",
              Logros: "",
              Trabajo_Social: "",
              Universidad: "",
              Idiomas:
                cv.languages?.map((idioma) => idioma.Language).join(", ") || "",
              Salario: cv.aspiracion_salarial || "",
              Tipo_de_empleo: "",
              Modalidad: "",
              Puntaje_hr: 0,
            };
            const postData = {
              empleo: empleoData,
              aplicantes: [aplicantesData],
            };
            axios
              .post(
                "https://resume-matching-deploy.herokuapp.com/aplicantes-empleo",
                postData,
                { timeout: 3 * 60 * 1000 }
              )
              .then((res) => {
                const { fit1 } = res.data.response;
                setSelectedOportunity({ ...r.data.elements[0], fit: fit1 });
              })
              .catch((err) => {
                console.log(err);
              });
          }
          setLoading(false);
        })
        .catch((r) => {
          if (r.name === "AbortError") return console.log("fetch aborted");
          setLoading(false);
        });
      return () => {
        cancel.abort();
      };
    }
    // eslint-disable-next-line
  }, [countrySelected]);

  useEffect(() => {
    if (!countrySelected) {
      setLoading(true);
      axios
        .post(
          `https://findally-backend.herokuapp.com/api/university/oportunities?skip=${page.currentPage}&limit=10`,
          {
            name: "schneider",
          }
        )
        .then((r) => {
          setProjects(r.data.elements);
          setSelectedOportunity(r.data.elements[0] ? r.data.elements[0] : null);
          if (cv && r.data.elements[0]) {
            const empleoData = {
              ID: r.data.elements[0]._id,
              Descripcion: r.data.elements[0]?.DescribeProyecto || "",
              Descripcion_empresa: r.data.elements[0]?.descripcionEmpresa || "",
              Experiencia: r.data.elements[0]?.experienciaAnos
                ? `${r.data.elements[0].experienciaAnos} años`
                : "",
              Habilidades_tecnicas:
                r.data.elements[0]?.habilidadesTecnicas?.join(", ") || "",
              Habilidades_Blandas:
                r.data.elements[0]?.habilidadesBlandas?.join(", ") || "",
              Idiomas: r.data.elements[0]?.Lenguaje?.join(", ") || "",
              Salario: r.data.elements[0]?.presupuesto || "",
              Beneficios:
                r.data.elements[0]?.listadoBeneficios?.join(", ") || "",
              Tipo_de_empleo: r.data.elements[0]?.modalidadDeContratacion || "",
              Modalidad: r.data.elements[0]?.modalidadDeTrabajo || "",
            };
            const aplicantesData = {
              ID: cv._id,
              Descripcion: cv.extracto || "",
              Experiencia:
                cv.experiencia?.flatMap((exp) => {
                  return (
                    exp.cargos?.map((cargo) => {
                      return {
                        nombre_cargo: cargo.nombrecargo
                          ? cargo.nombrecargo
                          : "",
                        duracion: cargo.duracion ? cargo.duracion : "",
                        ubicacion: cargo.ubicacion ? cargo.ubicacion : "",
                        descripcion: cargo.descripcion ? cargo.descripcion : "",
                      };
                    }) || []
                  );
                }) || [],
              Estudios:
                cv.educacion
                  ?.map((edu) => edu.Titulo_Certificacion)
                  .join(", ") || "",
              Habilidades_tecnicas: cv.aptitudes_principales?.join(", ") || "",
              Habilidades_Blandas: "",
              Logros: "",
              Trabajo_Social: "",
              Universidad: "",
              Idiomas:
                cv.languages?.map((idioma) => idioma.Language).join(", ") || "",
              Salario: cv.aspiracion_salarial || "",
              Tipo_de_empleo: "",
              Modalidad: "",
              Puntaje_hr: 0,
            };
            const postData = {
              empleo: empleoData,
              aplicantes: [aplicantesData],
            };
            axios
              .post(
                "https://resume-matching-deploy.herokuapp.com/aplicantes-empleo",
                postData,
                { timeout: 3 * 60 * 1000 }
              )
              .then((res) => {
                const { fit1 } = res.data.response;
                setSelectedOportunity({ ...r.data.elements[0], fit: fit1 });
              })
              .catch((err) => {
                console.log(err);
              });
          }
          setLoading(false);
        })
        .catch((r) => {
          if (r.name === "AbortError") return console.log("fetch aborted");
          setLoading(false);
        });
      return () => {
        cancel.abort();
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cv, countrySelected]);

  useEffect(() => {
    axios
      .post(
        `https://findally-backend.herokuapp.com/api/university/oportunities`,
        {
          name: "schneider",
        }
      )
      .then((r) => {
        setPage({ totalPage: Math.ceil(r.data?.elements?.length / 10) });
      })
      .catch((r) => {
        if (r.name === "AbortError") return console.log("fetch aborted");
        console.log(r);
      });
    return () => {
      cancel.abort();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page.totalPage]);

  useEffect(() => {
    // eslint-disable-next-line
    dispatch(getUserProjects(user.id));
    return () => {};
    // eslint-disable-next-line
  }, [user.id, refresh]);

  useEffect(() => {
if (projectsUser?.length >= 0 && selectedOportunity?._id) {
  const isProjectPresent = projectsUser.some(project => project._id === selectedOportunity._id);
  if (isProjectPresent && !aplicable) {
    setaplicable(true);
  } else if (!isProjectPresent && aplicable) {
    setaplicable(false);
  }
  setReady(true);
} else {
  setReady(true);
}

    // eslint-disable-next-line
  }, [selectedOportunity?._id, projectsUser]);

  useEffect(() => {
    if (user?.id) {
      axios
        .get("https://findally-backend.herokuapp.com/user/" + user.id, {
          signal: cancel.signal,
        })
        .then((respuesta) => {
          if (respuesta.data.cv_file) {
            setaplicable(true);
          }
        })
        .catch((r) => {
          if (r.name === "AbortError") return console.log("fetch aborted");
        });
    }
    return () => {
      cancel.abort();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user.id]);

  const handlePageClick = (e: any) => {
    setProjects([]);
    setSelectedOportunity(null);
    axios
      .post(
        `https://findally-backend.herokuapp.com/api/university/oportunities?skip=${e.selected}&limit=10`,
        {
          name: "schneider",
        }
      )
      .then((r) => {
        setProjects(r.data.elements);
        // @ts-ignore
        setSelectedOportunity(r.data.elements[0] ? r.data.elements[0] : null);
        if (cv && r.data.elements[0]) {
          const empleoData = {
            ID: r.data.elements[0]._id,
            Descripcion: r.data.elements[0]?.DescribeProyecto || "",
            Descripcion_empresa: r.data.elements[0]?.descripcionEmpresa || "",
            Experiencia: r.data.elements[0]?.experienciaAnos
              ? `${r.data.elements[0].experienciaAnos} años`
              : "",
            Habilidades_tecnicas:
              r.data.elements[0]?.habilidadesTecnicas?.join(", ") || "",
            Habilidades_Blandas:
              r.data.elements[0]?.habilidadesBlandas?.join(", ") || "",
            Idiomas: r.data.elements[0]?.Lenguaje?.join(", ") || "",
            Salario: r.data.elements[0]?.presupuesto || "",
            Beneficios: r.data.elements[0]?.listadoBeneficios?.join(", ") || "",
            Tipo_de_empleo: r.data.elements[0]?.modalidadDeContratacion || "",
            Modalidad: r.data.elements[0]?.modalidadDeTrabajo || "",
          };
          const aplicantesData = {
            ID: cv._id,
            Descripcion: cv.extracto || "",
            Experiencia:
              cv.experiencia?.flatMap((exp) => {
                return (
                  exp.cargos?.map((cargo) => {
                    return {
                      nombre_cargo: cargo.nombrecargo ? cargo.nombrecargo : "",
                      duracion: cargo.duracion ? cargo.duracion : "",
                      ubicacion: cargo.ubicacion ? cargo.ubicacion : "",
                      descripcion: cargo.descripcion ? cargo.descripcion : "",
                    };
                  }) || []
                );
              }) || [],
            Estudios:
              cv.educacion?.map((edu) => edu.Titulo_Certificacion).join(", ") ||
              "",
            Habilidades_tecnicas: cv.aptitudes_principales?.join(", ") || "",
            Habilidades_Blandas: "",
            Logros: "",
            Trabajo_Social: "",
            Universidad: "",
            Idiomas:
              cv.languages?.map((idioma) => idioma.Language).join(", ") || "",
            Salario: cv.aspiracion_salarial || "",
            Tipo_de_empleo: "",
            Modalidad: "",
            Puntaje_hr: 0,
          };
          const postData = { empleo: empleoData, aplicantes: [aplicantesData] };
          axios
            .post(
              "https://resume-matching-deploy.herokuapp.com/aplicantes-empleo",
              postData,
              { timeout: 3 * 60 * 1000 }
            )
            .then((res) => {
              const { fit1 } = res.data.response;
              setSelectedOportunity({ ...r.data.elements[0], fit: fit1 });
            })
            .catch((err) => {
              console.log(err);
            });
          return setPage({
            ...page,
            currentPage: page.currentPage + 1,
          });
        }
        setPage({
          ...page,
          currentPage: page.currentPage + 1,
        });
      });
  };

  const handleClickOportunity = (oportunity) => {
    if (cv) {
      setSelectedOportunity(oportunity);
      const empleoData = {
        ID: oportunity._id,
        Descripcion: oportunity?.DescribeProyecto || "",
        Descripcion_empresa: oportunity?.descripcionEmpresa || "",
        Experiencia: oportunity?.experienciaAnos
          ? `${oportunity.experienciaAnos} años`
          : "",
        Habilidades_tecnicas: oportunity?.habilidadesTecnicas?.join(", ") || "",
        Habilidades_Blandas: oportunity?.habilidadesBlandas?.join(", ") || "",
        Idiomas: oportunity?.Lenguaje?.join(", ") || "",
        Salario: oportunity?.presupuesto || "",
        Beneficios: oportunity?.listadoBeneficios?.join(", ") || "",
        Tipo_de_empleo: oportunity?.modalidadDeContratacion || "",
        Modalidad: oportunity?.modalidadDeTrabajo || "",
      };
      const aplicantesData = {
        ID: cv._id,
        Descripcion: cv.extracto || "",
        Experiencia:
          cv.experiencia?.flatMap((exp) => {
            return (
              exp.cargos?.map((cargo) => {
                return {
                  nombre_cargo: cargo.nombrecargo ? cargo.nombrecargo : "",
                  duracion: cargo.duracion ? cargo.duracion : "",
                  ubicacion: cargo.ubicacion ? cargo.ubicacion : "",
                  descripcion: cargo.descripcion ? cargo.descripcion : "",
                };
              }) || []
            );
          }) || [],
        Estudios:
          cv.educacion?.map((edu) => edu.Titulo_Certificacion).join(", ") || "",
        Habilidades_tecnicas: cv.aptitudes_principales?.join(", ") || "",
        Habilidades_Blandas: "",
        Logros: "",
        Trabajo_Social: "",
        Universidad: "",
        Idiomas:
          cv.languages?.map((idioma) => idioma.Language).join(", ") || "",
        Salario: cv.aspiracion_salarial || "",
        Tipo_de_empleo: "",
        Modalidad: "",
        Puntaje_hr: 0,
      };
      const postData = { empleo: empleoData, aplicantes: [aplicantesData] };
      axios
        .post(
          "https://resume-matching-deploy.herokuapp.com/aplicantes-empleo",
          postData,
          { timeout: 3 * 60 * 1000 }
        )
        .then((res) => {
          const { fit1 } = res.data.response;
          setSelectedOportunity({ ...oportunity, fit: fit1 });
        })
        .catch((err) => {
          console.log(err);
        });
      return;
    }
    setSelectedOportunity(oportunity);
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      if (filtro?.trim() === "") {
        return axios
          .post(
            `https://findally-backend.herokuapp.com/api/university/oportunities?skip=${page.currentPage}&limit=5`,
            {
              name: "schneider",
            }
          )
          .then((res) => {
            setProjects(res.data.elements);
            setSelectedOportunity(
              res.data.elements[0] ? res.data.elements[0] : null
            );
          })
          .catch((err) => {
            console.log(err);
          });
      }
      setSearching(true);
      axios
        .post(`https://findally-backend.herokuapp.com/api/Project/text`, {
          text: filtro,
        })
        .then((res) => {
          setProjects(res.data);
          setSelectedOportunity(res.data[0] ? res.data[0] : null);
          setSearching(false);
        })
        .catch((err) => {
          setSearching(false);
          console.log(err);
        });
    }
  };

  const handleOnClickSearch = () => {
    if (filtro?.trim() === "") {
      return axios
        .post(
          `https://findally-backend.herokuapp.com/api/university/oportunities?skip=${page.currentPage}&limit=5`,
          {
            name: "schneider",
          }
        )
        .then((res) => {
          setProjects(res.data.elements);
          setSelectedOportunity(
            res.data.elements[0] ? res.data.elements[0] : null
          );
        })
        .catch((err) => {
          console.log(err);
        });
    }
    setSearching(true);
    axios
      .post(`https://findally-backend.herokuapp.com/api/Project/text`, {
        text: filtro,
      })
      .then((res) => {
        setProjects(res.data);
        setSelectedOportunity(res.data[0] ? res.data[0] : null);
        setSearching(false);
      })
      .catch((err) => {
        setSearching(false);
        console.log(err);
      });
  };

  return {
    ready,
    loading,
    user,
    aplicable,
    projects,
    selectedOportunity,
    filtro,
    searching,
    width,
    height,
    page,
    setaplicable,
    setfiltro,
    handlePageClick,
    handleClickOportunity,
    handleKeyPress,
    handleOnClickSearch,
    tourViewed,
    lang,
    setRefresh,
    countrySelected,
  };
};
