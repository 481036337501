import React, { useContext, useEffect, useState } from "react";
// @ts-ignore
import Select from "react-select";
import { useHistory } from "react-router-dom";
import { SchneiderContext } from "../../../../context/SchneiderContext";

interface Country {
  value: string;
  label: string;
}

const FiltersOpportunities: React.FC = () => {
  const countries: Country[] = [
    { value: "colombia", label: "Colombia" },
    { value: "ecuador", label: "Ecuador" },
    { value: "perú", label: "Perú" },
  ];

  const { schneider } = useContext(SchneiderContext);

  const customStyles = {
    container: (provided: any, state: any) => ({
      ...provided,
      borderRadius: "8px",
      border: state.hasValue
        ? `1px solid ${schneider.color.primary}`
        : "1px solid #E5E5E5",
      width: "180px",
      marginInline: "5px",
      textTransform: "capitalize",
    }),
    control: (provided: any) => ({
      ...provided,
      backgroundColor: "white",
    }),
    option: (provided: any, state: any) => ({
      ...provided,
      backgroundColor: state.isSelected ? schneider.color.primary : "white",
      color: state.isSelected ? "white" : schneider.color.primary,
      ":hover": {
        backgroundColor: schneider.color.primary,
        color: "white",
      },
    }),
    indicatorSeparator: (provided: any) => ({
      ...provided,
      backgroundColor: schneider.color.primary,
    }),
    dropdownIndicator: (provided: any) => ({
      ...provided,
      color: schneider.color.primary,
    }),
    clearIndicator: (provided: any) => ({
      ...provided,
      color: schneider.color.primary,
    }),
  };

  const [selectedOption, setSelectedOption] = useState<Country>(null);
  const history = useHistory();

  const handleChange = (option: Country) => {
    setSelectedOption(option);
    const selectedValue = option ? option.value : "";
    if (selectedValue) {
      history.push(`?country=${selectedValue}`);
    } else {
      history.push("/schneider/projects");
    }
  };

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const country = urlParams.get("country");
    setSelectedOption(country ? { value: country, label: country } : null);
  }, []);

  return (
    <>
      <Select
        styles={customStyles}
        options={countries}
        onChange={(option: any) => handleChange(option)}
        value={selectedOption}
        isClearable
      />
    </>
  );
};

export default FiltersOpportunities;
