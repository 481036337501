import React from 'react'
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import CloseIcon from '@mui/icons-material/Close';

export default function PopupCreator(props) {
    const { open, handleClose, children } = props;

    const style = {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: 500,
      height: 450,
      bgcolor: '#FFFFFF',
      border: '2px solid #000',
      borderRadius: '8px',
      boxShadow: 24,
      overflow: 'auto'
    };

    const body = (
        <Box sx={style}>
            <div style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100%',
                    flexDirection: 'column'
                }}>
                <div>
                    <CloseIcon onClick={()=>{handleClose()}} style={{ cursor: 'pointer', position: 'absolute', right: 20, top: 20 }} />
                </div>
                <div>
            {children}
            </div>
            </div>
        </Box>
    );

    return (
        <div>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >           
                  {body}  
            </Modal>
        </div>
    );
}