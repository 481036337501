import React from "react";
import './unileverMentorCardHome.css';
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

export default function UnileverMentorCardHomeGrid() {
  const lang = useSelector((state) => state.lang);

  const names = [
    "John",
    "Paul",
    "George",
    "Ringo",
    "Pete",
    "Mike",
    "Dave",
    "Mick",
    "Keith",
    "Charlie",
    "Bill",
    "Bob",
    "Bruce",
    "Eric",
    "Jimi",
    "Jimmy",
  ]
  const lastNames = [
    "Lennon",
    "McCartney",
    "Harrison",
    "Starr",
    "Townshend",
    "Love",
    "Gilmour",
    "Jagger",
    "Richards",
    "Watts",
    "Wyman",
    "Dylan",
    "Springsteen",
    "Clapton",
    "Hendrix",
    "Page",
  ]

  const profession = [
    "Developer",
    "Designer",
    "Engineer",
    "Architect",
    "Manager",
    "Analyst",
    "Consultant",
    "Scientist",
    "Technician",
    "Technologist",
    "Programmer",
    "Architect",
    "Artist",
    "Writer",
    "Director",
    "Producer",
  ]

  const randomProfession = () => {
    return profession[Math.floor(Math.random() * profession.length)]
  }

  const randomName = () => {
    return names[Math.floor(Math.random() * names.length)] + " " + lastNames[Math.floor(Math.random() * lastNames.length)]
  }

  return (
    <div className={'Container-square-home-profesional-unilever'}>
          <div className={'NewCardOportunidad-Top-Container'}>
            <div style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '30%',
            }}>
              <img className={'image-profesional-home-unilever'} src={'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png'} alt={'profile broken'} />
            </div>
            <div style={{
              display: 'flex',
              justifyContent: 'center',
              flexDirection: 'column',
              alignItems: 'center',
              width: '100%',
            }}>
              <h1 style={{
                fontSize: '12px',
                fontWeight: '400',
                color: '#F07130',
                textAlign: 'left',
                width: '100%',
              }}>
                {lang === 'es' ? '75% Compatibilidad' : '75% Compatibility'}
              </h1>
                  <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    alignItems: 'flex-start',
                    width: '100%',
                    textAlign: 'center',
                  }}>
                    <div style={{
                      display: 'flex',
                      justifyContent: 'flex-start',
                      alignItems: 'center',
                      width: '100%',
                      height: '100%',
                      color: '#393939',
                      fontSize: '18px',
                      fontWeight: '700',
                    }}>
                      {randomName()}
                    </div>
                  </div>
            </div>
          </div>
          <div className="card-home-unilever-profesional-mid">
            <div className="NewCardOportunidad-mid-Container-inner-2">
              
              {randomProfession()}
            </div>
            <div className="card-home-unilever-profesional-mid-section-3">
              <div className="NewCardOportunidad-mid-Container-inner-3-1">
                <p style={{
                  fontSize: '14px',
                  fontWeight: '400',
                  color: '#5D5D5D',
                }}>Ver más</p>
              </div>
              <div className="NewCardOportunidad-mid-Container-inner-3-2">
                {
                    <Link to={`/unilever/mentor/${randomName()}`}>
                      <button
                        className={'CardOportunidadProfesional-Button-new'}>
                        {lang === 'es' ? 'Seleccionar' : 'Select'}
                      </button>
                    </Link>
                }
              </div>
            </div>
          </div>
    </div>
  )
}