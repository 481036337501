import React from 'react'
import './CardCantidad.css'

const CardCantidad = ({ title, value, Icon, color }) => { 
    return (
        <div className='card-info-Cantidad'
            style={{ background: color }}>
            <div className='card-info-Cantidad-Icon'>
                <Icon sx={{ fontSize: '65px' }} />
            </div>
            <div className='card-info-Cantidad-container'>
                <div>
                    <h1 className='card-info-Cantidad-title'>
                        {title}
                    </h1>
                </div>
                <div className='card-info-Cantidad-container-2'>
                    <h1 className='card-info-Cantidad-value'>
                        {value}
                    </h1>
                </div>

            </div>
        </div>
    )
}

export default CardCantidad