import React, {useState} from "react";
import { Link } from "react-router-dom";
// import { Icon } from 'react-icons-kit'
// import {pie_chart} from 'react-icons-kit/ikons/pie_chart'
import {   logoutUser, changeview,changeLang } from "../../actions";
import { connect, useDispatch } from "react-redux";
import logo from "../../assets/logos/logo_coally_plf.jpeg";
import PersonOutlineIcon from '@material-ui/icons/PersonOutline';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
// import PhoneIcon from '@material-ui/icons/Phone';
import en from "../../lang/en-US";
import es from "../../lang/es-COL";
import counterpart from "counterpart";
import LanguageIcon from '@material-ui/icons/Language';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import AssignmentIcon from '@material-ui/icons/Assignment';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
/* import GroupIcon from '@material-ui/icons/Group'; */
import Translate from "react-translate-component";
import PolicyIcon from '@material-ui/icons/Policy';

import "./HeaderEmpresa.css";

const HeaderEmpresa = (props) => {
  
  const handleLogout = () => {
    document.cookie =
    "ActualProject=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/";
  document.cookie =
    "ActualProjec=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/";
  document.cookie = "Aemail=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/";
  document.cookie = "Aname=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/";
  document.cookie = "Aid=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/";
  document.cookie = "Atoken=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/";
  document.cookie =
    "Aprojects=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/";
  document.cookie = "Aform=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/";
  document.cookie = "Aroles=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/";
  document.cookie = "Methodology=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/";
  document.cookie = "singleEtapa=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/";
  document.cookie = "ActualProject=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/";
  document.cookie = "casos_exito=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/";
  document.cookie = "signature=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/";
  document.cookie = "AprojectsConsultor=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/";
  document.cookie = "tickets=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/";
  document.cookie = "certificados=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/";
  document.cookie = "kps=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/";
  document.cookie = "nda=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/";
  
    
    props.logoutUser({}, '/');
  };

  // const [showTitleProyectos, setShowTitleProyectos] = useState(false)
  if(!localStorage.getItem('langlocal')){
    localStorage.setItem('langlocal','es')
  }
  const dispatch = useDispatch()
  const [locallang,setLocallang]=useState(localStorage.getItem('langlocal'))
  const [color, setColor] = useState(localStorage.getItem('langlocal'))
  const cambiarlanges=(camlang)=>{    
    localStorage.setItem('langlocal',camlang)
    setLocallang(localStorage.getItem('langlocal'))
    dispatch(changeLang(camlang))
    setColor(camlang)
  }
  counterpart.registerTranslations('en',en) 
  counterpart.registerTranslations('es',es)  
  counterpart.setLocale(localStorage.getItem('langlocal'));
  return (
    <>
 {locallang?null:null}
          <div style={{display:'flex',background:'white',paddingLeft:'15.3%',width:'100%'}}>

    <div className="grap" style={{display:'flex',justifyContent:'space-between',padding:'10px',paddingTop:'20px',background:'#ffffff',width:'78%'}}>
      {/* logo */}
      <div className="header-logo">
        <Link to="/company/home">
          <img
            src={logo}
            alt="logo img"
            className="header-logo"
            width="155px"
            height="40px"
            />
        </Link>
      </div>
      {/*header navigation */}

      <div className="header__icons-group" style={{width:'70%'}}>
        <ul className="header__icons__group-ul" style={{justifyContent:'space-evenly',position:'relative',alignItems:'center'}}>       
          {/*section hidden  
           <li className="header__icons__group-li">
           <Link to="/consultor/education">
           <img
           src=""
           alt="Education"
           className="header__icons__group-icon"
           />
           </Link>
           </li>
          */}
          <li className="header__icons__group-li">
            <Link to="/company/project">
            <div 
            // onMouseOver={() => setShowTitleProyectos(true)} onMouseLeave={() => setShowTitleProyectos(false)} 
            style={{color: '#212156', textAlign:'center', marginTop:'3px',cursor:'pointer'}}>{window.location.href.includes("project")?<Translate className="educacion-header-selecionado  semiboldfont" content="Proyectos" component="span" />:<Translate className="educacion-header  semiboldfont" content="Proyectos" component="span" />}</div>
              {/* {showTitleProyectos? <p className="tittle-menu" style={{marginTop:'10px', marginLeft:'-35px', position:'absolute', zIndex:'400', backgroundColor:'rgba(82, 82, 82, 0.671)', borderRadius:'5px', color:'white', paddingRight:'10px',  paddingLeft:'10px', cursor:'default'}}>{locallang?'Proyectos':'Proyectos'}</p>:null} */}
            </Link>
          </li>
          <li className="header__icons__group-li">
            <Link to="/company/new/project">
            <div className="button-menu-side">
              <button>{<Translate className="semiboldfont" content="CREARPROYECTO" component="span"/>}</button>
            </div>
            </Link>
          </li>
          {/*section hidden 
          <li className="header__icons__group-li">
          <img
          src=""
          alt="Notifications" 
          className="header__icons__group-icon"
          />
          <ul className="header__display-menu">
          <li className="header__display__menu-item">signin</li>
          <li className="header__display__menu-item">Hola user</li>
          </ul>
          </li>
        */}
        <div style={{display:'flex',alignItems:'center'}}>

        
          <li className="header__icons__group-li">
          <PersonOutlineIcon style={{ color: '#212156',fontSize: '30px' }} />
            <ul className="header__display-menu">
              <li className="header__display__menu-perfil-ver-perfil">
              <AccountCircleIcon style={{ color: '#212156' ,fontSize: '65px'}} />
                <div className="header__display__menu__perfil-data">
                  {/*<p>Username</p>*/}
                  <p className="header-display-name-coallier">{<Translate content="Empresa" component="span" />}</p>
                
                </div>
                
              </li>
              <Link onClick={()=>props.changeview('coallier')} to="/consultor/home">
                <li className="header__display__menu-item_coallier">
              <PersonOutlineIcon style={{ color: '#212156' ,fontSize: '20px'}} />
              <p className="menu-text">Coallier</p>
                </li>
              </Link>
{/*               <Link onClick={()=>props.changeview('company')} to="/company/home">
                <li className="header__display__menu-item_company">
                <PersonOutlineIcon style={{ color: '#212156' ,fontSize: '20px'}} />
                <p className="menu-text">{<Translate content="Company" component="span" />}</p>
                </li>
              </Link> */}
{/*               <Link to="/company/coalliers">
                <li className="header__display__menu-item">
                <GroupIcon style={{ color: '#212156' ,fontSize: '20px'}} />
                <p className="menu-text">Coalliers</p>
                </li>
              </Link> */}
              {/* <Link to="/company/contact">
                <li className="header__display__menu-item">
                <PhoneIcon style={{ color: '#212156' ,fontSize: '20px'}} />
                <p className="menu-text">{<Translate content="Contáctanos" component="span" />}</p>
                </li>
              </Link> */}
              <Link to="/company/faq">
                <li className="header__display__menu-item">
              <HelpOutlineIcon style={{ color: '#212156' ,fontSize: '20px'}} />
              <p className="menu-text">{<Translate content="PreguntasFrecuentes" component="span" />}</p>
                </li>
              </Link>
              <Link to="/company/DataPolicy">
                <li className="header__display__menu-item">
              <PolicyIcon style={{ color: '#212156' ,fontSize: '20px'}} />
              <p className="menu-text">{<Translate content="Politicadeprivacidad" component="span" />}</p>
                </li>
              </Link>
              <Link to="/company/tyc">
                <li className="header__display__menu-item">
              <AssignmentIcon style={{ color: '#212156' ,fontSize: '20px'}} />
              <p className="menu-text">{<Translate content="Terminosycondiciones" component="span" />}</p>
                </li>
              </Link>
              <Link to="#" onClick={handleLogout}>
                <li className="header__display__menu-item">
                  {" "}
                  <ExitToAppIcon style={{ color: '#212156' ,fontSize: '20px'}}/>
                   <p className="menu-text">{<Translate content="Cerrarsesion" component="span" />}</p>
                </li>
              </Link>
              <p className="header__display_copy">© 2021 CoAlly | Powered by CoAlly</p>
            </ul>
          </li>
          <div  style={{marginRight:'50px', display: 'flex', flexDirection: 'row-reverse'}}>
              <div  > <button style={{background:'none', color: color === 'es' && 'orange'}} onClick={()=>cambiarlanges('es')}>ES</button> </div>
              <div  > <button style={{background:'none', color: color === 'en' && 'orange'}} onClick={()=>cambiarlanges('en')}>EN</button> </div>
              <div  > <button  style={{cursor:"default",background:'none',marginTop:'-1rem'}}><LanguageIcon></LanguageIcon></button> </div>
            </div>
</div>
        </ul>
      </div>
    </div>
</div>
    </>
  );
};
const mapDispatchToProps = {
  logoutUser,
  changeview,
  changeLang,
};
export default connect(null, mapDispatchToProps)(HeaderEmpresa);
