import React, { useEffect, useState } from "react";
import "./styles/Certificado.css";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { connect, useSelector } from "react-redux";
import {
  Methodology,
  getProject,
  getPhase,
  ConsultorData1,
  ConsultorData,
  getNewEtapas,
  getEtapas,
  savePdf,
  firmaCertificado,
  getSinglePhase,
  getCertificados,
  getProjectPhases,
  setEtapasNull,
} from "../../actions/index";

import { Img } from 'react-image'
import en from "../../lang/en-US";
import es from "../../lang/es-COL"
import counterpart from "counterpart";
import Translate from "react-translate-component";
import { useParams } from "react-router";
import axios from 'axios'



function CertificadoGenerator(props) {
  // const {
  //   match: { params },
  // } = props;
  const {id}=useParams()
  const getMethodology = () => {
    if (props.projectDetailsDone) {
      props.getProject(id);
    }
  }
  const [status, setStatusValue] = useState("show");
  
  const handdleStatusChange =(value)=>{
    if (value ==="show"){
      setStatusValue("done")
    }
    if(value==="done"){
      setStatusValue("show")
    }
  }
  const getlegal = () => {
    if (props.actual) {
      props.getCertificados(props.actual.legal[0])
    }
  }
  const proyectoactual=useSelector((state) => state.ActualProject)
const [documentos, setdocumentos] = useState('')
  const info=()=>{
    axios.get(`https://findally-backend.herokuapp.com/api/legal/${proyectoactual.legal[0]}`).then(response=>setdocumentos(response.data))
  }
  useEffect(() => {
    getlegal(); // eslint-disable-next-line
    if(proyectoactual===""){
      // window.history.back()
    }
    
    getMethodology();// eslint-disable-next-line
  }, []);
  console.log(documentos)
if(proyectoactual.legal && documentos===''){
  console.log('h')
      info()
    }
  let ref = React.createRef();

  const fecha = new Date();
  const año = fecha.getFullYear();
  const mes = fecha.getMonth() + 1;
  const dia = fecha.getDay()



  const printDocument = () => {
    const input = ref.current;
    html2canvas(input, {
      useCORS: true
    }).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      // const pdf = new jsPDF("l", "px");
    const pdf = new jsPDF("p", "mm", "a3");
      const width = pdf.internal.pageSize.getWidth();
      console.log(width);
      const height = pdf.internal.pageSize.getHeight();
      console.log(height );

      pdf.addImage(imgData, "JPEG", 0, 0, width, height);
      pdf.save("certificado_coally.pdf");
      const payload = {
        doc: imgData,
        proyecto: documentos.project._id,
        usuario: documentos.company_user._id,
        // proyecto: props.actual._id,
        // usuario: props.user.id,
      };
      props.firmaCertificado(payload);
    });
  };
console.log(props)
  counterpart.registerTranslations('en',en) 
  counterpart.registerTranslations('es',es)  
  counterpart.setLocale(localStorage.getItem('langlocal'));
  return (
    <>
      {/* <Header /> */}
      {documentos ? (
        <div>
          
          <div
            ref={ref}
            /* ref={docToPrint} */
            style={{
              borderRadius: "5px",/* 
              width: "780px", */
              marginLeft: "10%",
              marginRight: "10%",/* 
              height: "850px", */
              paddingTop: "5mm",
              paddingLeft: "10mm",
              paddingRight: "10mm",
              paddingBottom: "5mm",
            }}
          >
            <div className="certificate">

              <div className="certif-location-date">
                <p>BOGOTÁ D.C.</p>
                <p>{`${dia}-${mes}-${año}`}</p>
              </div>
              <div className="cert-rep-legal">
                <article>
                  <span className="cert-company">
                    {" "}
                    Andres Felipe Joya Mosquera{" "}
                  </span>{" "}
                  {<Translate content="RepresentanteLegalde" component="span"/>}{" "}
                  <p className="cert-company-name"> Coally S.A.S </p> NIT{" "}
                  <p className="cert-nit-company"> 123456789 </p>
                </article>
              </div>
              <div className="tittle-certificado">
                <h2 className="cert-tittle-sec">{<Translate content="CERTIFICA" component="span"/>}</h2>
              </div>
              <div className="cert-description">
                <article className="cert-company">
                  {<Translate content="Queelseñor" component="span"/>}{" "}
                  <span className="cert-company">
                    <strong>
                      {props.userform.nombre || "--"}
                    </strong>
                  </span>
                  {" "}{<Translate content="identificadoconcéduladeciudadaníaNo" component="span"/>}{" "}
                  <p className="cert-company">
                    <strong>
                      {props.userform.cedula || "--"}{" "}
                    </strong>
                  </p>{" "}
                  {<Translate content="expedidaen" component="span"/>}{" "}
                  <p className="cert-company">
                    {" "}
                    <strong>
                      {props.userform.cedula_exp || "--"}{" "}
                    </strong>
                  </p>{" "}
                  {<Translate content="desarrollóycompletódemaneraexitosalaconsultoriade" component="span"/>}{" "}
                  <p className="cert-company">
                    {" "}
                    <strong>
                      {documentos.project.nombre || "--"}{" "}
                    </strong>
                  </p>{" "}
                  {<Translate content="comopartedelastartupdeimpactoCoallyparala" component="span"/>} {" "}
                  <strong className="cert-company">
                    {" "}
                    {documentos.company_user.username || "--"}{" "}
                  </strong>{" "}
                  {<Translate content="atravésdeunamodalidaddeprestación" component="span"/>}{" "}
                  <strong className="cert-company">
                    {" "}
                    {documentos.project.nombre || "--"}{" "}
                  </strong>
                </article>

              </div>
              <div className="cert-tittle-sign">
                <h2 className="cert-firm">
                  {<Translate content="FIRMAREPRESENTANTELEGAL" component="span"/>}<span className="cert-company">{" "} Andres Felipe Joya Mosquera  {" "}</span>
                </h2>
              </div>
              <div
                className="cert-personal-data"
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <div>
                  <p>
                    {<Translate content="NOMBREYAPELLIDOS" component="span"/>}{" "}
                    <span className="cert-company">
                      <strong>{props.userform.nombre || "--"} </strong>{" "}
                    </span>
                  </p>
                  <p>
                    {<Translate content="CEDULA" component="span"/>}{" "}
                    <span className="cert-company">
                      <strong>{props.userform.cedula || "--"} </strong>{" "}
                    </span>
                  </p>
                  <p>
                    {<Translate content="DIRECCIÓN" component="span"/>}{" "}
                    <span className="cert-company">
                      <strong>{props.userform.direccion || "--"} </strong>{" "}
                    </span>
                  </p>
                  <p>
                    {<Translate content="EMAIL" component="span"/>}{" "}
                    <span className="cert-company">
                      <strong>{props.userform.email || "--"} </strong>{" "}
                    </span>
                  </p>
                  <p>
                    {<Translate content="TELEFONOO" component="span"/>}{" "}
                    <span className="cert-company">
                      <strong>{props.userform.telefono || "--"} </strong>{" "}
                    </span>
                  </p>
                </div>
                
                <div>
                  {
                    status === "done" ?
                    <Img  style={{ width: '300px', height:'100px'}}   src={props.user.signature} crossorigin="anonymous" /> 
                    : 
                    <button className="button-comments" style={{backgroundColor:'#d0d0d0',borderRadius:'7px',color:'white',padding:'6px',border:'solid 1px #d0d0d0'}} onClick={()=>handdleStatusChange(status)}>{<Translate content="Firmaar" component="span"/>}</button>

                  }
                  </div>
              <br></br>
                <br></br>
                <br></br>
                <br></br>
              </div>
            </div>
          </div>
          <div className="div-button-pdf-gen">
            <button className="doc-button-pdf" onClick={printDocument}>
              {<Translate content="DESCARGARDOCUMENTO" component="span"/>}
            </button>
          </div>
        </div>
      ) : (
        <div>
          <h1>{<Translate content="Cargandodatos" component="span"/>}</h1>
        </div>
      )}
    </>
  );
}

function mapStateToProps(state) {
  return {
    actual: state.ActualProject,
    user: state.user,
    documentos: state.documentos,
    methodology: state.methodology,
    Phases: state.Phases,
    company: state.company_form,
    userform: state.userform,
    Etapas: state.Etapas,
    etapasDone: state.etapasDone,
    projectDetailsDone: state.projectDetailsDone,
  };
}
const mapDispatchToProps = {
  Methodology,
  getProject,
  getPhase,
  savePdf,
  getEtapas,
  ConsultorData,
  firmaCertificado,
  ConsultorData1,
  getCertificados,
  getNewEtapas,
  getSinglePhase,
  getProjectPhases,
  setEtapasNull,
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CertificadoGenerator);