import React from 'react'
import './StepOportunidad.css'

export default function StepOportunidadPre({step, lang}) {
  return (
    <div className={'step-global-cont'}>

      <div className={'step-sub-cont'}>
        <div className={step >= 1 ? 'step-big-circle-active' : 'step-big-circle-inactive'}>
          <div className={step >= 1 ? 'step-inner-circle-active' : 'step-inner-circle-inactive'}>
          </div>
        </div>
        <h3 className={'title-active'}>{lang === 'es' ? 'Tipo de servicio' : 'Type of service'}</h3>
      </div>
      <div className={'step-mini-sub-cont'}>
        <div className={step >= 2 ? 'step-circle-active' : 'step-circle-inactive'}></div>
        <div className={step >= 3 ? 'step-circle-active' : 'step-circle-inactive'}></div>
        <div className={step >= 4 ? 'step-circle-active' : 'step-circle-inactive'}></div>
        <div className={step >= 5 ? 'step-circle-active' : 'step-circle-inactive'}></div>
        <div className={step >= 6 ? 'step-circle-active' : 'step-circle-inactive'}></div>
        </div>
        <div className={'step-sub-cont'}>
        <div className={step >= 7 ? 'step-big-circle-active' : 'step-big-circle-inactive'}>
          <div className={step >= 7 ? 'step-inner-circle-active' : 'step-inner-circle-inactive'}>
          </div>
        </div>
        <h3 className={'title-active'}>{lang === 'es' ? 'Requerimientos' : 'Requirements'}</h3>
      </div>
        <div className={'step-mini-sub-cont'}>
        <div className={step >= 8 ? 'step-circle-active' : 'step-circle-inactive'}></div>
        <div className={step >= 9 ? 'step-circle-active' : 'step-circle-inactive'}></div>
        <div className={step >= 10 ? 'step-circle-active' : 'step-circle-inactive'}></div>
        <div className={step >= 11 ? 'step-circle-active' : 'step-circle-inactive'}></div>
        <div className={step >= 11 ? 'step-circle-active' : 'step-circle-inactive'}></div>
      </div>
      <div className={'step-sub-cont'}>
        <div className={step >= 14 ? 'step-big-circle-active' : 'step-big-circle-inactive'}>
          <div className={step >= 14 ? 'step-inner-circle-active' : 'step-inner-circle-inactive'}>
          </div>
        </div>
        <h3 className={step >= 14 ? 'title-active' :'title-inactive'}>{lang === 'es' ? 'Pago' : 'Payment'}</h3>
      </div>

    </div>
  )
}