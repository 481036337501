/* eslint-disable */
import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import Headerprofesional from "../header/Headerprofesional";
import HeadertopV2 from "../headertop/HeadertopV2";
import './pricingempresav2.css';
import { tourStatus } from "../../actions";
import {useSelector, useDispatch} from "react-redux";
import ButtonP from "../../pages/Companies/ButtonP";
import CircleIcon from '@mui/icons-material/Circle';
import Sidebar from "../../components/Sidebar/Sidebar";
import UseTourCom from "../home/useTourCom";
import Navbar from "../../components/Navbar/Navbar";
//import bootstrap css

export default function PricingEmpresaV2() {
  const lang = useSelector((state) => state.lang);
  const user = useSelector((state) => state.user);
  const plan = useSelector((state) => state.userPlan);
  const tourViewedCompany = useSelector((state) => state.tourViewedCompany);
  const dispatch = useDispatch();

  const [width, setWidth] = useState(window.innerWidth);
  const [height, setHeight] = useState(window.innerHeight);
  const updateWidthAndHeight = () => {
    setWidth(window.innerWidth);
    setHeight(window.innerHeight);
  }
  useEffect(() => {
    window.addEventListener("resize", updateWidthAndHeight);
    return () => window.removeEventListener("resize", updateWidthAndHeight);
  }, [width, height]);

  useEffect(() => {
    if (tourViewedCompany) {
      return
    }
    setTimeout(() => {
      width > 600 ? dispatch(tourStatus({run: true, stepIndex: 4})) : dispatch(tourStatus({run: true, stepIndex: 3}))
    }, 500);
  }, [dispatch]);

  return (
    <div className="contenedor-pricing-empresa">
      <div className="contenedor-tour">
        <UseTourCom />
      </div>
      <div>
        <Sidebar/>
        <div className="BodyWeb"
             style={{
               padding: "0rem 2rem",
               position: "absolute",
               right: "0",
             }}>
          {/*<HeadertopV2/>*/}
          <Navbar locate={''}/>
          <h1 style={{
            margin: "0",
            fontSize: "40px",
            fontFamily: "Poppins",
            fontWeight: "700",
            color: "#202256",
            marginBottom: "27px",
          }}>
            {lang === "es" ? "¡Mejora tu cuenta!" : "Upgrade your account!"}
          </h1>
          <div className="generic_price_table">
            <section>
              <div className="container" id='pricing' style={{justifyContent: 'center', alignItems: 'center'}}>

                {/* <!--BLOCK ROW START--> */}
                <div className="row-pricing">
                  <div className="col-md-4">

                    {/* <!--PRICE CONTENT START--> */}
                    <div className="card_container_pricing" style={{width: '100%'}}>
                      <div className="card_pricing_content">
                        <div className="card_pricing_content_header">
                          <div className="card_price_tag">
                            <span className="card_title_tag">
                              Free
                            </span>
                          </div>
                        </div>
                        <div className="card_pricing_tag_title">
                          <h3 className="card_price_text">
                            $ 0.00
                          </h3>
                          <h3 className="card_price_text2">
                            /post
                          </h3>
                        </div>
                        <div className="card_pricing_content_body">
                          <div className="card_pricing_body_inner">
                          <span style={{marginRight: '5px'}}><CircleIcon sx={{
                            fontSize: 5,
                          }}/></span>
                            <span>
                              {lang === 'es' ? ' Límite 2 aplicantes.' : ' Limit 2 applicants.'}
                          </span>
                          </div>
                          <div className="card_pricing_body_inner">
                          <span style={{marginRight: '5px'}}><CircleIcon sx={{
                            fontSize: 5,
                          }}/></span>
                            <span>
                              {lang === 'es' ? ' Ahorra tiempo, ranking con inteligencia artifical.' : ' Save time, ranking with artificial intelligence.'}
                          </span>
                          </div>
                          <div className="card_pricing_body_inner">
                          <span style={{marginRight: '5px'}}><CircleIcon sx={{
                            fontSize: 5,
                          }}/></span>
                            <span>
                              {lang === 'es' ? ' Atrae candidatos de calidad y con fit.' : ' Attract quality candidates and with fit.'}
                          </span>
                          </div>
                          <div className="card_pricing_body_inner">
                          <span style={{marginRight: '5px'}}><CircleIcon sx={{
                            fontSize: 5,
                          }}/></span>
                            <span>
                              {lang === 'es' ? ' Soporte en publicación en diferentes plataformas.' : ' Support in publication on different platforms.'}
                          </span>
                          </div>

                        </div>
                        <div className="card_pricin_button_epayco1">
                          {plan?.nombre_paquete === 'Paquete Free'
                            ? <button style={{color: '#D6D6D6'}} disabled>
                              {lang === 'es' ? 'Plan actual' : 'Actual plan'}
                            </button>
                            : <button>
                              {lang === 'es' ? 'Adquirir' : 'Buy'}
                            </button>
                          }
                        </div>
                      </div>
                    </div>

                    {/* <!--//PRICE CONTENT END--> */}

                  </div>

                  <div className="col-md-4">

                    {/* <!--PRICE CONTENT START--> */}
                    <div className="card_container_pricing2" style={{width: '100%'}}>
                      <div className="card_pricing_content">
                        <div className="card_pricing_content_header">
                          <div className="card_price_tag">
                            <span className="card_title_tag">
                              Standard
                            </span>
                          </div>
                        </div>
                        <div className="card_pricing_tag_title2">
                          <div>
                            <h3 className="card_price_text">
                              $ 250 K
                            </h3>
                            <h3 className="card_price_text2">
                              /post
                            </h3>
                          </div>
                          <div>
                            <h3 className="card_price_text3">
                              {lang === 'es' ? '+ 4% al' : ' + 4% fee*'}
                            </h3>
                            <h3 className="card_price_text3">
                              {lang === 'es' ? 'contratar*' : ''}
                            </h3>
                          </div>
                        </div>
                        <div className="card_pricing_content_body">
                          <div className="card_pricing_body_inner">
                          <span style={{marginRight: '5px'}}><CircleIcon sx={{
                            fontSize: 5,
                          }}/></span>
                            <span>
                              {lang === 'es' ? ' Puedes contactar a todos los aplicantes.' : ' You can contact all applicants.'}
                          </span>
                          </div>
                          <div className="card_pricing_body_inner">
                          <span style={{marginRight: '5px'}}><CircleIcon sx={{
                            fontSize: 5,
                          }}/></span>
                            <span>
                              {lang === 'es' ? ' Difusión en redes sociales.' : ' Marketing campaigns in social media.'}
                          </span>
                          </div>
                          <div className="card_pricing_body_inner">
                          <span style={{marginRight: '5px'}}><CircleIcon sx={{
                            fontSize: 5,
                          }}/></span>
                            <span>
                              {lang === 'es' ? ' Personalización de proceso' : ' Process customization'}
                          </span>
                            <br/>
                          </div>

                        </div>
                        <Link to="/company/pricing/standard">
                          <div className="card_pricin_button_epayco2">
                            {plan?.nombre_paquete === 'Plan Basico'
                              ? <button style={{color: '#FFF', background: 'none'}} disabled>
                                {lang === 'es' ? 'Plan actual' : 'Actual plan'}
                            </button>
                              : <button style={{color: '#FFF', background: 'none'}}>
                              {lang === 'es' ? 'Adquirir' : 'Buy'}
                              </button>
                            }
                          </div>
                        </Link>
                      </div>
                    </div>

                    {/* <!--//PRICE CONTENT END--> */}

                  </div>

                  <div className="col-md-4">

                    {/* <!--PRICE CONTENT START--> */}
                    <div className="card_container_pricing3" style={{width: '100%'}}>
                      <div className="card_pricing_content">
                        <div className="card_pricing_content_header">
                          <div className="card_price_tag">
                            <span className="card_title_tag">
                              Bussiness
                            </span>
                          </div>
                        </div>
                        <div className="card_pricing_tag_title2">
                          <div>
                            <h3 className="card_price_text">
                              $ 3 M
                            </h3>
                            <h3 className="card_price_text2">
                              /año
                            </h3>
                          </div>
                          <div>
                            <h3 className="card_price_text3">
                              {lang === 'es' ? '+ 2% al' : ' + 2% fee*'}
                            </h3>
                            <h3 className="card_price_text3">
                              {lang === 'es' ? 'contratar*' : ''}
                            </h3>
                          </div>
                        </div>
                        <div className="card_pricing_content_body">
                          <div className="card_pricing_body_inner">
                          <span style={{marginRight: '5px'}}><CircleIcon sx={{
                            fontSize: 5,
                          }}/></span>
                            <span>
                              {lang === 'es' ? ' Publicaciones ilimitadas.' : ' Unlimited posts.'}
                          </span>
                          </div>
                          <div className="card_pricing_body_inner">
                          <span style={{marginRight: '5px'}}><CircleIcon sx={{
                            fontSize: 5,
                          }}/></span>
                            <span>
                              {lang === 'es' ? ' Analítica de datos' : ' Data analytics'}
                          </span>
                            <br/>
                            <span>
                              {lang === 'es' ? ' (dashboards).' : ' (dashboards).'}
                          </span>
                          </div>
                          <div className="card_pricing_body_inner">
                          <span style={{marginRight: '5px'}}><CircleIcon sx={{
                            fontSize: 5,
                          }}/></span>
                            <span>
                              {lang === 'es' ? ' Marca empleador, landing' : ' Employer brand, landing'}
                          </span>
                            <br/>
                            <span>
                              {lang === 'es' ? ' empresa, webinars.' : ' company, webinars.'}
                          </span>
                          </div>

                        </div>
                        <Link to="/company/pricing/bussiness">
                          <div className="card_pricin_button_epayco3">
                            {plan?.nombre_paquete === 'Plan empresarial'
                              ? <button style={{color: '#FFF', background: 'none'}} disabled>
                                {lang === 'es' ? 'Plan actual' : 'Actual plan'}
                              </button>
                              : <button style={{color: '#FFF', background: 'none'}}>
                                {lang === 'es' ? 'Adquirir' : 'Buy'}
                              </button>
                            }
                          </div>
                        </Link>
                      </div>
                    </div>

                    {/* <!--//PRICE CONTENT END--> */}

                  </div>
                </div>
                {/* <!--//BLOCK ROW END--> */}

              </div>
            </section>
          </div>
        </div>
      </div>
    </div>
  )
}
