import React, {useState} from "react";
import "./Sidebar.css";
import Home from "../../assets/icons/home.svg";
import HomeInactive from "../../assets/icons/home-inactive.svg";
import oportunidades from "../../assets/icons/oportunidades.svg";
import oportunidadesInactive from "../../assets/icons/oportunidades-inactive.svg";
import formacion from "../../assets/icons/formacion.svg";
import formacionInactive from "../../assets/icons/formacion-inactive.svg";
import gear from "../../assets/icons/gear.svg";
import gearInactive from "../../assets/icons/gear-inactive.svg";
import logout from "../../assets/icons/logout.svg";
import {useDispatch, useSelector} from "react-redux";
import {Link} from "react-router-dom";
import {logoutUser} from "../../actions";
import SidebarCv from "./SidebarCv/SidebarCv";
import SidebarOp from "./SidebarOp/SidebarOp";
// import logoCoally from "../../assets/logos/coallyLogoNew.png";
import PedalBikeOutlinedIcon from "@mui/icons-material/PedalBikeOutlined";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import axios from "axios";

export default function Sidebar({location, style}) {
  const {lang, user} = useSelector((state) => state);
  const dispatch = useDispatch();
  const [unilever] = useState(localStorage.getItem("unilever"));
  const [form] = useState({
    usuario: user.id,
    buttonName: "ingresoAlBootcamp",
  })

  const handleLogout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    localStorage.removeItem("redirect");
    localStorage.removeItem("plan_premium");
    localStorage.removeItem("unilever");
    localStorage.removeItem("unilever_rol");
    localStorage.removeItem("unilever_id");
    dispatch(logoutUser({}, "/"));
  };

  const handleSaveClick = () => {
    axios.post('https://backend-production-1bc8.up.railway.app/api/metricsBootcamp/SaveClick', form)
      .catch(err => console.log(err))
  }

  return (
    <div className={style ? "Sidebar-Cont22" : "Sidebar-Cont"}>
      <div className="contenedor-tour"></div>
      <div className={"Sidebar-Sub-Cont-1"}>
        <Link
          to={user.roles === "coallier" ? "/consultor/home" : "/company/home"}
        >
          <img
            className={"Sidebar-Logo"}
            src={'http://coally.com.co/wp-content/uploads/2023/09/holbertonblancocoderise.webp'}
            alt={"Logo Coally"}
          />
        </Link>
        <Link
          to={user.roles === "coallier" ? "/consultor/home" : "/company/home"}
          className={
            location === "home"
              ? "Sidebar-Sub-Cont-But-active"
              : "Sidebar-Sub-Cont-But"
          }
        >
          <img
            className={"Sidebar-Sub-Cont-Img"}
            src={location === "home" ? Home : HomeInactive}
            alt={"Home-Icon"}
          />
          {lang === "es" ? "Inicio" : "Home"}
        </Link>
        <Link
          to={
            user.roles === "coallier"
              ? "/consultor/projects"
              : "/company/project"
          }
          className={
            location === "oportunidades"
              ? "Sidebar-Sub-Cont-But-active"
              : "Sidebar-Sub-Cont-But"
          }
        >
          <img
            className={"Sidebar-Sub-Cont-Img"}
            src={
              location === "oportunidades"
                ? oportunidades
                : oportunidadesInactive
            }
            alt={"Home-Icon"}
          />
          {lang === "es" ? "Oportunidades" : "Opportunities"}
        </Link>
        {user.roles === "company" ? null : (
          <Link
            to={
              user.roles === "coallier"
                ? "/consultor/formacion"
                : "/company/formacion"
            }
            className={
              location === "formación"
                ? "Sidebar-Sub-Cont-But-active"
                : "Sidebar-Sub-Cont-But"
            }
          >
            <img
              className={"Sidebar-Sub-Cont-Img"}
              src={location === "formación" ? formacion : formacionInactive}
              alt={"Home-Icon"}
            />
            {lang === "es" ? "Formación" : "Training"}
          </Link>
        )}
        {unilever === "true" ? (
          <Link
            to={"/unilever/home"}
            className={
              location === "unilever home"
                ? "Sidebar-Sub-Cont-But-active"
                : "Sidebar-Sub-Cont-But"
            }
            style={{textAlign: "left"}}
          >
            <PedalBikeOutlinedIcon
              className={"Sidebar-Sub-Cont-Img"}
              style={{
                color: location === "unilever home" ? "#00A8E8" : "#BDBDBD",
                marginRight: "15px",
              }}
            />
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              <span>Métele pedal</span>
              <span>a tu futuro</span>
            </div>
          </Link>
        ) : null}
        {user && user.roles !== "company" ? (
          <Link
            to={"/consultor/bootcamp/administracion"}
            onClick={() => handleSaveClick()}
            className={
              location === "bootcamp administracion"
                ? "Sidebar-Sub-Cont-But-active"
                : "Sidebar-Sub-Cont-But"
            }
            style={{textAlign: "left"}}
          >
            <TrendingUpIcon
              className={"Sidebar-Sub-Cont-Img"}
              style={{
                color:
                  location === "bootcamp administracion"
                    ? "#00A8E8"
                    : "#BDBDBD",
                marginRight: "15px",
              }}
            />
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              <span>Bootcamp</span>
            </div>
          </Link>
        ) : null}
      </div>

      <div className={"Sidebar-Sub-Cont-2"}>
        <label
          id="pusblish"
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
          }}
        >
          {user.roles === "coallier" ? <SidebarCv/> : <SidebarOp/>}
        </label>
        <Link
          to={
            user.roles === "coallier"
              ? "/consultor/config"
              : "/company/configuracion"
          }
          className={
            location === "configuración"
              ? "Sidebar-Sub-Cont-But-active"
              : "Sidebar-Sub-Cont-But"
          }
        >
          <img
            className={"Sidebar-Sub-Cont-Img"}
            src={location === "configuración" ? gear : gearInactive}
            alt={"Home-Icon"}
          />
          {lang === "es" ? "Configuración" : "Settings"}
        </Link>
        <Link
          to={"#"}
          onClick={() => handleLogout()}
          className={"Sidebar-Sub-Cont-But-2"}
        >
          <img
            className={"Sidebar-Sub-Cont-Img"}
            src={logout}
            alt={"Home-Icon"}
          />
          {lang === "es" ? "Cerrar sesión" : "Log out"}
        </Link>
      </div>
    </div>
  );
}
