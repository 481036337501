import React, { useState, useEffect, useContext } from "react";
import Sidebar from "../../../components/sidebar/Sidebar";
import Navbar from "../../../components/navbar/Navbar";
import { useSelector } from "react-redux";
import {
  BodyWebPapa,
  DefaultColumnContainer,
} from "../../../styles/GlobalStyles";
import { CustomizableTitle } from "../../../styles/TitleStyles";
import {
  FirstFilterLabelContainer,
  FirstCardPreguntaContainer,
} from "./FirstFilterFormStyles";
import { ReduxStateInterface } from "../../../types/reduxInterface";
import { useFilterFormData } from "./utils/useFilterFormData";
// @ts-ignore
import Select from "react-select";
import { Chip } from "@mui/material";
import { SchneiderContext } from "../../../context/SchneiderContext";
import { AddButton, CreateButton } from "../cvform/CvFormStyles";

function FirstFilterForm() {
  const { schneider } = useContext(SchneiderContext);
  const { lang, user } = useSelector((state: ReduxStateInterface) => state);
  const {
    formData,
    handleFormData,
    setFormData,
    handleDeleteHobby,
    hobby,
    setHobby,
    handleAddHobby,
    handleAddDigitalTool,
    handleDeleteDigitalTool,
    digitalTool,
    setDigitalTool,
    handleSubmit,
  } = useFilterFormData();
  const [tiempoRestante, setTiempoRestante] = useState<number | null>(null);
  const [completado, setCompletado] = useState(false);
  const [contador, setContador] = useState<string>("01:00:00");

  useEffect(() => {
    const storedTime = Number(localStorage.getItem("tiempoRestante"));

    if (tiempoRestante === null) {
      if (storedTime) {
        setTiempoRestante(storedTime);
      } else {
        setTiempoRestante(5400);
        localStorage.setItem("tiempoRestante", "5400");
      }
    }

    if (storedTime && storedTime === 0) {
      return setCompletado(true);
    }

    if (storedTime < 0) {
      localStorage.setItem("tiempoRestante", "0");
      return;
    }

    const interval = setInterval(() => {
      if (tiempoRestante || storedTime > 0) {
        setTiempoRestante(tiempoRestante - 1);
        const hrs = Math.floor(tiempoRestante / 3600);
        const mins = Math.floor((tiempoRestante % 3600) / 60);
        const secs = Math.floor(tiempoRestante % 60);
        const hrsStr = hrs.toString().padStart(2, "0");
        const minsStr = mins.toString().padStart(2, "0");
        const secsStr = secs.toString().padStart(2, "0");
        setContador(`${hrsStr}:${minsStr}:${secsStr}`);
        localStorage.setItem("tiempoRestante", tiempoRestante.toString());
      } else {
        localStorage.setItem("tiempoRestante", "0");
        clearInterval(interval);
        setCompletado(true);
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [tiempoRestante]);

  const checkBoxLabels = [
    {
      label: lang === "es" ? "Sí" : "Yes",
      value: lang === "es" ? "Sí" : "Yes",
    },
    {
      label: lang === "es" ? "No" : "No",
      value: lang === "es" ? "No" : "No",
    },
  ];

  return (
    <div>
      <Sidebar />
      <BodyWebPapa>
        <Navbar />
        <DefaultColumnContainer>
          {tiempoRestante > 0 && !completado ? (
            <div>
              <FirstFilterLabelContainer>
                <div style={{ padding: "10px 20px" }}>
                  <CustomizableTitle weight={"600"} size={"18px"}>
                    {lang === "es"
                      ? "Por favor, lee y responde el siguiente formulario"
                      : "Please read and answer the next form"}
                  </CustomizableTitle>
                  <CustomizableTitle
                    weight={"600"}
                    size={"18px"}
                    color={"#da781d"}
                    margin={"10px 0"}
                  >
                    {lang === "es" ? "Tiempo Restante" : "Time Remaining"}
                  </CustomizableTitle>
                  <CustomizableTitle
                    weight={"600"}
                    size={"18px"}
                    margin={"10px 0"}
                  >
                    {contador}
                  </CustomizableTitle>
                </div>
              </FirstFilterLabelContainer>
              <FirstCardPreguntaContainer>
                <h3 className="title-crear-oportunidad">
                  {lang === "es"
                    ? "¿Estás de acuerdo con el salario propuesto?"
                    : "Are you agree with the proposed salary?"}
                  <span className={"Obligatorio"}> *</span>
                </h3>
                <div>
                  <Select
                    options={checkBoxLabels.slice()}
                    value={checkBoxLabels.find(
                      (obj) => obj.value === formData.proposed_salary
                    )}
                    onChange={(e: any) =>
                      setFormData({
                        ...formData,
                        proposed_salary: e.value,
                      })
                    }
                  />
                </div>
                <h3 className="title-crear-oportunidad">
                  {lang === "es"
                    ? "¿Estás de acuerdo en que sea un contrato a término fijo con una duración de 6 meses?"
                    : "Are you agree with a fixed term contract with a duration of 6 months?"}
                  <span className={"Obligatorio"}> *</span>
                </h3>
                <div>
                  <Select
                    options={checkBoxLabels.slice()}
                    value={checkBoxLabels.find(
                      (obj) => obj.value === formData.contract_acceptance
                    )}
                    onChange={(e: any) =>
                      setFormData({
                        ...formData,
                        contract_acceptance: e.value,
                      })
                    }
                  />
                </div>
                <h3 className="title-crear-oportunidad">
                  {lang === "es"
                    ? "Teniendo en cuenta que el trabajo es tiempo completo ¿Cuentas con la disponibilidad de tiempo?"
                    : "Considering that the work is full time, do you have the availability of time?"}
                  <span className={"Obligatorio"}> *</span>
                </h3>
                <div>
                  <Select
                    options={checkBoxLabels.slice()}
                    value={checkBoxLabels.find(
                      (obj) => obj.value === formData.available_time
                    )}
                    onChange={(e: any) =>
                      setFormData({
                        ...formData,
                        available_time: e.value,
                      })
                    }
                  />
                </div>
                <h3 className="title-crear-oportunidad">
                  {lang === "es"
                    ? "¿Cuales son tus hobbies?"
                    : "What are your hobbies?"}
                  <span className={"Obligatorio"}> *</span>
                </h3>
                <div
                  style={{
                    marginBottom: "20px",
                    display: "flex",
                    width: "100%",
                    flexDirection: "row",
                  }}
                >
                  {formData?.hobbies.length > 0
                    ? formData?.hobbies.map((item: string, index) => {
                        return (
                          <div
                            key={index}
                            style={{
                              marginRight: "20px",
                              marginBottom: "20px",
                            }}
                          >
                            <Chip
                              sx={{
                                background: schneider.color.primary,
                                borderRadius: "5px",
                                fontSize: "14px",
                                color: "white",
                                padding: "10px",
                              }}
                              label={item}
                              onDelete={() => handleDeleteHobby(index)}
                              style={{ textTransform: "capitalize" }}
                            />
                          </div>
                        );
                      })
                    : null}
                </div>
                <input
                  className={"input-crear-oportunidad"}
                  type="text"
                  name={`hobby`}
                  placeholder={lang === "es" ? "Escribe un Hobby, luego haz click en el botón Agregar" : "Write a Hobby, then click on the Add button"}
                  value={hobby}
                  onChange={(e) => setHobby(e.target.value)}
                />
                <AddButton
                  color={schneider.color.primary}
                  onClick={(e) => handleAddHobby(e)}
                  disabled={hobby === ""}
                >
                  {lang === "es" ? "Agregar" : "Add"}
                </AddButton>
                <h3 className="title-crear-oportunidad">
                  {lang === "es"
                    ? "¿Practicas algún deporte? ¿Cuál? "
                    : "Do you practice any sport? Which one?"}
                </h3>
                <input
                  className={"input-crear-oportunidad"}
                  type="text"
                  name={`sport`}
                  placeholder={lang === "es" ? "Deporte" : "Sport"}
                  value={formData.sport}
                  onChange={(e) => handleFormData(e)}
                />
                <h3 className="title-crear-oportunidad">
                  {lang === "es"
                    ? "¿Qué herramientas digitales maneja o ha tenido interacción? "
                    : "What digital tools does he handle or has had interaction?"}
                  <span className={"Obligatorio"}> *</span>
                </h3>
                <div
                  style={{
                    marginBottom: "20px",
                    display: "flex",
                    width: "100%",
                    flexDirection: "row",
                  }}
                >
                  {formData?.digital_tools.length > 0
                    ? formData?.digital_tools.map((item: string, index) => {
                        return (
                          <div
                            key={index}
                            style={{
                              marginRight: "20px",
                              marginBottom: "20px",
                            }}
                          >
                            <Chip
                              sx={{
                                background: schneider.color.primary,
                                borderRadius: "5px",
                                fontSize: "14px",
                                color: "white",
                                padding: "10px",
                              }}
                              label={item}
                              onDelete={() => handleDeleteDigitalTool(index)}
                              style={{ textTransform: "capitalize" }}
                            />
                          </div>
                        );
                      })
                    : null}
                </div>
                <input
                  className={"input-crear-oportunidad"}
                  type="text"
                  name={`digitalTool`}
                  placeholder={lang === "es" ? "Escribe una herramienta digital, luego haz click en el botón Agregar" : "Write a digital tool, then click on the Add button"}
                  value={digitalTool}
                  onChange={(e) => setDigitalTool(e.target.value)}
                />
                <AddButton
                  color={schneider.color.primary}
                  onClick={(e) => handleAddDigitalTool(e)}
                  disabled={digitalTool === ""}
                >
                  {lang === "es" ? "Agregar" : "Add"}
                </AddButton>
                <h3 className="title-crear-oportunidad">
                  {lang === "es"
                    ? "Cuéntanos una experiencia ejemplo de uso de cada una de las herramientas digitales manejadas"
                    : "Tell us an example experience of use of each of the digital tools handled"}
                  <span className={"Obligatorio"}> *</span>
                </h3>
                <textarea
                  className={"input-crear-oportunidad"}
                  name={`digital_tools_experience`}
                  placeholder={"Ejemplo de experiencia"}
                  value={formData.digital_tools_experience}
                  onChange={(e) => handleFormData(e)}
                />
                <h3 className="title-crear-oportunidad">
                  {lang === "es"
                    ? "Cuéntanos un reto o dificultad que se te haya presentado y como lo solucionaste"
                    : "Tell us a challenge or difficulty that has been presented to you and how you solved it"}
                  <span className={"Obligatorio"}> *</span>
                </h3>
                <textarea
                  className={"input-crear-oportunidad"}
                  name={`difficulties`}
                  placeholder={"Ejemplo de experiencia"}
                  value={formData.difficulties}
                  onChange={(e) => handleFormData(e)}
                />
                <h3 className="title-crear-oportunidad">
                  {lang === "es"
                    ? "Realiza el siguiente test de ingles (Lectura y comprensión auditiva) e Ingresa tu resultado."
                    : "Take the following English test (Reading and listening comprehension) and enter your result."}
                  <span className={"Obligatorio"}> *</span>
                </h3>
                <a
                  href={"https://www.ef.com/test/english/form/"}
                  target={"_blank"}
                  style={{ textDecoration: "none" }}
                  rel={"noopener noreferrer"}
                >
                  <AddButton color={schneider.color.primary}>
                    {lang === "es" ? "Realizar Test" : "Take Test"}
                  </AddButton>
                </a>
                <input
                  className={"input-crear-oportunidad"}
                  type="text"
                  name={`english_test`}
                  placeholder={lang === "es" ? "Introducir el resultado, Ej: 80%" : "Enter the result, Ex: 80%"}
                  value={formData.english_test}
                  onChange={(e) => handleFormData(e)}
                />
                <h3 className="title-crear-oportunidad">
                  {lang === "es"
                    ? "Realiza el siguiente test de personalidad e Ingresa tu resultado."
                    : "Take the following personality test and enter your result."}
                  <span className={"Obligatorio"}> *</span>
                </h3>
                <a
                  href={
                    "https://principlesyou.com/assessments/pdp"
                  }
                  target={"_blank"}
                  style={{ textDecoration: "none" }}
                  rel={"noopener noreferrer"}
                >
                  <AddButton color={schneider.color.primary}>
                    {lang === "es" ? "Realizar Test" : "Take Test"}
                  </AddButton>
                </a>
                <input
                  className={"input-crear-oportunidad"}
                  type="text"
                  name={`personality_test`}
                  placeholder={lang === "es" ? "Introducir el resultado, Ej: Admin" : "Enter the result, Ex: Admin"}
                  value={formData.personality_test}
                  onChange={(e) => handleFormData(e)}
                />
                  <h3 className="title-crear-oportunidad">
                  {lang === "es"
                    ? "Realiza el siguiente test de habilidades sociales e Ingresa la url del resultado."
                    : "Take the following social skills test and enter the result url."}
                  <span className={"Obligatorio"}> *</span>
                </h3>
                <a
                  href={
                    "https://www.mydiscprofile.com/es-co/free-personality-test.php"
                  }
                  target={"_blank"}
                  style={{ textDecoration: "none" }}
                  rel={"noopener noreferrer"}
                >
                  <AddButton color={schneider.color.primary}>
                    {lang === "es" ? "Realizar Test" : "Take Test"}
                  </AddButton>
                </a>
                <input
                  className={"input-crear-oportunidad"}
                  type="text"
                  name={`social_skills`}
                  placeholder={lang === "es" ? "Ej. https://www.mydiscprofile.com/es-co/personality-report.php?id=224033346" : "E.g. https://www.mydiscprofile.com/es-co/personality-report.php?id=224033346"}
                  value={formData.social_skills}
                  onChange={(e) => handleFormData(e)}
                />
              </FirstCardPreguntaContainer>
              <CreateButton
                color={schneider.color.primary}
                onClick={(e) => handleSubmit(e, user.id)}
              >
                {lang === "es" ? "Finalizar" : "Finish"}
              </CreateButton>
            </div>
          ) : (
            <div>
              <FirstFilterLabelContainer>
                <div style={{ padding: "10px 20px" }}>
                  <CustomizableTitle weight={"600"} size={"18px"}>
                    {lang === "es"
                      ? "Su tiempo de respuesta ha expirado"
                      : "Your response time has expired"}
                  </CustomizableTitle>
                </div>
              </FirstFilterLabelContainer>
            </div>
          )}
        </DefaultColumnContainer>
      </BodyWebPapa>
    </div>
  );
}

export default FirstFilterForm;
