import React, { useEffect } from "react";
import { createPortal } from "react-dom";
import styled from "styled-components";
import {
  FromBottom,
  ToBottom,
  OpacityOff,
  OpacityOn,
} from "../../styles/AnimationStyles";

const PopupContainer = styled.div<{ isClosing?: boolean }>`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
  background: rgba(0, 0, 0, 0.5);
  animation: ${(props) => (props.isClosing ? OpacityOff : OpacityOn)} 0.5s
    ease-in-out;
`;

const PopupWrapper = styled.div<{ isClosing?: boolean }>`
  background-color: white;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  padding: 24px;
  animation: ${(props) => (props.isClosing ? ToBottom : FromBottom)} 0.5s
    ease-in-out;
`;

interface PopupProps {
  id?: string;
  children?: React.ReactNode;
  onClose?: () => void;
}

const Popup: React.FC<PopupProps> = ({ id, children, onClose }) => {
  useEffect(() => {
    const handleEscape = (e: KeyboardEvent) => {
      if (e.key === "Escape") {
        onClose();
      }
    };

    const handleClickOutside = (e: MouseEvent) => {
      const target = e.target as HTMLElement;
      if (!target.closest("#popup-wrapper")) {
        onClose();
      }
    };

    document.addEventListener("keydown", handleEscape);
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("keydown", handleEscape);
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [onClose]);

  return createPortal(
    <PopupContainer>
      <PopupWrapper id="popup-wrapper">{children}</PopupWrapper>
    </PopupContainer>,
    document.body
  );
};

export default Popup;
