import React, { useState } from "react";
import { registerTicket } from "../actions";
import Header from "../empresa/header/Headerprofesional";
import './styles/Support_ticket.css';
import Select from "react-select";
import { connect } from "react-redux";
// import { Link } from 'react-router-dom';
import Headertop from "../empresa/headertop/Headertop";
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import PhoneIcon from '@material-ui/icons/Phone';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import en from "../lang/en-US";
import es from "../lang/es-COL"
import counterpart from "counterpart";
import Translate from "react-translate-component";


function FormTicket(props) {
  const [form, setValues] = useState({
    problema: "",
    categoria: "",
    descripcion: "",
    usuario: props.user.id,
  });

  const handleInput = (event) => {
    setValues({
      ...form,
      [event.target.name]: event.target.value,
    });
  };

  const handleChange = (selectedOption, { name }) => {
    setValues({
      ...form,
      [name]: selectedOption.value
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    console.log(form)
    props.registerTicket(form);
  };

  const categories = [
    { value: "general", label: "General" },
    { value: "tecnico", label: "Técnico" },
    { value: "legal", label: "Legal" },
    { value: "financiero", label: "Financiero" }
  ];

  //const lang = useSelector(state => state.lang)



  counterpart.registerTranslations('en', en)
  counterpart.registerTranslations('es', es)
  counterpart.setLocale(localStorage.getItem('langlocal'));
  const Problema = counterpart.translate('Problema')
  const Descripción = counterpart.translate('Descripción')
  return (
    <div>
      <div>
        <Header />
        {/* <select className="Header-lang" value={lang} onChange={(e)=>dispatch(changeLang(e.target.value))}>
        <option value="en">en-US</option>
        <option value="es">es-COL</option>
      </select> */}
        <div style={{ padding: "0rem 2rem", width: "calc(99vw - 237px)", position: "absolute", right: "0" }}>
        <Headertop></Headertop>
          {/* <Link className="volver-leg-icon" to="/consultor/home">
          {" "}
          🠸 {<Translate content="ATRÁS" component="span"/>}
        </Link>
        <p style={{display:'flex',margin:'50px',marginLeft:'190px',fontSize:'30px'}}><h2 className="title_form_ticket">{<Translate content="soporte" component="span"/>} <span className="highlight-word-contact">{<Translate content="coally" component="span"/>} </span></h2></p> */}
          <h1 style={{
            margin: '0',
            fontSize: "40px",
            fontFamily: 'Poppins',
            fontWeight: '700',
            color: '#202256',
            marginBottom: '27px'
          }}>SOPORTE COALLY</h1>

          <div className="contenedor-indo">
            <div className="info-">
              <p className="content-contact-support-info">{<Translate content="Enesteespaciode" component="span" />}
                <span className="highlight-word-contact">{<Translate content="bienestar" component="span" />}</span>
                {<Translate content="puedescompartircon" component="span" />} </p>
              <ul className="list-contact-coally">
                <div className="lista-contact-coally-contenedor">
                  <li style={{ color: '#212156' }}><WhatsAppIcon className="iconos-lista-contact" />{<Translate content="EscríbenosporWhatsApp" component="span" />}</li>
                </div>
                <div className="lista-contact-coally-contenedor">
                  <li style={{ color: '#212156' }}><PhoneIcon className="iconos-lista-contact" />{<Translate content="telefonocontacto" component="span" />}</li>
                </div>
                <div className="lista-contact-coally-contenedor">
                  <li style={{ color: '#212156' }}><MailOutlineIcon className="iconos-lista-contact" />soporte@coally.com</li>
                </div>
              </ul>
            </div>

            <div className="support_ticket">
              <form className="support_form2" onSubmit={handleSubmit}>

                <label className="category-label" style={{ width: '89%' }}>
                  {<Translate content="Seleccionalacategoríadelproblema" component="span" />} <br />
                  <Select
                    className="category-options"
                    classNamePrefix="select"
                    defaultValue={""}
                    isDisabled={false}
                    isLoading={false}
                    isClearable={true}
                    isRtl={false}
                    isSearchable={true}
                    name="categoria"
                    options={categories}
                    onChange={handleChange}
                  />
                </label>
                <input
                  className="input_support"
                  type="text"
                  placeholder={Problema}
                  name="problema"
                  onChange={handleInput}
                />
                <textarea
                  className="textarea-support"
                  placeholder={Descripción}
                  name="descripcion"
                  onChange={handleInput}
                />
                <button className="button_support" onSubmit={handleSubmit}>{<Translate content="Enviar" component="span" />}</button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const mapDispatchToProps = {
  registerTicket,
};

const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FormTicket);
