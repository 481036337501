import React from 'react'
import { useSelector } from 'react-redux';
import './CardSector.css'


const CardSector = ({ image, title, cant }) => {
  const { lang } = useSelector((state) => state);

  return (
    <div style={{ marginRight: '20px' }}>
      <div className='cardSector-container'
        style={{ backgroundImage: `url(${image})` }}>
        <div className='cardSector-subContainer'>
          <div>
            <h3 className='cardSector-title'>
              {title}
            </h3>
            <h3 className='cardSector-cant'>
              {cant} {lang === 'es' ? 'cursos' : 'courses'}
            </h3>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CardSector