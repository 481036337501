import React, { useEffect, useState } from 'react';
import Avatar from "@mui/material/Avatar";
import phone from '../../../assets/icons/phone.svg'
import mail from '../../../assets/icons/mail.svg'
import '../../../profesionalpag/PerfilProfesional/sideCards/cards.css'
import { User } from "./ProfileInterfaces";
import axios from 'axios';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

interface PromedioSalario {
    moneda: string;
    promedio?: number;
}

export default function ProfileCard({ user }: { user: User }) {
    const{lang} = useSelector((state:any)=>state)
    const [promedioSalario, setPromedioSalario] = useState<PromedioSalario | null>(null);
    const location = useLocation();
    const params = new URLSearchParams(location.search)
    const currencyValue = params.get('$')

    useEffect(() => {
        if (user.id) {
            axios
                .post(`https://findally-backend.herokuapp.com/api/user/salaryAverage`, {
                    id: user.id,
                    moneda: currencyValue,
                })
                .then((res) => {
                    setPromedioSalario(res.data);
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    }, [user , currencyValue]);

    return (
        <div className='Perfil-container-cards'>

            <div className='porcentaje-editar-container'>
                {/* <div className='editar-foto-perfil'>
              <button onClick={()=>handleAvatarSubmit()} >
                <img src={camera} alt={'camera'}/>
              </button>
            </div> */}
                <div className='Avatar-camara-perfil'>
                    <Avatar
                        className="avatar-element-perfil"
                        src={user.avatar ? user.avatar : "/broken-image.jpg"}
                        sx={{ width: "164px", height: "164px" }}
                    />
                </div>
                {/* <div className='porcentaje-complete'>
                    <p className='porcentaje-p'>{progress}%</p>
                </div>*/}
                <hr className='herre' />
            </div>
            <div className='contact-data'>
                <p><img src={phone} alt='phone' className='icon-cards' />{user.phoneNumber ? user.phoneNumber : ''}</p>
                <p><img src={mail} alt='mail' className='icon-cards' />{user.email}</p>
                {promedioSalario?.promedio !== 0 &&
                    <div >
                        <hr />
                        <p style={{
                            fontWeight: '700',
                            fontSize: '14px',
                            color: '#000',
                            margin: '0px',
                        }}>{lang === 'es' ? 'Salario promedio' : 'Average salary' }</p>
                        <p className='CardOportunidadProfesional-Salario'>{promedioSalario?.moneda} {promedioSalario?.promedio}</p>                    
                    </div>
                }
            </div>
        </div>
    )
}
