/* eslint-disable */
import React, {useState, useEffect} from 'react';
import {useDispatch} from 'react-redux';
import {saveOportunityForm} from '../../actions';
import {useSelector} from 'react-redux';

export default function Presupuesto(props) {
  const {setform, form, step, handleStep, handleBackStep, lang} = props;
  const oportunityForm = useSelector((state) => state.oportunityForm);
  const [button, setButton] = useState(true);
  const dispatch = useDispatch();
  const [presupuesto, setPresupuesto] = useState({
    //the value form oportunityForm is coming in the next format "USD 1000 - USD 5000" take the first value after the currency, if it have dots, remove them and convert to number
    pres_min: oportunityForm?.presupuesto?.length ? oportunityForm?.presupuesto?.split(' ')[1].replace(/\./g, '') * 1 : '',
    pres_max: oportunityForm?.presupuesto?.length ? oportunityForm?.presupuesto?.split(' ')[4].replace(/\./g, '') * 1 : ''
  })
  console.log(presupuesto);
  const [error, setError] = useState({
    pres_min: '',
    pres_max: '',
    isOkay: false
  })
  const [moneda, setMoneda] = useState(oportunityForm?.presupuesto?.length ? oportunityForm?.presupuesto?.substring(0, 3) : form?.presupuesto?.substring(0, 3))

  const formatterPeso = new Intl.NumberFormat('es-CO', {
    minimumFractionDigits: 0
  })

  useEffect(() => {
    if (presupuesto.pres_min !== '' && presupuesto.pres_max !== '' && moneda !== '' && moneda !== 'Moneda' && moneda !== 'Currency') {
      if (Number(presupuesto.pres_min) <= Number(presupuesto.pres_max)) {
        setButton(false)
        setError({...error, isOkay: true})
      }
    }
  }, [presupuesto, moneda])

  useEffect(() => {
    //it shouldnt load when the component is mounted
    if (presupuesto.pres_min !== '' && presupuesto.pres_max !== '') {
      setform({
        ...form,
        presupuesto: `${moneda} ${formatterPeso.format(presupuesto.pres_min)} - ${moneda} ${formatterPeso.format(presupuesto.pres_max)}`
      })
      dispatch(saveOportunityForm({
        presupuesto: `${moneda} ${formatterPeso.format(presupuesto.pres_min)} - ${moneda} ${formatterPeso.format(presupuesto.pres_max)}`
      }, step))
    }
    // eslint-disable-next-line
  }, [presupuesto, moneda])

  const handleChangeMoneda = (e) => {
    setMoneda(e.target.value)
    dispatch(saveOportunityForm({
      ...oportunityForm,
      presupuesto: `${e.target.value} ${formatterPeso.format(presupuesto.pres_min)} - ${e.target.value} ${formatterPeso.format(presupuesto.pres_max)}`
    }))
  }
  const validation = (presupuesto) => {
    const error = {}
    error.isOkay = true

    if (presupuesto.pres_min !== '' && presupuesto.pres_max !== ''
      && (Number(presupuesto.pres_max) < Number(presupuesto.pres_min))) {
      error.max = lang === 'es' ? 'El mínimo debe ser menor que el máximo'
        : 'Minimum must be less than maximum'
      error.isOkay = false
    }
    if (!presupuesto.pres_min || !presupuesto.pres_max) {

      error.isOkay = false
    }
    return error
  }

  const handlePres = (e) => {
    setPresupuesto({
      ...presupuesto,
      [e.target.name]: e.target.value
    })
    setError(validation({
      ...presupuesto,
      [e.target.name]: e.target.value
    }))
    /*     dispatch(saveOportunityForm({...oportunityForm, presupuesto: moneda + " " + presupuesto.pres_min + ' - ' + moneda + " " + presupuesto.pres_max }, step)) */
  }

  const handleNext = () => {
    if (error.isOkay) {
      handleStep()
    }
  }
  return (
    <>
      <div className='contenedorPresupuesto'>
        <div style={{marginBottom: '30px', display: 'flex', flexDirection: 'column'}}>
          <h3 style={{
            fontFamily: 'Poppins',
            fontWeight: '700',
            fontSize: '14px',
            marginBottom: '20px',
            marginLeft: '-10px'
          }}>{lang === 'es' ? 'Presupuesto para la oportunidad' : 'Budget for the opportunity'} </h3>
          <select className='selectCurrency' onChange={e => handleChangeMoneda(e)} defaultValue={moneda}>
            <option value={''}>{lang === 'es' ? 'Moneda' : 'Currency'}</option>
            <option value="USD">USD</option>
            <option value="COP">COP</option>
          </select>
          <input type="text" placeholder='Min: COP 10.000' name="pres_min"
                 onChange={(e) => handlePres(e)}
                 value={presupuesto.pres_min !== '' ? Number(presupuesto.pres_min) : ''}
                 onKeyPress={(e) => {
                   if (!/[0-9]/.test(e.key)) {
                     e.preventDefault();
                   }
                 }}
                 className="contenedorPresupuesto_input"></input>
        </div>

        <div style={{display: 'flex', flexDirection: 'column'}}>
          <input type="text" placeholder='Max: COP 400.000.000' name="pres_max"
                 onChange={(e) => handlePres(e)}
                 value={presupuesto.pres_max}
                 onKeyPress={(e) => {
                   if (!/[0-9]/.test(e.key)) {
                     e.preventDefault();
                   }
                 }}
                 className="contenedorPresupuesto_input"></input>
          {error.max && <p style={{color: 'red', fontSize: '12px', marginTop: '5px'}}>{error.max}</p>}
        </div>
      </div>
      <div
        style={{
          display: "flex",
          width: "100%",
          justifyContent: "space-between",
          padding: "1rem 3rem 0rem 3rem",
          alignItems: "center",
        }}
      >
        <button className='button_siguiente_create_B'
                onClick={handleBackStep}>{lang === 'es' ? 'Anterior' : 'Previous'}</button>
        <h3 className='stepCounter'>{`${step}/14`}</h3>
        <button className='button_siguiente_create' disabled={button}
                onClick={() => handleNext()}>{lang === 'es' ? 'Siguiente' : 'Next'}</button>
      </div>
    </>
  )
}
