import React from "react";
import './banner-unilever.css';
import Logo from '../../../assets/logos/Logo-Unilever.png';
import {useSelector} from "react-redux";
  const BannerUnileverHome = () => {
    const {lang} = useSelector((state) => state)
    return(
      <div className="unilever-banner-profesional">
        <div className="unilever-banner-profesional-inner-1">
          <div className="unilever-banner-profesional-inner-1-1">
            <h1 className="unilever-home-subtitle">
              {lang === 'es' ? 'Bievenido a' : 'Welcome to'}
            </h1>
            <h1 className="unilever-home-title">
              {lang === 'es' ? '¡Métele Pedal a tu Futuro!' : '¡Métele Pedal a tu Futuro!'}
            </h1>
          </div>
        </div>
        <div className="unilever-banner-profesional-inner-2">
          <img src={Logo} alt="logo unilever" />
        </div>
      </div>
    )
  }


  export default BannerUnileverHome;