import React from "react";
import {useCvForm} from "./utils/useCvForm";
import Sidebar from "../../components/Sidebar/Sidebar";
import Navbar from "../../components/Navbar/Navbar";
import './NewCVForm.css';
import {
  handleFormChange,
  // preset
} from "../Form/formUtils";
import Select from "react-select";
import {toast} from "react-toastify";
import Creatable from "react-select/creatable";
import {useParams} from "react-router";
import axios from "axios";
import {useSelector} from "react-redux";
import {Chip} from "@mui/material";
import PhoneInput from "react-phone-input-2";


const NewCVForm = () => {
  const {idUser} = useParams();

  const {cv, user} = useSelector((state) => state)
  const {
    form,
    setForm,
    lang,
    editable,
    isSubmit,
    // setMontoMin,
    profesions,
    experiencia,
    educacion,
    certificados,
    addExperience,
    handleExperienceChange,
    handleCargoChange,
    handleAptitudChange,
    addEducacion,
    handleEducacionChange,
    addCertificado,
    handleCertificadoChange,
    handleDelete,
    isLanguageExists,
    habilidades_blandas,
    softSkills,
    generos,
    handleFormInfoPersonal,
    handleFormGenre,
    removeCertificado,
    handleFormProfesion,
    nivelLenguaje,
    levelLanguage,
    languages,
    setLanguages,
    seleccionLenguajes,
    selectionLanguages,
    handleActualJobChange,
    removeExperience,
    removeEducacion,
    nivelFormacion,
    levelEducation,
    handleActualStudy,
    handleFormContacto,
    // divisa,
    // montoMin,
    // monedas,
    // setDivisa,
   
  } = useCvForm(idUser);

  const handleSubmit = (e) => {
    if (form?.info_personal?.nombre === "") {
      toast.error(lang === 'es' ? "Por favor, ingresa tu nombre" : "Please enter your name", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }
    if (form?.info_personal?.fecha_nacimiento === "") {
      toast.error(lang === 'es' ? "Por favor, ingresa tu fecha de nacimiento" : "Please insert your birthdate", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }
    if (form?.info_personal?.sexo === "") {
      toast.error(lang === 'es' ? "Por favor, ingresa tu género" : "Please insert your gender", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }
    if (form?.info_personal?.profesion_actual === "") {
      toast.error(lang === "es" ? "Por favor, ingresa tu profesión actual" : "Please enter your current profession", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }
    // if (form?.aspiracion_salarial === "") {
    //   toast.error(lang === "es" ? "Por favor, ingresa tu aspiración salarial" : "Please enter your salary aspiration", {
    //     position: toast.POSITION.TOP_CENTER,
    //     autoClose: 5000,
    //     hideProgressBar: true,
    //     closeOnClick: true,
    //     pauseOnHover: true,
    //     draggable: true,
    //     progress: undefined,
    //   });
    //   return;
    // }
    if (form?.extracto === "") {
      toast.error(lang === "es" ? "Por favor, ingresa tu descripción" : "Please enter your description", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }
    if (form?.aptitudes_principales?.length === 0) {
      toast.error(lang === "es" ? "Por favor, ingresa tus aptitudes principales" : "Please enter your main skills", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }
    if (form?.languages?.length === 0) {
      toast.error(lang === "es" ? "Por favor, ingresa tus idiomas" : "Please enter your languages", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }
    if (form?.certificados?.length === 0) {
      toast.error(lang === "es" ? "Por favor, ingresa tus certificados" : "Please enter your certificates", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }
    if (form?.educacion?.length === 0) {
      toast.error(lang === "es" ? "Por favor, ingresa tu educación" : "Please enter your education", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }
    if (idUser) {
      return axios.put(`https://findally-backend.herokuapp.com/update/cv/${cv._id}`, form)
        .then((res) => {
          console.log(res.data)
          toast.success("CV actualizado correctamente", {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          window.location.href = `/consultor/profile/${idUser}`
        })
        .catch((err) => {
          console.log(err)
        })
    }
    axios.post(`https://findally-backend.herokuapp.com/api/user/readCVFromBody`, {
      usuario: user.id,
      infoCV: form
    })
      .then((res) => {
        console.log(res.data)
        toast.success("CV creado correctamente", {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        window.location.href = `/consultor/profile/${user.id}`
      })
      .catch((err) => {
        console.log(err)
      })
  }

  return (
    <div>
      <Sidebar location={"unilever home"}/>
      <div
        className="BodyWeb"
        style={{
          padding: "0rem 2rem",
          position: "absolute",
          right: "0",
        }}
      >
        <Navbar locate={"home"}/>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
          }}
        >
          <div>
            <div className={"card-grupo-preguntas2"}>
              <div className={"new-empresa-home-banner"}>
                <h1>
                  {lang === "es"
                    ? "¡Estas a un paso de encontrar al EMPLEO ideal!"
                    : "You are one step away from finding the ideal JOB!"}
                </h1>
              </div>
              <div style={{padding: "10px 20px"}}>
                <h2 className={"banner-crear-oportunidad-st"}>
                  {lang === "es"
                    ? "Por favor, lee y responde el siguiente formulario"
                    : "Please read and answer the next form"}
                </h2>
                <h3 className={"Obligatorio"}>
                  {lang === "es"
                    ? "* Campos obligatorios"
                    : "* Required Fields"}
                </h3>
              </div>
            </div>
            <hr/>

            <div className={"card-grupo-preguntas"}>
              <h3 className="title-crear-oportunidad">
                {lang === "es" ? "Nombre" : "Name"}
                <span className={"Obligatorio"}> *</span>
              </h3>
              <input
                className={"input-crear-oportunidad"}
                name={"nombre"}
                value={form?.info_personal?.nombre}
                onChange={(e) => handleFormInfoPersonal(e, setForm, form)}
              />

              <h3 className="title-crear-oportunidad">
                {lang === "es" ? "Fecha de nacimiento" : "Birthdate"}
                <span className={"Obligatorio"}> *</span>
              </h3>
              <input
                type="date"
                className={"input-crear-oportunidad"}
                style={{width: "100%"}}
                name={"fecha_nacimiento"}
                value={form?.info_personal?.fecha_nacimiento}
                onChange={(e) => handleFormInfoPersonal(e, setForm, form)}
              />
              <h3 className="title-crear-oportunidad">
                {lang === "es" ? "Género" : "Genre"}
                <span className={"Obligatorio"}> *</span>
              </h3>
              {editable &&
                <Select
                  options={generos}
                  onChange={(e) => handleFormGenre(e, setForm, form)}
                  defaultValue={{value: form?.info_personal?.sexo, label: form?.info_personal?.sexo}}
                />
              }
              {!editable &&
                <Select
                  options={generos}
                  onChange={(e) => handleFormGenre(e, setForm, form)}
                />
              }

              <h3 className="title-crear-oportunidad">
                {lang === "es" ? "Profesión Actual" : "Actual profession"}
                <span className={"Obligatorio"}> *</span>
              </h3>
              {editable &&
                <Select
                  options={profesions.map(
                    (profesion) => ({value: profesion?.name, label: profesion?.name})
                  )}
                  defaultValue={{
                    value: form?.info_personal?.profesion_actual,
                    label: form?.info_personal?.profesion_actual
                  }}
                  onChange={(e) => handleFormProfesion(e, setForm, form)}/>
              }
              {!editable &&
                <Select
                  options={profesions.map(
                    (profesion) => ({value: profesion?.name, label: profesion?.name})
                  )}
                  onChange={(e) => handleFormProfesion(e, setForm, form)}
                />
              }
              <h3 className="title-crear-oportunidad">
                {lang === "es" ? "Departamento de nacimiento" : "Department of birth"}
              </h3>
              <input
                className={"input-crear-oportunidad"}
                name={"departamento_nacimiento"}
                value={form?.info_personal?.departamento_nacimiento}
                onChange={(e) => handleFormInfoPersonal(e, setForm, form)}
              />

              <h3 className="title-crear-oportunidad">
                {lang === "es" ? "País de nacimiento" : "Country of birth"}
              </h3>
              <input
                className={"input-crear-oportunidad"}
                name={"pais_nacimiento"}
                value={form?.info_personal?.pais_nacimiento}
                onChange={(e) => handleFormInfoPersonal(e, setForm, form)}
              />

              <h3 className="title-crear-oportunidad">
                {lang === "es" ? "Municipio de nacimiento" : "Municipality of birth"}
              </h3>
              <input
                className={"input-crear-oportunidad"}
                name={"municipio_nacimiento"}
                value={form?.info_personal?.municipio_nacimiento}
                onChange={(e) => handleFormInfoPersonal(e, setForm, form)}
              />
              <hr/>

              <h3 className="title-crear-oportunidad">
                {lang === "es" ? "Ubicación actual" : "Current location"}
              </h3>
              <input
                className={"input-crear-oportunidad"}
                name={"ubicacion"}
                value={form?.info_personal?.ubicacion}
                onChange={(e) => handleFormInfoPersonal(e, setForm, form)}
              />
              <h3 className="title-crear-oportunidad">
                Linkedin
              </h3>
              <input
                className={"input-crear-oportunidad"}
                name={"linkedin"}
                value={form?.contacto?.linkedin}
                onChange={(e) => handleFormContacto(e, setForm, form)}
              />
              <h3 className="title-crear-oportunidad">Email</h3>
              <input
                className={"input-crear-oportunidad"}
                name={"correo"}
                value={form?.contacto?.correo ? form?.contacto?.correo : user?.email}
                onChange={(e) => handleFormContacto(e, setForm, form)}
              />
              <h3 className="title-crear-oportunidad">{lang === 'es' ?  'Telefono' : 'Phone'}</h3>
              <PhoneInput
                  country={'co'}
                  regions={'america'}
                  enableLongNumbers={true}
                  countryCodeEditable={false}
                  placeholder={lang === "es" ? 'Teléfono' : 'Phone number'}
                  inputStyle={{
                    border: '1px solid #C5C5C5',
                    borderRadius: '0px',
                    width: '100%',                    
                    fontstyle: 'normal',
                    fontWeight: '400',
                    height: '41px',
                    fontSize: '16px',
                  }}
                  name={"telefono"}
                  onChange={
                    (phone, country, event, formattedValue) => {
                      setForm({
                        ...form,
                        contacto: {
                          ...form.contacto,
                          telefono: formattedValue
                        }
                      })
                    }
                  }
                  value={form?.contacto?.telefono ? form?.contacto?.telefono : user?.phoneNumber}
              />      
            </div>
            <hr/>

            {/*tipo de servicio*/}
           <div className={"card-grupo-preguntas"}>
              {/* <h3 className="title-crear-oportunidad">
                {lang === "es" ? "Aspiración salarial" : "Base salary"}
                <span className={"Obligatorio"}> *</span>
              </h3>
              <Select 
              options= {monedas}
              placeholder={lang === 'es' ? 'Selecciona la divisa que quieres usar' : 'Select the currency you want to use'}
              isSearchable={true}
              isClearable={true}
              value={divisa ? { value: divisa, label: divisa } : ''}
              onChange={(e) => {
                setDivisa(e.value)
                setForm({
                  ...form,
                  aspiracion_salarial: `${e.value} ${montoMin}`
                })
              }}           
              />
              <input
                className={"input-crear-oportunidad"}
                type={"text"}
                placeholder={
                  lang === "es" ? "Escriba el salario" : "Write the salary"
                }
                name={"aspiracion_salarial"}
                value={montoMin ? montoMin : ''}
                onKeyPress={(e) => {
                  if (!/[0-9]/.test(e.key)) {
                    e.preventDefault();
                  }
                }}
                onChange={(e) => {
                  if (!divisa) {
                    return toast.error(lang === 'es' ? 'Por favor selecciona una divisa' : 'Please select a currency', preset)
                  }
                  setMontoMin(e.target.value)
                  return setForm({
                    ...form,
                    aspiracion_salarial: `${divisa} ${e.target.value}`
                  })
                }}
              /> */}
              <h3 className="title-crear-oportunidad">
              {lang === "es"
                  ? "Descripción sobre ti"
                  : "Description about you"}
                <span className={"Obligatorio"}> *</span>
              </h3>
              <textarea
                className={"text-crear-oportunidad"}
                name={"extracto"}
                value={form?.extracto}
                onChange={(e) => handleFormChange(e, setForm, form)}
              />
            </div> 

            <hr/>

            {/*data empleado*/}
            <div className={"card-grupo-preguntas"}>
              <h3 className="title-crear-oportunidad">
                {lang === "es"
                  ? "Selecciona las habilidades blandas que más te identifican."
                  : "Select the soft skills that most identify you."}
                <span className={"Obligatorio"}> *</span>
              </h3>
              <p className={"item-crear-oportunidad"}>
                {lang === "es"
                  ? "Puedes seleccionar varias de la lista"
                  : "You can select several from the list"}
              </p>
              {editable &&
                <Select
                  isMulti
                  options={lang === "es" ? habilidades_blandas : softSkills}
                  placeholder={lang === "es" ? "Selecciona las habilidades" : "Select soft skills"}
                  isSearchable={true}
                  isClearable={true}
                  isOptionDisabled={() => form?.aptitudes_principales?.length >= 5}
                  defaultValue={form?.aptitudes_principales?.map((item) => {
                    return {
                      value: item,
                      label: item,
                    };
                  })}
                  onChange={(selectedOptions) => handleAptitudChange(selectedOptions, setForm, form)}
                />
              }
              {!editable && (
                <Creatable
                  isMulti
                  options={lang === "es" ? habilidades_blandas : softSkills}
                  placeholder={
                    lang === "es"
                      ? "Selecciona o escribe las habilidades blandas"
                      : "Select or write soft skills"
                  }
                  isSearchable={true}
                  isClearable={true}
                  // isOptionDisabled={() => form.habilidades_blandas.length >= 5}
                  formatCreateLabel={
                    lang === "es"
                      ? (inputValue) => `Agregar "${inputValue}"`
                      : (inputValue) => `Add "${inputValue}"`
                  }
                  onChange={(e) => handleAptitudChange(e, setForm, form)}
                />
              )}

              <h3 className="title-crear-oportunidad">
                {lang === "es"
                  ? "Idiomas"
                  : "Languages"}
                <span className={"Obligatorio"}> *</span>
              </h3>

              <div style={{marginBottom: "20px"}}>
                {form?.languages?.length > 0 ?
                  form?.languages?.map((item, index) => {
                    if (typeof item === 'object') {
                      return (
                        <div key={index} style={{marginRight: '20px', marginBottom: '20px'}}>
                          <Chip
                            sx={{
                              background: '#E5DCF7',
                              borderRadius: '5px',
                              fontSize: '14px',
                              color: '#202256',
                              padding: '10px',
                            }}
                            label={item.Language + '-' + item.Nivel}
                            onDelete={() => handleDelete(item.Language + item.Nivel)}
                            style={{textTransform: 'capitalize'}}/>
                        </div>)
                    } else {
                      return null
                    }
                  })
                  : null}
              </div>
              <div style={{marginBottom: '20px'}}>
                <Select
                  styles={{
                    container: (provided) => ({
                      ...provided,
                      padding: '0px 10px 0px 0px'
                    }),
                  }}
                  options={lang === 'es' ? seleccionLenguajes : selectionLanguages}
                  placeholder={lang === 'es' ? `Selecciona el idioma` : `Select the language`}
                  isSearchable={true}
                  isClearable={true}
                  onChange={e => setLanguages({
                    ...languages,
                    Language: e === null ? '' : e.value
                  })
                  }
                />
                <div style={{marginBottom: '20px'}}>
                  <Select
                    options={lang === 'es' ? nivelLenguaje : levelLanguage}
                    placeholder={lang === 'es' ? `Selecciona el nivel` : `Select the level`}
                    isSearchable={true}
                    isClearable={true}
                    onChange={e => setLanguages({
                      ...languages,
                      Nivel: e === null ? '' : e.value
                    })
                    }
                  />
                  <button
                    className='button-add-language'
                    disabled={languages.Language === '' || languages.Nivel === ''}
                    onClick={() => {
                      if (!isLanguageExists(languages?.Language)) {
                        setForm({
                          ...form,
                          languages: [...form?.languages, {Language: languages?.Language, Nivel: languages?.Nivel}]
                        });
                      }
                    }}
                  >{lang === 'es' ? 'Agregar' : 'Add'}</button>
                </div>
              </div>
            </div>
            <hr/>

            <div className={"card-grupo-preguntas"}>
              <h3 className="title-crear-oportunidad">
                {lang === "es"
                  ? "Certificaciones de Formación Oficiales"
                  : "Official Training Certifications"}
                <span className={"Obligatorio"}> *</span>
              </h3>
              <button className="button-add-cv-form"
                      onClick={() => addCertificado()}
              >
                {lang === "es" ? "Agregar Certificado" : "Add Certificate"}
              </button>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                {certificados?.length > 0 &&
                  certificados.map((item, index) => {
                    return (
                      <div
                        key={index}
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                          alignItems: "center",
                          marginBottom: "10px",
                        }}
                      >
                        <input
                          className={"input-crear-oportunidad"}
                          type="text"
                          name={`certificado${index}`}
                          placeholder={lang === "es" ?
                            "Ej. Microsoft Certified Network Associate Security" :
                            "E.g. Microsoft Certified Network Associate Security"}
                          value={certificados[index]}
                          onChange={(e) => handleCertificadoChange(e, index)}
                        />
                        <button
                          style={{
                            width: "40px",
                            height: "40px",
                            backgroundColor: "#27165b",
                            color: "white",
                            border: "none",
                            borderRadius: "5px",
                            marginBottom: "10px",
                            marginTop: "10px",
                          }}
                          onClick={(e) => removeCertificado(index)}
                        >
                          X
                        </button>
                      </div>
                    );
                  })}
              </div>

              <h3 className="title-crear-oportunidad">
                {lang === "es" ? "Educación" : "Education"}
                <span className={"Obligatorio"}> *</span>
              </h3>
              <button
                className="button-add-cv-form"
                onClick={(e) => addEducacion(e)}
              >
                {lang === "es" ? "Agregar Educación" : "Add Education"}
              </button>
              {educacion &&
                educacion?.length >= 0 &&
                educacion.map((item, index) => {
                  return (
                    <div key={index}>
                      <div className="container-eliminar-experiencia">
                        <button className="button-eliminar-experiencia"
                                onClick={(e) => removeEducacion(index)}
                        >
                          {lang === "es" ? "Eliminar" : "Delete"}
                        </button>
                        <h3
                          style={{fontWeight: "400"}}
                          className="title-crear-oportunidad"
                        >
                          {lang === "es"
                            ? "Institución"
                            : "Education institution"}
                        </h3>
                      </div>
                      <input
                        className={"input-crear-oportunidad"}
                        type="text"
                        name={`institucion`}
                        placeholder={
                          lang === "es" ? "Institución" : "Education"
                        }
                        value={educacion[index]?.NombreInstitucion}
                        onChange={(e) =>
                          handleEducacionChange(e, index, "NombreInstitucion")
                        }
                      />
                      <h3
                        style={{fontWeight: "400"}}
                        className="title-crear-oportunidad"
                      >
                        {lang === "es" ? "Titulo" : "Career title"}
                      </h3>
                      <input
                        className={"input-crear-oportunidad"}
                        type="text"
                        name={`titulo`}
                        placeholder={lang === "es" ? "Título" : "Career title"}
                        value={educacion[index]?.Titulo_Certificacion}
                        onChange={(e) =>
                          handleEducacionChange(
                            e,
                            index,
                            "Titulo_Certificacion"
                          )
                        }
                      />
                      <h3
                        style={{fontWeight: "400"}}
                        className="title-crear-oportunidad"
                      >
                        {lang === "es"
                          ? "Fecha de inicio"
                          : "Start date"}
                      </h3>
                      <input
                        className={"input-crear-oportunidad"}
                        type="date"
                        name={`fecha_inicio`}
                        placeholder={
                          lang === "es" ? "Fecha Inicio" : "Start date"
                        }
                        defaultValue={educacion[index]?.fecha_inicio}
                        onChange={(e) => handleEducacionChange(e, index, 'fecha_inicio')}
                      />
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          width: '20%',
                          marginTop: '10px',
                        }}
                      >
                        <h3 style={{
                          fontWeight: "400",
                          fontSize: '14px',
                          color: '#151515',
                        }}
                        >
                          {lang === "es"
                            ? "Actualmente estudiando"
                            : "Currently studying"}
                        </h3>
                        <input
                          type="checkbox"
                          name={`actualStudy`}
                          onChange={(e) => handleActualStudy(e, index)}
                          checked={educacion[index]?.actualStudy}
                        />
                      </div>
                      {!educacion[index]?.actualStudy &&
                        <>
                          <h3
                            style={{fontWeight: "400"}}
                            className="title-crear-oportunidad"
                          >
                            {lang === "es"
                              ? "Fecha de finalización"
                              : "Finish date"}
                          </h3>
                          <input
                            className={"input-crear-oportunidad"}
                            type="date"
                            name={`fecha_fin`}
                            placeholder={
                              lang === "es" ? "Fecha finalización" : "Finish date"
                            }
                            value={educacion[index]?.fecha_finalizacion}
                            onChange={(e) =>
                              handleEducacionChange(e, index, "fecha_finalizacion")
                            }
                          />
                        </>
                      }
                      <h3
                        style={{fontWeight: "400"}}
                        className="title-crear-oportunidad"
                      >
                        {lang === "es" ? "Tipo de titulo" : "Degree title"}
                      </h3>
                      <Select
                        options={lang === "es" ? nivelFormacion : levelEducation}
                        onChange={(e) => handleEducacionChange(e, index, "TipoEduacion")}
                        isClearable
                        isSearchable
                        value={educacion[index]?.TipoEduacion ?
                          {value: educacion[index]?.TipoEduacion, label: educacion[index]?.TipoEduacion} : null}
                      />
                      <hr/>
                    </div>
                  );
                })}
            </div>
            <hr/>

            <div className={"card-grupo-preguntas"}>
              <h3 className="title-crear-oportunidad">
                {lang === "es" ? "Experiencia" : "Experience"}
              </h3>
              <button
                className="button-add-cv-form"
                onClick={(e) => addExperience(e)}
              >
                {lang === "es" ? "Agregar Experiencia" : "Add Experience"}
              </button>
              {experiencia &&
                experiencia?.length >= 0 &&
                experiencia.map((item, index) => {
                  return (
                    <div key={index}>
                      <div className="container-eliminar-experiencia">
                        <button className="button-eliminar-experiencia"
                                onClick={(e) => removeExperience(index)}
                        >
                          {lang === "es" ? "Eliminar" : "Delete"}
                        </button>
                        <h3
                          style={{fontWeight: "400"}}
                          className="title-crear-oportunidad"
                        >
                          {lang === "es"
                            ? "Empresa"
                            : "Company"}
                        </h3>
                      </div>
                      <input
                        className={"input-crear-oportunidad"}
                        type="text"
                        name={`empresa`}
                        placeholder={lang === "es" ? "Empresa" : "Company"}
                        defaultValue={experiencia[index]?.Nombreempresa}
                        onChange={(e) => handleExperienceChange(e, index, 'Nombreempresa')}
                      />
                      <h3
                        style={{fontWeight: "400"}}
                        className="title-crear-oportunidad"
                      >
                        {lang === "es" ? "Cargo" : "Position"}
                      </h3>
                      {item?.cargos?.length > 0 &&
                        item.cargos.map((cargo, cargoIndx) => (
                          <div key={cargoIndx}>
                            <input
                              className={"input-crear-oportunidad"}
                              type="text"
                              name={`cargo`}
                              placeholder={lang === "es" ? "Cargo" : "Position"}
                              defaultValue={
                                experiencia[index]?.cargos[cargoIndx]?.nombrecargo
                              }
                              onChange={(e) =>
                                handleCargoChange(
                                  e,
                                  index,
                                  cargoIndx,
                                  "nombrecargo"
                                )
                              }
                            />
                            <h3
                              style={{fontWeight: "400"}}
                              className="title-crear-oportunidad"
                            >
                              {lang === "es"
                                ? "Ubicación"
                                : "Location"}
                            </h3>
                            <input
                              className={"input-crear-oportunidad"}
                              type="text"
                              name={`ubicacion`}
                              placeholder={lang === "es" ? "Ubicación" : "Location"}
                              defaultValue={experiencia[index]?.cargos[cargoIndx]?.ubicacion}
                              onChange={(e) =>
                                handleCargoChange(
                                  e,
                                  index,
                                  cargoIndx,
                                  "ubicacion"
                                )}
                            />
                          </div>
                        ))}
                      <h3
                        style={{fontWeight: "400"}}
                        className="title-crear-oportunidad"
                      >
                        {lang === "es"
                          ? "Fecha de inicio"
                          : "Start date"}
                      </h3>
                      <input
                        className={"input-crear-oportunidad"}
                        type="date"
                        name={`fecha_inicio`}
                        placeholder={
                          lang === "es" ? "Fecha Inicio" : "Start date"
                        }
                        defaultValue={experiencia[index]?.fecha_inicio}
                        onChange={(e) => handleExperienceChange(e, index, 'fecha_inicio')}
                      />
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          width: '20%',
                          marginTop: '10px',
                        }}
                      >
                        <h3 style={{
                          fontWeight: "400",
                          fontSize: '14px',
                          color: '#151515',
                        }}
                        >
                          {lang === "es"
                            ? "Trabajo actual"
                            : "Current job"}
                        </h3>
                        <input
                          type="checkbox"
                          name={`actualJob`}
                          onChange={(e) => handleActualJobChange(e, index)}
                          checked={experiencia[index]?.actualJob}
                        />
                      </div>
                      {
                        !experiencia[index]?.actualJob &&
                        <>
                          <h3
                            style={{fontWeight: "400"}}
                            className="title-crear-oportunidad"
                          >
                            {lang === "es"
                              ? "Fecha de finalización"
                              : "Finish date"}
                          </h3>
                          <input
                            className={"input-crear-oportunidad"}
                            type="date"
                            name={`fecha_fin`}
                            placeholder={
                              lang === "es" ? "Fecha Inicio" : "Start date"
                            }
                            defaultValue={experiencia[index]?.fecha_finalizacion}
                            onChange={(e) => handleExperienceChange(e, index, 'fecha_finalizacion')}
                          />
                        </>
                      }

                      <hr/>
                    </div>
                  );
                })}
            </div>
            <hr/>

            <button
              onClick={(e) => {
                console.log("1", JSON.stringify(form));
                return handleSubmit(e, form, setForm, lang, user);
              }}
              className={"submit-button-crear-oportunidad"}
              disabled={isSubmit}
            >
              {editable
                ? lang === "es"
                  ? "Actualizar Perfil"
                  : "Update profile"
                : lang === "es"
                  ? "Cargar Cv"
                  : "Upload Cv"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewCVForm;
