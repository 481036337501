import React, { useEffect, useState } from "react";
import { SchneiderContext } from "./SchneiderContext";
import { SchneiderInfo } from "./interfaces/schneiderInterface";
import { fetchSchneiderData } from "./utils/fetchData";
import { GlobalStyle } from "../styles/GlobalStyles";

interface SchneiderProviderProps {
  children: React.ReactNode;
}

const SchneiderProvider: React.FC<SchneiderProviderProps> = ({ children }) => {
  const [schneider, setSchneider] = useState<SchneiderInfo | undefined>({
    name: "",
    address: "",
    city: "",
    country: "",
    color: {
      primary: "",
      secondary: "",
      tertiary: "",
      quaternary: "",
    },
    oportunidades: [],
    usuarios: [],
    logo: "",
    route: "",
  });

  useEffect(() => {
    const schneider = JSON.parse(localStorage.getItem("schneider") || "{}");
    if (schneider && schneider.name) {
      setSchneider(schneider);
    } else {
      fetchSchneiderData().then((data) => {
        localStorage.setItem("schneider", JSON.stringify(data));
        setSchneider(data);
      });
    }
  }, []);

  return (
    <SchneiderContext.Provider value={{ schneider }}>
      <GlobalStyle color={schneider.color.primary} />
      {schneider && schneider.name && children}
    </SchneiderContext.Provider>
  );
};

export default SchneiderProvider;
