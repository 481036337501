import React from 'react'
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import '../Styles/CardPlanActual.css'

export const CardPlanActual = ({ lang }) => {
  const { userPlan } = useSelector((state) => state);

  const planLanguage = (plan) => {
    return plan === 'Plan empresarial' ? 'Business plan' : plan === 'Plan Basico' ? 'Standard plan' : 'Free plan'
  }


  return (
    <div className='container-card-plan'>
      <div className='Subcontainer-CardPlan'>
        <h3 className='Card-Title-Plan'>
          {planLanguage(userPlan.nombre_paquete)}
        </h3>
        <Link to='/company/pricing'>
        <button className={'CardPlan-Button'}>
          {lang === 'es' ? 'Mejora tu cuenta' : 'Upgrade your account'}
        </button>
        </Link>
      </div>
    </div>
  )
}
