import styled from "styled-components";

const FirstFilterLabelContainer = styled.div`
  background: #ffffff;
  box-shadow: 0 7px 32px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
`;

const FirstCardPreguntaContainer = styled.div`
  background: #ffffff;
  box-shadow: 0 7px 32px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 10px 20px;
`;

const CheckboxContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

export {
  FirstFilterLabelContainer,
  FirstCardPreguntaContainer,
  CheckboxContainer,
};
