/* eslint-disable  */
// @ts-nocheck
import React, { useEffect, useState, useContext } from "react";
import "../../../../profesionalpag/PerfilProfesional/PerfilProfesional.css";
import Sidebar from "../../../components/sidebar/Sidebar";
import Navbar from "../../../components/navbar/Navbar";
import { Link } from "react-router-dom";
import larrow from "../../../../assets/icons/l-arrow.svg";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { LoadCv, loginReq, tourStatus } from "../../../../actions";
import work from "../../../../assets/icons/work-image.svg";
import upload from "../../../../assets/icons/Upload.svg";
/*import download from "../../../../assets/icons/download-icon.svg";*/
import file from "../../../../assets/icons/file.svg";
import CardUser from "./CardUser";
import OportunidadIdiomaCard from "../../../../profesionalpag/PerfilProfesional/sideCards/oportunidadIdiomaCard";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { CVdocument } from "../../../../utils/CvCreator";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
/*import { getUserInfo } from "../../../../actions";*/
/*import { AffindaAPI, AffindaCredential } from "@affinda/affinda";*/
/*import Spinner from "react-bootstrap/Spinner";*/
import { ReduxStateInterface } from "../../../types/reduxInterface";
import { CustomizableTitle } from "../../../styles/TitleStyles";
import { SchneiderContext } from "../../../context/SchneiderContext";
import { ProfileDownloadImageContainer, FileDetailsContainer, UploadFileProfileButton } from "./ProfileStyles";
import TourProfessional from "../../../components/tour/TourProfessional";
import {TutorialPopupContainer, TutorialSpan} from "../../../components/sidebar/SidebarStyles";
import ReactPlayer from "react-player";
import {usePopupContext} from "../../../context/popups/PopupContext";
import Popup from "../../../components/popup/Popup";

export default function ProfileSchneider() {
  const { popups, addPopup, removePopup } = usePopupContext();
  const { schneider } = useContext(SchneiderContext);
  const dispatch = useDispatch();
    useState(false);
  const [ready, setReady] = useState(false);
  /*const [loading, setLoading] = useState(false);*/
  //eslint-disable-next-line
  /*const [popupPersonalidad, setPopUpPersonalidad] = useState(false);*/

  /*Affinda credentials*/
  /*const credential = new AffindaCredential(
    "1d620a1accfdd611db6a5f740f2df79118a80cae"
  );*/
/*  const client = new AffindaAPI(credential);*/

  const { lang, user, cv, tourViewed } = useSelector(
    (state: ReduxStateInterface) => state
  );

  const [width, setWidth] = useState(window.innerWidth);
  const updateWidthAndHeight = () => {
    setWidth(window.innerWidth);
  };
  useEffect(() => {
    window.addEventListener("resize", updateWidthAndHeight);
    return () => window.removeEventListener("resize", updateWidthAndHeight);
  }, [width]);


  useEffect(() => {
    async function fetchData() {
      if (user.id && !cv) {
        try {
          const res = await axios.get<any>(
            `https://findally-backend.herokuapp.com/user/CVprofile/${user.id}`
          );
          console.log(res)
          dispatch(LoadCv(res.data.cv_file) as any);
          setReady(true);
        } catch (err) {
          console.log(err);
          setReady(true);
        }
      }
    }

    fetchData();
  }, [dispatch, user.id, cv]);

  useEffect(() => {
    const showAdvise = Boolean(localStorage.getItem("show-advise"));
    if(showAdvise){
      localStorage.removeItem("show-advise")
      addPopup({
        id: "edit-profile-advise",
        content: (
          <div>
            <CustomizableTitle color={schneider.color.primary} size={'20px'}>
              {lang === "es" ? "Por favor haz click en 'Editar perfil' y completa los campos faltantes." : "Please click on 'Edit profile' and complete the missing fields."}
            </CustomizableTitle>
          </div>
        ),
        onClose: () => removePopup({ id: "edit-profile-advise" }),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [selectedFile, setSelectedFile] = useState(null);
  const [isFilePicked, setIsFilePicked] = useState(false);
  const [selectedFile2, setSelectedFile2] = useState(null);
  const [isFilePicked2, setIsFilePicked2] = useState(false);

  const changeHandler = (e: any) => {
    setSelectedFile(e.target.files[0]);
    const fileData = new FormData();
    fileData.append("upload_file", e.target.files[0]);
    fileData.append("usuario", user.id);
    axios
      .post<any>(
        `https://findally-backend.herokuapp.com/api/user/validateCV?lang=${lang}`,
        fileData
      )
      .then((res) => {
        if (res.status === 200) {
          toast.success(lang === "es" ? "Tu CV ha sido Validado, haz click en el botón 'Subir' para guardar los cambios" : "Your CV has been validated, click on the 'Upload' button to save the changes", {
            position: "top-center",
            autoClose: 10000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          })
        }})
      .catch((err) => {
        if (err.response.status === 400) {
          setSelectedFile(null);
          toast.error(
            lang === "es"
              ? "Formato invalido de cv linkedin"
              : "Invalid linkedin cv format",
            {
              position: "top-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            }
          );
        }
        // setMessage(true)
      });
  };

 /* const changeHandler2 = (e: any) => {
    setSelectedFile2(e.target.files[0]);
    setIsFilePicked2(true);
  };*/

  const handleUpload = () => {
    const fd = new FormData();
    fd.append("upload_file", selectedFile);
    fd.append("usuario", user.id);
    axios
      .post("https://findally-backend.herokuapp.com/api/user/readCv", fd)
      .then(() => {
        localStorage.setItem("show-advise", "true");
       window.location.reload()
      })
      .catch((err) => {
        console.log(err);
        return toast.error(
          lang === "es"
            ? "Hubo un error al subir tu cv"
            : "There was an error uploading your cv",
          {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          }
        );
      });
  };

  /*const handleUpload2 = () => {
   /!* setLoading(true);*!/
    const fd2 = new FormData();
    fd2.append("upload_file", selectedFile2);
    fd2.append("usuario", user.id);
    axios
      .post<any>(
        "https://findally-backend.herokuapp.com/api/user/readCvPersonalized",
        fd2
      )
      .then((res) => {
        if (res.status === 200) {
          /!*Affinda Api client*!/
          client
            .createResume({ file: fd2.get("upload_file") })
            .then((r: any) => {
              toast
                .promise(
                  axios.post(
                    `https://findally-backend.herokuapp.com/api/user/readCVFromBody`,
                    {
                      usuario: user.id,
                      infoCV: {
                        info_personal: {
                          nombre: r?.data?.name
                            ? r.data.name?.first
                              ? r?.data?.name?.last
                                ? r?.data?.name?.first +
                                  " " +
                                  r?.data?.name?.last
                                : r?.data?.name?.first
                              : r?.data?.name?.last
                            : "-",
                          sexo: "-",
                          profesion_actual: r?.data?.profession
                            ? r?.data?.profession
                            : "-",
                          fecha_nacimiento: r?.data?.dateOfBirth
                            ? r?.data?.dateOfBirth
                            : "-",
                          pais_nacimiento: r?.data?.location
                            ? r?.data?.location.country
                              ? r?.data?.location.country
                              : "-"
                            : "-",
                          departamento_nacimiento: r?.data?.location
                            ? r?.data?.location.state
                              ? r?.data?.location.state
                              : "-"
                            : "-",
                          municipio_nacimiento: r?.data?.location
                            ? r?.data?.location.state
                              ? r?.data?.location.state
                              : "-"
                            : "-",
                          ubicacion: r?.data?.location
                            ? r?.data?.location.country
                              ? r?.data?.location.country
                              : "-"
                            : "-",
                        },
                        contacto: [
                          r.data?.phoneNumbers ? r.data.phoneNumbers[0] : null,
                          r.data?.emails ? r.data.emails[0] : null,
                          r.data?.websites ? r.data.websites[0] : null,
                        ],
                        aptitudes_principales: r?.data?.skills
                          ? r?.data?.skills.map((skill: any) => {
                              return skill.name;
                            })
                          : [],
                        languages: r?.data?.languages
                          ? r?.data?.languages.map((language: any) => {
                              return language;
                            })
                          : [],
                        certifications: r?.data?.certifications
                          ? r?.data?.certifications.map(
                              (certification: any) => {
                                return certification;
                              }
                            )
                          : [],
                        extracto: r?.data?.summary ? r?.data?.summary : "-",
                        experiencia: r?.data?.workExperience
                          ? r?.data?.workExperience.map((experience: any) => {
                              return {
                                Nombreempresa: experience?.organization
                                  ? experience?.organization
                                  : "-",
                                fecha_inicio: experience?.dates
                                  ? experience?.dates.startDate
                                    ? experience?.dates.startDate
                                    : "-"
                                  : "-",
                                fecha_finalizacion: experience?.dates
                                  ? experience?.dates.endDate
                                    ? experience?.dates.endDate
                                    : "-"
                                  : "-",
                                totalDuracion: experience?.dates
                                  ? experience?.dates.monthsInPosition !== null
                                    ? experience?.dates.monthsInPosition
                                    : "-"
                                  : "-",
                                actualJob: experience?.dates
                                  ? experience?.dates.isCurrent
                                    ? experience?.dates.isCurrent
                                    : false
                                  : false,
                                cargos: [
                                  {
                                    nombrecargo: experience?.jobTitle
                                      ? experience?.jobTitle
                                      : "-",
                                    duracion: experience?.dates
                                      ? experience?.monthsInPosition !== null
                                        ? experience?.monthsInPosition
                                        : "-"
                                      : "-",
                                    ubicacion: experience?.location
                                      ? experience?.location.country
                                      : "-",
                                    descripcion: experience?.jobDescription
                                      ? experience?.jobDescription
                                      : "-",
                                  },
                                ],
                              };
                            })
                          : [],
                        educacion: r?.data?.education
                          ? r?.data?.education.map((education: any) => {
                              return {
                                NombreInstitucion: education?.organization
                                  ? education?.organization
                                  : "-",
                                TipoEduacion: education?.accreditation
                                  ? education?.accreditation.educationLevel
                                    ? education?.accreditation.educationLevel
                                    : "-"
                                  : "-",
                                Titulo_Certificacion: education?.accreditation
                                  ? education?.accreditation.education
                                    ? education?.accreditation.education
                                    : "-"
                                  : "-",
                                fecha_finalizacion: education?.dates
                                  ? education?.completionDate !== null
                                    ? education?.completionDate
                                    : "-"
                                  : "-",
                                duracion: education?.dates
                                  ? education?.monthsInPosition !== null
                                    ? education?.monthsInPosition
                                    : "-"
                                  : "-",
                              };
                            })
                          : [],
                      },
                    }
                  ),
                  {
                    pending:
                      lang === "es" ? "Leyendo tu cv..." : "Reading your cv...",
                    success:
                      lang === "es"
                        ? "Tu cv se ha leido correctamente"
                        : "Your cv has been read correctly",
                    error:
                      lang === "es"
                        ? "Hubo un error al subir tu cv"
                        : "There was an error uploading your cv",
                  }
                )
                .then(() => {
                  /!*setLoading(false);*!/
                  dispatch(getUserInfo(user.id) as any);
                });
            });
        }
      })
      .catch((err) => {
        /!*setLoading(false);*!/
        console.log(err);
        return toast.error(
          lang === "es"
            ? "Hubo un error al subir tu cv"
            : "There was an error uploading your cv",
          {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          }
        );
      });
  };*/

  const handleCancel = (e: any) => {
    e.preventDefault();
    setSelectedFile(null);
    setIsFilePicked(false);
    setSelectedFile2(null);
    setIsFilePicked2(false);
  };
  const handleCancel1 = (e: any) => {
    e.preventDefault();
    setSelectedFile(null);
    setIsFilePicked(false);
  };
  const handleCancel2 = (e: any) => {
    e.preventDefault();
    setSelectedFile2(null);
    setIsFilePicked2(false);
  };

 /* const handleDownloadATS = () => {
    if (cv?.usuario) {
      document.getElementById("download456").click();
    }
  };*/
  useEffect(() => {
    if (tourViewed === true) {
      return;
    }
    setTimeout(() => {
      dispatch(tourStatus({ run: true }));
    }, 1000);
  }, [tourViewed, dispatch]);

  const handleOpenTutorialPopUp = () => {
    addPopup({
      id: "tutorial-cv",
      content: (
        <TutorialPopupContainer>
          <ReactPlayer
            url={"https://youtu.be/9NIF-vD-zvI"}
            controls={true}
            width={"100%"}
            height={"100%"}
          />
        </TutorialPopupContainer>
      ),
      onClose: () => removePopup({ id: "tutorial-cv" }),
    });
  };

  return (
    <>
      {/* <ToastContainer/> */}
      {popups.map((popup) => (
        <Popup key={popup.id} id={popup.id} onClose={popup.onClose}>
          {popup.content}
        </Popup>
      ))}
      <div className={"contenedor-oportunidad"}>
        <Sidebar location={"home"} />
        <div
          className="BodyWeb"
          style={{
            padding: "0rem 2rem",
            position: "absolute",
            right: "0",
          }}
        >
          <Navbar locate={"home"} />
          {/*head perfil: los (links under the hood son un div)*/}
          <Link className={"perfil-prof-header"} to={"/schneider/home"}>
            <img src={larrow} alt={""} />
            <CustomizableTitle
              color={schneider.color.primary}
              size={"40px"}
              margin={"0 0 0 20px"}
              weight={"700"}
            >
              {lang === "es" ? "Perfil" : "Profile"}
            </CustomizableTitle>
          </Link>
          {/*body perfil*/}
          <div className={"perfil-prof-body"}>
            <div className={"perfil-prof-body-izq"}>
              {/*acá van tus componentes y sections @nahuel*/}
              <CardUser user={user} cv={cv} lang={lang} />
              <OportunidadIdiomaCard user={user} cv={cv} lang={lang} />
              {/*<PortfolioCard user={user} cv={cv} lang={lang}/>*/}
            </div>
            <div className={"perfil-prof-body-der"}>
              <div>
                <h1 className={"perfil-prof-nombre"}>
                  {cv?.info_personal?.nombre
                    ? cv?.info_personal?.nombre
                    : lang === "es"
                    ? "Completar tu nombre"
                    : "Complete your name"}
                </h1>
                <h2 className={"perfil-prof-empleo"}>
                  {cv?.info_personal?.profesion_actual
                    ? cv?.info_personal?.profesion_actual
                    : lang === "es"
                    ? "Completar tu profesión"
                    : "complete your profession"}
                </h2>
              </div>
              <div className={"perfil-prof-sub-cont"} style={{boxShadow: "none"}}>
                <h2 className={"perfil-prof-sub-subtitulo"}>
                  {lang === "es" ? "Acerca de" : "About"}
                </h2>
                <p className={"perfil-prof-textos"}>
                  {cv?.extracto
                    ? cv?.extracto
                    : lang === "es"
                    ? "Completar tu extracto"
                    : "Complete your extract"}
                </p>
                <hr />
                {!cv ? <h2 className={"perfil-prof-sub-subtitulo"}>
                  {lang === "es" ? "Hoja de vida" : "Resume"}
                </h2> : null}
                <div className={"contenedor-tour"}>
                  <TourProfessional />
                </div>
                {!cv && <div className={"perfil-cv-input-contenedor-global"} id="cv">
                  {!cv && ready && (
                    <label className={"perfil-cv-input-contenedor"}>
                      <ProfileDownloadImageContainer
                        color={schneider.color.primary}
                      >
                        <img src={upload} alt={"img-upload"}/>
                      </ProfileDownloadImageContainer>

                      <div>
                        <h3 className={"perfil-cv-input-title"}>
                          {lang === "es"
                            ? "Subir CV LinkedIn"
                            : "Upload LinkedIn CV"}
                        </h3>
                        <h3 className={"perfil-cv-input-subtitle"}>PDF</h3>
                      </div>
                      <input
                        onChange={(e) => changeHandler(e)}
                        style={{display: "none"}}
                        type={"file"}
                      />
                    </label>
                  )}

                  {/*<label className={"perfil-cv-input-contenedor"}>
                    <ProfileDownloadImageContainer
                      color={schneider.color.primary}
                    >
                      {!loading ? (
                        <img src={upload} alt={"img-upload"} />
                      ) : (
                        <Spinner animation="border" variant="light" />
                      )}
                    </ProfileDownloadImageContainer>
                    <div>
                      {!loading ? (
                        <>
                          <h3 className={"perfil-cv-input-title"}>
                            {lang === "es"
                              ? "Subir CV general"
                              : "Upload general CV"}
                          </h3>
                          <h3 className={"perfil-cv-input-subtitle"}>PDF</h3>{" "}
                        </>
                      ) : (
                        <>
                          <h3
                            className={"perfil-cv-input-title"}
                            style={{ marginLeft: "-40px" }}
                          >
                            {lang === "es"
                              ? "Leyendo CV ..."
                              : "Reading resume..."}
                          </h3>
                          <h3
                            className={"perfil-cv-input-subtitle"}
                            style={{ marginLeft: "-40px" }}
                          >
                            PDF
                          </h3>
                        </>
                      )}
                    </div>
                    <input
                      onChange={(e) => changeHandler2(e)}
                      style={{ display: "none" }}
                      type={"file"}
                    />
                  </label>*/}

                  {/*{cv && cv?.usuario && (
                    <label
                      className={"perfil-cv-input-contenedor"}
                      onClick={handleDownloadATS}
                    >
                      <ProfileDownloadImageContainer
                        color={schneider.color.primary}
                      >
                        <img src={download} alt={"img-upload"} />
                      </ProfileDownloadImageContainer>
                      <div>
                        <h3 className={"perfil-cv-input-title"}>
                          {lang === "es"
                            ? "Descargar CV ATS"
                            : "Download ATS CV"}
                        </h3>
                        <h3 className={"perfil-cv-input-subtitle"}>PDF</h3>
                      </div>
                    </label>
                  )}*/}
                  {isFilePicked ? (
                    <FileDetailsContainer color={schneider.color.primary}>
                      <ProfileDownloadImageContainer
                        color={schneider.color.primary}
                      >
                        <img src={file} alt={"img-upload"}/>
                      </ProfileDownloadImageContainer>
                      <div>
                        <h3 className={"perfil-cv-input-title2"}>
                          {selectedFile.name}
                        </h3>
                        <h3 className={"perfil-cv-input-subtitle2"}>
                          {selectedFile?.size}KB
                        </h3>
                      </div>
                      <div>
                        <button
                          style={{
                            backgroundColor: "#fff",
                            color: schneider.color.primary,
                            position: "absolute",
                            marginTop: "-50px",
                            fontWeight: 600,
                          }}
                          onClick={(e) => handleCancel1(e)}
                        >
                          X
                        </button>
                      </div>
                    </FileDetailsContainer>
                  ) : null}
                  {isFilePicked2 ? (
                    <div className={"perfil-cv-input-contenedor2"}>
                      <ProfileDownloadImageContainer
                        color={schneider.color.primary}
                      >
                        <img src={file} alt={"img-upload"}/>
                      </ProfileDownloadImageContainer>
                      <div>
                        <h3 className={"perfil-cv-input-title2"}>
                          {selectedFile2.name}
                        </h3>
                        <h3 className={"perfil-cv-input-subtitle2"}>
                          {selectedFile2?.size}KB
                        </h3>
                      </div>
                      <div>
                        <button
                          style={{
                            backgroundColor: "#fff",
                            color: "#202256",
                            position: "absolute",
                            marginTop: "-50px",
                            fontWeight: 600,
                          }}
                          onClick={(e) => handleCancel2(e)}
                        >
                          X
                        </button>
                      </div>
                    </div>
                  ) : null}
                </div>}
                <div style={{ display: "none" }}>
                  <PDFDownloadLink
                    document={<CVdocument user={{ cv_file: cv }} />}
                    fileName={`Cv-${cv?.info_personal?.nombre}.pdf`}
                  >
                    <button
                      id="download456"
                      style={{
                        backgroundColor: "transparent",
                        height: "35px",
                        borderRadius: "5px",
                        borderStyle: "solid",
                        borderWidth: "2px",
                      }}
                    ></button>
                  </PDFDownloadLink>
                </div>
                <div>
                  {ready && !cv && (
                    <>
                    <h2 className={"perfil-cv-advise"}>
                      {lang === "es"
                        ? "Si no tienes Hoja de vida, puedes llenar el"
                        : "If you do not have a CV, you can fill out the"}{" "}
                      {lang === "es" ? (
                        <Link
                          to={`/${schneider.route}/cv`}
                          className={"perfil-cv-advise2"}
                          style={{color: schneider.color.primary}}
                        >
                          siguiente formulario.
                        </Link>
                      ) : (
                        <Link
                          to={`/${schneider.route}/cv`}
                          className={"perfil-cv-advise2"}
                        >
                          following form.
                        </Link>
                      )}
                    </h2>
                      <h2 className={"perfil-cv-advise"}>
                        {lang === 'es' ? 'Si no sabes como descargar una hoja de vida de LinkedIn, mira el siguiente, ' : 'If you do not know how to download a resume from LinkedIn, watch the following, '}
                        <TutorialSpan
                          color={schneider.color.primary}
                          onClick={() => handleOpenTutorialPopUp()}
                        >
                          {lang === "es"
                            ? " Tutorial de descarga"
                            : " Download tutorial"}
                        </TutorialSpan>
                      </h2>
                    </>
                  )}

                  {!cv && <div className={"perfil-cv-button-group"}>
                    <UploadFileProfileButton
                      onClick={() => handleUpload()}
                      disabled={!selectedFile}
                      color={schneider.color.primary}
                    >
                      {lang === "es" ? "Subir" : "Upload"}
                    </UploadFileProfileButton>
                    {/*     {isFilePicked2 ? (
                      <button
                        style={{ backgroundColor: "#F07130" }}
                        onClick={() => handleUpload2()}
                        className={"perfil-cv-subir"}
                      >
                        {lang === "es"
                          ? "Subir cv general"
                          : "Upload general cv"}
                      </button>
                    ) : null}*/}
                    <button
                      onClick={(e) => handleCancel(e)}
                      className={"perfil-cv-cancel"}
                    >
                      {lang === "es" ? "Cancelar" : "Cancel"}
                    </button>
                  </div>}
                </div>
              </div>
              <div className={"perfil-prof-sub-cont"}>
                <h2 className={"perfil-prof-sub-subtitulo"}>
                  {lang === "es" ? "Educación" : "Education"}
                </h2>
                {cv?.educacion &&
                cv.educacion.length > 0 &&
                cv.educacion[0].NombreInstitucion !== "" ? (
                  cv?.educacion?.map((e, i) => {
                    return (
                      <div className={"perfil-edu-container"} key={i}>
                        {e.NombreInstitucion ? (
                          <h3
                            className={"perfil-prof-sub-subtitulo-edu-trabajo"}
                          >
                            {e.NombreInstitucion}
                          </h3>
                        ) : null}
                        {e.Titulo_Certificacion ? (
                          <h3
                            className={"perfil-prof-sub-subtitulo-edu-trabajo2"}
                          >
                            {e.Titulo_Certificacion}
                          </h3>
                        ) : null}
                        {e.TipoEduacion ? (
                          <h3
                            className={"perfil-prof-sub-subtitulo-edu-trabajo2"}
                          >
                            {e.TipoEduacion}
                          </h3>
                        ) : null}
                        {e.fecha_finalizacion ? (
                          <h3
                            className={"perfil-prof-sub-subtitulo-edu-trabajo2"}
                          >
                            {e.fecha_finalizacion}
                          </h3>
                        ) : null}
                        {e.duracion ? (
                          <h3
                            className={"perfil-prof-sub-subtitulo-edu-trabajo3"}
                          >
                            {e.duracion}
                          </h3>
                        ) : null}
                        {i + 1 < cv?.experiencia?.length ? <hr /> : null}
                      </div>
                    );
                  })
                ) : (
                  <p className={"perfil-prof-textos"}>
                    {lang === "es"
                      ? "Completar tu educación"
                      : "Complete your education"}
                  </p>
                )}
                <h2 className={"perfil-prof-sub-subtitulo"}>
                  {lang === "es" ? "Experiencia laboral" : "Work experience"}
                </h2>
                {cv?.experiencia &&
                cv.experiencia.length > 0 &&
                cv.experiencia[0].Nombreempresa !== "" ? (
                  cv?.experiencia?.map((e, i) => {
                    return (
                      <div key={i}>
                        <div className={"perfil-trab-container"}>
                          <div>
                            <img src={work} alt={"asd"} />
                          </div>
                          <div>
                            {e.Nombreempresa ? (
                              <h3
                                className={
                                  "perfil-prof-sub-subtitulo-edu-trabajo"
                                }
                              >
                                {e.Nombreempresa}
                              </h3>
                            ) : null}
                            {e?.cargos?.length
                              ? e.cargos.map((c: any, i: any) => {
                                  return (
                                    <div key={i}>
                                      {c.nombrecargo ? (
                                        <h3
                                          className={
                                            "perfil-prof-sub-subtitulo-edu-trabajo2"
                                          }
                                        >
                                          {lang === "es"
                                            ? `Cargo ${i + 1}: `
                                            : `Charge ${i + 1}: `}{" "}
                                          {c.nombrecargo}
                                        </h3>
                                      ) : null}

                                      {c.duracion ? (
                                        <h3
                                          className={
                                            "perfil-prof-sub-subtitulo-edu-trabajo3"
                                          }
                                        >
                                          {c.duracion.replace(
                                            /\s*\(.*?\)\s*/g,
                                            ""
                                          )}
                                        </h3>
                                      ) : null}
                                      {/* <h3
                              className={'perfil-prof-sub-subtitulo-edu-trabajo2'}>{lang === 'es' ? `Descripción: ` : `Description: `} {c.descripcion ? c.descripcion : 'N/A'}</h3> */}
                                    </div>
                                  );
                                })
                              : null}
                            {/* <h3
                          className={'perfil-prof-sub-subtitulo-edu-trabajo3'}>{lang === 'es' ? 'Duración' : 'Duration'} {e.totalDuracion}</h3> */}
                          </div>
                        </div>
                        {i + 1 < cv?.experiencia?.length ? <hr /> : null}
                      </div>
                    );
                  })
                ) : (
                  <p className={"perfil-prof-textos"}>
                    {lang === "es"
                      ? "Completar tu experiencia laboral"
                      : "Complete your work experience"}
                  </p>
                )}
              </div>
            </div>
          </div>
          {/*footer perfil*/}
          {/* <h2 className={"perfil-prof-subtitulo"}>Test</h2>
          <div className={"perfil-prof-footer"}>
            {lang === "es"
              ? tests.map((test, i) => {
                  return (
                    <TestCard
                      key={i}
                      id={test.id}
                      name={test.name}
                      description={test.description}
                      user={user}
                      setOpenTestPersonalidad={setOpenTestPersonalidad}
                      width={width}
                      real={test.real}
                      color={schneider.color.primary}
                    />
                  );
                })
              : testsENG.map((test, i) => {
                  return (
                    <TestCard
                      key={i}
                      id={test.id}
                      name={test.name}
                      description={test.description}
                      user={user}
                      setOpenTestPersonalidad={setOpenTestPersonalidad}
                      width={width}
                      real={test.real}
                      color={schneider.color.primary}
                    />
                  );
                })}
            <div> */}
              {/* <Modal
                open={isOpenTestPersonalidad}
                onClose={handleClosePersonalidad}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box sx={style1}>
                  <PopUpTest
                    handleOpenPersonalidadResultado={
                      handleOpenPersonalidadResultado
                    }
                    width={width}
                  />
                </Box>
              </Modal> */}

              {/* <Modal
                open={openTestPersonalidadResultado}
                onClose={handleCloseResultado}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box sx={styles2}>
                  <PopUpResultado resultTest={resultadoTest} />
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-around",
                      marginTop: "40px",
                      paddingBottom: "40px",
                    }}
                  >
                    <button
                      style={{
                        backgroundColor: "transparent",
                        height: "28px",
                        width: "141px",
                        borderRadius: "5px",
                        borderStyle: "solid",
                        borderWidth: "1px",
                        fontSize: "14px",
                        fontWeight: "400",
                        color: "#202256",
                      }}
                    >
                      {lang === "es" ? "Más tests" : "More tests"}
                    </button>
                    <button
                      style={{
                        backgroundColor: "transparent",
                        height: "28px",
                        width: "141px",
                        borderRadius: "5px",
                        borderStyle: "solid",
                        borderWidth: "1px",
                        fontSize: "14px",
                        fontWeight: "400",
                        color: "#202256",
                      }}
                      onClick={mandarpersonalizad}
                    >
                      {lang === "es" ? "Guardar" : "Save"}
                    </button>
                  </div>
                </Box>
              </Modal> */}
            {/* </div>
          </div> */}
        </div>
      </div>
    </>
  );
}
