import React from "react";
import './BannerBancolombia.css';
import {useSelector} from "react-redux";
// import Logo from '../../assets/logos/logo-bancolombia.png';
  const BannerBancolombia = () => {
    const {lang} = useSelector((state) => state)
    return(
      <div className="unilever-banner-profesional2" style={{justifyContent:'space-between'}}>
        <div className="unilever-banner-profesional-inner-1">
          <div className="unilever-banner-profesional-inner-1-1">
            <h1 className="unilever-home-subtitle">
              {lang === 'es' ? 'Bievenido a' : 'Welcome to'}
            </h1>
            <h1 className="unilever-home-title">
              {lang === 'es' ? '¡Mentorías Holberton!' : 'Holberton Mentorships!'}
            </h1>
          </div>
        </div>
        <div className="unilever-banner-profesional-inner-2">
          <img src={'https://coally-cv-coalliers.s3.amazonaws.com/Holberton-Logo-v1.png-1701276278650'} alt="logo bancolombia" style={{
            width: '200px',          
          }}
           />
        </div>
      </div>
    )
  }


  export default BannerBancolombia;
