import React, { useState, useContext } from 'react';
import proyectoimagen from "../../../empresa/oportunidad/proyectos.webp";
import {Link} from "react-router-dom";
import {AvatarGroup} from "@mui/material";
import Avatar from '@mui/material/Avatar';
//import {AvatarUser} from "../../empresa/home/AvatarUser";
import './CardOportunidad.css';
import {useSelector} from "react-redux";
import CrearOportunidad from '../../../empresa/CrearOportunidad/CrearOportunidad';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import {UniversityContext} from "../../context/UniversitiesContext";
export default function CardOportunidadEmpresas(props) {
  const {university} = useContext(UniversityContext);
  const { lang } = useSelector(state => state);
  const [proyectoactivate, setproyectoactivate] = useState(false);
  const handleCloseCreateProject = () => {
    setproyectoactivate(false)
  }

  const handleFinishCreateProject = () => {
    setproyectoactivate(false)
  }
  return (
    <div key={props.key} className={'body-card-oportunidad'}>
      {proyectoactivate &&
          <CrearOportunidad open={proyectoactivate} handleClose={handleCloseCreateProject} handleFinish={handleFinishCreateProject} id={props.id}/>
      }
      <div style={{height: "100%", width: "25%"}}>
        <img src={props.imagen ? props.imagen : proyectoimagen} alt={props.titulo} className={'img-card-oportunidad'}/>
      </div>
      <div style={{
        paddingBottom: "20px",
        height: "100%",
        width: "75%",
        margin: '10px 10px 0 20px',
        display: "flex",
        justifyContent: "space-between",
        flexDirection: "column"
      }}>
        <div style={{display: "flex", justifyContent: "space-between"}}>
          <h3 className={'titulo-card-oportunidad'}>{props.titulo ? props.titulo : 'Oportunidad sin Nombre'}</h3>
          {/**<Link to={`/company/CompanyDashboard/${props.id}`}>*/}
          <Link to={`/${university.route}/company/create/${props.id}`}>
                              <DriveFileRenameOutlineIcon className="boton-editar-card-oportunidad" style={{color: university && university.color ? university.color.secondary : '#000'}}/>
          </Link>
         {/*</Link>*/}
        </div>
        <div>
          <h3 className={'descripcion-card-oportunidad'}>
            <span style={{fontWeight: "bold"}}>{lang === "es" ? 'Habilidades:  ' : 'Skills: '}</span>
            {props.habilidades ? props.habilidades : lang === "es" ? 'No se especificaron habilidades' : 'No skills specified'}
          </h3>
        </div>
        <div style={{display: "flex", justifyContent: "space-between"}}>
          <Link to={`/${university.route}/company/CompanyDashboard/${props.id}`}>
            <button className={'boton-vermas-card-oportunidad'}>{lang === "es" ? 'Ver más' : 'More'}</button>
          </Link>
          <div className='applicant-company-card-home'>
            <AvatarGroup max={4}>
              {props.aplicantes.map((aplicante, index) => {
                // let person = Math.floor(Math.random() * 78)
                // let gender = Math.floor(Math.random() * 2)
                return (
                  //<Avatar key={index+'A'} {...stringAvatar(aplicante['username'])} />
                  //<AvatarUser style={{fontSize: '25px', marginLeft: '-40px'}} name={aplicante}/>

                  <Avatar  key={index+'A'} alt={aplicante['username']} src={aplicante?.avatar ? aplicante.avatar : 'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png'} />
                )
              })}
            </AvatarGroup>
          </div>
        </div>
      </div>
    </div>
  )
}
