import React, { useState, useContext } from "react"
import Spinner from "react-bootstrap/Spinner"
import { useDispatch } from "react-redux"
import { useHistory, useLocation } from "react-router-dom"
import VisibilityIcon from "@mui/icons-material/Visibility"
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff"
import { changeLang } from "../../../../actions"
import { toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { SchneiderContext } from "../../../context/SchneiderContext"
import Edit from "../../../../assets/imagenes/edit.svg"
import axios from "axios"
import {
  AuthGlobalContainer,
  AuthFormContainer,
  AuthButton,
  AuthButtonContainer,
  AuthButtonDisabled,
  /*AuthFooterContainer,
  AuthLink,
  AuthFooterText,*/
  AuthLogo,
  LanguageContainer,
  LanguageButton,
  AuthTitle,
  AuthInputContainer,
  AuthInput,
  AuthInputSubContainer,
  AuthInputIconContainer,
} from "../AuthStyles"

export default function ChangePassOtp() {
  const { schneider } = useContext(SchneiderContext)
  const dispatch = useDispatch()
  const [cargando, setCargando] = useState(false)
  let location = useLocation()
  const history = useHistory()
  const [localLang, setLocalLang] = useState(localStorage.getItem("langlocal"))
  const [passwordShown, setPasswordShown] = useState(false)

  const handleSubmit = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.preventDefault()
    setCargando(true)
    try {
      await axios.post(
        `https://findally-backend.herokuapp.com/api/auth/verifyOTP`,
        {
          otp: form.otp,
          password: form.password,
          email: location?.search?.split("=")[1],
        }
      )
      history.push("/schneider/login")
    } catch (error: any) {
      setCargando(false)
      return toast.error(error.response.data.message)
    }
  }

  const [form, setForm] = useState({
    password: "",
    passwordRepeat: "",
    otp: "",
  })

  const togglePassword = () => {
    setPasswordShown(!passwordShown)
  }

  const changeLanguages = (lang: string) => {
    dispatch(changeLang(lang))
    localStorage.setItem("langlocal", lang)
    setLocalLang(localStorage.getItem("langlocal"))
  }

  const changeForm = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault()
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    })
  }

  return (
    <>
      {schneider && schneider?.color && (
        <AuthGlobalContainer>
          <AuthFormContainer>
            <AuthLogo src={schneider.logo} alt="logo" />
            <LanguageContainer>
              <LanguageButton
                onClick={() => changeLanguages("es")}
                color={localLang === "es" ? schneider.color.primary : "#000000"}
                weight={localLang === "es" ? 700 : 300}
              >
                SP
              </LanguageButton>
              <LanguageButton
                onClick={() => changeLanguages("en")}
                color={localLang === "en" ? schneider.color.primary : "#000000"}
                weight={localLang === "en" ? 700 : 300}
              >
                EN
              </LanguageButton>
            </LanguageContainer>
            <div style={{ width: "100%" }}>
              <AuthTitle color={schneider.color.primary}>
                {localLang === "es"
                  ? "¡Cambia tu contraseña!"
                  : "Change your password!"}
              </AuthTitle>
            </div>
            <AuthInputContainer>
              <p>{localLang === "es" ? "Código Otp" : "Otp Code"}</p>
              <AuthInputSubContainer>
                <AuthInput
                  type="text"
                  name={"otp"}
                  autoComplete={"otp"}
                  placeholder={localLang === "es" ? "xxxxx" : "xxxxx"}
                  onChange={(e) => changeForm(e)}
                />
                <AuthInputIconContainer>
                  <img src={Edit} alt="editar" />
                </AuthInputIconContainer>
              </AuthInputSubContainer>
            </AuthInputContainer>
            <AuthInputContainer>
              <p>
                {localLang === "es"
                  ? "Contraseña nueva, mínimo 6 caracteres."
                  : "New password, minimum 6 characters."}
              </p>
              <AuthInputSubContainer>
                <AuthInput
                  type={passwordShown ? "text" : "password"}
                  name={"password"}
                  autoComplete={"current-password"}
                  placeholder={
                    localLang === "es" ? "Introduce tu contraseña" : "Password"
                  }
                  onChange={(e) => changeForm(e)}
                />
                <AuthInputIconContainer>
                  <button
                    style={{
                      background: "transparent",
                      borderRadius: "0px 1.5rem 1.5rem 0px",
                    }}
                    onClick={() => togglePassword()}
                  >
                    {passwordShown ? <VisibilityOffIcon /> : <VisibilityIcon />}
                  </button>
                </AuthInputIconContainer>
              </AuthInputSubContainer>
            </AuthInputContainer>
            <AuthInputContainer>
              <p>
                {localLang === "es" ? "Repetir contraseña" : "Repeat password"}
              </p>
              <AuthInputSubContainer>
                <AuthInput
                  type={passwordShown ? "text" : "password"}
                  name={"passwordRepeat"}
                  autoComplete={"current-password"}
                  placeholder={
                    localLang === "es" ? "Introduce tu contraseña" : "Password"
                  }
                  onChange={(e) => changeForm(e)}
                />
                <AuthInputIconContainer>
                  <button
                    style={{
                      background: "transparent",
                      borderRadius: "0px 1.5rem 1.5rem 0px",
                    }}
                    onClick={() => togglePassword()}
                  >
                    {passwordShown ? <VisibilityOffIcon /> : <VisibilityIcon />}
                  </button>
                </AuthInputIconContainer>
              </AuthInputSubContainer>
            </AuthInputContainer>
            <AuthButtonContainer>
              {form.otp && form.password ? (
                <AuthButton
                  background={schneider.color.primary}
                  style={{ background: schneider.color.primary, color: "#fff" }}
                  onClick={(e) => handleSubmit(e)}
                >
                  {cargando ? (
                    <Spinner animation="border" size="sm" />
                  ) : localLang === "es" ? (
                    "Recuperar contraseña"
                  ) : (
                    "Recover password"
                  )}
                </AuthButton>
              ) : (
                <AuthButtonDisabled>
                  {localLang === "es"
                    ? "Recuperar contraseña"
                    : "Recover password"}
                </AuthButtonDisabled>
              )}
            </AuthButtonContainer>
          </AuthFormContainer>
        </AuthGlobalContainer>
      )}
    </>
  )
}
