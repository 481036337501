/* eslint-disable */
import React, {useEffect} from "react";
//router
import {Route, Switch, BrowserRouter} from "react-router-dom";

//Consultor pages
import CvForm from "../profesionalpag/Form/CvForm";
import Config from "../components/Configuracion/Config.jsx";
import Education from "./Consultor/Education";
import ConsultorResume from "./Consultor/ConsultorResume";
import NotFound from "./NotFound";
import NewRecovery from "./Auth/NewRecovery/NewRecovery";
import Match from "./Consultor/Match";
import ProjectMethodologycopy from "../components/Methodology-Phase/MethodologyDetailscopy";
import CalendarCoallier from "../components/Calendar/CalendarCoallier";

//AUTH PAGES
import NewLogin from "./Auth/NewLogin/NewLogin";
import NewRegister from "./Auth/NewRegister/NewRegister";
import RecoverOtp from "./Auth/NewRecovery/RecoverOtp/RecoverOtp";
import NewOtpVerify from "./Auth/NewOtpVerify/NewOtpVerify";
import LoginEmpresa from "./LoginEmpresa";
import RegisterEmpresa from "./RegisterEmpresa";
import TyC from "./TyC";
import Contracts from "../pages/Legal/ContractDocs";
import Legal from "./Companies/LegalDocs";
import DataPolicy from "./DataPolicy";
import Support_ticket from "./Support_ticket";
import Feedback from "./Feedback";
import ContactUs from "./ContactUs";
import Faq from "./Faq";
import Detalleoportunidad from "../profesionalpag/detalleoportunidad/Detalleoportunidad";

//Companies pages
import CompanyDemoEs from "./Companies/Demo/CompanyDemo";
import CompanyNewCreateProjectUnregister from "./Companies/CompanyNewCreateProjectUnregister";
import CreateUnregisterProject from "./Companies/CompaniesCreateProjectUnregister";
import CompaniesCreateProject from "./Companies/CompaniesCreateProject";
import CompaniesRegisterForm from "./Companies/CompaniesRegisterForm";
import CompaniesCreateUregisterProject from "./Companies/CompaniesCreateUnregister";
import PricingEmpresaV2 from "../empresa/pricing/PricingEmpresaV2";
import PricingDetails from "../empresa/pricing/pricingDetails/PricingDetails";
import ProfileNew from "../empresa/professionalprofile/ProfessionalProfileNew";
import CompaniesForm from "./Companies/CompanyForm";
import CompaniesEntrega from "./Companies/CompaniesEntrega";
import CompaniesEditProfile from "./Companies/CompanyEditProfile";
import ContactUsCompany from "./Companies/ContactUsCompany";
import CoalliersListCompany from "./Companies/CompanyListFreelancers";
import Events from "../components/Calendar/Calendar";
import PaymentCompany from "./Companies/Payment/Payment";
import FaqCompany from "./Companies/CompaniesFaq";
import TyCcompanies from "./Companies/CompaniesTyC";
import DataPolicyCompanies from "./Companies/DataPolicy";

//admin pages
import Manageadmin from "./Admin/pages/manageProjects";
import Manageusers from "./Admin/pages/manageUsers";
import SupportTicket from "./Admin/pages/manageTickets";
import CertificadoGenerator from "./Legal/Certificado";
import ConfidencialidadContrato from "./Legal/Confidencialidad";
import KpsGenerator from "./Legal/kps";
import Dashboard from "../components/Dashboard/Dashboard";
import CasosExito from "./CasosExito";

//styles
import "./styles/App.css";
import Paymentnequipush from "./Companies/Payment/Paymentnequipush";
import Consultornequianulardispersion from "./Consultor/consultornequi/Consultornequianulardispersion";
import Consultornequidispersion from "./Consultor/consultornequi/Consultornequidispersion";
import Getstatuspaymentpush from "./Companies/Payment/Getstatuspaymentpush";
import Cancelpaymentpush from "./Companies/Payment/Cancelpaymentpush";
import CompanyNewCreateProject2 from "./Companies/CompanyNewCreateProject2";
import Cesde from "./cesde";
import Hubbog from "./hubbog";
import CompanyNewCreateProjectcomplete from "./Companies/CompanyNewCreateProjectcomplete";
import Oportunidadescompany from "../empresa/oportunidades.jsx/Oportunidadescompany";

import Homeempresa from "../empresa/home/Homeempresa";
import Configuracion from "../empresa/configuracion/Configuracion.jsx";
import HomeempresaV2 from "../empresa/home/HomeEmpresav2";
import Trello from "../empresa/Trello/Trello";
import ManageusersCoallier from "./Admin/pages/manageUsersCoallier";
import EducationCompany from "../empresa/education/educationCompany";

import HomeprofesionalV2 from "../profesionalpag/home/HomeprofesionalV2";
import CompanyProfile from "../empresa/CompanyProfile/CompanyProfile";
import DashboardOportunidad from "../empresa/dashboardOportunidad/DashboardOportunidad";
import OportunidadesNewLinked from "../profesionalpag/oportunidad/OportunidadesNewLinked";
import ProfesionalCareerIA from "../profesionalpag/home/careerIA/ProfesionalCareerIA";
/*-------------------------- UNILEVER ------------------------------------------*/
import LandingUnilever from "./Unilever/LandingUnilever";
import PostulateUnilever from "./Unilever/PostulateUnilever";
import RegisterMentorUnilever from "../unilever/mentor/register/RegisterMentorUnilever";
import HomeUnileverProfessional from "../unilever/profesional/home/HomeUnileverProfessional";
import HomeMentorUnilever from "../unilever/mentor/home/HomeMentorUnilever";
import FeedbackProfesional from "../unilever/profesional/feedback/Feedback";
import CalendarioMentorUnilever from "../unilever/mentor/calendario mentoras/calendarioMentorUnilever";
import AdminUnilever from "../unilever/admin/AdminUnilever";
import AdminUnileverGraphs from "../unilever/admin/AdminUnileverGraphs";
/* -------------------------------------------------------- */
import PerfilProfesional from "../profesionalpag/PerfilProfesional/PerfilProfesional";
import {ToastContainer} from "react-toastify";
import ReactGA from "react-ga";
import HomeUnilever from "../unilever/Home/HomeUnilever";
import LoginAdminUnilever from "../unilever/admin/Login/LoginAdminUnilever";
import {withRouter} from "react-router";
import FormularioOportunidad from "../empresa/formOportunidad/FormularioOportunidad";
import BootCamp from "../profesionalpag/bootcamp/BootCamp";
import HomeAdmBootcamp from "../profesionalpag/bootcamp/administracion/HomeAdmBootcamp";
import RegisterBootcamp from "../profesionalpag/bootcamp/Register/RegisterBootcamp";
import TestsBootcamp from "../profesionalpag/bootcamp/TestsBootcamp/TestsBootcamp";
import CvFormClosed from "../profesionalpag/Form/CvFormClosed";
import PruebasTecnicasPage from "../profesionalpag/PruebasTecnicas/PruebasTecnicasPage";
import UniversitiesRoutes from "../universities/routes/UniversitiesRoutes";
import MasterclassSostenible from "../profesionalpag/bootcamp/Sostenibilidad/MasterclassSostenible";
import HomeBancolombia from "../bancolombia/home/HomeBancolombia";
import calendarioMentorBancolombia from "../bancolombia/mentor/calendario mentoras/calendarioMentorBancolombia";
import MasterclassEmpresa from "../profesionalpag/bootcamp/Empresas/MasterclassEmpresa";
import NewEducation from "../profesionalpag/newEducacion/Formacion";
import {SectorVideos} from "../profesionalpag/newEducacion/SectorVideos";
import SchneiderRoutes from "../schneider/routes/SchneiderRoutes";
import NewCVForm from "../profesionalpag/newForm/NewCVForm";
import BootcampAdmin from "../profesionalpag/bootcamp/BootcampAdmin";

ReactGA.initialize("G-8TKXBRBTG3");

function App() {
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  });
  return (
    <BrowserRouter>
      <ToastContainer/>
      <Switch>
        {/* principal page */}
        {/*companies*/}
        <Route exact path="/company/new/projectcomplete" component={CompanyNewCreateProjectcomplete}/>
        <Route exact path="/company/new/project2" component={CompanyNewCreateProject2}/>
        <Route exact path="/company/unregister/project" component={CompanyNewCreateProjectUnregister}/>
        <Route exact path="/demo/company/español" component={CompanyDemoEs}/>
        <Route exact path="/company/project/create" component={CompaniesCreateProject}/>
        <Route exact path="/company/project/:idp" component={Oportunidadescompany}/>
        <Route exact path="/company/project/create/unregister" component={CreateUnregisterProject}/>
        <Route exact path="/company/register/form" component={CompaniesRegisterForm}/>
        <Route exact path="/project/create" component={CompaniesCreateUregisterProject}/>
        <Route exact path="/company/edit/profile" component={CompaniesEditProfile}/>
        <Route exact path="/company/register" component={CompaniesForm}/>
        <Route exact path="/company/project/entregas/:project" component={CompaniesEntrega}/>
        <Route exact path="/company/coalliers" component={CoalliersListCompany}/>
        <Route exact path="/company/contact" component={ContactUsCompany}/>
        <Route exact path="/project/legal/:id" component={Legal}/>
        <Route exact path="/company/calendar" component={Events}/>
        <Route exact path="/company/payment" component={PaymentCompany}/>
        <Route exact path="/company/paymentnequi" component={Paymentnequipush}/>
        <Route exact path="/company/getpaymentnequi" component={Getstatuspaymentpush}/>
        <Route exact path="/company/cancelpaymentnequi" component={Cancelpaymentpush}/>
        <Route exact path="/company/tyc" component={TyCcompanies}/>
        <Route exact path="/company/faq" component={FaqCompany}/>
        <Route exact path="/company/DataPolicy" component={DataPolicyCompanies}/>

        {/*-------------------------------------------------------------------------- */
          /*                            nuevo diseño empresa                            */
          /* -------------------------------------------------------------------------- */}
        <Route exact path="/company/new/project" component={CompanyNewCreateProject2}/>
        <Route exact path="/company/profile/:id" component={CompanyProfile}/>
        <Route exact path="/company/profile/aplicant/:id" component={ProfileNew}/>
        <Route exact path="/company/create/:id" component={FormularioOportunidad}/>
        <Route exact path="/company/CompanyDashboard/:id" component={DashboardOportunidad}/>
        <Route exact path="/company/project" component={Oportunidadescompany}/>
        <Route exact path="/company/home" component={Homeempresa}/>
        <Route exact path="/company/configuracion" component={Config}/>
        <Route exact path="/company/trello" component={Trello}/>
        <Route exact path="/company/formacion" component={EducationCompany}/>
        <Route exact path="/company/pricing" component={PricingEmpresaV2}/>
        <Route exact path="/company/pricing/:plan" component={PricingDetails}/>
        {/*-------------------------------------------------------------------------- */
          /*                            nuevo diseño empresa                            */
          /* -------------------------------------------------------------------------- */}

        {/*-------------------------------------------------------------------------- */
          /*                          nuevo diseño profesional                          */
          /* -------------------------------------------------------------------------- */}
        <Route exact path="/consultor/project/:id" component={Detalleoportunidad}/>
        <Route exact path="/consultor/projects" component={OportunidadesNewLinked}/>
        <Route exact path="/consultor/home" component={HomeprofesionalV2}/>
        <Route exact path="/consultor/formacion" component={NewEducation}/>
        <Route exact path="/consultor/formacion/:sector" component={SectorVideos}/>
        {/*bootcamp*/}
        <Route exact path="/consultor/bootcamp" component={BootCamp}/>
        <Route exact path="/consultor/bootcamp/administracion" component={HomeAdmBootcamp}/>
        <Route exact path="/consultor/bootcamp/dashboard" component={BootcampAdmin}/>
        <Route exact path="/consultor/bootcamp/register" component={RegisterBootcamp}/>
        <Route exact path="/consultor/bootcamp/tests" component={TestsBootcamp}/>
        <Route exact path={"/consultor/bootcamp/sostenibilidad"} component={MasterclassSostenible}/>
        <Route exact path={"/consultor/bootcamp/empresas"} component={MasterclassEmpresa}/>
        {/*-------------------------------------------------------------------------- */
          /*                          nuevo diseño profesional                          */
          /* -------------------------------------------------------------------------- */}
        {/*unilever*/}
        <Route exact path="/unilever" component={LandingUnilever}/>
        <Route exact path="/unilever/postulate" component={PostulateUnilever}/>
        <Route exact path="/unilever/postulate/mentor" component={RegisterMentorUnilever}/>
        <Route exact path="/unilever/home" component={HomeUnilever}/>
        <Route exact path="/unilever/professional" component={HomeUnileverProfessional}/>
        <Route exact path="/unilever/mentor" component={HomeMentorUnilever}/>
        {/*generar ruta con querys*/}

        <Route exact path="/unilever/profesional/feedback/:mentor/:mentoring" component={FeedbackProfesional}/>
        {/*http://localhost:3001/unilever/calendario*/}
        <Route exact path="/unilever/calendario/" component={CalendarioMentorUnilever}/>
        <Route exact path="/unilever/admin" component={AdminUnilever}/>
        <Route exact path="/unilever/admin/login" component={LoginAdminUnilever}/>
        <Route exact path="/unilever/admin/dashboard" component={AdminUnileverGraphs}/>

        {/*bancolombia*/}
        <Route exact path="/bancolombia/home" component={HomeBancolombia}/>
        <Route exact path="/bancolombia/calendario" component={calendarioMentorBancolombia}/>

        {/*admin*/}
        <Route exact path="/admin/managment/projects" component={Manageadmin}/>
        <Route exact path="/admin/managment/users" component={Manageusers}/>
        <Route exact path="/admin/managment/users/coallier" component={ManageusersCoallier}/>
        <Route exact path="/admin/managment/tickets" component={SupportTicket}/>
        <Route exact path="/legal/contract/confidentiality/:id" component={ConfidencialidadContrato}/>
        <Route exact path="/legal/contract/kps/:id" component={KpsGenerator}/>
        <Route exact path="/admin/match" component={Match}/>
        <Route exact path="/admin/dashboard" component={Dashboard}/>
        <Route exact path="/legal/certificate/:id" component={CertificadoGenerator}/>
        <Route exact path="/legal/Contract/:id" component={Contracts}/>

        {/*consultor*/}
        <Route exact path="/consultor/newcv" component={CvFormClosed}/>
        <Route exact path="/consultor/cv" component={NewCVForm}/>
        <Route exact path="/consultor/cv/:idUser" component={NewCVForm}/>
        <Route exact path="/consultor/profile/:id" component={PerfilProfesional}/>
        <Route exact path="/consultor/config" component={Config}/>
        <Route exact path="/consultor/project/details/:project" component={ProjectMethodologycopy}/>
        <Route exact path="/consultor/education" component={Education}/>
        <Route exact path="/consultor/nequianulardispersion" component={Consultornequianulardispersion}/>
        <Route exact path="/consultor/nequidispersion" component={Consultornequidispersion}/>
        <Route exact path="/consultor/calendar" component={CalendarCoallier}/>
        <Route exact path="/consultor/resume/:coallyform" component={ConsultorResume}/>
        <Route exact path="/consultor/pruebastecnicas/:id" component={PruebasTecnicasPage}/>
        <Route exact path="/consultor/career/ia" component={ProfesionalCareerIA}/>

        {/*--------NEW AUTH---------*/}
        <Route exact path="/" component={NewLogin}/>
        <Route exact path="/cambiar-contrasena/" component={RecoverOtp}/>
        <Route exact path="/auth/consultor/register" component={NewRegister}/>
        <Route exact path="/auth/consultor/recoverypassword" component={NewRecovery}/>
        <Route exact path="/auth/verify" component={NewOtpVerify}/>
        <Route exact path="/auth/company/register" component={RegisterEmpresa}/>
        <Route exact path="/auth/company/login" component={LoginEmpresa} />
        <Route exact path="/tyc" component={TyC} />
        <Route exact path="/datapolicy" component={DataPolicy} />
        <Route exact path="/casos-exito" component={CasosExito} />
        <Route exact path="/support_ticket" component={Support_ticket} />
        <Route exact path="/Feedback" component={Feedback} />
        <Route exact path="/ContactUs" component={ContactUs} />
        <Route exact path="/cesde" component={Cesde} />
        <Route exact path="/hubbog" component={Hubbog} />
        <Route exact path="/Faq" component={Faq} />
        <Route path="/schneider" component={SchneiderRoutes} />
        <Route exact path="/*" component={UniversitiesRoutes} />
        <Route path="*" component={NotFound} />
      </Switch>
    </BrowserRouter>
  );
}

export default withRouter(App);
