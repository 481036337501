import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import { getUsersCompany } from "../../actions";
import axios from "axios";
import { connect } from "react-redux";
import en from "../../lang/en-US";
import es from "../../lang/es-COL"
import counterpart from "counterpart";
const UsersListCompany = (props) => {

    const [allData, setAllData] = useState([]);
    const [filteredData, setFilteredData] = useState(allData);
    const handleSearch = (event) => {
        let value = event.target.value.toLowerCase();
        let result = [];
        console.log(value);
        result = allData.filter((data) => {
            return data.profesion.search(value) !== -1;
        });
        setFilteredData(result);
    }

    useEffect(() => {
        // eslint-disable-next-line
        axios
            .get("https://findally-backend.herokuapp.com/user/company/coalliers")
            .then(response => {
                console.log(response.data)
                setAllData(response.data);
                setFilteredData(response.data);
            })
            .catch(error => {
                console.log('Error getting fake data: ' + error);
            })
        // eslint-disable-next-line
    }, []);
    counterpart.registerTranslations('en',en) 
    counterpart.registerTranslations('es',es)  
    counterpart.setLocale(localStorage.getItem('langlocal'));
    const Profesion =counterpart.translate('Profesion')
    return (
        <>
            <div className="section-tittle-list-coalliers">
                <h1 className="tittle-list-coalliers">Coalliers</h1>
            </div>
            <div>
                <div className="div-search-list-coalliers">
                    <label className="label-search-coalliers">Buscar: </label>
                    <input className="input-search-list-coalliers" placeholder={Profesion} type="text" onChange={(event) => handleSearch(event)} />
                </div>

                {filteredData.map((value, index) => {
                    return (
                        <div className="card_coallier_companies" key={value.nombre}>
                            <div className="card_coallier_companies_box">
                                <img
                                    className="img-list-coalliers"
                                    style={{
                                        display: "block",
                                        width: "100%",
                                        maxHeight: "290px",
                                        maxWidth: "250",
                                    }}
                                    src="https://images.pexels.com/photos/8090136/pexels-photo-8090136.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940"
                                    alt="img"
                                />
                            </div>
                            <p className="coallier-card-name">
                                {"Consultor Coally" || value.nombre.slice(0, 8) + "  Coally"}
                            </p>
                            <p className="coallier-card-profesion">
                                {value.profesion}
                            </p>
                            <p className="coallier-card-description">
                                {value.descripcion}
                            </p>
                            <Link
                                className="coallier-cv-data-link"
                                to={`/consultor/resume/${value.coallyform}`}//enviar id
                            >
                                <span>
                                    <p className="coallier-cv-data-link-txt">Hoja de vida</p>
                                </span>
                            </Link>
                        </div>
                    )
                })}

            </div>
        </>
    )
}

const mapDispatchToProps = {
    getUsersCompany
};


const mapStateToProps = (state) => {
    return {
        users_company: state.users_company
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(UsersListCompany);