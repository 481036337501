import "./Styles/CompanyForm.css";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import  { useEffect } from "react";
import Paper from "@material-ui/core/Paper";
import Divider from "@material-ui/core/Divider";
import RegisterFormCompany from "../../components/RegisterCompanyForm/RegisterForm";
import HeaderEmpresa from "../../components/HeaderEmpresa/HeaderEmpresa";
import { formCompany , ConsultorData1} from "../../actions"
import { connect } from "react-redux";
import en from "../../lang/en-US";
import es from "../../lang/es-COL"
import counterpart from "counterpart";
import Translate from "react-translate-component";



const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
    paddingRight: 10,
    paddingLeft: 10
  },
  svg: {
    verticalAlign: "middle"
  },
  layout: {
    width: "auto",
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
      width: 600,
      marginLeft: "auto",
      marginRight: "auto"
    }
  },
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(8),
      marginBottom: theme.spacing(8),
      padding: theme.spacing(3)
    }
  }
}));



function VerticalLinearStepper(props) {

    
useEffect(() => {
    const getUser=()=>{
      console.log(props.user.id)
        props.ConsultorData1(props.user.id)
      
    }
    if(props.userform){
      window.location.href  = '/company/profile'
    }
    // eslint-disable-next-line
    getUser();  
    // eslint-disable-next-line
  }, []); 

  const classes = useStyles();

  counterpart.registerTranslations('en',en) 
  counterpart.registerTranslations('es',es)  
  counterpart.setLocale(localStorage.getItem('langlocal'));
  return (
    <>
    <HeaderEmpresa/>
    <div className="section-tittle-out-form">
        <h2 className="tittle-out-form">{<Translate content="Formularioderegistro" component="span"/>}</h2>
      </div>
      <div className="CompanyForm">
        <section className="CompanyForm-container">
    <div className="App">
      <CssBaseline />
      <main className={classes.layout}>
        <Paper className={classes.paper}>
          <RegisterFormCompany />
        </Paper>
        <Divider style={{ marginTop: 100 }} />

      </main>
    </div>
  
        </section>
      </div>
    </>
  );
}

const mapDispatchToProps = {
  formCompany,
  ConsultorData1
};
const mapStateToProps = (state) => {
  return {
    user: state.user,
    projects : state.projects,
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(VerticalLinearStepper);
