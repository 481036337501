import React, { useState } from 'react'
import checkEclipse from '../../assets/icons/checkEclipse.svg'
import checkedEclipse from '../../assets/icons/checkedEclipse.svg'
import checkedEclipse2 from '../../assets/icons/checkedEclipse2.svg'
import Translate from "react-translate-component";
import axios from 'axios';
function PopUpTest(props) {

    const [checked, setChecked] = useState(
        {
            question1: { yes: false, no: false },
            question2: { yes: false, no: false },
            question3: { yes: false, no: false },
        }
    )

    const handleOnclick = (e) => {
        const { name, id } = e.target
        if (id === 'yes') {
            setChecked({
                ...checked,
                [name]: { yes: true }
            })
        }
        else if (id === 'no') {
            setChecked({
                ...checked,
                [name]: { no: true }
            })
        }
        else {
            setChecked({
                ...checked,
                [name]: { yes: false, no: false }
            })
        }
    }
    const handleSubmit = async() => {
        let resultTest = []
        for (let key in checked) {
            if (checked[key].yes) {
                resultTest.push(true)
            }
            else {
                resultTest.push(false)
            }
        }
            const res = await axios.post('https://findally-backend.herokuapp.com/personality_test/', {test: resultTest})
            console.log(res)
            props.handleOpenPersonalidadResultado(res.data.type)
    }


    return (
        <div style={{width: '90%'}}>
            <div style={{ marginLeft: props.width > 600 ? '93px' : '20px', marginRight: props.width > 600 ? '96px' : '0px',marginTop: '51px', width: '100%' }}>
                <h1 style={{ fontSize: '20px', fontWeight: '700', lineHeight: '30px', color: '#151515', }}>Test de Personalidad</h1>
                <div>
                    <p style={{ fontSize: '14px', fontWeight: '400' }}>
                        ¿Crees que el día no tiene bastantes horas para todas las cosas que deberías hacer?
                    </p>
                    <div style={{ marginLeft: '20px' }}>
                        <label>
                            <input style={{ display: 'none' }} name='question1' id='yes' type="checkbox" onChange={handleOnclick} />
                            {!checked.question1.yes ? <img src={checkEclipse} alt="checkEclipse" />
                                :
                                <div >
                                    <img style={{ position: 'absolute', marginLeft: '4px', marginTop: '7.5px' }} src={checkedEclipse} alt="checkedEclipse" />
                                    <img src={checkedEclipse2} alt="checkEclipse2" />
                                </div>
                            }
                        </label>
                        <label style={{ marginLeft: '20px' }}>Si</label>
                        <br />
                        <label>
                            <input style={{ display: 'none' }} name='question1' id='no' type="checkbox" onChange={handleOnclick} />
                            {!checked.question1.no ? <img src={checkEclipse} alt="checkEclipse" />
                                :
                                <div >
                                    <img style={{ position: 'absolute', marginLeft: '4px', marginTop: '7.5px' }} src={checkedEclipse} alt="checkedEclipse" />
                                    <img src={checkedEclipse2} alt="checkEclipse2" />
                                </div>
                            }
                        </label>
                        <label style={{ marginLeft: '20px' }}>No</label>
                    </div>
                </div>
                <div>
                    <p style={{ fontSize: '14px', fontWeight: '400' }}>
                        ¿Siempre te mueves, caminas o comes con rapidez?
                    </p>
                    <div style={{ marginLeft: '20px' }}>
                        <label>
                            <input style={{ display: 'none' }} name='question2' id='yes' type="checkbox" onChange={handleOnclick} />
                            {!checked.question2.yes ? <img src={checkEclipse} alt="checkEclipse" />
                                :
                                <div >
                                    <img style={{ position: 'absolute', marginLeft: '4px', marginTop: '7.5px' }} src={checkedEclipse} alt="checkedEclipse" />
                                    <img src={checkedEclipse2} alt="checkEclipse2" />
                                </div>
                            }
                        </label>
                        <label style={{ marginLeft: '20px' }}>Si</label>
                        <br />
                        <label>
                            <input style={{ display: 'none' }} name='question2' id='no' type="checkbox" onChange={handleOnclick} />
                            {!checked.question2.no ? <img src={checkEclipse} alt="checkEclipse" />
                                :
                                <div >
                                    <img style={{ position: 'absolute', marginLeft: '4px', marginTop: '7.5px' }} src={checkedEclipse} alt="checkedEclipse" />
                                    <img src={checkedEclipse2} alt="checkEclipse2" />
                                </div>
                            }
                        </label>
                        <label style={{ marginLeft: '20px' }}>No</label>
                    </div>
                </div>
                <div>
                    <p style={{ fontSize: '14px', fontWeight: '400' }}>
                        ¿Te sientes impaciente por el ritmo al que se desarrollan los acontecimientos?
                    </p>
                    <div style={{ marginLeft: '20px' }}>
                        <label>
                            <input style={{ display: 'none' }} name='question3' id='yes' type="checkbox" onChange={handleOnclick} />
                            {!checked.question3.yes ? <img src={checkEclipse} alt="checkEclipse" />
                                :
                                <div >
                                    <img style={{ position: 'absolute', marginLeft: '4px', marginTop: '7.5px' }} src={checkedEclipse} alt="checkedEclipse" />
                                    <img src={checkedEclipse2} alt="checkEclipse2" />
                                </div>
                            }
                        </label>
                        <label style={{ marginLeft: '20px' }}>Si</label>
                        <br />
                        <label>
                            <input style={{ display: 'none' }} name='question3' id='no' type="checkbox" onChange={handleOnclick} />
                            {!checked.question3.no ? <img src={checkEclipse} alt="checkEclipse" />
                                :
                                <div >
                                    <img style={{ position: 'absolute', marginLeft: '4px', marginTop: '7.5px' }} src={checkedEclipse} alt="checkedEclipse" />
                                    <img src={checkedEclipse2} alt="checkEclipse2" />
                                </div>
                            }
                        </label>
                        <label style={{ marginLeft: '20px' }}>No</label>
                    </div>
                </div>
            </div>
            <div style={{ display: 'flex', 
            justifyContent: 'end', 
            marginRight: '80px',
            marginTop: props.width > 600 ? '240px' : '100px',
            marginBottom: props.width > 600 ? '0px' : '50px',
             }}>
                {
                    !checked.question1.yes && !checked.question1.no && !checked.question2.yes && !checked.question2.no && !checked.question3.yes && !checked.question3.no ? 
                    <button style={{ backgroundColor: 'transparent', height: '28px', width: '141px', borderRadius: '5px', borderStyle: 'solid', borderWidth: '1px', fontSize: '14px', fontWeight: '400', color: '#202256' }} disabled><Translate className="mediumfont" content="PuntuarTestv1" component="span" /></button>
                    :
                    <button style={{ backgroundColor: 'transparent', height: '28px', width: '141px', borderRadius: '5px', borderStyle: 'solid', borderWidth: '1px', fontSize: '14px', fontWeight: '400', color: '#202256' }} onClick={handleSubmit}><Translate className="mediumfont" content="PuntuarTestv1" component="span" /></button>
                }
                
              </div>
        </div>
    )
}

export default PopUpTest