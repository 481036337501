import React, { useEffect, useState } from 'react';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import { useSelector } from 'react-redux';

const MouseOverPopover = ({ cv, user, color }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const { lang } = useSelector((state) => state);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const getMissingProperties = () => {
    const missingProperties = [];
    if (!cv?.aptitudes_principales || cv?.aptitudes_principales?.length === 0) {
      missingProperties.push(
        lang === 'es' ? 'Aptitudes principales' : 'Main skills',
      );
    }
    if (!cv?.certifications || cv?.certifications?.length === 0) {
      missingProperties.push(
        lang === 'es' ? 'Certificaciones' : 'Certifications',
      );
    }
    if (!cv?.contacto ||( cv?.contacto?.correo?.length === 0 && user?.email?.length === 0)) {
      missingProperties.push(
        lang === 'es' ? 'Contacto' : 'Contact',
      );
    }
    if (!cv?.educacion || cv?.educacion.length === 0 || cv?.educacion[0]?.NombreInstitucion === "") {
      missingProperties.push(
        lang === 'es' ? 'Educación' : 'Education',
      );
    }
    if (!cv?.experiencia || cv?.experiencia?.length === 0 || cv?.experiencia[0]?.Nombreempresa === "") {
      missingProperties.push(
        lang === 'es' ? 'Experiencia' : 'Experience'
      );
    }
    if (!cv?.extracto || cv?.extracto === "") {
      missingProperties.push(
        lang === 'es' ? 'Descripción' : 'Description'
      );
    }
    if (!cv?.languages || cv?.languages?.length === 0) {
      missingProperties.push(
        lang === 'es' ? 'Idiomas' : 'Languages'
      );
    }
    if (!user?.avatar || user?.avatar === "") {
      missingProperties.push(
        lang === 'es' ? 'Avatar' : 'Avatar'
      );
    }
    return missingProperties;
  };

  useEffect(() => {
    // Agrega un event listener al evento 'scroll' del objeto 'window'
    window.addEventListener('scroll', handlePopoverClose);
    return () => {
      // Limpia el event listener al desmontar el componente
      window.removeEventListener('scroll', handlePopoverClose);
    };
  }, []);

  const open = Boolean(anchorEl);
  const missingProperties = getMissingProperties();
  return (
    <div style={{
      cursor: 'pointer',
    }}>
      <Typography
        aria-owns={open ? 'mouse-over-popover' : undefined}
        aria-haspopup="true"
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
        onClick={handlePopoverOpen}
      >
        <p className='porcentaje-text-coally' style={{color: color ? color : ''}}> {lang === 'es' ? 'Completa la información.' : 'Completed information.'}</p>
      </Typography>
      <Popover
        id="mouse-over-popover"
        sx={{
          pointerEvents: 'none',
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
        disableScrollLock
      >
        {missingProperties.length > 0 ? (
          <Typography sx={{ p: 1, width: 200, fontSize: '14px' }}>
            {lang === 'es' ? 'Falta la siguiente información: ' : 'The following information is missing: '}{missingProperties.join(', ')}
          </Typography>

        ) : (
          <Typography sx={{ p: 1, width: 200, fontSize: '14px' }}>
            {lang === 'es' ? '¡Felicidades! Has completado tu perfil.' : 'Congratulations! You have completed your profile.'}
          </Typography>
        )}
      </Popover>
    </div>
  );
};

export default MouseOverPopover;
