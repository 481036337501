import React from 'react'

function PopUpResultado(props) {
    return (
        <div style={{width: '90%'}}>
            <div style={{width: '100%'}}>
                <div style={{ marginLeft: '20px', marginTop: '51px', width: '100%' }}>
                    <h1 style={{ fontSize: '20px', fontWeight: '700', lineHeight: '30px', color: '#151515', }}>Resultado del test de personalidad
                    </h1>
                    <div style={{ fontSize: '14px', fontWeight: '400' }}>
                        <p>El tipo de personalidad <span style={{fontWeight:'700'}}>A</span> corresponde a aquellas personas que necesitan afirmarse permanentemente a través de logros personales para alcanzar la percepción de auto-control. Experimentan una constante urgencia de tiempo que les hace intolerable el reposo y la inactividad.
                        </p>
                        <p>Estas personas también presentan una percepción de amenaza casi contínua a su autoestima, eligiendo la acción como estrategia de enfrentamiento a sus problemas. Tienen tendencia a la dominación, una profunda inclinación a competir y una elevada agresividad que les induce a vivir en un constante estado de lucha.
                        </p>                     
                        <p>
                            El patrón de personalidad <span style={{fontWeight:'700'}}>B</span> es, evidentemente, lo opuesto al anterior. Son personas con un adecuado nivel de auto-control y autoestima que no les hace falta mantener actitudes compensadoras para reafirmarse. No son competitivas ni tan fácilmente irritables, y en general se toman la vida con mayor tranquilidad.
                        </p>
                        <h2 style={{fontSize:'14px', fontWeight:'700', lineHeight:'20px', color:'#E7BF00'}}>
                            Tienes una personalidad de tipo {props.resultTest}
                        </h2>
                        <p>   
                            {
                                props.resultTest === 'A' ? 'Deberías relajarte y tomarte las cosas con más filosofía, pues tienes mayor peligro de padecer enfermedades coronarias, problemas psicosomáticos y estados de ansiedad.'
                                
                            : 'Eres bastante menos vulnerable a sufrir ansiedad que otras personas, no te muestras ambicioso ni dominante, dejas que las cosas sigan su cauce sin preocuparte en exceso. No quiere decir que nunca te muestres nervioso o angustiado si la situación te desborda, pero en general tienes un temperamento templado.'}
                        </p>

                    </div>
                </div>
            </div>
        </div>
    )
}

export default PopUpResultado