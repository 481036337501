import React, { Fragment } from "react";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Divider from "@material-ui/core/Divider";
import Button from "@material-ui/core/Button";
import { formCompany } from "../../actions/index";
import { useDispatch , useSelector} from "react-redux";
import en from "../../lang/en-US";
import es from "../../lang/es-COL"
import counterpart from "counterpart";
import Translate from "react-translate-component";


// Destructure props
const Confirm = ({
  handleNext,
  handleBack,
  values: {  facebook, instagram, linkedin, email, descripcion, tiempo_mercado, nit, sector, ubicacion, telefono, idioma, sitio_web, tamaño,  }
}) => {
  const handleSubmit = () => {
    console.log({ usuario:state.id, facebook, instagram, linkedin, email, descripcion, tiempo_mercado, nit, sector, ubicacion, telefono, idioma, sitio_web, tamaño,  });
    dispatch(formCompany({ usuario:state.id, facebook, instagram, linkedin, email, descripcion, tiempo_mercado, nit, sector, ubicacion, telefono, idioma, sitio_web, tamaño,  },  "/company/profile"))
    handleNext();
  };
  const state = useSelector(state => state.user)
  const dispatch = useDispatch()
  counterpart.registerTranslations('en',en) 
      counterpart.registerTranslations('es',es)
      counterpart.setLocale(localStorage.getItem('langlocal'));
  return (
    <Fragment>
      <List disablePadding>
        <ListItem>
          <ListItemText primary="Email" secondary={email} />
        </ListItem>

        <Divider />

        <ListItem>
          <ListItemText primary="Descripcion" secondary={descripcion} />
        </ListItem>

        <Divider />

        <ListItem>
          <ListItemText primary="NIT" secondary={nit} />
        </ListItem>

        <Divider />

        <ListItem>
          <ListItemText primary="Sector" secondary={sector} />
        </ListItem>

        <Divider />

        <ListItem>
          <ListItemText primary="Ubicacion" secondary={ubicacion} />
        </ListItem>

        <Divider />

        <ListItem>
          <ListItemText primary="Telefono" secondary={telefono} />
        </ListItem>

        <Divider />
        
        <ListItem>
          <ListItemText primary="Idioma" secondary={idioma} />
        </ListItem>

        <Divider />
        
        <ListItem>
          <ListItemText primary="Sitio web" secondary={sitio_web} />
        </ListItem>

        <Divider />
        
        <ListItem>
          <ListItemText primary="Tamaño" secondary={tamaño} />
        </ListItem>

        <Divider />
      </List>

      <div
        style={{ display: "flex", marginTop: 50, justifyContent: "flex-end" }}
      >
        <Button variant="contained" color="default" onClick={handleBack}>
          {<Translate content="Back" component="span"/>}
        </Button>
        <Button
          style={{ marginLeft: 20 }}
          variant="contained"
          color="secondary"
          onClick={handleSubmit}
        >
          Confirm & Continue
        </Button>
      </div>
    </Fragment>
  );
};

export default Confirm;
