import { useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import {useHistory} from "react-router-dom";

export const useFilterFormData = () => {
  const [formData, setFormData] = useState({
    proposed_salary: "",
    contract_acceptance: "",
    available_time: "",
    hobbies: [],
    sport: "",
    digital_tools: [],
    digital_tools_experience: "",
    difficulties: "",
    english_test: "",
    personality_test: "",
    social_skills: "",
  });
  const history = useHistory();
  const [hobby, setHobby] = useState("");
  const [digitalTool, setDigitalTool] = useState("");
  const handleFormData = (e: any) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleFormDataCheckbox = (e: any) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.checked,
    });
  };

  const handleAddHobby = (e: any) => {
    e.preventDefault();
    const hobbies = formData.hobbies;
    hobbies.push(hobby);
    setFormData({
      ...formData,
      hobbies,
    });
    setHobby("");
  };

  const handleDeleteHobby = (index: number) => {
    const hobbies = formData.hobbies;
    hobbies.splice(index, 1);
    setFormData({
      ...formData,
      hobbies,
    });
  };

  const handleAddDigitalTool = (e: any) => {
    e.preventDefault();
    const digitalTools = formData.digital_tools;
    digitalTools.push(digitalTool);
    setFormData({
      ...formData,
      digital_tools: digitalTools,
    });
    setDigitalTool("");
  };

  const handleDeleteDigitalTool = (index: number) => {
    const digitalTools = formData.digital_tools;
    digitalTools.splice(index, 1);
    setFormData({
      ...formData,
      digital_tools: digitalTools,
    });
  };
  console.log(formData);

  const handleSubmit = (e: any, user: string) => {
    e.preventDefault();
    /* if (
      formData.proposed_salary === "" ||
      formData.contract_acceptance === "" ||
      formData.available_time === "" ||
      formData.hobbies.length === 0 ||
      formData.digital_tools.length === 0 ||
      formData.digital_tools_experience === "" ||
      formData.difficulties === "" ||
      formData.english_test === "" ||
      formData.personality_test === ""
    ) {
      toast.error("Por favor, rellena todos los campos");
    }*/
    axios.post(
      `https://findally-backend.herokuapp.com/api/filterForm/${user}`,
      formData
    ).then(() => {
      toast.success("Formulario enviado con éxito");
      setTimeout(() => {
        history.push("/schneider/projects");
      }, 2000);
    });
  };

  return {
    formData,
    setFormData,
    handleFormData,
    handleFormDataCheckbox,
    handleAddHobby,
    handleDeleteHobby,
    hobby,
    setHobby,
    handleAddDigitalTool,
    handleDeleteDigitalTool,
    digitalTool,
    setDigitalTool,
    handleSubmit,
  };
};
