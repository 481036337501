import React, {useContext} from 'react';
import { useParams} from "react-router-dom";
import {useDashboardData} from "./utils/useDashboardData";
import Sidebar from "../../../components/sidebar/Sidebar"
import Navbar from "../../../components/navbar/Navbar"
import arrow from "../../../../assets/icons/l-arrow.svg";
import Spinner from "react-bootstrap/Spinner";
import DashboardCardInfo from "../../../components/cards/oportunitycard/dashboardcardinfo/DashboardCardInfo";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AccordionDetails from "@mui/material/AccordionDetails";
import ProfesionalesSimilaresCard
  from "../../../../empresa/dashboardOportunidad/ProfesionalesSimilaresCard/ProfesionalesSimilaresCard";
import {useHistory} from "react-router-dom";
import OpportunityApplicantCard from "../../../components/cards/oportunitycard/aplicantescard/OpportunityApplicantCard";
// @ts-ignore
import { CSVLink } from "react-csv";
import {SchneiderContext} from "../../../context/SchneiderContext";
import ApplicantListCard from "../../../components/cards/oportunitycard/aplicantescard/ApplicantListCard";
import {DownloadLabelButton} from "../../../components/buttons/ButtonStyles";
import DashboardTbs from "./partials/DashboardTbs";

export const CompanyDashboard = () => {
  const {schneider} = useContext(SchneiderContext)
  const { id } = useParams<any>();
  const {loading,
    profesionales1,
    redondeo,
    csvReport,
    usuariosRecomendados,
    readyQueryMl,
    aplicantes,
    enpruebas,
    seleccionados,
    ocultos,
    loadnum,
    setLoadnum,
    lang, oportunidad} = useDashboardData(id);
  const history = useHistory();
  return (
    <>
      <>
        <Sidebar />
        <div className={'dashOp-global-cont'}>
          <Navbar />
          {/*aplicantes*/}
          <div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <img onClick={history.goBack} style={{ marginRight: '31px', cursor: 'pointer' }} src={arrow}
                   alt={'l-arrow'} />
              <h1
                className={'dashOp-title'} style={{
                  color: schneider.color.primary
              }}>{lang === 'es' ? 'Oportunidad' : 'Opportunity'} {oportunidad?.nombre === 'Unnamed Oportunity' || oportunidad?.nombre === undefined ? oportunidad?.NombreOportunidad
                : oportunidad?.nombre}</h1>
            </div>
            {/*<h2 className={'dashOp-subtitle'}>*/}
            {/*  {lang === 'es' ? 'Aplicantes' : 'Applicants'}*/}
            {/*</h2>*/}
            {
              profesionales1?.applicants?.length === 0 ? null : <h2 className={'dashOp-subtitle'}>
                {lang === 'es' ? 'Mejores aplicantes' : 'Best applicants'}
              </h2>
            }
            <div className='container-dashOp-cards-cont'>
              <div className={'dashOp-cards-cont'}>
                {/*cards aplicantes*/}
                {
                  !profesionales1?.applicants?.length && loading && !readyQueryMl ? <div style={{
                    display: 'flex',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                    width: '100%',
                    height: '100%'
                  }}>
                    <Spinner animation="border" size="sm" variant="warning" />
                  </div> : null
                }{
                profesionales1 && readyQueryMl && profesionales1?.applicants?.filter((e: any) => e.fit !== undefined).sort((a: any, b: any) => b.fit - a.fit).concat(profesionales1?.applicants?.filter((e: any) => e.fit === undefined)).slice(0, 3).map((e: any, i: any) => {
                  // let person = Math.floor(Math.random() * 78)
                  // let gender = Math.floor(Math.random() * 2)
                  return (
                    <OpportunityApplicantCard
                      key={i}
                      keynum={i + 1}
                      name={`${e?.cv_file?.info_personal?.nombre?.slice(0, 15)}...`}
                      nameEntero={e?.cv_file?.info_personal?.nombre}
                      job={`${e?.cv_file?.info_personal?.profesion_actual?.slice(0, 20)}...`}
                      jobEntero={e?.cv_file?.info_personal?.profesion_actual}
                      compatibility={e.fit ? redondeo(e?.fit) + '%' : '0%'}
                      image={e?.avatar}
                      id={e.id}
                      LinkedInCv={e?.cv_LinkedIn_uri}
                      generalCv={e?.cv_Personalized_uri}
                      opportunityId={id}
                    />
                  )
                })
              }
              </div>
              <DashboardCardInfo data={oportunidad} lang={lang} />
            </div>
            <div>
              {/*listado aplicantes*/}
              {profesionales1?.applicants?.length === 0 && profesionales1?.applicants?.length < 3 ? null :
                <h2 className={'dashOp-subtitle'}>
                  {lang === 'es' ? 'Otros aplicantes' : 'Other applicants'}
                </h2>}
              {(profesionales1 && readyQueryMl && profesionales1?.applicants?.filter((e: any) => e.fit !== undefined).sort((a: any, b: any) => b.fit - a.fit).concat(profesionales1?.applicants?.filter((e: any) => e.fit === undefined)).slice(3, loadnum).map((e: any, i: any) => {
                // let person = Math.floor(Math.random() * 78)
                // let gender = Math.floor(Math.random() * 2)
                return (
                  <ApplicantListCard
                    keynum={i + 4}
                    key={i}
                    name={e?.cv_file?.info_personal?.nombre}
                    job={e?.cv_file?.info_personal?.profesion_actual}
                    compatibility={e.fit ? redondeo(e?.fit) + '%' : '0%'}
                    image={e?.avatar}
                    id={e.id}
                    LinkedInCv={e?.cv_LinkedIn_uri}
                    generalCv={e?.cv_Personalized_uri}
                    opportunityId={id}
                  />

                )
              }))}
            </div>
            {(profesionales1 && profesionales1?.applicants?.length > 0 && profesionales1?.applicants?.length > 3 ?
              <div style={{ width: '100%', display: "flex", justifyContent: "center", alignItems: "center" }}>
                {
                  profesionales1 && profesionales1?.applicants?.length > loadnum ?
                    <button onClick={() => setLoadnum(loadnum + 3)} className={'dashOp-btn-ver-mas'}>
                      {lang === 'es' ? 'Cargar más' : 'Load more'}
                    </button> :
                    <button onClick={() => setLoadnum(6)} className={'dashOp-btn-ver-mas'}>
                      {lang === 'es' ? 'Ver menos' : 'See less'}
                    </button>
                }
              </div> : null)}
          </div>

          {/*aplicantes ocultos*/}
          {oportunidad?.hiddenAplicants?.length ?
            <Accordion style={{
              marginTop: '30px',
            }}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <h2 style={{ marginTop: '0' }} className={'dashOp-subtitle'}>
                  {lang === 'es' ? 'Aplicantes descartados' : 'Discarded applicants'}
                </h2>
              </AccordionSummary>
              <div>
                <AccordionDetails>
                  <div className={'perfiles-de-prof-similares'}>
                    {
                      ocultos?.map((prof: any, index: any) => {
                        return (
                          <ProfesionalesSimilaresCard
                            key={index}
                            name={prof?.cv_file?.info_personal?.nombre}
                            job={prof?.cv_file?.info_personal?.profesion_actual}
                            compatibility={''}
                            image={prof.avatar}
                            recos={false}
                            id={prof._id}
                            LinkedInCv={prof?.urlCVLinkedIn}
                            generalCv={prof?.cv_Personalized_uri
                              ? prof?.cv_Personalized_uri
                              : null}
                          />
                        )
                      })
                    }
                  </div>
                </AccordionDetails>
              </div>
            </Accordion>
            : null}

          {/*Aplicantes en espera*/}
          {
            aplicantes && (aplicantes?.contacted_users?.length > 0 || enpruebas?.data?.length > 0) ?
              <div style={{ marginTop: '45px' }}>
                <h2 className={'dashOp-subtitle'}>
                  {lang === 'es' ? 'Aplicantes en espera' : 'Waiting Applicants'}
                </h2>
                <div>
                  <DashboardTbs entrevista={aplicantes} enPruebas={enpruebas} opId={id} seleccionados={seleccionados} oportunidad={oportunidad} />
                </div>
              </div>
              : null
          }
          {/*INFO EMPRESA*/}
          <div className={'dashOp-cont-info-emp'}>
            {/*info empresa y puesto*/}
            <div style={{ width: '100%' }}>
              <div>
                <div>
                  <h2
                    className={'dashOp-subtitle'}>{lang === 'es' ? 'Descripción de la oportunidad' : 'Opportunity Description'}
                  </h2>
                  {oportunidad?.aplicantes?.length > 0 ?
                    <DownloadLabelButton
                      color={schneider.color.primary}
                      width={'200px'}
                    >
                    <CSVLink {...csvReport} style={{ textDecoration: 'none' }}></CSVLink>
                      {lang === 'es' ? 'Descargar aplicantes' : 'Download applicants'}
                      </DownloadLabelButton> : null}
                </div>
                <div className={'dasOp-card'}>s
                  <div className='Sub-dasOp-card'>
                    <div>
                      <p style={{
                        fontSize: '20px',
                        fontWeight: 700,
                        color: '#393939',
                      }}>{oportunidad?.NombreOportunidad}</p>
                      <p style={{
                        fontSize: '16px',
                        fontWeight: 400,
                        color: '#393939',
                        marginTop: '-10px'
                      }}>{oportunidad?.NombreEmpresa}</p>
                      <div style={{
                        display: 'flex',
                        flexDirection: 'row',
                        marginTop: '-10px'
                      }}>
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                             xmlns="http://www.w3.org/2000/svg">
                          <path fill-rule="evenodd" clip-rule="evenodd"
                                d="M12 21C12 21 18 14.7 18 9C18 5.68629 15.3137 3 12 3C8.68629 3 6 5.68629 6 9C6 14.7 12 21 12 21Z"
                                stroke="#5D5D5D" />
                          <path fill-rule="evenodd" clip-rule="evenodd"
                                d="M12 10C12.5523 10 13 9.55228 13 9C13 8.44772 12.5523 8 12 8C11.4477 8 11 8.44772 11 9C11 9.55228 11.4477 10 12 10Z"
                                stroke="#5D5D5D" />
                        </svg>
                        <p className={'dashOp-text-sub'}>
                          {oportunidad?.country === 'vacio' ? null : oportunidad?.country}
                        </p>
                      </div>
                    </div>
                    <div style={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                    }}>
                      <p style={{
                        fontSize: '14px',
                        color: '#24D054'
                      }}>
                        {oportunidad?.isVisible === true ? lang === 'es' ? 'Abierta' : 'Open' : lang === 'es' ? 'Cerrada' : 'Close'}
                      </p>
                      <p style={{
                        fontSize: '14px',
                        color: '#989898'
                      }}>
                        • {oportunidad?.modalidadDeTrabajo} •
                      </p>
                      <p style={{
                        fontSize: '14px',
                        color: '#989898'
                      }}>
                        {oportunidad?.aplicantes?.length} {lang === 'es' ? 'Aplicantes' : 'Applications'}
                      </p>
                    </div>
                  </div>
                  <p className={'dasOp-card-title'}>ÁREA DE CONOCIMIENTO:</p>
                  <ul>
                    {
                      oportunidad?.empleos_alternativos?.map((e: any, i: any) => {
                        return (<li key={i} className={'dasOp-card-desc'}>{e}</li>)
                      })
                    }
                  </ul>
                  <h3 className={'dasOp-card-title'}>
                    {
                      lang === 'es' ? 'RESPONSABILIDADES Y FUNCIONES:' : 'RESPONSIBILITIES AND FUNCTIONS:'
                    }
                  </h3>
                  <ul>
                    {Array.isArray(oportunidad?.responsabilidadYfunciones) ? oportunidad?.responsabilidadYfunciones?.map((item: any, index: any) => {
                      return <li key={index}
                                 className={'dasOp-card-desc'}>{item.charAt(0).toUpperCase() + item.slice(1).toLowerCase()}</li>
                    }) : oportunidad?.responsabilidadYfunciones}
                  </ul>
                  <h3
                    className={'dasOp-card-title'}>{lang === 'es' ? 'DESCRIPCIÓN DEL CARGO' : 'POSITION DESCRIPTION'}</h3>
                  <ul>
                    <li key={oportunidad?.DescribeProyecto}
                        className={'dasOp-card-desc'}>{oportunidad?.DescribeProyecto}</li>
                  </ul>
                </div>
              </div>
              {/*cards verticales info laboral*/}
              <div style={{ width: '100%' }}>
                <div className={'dasOp-card'}>
                  <h3
                    className={'dasOp-card-title'}>{lang === 'es' ? 'INFORMACIÓN LEGAL' : 'LEGAL INFORMATION'}</h3>
                  <div style={{ paddingLeft: '40px', marginTop: '10px' }}>
                    <h3 key={oportunidad?.experienciaAnos}
                        className={'legal-card-desc'}>{lang === 'es' ? 'Experiencia requerida:' : 'Experience required:'}
                      <span
                        className='legal-information-company'>{oportunidad?.experienciaAnos}{oportunidad?.experienciaAnos.includes('años') ? '' : lang === 'es' ? ' años' : ' years'}</span>
                    </h3>
                    <h3 key={oportunidad?.fecha_inicio}
                        className={'legal-card-desc'}>{lang === 'es' ? 'Fecha de publicación:' : 'Publication date:'}
                      <span
                        className='legal-information-company'>{oportunidad?.fecha_inicio !== 'no definido' ? oportunidad?.fecha_inicio.slice(0, 10) : '-'}</span>
                    </h3>
                    <h3 key={oportunidad?.fecha_vencimiento}
                        className={'legal-card-desc'}>{lang === 'es' ? 'Fecha de vencimiento:' : 'Expiration date:'}
                      <span
                        className='legal-information-company'>{oportunidad?.fecha_vencimiento !== 'no definido' ? oportunidad?.fecha_vencimiento.slice(0, 10) : '-'}</span>
                    </h3>
                    <h3 key={oportunidad?.departamento}
                        className={'legal-card-desc'}>{lang === 'es' ? 'Departamento:' : 'Department:'}
                      <span
                        className='legal-information-company'>{oportunidad?.departamento !== 'no definido' ? oportunidad?.departamento : '-'}</span>
                    </h3>
                    <h3 key={oportunidad?.municipio}
                        className={'legal-card-desc'}>{lang === 'es' ? 'Municipio:' : 'Municipality:'}
                      <span
                        className='legal-information-company'>{oportunidad?.municipio !== 'no definido' ? oportunidad?.municipio : '-'}</span>
                    </h3>
                    <h3 key={oportunidad?.sector_economico}
                        className={'legal-card-desc'}>{lang === 'es' ? 'Sector económico:' : 'Economic sector:'}
                      <span
                        className='legal-information-company'>{oportunidad?.sector_economico !== 'no definido' ? oportunidad?.sector_economico : '-'}</span>
                    </h3>
                    <h3 key={oportunidad?.tipo_documento}
                        className={'legal-card-desc'}>{lang === 'es' ? 'Tipo de documento:' : 'Document type:'}
                      <span
                        className='legal-information-company'>{oportunidad?.tipo_documento !== 'no definido' ? oportunidad?.tipo_documento : '-'}</span>
                    </h3>
                    <h3 key={oportunidad?.documento}
                        className={'legal-card-desc'}>{lang === 'es' ? 'Documento:' : 'Document:'}
                      <span
                        className='legal-information-company'>{oportunidad?.documento !== 'no definido' ? oportunidad?.documento : '-'}</span>
                    </h3>
                  </div>
                </div>
              </div>
            </div>
            {/*Das SERVICIOS OCULTO MIENTRAS TANTO*/}
            <div className={'dashOp-cont-info-emp-serv'} style={{ display: "none" }}>
              <div>
                <h2 className={'dashOp-subtitle'}>{lang === 'es' ? 'Metodologías' : 'Methodologies'}</h2>
                <button
                  className={'dashOp-met-button'}>{lang === 'es' ? 'Subir metodologías' : 'Upload methodologies'}</button>
                <h3
                  className={'dashOp-met-text'}>{lang === 'es' ? '*Este espacio se habilitará cuando selecciones algún aplicante.' : '*This space will be enabled when you select an applicant.'}</h3>
              </div>
            </div>
          </div>
          {/*PERFILES DE PROFESIONALES SIMILARES*/}
          <div>
            <hr />
            <h2 className={'dashOp-subtitle'}>
              {lang === 'es' ? 'Perfiles de profesionales similares' : 'Similar professional profiles'}
              {usuariosRecomendados?.length ? null :
                <Spinner animation="border" size="sm" style={{ marginLeft: '10px' }} />}
            </h2>
            <div className={'perfiles-de-prof-similares'}>
              {
                usuariosRecomendados?.length && usuariosRecomendados?.map((prof: any, index: any) => {
                  return (
                    <ProfesionalesSimilaresCard
                      key={index}
                      name={''}
                      job={''}
                      compatibility={`${Math.floor(prof.fit)}%`}
                      image={''}
                      recos={true}
                      id={prof.ID}
                      LinkedInCv={''}
                      generalCv={''}
                    />
                  )
                })
              }
            </div>
          </div>
        </div>
      </>
    </>
  )
}
