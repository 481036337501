import React, { useState } from 'react'
import { useDispatch , useSelector} from "react-redux";
import './styles/ConfigContactUs.css'
import phone from "../../assets/icons/phone.svg";
import mail from "../../assets/icons/mail.svg";
import whatsApp from "../../assets/icons/whatsApp.svg";
import { registerContact} from "../../actions/index";

function ConfigContactUs(props) {
    const dispatch = useDispatch()
    const { lang } = useSelector(state => state)
    const [form, setValues] = useState({
        correo: props.email,
        asunto: "",
        descripcion: "",
        tipo: ""
    });

    const handleInput = (event) => {
        setValues({
            ...form,
            [event.target.name]: event.target.value,
        });
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        let g = await dispatch(registerContact(form));
       console.log(g)
    };


    return (
        <div className='soporte'>
            <div className="soporte-contactUs">
                <div>
                    <p>{lang === 'es' ? 'En este espacio de bienestar, puedes compartir con nosotros tus requerimientos, estamos para escucharte y ayudarte.' : 'In this wellness space, you can share your needs with us, we are here to listen and help you.'}
                    </p>                        
                        {/* {<Translate content="puedescompartircon" component="span" />} */}                    
                        <ul style={{ display: 'grid', justifyContent: 'center' , padding: 0}}>
                        <li ><img src={whatsApp} alt={'whatsApp'} /><span><a href="https://wa.link/pg0un2" target="_blank" rel="noopener noreferrer">{lang === 'es' ? 'Escríbenos por WhatsApp' : 'Write us by WhatsApp'}</a></span></li>

                        <li ><img src={phone} alt={'phone'} /><span>+1 786 856 6496</span></li>

                        <li ><img src={mail} alt={'mail'} /><span>soporte@coally.com</span></li>
                    </ul>
                </div>
            </div>
            <div className="container-form">
                <div>
                    <form className="config-form" onSubmit={handleSubmit}>
                        <p className="title-form-contact">{lang === 'es' ? 'Seleccione la categoría del problema' : 'Select the category of the problem'}</p>                       
                            <select className='select-contact-us' name="tipo" onChange={handleInput}>
                                <option selected disabled>{lang === 'es' ? 'Seleccionar..': 'Select ..'}</option>
                                <option value="tecnico">{lang === 'es' ? 'Problemas técnicos': 'Technical problems'}</option>
                                <option value="general">{lang === 'es' ? 'Problemas generales': 'General problems'}</option>
                            </select>                        
                        <input
                            className="input_contact_us"
                            type="text"
                            placeholder={lang === 'es' ? "Problema" : "Problem"}
                            name="asunto"
                            onChange={handleInput}
                        />
                        <textarea
                            className="text-contact_us"
                            placeholder={lang === 'es' ? "Descripción" : "Description"}
                            name="descripcion"
                            onChange={handleInput}
                        />
                        <div className="button-contact_us">
                            <button style={{ display: 'flex', justifyContent: 'center', height:'33px'}} onSubmit={handleSubmit}>
                                <span style={{ margin: 0 }}>{lang === 'es' ? 'Enviar' : 'Send'}</span>
                            </button>
                        </div>

                    </form>
                </div>
            </div>

        </div >
    )
}

export default ConfigContactUs
