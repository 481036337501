import styled from "styled-components";

const SmallCardContainerWrapper = styled.div`
  cursor: pointer;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  margin-top: 5px;
  margin-bottom: 5px;
  border-radius: 5px;
`;

const SmallCardLinkedContainer = styled.div<{
  active?: boolean;
  color: string;
}>`
  display: flex;
  flex-direction: column;
  background-color: ${(props) => (props.active ? props.color : "transparent")};
  width: 100%;
  transition: all 0.3s ease-in-out;
  transform: ${(props) => (props.active ? "scale(1.05)" : "none")};
  border-radius: 5px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
`;

export { SmallCardContainerWrapper, SmallCardLinkedContainer };
