import React,{useState,useEffect} from 'react'
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'/* 
import { INITIAL_EVENTS } from './event-utils.js' */
import { connect } from "react-redux";
import { registerEvent } from "../../actions";
import { getEventsProject , getProject } from "../../actions";
import HeaderEmpresa from "../../components/HeaderEmpresa/HeaderEmpresa";
import "./Styles/styles.css";
import { Link, useHistory } from 'react-router-dom'
import en from "../../lang/en-US";
import es from "../../lang/es-COL"
import counterpart from "counterpart";
import Translate from "react-translate-component";

  function FormEvents(props) { 
    const history = useHistory()

const goBack = () => {
  history.goBack()
}
const readCookie =(name) => {
  var nameEQ = name + "=";
  var ca = document.cookie.split(";");
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) === " ") c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) === 0) {
      return decodeURIComponent(c.substring(nameEQ.length, c.length));
    }
  }
  return null;
}
    useEffect(() => {
      const getData = () => {
          props.getEventsProject()
      }
      const getActualProject=()=>{
        if(!props.ActualProject){
            props.getProject(readCookie('ActualProject'))         
        }
    
    }
      getData(); 
      getActualProject(); // eslint-disable-next-line
  }, []); // eslint-disable-next-line


    const [form, setValues] = useState({
      proyecto : props.actual._id,
      title : "",
      description : "",
      url : "",
      start : "",
      end : "",
    });
    

 /*    
handleWeekendsToggle = () => {
  this.setState({
    weekendsVisible: !this.state.weekendsVisible
  })
}

handleDateSelect = (selectInfo) => {
  let title = prompt('Please enter a new title for your event')
  let calendarApi = selectInfo.view.calendar

  calendarApi.unselect() // clear date selection

  if (title) {
    calendarApi.addEvent({
      id: createEventId(),
      title,
      start: selectInfo.startStr,
      end: selectInfo.endStr,
      allDay: selectInfo.allDay
    })
  }
}

handleEventClick = (clickInfo) => {
  clickInfo.event.remove()
}

handleEvents = (events) => {
  this.setState({
    currentEvents: events
  })
}


function renderEventContent(eventInfo) {
  return (
    <>
      <b>{eventInfo.timeText}</b>
      <i>{eventInfo.event.title}</i>
    </>
  )
} */

    const handleInput = (event) => {
        setValues({
          ...form,
          [event.target.name]: event.target.value,
        });
      };
    
      const handleSubmit = (event) => {
        event.preventDefault();
        props.registerEvent(form);
      };
    
/* 
  state = {
    weekendsVisible: true,
    currentEvents: []
  } */ 
    counterpart.registerTranslations('en',en) 
    counterpart.registerTranslations('es',es)  
    counterpart.setLocale(localStorage.getItem('langlocal'));
const titulo =counterpart.translate('titulo')
const Descripción =counterpart.translate('Descripción')
const MeetURL =counterpart.translate('MeetURL')
const FechaInicio =counterpart.translate('FechaInicio')
const FechaFin =counterpart.translate('FechaFin')
    return (
      <>
       <HeaderEmpresa />
      <div className="volver-legal">
        <Link className="volver-leg-icon" onClick={goBack} to="company/home">
          {" "}
          &#x276e;&nbsp;&nbsp; {<Translate content="home" component="span"/>}
        </Link>
      </div>
        <div className='calendar-events-company'>
          <div className='calendar-events-company-main'>
            <FullCalendar
              plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
              headerToolbar={{
                left: 'prev,next today',
                center: 'title',
                right: 'dayGridMonth,timeGridWeek,timeGridDay'
              }}
              initialView='dayGridMonth'
              editable={true}
              selectable={true}
              selectMirror={true}
              dayMaxEvents={true}/* 
              weekends={this.state.weekendsVisible} */
              events={props.actual.eventos} // alternatively, use the `events` setting to fetch from a feed
/*               select={this.handleDateSelect} */
/*               eventContent={renderEventContent} // custom render function
              eventClick={this.handleEventClick}
              eventsSet={this.handleEvents} // called after events are initialized/added/changed/removed */
            /* you can update a remote database when these fire:
            eventAdd={function(){}}
            eventChange={function(){}}
            eventRemove={function(){}}
            */
            />
          </div>
          
        <form className="calendar-events-company-side-form" onSubmit={handleSubmit}>
            <input
              className="input_calendar"
              type="text"
              placeholder={titulo}
              name="title"
              onChange={handleInput}
            />
            <input
              className="input_calendar"
              type="text"
              placeholder={Descripción}
              name="description"
              onChange={handleInput}
            />
            <input
              className="input_calendar"
              type="text"
              placeholder={MeetURL}
              name="url"
              onChange={handleInput}
            />
            <input
              className="input_calendar-date"
              type="datetime-local"
              placeholder={FechaInicio}
              name="start"
              onChange={handleInput}
            />
            <input
              className="input_calendar-date"
              type="datetime-local"
              placeholder={FechaFin}
              name="end"
              onChange={handleInput}
            />
            <div className="buttons-calendar">
              <button className="buttons-calendar-guardar" onSubmit={handleSubmit}> {<Translate content="GUARDAR" component="span"/>} </button>
            </div>
          </form>
        </div>
      </>
    )
  

  /*   <div className='calendar-events-company-sidebar-section'>
    <h2>All Events ({this.state.currentEvents.length})</h2>
    <ul>
      {this.state.currentEvents.map(renderSidebarEvent)}
    </ul>
  </div> */

}

const mapDispatchToProps = {
  registerEvent,
  getProject,
  getEventsProject
};


const mapStateToProps = (state) => {
  return {
    user: state.user,
    actual : state.ActualProject,
    events: state.events
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FormEvents);
