import React, { useContext, useState } from 'react';
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import { StyledAuraGreen, StyledAuraGrey, StyledIndexCourse, StyledPopupButton, StyledPopupDescription, StyledPopupTitle } from './SnakeStyles';
import { SchneiderContext } from '../../../../context/SchneiderContext';
import { useSelector } from 'react-redux';
import { CustomizableTitle } from '../../../../styles/TitleStyles';

interface snakeProgressProps {
  data: any;
}

interface popUpData {
  title: string;
  description: string;
  url: string;
}


const SnakeProgress = ({ data }: snakeProgressProps) => {
  const { schneider } = useContext(SchneiderContext);
  const [popUp, setPopUp] = useState(false);
  const { lang } = useSelector((state: any) => state);
  const [popUpData, setPopUpData] = useState({
    title: '',
    description: '',
    url: '',
  });

  const handlePopUp = (e: any, item: popUpData) => {
    e.preventDefault()
    setPopUpData({
      title: item.title,
      description: item.description,
      url: item.url,
    })
    setPopUp(!popUp)
  }


  return (
    <div style={{ width: '100%' }}>
      <Dialog
        style={{}}
        open={popUp}
        onClose={() => setPopUp(false)}
        // scroll={scroll}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >

        <StyledPopupTitle >
          {popUpData.title}
        </StyledPopupTitle>

        <DialogContent>
          <div>
            <hr />
            <StyledPopupDescription>
              {popUpData.description}
            </StyledPopupDescription>
            <a href={popUpData.url} target="_blank" rel="noopener noreferrer">
              <StyledPopupButton border={schneider.color.primary}>
                {lang === 'es' ? 'Ir al curso' : 'Go to course'}
              </StyledPopupButton>
            </a>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setPopUp(!popUp)}>
            Cerrar
          </Button>
        </DialogActions>
      </Dialog>
      <div style={{ marginTop: '-40px' }}>
        <div>
          {
            data?.map((item: any, index: any) => {
              return (
                <div key={index}>
                  <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'flex-start',
                    width: '100%',
                    marginTop: '10px'
                  }}>
                    <StyledAuraGreen background={schneider.color.primary}>
                      <StyledAuraGrey>
                        <StyledIndexCourse>{index + 1}</StyledIndexCourse>
                      </StyledAuraGrey>
                    </StyledAuraGreen>
                    <div style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      justifyContent: 'center',
                      height: '40px',
                    }}>
                      <CustomizableTitle color={'#393939'} size={'16px'} >{item.title}</CustomizableTitle>
                    </div>
                    <StyledPopupButton
                      border={schneider.color.primary}
                      onClick={(e) => handlePopUp(e, item)}>
                      {lang === 'es' ? 'Ver' : 'View'}
                    </StyledPopupButton>
                  </div>
                  {index === data.length - 1 ? null : <hr />}
                </div>
              )
            })
          }
        </div>
      </div>

    </div>
  )
}

export default SnakeProgress;
