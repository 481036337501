import React, {useState} from 'react';
import './HomeMentorUnilever.css';
import Sidebar from "../../../components/Sidebar/Sidebar";
import Navbar from "../../../components/Navbar/Navbar";
import {useSelector} from "react-redux";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import WindowIcon from "@mui/icons-material/Window";
import BannerUnileverHome from "../../../components/unilever/banner/BannerUnileverHome";
import CheckBoxOutlinedIcon from "@mui/icons-material/CheckBoxOutlined";
import IndeterminateCheckBoxOutlinedIcon from "@mui/icons-material/IndeterminateCheckBoxOutlined";
import SearchIcon from "@mui/icons-material/Search";
import UnileverMentorCardHomeList from "../../profesional/home/UnileverMentorCardHomeList";
import UnileverMentorCardHomeGrid from "../../profesional/home/UnileverMentorCardHomeGrid";

// https://www.figma.com/file/bRCsb0kTVy7fsHDPrWlxTn/Coally?node-id=4893%3A30786&t=7ahUaxnS4WbW7Czd-4
// http://localhost:3001/unilever/mentor

const HomeMentorUnilever = () => {
  const {lang} = useSelector(state => state);
  const [listView, setListView] = useState(true);
  const [gridView, setGridView] = useState(false);
  const [filters, setFilters] = useState([]);

  const handleListView = () => {
    setListView(true);
    setGridView(false);
  }

  const handleGridView = () => {
    setListView(false);
    setGridView(true);
  }

  const handleFilters = (filter) => {
    const newFilters = [...filters];
    const index = newFilters.indexOf(filter);
    if (index === -1) {
      newFilters.push(filter);
    } else {
      newFilters.splice(index, 1);
    }
    setFilters(newFilters);
  }

  // const handleClearFilters = () => {
  //   setFilters([]);
  // }

  return (
    <div>
      <Sidebar location={'home'}/>
      <div
        className="BodyWeb"
        style={{
          padding: "0rem 2rem",
          position: "absolute",
          right: "0",
        }}
      >
        <Navbar locate={'home'}/>
        <div className="home-unilever-profesional-banner">
          <BannerUnileverHome/>
        </div>
        <div style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
        }}>
          <div style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "90%",
          }}>
            <div className={'home-mentor-unilever-container'}>
              {/*  filtros*/}
              <div className={'home-mentor-unilever-filtro'}>
                <h2 className={'home-mentor-unilever-filtro-titulo'}>{lang === 'es' ? 'Mentoría' : 'Mentoring'}</h2>
                <hr/>
                <h2 className={'home-mentor-unilever-filtro-subtitulo'}>
                  {lang === 'es' ? 'Desarrollo e ingeniería' : 'Development and engineering'}
                </h2>
                {/*sub*/}
                <div className="home-mentor-unilever-filtro-check-container" onClick={() => handleFilters(
                  'Frontend'
                )}>
                  {filters.includes('Frontend') ?
                    <CheckBoxOutlinedIcon sx={{fontSize: '20px', color: '#202256'}}/>
                    :
                    <IndeterminateCheckBoxOutlinedIcon sx={{fontSize: '20px', color: '#C5C5C5'}}/>}
                  <h1 className='home-mentor-unilever-filtro-check-title'
                      style={{margin: 0, marginLeft: '20px'}}>Frontend</h1>
                </div>
                <h2 className={'home-mentor-unilever-filtro-subtitulo'}>{lang === 'es' ? 'Marketing' : 'Marketing'}</h2>
                {/*sub*/}
                <div className="home-mentor-unilever-filtro-check-container" onClick={() => handleFilters(
                  'Especialista SEO'
                )}>
                  {filters.includes('Especialista SEO') ?
                    <CheckBoxOutlinedIcon sx={{fontSize: '20px', color: '#202256'}}/>
                    :
                    <IndeterminateCheckBoxOutlinedIcon sx={{fontSize: '20px', color: '#C5C5C5'}}/>}
                  <h1 className='home-mentor-unilever-filtro-check-title'
                      style={{
                        margin: 0,
                        marginLeft: '20px'
                      }}>{lang === 'es' ? 'Especialista SEO' : 'SEO Specialist'}</h1>
                </div>
                <h2
                  className={'home-mentor-unilever-filtro-subtitulo'}>{lang === 'es' ? 'Diseño y UX' : 'Design and UX'}</h2>
                {/*sub*/}
                <div className="home-mentor-unilever-filtro-check-container" onClick={() => handleFilters(
                  'Branding designer'
                )}>
                  {filters.includes('Branding designer') ?
                    <CheckBoxOutlinedIcon sx={{fontSize: '20px', color: '#202256'}}/>
                    :
                    <IndeterminateCheckBoxOutlinedIcon sx={{fontSize: '20px', color: '#C5C5C5'}}/>}
                  <h1 className='home-mentor-unilever-filtro-check-title'
                      style={{margin: 0, marginLeft: '20px'}}>Branding
                    designer</h1>
                </div>
              </div>
              {/*sub-nab*/}
              <div className={'home-mentor-unilever-sub-container'}>
                <div className={'home-mentor-unilever-container-topbar'}>
                  <div className={'home-mentor-unilever-container-searchbar'}>
                    <SearchIcon className={'lupa'}/>
                    <input className={'home-mentor-unilever-input-searchbar'}
                           placeholder={lang === 'es' ? 'Buscar profesionales' : '^Find professionals'}/>
                    <button
                      className={'home-mentor-unilever-button-searchbar'}>{lang === 'es' ? 'Buscar' : 'Search'}</button>
                  </div>
                  <div className={'home-mentor-unilever-view-container'}>
                    <div className={'home-mentor-unilever-view-subcontainer'}>
                      <button onClick={handleListView}
                              className={listView ? 'home-mentor-unilever-button-view-active' : 'home-mentor-unilever-button-view'}>
                        <FormatListBulletedIcon
                          sx={{fontSize: 24, color: `${listView === true ? '#FFFFFF' : '#C5C5C5'}`}}/>
                      </button>
                      <button onClick={handleGridView}
                              className={gridView ? 'home-mentor-unilever-button-view-active' : 'home-mentor-unilever-button-view'}>
                        <WindowIcon sx={{fontSize: 24, color: `${gridView === true ? '#FFFFFF' : '#C5C5C5'}`}}/>
                      </button>
                    </div>
                    <hr/>
                  </div>
                </div>
                {/*cards*/}
                <div className="home-unilever-profesional-inner-2-2-container">
                  {
                    listView ? (
                        <div className="home-unilever-profesional-inner-2-2-container-inner">
                          {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20].map((item, index) => (
                            <UnileverMentorCardHomeList key={index}/>
                          ))}
                        </div>
                      )
                      :
                      (
                        <div className="home-unilever-profesional-inner-2-2-container-grid">
                          {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20].map((item, index) => (
                            <UnileverMentorCardHomeGrid key={index}/>
                          ))}
                        </div>
                      )
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HomeMentorUnilever;