import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { ReduxStateInterface } from "../../../../types/reduxInterface";

const useCircularProgress = () => {
  const { cv } = useSelector((state: ReduxStateInterface) => state);
  const {user} = useSelector((state: ReduxStateInterface) => state);

  const [progress, setProgress] = useState(0);

  useEffect(() => {
    let counter = 0;
    if (user?.roles === 'coallier' && cv) {
      if (cv.aptitudes_principales && cv.aptitudes_principales.length > 0) counter += 12.5
      if (cv.certifications && cv.certifications.length > 0) counter += 12.5
      if (cv.contacto && cv.contacto.correo.length > 0) counter += 12.5
      if (cv.educacion && cv.educacion.length > 0 && cv.educacion[0].NombreInstitucion !== "") counter += 12.5
      if (cv.experiencia && cv.experiencia.length > 0 && cv.experiencia[0].Nombreempresa !== "") counter += 12.5
      if (cv.extracto && cv.extracto !== "") counter += 12.5
      if (cv.languages && cv.languages.length > 0) counter += 12.5
      if( user?.avatar && user?.avatar !== "")counter += 12.5
      setProgress(counter);
    }
  }, [cv, user]);

  return progress;
};

export default useCircularProgress;
