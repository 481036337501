import React, {useContext} from 'react'
import { useSelector } from "react-redux";
import {Link, useParams} from "react-router-dom";
import Advise from "../../../../../assets/icons/advise.svg";
import ApplicantCvModalButton from "../../../buttons/cvmodalbutton/ApplicantCvModalButton";
import axios from "axios";
import {OcultarButton} from "./OpportunityApplicantCard";
import {ReduxStateInterface} from "../../../../types/reduxInterface";
import {SelectApplicantButtonList} from "./AplicantsStyles";
import {SchneiderContext} from "../../../../context/SchneiderContext";

export default function ApplicantListCard({ keynum, compatibility, name, job, image, id, LinkedInCv, generalCv, opportunityId }: any) {
  const { lang } = useSelector((state: ReduxStateInterface) => state)
  const {schneider} = useContext(SchneiderContext)
  const ideproy = useParams<any>().id

  const handleContact = async (e: any) => {
    e.preventDefault()
    await axios.post("https://findally-backend.herokuapp.com/api/applicants/contact/coallier", {
      proyecto: ideproy,
      usuarios: [id]
    }).then(res => {
      console.log(res)
      return window.location.reload()
    }).catch(err => {
      console.log(err)
    })
  }
  return (
    <div>
      <div className={'prof-list-global-cont'}>
        <div className={'prof-list-rig-cont'}>
          <div className={'prof-list-rig-sub-cont'}>
            <h2 className={'prof-list-number'}>{keynum}{lang === 'es' ? 'to' : 'th'}</h2>
            <div className={'prof-list-card-image-filtro'}>
              <img className={'prof-list-img'}
                   src={image ? image : 'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png'}
                   alt={'profile-pic'} />
            </div>
            <div>
              <Link to={`/${schneider.route}/company/applicant/${id}?op=${opportunityId}`} className={'prof-list-name'}>{name}</Link>
              <h2 className={'prof-list-job'}>{job}</h2>
            </div>
          </div>
        </div>
        <div className={'prof-list-compat-cont'}>
          <img src={Advise} alt={'advise-icon'} />
          <h2 className={'prof-list-compat'}>
            {lang === 'es' ? `${compatibility} Compatibilidad` : `${compatibility} Compatibility`}
          </h2>
        </div>
        <div className={'prof-list-button-cont'}>
          <div className={'prof-list-ver-perfil-cont'}>
            <OcultarButton userId={id} />
          </div>
          <ApplicantCvModalButton idUser={id} LinkedInCv={LinkedInCv} generalCv={generalCv}/>
          <SelectApplicantButtonList onClick={(e) => handleContact(e)} color={schneider.color.primary}>{lang === 'es' ? 'Seleccionar' : 'Select'}</SelectApplicantButtonList>
        </div>
      </div>
      <hr />
    </div>
  )
}
