import axios from "axios";
import {
  ResponseConfig,
  Datum,
} from "../../../../types/responseOportunitiesInterface";
import { CvInterface } from "../../../../types/cvInterface";

export const fetchSchneiderData = async ({
  name,
}: {
  name: string;
}): Promise<ResponseConfig> => {
  try {
    const response = await axios.post(
      "https://findally-backend.herokuapp.com/api/university/oportunities?skip=0&limit=4",
      {
        name,
      }
    );
    return response.data as any;
  } catch (error) {
    console.log(error);
  }
};

export const fetchOpportunitiesData = async (
  opportunities: Datum[],
  cv: CvInterface
) => {
  const promises = opportunities.map(async (opportunity, index) => {
    const empleoData = {
      ID: opportunity._id,
      Descripcion: opportunity?.DescribeProyecto || "",
      Descripcion_empresa: opportunity?.descripcionEmpresa || "",
      Experiencia: opportunity?.experienciaAnos
        ? `${opportunity.experienciaAnos} años`
        : "",
      Habilidades_tecnicas: opportunity?.habilidadesTecnicas?.join(", ") || "",
      Habilidades_Blandas: opportunity?.habilidadesBlandas?.join(", ") || "",
      Idiomas: opportunity?.Lenguaje?.join(", ") || "",
      Salario: opportunity?.presupuesto || "",
      Beneficios: opportunity?.listadoBeneficios?.join(", ") || "",
      Tipo_de_empleo: opportunity?.modalidadDeContratacion || "",
      Modalidad: opportunity?.modalidadDeTrabajo || "",
    };
    const aplicantesData = cv
      ? {
          ID: cv._id,
          Descripcion: cv.extracto || "",
          Experiencia:
            cv.experiencia?.flatMap((exp) => {
              return (
                exp.cargos?.map((cargo: any) => {
                  return {
                    nombre_cargo: cargo.nombrecargo ? cargo.nombrecargo : "",
                    duracion: cargo.duracion ? cargo.duracion : "",
                    ubicacion: cargo.ubicacion ? cargo.ubicacion : "",
                    descripcion: cargo.descripcion ? cargo.descripcion : "",
                  };
                }) || []
              );
            }) || [],
          Estudios:
            cv.educacion?.map((edu) => edu.Titulo_Certificacion).join(", ") ||
            "",
          Habilidades_tecnicas: cv.aptitudes_principales?.join(", ") || "",
          Habilidades_Blandas: "",
          Logros: "",
          Trabajo_Social: "",
          Universidad: "",
          Idiomas:
            cv.languages?.map((idioma) => idioma.Language).join(", ") || "",
          Salario: cv.aspiracion_salarial || "",
          Tipo_de_empleo: "",
          Modalidad: "",
          Puntaje_hr: 0,
        }
      : null;
    const postData = {
      empleo: empleoData,
      aplicantes: aplicantesData ? [aplicantesData] : [],
    };
    const res = await axios.post<any>(
      "https://resume-matching-deploy.herokuapp.com/aplicantes-empleo",
      postData,
      { timeout: 3 * 60 * 1000 }
    );
    const { fit1 } = res.data.response;
    return { ...opportunity, fit: fit1, index: index + 1 };
  });
  return Promise.all(promises);
};
