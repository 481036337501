import styled from "styled-components";
import { TfiArrowCircleRight } from "react-icons/tfi";
import { MdOutlineAlarm } from "react-icons/md";


const StyledEducationSubcontainer = styled.div`
padding-inline: 2.5%;
@media (max-width: 768px) {
  padding-inline: 0%;
}
`;

const StyledSector = styled.div`
  display: flex;
  flex-direction: row;
  `;
const StyledCardsSector = styled.div`
  display: flex;
  justify-content: flex-start;
  margin-top: 2%;
  width: 100%;
  @media (max-width: 1300px) {
    overflow-x: scroll;
  `;
const StyledHabilidades = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 2%;
  margin-bottom: 2%;
  height: 250px;
  justify-content: space-between;
  `;

const ArrowLeft = styled(TfiArrowCircleRight)`
  rotate: 180deg;
  font-size: 24px;
`;

const ArrowRight = styled(TfiArrowCircleRight)`
  font-size: 24px;
`;

const Alarm = styled(MdOutlineAlarm)<{ color?: string }>`
  font-size: 24px;
  color: ${(props) => (props.color ? props.color : "#202256")};
`;


export {
  StyledEducationSubcontainer,
  StyledSector,
  ArrowLeft,
  ArrowRight,
  Alarm,
  StyledCardsSector,
  StyledHabilidades,
}