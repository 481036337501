import React, { useEffect, useState } from 'react';
import './MethodologyDetailscopy.css';
//import DateRangeIcon from '@material-ui/icons/DateRange';
//import ChatBubbleIcon from '@material-ui/icons/ChatBubble';
import Header from '../../components/Header/Header';
//import DoneAllIcon from '@material-ui/icons/DoneAll';
import UpdateCasos from "../../components/upload/UploadCaso";
//import { CheckCircleRounded, /* AdjustRounded */ } from '@material-ui/icons';
import {getSinglePhase ,setActualPhase, getEtapas , Methodology,setActualEtapa ,getSingleEtapa , ConsultorProjects, getProject, getSubEtapas} from "../../actions/index";
import { connect, useSelector } from 'react-redux';
/* import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore'; */
//import Etapascopy from '../Etapas/Etapascopy';
//import { Link } from 'react-router-dom';
import axios from 'axios';
import en from "../../lang/en-US";
import es from "../../lang/es-COL"
import GroupIcon from '@material-ui/icons/Group';
import { Link } from "react-router-dom";
import FeedbackIcon from '@material-ui/icons/Feedback';
import Upload from "../upload/Uploadcopy"
import InstagramIcon from '@material-ui/icons/Instagram';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import FacebookIcon from '@material-ui/icons/Facebook';
import ThumbDownIcon from '@material-ui/icons/ThumbDown';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import MailIcon from '@material-ui/icons/Mail';
import counterpart from "counterpart";
import Translate from "react-translate-component";
import Footer from '../Footer/Footer';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import { useParams } from 'react-router';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

function MethodologyDetails(props) {
  const [mensajepooup,setMensajepooup]=useState(false)
  const [enviarpropooup,setEnviarpropooup]=useState(false)
  const [formulariopooup,setFormulariopooup]=useState(false)
  const [reunion,setReunion]=useState(false)
  const [cargando,setCargando]=useState(false)
  const [cargandoerr,setCargandoerr]=useState(false)
  const [cargandonoerr,setCargandonoerr]=useState(false)
  const [subirtarea,setSubirtarea]=useState(false)
  const [statusrechazado, setstatusrechazado] = useState(false)
  const [msgrechazo, setmsgrechazo] = useState('')
  const etapedata = useSelector(state => state.EtapaData)
  const [comment, 
    setComment
  ]= useState("");
  const [animationpopup, setAnimationpopup] = useState({animation:'popupmensaje 0.25s alternate  ease-in'})
  const [input, setInput] = useState({
    proyecto: props.project._id,
    asunto: '',
    mensaje: ''
})
  const [inputreunion, setInputreunion] = useState({
    proyecto: props.project._id,
    titulo: '',
    descripcion: '',
    fecha:'',
    hora:'',
    enlace:''
})
  const [inputformulario, setInputformulario] = useState({
    proyecto: props.project._id,
    boton:''
})
function actualizarmensaje(){
  setMensajepooup(true)
  setInput({ ...input, proyecto: props.project._id })
}
function actualizreunion(){
  setReunion(true)
  setInputreunion({ ...inputreunion, proyecto: props.project._id })
}
// function actualizformulario(){
//   setFormulariopooup(true)
//   setInputformulario({ ...inputformulario, proyecto: props.project._id })
// }
function mandarMensaje(){
  setCargando(true)
  // console.log(input)
  axios.post('https://findally-backend.herokuapp.com/api/Project/send/message/company', input).then(response => {
    cerrarpopup3()
    setMensajepooup(false)
    setCargando(false)
    setCargandonoerr(true)
      },error =>{
        cerrarpopup3()
        setCargandoerr(true)
        setCargando(false)
        setMensajepooup(false)
    })
}
function cerrarpopup(){
  setMensajepooup(false)
  setReunion(false)
  setCargandoerr(false)
  setCargando(false)
  setSubirtarea(false)
  setEnviarpropooup(false)
  setFormulariopooup(false)
  setstatusrechazado(false)
}
const [contadorr, setcontadorr] = useState(1)
function cerrarpopup3(){
  setMensajepooup(false)
  setReunion(false)
  setCargandoerr(false)
  setcontadorr(contadorr+1)
  vaciarinfo()
  setCargando(false)
  setSubirtarea(false)
  setEnviarpropooup(false)
  setFormulariopooup(false)
}
function mandarReunion(){
  setCargando(true)
  axios.post('https://findally-backend.herokuapp.com/api/Project/send/message/meet', inputreunion).then(response => {
    setMensajepooup(false)
    setReunion(false)
    setCargando(false)
    setCargandonoerr(true)
      },error =>{
        setCargandoerr(true)
        setReunion(false)
        setCargando(false)
    })
}
/*     const readCookie =(name) => {
        var nameEQ = name + "=";
        var ca = document.cookie.split(";");
        for (var i = 0; i < ca.length; i++) {
          var c = ca[i];
          while (c.charAt(0) === " ") c = c.substring(1, c.length);
          if (c.indexOf(nameEQ) === 0) {
            return decodeURIComponent(c.substring(nameEQ.length, c.length));
          }
        }
        return null;
      } */
  //  const {tittle ,  description } = props.project[params.id]
    const handleButtonClick = (project) => {
     // console.log(project)
        props.setActualEtapa(project)
        // console.log(etapedata)
    }
    //console.log(prueba.map(project=>project))
    //console.log(readCookie('sigleEtapa'))
/*     const getMethodology = () => {
            props.Methodology(readCookie('Methodology'));
            props.setActualPhase(readCookie('sigleEtapa'));
      }; */
      const [metodologianueva] = useState([])
      const [metodologianueva2, setmetodologianueva2] = useState([])
      const {project} = useParams()
      const vaciarinfo=()=>{
        const length=metodologianueva.length
        for(let o=0;o-3<length;o++){
        if(metodologianueva.length>0) {
         metodologianueva.shift()

        }}
      if(metodologianueva.length===0)pedirinfo()
      }
       const pedirinfo=()=>{
      axios.get(`https://findally-backend.herokuapp.com/api/Project/${project}`).then(respuesta=>
      respuesta.data.methodology_new_list.methodology_list.map(e=>axios.get("https://findally-backend.herokuapp.com/api/etapas/new/"+e).then(respuesta=>
      {metodologianueva.push(respuesta.data)
        setmetodologianueva2(respuesta.data)
        console.log(respuesta.data)
      })))      
      }
      
      
      useEffect(() => {
      }, [metodologianueva2,metodologianueva])
      useEffect(() => {
        const {
          match: { params },
        } = props;
        const getActualProject=()=>{
          if(!props.ActualProject){
            props.getProject(params.project)         
          }
          
        }
        // eslint-disable-next-line
        pedirinfo()
        getActualProject();  
/*         getMethodology(); */     // eslint-disable-next-line
    }, [input]);

    
  //const lang = useSelector(state => state.lang)
  

  // const cerrar=()=>{
  //   setTimeout(function(){
  //     setAnimationpopup({animation:'popupmensaje 0.25s alternate  ease-in'})
  //   }, 250);
  //   setAnimationpopup({animation:'popupmensajeend 0.25s alternate  ease-in'})
  // }
  counterpart.registerTranslations('en',en) 
  counterpart.registerTranslations('es',es)  
  counterpart.setLocale(localStorage.getItem('langlocal'));

    const ordenar= () =>{
        props.subEtapas.sort(function (a, b){
          return (a.number - b.number)
      })
    }
    ordenar()
    // const lista=project
   // const lista=props.project.listMetho
    // const listMethoChange= function(evento,index){
    //   if(evento.target.checked){
    //     props.project.listMetho[index].status=true
    //   }else{
    //     props.project.listMetho[index].status=false
    //   }
    // }
    //console.log(props.project.methodology_list)
    useEffect(() => {
      const {
        match: { params },
      } = props;
        const getActualProject=()=>{
            if(!props.ActualProject){
                props.getProject(params.project)         
            }
        
        }
        // eslint-disable-next-line
        setAnimationpopup({animation:'popupmensaje 0.25s alternate  ease-in'})
        getActualProject();  
        /* getMethodology();  */    // eslint-disable-next-line
    }, []);
/*     console.log(props)


    console.log('sadsdf')
    console.log('sadsdf')
    console.log('sadsdf')
    console.log(props)
    console.log('sadsdf')
    console.log('sadsdf')
    console.log('sadsdf')
    console.log('sadsdf') */
   
    return ( 
        <>
          <div className="fondo_methodology"></div>
        <div style={mensajepooup||reunion||formulariopooup||enviarpropooup||subirtarea?{filter: `blur(1rem)`}:{filter: `blur(0rem)`}}>
         {/* <select className="Header-lang" value={lang} onChange={(e)=>dispatch(changeLang(e.target.value))}>
        <option value="en">en-US</option>
        <option value="es">es-COL</option>
      </select> */}
   
        <Header />
      {/* <div className="volver-legal">
        
        <div style={{display:'flex',justifyContent:'space-between',alignItems:'center'}}>
          {props.project.nombre?
        <p style={{display:'flex',marginLeft:'150px',fontSize:'30px',marginBottom:'0'}}>{props.project.nombre}</p>:<p style={{display:'flex',marginLeft:'150px',fontSize:'30px',marginBottom:'0'}}>{props.project.nombre}</p>}
        <div style={{display:'flex',marginRight:'5%'}}>
          <DoneAllIcon style={{cursor:'pointer',width:'2rem',height:'2rem'}} onClick={()=>actualizformulario()}></DoneAllIcon>
          <DateRangeIcon style={{cursor:'pointer',width:'2rem',height:'2rem',marginLeft:'10px'}} onClick={()=>actualizreunion()}></DateRangeIcon>
          <ChatBubbleIcon style={{cursor:'pointer',marginLeft:'10px',width:'2rem',height:'2rem'}} onClick={()=>actualizarmensaje()}></ChatBubbleIcon>
        </div>
      </div>
      </div> */}
      {/* <div className='sub_header'>
            <div className="metho-details-tittle_edit">
                <p className="tittle-project-methodology_edit">{props.project.nombre || <Translate content="Nombredelprojecto" component="span"/>}</p>
           </div>
                <p className="metho-details-tittle-p_subtittle_edit">{props.project.linea_negocio || "Coally"}</p>    
      </div> */}
      <div className='container_body_metho'>
      {  
                props.project.nombre?<div style={{zIndex:'3',fontWeight:'600',marginTop:'2rem',fontSize:'1.6rem',padding:'10px',color:'#2e2e31'}}>
                  <div style={{textTransform:'uppercase',fontWeight:'600'}}>
                  <Link className="volver-leg-icon boldfont" to="/consultor/projects">

                    &#x276e;&nbsp;&nbsp;&nbsp;{props.project.nombre}
                    
                  </Link>
                    </div>
                  
                  </div>:null
              }
        <div className="metho-details-containercopy">
          <div className="metho-details_editcopy2">

          <div className="metho-details_editcopy">
                {/*  <h3 className="metho-details-list-definition-project_edit"> {<Translate content="Definicióndelproyecto" component="span"/>}</h3>
               <li>{props.subEtapas.Qué}</li> 
              <hr  style={{width:'80%', marginLeft:'7%', marginTop:'5px'}}/>*/}
                <div >
                  <div>
                    {metodologianueva?metodologianueva.map((e,index)=>{return(<div key={index+'sda'}><label className='semiboldfont sombrahover' htmlFor={`checkbox${e._id}${index}l2`} key={index} style={{background:'white',width:'100%',textTransform:'uppercase',borderRadius:'10px',color:'#696a7c',margin:'0',marginTop:'1rem',cursor:'pointer',fontWeight:'900',padding:'0.5rem 0.5rem 0.5rem 1rem',display:'flex',justifyContent:'space-between'}}><div style={{cursor:'pointer'}}>{e.name}</div><ArrowDropDownIcon style={{color:'#696a7c'}}/></label>
                    {/* <input type="checkbox" defaultChecked  id={`checkbox${e._id}${index}l`} className="checkboxestilo3"/> */}
                    
                    {/* <div className="deliverablesclasproject"><label htmlFor={`checkbox${e._id}${index}l2`} style={{background:'white',textTransform:'uppercase',width:'100%',color:'#2e2e31',fontWeight:'900',padding:'0.3rem 0.3rem 0.3rem 2rem',margin:'-0.188rem'}}>{<Translate content="DELIVERABLES" component="span" />}</label> */}
                    <input type="checkbox" defaultChecked id={`checkbox${e._id}${index}l2`} className="checkboxestilo4"/>
                    <div className="labelfinalproject" style={{background:'white',color:'#2e2e31',margin:'-0.188rem',borderRadius:'0px 0px 15px 15px',marginBottom:'1rem'}}>
                    {e.phases.map((j,index)=>
                    
                    <label   className='metho-label-new' key={index+'ggdf'} onClick={()=>{handleButtonClick(j);console.log(j)}} htmlFor={`checkbox${e._id}${index}`} style={{display:'flex',margin:'0.2rem',paddingBottom:'0.2rem',marginLeft:'1rem',overflowWrap:'break-word',cursor:'pointer',fontWeight:'900',alignItems:'center'}}>
                      <>
                      <hr className="linea_ht"/>
                      {j.status==="En espera"?
                      <input type="checkbox" className="checkboxestilo2"/>
                      :
                      <input type="checkbox" defaultChecked className="checkboxestilo2" style={{background:'red'}}/>} 
                      {j.status ==="En proceso" &&<label htmlFor={`checkbox${e._id}${index}`}  style={{background:'#f47227'}} className="checkboxestilo">
                        </label>}
                        {j.status ==="En espera" &&<label htmlFor={`checkbox${e._id}${index}`}  style={{background:'gray'}} className="checkboxestilo">
                          </label>}
                          {j.status ==="Aceptada" &&<label htmlFor={`checkbox${e._id}${index}`}  style={{background:'#65ff00'}} className="checkboxestilo">
                            </label>}
                            {j.status ==="Rechazada" &&<label htmlFor={`checkbox${e._id}${index}`}  style={{background:'red'}} className="checkboxestilo">
                              </label>}
                              </>
                              <span style={{marginLeft:'0.5rem'}}>{index +1}. {j.name}</span>
                              {j.status ==="Rechazada" &&
                              <div style={{position:'absolute',right:'1%',color:'red'}} onClick={()=>{setstatusrechazado(true); j.mensajetarea && setmsgrechazo(j.mensajetarea)}}>
                                <FeedbackIcon></FeedbackIcon>
                                </div>}
                                </label>)}
                    </div></div>
                    // </div>
                    )}):null}
                  
                    </div>
                </div>
          </div>
                  {/* { lista ?<div style={{display:'flex',justifyContent:'center'}}><button className="btn_mensaje4" onClick={()=>setEnviarpropooup(true)}>{<Translate content="Subircasodeexito" component="span" />}&nbsp;&nbsp;&nbsp;<CloudUploadIcon></CloudUploadIcon></button></div>:null} */}
                  <div style={{display:'flex'}}>
<button className="btn_mensaje5" style={{background:'#765bff'}} onClick={()=>actualizreunion()}><GroupIcon></GroupIcon>{<Translate content="Reunion" component="span" />}</button>
<button className="btn_mensaje5" style={{background:'#765bff'}} onClick={()=>actualizarmensaje()}><MailIcon></MailIcon>{<Translate content="Mensajeeee" component="span" />}</button>
<button className="btn_mensaje5-tarea" onClick={()=>setSubirtarea(true)}><CloudUploadIcon></CloudUploadIcon>{<Translate content="SubirTarea" component="span" />}</button>
</div>
          </div>
          <div className='video_archivos__containercopy'> 
          {etapedata ? 
      <div className="metho-details-content">
        <div className="metho-details-content-video">
          <iframe
            width="100%"
            height="515"
            src={
             `https://www.youtube.com/embed/${etapedata.video}` ||
              "https://www.youtube.com/embed/onV54dQZgSg"
            }
            title="vid"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </div>
        <div className="metho-details-content-description mediumfont" style={{color:'#696A7C'}}>
          <p className="metho-details-content-description-tittle mediumfont" style={{color:'#696A7C'}}>
            {etapedata.que}
          </p>
          {/* //{props.etapedata.Descripción || <Translate content="descripciondelatarea" component="span"/>} */}
          {etapedata.description || <Translate className="mediumfont" content="descripciondelatarea" component="span"/>}
        </div>
        <div className='mediumfont' style={{marginTop:'3%',color:'#696A7C'}}>
          {etapedata.template?<a href={`${etapedata.template}`}>{<Translate className='mediumfont' style={{color:'#f47227'}} content="Descargarplantillaaqui" component="span" />}</a>:<></>}
        </div>
        <div style={{margin:'3%',width:'98%',color:'#696A7C'}}>
          {etapedata.deliverable?<div style={{display:'flex'}}><p ><span style={{color:'#696A7C',fontWeight:'bold'}}>{etapedata.deliverable.slice(0,11)}</span>{etapedata.deliverable.slice(11)}</p></div>:<></>}
        </div>
        
        {/* <label className="tarea-container-methocopy" htmlFor='textarea'>
          <p className="placeholder-task-methocopy" htmlFor='textarea'>Mensaje:</p> 
        <textarea value={comment} className="comments-text-inputcopy" id='textarea' onChange={(e)=>setComment(e.target.value)}/> 
        </label>
        <div className='upload_file_css'>
        <Upload idUsuario={props.user.id} idProyecto={props.ActualProject._id} nombre={props.etapedata.name} idPhase={ props.etapedata.Qué} comCoallier={comment}/>
        </div> 
  // :props.project.listMetho?*/}
        </div>

  :metodologianueva+','!==','?
  <div className="metho-details-content">
        <div className="metho-details-content-video">
          <iframe
            width="100%"
            height="515"
            style={{borderRadius:'8px'}}
            src={
             `https://www.youtube.com/embed/${metodologianueva[0].phases[0].video}` ||
              "https://www.youtube.com/embed/onV54dQZgSg"
            }
            title="vid"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </div>
        <div className="metho-details-content-description mediumfont" style={{color:'#696A7C'}}>
          <p className="metho-details-content-description-tittle mediumfont" style={{color:'#696A7C'}}>
            {metodologianueva[0].phases[0].que}
          </p>
          {/* //{props.etapedata.Descripción || <Translate content="descripciondelatarea" component="span"/>} */}
          {metodologianueva[0].phases[0].description || <Translate className='mediumfont' content="descripciondelatarea" component="span"/>}
        </div>
        <div style={{marginTop:'3%',color:'#696A7C'}}>
          {metodologianueva[0].phases[0].template?<a href={`${metodologianueva[0].phases[0].template}`}>{<Translate className='mediumfont' style={{color:'#f47227'}} content="Descargarplantillaaqui" component="span" />}</a>:<></>}
        </div>
        <div style={{margin:'3%',width:'98%',color:'#696A7C'}}>
          {metodologianueva[0].phases[0].deliverable?<div style={{display:'flex'}}><p><span style={{color:'#696A7C',fontWeight:'bold'}}>{metodologianueva[0].phases[0].deliverable.slice(0,11)}</span>{metodologianueva[0].phases[0].deliverable.slice(11)} </p></div>:<></>}
        </div>
        
        </div>
  : null  }
          </div> 
        </div>
<div style={{display:'flex',width:'100%',color:'white',justifyContent:'center'}}>
          {/* <button className="btn_mensaje5" onClick={()=>actualizformulario()}>Terminar</button> */}
          
        </div>
        </div>
        </div>
        {mensajepooup===true?
       <div className="mensaje_popup">
         <div className="container_popup_mensaje" style={animationpopup}>
         <div className="cancel_mensaje" onClick={()=>cerrarpopup3()}>
         X
         </div>

        <div  style={{display:'flex',width:'100%'}}>
          <div style={{display:'flex',flexDirection:'column',width:'100%',alignItems:'center',background:'white',color:'#212156',padding:'10% 0% 20% 0%',position:'relative',borderRadius:'10px'}}>
            <p style={{fontWeight:'600',fontSize:'22px',width:'80%'}}>{<Translate content="Enviarmensaje" component="span" />}</p>
           <div className="container_input_p_mensaje">
             <p style={{width:"100%",margin:'0'}}>{<Translate content="Asuntooo" component="span" />}</p>
             <input type="text" className="input_mensaje_popup" style={{width:"100%",height:'50%',borderTop:'0',borderRight:'0',borderLeft:'0'}} onChange={(event) => {setInput({ ...input, asunto: event.target.value })}}></input>
           </div>
           <div className="container_input_p_mensaje">
             <p style={{width:"100%",margin:'0',marginTop:'5%'}}>{<Translate content="Mensajeeee" component="span" />}</p>
             <input className="input_mensaje_popup" type="text"style={{width:"100%",resize:'none',borderTop:'0',borderRight:'0',borderLeft:'0'}} onChange={(event) => {setInput({ ...input, mensaje: event.target.value })}}></input>
           </div>
                  {cargando===false?
                  <button className="btn_mensaje" onClick={mandarMensaje}>{<Translate content="Enviarr" component="span" />}</button>:<button className="btn_mensaje_cargando" onClick={mandarMensaje}>{<Translate content="Cargandooo" component="span" />}</button>}
                  {/* </div>
                  <div className="derecha_mensaje_popup">
                    <p style={{textAlign:'center',width:'70%',color:'white',fontSize:'22px'}}>{<Translate content="Enviaunmensajealaempresa" component="span" />}</p>
                    <img style={{width:'60%'}} src="https://coally.com/wp-content/uploads/2021/10/Logo-0-1.png" alt="logo"></img>
                    <p style={{textAlign:'center'}}>The on-demand platform for Business Transformation services</p>
                    <div style={{width:'50%',display:'flex',justifyContent:'space-around'}}>
                    <FacebookIcon></FacebookIcon>
                    <InstagramIcon></InstagramIcon>
                    <LinkedInIcon></LinkedInIcon>
                  </div> */}
                  </div>
                  </div>
       </div>
       </div>
       :null
      }
        
        {enviarpropooup===true?
       <div className="mensaje_popup">
         <div className="container_popup_mensaje" style={animationpopup}>
         <div className="cancel_mensaje" onClick={()=>cerrarpopup()}>
         X
         </div>

        <div  style={{display:'flex',width:'100%'}}>
          <div style={{display:'flex',flexDirection:'column',width:'100%',alignItems:'center',background:'white',color:'#212156',padding:'10% 0% 20% 0%',position:'relative',borderRadius:'10px'}}>
            <p style={{fontWeight:'600',fontSize:'22px',width:'80%'}}>{<Translate content="Subirproyecto" component="span" />}</p>
           <div className="container_input_p_mensaje">
             <p style={{width:"100%",margin:'0'}}>{<Translate content="Proyecto" component="span" />}</p>
             <input type="text" className="input_mensaje_popup" value={props.project.nombre} style={{width:"100%",height:'50%',borderTop:'0',borderRight:'0',borderLeft:'0'}} disabled></input>
           </div>
           <div className="container_input_p_mensaje">
        {/* <h3 className="subtittle-casos-exito">{<Translate content="Seleccionaelproyectoysubeledocumentoconladescripciondetucasodeexito" component="span"/>}</h3> */}
        <UpdateCasos idUsuario={props.user.id} idProyecto={props.project._id} />
        </div>
                  
                  </div>
                  {/* <div className="derecha_mensaje_popup">
                    <p style={{textAlign:'center',width:'70%',color:'white',fontSize:'22px'}}>{<Translate content="Subircasodeexitooo" component="span" />}</p>
                    <img style={{width:'60%'}} src="https://coally.com/wp-content/uploads/2021/10/Logo-0-1.png" alt="logo"></img>
                    <p style={{textAlign:'center'}}>The on-demand platform for Business Transformation services</p>
                    <div style={{width:'50%',display:'flex',justifyContent:'space-around'}}>
                    <FacebookIcon></FacebookIcon>
                    <InstagramIcon></InstagramIcon>
                    <LinkedInIcon></LinkedInIcon>
                  </div> 
                  </div>*/}
                  </div>
       </div>
       </div>
       :null
      }
      {cargandoerr===true?<div className="mensaje_popup">
      <div  style={{display:'flex',background:'white',color:'black',flexDirection:'column',borderRadius:'10px',width:'20%',animationpopup}}>
      <div className='barra_mensaje_dislike'></div>
      <div style={{display:'flex',flexDirection:'column',alignItems:'center',padding:'5%',position:'relative'}}>
      <ThumbDownIcon className="MuiSvgIcon-root_likes"></ThumbDownIcon>
      <p style={{margin:'0'}}>Error</p>
      <div style={{display:'flex',justifyContent:'center',alignItems:'center',width:'20%'}}>
        <div className="barra_descheck_popup"></div><div style={{color:'red'}}>✖</div>
      </div>
      <p style={{textAlign:'center'}}>{<Translate content="itappearsthattherewassanissussubmittingyourreuqest" component="span" />}</p>
      <button onClick={()=>setCargandoerr(false)} className="btn_mensaje_dislike">Ok!</button></div>
      </div>
    </div>:null}
    {cargandonoerr===true?<div className="mensaje_popup">
      <div  style={{display:'flex',background:'white',color:'black',animationpopup,flexDirection:'column',borderRadius:'10px',width:'20%'}}>
      <div className='barra_mensaje_like'></div>
      <div style={{display:'flex',flexDirection:'column',alignItems:'center',padding:'5%',position:'relative'}}>
      <ThumbUpIcon className="MuiSvgIcon-root_likes2"></ThumbUpIcon>
      <p style={{margin:'0'}}>{<Translate content="Success" component="span" />}</p>
      <div style={{display:'flex',justifyContent:'center',alignItems:'center',width:'20%'}}>
        <div className="barra_check_popup"></div><div style={{color:'green'}}>✔</div>
      </div>
      <p style={{textAlign:'center'}}>{<Translate content="Yourrequestwassubmittedsuccesfully" component="span" />}</p>
      <button onClick={()=>setCargandonoerr(false)} className="btn_mensaje_like">{<Translate content="Done" component="span" />}</button></div>
      </div>
    </div>:null}
        {/* {mensajepooup===true?
       <div className="mensaje_popup">
         <div className="container_popup_mensaje">
         <div className="cancel_mensaje" onClick={()=>cerrarpopup()}>
         X
         </div>
           <div className="container_input_p_mensaje">
             <p style={{width:"20%",margin:'0'}}>Asunto</p>
             <input type="text" style={{width:"100%",height:'50%'}} onChange={(event) => {setInput({ ...input, asunto: event.target.value })}}></input>
           </div>
           <div className="container_input_p_mensaje">
             <p style={{width:"20%"}}>Mensaje</p>
             <textarea type="textarea"style={{width:"100%",resize:'none'}} onChange={(event) => {setInput({ ...input, mensaje: event.target.value })}}></textarea>
           </div>
                  {cargando===false?
                  <button className="btn_mensaje" onClick={()=>mandarMensaje()}> Enviar</button>:<button className="btn_mensaje_cargando" onClick={()=>mandarMensaje()}>Cargando...</button>}
         </div>
       </div>:null
      } */}
      {formulariopooup===true?
        <div className="mensaje_popup">
        <div className="container_popup_mensaje4" style={animationpopup}>
         <div className="cancel_mensaje" onClick={()=>cerrarpopup()}>
        X
        </div>

       <div style={{display:'flex',width:'100%',maxHeight:'70vh'}}>
         <div className="formulariopopup_izquierda" style={{display:'flex',flexDirection:'column',width:'100%',overflow:'auto',alignItems:'center',background:'white',color:'#212156',padding:'5% 0% 15% 0%',position:'relative'}}>
           <p style={{fontWeight:'600',fontSize:'22px',width:'80%'}}>{<Translate content="Terminado" component="span" />}</p>
           <div className="container_input_p_mensaje">
             <p style={{width:"100%",margin:'0'}}>{<Translate content="porquequieresterminar" component="span" />}</p>
             <button style={inputformulario.boton==='ya acabe con mis entregables'?{background:'#212156'}:{background:'#808082'}} 
             onClick={(event) => {setInputformulario({ ...inputformulario, boton: event.target.value })}}
              value="ya acabe con mis entregables" className="btn_formulario_popup"> ya acabe con mis entregables</button>
              <button style={inputformulario.boton==='no quiero continuar con el proceso'?{background:'#212156'}:{background:'#808082'}} 
              className="btn_formulario_popup" onClick={(event) => {setInputformulario({ ...inputformulario, boton: event.target.value })}} 
              value="no quiero continuar con el proceso"> no quiero continuar con el proceso</button>
           </div>
           <div className="container_input_p_mensaje">
             <p style={{width:"100%",margin:'0'}}>¿Cuánto tiempo llevas vinculado a FindAlly? (meses)</p>
             <input className="input_mensaje_popup" type="text"style={{width:"100%",resize:'none',borderTop:'0',borderRight:'0',borderLeft:'0'}} onChange={(event) => {setInputreunion({ ...inputreunion, descripcion: event.target.value })}}></input>
           </div>
           <div className="container_input_p_mensaje">
             <p style={{width:"100%",margin:'0'}}>De 1 a 5, siendo 1 la calificación más baja y 5 la más alta, ¿cómo calificarías tu experiencia en FindAlly?</p>
            <div style={{display:'flex',justifyContent:'space-between'}}>
           <div style={{display:'flex',flexDirection:'column',alignItems:'center'}}>
            <p style={{margin:'0'}}>1</p>
           <input style={{display:'flex'}} name="experiencia" type="radio"></input>
           </div>
           <div style={{display:'flex',flexDirection:'column',alignItems:'center'}}>
            <p style={{margin:'0'}}>2</p>
           <input style={{display:'flex'}} name="experiencia" type="radio"></input>
           </div>
           <div style={{display:'flex',flexDirection:'column',alignItems:'center'}}>
            <p style={{margin:'0'}}>3</p>
           <input style={{display:'flex'}} name="experiencia" type="radio"></input>
           </div>
           <div style={{display:'flex',flexDirection:'column',alignItems:'center'}}>
            <p style={{margin:'0'}}>4</p>
           <input style={{display:'flex'}} name="experiencia" type="radio"></input>
           </div>
           <div style={{display:'flex',flexDirection:'column',alignItems:'center'}}>
            <p style={{margin:'0'}}>5</p>
           <input style={{display:'flex'}} name="experiencia" type="radio"></input>
           </div>
           </div>
           </div>
           <div className="container_input_p_mensaje">
             <p style={{width:"100%",margin:'0'}}>Si la calificación no es 5, ¿qué crees que hizo falta para que lo puntuaras de esa manera?</p>
             <input className="input_mensaje_popup" type="text"style={{width:"100%",resize:'none',borderTop:'0',borderRight:'0',borderLeft:'0'}} onChange={(event) => {setInputreunion({ ...inputreunion, descripcion: event.target.value })}}></input>
           </div>
           <div className="container_input_p_mensaje">
             <p style={{width:"100%",margin:'0'}}>De 1 a 5, siendo 1 la calificación más baja y 5 la más alta, ¿qué tan satisfecho estás con las oportunidades de desarrollo profesional en FindAlly?</p>
            <div style={{display:'flex',justifyContent:'space-between'}}>
           <div style={{display:'flex',flexDirection:'column',alignItems:'center'}}>
            <p style={{margin:'0'}}>1</p>
           <input style={{display:'flex'}} name="experiencia" type="radio"></input>
           </div>
           <div style={{display:'flex',flexDirection:'column',alignItems:'center'}}>
            <p style={{margin:'0'}}>2</p>
           <input style={{display:'flex'}} name="experiencia" type="radio"></input>
           </div>
           <div style={{display:'flex',flexDirection:'column',alignItems:'center'}}>
            <p style={{margin:'0'}}>3</p>
           <input style={{display:'flex'}} name="experiencia" type="radio"></input>
           </div>
           <div style={{display:'flex',flexDirection:'column',alignItems:'center'}}>
            <p style={{margin:'0'}}>4</p>
           <input style={{display:'flex'}} name="experiencia" type="radio"></input>
           </div>
           <div style={{display:'flex',flexDirection:'column',alignItems:'center'}}>
            <p style={{margin:'0'}}>5</p>
           <input style={{display:'flex'}} name="experiencia" type="radio"></input>
           </div>
           </div>
           </div>
           <div className="container_input_p_mensaje">
             <p style={{width:"100%",margin:'0'}}>De 1 a 5, siendo 1 la calificación más baja y 5 la más alta, ¿qué tan satisfecho estás con la formación proporcionada por FindAlly?</p>
            <div style={{display:'flex',justifyContent:'space-between'}}>
           <div style={{display:'flex',flexDirection:'column',alignItems:'center'}}>
            <p style={{margin:'0'}}>1</p>
           <input style={{display:'flex'}} name="experiencia" type="radio"></input>
           </div>
           <div style={{display:'flex',flexDirection:'column',alignItems:'center'}}>
            <p style={{margin:'0'}}>2</p>
           <input style={{display:'flex'}} name="experiencia" type="radio"></input>
           </div>
           <div style={{display:'flex',flexDirection:'column',alignItems:'center'}}>
            <p style={{margin:'0'}}>3</p>
           <input style={{display:'flex'}} name="experiencia" type="radio"></input>
           </div>
           <div style={{display:'flex',flexDirection:'column',alignItems:'center'}}>
            <p style={{margin:'0'}}>4</p>
           <input style={{display:'flex'}} name="experiencia" type="radio"></input>
           </div>
           <div style={{display:'flex',flexDirection:'column',alignItems:'center'}}>
            <p style={{margin:'0'}}>5</p>
           <input style={{display:'flex'}} name="experiencia" type="radio"></input>
           </div>
           </div>
           </div>
           <div className="container_input_p_mensaje">
             <p style={{width:"100%",margin:'0'}}>De 1 a 5, siendo 1 la calificación más baja y 5 la más alta, ¿qué tan satisfecho estás con la experiencia proporcionada por FindAlly?</p>
            <div style={{display:'flex',justifyContent:'space-between'}}>
           <div style={{display:'flex',flexDirection:'column',alignItems:'center'}}>
            <p style={{margin:'0'}}>1</p>
           <input style={{display:'flex'}} name="experiencia" type="radio"></input>
           </div>
           <div style={{display:'flex',flexDirection:'column',alignItems:'center'}}>
            <p style={{margin:'0'}}>2</p>
           <input style={{display:'flex'}} name="experiencia" type="radio"></input>
           </div>
           <div style={{display:'flex',flexDirection:'column',alignItems:'center'}}>
            <p style={{margin:'0'}}>3</p>
           <input style={{display:'flex'}} name="experiencia" type="radio"></input>
           </div>
           <div style={{display:'flex',flexDirection:'column',alignItems:'center'}}>
            <p style={{margin:'0'}}>4</p>
           <input style={{display:'flex'}} name="experiencia" type="radio"></input>
           </div>
           <div style={{display:'flex',flexDirection:'column',alignItems:'center'}}>
            <p style={{margin:'0'}}>5</p>
           <input style={{display:'flex'}} name="experiencia" type="radio"></input>
           </div>
           </div>
           </div>
           <div className="container_input_p_mensaje">
             <p style={{width:"100%",margin:'0'}}>¿Qué fue lo que más te gustó de la experiencia?</p>
             <input className="input_mensaje_popup" type="text"style={{width:"100%",resize:'none',borderTop:'0',borderRight:'0',borderLeft:'0'}} onChange={(event) => {setInputreunion({ ...inputreunion, descripcion: event.target.value })}}></input>
           </div>
           <div className="container_input_p_mensaje">
             <p style={{width:"100%",margin:'0'}}>¿Qué le cambiarías a la experiencia?</p>
             <input className="input_mensaje_popup" type="text"style={{width:"100%",resize:'none',borderTop:'0',borderRight:'0',borderLeft:'0'}} onChange={(event) => {setInputreunion({ ...inputreunion, descripcion: event.target.value })}}></input>
           </div>
           <div className="container_input_p_mensaje">
             <p style={{width:"100%",margin:'0'}}>¿Consideras que tuviste los recursos suficientes para desempeñar tu labor correctamente? (Plataformas como Trello, Slack; apoyo del equipo, literatura, etc)</p>
             <div style={{display:'flex',justifyContent:'center'}}>
              <div style={{width:'50%',display:'flex',justifyContent:'space-between'}}>
           <div style={{display:'flex',flexDirection:'column',alignItems:'center'}}>
            <p style={{margin:'0'}}>si</p>
           <input style={{display:'flex'}} name="experiencia" type="radio"></input>
           </div>
           <div style={{display:'flex',flexDirection:'column',alignItems:'center'}}>
            <p style={{margin:'0'}}>no</p>
           <input style={{display:'flex'}} name="experiencia" type="radio"></input>
           </div>
           </div>
           </div>
           </div>
           <div className="container_input_p_mensaje">
             <p style={{width:"100%",margin:'0'}}>Si tu respuesta es negativa, ¿qué recursos consideras que hicieron falta para cumplir con tus tareas satisfactoriamente?</p>
             <input className="input_mensaje_popup" type="text"style={{width:"100%",resize:'none',borderTop:'0',borderRight:'0',borderLeft:'0'}} onChange={(event) => {setInputreunion({ ...inputreunion, descripcion: event.target.value })}}></input>
           </div>
           <div className="container_input_p_mensaje">
             <p style={{width:"100%",margin:'0'}}>¿Qué expectativas tenías al entrar?</p>
             <input className="input_mensaje_popup" type="text"style={{width:"100%",resize:'none',borderTop:'0',borderRight:'0',borderLeft:'0'}} onChange={(event) => {setInputreunion({ ...inputreunion, descripcion: event.target.value })}}></input>
           </div>
           <div className="container_input_p_mensaje">
             <p style={{width:"100%",margin:'0'}}>¿Qué porcentaje de estas expectativas se cumplió?</p>
             <input className="input_mensaje_popup" type="text"style={{width:"100%",resize:'none',borderTop:'0',borderRight:'0',borderLeft:'0'}} onChange={(event) => {setInputreunion({ ...inputreunion, descripcion: event.target.value })}}></input>
           </div>
           <div className="container_input_p_mensaje">
             <p style={{width:"100%",margin:'0'}}>De 1 a 5, siendo 1 la calificación más baja y 5 la más alta, ¿cómo calificarías el trabajo con los miembros de tu equipo?</p>
            <div style={{display:'flex',justifyContent:'space-between'}}>
           <div style={{display:'flex',flexDirection:'column',alignItems:'center'}}>
            <p style={{margin:'0'}}>1</p>
           <input style={{display:'flex'}} name="experiencia" type="radio"></input>
           </div>
           <div style={{display:'flex',flexDirection:'column',alignItems:'center'}}>
            <p style={{margin:'0'}}>2</p>
           <input style={{display:'flex'}} name="experiencia" type="radio"></input>
           </div>
           <div style={{display:'flex',flexDirection:'column',alignItems:'center'}}>
            <p style={{margin:'0'}}>3</p>
           <input style={{display:'flex'}} name="experiencia" type="radio"></input>
           </div>
           <div style={{display:'flex',flexDirection:'column',alignItems:'center'}}>
            <p style={{margin:'0'}}>4</p>
           <input style={{display:'flex'}} name="experiencia" type="radio"></input>
           </div>
           <div style={{display:'flex',flexDirection:'column',alignItems:'center'}}>
            <p style={{margin:'0'}}>5</p>
           <input style={{display:'flex'}} name="experiencia" type="radio"></input>
           </div>
           </div>
           </div>
           <div className="container_input_p_mensaje">
             <p style={{width:"100%",margin:'0'}}>De 1 a 5, siendo 1 la calificación más baja y 5 la más alta, ¿cómo calificarías la relación con los miembros de FindAlly?</p>
            <div style={{display:'flex',justifyContent:'space-between'}}>
           <div style={{display:'flex',flexDirection:'column',alignItems:'center'}}>
            <p style={{margin:'0'}}>1</p>
           <input style={{display:'flex'}} name="experiencia" type="radio"></input>
           </div>
           <div style={{display:'flex',flexDirection:'column',alignItems:'center'}}>
            <p style={{margin:'0'}}>2</p>
           <input style={{display:'flex'}} name="experiencia" type="radio"></input>
           </div>
           <div style={{display:'flex',flexDirection:'column',alignItems:'center'}}>
            <p style={{margin:'0'}}>3</p>
           <input style={{display:'flex'}} name="experiencia" type="radio"></input>
           </div>
           <div style={{display:'flex',flexDirection:'column',alignItems:'center'}}>
            <p style={{margin:'0'}}>4</p>
           <input style={{display:'flex'}} name="experiencia" type="radio"></input>
           </div>
           <div style={{display:'flex',flexDirection:'column',alignItems:'center'}}>
            <p style={{margin:'0'}}>5</p>
           <input style={{display:'flex'}} name="experiencia" type="radio"></input>
           </div>
           </div>
           </div>
           <div className="container_input_p_mensaje">
             <p style={{width:"100%",margin:'0'}}>De 1 a 5, siendo 1 la calificación más baja y 5 la más alta, ¿cómo calificarías la relación con el Project Manager de tu equipo? (Si aplica)</p>
            <div style={{display:'flex',justifyContent:'space-between'}}>
           <div style={{display:'flex',flexDirection:'column',alignItems:'center'}}>
            <p style={{margin:'0'}}>1</p>
           <input style={{display:'flex'}} name="experiencia" type="radio"></input>
           </div>
           <div style={{display:'flex',flexDirection:'column',alignItems:'center'}}>
            <p style={{margin:'0'}}>2</p>
           <input style={{display:'flex'}} name="experiencia" type="radio"></input>
           </div>
           <div style={{display:'flex',flexDirection:'column',alignItems:'center'}}>
            <p style={{margin:'0'}}>3</p>
           <input style={{display:'flex'}} name="experiencia" type="radio"></input>
           </div>
           <div style={{display:'flex',flexDirection:'column',alignItems:'center'}}>
            <p style={{margin:'0'}}>4</p>
           <input style={{display:'flex'}} name="experiencia" type="radio"></input>
           </div>
           <div style={{display:'flex',flexDirection:'column',alignItems:'center'}}>
            <p style={{margin:'0'}}>5</p>
           <input style={{display:'flex'}} name="experiencia" type="radio"></input>
           </div>
           </div>
           </div>
           <div className="container_input_p_mensaje">
             <p style={{width:"100%",margin:'0'}}>¿En qué medida el aprendizaje obtenido en FindAlly es significativo para trabajos posteriores?</p>
             <input className="input_mensaje_popup" type="text"style={{width:"100%",resize:'none',borderTop:'0',borderRight:'0',borderLeft:'0'}} onChange={(event) => {setInputreunion({ ...inputreunion, descripcion: event.target.value })}}></input>
           </div>
           <div className="container_input_p_mensaje">
             <p style={{width:"100%",margin:'0'}}>En general, ¿sentiste que tu ambiente de trabajo fue positivo o negativo? ¿En qué sentido?</p>
             <input className="input_mensaje_popup" type="text"style={{width:"100%",resize:'none',borderTop:'0',borderRight:'0',borderLeft:'0'}} onChange={(event) => {setInputreunion({ ...inputreunion, descripcion: event.target.value })}}></input>
           </div>
           <div className="container_input_p_mensaje">
             <p style={{width:"100%",margin:'0'}}>¿Te gustaría ser tenido en cuenta para futuros proyectos?</p>
            <div style={{display:'flex',justifyContent:'center'}}>
              <div style={{width:'50%',display:'flex',justifyContent:'space-between'}}>
           <div style={{display:'flex',flexDirection:'column',alignItems:'center'}}>
            <p style={{margin:'0'}}>si</p>
           <input style={{display:'flex'}} name="experiencia" type="radio"></input>
           </div>
           <div style={{display:'flex',flexDirection:'column',alignItems:'center'}}>
            <p style={{margin:'0'}}>no</p>
           <input style={{display:'flex'}} name="experiencia" type="radio"></input>
           </div>
           </div></div>
           </div>
           <div className="container_input_p_mensaje">
             <p style={{width:"100%",margin:'0'}}>¿Recomendarías esta experiencia a un amigo? Si/No, ¿por qué?</p>
             <input className="input_mensaje_popup" type="text"style={{width:"100%",resize:'none',borderTop:'0',borderRight:'0',borderLeft:'0'}} onChange={(event) => {setInputreunion({ ...inputreunion, descripcion: event.target.value })}}></input>
           </div>
           <div className="container_input_p_mensaje">
             <p style={{width:"100%",margin:'0'}}>¿Como fue tu experiencia con coally?</p>
             <input className="input_mensaje_popup" type="text"style={{width:"100%",resize:'none',borderTop:'0',borderRight:'0',borderLeft:'0'}} onChange={(event) => {setInputreunion({ ...inputreunion, descripcion: event.target.value })}}></input>
           </div>
            <div className="container_input_p_mensaje">
             <p style={{width:"100%",margin:'0'}}>¿Volverias a trabajar con nosotros?</p>
             <input className="input_mensaje_popup" type="text" style={{width:"100%",height:'50%',borderTop:'0',borderRight:'0',borderLeft:'0'}} onChange={(event) => {setInputreunion({ ...inputreunion, fecha: event.target.value })}}></input>
           </div>
           <div className="container_input_p_mensaje">
             <p style={{width:"100%",margin:'0'}}>¿Te ayudo profesionalmente nuestra metodologia?</p>
             <input className="input_mensaje_popup" type="text" style={{width:"100%",height:'50%',borderTop:'0',borderRight:'0',borderLeft:'0'}} onChange={(event) => {setInputreunion({ ...inputreunion, hora: event.target.value })}}></input>
           </div>
           <div className="container_input_p_mensaje">
             <p style={{width:"100%",margin:'0'}}>¿Que crees que deberiamos mejorar?</p>
             <input className="input_mensaje_popup" type="text" style={{width:"100%",height:'50%',borderTop:'0',borderRight:'0',borderLeft:'0'}} onChange={(event) => {setInputreunion({ ...inputreunion, enlace: event.target.value })}}></input>
           </div> 
           <div className="container_input_p_mensaje">
                  <p style={{ width: "20%", margin: '0', textAlign: 'start', display: 'flex', alignItems: 'center' }}>Clasificacion</p>
                  <div className="section_feedback clasificacion avances_clasificacion">
                    <label className="perfil__score-form-label2" style={{ display: 'none' }} htmlFor="radio0">
                      ★
                    </label>
                    <input
                      className="perfil__score-form-input "
                      id="radio0"
                      type="radio"
                      name="calificacion"
                      value="5"
                     // onClick={() => { setInputAceptar({ ...inputAceptar, calificacion: 5 }) }}
                    />
                    <label className="perfil__score-form-label2" htmlFor="radio0">
                      ★
                    </label>
                    <input
                      className="perfil__score-form-input "
                      id="radio1"
                      type="radio"
                      name="calificacion"
                      value="4"
                     // onClick={() => { setInputAceptar({ ...inputAceptar, calificacion: 4 }) }}
                    />
                    <label className="perfil__score-form-label2 " htmlFor="radio1">
                      ★
                    </label>
                    <input
                      className="perfil__score-form-input "
                      id="radio2"
                      type="radio"
                      name="calificacion"
                      value="3"
                      //onClick={() => { setInputAceptar({ ...inputAceptar, calificacion: 3 }) }}
                    />
                    <label className="perfil__score-form-label2 " htmlFor="radio2">
                      ★
                    </label>
                    <input
                      className="perfil__score-form-input "
                      id="radio3"
                      type="radio"
                      name="calificacion"
                      value="2"
                      //onClick={() => { setInputAceptar({ ...inputAceptar, calificacion: 2 }) }}
                    />
                    <label className="perfil__score-form-label2 " htmlFor="radio3">
                      ★
                    </label>
                    <input
                      className="perfil__score-form-input "
                      id="radio4"
                      type="radio"
                      name="calificacion"
                      value="1"
                      // onClick={() => { setInputAceptar({ ...inputAceptar, calificacion: 1 }) }}
                    />
                    <label className="perfil__score-form-label2" htmlFor="radio4">
                      ★
                    </label>
                    <input
                      className="perfil__score-form-input "
                      id="radio5"
                      type="radio"
                      name="calificacion"
                      value="1"
                    />
                  </div>
                  </div>
                 {cargando===false?
                 <button className="btn_mensaje" style={{position:'initial',marginTop:'3rem'}} onClick={()=>mandarReunion()}>{<Translate content="Enviarr" component="span"/>}</button>:<button style={{position:'inherit',marginTop:'3rem'}} className="btn_mensaje_cargando" onClick={()=>mandarReunion()}>{<Translate content="Cargandooo" component="span"/>}</button>}
                 </div>
                 <div className="derecha_mensaje_popup">
                   <p style={{textAlign:'center',width:'70%',color:'white',fontSize:'22px'}}>{<Translate content="Creaunareuniónconlaempresa" component="span"/>}</p>
                   <img style={{width:'60%'}} src="https://coally.com/wp-content/uploads/2021/10/Logo-0-1.png" alt="logo"></img>
                   <div>
                   <p style={{textAlign:'center'}}>The on-demand platform for Business Transformation services</p>
                   <div style={{display:'flex',justifyContent:'space-around'}}>
                   <FacebookIcon></FacebookIcon>
                   <InstagramIcon></InstagramIcon>
                   <LinkedInIcon></LinkedInIcon>
                 </div></div></div>
                 </div>
      </div>
      </div>:null}
        {reunion===true?
        <div className="mensaje_popup">
        <div className="container_popup_mensaje" style={animationpopup}>
         <div className="cancel_mensaje" onClick={()=>cerrarpopup()}>
        X
        </div>

       <div style={{display:'flex',width:'100%'}}>
         <div style={{display:'flex',flexDirection:'column',width:'100%',alignItems:'center',background:'white',color:'#212156',padding:'10% 0% 20% 0%',position:'relative',borderRadius:'10px'}}>
           <p style={{fontWeight:'600',fontSize:'22px',width:'80%'}}>{<Translate content="Nuevareunión" component="span"/>}</p>
           <div className="container_input_p_mensaje">
             <p style={{width:"100%",margin:'0'}}>{<Translate content="Titulooo" component="span"/>}</p>
             <input className="input_mensaje_popup" type="text" style={{width:"100%",height:'50%',borderTop:'0',borderRight:'0',borderLeft:'0'}} onChange={(event) => {setInputreunion({ ...inputreunion, titulo: event.target.value })}}></input>
           </div>
           <div className="container_input_p_mensaje">
             <p style={{width:"100%",margin:'0'}}>{<Translate content="Descripcionnn" component="span"/>}</p>
             <input className="input_mensaje_popup" type="text"style={{width:"100%",resize:'none',borderTop:'0',borderRight:'0',borderLeft:'0'}} onChange={(event) => {setInputreunion({ ...inputreunion, descripcion: event.target.value })}}></input>
           </div>
           <div className="container_input_p_mensaje">
             <p style={{width:"100%",margin:'0'}}>{<Translate content="Fechaaa" component="span"/>}</p>
             <input className="input_mensaje_popup" type="date" style={{width:"100%",height:'50%',borderTop:'0',borderRight:'0',borderLeft:'0'}} onChange={(event) => {setInputreunion({ ...inputreunion, fecha: event.target.value })}}></input>
           </div>
           <div className="container_input_p_mensaje">
             <p style={{width:"100%",margin:'0'}}>{<Translate content="Hora" component="span"/>}</p>
             <input className="input_mensaje_popup" type="text" style={{width:"100%",height:'50%',borderTop:'0',borderRight:'0',borderLeft:'0'}} onChange={(event) => {setInputreunion({ ...inputreunion, hora: event.target.value })}}></input>
           </div>
           {/* <div className="container_input_p_mensaje">
             <p style={{width:"100%",margin:'0'}}>{<Translate content="Enlacedereunion" component="span"/>}</p>
             <input className="input_mensaje_popup" type="text" style={{width:"100%",height:'50%',borderTop:'0',borderRight:'0',borderLeft:'0'}} onChange={(event) => {setInputreunion({ ...inputreunion, enlace: event.target.value })}}></input>
           </div> */}
                 {cargando===false?
                 <button className="btn_mensaje" onClick={()=>mandarReunion()}>{<Translate content="Enviarr" component="span"/>}</button>:<button className="btn_mensaje_cargando" onClick={()=>mandarReunion()}>{<Translate content="Cargandooo" component="span"/>}</button>}
                 {/* </div>
                 <div className="derecha_mensaje_popup">
                   <p style={{textAlign:'center',width:'70%',color:'white',fontSize:'22px'}}>{<Translate content="Creaunareunionconlaempresa" component="span"/>}</p>
                   <img style={{width:'60%'}} src="https://coally.com/wp-content/uploads/2021/10/Logo-0-1.png" alt="logo"></img>
                   <p style={{textAlign:'center'}}>The on-demand platform for Business Transformation services</p>
                   <div style={{width:'50%',display:'flex',justifyContent:'space-around'}}>
                   <FacebookIcon></FacebookIcon>
                   <InstagramIcon></InstagramIcon>
                   <LinkedInIcon></LinkedInIcon>
                 </div>*/}
                 </div> 
                 </div>
      </div>
      </div>
      //  <div className="mensaje_popup">
      //    <div className="container_popup_mensaje2">
      //    <p style={{fontWeight:'600'}}>Nueva reunión</p>
      //    <div className="cancel_mensaje" onClick={()=>cerrarpopup()}>
      //    X
      //    </div>

      //      <div className="container_input_p_mensaje2">
      //        <p style={{width:"20%",margin:'0'}}>Titulo</p>
      //        <input type="text" style={{width:"80%",height:'50%'}} onChange={(event) => {setInputreunion({ ...inputreunion, titulo: event.target.value })}}></input>
      //      </div>
      //      <div className="container_input_p_mensaje2">
      //        <p style={{width:"20%"}}>Descripcion</p>
      //        <textarea type="textarea"style={{width:"80%",resize:'none'}} onChange={(event) => {setInputreunion({ ...inputreunion, descripcion: event.target.value })}}></textarea>
      //      </div>
      //      <div className="container_input_p_mensaje2">
      //        <p style={{width:"20%",margin:'0'}}>Fecha</p>
      //        <input type="date" style={{width:"80%",height:'50%'}} onChange={(event) => {setInputreunion({ ...inputreunion, fecha: event.target.value })}}></input>
      //      </div>
      //      <div className="container_input_p_mensaje2">
      //        <p style={{width:"20%",margin:'0'}}>Hora</p>
      //        <input type="text" style={{width:"80%",height:'50%'}} onChange={(event) => {setInputreunion({ ...inputreunion, hora: event.target.value })}}></input>
      //      </div>
      //      <div className="container_input_p_mensaje2">
      //        <p style={{width:"20%",margin:'0'}}>Enlace de reunion</p>
      //        <input type="text" style={{width:"80%",height:'50%'}} onChange={(event) => {setInputreunion({ ...inputreunion, enlace: event.target.value })}}></input>
      //      </div>
      //             {cargando===false?
      //             <button className="btn_mensaje2" onClick={()=>mandarReunion()}> Enviar</button>:<button className="btn_mensaje_cargando2" onClick={()=>mandarReunion()}>Cargando...</button>}
      //    </div>
      //  </div>
      :null
      }
        {statusrechazado===true?
        <div className="mensaje_popup">
        <div className="container_popup_mensaje" style={animationpopup}>
         <div className="cancel_mensaje" onClick={()=>cerrarpopup()}>
        X
        </div>

       <div style={{display:'flex',width:'100%'}}>
         <div style={{display:'flex',flexDirection:'column',width:'100%',alignItems:'center',background:'white',color:'#212156',padding:'10% 0% 20% 0%',position:'relative',borderRadius:'10px'}}>
           <p style={{fontWeight:'600',fontSize:'22px',width:'80%'}}>{<Translate content="Tarearechazada" component="span"/>}</p>
           <div className="container_input_p_mensaje">
             <p style={{width:"100%",margin:'0'}}>{<Translate content="Motivo0" component="span"/>}:</p>
             <p>{msgrechazo?msgrechazo:null}</p>
           </div>
          
                 
                 </div> 
                 </div>
      </div>
      </div>
      :null
      }
      {subirtarea===true?
       <div className="mensaje_popup">
         <div className="container_popup_mensaje" style={animationpopup}>
         <div className="cancel_mensaje" style={{color:'black',zIndex:'10'}} onClick={()=>cerrarpopup3()}>
         X
         </div>

        <div  style={{display:'flex',width:'100%'}}>
          <div style={{display:'flex',flexDirection:'column',width:'100%',alignItems:'center',background:'white',color:'#212156',padding:'5% 0% 15% 0%',position:'relative'}}>
          <label className="tarea-container-methocopy" htmlFor='textarea'>
          <p className="placeholder-task-methocopy" htmlFor='textarea'>{<Translate content="COMENTARIOSSOBRETUENTREGABLE" component="span"/>}</p> 
        <textarea value={comment} className="comments-text-inputcopy" id='textarea' onChange={(e)=>setComment(e.target.value)}/> 
        </label>
        <div className='upload_file_css'>
        {/* props.project.methodology_list[0].etapas[0] */}
        {etapedata?<Upload idUsuario={props.user.id} idProyecto={props.project._id} statusid={etapedata._id} nombre={etapedata.name} idPhase={ etapedata.Qué} comCoallier={comment}/>:<Upload idUsuario={props.user.id} idProyecto={props.project._id} statusid={metodologianueva[0].phases[0]._id} nombre={metodologianueva[0].phases[0].name} idPhase={ metodologianueva[0].phases[0].Qué} comCoallier={comment}/>}
        </div>
       </div>
       </div>
       </div>
       </div>
       :null
      }  
  <div style={mensajepooup||reunion||formulariopooup||enviarpropooup||subirtarea?{filter: `blur(1rem)`}:{filter: `blur(0rem)`}}>

      <Footer></Footer>
  </div>
        </>
    )
    
}

function mapStateToProps (state) {
    return{
        subEtapas :state.subEtapas,
        singlePhase : state.SinglePhase,
        Etapas : state.Etapas,
        user : state.user,
        userProjects : state.user.projects,
        project : state.ActualProject,
        doneMetho : state.doneMetho,
        
    }
}
const mapDispatchToProps = {
    getSinglePhase,
    setActualEtapa,
    getEtapas,
    Methodology,
    setActualPhase,
    getSingleEtapa,
    getProject,
    ConsultorProjects,
    getSubEtapas
}

export default connect(mapStateToProps , mapDispatchToProps)(MethodologyDetails);
