import axios from "axios";
import { ResponsesInterfaces } from "../interfaces/responsesInterfaces";

export const fetchSchneiderData = async (): Promise<
  ResponsesInterfaces | undefined
> => {
  try {
    const response = await axios.get(
      "https://findally-backend.herokuapp.com/api/get/university/schneider"
    );
    return response.data;
  } catch (error) {
    console.log(error);
  }
};
