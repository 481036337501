import React, { useState } from 'react';
import './Snake.css';
// import cup from '../../assets/icons/cup.svg';
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";

const SnakeProgress = ({ data }) => {
  const [popUp, setPopUp] = useState(false);
  // const [show, setShow] = useState(true);
  const [popUpData, setPopUpData] = useState({
    title: '',
    description: '',
    url: '',
  });

  const handlePopUp = (e, item) => {
    e.preventDefault()
    setPopUpData({
      title: item.title,
      description: item.description,
      url: item.url,
    })
    setPopUp(!popUp)
  }

  // useEffect(() => {
  //   function handleResize() {
  //     if (window.innerWidth < 1009) {
  //       setShow(false);
  //     }
  //     else {
  //       setShow(true);
  //     }
  //   }

  //   window.addEventListener('resize', handleResize)
  //   return _ => {
  //     window.removeEventListener('resize', handleResize)
  //   }
  // }, [])

  return (
    <div style={{ width: '100%' }}>
      <Dialog
        style={{}}
        open={popUp}
        onClose={() => setPopUp(false)}
        // scroll={scroll}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title" style={{
          textAlign: 'center',
          color: 'rgba(21, 21, 21, 0.99)',
          fontWeight: '700',
          fontSize: '24px',
          marginTop: '20px',
        }}>
          {popUpData.title}
        </DialogTitle>
        <DialogContent>
          <div>
            <hr />
            <p style={{
              color: '#5D5D5D',
              fontSize: '16px',
              fontWeight: '400',
              textAlign: 'justify',
              marginBottom: '20px',
            }}>
              {popUpData.description}
            </p>
            <a href={popUpData.url} target="_blank" rel="noopener noreferrer">
              <button style={{
                backgroundColor: '#F07130',
                color: '#fff',
                border: '0.5px solid #F07130',
                borderRadius: '5px',
                padding: '10px',
                width: '100%',
              }}>
                Ir al curso
              </button>
            </a>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setPopUp(!popUp)}>
            Cerrar
          </Button>
        </DialogActions>
      </Dialog>
      {/* {
        // data?.length === 7 &&
        show
          ? <div>
            <div className={'container-snake-LINE'}>
              {
                data?.map((item, index) => {
                  return (
                    <div
                      className={index === 0 ? 'item-inactive-aura-indigo' : index === data.length - 1 ? 'item-inactive-aura-orange' : null}>
                      <div style={{cursor: 'pointer'}} onClick={(e) => handlePopUp(e, item)}
                           className={index === 0 ? 'item-inactive-indigo' : index === data.length - 1 ? 'item-inactive-orange' : 'item-inactive2-indigo'}>
                        {index === data.length - 1 ? <img src={cup} alt={''}/> : <h2 className={'item-title-responsive-list-number-snake'}>{index + 1}</h2>}
                        <div style={{width: '80px', position: 'absolute', textOverflow: 'ellipsis', marginTop: '30px'}}>
                          <h2 className={'item-inactive-text'}>
                            {item.title}
                          </h2>
                        </div>
                      </div>
                    </div>
                  )
                })
              }
            </div>
          </div>
          : <div style={{marginTop: '-40px'}}> */}
      <div>
        {
          data?.map((item, index) => {
            return (
              <div key={index}>
                <div style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'flex-start',
                  width: '100%',
                  marginTop: '10px'
                }}>
                  <div
                    className={index === 0 ? 'item-inactive-aura-indigo-mini' : index === data.length - 1 ? 'item-inactive-aura-orange-mini' : null}>
                    <div
                      className={index === 0 ? 'item-inactive-indigo-mini' : index === data.length - 1 ? 'item-inactive-orange-mini' : 'item-inactive2-indigo-mini'}>
                      <h2 className={'item-title-responsive-list-number'}>{index + 1}</h2>
                    </div>
                  </div>
                  <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: '40px',
                  }}>
                    <h1 className={'item-title-responsive-list'}>{item.title}</h1>
                  </div>
                  <button style={{
                    backgroundColor: '#F07130',
                    color: '#fff',
                    border: '0.5px solid #F07130',
                    borderRadius: '5px',
                    marginLeft: '10px',
                  }}
                    onClick={(e) => handlePopUp(e, item)}>Ver
                  </button>
                </div>
                {index === data.length - 1 ? null : <hr />}
              </div>
            )
          })
        }
      </div>
      {/* </div>
      } */}
    </div>
  )
}

export default SnakeProgress;
