import React from 'react';
import '../UserInfo.css'

export default function CardConsultor({nombre,profesion,idiomas,habilidades,experiencia,language}){
    return(
        <div className='container-card-data'>
                  <div className='Data_usuario'>
                    <p key={nombre} className='nombre'>{nombre}</p>
                    <p key={profesion} className='TextoInterno'>{profesion}</p>
                  </div>
                 
                  <div className={'contenedor-iner-data'}>
                    <p className='TituloDesc'>{language=== 'es'?'Idiomas' : 'Languages'}</p>
                  <div className='Idioma'>
                    {idiomas.map((langu,index)=> (<p key={index} className='TextoInterno'>{langu.Language}</p>))}
                  </div>
                  <hr className='herre'/>
                  </div>
                    
                    <div className={'contenedor-iner-data'}>
                    <p className='TituloDesc'>{language === 'es' ?'Habilidades' : 'Skills'}</p>
                    <div >
                    {habilidades.map((hab, index)=> (<p key={index} className='TextoInterno'>{hab}</p>))}
                    </div>
                    <hr className='herre'/>
                    </div>

                  <div className={'contenedor-iner-data'}>
                    <p className='TituloDesc'>{language=== 'es' ? 'Experiencia' : 'Experience'}</p>
                    <div>
                    {experiencia.map((exp,index)=> (<p key={index} className='TextoInterno'>{exp.Nombreempresa}</p>))}
                  </div>
                  <hr className='herre'/>
                  </div>
                  
                </div>
    )
}