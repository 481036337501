import React, { useState, useEffect } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import "./CircularFrame.css";
import userImg from "../../assets/icons/user.svg";

export default function CircularFrameNav({ user, cv }) {
  const [progress, setProgress] = useState(0);
 
  useEffect(() => {
    let counter = 0;
    if (cv.aptitudes_principales && cv.aptitudes_principales.length > 0)
      counter += 12.5;
    if (cv.certifications && cv.certifications.length > 0) counter += 12.5;
    if (cv?.contacto && (cv?.contacto?.correo?.length > 0 || user?.email?.length !== 0))counter += 12.5
    if (
      cv.educacion &&
      cv.educacion.length > 0 &&
      cv.educacion[0].NombreInstitucion !== ""
    )
      counter += 12.5;
    if (
      cv.experiencia &&
      cv.experiencia.length > 0 &&
      cv.experiencia[0].Nombreempresa !== ""
    )
      counter += 12.5;
    if (cv.extracto && cv.extracto !== "") counter += 12.5;
    if (cv.languages && cv.languages.length > 0) counter += 12.5;
    if (user.avatar && user.avatar !== "") counter += 12.5;
    setProgress(counter);
    //eslint-disable-next-line
  }, [cv, user]);

  return (
    <div className="CircularProfile-nab">
      <CircularProgress
        thickness={2}
        variant="determinate"
        value={100}
        id="SecondCirclie-nab"
      />
      <CircularProgress
        thickness={1.5}
        variant="determinate"
        value={progress}
      />

      <div className="Avatar-container-nav">
        <img src={user.avatar ? user.avatar : userImg} alt={"profile-pic"} />
      </div>
    </div>
  );
}
