/* eslint-disable */
import React, {useState, useEffect, useContext} from "react"
import {useDispatch} from "react-redux"
import {useHistory, Link} from "react-router-dom"
import VisibilityIcon from "@mui/icons-material/Visibility"
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff"
import Spinner from "react-bootstrap/Spinner"
import {changeLang, newLoginUser} from "../../../../actions"
import Edit from "../../../../assets/imagenes/edit.svg"
import {SchneiderContext} from "../../../context/SchneiderContext"
import {
  AuthGlobalContainer,
  AuthFormContainer,
  AuthButton,
  AuthButtonContainer,
  AuthButtonDisabled,
  AuthFooterContainer,
  AuthLink,
  AuthFooterText,
  AuthLogo,
  LanguageContainer,
  LanguageButton,
  AuthTitle,
  AuthInputContainer,
  AuthInput,
  AuthInputSubContainer,
  AuthInputIconContainer
} from "../AuthStyles"


export default function Login() {

  const {schneider} = useContext(SchneiderContext)

  const [cargando, setCargando] = useState(false)
  const [localLang, setLocalLang] = useState(localStorage.getItem("langlocal"))
  const [passwordShown, setPasswordShown] = useState(false)

  const [user, setUser] = useState({
    email: "",
    password: ""
  })

  const dispatch: any = useDispatch()
  const router = useHistory()

  useEffect(() => {
    const handleKeyPress = (e: any) => {
      if (e.key === "Enter") {
        e.preventDefault()
        handleSubmit(e)
      }
    }
    document.addEventListener("keydown", handleKeyPress)
    return () => {
      document.removeEventListener("keydown", handleKeyPress)
    }
  }, [user])


  const changeLanguages = (lang: string) => {
    dispatch(changeLang(lang))
    localStorage.setItem("langlocal", lang)
    setLocalLang(localStorage.getItem("langlocal"))
  }

  const changeUser = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault()
    setUser({
      ...user,
      [e.target.name]: e.target.value
    })
  }

  const handleSubmit = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault()
    setCargando(true)
    dispatch(newLoginUser(user))
    setTimeout(() => {
    sessionStorage.setItem('popup', 'Bienvenido');
    router.push("/schneider/home")
    }, 2000)
  }

  const togglePassword = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault()
    setPasswordShown(!passwordShown)
  }

  return (<>
    {schneider && schneider?.color && <AuthGlobalContainer>
      <AuthFormContainer>
        <AuthLogo src={schneider.logo} alt="logo"/>
        <LanguageContainer>
          <LanguageButton
            onClick={() => changeLanguages("es")}
            color={localLang === "es" ? schneider.color.primary: "#000000"}
            weight={localLang === "es" ? 700 : 300}
          >
            SP
          </LanguageButton>
          <LanguageButton
            onClick={() => changeLanguages("en")}
            color={localLang === "en" ? schneider.color.primary: "#000000"}
            weight={localLang === "en" ? 700 : 300}
          >
            EN
          </LanguageButton>
        </LanguageContainer>

        <div style={{width: "100%"}}>
          <AuthTitle color={schneider.color.primary}>
            {localLang === "es" ? "¡Bienvenido de nuevo!" : "Welcome back!"}
          </AuthTitle>
        </div>

        <AuthInputContainer>
          <p>{localLang === "es" ? "Correo electrónico" : "Email"}</p>
          <AuthInputSubContainer>
            <AuthInput
              type="text"
              name={"email"}
              autoComplete={"email"}
              placeholder={localLang === "es" ? "nombre@correo.com" : "name@email.com"}
              onChange={(e) => changeUser(e)}/>
            <AuthInputIconContainer>
              <img src={Edit} alt="editar"/>
            </AuthInputIconContainer>
          </AuthInputSubContainer>
        </AuthInputContainer>

        <AuthInputContainer>
          <p>{localLang === "es" ? "Contraseña" : "Password"}</p>
          <AuthInputSubContainer>
            <AuthInput
              type={passwordShown ? "text" : "password"}
              name={"password"}
              autoComplete={"current-password"}
              placeholder={localLang === "es" ? "Introduce tu contraseña" : "Password"}
              onChange={(e) => changeUser(e)}/>
            <AuthInputIconContainer>
              <button
                style={{background: "transparent", borderRadius: "0px 1.5rem 1.5rem 0px"}}
                onClick={(e) => togglePassword(e)}>
                {passwordShown ? <VisibilityOffIcon/> : <VisibilityIcon/>}
              </button>
            </AuthInputIconContainer>
          </AuthInputSubContainer>
          <Link to="/schneider/recover">
            <AuthLink color={schneider.color.primary}>
              {localLang === "es" ? "¿Olvidaste tu contraseña?" : "Forgot your password?"}
            </AuthLink>
          </Link>
        </AuthInputContainer>

        <AuthButtonContainer>
          {
            user.email && user.password
              ? <AuthButton
                background={schneider.color.primary}
                onClick={(e) => handleSubmit(e)}>
                {cargando ? <Spinner animation="border" size="sm"/> :
                  localLang === "es" ? "Iniciar sesión" : "Sign in"
                }
              </AuthButton>
              : <AuthButtonDisabled>
                {localLang === "es" ? "Iniciar sesión" : "Sign in"}
              </AuthButtonDisabled>
          }
        </AuthButtonContainer>

        <AuthFooterContainer>
          <AuthFooterText color={schneider.color.tertiary}>
            {localLang === "es" ? "¿Es la primera vez que usas Coally?" : "Is this your first time using Coally?"}
          </AuthFooterText>
          <Link to={`/schneider/register`}>
            <AuthLink color={schneider.color.primary} weight={600}>
              {localLang === "es" ? "Regístrate" : "Sign up"}
            </AuthLink>
          </Link>
        </AuthFooterContainer>

      </AuthFormContainer>
    </AuthGlobalContainer>}
  </>)
}
