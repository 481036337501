import React, { useEffect, useState } from "react";
import HeaderEmpresa from "../../components/HeaderEmpresa/HeaderEmpresa";
import {
  getSinglePhase,
  getEtapas,
  getSingleEtapa,
  ConsultorProjects,
  getProject,
  getSubEtapas,
  // setCommmentAvance,
} from "../../actions/index";
import en from "../../lang/en-US";
import axios from 'axios';
import EmailIcon from '@material-ui/icons/Email';
import GetAppIcon from '@material-ui/icons/GetApp';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import {
  actualTarea,
  //setStatusTask , 
  //setCommment 
} from "../../actions";
import { useDispatch, useSelector } from 'react-redux'
// import InstagramIcon from '@material-ui/icons/Instagram';
// import LinkedInIcon from '@material-ui/icons/LinkedIn';
// import FacebookIcon from '@material-ui/icons/Facebook';
import ThumbDownIcon from '@material-ui/icons/ThumbDown';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxcancel from '@material-ui/icons/Close';
import Info from '@material-ui/icons/Info';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import es from "../../lang/es-COL"
import counterpart from "counterpart";
import Translate from "react-translate-component";
// import Avances from "../../components/Avances/Avances";
//import  CompanyEquipo  from "../../components/CompanyEquipo/CompanyEquipo";
import {
  connect,
  //useDispatch
} from "react-redux";
import "./Styles/CompaniesEntrega.css";
import { Link } from "react-router-dom";
import Footer from "../../components/Footer/Footer";
import { useParams } from 'react-router';

function CompaniesEntrega(props) {
  const {
    match: { params },
  } = props;
  const [popup, setPopup] = useState("0rem");
  //const [mensajepopup,setMensajepopup] = useState(false);
  const [cargando, setCargando] = useState(false)
  const [terminarpro, setTerminarpro] = useState(false)
  const [metodologianueva, setmetodologianueva] = useState('')
  const [devolucionpopup, setDevolucionpopup] = useState('')
  const [statusrechazado, setstatusrechazado] = useState(false)
  const [cargandoerr, setCargandoerr] = useState(false)
  const [animationpopup, setAnimationpopup] = useState({ animation: 'popupmensaje 0.25s alternate  ease-in' })
  const [cargandonoerr, setCargandonoerr] = useState(false)
  const [staterechazado, setstaterechazado] = useState('')
  // const [status, setStatusValue] = useState("avances");
  // const [comment, setCommentValue] = useState("");
  // const dispatch = useDispatch();

  //const lang = useSelector(state => state.lang)

  const cerrar = () => {
    setTimeout(function () {
      setInputRechazar({ ...inputRechazar, tarea: '', ref3: '' })
      setInputAceptar({ ...inputAceptar, tarea: '', ref3: '' })
      setInput({ ...input, ref3: '' })
      setDevolucionpopup('')
      setTerminarpro(false)
      setCargandoerr(false)
      setCargandonoerr(false)
      setInfopopup("");
      setPopup("0rem")
      setAnimationpopup({ animation: 'popupmensaje 0.25s alternate  ease-in' })
    }, 250);
    setAnimationpopup({ animation: 'popupmensajeend 0.25s alternate  ease-in' })

  }
  counterpart.registerTranslations('en', en)
  counterpart.registerTranslations('es', es)
  counterpart.setLocale(localStorage.getItem('langlocal'));

  const mensajepopupstate = () => {
    setInput({ ...input, ref3: '' })
    setPopup("0rem")
  }
  const mandarMensaje = () => {
    setCargando(true)
    console.log(input)
    axios.post('https://findally-backend.herokuapp.com/api/Project/send/message', input).then(response => {
      setInput({ ...input, ref3: '' })
      setPopup("0rem")
      setCargandonoerr(true)
      setCargando(false)
    }, error => {
      setInput({ ...input, ref3: '' })
      setPopup("0rem")
      setCargandoerr(true)
      setCargando(false)
    })
  }
  // const handdleStatusChange = (value) => {
  //   setStatusValue(value);
  // };
  //  const {tittle ,  description } = props.project[params.id]
  useEffect(() => {
    const getPhase = () => {
      if (!props.doneMetho) {
        //  props.getSinglePhase(params.id);
      }
    };
    const getUser = () => {
      props.getProject(params.project);
    };
    const getSub = () => {
      if (props.singlePhase) {
        //   props.getSubEtapas(props.singlePhase)
      }
    };
    // eslint-disable-next-line
    getUser();
    getSub();
    getPhase(); // eslint-disable-next-line
  }, []);
  const [input, setInput] = useState({
    proyecto: props.project._id,
    asunto: '',
    mensaje: '',
    ref3: ''
  })
  function actualizarmensaje() {
    setPopup("1rem");
    setInput({ ...input, proyecto: props.project._id, ref3: 'mensaje' });
  }
  //console.log(props.location.pathname.slice(26))
  const [infopopup, setInfopopup] = useState('');
  // const [cargando, setCargando] = useState(false)
  // const [animationpopup, setAnimationpopup] = useState({animation:'popupmensaje 0.25s alternate  ease-in'})
  // const [cargandoerr, setCargandoerr] = useState(false)
  // const [cargandonoerr,setCargandonoerr]=useState(false)
  // const [estadosproject] = useState('En espera')
  //const [comment, setcomment] = useState("");
  const project = useSelector(state => state.ActualProject)
  // const actualTareaShow = useSelector(state => state.actualTareaShow)
  let tareaactual = useSelector(state => state.ActualProject
    // .tareas[0]
  )
  const dispatch = useDispatch();
  //  const handdleStatusRequest = (e)=>{
  //      e.preventDefault();
  //      console.log(e)
  //      dispatch(setStatusTask(actualTareaShow._id))
  //  }

  // const handdleSetComments = (e)=>{
  //     e.preventDefault();
  //     console.log(e)
  //     dispatch(setCommment(actualTareaShow._id , comment))
  // }


  useEffect(() => {
    const setActual = () => {
      if (tareaactual) {
        dispatch(actualTarea(tareaactual.tareas[0]));
      }
    };

    setActual();

    // eslint-disable-next-line
  }, []);
  // const cerrar=()=>{
  //   setTimeout(function(){
  //   setInputRechazar({ ...inputRechazar, tarea: '', ref3: '' }) 
  //  setInputAceptar({ ...inputAceptar, tarea: '', ref3: '' }) 
  //  setCargandonoerr(false)
  //  setCargandoerr(false)
  //     setInfopopup(""); 
  //     setAnimationpopup({animation:'popupmensaje 0.25s alternate  ease-in'})
  //   }, 250);
  //   setAnimationpopup({animation:'popupmensajeend 0.25s alternate  ease-in'})
  // }
  // const changeActual = (actual) => {
  //   dispatch(actualTarea(actual));
  // };
  // const changeActual2 = (actual) => {
  //   var href = actualTareaShow.task;
  //   window.open(href);
  // };
  const [inputAceptar, setInputAceptar] = useState({
    proyecto: props.project._id,
    tarea: '',
    calificacion: '',
    feedback: '',
    ref3: ''
  })
  const [inputRechazar, setInputRechazar] = useState({
    proyecto: props.project._id,
    tarea: '',
    motivo: '',
    calificacion: '',
    feedback: '',
    ref3: ''
  })
  const enviarAceptacion = () => {
    setInputAceptar({ ...inputAceptar, proyecto: props.project._id })
    setCargando(true)
    setCargandoerr(false)
    let aceptarinfo = {
      proyecto: props.project._id,
      tarea: inputAceptar.tarea,
      calificacion: inputAceptar.calificacion,
      feedback: inputAceptar.feedback,
      ref3: inputAceptar.ref3,
      fase: staterechazado
    }
    axios.post('https://findally-backend.herokuapp.com/api/Project/send/message/accept', aceptarinfo).then(response => {
      setInputAceptar({ ...inputAceptar, ref3: '' })
      setCargando(false)
      setCargandonoerr(true)
    }, error => {
      setInputAceptar({ ...inputAceptar, ref3: '' })
      setCargandoerr(true)
      setCargando(false)
    })
  }
  const enviarRechazo = () => {
    setCargando(true)
    setCargandoerr(false)
    let rechazarinfo = {
      proyecto: props.project._id,
      tarea: inputRechazar.tarea,
      motivo: inputRechazar.motivo,
      calificacion: inputRechazar.calificacion,
      feedback: inputRechazar.feedback,
      ref3: inputRechazar.ref3,
      msgrechazado: inputRechazar.motivo,
      fase: staterechazado
    }
    console.log(rechazarinfo)
    axios.post('https://findally-backend.herokuapp.com/api/Project/send/message/refuse', rechazarinfo).then(response => {
      setInputRechazar({ ...inputRechazar, ref3: '' })
      setCargando(false)
      setCargandonoerr(true)
    }, error => {
      setInputRechazar({ ...inputRechazar, ref3: '' })
      setCargandoerr(true)
      setCargando(false)
    })
  }
  const idioma = useSelector(state => state.lang)
  const infor = useParams()
  const [informacionmap] = useState([])
  const infopedir = () => {
    let informaiconarray = []
    axios.get(`https://findally-backend.herokuapp.com/api/Project/${infor.project}`).then(respuestaa => {
      console.log(respuestaa.data.methodology_new_list.methodology_list.length)
      respuestaa.data.methodology_new_list.methodology_list.map(e => axios.get("https://findally-backend.herokuapp.com/api/etapas/new/" + e).then(respuesta => {
        informaiconarray.push(respuesta.data)
        console.log(informaiconarray.length)
        if (
          respuestaa.data.methodology_new_list.methodology_list.length === informaiconarray.length
        ) {

          setmetodologianueva(informaiconarray)
        }
      }
      )
      )
    }
    )
  }
  console.log(informacionmap)
  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    infopedir()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  // const pedirinfo=()=>{
  //   let informaiconarray=[]
  //   axios.get(`https://findally-backend.herokuapp.com/api/Project/${project}`).then(respuesta=>
  //   // respuesta.data.methodology_new_list.methodology_list.map(e=>axios.get("https://findally-backend.herokuapp.com/api/etapas/new/"+e).then(respuesta=>
  //   // {informaiconarray.push(respuesta.data)
  //   //   console.log(respuesta.data)
  //   // }))
  //   console.log(respuesta)
  //   )      
  //   setmetodologianueva(informaiconarray)
  //   }
  //   useEffect(() => {
  //     pedirinfo()
  //   }, [])
  const handleButtonClick = (project) => {
    console.log(project)
    // props.setActualEtapa(project)
    // console.log(etapedata)
  }
  console.log(infopopup)
  return (
    <>
    {statusrechazado?null:null}
      <div style={{ filter: `blur(${popup})` }}>
        <HeaderEmpresa />
        {/* <select className="Header-lang" value={lang} onChange={(e)=>dispatch(changeLang(e.target.value))}>
        <option value="en">en-US</option>
        <option value="es">es-COL</option>
      </select> */}
        <div className="volver-legal2">
          <Link to="/company/project">
            &#x276e;&nbsp;&nbsp;&nbsp;{<Translate className="boldfont" content="Entregas2" component="span" />}
          </Link>
        </div>
        <div className="metho-details-entregas">
          <div className="userprojects__buttons">
            {/* <button
            className="userprojects__buttons-btn"
            onClick={() => {actualizarmensaje()}}
          >
            {<Translate content="Mensaje" component="span"/>}
          </button>
          <button
            className="userprojects__buttons-btn"
            onClick={()=>setTerminarpro(true)}
          >
            {/* <Link       
              to="/feedback"
            > 
              {<Translate content="TerminarProyecto" component="span"/>}
            {/* </Link> 
          </button> */}
            {/* <button
            className="userprojects__buttons-btn"
            onClick={() => {
              handdleStatusChange("comentarios");
            }}
          >
            {<Translate content="COMENTARIOS" component="span"/>}
          </button>
          <button
            className="userprojects__buttons-btn"
            onClick={() => {
              handdleStatusChange("avances");
            }}
          >
            {<Translate content="Avances" component="span"/>}
          </button>
          <button
            className="userprojects__buttons-btn"
            onClick={() => {
              handdleStatusChange("equipo");
            }}
          >
          {<Translate content="Equipo" component="span"/>}
          </button> */}
            {/* <button className="userprojects__buttons-btn">Finalizados</button> */}
          </div>
          <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>


            <div className="metho-details-container-cmy" style={{ width: '80%' }}>
              {/*  {status === "comentarios" && props.project
            ? props.project.avances.map((e) => {
                return (
                  <>
                    <div key={e._id} className="metho-details-tarea">
                      <div className="" style={{ width: "30%", textAlign: '-webkit-auto' }}>
                        <span className="etiqueta">
                        {<Translate content="Avances" component="span"/>}
                        </span> 
                        <p className="project-name-task-module" >{props.project.nombre || "proyecto actual"}</p>
                      </div>
                      <div style={{ width: "70%", alignSelf:'center' }}>
                        <div className="metho-details-coments">
                          {/* <strong>Comentario de los consultores:</strong> 
                          <p className="metho-details-coment-span">
                            <q className="comment-quotes-coallier">{e.comentario || "comentario de los consultores"}</q>
                          </p>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            gap: ".5rem",
                            margin: ".5rem 0rem",
                          }}
                        >
                          <input
                            type="text"
                            id=""
                            className="input-text-answer"
                            value={comment}
                            onChange={(e) => setCommentValue(e.target.value)}
                            placeholder={e.respuesta||"Respuesta"}
                          />
                          <input
                            type="button"
                            value="Responder"
                            onClick={() => dispatch(setCommmentAvance(e._id, comment))}
                            className="answer-comment-task-company"
                          />
                        </div>
                      </div>
                    </div>
                  </>
                );
              })
            : null}

          {status === "equipo" ? <CompanyEquipo /> : null}
*/}
              {props.project.tareas
                ?
                <>
                  <div style={{ width: '95%', marginLeft: '2.5%', borderRadius: '10px', background: 'white' }}>


                    <div style={{ display: 'flex', marginLeft: '2.5%', width: '95%', paddingLeft: '15%' }}>


                      <div className="container_avances">
                        {metodologianueva ? metodologianueva.map((e, index) => {
                          return (<div key={index + 'sda'}><label className="semiboldfont sombrahover" htmlFor={`checkbox${e._id}${index}l2`} key={index} style={{ background: 'white', width: '100%', textTransform: 'uppercase', borderRadius: '10px', color: '#2e2e31', margin: '0', marginTop: '1rem', cursor: 'pointer', fontWeight: '900', padding: '0.5rem 0.5rem 0.5rem 1rem',display:'flex',justifyContent:'space-between' }}><div style={{cursor:'pointer'}}>{e.name}</div><ArrowDropDownIcon style={{color:'#696a7c'}}/></label>
                            {/* <input type="checkbox" defaultChecked  id={`checkbox${e._id}${index}l`} className="checkboxestilo3"/> */}

                            {/* <div className="deliverablesclasproject"><label htmlFor={`checkbox${e._id}${index}l2`} style={{background:'white',textTransform:'uppercase',width:'100%',color:'#2e2e31',fontWeight:'900',padding:'0.3rem 0.3rem 0.3rem 2rem',margin:'-0.188rem'}}>{<Translate content="DELIVERABLES" component="span" />}</label> */}
                            <input type="checkbox" defaultChecked id={`checkbox${e._id}${index}l2`} className="checkboxestilo4" />
                            <div className="labelfinalproject" style={{ background: 'white', color: '#2e2e31', margin: '-0.188rem', borderRadius: '0px 0px 15px 15px', marginBottom: '1rem' }}>
                              {e.phases.map((j, index) => <label className='metho-label-new' key={index + 'ggdf'} onClick={() => { handleButtonClick(j); console.log(j) }} htmlFor={`checkbox${e._id}${index}`} style={{ display: 'flex', margin: '0.2rem', paddingBottom: '0.2rem', marginLeft: '1rem', overflowWrap: 'break-word', cursor: 'pointer', fontWeight: '900', alignItems: 'center' }}>
                                <>
                                  <hr className="linea_ht" />
                                  {j.status === "En espera" ? <input type="checkbox" className="checkboxestilo2" /> : <input type="checkbox" defaultChecked className="checkboxestilo2" style={{ background: 'red' }} />}
                                  {j.status === "En proceso" && <label htmlFor={`checkbox${e._id}${index}`} style={{ background: '#f47227' }} className="checkboxestilo"></label>}
                                  {j.status === "En espera" && <label htmlFor={`checkbox${e._id}${index}`} style={{ background: 'gray' }} className="checkboxestilo"></label>}
                                  {j.status === "Aceptada" && <label htmlFor={`checkbox${e._id}${index}`} style={{ background: '#65ff00' }} className="checkboxestilo"></label>}
                                  {j.status === "Rechazada" && <label htmlFor={`checkbox${e._id}${index}`} style={{ background: 'red' }} className="checkboxestilo"></label>}
                                </>
                                <div style={{ display: 'flex', width: '95%', justifyContent: 'space-between' }}>

                                  <div>

                                    <span style={{ marginLeft: '0.5rem' }}>{index + 1}. {j.name}</span>{j.status === "Rechazada" && <div style={{ position: 'absolute', right: '1%', color: 'red' }} onClick={() => { setstatusrechazado(true) }}></div>}
                                    {j.status === "En proceso" ? <Info fontSize="inherit"
                                      style={{ fontSize: "27px", color: '#28157E', margin: '0.25rem', cursor: 'pointer', zIndex: '2', position: 'relative' }} onClick={() => { setInfopopup(j.mensajetarea); console.log("mira", j.mensajetarea) }}></Info> : null}
                                  {j.status !=="En espera"?<>&nbsp;<a href={j.taskurl} target="_blank" rel="noopener noreferrer"> <CloudDownloadIcon style={{color:'#fe7a00'}}>

                                  </CloudDownloadIcon></a></>:null}
                                  </div>
                                  {j.status === "En proceso" ? <div>
                                    <button className="btn_aceptar" onClick={() => { setInputAceptar({ ...inputAceptar, tarea: e._id, ref3: 'aceptar' }); setstaterechazado(j._id) }}>{<Translate content="Aceptar" component="span" />}</button>
                                    <button className="btn_rechazar" onClick={() => { setInputRechazar({ ...inputRechazar, tarea: e._id, ref3: 'cancel' }); setstaterechazado(j._id) }}>{<Translate content="Rechazar" component="span" />}</button>
                                  </div> : null}
                                </div>
                              </label>)}
                            </div></div>
                            // </div>
                          )
                        }) : null}
                        {/* {estadosproject ? 
                      project.tareas.filter(h => h.status === 'En espera').map((e, index) => {
                        return (
                          <div key={e._id} className="entrega-item">
                            <div className="descarga_avances" style={{borderRadius:'10px'}} onMouseOver={() => { changeActual(e) }}></div>

                            <div className="card_avances" ><div style={{ position: 'relative', zIndex: '2' }}>{index + 1})</div>&nbsp;<div style={{ position: 'relative', zIndex: '2', fontWeight: 'blod' }} >{e.etapa}</div>   <div> <Info fontSize="inherit"
                              style={{ fontSize: "27px", color: '#28157E', margin: '0.25rem', cursor: 'pointer', zIndex: '2', position: 'relative' }} onClick={() => setInfopopup(e.comCoallier)}></Info></div></div>
                            <div className="botones_avance" >
                              {e.status === 'En espera' ? <> <button className="btn_aceptar" onClick={() => { setInputAceptar({ ...inputAceptar, tarea: e._id, ref3: 'aceptar' }) }}>{<Translate content="Aceptar" component="span" />}</button>
                                <button className="btn_rechazar" onClick={() => { setInputRechazar({ ...inputRechazar, tarea: e._id, ref3: 'cancel' }) }}>{<Translate content="Rechazar" component="span" />}</button></> : null}

                              <GetAppIcon style={{ color: 'black', zIndex: '100', cursor: 'pointer' }} onClick={() => { changeActual2(e) }}></GetAppIcon>
                            </div>
                          </div>
                        )
                      }) 
                      : project.tareas.map((e, index) => {

                        return (
                          <div key={e._id} className="entrega-item">
                            <div className="descarga_avances" onMouseOver={() => { changeActual(e) }}></div>

                            <div className="card_avances" ><div style={{ position: 'relative', zIndex: '2' }}>{index + 1})</div>&nbsp;<div style={{ position: 'relative', zIndex: '2', fontWeight: 'blod' }} >{e.etapa}</div>   <div> <Info fontSize="inherit"
                              style={{ fontSize: "27px", color: '#28157E', margin: '0.25rem', cursor: 'pointer', zIndex: '2', position: 'relative' }} onClick={() => setInfopopup(e.comCoallier)}></Info></div></div>
                            <div className="botones_avance" >
                              {e.status === 'En espera' ? <> <button className="btn_aceptar" onClick={() => { setInputAceptar({ ...inputAceptar, tarea: e._id, ref3: 'aceptar' }) }}>{<Translate content="Aceptar" component="span" />}</button>
                                <button className="btn_rechazar" onClick={() => { setInputRechazar({ ...inputRechazar, tarea: e._id, ref3: 'cancel' }) }}>{<Translate content="Rechazar" component="span" />}</button></> : null}
                              <GetAppIcon style={{ color: 'black', zIndex: '100', cursor: 'pointer' }} onClick={() => { changeActual2(e) }}></GetAppIcon> </div>
                          </div>
                        )
                      })}
                      {estadosproject && 
                      project.tareas.filter(h => h.status === 'Aceptada').map((e, index) => {
                        return (
                          <div key={e._id} className="entrega-item">
                            <div className="descarga_avances" style={{background:'#9cf75b',borderRadius:'10px'}} onMouseOver={() => { changeActual(e) }}></div>

                            <div className="card_avances" ><div style={{ position: 'relative', zIndex: '2' }}>{index + 1})</div>&nbsp;<div style={{ position: 'relative', zIndex: '2', fontWeight: 'blod' }} >{e.etapa}</div>   <div> <Info fontSize="inherit"
                              style={{ fontSize: "27px", color: '#28157E', margin: '0.25rem', cursor: 'pointer', zIndex: '2', position: 'relative' }} onClick={() => setInfopopup(e.comCoallier)}></Info></div></div>
                            <div className="botones_avance" >
                              {e.status === 'En espera' ? <> <button className="btn_aceptar" onClick={() => { setInputAceptar({ ...inputAceptar, tarea: e._id, ref3: 'aceptar' }) }}>{<Translate content="Aceptar" component="span" />}</button>
                                <button className="btn_rechazar" onClick={() => { setInputRechazar({ ...inputRechazar, tarea: e._id, ref3: 'cancel' }) }}>{<Translate content="Rechazar" component="span" />}</button></> : null}

                              <GetAppIcon style={{ color: 'black', zIndex: '100', cursor: 'pointer' }} onClick={() => { changeActual2(e) }}></GetAppIcon>
                            </div>
                          </div>
                        )
                      }) 
                      }
                      {estadosproject &&
                      project.tareas.filter(h => h.status === 'Rechazada').map((e, index) => {
                        return (
                          <div key={e._id} className="entrega-item">
                            <div className="descarga_avances" style={{background:' #F2B7B8',borderRadius:'10px'}} onMouseOver={() => { changeActual(e) }}></div>

                            <div className="card_avances" ><div style={{ position: 'relative', zIndex: '2' }}>{index + 1})</div>&nbsp;<div style={{ position: 'relative', zIndex: '2', fontWeight: 'blod' }} >{e.etapa}</div>   <div> <Info fontSize="inherit"
                              style={{ fontSize: "27px", color: '#28157E', margin: '0.25rem', cursor: 'pointer', zIndex: '2', position: 'relative' }} onClick={() => setInfopopup(e.comCoallier)}></Info></div></div>
                            <div className="botones_avance" >
                              {e.status === 'En espera' ? <> <button className="btn_aceptar" onClick={() => { setInputAceptar({ ...inputAceptar, tarea: e._id, ref3: 'aceptar' }) }}>{<Translate content="Aceptar" component="span" />}</button>
                                <button className="btn_rechazar" onClick={() => { setInputRechazar({ ...inputRechazar, tarea: e._id, ref3: 'cancel' }) }}>{<Translate content="Rechazar" component="span" />}</button></> : null}

                              <GetAppIcon style={{ color: 'black', zIndex: '100', cursor: 'pointer' }} onClick={() => { changeActual2(e) }}></GetAppIcon>
                            </div>
                          </div>
                        )
                      }) 
                      } */}
                      </div>
                    </div>

                    {/*                 <CheckBoxIcon fontSize="inherit"
                          style={{ fontSize: "27px", color: 'green', margin: '0.25rem', cursor: 'pointer', position: 'relative', zIndex: '2' }} onClick={() => { setInputAceptar({ ...inputAceptar, tarea: e._id, ref3: 'aceptar' }) }} ></CheckBoxIcon>
                          <CheckBoxcancel fontSize="inherit"
                          style={{ fontSize: "20px", color: 'white', background: "red", margin: '0.25rem', cursor: 'pointer', position: 'relative', zIndex: '2' }} onClick={() => { setInputRechazar({ ...inputRechazar, tarea: e._id, ref3: 'cancel' }) }}></CheckBoxcancel>
                        */}


                                                                                  {!project.tareas.length ? <div className="entrega-item2">

                                                                                    <div className="card_avances" ><Translate content="Vacio" component="span" /> &nbsp; <div ></div> <Info fontSize="inherit"
                                                                                      style={{ fontSize: "27px", color: '#28157E', margin: '0.25rem', cursor: 'pointer', zIndex: '1000' }} ></Info></div>
                                                                                    <div className="botones_avance" >
                                                                                      <CheckBoxIcon fontSize="inherit"
                                                                                        style={{ fontSize: "27px", color: 'green', margin: '0.25rem', cursor: 'pointer' }}></CheckBoxIcon>
                                                                                      <CheckBoxcancel fontSize="inherit"
                                                                                        style={{ fontSize: "20px", color: 'white', background: "red", margin: '0.25rem', cursor: 'pointer' }}></CheckBoxcancel>
                                                                                      <GetAppIcon style={{ color: 'black', zIndex: '100', cursor: 'pointer' }}></GetAppIcon>
                                                                                    </div>
                                                                                  </div> : null}
                  </div>
                                                                                  
                                                                                                                            
                                                                          
                  {/* <Avances proyecto={props.project._id} />    */}
                </>
                : null}
            </div>
            <div style={{ flexDirection: 'column', display: 'flex', alignItems: 'center', width: '15%', marginLeft: '1%', padding: '2%', background: 'white', borderRadius: '10px', marginBottom: '40px' }}>
              <button
                className="userprojects__buttons-btn2"
                onClick={() => { actualizarmensaje() }}
                style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%',background:'#765bff' }} >
                <EmailIcon></EmailIcon>{<Translate content="Mensaje" component="span" />}
              </button>
              <button
                className="userprojects__buttons-btn2"
                onClick={() => setTerminarpro(true)}
                style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%',backgroundColor:'#f07130' }} >
                {/* <Link       
              to="/feedback"
            > */}<CheckCircleOutlineIcon></CheckCircleOutlineIcon>
                {<Translate content="Finalizarr" component="span" />}
                {/* </Link> */}
              </button>
            </div>
          </div>
        </div>
      </div>

      {/*
            <div className="metho-details-btn">
            <Link
            className="services-btn rounded"          
            to="/feedback"
            >
            {<Translate content="TerminarProyecto" component="span"/>}
            </Link>
            </div> 
            </div>
            </div>
          </div>*/}
                                                                              
                                                      
                                                      
                                              
      <Footer></Footer>











      {inputRechazar.ref3 === 'cancel' ?
                                                                                                                              <div className="mensaje_popup">
                                                                                                                                <div className="fondo_avance_popup" onClick={cerrar}></div>
                                                                                                                                <div className="container_popup_mensaje" style={animationpopup}>
                                                                                                                                  <div className="cancel_mensaje" onClick={cerrar}>
                                                                                                                                    X
                                                                                                                                  </div>

                                                                                                                                  <div style={{ display: 'flex', width: '100%' }}>
                                                                                                                                    <div style={{ display: 'flex', flexDirection: 'column', width: '100%', alignItems: 'center', background: 'white', color: '#212156', padding: '10% 0% 20% 0%', position: 'relative', borderRadius: '10px' }}>
                                                                                                                                      <p style={{ fontWeight: '600', fontSize: '22px', width: '80%' }}>{<Translate content="Cancelacion" component="span" />}</p>
                                                                                                                                      <div className="container_input_p_mensaje">
                                                                                                                                        <p style={{ width: "20%", margin: '0', textAlign: 'start', display: 'flex', alignItems: 'center' }}>{<Translate content="Clasificacionn" component="span" />}</p>
                                                                                                                                        <div className="section_feedback clasificacion avances_clasificacion">
                                                                                                                                          <label className="perfil__score-form-label2" style={{ display: 'none' }} htmlFor="radio0">
                                                                                                                                            ★
                                                                                                                                          </label>
                                                                                                                                          <input
                                                                                                                                            className="perfil__score-form-input "
                                                                                                                                            id="radio0"
                                                                                                                                            type="radio"
                                                                                                                                            name="calificacion"
                                                                                                                                            value="5"
                                                                                                                                            onClick={() => { setInputRechazar({ ...inputRechazar, calificacion: 5 }) }}
                                                                                                                                          />
                                                                                                                                          <label className="perfil__score-form-label2" htmlFor="radio0">
                                                                                                                                            ★
                                                                                                                                          </label>
                                                                                                                                          <input
                                                                                                                                            className="perfil__score-form-input "
                                                                                                                                            id="radio1"
                                                                                                                                            type="radio"
                                                                                                                                            name="calificacion"
                                                                                                                                            value="4"
                                                                                                                                            onClick={() => { setInputRechazar({ ...inputRechazar, calificacion: 4 }) }}
                                                                                                                                          />
                                                                                                                                          <label className="perfil__score-form-label2 " htmlFor="radio1">
                                                                                                                                            ★
                                                                                                                                          </label>
                                                                                                                                          <input
                                                                                                                                            className="perfil__score-form-input "
                                                                                                                                            id="radio2"
                                                                                                                                            type="radio"
                                                                                                                                            name="calificacion"
                                                                                                                                            value="3"
                                                                                                                                            onClick={() => { setInputRechazar({ ...inputRechazar, calificacion: 3 }) }}
                                                                                                                                          />
                                                                                                                                          <label className="perfil__score-form-label2 " htmlFor="radio2">
                                                                                                                                            ★
                                                                                                                                          </label>
                                                                                                                                          <input
                                                                                                                                            className="perfil__score-form-input "
                                                                                                                                            id="radio3"
                                                                                                                                            type="radio"
                                                                                                                                            name="calificacion"
                                                                                                                                            value="2"
                                                                                                                                            onClick={() => { setInputRechazar({ ...inputRechazar, calificacion: 2 }) }}
                                                                                                                                          />
                                                                                                                                          <label className="perfil__score-form-label2 " htmlFor="radio3">
                                                                                                                                            ★
                                                                                                                                          </label>
                                                                                                                                          <input
                                                                                                                                            className="perfil__score-form-input "
                                                                                                                                            id="radio4"
                                                                                                                                            type="radio"
                                                                                                                                            name="calificacion"
                                                                                                                                            value="1"

                                                                                                                                            onClick={() => { setInputRechazar({ ...inputRechazar, calificacion: 1 }) }}
                                                                                                                                          />
                                                                                                                                          <label className="perfil__score-form-label2" htmlFor="radio4">
                                                                                                                                            ★
                                                                                                                                          </label>
                                                                                                                                          <input
                                                                                                                                            className="perfil__score-form-input "
                                                                                                                                            id="radio5"
                                                                                                                                            type="radio"
                                                                                                                                            name="calificacion"
                                                                                                                                            value="1"
                                                                                                                                          />
                                                                                                                                        </div>
                                                                                                                                        <div className="container_input_p_mensaje">
                                                                                                                                          <p style={{ width: "20%", textAlign: 'start' }}>{<Translate content="Motivo" component="span" />}</p>
                                                                                                                                          <input type="text" className="input_mensaje_popup" style={{ width: "100%", resize: 'none', borderTop: '0', borderRight: '0', borderLeft: '0' }} onChange={(event) => { setInputRechazar({ ...inputRechazar, motivo: event.target.value }) }}></input>
                                                                                                                                        </div>
                                                                                                                                        <div className="container_input_p_mensaje">
                                                                                                                                          <p style={{ width: "20%", textAlign: 'start' }}>Feedback</p>
                                                                                                                                          <input type="text" className="input_mensaje_popup" style={{ width: "100%", resize: 'none', borderTop: '0', borderRight: '0', borderLeft: '0' }} onChange={(event) => { setInputRechazar({ ...inputRechazar, feedback: event.target.value }) }}></input>
                                                                                                                                        </div>
                                                                                                                                        {cargando === false ?
                                                                                                                                          <button className="btn_mensaje" onClick={enviarRechazo}>{<Translate content="Enviarr" component="span" />}</button> : <button className="btn_mensaje_cargando" onClick={enviarRechazo}>{<Translate content="Cargandooo" component="span" />}</button>}
                                                                                                                                      </div>
                                                                                                                                    </div>
                                                                                                                                    {/* <div className="derecha_mensaje_popup">
                                                                                                                                      <p style={{ textAlign: 'center', width: '70%', color: 'white', fontSize: '22px' }}>{<Translate content="Rechazarentrega" component="span"/>}</p>
                                                                                                                                      <img style={{ width: '60%' }} src="https://coally.com/wp-content/uploads/2021/10/Logo-0-1.png" alt="logo"></img>
                                                                                                                                      <p style={{ textAlign: 'center' }}>The on-demand platform for Business Transformation services</p>
                                                                                                                                      <div style={{ width: '50%', display: 'flex', justifyContent: 'space-around' }}>
                                                                                                                                      <FacebookIcon></FacebookIcon>
                                                                                                                                      <InstagramIcon></InstagramIcon>
                                                                                                                                        <LinkedInIcon></LinkedInIcon>
                                                                                                                                      </div></div> */}
                                                                                                                                  </div>
                                                                                                                                </div>
                                                                                                                              </div> : null
                                                                                                                            }














      {cargandoerr === true ? <div className="mensaje_popup">
                                                                            <div className="animacion_popup" style={{ display: 'flex', background: 'white', animationpopup, color: 'black', flexDirection: 'column', borderRadius: '10px', width: '20%' }}>
                                                                              <div className='barra_mensaje_dislike'></div>
                                                                              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '5%', position: 'relative' }}>
                                                                                <ThumbDownIcon className="MuiSvgIcon-root_likes"></ThumbDownIcon>
                                                                                <p style={{ margin: '0' }}>Error</p>
                                                                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '20%' }}>
                                                                                  <div className="barra_descheck_popup"></div><div style={{ color: 'red' }}>✖</div>
                                                                                </div>
                                                                                <p style={{ textAlign: 'center' }}>{<Translate content="itappearsthattherewassanissussubmittingyourreuqest" component="span" />}</p>
                                                                                <button onClick={cerrar} className="btn_mensaje_dislike">Ok!</button></div>
                                                                            </div>
                                                                          </div> : null}
                                                                          {cargandonoerr === true ? <div className="mensaje_popup">
                                                                            <div className="animacion_popup" style={{ display: 'flex', background: 'white', animationpopup, color: 'black', flexDirection: 'column', borderRadius: '10px', width: '20%' }}>
                                                                              <div className='barra_mensaje_like'></div>
                                                                              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '5%', position: 'relative' }}>
                                                                                <ThumbUpIcon className="MuiSvgIcon-root_likes2"></ThumbUpIcon>
                                                                                <p style={{ margin: '0' }}>{<Translate content="Success" component="span" />}</p>
                                                                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '20%' }}>
                                                                                  <div className="barra_check_popup"></div><div style={{ color: 'green' }}>✔</div>
                                                                                </div>
                                                                                <p style={{ textAlign: 'center' }}>{<Translate content="Yourrequestwassubmittedsuccesfully" component="span" />}</p>
                                                                                <button onClick={cerrar} className="btn_mensaje_like">{<Translate content="Done" component="span" />}</button></div>
                                                                            </div>
                                                                          </div> : null}









          {mensajepopupstate ? null : null}







      {input.ref3 === 'mensaje' ?
                                                                                <div className="mensaje_popup">
                                                                                  <div className="fondo_avance_popup" onClick={cerrar}></div>
                                                                                  <div className="container_popup_mensaje" style={animationpopup}>
                                                                                    <div className="cancel_mensaje" onClick={cerrar}>
                                                                                      X
                                                                                    </div>

                                                                                    <div style={{ display: 'flex', width: '100%' }}>
                                                                                      <div style={{ display: 'flex', flexDirection: 'column', width: '100%', alignItems: 'center', background: 'white', color: '#212156', padding: '10% 0% 20% 0%', position: 'relative', borderRadius: '10px' }}>
                                                                                        <p style={{ fontWeight: '600', fontSize: '22px', width: '80%', textAlign: 'center' }}>{<Translate content="Enviarmensaje" component="span" />}</p>
                                                                                        <p>{<Translate content="Enviaunmensajeatuequipodetrabajo" component="span" />}</p>
                                                                                        <div className="container_input_p_mensaje">
                                                                                          <input placeholder={idioma === "en" ? "Affair" : 'Asunto'} type="text" className="input_mensaje_popup" style={{ width: "100%", background: '#F1F1F1', padding: '0.3rem 1rem', border: 'none', borderRadius: '10px' }} onChange={(event) => { setInput({ ...input, asunto: event.target.value }) }}></input>
                                                                                        </div>
                                                                                        <div className="container_input_p_mensaje">
                                                                                          <textarea placeholder={idioma === "en" ? "Message" : 'Mensaje'} className="input_mensaje_popup" type="text" style={{ width: "100%", resize: 'none', background: '#F1F1F1', padding: '0.3rem 1rem', border: 'none', borderRadius: '10px' }} onChange={(event) => { setInput({ ...input, mensaje: event.target.value }) }}></textarea>
                                                                                        </div>
                                                                                        {cargando === false ?
                                                                                          <button className="btn_mensaje" onClick={mandarMensaje}>{<Translate content="Enviarr" component="span" />}</button> : <button className="btn_mensaje_cargando" onClick={mandarMensaje}>{<Translate content="Cargandooo" component="span" />}</button>}
                                                                                      </div>
                                                                                      {/* <div className="derecha_mensaje_popup">
                                                                                            <p style={{textAlign:'center',width:'70%',color:'white',fontSize:'22px'}}>{<Translate content="Enviaunmensajeatuequipodetrabajo" component="span"/>}</p>
                                                                                            <img style={{width:'60%'}} src="https://coally.com/wp-content/uploads/2021/10/Logo-0-1.png" alt="logo"></img>
                                                                                            <p style={{textAlign:'center'}}>The on-demand platform for Business Transformation services</p>
                                                                                            <div style={{width:'50%',display:'flex',justifyContent:'space-around'}}>
                                                                                            <FacebookIcon></FacebookIcon>
                                                                                            <InstagramIcon></InstagramIcon>
                                                                                            <LinkedInIcon></LinkedInIcon>
                                                                                          </div></div>*/}
                                                                                    </div>
                                                                                  </div>
                                                                                </div>
                                                                                : null
                                                                              }












      {terminarpro ?
                                                        <div className="mensaje_popup">
                                                          <div className="fondo_avance_popup" onClick={cerrar}></div>
                                                          <div className="container_popup_mensaje" style={animationpopup}>
                                                            <div className="cancel_mensaje" onClick={cerrar}>
                                                              X
                                                            </div>

                                                            <div style={{ display: 'flex', width: '100%' }}>
                                                              <div style={{ display: 'flex', flexDirection: 'column', width: '100%', alignItems: 'center', background: 'white', color: '#212156', padding: '10% 0% 20% 0%', position: 'relative', borderRadius: '10px' }}>
                                                                <p style={{ fontWeight: '600', fontSize: '22px', width: '80%', textAlign: 'center' }}>{<Translate content="Terminarproyecto" component="span" />}</p>
                                                                <div className="container_input_p_mensaje">
                                                                  <p style={{ width: "100%", margin: '0', textAlign: 'center' }}>{<Translate content="Porqueterminasteelproyecto" component="span" />}</p>
                                                                  <Link
                                                                    to="/feedback"
                                                                  >
                                                                    <button
                                                                      // onClick={(event) => {setInputformulario({ ...inputformulario, boton: event.target.value })}}
                                                                      className="btn_formulario_popup" style={{ background: 'white', borderStyle: 'dashed', borderColor: '#7B61FF', color: '#F07130' }}>{<Translate content="Yatengotodosmisentregables" component="span" />}</button>
                                                                  </Link>
                                                                  <button style={{ background: 'white', color: '#202256', border: 'none', fontWeight: '900' }}
                                                                    className="btn_formulario_popup"
                                                                    onClick={() => setDevolucionpopup('En los proximos 7 dias nos contactaremos contigo para la devolución del dinero')}
                                                                  > {<Translate content="Devoluciondepago" component="span" />}</button>
                                                                </div>
                                                                {cargando === false ?
                                                                  <button className="btn_mensaje" onClick={mandarMensaje}>{<Translate content="Enviarr" component="span" />}</button> : <button className="btn_mensaje_cargando" onClick={mandarMensaje}>{<Translate content="Cargandooo" component="span" />}</button>}
                                                                {/* </div>
                                                                  <div className="derecha_mensaje_popup">
                                                                  <p style={{textAlign:'center',width:'70%',color:'white',fontSize:'22px'}}>{<Translate content="Finalizarproyecto" component="span"/>}</p>
                                                                  <img style={{width:'60%'}} src="https://coally.com/wp-content/uploads/2021/10/Logo-0-1.png" alt="logo"></img>
                                                                  <p style={{textAlign:'center'}}>The on-demand platform for Business Transformation services</p>
                                                                  <div style={{width:'50%',display:'flex',justifyContent:'space-around'}}>
                                                                  <FacebookIcon></FacebookIcon>
                                                                  <InstagramIcon></InstagramIcon>
                                                                  <LinkedInIcon></LinkedInIcon>
                                                                </div>*/}</div>
                                                            </div>
                                                          </div>
                                                        </div>
                                                        : null
                                                      }










      {devolucionpopup === '' ? null :
                                                        <div className="mensaje_popup">
                                                          <div className="fondo_avance_popup" onClick={cerrar}></div>
                                                          <div className="container_popup_mensaje2" style={animationpopup}>
                                                            <p>{devolucionpopup}</p>
                                                            <div id="2" className="cancel_mensaje2" onClick={cerrar}>
                                                              X
                                                            </div>

                                                          </div>
                                                        </div>

                                                      }








      {cargandoerr === true ? <div className="mensaje_popup">
                                                <div style={{ display: 'flex', background: 'white', color: 'black', animationpopup, flexDirection: 'column', borderRadius: '10px', width: '20%' }}>
                                                  <div className='barra_mensaje_dislike'></div>
                                                  <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '5%', position: 'relative' }}>
                                                    <ThumbDownIcon className="MuiSvgIcon-root_likes"></ThumbDownIcon>
                                                    <p style={{ margin: '0' }}>Error</p>
                                                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '20%' }}>
                                                      <div className="barra_descheck_popup"></div><div style={{ color: 'red' }}>✖</div>
                                                    </div>
                                                    <p style={{ textAlign: 'center' }}>{<Translate content="itappearsthattherewassanissussubmittingyourreuqest" component="span" />}</p>
                                                    <button onClick={cerrar} className="btn_mensaje_dislike">Ok!</button></div>
                                                </div>
                                              </div> : null}
                                            {cargandonoerr === true ? <div className="mensaje_popup">
                                              <div style={{ display: 'flex', background: 'white', color: 'black', animationpopup, flexDirection: 'column', borderRadius: '10px', width: '20%' }}>
                                                <div className='barra_mensaje_like'></div>
                                                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '5%', position: 'relative' }}>
                                                  <ThumbUpIcon className="MuiSvgIcon-root_likes2"></ThumbUpIcon>
                                                  <p style={{ margin: '0' }}>{<Translate content="Success" component="span" />}</p>
                                                  <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '20%' }}>
                                                    <div className="barra_check_popup"></div><div style={{ color: 'green' }}>✔</div>
                                                  </div>
                                                  <p style={{ textAlign: 'center' }}>{<Translate content="Yourrequestwassubmittedsuccesfully" component="span" />}</p>
                                                  <button onClick={cerrar} className="btn_mensaje_like">{<Translate content="Done" component="span" />}</button></div>
                                              </div>
                                            </div> : null}








      {inputAceptar.ref3 === 'aceptar' ?
                                                                                    <div className="mensaje_popup" >
                                                                                      <div className="fondo_avance_popup" onClick={cerrar}></div>
                                                                                      <div className="container_popup_mensaje " style={animationpopup}>
                                                                                        <div className="cancel_mensaje" onClick={cerrar}>
                                                                                          X
                                                                                        </div>

                                                                                        <div style={{ display: 'flex', width: '100%' }}>
                                                                                          <div style={{ display: 'flex', flexDirection: 'column', width: '100%', alignItems: 'center', background: 'white', color: '#212156', padding: '10% 0% 20% 0%', position: 'relative', borderRadius: '10px' }}>
                                                                                            <p style={{ fontWeight: '600', fontSize: '22px', width: '80%' }}>{<Translate content="Aceptacion" component="span" />}</p>
                                                                                            <div className="container_input_p_mensaje">
                                                                                              <p style={{ width: "20%", margin: '0', textAlign: 'start', display: 'flex', alignItems: 'center' }}>{<Translate content="Clasificacionn" component="span" />}</p>
                                                                                              <div className="section_feedback clasificacion avances_clasificacion">
                                                                                                <label className="perfil__score-form-label2" style={{ display: 'none' }} htmlFor="radio0">
                                                                                                  ★
                                                                                                </label>
                                                                                                <input
                                                                                                  className="perfil__score-form-input "
                                                                                                  id="radio0"
                                                                                                  type="radio"
                                                                                                  name="calificacion"
                                                                                                  value="5"
                                                                                                  onClick={() => { setInputAceptar({ ...inputAceptar, calificacion: 5 }) }}
                                                                                                />
                                                                                                <label className="perfil__score-form-label2" htmlFor="radio0">
                                                                                                  ★
                                                                                                </label>
                                                                                                <input
                                                                                                  className="perfil__score-form-input "
                                                                                                  id="radio1"
                                                                                                  type="radio"
                                                                                                  name="calificacion"
                                                                                                  value="4"
                                                                                                  onClick={() => { setInputAceptar({ ...inputAceptar, calificacion: 4 }) }}
                                                                                                />
                                                                                                <label className="perfil__score-form-label2 " htmlFor="radio1">
                                                                                                  ★
                                                                                                </label>
                                                                                                <input
                                                                                                  className="perfil__score-form-input "
                                                                                                  id="radio2"
                                                                                                  type="radio"
                                                                                                  name="calificacion"
                                                                                                  value="3"
                                                                                                  onClick={() => { setInputAceptar({ ...inputAceptar, calificacion: 3 }) }}
                                                                                                />
                                                                                                <label className="perfil__score-form-label2 " htmlFor="radio2">
                                                                                                  ★
                                                                                                </label>
                                                                                                <input
                                                                                                  className="perfil__score-form-input "
                                                                                                  id="radio3"
                                                                                                  type="radio"
                                                                                                  name="calificacion"
                                                                                                  value="2"
                                                                                                  onClick={() => { setInputAceptar({ ...inputAceptar, calificacion: 2 }) }}
                                                                                                />
                                                                                                <label className="perfil__score-form-label2 " htmlFor="radio3">
                                                                                                  ★
                                                                                                </label>
                                                                                                <input
                                                                                                  className="perfil__score-form-input "
                                                                                                  id="radio4"
                                                                                                  type="radio"
                                                                                                  name="calificacion"
                                                                                                  value="1"
                                                                                                  onClick={() => { setInputAceptar({ ...inputAceptar, calificacion: 1 }) }}
                                                                                                />
                                                                                                <label className="perfil__score-form-label2" htmlFor="radio4">
                                                                                                  ★
                                                                                                </label>
                                                                                                <input
                                                                                                  className="perfil__score-form-input "
                                                                                                  id="radio5"
                                                                                                  type="radio"
                                                                                                  name="calificacion"
                                                                                                  value="1"
                                                                                                />
                                                                                              </div>
                                                                                              <div className="container_input_p_mensaje">
                                                                                                <p style={{ width: "20%", textAlign: 'start' }}>Feedback</p>
                                                                                                <input type="text" className="input_mensaje_popup" style={{ width: "100%", resize: 'none', borderTop: '0', borderRight: '0', borderLeft: '0' }} onChange={(event) => { setInputAceptar({ ...inputAceptar, feedback: event.target.value }) }}></input>
                                                                                              </div>
                                                                                              {cargando === false ?
                                                                                                <button className="btn_mensaje" onClick={enviarAceptacion}>{<Translate content="Enviarr" component="span" />}</button> : <button className="btn_mensaje_cargando" onClick={enviarAceptacion}>{<Translate content="Cargandooo" component="span" />}</button>}
                                                                                            </div>
                                                                                          </div>
                                                                                          {/* <div className="derecha_mensaje_popup">
                                                                                          <p style={{ textAlign: 'center', width: '70%', color: 'white', fontSize: '22px' }}>{<Translate content="Aceptarentrega" component="span"/>}</p>
                                                                                          <img style={{ width: '60%' }} src="https://coally.com/wp-content/uploads/2021/10/Logo-0-1.png" alt="logo"></img>
                                                                                          <p style={{ textAlign: 'center' }}>The on-demand platform for Business Transformation services</p>
                                                                                          <div style={{ width: '50%', display: 'flex', justifyContent: 'space-around' }}>
                                                                                            <FacebookIcon></FacebookIcon>
                                                                                            <InstagramIcon></InstagramIcon>
                                                                                            <LinkedInIcon></LinkedInIcon>
                                                                                            </div>
                                                                                          </div> */}
                                                                                        </div>
                                                                                      </div>
                                                                                    </div>
                                                                                    : null
                                                                                  }







                                          {infopopup === '' ? null :
                                          <div className="mensaje_popup">
                                            <div className="fondo_avance_popup" onClick={cerrar}></div>
                                            <div className="container_popup_mensaje2" style={animationpopup}>
                                              <p>{infopopup}</p>
                                              <div id="2" className="cancel_mensaje2" onClick={cerrar}>
                                                X
                                              </div>

                                            </div>
                                          </div>

                                        }
    </>
  );
}

function mapStateToProps(state) {
  return {
    subEtapas: state.subEtapas,
    singlePhase: state.SinglePhase,
    Etapas: state.Etapas,
    user: state.user,
    userProjects: state.user.projects,
    project: state.ActualProject,
    doneMetho: state.doneMetho,
  };
}
const mapDispatchToProps = {
  getSinglePhase,
  getEtapas,
  getSingleEtapa,
  getProject,
  ConsultorProjects,
  getSubEtapas,
};

export default connect(mapStateToProps, mapDispatchToProps)(CompaniesEntrega);