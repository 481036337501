import React from 'react';
import './CrearOportunidadNew.css'
import { useDispatch } from 'react-redux';
import { saveOportunityForm } from '../../actions';

export default function External(props) {
const { form, step, handleStep, handleBackStep, lang, oportunityForm, handleForm} = props;
  const dispatch = useDispatch();
    const handleChange = (event) => {
        handleForm({...form, [event.target.name]: event.target.value});
    dispatch(saveOportunityForm({[event.target.name]: event.target.value}, step));
  }

    return (
        <>
        <div style={{display: 'flex', alignItems: 'flex-start', flexDirection: 'column', marginBottom: '50px'}}>
        <div style={{marginBottom: '30px'}}>
        <h3 style={{ fontFamily: 'Poppins', fontWeight: '700', fontSize: '14px', marginBottom: '20px', marginLeft: '-5px' }}>{lang === 'es' ? 'Si tu oportunidad reposa en una plataforma externa, coloca el link aquí.' : 'If your opportunity rests on an external platform, place the link here.'}</h3>
        <input type="text" placeholder='Link' name="link" onChange={handleChange} value={oportunityForm?.link ? oportunityForm?.link : form?.link}
        className="contenedorExternal_input"></input>
        </div>
            </div>
            <div
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "space-between",
              padding: "1rem 3rem 0rem 3rem",
              alignItems: "center",
            }}
          >
             <button className='button_siguiente_create_B' onClick={handleBackStep}>Anterior</button>
            <h3 className='stepCounter'>{`${step}/14`}</h3>
            <button className='button_siguiente_create'
            onClick={()=>handleStep('link')}>{lang === 'es' ?'Siguiente' : 'Next'}</button>
          </div>
          </>
    )
}
