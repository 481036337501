import React from "react";
import './unileverMentorCardHome.css';
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

export default function UnileverMentorCardHomeList() {
  const lang = useSelector((state) => state.lang);

  const names = [
    "John",
    "Paul",
    "George",
    "Ringo",
    "Pete",
    "Mike",
    "Dave",
    "Mick",
    "Keith",
    "Charlie",
    "Bill",
    "Bob",
    "Bruce",
    "Eric",
    "Jimi",
    "Jimmy",
  ]
  const lastNames = [
    "Lennon",
    "McCartney",
    "Harrison",
    "Starr",
    "Townshend",
    "Love",
    "Gilmour",
    "Jagger",
    "Richards",
    "Watts",
    "Wyman",
    "Dylan",
    "Springsteen",
    "Clapton",
    "Hendrix",
    "Page",
  ]

  const profession = [
    "Developer",
    "Designer",
    "Engineer",
    "Architect",
    "Manager",
    "Analyst",
    "Consultant",
    "Scientist",
    "Technician",
    "Technologist",
    "Programmer",
    "Architect",
    "Artist",
    "Writer",
    "Director",
    "Producer",
  ]

  const randomProfession = () => {
    return profession[Math.floor(Math.random() * profession.length)]
  }

  const randomName = () => {
    return names[Math.floor(Math.random() * names.length)] + " " + lastNames[Math.floor(Math.random() * lastNames.length)]
  }
  return(
    <div className="home-profesional-unilever-list-Container-rectangular">
      <div className={'NewCardOportunidad-Top-Container-rectangle'}>
            <div className="NewCardOportunidad-Top-Container-rectangle-inner-1">
              <div className="NewCardOportunidad-Top-Container-rectangle-inner-1-1">
                <div className="NewCardOportunidad-Top-subContainer-1">
                  <img className={'image-profesional-home-unilever'} src={'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png'} alt={'profile broken'} />
                </div>
                <div className="card-home-unilever-profesional-mid-section-list">
                <h1 style={{
                fontSize: '12px',
                fontWeight: '400',
                color: '#F07130',
                textAlign: 'left',
                width: 'calc(100% - 60px)',
                margin: '0px',
                alignSelf: 'flex-start',
              }}>
                {lang === 'es' ? '75% Compatibilidad' : '75% Compatibility'}
              </h1>
                  <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    width: '100%',
                    textAlign: 'center',
                  }}>
                    <div style={{
                      display: 'flex',
                      justifyContent: 'flex-start',
                      alignItems: 'center',
                      width: 'calc(100% - 20px)',
                      height: '100%',
                      color: '#393939',
                      fontSize: '18px',
                      fontWeight: '700',
                    }}>
                      {randomName()}
                    </div>
                  </div>
                <p style={{
                fontSize: '14px',
                fontWeight: '400',
                color: '#5D5D5D',
                textAlign: 'left',
                margin: 0,
                paddingBottom: '30px',
                alignSelf: 'flex-start',
              }}>{randomProfession()}</p>
                </div>
              </div>
              <div className="NewCardOportunidad-Top-Container-rectangle-inner-1-2">
                <div className="NewCardOportunidad-Top-subContainer-3">
                </div>
              </div>
            </div>
            <div className="unilever-home-profesional-Top-Container-rectangle-inner-2">
            <div className="unilever-home-profesional-Top-Container-rectangle-inner-2-1">
            <div className="unilever-home-profesional-mid-Container-inner-3-1">
                <p style={{fontWeight: '400', fontSize: '14px', margin: '0'}}>Ver más</p>
                <div>
              <Link to={`/consultor/project/${randomName()}`}>
                <button
                  className={'CardOportunidadProfesional-Button'}>
                  {lang === 'es' ? 'Seleccionar' : 'Select'}
                </button>
              </Link>
            </div>
              </div>
            </div>
            </div>
          </div>
    </div>
  )
}