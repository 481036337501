import React from "react";
import TestCard from "./TestCard";

const TestsInfo = ({ lang, width, color }) => {
  const tests = [
    {
      id: 1,
      name: "Test de personalidad",
      real: "personalidad",
      description:
        "Realice este test gratuito para saber más sobre sí mismo y sus puntos fuertes.",
    },
    {
      id: 2,
      name: "Test Vocacional",
      description:
        "Realice este test gratuito para saber más sobre sí mismo y sus puntos fuertes.",
    },
    {
      id: 3,
      name: "Test de Inteligencia",
      description:
        "Realice este test gratuito para saber más sobre sí mismo y sus puntos fuertes.",
    },
    {
      id: 4,
      name: "Habilidades sociales",
      description:
        "Realice este test gratuito para saber más sobre sí mismo y sus puntos fuertes.",
    },
  ];

  const testsENG = [
    {
      id: 1,
      name: "Personality test",
      real: "personalidad",
      description:
        "Take this free trial to learn more about yourself and your strengths.",
    },
    {
      id: 2,
      name: "Vocational test",
      description:
        "Take this free trial to learn more about yourself and your strengths.",
    },
    {
      id: 3,
      name: "Inteligence test",
      description:
        "Take this free trial to learn more about yourself and your strengths.",
    },
    {
      id: 4,
      name: "Social skills",
      description:
        "Take this free trial to learn more about yourself and your strengths.",
    },
  ];

  return (
    <>
      {lang === "es"
        ? tests.map((test, i) => {
            return (
              <TestCard
                key={i}
                id={test.id}
                name={test.name}
                description={test.description}
                width={width}
                real={test.real}
                color={color ? color : ""}
              />
            );
          })
        : testsENG.map((test, i) => {
            return (
              <TestCard
                key={i}
                id={test.id}
                name={test.name}
                description={test.description}
                width={width}
                real={test.real}
                color={color ? color : ""}
              />
            );
          })}
    </>
  );
};

export default TestsInfo;
