import React from 'react';
import { Document, Page, StyleSheet } from '@react-pdf/renderer';
import Html from 'react-pdf-html';

// Create styles
const styles = StyleSheet.create({
  page: {
    flexDirection: 'row',
    backgroundColor: 'white',
  }
});

const html = (user)  => `<html lang="en">
<style>
    .body{
        margin: 16px;
        padding: 0;
    }

    .contact{
        list-style: none;
        float: left;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }

    .contact li{
        margin-left: 16px;
    }

    .title{
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-items: center;
        border-bottom: 2px solid black;
        margin: 0px;
    }
    .titles {
      margin: 5px;
    }
    .p_text {
      font-size: 10px;
      text-align: justify;
     text-justify: inter-word;
     margin: 0px;
    }
    .camps_info{
      float: left;
      width: 100%;
    }
    .eduEXp {
      margin: 0px;
    }
</style>
<body class="body">
    <div class="title">
        <h3>${user?.info_personal?.nombre ? user?.info_personal?.nombre : user?.cv_file?.info_personal ? user?.cv_file?.info_personal.nombre : ''}</h3>
        <div>

        </div>
    </div>
    <div class="title">
      <h5 class="titles">Acerca de mi</h5>
      <p class="p_text">${user?.extracto ? user.extracto : user?.cv_file?.extracto ? user?.cv_file?.extracto : ''}</p>
    </div>
    <div class="title">
        <h5 class="titles">Educacion</h5>
        <div class="camps_info"> 
        ${user?.educacion ? user?.educacion.map(e => {
          return `<div> 
            <h5 class="eduEXp">
            * ${e.NombreInstitucion ? e.NombreInstitucion : ""}
            </h5>
            <p class="p_text">
            ${e.Titulo_Certificacion ? e.Titulo_Certificacion : ""}
            </p>
            <p class="p_text">
            ${e.duracion ? e.duracion : ""}
            </p>
          </div>`
        }): user?.cv_file?.educacion ? user?.cv_file?.educacion.map(e => {
          return `<div> 
            <h5 class="eduEXp">
            * ${e.NombreInstitucion ? e.NombreInstitucion : ""}
            </h5>
            <p class="p_text">
            ${e.Titulo_Certificacion ? e.Titulo_Certificacion : ""}
            </p>
            <p class="p_text">
            ${e.duracion ? e.duracion : ""}
            </p>
          </div>`
        } ): ""}
        </div>
    </div>
    <div class="title">
        <h5 class="titles">Experiencia</h5>
        <div class="camps_info"> 
        ${user?.experiencia ? user?.experiencia.map(e => {
          return `<div> 
            <h5 class="eduEXp">
            * ${e.Nombreempresa ? e.Nombreempresa : 'No especificado'}
            </h5>
            <p class="p_text">
            ${e.cargos ? e.cargos[0].nombrecargo : 'No especificado'}
            </p>
            <p class="p_text">
            ${e.cargos ? e.cargos[0].duracion : 'No especificado'}
            </p>
          </div>`
        }): user?.cv_file?.experiencia ? user?.cv_file?.experiencia.map(e => {
          return `<div> 
            <h5 class="eduEXp">
            * ${e.Nombreempresa ? e.Nombreempresa : 'No especificado'}
            </h5>
            <p class="p_text">
            ${e.cargos ? e.cargos[0].nombrecargo : 'No especificado'}
            </p>
            <p class="p_text">
            ${e.cargos ? e.cargos[0].duracion : 'No especificado'}
            </p>
          </div>`
        }) : ""}
        </div>
    </div>
</body>
</html>
`;

const htmlFree = (user)  => `<html lang="en">
<style>
    .body{
        margin: 16px;
        padding: 0;
    }

    .contact{
        list-style: none;
        float: left;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }

    .contact li{
        margin-left: 16px;
    }

    .title{
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-items: center;
        border-bottom: 2px solid black;
        margin: 0px;
    }
    .titles {
      margin: 5px;
    }
    .p_text {
      font-size: 10px;
      text-align: justify;
     text-justify: inter-word;
     margin: 0px;
    }
    .camps_info{
      float: left;
      width: 100%;
    }
    .eduEXp {
      margin: 0px;
    }
</style>
<body class="body">
    <div class="title">
        <h3>Aplicante</h3>
    </div>
    <div class="title">
      <h5 class="titles">Acerca de mi</h5>
      <p class="p_text"> ${user?.extracto ? user.extracto : user?.cv_file?.extracto ? user?.cv_file?.extracto : ''}</p>
    </div>
    <div class="title">
        <h5 class="titles">Educacion</h5>
        <div class="camps_info"> 
          ${user?.educacion ? user?.educacion.map(e => {
            return `<div> 
              <h5 class="eduEXp">
              * ${e.NombreInstitucion ? e.NombreInstitucion : ""}
              </h5>
              <p class="p_text">
              ${e.Titulo_Certificacion ? e.Titulo_Certificacion : ""}
              </p>
              <p class="p_text">
              ${e.duracion ? e.duracion : ""}
              </p>
            </div>`
          }): user?.cv_file?.educacion ? user?.cv_file?.educacion.map(e => {
            return `<div> 
              <h5 class="eduEXp">
              * ${e.NombreInstitucion ? e.NombreInstitucion : ""}
              </h5>
              <p class="p_text">
              ${e.Titulo_Certificacion ? e.Titulo_Certificacion : ""}
              </p>
              <p class="p_text">
              ${e.duracion ? e.duracion : ""}
              </p>
            </div>`
          } ): ""}
        </div>
    </div>
    <div class="title">
        <h5 class="titles">Experiencia</h5>
        <div class="camps_info"> 
          ${user?.experiencia ? user?.experiencia.map(e => {
            return `<div> 
              <h5 class="eduEXp">
              * ${e.Nombreempresa ? e.Nombreempresa : 'No especificado'}
              </h5>
              <p class="p_text">
              ${e.cargos ? e.cargos[0].nombrecargo : 'No especificado'}
              </p>
              <p class="p_text">
              ${e.cargos ? e.cargos[0].duracion : 'No especificado'}
              </p>
            </div>`
          }): user?.cv_file?.experiencia ? user?.cv_file?.experiencia.map(e => {
            return `<div> 
              <h5 class="eduEXp">
              * ${e.Nombreempresa ? e.Nombreempresa : 'No especificado'}
              </h5>
              <p class="p_text">
              ${e.cargos ? e.cargos[0].nombrecargo : 'No especificado'}
              </p>
              <p class="p_text">
              ${e.cargos ? e.cargos[0].duracion : 'No especificado'}
              </p>
            </div>`
          }) : ""}
        </div>
    </div>
</body>
</html>
`;

export const CVdocument = ({user}) => {
  return ( <Document>
    <Page size="A4" style={styles.page}>
        <Html>{html(user)}</Html>
    </Page>
  </Document>)
};

export const CVdocumentFree = ({user}) => {
  return ( <Document>
    <Page size="A4" style={styles.page}>
        <Html>{htmlFree(user)}</Html>
    </Page>
  </Document>)
};