import styled from "styled-components";
import Accordion from "@mui/material/Accordion";

const StyledCoursesSubcontainer = styled.div`
display: flex;
flex-direction: column;
margin-top: 4%;
width: 100%;
`;

const StyledAccordion = styled(Accordion)`
  background-color: transparent !important;
  width: 100% !important;
  box-shadow: none !important;
  border-bottom: 1px solid #d6d6d7 !important;
  border-radius: 0px !important;
`;

const StyledSubtitleCourses = styled.h2`
font-weight: 700;
font-size: 16px;
color: #393939;
margin: 8px 5px;
`;

const StyledImage = styled.img`
  width: 55px;
  height: 55px;
  margin-right: 20px;
  background-color: white;
  border-radius: 50%;
`;


export { 
  StyledCoursesSubcontainer,
  StyledSubtitleCourses,
  StyledAccordion,
  StyledImage,
};