import React, {useEffect, useState} from 'react';
import './CvFormClosed.css'
import Sidebar from "../../components/Sidebar/Sidebar";
import Navbar from "../../components/Navbar/Navbar";
import {useSelector} from "react-redux";
import Creatable from "react-select/creatable";
import Select from "react-select";
import axios from "axios";
import {
  handleFormChange,
  handleChangeLanguage,
  handleChangeNivel,
  formatterPeso, preset, handleFormSubChange, handleFormSubChangePlace, handleFormSubChangeSocial
} from './formUtils'
import {useParams} from "react-router";
import {toast} from "react-toastify";


//TODO: CERRAR EL INPUT DE UBICACION POR UN SELECT

// http://localhost:3001/consultor/newcv
// http://localhost:3001/company/create/63a4a2b799a63d6c7c48b9c6
const FormularioCvCerrado = () => {
  //eslint-disable-next-line
  const {lang, user} = useSelector(state => state);
  //eslint-disable-next-line
  const {id} = useParams();
  const [editable] = useState(false)
  const [profesions, setProfesiones] = useState([])
  //eslint-disable-next-line
  const [isSubmit, setisSubmit] = useState(false);
  const [divisa, setDivisa] = useState('');
  const [montoMin, setMontoMin] = useState('');
  const [certificados, setCertificados] = useState([
    {certificado: ""},
  ])
  const [educacion, setEducacion] = useState([
    {educacion0: {institucion: "", titulo: "", fecha_inicio: "", fecha_fin: "", tipo_titulo: ""}}
  ])

  const [experiencia, setExperiencia] = useState([
    {experiencia0: {empresa: '', cargo: '', fecha_inicio: '', fecha_fin: '', ubicacion: ''}}
  ])

  const addExperience = (e) => {
    e.preventDefault()
    const newExperience = [...experiencia]
    newExperience.push({
      [`experiencia${newExperience.length}`]: {
        empresa: '',
        cargo: '',
        fecha_inicio: '',
        fecha_fin: '',
        ubicacion: ''
      }
    })
    setExperiencia(newExperience)
    return console.log('123', experiencia)
  }
  const onchangeExperience = (e, index) => {
    e.preventDefault()
    let nuevoExperiencia = experiencia.map((item, i) => {
      if (i === index) {
        return {
          [`experiencia${i}`]: {
            ...item[`experiencia${i}`],
            [e.target.name]: e.target.value
          }
        }
      }
      return item
    })
    setExperiencia(nuevoExperiencia)
  }

  const addEducation = (e) => {
    e.preventDefault()
    const newEducation = [...educacion]
    newEducation.push({
      [`educacion${newEducation.length}`]: {
        institucion: "",
        titulo: "1",
        fecha_inicio: "",
        fecha_fin: "",
        tipo_titulo: ""
      }
    })
    setEducacion(newEducation)
    return console.log('123', educacion)
  }

  const onchangeEducation = (e, index) => {
    e.preventDefault()
    let nuevoEducacion = educacion.map((item, i) => {
      if (i === index) {
        return {
          [`educacion${i}`]: {
            ...item[`educacion${i}`],
            [e.target.name]: e.target.value
          }
        }
      }
      return item
    })
    setEducacion(nuevoEducacion)
  }


  const [form, setForm] = useState({
    modalidad: "",
    tipo_jornada: "",
    salario_deseado: "",
    experiencia_meses: "",
    descripcion: "",
    beneficios: [],
    departamento: "",
    municipio: "",
    carrera: "",
    nivel_tecnico_profesional: "",
    habilidades_blandas: [],
    habilidades_tecnicas: [],
    idiomas: [],
    motivacion: "aquí va el resultado del test de motivación",
    objetivos: [],
    profesional_info: {
      nombre: "",
      genero: "",
      fecha_nacimiento: "",
      email: user.email,
      phone: user.phone,
      redes_sociales: {
        facebook: "",
        instagram: "",
        linkedin: "",
      },
      lugar_nacimiento: {
        pais: "",
        departamento: "",
        municipio: ""
      },
      residencia_actual: "",
      educacion: {
        certificaciones: certificados,
        estudios: educacion
      },
      experiencia: experiencia
    }
  });

  // useEffect(() => {
  //   if (id === 'new') {
  //     setEditable(false)
  //   } else {
  //     setEditable(true)
  //     axios.get(`https://findally-backend.herokuapp.com/api/Project/data/${id}`)
  //       .then(res => {
  //         if (form) {
  //           setForm(res?.data?.data)
  //           const parseo = res?.data?.data?.salario_deseado?.split(' ')
  //           console.log(parseo)
  //           setDivisa(parseo[0])
  //           return setMontoMin(Number(parseo[1].replace(/\./g, '')))
  //         }
  //       })
  //       .catch(err => {
  //         console.log(err)
  //         return toast.error(lang === 'es' ? 'No se puede conseguir la información de la oportunidad, intentalo mas tarde' : 'Can\'t get the opportunity information, please try again later', preset)
  //       })
  //   }
  //   //eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [id])

  useEffect(() => {
    axios.get('https://findally-backend.herokuapp.com/profesions')
      .then(res => {
        setProfesiones(res?.data)
      })
  }, [])

  const monedas = [
    {value: 'COP', label: 'COP'},
    {value: 'USD', label: 'USD'}
  ]

  const habilidades_blandas = [
    {value: 'Capacidad de respuesta', label: 'Capacidad de respuesta'},
    {value: 'Adaptabilidad', label: 'Adaptabilidad'},
    {value: 'Atención a los detalles', label: 'Atención a los detalles'},
    {value: 'Colaboración', label: 'Colaboración'},
    {value: 'Habilidad para las comunicaciones', label: 'Habilidad para las comunicaciones'},
    {value: 'Creatividad', label: 'Creatividad'},
    {value: 'Pensamiento crítico', label: 'Pensamiento crítico'},
    {value: 'Inteligencia emocional', label: 'Inteligencia emocional'},
    {value: 'Empatía', label: 'Empatía'},
    {value: 'Flexibilidad', label: 'Flexibilidad'},
    {value: 'Innovación', label: 'Innovación'},
    {value: 'Liderazgo', label: 'Liderazgo'},
    {value: 'Organización', label: 'Organización'},
    {value: 'Paciencia', label: 'Paciencia'},
    {value: 'Habilidades para socializar', label: 'Habilidades para socializar'},
    {value: 'Resolución de problemas', label: 'Resolución de problemas'},
    {value: 'Responsabilidad', label: 'Responsabilidad'},
    {value: 'Autocritica', label: 'Autocritica'},
    {value: 'Pensamiento estratégico', label: 'Pensamiento estratégico'},
    {value: 'Trabajo en equipo', label: 'Trabajo en equipo'},
    {value: 'Gestión del tiempo', label: 'Gestión del tiempo'},
    {value: 'Ética laboral', label: 'Ética laboral'},
  ]
  const softSkills = [
    {value: 'Response capacity', label: 'Response capacity'},
    {value: 'Adaptability', label: 'Adaptability'},
    {value: 'Attention to detail', label: 'Attention to detail'},
    {value: 'Collaboration', label: 'Collaboration'},
    {value: 'Communication skills', label: 'Communication skills'},
    {value: 'Creativity', label: 'Creativity'},
    {value: 'Critical thinking', label: 'Critical thinking'},
    {value: 'Emotional intelligence', label: 'Emotional intelligence'},
    {value: 'Empathy', label: 'Empathy'},
    {value: 'Flexibility', label: 'Flexibility'},
    {value: 'Innovation', label: 'Innovation'},
    {value: 'Leadership', label: 'Leadership'},
    {value: 'Organization', label: 'Organization'},
    {value: 'Patience', label: 'Patience'},
    {value: 'Social skills', label: 'Social skills'},
    {value: 'Problem solving', label: 'Problem solving'},
    {value: 'Responsibility', label: 'Responsibility'},
    {value: 'Self-criticism', label: 'Self-criticism'},
    {value: 'Strategic thinking', label: 'Strategic thinking'},
    {value: 'Teamwork', label: 'Teamwork'},
    {value: 'Time management', label: 'Time management'},
    {value: 'Work ethics', label: 'Work ethics'},
  ]

  const jornadas = [
    {value: 'Tiempo completo', label: 'Tiempo completo'},
    {value: 'Tiempo parcial', label: 'Tiempo parcial'},
    {value: 'Por horas', label: 'Por horas'},
  ]

  const schedules = [
    {value: 'Full time', label: 'Full time'},
    {value: 'Part time', label: 'Part time'},
    {value: 'By hours', label: 'By hours'},
  ]


  const beneficios = [
    {value: 'Salud', label: 'Salud'},
    {value: 'Comisión / bono', label: 'Comisión / bono'},
    {value: 'Caja de compensación', label: 'Caja de compensación'},
    {value: 'Seguro de vida', label: 'Seguro de vida'},
    {value: 'Seguro de accidentes', label: 'Seguro de accidentes'},
    {value: 'Seguro de desempleo', label: 'Seguro de desempleo'},
    {value: 'Otro', label: 'Otro'},
  ]
  const benefits = [
    {value: 'Health', label: 'Health'},
    {value: 'Commission / bond', label: 'Commission / bond'},
    {value: 'Compensation box', label: 'Compensation box'},
    {value: 'Life insurance', label: 'Life insurance'},
    {value: 'Accident insurance', label: 'Accident insurance'},
    {value: 'Unemployment insurance', label: 'Unemployment insurance'},
    {value: 'Other', label: 'Other'},
  ]

  //experiencia laboral detallada dinam input -

  const handleSubmit = (e, form, setForm, lang, user) => {
    e.preventDefault()
    if (lang === 'es') {
      if (form.modalidad === '' || form.tipo_jornada === '' || form.salario_deseado === '' || form.experiencia_meses === '' || form.descripcion === '' || form.beneficios === '' || form.departamento === '' || form.municipio === '' || form.carrera === '' || form.nivel_tecnico_profesional === '' || form.habilidades_blandas === '' || form.habilidades_tecnicas === '' || form.idiomas === '' || form.motivacion === '' || form.objetivos === '') {
        if (lang === 'es') {
          toast.error('Por favor completa todos los campos', preset)
        } else {
          toast.error('Please fill in all fields', preset)
        }
      } else {
        setForm({
          ...form,
          carrera: form.carrera[0]
        })
        console.log(form)
        axios.post(`https://findally-backend.herokuapp.com/api/userForm/${user.id}`, form)
          .then(res => {
            console.log(res)
            toast.success('Felicitaciones, diste un paso más en tu carrera profesional', preset)
            return window.location.reload()
          })
          .catch(err => {
            console.log(err)
            toast.error('Error al enviar el formulario', preset)
          })
      }
    }
  }

  return (
    <div>
      <Sidebar location={'unilever home'}/>
      <div
        className="BodyWeb"
        style={{
          padding: "0rem 2rem",
          position: "absolute",
          right: "0",
        }}
      >
        <Navbar locate={'home'}/>
        <div style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
        }}>
          <div>
            <div className={'card-grupo-preguntas2'}>
              <div className={'new-empresa-home-banner'}>
                <h1>{lang === 'es' ? '¡Estas a un paso de encontrar al EMPLEO ideal!' : 'You are one step away from finding the ideal JOB!'}</h1>
              </div>
              <div style={{padding: '10px 20px'}}>
                <h2
                  className={'banner-crear-oportunidad-st'}>{lang === 'es' ? 'Por favor, lee y responde el siguiente formulario' : 'Please read and answer the next form'}</h2>
                <h3 className={'Obligatorio'}>{lang === 'es' ? '* Campos obligatorios' : '* Required Fields'}</h3>
              </div>
            </div>
            <hr/>


            <div className={'card-grupo-preguntas'}>
              <h3 className='title-crear-oportunidad'>
                {lang === 'es' ? 'Nombre' : 'Name'}
                <span className={'Obligatorio'}> *</span>
              </h3>
              <input
                className={'input-crear-oportunidad'}
                name={'nombre'}
                value={form.profesional_info.nombre}
                onChange={(e) => handleFormSubChange(e, setForm, form)}
              />
              {/*<h3 className='title-crear-oportunidad'>*/}
              {/*  {lang === 'es' ? 'Sexo' : 'Name'}*/}
              {/*  <span className={'Obligatorio'}> *</span>*/}
              {/*</h3>*/}
              {/*<Creatable*/}
              {/*  onChange={}*/}
              {/*/>*/}
              <h3 className='title-crear-oportunidad'>
                {lang === 'es' ? 'Fecha de nacimiento' : 'Name'}
                <span className={'Obligatorio'}> *</span>
              </h3>
              <input type="date" className={'input-crear-oportunidad'} style={{width: '100%'}}
                     name={'fecha_nacimiento'}
                     value={form.profesional_info.fecha_nacimiento}
                     onChange={(e) => handleFormSubChange(e, setForm, form)}
              />

              < h3 className='title-crear-oportunidad'>
                {lang === 'es' ? 'País de nacimiento' : 'Name'}
                <span className={'Obligatorio'}> *</span>
              </h3>
              <input
                className={'input-crear-oportunidad'}
                name={'pais'}
                value={form.profesional_info.lugar_nacimiento.pais}
                onChange={(e) => handleFormSubChangePlace(e, setForm, form)}
              />

              <h3 className='title-crear-oportunidad'>
                {lang === 'es' ? 'Departamento de nacimiento' : 'Name'}
                <span className={'Obligatorio'}> *</span>
              </h3>
              <input
                className={'input-crear-oportunidad'}
                name={'departamento'}
                value={form.profesional_info.lugar_nacimiento.departamento}
                onChange={(e) => handleFormSubChangePlace(e, setForm, form)}
              />

              <h3 className='title-crear-oportunidad'>
                {lang === 'es' ? 'Municipio de nacimiento' : 'Name'}
                <span className={'Obligatorio'}> *</span>
              </h3>
              <input
                className={'input-crear-oportunidad'}
                name={'municipio'}
                value={form.profesional_info.lugar_nacimiento.municipio}
                onChange={(e) => handleFormSubChangePlace(e, setForm, form)}
              />
              <hr/>

              <h3 className='title-crear-oportunidad'>
                {lang === 'es' ? 'País actual' : 'Name'}
                <span className={'Obligatorio'}> *</span>
              </h3>
              <input
                className={'input-crear-oportunidad'}
                name={'residencia_actual'}
                value={form.profesional_info.residencia_actual}
                onChange={(e) => handleFormSubChange(e, setForm, form)}
              />
              <h3 className='title-crear-oportunidad'>
                {lang === 'es' ? 'Departamenteo' : 'Enter the department where the opportunity will be registered'}
                <span className={'Obligatorio'}> *</span></h3>
              <input
                className={'input-crear-oportunidad'}
                name={'departamento'}
                value={form.departamento}
                onChange={(e) => handleFormChange(e, setForm, form)}
                placeholder={'Ej: Valle del Cauca'}/>

              <h3 className='title-crear-oportunidad'>
                {lang === 'es' ? 'Municipio actual' : 'Enter the municipality where the opportunity will be registered'}
                <span className={'Obligatorio'}> *</span></h3>
              <input
                className={'input-crear-oportunidad'}
                name={'municipio'}
                value={form.municipio}
                onChange={(e) => handleFormChange(e, setForm, form)}
                placeholder={'Ej: Cali'}/>
              <hr/>

              <h3 className='title-crear-oportunidad'>
                Linkedin
                <span className={'Obligatorio'}> *</span>
              </h3>
              <input
                className={'input-crear-oportunidad'}
                name={'linkedin'}
                value={form.profesional_info.redes_sociales.linkedin}
                onChange={(e) => handleFormSubChangeSocial(e, setForm, form)}
              />
              <h3 className='title-crear-oportunidad'>
                Facebook
                <span className={'Obligatorio'}> *</span>
              </h3>
              <input
                className={'input-crear-oportunidad'}
                name={'facebook'}
                value={form.profesional_info.redes_sociales.facebook}
                onChange={(e) => handleFormSubChangeSocial(e, setForm, form)}
              />
              <h3 className='title-crear-oportunidad'>
                Instagram
                <span className={'Obligatorio'}> *</span>
              </h3>
              <input
                className={'input-crear-oportunidad'}
                name={'instagram'}
                value={form.profesional_info.redes_sociales.instagram}
                onChange={(e) => handleFormSubChangeSocial(e, setForm, form)}
              />

            </div>
            <hr/>


            {/*tipo de servicio*/}
            <div className={'card-grupo-preguntas'}>

              <h3 className='title-crear-oportunidad'>
                {lang === 'es' ? '¿Qué modalidad de trabajo prefieres?' : 'What type of work do you prefer?'}
                <span className={'Obligatorio'}> *</span>
              </h3>
              <div style={{marginBottom: '25px'}}>
                <label className="control control-radio">
                  {lang === 'es' ? 'Presencial' : 'On-site'}
                  <input
                    type="radio"
                    name="modalidad"
                    value={'Presencial'}
                    checked={form?.modalidad === 'Presencial'}
                    onChange={(e) => handleFormChange(e, setForm, form)}
                  />
                  <div className="control_indicator"></div>
                </label>
                <label className="control control-radio">
                  {lang === 'es' ? 'Remoto' : 'Remote'}
                  <input
                    type="radio"
                    name="modalidad"
                    value={'Remoto'}
                    checked={form?.modalidad === 'Remoto'}
                    onChange={(e) => handleFormChange(e, setForm, form)}
                  />
                  <div className="control_indicator"></div>
                </label>
                <label className="control control-radio">
                  {lang === 'es' ? 'Híbrida' : 'Hybrid'}
                  <input
                    type="radio"
                    name="modalidad"
                    value={'Híbrida'}
                    checked={form?.modalidad === 'Híbrida'}
                    onChange={(e) => handleFormChange(e, setForm, form)}
                  />
                  <div className="control_indicator"></div>
                </label>
              </div>

              <h3 className='title-crear-oportunidad'>
                {lang === 'es' ? '¿Tipo de jornada laboral?' : 'Type of working day?'}
                <span className={'Obligatorio'}> *</span>
              </h3>
              {
                form?.tipo_jornada &&
                editable && <Select
                  options={lang === 'es' ? jornadas : schedules}
                  placeholder={lang === 'es' ? 'Selecciona la modalidad' : 'Select the modality'}
                  isSearchable={true}
                  isClearable={true}
                  defaultValue={{value: form?.tipo_jornada, label: form?.tipo_jornada}}
                  name={'tipo_jornada'}
                  onChange={(e) => setForm({...form, tipo_jornada: e.value})}
                />}
              {!editable && <Select
                options={lang === 'es' ? jornadas : schedules}
                placeholder={lang === 'es' ? 'Selecciona la modalidad' : 'Select the modality'}
                isSearchable={true}
                isClearable={true}
                name={'tipo_jornada'}
                onChange={(e) => setForm({...form, tipo_jornada: e.value})}
              />}

              <h3 className='title-crear-oportunidad'>
                {lang === 'es' ? 'Salario base' : 'Base salary'}
                <span className={'Obligatorio'}></span></h3>
              {!editable && <Select
                options={monedas}
                placeholder={lang === 'es' ? 'Selecciona la divisa que quieres usar' : 'Select the currency you want to use'}
                isSearchable={true}
                isClearable={true}
                onChange={(e) => {
                  setDivisa(e.value)
                  setForm({
                    ...form,
                    salario_deseado: `${e.value} ${formatterPeso.format(montoMin)}`
                  })
                }}
              />
              }
              <input
                className={'input-crear-oportunidad'}
                type={'number'}
                min={0}
                placeholder={lang === 'es' ? 'Escriba el salario' : 'Write the salary'}
                name={'montoMin'}
                value={montoMin}
                onKeyPress={(e) => {
                  if (!/[0-9]/.test(e.key)) {
                    e.preventDefault();
                  }
                }}
                // onChange={(e) => setMontoMin(e.target.value)}
                onChange={(e) => {
                  if (!divisa) {
                    return toast.error(lang === 'es' ? 'Por favor selecciona una divisa' : 'Please select a currency', preset)
                  }
                  setMontoMin(e.target.value)
                  return setForm({
                    ...form,
                    salario_deseado: `${divisa} ${formatterPeso.format(e.target.value)}`
                  })
                }}
              />

              <h3 className='title-crear-oportunidad'>
                {lang === 'es' ? 'Ingrese su experiencia laboral en meses' : 'Enter your work experience in months'}
                <span className={'Obligatorio'}> *</span></h3>
              <input
                className={'input-crear-oportunidad'}
                name={'experiencia_meses'}
                value={form.experiencia_meses}
                onChange={(e) => handleFormChange(e, setForm, form)}
                placeholder={'Ej: 10'}/>

              <h3 className='title-crear-oportunidad'>
                {lang === 'es' ? 'Cuéntanos mas sobre ti' : 'Tell us more about yourself'}
                <span className={'Obligatorio'}> *</span></h3>
              <textarea
                className={'text-crear-oportunidad'}
                name={'descripcion'}
                value={form.descripcion}
                onChange={(e) => handleFormChange(e, setForm, form)}
              />

              <h3 className='title-crear-oportunidad'>
                {lang === 'es' ? 'Cuéntanos tus metas y objetivos' : 'Tell us your goals and objectives'}
                <span className={'Obligatorio'}> *</span></h3>

              <Creatable
                isMulti
                isDisabled={false}
                isSearchable={true}
                isClearable={true}
                placeholder={lang === 'es' ? 'Agrega tus metas y objetivos de a uno y pulsa enter' : 'Add your goals and objectives one by one and hit enter'}
                onChange={(e) => {
                  setForm({...form, objetivos: e === null ? [] : e.map(e => e.value)})
                }}
                formatCreateLabel={lang === 'es' ? (inputValue) => `Agregar "${inputValue}"` : (inputValue) => `Add "${inputValue}"`}
              />
            </div>
            <hr/>

            {/*data de la oportunidad*/}
            <div className={'card-grupo-preguntas'}>
              <h3 className='title-crear-oportunidad'>
                {lang === 'es' ? 'Detallar comisiones o beneficios de tu preferencia' : 'Detail commissions or benefits of your preference, if exist.'}
              </h3>
              {!editable && beneficios && <Creatable
                isMulti
                isDisabled={false}
                isSearchable={true}
                isClearable={true}
                options={lang === 'es' ? beneficios : benefits}
                placeholder={lang === 'es' ? 'Escriba los beneficios' : 'Write the benefits'}
                onChange={(e) => {
                  setForm({...form, beneficios: e === null ? [] : e.map(e => e.value)})
                }}
                formatCreateLabel={lang === 'es' ? (inputValue) => `Agregar "${inputValue}"` : (inputValue) => `Add "${inputValue}"`}
              />}
            </div>
            <hr/>

            {/*data empleado*/}
            <div className={'card-grupo-preguntas'}>
              <h3 className='title-crear-oportunidad'>
                {lang === 'es' ? 'Selecciona tu carrera  o área de conocimiento. ' : "Select your career or area of knowledge"}
                <span className={'Obligatorio'}> *</span></h3>
              <p className={'item-crear-oportunidad'}>
                {lang === 'es' ? 'Puedes seleccionar varias de la lista o crearlas' : "You can select several from the list or create them"}
              </p>
              <Creatable
                options={profesions.map((item) => {
                  return {value: item.name, label: item.name}
                })}
                isMulti
                isDisabled={false}
                placeholder={lang === 'es' ? 'Selecciona o escribe la profesión necesaria' : 'Select or write the necessary profession'}
                isSearchable={true}
                isClearable={true}
                isOptionDisabled={() => form.carrera.length >= 1}
                onChange={(e) => {
                  setForm({...form, carrera: e === null ? [] : e.map(e => e.value)})
                }}
                formatCreateLabel={lang === 'es' ? (inputValue) => `Agregar "${inputValue}"` : (inputValue) => `Add "${inputValue}"`}
              />

              <h3 className='title-crear-oportunidad'>
                {lang === 'es' ? 'Selecciona el nivel de tu carrera.' : "Select your career level."}
                <span className={'Obligatorio'}> *</span></h3>

              <div style={{marginBottom: '20px'}}>
                <label className="control control-radio">
                  {lang === 'es' ? 'Profesional' : 'Professional'}
                  <input
                    type="checkbox"
                    name="nivel_tecnico_profesional"
                    value={lang === 'es' ? 'Profesional' : 'Professional'}
                    checked={form?.nivel_tecnico_profesional?.includes(lang === 'es' ? 'Profesional' : 'Professional')}
                    onChange={(e) => handleChangeNivel(e, form, setForm)}
                  />
                  <div className="control_indicator"></div>
                </label>
                <label className="control control-radio">
                  {lang === "es" ? "Técnico/tecnólogo" : "Technical"}
                  <input
                    type="checkbox"
                    name="nivel_tecnico_profesional"
                    value={lang === "es" ? "Técnico/tecnólogo" : "Technical"}
                    checked={form?.nivel_tecnico_profesional?.includes(lang === "es" ? "Técnico/tecnólogo" : "Technical")}
                    onChange={(e) => handleChangeNivel(e, form, setForm)}
                  />
                  <div className="control_indicator"></div>
                </label>
              </div>

              <h3 className='title-crear-oportunidad'>
                {lang === 'es' ? 'Selecciona las habilidades blandas que más te identifican.' : "Select the soft skills that most identify you."}
                <span className={'Obligatorio'}> *</span></h3>
              <p className={'item-crear-oportunidad'}>
                {lang === 'es' ? 'Puedes seleccionar varias de la lista' : "You can select several from the list"}
              </p>
              {!editable && <Creatable
                isMulti
                options={lang === 'es' ? habilidades_blandas : softSkills}
                placeholder={lang === 'es' ? 'Selecciona o escribe las habilidades blandas' : 'Select or write soft skills'}
                isSearchable={true}
                isClearable={true}
                // isOptionDisabled={() => form.habilidades_blandas.length >= 5}
                formatCreateLabel={lang === 'es' ? (inputValue) => `Agregar "${inputValue}"` : (inputValue) => `Add "${inputValue}"`}
                onChange={e => setForm({...form, habilidades_blandas: e === null ? [] : e.map(item => item.value)})}
              />}

              <h3 className='title-crear-oportunidad'>
                {lang === 'es' ? 'Selecciona las habilidades técnicas que más te identifican.' : "Select the technical skills that most identify you."}
                <span className={'Obligatorio'}> *</span></h3>
              <p className={'item-crear-oportunidad'}>
                {lang === 'es' ? 'Puedes seleccionar varias de la lista o crearlas' : "You can select several from the list or create them"}
              </p>

              {!editable &&
                <Creatable
                  isMulti
                  options={profesions.map((item) => {
                    return {value: item.name, label: item.name}
                  })}
                  placeholder={lang === 'es' ? 'Selecciona o escribe las habilidades técnicas' : 'Select or write technical skills'}
                  isSearchable={true}
                  isClearable={true}
                  // isOptionDisabled={() => form.habilidades_tecnicas.length >= 5}
                  formatCreateLabel={lang === 'es' ? (inputValue) => `Agregar "${inputValue}"` : (inputValue) => `Add "${inputValue}"`}
                  onChange={e => setForm({...form, habilidades_tecnicas: e === null ? [] : e.map(item => item.value)})}
                />
              }

              <h3 className='title-crear-oportunidad'>
                {lang === 'es' ? 'Idioma (selección múltiple)' : 'Language (multiple choice)'}
                <span className={'Obligatorio'}> *</span></h3>

              <div style={{marginBottom: '20px'}}>
                <label className="control control-radio">
                  {lang === "es" ? "Inglés" : "English"}
                  <input
                    type="checkbox"
                    name="Lenguaje1"
                    value={lang === "es" ? "Inglés" : "English"}
                    checked={form?.idiomas?.includes(lang === "es" ? "Inglés" : "English")}
                    onChange={(e) => handleChangeLanguage(e, form, setForm)}
                  />
                  <div className="control_indicator"></div>
                </label>

                <label className="control control-radio">
                  {lang === 'es' ? 'Español' : 'Spanish'}
                  <input
                    type="checkbox"
                    name="Lenguaje2"
                    value={lang === 'es' ? 'Español' : 'Spanish'}
                    checked={form?.idiomas?.includes(lang === 'es' ? 'Español' : 'Spanish')}
                    onChange={(e) => handleChangeLanguage(e, form, setForm)}
                  />
                  <div className="control_indicator"></div>
                </label>
              </div>
            </div>
            <hr/>


            <div className={'card-grupo-preguntas'}>


              <h3 className='title-crear-oportunidad'>
                {lang === 'es' ? 'Certificaciones de Formación Oficiales' : "Select your career level."}
                <span className={'Obligatorio'}> *</span>
              </h3>
              <button
                style={{
                  width: '30%',
                  height: '40px',
                  backgroundColor: '#27165b',
                  color: 'white',
                  border: 'none',
                  borderRadius: '5px',
                  marginBottom: '10px',
                  marginTop: '10px'
                }}
                onClick={(e) => {
                  e.preventDefault()
                  let indexCert = certificados.length
                  let nuevoCertificado = {}
                  nuevoCertificado[`certificado${indexCert}`] = ''
                  console.log(certificados)
                  return setCertificados([...certificados, nuevoCertificado])
                }}>agregar certificado
              </button>
              <div style={{
                display: 'flex',
                flexDirection: 'column',

              }}>
                {
                  certificados.length > 0 && certificados.map((item, index) => {
                    return (
                      <div key={index} style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        marginBottom: '10px'
                      }}>
                        <input

                          className={'input-crear-oportunidad'}
                          type="text"
                          name={`certificado${index}`}
                          placeholder={lang === 'es' ? 'Certificado' : 'Certificate'}
                          value={form[`certificado${index}`]}
                          onChange={(e) => {
                            let nuevoCertificados = certificados.map((item, i) => {
                              if (i === index) {
                                return {[e.target.name]: e.target.value}
                              }
                              return item
                            })
                            setCertificados(nuevoCertificados)
                          }}
                        />
                        <button
                          style={{
                            width: '40px',
                            height: '40px',
                            backgroundColor: '#27165b',
                            color: 'white',
                            border: 'none',
                            borderRadius: '5px',
                            marginBottom: '10px',
                            marginTop: '10px'
                          }}
                          onClick={(e) => {
                            e.preventDefault()
                            if (certificados.length === 1) {
                              return
                            }
                            let nuevoCertificados = certificados.filter((item, i) => i !== index)
                            return setCertificados(nuevoCertificados)
                          }
                          }>X
                        </button>
                      </div>
                    )
                  })
                }
              </div>


              <h3 className='title-crear-oportunidad'>
                {lang === 'es' ? 'Educación' : "Select your career level."}
                <span className={'Obligatorio'}> *</span>
              </h3>
              <button
                style={{
                  width: '30%',
                  height: '40px',
                  backgroundColor: '#27165b',
                  color: 'white',
                  border: 'none',
                  borderRadius: '5px',
                  marginBottom: '10px',
                  marginTop: '10px'
                }}
                onClick={(e) => addEducation(e)}
              >
                agregar educacion
              </button>
              {
                educacion && educacion?.length >= 0 && educacion.map((item, index) => {
                    return (
                      <div key={index}>
                        <h3 style={{fontWeight: '400'}} className='title-crear-oportunidad'>
                          {lang === 'es' ? 'Institución' : "Select your career level."}
                        </h3>
                        <input
                          className={'input-crear-oportunidad'}
                          type="text"
                          name={`institucion`}
                          placeholder={lang === 'es' ? 'Institución' : 'Education'}
                          value={item[`educacion${index}`].institucion}
                          onChange={(e) => onchangeEducation(e, index)}
                        />
                        <h3 style={{fontWeight: '400'}} className='title-crear-oportunidad'>
                          {lang === 'es' ? 'Titulo' : "Select your career level."}
                        </h3>
                        <input
                          className={'input-crear-oportunidad'}
                          type="text"
                          name={`titulo`}
                          placeholder={lang === 'es' ? 'Titulo' : 'Education'}
                          value={item[`educacion${index}`].titulo}
                          onChange={(e) => onchangeEducation(e, index)}
                        />
                        <h3 style={{fontWeight: '400'}} className='title-crear-oportunidad'>
                          {lang === 'es' ? 'Fecha de inicio' : "Select your career level."}
                        </h3>
                        <input
                          className={'input-crear-oportunidad'}
                          type="date"
                          name={`fecha_inicio`}
                          placeholder={lang === 'es' ? 'Fecha Inicio' : 'Education'}
                          value={item[`educacion${index}`].fecha_inicio}
                          onChange={(e) => onchangeEducation(e, index)}
                        />
                        <h3 style={{fontWeight: '400'}} className='title-crear-oportunidad'>
                          {lang === 'es' ? 'Fecha de finalización' : "Select your career level."}
                        </h3>
                        <input
                          className={'input-crear-oportunidad'}
                          type="date"
                          name={`fecha_fin`}
                          placeholder={lang === 'es' ? 'Fecha Inicio' : 'Education'}
                          value={item[`educacion${index}`].fecha_fin}
                          onChange={(e) => onchangeEducation(e, index)}
                        />
                        <h3 style={{fontWeight: '400'}} className='title-crear-oportunidad'>
                          {lang === 'es' ? 'Tipo de titulo' : "Select your career level."}
                        </h3>
                        <input
                          className={'input-crear-oportunidad'}
                          type="text"
                          name={`tipo_titulo`}
                          placeholder={lang === 'es' ? 'Fecha Inicio' : 'Education'}
                          value={item[`educacion${index}`].tipo_titulo}
                          onChange={(e) => onchangeEducation(e, index)}
                        />
                        <hr/>
                      </div>
                    )
                  }
                )
              }

            </div>
            <hr/>

            <div className={'card-grupo-preguntas'}>

              <h3 className='title-crear-oportunidad'>
                {lang === 'es' ? 'Experiencia' : "Select your career level."}
                <span className={'Obligatorio'}> *</span>
              </h3>
              <button
                style={{
                  width: '30%',
                  height: '40px',
                  backgroundColor: '#27165b',
                  color: 'white',
                  border: 'none',
                  borderRadius: '5px',
                  marginBottom: '10px',
                  marginTop: '10px'
                }}
                onClick={(e) => addExperience(e)}
              >
                agregar experiencia
              </button>
              {
                experiencia && experiencia?.length >= 0 && experiencia.map((item, index) => {
                    return (
                      <div key={index}>
                        <h3 style={{fontWeight: '400'}} className='title-crear-oportunidad'>
                          {lang === 'es' ? 'Empresa' : "Select your career level."}
                        </h3>
                        <input
                          className={'input-crear-oportunidad'}
                          type="text"
                          name={`empresa`}
                          placeholder={lang === 'es' ? 'Empresa' : 'Education'}
                          value={item[`experiencia${index}`].empresa}
                          onChange={(e) => onchangeExperience(e, index)}
                        />
                        <h3 style={{fontWeight: '400'}} className='title-crear-oportunidad'>
                          {lang === 'es' ? 'Cargo' : "Select your career level."}
                        </h3>
                        <input
                          className={'input-crear-oportunidad'}
                          type="text"
                          name={`cargo`}
                          placeholder={lang === 'es' ? 'Cargo' : 'Education'}
                          value={item[`experiencia${index}`].cargo}
                          onChange={(e) => onchangeExperience(e, index)}
                        />
                        <h3 style={{fontWeight: '400'}} className='title-crear-oportunidad'>
                          {lang === 'es' ? 'Fecha de inicio' : "Select your career level."}
                        </h3>
                        <input
                          className={'input-crear-oportunidad'}
                          type="date"
                          name={`fecha_inicio`}
                          placeholder={lang === 'es' ? 'Fecha Inicio' : 'Education'}
                          value={item[`experiencia${index}`].fecha_inicio}
                          onChange={(e) => onchangeExperience(e, index)}
                        />
                        <h3 style={{fontWeight: '400'}} className='title-crear-oportunidad'>
                          {lang === 'es' ? 'Fecha de finalización' : "Select your career level."}
                        </h3>
                        <input
                          className={'input-crear-oportunidad'}
                          type="date"
                          name={`fecha_fin`}
                          placeholder={lang === 'es' ? 'Fecha Inicio' : 'Education'}
                          value={item[`experiencia${index}`].fecha_fin}
                          onChange={(e) => onchangeExperience(e, index)}
                        />
                        <h3 style={{fontWeight: '400'}} className='title-crear-oportunidad'>
                          {lang === 'es' ? 'Ubicación' : "Select your career level."}
                        </h3>
                        <input
                          className={'input-crear-oportunidad'}
                          type="text"
                          name={`ubicacion`}
                          placeholder={lang === 'es' ? 'Ubicación' : 'Education'}
                          value={item[`experiencia${index}`].ubicacion}
                          onChange={(e) => onchangeExperience(e, index)}
                        />
                        <hr/>
                      </div>
                    )
                  }
                )
              }
            </div>
            <hr/>

            <button
              onMouseEnter={() => {
                console.log('1', form.personal_info?.educacion)
                console.log('2', form.personal_info?.experiencia)
                setForm({
                  ...form,
                  profesional_info: {
                    ...form.profesional_info,
                    educacion: {
                      certificaciones: certificados,
                      estudios: educacion
                    },
                    experiencia: experiencia
                  }
                })
              }}
              onClick={(e) => {
                //hacer una promesa y si se cumple continuar
                setForm({
                  ...form,
                  profesional_info: {
                    ...form.profesional_info,
                    educacion: {
                      certificaciones: certificados,
                      estudios: educacion
                    },
                    experiencia: experiencia
                  }
                })

                console.log('1', form)
                return handleSubmit(e, form, setForm, lang, user)
              }}
              className={'submit-button-crear-oportunidad'} disabled={isSubmit}>
              {editable
                ? lang === 'es' ? 'Actualizar oportunidad' : 'Update opportunity'
                : lang === 'es' ? 'Cargar Cv' : 'Upload Cv'}
            </button>
          </div>
        </div>
      </div>
    </div>
  )
    ;
}

export default FormularioCvCerrado;