import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Title from './Title';
import en from "../../lang/en-US";
import es from "../../lang/es-COL"
import counterpart from "counterpart";

const useStyles = makeStyles({
    depositContext: {
      flex: 1,
    },
  });

export default function Registers({Total_users, Total_projects, Total_professionals, Total_companies}) {
    const classes = useStyles();
    counterpart.registerTranslations('en',en) 
      counterpart.registerTranslations('es',es)
      counterpart.setLocale(localStorage.getItem('langlocal'));
  
  return (
    <React.Fragment>
      <Title>REGISTROS TOTALES</Title>
      <Typography color="textSecondary" className={classes.depositContext}>
        Usuarios
      </Typography>
      <Typography component="p" variant="h4">
       {Total_users}
      </Typography>
      <Typography color="textSecondary" className={classes.depositContext}>
        Proyectos
      </Typography>
      <Typography component="p" variant="h4">
       {Total_projects}
      </Typography>
      <Typography color="textSecondary" className={classes.depositContext}>
        Profesionales
      </Typography>
      <Typography component="p" variant="h4">
        {Total_professionals}
      </Typography>
      <Typography color="textSecondary" className={classes.depositContext}>
        Empresas
      </Typography>
      <Typography component="p" variant="h4">
        {Total_companies}
      </Typography>
    </React.Fragment>
  );
}