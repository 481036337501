/* eslint-disable*/
import React, { useState, useEffect, useContext } from "react";
import { useSelector, useDispatch } from "react-redux";
import { changeLang, otpRegister } from "../../../../actions";
import { SchneiderContext } from "../../../context/SchneiderContext";
import Spinner from "react-bootstrap/Spinner";
import axios from "axios";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import {
  AuthGlobalContainer,
  AuthFormContainer,
  AuthButton,
  AuthButtonContainer,
  AuthButtonDisabled,
  AuthLogo,
  LanguageContainer,
  LanguageButton,
  AuthOtpSubTitle,
  AuthOtpContainer,
} from "../AuthStyles";

export default function ConfirmOtp() {
  const { schneider } = useContext(SchneiderContext);
  const dispatch: any = useDispatch();
  const router = useHistory();
  const userVerif = useSelector((state: any) => state.userToVerify);
  const [otp, setOtp] = useState(new Array(6).fill(""));
  const [localLang, setlocalLang] = useState(localStorage.getItem("langlocal"));
  const [cargando, setCargando] = useState(false);

  useEffect(() => {
    if (!userVerif.id) {
      if (localStorage.getItem("userToVerify")) {
        const user = JSON.parse(localStorage.getItem("userToVerify"));
        user.id && dispatch(otpRegister(user, localLang, true));
      }
    }
    /*userVerif.id && dispatch(otpRegister(userVerif, localLang))
    userVerif.id && window.localStorage.setItem('userToVerify', JSON.stringify(userVerif))*/
  }, [userVerif, dispatch]);

  const handleChange = (e: any, index: number) => {
    if (isNaN(e.value)) return false;
    setOtp([...otp.map((d, idx) => (idx === index ? e.value : d))]);
    if (e.nextSibling) e.nextSibling.focus();
  };
  const handleSubmit = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    setCargando(true);
    if (otp.join("").length !== 6) return false;
    axios
      .post("https://findally-backend.herokuapp.com/api/auth/verifyotp", {
        email: userVerif.email,
        otp: otp.join(""),
      })
      .then(() => {
        window.localStorage.removeItem("userToVerify");
        setCargando(false);
        router.push("/schneider/login");
      })
      .catch((e) => {
        setCargando(false);
        toast.error(e.response.data.message);
      });
  };

  const changeLanguages = (lang: string) => {
    dispatch(changeLang(lang));
    localStorage.setItem("langlocal", lang);
    setlocalLang(localStorage.getItem("langlocal"));
  };

  return (
    <>
      {schneider && schneider?.color && (
        <AuthGlobalContainer>
          <AuthFormContainer>
            <AuthLogo src={schneider.logo} alt="logo" />
            <LanguageContainer>
              <LanguageButton
                onClick={() => changeLanguages("es")}
                color={localLang === "es" ? schneider.color.primary : "#000000"}
                weight={localLang === "es" ? 700 : 300}
              >
                SP
              </LanguageButton>
              <LanguageButton
                onClick={() => changeLanguages("en")}
                color={localLang === "en" ? schneider.color.primary : "#000000"}
                weight={localLang === "en" ? 700 : 300}
              >
                EN
              </LanguageButton>
            </LanguageContainer>
            <div style={{ width: "100%" }}>
              <AuthOtpSubTitle>
                {localLang === "es"
                  ? "Te hemos enviado un correo, por favor introduce el código para verificarte."
                  : "We have sent you an email, please enter the code to verify you."}
              </AuthOtpSubTitle>
            </div>
            <AuthOtpContainer>
              {otp.map((d, idx) => {
                return (
                  <input
                    key={idx}
                    maxLength={1}
                    name="otp"
                    type="text"
                    value={d}
                    onChange={(e) => handleChange(e.target, idx)}
                    onFocus={(e) => e.target.select()}
                  />
                );
              })}
            </AuthOtpContainer>
            <AuthButtonContainer>
              {otp ? (
                <AuthButton
                  background={schneider.color.primary}
                  style={{ background: schneider.color.primary, color: "#fff" }}
                  onClick={(e) => handleSubmit(e)}
                >
                  {cargando ? (
                    <Spinner animation="border" size="sm" />
                  ) : localLang === "es" ? (
                    "Verificarse"
                  ) : (
                    "Verify"
                  )}
                </AuthButton>
              ) : (
                <AuthButtonDisabled>
                  {localLang === "es" ? "Verificarse" : "Verify"}
                </AuthButtonDisabled>
              )}
            </AuthButtonContainer>
          </AuthFormContainer>
        </AuthGlobalContainer>
      )}
    </>
  );
}
