import React from "react";

//styles
import "./styles/NotFound.css";

function NotFound() {
  return <div className="img-not-found"><img alt="notfound" src="https://image.flaticon.com/icons/png/512/580/580185.png"/></div>
}

export default NotFound;
