import React, { useState, useEffect } from "react";
import Select from "react-select";
interface Country {
  name: {
    common: string;
  };
  flags: {
    png: string;
  };
}

interface SelectOption {
  value: string;
  label: string;
}

const CountrySelector = ({
  lang,
  setForm,
}: {
  lang?: unknown;
  setForm: any;
}) => {
  const [countries, setCountries] = useState<Country[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchCountries = async () => {
      try {
        const response = await fetch("https://restcountries.com/v3.1/all");
        const data = await response.json();
        console.log(data);
        const countryOptions = data.map((country: Country) => ({
          value: country.name.common,
          label: country.name.common,
        }));
        const sortedCountries = countryOptions.sort(
          (a: SelectOption, b: SelectOption) => a.label.localeCompare(b.label)
        );
        setCountries(sortedCountries);
        setLoading(false);
      } catch (error) {
        console.error("Error al obtener la lista de países:", error);
      }
    };
    fetchCountries();
  }, []);

  return (
    <Select
      options={countries}
      placeholder={
        lang === "es"
          ? "Selecciona el país donde se registrará la oportunidad"
          : "Select the country where the opportunity will be registered"
      }
      isLoading={loading}
      isSearchable
      isClearable
      onChange={(selectedOption: any) =>
        setForm((prev: any) => ({
          ...prev,
          country: selectedOption === null ? "" : selectedOption.value,
        }))
      }
    />
  );
};

export default CountrySelector;
