import axios from 'axios'
import React, { useEffect, useState } from 'react'

const PorcentajeAplicante = ({ lang, user, oportunidad }) => {
  const [compatibility, setCompatibility] = useState(0)

  useEffect(() => {
    axios.get(`https://resume-matching-deploy.herokuapp.com/compatibility/${oportunidad._id}/${user.cv_file._id}/`)
      .then(res => {
        setCompatibility(res.data)
      }
      )
    //eslint-disable-next-line
  }, [oportunidad._id, user.cv_file._id])
  return (
    <>
      <h2 className={'prof-list-compat'}>
        {compatibility?.toFixed(0)}% {lang === 'es' ? 'Compatibilidad' : 'Compatibility'}
      </h2>
    </>
  )
}

export default PorcentajeAplicante