import React, { useEffect, useState } from "react";
import "./Styles/UserProjects.css";
import { connect } from "react-redux";
import { getVideos , setvideolist} from "../../actions/index";
import Header from "../../components/Header/Header";

import "./Styles/Education.css";
import VideoList from "../../components/educationPage/VideoList";
import en from "../../lang/en-US";
import es from "../../lang/es-COL"
import counterpart from "counterpart";
import Translate from "react-translate-component";


function Education(props) {

  const [filtro, setfiltro] = useState('')

  useEffect(() => {
    const getVideos = () => {
        props.getVideos();
    };
    // eslint-disable-next-line
    getVideos();
    // eslint-disable-next-line
  }, []);
  
  //const lang = useSelector(state => state.lang)
  
  
  
  const handleFilter =(e)=>{
    setfiltro(e.target.value) 
  }
  counterpart.registerTranslations('en',en) 
  counterpart.registerTranslations('es',es)  
  counterpart.setLocale(localStorage.getItem('langlocal'));
  
  let videos = props.videos
  return (
    <>
    {/*<select className="Header-lang" value={lang} onChange={(e)=>dispatch(changeLang(e.target.value))}>
        <option value="en">en-US</option>
        <option value="es">es-COL</option>
    </select>*/}
      <Header /> 
      <div className="education">
        <div className="education__container">
          <p className="education__container-tittle">{<Translate content="EducacionCoAlly" component="span"/>}</p>
          <div className='subtitle_box'><p className="education__container-text-subtittle">
          {<Translate content="Ademáshemosdiseñadoesteespacioconcontenidogratuitoeducativoparapotencializartusconocimientoscapacitarteeimpulsarteprofesionalmente" component="span"/>}
          </p></div>
          <div className="education__container-media">
            <p className="education__container-description">   {<Translate content="Paraaplicaralasofertasquetenemosparatiesnecesarioquetecapacitesviendolossiguientesvideos" component="span"/>}
       </p>
          <iframe className="education-iframe" src="https://www.youtube.com/embed/Rwyn8Dlh6BM" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" style={{width:'300px', height:'150px'}} allowFullScreen></iframe>
          <iframe className="education-iframe" src="https://www.youtube.com/embed/ShS713MoMFo" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" style={{width:'300px', height:'150px'}} allowFullScreen></iframe>
          <iframe className="education-iframe" src="https://www.youtube.com/embed/VLFT5x_zAgw" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" style={{width:'300px', height:'150px'}} allowFullScreen></iframe>
          </div>
        </div>
        <br></br>
        <br></br>
        <div className="education__media-container">
               <div className="education-filter">
            <div className="education__filter-group">
            <div className="education__filter-tittle"><p>{<Translate content="Categorias" component="span"/>}</p></div>
              <div className="education__filter-select">
                <input
                  className="education__filter-input radio"
                  type="radio"
                  value="Desarrollo personal"
                  name="categoriasFilter"
                  id="DesarrolloPersonal"
                  onChange={handleFilter}
                />
                <label className="label"  htmlFor="DesarrolloPersonal">{<Translate content="desarrollopro" component="span"/>}</label>
              </div>
              <div className="education__filter-select">
                <input
                  className="education__filter-input radio"
                  type="radio"
                  value="Desarrollo profesional"
                  name="categoriasFilter"
                  id="DesarrolloProfesional"
                  onChange={handleFilter}
                />
                <label className="label"  htmlFor="DesarrolloProfesional">
                {<Translate content="desarrolloper" component="span"/>}
                </label>
              </div>
              <div className="education__filter-select">
                <input
                  className="education__filter-input radio"
                  type="radio"
                  value="Actualidad"
                  name="categoriasFilter"
                  id="Actualidad"
                  onChange={handleFilter}
                />
                <label className="label" htmlFor="Actualidad">{<Translate content="actualidad" component="span"/>}</label>
              </div>
            </div>
            <div className="education__filter-group">
              <div className="education__filter-tittle"><p>{<Translate content="profe" component="span"/>}</p></div>
              <div className="education__filter-select">
                <input
                  className="education__filter-input radio"
                  type="radio"
                  value="Economía"
                  name="ProfesionFilter"
                  id="Economia"
                  onChange={handleFilter}
                />
                <label className="label" htmlFor="Economia">{<Translate content="econo" component="span"/>}</label>
              </div>
              <div className="education__filter-select">
                <input
                  className="education__filter-input radio"
                  type="radio"
                  value="administracion"
                  name="ProfesionFilter"
                  id="Administracion"
                  onChange={handleFilter}
                />
                <label className="label" htmlFor="Administracion">{<Translate content="admin" component="span"/>}</label>
              </div>
              <div className="education__filter-select">
                <input
                  className="education__filter-input radio"
                  type="radio"
                  value="negocios"
                  name="ProfesionFilter"
                  id="Negocios"
                  onChange={handleFilter}
                />
                <label className="label" htmlFor="Negocios">{<Translate content="negoci" component="span"/>}</label>
              </div>
              <div className="education__filter-select">
                <input
                  className="education__filter-input radio"
                  type="radio"
                  value="derecho"
                  name="ProfesionFilter"
                  id="Derecho"
                  onChange={handleFilter}
                />
                <label className="label" htmlFor="Derecho">{<Translate content="der" component="span"/>}</label>
              </div>

              <div className="education__filter-select">
                <input
                  className="education__filter-input radio"
                  type="radio"
                  name="ProfesionFilter"
                  id="IngenieriaIndustrial"
                  onChange={handleFilter}
                />
                <label className="label" htmlFor="IngenieriaIndustrial">
                {<Translate content="ingind" component="span"/>}
                </label>
              </div>

              <div className="education__filter-select">
                <input
                  className="education__filter-input radio"
                  type="radio"
                  name="ProfesionFilter"
                  id="Diseño"
                  onChange={handleFilter}
                />
                <label className="label" htmlFor="Diseño">{<Translate content="disñeo" component="span"/>}</label>
              </div>
              <div className="education__filter-select">
                <input
                  className="education__filter-input radio"
                  type="radio"
                  name="ProfesionFilter"
                  id="Sistemas"
                  onChange={handleFilter}
                />
                <label className="label" htmlFor="Sistemas">{<Translate content="infsis" component="span"/>}</label>
              </div>

              <div className="education__filter-select">
                <input
                  className="education__filter-input radio"
                  type="radio"
                  name="ProfesionFilter"
                  id="Data"
                  onChange={handleFilter}
                />
                <label className="label" htmlFor="Data">{<Translate content="dataanali" component="span"/>}</label>
              </div>
            </div>

            <div className="education__filter-group">
              <div className="education__filter-tittle"><p>{<Translate content="niv" component="span"/>}</p></div>
              <div className="education__filter-select">
                <input
                  className="education__filter-input radio"
                  type="radio"
                  name="level"
                  id="Principiante"
                  onChange={handleFilter}
                />
                <label className="label" htmlFor="Principiante">{<Translate content="princi" component="span"/>}</label>
              </div>
              <div className="education__filter-select">
                <input
                  className="education__filter-input radio"
                  type="radio"
                  name="level"
                  id="intermedio"
                  onChange={handleFilter}
                />
                <label className="label" htmlFor="intermedio">{<Translate content="inter" component="span"/>}</label>
              </div>

              <div className="education__filter-select">
                <input
                  className="education__filter-input radio"
                  type="radio"
                  name="level"
                  id="Avanzado"
                  onChange={handleFilter}
                />
                <label className="label" htmlFor="Avanzado">{<Translate content="avanz" component="span"/>}</label>
              </div>
            </div>
            <div className="education__filter-group">
              <div className="education__filter-tittle"><p >{<Translate content="idio" component="span"/>}</p></div>
              <div className="education__filter-select">
                <input
                  className="education__filter-input radio"
                  type="radio"
                  name="usertipe"
                  id="Ingles"
                  onChange={handleFilter}
                />
                <label className="label" htmlFor="Ingles">{<Translate content="Ingles" component="span"/>}</label>
              </div>
              <div className="education__filter-select">
                <input
                  className="education__filter-input radio"
                  type="radio"
                  name="usertipe"
                  id="Español"
                  onChange={handleFilter}
                />
                <label className="label" htmlFor="Español">{<Translate content="Español" component="span"/>}</label>
              </div>
            </div>
          </div>
        
          <div className="education__videos">
           <VideoList video={videos} filter={filtro}/>
          </div>
        </div>
      </div>
    </>
  );
}

function mapStateToProps(state) {
  return {
   videos:state.videos
  };
}
const mapDispatchToProps = {
  getVideos,
  setvideolist,
};
export default connect(mapStateToProps, mapDispatchToProps)(Education);
