import axios from "axios";
import {
  ProfessionalProfileResponse,
  CompanyProfileResponse,
} from "./responseInterface/professionalProfileFetchResponse";

export const fetchProfessionalProfile = async (
  userId: string
): Promise<ProfessionalProfileResponse | undefined> => {
  try {
    const response = await axios.get<ProfessionalProfileResponse>(
      `https://findally-backend.herokuapp.com/user/CVprofile/${userId}`
    );
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const fetchCompanyForm = async (
  userId: string
): Promise<CompanyProfileResponse | undefined> => {
  try {
    const response = await axios.get<CompanyProfileResponse>(
      `https://findally-backend.herokuapp.com/api/user/Companyform/${userId}`
    );
    return response.data;
  } catch (error) {
    console.error(error);
  }
};
