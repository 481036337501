import { createContext } from "react";
import { SchneiderContextProps } from "./interfaces/schneiderInterface";

export const SchneiderContext = createContext<SchneiderContextProps>({
  schneider: {
    name: "",
    address: "",
    city: "",
    country: "",
    color: {
      primary: "",
      secondary: "",
      tertiary: "",
      quaternary: "",
    },
    oportunidades: [],
    usuarios: [],
    logo: "",
    route: "",
  },
});
