import React, {useContext, useState} from "react";
import PlaceOutlinedIcon from '@mui/icons-material/PlaceOutlined';
import './NewCardOportunidad.css';
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import {UniversityContext} from "../../context/UniversitiesContext";

export default function OportunidadCardGrid({ imagen, lenguaje, id, dir, salario, titulo, titulo2, descripcion, ciudad, profesionales, status, modalidadDeTrabajo , porcentaje }) {
    const {university} = useContext(UniversityContext);
    const lang = useSelector((state) => state.lang);
    const [isHovered, setIsHovered] = useState(false)

    const styles = {
        button : {
            backgroundColor: 'transparent',
            color: university && university.color && university.color.quaternary ? university.color.quaternary : university.color.secondary,
            border: university && university.color && '0.5px solid ' + university.color.quaternary ? university.color.quaternary : university.color.secondary,
        },
        buttonHover : {
            backgroundColor: university && university.color && university.color.primary,
            border: university && university.color && '0.5px solid ' + university.color.primary,
            color: 'white'
        }
    }

    const formatterPeso = new Intl.NumberFormat('es-CO', {
        style: 'currency',
        currency: 'COP',
        minimumFractionDigits: 0
    })

    const modalidadDeTrabajoTraducir = (modalidad) => {
        if (modalidad === 'Presencial') {
            return lang === 'es' ? 'Presencial' : 'On-site'
        } else if (modalidad === 'Remoto') {
            return lang === 'es' ? 'Remoto' : 'Remote'
        } else if (modalidad === 'Híbrida') {
            return lang === 'es' ? 'Híbrida' : 'Hybrid'
        }
    }


    return (
        <div className={'NewCardOportunidad-Container-square'}>


            <div className={'NewCardOportunidad-Top-Container'}>
                <div style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '50%',
                }}>
                    <img className={'NewCardOportunidad-Image'} src={imagen ? imagen : ''} alt={imagen ? imagen : ''} />
                </div>
                <Link to={`/${university.route}/project/${id}`} style={{
                    width: '80%',
                    cursor: 'pointer'
                }}>
                    <div style={{
                        display: 'flex',
                        justifyContent: 'center',
                        flexDirection: 'column',
                        alignItems: 'center',
                        width: '100%'
                    }}>
                        <h1 style={{
                            fontSize: '20px',
                            fontWeight: '700',
                            color: '#393939',
                            textAlign: 'left',
                            width: '100%',
                            textTransform: 'capitalize',
                            marginRight: '20px',
                        }}>
                            {/* {empresa.length > 20 ? empresa.substring(0, 20) + '...' : empresa} */}
                            {titulo !== 'Unnamed Oportunity' ? titulo.length > 30 ? titulo.substring(0, 30).charAt(0).toUpperCase() + titulo.substring(0, 30).slice(1).toLowerCase() + '...' : titulo.charAt(0).toUpperCase() + titulo.slice(1).toLowerCase() : titulo2.length > 30 ? titulo2.substring(0, 30).charAt(0).toUpperCase() + titulo2.substring(0, 30).slice(1).toLowerCase() + '...' : titulo2.charAt(0).toUpperCase() + titulo2.slice(1).toLowerCase()}
                        </h1>

                    </div></Link>

            </div>
            {
                ciudad ? (
                    <div style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        width: '100%',
                        textAlign: 'center',
                        marginLeft: '25px'
                    }}>
                        <PlaceOutlinedIcon style={{ color: '#5D5D5D', fontSize: '20px' }} />
                        <div style={{
                            display: 'flex',
                            justifyContent: 'flex-start',
                            alignItems: 'center',
                            width: '90%',
                            height: '100%',
                            color: '#5D5D5D',
                            fontSize: '16px',
                            marginLeft: '15px',
                        }}>
                            {ciudad}
                        </div>
                    </div>
                ) : null
            }
               {
        porcentaje ?
          <div style={{
            color: '#F07130',
            marginTop: '10px',
            marginLeft: '25px'
          }}>
            {porcentaje.toFixed(2)}% {lang === 'es' ? 'Compatibilidad' : 'Compatibility'}
          </div>
          : null

      }
            <div className="NewCardOportunidad-mid-Container">
                {/* <div className="NewCardOportunidad-mid-Container-inner-1">
              <h1 style={{
                fontSize: '20px',
                fontWeight: '700',
                color: '#393939',
                width: '100%',
                margin: '0',
              }}>
                {titulo !== 'Unnamed Oportunity' ? titulo.length > 25 ? titulo.substring(0, 25).charAt(0).toUpperCase() + titulo.substring(0, 25).slice(1).toLowerCase() + '...' : titulo.charAt(0).toUpperCase() + titulo.slice(1).toLowerCase() : titulo2.length > 25 ? titulo2.substring(0, 25).charAt(0).toUpperCase() + titulo2.substring(0, 25).slice(1).toLowerCase() + '...' : titulo2.charAt(0).toUpperCase() + titulo2.slice(1).toLowerCase()}
              </h1>
            </div> */}
                <div className="NewCardOportunidad-mid-Container-inner-2">
                    <p style={{
                        fontSize: '14px',
                        fontWeight: '400',
                        color: '#5D5D5D',
                        textAlign: 'left',
                        margin: 0,
                        padding: 0,
                    }}>{lang === 'es' ? 'Profesionales con conocimiento en: ' : 'Professionals with knowledge in: '}</p>
                    {!descripcion || descripcion === 'NA' || descripcion === 'Otro' ? lenguaje === 'es' ? 'No especifica' : 'Not specified' : descripcion.length > 100 ? descripcion.substring(0, 100) + '...' : descripcion}
                </div>

                <div style={{ display: 'flex', width: '100%', alignItems: 'center' }}>
                    <h3 className={
                        // status === 'Finalizado' ? 'text-card-status-Close' :
                         'text-card-status-Open'}>
                        {
                        // status === "Finalizado" ? lang === 'es' ? 'Cerrada' : 'close' :
                         lang === 'es' ? 'Abierta' : 'Open'}
                    </h3>
                    <h3 className="stop-info2">
                        •
                    </h3>
                    <h3 className="text-card-info2">
                        {modalidadDeTrabajo ? modalidadDeTrabajoTraducir(modalidadDeTrabajo) : 'NA'}
                    </h3>
                    <h3 className="stop-info2">
                        •
                    </h3>
                    <h3 className="text-card-info2">
                        {profesionales} {lang === 'es' ? 'aplicantes' : 'applicants'}
                    </h3>
                </div>
                <div className="NewCardOportunidad-mid-Container-inner-3">
                    <div className="NewCardOportunidad-mid-Container-inner-3-1">
                        {
                            !salario.length ? lenguaje === 'es' ?
                                <p className={'CardOportunidadProfesional-Salario-Null'}>Salario no especificado</p> :
                                <p className={'CardOportunidadProfesional-Salario-Null'}>'Salary not
                                    specified</p> : typeof salario === 'number' ?
                                <p className={'CardOportunidadProfesional-Salario'}>`${formatterPeso.format(salario)}`</p> :
                                <p className={'CardOportunidadProfesional-Salario'}>
                                    {salario.split(' ')[0] === 'COP' || salario.split(' ')[0] === 'USD' ? salario.split(' ')[0] === 'Divisa' ? `$ ${salario.split(' ')[1]}` : salario.split(' ')[1] === '0' ? lang === 'es' ? 'Salario no especificado' : 'Salary not specified' : `${salario.split(' ')[0]} ${salario.split(' ')[1]}` : `${salario.split(' ')[1]}`}
                                    <span style={{
                                        fontSize: '14px',
                                        fontWeight: '700',
                                        color: '#C3CCFF'
                                    }}>
                    {lang === 'es' && salario.split(' ')[1] !== '0' ? '/mensual' : lang === 'en' && salario.split(' ')[1] !== '0' ? '/month' : ''}
                  </span>
                                </p>
                        }
                    </div>
                    <div className="NewCardOportunidad-mid-Container-inner-3-2">
                        <Link to={`/${university.route}/project/${id}`}>
                            <button
                                className={'CardOportunidadProfesional-Button-new'} style={
                                isHovered ? {...styles.button, ...styles.buttonHover} : {...styles.button}
                            } onMouseEnter={()=>setIsHovered(true)} onMouseLeave={()=>setIsHovered(false)}>
                                {lenguaje === 'es' ? 'Aplicar' : 'Apply'}
                            </button>
                        </Link>
                    </div>
                </div>
            </div>

        </div>
    )
}
