import React, { useEffect, useState } from 'react'
import DataTable from 'react-data-table-component';
import axios from 'axios'

const UserAplication = ({ university }) => {
  const [data, setData] = useState([])

  const columns = [
    {
      name: 'Nombre',
      selector: 'name',
      sortable: true,
    },
    {
      name: 'Email',
      selector: 'email',
      sortable: true,
    },
    {
      name: 'Oportunidad',
      selector: row => row.project.name,
      sortable: true,
    }
  ];

  useEffect(() => {
    axios.get(`https://findally-backend.herokuapp.com/api/university/appliedUsers?university=${university}`)
      .then(res => {
        setData(res.data.data)   
        console.log(res.data.data)     
      })
      .catch(err => console.log(err))
  }, [university])
  return (
    <>
      <DataTable
        columns={columns}
        data={data}
        pagination
        paginationPerPage={5}
        loading={data.length === 0}
        noHeader
      />
    </>
  )
}

export default UserAplication