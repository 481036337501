import React, { useEffect, useState } from "react";
import WestOutlinedIcon from '@mui/icons-material/WestOutlined';
import EastOutlinedIcon from '@mui/icons-material/EastOutlined';
import { useSwipeable } from "react-swipeable";
import sectorAgro from '../../assets/imagenes/sectorAgropecuario.png';
import sectorInd from '../../assets/imagenes/sectorIndustrial.png';
import sectorEdu from '../../assets/imagenes/sectorEducativo.png'
import './homeCarousel.css';
import { Link } from "react-router-dom";
export default function HomeCarousel({ children, title, lang }) {


  const sliderData = [
    (
      <div className="slide-home-inner">
        <div className="slide-home-inner-1" style={{
          backgroundImage: `url(${sectorAgro})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          borderRadius: '20px',
        }}>
          <div className="slide-home-inner-1-text">
            <h1 className="slide-home-inner-title">
              {
                lang === 'es' ? 'Formación en el sector agropecuario' : 'Training in the agricultural sector'
              }
            </h1>
          </div>
        </div>
        <div className="slide-home-inner-2">
          <div style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            alignItems: 'flex-start',
            textAlign: 'start'
          }}>
            <h1 style={{
              color: '#393939',
              fontSize: '20px',
              fontWeight: '500'
            }}>
              {
                lang === 'es' ? 'Sector agropecuario' : 'Agricultural sector'
              }
            </h1>
          </div>
          <div style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'flex-start',
            marginBottom: '14px',
          }}>
            <h1 style={{
              color: '#C5C5C5',
              fontSize: '16px',
              fontWeight: '500'
            }}>
              {
                lang === 'es' ? '11 Cursos' : '11 Courses'
              }
            </h1>
          </div>
          <div style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-end',
            alignItems: 'center',

            width: '100%',
          }}>
            <Link to='/consultor/formacion/agriculture'>
              <button style={{
                backgroundColor: '#202256',
                height: '33px',
                width: '100%',
                borderRadius: '5px',
                color: '#FFFFFF',
                textAlign: 'center',
              }}>
                {
                  lang === 'es' ? 'Continuar' : 'Continue'
                }
              </button>
            </Link>
          </div>
        </div>
      </div>
    ),
    (
      <div className="slide-home-inner">
        <div className="slide-home-inner-1" style={{
          backgroundImage: `url(${sectorInd})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          borderRadius: '20px',
        }}>
          <div className="slide-home-inner-1-text">
            <h1 className="slide-home-inner-title">
              {
                lang === 'es' ? 'Formación en el sector industrial' : 'Training in the industrial sector'
              }
            </h1>
          </div>
        </div>
        <div className="slide-home-inner-2">
          <div style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            alignItems: 'flex-start',
            textAlign: 'start'
          }}>
            <h1 style={{
              color: '#393939',
              fontSize: '20px',
              fontWeight: '500'
            }}>
              {
                lang === 'es' ? 'Sector industrial' : 'Industrial sector'
              }
            </h1>
          </div>
          <div style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'flex-start',
            marginBottom: '14px',
          }}>
            <h1 style={{
              color: '#C5C5C5',
              fontSize: '16px',
              fontWeight: '500'
            }}>
              {
                lang === 'es' ? '12 Cursos' : '12 Courses'
              }
            </h1>
          </div>
          <div style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-end',
            alignItems: 'center',

            width: '100%',
          }}>
            <Link to='/consultor/formacion/industrial'>
              <button style={{
                backgroundColor: '#202256',
                height: '33px',
                width: '100%',
                borderRadius: '5px',
                color: '#FFFFFF',
                textAlign: 'center',
              }}>
                {
                  lang === 'es' ? 'Continuar' : 'Continue'
                }
              </button>
            </Link>
          </div>
        </div>
      </div>
    ),
    (
      <div className="slide-home-inner">
        <div className="slide-home-inner-1" style={{
          backgroundImage: `url(${sectorEdu})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          borderRadius: '20px',
        }}>
          <div className="slide-home-inner-1-text">
            <h1 className="slide-home-inner-title">
              {
                lang === 'es' ? 'Formación en el sector educativo' : 'Training in the educational sector'
              }
            </h1>
          </div>
        </div>
        <div className="slide-home-inner-2">
          <div style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            alignItems: 'flex-start',
            textAlign: 'start'
          }}>
            <h1 style={{
              color: '#393939',
              fontSize: '20px',
              fontWeight: '500'
            }}>
              {
                lang === 'es' ? 'Sector educativo' : 'Sector educativo'
              }
            </h1>
          </div>
          <div style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'flex-start',
            marginBottom: '14px',
          }}>
            <h1 style={{
              color: '#C5C5C5',
              fontSize: '16px',
              fontWeight: '500'
            }}>
              {
                lang === 'es' ? '10 Cursos' : '10 Courses'
              }
            </h1>
          </div>
          <div style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-end',
            alignItems: 'center',

            width: '100%',
          }}>
            <Link to='/consultor/formacion/educational'>
            <button style={{
              backgroundColor: '#202256',
              height: '33px',
              width: '100%',
              borderRadius: '5px',
              color: '#FFFFFF',
              textAlign: 'center',
            }}>
              {
                lang === 'es' ? 'Continuar' : 'Continue'
              }
            </button>
            </Link>
          </div>
        </div>
      </div>
    ),
    // (
    //   <div className="slide-home-inner">
    //     <div className="slide-home-inner-1" style={{
    //       backgroundImage: `url(https://images.pexels.com/photos/3376790/pexels-photo-3376790.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1)`,
    //       backgroundSize: 'cover',
    //       backgroundPosition: 'center',
    //       borderRadius: '20px',
    //     }}>
    //       <div className="slide-home-inner-1-text">
    //         <h1 className="slide-home-inner-title">
    //           {
    //             lang === 'es' ? 'Certifícate en el sector salud' : 'Certify in the health sector'
    //           }
    //         </h1>
    //       </div>
    //     </div>
    //     <div className="slide-home-inner-2">
    //       <div style={{
    //         display: 'flex',
    //         flexDirection: 'column',
    //         justifyContent: 'flex-start',
    //         alignItems: 'flex-start',
    //         textAlign: 'start'
    //       }}>
    //         <h1 style={{
    //           color: '#393939',
    //           fontSize: '20px',
    //           fontWeight: '500'
    //         }}>
    //           {
    //             lang === 'es' ? 'Sector salud' : 'Health sector'
    //           }
    //         </h1>
    //       </div>
    //       <div style={{
    //         display: 'flex',
    //         flexDirection: 'column',
    //         justifyContent: 'center',
    //         alignItems: 'flex-start',
    //         marginBottom: '14px',
    //       }}>
    //         <h1 style={{
    //           color: '#C5C5C5',
    //           fontSize: '16px',
    //           fontWeight: '500'
    //         }}>
    //           {
    //             lang === 'es' ? '10 Cursos' : '10 Courses'
    //           }
    //         </h1>
    //       </div>
    //       <div style={{
    //         display: 'flex',
    //         flexDirection: 'column',
    //         justifyContent: 'flex-end',
    //         alignItems: 'center',

    //         width: '100%',
    //       }}>
    //         <button style={{
    //           backgroundColor: '#202256',
    //           height: '33px',
    //           width: '100%',
    //           borderRadius: '5px',
    //           color: '#FFFFFF',
    //           textAlign: 'center',
    //         }}>
    //           {
    //             lang === 'es' ? 'Continuar' : 'Continue'
    //           }
    //         </button>
    //       </div>
    //     </div>
    //   </div>
    // )
  ]

  const [current, setCurrent] = useState(0);
  const [paused, setPaused] = useState(false);
  const length = sliderData.length;

  useEffect(() => {
    const interval = setInterval(() => {
      if (!paused) {
        setCurrent(current === length - 1 ? 0 : current + 1);
      }
    }, 3000);
    return () => clearInterval(interval);
  }, [current, length, paused]);


  const nextSlide = () => {
    setCurrent(current === length - 1 ? 0 : current + 1);
  }

  const prevSlide = () => {
    setCurrent(current === 0 ? length - 1 : current - 1);
  }

  const handlers = useSwipeable({
    onSwipedLeft: () => nextSlide(),
    onSwipedRight: () => prevSlide(),
  });



  return (
    <div className="slider-home-container">
      <div
        {...handlers}
        className="slider-home"
        onMouseEnter={() => setPaused(true)}
        onMouseLeave={() => setPaused(false)}
      >
        {
          sliderData.map((slide, index) => {
            return (
              <div key={index} className={
                index === current ? 'slide-home active' : 'slide-home'
              }>
                {index === current && (
                  slide
                )}
              </div>
            )
          })
        }
      </div>
      <div className="slider-home-row">
        <WestOutlinedIcon className="slider-home-arrow" style={{ marginRight: '5px' }} onClick={prevSlide} />
        <EastOutlinedIcon className="slider-home-arrow" style={{ marginLeft: '5px' }} onClick={nextSlide} />
      </div>
    </div>
  )
}