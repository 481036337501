/* eslint-disable */
import React, {useState, useEffect} from 'react'
import img from "./footer.png"
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import LaptopIcon from '@mui/icons-material/Laptop';
import {logoutUser, changeview, getUserPlan} from "../../actions";
import {connect} from "react-redux";
import {Link} from "react-router-dom"
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
/* import CompanyNewCreateProject2 from "../../pages/Companies/CompanyNewCreateProject2.jsx" */
import "./Headerprofesional.css"
import homesvg from '../../assets/icons/home.svg'
import documentsvg from '../../assets/icons/document.svg'
// import SettingsIcon from '@mui/icons-material/Settings';
import {Circle} from "@mui/icons-material";
import en from "../../lang/en-US";
import es from "../../lang/es-COL"
import counterpart from "counterpart";
import Translate from "react-translate-component";
import {useSelector, useDispatch} from 'react-redux';
import axios from 'axios';
import Lock from '../../assets/icons/lock-alt.svg'
import PopUpForm from '../../pages/Companies/PopupCompany/PopUpForm';
import PopupCreator from '../../pages/Companies/PopupCompany/PopupCreator';
import configuracion from '../../assets/icons/configuracion.svg'
import CrearOportunidad from '../CrearOportunidad/CrearOportunidad';
import { toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const Headerprofesional = (props) => {
  const handleLogout = () => {
    localStorage.removeItem("user");
    localStorage.removeItem("token");
    props.logoutUser({}, "/");
  };
  const [open, setOpen] = useState(false);
  const [popupInstruction, setpopupInstruction] = useState(false)
  const [proyectoactivate, setproyectoactivate] = React.useState(false)
  const [block, setblock] = React.useState(false)
  const dispatch = useDispatch();
  const lang = useSelector(state => state.lang)
  const user = useSelector(state => state.user)


  useEffect(() => {

    dispatch(getUserPlan(user.id))
    axios.get("https://findally-backend.herokuapp.com/api/user/validatePremiumProps/" + user.id)
      .then(res => {
        console.log(res.data.numOfertasDisponibles)
        console.log(res.data)
        if (res.data.numOfertasDisponibles === "agotado") {
          setblock(true)
        }
      })
      .catch(err => {
        console.log(err)
      })
  }, [user.id, block, dispatch])


  let [formatoCirculo1, setFormatoCirculo1] = useState('circle')
  let [formatoCirculo2, setFormatoCirculo2] = useState('circle')
  let [formatoCirculo3, setFormatoCirculo3] = useState('circle')

  let [formatoItem1, setFormatoItem1] = useState('h2-header-empresa')
  let [formatoItem2, setFormatoItem2] = useState('h2-header-empresa')
  let [formatoItem3, setFormatoItem3] = useState('h2-header-empresa')
  let [formatoItem4, setFormatoItem4] = useState('h2-header-empresa')
  let [formatoItem5] = useState('h2-header-empresa')

  let [formatoImagen1, setFormatoImagen1] = useState('imagenes-color')
  let [formatoImagen2, setFormatoImagen2] = useState('imagenes-gris')

  let [formatoTexto1, setFormatoTexto1] = useState('h2-header-empresa-active')
  let [, setFormatoTexto2] = useState('h2-header-empresa')

  const handleCloseCreateProject = () => {
    setproyectoactivate(false)
  }
  const handleFinishCreateProject = () => {
    setproyectoactivate(false)
    setOpen(true)
  }
  const handleClose = () => {
    setOpen(false)
  }

  const handleOnClick1 = () => {
    setFormatoCirculo2(formatoCirculo2 = 'circle')
    setFormatoCirculo3(formatoCirculo3 = 'circle')
    setFormatoCirculo1(formatoCirculo1 = 'circle-active')

    setFormatoItem2(formatoItem2 = 'h2-header-empresa')
    setFormatoItem3(formatoItem2 = 'h2-header-empresa')
    setFormatoItem4(formatoItem4 = 'h2-header-empresa')
    setFormatoItem1(formatoItem1 = 'h2-header-empresa-active')
    return props.setTab(0)
  }

  const handleOnClick2 = () => {
    setFormatoCirculo1(formatoCirculo1 = 'circle')
    setFormatoCirculo3(formatoCirculo3 = 'circle')
    setFormatoCirculo2(formatoCirculo2 = 'circle-active')

    setFormatoItem3(formatoItem3 = 'h2-header-empresa')
    setFormatoItem1(formatoItem1 = 'h2-header-empresa')
    setFormatoItem4(formatoItem4 = 'h2-header-empresa')
    setFormatoItem2(formatoItem2 = 'h2-header-empresa-active')
    return props.setTab(1)
  }
  const handleOnClick3 = () => {
    setFormatoCirculo1(formatoCirculo1 = 'circle')
    setFormatoCirculo2(formatoCirculo2 = 'circle')
    setFormatoCirculo3(formatoCirculo3 = 'circle-active')

    setFormatoItem1(formatoItem1 = 'h2-header-empresa')
    setFormatoItem2(formatoItem2 = 'h2-header-empresa')
    setFormatoItem4(formatoItem4 = 'h2-header-empresa')
    setFormatoItem3(formatoItem3 = 'h2-header-empresa-active')

    return props.setTab(2)
  }

  const handleOnClick4 = () => {
    setFormatoCirculo1(formatoCirculo1 = 'circle')
    setFormatoCirculo2(formatoCirculo2 = 'circle')
    setFormatoCirculo3(formatoCirculo3 = 'circle')

    setFormatoItem1(formatoItem1 = 'h2-header-empresa')
    setFormatoItem2(formatoItem2 = 'h2-header-empresa')
    setFormatoItem3(formatoItem3 = 'h2-header-empresa')
    setFormatoItem4(formatoItem4 = 'h2-header-empresa-active')

    setFormatoImagen1('imagenes-gris')
    setFormatoImagen2('imagenes-color')
    setFormatoTexto1('h2-header-empresa')
    setFormatoTexto2('h2-header-empresa-active')

    return props.setTab ? props.setTab(4) : null
  }

  const handleOnClick5 = () => {
    setFormatoImagen1('imagenes-color')
    setFormatoImagen2('imagenes-gris')
    setFormatoTexto1('h2-header-empresa-active')
    setFormatoTexto2('h2-header-empresa')
  }

  const handleOnleave = () => {
    if (window.location.href.includes("/company/project")) {
      setFormatoImagen2('imagenes-color')
      setFormatoImagen1('imagenes-gris')
      setFormatoItem4('h2-header-empresa-active')
      setFormatoTexto1('h2-header-empresa')
    }
  }

  counterpart.registerTranslations('en', en)
  counterpart.registerTranslations('es', es)
  counterpart.setLocale(localStorage.getItem('langlocal'));
  return (
    <div className='contenedor  HeaderTotal-responsive'>
      {/* <ToastContainer /> */}
      {proyectoactivate &&
        <CrearOportunidad open={proyectoactivate} handleClose={handleCloseCreateProject}
                          handleFinish={handleFinishCreateProject}/>
      }
      {
        open &&
        <PopupCreator open={open} handleClose={handleClose}>
          <PopUpForm handleClose={handleClose}/>
        </PopupCreator>
      }
      <Link to={'/company/home'}>
        <img src={img} alt="" style={{marginTop: '2rem'}}/>
      </Link>
      <div style={{
        bottom: '0',
        width: '100%',
        position: 'absolute',
        height: '70vh',
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'column'
      }}>
        <div style={{width: '100%', paddingLeft: '2vw'}}>

          <Link to={"/company/home"} onClick={() => handleOnClick5()}
                className={window.location.href.includes("/company/home") ? "link-header-color" : "link-header"}>
            <h2 className={formatoTexto1}>
              <img src={homesvg} alt="#" className={formatoImagen1} style={{marginRight: '1rem'}}></img>
              {<Translate content="Inicio" component="span"/>}
            </h2>
          </Link>

          <Link to={"/company/project"}
                style={{display: 'flex', flexDirection: "column"}}
                className={window.location.href.includes("/company/project") ? "link-header-color" : "link-header"}>
            <div style={{display: 'flex'}}>
              <h2 className={formatoItem4} onClick={() => handleOnClick4()} onMouseLeave={() => handleOnleave()}><img
                src={documentsvg} alt="#"
                className={formatoImagen2}
                style={{marginRight: '1rem'}}></img>{<Translate content="Oportunidades" component="span"/>}
                <KeyboardArrowDownIcon
                  className='h2-header-empresa'></KeyboardArrowDownIcon></h2>
            </div>
            <div style={{marginTop: '15px'}}>
              {props.filtroopor && window.location.href.includes("/company/project") &&
                <div style={{display: 'flex', flexDirection: "row"}}>
                  <div style={{
                    marginTop: '20px',
                    height: '55px',
                    backgroundColor: '#C5C5C5',
                    width: '3px',
                    display: "flex",
                    justifyContent: 'center',
                    marginBottom: '-7px',
                    marginRight: '20px',
                    marginLeft: '40px'
                  }}>
                    <Circle className={formatoCirculo1} style={{fontSize: '15px', zIndex: '1', marginTop: '-5px'}}/>
                  </div>
                  <h2 style={{marginBottom: '30px', marginTop: '10px'}} className={formatoItem1}
                      onClick={() => handleOnClick1()}>{lang === 'es' ? 'Publicadas' : 'Published'}</h2>
                </div>
              }
              {/* {props.filtroopor && window.location.href.includes("/company/project") &&
                <div style={{display: 'flex', flexDirection: "row"}}>
                  <div style={{
                    height: '100px',
                    width: '3px',
                    backgroundColor: '#C5C5C5',
                    display: "flex",
                    alignItems: "center",
                    justifyContent: 'center',
                    marginTop: '-15px',
                    marginBottom: '-15px',
                    marginRight: '20px',
                    marginLeft: '40px'
                  }}>
                    <Circle className={formatoCirculo2} style={{fontSize: '15px'}}/>
                  </div>
                  <h2 className={formatoItem2} onClick={() => handleOnClick2()}>{<Translate content="EnEspera" component="span"/>}</h2>
                </div>
              } */}
              {props.filtroopor && window.location.href.includes("/company/project") &&
                <div style={{display: 'flex', flexDirection: "row"}}>
                  <div style={{
                    height: '0',
                    width: '3px',
                    backgroundColor: '#C5C5C5',
                    display: "flex",
                    justifyContent: 'center',
                    marginRight: '20px',
                    marginLeft: '40px'
                  }}>
                    <Circle className={formatoCirculo3} style={{fontSize: '15px'}}/>
                  </div>
                  <h2 style={{marginTop: '-2px'}} className={formatoItem3} onClick={() => handleOnClick3()}>{<Translate
                    content="Finalizadas" component="span"/>}</h2>
                </div>
              }
            </div>
          </Link>
          {window.location.href.split("/").includes("company") &&
            <Link to={"/company/formacion"} style={{textDecoration: 'none', display: 'flex', cursor: 'pointer'}}
                  onClick={() => handleOnClick5()}>
              <h2 className={formatoItem5}>
                <LaptopIcon className={formatoImagen1} style={{marginRight: '1rem'}}/>
                <Translate className="mediumfont" content="Formaciónv1" component="span"/>
              </h2>
            </Link>}
        </div>
        <div>
          {block &&
            <Link to={"/company/pricing"}>
              <div style={{marginLeft: '40px', marginBottom: '10px'}}><span style={{paddingTop: '20px', color: 'red'}}>
                      {lang === "es" ? 'Actualiza tu plan' : "Update plan"}</span>
                <img style={{marginBottom: '5px'}} src={Lock} alt="lock"/></div>
            </Link>}
          {!window.location.href.split("/").includes("consultor") &&
            <div style={{display: 'flex', justifyContent: 'center'}}>
              <div onClick={() => {
                if (block) {
                  toast.error('Limite de oportunidades excedido, por favor contrata el plan premium',{
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                  })
                  return
                }
                setproyectoactivate(true);
                /* document.getElementsByTagName("body")[0].style.overflow = "hidden" */
              }} className={"link-header"}
                   style={{
                     borderRadius: '5px',
                     cursor: 'pointer',
                     textDecoration: 'none',
                     background: 'rgba(240, 113, 48, 1)',
                     width: '185px',
                     height: '33px',
                     display: 'flex',
                     justifyContent: 'center'
                   }}>
                <h2 className='h2-header-empresa'
                    style={{color: 'white', margin: '0', fontSize: '16px', filter: 'grayscale(0%) opacity(1)'}}>
                  {<Translate content="PublicarOportunidad" component="span"/>}</h2>
              </div>
            </div>
          }

          {/* {!window.location.href.split("/").includes("consultor")&&
            <div style={{display:'flex',justifyContent:'center'}}>
            <Link to="/company/new/project" className={window.location.href.includes("/company/new/project")?"link-header-color":"link-header"}
            style={{borderRadius:'5px',cursor:'pointer',textDecoration:'none',background:'rgba(240, 113, 48, 1)',width:'13.8vw',display:'flex',justifyContent:'center'}}>
                <h2 className='h2-header-empresa' style={{color:'white',margin:'0',fontSize:'1vw'}}>Publicar oportunidad</h2>
            </Link>
            </div>
            } */}
          <Link to="/company/configuracion"
                style={{
                  textDecoration: 'none', display: 'flex', cursor: 'pointer', paddingLeft: '2vw', paddingBottom: '3vw'
                }}>
            <img src={configuracion} alt='configuracion' style={{marginRight: '1rem'}}/>
            <h2 className='h2-header-empresa'>
              <span className="mediumfont">{lang === 'es' ? 'Configuración' : 'Setting'}</span>
            </h2>
          </Link>
          <Link to="#" onClick={handleLogout}
                style={{
                  textDecoration: 'none',
                  display: 'flex',
                  cursor: 'pointer',
                  paddingLeft: '2vw',
                  paddingBottom: '1vw'
                }}>
            <ExitToAppIcon className='h2-header-empresa' style={{marginRight: '1rem'}}></ExitToAppIcon>
            <h2 className='h2-header-empresa'>{<Translate content="Cerrarsesión" component="span"/>}</h2>
          </Link>
        </div>
      </div>
    </div>
  )
}
const mapDispatchToProps = {
  logoutUser,
  changeview,
};
export default connect(null, mapDispatchToProps)(Headerprofesional);
