import axios from 'axios'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import './Styles/PopUpEdit.css'

function PopUpEdit({ user, handleClose, dataUser }) {
  const { lang } = useSelector(state => state)
  const [edit, setEdit] = useState({})


  const handleEdit = (e) => {
    setEdit({
      ...edit,
      [e.target.name]: e.target.value
    })
    console.log(edit)
  }
  console.log(user)
  console.table(dataUser)
  const handleOnSubmit = (e) => {
    e.preventDefault()
    if (!dataUser) {
      axios.post(`https://findally-backend.herokuapp.com/api/Company_Form/`, { ...edit, usuario: user })
        .then(res => {
          console.log(res)
          handleClose()
          window.location.reload()
        })
        .catch(err => {
          console.log(err)
        })
    } else {
      axios.put(`https://findally-backend.herokuapp.com/api/Company_Form/updatebyuser/${user}`, edit)
        .then(res => {
          console.log(res)
          window.location.reload()
        }
        )
        .catch(err => console.log(err))
    }
  }
  return (
    <div>
      <form onSubmit={handleOnSubmit} style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        width: '100%',
        alignItems: 'center',
      }} className={'form-edit-profile-Company'}>

        <div style={{ display: 'flex', alignItems: 'flex-start', width: '100%' }}>
          <button style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            color: '#202256',
            borderRadius: '5px',
            width: '20px',
            height: '20px',
            marginTop: '9px',
            marginLeft: '11px',
            backgroundColor: "transparent",
            border: '2px solid #202256'
          }} onClick={handleClose}>
            x
          </button>
        </div>
        <div className={'container-input-edit-profile-Company'}
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            padding: '10px',
            width: '60%',
            minWidth: '500px',
          }}>
          <div style={{
            display: 'flex',
            flexDirection: 'column',
            marginTop: '5%'
          }}>
            <h2 style={{
              color: '#393939',
              fontSize: '24px',
              fontWeight: 700
            }}>{lang === 'es' ? 'Editar perfil' : 'Edit profile'} </h2>
            <hr />
            <p>{lang === 'es' ? '* El asterisco indica que es obligatorio' : '* The asterisk indicates that it is mandatory'}</p>
            <div style={{}}>
              <label>{lang === 'es' ? 'Nombre de la empresa*' : 'Company name*'}</label>
              <input type="text"
                style={{ width: '100%', marginBottom: '1em' }}
                onChange={handleEdit} name='nombre_empresa' defaultValue={dataUser?.nombre_empresa ? dataUser?.nombre_empresa : ''}/>
              <label>Sector*</label>
              <input type="text"
                style={{ width: '100%', marginBottom: '2em' }}
                onChange={handleEdit} defaultValue={dataUser?.sector ? dataUser?.sector : ''} name='sector' />
              <div style={{ display: 'flex', alignItems: 'center', marginBottom: '0.5rem' }}>
                <h3 style={{ width: 'auto', marginRight: '2%', marginBottom: '0' }}>{lang === 'es' ? 'Resumen' : 'Summary'}</h3>
              </div>
              <textarea maxLength="320" rows="5" cols="50"
                style={{ width: '100%', padding: '10px' }}
                onChange={handleEdit} defaultValue={dataUser?.descripcion ? dataUser?.descripcion : ''} name='descripcion' />
              <p>{lang === 'es' ? 'Máximo de caracteres' : 'Maximum characters'} ({edit?.descripcion?.length}/320)</p>
              <div style={{ display: 'flex', alignItems: 'center', marginBottom: '0.5rem' }}>
                <h3 style={{ width: 'auto', marginRight: '2%', marginBottom: '0' }}>{lang === 'es' ? 'Tamaño de la empresa' : 'Size of the company'}</h3>
              </div>
              <textarea maxLength="320" rows="5" cols="50"
                style={{ width: '100%', padding: '10px', height: '72px' }}
                onChange={handleEdit} defaultValue={dataUser?.tamanio ? dataUser?.tamanio : ''} name='tamanio' />
              <p>{lang === 'es' ? 'Máximo de caracteres' : 'Maximum characters'} ({edit?.descripcion?.tamanio}/320)</p>
              <h3>{lang === 'es' ? 'Sitio Web' : 'Website'}</h3>
              <input type="text"
                style={{ width: '100%', marginBottom: '2em' }}
                onChange={handleEdit} defaultValue={dataUser?.sitio_web ? dataUser?.sitio_web : ''} name='sitio_web' />
              <h3>{lang === 'es' ? 'Años de fundación' : 'Years of foundation'}</h3>
              <input type="number"
                onKeyPress={(e) => {
                  if (!/[0-9]/.test(e.key)) {
                    e.preventDefault();
                  }
                }}
                style={{ width: '100%', marginBottom: '2em' }}
                onChange={handleEdit} defaultValue={dataUser?.fecha_fundacion ? dataUser?.fecha_fundacion : ''} name='fecha_fundacion' />
                <h3>{lang === 'es' ? 'Ciudad de fundación' : 'Founding city'}</h3>
              <input type="text"
                style={{ width: '100%', marginBottom: '2em' }}
                onChange={handleEdit} defaultValue={dataUser?.ciudad_fundacion ? dataUser?.ciudad_fundacion : ''} name='ciudad_fundacion' />
              <div style={{ display: 'flex', alignItems: 'center', marginBottom: '0.5rem' }}>
                <h3 style={{ width: 'auto', marginRight: '2%' }}>{lang === 'es' ? 'Especialidades' : 'Specialties'}</h3>
              </div>
              <textarea maxLength="320" rows="5" cols="50"
                style={{ width: '100%', padding: '10px', height: '132px', marginTop: '10px' }}
                onChange={handleEdit} defaultValue={dataUser?.especialidades ? dataUser?.especialidades : ''} name='especialidades' />
            </div>
            <div className="buttonGuardar">
              <button><span style={{ fontSize: '14px', display: 'flex', justifyContent: 'center' }}>
                {lang === 'es' ? 'Guardar' : 'Save'}
              </span></button>
            </div>
          </div>
        </div>
      </form>
    </div>
  )
}

export default PopUpEdit
