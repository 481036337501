import React, { useContext } from "react";
import PlaceOutlinedIcon from "@mui/icons-material/PlaceOutlined";
import "../../../../../empresa/home/OportunityCard/CardOportunidad.css";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import Avatar from "@mui/material/Avatar";
import { AvatarGroup } from "@mui/material";
import { ReduxStateInterface } from "../../../../types/reduxInterface";
import { SchneiderContext } from "../../../../context/SchneiderContext";
import {CompanyOpportunityCardContainer} from "./CompanyOpportunityCardStyles";
import {AutoWidthButton} from "../../../buttons/ButtonStyles";

interface CompanyOpportunityCardProps {
  imagen?: string;
  aplicantes?: any;
  id?: string;
  titulo?: string;
  titulo2?: string;
  habilidades?: any;
  ciudad?: string;
  pais?: string;
}

export default function CompanyOpportunityCard({
  imagen,
  aplicantes,
  id,
  titulo,
  titulo2,
  habilidades,
  ciudad,
  pais,
}: CompanyOpportunityCardProps) {
  const { schneider } = useContext(SchneiderContext);
  const { lang } = useSelector((state: ReduxStateInterface) => state);

  return (
    <CompanyOpportunityCardContainer>
      <div className={"Top-Container-Card"}>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "50%",
          }}
        >
          <img
            className={"CardOportunidad-Image"}
            src={imagen ? imagen : ""}
            alt={imagen ? imagen : ""}
          />
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
            width: "100%",
          }}
        >
          <h1
            style={{
              fontSize: "18px",
              fontWeight: "700",
              color: "#393939",
              textAlign: "left",
              width: "100%",
              textTransform: "capitalize",
              marginRight: "20px",
            }}
          >
            {titulo !== "Unnamed Oportunity"
              ? titulo.length > 30
                ? titulo.substring(0, 30).charAt(0).toUpperCase() +
                  titulo.substring(0, 30).slice(1).toLowerCase() +
                  "..."
                : titulo.charAt(0).toUpperCase() + titulo.slice(1).toLowerCase()
              : titulo2.length > 30
              ? titulo2.substring(0, 30).charAt(0).toUpperCase() +
                titulo2.substring(0, 30).slice(1).toLowerCase() +
                "..."
              : titulo2.charAt(0).toUpperCase() +
                titulo2.slice(1).toLowerCase()}
          </h1>
        </div>
      </div>
      {ciudad || pais ? (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
            textAlign: "center",
            marginLeft: "25px",
          }}
        >
          <PlaceOutlinedIcon style={{ color: "#C5C5C5", fontSize: "20px" }} />
          <div
            style={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              width: "90%",
              height: "100%",
              color: "#C5C5C5",
              fontSize: "16px",
              marginLeft: "15px",
            }}
          >
            {ciudad}, {pais}
          </div>
        </div>
      ) : null}
      <div className="CardOportunidad-mid">
        <div className="CardOportunidad-mid-inner-2">
          <p
            style={{
              fontSize: "14px",
              fontWeight: "400",
              color: "#5D5D5D",
              textAlign: "left",
              margin: 0,
              padding: 0,
            }}
          >
            {lang === "es"
              ? "Profesionales con conocimiento en: "
              : "Professionals with knowledge in: "}
          </p>
          {!habilidades || habilidades === "NA" || habilidades === "Otro"
            ? lang === "es"
              ? "No especifica"
              : "Not specified"
            : habilidades.length > 100
            ? habilidades.substring(0, 100) + "..."
            : habilidades}
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            width: "100%",
            paddingBottom: "30px",
          }}
        >
          <AvatarGroup max={4}>
            {aplicantes &&
              aplicantes.map((aplicante: any, index: number) => {
                return (
                  <Avatar
                    key={index + "A"}
                    alt={aplicante["username"]}
                    src={
                      aplicante?.avatar
                        ? aplicante.avatar
                        : "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png"
                    }
                  />
                );
              })}
          </AvatarGroup>
        </div>
        <div className="CardOportunidad-mid-inner-3">
          <div className="CardOportunidad-mid-inner-3-2">
            <Link to={`/${schneider.route}/company/dashboard/${id}`}>
              <AutoWidthButton color={schneider.color.primary}>
                <span>
                  {lang === "es" ? "Ver oportunidad" : "See Opportunity"}
                </span>
              </AutoWidthButton>
            </Link>
          </div>
        </div>
      </div>
    </CompanyOpportunityCardContainer>
  );
}
