
/* eslint-disable */
export default {
  login: "Login",
  Ingresamail: "E-mail adress",
  contraseña: "Password",
  entrar: "Login",
  aunno: "Not a user yet?",
  registrarse: "Register",
  olvidado: "Forgot your password",

  nombre: "Name",
  registrate: "Register",
  recuperatucontrasena: "Recovery Password",
  tipodeusuario: "User type",
  tyc: "I accept terms and conditions",
  alredyuser: "Already have an account?",

  ESTUDIOS: "Career",
  Definicióndelproyecto: "Definition of the project",
  AboutUs: "About Us.",
  plataformaconectaequipos: "Coally is a platform that connects teams of young professionals with entrepreneurs to add value to emerging economies",
  tecnologiainnovadora: "Using innovative technology, students inenterdicipate and executing an exponential learning, we provide solutions to our customers",
  Conocemas: "Learn more",
  Conocealgunos: "Meet some of our consultants",
  OurFocus: "Our Focus.",
  Elproposito: "The purpose of Coally is uniting human capital with companies that need it",
  Impactosocial: "Social impact",
  Tomamoseltalento: "We take the talent of the best universities in Colombia and give them the opportunity to impact companies and NGOs in different economic sectors",
  Compañias: "Companies",
  Generamosimpacto: "We generate impact, we use knowledge as a catalyst of effectiveness",
  Estudiantes: "Students",
  Coallyofrece: "Coally offers students to develop their skills and contribute to projects actively",
  Nuestrosservicios: "Our services",
  Encoallyteayudados: "In Coally you helped you achieve your goals",
  Quierosabermas: "I want to know more",
  Reinvenciondespuesdelacrisis: "1. Reinvention after the crisis",
  Queremosayudarteatomarventaja: "We want to help you take advantage of the crisis by designing a strategy to respond to Covid.",
  CrearProyecto: "Create a project",
  Estrategiadenegocios: "2. Business strategy",
  Ofrecemosunaampliacantidad: "We offer a wide range of tools and services that support each stage of strategy development.",
  Estructuraciondeproyectos: "3. Project Structuring",
  Puedequetengasunagranidea: "You may have a great idea, we help you structure it.",
  Emprendedores: "4. Entrepreneurs",
  Cremosquehaymejores: "We believe that there are better ideas on the way.",
  Trabajoremoto: "5. Remote Work",
  Trabajoremotoonly: "Remote Work",
  Creemosquelosnegociosestan: "We believe that businesses are migrating towards a digital transformation.",
  proyectosrecomendados: "Projects for you",
  Habilidadesrequeridas: "Habilidades requerida",
  Aplicarantesde: "Apply before::",
  Vermas: "More",
  Aplicar: "Apply",
  Aplicaciónrealizadaconexito: "Successfully applied",
  Habilidades: "Skills",
  Hemosllegado: 'We have reached the end!',
  estamosfelices: 'We are happy that we managed to solve this challenge together. Thank you for being part of this team; CoAlly Conscious Consulting for All. ',
  califica: 'Rate the consulting team',
  tienescomentario: 'Do you have any comments for your consulting team or the consulting process',
  continuarioas: 'Would you continue to work with CoAlly?',
  cual: 'Which one?',
  Completaturegistro: "complete your registration",
//PAGINA PROYECTOS EMPRESA
  Enespera: 'Projects on hold',
  Proyectosendesarrollo: 'On going projects',
  Proyectofinalizado: 'Done',
  Entregas: 'Deliverables',
  noproject: 'No projects yet',
  Comentarios: 'Comments',
  Avances: 'Advances',
  DETAILSPRO: 'See details',
  Equipo: 'Team',
  comonosconociste: 'How did you find out about us?',
  pandemia: 'The pandemic wiped out my business',
  TerminarProyecto: 'Finish Project',
  Titulo: 'Title',
  GUARDAR: 'SAVE',
  familiarices: 'We want you to know our platform, so please participate on our mock project. Send us comments!',
  capital: 'I need capital',
//MENU EMPRESA

  Verperfil: 'View profile',
  Contáctanos: 'Contact us',
  PreguntasFrecuentes: 'Frequent questions',
  Feedback: 'Feedback',
  Terminosycondiciones: 'Terms and Conditions',
  Cerrarsesión: 'Sign out',
  donwloadfile: 'DOWNLOAD FILE',
  Aunnohassubidoalguncertificado: "Not any certificate yet",
  payments: 'Payments',

//PAGINA PERFIL EMPRESA
  PERFIL: 'Profile',
  PROYECTOS: 'Projects',
  SECTOR: 'Sector',
  UBICACIÓN: 'Location',
  ENLAWEB: 'Website',
  REDES: 'Social media',
  ConsultoriasRealizadas: 'Completed consultancies',
  CREARPROYECTO: 'Create a project',
  EDITARPERFIL: 'EDIT PROFILE',
  Aplicaaqui: 'Apply here',
  closesesion: 'Sing out and when you back you will be able yo start the project',
  PROFESION: "Profession",
  acercademi: "About me",
//PAGINA CREAR PROYECTO
  QUENOMBRELEPONDRAS: 'What name did you put the project?',
  Estenombreseutilizara: 'This name will be used as a reference when publishing the Project',
  ENQUECIUDADSEREALIZARA: 'In which city will the project be made?',
  EstaCiudadseutilizaracomo: 'This city will be used as a reference to find the consultantsm',
  CUALESSONLOSOBJETIVOSQUEQUIERES: 'Objectives you want to fulfill?',
  Estosseranlosprincipalesobjetivosdelproyecto: 'These will be the main objectives of the Project',
  objetivos: 'objectives',
  ciudad: 'town',
  CUALESSONLASMETRICASQUEQUIERES: 'Metrics you want to measure in this project?',
  Estosseranlosprincipalesmetricasdelproyecto: 'These will be the main Metrics of the Project',
  Métricas: 'Metrics',
  caupromblema: 'Causa del problema',


  DESCRIBETUPROBLEMAENUNAORACIÓN: 'Describe your problem in a sentence',
  Lainformaciónquesediligenciará: 'The information that will be diligent is specifically the challenge or challenge with which we are going to work.',
  Describetuproblemaacá: 'Describe your problem here',
  CAUSASDELPROBLEMA: 'Causes of the problem',

  Cuálescreesquesonlascausasdeesteproblema: 'What do you think are the causes of this problem?',
  Otro: 'Other?',
  Elpersonalnoestátrabajandocomoesperaba: 'The staff is not working as I expected',
  Sientoqueestamosestancados: 'Feel like we´r stuck',
  Quieroreestructrarlasbasesdeminegocio: 'I want to restructrate the bases of my business',

  LÍNEADELNEGOCIO: 'Business line',
  Líneadenegocioenlaquenecesitasapoyo: 'What sector does your business belong to?',

  Desarrollodesoftwareybigdata: 'Software Development and Big Data',
  Marketingdigitalyestudiosdemercado: 'Digital Marketing and Market Studies',


  Innovacióneintraemprendimiento: 'Innovation and intraemployment',
  CONOCIMIENTOSESPECÍFICO: 'Specific knowledge',


  Conocimientosespecíficosquerequiereelproyecto: 'Specific knowledge required by the project',

  FinanzasNegociaciónyliderazgo: 'Finance Negotiation and Leadership',
  Ingenierias: 'Engineering',
  Planeaciónymodelajedenegocios: 'Planning and business modeling',
  Impactosocialysostenibilidad: 'Social impact and sustainability',
  RecursosHumanos: 'Human Resources',
  Estrategia: 'Strategy',
  Legal: 'Legal',
  Marketingyventas: 'Marketing and sales',

  NIVELDEINGLÉS: 'ENGLISHLEVEL',

  Quéniveldeinglésserequiere: 'What level of English is required by the consultants to carry out the project?',
  Nulo: 'Null',
  Básico: 'Basic',
  Intermedio: 'Intermediate',
  Avanzado: 'Advanced',

  CODIGOPROMOCIONAL: 'PROMOTIONAL CODE',
  InsertaacatuCodigoPromocional: 'Insert here your promotional code',
  Codigo: 'Code',
  COMENTARIOS: 'Comments',
  Comentariosoinformaciónadicionalrelevante: 'Comments or additional information relevant',


//PAGINA REGISTRO EMPRESA
  COMPLETAELFORMULARIO: 'Complete the form',
  NOMBREDELAEMPRESA: 'Company name',

  Estedatoserautilizadoenelcontratoydemas: 'This data will be used in the contract and other legal documents',
  Nombreempresa: 'Companyname',

  NOMBREDELREPRESENTANTELEGAL: 'Name of the legal representative',
  NUMERODECEDULADELREPRESENTANTELEGAL: 'Cedula number of the legal representative',
  CIUDADDEEXPEDICIONDELACEDULADELREPRESENTANTELEGAL: 'Expedition city of the Cedula of the Legal Representative',

  nitidempresa: 'Nit / ID of the company',
  IngreseelNITdelaempresa: 'Company`s NIT',
  SITIOWEB: 'Web site',

  Compartenosellinkdelapaginawebdelaempresa: 'Share the link of the company s website',


  TELEFONO: 'Phone',

  Telefonodelaempresa: 'Company phone',
  EMAIL: 'E mail',

  Emaildelaempresa: 'Emailcompany',
  Ubicacióndelaempresa: 'Location of the company',

  TIEMPOENELMERCADO: 'Time in the market',
  Cuantotiempollevalaempresaenelmercado: 'How long does the company take in the market?',
  Menosde1año: 'Less than 1 year',
  De1a3años: 'From 1 to 3 years old',
  Masde3años: 'Over 3 years',
  idio: 'Language',
  IDIOMA: 'Language',

  Queidiomasemanejaenlaempresa: 'Which language is handled in the company?',

  Ingles: 'English',
  chino: 'Chinese',
  Portugues: 'Portuguese',
  Español: 'Spanish',

  Enquemercadoestalaempresa: 'In which market is the company?',

  TAMAÑODELAEMPRESA: 'Size of the company',

  Cuentanoseltamañodelaempresasegunlacantidad: 'Tell us the size of the company according to the number of employees',
  Microempresa: 'Micro company',
  Pequeñaempresa: 'Small company',
  Medianaempresa: 'Medium enterprises',
  GranEmpresa: 'Big company',
  Categorias: 'Categories',

  DESCRIPCIONDELAEMPRESA: 'Company Description',
  Seleccionalacategoríadelproblema: 'Please select the area you need support with',
  Unabrevedescripciondelaempresa: 'A brief description of the company',
  direct: 'Message Us',
  Descripcion: 'Description',

  ATRÁS: 'Back',
  Enesteespaciode: '',
  bienestar: '',
  SIGUIENTE: 'Next',
  puedescompartircon: 'We are here for you. Please reach out for any comments, questions, complaints, doubts or if you just want to have a chat with us.',
  EscríbenosporWhatsApp: '+57 311 8868852',
  FINALIZAR: 'FINISH',
  SOPORTECOALLY: 'COALLY SUPPORT',
  ENVIAR: 'SEND',
  soporte: 'Coally',
  coally: 'Support',
//PAGINA HOME COALLIER
  Proyectosrecomendadosparati: 'Projects recommended for you',

//HEADERCOALLIER
  Educación: 'Education',
  Proyectos: 'Projects',
  Notificaciones: 'Alerts',
  Perfil: 'Profile',


//MENU COALLIER

  Pagosycertificados: 'Payments',
  Casosdeexito: 'Success stories',
  SoporteCoally: 'Coally Support ',
  Politicadeprivacidad: 'Privacy Policy',
  Idioma: 'Language',


//project details
  checkcomments: "CHECK COMMENTS",
  checkcalendar: "CHECK CALENDAR",


//PAGINA EDUCACION COALLIERS

  EducacionCoAlly: 'Coally Education',
  Ademáshemosdiseñadoesteespacioconcontenidogratuitoeducativoparapotencializartusconocimientoscapacitarteeimpulsarteprofesionalmente: 'We have designed this space with free educational content to potentiate your knowledge and skills',
  Paraaplicaralasofertasquetenemosparatiesnecesarioquetecapacitesviendolossiguientesvideos: 'We recommend you watch this videos to familiarize yourself with our platform',

//PAGINA DE LEGAL COALLY

  DocumentosLegalesCoally: 'LegaldocumentsCOALLY',
  CERTIFICADODEPROYECTO: 'Project certificate',
  CONTRATODECONFIDENCIALIDAD: 'Confidentiality contract',
  CONTRATODEPRESTACIÓNDESERVICIOS: 'CONTRACT OF SERVICE',
  SEGURIDADSOCIAL: 'SOCIAL SECURITY',
  Añadeunafirmaestaserautilizadaenlosdocumentoslegales: 'Add a signature, this will be used in legal documents:',
  Borrar: 'Delete',
  Firmar: 'Sign',
  Enviar: 'Send',


//PAGINA DE PAGOS COALLIERS

  Pagos: 'Payments',
  Certificados: 'Certificates',
  Casosdeéxito: 'Success stories',

  Descargarcertificado: 'Download certificate',
  Descargarcasodeéxito: 'Download Success Case',

//PAGINA SUBIR CASOS DE ÉXITO

  Seleccionaelproyectoysubeledocumentoconladescripciondetucasodeexito: 'Click below to upload a success story, Remember it must be in pdf',
  Selecttheprojectanduploadthedocumentwiththedescriptionofyoursuccesscase: 'Click below to upload a success case, remember it must be in pdf',


//PAGINA CONTACTANOS COALLIER

  Enesteespaciodebienestarpuedescompartirconnosotrostusrequerimientosestamosparaescucharteyayudarte: 'In this space of well-being, you can share with us your requirements, we are here to listen to you and help you.',
  EscríbenosporWhatsApp018003453298: 'Write us by whatsapp 01800-345-3298.',
  contactofindallycom: 'contacto@findally.com.',
  MensajeDirecto: 'Direct message',
  Nombrecompleto: 'Full name',
  Asunto: 'Subject',


  alentrarcon: 'Upon contacting the designated Consultant, you must accept the terms established in the service provision contract that you will find on this same platform. By accepting these terms, you understand that there is no employment relationship supervening to the contract accepted between you and the Consultant, nor between you and the platform. As a Client, you will not be able to select in the first instance the Consultant who will provide the service, as this will be designated by the platform depending on the project to which it has applied, its interests and knowledge. Additionally, you must use the platform as a means of accepting service provision contracts, validating the methodology and releasing the value of the service through the TPAGA platform. You must verify that, during the time of the provision of the service, the Consultant, and Gold Consultant comply with the payment of all items of social security that comes from the contract accepted between the parties. In addition, as a Client you must, once the service is finished, give a certificate of experience to the Consultant, and to the Gold Consultant.',
  ionalmentesiust: 'Additionally, if you are a Client, you will be in charge of: providing pertinently the information necessary for the development of the project, appointing a person who will be in charge of accompanying the development of the consultancy and who must attend all meetings previously agreed with the team and send observations of each product developed by the Consultant in the stipulated time. To guarantee the efficiency of the service, the Client must comply with the business day available to review the products. Otherwise, you must make an additional payment that will be equivalent to the corresponding cost of the time of your delay (eg if the initial price was 600 USD in a term of 20 business days, then the delay of each business day will correspond to 30 USD).',
  condiconsulto: 'Conditions for Consultants',
  alentraralaplat: 'When entering the platform, you must clearly and objectively establish your knowledge and capabilities. By accepting these terms, you are aware that when you contact the Client, you must review a service provision contract that is a requirement for starting consulting. By accepting these terms, you understand that there is no employment relationship supervening from the contract accepted between you and the Client, nor between you and the platform. As a Consultant, you will be able to select the Client with whom you want to work, this in order to work on a project with which you have an affinity. You must comply with the payment of the items corresponding to your social security, as an independent, within the framework of the fulfillment of the contract between you and the Client.',
  Adicionalmentealaceptares: 'Additionally, by accepting these Conditions, you consent to develop the service, taking as a methodological guide any tool provided by the coally SAS team that facilitates the development of the consultancy, as well as the monitoring of tentative dates of delivery of advances that guarantee the term of 4 weeks that each service provided lasts. In addition, you accept by means of these terms that as a Consultant you will establish, in a coordinated manner with the consulting team, schedules that suit your possibilities for guidance in the development of the consultancy.',
  SiustedesConsultoresta: 'If you are a Consultant, you will be in charge of: complying efficiently and in a timely manner with the delivery of the consulting products, developing the project taking as a methodological guide the tools that have been provided by coally SAS and following the recommendations against the form implementation of the same, attend previously agreed review meetings with the team for the guidance and development of the consultancy, attend to the recommendations suggested by the Client to improve the product, and follow the advance dates and tentative delivery of each product , which are stipulated on the platform, to guarantee the term of 4 weeks that each service provided lasts.',
  Seguirlaguiaque: 'Follow the guide provided by those users who are Gold, as the consultancy is the result of joint work between these three parties.',
  condigold: 'Conditions for Gold',
  Alaceptarestostérmi: 'By accepting these terms, you are aware that when you contact the Client you must review a service provision contract that is a requirement for the start of the consultancy. Additionally, you understand that there is no employment relationship that is supervening from the contract accepted between you and the Client, nor between you and the platform. As Gold will be able to select the Client with whom it wishes to work, this in order to work on a project with which it has an affinity. You must comply with the payment of the items corresponding to your social security, as an independent, within the framework of the fulfillment of the contract between you and the Clients.',
  Adicionalmentesuste: 'Additionally, if you are Gold, you will be in charge of: coordinating project delivery dates, organizing the project calendar, validating all deliverables, receiving the respective feedback from the Client, developing success factor schemes, keeping an eye on the delivery of the Client s own documents (eg financial statements, management reports), design heat maps of results, and define the best strategy for the Client. Additionally, it will be in charge of adding, modifying or removing steps of the methodology to be applied in the specific case that it develops. All this subject to prior review by the coally team.',
  aceptasentrega: 'You accept the results of the first delivery in order to continue with the process',
  aceptar: 'Agree',
  creaComentarios: 'Send feedback to your consultants',
  desarrollopro: 'Professional development',
  desarrolloper: 'Personal development',
  actualidad: 'Relevant for today',
  profe: 'Profession',
  econo: 'Economy',
  admin: 'Management',
  negoci: 'Business',
  der: 'Law',
  ingind: 'Industrial engineering',
  disñeo: 'Design',
  infsis: 'Systems Engineering',
  dataanali: 'Big data',
  niv: 'Level',
  princi: 'Beginner',
  inter: 'Mid level',
  avanz: 'Advanced',
  home: 'Home',
//avances
  Entrega: 'Delivery',
  InformacionActualizada: 'Updated Information',
  RespuestasEmpresa: 'Company answers',
  faseActual: 'Current phase',
  comentariodelosconsultores: 'Consultants comments',
  Consultor: 'Consultant',
  PROGRESO: 'PROGRESS',
  proyecto: 'Project',
  Ventas: 'Sales',
  Dashboard: 'Dashboard',
  Managment: 'Managment',
  ManageUsers: 'Manage users',
  ManageProjects: 'Manage projects',
  Match: 'Match',
  Cerrarsesion: 'Log out',
  INGRESOSTOTALES: 'TOTAL REVENUE',
  Gestiondeproyectos: 'Project management',
  Proyectosrecientes: 'Recent projects',
  Fecha: 'Date',
  Empresa: 'Client',
  Tipodepago: 'Method of payment',
  MontoUSD: 'Amount USD',
  Vertodoslosproyectos: 'See all projects',
  Titulodevideo: 'Video title',
  profesionco: 'profession',
  cv: "CV",
  descripciondelatarea: 'Task description',
  INGRESAUNCOMENTARIOREFERENTEALATAREA: 'ENTER A COMMENT REGARDING THE TASK',
  Firmaingresadacorrectamente: 'Signature entered correctly',
  INGENIERIAA: 'ENGINEERING',
  ESTRATEGIAA: 'STRATEGY',
  MARKETINGYVENTAS: 'MARKETING AND SALES',
  TRABAJOREMOTOO: 'REMOTE WORK',
  HOMEEE: 'HOME',
  NOSOTROSS: 'ABOUT US',
  SERVICIOSS: 'SERVICIOS',
  ÚNETEEE: 'JOIN',
  PROYECTOSSS: 'PROJECTS',
  Socials: 'Socials',
  Seleccionaelsectorenelcualtedesempeñas: 'Select you field',
  Preguntascoally: 'Coally questions',
  Cuantashorasalasemanalededicariasaunproyecto: 'How many hours a week would you dedicate to a project?',
  Algunaactividadextraquedesempeñarasenlauniversidad: 'Some extra activity that you did at the university',
  Cuantassemanasestariasdispuestoatrabajarcomovoluntario: 'How many weeks would you be willing to work as a volunteer?',
  Cuantassemanasestariasdispuestoatrabajarenunmismoproyecto: 'How many weeks would you be willing in a same project?',
  Añadeunapequeñadescripciondetuperfil: 'Add a little description of your profile',
  Seleccionalosidiomasqueconoces: 'Select the languages you know',
  atras: 'back',
  siguienteee: 'Next',
  paracontinuarllenaelcampodescripcion: 'To continue fill the description field',
  educacion: 'education',
  FromYear: 'From Year',
  ToYear: 'To Year',
  Career: 'Career',
  School: 'School',
  paracontinuarllenaelcampoCareer: 'To continue fill Career field',
  Pagina25: 'Page 2 / 5',
  Experiencia: 'Experience ',
  Position: 'Position',
  Duration: 'Duration',
  Pagina45: 'Page 4 / 5',
  Detallesextra: 'Extra details',
  Skill1: 'Skill 1',
  Skill2: 'Skill 2',
  Skill3: 'Skill 3',
  Skill4: 'Skill 4',
  Skill5: 'Skill 5',
  Skill6: 'Skill 6',
  Intereses: 'Interests',
  Intereses1: 'Interests 1',
  Intereses2: 'Interests 2',
  Intereses3: 'Interests 3',
  Intereses4: 'Interests 4',
  Intereses5: 'Interests 5',
  Intereses6: 'Interests 6',
  Terminar: 'Finish',
  Pagina55: 'Page 5 / 5',

  Detallespersonales: 'Personal details',
  Direccion: 'Direction',
  CedulaId: 'Identification card/Id',
  cedula: 'identification card',
  Fechadeexpediciondelacedula: 'Date of issuance of the ID',
  YourWebsite: 'Su pagina web',
  paracontinuarllenaelcamponombre: 'to continue fill in the name field',
  Pagina15: 'Page 1 / 5',
  Proyectosdesarrollados: 'Projects developed',
  Proyecto1: 'Project 1 (optional)',
  Title: 'Title',
  Proyecto2: 'Project 2',
  Proyecto3: 'Project 3',
  Pagina35: 'Pagina 3 / 5',
  Company: 'Company',
  EMPRESA: 'COMPANY',
  Consultores: 'Consultants',
  listadetags: 'tag list',
  descripciondelproyecto: 'project description',
  Lineadenegocio: 'Business line',
  Nombredelproyecto: 'Project name',
  Iniciodeproyecto: 'Project start',
  Findeproyecto: 'Project end',
  Certificado: 'Certificate',
  Remuneracion: 'Remuneration',
  Si: 'Yes',
  textpredeterminado: 'default text',
  Habilidadesrecomendadas: 'Recommended skills',
  Numerodeetapas: 'Number of stages',
  Verdetalles: 'See details',
  Cerrar: 'Close',
  Inicia: 'Start',
  buscarr: "Search",
  profesion: 'Occupation',
  Remuneración: 'Remuneration',
  estárevisandotuperfilparaesteproyecto: 'The project is on hold',
  Thankyou: '¡Thank you!',
  Youwillgetanemailwithfurtherinstructions: 'You Will get an email with further instructions.',
  Back: 'back',
  Clear: 'clear',
  Close: 'close',
  PaymentSucceeded: 'Payment Succeeded',
  Yourcardpaymenthasbeenconfirmed: 'Your card payment has been confirmed',
  Publicarproyecto: ' Publish project ',
  FrequentlyAskedQuestion: 'Frequently asked question',
  Cómopagamos: 'How do we pay?',
  Somoscompletamente: 'We are completely digital, therefore, our payment is made by PSE, credit cards.',
  Cómofuncionalacontratación: 'How does hiring work?',
  Contratoporprestacióndeservicios: 'Contract to provide services',
  CómofuncionaTpaga: 'How Tpaga works?',
  Tpagaeslaplataforma: 'Tpaga is the platform through which we receive our payments. When you pay our fee, it is frozen and we do not receive it until you verify that you are happy with our result. This gives you the confidence of your investment in our services, and ensures a timely payment. ',
  Recibimosproyectosinternacionales: 'Do we receive international projects?',
  Sirecibimospagosporpaypal: 'Yes, we receive payments by paypal',
  Decuantotiemposonlosproyectos: 'How long are the projects?',
  Nuestrosproyectossuelen: "Our projects are usually 5 weeks long, and we put all our efforts to meet these times. However, when you publish your project, we do an analysis of how long it will take us to solve your challenge, and it depends on its complexity.",
  Cómohacemoslaselección: 'How do we make the selection? ',
  Coallydesarrollaunproceso: 'Coally develops an automatic process where we evaluate, among other things, the skills of the young person and his fit with the project.',
  Encuantotiempomeavisansimeaceptaron: 'How long do you let me know if they accepted me?',
  MiproductoperteneceaCoally: 'Does my product belong to Coally?',
  Cómohagoseguimientodemiequipo: 'How do I keep track of my team?',
  Puedocontrataraalgúnmiembrodelequipodirectamente: 'Can I hire a member of the team directly?',
  Sideberáspagarelfeederetenciónde300USD: 'Yes, you will have to pay the retention fee of 300 USD',
  Enquémomentodebopagar: 'At what time should I pay?',
  Elpagoserealizaantesdepublicartuproyecto: 'Payment is made before publishing your project.',
  Quénosdiferenciadeotrasplataformasfreelancesyconsultoras: 'What sets us apart from other freelance and consulting platforms?',
  SomosunaPlataformaEdtech: 'We are an Edtech and freelance Platform that solves your business problems. How? We connect companies with quality work teams that provide solutions directed by Coally methodologies,',
  Quéserviciosprestamos: 'What services do we provide?',
  DESARROLLODESOFTWAREYBIGDATA: 'SOFTWARE AND BIG DATA DEVELOPMENT',
  MARKETINGDIGITALYESTUDIOSDEMERCADO: 'DIGITAL MARKETING AND MARKET STUDIES',
  INNOVACIÓNEINTRAEMPRENDIMIENTO: 'INNOVATION AND INTRA-ENTREPRENEURSHIP',
  Quédebohacersinoestoydeacuerdoconunentregable: 'What should I do if I do not agree with a deliverable?',
  Deberásenviarnosunemailacoally3gmailcom: 'You must send us an email to coally3@gmail.com',
  CuáleselmargendeCoally: 'What is Coally margin?',
  Coallygenerael20demargenporproyecto: 'Coally generates 20% margin per project.',
  Cómofunciona: 'How does it work?',
  Registroenlaplataformacoallycomypaga720USD: 'Register on the coally.com platform and pay 720USD.',
  Coallyseleccionaunequipointerdisciplinariode3jóvenes: 'Coally selects an interdisciplinary team of 3 young people and directs their work using the Coally methodology, transmitted with artificial intelligence.',
  Ellostrabajaránentre2y3horasaldíadurante4semanas: 'They will work 2-3 hours a day for 4 weeks.',
  Puedeshacerseguimientoalavancedetu: 'You can track the progress of your project through the platform, with daily notifications and weekly deliverables.',
  Propuestadevalor: 'Value proposal',
  Permitequeprofesionalesjóvenesy: 'Let young professionals and Coally technology solve your problems quickly and with quality.',
  Cuéntanostudesafío: 'Tell us your challenge',
  Testimonios: 'Testimonials',
  Companyprofile: 'Company profile',
  Resume: 'Resume',
  Aquívaladescripcióndelaempresa: 'Aqui va la descripción de la empresa, de qué es, a qué se dedica, una breve descripcion de si misma.',
  BogotáColombia: 'Bogota, Colombia',
  wwwtusitiowebcom: 'www.tusitioweb.com',
  años3: '3 años',
  Empleados1050: '10-50 Empleados',


  Aunnohacompledoalgunaconsultoria: 'You have not yet completed any consultancy',
  Formularioderegistro: 'Registration Form',

//TYC
  TérminosyCondicionesdeUsodelaPlataformacoally: 'Terms and Conditions of Use of the coally Platform',
  tyc1: 'Intellectual property',
  tyc2: 'All computer, graphic, advertising, photographic, multimedia, audiovisual and design material, as well as all content, texts and databases made available in this application are protected by copyright and / or industrial property whose holder is coally. By accepting these Terms and Conditions, you accept that the use of the coally methodology will be exclusively intended for the development of the consultancy, so its application to matters unrelated to the project itself is prohibited, unless expressly authorized by coally. Likewise, the use of the application and some materials owned by third parties is expressly authorized by law or by said third parties. All the contents in the application are protected by copyright regulations and by all applicable national and international regulations. ',
  tyc3: 'Except for what is expressly stipulated in these Terms and Conditions, any act of copying, reproduction, modification, creation of derivative works, sale or distribution, exhibition of the contents of this application, in any way or by any means, is prohibited, including, but not limited to limited to electronic, mechanical, photocopying, recording or any other type of media, without the prior written permission of coally or the owner of the respective rights that will be exclusively the clients of coally. ',
  tyc4: 'In no case do these Terms and Conditions confer rights, licenses or authorizations to carry out the previously prohibited acts. Any unauthorized use of the contents will constitute a violation of this document and the current regulations on copyright, the current national and international regulations on Industrial Property, and any other that may be applicable. ',
  tyc5: 'Use of Information and Privacy',
  tyc6: 'By downloading coally you accept and authorize Coally S.A.S to use your data as the data controller for purposes derived from the execution of the application. Coally S.AS. informs that you may exercise your rights to know, update, rectify and delete your personal information; as well as the right to revoke the consent granted for the treatment of personal data provided for in law 1581 of 2012, observing our information treatment policy available at: link. ',
  tyc7: 'Coally S.A.S. may disclose, transfer and / or transmit your personal data to third parties inside and outside the country because of a contract, law or legal link that requires it. For all the above, you accept and grant your express and unequivocal authorization. ',
  tyc8: 'Additionally, by accepting these Terms and Conditions you understand that the information collected by the platform is provided freely and voluntarily, so that it can be managed by the platform. Likewise, the data collected may be analysed to improve the platform business strategy, supported by business intelligence and data mining tools, which allow acquiring prospective knowledge for prediction, classification, and segmentation purposes. ',
  tyc9: 'The Consumer may exercise their right to know, update, modify and delete the personal data existing in the databases associated with the Platform. To do this, you must make the request for consultation, claim or deletion to the email address Coally3@gmail.com detailing the modifications to be made and providing the supporting documents. ',
  tyc10: 'Coally S.A.S is responsible for the treatment of personal information collected through the web portal, a responsibility that may be delegated to a third party, as the person in charge or in charge of the information, contractually ensuring its adequate treatment.',
  tyc11: 'Coally responsibility',
  tyc12: 'Coally will endeavour to ensure availability, continuity and proper functioning of the application. You can block, interrupt, or restrict access to it when you consider it necessary for the improvement or withdrawal from it. Coally may change or modify the services offered through the platform, include, or remove services or functions that will not affect its use or performance. Coally must develop all updates to the platform that guarantee optimal performance, this at no additional charge to users. ',
  tyc13: 'The User is recommended to take adequate measures and act diligently when accessing the application, such as, for example, having protection programs, antivirus programs, for handling malware, spyware and similar tools.',
  tyc14: 'Coally will not be responsible for: a) Force majeure or acts of God; b) Due to the loss, misplacement or theft of your mobile device that implies third-party access to the mobile application; c) Due to typing errors or connectivity failures by the user, which prevent the normal development of the consultancy within the established times; d) For damages, loss of profits, consequential or moral damages, and in general amounts to be paid by Coally for delays, non-processing of information or suspension of the mobile operator service or damage to mobile devices. ',
  tyc15: 'Coally does not undertake its responsibility for the services that are marketed by these third parties, since the Platform serves as a communication and advertising channel, but not as a service provision tool. Consequently, it is the Consumers total responsibility to access the sites that the advertising sends, assuming the obligation to verify and know the terms of the services offered by third parties.',
  tyc16: 'Denial and Withdrawal of Access to the Application',
  tyc17: 'If a User breaches these Terms and Conditions, or any other provisions that result from the application, Coally may suspend their access to the application.',
  tyc18: 'Terms and Conditions',
  tyc19: 'As a user, you expressly accept the Terms and Conditions, being an essential condition for the use of the application. If you disagree with these Terms and Conditions, we request that you leave the application immediately. Coally may modify these terms and conditions, notifying the users of the application by publication on the Coally website or by disseminating the modifications by any electronic means, social networks, or email, which will be understood as accepted by you if you continue with the use of the application. ',
  tyc20: 'The Consumer expressly states that they have the legal capacity to use the Platform and to carry out the commercial transactions that may be generated. Likewise, he claims to have provided real, truthful, and reliable information; Therefore, you expressly and unequivocally declare that you have read, that you understand and that you accept all the situations regulated in this document of Terms and Conditions of Use of the Platform, for which you undertake to fully comply with your duties, obligations, actions, and omissions expressed herein.',
  tyc21: 'If Consumers from other countries use the Platform to request products and services in Colombia, they are fully subject to the provisions of these terms.',
  tyc22: 'Paragraph. The Consumer expressly authorizes the moment of acceptance of these Terms, the use of Cookies by the Operator in all activity of use of the Platform.',
  tyc23: 'Jurisdiction',
  tyc24: 'These terms and conditions and everything that has to do with this application and the legal relationships that arise from it, are governed by Colombian law.',
  tyc25: 'Use of Non-Personal Information',
  tyc26: 'Coally S.A.S also collects non-personal information in aggregate form to track data such as the total number of application downloads, number of clicks, preferred projects, preferred profiles, among others. We use this information, which remains in aggregate form, to understand the behavior of the application.',
  tyc27: 'Use of IP Addresses',
  tyc28: 'An Internet Protocol (IP) address is a set of numbers that is automatically assigned to your or mobile device when you access your internet service provider, or through your organization local area network (LAN) or wide area network (WAN). Web servers automatically identify your mobile device by the IP address assigned to it during your online session. ',
  tyc29: 'Coally may collect IP addresses for systems administration purposes and to audit the use of its site, all of the above in accordance with the data protection authorization that is signed for that purpose. Typically, a user IP address is not linked to the user personal information, which means that each user session is logged, but the user remains anonymous to Coally. However, Coally may use IP addresses to identify users of our site when necessary to enforce the site terms of use, or to protect its service, site, or other users. ',

  tycTableTitle: 'POLÍTICA TRATAMIENTO DE DATOS COALLY S.A.S',
  tycTableContent: 'Tabla de contenido',
  tycTableContent1: '1. PRESENTACIÓN',
  tycTableContent2: '2. ÁMBITO DE APLICACIÓN',
  tycTableContent3: '3. DEFINICIONES',
  tycTableContent4: '4. PRINCIPIOS RECTORES PARA EL TRATAMIENTO DE LOS DATOS PERSONALES',
  tycTableContent5: '5. AUTORIZACIÓN PARA EL TRATAMIENTO DE DATOS PERSONALES Y CASOS EN LOS QUE NO ES NECESARIA LA AUTORIZACIÓN',
  tycTableContent6: '6. TIPOS DE DATOS RECOPILADOS POR COALLY S.A.S',
  tycTableContent7: '7. FINALIDAD DE TRATAMIENTO DE LOS DATOS PERSONALES',
  tycTableContent8: '8. NORMATIVIDAD APLICABLE',
  tycTableContent9: '9. DERECHOS DEL TITULAR DE LOS DATOS PERSONALES',
  tycTableContent10: '10. RESPONSABLE DE LOS DATOS PERSONALES',
  tycTableContent11: '11.  DEBERES DE LOS ENCARGADOS DEL TRATAMIENTO',
  tycTableContent12: '12. CONSULTAS Y RECLAMOS',
  tycTableContent13: '13. MODIFICACIONES',
  tycTableContent14: '14. VIGENCIA',


  Nombredelaempresa: 'Company name',
  Nombredelrepresentantelegal: 'Name of the legal representative',
  Numerodeceduladelrepresentantelegal: 'ID number of the legal representative',
  Ciudaddeexpediciondelaceduladelrepresentantelegal: 'City of issuance of the identity card of the legal representative',
  Sitioweb: 'Website',
  Telefono: 'Telephone',
  Ubicación: 'Location',
  Tiempoenelmercado: 'Time in market',
  Sector: 'sector',
  Tamañodelaempresa: 'Size of the company',
  Descripciondelaempresa: 'Company description',
  Completaelformulario: 'Complete the form',
  Registrofinalizadoconexito: 'Registration completed successfully',

  Informacióngeneral: 'General information',
  Deseaspublicaresteproyectodemaneraanonima: 'Do you want to publish this project anonymously?',
  Selecciona: 'Select...',
  Ingreseelnombredelaempresa: 'Company name',
  Ingresaelnombredelproyecto: 'Project/ role name',
  EnqueCiudadserealizaraelproyecto: 'In which City will the project be carried out?',
  Cuálestunecesidadytúobjetivo: 'What is your need and your goal?',
  Requerimientosespecificos: 'Specific requirements',
  Seleccioneelareadeconocimientorequeridasegunsusrequerimientos: 'Please choose the knowledge area/major of the professional if you wish',
  Finanzasynegociacion: 'Finance and negotiation',
  Ingenieriass: 'Engineering',
  Planeaciondenegocios: 'Business Planning',
  Impactosocialysostenibilidadd: 'Social impact and sustainability',
  Quieroreestructrarlasbasesdeminegocioo: 'I want to restructure the foundations of my business',
  Otroo: 'Other',
  Cualescreesquesonlascausasdelproblema: 'What do you think are the causes of the problem?',
  Cómomediríaseléxitodelproyecto: 'please list the minimal functioning requirements, example: Informative website, 4 marketing posts',
  Seleccionaellenguajedeprogramaciónquedesees: 'Select the desired programming language',
  Seleccionaelidiomarequeridoparaelproyecto: 'Languaje',
  Informacionadicional: 'Additional Information',
  Ingresauncodigopromocional: 'Promotional code',
  Ingresatuscomentarios: 'Comments (Optional)',
  Cuálestupresupuestoparaesteproyecto: 'What is your minimum budget for the project/role?',
  Elpresupuestoingresadodebeserigualomayora370USDsegunelproyecto: 'The budget entered must be equal to or greater than (370 USD or 1.423.637 COP) according to the project',
  Deseaspagarahoraodeseasunaasesoriapersonalizada: 'Select the payment method',
  DeseopagarAhora: 'I want to pay now',
  Deseounaasesoriapersonalizada: 'I want personalized advice',
  Enviarpagarahora: 'Send pay now',
  Enviarpersonalizada: 'Send Custom',
  Coallymantieneeldinerodentrodesuplataforma: 'Coally keeps the money within its platform until the project is finished. Coally guarantees the partial or total return of the money if the outcome does not comply with the outcome requirements.',
  Ingresatucorreoelectronico: 'Enter your email',
  Siseleccionastepagoporentregableingresaelmontodeseado: 'If you selected payment by deliverable enter the desired amount',
  ContactUs: 'Contact us',
  NombreCompleto: 'Full Name',
  DocumentosLegalesCoallyy: 'Coally Legal Documents',
  Certificadodeproyecto: 'Project certificate',
  Contratodeconfidencialidad: 'Confidentiality Agreement',
  Seguridadsocial: 'Social security',
  Contratodeprestacióndeservicios: 'Contract for the provision of services',
  Aunnotienesproyectosenproceso: 'You do not have projects in the works yet',
  Añadeunafirmaestaserautilizadaenlosdocumentoslegaless: 'Add a signature, this will be used in legal documents:',
  saldo: 'Balance',
  montoo: 'Amount',
  Proyectofinalizadoo: 'Project completed',
  Casodeexito: 'Success story',
  Aunnohassubidoalguncasodeexito: 'You have not yet uploaded a success story',
  Ingresatucomentariooavanceenelsiguientecampo: 'Enter your comment or advance in the next field',
  Avanceregistradoconexito: 'Successful progress',
  Lenguajes: 'Languages',
  Cargando: 'Loading',

  Empiezaaaplicartusconocimientos: 'Start applying your knowledge',
  SéconsultorenCoAlly: 'Be a consultant in CoAlly',
  Ningunproyectoencontradoconesefiltro: 'No project found with that filter',
  Companies: 'Empresas',
  Proyectosdisponibles: 'Available projects',
  Legall: 'Legal',
  Matchcompletadoconexito: 'Match completed successfully',
  Usuarios: 'Users',
  projectdescription: 'project description',
  projectname: 'project name',
  DESCARGARDOCUMENTO: 'DOWNLOAD DOCUMENT',
  RepresentanteLegalde: 'Legal Representative of',
  CERTIFICA: 'CERTIFIES:',
  Queelseñor: 'May the mr/mrs',
  identificadoconcéduladeciudadaníaNo: 'identified with citizenship card number',
  expedidaen: 'issued in',
  desarrollóycompletódemaneraexitosalaconsultoriade: 'developed and successfully completed the consultancy of',
  comopartedelastartupdeimpactoCoallyparala: 'as part of the impact startup Coally  for the',
  atravésdeunamodalidaddeprestación: 'through a service provision mode, with the following activities',
  FIRMAREPRESENTANTELEGAL: 'LEGAL REPRESENTATIVE SIGNATURE',
  NOMBREYAPELLIDOS: 'NAME AND SURNAME:',
  CEDULA: 'ID:',
  DIRECCIÓN: 'DIRECTION:',
  TELEFONOO: 'TELEPHONE:',
  Firmaar: 'Sign',
  Cargandodatos: 'Loading data...',
  Subeaquilosdocumentos: 'Upload the documents here',


  DESCARGARDOCUMENTOO: 'DOWNLOAD DOCUMENT',
  ACUERDODECONFIDENCIALIDAD: 'CONFIDENTIALITY AGREEMENT',
  PorLapartereveladoraAndresFelipe: 'For the revealing part Andrés Felipe Joya Mosquera Address: 544 washington street, Wellesley, MA. USA Telephone: 7742903687 E-mail: ajoya1@babson.edu Fax: n / a ',
  Porlapartereceptoradelainformación: 'By the receiving party of the information',
  NombreEE: 'Name:',
  Dirección: 'Direction: ',
  Teléfono: 'Telephone: ',
  Identificacióndelproyecto: 'Project identification',
  Entrelosfirmantesidentificadosanteriormentehemos: 'Among the signatories, identified above, we have agreed to enter into this confidentiality agreement prior to the following',
  CONSIDERACIONES: 'CONSIDERATIONS',
  Quelainformacióncompartidaenvirtuddelpresenteacuerdo: '. That the information shared under this agreement belongs to the startup Coally, and it is considered sensitive and restricted in its disclosure, handling and use. Said information is shared by virtue of the development of the venture and prototype as previously identified. ',
  Quelainformacióndepropiedad: '. That the information shared under this agreement belongs to the startup Coally, and it is considered sensitive and restricted in its disclosure, handling and use. Said information is shared by virtue of the development of the venture and prototype as previously identified. ',
  Queelpresente: '. That this agreement is made, on the one hand, between the party receiving the information as a member of the undertaking and, on the other hand, Andres Felipe Joya Mosquera, who for the present current case as the developer, guardian and administrator of the information owned by the Coally. Consequently, the parts subscribe to the following clauses: ',
  PrimeraObjetoenvirtuddel: 'First. Purpose: by virtue of this confidentiality agreement, the receiving party undertakes not to disclose directly, indirectly, close to remotely, or through any other person or their subordinates or officials, advisors or any person related to it, the confidential information belonging to the startup Coally, as well as not to use said information for their own benefit or that of third parts. ',
  Segunda: 'Second. ',
  Definicióndeinformación: 'Definition of confidential information: it is understood as Confidential Information, for the purposes of this agreement:',
  Lainformaciónquenosea: '. Information that is not public and is known by the receiving party on the occasion of the research and / or extension project. ',
  Cualquierinformación: '. Any corporate, technical, legal, financial, commercial, market, strategic, product information, new technologies, patents, utility models, industrial designs, business models and / or any other information related to the business model and entrepreneurship to achieve such purposes, and / or any other entity related to the organizational structure, whether it is written, oral or visual, or in any tangible form or not, including data messages (as defined by law), of which the receiving party is aware of or to which it has access by any means or circumstance by virtue of the meetings held and / or documents supplied. ',
  Laquecorrespondaodebaconsiderarsecomotalpara: '. The one that corresponds or should be considered as such to guarantee the constitutional right to privacy, honor and good name of the people and due diligence must be kept in its discretion and management in the performance of its functions. ',
  TerceraOrigendelainformaciónconfidencial: 'Third. Origin of confidential information: it will come from documents supplied in the development of the project and that has to do with the creations of the intellect, nature, means, forms of distribution, commercialization of products or the provision of services, transmitted verbal, visual or materially, in writing on documents, electronic media, optical discs, microfilms, films, e-mail or other similar elements supplied in a tangible or intangible way, regardless of their source or medium and without requiring notice of their confidential nature. ',
  CuartaObligaciones: 'Fourth. Obligations of the receiving party: The person who receives the information, or who has access to it, will be considered as the receiving party of the confidential information. The receiving party agrees to: ',
  Mantenerlainformaciónconfidencialsegurausarlasolamente: 'Keep the confidential information secure, use it only for the purposes related to it, if requested, return it all (including copies of it) at the time you no longer need to make use of it or when the relationship ends, in which case, you must deliver said information before the termination of the relationship. ',
  Protegerlainformaciónconfidencialseaverbalescrita: 'Protect confidential information, whether verbal, written, visual, tangible, intangible or received by any other means, the startup Coally being the legitimate owner of it, restricting its use exclusively to people who have an absolute need to know it. ',
  Abstenersedepublicarla: '. Refrain from publishing confidential information that he knows, receives or exchanges on the occasion of the meetings held. ',
  Usarlainformación: '. Use the confidential information that is delivered, only for the purposes indicated at the time of delivery of said information. ',
  Mantenerlainformaciónconfidencialenreservahastatanto: 'Keep confidential information in reserve until it acquires the character of public. ',
  Responderporelmalusoque: '. Respond for the misuse that their representatives give to confidential information. ',
  Guardarlareservadelainformaciónconfidencialcomomínimo: '. Keep confidential information confidential as a minimum, with the same care with which you protect confidential information. ',
  Lapartereceptoraseobligaanotransmitir: '. The receiving party undertakes not to transmit, communicate, reveal or in any other way fully or partially disclose, publicly or privately, the confidential information without the prior written consent of Coally. vParagraph: Any authorized disclosure of confidential information to third parts will be subject to the same confidentiality obligations derived from this Agreement and the receiving party must report these restrictions including identifying the information as confidential. ',
  QuintaObligacionesdelapartereveladora: 'Fifth. Obligations of the disclosing party: The disclosing parts obligations are: ',
  Mantenerlareservade: '. Keep information confidential until it becomes public. ',
  Documentartodalainformaciónconfidencialque: '. Document all confidential information that you transmit in a written, oral or visual way, through documents, electronic media, optical discs, microfilms, films, e-mails or other similar elements or in any tangible or not, including data messages, such as registration of the same to determine its scope, and indicate specifically and clearly and unequivocally the confidentiality of the information provided by the receiving party. ',
  SextaExclusionesalaconfidencialidadLaparte: 'Sixth. Exclusions to confidentiality: The receiving party is relieved or exempted from the obligation of confidentiality, only in the following cases: ',
  lainformaciónconfidencialhayasidooseadedominiopúblico: '. When confidential information has been or is in the public domain. If the information becomes public domain during the term of this agreement, due to an act beyond the control of the receiving party, it will retain its duty to reserve the information that has not been affected. ',
  Cuandolainformaciónconfidencialdeba: '. When confidential information must be revealed by a final judgment of a court or competent authorities in the performance of their functions that order the lifting of the reserve and request the provision of this information. However, in this case, the revealing party will be in charge of complying with the order, restricting the disclosure to strictly necessary information, and in the event that confidentiality is maintained, it will not exempt the receiving party from the duty of reserve. ',
  Cuandolaparte: '. When the receiving party proves that the confidential information has been obtained from other sources. ',
  Cuandolainformación: '. When the confidential information was already in its possession by the receiving party prior to the delivery of the reserved information. ',
  Séptima: 'Seventh. Responsibility: the party that violates the agreement will be responsible to the other party or to third parts in good faith on whom it is shown that they have been affected by the non-observance of this agreement, for the moral and economic damages that they may suffer as a result of the breach of the obligations contained herein. ',
  Octava: 'Eighth. Dispute resolution: The parts undertake to endeavour to resolve any dispute that may arise as a result of the execution of this agreement through alternative dispute resolution mechanisms. In the event of not reaching a direct solution for the dispute, they will submit the disputed issue to Colombian law and to the competent jurisdiction at the time the difference is presented. ',
  NovenaLegislaciónaplicableEsteacuerdose: 'Ninth. Applicable legislation: This agreement will be governed by the laws of the Republic of Colombia and will be interpreted in accordance with them. Tenth. Acceptance of the Agreement: The parts have carefully read and studied the terms and content of this Agreement and therefore declare that they agree and accept all the conditions. ',
  díasdelmesde: 'days of the month of',
  EnBogotáDCalos: 'In Bogota D.C., to the',
  de: 'from',
  PorlapartereveladoraAndresFelipeJoyaMosqueraCCNode: 'For the revealing part: Andres Felipe Joya Mosquera C.C. No. of C.C. No. 1019106268 of Bogota ',
  cargando: 'loading...',
  CONTRATODEPRESTACIÓNDESERVICIOSSUSCRITOENTRE: 'SERVICE PROVISION CONTRACT SIGNED BETWEEN',
  y: 'and',
  Porunaparte: 'On the one hand, ',
  sociedadidentificadaconNITn: ' company identified with TIN number',
  representadalegalmentepor: 'legally represented by',
  mayordeedad: 'of legal age, identified with citizenship card number',
  expedidaenlaciudadde: 'issued in the city of',
  personamayordeedad: 'person of legal age, identified with citizenship card number',
  quienparaefectosde: ', who for the purposes of this document will be called THE CONTRACTOR; and on the other hand,',
  quienenadelantesedenominaráELCONTRATISTAsuscribenun: 'who hereinafter will be called THE CONTRACTOR, sign a SERVICE PROVISION CONTRACT, which will be governed by the following clauses:',
  Elcontratistaconformasusconocimientosen: 'The contractor conforms his knowledge in ',
  prestarálosserviciosde: 'he will provide the consulting services autonomously and with full technical knowledge for THE CONTRACTOR. ',
  SEGUNDAA: 'SECOND',
  PLAZOElplazoparalaejecucióndelpresente: '. TERM. The term for the execution of this contract will be ',
  semanascomprendidoentrelafecha: 'weeks between date',
  Parágrafo1Lodispuestoenlapresentecláusulapodráestar: 'Paragraph 1. The provisions of this clause may be subject to an extension by mutual agreement and must be in writing.',
  TERCERAA: 'THIRD',
  VALORElvalordelpresentecontratoseráporla: '. VALUE. The value of this contract will be for the sum of ',
  CUARTAA: 'FOURTH',
  FORMADEPAGOElvalorpactadoenlacláusula: '. WAY TO PAY. The value agreed in the previous clause will be canceled in two moments. Fifty percent (50%) when starting the consulting project and the remaining fifty percent (50%) to customer satisfaction, once it has finished. ',
  QUINTAA: 'FIFTH',
  OBLIGACIONESDELCONTRATANTEÉsteseobligaráa: '. CONTRACTOR OBLIGATIONS. The latter shall undertake to: 1. Deliver the CONTRACTOR in a timely manner the tools and information required for the development of the consulting process to ensure its efficient development; 2. Appoint a person in charge of accompanying the development of the consultancy for 4 hours a week, which includes attending the meetings previously agreed with the contractor to review progress; 3. Inform THE CONTRACTOR immediately be aware of any irregularity with the performance of this contract; 4. THE CONTRACTOR shall verify that THE CONTRACTOR is complying with its obligations inherent to the payment of social security as independent; 5. Send the observations of each of the products within the business day following receipt of the same. Otherwise, you must make an additional payment that will be equivalent to the corresponding cost of the time of its delay. ',
  SEXTAA: 'SIXTH',
  OBLIGACIONESDELCONTRATISTAElContratistadeberá: '. CONTRACTOR OBLIGATIONS. The Contractor must fulfill in a perfect and timely manner the tasks assigned to them, which are specific to the nature of the service. The contractor undertakes to: 1. Execute in good faith the consulting service in which this document is contracted 2. Develop the consulting process taking as a methodological guide the tools that have been provided by coally and follow the recommendations against the form of their implementation; 3. Attend meetings previously agreed with the contractor to review progress. 4. Attend the recommendations that the contractor makes regarding the service developed; 5. Comply with the advance and delivery deadlines of the advances of the product, as they have been stipulated; 6. Comply with their responsibilities to pay social security under penalty of the contractor suspending or withholding the payment of his fees. 7. Respond to the observations made by the contractor in no more than two (2) days from the delivery thereof. ',
  OCTAVAA: 'EIGHTH',
  DURACIÓNDELSERVICIOEstecontratoesporeltiempo: '. SERVICE DURATION. This contract is for the time of the execution of the contracted consulting work, but either party may terminate it for unjustified breach of the obligations stipulated in this contract, notifying the other with no less than five (5 days).',
  CLÁUSULANOVENA: 'NINTH CLAUSE',
  TERMINACIÓNDELCONTRATOElpresente: '. TERMINATION OF THE CONTRACT. This contract may be terminated by mutual agreement between the parts, or unilaterally by coally at any time for, among others, the breach of the obligations derived from this contract. Paragraph 1: In both cases, the contractor must pay up to the point of execution of the consultancy for the services of the contractor. ',
  CLÁUSULADÉCIMA: 'TENTH CLAUSE',
  INDEPENDENCIADELASPARTESEndesarrollo: '. INDEPENDENCE OF THE PARTS. In developing this Contract, the parts will act on their own account, with autonomy and therefore will not be subject to labour subordination of any other party, and each of them will be solely responsible for the fulfillment of their civil, commercial, labour obligations, and any other derivative of the law. ',
  Laspartescumpliránlasobligacionesobjetodeestecontrato: 'The parts will fulfill the obligations that are the object of this contract using their own human resources. Therefore, between the CONTRACTOR and the CONTRACTOR, there is and will not be any employment relationship. Therefore, between the CONTRACTOR and (i) the CONTRACTOR, (ii) the employees, workers, workers and / or operators of the parts and (iii) other persons involved in the execution of the Contract, there is and will not be a labour relationship or any civil, or relationship of solidarity or responsibility of any title. Consequently, the parts assume all the responsibility that corresponds to them as sole employers or contractors of the people who come to use, being their position, among others, salaries, social benefits, compensation, fees and other obligations that may arise. In the event of a claim or litigation against any of the parts due to any of such obligations, the party shall be obliged to concur in the defense of the other party and to pay the claimant the amount owed to him. ',
  CLÁUSULADÉCIMASEGUNDA: 'TWELFTH CLAUSE',
  CESIÓNDELCONTRATONingunadelaspartespodrácederparcial: 'ASSIGNMENT OF THE CONTRACT. None of the parts may partially or totally assign the execution of this contract to a third party except with the express and written authorization of the other party and of the company coally S.A.S ',
  CLÁUSULADÉCIMACUARTA: 'FOURTEENTH CLAUSE',
  MODIFICACIÓNOPRÓRROGACualquier: '. MODIFICATION OR EXTENSION. Any modification or extension to this contract must be made in writing and by mutual agreement of the parts. ',
  CLÁUSULADÉCIMAQUINTA: 'FIFTEENTH CLAUSE',
  CONFIDENCIALIDADElpresente: '. CONFIDENTIALITY. This Contract, as well as all the information, specifications, projects, designs, investigations, reports, knowledge or data, technical or not, presented verbally, in physical medium or in electronic or magnetic format, that have been or may be disclosed to Any of the Parts and / or their partners, employees, agents and / or contractors due to the relationship between the Parts will be considered confidential information, which may not be used, reproduced or disclosed to third parts, unless the Party wishes to make use of Confidential Information obtain specific prior authorization in writing from the other Party, with observance of the reservations established below. ',
  LasPartessussociosempleadosagentesyocontratados: 'The Parts, their partners, employees, agents and / or contractors must keep secret and prevent the disclosure of any Confidential Information, except what is necessary for the proper development of this Contract.',
  Adicionalmentelaobligacióndeconfidencialidadaquí: 'Additionally, the confidentiality obligation established here will not be applicable to:',
  aAquellainformaciónqueresulteaccesiblealpúblicopor: 'a. That information that is accessible to the public for reasons other than breach of this Contract; ',
  bAquellainformaciónqueestélegítimamenteenpoderdela: 'b. That information that is legitimately in the possession of the recipient party of the same before being communicated by the revealing party; ',
  cAquellainformaciónquehayasidoobtenidalegítimamentede: 'c. That information that has been legitimately obtained from a third party without any restriction; and',
  dAquellainformaciónquedebasercomunicadaporordendeun: 'd. That information that must be communicated by order of a competent court, governmental body or the corresponding regulatory authority as long as the party that is obliged to communicate said information tries by all reasonable means to notify the other party of said communication as far in advance as possible.',
  Estasobligacionesdeconfidencialidadsobreviviránla: 'These confidentiality obligations will survive the termination of this Agreement and will remain in effect for a period of three (3) years from their termination.',
  CLÁUSULADÉCIMOSEXTA: 'SIXTEENTH CLAUSE',
  NATURALEZAElpresenteContratoesde: '. NATURE. This Contract is eminently commercial in nature and, therefore, will be governed in everything not stipulated in it, by the rules of the Civil and Commercial Code ',


  Rating: 'Rating',
  Consultorprofile: 'Profile consultant',
  Presentación: '.	Presentation',
  COALLYSASesunacompañíacolombianaquetienecomoactividadprincipalconectarempresasy: 'COALLY S.A.S. is a Colombian company whose main activity is to connect companies and / or individuals with young professionals through a virtual platform, composed of a web page and a mobile application, so that a consulting service is developed that adds value to clients. (Companies and / or natural persons). ',
  COALLYSASconlafinalidaddedarcumplimientoestrictoalanormatividadvigentedeprotección: 'COALLY SAS, in order to strictly comply with current regulations for the protection of Personal Data, in accordance with the provisions of Law 1581 of 2012, Decree 1074 of 2015 and other provisions that modify, add or complement them, presents the following PERSONAL DATA PROTECTION AND TREATMENT POLICY (hereinafter "Treatment Policy") in order to protect the personal information provided by the Holders that are related to COALLY SAS as are clients, consultants and any other natural or legal person from which COALLY S.A.S., obtains, collects, processes or treats personal data, whether said treatment is carried out by COALLY S.A.S. or by third parties who do so on behalf of it. ',
  ElobjetodelaPolíticadeTratamientoesprotegerelderechoconstitucionaldelHabeasDataque: 'The purpose of the Treatment Policy is to protect the constitutional right of Habeas Data that all people have to know, update, and rectify the information that has been collected and stored in the different databases of COALLY SAS, and in By virtue of the fulfillment of said right, it only collects and treats Personal Data, when it has been previously authorized by its Holder, implementing for this purpose, clear measures on confidentiality and privacy of Personal Data. Likewise, it details the general corporate guidelines that are taken into account in order to protect the Personal Data of the Holders. ',
  Estostérminosycondicionesytodoloquetengaqueverconestaaplicaciónylasrelaciones: 'These terms and conditions and everything that has to do with this application and the legal relationships that arise from it, are governed by Colombian law, specifically Law 1581 of 2012 and Decree 1074 of 2015',
  Ámbitodeaplicación: '.	Area of aplication',
  Definiciones: '.	Definitions ',
  TitularPersonanaturalcuyosdatossonobjetodetratamientoParadelapresentepolíticade: '. Owner: Natural person whose data is subject to treatment. For this personal data treatment policy, the holders may be: (i) Clients of the platform; (ii) Consultants and all those people not related to COALLY S.A.S. of whom the personal data is processed. ',
  ClientePersonanaturalojurídica: '.	Client: Natural or legal person ',
  Usuariotodapersonaquehayadescargadoyhagausodelaplataformayaseacomoclienteocomo: '.	User: any person who has downloaded and uses the platform either as a client or as a consultant. ',
  PlataformalarecoleccióndedatosporparteCoallySASseráatravésdelasplataformasCoally: '.	Platform: the data collection by Coally S.A.S will be through the Coally App and Coally Web platforms used for the development of the activity of this company. ',
  DatopersonalCualquierinformaciónvinculadaoquepuedaasociarseaunaovariaspersonas: '.	Personal data: Any information linked or that may be associated with one or more specific or determinable natural persons. ',
  BasedeDatosConjuntoorganizadodedatosqueseanobjetodeTratamiento: '.	Database: Organized set of data that are subject to the Treatment; ',
  BasededatospersonalesConjuntoorganizadodedatospersonalesquesonobjetodetratamiento: '.	Personal database: Organized set of personal data that are subject to treatment by a natural or legal person. ',
  DatosensibleEsaqueldatopersonalqueafectalaintimidaddelTitularycuyousoincorrecto: '  Sensitive data: It is that personal data that affects the privacy of the Holder and whose incorrect use could generate discrimination. Sensitive data, among others, are considered health data, data on sexual orientation, racial and ethnic origin, political opinions, religious, philosophical or moral convictions. ',
  DatoprivadoEsaqueldatopersonalqueporsucarácteríntimooreservadoesrelevanteparael: '.	Private data: It is that personal data that due to its intimate or reserved nature is relevant for the Holder. ',
  DatoSemiprivadoEsaqueldatopersonalconocidoydeinteréstantoparaeltitularcomoparaun: '.	Semi-private Data: It is that personal data known and of interest both for the owner and for a certain sector of people or for society in general, so it is not of an intimate, reserved or public nature. ',
  DatopúblicoEsaqueldatopersonalcalificadocomotalsegúnlaConstituciónylaleyyqueno: '.	Public data: It is that personal data qualified as such according to the Constitution and the law, and that has not been classified as private or semi-private personal data. ',
  HabeasdataEselderechoquetieneelTitulardelosdatospersonalesdeexigirdelas: '.	Habeas data: It is the right of the Holder of the personal data to demand from the administrators of the same the access, inclusion, exclusion, correction, addition, updating and rectification of the data, as well as the limitation in its disclosure, publication or assignment.',
  AutorizaciónConsentimientoprevioexpresoeinformadodelTitularparallevaracaboel: '.	Authorization: Prior, express and informed consent of the Holder to carry out the processing of personal data. ',
  AvisodeprivacidadComunicaciónverbaloescritadirigidaalosTitularesdelosdatos: '.	Privacy notice: Verbal or written communication addressed to the Holders of the personal data that are being processed by the company, in which they are informed about the existence of the personal data treatment policies that will be applied to them, the form of access them, and the purposes for which your personal data will be used. ',
  TratamientoCualquieroperaciónoconjuntodeoperacionessobredatospersonalestalescomola: '.	Treatment: Any operation or set of operations on personal data, such as the collection, storage, use, circulation or deletion. ',
  ResponsabledeltratamientoPersonanaturalojurídicadecarácterpúblicooprivadoqueporsí: '.	Responsible for the treatment: Natural or legal person of a public or private nature that by itself or in association with another or others decides on the processing of personal data. In this case, it will be COALLY S.A.S. the Responsible for the treatment. ',
  Principiosrectoresparaeltratamientodelosdatospersonales: '.	Guiding principles for the processing of personal data ',
  SegúnloestablecidoenelTítuloIIdelaLeyEstatutaria1581de2012laproteccióndedatos: 'As established in Title II of Statutory Law 1581 of 2012, the protection of personal data will be governed by the harmonious and comprehensive application of the following principles:',
  PrincipiodelegalidadeneltratamientodedatospersonalesEltratamientodedatos: '.	Principle of legality in the processing of personal data: The processing of personal data referred to in Statutory Law 1581 of 2012 is a regulated activity that must be subject to what is established in it and in the other provisions that develop it. ',
  PrincipiodefinalidadEltratamientodelosdatospersonalesdebeobedeceraunafinalidad: '.	Principle of purpose: The processing of personal data must obey a legitimate purpose in accordance with the Constitution and the law, which must be informed to the Holder. ',

  PrincipiodelibertadEltratamientodelosdatospersonalessólopuedeejercerseconel: '.	Principle of freedom: The processing of personal data can only be exercised with the prior, express, and informed consent of the Holder. Personal data may not be obtained or disclosed without prior authorization, or in the absence of a legal or judicial mandate that relieves consent. ',
  PrincipiodeveracidadocalidadLainformaciónsujetaatratamientodebeserverazcompleta: '.	Principle of truthfulness or quality: The information subject to treatment must be truthful, complete, exact, updated, verifiable and understandable. Processing of partial, incomplete, fractional, or misleading data is prohibited. ',
  PrincipiodetransparenciaEnelTratamientodelosdatospersonalesdebegarantizarseel: '.	Principle of transparency: In the Processing of personal data, the right of the Holder to obtain from the Responsible for the treatment or the Person in charge of the treatment, at any time and without restrictions, information about the existence of data that concerns him must be guaranteed. ',
  PrincipiodeseguridadLainformaciónsujetaatratamientoporelResponsabledelTratamiento: '.	Security principle: The information subject to treatment by the Treatment Manager or Person in Charge of the treatment referred to in Statutory Law 1581 of 2012, must be managed with the technical, human, and administrative measures that are necessary to provide security to the records avoiding its adulteration, loss, consultation, use or unauthorized or fraudulent access. ',
  PolíticadetratamientodedatosCoallySAS: 'Data treatment policy Coally S.A.S',
  PrincipiodeconfidencialidadTodaslaspersonasqueintervenganeneltratamientodedatos: '.	Principle of confidentiality: All persons who intervene in the processing of personal data that are not public in nature are obliged to guarantee the reservation of the information, even after the end of their relationship with any of the tasks that comprise the treatment, being able only carry out supply or communication of personal data when this corresponds to the development of the activities authorized in Statutory Law 1581 of 2012 and in the terms of the same. ',
  PrincipiodeaccesoycirculaciónrestringidaEltratamientosesujetaaloslímitesquese: '.	Principle of access and restricted circulation: The treatment is subject to the limits that derive from the nature of the personal data, the provisions of Statutory Law 1581 of 2012 and the Constitution. In this sense, the treatment can only be done by persons authorized by the Holder and / or by the persons provided for in the law. ',
  Autorizaciónparaeltratamientodedatospersonalesycasosenlosquenoesnecesarialaautorización: '.	Authorization for the processing of personal data and cases in which authorization is not necessary ',
  ElconsentimientoyautorizaciónporpartedelTitulardelainformaciónesunrequisitoconstitucional: 'The consent and authorization by the Information Holder is a constitutional and legal requirement that must be met by the persons responsible for the processing of personal data. The consent must comply with the following presuppositions: ',
  PrevioLaautorizaciónladebedarelTitulardeinformacióndemanerapreviaacualquiertipode: 'Prior: The authorization must be given by the Information Holder prior to any type of Personal Data Processing.',
  ExpresoLaautorizacióndebeotorgarsedeformainequívocaclarayespecífica: 'Express: The authorization must be granted unequivocally, clearly and specifically.',
  InformadoElTitulardebecomprenderclaramenteparaquéserántratadossusdatospersonalesylas: 'Informed: The Holder must clearly understand why their personal data will be processed and the purposes that may arise from the Treatment thereof.',
  TodoslosvisitantesdelasPlataformasdeCOALLYSASdebenregistrarseyautorizareltratamiento: 'All visitors to the COALLY S.A.S. They must register and authorize the processing of personal data to make use of the services offered. Therefore, in each of the systems there is a box that says, "Privacy Policy and Treatment of Personal Data" which must be read and accepted in order to continue using the services of COALLY S.A.S. ',
  TIPOSDEDATOSRECOPILADOSPORCOALLYSAS: '. TYPES OF DATA COLLECTED BY COALLY S.A.S ',
  CoallySASrecopilarádatosproporcionadosporlosusuariosdeCoallycomolosgeneradosdurante: 'Coally S.A.S will collect data provided by Coally users, such as those generated during account creation, those provided in the use of the services. ',
  PerfildelusuariodatosgeneradoscuandosecreanoactualizanlascuentasEstopuede: '.	User profile: data generated when accounts are created or updated. This may include name, email, phone number, username, password, identification document, profile photos. ',
  Datosdemográficosserecopilarándatosdemográficosdelosusuarioscomodirecciónedad: '.	Demographic data: demographic data will be collected from users such as address, age, gender, place of residence, career, and areas of interest. It may also be collected through surveys intended for it ',
  Datosdeusoserecopilandatossobreelservicioprestadooelserviciosolicitadoesto: '.	Usage data: data is collected about the service provided or the service requested, this includes the type of service requested or provided, details of the service, information about the time the service was provided and the frequency of request or provision of the same . ',
  Otrosdatoscomentariosdelclientecalificacionesyopinionesdelservicioprestado: '. Other information: customer comments, ratings and opinions of the service provided. ',
  Finalidaddetratamientodelosdatospersonales: '. Purpose of processing personal data ',
  LosDatosPersonalesquerecolectaCoallySASsonincluidosenunaBasedeDatosalacual: ' The Personal Data that Coally S.A.S. collects are included in a Database to which the authorized personnel of COALLY S.A.S. have access. in the exercise of their functions, noting that in no case is the Processing of information authorized for purposes other than those described here, and that they are communicated to the Holder directly at the latest at the time of collection. ',
  LarecolecciónalmacenamientousoyocirculacióndelosdatospersonalesrecolectadosporCoally: 'The main purpose of the collection, storage, use and / or circulation of personal data collected by Coally S.A.S is to provide the services offered in an adequate manner and to achieve the fulfillment of the expectations of the users of the web and mobile platform.',
  AdicionalmenteconelfindedesarrollarlosobjetossocialdelacompañíaCoally: 'Additionally, in order to develop the social objects of the company, Coally S.A.S may process data with the following purposes: Purposes of processing the personal data of Users',
  RecolectarlosdatospersonalesdelosusuarioseincorporarlosyalmacenarlosenlabasededatosdeCoallySAS: '.	Collect the personal data of users and incorporate and store them in the database of Coally S.A.S. ',
  Ordenarcatalogarclasificardividirosepararlainformaciónsuministrada: '.	Sort, catalog, classify, divide, or separate the information provided. ',
  Utilizarlosdatossuministradosencampañasdecomunicacióndivulgaciónypromociónuoferta: '.	Use the data provided in communication, disclosure and promotion campaigns or offer of products, activities and / or services developed as part of the company internal strategies. ',
  Conservarregistroshistóricosdelacompañíaymantenercontactoconlostitularesdeldato: '.	Maintain historical records of the company and maintain contact with the holders of the data. ',
  Verificarcomprobarovalidarlosdatossuministrados: '.	Verify, check, or validate the data provided. ',
  Transferirlosdatospersonalesacualquierpaísoservidorenotropaís: '. Transfer personal data to any country or server in another country. ',
  Comunicarypermitirelaccesoalosdatospersonalessuministradosatercerosproveedoresde: '.	Communicate and allow access to personal data provided to third party providers of general support services and to natural or legal persons shareholders of COALLY S.A.S. ',
  Realizardeconformidadconlaleylosreportesacentralesderiesgoporincumplimientode: '.	Carry out, in accordance with the law, reports to credit bureaus for breach of financial obligations derived from the commercial relationship. ',
  Envíodecomunicacionesatravésdecualquiercanalincluyendoperosinlimitarsearedes: '.	Sending communications through any channel including, but not limited to, social networks, text messages, push notifications, email, phone call, etc., related to the corporate purpose of COALLY S.A.S. such as marketing activities and / or any request made by the owner to COALLY S.A.S, among others. ',
  Prestarlosserviciosofrecidosyocontratadosdeformaadecuadayconunaexcelentecalidad: '.	Provide the services offered and / or contracted adequately and with excellent quality ',
  Recomendardeacuerdoalainformaciónfacilitadaporelconsultorproyectosespecíficos: '.	Recommend, according to the information provided by the consultant, specific projects that respond to their tastes, preferences or needs',
  Normatividadaplicable: '.	Applicable regulations',
  EltratamientodelosDatospersonalesdeltitularserealizaráconbaseenlodictadoenlapresente: 'The treatment of the owner Personal Data will be carried out based on the provisions of this privacy policy and the provisions of Law 1581 of 2012, Decree 1377 of 2013, Decree 886 of 2014, standards compiled in Decree 1074 of 2015 of the Republic of Colombia, and the other norms that add or modify. ',
  Derechosdelostitulares: '.	Rights of the holders',
  EncumplimientodelasgarantíasfundamentalesconsagradasenlaConstituciónylaleyysin: ' In compliance with the fundamental guarantees enshrined in the Constitution and the law, and without prejudice to the provisions of the other regulations that regulate the matter, the Holders of personal data may exercise the following rights free of charge and without limitation: ',
  Derechoalaccesoasuinformaciónpersonalobjetodetratamiento: '.	Right of access to your personal information subject to treatment. ',
  Derechoalaactualizacióndelosdatospersonalesobjetodetratamiento: '.	Right to update the personal data being processed. ',
  Derechoalarectificacióndelosdatospersonalesobjetodetratamiento: '.	Right to rectification of the personal data being processed. ',
  Derechodeoposiciónaquelosdatospersonalesseanobjetodetratamiento: '.	Right to object to the processing of personal data ',
  Derechoasolicitarlasupresióndelosdatospersonalescuandoeneltratamientonose: '.	Right to request the deletion of personal data when the principles, rights and constitutional and legal guarantees are not respected in the treatment. ',
  Derechoasolicitarpruebadelaautorizaciónotorgadaparaeltratamiento: '.	Right to request proof of the authorization granted for the treatment. ',
  Derechoarevocarelconsentimientoparaeltratamientodelosdatospersonales: '.	Right to revoke consent for the processing of personal data. ',
  DerechoapresentarquejasyreclamosantelaSuperintendenciadeIndustriayComerciopor: '.	Right to present complaints and claims before the Superintendency of Industry and Commerce for infractions to the provisions of Statutory Law 1581 of 2012 and the other regulations that modify, add, or complement it. ',
  DerechoaserinformadoporpartedelResponsableyoEncargadodelusoytratamientoquese: '.	Right to be informed by the Responsible and / or Person in Charge of the use and treatment that will be given to personal data, as well as of the modifications and updates of the protection policies, security measures and purposes',
  Identificacióndelresponsableyoencargadodeltratamiento: '. Identification of the person in charge and / or in charge of the treatment ',
  CoallySASesresponsabledeltratamientodesusdatospersonalesyharáusodeestosúnicamente: 'Coally S.A.S is responsible for the processing of your personal data and will use these only for the purposes for which it is empowered. Especially those indicated in this policy. ',
  CoallySASNIT9014205746: 'Coally S.A.S NIT: 901. 420 57 4 - 6 Address: Street 19 # 4 56 apartment 1403 Website: coally.com Email: coally3@gmail.com Telephone: 316 359 05 61',
  Deberesdelresponsabledelosdatospersonales: '. Duties of the person responsible for personal data. ',
  EnlíneaconlanormatividadvigenteentratamientodedatosCoallySASdeberácumplirconlos: 'In line with current regulations on data processing, Coally S.A.S must comply with the following duties:',
  Garantizaraltitularentodotiempoelplenoyefectivoejerciciodelderechodehábeasdata: '.	Guarantee the holder, at all times, the full and effective exercise of the right to habeas data ',

  Solicitaryconservarlareservayautorizaciónotorgadaporeltitular: '.	Request and keep the reservation and authorization granted by the owner ',
  Informaraltitularsobrelafinalidaddelarecoleccióndedatosylosderechosquele: '.	Inform the owner about the purpose of data collection and the rights that assist him by virtue of the authorization granted.',
  Conservarlainformaciónbajolascondicionesdeseguridadnecesariasparaimpedir: '.	Keep the information under the security conditions necessary to prevent adulteration, loss, or unauthorized access',
  Actualizarlainformaciónyadoptarlasmedidasnecesariasparaquelainformaciónsuministrada: '.	Update the information and adopt the necessary measures so that the information provided is kept up-to-date',
  Consultasyreclamos: '. Queries and complaints ',
  Lasconsultasquejasoreclamospodránserpresentadosatravésdeunmensajededatos: 'Queries, complaints or claims may be submitted through a data message: ',
  Consultas: 'Queries',
  TratándosedelderechodepeticiónencalidaddeinformaciónyoconsultasCoallySASdarárespuestaenuntérmino: 'In the case of the right of request as information and / or consultations, Coally S.A.S. It will respond within a maximum term of ten (10) business days from the day following the date of receipt of the request or inquiry.',
  Cuandonofuereposibleatenderlaconsultadentrodedichotérminoseinformaráalinteresado: 'When it is not possible to attend the query within said term, the interested party will be informed, indicating the reasons for the delay and indicating the date on which it will be attended, which in no case may exceed five (5) business days following the expiration of the first finished. The consultation document must contain the following:',
  ÁreadeCoallySASalacualvadirigidaServicioalCliente: '.	Coally S.A.S area to which it is addressed (Customer Service)',
  ElnombreynúmerodeidentificacióndelTitular: '.	The name and identification number of the Holder.',
  Copiadocumentodeidentidaddeltitular: '.	Copy of the holder identity document',
  Quejasyoreclamos: 'Complaints and / or claims',
  CuandoelTitularconsiderequesuinformacióndebeserobjetodecorrecciónactualizaciónosupresiónocuandoadvierta: 'When the Holder considers that his information should be corrected, updated or deleted or when he notices an alleged breach of any of his rights, the maximum term to attend the complaint or claim will be fifteen (15) business days from the day following the date of receipt of the document. ',
  Cuandonofuereposibleatenderelreclamodentrodedichotérminoseinformaráalinteresadolos: 'When it is not possible to attend the claim within said term, the interested party will be informed of the reasons for the delay and the date on which it will be attended, which in no case may exceed eight (8) business days following the expiration of the first term. If the claim is incomplete, the interested party will be required within five (5) days after receiving the complaint and / or claim to correct the faults. After two (2) months have elapsed from the date of the request without the applicant presenting the required information, the complaint or claim shall be deemed to have been withdrawn. The complaint or claim document must contain:',
  ÁreadeCoallySASalacualvadirigidaServicioalcliente: '.	Coally S.A.S area to which it is addressed (Customer Service)  ',
  Modificaciones: '. Modifications ',
  EsposiblequeactualicemoslapresentePolíticadeDatosocasionalmenteEncasoderealizarse: 'We may update this Data Policy from time to time. In case of changes, users will be notified through the application or the website. Therefore, accepting the use of the services after the notice of an update constitutes the user consent to the content of the update.',
  Vigencia: '. Validity ',
  LapresentePolíticaentraenvigenciaapartirdeldía01delmesdeEnerode2021: 'This Policy is effective as of January 1, 2021. ',
  LosDatosPersonalesqueseanalmacenadosutilizadosotransmitidospermaneceránenlasbasesdedatosdeCoallySAS: 'The Personal Data that is stored, used or transmitted will remain in the databases of Coally S.A.S for as long as is necessary to fulfill the purposes set out in this manual or for the Company to comply with its legal duties. ',
  Sinembargolainformaciónserárevisadacadaañoparaverificarlaveracidaddeldatoyfinalidad: 'However, the information will be reviewed every year to verify the veracity of the data and the purpose of continuing with its treatment. If necessary, Coally S.A.S reserves the right to modify this Policy unilaterally; Notwithstanding this, in the event of any substantial change to the Policy, it will be communicated to the Holders.',

  pagarnequi: 'Pay with nequi',

  Mensaje: 'Message',
  Vacio: 'void',
  Ingresatuemail: 'Enter your email',


  SoftwareDevelopment: 'Software Development',
  BusinessAnalystservices: 'Business Analyst services',
  Digitalmarketinganddesign: 'Digital marketing and design',

  AgronomíaVeterinariayafines: 'Agronomy, Veterinary and related',
  BellasArtes: 'Fine arts',
  CienciasdelaEducación: 'Education Sciences',
  CienciasdelaSalud: 'Health Sciences',
  CienciasSocialesyHumanas: 'Social and Human Sciences',
  EconomíaAdministraciónContaduríayafines: 'Economy, Administration, Accounting and related',
  IngenieríaArquitecturaUrbanismoyafines: 'Engineering, Architecture, Urbanism and related',
  MatemáticasyCienciasNaturales: 'Mathematics and Natural Sciences',
  Crearproyectoahoraypagardespués: 'Create project now and pay later',
  pagarconstripe: 'Pay with stripe',

  Modalidaddecontratatacion: 'Which contract type do you prefer?',
  parttime: 'Part time',
  fulltime: 'Full time',
  porproyecto: 'By project',
  vercv: 'View profile',
  impulsenegocio: 'Take your business to the next level',
  transformdigitla: 'with digital transformation',
  titulo: 'Title',
  Descripción: "Description",
  MeetURL: 'Meet URL',
  FechaInicio: 'Start date',
  FechaFin: 'End date',
  Ciudaddelproyecto: 'Project City',
  CorreoElectrónico: 'Email',
  Asuntoo: 'Subject',
  Turespuesta: 'Your answer',
  Comentario: 'Comment',
  Contraseña: 'Password',
  Nombre: 'Name',
  Problema: 'Problem',
  Profesion: 'Profession',
  Nombreproyecto: 'Project name',
  Ciudad: 'City',
  Necesidad: 'Need',
  Causas: 'Causes',
  Comentarioss: 'Comments',
  Presupuestototal: 'Total budget',
  Codigopromocional: 'Promo code',
  Busca: 'Search',
  ingresatucorreo: 'Enter your email',
  Enterthecompanyname: 'Enter company name',
  Deseaspublicaresteproyectodemaneraanonimai: 'enter: Do you want to publish this project anonymously?',
  Cuálestupresupuestoparaesteproyectoi: 'enter: What is your minimum budget for the project/role?',


  QuétansatisfechoestásconlaconsultoríallevadaacaboporCoally: 'How satisfied are you with the consulting carried out by Coally?',
  Muyinsatisfecho: 'Very unsatisfied',
  Insatisfecho: 'unsatisfied',
  Satisfecho: 'Satisfied',
  Muysatisfecho: 'Very satisfied',
  SinoestássatisfechoconeltrabajorealizadoquéconsiderasquepudieramejorarsiturespuestaesMuysatisfechoponNA: 'If you are not satisfied with the work done, what do you think could be improved? - if your answer is "Very satisfied" put N / A',
  Quétansatisfechoestásconeldesempeñoquetuvoelequipodetrabajo: 'How satisfied are you with the performance of the work team?',
  Quéniveldeinteraccióntuvoconlosconsultores: 'What level of interaction did you have with the consultants?',
  Nunca: 'Never',
  Pocasveces: 'Rarely',
  Lamayoriadelasveces: 'Most of the time',
  Siempre: 'Always',
  Quétansatisfechoestásconestainteracción: 'How satisfied are you with this interaction?',
  Conrespectoalacalidadpreciodelaconsultoríaconsiderasestar: 'Regarding the quality-price of the consultancy, you consider being:',
  Volveríasausarnuestrosservicios: 'Would you use our services again?',
  si: 'yes',
  Porque: 'Because?',
  Considerasquelaconsultoríarespondióatusnecesidades: 'Do you consider that the consultancy responded to your needs?',
  Quétansatisfechoestásconladuracióndeldesarrollodelproyecto: 'How satisfied are you with the duration of the project development?',
  AntesdeescogeraCoallyparallevaracaboelproyectotuvisteotraopción: 'Before choosing Coally to carry out the project, did you have a choice?',
  Silarespuestaesafirmativabajoquécriteriotomasteladecisión: 'If the answer is yes, under what criteria did you make the decision?',
  Siendo1lacalificaciónmásbajay4lamásaltacuáleselimpactopositivoquecreesquevasaobtenerdeestaconsultoría: 'With 1 being the lowest rating and 4 being the highest, what is the positive impact you think you will get from this consultancy?',
  CuáldelassiguientespalabrasutilizaríasparadescribirnuestroservicioSeleccionatodaslasopcionesqueconsideres: 'Which of the following words would you use to describe our service? - Select all the options you consider',
  Confiable: 'dependable',
  Innovador: 'Innovative',
  Ineficaz: 'Ineffective',
  Pocofiable: 'Not very reliable',
  Pocoinnovador: 'Little innovative',
  Leinteresaríacontrataraalgúnmiembrodelequipoquehizopartedeesteproyecto: 'Are you interested in hiring a member of the team that was part of this project?',
  Sisurespuestaesafirmativaaquiénleinteresaríavincular: 'If your answer is yes, who would you be interested in linking?',
  RecomendaríasCoallyatuscolegasuotrasempresas: 'Would you recommend Coally to your colleagues or other companies?',
  EnestecasoQuemensajeledejaríasafuturosclientesqueentrenanuestrapágina: 'In this case, what message would you leave to future clients who enter our page?',
  impulsatu: 'Take your business',
  impulsatu2: 'to the next level',
  trasnformaciondigital: 'with digital transformation',
  Profesiones: 'PROFESSIONS',
  Todos: 'All',

  Aceptado: 'Accepted',
  Rechazado: 'Rejected',
  Enesperatareas: 'Tasks on hold',
  Aceptar: 'Accept',
  Rechazar: 'Reject',
  Crearproyecto: 'Create Project',
  quenecesitas: 'Please give us a short description about the project/role',
  paginaweb: 'Web page',
  desarrollohecho: 'Development done',
  incrementoenventas: 'increase in sales',
  Tiendaonline: 'Online store',
  instrucciones: 'Instructions',


  Aceptacion: 'Acceptation',
  Clasificacionn: 'Rating',
  Enviarr: 'Send',
  Cargandooo: 'Loading...',
  Aceptarentrega: 'Accept delivery',
  Cancelacion: 'Cancellation',
  Motivo: 'Reason',
  Rechazarentrega: 'Refuse delivery',
  itappearsthattherewassanissussubmittingyourreuqest: 'It appears that there was an issue submitting your request',
  Success: 'Success',
  Yourrequestwassubmittedsuccesfully: 'Your request was submitted succesfully',
  Done: 'Done',
  Descargarplantillaaqui: 'Download template here',
  SubirTarea: 'Upload Task',
  Mensajee: 'Message:',
  DELIVERABLES: 'DELIVERABLES',
  Subircasodeexito: 'Upload success story',
  Reunion: 'Meeting',
  Mensajeeee: 'Message',
  Enviarmensaje: 'Send Message',
  Asuntooo: 'Affair',
  Enviaunmensajealaempresa: 'Send a message to the company',
  Subirproyecto: 'Upload project',
  Proyecto: 'Project',
  Subircasodeexitooo: 'Upload success story',
  Terminado: 'Finished',
  porquequieresterminar: 'Why do you want to finish?',
  Creaunareuniónconlaempresa: 'Create a meeting with the company',
  Nuevareunión: 'New meeting',
  Titulooo: 'Title',
  Descripcionnn: 'Description',
  Fechaaa: 'Date',
  Hora: 'Hour',
  Enlacedereunion: 'Meeting link',
  Creaunareunionconlaempresa: 'Crea una reunion con la empresa',
  COMENTARIOSSOBRETUENTREGABLE: 'COMMENTS ABOUT YOUR DELIVERY:',
  Subirarchivo: 'Upload file',
  FormatoPDF: 'Format PDF',
  Enviaunmensajeatuequipodetrabajo: 'Send a message to your work team',
  Terminarproyecto: 'Finish project',
  Porqueterminasteelproyecto: 'Why did you finish the project?',
  Yatengotodosmisentregables: 'I already have all my deliverables',
  Devoluciondepago: 'Return of payment',
  Finalizarproyecto: 'Finish project',
  Consultorprofilee: 'Profile consultant',
  EMPRESAaa: 'COMPANY',
  CORREOoo: 'MAIL',
  NOMBREee: 'NAME',
  FECHACREACION: 'CREATION DATE',
  FECHAPAGO: 'PAY DAY',
  MONTO: 'AMOUNT',



  Empieceaaplicarsus: 'Start applying your ',
  conocimientos: 'knowledge',
  SeaunconsultorenCoally: 'Be a consultant at Coally',
  Buscaproyectosquenecesites: 'Look for your next project',
  enprocesoo: 'In process',
  enesperaa: 'On hold',
  finalizadoo: 'Finalized',
  lugarr: 'Place',
  buscarr2: "Find professionals",
  verperfil: 'View profile',
  Profesionales: 'Professionals',
  Entregas2: 'Deliveries',
  Finalizarr: 'Finalize',
  telefonocontacto: '+1 (754) 267-0644',
  Tarearechazada: 'Task rejected',
  Motivo0: 'Reason',
  Contrataelmejor: 'Hire the best latin talent to take your projects to the next level',
  Trabajaremoto: 'Work remotely, gain experience and build your dreams',
  PLATAFORMABAJODEMANDAPARA: 'ON DEMAND PLATFORM FOR',
  BUSINESSTRANSFORMATIONSERVICES: 'BUSINESS TRANSFORMATION SERVICES',
  Pagarconwenjoy: 'Pay with wenjoy',
  Duration2: 'Duration (months)',
  agregarotro: 'Add other',
  Coallymantieneeldinerodentrodesuplataforma2: 'It is important that you know that in our terms and conditions we list that any modification with the conditions posted in this form will have consequences in time and cost, by posting you agree to it',
  Recomendaciones: 'Recommendations',
  Aplicara: 'Apply',
  Quieresaplicara: 'You want to apply to ',
  Cancelar: 'Cancel',
  Confirmar: 'Confirm',
  EligeTuRespuesta: 'choose your answer',
  creatucuentaa: 'Create your account',
  recuperarcontrasena: 'recover password',
  describeenelmayordetalletuobjetivo: 'please describe with the most detail your goal, if you want please send us a voicenote with the detailed information you require',
  deseasincorporarunadescripcióndetalladadelospasosrequeridos: 'Want to add a detailed description of the steps required',
  pasos: "Step",
  agregarotraetapa: "Add another step",
  hometexto1: "Work in your professional area and apply what you have learnt",
  hometexto2: "We give you work opportunities so you can probe the skills you have learnt academically",
  hometexto3: "Find your next opportunity",
  oportunidadess: "Opportunities",
  Sieresnuevousuario: "If you are a new user",
  Iniciosesión: "Login",
  Recoverypassword: "Recovery password",
  BienvenidoaCoally: "Welcome to Coally!",
  Impulsatunegocioalsiguientenivel: "Take your business to the next level.",
  TusOportunidades: 'Your opportunities',
  Estado: 'State',
  Modificar: 'Change',
  TipsDeReclutamiento: 'Recruitment Tips',
  OnboardingOInducción: 'Onboarding or Induction',
  AnálisisDeDesempeño: 'Performance Analysis',
  FuturoDelTrabajo: 'Future of Work',
  Fórmateconnosotros: 'Learn with us',
  BúsquedadeCandidatos: 'Candidate Search',
  CvyCartaDePresentacion: 'CV and Presentation Letter',
  Entrevistas: 'Job Interview',
  PerfilProfesional: 'Professional Profile',
  TipsDeDesempeño: 'Performance Tips',
  ExperienciadelCandidato: 'Candidate Experience',
  Diversidadeinclusión: 'Diversity and inclusion',
  Eficiencia: 'Efficiency',
  Oportunidades: 'Opportunities',
  Publicadas: 'published',
  Profesionalesconconocimientoen: 'Professionals with knowledge in',
  EnEspera: 'On hold',
  Finalizadas: 'Finished',
  Inicio: 'Home',
  PublicarOportunidad: 'Post Opportunity',
  goback: 'Go back',
  iniciarsesion: 'login',
  registro: 'Registration',
  llenarformulario: 'Fill form',
  Queteapasionadetutrabajo: 'What do you love about your job?',
  SubirCV: 'Upload CV',
  Proyectooooo: 'Project',
  Fechadeinicio: 'Start date',
  Profesional:"Professional",
  Empresaaaaa:'Business',
  // nuevo
  BienvenidoaCoallyv1:"Welcome to Coally!",
  Impulsatunegocioalsiguientenivelv1:"Take your business to the next level.",
  TusOportunidadesv1:"Your opportunities",
  Habilidadesv1:"Skills",
  Vermásv1:"See more",
  Modificarv1:"Modify",  
Descripcióndelproyectov1:"Project description",
Salariov1:"Salary",
Salarionodefinidov1:"Unspecified salary",
Descripciónnoespecificadav1:"Unspecified description",
Carrerav1:"Career",
Carreranoespecificadav1:"Unspecified career",
Modalidaddecontrataciónv1:"Modality of contracting",
Modalidadnoespecificadav1:"Unspecified Modality",
Habilidadesnoespecificadasv1:"Unspecified skills",
Inglesrequeridov1:"English required.",
Linkalaoportunidadv1:"Link to the opportunity",
Editaroportunidadv1:"Edit opportunity",
Guardarv1:"Save",
Cancelarv1:"Cancel",
Filtrosv1:"Filters",
NombredelaAZv1:"Name from A to Z",
NombredelaZAv1:"Name from Z to A",
Porcalificaciónv1:"Rating",
Confirmadov1:"Confirmed",
estáenentrevistav1:"Is in interview",
Trabajaentuáreadeconocimientoyaplicaloaprendidov1:"Work in your area of ​​knowledge and apply what you have learned",
Resultadosv1:"Results",
Aplicarv1:"Apply",
Oportunidadesv1:"Opportunities",
listav1:"list",
Totalv1:"Total",
Enesperav1:"On hold",
Iniciadosv1:"initiates",
Finalizadosv1:"Finished",
Progresov1:"Progress",
Verdetallesv1:"See details",
Enprocesov1:"In process",
Completadov1:"complete",
Yaaplicastev1:"You already applied",
llenarformulariov1:"Fill form",
Queteapasionadetutrabajov1:"What do you love about your job?",
SubirCVpdfdescargadodelinkedinv1:"Upload CV (pdf downloaded from linkedin)",
Llenamanualmenteelformulario:"Manually fill out the form",
Continuarv1:"Continue",
Otrasoportunidadesv1:"Other opportunities",
Acercadev1:"About",
Educaciónv1:"Education",
Experienciav1:"Experience",
OportunidadesFinalizadas:"Opportunities Completed",
empresav1:"Business",
AdministradordeEmpresasv1:"Business administrator",
Idiomav1:"language",
Guardarv2:"Save!",
Subirhojadevida:"Upload resume",
Cargatuhojadevidav1:"Upload your resume",
Subirv1:"Upload",
RecuerdacompletartuperfilconelTestdePersonalidadv1:"Remember to complete your profile with the Personality Test!",
irahorav1:"go now",
PuntuarTestv1:"Score Quiz",
MásTestv1:"More Test",
Siyaterminastetutestdepersonalizadadingresaturesultadov1:"If you have already finished your personality test, enter your result:",
Enviarv1:"Send",
Iniciov1:"Home",
Formaciónv1:"Training",
Cerrarsesiónv1:"Log out",


/* terms and conditions */
 //no se usa
 tycT: 'Términos y Condiciones de Uso de la Plataforma coally',
 thisdoc: 'El presente documento establece las condiciones mediante las cuales se regirá el uso de la aplicación móvil: coally Colombia (en adelante la plataforma), la cual es operada por coally S.A.S compañía constituida en Colombia y domiciliada en la ciudad de Bogotá.',
 carring: 'La aplicación funcionará como un nuevo canal para la realización de ciertas actividades descritas más adelante, con el objeto de conectar las necesidades de quienes se suscriban como clientes (en adelante Clientes), y quienes se suscriban como Prestadores de servicio (en adelante Consultor).',
 comousted: 'Como usuario usted se compromete a leer los términos y condiciones aquí establecidos antes de hacer uso de la plataforma. Una vez ingrese a ésta, bien sea como Cliente o como Consultor, usted consiente quedar vinculado por estas Condiciones y por la Política de Protección de Datos personales, por lo que, si no está de acuerdo con todas las condiciones o las políticas acá establecidas no debe hacer uso de la plataforma.',
 cuertoparr: 'El usuario reconoce que el ingreso de su información, y los datos que contiene la aplicación, se realizan de manera voluntaria. Quienes optan por acceder a esta aplicación en Colombia o desde fuera del territorio nacional, lo hacen por iniciativa propia y son responsables del cumplimiento de las leyes locales, en la medida en que dichas leyes sean aplicables a su correspondiente país. El uso de esta plataforma está limitado a mayores de edad. En el evento en que no se cumpla esta condición, le agradecemos no hacer uso de la aplicación.',
 //se usa
 servicio: '1. Presentación',
 esunaplataforma: 'COALLY S.A.S. es una compañía colombiana que tiene como actividad principal\n' +
   'conectar empresas y/o personas naturales con profesionales jóvenes por medio\n' +
   'de una plataforma virtual, compuesta por una página web y una aplicación móvil,\n' +
   'para que se desarrolle un servicio de consultoría que aporte valor a los clientes. (\n' +
   'empresas y/o personas naturales).',
 siustedescon: 'COALLY S.A.S., con la finalidad de dar cumplimiento estricto a la normatividad\n' +
   'vigente de protección de Datos Personales, de acuerdo a lo establecido en la Ley\n' +
   '1581 del 2012, Decreto 1074 de 2015 y demás disposiciones que las modifiquen,\n' +
   'adicionen o complementen, presenta la siguiente POLÍTICA DE PROTECCIÓN Y\n' +
   'TRATAMIENTO DE DATOS PERSONALES (en adelante “Política de\n' +
   'Tratamiento”) con el propósito de proteger la información personal brindada por los\n' +
   'Titulares que tengan relación con COALLY S.A.S. como lo son clientes,\n' +
   'consultores y cualquier otra persona natural o jurídica de la cual COALLY S.A.S.,\n' +
   'obtenga, recolecta, procesa o trate datos personales, sea que dicho tratamiento\n' +
   'sea realizado por COALLY S.A.S. o por terceras personas que lo hagan por\n' +
   'encargo de ésta.',
 adicional: 'El objeto de la Política de Tratamiento es proteger el derecho constitucional del\n' +
   'Habeas Data que tienen todas las personas para conocer, actualizar, y rectificar la\n' +
   'información que se haya recogido y almacenado en las distintas bases de datos\n' +
   'de COALLY S.A.S., y en virtud del cumplimiento de dicho derecho sólo recolecta y\n' +
   'da Tratamiento a Datos Personales, cuando así haya sido autorizado previamente\n' +
   'por su Titular, implementando para tal efecto, medidas claras sobre\n' +
   'confidencialidad y privacidad de los Datos Personales. Así mismo, detalla los\n' +
   'lineamientos generales corporativos que se tienen en cuenta con el fin de proteger\n' +
   'los Datos Personales de los Titulares.',
 //no se usa
 siustedes: ' b.	Si usted es Cliente deberá registrarse como tal y hacer uso de esta plataforma para conectarse con los Consultores que estén disponibles para prestar un servicio de consultoría. Para esto, usted deberá publicar un proyecto acorde a sus necesidades. Posteriormente, usted será conectado con un Consultor de acuerdo con la convergencia entre sus necesidades y las capacidades del Consultor.',
 portanto: 'Por tanto el servicio que ofrece la plataforma se limita a ser uno de intermediación entre las partes mencionadas anteriormente, con la intención de suplir las necesidades inherentes a las mismas. Por medio de esta plataforma se accede al contrato a suscribir entre Consultor, Consultor Gold, y Cliente. Al aceptar estos Términos usted acepta que coally vincula a las partes involucradas en el proyecto –entiéndase Cliente con Consultor, Consultor Gold– por medio de un contrato de prestación de servicios, del que esta compañía no hace parte; y del que deriva una comisión por intermediación para coally y los honorarios estipulados en el contrato de prestación de servicio para para los prestadores. Dicha vinculación surte efecto una vez el algoritmo de la aplicación haya establecido el equipo consultor encargado de desarrollar la consultoría solicitada por el Cliente.',
 adicionalment: 'Adicionalmente al aceptar estos términos usted entiende que esta plataforma no cumple el rol de empleador, por lo que ningún Consultor, o Consultor Gold gozará de la calidad trabajador de la compañía.',
 //se usa
 alcanceyusu: '2. Ámbito de aplicación',
 paraquellos: 'Estos términos y condiciones y todo lo que tenga que ver con esta aplicación y las\n' +
   'relaciones jurídicas que surgen de esta, se rigen por las leyes colombianas,\n' +
   'específicamente a la Ley 1581 del 2012 y el Decreto 1074 de 2015',
 //no se usa
 paragold: 'Para aquellos usuarios que se registren como Gold Con el fin de usar los servicios ofrecidos por la plataforma, usted deberá registrarse como persona natural, mayor de edad. Para esta categoría usted puede ser estudiante universitario de últimos semestres, recién graduado o profesional. Adicionalmente, usted entiende por medio de estos términos y condiciones que la presente categoría solo se habilita si ha participado anteriormente como Consultor tres (3) veces, dadas las exigencias de la posición. Usted se compromete a mantener la información en su Cuenta de forma exacta, completa y actualizada; de lo contrario, usted no podrá acceder y utilizar los servicios proporcionados por la plataforma, y devendrá la resolución del presente contrato. Usted es responsable de toda la actividad que ocurre en su Cuenta y se compromete a mantener en todo momento de forma segura y secreta el nombre de usuario y la contraseña de su Cuenta. A menos que coally  permita otra cosa por escrito, usted solo puede poseer una Cuenta.',
 paraclientes: 'Para aquellos usuarios que se registren como Clientes: Con el fin de usar los servicios ofrecidos por la plataforma, usted deberá registrarse como persona jurídica e ingresar los datos correspondientes a esta; o podrá registrarse como persona natural y proporcionar cierta información personal como su nombre, dirección, número de teléfono móvil. El registro como persona jurídica igualmente requiere que usted comunique a coally cierta información empresarial como el nombre, la dirección, y el número de contacto entre otros. Usted se compromete a mantener la información en su Cuenta de forma exacta, completa y actualizada; de lo contrario, usted no podrá acceder y utilizar los servicios proporcionados por la plataforma, y devendrá la resolución del presente contrato. Usted es responsable e toda la actividad que ocurre en su Cuenta y se compromete a mantener en todo momento de forma segura y secreta el nombre de usuario y la contraseña de su Cuenta.',
 //se usa
 requisitosuso: '3. Definiciones',
 elusuariodeber: 'a. Titular: Persona natural cuyos datos son objeto de tratamiento. Para de\n' +
   'la presente política de tratamiento de datos personales los titulares\n' +
   'podrán ser: (i) Clientes de la plataforma; (ii) Consultores y todas\n' +
   'aquellas personas no vinculadas a COALLY',
 paraacceder: 'b. Cliente: Persona natural o jurídica',
 paraacceder2: 'c. Usuario: toda persona que haya descargado y haga uso de la plataforma ya sea como cliente o como consultor.',
 paraacceder3: 'd. Plataforma: la recolección de datos por parte Coally S.A.S será a través de las plataformas Coally App y Coally Web utilizadas para el desarrollo de la actividad de esta compañía.',
 paraacceder4: 'e. Dato personal: Cualquier información vinculada o que pueda asociarse\n' +
   'a una o varias personas naturales determinadas o determinables.',
 paraacceder5: 'f. Base de Datos: Conjunto organizado de datos que sean objeto de\n' +
   'Tratamiento;',
 paraacceder6: 'g. Base de datos personales: Conjunto organizado de datos personales\n' +
   'que son objeto de tratamiento por una persona natural o jurídica.',
 paraacceder7: 'h. Dato sensible: Es aquel dato personal que afecta la intimidad del Titular\n' +
   'y cuyo uso incorrecto podría generar discriminación. Son considerados\n' +
   'datos sensibles entre otros, los datos de salud, los datos de orientación\n' +
   'sexual, origen racial y étnico, opiniones políticas, convicciones\n' +
   'religiosas, filosóficas o morales.',
 paraacceder8: 'i. Dato privado: Es aquel dato personal que por su carácter íntimo o\n' +
   'reservado es relevante para el Titular.',
 paraacceder9: 'j. Dato Semiprivado: Es aquel dato personal conocido y de interés tanto para el titular como para un determinado sector de personas o para la sociedad en general, por lo que no es de carácter íntimo, reservado o público.',
 paraacceder10: 'k. Dato público: Es aquel dato personal calificado como tal según la\n' +
   'Constitución y la ley, y que no se ha clasificado como dato personal\n' +
   'privado o semiprivado.',
 paraacceder11: 'l. Habeas data: Es el derecho que tiene el Titular de los datos personales\n' +
   'de exigir de las administradoras de los mismos el acceso, inclusión,\n' +
   'exclusión, corrección, adición, actualización y rectificación de los datos,\n' +
   'así como la limitación en su divulgación, publicación o cesión.',
 paraacceder12: 'm. Autorización: Consentimiento previo, expreso e informado del Titular\n' +
   'para llevar a cabo el tratamiento de datos personales.',
 paraacceder13: 'n. Aviso de privacidad: Comunicación verbal o escrita dirigida a los\n' +
   'Titulares de los datos personales que están siendo tratados por la\n' +
   'empresa, en la cual se le informa acerca de la existencia de las políticas\n' +
   'de tratamiento de datos personales que le serán aplicadas, la forma de\n' +
   'acceder a la mismas, y las finalidades para las cuales serán usados sus\n' +
   'datos personales.',
 paraacceder14: 'o. Tratamiento: Cualquier operación o conjunto de operaciones sobre\n' +
   'datos personales, tales como la recolección, almacenamiento, uso,\n' +
   'circulación o supresión.',
 paraacceder15: 'p. Responsable del tratamiento: Persona natural o jurídica de carácter\n' +
   'público o privado que por sí misma o en asocio con otro u otros decide\n' +
   'sobre el tratamiento de datos personales. En este caso, será COALLY\n' +
   'S.A.S. el Responsable del tratamiento.',
 //se usa
 obligacionesusuario: '4. Principios rectores para el tratamiento de los datos personales',
 seobligaa: 'Según lo establecido en el Título II de la Ley Estatutaria 1581 de 2012, la\n' +
   'protección de datos personales se regirá por la aplicación armónica e integral de\n' +
   'los siguientes principios:',
 Utilizarlosconteni: 'a. Principio de legalidad en el tratamiento de datos personales: El\n' +
   'tratamiento de datos personales a que se refiere la Ley Estatutaria 1581 de\n' +
   '2012 es una actividad reglada que debe sujetarse a lo establecido en ella y\n' +
   'en las demás disposiciones que la desarrollen.',
 Utilizarlosconteni2: 'b. Principio de finalidad: El tratamiento de los datos personales debe obedecer a una finalidad legítima de acuerdo con la Constitución y la ley, la cual debe ser informada al Titular.',
 Utilizarlosconteni3: 'c. Principio de libertad: El tratamiento de los datos personales sólo puede ejercerse con el consentimiento, previo, expreso e informado del Titular.Los datos personales no podrán ser obtenidos o divulgados sin previa autorización, o en ausencia de mandato legal o judicial que releve el consentimiento.',
 Utilizarlosconteni4: 'd. Principio de veracidad o calidad: La información sujeta a tratamiento debe ser veraz, completa, exacta, actualizada, comprobable y comprensible. Se prohíbe el Tratamiento de datos parciales, incompletos, fraccionados o que induzcan a error.',
 Utilizarlosconteni5: 'e. Principio de transparencia: En el Tratamiento de los datos personales debe garantizarse el derecho del Titular a obtener del Responsable del tratamiento o del Encargado del tratamiento, en cualquier momento y sin restricciones, información acerca de la existencia de datos que le concierne.',
 Utilizarlosconteni6: 'f. Principio de seguridad: La información sujeta a tratamiento por el Responsable del Tratamiento o Encargado del tratamiento a que se refiere la Ley Estatutaria 1581 de 2012, se deberá manejar con las medidas técnicas, humanas y administrativas que sean necesarias para otorgar seguridad a los registros evitando su adulteración, pérdida, consulta, uso o acceso no autorizado o fraudulento.',
 Utilizarlosconteni7: 'g. Principio de confidencialidad: Todas las personas que intervengan en el tratamiento de datos personales que no tengan la naturaleza de públicos están obligadas a garantizar la reserva de la información, inclusive después de finalizada su relación con alguna de las labores que comprende el tratamiento, pudiendo sólo realizar suministro o comunicación de datos personales cuando ello corresponda al desarrollo de las actividades autorizadas en la Ley Estatutaria 1581 de 2012 y en los términos de la misma.',
 Utilizarlosconteni8: 'h. Principio de acceso y circulación restringida: El tratamiento se sujeta a los límites que se derivan de la naturaleza de los datos personales, de las disposiciones de la Ley Estatutaria 1581 de 2012 y la Constitución. En este sentido, el tratamiento sólo podrá hacerse por personas autorizadas por el Titular y/o por las personas previstas en la mencionada ley.',
 //se usa
 condiparacli: '5. Autorización para el tratamiento de datos personales y casos en\n' +
   'los que no es necesaria la autorización',
 alentrarcon: 'El consentimiento y autorización por parte del Titular de la información es un\n' +
   'requisito constitucional y legal que deben cumplir las personas responsables del\n' +
   'tratamiento de datos personales. El consentimiento debe cumplir con los\n' +
   'siguientes presupuestos:',
 ionalmentesiust: 'Previo: La autorización la debe dar el Titular de información de manera previa a\n' +
   'cualquier tipo de Tratamiento de datos personales.',
 ionalmentesiust2: 'Expreso: La autorización debe otorgarse de forma inequívoca, clara y específica.',
 ionalmentesiust3: 'Informado: El Titular debe comprender claramente para qué serán tratados sus\n' +
   'datos personales y las finalidades que pueden derivarse del Tratamiento de los\n' +
   'mismos.',
 ionalmentesiust4: 'Todos los visitantes de las Plataformas de COALLY S.A.S. deben registrarse y autorizar el tratamiento de los datos personales para poder hacer uso de los servicios ofrecidos. Por tanto, en cada uno de los sistemas se encuentra una casilla que dice “Política de privacidad y Tratamiento de Datos Personales” la cual debe ser leída y aceptada para poder continuar con el uso de los servicios de COALLY S.A.S.',
 //se usa
 condiconsulto: '6. TIPOS DE DATOS RECOPILADOS POR COALLY S.A.S',
 alentraralaplat: 'Coally S.A.S recopilará datos proporcionados por los usuarios de Coally, como los\n' +
   'generados durante la creación de cuentas, aquellos proporcionados en el uso de\n' +
   'los servicios.',
 Adicionalmentealaceptares: 'a. Perfil del usuario: datos generados cuando se crean o actualizan las\n' +
   'cuentas. Esto puede incluir nombre, correo electrónico, número de teléfono,\n' +
   'nombre de usuario, contraseña, documento de identificación, fotos de perfil.',
 SiustedesConsultoresta: 'b. Datos demográficos: se recopilarán datos demográficos de los usuarios\n' +
   'como dirección, edad, género, lugar de residencia, carrera y áreas de\n' +
   '\n' +
   'interés. También podrá recolectarse a través de las encuestas destinadas a\n' +
   'ello',
 Seguirlaguiaque: 'c. Datos de uso: se recopilan datos sobre el servicio prestado o el servicio\n' +
   'solicitado, esto incluye el tipo de servicio solicitado o prestado, detalles del\n' +
   'servicio, información sobre el momento en el que se prestó el servicio y la\n' +
   'frecuencia de solicitud o prestación del mismo.',
 Seguirlaguiaque2: 'd. Otros datos: comentarios del cliente, calificaciones y opiniones del servicio prestado.',
 //se usa
 condigold: '7. Finalidad de tratamiento de los datos personales',
 Alaceptarestostérmi: 'Los Datos Personales que recolecta  Coally S.A.S., son incluidos en una Base de\n' +
   'Datos a la cual tiene acceso el personal autorizado de COALLY S.A.S. en\n' +
   'ejercicio de sus funciones, advirtiendo que en ningún caso está autorizado el\n' +
   'Tratamiento de la información para fines diferentes a los aquí descritos, y que le\n' +
   'sean comunicados al Titular directamente a más tardar al momento de la\n' +
   'recolección.',
 Adicionalmentesuste: 'La recolección, almacenamiento, uso y/o circulación de los datos personales\n' +
   'recolectados por Coally S.A.S tiene como finalidad principal prestar los servicios\n' +
   'ofrecidos de manera adecuada y lograr el cumplimiento de las expectativas de los\n' +
   'usuarios de la plataforma web y móvil.',
 Adicionalmentesuste2: 'Adicionalmente, con el fin de desarrollar los objetos social de la compañía, Coally S.A.S podrá hacer tratamiento de datos con las siguientes finalidades:',
 Adicionalmentesuste3: 'Finalidades del tratamiento de los datos personales de los Usuarios',
 Adicionalmentesuste4: '1. Recolectar los datos personales de los usuarios e incorporarlos y\n' +
   'almacenarlos en la base de datos de Coally S.A.S.',
 Adicionalmentesuste5: '2. Ordenar, catalogar, clasificar, dividir o separar la información suministrada.',
 Adicionalmentesuste6: '3. Utilizar los datos suministrados en campañas de comunicación, divulgación\n' +
   'y promoción u oferta de productos, actividades y/o servicios desarrollados\n' +
   'como parte de estrategias internas de la compañía.',
 Adicionalmentesuste7: '4. Conservar registros históricos de la compañía y mantener contacto con los titulares del dato.',
 Adicionalmentesuste8: '5. Verificar, comprobar o validar los datos suministrados.',
 Adicionalmentesuste9: '6. Transferir los datos personales a cualquier país o servidor en otro país.',
 Adicionalmentesuste10: '7. Compartir, transferir los datos personales a aliados y a los aliados de nuestros aliados.',
 Adicionalmentesuste11: '8.  Comunicar y permitir el acceso a los datos personales suministrados a terceros proveedores de servicios de apoyo general y a las personas naturales o jurídicas accionistas de COALLY S.A.S.',
 Adicionalmentesuste12: '9. Realizar de conformidad con la ley los reportes a centrales de riesgo por incumplimiento de las obligaciones financieras derivadas de la relación comercial.',
 Adicionalmentesuste13: '10. Envío de comunicaciones a través de cualquier canal incluyendo, pero sin limitarse a, redes sociales, mensajes de texto, notificaciones push, correo electrónico, llamada telefónica, etc., relacionadas con el objeto social de COALLY S.A.S. tales como actividades de mercadeo y/o cualquier solicitud realizada por el titular a COALLY S.A.S, entre otros.',
 Adicionalmentesuste14: '11. Prestar los servicios ofrecidos y/o contratados de forma adecuada y con una excelente calidad',
  Adicionalmentesuste15: '12. Recomendar de acuerdo a la información facilitada por el consultor, proyectos específicos que responda a sus gustos, preferencias o necesidad',

 aceptasentrega: 'Aceptas los resultados de la primera entrega para poder continuar con el proceso',
//TYC
  //se usa
  TérminosyCondicionesdeUsodelaPlataformacoally: 'Términos y Condiciones de Uso de la Plataforma coally',
  tyc1: '8. Normatividad aplicable',
  tyc2: 'El tratamiento de los Datos personales del titular se realizará con base en lo\n' +
    'dictado en la presente política de privacidad y lo estipulado en la ley 1581 de\n' +
    '2012, el Decreto 1377 de 2013, el Decreto 886 de 2014, normas compiladas en el\n' +
    'Decreto 1074 de 2015 de la República de Colombia, y las demás normas que\n' +
    'adicionen o modifiquen.',
  //no se usa
  tyc3: 'Exceptuando lo expresamente estipulado en estos Términos y Condiciones, queda prohibido todo acto de copia, reproducción, modificación, creación de trabajos derivados, venta o distribución, exhibición de los contenidos de esta aplicación, de manera o por medio alguno, incluyendo, más no limitado a, medios electrónicos, mecánicos, de fotocopiado, de grabación o de cualquier otra índole, sin el permiso previo y por escrito de coally o del titular de los respectivos derechos que serán exclusivamente los clientes de coally.',
  tyc4: 'En ningún caso estos Términos y Condiciones confieren derechos, licencias ni autorizaciones para realizar los actos anteriormente prohibidos. Cualquier uso no autorizado de los contenidos constituirá una violación del presente documento y a las normas vigentes sobre derechos de autor, a las normas vigentes nacionales e internacionales sobre Propiedad Industrial, y a cualquier otra que sea aplicable.',
  //se usa
  tyc5: '9. Derechos de los titulares',
  tyc6: 'En cumplimiento de las garantías fundamentales consagradas en la Constitución y\n' +
    'la ley, y sin perjuicio de lo dispuesto en las demás normas que regulen la materia,\n' +
    'los Titulares de los datos personales podrán ejercer de forma gratuita e\n' +
    'ilimitadamente los siguientes derechos:',
  tyc7: 'a. Derecho al acceso a su información personal objeto de tratamiento.',
  tyc71: 'b. Derecho a la actualización de los datos personales objeto de tratamiento.',
  tyc72: 'c. Derecho a la rectificación de los datos personales objeto de tratamiento.',
  tyc73: 'd. Derecho de oposición a que los datos personales sean objeto de\n' +
    'tratamiento',
  tyc74: 'e. Derecho a solicitar la supresión de los datos personales cuando en el\n' +
    'tratamiento no se respeten los principios, derechos y garantías\n' +
    'constitucionales y legales.',
  tyc75: 'f. Derecho a solicitar prueba de la autorización otorgada para el\n' +
    'tratamiento.',
  tyc76: 'g. Derecho a revocar el consentimiento para el tratamiento de los datos\n' +
    'personales.',
  tyc77: 'h. Derecho a presentar quejas y reclamos ante la Superintendencia de\n' +
    'Industria y Comercio por infracciones a lo dispuesto en la Ley Estatutaria\n' +
    '1581 de 2012 y las demás normas que la modifiquen, adicionen o\n' +
    'complementen.',
  tyc78: 'i. Derecho a ser informado por parte del Responsable y/o Encargado del\n' +
    'uso y tratamiento que se les dará a los datos personales, así como de las\n' +
    'modificaciones y actualizaciones de las políticas de protección, medidas de\n' +
    'seguridad y finalidades.',
  //no se usa
  tyc8: 'Adicionalmente, al aceptar estos Términos y Condiciones usted entiende que la información recolectada por la plataforma, es suministrada de forma libre y voluntaria, para que esta sea administrada por la plataforma. Así mismo, los datos recolectados podrán ser objeto de análisis para fines de mejorar la estrategia de negocios de la plataforma, apoyada en herramientas de inteligencia de negocios y minería de datos, que permiten adquirir conocimientos prospectivos para fines de predicción, clasificación y segmentación.',
  tyc9: 'El Consumidor podrá ejercer su derecho de conocer, actualizar, modificar y suprimir los datos personales existentes en las bases de datos asociadas a la Plataforma. Para ello deberá realizar la solicitud de consulta, reclamo o supresión a la dirección electrónica Coally3@gmail.com detallando las modificaciones a realizar y aportando los documentos que lo soportan.',
  tyc10: 'Coally S.A.S es responsable del tratamiento de la información personal recolectada a través del portal web, responsabilidad que podrá delegar en un tercero, en calidad de responsable o encargado de la información, asegurando contractualmente el adecuado tratamiento de la misma.',
  //se usa
  tyc11: '10. Identificación del responsable y/o encargado del tratamiento:',
  tyc12: 'Coally S.A.S es responsable del tratamiento de sus datos personales y hará uso\n' +
    'de estos únicamente para las finalidades que se encuentra facultado.\n' +
    'Especialmente las señaladas en la presente política',
  tyc13: 'Coally S.A.S\n' +
    'NIT: 901. 420 57 4 - 6\n' +
    'Domicilio: calle 19 #4 56 apartamento 1403\n' +
    'Sitio web: coally.com\n' +
    'Correo electrónico: coally3@gmail.com\n' +
    'Teléfono: 316 359 05 61',
  //no se usa
  tyc14: 'Coally no será responsable por: a) Fuerza mayor o caso fortuito; b) Por la pérdida, extravío o hurto de su dispositivo móvil que implique el acceso de terceros a la aplicación móvil; c) Por errores en la digitación o por fallas de conectividad por parte del usuario, que impidan el normal desarrollo de la consultoría dentro de los tiempos establecidos; d) Por los perjuicios, lucro cesante, daño emergente o moral, y en general sumas a cargo de Coally por los retrasos, no procesamiento de información o suspensión del servicio del operador móvil o daños en los dispositivos móviles.',
  tyc15: 'Coally no  compromete su responsabilidad frente a los servicios que se comercialicen por estos terceros, ya que la Plataforma sirve como canal de comunicación y publicidad, mas no como herramienta de prestación de servicios. En consecuencia, es total responsabilidad de los Consumidores el acceso a los sitios que remite la publicidad, asumiendo la obligación de verificar y conocer los términos de los servicios ofrecidos por los terceros.',
  //se usa
  tyc16: '11. Deberes del responsable de los datos personales.',
  tyc17: 'En línea con la normatividad vigente en tratamiento de datos Coally S.A.S deberá\n' +
    'cumplir con los siguientes deberes:',
  tyc171: 'a. Garantizar al titular, en todo tiempo, el pleno y efectivo ejercicio del derecho de hábeas data',
  tyc172: 'b. Solicitar y conservar la reserva y autorización otorgada por el titular',
  tyc173: 'c. Informar al titular sobre la finalidad de la recolección de datos y los derechos que le asisten en virtud de la autorización otorgada.',
  tyc174: 'd. Conservar la información bajo las condiciones de seguridad necesarias para impedir adulteración, pérdida, o acceso no autorizado',
  tyc175: 'e. Actualizar la información y adoptar las medidas necesarias para que la información suministrada se mantenga actualizada',
  //se usa
  tyc18: '12. Consultas y reclamos',
  tyc19: 'Las consultas, quejas o reclamos podrán ser presentados a través de un mensaje\n' +
    'de datos:',
  tyc20: 'Consultas',
  tyc21: 'Tratándose del derecho de petición en calidad de información y/o consultas, Coally\n' +
    'S.A.S. dará respuesta en un término máximo de diez (10) días hábiles contados a\n' +
    'partir del día siguiente a la fecha de recepción de la petición o consulta.',
  tyc22: 'Cuando no fuere posible atender la consulta dentro de dicho término se informará\n' +
    'al interesado, indicando los motivos de la demora y señalando la fecha en que\n' +
    'esta se atenderá, la cual en ningún caso podrá superar cinco (5) días hábiles\n' +
    'siguientes al vencimiento del primer término. El documento de consulta deberá\n' +
    'contener lo siguiente:',
  tyc221: '1. Área de Coally S.A.S a la cual va dirigida (Servicio al Cliente)',
  tyc2212: '2. El nombre y número de identificación del Titular.',
  tyc2213: '3. Copia documento de identidad del titular',
  tyc222: 'Quejas y/o reclamos',
  tyc223: 'Cuando el Titular considere que su información debe ser objeto de corrección, actualización o supresión o cuando advierta un presunto incumplimiento de cualquiera de sus derechos, el término máximo para atender la queja o reclamoserá de quince (15) días hábiles contados a partir del día siguiente a la fecha de recepción del documento.',
  tyc224: 'Cuando no fuere posible atender el reclamo dentro de dicho término, se informará\n' +
    'al interesado los motivos de la demora y la fecha en que será atendido, la cual en\n' +
    'ningún caso podrá superar los ocho (8) días hábiles siguientes al vencimiento del\n' +
    'primer término. Si el reclamo resulta incompleto, se requerirá al interesado dentro\n' +
    'de los cinco (5) días siguientes a la recepción de la queja y/o reclamo para que\n' +
    'subsane las fallas. Transcurridos dos (2) meses desde la fecha del requerimiento\n' +
    '\n' +
    'sin que el solicitante presente la información requerida, se entenderá desistida la\n' +
    'queja o reclamo. El documento de queja o reclamo deberá contener:',
  tyc2241: '1. Área de Coally S.A.S a la cual va dirigida (Servicio al cliente)',
  tyc2242: '2. El nombre y número de identificación del Titular.',
  tyc2243: '3. Copia documento de identidad del titular.',
  tyc23: '13. Modificaciones',
  tyc24: 'Es posible que actualicemos la presente Política de Datos ocasionalmente. En\n' +
    'caso de realizarse cambios se le notificará a los usuarios a través de la aplicación\n' +
    'o la página web. Por lo tanto, aceptar el uso de los servicios después del aviso de\n' +
    'una actualización constituye el consentimiento del usuario sobre el contenido de\n' +
    'esta.',
  tyc25: '14. Vigencia',
  tyc26: 'La presente Política entra en vigencia a partir del día 01 del mes de Enero de\n' +
    '2021.',
  tyc261: 'Los Datos Personales que sean almacenados, utilizados o transmitidos permanecerán en las bases de datos de Coally S.A.S durante el tiempo que sea necesario para cumplir con las finalidades expuestas en este manual o para que la Empresa pueda cumplir con sus deberes legales.',
  tyc262: 'Sin embargo, la información será revisada cada año para verificar la veracidad del dato y finalidad de seguir con su tratamiento. De ser necesario Coally S.A.S se reserva el derecho a modificar la presente Política de forma unilateral; sin perjuicio de esto, en caso de generarse algún cambio sustancial sobre la Política, se comunicará a los Titulares.',
  //no se usa
  tyc27: 'Uso de Direcciones IP',
  tyc28: 'Una dirección de Protocolo de Internet (IP) es un conjunto de números que se asigna automáticamente a su o dispositivo móvil cuando usted accede a su proveedor de servicios de internet, o a través de la red de área local (LAN) de su organización o la red de área amplia (WAN). Los servidores web automáticamente identifican su dispositivo móvil por la dirección IP asignada a él durante su sesión en línea.',
  tyc29: 'Coally podrá recolectar direcciones IP para propósitos de administración de sistemas y para auditar el uso de su sitio, todo lo anterior de acuerdo con la autorización de protección de datos que se suscriba para tal efecto. Normalmente no se vincula la dirección IP de un usuario con la información personal de este, lo que significa que cada sesión de usuario se registra, pero el usuario sigue siendo anónimo para Coally. Sin embargo, Coally podrá usar las direcciones IP para identificar a los usuarios de nuestro sitio cuando sea necesario con el objeto de exigir el cumplimiento de los términos de uso del sitio, o para proteger su servicio, sitio u otros usuarios.',






}