import React, { useState } from "react";
// import { Radio } from '@material-ui/core'
import '../../../profesional/feedback/feedbackStyle/feedback.css'
import InsertEmoticonOutlinedIcon from '@mui/icons-material/InsertEmoticonOutlined';
import SentimentSatisfiedAltOutlinedIcon from '@mui/icons-material/SentimentSatisfiedAltOutlined';
import SentimentNeutralOutlinedIcon from '@mui/icons-material/SentimentNeutralOutlined';
import SentimentDissatisfiedOutlinedIcon from '@mui/icons-material/SentimentDissatisfiedOutlined';
import MoodBadOutlinedIcon from '@mui/icons-material/MoodBadOutlined';
import { useSelector } from "react-redux";


export default function Question1({question1,setQuestion1}) {


    const { lang } = useSelector(state => state)

    const [selected, setSelected] = useState(
        {name1:false,
        name2:false,
        name3:false,
        name4:false,
        name5:false
        }
    )

    const handleOnClick = (e) => {
        const { name } = e.target
        setSelected({            
            [name]: !selected[name]
        })
    }

    
    return (
        <div className="form-puntuacion">
            <label>
                <div className={ selected.name5 ? 'form-puntuacion-selected' : "form-puntuacion-title"}
                    style={{ color: 'green' }} >
                    <input type="checkbox"
                        style={{ display: 'none' }}
                        onChange={()=>setQuestion1('5')}
                        onClick={handleOnClick}
                        name="name5" />
                    <InsertEmoticonOutlinedIcon style={{ color: 'green' }} />
                    <p>{lang === 'es' ? 'Muy satisfecho' : 'Very satisfied'}</p>

                </div>
            </label>

            <label>
                <div className={ selected.name4 ? 'form-puntuacion-selected' : "form-puntuacion-title"}
                    style={{ color: 'lightgreen' }}>
                    <input type="checkbox"
                        style={{ display: 'none' }}
                        onChange={()=>setQuestion1('4')}
                        onClick={handleOnClick}
                        name="name4" />
                    <SentimentSatisfiedAltOutlinedIcon style={{ color: 'lightgreen' }} />
                    <p>{lang === 'es' ? 'Satisfecho' : 'Satisfied'}</p>
                </div>
            </label>

            <label>
                <div className={ selected.name3 ? 'form-puntuacion-selected' : "form-puntuacion-title"}
                    style={{ color: 'gold' }}>
                    <input type="checkbox"
                        style={{ display: 'none' }}
                        onChange={()=>setQuestion1('3')}
                        onClick={handleOnClick}
                        name="name3" />
                    <SentimentNeutralOutlinedIcon style={{ color: 'gold' }} />
                    <p>{lang === 'es' ? 'Regular' : 'Regular'}</p>
                </div>
            </label>

            <label>
                <div className={ selected.name2 ? 'form-puntuacion-selected' : "form-puntuacion-title"}
                    style={{ color: 'orange' }}>
                    <input type="checkbox"
                        style={{ display: 'none' }}
                        onChange={()=>setQuestion1('2')}
                        onClick={handleOnClick}
                        name="name2" />
                    <SentimentDissatisfiedOutlinedIcon style={{ color: 'orange' }} />
                    <p>{lang === 'es' ? 'Insatisfecho' : 'Unsatisfied'}</p>
                </div>
            </label>

            <label>
                <div className={ selected.name1 ? 'form-puntuacion-selected' : "form-puntuacion-title"}
                    style={{ color: 'red' }}>
                    <input type="checkbox"
                        style={{ display: 'none' }}
                        onChange={()=>setQuestion1('1')}
                        onClick={handleOnClick}
                        name="name1" />
                    <MoodBadOutlinedIcon style={{ color: 'red' }} />
                    <p>{lang === 'es' ? 'Muy insatisfecho' : 'Very unsatisfied'}</p>
                </div>
            </label>

        </div>



    )
}