/* eslint-disable  */
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import './landingUnilever.css';
import Logo from '../../assets/logos/Logo-Unilever.png'
import logoCoally from '../../assets/logos/Logo-Coally.png'
import RingIzq from '../../assets/logos/anillo-izquierda.png'
import RingDer from '../../assets/logos/anillo-derecha.png'
import Firulete1 from '../../assets/logos/firulete1.png'
import Firulete2 from '../../assets/logos/firulete2.png'
import Firulete3 from '../../assets/logos/firulete3.png'
import Firulete4 from '../../assets/logos/firulete4.png'
import LayerTop from '../../assets/logos/layer-unilever.png'
import CardImg1 from '../../assets/logos/unilever-card-1.png'
import CardImg2 from '../../assets/logos/unilever-card-2.png'
import CardImg3 from '../../assets/logos/unilever-card-3.png'
import CardImg4 from '../../assets/logos/unilever-card-4.png'
import ScrollIndicator from "./ScrollIndicator";
import CoffeGirl from '../../assets/imagenes/unilever-coffee-girl.png'
import Requirements from '../../assets/imagenes/requisitos-unilever.png'
import { FaFacebookF, FaInstagram, FaLinkedinIn } from "react-icons/fa";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Aos from "aos";
import "aos/dist/aos.css";
import CarrouselAliados from "./CarrouselAliados";
import CarrouselMarcas from "./CarrouselMarcas";




const questions = [
  {
    //listo
    title: '¿Qué duración y frecuencia tiene el programa?',
    content: 'El programa tiene una duración de 3 meses. Iniciará con un taller inmersivo de dos días. Posteriormente tendrás sesiones de mentoría de 1 hora cada 15 días.'
  },
  {
    //listo
    title: '¿Cómo será mi participación como joven?',
    content: 'Necesitamos toda tu actitud, pasión y compromiso con tu proceso y tu desarrollo. Esto incluye una participación activa en todos los talleres y sesiones.'
  },
  {
    //listo
    title: '¿Tiene algún costo el programa?',
    content: 'El programa no tiene ningún costo para los participantes. Patrocinaremos esta experiencia para ti. Solo tendrás que tener acceso a un computador en casa y disponibilidad para asistir a 2 días de taller experiencial en Palmira. '
  },
  {
    //listo
    title: '¿Voy a tener algún tipo de soporte?',
    content: 'Queremos que tengas una experiencia agradable y cómoda durante el proceso, por lo que te apoyaremos con el transporte para el taller experiencial y estaremos muy atentos al soporte adicional que necesites durante todo el proceso. Adicionalmente, no vas a estar solo/a tendrás una persona que te apoyará mientras despegas en la carrera que escogiste desarrollar.'
  },
  {
    //listo
    title: '¿Es presencial o virtual?',
    content: 'El proceso será hibrido. El taller experiencial será presencial en Palmira, mientras que la modalidad de las sesiones de mentoría puede ser acordada entre el líder y tú.'
  },
  {
    //listo
    title: '¿Cuánto dura el proceso de selección?',
    content: 'El proceso se extenderá hasta la segunda semana de diciembre.'
  },
  {
    //listo
    title: '¿A través de qué medios me voy a comunicar con Unilever y mi mentor(a)?',
    content: 'Principalmente a través del correo, celular, plataforma de video llamada,  e-mail y nuestro instagram. '
  }
  ,
  {
    //listo
    title: '¿Por qué creamos este programa?',
    content: 'Desde Unilever nos comprometimos con el futuro de la juventud. Es por esto que decidimos materializar una iniciativa que nos ayude a construir un país más equitativo y con mayores oportunidades para los jóvenes.'
  }
  ,
  {
    //listo
    title: '¿Quiénes somos?',
    content: 'Somos una multinacional líder en la industria del consumo masivo. Contamos marcas que seguro amas como Fruco, Dove, Axe, Rexona, Maizena, Fab, entre otras y nos aliamos con empresas inspiradas en el desarrollo de jóvenes que comparten nuestra visión de un ayudar a la juventud colombiana. Algunas de estas son Coally, Enel, El SENA, Manpower, DISRUPTIA, Globant y Compromiso Valle. '
  }
]

export default function LandingUnilever() {
  useEffect(() => {
    Aos.init({
      duration: 1500,
    });
  }, []);
  const [expanded, setExpanded] = useState(0);
  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  }

  const scrollToTop = () => {
    const sectionTop = document.querySelector('.landing-unilever-section-1');
    sectionTop.scrollIntoView({ behavior: 'smooth', block: 'start' });
  }
  const scrolltoMiddle = () => {
    const sectionMiddle = document.querySelector('.landing-unilever-section-2');
    sectionMiddle.scrollIntoView({ behavior: 'smooth', block: 'start' });
  }
  const scrolltoBottom = () => {
    const sectionBottom = document.querySelector('.landing-unilever-section-5');
    sectionBottom.scrollIntoView({ behavior: 'smooth', block: 'start' });
  }



  return (
    <div className="landing-unilever-container">
      <section className="landing-unilever-section-1" data-aos="fade-out">
        <div className="landing-unilever-section-1-container">
        <div className="landing-unilever-section-1-logo">
              <img src={Logo} alt="logo-unilever" className="landing-unilever-logo" />
              </div>
          <div className="landing-unilever-section-1-container-body">
{/*             <div className="landing-unilever-section-1-logo">
              <img src={logoCoally} alt='logo-coally' className="landing-unilever-logo"/>
            </div> */}
            <div className="landing-unilever-body-scroll-container">
              <div className="landing-unilever-section-1-title-container">
                <h1 className="unilever-title-1">Bienvenido a</h1>
                <img src={LayerTop} alt="layer-unilever-top" className="layer-pedal-landing" />
                <div className="landing-unilever-section1-paraph-container">
                  <p style={{
                    fontWeight: '400',
                    fontSize: '20px',
                    color: '#CFD3D6',
                    margin: 0,
                    fontFamily: 'Unilever'
                  }}>
                    Adquiere las herramientas para construir el futuro
                  </p>
                  <p style={{
                    fontWeight: '400',
                    fontSize: '20px',
                    color: '#CFD3D6',
                    lineHeight: '-500px',
                    fontFamily: 'Unilever'
                  }}>
                    que sueñas y mereces.
                  </p>
                <div className="landing-unilever-section1-button-container">
                  <Link to='/unilever/postulate'>
                  <button className="landing-unilever-section1-button">
                    <p style={{
                      fontWeight: '700',
                      fontSize: '16px',
                      color: '#FFFFFF',
                      textAlign: 'center',
                      margin: 0,
                      fontFamily: 'Unilever'
                    }}>
                      Regístrate aquí
                    </p>
                  </button>
                  </Link>
                </div>
                </div>
               
              </div>
              <div className="scroll-container1">
                <ScrollIndicator scrollTop={scrollToTop} scrollMiddle={scrolltoMiddle} scrollBottom={scrolltoBottom}/>
              </div>
             
            </div>
            
          </div>
          <div className="landing-unilever-section1-arrow-indicator">
                  <i className="gg-arrow-long-down"></i>
                <div className="landing-unilever-section1-arrow-social-media">
                <div className="social-media-unilever-container">
                  <p style={{
                    margin: 0,
                    color: '#FFFFFF',
                    marginRight: '30px',
                    fontFamily: 'Unilever'
                  }}>
                    Síguenos en
                  </p>
                  <a href="https://www.linkedin.com/company/unilever/" target="_blank" rel="noopener noreferrer">
                  <FaLinkedinIn className="landing-unilever-section1-arrow-social-media-icon" style={{ color: '#fff', marginRight: '30px' }} />
                  </a>
                  <a href="https://www.facebook.com/unilever/" target="_blank" rel="noopener noreferrer">
                  <FaFacebookF className="landing-unilever-section1-arrow-social-media-icon" style={{ color: '#fff', marginRight: '30px' }} />
                  </a>
                  <a href="https://www.instagram.com/unilevercolombia/" target="_blank" rel="noopener noreferrer">
                  <FaInstagram className="landing-unilever-section1-arrow-social-media-icon" style={{ color: '#fff' }} />
                  </a>
                </div>
              </div>
                </div>
                <div className="landing-unilever-section-1-logo-bottom">
              <img src={logoCoally} alt='logo-coally' className="landing-unilever-logo"/>
            </div> 
        </div>
      </section>
      <div className="unilever-landing-firulete1">
        <img src={Firulete1} alt="firulete" data-aos="fade-out"/>
      </div>
      <section className="landing-unilever-section-2" data-aos="fade-out">
        <div className="container-marcas-unilever">
        <h1 className={'title-marcas-unilever'}>Nuestras marcas</h1>
          <CarrouselMarcas/>
        </div>
        <div className="landing-unilever-section-2-container-1">
          <div className="landing-unilever-section-2-container-1-title">
            <h1 className="unilever-title-2">¿Qué es #MételePedalATuFuturo?</h1>
            <div className='unilever-subtitle-1'>
              <p style={{
                color: '#fff',
                fontWeight: '400',
                fontFamily: 'Unilever',
                padding: '20px'
              }}>Un programa inspirado en construir un panorama más equitativo para los jóvenes, desarrollando habilidades esenciales  para acceder a mejores oportunidades de empleo y emprendimiento en el futuro a través de:</p>
            </div>
          </div>
        </div>
        <div className="landing-unilever-section-2-container-2">
          <div className="landing-unilever-section-2-container-2-1">
          <div className="unilever-section-2-cards">
              <div className="unilever-section-2-card-inner">
                <div className="unilever-section-2-card-front">
                <img src={CardImg1} alt="card 2 unilever" />
                <h1 className="section-2-card-front-unilever-text" style={{paddingLeft: '15px', paddingRight: '15px'}}>
                Taller descubriendo tu liderazgo
                </h1>
                </div>
                <div className="unilever-section-2-card-back">
                  <p className="section-2-card-front-unilever-paraph">
                  Tendrás la oportunidad de descubrir tu propósito, desarrollar una mentalidad de crecimiento y potenciar tus fortalezas.
                  </p>
                </div>
              </div>
            </div>
            <div className="unilever-section-2-cards">
              <div className="unilever-section-2-card-inner">
                <div className="unilever-section-2-card-front">
                <img src={CardImg2} alt="card 2 unilever" />
                <h1 className="section-2-card-front-unilever-text">
                Mentoría
                </h1>
                </div>
                <div className="unilever-section-2-card-back">
                <p className="section-2-card-front-unilever-paraph">
                Durante 5 sesiones recibirás acompañamiento de un líder con amplia experiencia en el sector empresarial o de emprendimiento.
                  </p>
                </div>
              </div>
            </div>
            <div className="unilever-section-2-cards">
              <div className="unilever-section-2-card-inner">
                <div className="unilever-section-2-card-front">
                <img src={CardImg3} alt="card 2 unilever" />
                <h1 className="section-2-card-front-unilever-text">
                Cápsulas de aprendizaje
                </h1>
                </div>
                <div className="unilever-section-2-card-back">
                <p className="section-2-card-front-unilever-paraph">
                Espacios de aprendizaje en donde profundizarás habilidades del futuro.
                  </p>
                </div>
              </div>
            </div>
            <div className="unilever-section-2-cards">
              <div className="unilever-section-2-card-inner">
                <div className="unilever-section-2-card-front">
                <img src={CardImg4} alt="card 2 unilever" />
                <h1 className="section-2-card-front-unilever-text">
                Conexión con oportunidades
                </h1>
                </div>
                <div className="unilever-section-2-card-back">
                <p className="section-2-card-front-unilever-paraph">
                Te prepararemos para que conectes con oportunidades que potencien tu desarrollo.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="scroll-container">
            <ScrollIndicator scrollTop={scrollToTop} scrollMiddle={scrolltoMiddle} scrollBottom={scrolltoBottom}/>
          </div>
          {/* <div className="landing-unilever-section-2-container-2-2">
            <div style={{ textAlign: 'center' }}>
              <p style={{
                fontWeight: '400',
                fontSize: '20px',
                color: '#CFD3D6',
                margin: 0
              }}>
                Serás parte de una experiencia inmersiva de liderazgo y autoconocimiento, en la que tendrás la oportunidad de descubrir tu propósito, desarrollar una mentalidad de crecimiento y potenciar tus fortalezas para alcanzar tus metas.
              </p>
            </div>
            <div style={{ textAlign: 'center' }}>
              <p style={{
                fontWeight: '400',
                fontSize: '20px',
                color: '#CFD3D6',
                margin: 0
              }}>
                Recibirás acompañamiento de un mentor, durante 5 sesiones con profesionales con amplia experiencia en el sector empresarial o del emprendimiento.
              </p>
            </div>
            <div style={{ textAlign: 'center' }}>
              <p style={{
                fontWeight: '400',
                fontSize: '20px',
                color: '#CFD3D6',
                margin: 0
              }}>
                Tendrás espacios de aprendizaje y conexión diseñados para profundizar y fortalecer habilidades clave para la empleabilidad o el emprendimiento, según tu preferencia.
              </p>
            </div>
          </div> */}
          <div className="landing-unilever-section2-arrow-indicator">
            <i className="gg-arrow-long-down"></i>
            <div className="landing-unilever-section1-arrow-social-media">
              <div className="social-media-unilever-container2">
                <p style={{
                  margin: 0,
                  color: '#FFFFFF',
                  marginRight: '30px',
                  fontFamily: 'Unilever'
                }}>
                  Síguenos en
                </p>
                <a href="https://www.linkedin.com/company/unilever/" target="_blank" rel="noopener noreferrer">
                  <FaLinkedinIn className="landing-unilever-section1-arrow-social-media-icon" style={{ color: '#fff', marginRight: '30px' }} />
                  </a>
                  <a href="https://www.facebook.com/unilever/" target="_blank" rel="noopener noreferrer">
                  <FaFacebookF className="landing-unilever-section1-arrow-social-media-icon" style={{ color: '#fff', marginRight: '30px' }} />
                  </a>
                  <a href="https://www.instagram.com/unilevercolombia/" target="_blank" rel="noopener noreferrer">
                  <FaInstagram className="landing-unilever-section1-arrow-social-media-icon" style={{ color: '#fff' }} />
                  </a>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="unilever-landing-firulete2">
        <img src={Firulete2} alt="firulete" data-aos="fade-out"/>
      </div>
      <div className="unilever-landing-ring2">
        <img src={RingDer} alt="ring" className="landing-unilever-ring" data-aos="fade-out"/>
      </div>
      <section className="landing-unilever-section-5" data-aos='fade-out'>
        <div className="landing-unilever-section-5-container">
          <div className="landing-unilever-section-5-container-row">
          <div className="landing-unilever-section-5-inner-1">
              <div className="landing-unilever-section-5-column-1">
                <div className="landing-unilever-section-5-column-1-inner-1">
                  <h1 className="landing-unilever-section-5-column-1-inner-1-title">
                    Tener entre 18 y 24 años.
                  </h1>
                </div>
                <div className="landing-unilever-section-5-column-1-inner-2">
                <h1 className="landing-unilever-section-5-column-1-inner-1-title">
                Tener disponibilidad de tiempo.
                  </h1>
                </div>
                <div className="landing-unilever-section-5-column-1-inner-3">
                    <h1 className="landing-unilever-section-5-column-1-inner-1-title1">
                Vivir en estrato 1, 2 o 3
                  </h1>
                </div>
              </div>
              <div className="landing-unilever-section-5-column-2">
                <div className="landing-unilever-section-5-column-2-inner-1">
                <h1 className="landing-unilever-section-5-column-1-inner-1-title" style={{
                  color: '#000'
                }}>
                Estar estudiando o graduado de estudios técnicos, tecnólogos o universitarios.
                  </h1>
                </div>
                <div className="landing-unilever-section-5-column-2-inner-2">
                <h1 className="landing-unilever-section-5-column-1-inner-1-title">
                Vivir en el Valle del Cauca (Cali, Palmira y alrededores).
                  </h1>
                </div>
                <div className="landing-unilever-section-5-column-2-inner-3">
                <h1 className="landing-unilever-section-5-column-1-inner-1-title">
                Participar en todo el proceso de selección.
                  </h1>
                </div>
              </div>
          </div>
          <div className="landing-unilever-section-5-inner-2">
            <div className="landing-unilever-section-5-inner-2-inner-2">
            <div className="landing-unilever-section-5-container-text">
          <h1 className="unilever-title-3">
            Requisitos
          </h1>
          </div>
            </div>
            <div className="landing-unilever-section-5-inner-2-inner-1">
              <img src={Requirements} alt="requisitos image" />
            </div>
          </div>
          <div className="scroll-container-3">
          <ScrollIndicator scrollTop={scrollToTop} scrollMiddle={scrolltoMiddle} scrollBottom={scrolltoBottom}/>
        </div>
            </div>
            <div className="landing-unilever-section1-arrow-social-media">
              <div className="social-media-unilever-container2">
                <p style={{
                  margin: 0,
                  color: '#005EEF',
                  marginRight: '30px',
                  fontFamily: 'Unilever'
                }}>
                  Síguenos en
                </p>
                <a href="https://www.linkedin.com/company/unilever/" target="_blank" rel="noopener noreferrer">
                  <FaLinkedinIn className="landing-unilever-section1-arrow-social-media-icon" style={{ color: '#005EEF', marginRight: '30px' }} />
                  </a>
                  <a href="https://www.facebook.com/unilever/" target="_blank" rel="noopener noreferrer">
                  <FaFacebookF className="landing-unilever-section1-arrow-social-media-icon" style={{ color: '#005EEF', marginRight: '30px' }} />
                  </a>
                  <a href="https://www.instagram.com/unilevercolombia/" target="_blank" rel="noopener noreferrer">
                  <FaInstagram className="landing-unilever-section1-arrow-social-media-icon" style={{ color: '#005EEF' }} />
                  </a>
              </div>
            </div>
        </div>
      </section>
      <section className="landing-unilever-section-3" data-aos="fade-out">
      <div className="landing-unilever-section-3-title-subcontainer">
          <p className="unilever-title-2">
             ¡Conoce como ser parte del Programa!   
          </p>
          </div>
        <div className="landing-unilever-section-3-container">
        <div className="landing-unilever-section-3-container-1">
          <div className="landing-unilever-section-3-title-container">
          <img src={CoffeGirl} alt="coffe girl unilever" className="coffe-girl-unilever" />
          </div>
        </div>
        <div className="landing-unilever-section-3-container-2">    
          <div className="landing-unilever-section-3-container-2-cards">
            <div className="landing-unilever-section-3-card">
              <div className="landing-unilever-section-3-circle">
                1.
              </div>
              <div className="landing-unilever-section-3-card-container-1">
                <p style={{ color: '#fff', fontFamily: 'Unilever',fontWeight: '600' }}><Link to='/unilever/postulate' style={{textDecoration: 'underline', color:'#FEC022'}}>Ir a la aplicación.</Link>
                </p>
              </div>
            </div>

            <div className="landing-unilever-section-3-card">
              <div className="landing-unilever-section-3-circle">
                2.
              </div>
              <div className="landing-unilever-section-3-card-container-1">
                <p style={{ color: '#fff', fontFamily: 'Unilever' }}><span style={{fontWeight: '600', margin: 0, fontFamily: 'Unilever'}}>Pruebas psicométricas para conocerte mejor.</span>
                </p>
              </div>
            </div>
            </div>
            <div className="unilever-section-3-container-2-cards-middle">
              <h1 className="section-2-card-front-unilever-text" style={{
                paddingTop: '25px',
                paddingBottom: '25px',
              }}>Conoce el proceso de selección.</h1>
            </div>
            <div className="landing-unilever-section-3-container-2-cards">
            <div className="landing-unilever-section-3-card">
              <div className="landing-unilever-section-3-circle">
                3.
              </div>
              <div className="landing-unilever-section-3-card-container-1">
                <p style={{ color: '#fff', fontFamily: 'Unilever' }}><span style={{fontWeight: '600', margin: 0, fontFamily: 'Unilever'}}>Video-entrevista.</span>
                </p>
              </div>
            </div>
            <div className="landing-unilever-section-3-card">
              <div className="landing-unilever-section-3-circle">
                4.
              </div>
              <div className="landing-unilever-section-3-card-container-1">
                <p style={{ color: '#fff', fontFamily: 'Unilever' }}><span style={{fontWeight: '600', margin: 0, fontFamily: 'Unilever'}}>Si llegaste a esta etapa, ya eres parte del programa.</span>
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="scroll-container">
          <ScrollIndicator scrollTop={scrollToTop} scrollMiddle={scrolltoMiddle} scrollBottom={scrolltoBottom}/>
        </div>
        </div>
        <div className="landing-unilever-section3-arrow-indicator">
            <i className="gg-arrow-long-down"></i>
            <div className="landing-unilever-section1-arrow-social-media">
              <div className="social-media-unilever-container2">
                <p style={{
                  margin: 0,
                  color: '#FFFFFF',
                  marginRight: '30px',
                  fontFamily: 'Unilever'
                }}>
                  Síguenos en
                </p>
                <a href="https://www.linkedin.com/company/unilever/" target="_blank" rel="noopener noreferrer">
                  <FaLinkedinIn className="landing-unilever-section1-arrow-social-media-icon" style={{ color: '#fff', marginRight: '30px' }} />
                  </a>
                  <a href="https://www.facebook.com/unilever/" target="_blank" rel="noopener noreferrer">
                  <FaFacebookF className="landing-unilever-section1-arrow-social-media-icon" style={{ color: '#fff', marginRight: '30px' }} />
                  </a>
                  <a href="https://www.instagram.com/unilevercolombia/" target="_blank" rel="noopener noreferrer">
                  <FaInstagram className="landing-unilever-section1-arrow-social-media-icon" style={{ color: '#fff' }} />
                  </a>
              </div>
            </div>
          </div>
      </section>
      <div className="unilever-landing-ring3">
        <img src={RingIzq} alt="ring" className="landing-unilever-ring" data-aos="fade-out" />
      </div>
      <section className="landing-unilever-section-4" data-aos="fade-out">
      <div className="unilever-landing-firulete3">
        <img src={Firulete3} alt="firulete" data-aos="fade-out"/>
      </div>
        <div className="landing-unilever-section-4-container">
        <div className="landing-unilever-section-2-container-1-title">
            <h1 className="unilever-title-3">Lo que necesitas saber sobre el programa</h1>
            {/* <div className='unilever-subtitle-1'>
              <p style={{
                color: '#fff',
                fontWeight: '400',
                fontFamily: 'Unilever'
              }}>Sabemos que no somos todos iguales y somos una empresa que promueve la diversidad, creamos este programa porque creemos que nuestros proyectos pueden enriquecerse con talento como el tuyo.</p>
            </div> */}
          </div>
            <div className="landing-unilever-section-4-dropdowns-container">
              {
                questions.map((question, index) => {
                  return (
                    <Accordion expanded={
                      expanded === index + 1
                    } 
                    onChange={handleChange(index + 1)}
                    className="landing-unilever-section-4-dropdowns" key={index} style={{
                      background: expanded === index + 1 ? '#1F36C7' : 'none', 
                      boxShadow: 'none', 
                      borderBottom: '1px solid #D6D6D6', 
                      borderRadius: '0',
                      width : '100%',
                      margin: 0,
                      marginTop: '25px',
                      color: expanded === index + 1 ? '#fff' : '#252525',
                    }}>
                    <AccordionSummary
                  expandIcon={<ExpandMoreIcon style={{ color: expanded === index + 1 ? '#fff' : '#252525' }} />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  className="landing-unilever-section-4-dropdowns-summary"
                  style={{margin: 0}}
                >
                  <h1 style={{
                    
                    fontWeight: '500',
                    fontSize: '20px',
                    fontFamily: 'Unilever'
                  }}>
                    {question.title}
                  </h1>
                </AccordionSummary>
                <AccordionDetails className="landing-unilever-section-4-dropdowns-details" style={{padding: 0}}>
                    <div className="landing-unilever-section-4-dropdowns-details-container">
                      <p style={{ color: '#252525', fontFamily: 'Unilever' }}>
                        {question.content}
                      </p>
                    </div>
                  </AccordionDetails>
              </Accordion>
                  )
                })
              }
            </div>
        </div>
        <div className="unilever-landing-firulete4">
        <img src={Firulete4} alt="firulete" data-aos="fade-out"/>
      </div>
      </section>
      <div style={{background:'white', width: '100%', height:'50vh'}}>
        <div style={{display:'flex', justifyContent:'center', marginTop:'5%'}}>
      <h1 className="title-marcas-unilever">Nuestros aliados</h1>      
        </div>
        <div style={{display:'flex', justifyContent:'center', marginTop:'4%'}}>
          <CarrouselAliados/>
        </div>
      </div>
      <footer style={{width: '100%'}}>
        <div className="unilever-landing-footer">
        © 2022 Coally - All rights reserved
        </div>
      </footer>
    </div>
  )
}
