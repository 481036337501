/*eslint-disable*/
import React, {useEffect, useState} from "react";
import axios from "axios";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import dayjs from "dayjs";
import {toast} from "react-toastify";
import 'dayjs/locale/es-mx';
import './selecthorariounilever.css'
import {useSelector} from "react-redux";
import Select from "react-select"

const customStyles = {
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? '#1f36c7' : '#f2f2f2',
    cursor: 'pointer',
    padding: 10,
    color: state.isSelected ? '#fff' : '#4a4a4a',
  }),
  control: (provided, state) => ({
    ...provided,
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    padding: '0',
    justifyContent: 'center',
    borderRadius: '8px',
    background: 'transparent',
    backgroundColor: state.isSelected ? '#005eef' : '#F5F5F5',
    color: state.isSelected ? '#fff' : '#4a4a4a',
    marginLeft: '10px',
  }),
  menu: (provided, state) => ({
    ...provided,
    color: 'white',
    padding: '0',
    width: '90%',
    marginTop: '-1em',
    marginLeft: '10px',
  }),
  placeholder: (provided, state) => ({
    ...provided,
    color: 'white',
  }),
  singleValue: (provided, state) => ({
    ...provided,
    color: 'white',
  }),
  input: (provided, state) => ({
    ...provided,
    color: 'white',
  }),
  menuList: (provided, state) => ({
    ...provided,
    background: '#f2f2f2',
  }),
  multiValueRemove: (provided, state) => ({
    ...provided,
    color: 'white',
    cursor: 'pointer',
  }),
  multiValue: (provided, state) => ({
    ...provided,
    background: 'transparent',
    color: 'black',
  }),
  multiValueLabel: (provided, state) => ({
    ...provided,
    color: 'black',
  }),
}

const dateAlreadyClicked2 = (date, selectedDays) => {
  const newDate = dayjs(date)
  for (let i = 0; i < selectedDays.length; i++) {
    const existingDate = dayjs(selectedDays[i])
    if (existingDate.isSame(newDate, 'day')) {
      return true
    }
  }
  return false
}
const datesExcept2 = (date, selectedDays) => {
  //need to filter the datesSplitted array of objects the format is [{date: '2021-09-01T00:00:00.000Z', hour: []}]
  return selectedDays?.filter(d => !dayjs(date).isSame(dayjs(d), 'day'))
}

export default function SelectHorarioUnilever(props) {
// Declaración de los estados del componente
  dayjs.locale('es-mx')
  const [selectedDays, setSelectedDays] = useState([]) // Almacena los días seleccionados por el usuario
  const [selectedDaysWithHours, setSelectedDaysWithHours] = useState([]) // Almacena los días seleccionados por el usuario junto con sus horas
  const [eventos, setEventos] = useState([]) // Almacena los eventos de los mentores
  const lang = useSelector(state => state.lang) // Almacena el idioma seleccionado por el usuario
  const [id] = useState(JSON.parse(localStorage.getItem('unilever_id'))) // Almacena el ID del usuario logueado
  const [isFlexible, setIsFlexible] = useState(false) // Almacena si el usuario ha seleccionado la opción de horarios flexibles
  const [isPeriodic, setIsPeriodic] = useState(false) // Almacena si el usuario ha seleccionado la opción de horarios periódicos
  const [step, setStep] = useState(1) // Almacena el paso actual del proceso de reserva

// Llamado a la API para obtener los eventos del mentor
  useEffect(() => {
    axios.get(`https://findally-backend.herokuapp.com/api/unilever/reservation/mentor/${id}`)
    .then(res => {
      console.log(res)
      res.data.data.map(item => {
        const evento = {
          title: item.name,
          start: item.date,
          extendedProps:
            {
              mentoring: item._id,
              hour: item?.date?.slice(11, 16),
              student: item?.student?.name,
              student_id: item?.student?._id
            },
          description: item.description,
          link: item?.meet_link
        }
        return setEventos(eventos => [...eventos, evento])
      })
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

// Función que verifica si un día ya ha sido seleccionado
  const dateAlreadyClicked2 = (date, selectedDays) => {
    return selectedDays.some(item => dayjs(date).isSame(item, 'day'))
  }

// Función que devuelve una lista de fechas sin una fecha en particular
  const datesExcept2 = (date, dates) => {
    return dates.filter(item => !dayjs(item).isSame(date, 'day'))
  }

// Función que verifica si una fecha es deshabilitada
  const isDisabled2 = ({activeStartDate, date, view}) => {
    // también se valida que la fecha no esté en el pasado y que sea una fecha real
    return eventos.some(item => dayjs(date).isSame(dayjs(item.start), 'day')) || date.getTime() < new Date().getTime() || props.datesMentor.some(item => dayjs(date).isSame(dayjs(item), 'day'))
  }


  //cambia de paso validando que se haya seleccionado una opción
  const handleStep = () => {
    if (!isFlexible && !isPeriodic) {
      return toast.error('Por favor selecciona una opción', {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
    }
    setStep(2)
  }
  //vuelve un paso limpiando los estados
  const handleStepBack = () => {
    if(selectedDays.length > 0) setSelectedDays([])
    if(selectedDaysWithHours.length > 0) setSelectedDaysWithHours([])
    if (isPeriodic || isFlexible) {
      setIsFlexible(false)
      setIsPeriodic(false)
      return setStep(1)
    }
    setStep(1)
  }


  const tileClassName = ({date}) => {
    const parsedDate = new Date(date);
    const utcDate = new Date(parsedDate).setUTCHours(0, 0, 0, 0);
    const classes = {
      dayTile: 'react-calendar__tile',
      activeDay: 'react-calendar__tile--active',

    }
    const classNames = [classes.dayTile]
    if (dateAlreadyClicked2(utcDate, selectedDays)) return [classes.activeDay, ...classNames]
    return classNames
  }


  const hours = [
    {label: '8:00', value: 8},
    {label: '9:00', value: 9},
    {label: '10:00', value: 10},
    {label: '11:00', value: 11},
    {label: '12:00', value: 12},
    {label: '13:00', value: 13},
    {label: '14:00', value: 14},
    {label: '15:00', value: 15},
    {label: '16:00', value: 16},
    {label: '17:00', value: 17},
    {label: '18:00', value: 18},
    {label: '19:00', value: 19},
    {label: '20:00', value: 20},
  ]

  const onClickDay = day => {
    if (selectedDays.includes(day)) {
      return setSelectedDays(selectedDays.filter(item => item !== day))
    } else {
      setSelectedDays([...selectedDays, day])
    }
  }
  const onClickDay2 = date => {
    //valida que el dia ya no ha sido clickeado, si es asi lo filtra
    const parsedDate = new Date(date);
    const utcDate = new Date(parsedDate).setUTCHours(0, 0, 0, 0);
    if (dateAlreadyClicked2(utcDate, selectedDays)) {
      return setSelectedDays(datesExcept2(utcDate, selectedDays))
    }
    if (selectedDays.length === 7) {
      return toast.info('Solo puedes seleccionar 7 días', {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
    } else {
      //sino lo agrega al estado
      setSelectedDays(selectedDays => [...selectedDays, utcDate])
    }
  }

  const days = [
    {label: 'Lunes', value: 1},
    {label: 'Martes', value: 2},
    {label: 'Miércoles', value: 3},
    {label: 'Jueves', value: 4},
    {label: 'Viernes', value: 5},
    {label: 'Sábado', value: 6},
    {label: 'Domingo', value: 0},
  ]

  const addDateHour = (day, hours) => {
    //busca el indice del dia en el estado
    let foundDay = selectedDaysWithHours.findIndex(item => item.day === day)
    //si es -1 es porque no existe y lo agrega
    if (foundDay === -1) {
      setSelectedDaysWithHours([...selectedDaysWithHours, {day, hours}])
    } else {
      //si existe filtra los valores de hours distintos y los almacena en newHours
      const newHours = [...hours].filter(hour => !selectedDaysWithHours[foundDay].hours.includes(hour))
      setSelectedDaysWithHours(selectedDaysWithHours.map(item => {
        if (item.day === day) {
          item.hours = [...item.hours, ...newHours]
        }
        return item
      }))
    }
  }

  const sendPeriodicDates = () => {
    if (!selectedDaysWithHours.length) {
      return toast.error('Por favor selecciona al menos un día y hora', {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
    }
    if (selectedDaysWithHours?.map(item => item.hours.length).includes(0)) {
      return toast.error('Por favor selecciona al menos una hora para cada día', {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
    }
    const today = dayjs()
    const currentDay = today.day()
    const selectedDays = []
    //ordena los dias de menor a mayor
    const sortedDays = selectedDaysWithHours.sort((a, b) => a.day - b.day)
    //recorre los dias seleccionados
    for (let i = 0; i < sortedDays.length; i++) {
      //calcula los dias que faltan para el siguiente dia seleccionado
      const {day, hours} = sortedDays[i]
      const daysUntilNextSelectedDay = (7 + day - currentDay) % 7
      const nextSelectedDay = today.startOf('day').add(daysUntilNextSelectedDay, 'day')
      let uniqueHours = []
      //recorre las horas seleccionadas
      for (let j = 0; j < hours.length; j++) {
        //si la hora ya existe en el array no la agrega
        const hour = `${hours[j]}:00`
        if (!uniqueHours.includes(hour)) {
          uniqueHours.push(hour)
        }
      }
      //agrega el dia y las horas al array de dias seleccionados
      selectedDays.push({date: new Date(nextSelectedDay.format('YYYY-MM-DD')).toISOString(), hours: uniqueHours})
    }
    toast.promise(axios.post(`https://findally-backend.herokuapp.com/api/unilever/scheldule/bulk/${props.idUnilever}`, selectedDays), {
      loading: 'Enviando horarios...',
      success: 'Horarios enviados correctamente',
      error: 'Error al enviar los horarios'
    })
    .then(res => {
      console.log(res)
      setTimeout(() => {
        window.location.reload()
      }, 2000)
    })
  }

  const sendFlexibleDates = () => {
    if (!selectedDaysWithHours.length) {
      return toast.error('Por favor selecciona al menos una fecha', {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
    }
    const obj = []
    //recorre los dias seleccionados
    for (let i = 0; i < selectedDaysWithHours.length; i++) {
      const {hours} = selectedDaysWithHours[i]
      let uniqueHours = []
      //recorre las horas seleccionadas
      for (let j = 0; j < hours.length; j++) {
        const hour = `${hours[j]}:00`
        if (!uniqueHours.includes(hour)) {
          uniqueHours.push(hour)
        }
      }
      //agrega el dia y las horas al array de dias seleccionados
      obj.push({date: selectedDaysWithHours[i].day, hours: uniqueHours})
    }
    toast.promise(axios.post(`https://findally-backend.herokuapp.com/api/unilever/scheldule/${props.idUnilever}`, obj), {
      loading: 'Enviando horarios...',
      success: 'Horarios enviados correctamente',
      error: 'Error al enviar los horarios'
    }).then(res => {
      console.log(res)
      setTimeout(() => {
        window.location.reload()
      }, 2000)
    })
  }
//funcion que formatea la fecha para que sea: '1 de enero de 2021' y pueda ser mostrada en la etiqueta p

  const formatDateSelected = (date) => {
    const parsedDate = new Date(date);
    const utcDate = dayjs.utc(parsedDate);
    return utcDate.locale('es-mx').format('LL');
  }


  return (
    <>
      {
        props.popup && (
          <Dialog
            style={{}}
            open={props.popup}
            onClose={() => props.setPopup(false)}
            // scroll={scroll}
            aria-labelledby="scroll-dialog-title"
            aria-describedby="scroll-dialog-description"
          >
            <DialogTitle id="scroll-dialog-title">
              <div style={{width: '100%', height: '100%', borderBottom: '1px solid #D6D6D6', textAlign: 'left'}}>
                <span style={{
                  fontSize: '20px',
                  fontWeight: 'bold',
                  color: '#393939',
                }}>
                  {lang === 'es' ? 'Propone sesiones de mentoría:' : 'Propose mentoring sessions:'}
                </span>
              </div>
            </DialogTitle>
            {
              step === 1 && (
                <DialogContent>
                  <div style={{
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    justifyContent: 'center'
                  }}>
                    <span style={{
                      fontWeight: '400',
                      fontSize: '16px',
                      color: '#393939'
                    }}>{lang === 'es' ? 'Seleccione una opción de programación' : 'Select a scheduling option'}</span>
                    <div style={{
                      width: '100%',
                      height: '100%',
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      marginTop: '10px'
                    }}>
                      <div style={{
                        width: '100%',
                        height: '100%',
                        display: 'flex',
                        flexDirection: 'row-reverse',
                        alignItems: 'center',
                        justifyContent: 'center'
                      }}>
                        <button className={"CardOportunidadProfesional-Button-new"} style={{
                          width: '100%',
                          height: '100%',
                          marginRight: '10px',
                          background: isPeriodic ? '#202256' : '',
                          color: isPeriodic ? '#fff' : '',
                        }} onClick={() => {
                          setIsPeriodic(true)
                          setIsFlexible(false)
                        }}>
                          {
                            lang === 'es' ? 'Agenda programada' : 'Planned schedule'
                          }
                        </button>
                      </div>
                      <div style={{
                        width: '100%',
                        height: '100%',
                        display: 'flex',
                        flexDirection: 'row-reverse',
                        alignItems: 'center',
                        justifyContent: 'center'
                      }}>
                        <button className={"CardOportunidadProfesional-Button-new"} style={{
                          width: '100%',
                          height: '100%',
                          background: isFlexible ? '#202256' : '',
                          color: isFlexible ? '#fff' : '',
                        }} onClick={() => {
                          setIsFlexible(true)
                          setIsPeriodic(false)
                        }}>
                          {
                            lang === 'es' ? 'Agenda personalizada' : 'Custom schedule'
                          }
                        </button>
                      </div>
                    </div>
                    <div style={{
                      width: '100%',
                      height: '100%',
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      justifyContent: 'center'
                    }}>
                      {step === 1 && isPeriodic && !isFlexible && (
                        <p style={{
                          fontWeight: '400',
                          fontSize: '16px',
                          color: '#393939',
                          marginTop: '10px'
                        }}>
                          {lang === 'es' ? 'Al elegir la opción de \'Agenda programada\', podrás seleccionar un día de la semana y un horario específico para tus mentorías. La aplicación propondrá automáticamente sesiones de mentoría para cada semana durante los próximos tres meses en los días seleccionados.' : 'By choosing the \'Scheduled schedule\' option, you will be able to select a specific day of the week and time for your mentoring sessions. The application will automatically create mentoring sessions for each week over the next three months on the selected days.'}
                        </p>
                      )}
                      {step === 1 && !isPeriodic && isFlexible && (
                        <p style={{
                          fontWeight: '400',
                          fontSize: '16px',
                          color: '#393939',
                          marginTop: '10px'
                        }}>
                          {lang === 'es' ? 'Al elegir la opción "Agenda personalizada", podrás seleccionar cualquier día en un calendario y elegir una hora disponible para programar tu mentoría. Solo se propondrá la reunión en el dia seleccionado' : 'For the "Custom schedule" option, you can choose any date and time available on the calendar to schedule your mentoring session. The application will only create the meeting for the selected date and time, without generating any additional sessions in the future.'}
                        </p>
                      )}
                    </div>
                    {(isPeriodic || isFlexible) && <div style={{
                      width: '100%',
                      height: '100%',
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      justifyContent: 'center',
                      marginTop: '10px'
                    }}>
                      <button className={"CardOportunidadProfesional-Button-new-orange"} onClick={handleStep}>
                        {
                          lang === 'es' ? 'Continuar' : 'Continue'
                        }
                      </button>
                    </div>}
                  </div>
                </DialogContent>
              )
            }
            {
              step === 2 && isPeriodic && !isFlexible && (
                <DialogContent>
                  <div style={{
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center'
                  }}>
                    <div className="popup-date-unilever-container">
                      <div className="popup-date-unilever-inner-2">
                        <div style={{
                          width: '100%',
                          height: '100%',
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'center',
                          justifyContent: 'center',
                          paddingTop: '5%'
                        }}>
                          <span style={{
                            fontWeight: '400',
                            fontSize: '16px',
                            color: '#393939',
                            textAlign: 'left',
                            display: 'flex',
                            alignSelf: 'flex-start'
                          }}>
              {lang === 'es' ? 'Por cada día deberás seleccionar tus horarios disponibles' : 'For each day you must select your available hours'}
            </span>
                          <div className="unilever-buttons-hours-grid" style={{paddingTop: '5%'}}>
                            {
                              days.map((day, index) => (
                                <button key={index} className={
                                  selectedDays.includes(day.value) ? 'unilever-select-button-hour-active' : 'unilever-select-button-hour'
                                } onClick={() => {
                                  onClickDay(day.value)
                                }}>
                                  {day.label}
                                </button>
                              ))
                            }
                          </div>
                          {selectedDays && selectedDays.length ? <div style={{
                            paddingTop: '5%',
                            display: 'flex',
                            flexDirection: 'column',
                            width: '100%',
                            justifyContent: 'space-between',
                            alignItems: 'center'
                          }}>
                            <p style={{
                              fontWeight: '400',
                              fontSize: '16px',
                              color: '#393939',
                              marginTop: '10px'
                            }}>
                              {lang === 'es' ? 'Selecciona tus horarios disponibles para cada día' : 'Select your available hours for each day'}
                            </p>
                            {
                              selectedDays.map((day, index) => {
                              const selectedDayObj = days.find(d => d.value === day);
                                return (<div key={index} style={{
                                  display: 'flex',
                                  flexDirection: 'row',
                                  alignItems: 'center',
                                  justifyContent: 'space-between',
                                  width: '100%',
                                  height: '100%'
                                }}>
                                  <p style={{}}>
                                    {selectedDayObj.label}
                                  </p>
                                  <Select
                                    key={index}
                                    options={hours.slice()}
                                    onChange={(e) => {
                                      addDateHour(day, e.map((item) => item.value))
                                    }}
                                    placeholder={'Selecciona un horario'}
                                    isMulti
                                    styles={customStyles}
                                  />
                                </div>)
                              })
                            }
                          </div> : null}
                          {selectedDaysWithHours && selectedDaysWithHours.length ? <div style={{
                            paddingTop: '5%',
                            display: 'flex',
                            width: '100%',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            flexDirection: 'row'
                          }}>
                            <button className={"CardOportunidadProfesional-Button-new"} onClick={handleStepBack}>
                              {
                                lang === 'es' ? 'Atrás' : 'Back'
                              }
                            </button>
                            <button className={"CardOportunidadProfesional-Button-new-orange"} onClick={sendPeriodicDates}>
                              {
                                lang === 'es' ? 'Continuar' : 'Continue'
                              }
                            </button>
                          </div> : <button className={"CardOportunidadProfesional-Button-new"} onClick={handleStepBack}>
                            {
                              lang === 'es' ? 'Atrás' : 'Back'
                            }
                          </button>}
                        </div>
                      </div>
                    </div>
                  </div>
                </DialogContent>
              )
            }
            {
              step === 2 && !isPeriodic && isFlexible && (
                <DialogContent>
                  <div style={{
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center'
                  }}>
                    <div className="popup-date-unilever-container">
                      <div className="popup-date-unilever-inner-2">
                        <div style={{
                          width: '100%',
                          height: '100%',
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'center',
                          justifyContent: 'center'
                        }}>
                      <span style={{
                        fontWeight: '400',
                        fontSize: '16px',
                        color: '#393939'
                      }}>{lang === 'es' ? 'Aquí puedes seleccionar cualquier fecha disponible en el calendario y proponer una sesión de mentoría en un horario conveniente para ti.' : 'Here you can select any available date in the calendar and propose a mentoring session at a convenient time for you.'}</span>
                        </div>
                        <Calendar
                          locale={lang === 'es' ? 'es-mx' : 'en'}
                          onClickDay={onClickDay2}
                          tileClassName={tileClassName}
                          tileDisabled={isDisabled2}
                        />
                        {selectedDays && selectedDays.length ? <div style={{
                          width: '100%',
                          height: '100%',
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'center',
                          justifyContent: 'center',
                          paddingTop: '5%'
                        }}>
            <span style={{
              fontWeight: '400',
              fontSize: '16px',
              color: '#393939',
              textAlign: 'left',
              display: 'flex',
              alignSelf: 'flex-start'
            }}>
              {lang === 'es' ? 'Horarios disponibles:' : 'Available hours:'}
            </span>
                           <div style={{
                            paddingTop: '5%',
                            display: 'flex',
                            flexDirection: 'column',
                            width: '100%',
                            justifyContent: 'space-between',
                            alignItems: 'center'
                          }}>
                            {
                              selectedDays.map((day, index) => (
                                <div key={index} style={{
                                  display: 'flex',
                                  flexDirection: 'row',
                                  alignItems: 'center',
                                  justifyContent: 'space-between',
                                  width: '100%',
                                  height: '100%'
                                }}>
                                  <p style={{}}>
                                    {formatDateSelected(day)}
                                  </p>
                                  <Select
                                    key={index}
                                    options={hours.slice()}
                                    onChange={(e) => {
                                      addDateHour(day, e.map((item) => item.value))
                                    }}
                                    placeholder={'Selecciona un horario'}
                                    isMulti
                                    styles={customStyles}
                                  />
                                </div>
                              ))
                            }
                          </div>
                          <div style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            width: '80%',
                          }}>
                            <button className={"CardOportunidadProfesional-Button-new"} onClick={handleStepBack}>
                              {
                                lang === 'es' ? 'Atrás' : 'Back'
                              }
                            </button>
                            <button className="unilever-select-button-hour2" onClick={() => sendFlexibleDates()} style={{
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                              alignSelf: 'flex-start'
                            }}>
                              {lang === 'es' ? 'Seleccionar' : 'Select'}
                            </button>
                          </div>
                        </div>
                            : null}
                      </div>
                    </div>
                  </div>
                </DialogContent>
              )
            }
            <DialogActions>
              <Button onClick={() => props.setPopup(false)}>
                {lang === 'es' ? 'Cerrar' : 'Close'}
              </Button>
            </DialogActions>
          </Dialog>
        )
      }
    </>
  )
}
