/* eslint-disable */
import React, { useState, useEffect } from "react";
import axios from "axios";
import Navbar from "../../components/Navbar/Navbar";
import Sidebar from "../../components/Sidebar/Sidebar";
import BannerUnileverHome from "../../components/unilever/banner/BannerUnileverHome";
import contratando from "../../assets/logos/contratando.png";
import oficina from "../../assets/logos/oficina.png";
import "./HomeUnilever.css";
import { CardDataEspMentores, CardDataEng } from "./carrusel/cardsData";
import CarruselCards from "./carrusel/CarruselCards";
import { useSelector, useDispatch } from "react-redux";
import arrowL from "../../assets/icons/left-arrow.svg";
import arrowR from "../../assets/icons/rigth-arrow.svg";
import SelectHorarioUnilever from "./SelectHorarioUnilever";
import SelectHorarioUnileverProf from "./SelectHorarioUnileverProf";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { setErrorMessageUnilever, setDataUnilever } from "../../actions";
import guiapdf from "./files/guia sesion.pdf";
import mentortoolkit from "./files/mentor toolkit.pdf";
import GoogleButton from "react-google-button";
import CarruselCardsStudentWAssistance from "./carrusel/CarruselCardsStudentWAssistance";
import BannerBancolombia from "../../bancolombia/bancolombiaComponentes/BannerBancolombia";
// https://www.figma.com/file/bRCsb0kTVy7fsHDPrWlxTn/Coally?node-id=5446%3A30870&t=xq7CSVgqgUS4M5q9-4
// http://localhost:3001/unilever/home

const HomeUnilever = () => {
  const [popup, setPopup] = useState(false);
  const [rolUnilever] = useState(
    JSON.parse(localStorage.getItem("unilever_rol"))
  );
  const [refreshTokenState] = useState(localStorage.getItem("refreshToken"));
  const [idUnilever] = useState(
    JSON.parse(localStorage.getItem("unilever_id"))
  );
  const [unileverUserData, setUnileverUserData] = useState([]);
  const [datesProf, setDatesProf] = useState([]);
  const [datesMentor, setDatesMentor] = useState([]);
  const [match, setMatch] = useState([]);
  const history = useHistory();
  const { lang } = useSelector((state) => state);
  const [popup2, setPopup2] = useState(false);
  const [message, setMessage] = useState("");
  const [ready, setReady] = useState(false);
  const [ready2, setReady2] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const dispatch = useDispatch();

  const storeTokenData = (accessToken, refreshToken, expirationDate) => {
    localStorage.setItem("accessToken", accessToken);
    localStorage.setItem("refreshToken", refreshToken);
    localStorage.setItem("expirationDate", expirationDate);
  };

  const newExpirationDate = () => {
    var expiration = new Date();
    expiration.setHours(expiration.getHours() + 1);
    return expiration;
  };

  const handleTokenFromQueryParams = () => {
    const query = new URLSearchParams(window.location.search);
    const accessToken = query.get("accessToken");
    const refreshToken = query.get("refreshToken");
    const expirationDate = newExpirationDate();
    console.log("App.js 30 | expiration Date", expirationDate);
    if (accessToken && refreshToken) {
      storeTokenData(accessToken, refreshToken, expirationDate);
      setIsLoggedIn(true);
      axios
        .post("https://backend-production-1bc8.up.railway.app/api/unilever/saveToken", {
          refreshToken: refreshToken,
          access_token: accessToken,
          id: idUnilever,
        })
        .then((response) => {
          console.log(response);
          setTimeout(() => {
            history.push("/unilever/home");
            window.location.reload();
          }, 2000);
        });
    }
  };

  const responseGoogle = () => {
    axios
      .post("https://backend-production-1bc8.up.railway.app/api/unilever/createlink")
      .then((response) => {
        console.log(response);
        window.location.href = response.data.url;
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    if (refreshTokenState) {
      setIsLoggedIn(true);
    }
    handleTokenFromQueryParams();
  }, []);

  useEffect(() => {
    axios
      .get(
        `https://backend-production-1bc8.up.railway.app/api/unilever/find/match/user/${idUnilever}`
      )
      .then((res) => {
        setMatch(res.data.data.mentorvsstudent_relation);
        dispatch(setDataUnilever(res.data.data));
        setUnileverUserData(res.data.data);
        setReady2(true);
      });
  }, []);

  useEffect(() => {
    if (rolUnilever === "ESTUDIANTE" && match?._id) {
      axios
        .get(
          `https://backend-production-1bc8.up.railway.app/api/unilever/scheldule/${match._id}`
        )
        .then((res) => {
          setDatesProf(res.data.data);
          setDatesMentor(res.data.data);
          console.log(res.data.data);
          setReady(true);
        })
        .catch((err) => {
          console.log(err);
          dispatch(setErrorMessageUnilever("No hay horarios disponibles"));
          setReady(true);
        });
    }
  }, [match]);

  const left = () => {
    document.getElementById("cards").scrollLeft -= 450;
  };

  const right = () => {
    document.getElementById("cards").scrollLeft += 450;
  };

  return (
    <div>
      <Sidebar location={"unilever home"} />
      <div
        className="BodyWeb"
        style={{
          padding: "0rem 2rem",
          position: "absolute",
          right: "0",
        }}
      >
        <Navbar locate={"home"} />
        <div className="home-unilever-banner">
          <BannerBancolombia />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
          }}
        >
          <div id={"gus"} className={"container-buttons-uni"}>
            <SelectHorarioUnilever
              popup={popup}
              setPopup={setPopup}
              idUnilever={idUnilever}
            />
            {ready && isLoggedIn && (
              <SelectHorarioUnileverProf
                popup={popup2}
                setPopup={setPopup2}
                message={message}
                datesProf={datesProf}
                mentor={match?.name}
                datesMentor={datesMentor}
              />
            )}
            {/*div bugstavo*/}
            {/*    {rolUnilever === 'MENTOR' && unileverUserData && ready2 && !unileverUserData?.reservations?.length && !unileverUserData?.mentor_schedule.dates?.length ?
              <button className={'button-home-unilever'} onClick={() => setPopup(true)}>
                <img src={contratando} alt='contratando'/>
                <span>{lang === 'es' ? 'Programa tus sesiones' : 'Program your sessions'}</span>
              </button> : null}*/}
            {rolUnilever === "MENTOR" && unileverUserData && ready2 ? (
              <>
                <Link to="/unilever/calendario">
                  <button className={"button-home-unilever"}>
                    <img src={oficina} alt="oficina" />
                    <span>
                      {lang === "es" ? "Ver mis reuniones" : "See my meetings"}
                    </span>
                  </button>
                </Link>
              </>
            ) : null}
            {/*            {rolUnilever === "ESTUDIANTE" && unileverUserData?.refresh_token && ready && match && !match.reservations?.length ?
              <button className={'button-home-unilever'} onClick={() => setPopup2(true)}>
                <img src={oficina} alt='oficina'/>
                <span>{lang === 'es' ? 'Selecciona tus horarios' : 'Select your schedules'}</span>
              </button> : null}*/}
            {rolUnilever === "ESTUDIANTE" &&
            ready &&
            !unileverUserData?.refresh_token ? (
              <GoogleButton onClick={() => responseGoogle()} />
            ) : null}
            {match && unileverUserData && unileverUserData.refresh_token ? (
              <>
                <Link to="/unilever/calendario">
                  <button className={"button-home-unilever"}>
                    <img src={oficina} alt="oficina" />
                    <span>
                      {lang === "es" ? "Ver mis reuniones" : "See my meetings"}
                    </span>
                  </button>
                </Link>
              </>
            ) : null}
          </div>
          <div
            id={"marce"}
            className={"home-unilever-carrusel-global-container"}
          >
            {/*div Marcelo*/}
            <div className={"home-unilever-carrusel-top"}>
              <h1 className={"home-unilever-subtitle"}>
                {lang === "es"
                  ? "Descarga las guías para tus sesiones."
                  : "Download the guides for your sessions."}
              </h1>
              <div>
                <button
                  className={"home-unilever-arrow-button"}
                  onClick={() => left()}
                >
                  <img src={arrowL} alt={"left"} />
                </button>
                <button
                  className={"home-unilever-arrow-button"}
                  onClick={() => right()}
                >
                  <img src={arrowR} alt={"right"} />
                </button>
              </div>
            </div>
            <div
              id={"cards"}
              className={"home-unilever-carrusel-sub-container"}
            >
              {rolUnilever === "MENTOR"
                ? CardDataEspMentores.map((card) => {
                    return (
                      <CarruselCards
                        key={card.id}
                        image={card.image}
                        title={card.title}
                        desc={card.description}
                        rol={rolUnilever}
                        video={card.isVideo ? card.isVideo : false}
                        link={card.link ? card.link : ""}
                      />
                    );
                  })
                : CardDataEng.map((card) => {
                    return (
                      <CarruselCardsStudentWAssistance
                        key={card.id}
                        id={card.id}
                        image={card.image}
                        title={card.title}
                        desc={card.description}
                        rol={rolUnilever}
                        video={card.isVideo ? card.isVideo : false}
                        link={card.link ? card.link : ""}
                        user={idUnilever}
                      />
                    );
                  })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeUnilever;
