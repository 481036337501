import React, { useEffect, useState } from "react";
import "./TestCard.css";
import Test1 from "../../../assets/imagenes/test1.svg";
import Test2 from "../../../assets/imagenes/test2.svg";
import Test3 from "../../../assets/imagenes/test3.svg";
import OK from "../../../assets/imagenes/circle-ok.svg";
import { Box, Modal } from "@mui/material";
import { useSelector } from "react-redux";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import TestCardButton from "./TestCardButton";
const TestCard = ({ id, name, description, color }) => {
  const { lang } = useSelector((state) => state);
  const [open, setOpen] = useState(false);
  const [test, setTest] = useState(false);
  const [user, setUser] = useState();
  const [testName, setTestName] = useState(null);
  const [testResult, setTestResult] = useState(null);

  const userLocal = JSON.parse(localStorage.getItem("user"));
  useEffect(() => {
    axios
      .get(`https://findally-backend.herokuapp.com/user/${userLocal.id}`)
      .then((r) => {
        setUser({
          social_skills: r.data.social_skills,
          sector_test: r.data.sector_test,
          performance_test: r.data.performance_test,
          personalityTest: r.data.personalityTest || "",
        });
      });
  }, [userLocal.id]);

  

  const handleOpen = () => {
    if (
      (name === "Test de personalidad" || name === "Personality test") &&
      user?.personalityTest?.length === 0
    ) {
      setOpen(true);
      setTestName(name);
      return;
    }
    if (
      (name === "Test Vocacional" || name === "Vocational test") &&
      user.sector_test.length === 0
    ) {
      setOpen(true);
      setTestName(name);
      return;
    }
    if (
      (name === "Test de Inteligencia" || name === "Inteligence test") &&
      user.performance_test.length === 0
    ) {
      setOpen(true);
      setTestName(name);
      return;
    }
    if (
      (name === "Habilidades sociales" || name === "Social skills") &&
      user.social_skills.length === 0
    ) {
      setOpen(true);
      setTestName(name);
      return;
    } else
      toast.error("Ya has enviado este test" + name, {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
  };

  function testForm(e, name) {
    setTest(true);
  }

  const style = {
    backgroundColor: "white",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    height: 250,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "10px",
  };

  const enviar = (e, nombreTest) => {
    e.preventDefault();
    const typeTest =
      nombreTest === ("Test de personalidad" || "Personality test")
        ? "personalityTest"
        : nombreTest === ("Test vocacional" || "Vocational test")
        ? "sector_test"
        : nombreTest === ("Test de Inteligencia" || "Inteligence test")
        ? "performance_test"
        : nombreTest === ("Habilidades sociales" || "Social skills")
        ? "social_skills"
        : null;
    if (testResult === null) {
      return toast.error("Debes insertar el resultado del test", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
    if (typeTest === "personalityTest" || typeTest === "performance_test") {
      if (!testResult.includes("https://")) {
        return toast.error("Debes insertar el link del test", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    }
    axios
      .put(
        "https://findally-backend.herokuapp.com/update/user/" + userLocal.id,
        {
          [typeTest]: testResult,
        }
      )
      .then((r) => {
        setTest(false);
        toast.success("Test enviado con exito", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      })
      .catch((err) => {
        toast.error("Error al enviar el test", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  };

  const urls =
    (name === "Test de personalidad" || name === "Personality test") &&
    user?.personalityTest?.length === 0
      ? "https://principlesyou.com/assessments/pdp"
      : (name === "Test Vocacional" || name === "Vocational test") &&
        user?.sector_test?.length === 0
      ? "https://www.testgratis.net/psicologicos/test-vocacional-chaside"
      : (name === "Test de Inteligencia" || name === "Inteligence test") &&
        user?.performance_test?.length === 0
      ? "https://www.iqtests.org/es/test-de-inteligencia"
      : (name === "Habilidades sociales" || name === "Social skills") &&
        user?.social_skills?.length === 0
      ? "https://www.mydiscprofile.com/es-co/free-personality-test.php"
      : null;

  return (
    <div>
      <Modal open={open} onClose={() => setOpen(false)}>
        <Box style={style}>
          <div className="Profesional-Test-Card-Sub-container">
            {name === "Test de personalidad" ||
            name === "Personality test" ||
            name === "Test de Inteligencia" ||
            name === "Inteligence test" ? (
              <h6>
                {lang === "es"
                  ? "Una vez que completes el test, recibirás una URL como resultado. Copia esa URL y pégala en el cuadro siguiente para que podamos procesar tus respuestas de manera adecuada."
                  : "After completing the test, you will receive a URL as a result. Copy that URL and paste it into the following box so we can properly process your answers."}
              </h6>
            ) : (
              <h6>
                {lang === "es"
                  ? "Una vez que completes el test, se te proporcionará una respuesta. Copia la respuesta y pégala en el cuadro siguiente para que podamos procesar tus respuestas de manera adecuada."
                  : "After completing the test, you will receive a response. Copy that response and paste it into the following box so we can properly process your answers."}
              </h6>
            )}
            <div
              style={{
                display: "flex",
                justifyContent: "space-around",
                width: "100%",
              }}
            >
              <button
                onClick={() => setOpen(false)}
                className="Profesional-Test-Button"
              >
                {lang === "es" ? "Cancelar" : "Cancel"}
              </button>
              <a href={urls} target={"_blank"} rel={"noopener noreferrer"}>
                <button
                  onClick={(e) => {
                    testForm(e, name);
                    setOpen(false);
                  }}
                  className="Profesional-Test-Button "
                >
                  {lang === "es" ? "Ir al test" : "Go test"}
                </button>
              </a>
            </div>
          </div>
        </Box>
      </Modal>

      <Modal open={test} onClose={() => setTest(false)}>
        <Box style={style}>
          {testName === "Test de personalidad" ||
          testName === "Personality test" ||
          testName === "Test de Inteligencia" ||
          testName === "Inteligence test" ? (
            <>
              <h3 style={{ textAlign: "center" }}>
                {lang === "es"
                  ? `Envíanos la url del resultado de tu ${testName}`
                  : `Send us the url of your ${testName} result`}
              </h3>

              <input
                onChange={(e) => setTestResult(e.target.value)}
                type="text"
                name={testName}
                placeholder={
                  lang === "es" ? "Url del resultado..." : "Result url..."
                }
                style={{ width: "90%", border: "solid 1px #D3D3D3" }}
              />
            </>
          ) : (
            <>
              <h3 style={{ textAlign: "center" }}>
                {lang === "es"
                  ? `Envianos el resultado de tu ${testName}`
                  : `Send to us your ${testName} result`}
              </h3>
              <input
                onChange={(e) => setTestResult(e.target.value)}
                type="text"
                name={testName}
                placeholder={lang === "es" ? "Resultado..." : "Result..."}
                style={{ width: "90%" }}
              />
            </>
          )}
          <TestCardButton
            onClick={(e) => enviar(e, testName)}
            color={color ? color : ""}
          >
            {lang === "es" ? "Enviar" : "Send"}
          </TestCardButton>
        </Box>
      </Modal>

      <div id={ id  === 1 ? 'testPers' : null} className={"Profesional-Test-Card-container"} onClick={handleOpen}>
        <div>
          <div
            style={{
              // position: "absolute",
              marginTop: "-10px",
              marginLeft: "-8px",
            }}
          >
            {id === 1 && user?.personalityTest?.length > 0 ? (
              <img src={OK} alt={OK} />
            ) : null}
            {id === 2 && user?.sector_test?.length > 0 ? (
              <img src={OK} alt={OK} />
            ) : null}
            {id === 3 && user?.performance_test?.length > 0 ? (
              <img src={OK} alt={OK} />
            ) : null}
            {id === 4 && user?.social_skills?.length > 0 ? (
              <img src={OK} alt={OK} />
            ) : null}
          </div>
          <div className={"Profesional-Test-Card-image-container"}>
            {id === 1 ? <img src={Test1} alt={Test1} /> : null}
            {id === 2 ? <img src={Test2} alt={Test2} /> : null}
            {id === 3 ? <img src={Test3} alt={Test3} /> : null}
            {id === 4 ? <img src={Test1} alt={Test1} /> : null}
          </div>
        </div>
        <div className={"Profesional-Test-Card-sub-container"}>
          <h3 className={"Profesional-Test-Card-Title"}>{name}</h3>
          <p className={"Profesional-Test-Card-P"}>{description}</p>
        </div>
      </div>
      {/* </a> */}
    </div>
  );
};

export default TestCard;
