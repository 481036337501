import React, {useState, useEffect} from 'react' ;
import './welcomeCoursesCard.css'
import {changeCourseAdm} from "../administracion/admBootcampUtils";
import Spinner from "react-bootstrap/Spinner";
/*import ReactPlayer  from "react-player";*/
import Dialog from '@mui/material/Dialog';
/*import DialogActions from '@mui/material/DialogActions';*/
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import PrecourseModalCard from "./PrecourseModalCard";

const WelcomeCoursesCard = ({title, setCourse, course, image, description}) => {
  const [loading, setLoading] = useState(null);
  const [open, setOpen] = useState(false);
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);


  const updateWidthAndHeight = () => {
    setWidth(window.innerWidth);
    setHeight(window.innerHeight);
  }
  useEffect(() => {
    window.addEventListener("resize", updateWidthAndHeight);
    return () => window.removeEventListener("resize", updateWidthAndHeight);
  }, [width, height]);

  return (
    <div className={'welcome-courses-card-container'} style={{
      backgroundImage: `url(${image})`,
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat'
    }}>
      <div className={'welcome-courses-card-title-container'}>
        <h1 style={{
          margin: '0',
          padding: '10px',
          textAlign: 'center'
        }} className={'card-course-bootcamp-title'}>{title.charAt(0).toUpperCase() + title.slice(1)}</h1>
          <p className={'card-course-bootcamp-descr'}>{description}</p>

        <div>
          {loading ? <Spinner animation="border" variant="light"/>
            : <button onClick={() => {
              changeCourseAdm(title, setCourse, setLoading, setOpen, image)
            }} className={'welcome-courses-card-button'}>Iniciar</button>}
        </div>
      </div>
      {course && !loading && <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>{title.charAt(0).toUpperCase() + title.slice(1)}</DialogTitle>
        <DialogContent>
          <div className={'bootcamp-modal-precourses-container'}>
            {course.map((course, index) => {
              return (
                <PrecourseModalCard
                  key={index} video={course.video ? course.video : ''}
                  title={course.title ? course.title : ''}
                  image={course.image ? course.image : ''}
                  file={course.file ? course.file : ''}
                />
              )
            })}
          </div>
        </DialogContent>
      </Dialog>}
    </div>
  )
}

export default WelcomeCoursesCard