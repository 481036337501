/* eslint-disable */
import React, {useState, useEffect} from "react";
import axios from "axios";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { toast } from "react-toastify";
import 'dayjs/locale/es-mx';
import './selecthorariounilever.css'
import { useSelector } from "react-redux";
import Select from "react-select";

dayjs.extend(utc);
const dateAlreadyClicked = (dates, date) => dates.some(d => dayjs(date).isSame(dayjs(d.date), 'day'))
const datesExcept = (dates, date) => dates.filter(d => !dayjs(date).isSame(dayjs(d.date), 'day'))

const customStyles = {
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? '#1f36c7' : '#f2f2f2',
    cursor: 'pointer',
    padding: 10,
    color: state.isSelected ? '#fff' : '#4a4a4a',
  }),
  control: (provided, state) => ({
    ...provided,
    width: '120px',
    display: 'flex',
    alignItems: 'center',
    padding: '0',
    justifyContent: 'center',
    borderRadius: '8px',
    background: 'transparent',
    backgroundColor: state.isSelected ? '#005eef' : '#F5F5F5',
    color: state.isSelected ? '#fff' : '#4a4a4a',
    marginLeft: '10px',
  }),
  menu: (provided, state) => ({
    ...provided,
    color: 'white',
    padding: '0',
    width: '90%',
    marginTop: '-1em',
    marginLeft: '10px',
  }),
  placeholder: (provided, state) => ({
    ...provided,
    color: 'white',
  }),
  singleValue: (provided, state) => ({
    ...provided,
    color: 'black',
  }),
  input: (provided, state) => ({
    ...provided,
    color: 'black',
  }),
  menuList: (provided, state) => ({
    ...provided,
    background: '#f2f2f2',
  }),
  multiValueRemove: (provided, state) => ({
    ...provided,
    color: 'white',
    cursor: 'pointer',
  }),
  multiValue: (provided, state) => ({
    ...provided,
    background: 'transparent',
    color: 'black',
  }),
  multiValueLabel: (provided, state) => ({
    ...provided,
    color: 'black',
  }),
}

export default function SelectHorarioUnileverProf(props) {
  const[selectedDates, setSelectedDates] = useState([])
  const [selectedDatesWithHours, setSelectedDatesWithHours] = useState([])
  const maxDates = 6
  const lang = useSelector(state => state.lang)
  const errorMessageUnilever = useSelector(state => state.errorMessageUnilever)
  const unileverMatch = useSelector(state => state.unileverMatch)


  const onClickDay = date => {
    const parsedDate = new Date(date);
    const utcDate = new Date(parsedDate).setUTCHours(0, 0, 0, 0);
    if (dateAlreadyClicked(selectedDates, utcDate)) return setSelectedDates(datesExcept(selectedDates, utcDate))
        if(selectedDates.length >= maxDates) {
          return toast.error(lang === 'es' ? 'Solo puedes seleccionar 6 fechas' : 'You can only select 6 dates', {
            position: 'top-center',
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          })
        }
        /*setSelectedDates([...selectedDates, date])*/
    else {
      const findDay = props.datesMentor.find(d => dayjs(utcDate).isSame(dayjs(d.date), 'day'))
      if(findDay){
        setSelectedDates([...selectedDates, findDay])
      } else {
        return toast.error(lang === 'es' ? 'No hay horas disponibles para esta fecha' : 'There are no hours available for this date', {
          position: 'top-center',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        })
      }
    }
  }
  const tileClassName = ({ date }) => {
    const parsedDate = new Date(date);
    const utcDate = dayjs.utc(parsedDate)
    const classes ={
      dayTile: 'react-calendar__tile',
      activeDay: 'react-calendar__tile--active',
    }
    const classNames = [classes.dayTile]
    if (dateAlreadyClicked(selectedDates, utcDate)) return [classes.activeDay, ...classNames]
    return classNames
  }

  const addDateHour = (day, hour) => {
    const selectedDate = selectedDatesWithHours.find(date => date.date === day);

    if (selectedDate) {
      // La fecha ya existe en el arreglo, actualizamos la hora
      const newHours = [hour];
      const newDates = selectedDatesWithHours.map(date =>
        date.date === day ? {...date, hours: newHours} : date
      );
      setSelectedDatesWithHours(newDates);
    } else {
      // La fecha no existe en el arreglo, la agregamos con la hora seleccionada
      const newDate = {date: day, hours: [hour]};
      setSelectedDatesWithHours([...selectedDatesWithHours, newDate]);
    }
  }

//disable all dates diferents than the ones in the array props.datesMentor
/*  const isDisabled = ({date}) => {
    if(!Array.isArray(props.datesMentor)){
     return
    }
    const utcDate = dayjs(date).utc().format('YYYY-MM-DD')
    return !props.datesMentor.some(d => dayjs(utcDate).isSame(dayjs(d.date), 'day')) || props?.eventos?.some(e => dayjs(utcDate).isSame(dayjs(e.start), 'day'))
  }*/

  const isDisabled = ({ date }) => {
    if (!Array.isArray(props.datesMentor)) {
      return;
    }
    const parsedDate = new Date(date);
    const utcDate = dayjs.utc(parsedDate)
    return (
      !props.datesMentor.some((d) =>
        dayjs(utcDate).isSame(dayjs(d.date).utc(), 'day')
      ) || props?.eventos?.some((e) => dayjs(utcDate).isSame(dayjs(e.start), 'day'))
    );
  };

  const handleSubmit = () => {
    if(!selectedDatesWithHours.length){
      return toast.error(lang === 'es' ? 'debes seleccionar un dia y una hora' :  'you must select a day and an hour', {
        position: 'top-center',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
    }
    const parsedDates = selectedDatesWithHours.map(date => {
      const hourSplit = date.hours[0].split(':')
      const hour = hourSplit[0]
      const parsedDate = new Date(date.date)
      parsedDate.setUTCHours(hour)
      return parsedDate.toISOString()
    })
    toast.promise(axios.post('https://findally-backend.herokuapp.com/api/unilever/reservation',{
      dates: parsedDates,
      mentor: unileverMatch?.mentorvsstudent_relation?._id,
      student: unileverMatch?._id,
      name: 'Mentoría Unilever',
      area: 'Metele Pedal a tu futuro',
      description: ''
    }),{
      pending: lang === 'es' ? 'Cargando...' : 'Loading...',
      success: lang === 'es' ? 'Reservación exitosa' : 'Successful reservation',
      error: lang === 'es' ? 'Error al reservar' : 'Error reserving'
    }).then(res => {
      console.log(res)
      setTimeout(() => {
        window.location.reload()
      }, 2000)
    })
  }

  const formatDateSelected = (date) => {
    const parsedDate = new Date(date);
    const utcDate = dayjs.utc(parsedDate);
    return utcDate.locale('es-mx').format('LL');
  }

  const findOptionInSelectedDates = (options, day) => {
    const findDay = selectedDatesWithHours.find(d => dayjs(day.date).isSame(dayjs(d.date), 'day'));
    const findHour = findDay?.hours[0];
    const selectedOption = options.find(option => option.value === findHour);

    return selectedOption;
  }

  return (
  <>
  {
            props.popup && (
              <Dialog
            style={{}}
            open={props.popup}
            onClose={() => props.setPopup(false)}
            // scroll={scroll}
            aria-labelledby="scroll-dialog-title"
            aria-describedby="scroll-dialog-description"
          >
            <DialogTitle id="scroll-dialog-title">
              <div style={{width: '100%', height: '100%', borderBottom: '1px solid #D6D6D6', textAlign: 'left'}}>
                <span style={{
                  fontSize: '20px',
                  fontWeight: 'bold',
                  color: '#393939',
                }}>
                  {lang === 'es' ? 'Horarios de reuniones' : 'Meeting times'}
                </span>
            </div>
            </DialogTitle>
            <DialogContent>
            <div style={{width: '100%', height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
              <div className="popup-date-unilever-container">
                {
                  errorMessageUnilever ?
                      <div className="popup-date-unilever-inner-1-1">
          <span style={{fontWeight: '400', fontSize: '16px', color: '#393939'}}>
          {lang === 'es' ? 'Tu mentor aún no ha seleccionado un horario, vuelve mas tarde' : 'Your mentor has not yet selected a schedule, come back later'}
        </span>
                      </div> : null
                }
                {
                    errorMessageUnilever === '' &&
                    <div className="popup-date-unilever-inner-2">
                      <div style={{width: '100%', height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
                        <span style={{fontWeight: '400', fontSize: '16px', color: '#393939'}}>{lang === 'es' ? 'Selecciona al menos un día y hora que tu mentor ha propuesto para llevar a cabo la sesión' : 'Select at least one day and time that your mentor has proposed to carry out the session'}</span>
                      </div>
                      <Calendar
                          locale={lang === 'es' ? 'es-mx' : 'en'}
                          onClickDay={onClickDay}
                          tileClassName={tileClassName}
                          tileDisabled={isDisabled}
                         /* tileContent*/
                      />
                      {
                        selectedDates && selectedDates.length ? (
                          <div style={{width: '100%', height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', paddingTop: '5%'}}>
            <span style={{fontWeight: '400', fontSize: '16px', color: '#393939', textAlign: 'left', display: 'flex', alignSelf: 'flex-start'}}>
            {lang === 'es'? 'Selecciona los horarios disponibles:' : 'Select the available times:'}
            </span>
                        <div style={{
                          paddingTop: '5%',
                          display: 'flex',
                          flexDirection: 'column',
                          width: '100%',
                          justifyContent: 'space-between',
                          alignItems: 'center'
                        }}>
                          {selectedDates.map((day, index) => {
                            const options = day.hours.map(hour => ({value: hour, label: hour}))
                            return (
                              <div key={index} style={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                width: '100%',
                                height: '100%'
                              }}>
                                <p>
                                  {formatDateSelected(day.date)}
                                </p>
                                <Select
                                  key={index}
                                  options={options}
                                  value={findOptionInSelectedDates(options, day)}
                                  onChange={(selected) => addDateHour(day.date, selected.value)}
                                  placeholder="Selecciona un horario"
                                  styles={customStyles}
                                />
                              </div>
                            )
                          })}

                        </div>
                        <div style={{paddingTop: '5%'}}>
                          <button className="unilever-select-button-hour2" onClick={()=>handleSubmit()} style={{display: 'flex', alignItems: 'center', justifyContent: 'center', alignSelf: 'flex-start'}}>
                            {lang === 'es' ? 'Seleccionar' : 'Select'}
                          </button>
                        </div>
                      </div>
                      ): null
                      }
                    </div>
                }
              </div>
            </div>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => props.setPopup(false)}>
              {lang === 'es' ? 'Cerrar' : 'Close'}
              </Button>
            </DialogActions>
          </Dialog>
            )
          }
  </>
  )
}
