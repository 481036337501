/* eslint-disable */
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getPostedProjects, ConsultorData, tourStatus, tourViewedCompanyStatus } from "../../actions";
import { Link, useHistory, useLocation } from "react-router-dom";
import axios from "axios";
import Loader from "../Loader/Loader";
import ReactPaginate from "react-paginate";
import CardOportunidadGrid from "./OportunityCard/CardOportunidadGrid";
import CardOportunidadList from "./OportunityCard/CardOportunidadList";
import "./home.css";
import notFound from '../../assets/icons/notFound.svg';
import {
  BarChart,
  Bar,
  XAxis,
  Tooltip,
  Legend
} from "recharts";
import NavigateBeforeOutlinedIcon from '@mui/icons-material/NavigateBeforeOutlined';
import NavigateNextOutlinedIcon from '@mui/icons-material/NavigateNextOutlined';
import Sidebar from "../../components/Sidebar/Sidebar";
import UseTourCom from "./useTourCom";
import PopUpPayInfo from '../popUpInfos/PopUpPayInfo';
import { CSVLink, CSVDownload } from "react-csv";
import Navbar from "../../components/Navbar/Navbar";
import WindowIcon from '@mui/icons-material/Window';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import EastOutlinedIcon from '@mui/icons-material/EastOutlined';


export default function HomeempresaV2() {
  const [popupInfoPay, setpopupInfoPay] = useState(false)
  const dispatch = useDispatch();
  const history = useHistory();
  const tourViewedCompany = useSelector((state) => state.tourViewedCompany);
  const user = useSelector((state) => state.user);
  const lang = useSelector((state) => state.lang);
  const [statistics, setStatistics] = useState([]);
  const [OpenTestOrganizacional, setOpenTestOrganizacional] = useState(false);
  const [dataEpayco, setDataEpayco] = useState()
  const [page, setPage] = useState({
    currentPage: 0,
    totalPage: 0,
  });
  const [orderProyectos, setOrderProyectos] = useState('grid')
  const [loading, setLoading] = useState(true);
  const [proyectosdeluser, setproyectosdeluser] = useState([]);
  const [proyectosCsv, setproyectosCsv] = useState([]);
  const [openTestCulturalResult, setOpenTestCulturalResult] = useState(false);

  console.log(proyectosdeluser)


  const handleCloseOrganizacional = () => {
    setOpenTestOrganizacional(false);
  }
  const handleCloseTestCulturalResult = () => {
    setOpenTestCulturalResult(false);
  }
  const handleOpenTestCulturalResult = () => {
    setOpenTestCulturalResult(true);
  }

  const [width, setWidth] = useState(window.innerWidth);
  const [height, setHeight] = useState(window.innerHeight);
  const params = new URLSearchParams(useLocation().search);


  useEffect(() => {
    window.addEventListener("resize", updateWidthAndHeight);
    if (width < 650) {
      setOrderProyectos("grid")
    }
    return () => window.removeEventListener("resize", updateWidthAndHeight);
  }, [width, height]);

  const updateWidthAndHeight = () => {
    setWidth(window.innerWidth);
    setHeight(window.innerHeight);
  }

  const popUpInfopayClose = () => {
    setpopupInfoPay(false)
    history.push('/company/home')
  }

  const location = useLocation();
  useEffect(() => {
    window.addEventListener("resize", updateWidthAndHeight);
    setpopupInfoPay(location.search.includes('ref_payco'))
    return () => window.removeEventListener("resize", updateWidthAndHeight);
  }, [width, height]);

  useEffect(() => {
    const refPayco = params.get("ref_payco")
    axios.get(`https://secure.epayco.co/validation/v1/reference/${refPayco}`)
      .then((res) => {
        console.log(res.data.data.x_response)
        setDataEpayco(res.data.data.x_response)
      })
      .catch((err) => {
        console.log(err)
      })
  }, [])

  const services = localStorage.getItem('additionals') ? JSON.parse(localStorage.getItem('additionals')) : []

  useEffect(() => {
    if (dataEpayco === 'Aceptada') {
      axios.post('https://findally-backend.herokuapp.com/api/premium_payment/add/additional-service', {
        userId: user.id,
        services: services
      })
        .then((res) => {
          console.log(res)
          localStorage.removeItem('additionals')
        }
        )
        .catch((err) => {
          console.log(err)
        })
    }
  }, [dataEpayco])

  const styleTestCultural = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: width > 600 ? 600 : '90%',
    height: '85vh',
    bgcolor: '#FFFFFF',
    borderRadius: '8px',
    overflow: 'auto',
  }
  const styleTestCulturalResult = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: width > 600 ? 600 : '90%',
    height: '90vh',
    bgcolor: '#FFFFFF',
    border: '2px solid #000',
    borderRadius: '8px',
    boxShadow: 24,
    overflow: 'auto'
  }

  const headers = [
    { label: "Nombre", key: "NombreOportunidad" },
    { label: "Estado", key: "status" },
    { label: "Tipo de servicio deseado", key: "tipoDeServicioDeseado" },
    { label: "Modalidad de contratación", key: "modalidadDeContratacion " },
    { label: "Anonimo", key: "Anonimo" },
    { label: "Profesionales", key: "profesionales" },
    { label: "Carrera", key: "SeleccionaCarrera" },
    { label: "Duración", key: "estimadoDuración" },
    { label: "Ciudad", key: "ciudad" },
    { label: "Fecha de publicación", key: "fecha_inicio" },
    { label: "Nivel de inglés", key: "nivel_ingles" },
    { label: "Presupuesto", key: "presupuesto" },
    { label: "Descripcion", key: "DescribeProyecto" },
    { label: "Area de conocimiento", key: "empleos_alternativos" },
    { label: "Habilidades", key: "habilidadesTecnicas" },
    { label: "Habilidades blandas", key: "habilidadesBlandas" },
    { label: "Responsabilidades", key: "responsabilidadYfunciones" },
  ]

  const csvReport = {
    filename: 'Oportunidades.csv',
    headers: headers,
    data: proyectosCsv
  }

  useEffect(() => {
    axios.get(`https://findally-backend.herokuapp.com/api/user/projects/Company/${user.id}`)
      .then((res) => {
        setproyectosCsv(res.data.projects)
      })
  }, []);

  useEffect(() => {
    if (!user.id) {
      return history.push("/");
    }
    dispatch(ConsultorData(user.id));
    dispatch(getPostedProjects());
    axios.get("https://findally-backend.herokuapp.com/api/user/projects/Company/" + user.id + `?skip=${page.currentPage}`)
      .then((e) => {
        setproyectosdeluser(e.data.projects);
        //order by aplicantes length 
        setStatistics(e.data?.projects?.map((e) => {
          return {
            nombre: e.nombre && e.nombre !== "Unnamed Oportunity"
              ? e.nombre
              : e.nombreProyecto &&
                e.nombreProyecto !== "Unnamed Oportunity"
                ? e.nombreProyecto
                : e.NombreOportunidad &&
                  e.NombreOportunidad !==
                  "Unnamed Oportunity"
                  ? e.NombreOportunidad
                  : "",
            aplicantes: e.aplicantes.length ? e.aplicantes.length : 0
          }
        }))
        setPage({ ...page, totalPage: e.data.totalPag });
      })
      .finally(() => setLoading(false));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, user.id]);

  const handlePageClick = (evento) => {
    if (!user.id) {
      return history.push("/");
    }
    setproyectosdeluser([]);
    axios.get("https://findally-backend.herokuapp.com/api/user/projects/Company/" + user.id + `?skip=${evento.selected}`).then((el) => {
      setproyectosdeluser(el.data.projects);
      setPage({
        ...page,
        currentPage: page.currentPage + 1,
        totalPage: el.data.totalPag,
      });
    });
  };


  const redirect = () => {
    if (!user.id) {
      return history.push("/")
    }
    history.push("/consultor/home");
  };

  useEffect(() => {
    if (tourViewedCompany === true) {
      return
    }
    setTimeout(() => {
      dispatch(tourStatus({ run: true }))
    }, 1500);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderCustomBarLabel = ({ payload, x, y, width, height, value }) => {
    return (
      <text x={x + width / 2} y={y} fill="#666" textAnchor="middle" dy={-6}>
        {`${value}`}
      </text>)
  }

    const handleTour = () => {
      localStorage.setItem('tourViewedCompany', false)
      dispatch(tourViewedCompanyStatus(false))
      dispatch(tourStatus({run: true}))
    }

  return (
    <div className="contenedor-oportunidad">
      {
        user && user.roles === "company" ? (
          <div>
            {popupInfoPay &&
              <PopUpPayInfo open={popupInfoPay} handleClose={popUpInfopayClose} handleFinish={popUpInfopayClose} />
            }
            <div className={"contenedor-tour"}>
              <UseTourCom />
            </div>
            <Sidebar location={'home'} />
            <div className="BodyWeb"
              style={{
                padding: "0rem 2rem",
                position: "absolute",
                right: "0",
              }}>
              <Navbar locate={'home'} />
              <h1 id='home' style={{
                marginBottom: "2rem",
                marginLeft: "0",
                fontFamily: "Poppins",
                fontWeight: "700",
                fontSize: "40px",
                color: "#202256",
              }}>
                {lang === "es" ? "¡Bienvenido a Coally!" : "Welcome to Coally!"}
              </h1>
              <div className="new-empresa-home-container">
                <div className="new-empresa-home-banner">
                  <div className="new-empresa-home-banner-text">
                    <h4 style={{
                      fontFamily: "Poppins",
                      fontWeight: "400",
                      fontSize: "20px",
                      color: "#FFF",
                    }}
                    >{lang === 'es' ? '¿Ya haces parte del plan Bussiness?' : 'Are you already part of the Business plan?'}</h4>
                    <h1 style={{
                      fontFamily: "Roboto",
                      fontWeight: "700",
                      fontSize: "36px",
                      color: "#FFF",
                    }}>
                      {lang === 'es' ? "¡Cámbiate ya!" : "Change now!"}
                    </h1>
                  </div>
                  <div className="new-empresa-home-banner-button" id='pricing1'>
                    <Link to='/company/pricing'>
                      <button style={{ background: 'transparent', color: '#fff' }}>
                        {lang === 'es' ? 'Ver detalles' : 'See details'}
                      </button>
                    </Link>
                  </div>
                </div>
                <div className="new-empresa-home-body">
                  <div
                    className="container-formacion-oportunidades-new"
                  >
                    <div className='home-published-opportunities'>
                      <div className="container-grid-list">
                        <h2
                          style={{
                            fontSize: "24px",
                            fontWeight: "700",
                            fontFamily: "Poppins",
                            paddingTop: "5px",
                          }}
                        >
                          {lang === "es" ? "Oportunidades Publicadas" : "Posted Opportunities"}
                        </h2>
                        {width >= 650 ?
                          <div className="Company-Home-Contenedor-3-inner-2">
                            <div className={`${orderProyectos === 'list' ? 'Company-Home-Contenedor-3-inner-2-1-active' : 'Company-Home-Contenedor-3-inner-2-1'}`} onClick={() => {
                              setOrderProyectos('list')
                            }}>
                              <FormatListBulletedIcon sx={{ fontSize: 24, color: `${orderProyectos === 'list' ? '#FFFFFF' : '#C5C5C5'}` }} />
                            </div>
                            <div className={`${orderProyectos === 'grid' ? 'Company-Home-Contenedor-3-inner-2-2-active' : 'Company-Home-Contenedor-3-inner-2-2'}`} onClick={() => {
                              setOrderProyectos('grid')
                            }}>
                              <WindowIcon sx={{ fontSize: 24, color: `${orderProyectos === 'grid' ? '#FFFFFF' : '#C5C5C5'}` }} />
                            </div>
                          </div> : null
                        }
                      </div>
                      {
                        <div
                          style={{
                            width: "100%",
                            paddingRight: "15px",
                          }} id='projects'
                          className={'container-cards-grid'}
                        >
                          {!proyectosdeluser.length && (
                            <div style={{
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                              width: '100%',
                              marginTop: '5%'
                            }}>
                              <Loader
                                render={loading}
                                array={proyectosdeluser}
                                altura={15}
                              />
                            </div>
                          )}
                          {orderProyectos === 'grid' && proyectosdeluser &&
                            proyectosdeluser.map((e, i) => {
                              return (
                                <div key={i + 'c'}>
                                  <CardOportunidadGrid
                                    id={e._id}
                                    titulo={e.NombreOportunidad}
                                    titulo2={e.nombre}
                                    imagen={e.imageUrl}
                                    ciudad={e.municipio ? e.municipio : null}
                                    pais={e.country ? e.country : null}
                                    aplicantes={e.aplicantes}
                                    habilidades={e.habilidadesTecnicas ? e.habilidadesTecnicas.slice(0, 1) : e.habilidadesTecnicas.slice(0, 1)}
                                  />
                                </div>
                              );
                            })}
                          {orderProyectos === 'list' && proyectosdeluser &&
                            proyectosdeluser.map((e, index) => {
                              return (
                                <div key={index} style={{ width: '100%' }}>
                                  <CardOportunidadList
                                    id={e._id}
                                    titulo={e.NombreOportunidad}
                                    titulo2={e.nombre}
                                    imagen={e.imageUrl}
                                    ciudad={e.municipio ? e.municipio : null}
                                    pais={e.country ? e.country : null}
                                    aplicantes={e.aplicantes}
                                    habilidades={e.habilidadesTecnicas ? e.habilidadesTecnicas.slice(0, 1) : e.habilidadesTecnicas.slice(0, 1)}
                                  />
                                </div>
                              );
                            })}
                        </div>
                      }
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          width: "100%",
                          height: "10%",
                        }}
                      >
                        <div>
                          {page.totalPage > 1 &&
                            <ReactPaginate
                              className="paginateStyle"
                              breakLabel="..."
                              nextLabel=">"
                              onPageChange={handlePageClick}
                              pageRangeDisplayed={3}
                              pageCount={page.totalPage}
                              previousLabel="<"
                              renderOnZeroPageCount={null}
                              marginPagesDisplayed={1}
                              activeClassName="pageAct"
                              nextClassName="nextB"
                              previousClassName="previB"
                            />
                          }
                        </div>
                      </div>
                    </div>
                    <div className="new-home-empresa-statistics-container">
                      <div className="new-home-empresa-statistics-title">
                        <h2 style={{
                          fontFamily: "Poppins",
                          fontWeight: "700",
                          fontSize: "14px",
                          color: "#202256",
                        }}>
                          {lang === 'es' ? 'Estadísticas de los aplicantes' : 'Applicants statistics'}
                        </h2>
                      </div>
                      <div className="new-home-empresa-statistics-body">
                        {
                          statistics.length ? (
                            <>
                              <div className="new-home-empresa-statistics-body-title">
                                <h2 style={{
                                  fontFamily: "Poppins",
                                  fontWeight: "700",
                                  fontSize: "14px",
                                  color: "#151515FC"
                                }}>

                                </h2>
                                <div className="new-home-empresa-statistics-arrows">
                                  <div>
                                    <NavigateBeforeOutlinedIcon className='navigate-arrow-statistics' />
                                  </div>
                                  <div>
                                    <NavigateNextOutlinedIcon className='navigate-arrow-statistics' />
                                  </div>
                                </div>
                              </div>

                              <div className="new-home-empresa-statistics-body-chart">
                                <BarChart
                                  width={250}
                                  height={300}
                                  data={statistics}
                                  margin={{
                                    top: 5,
                                    right: 30,
                                    left: 20,
                                    bottom: 5
                                  }}
                                >
                                  <XAxis dataKey="nombre" />
                                  <Tooltip />
                                  <Legend />
                                  <Bar dataKey="aplicantes" label={renderCustomBarLabel} fill="#202256" />
                                </BarChart>
                              </div>
                            </>
                          ) :
                            statistics.length === 0 && (
                              <div style={{ display: "flex", justifyContent: "center", alignItems: "center", width: "100%", height: "100%", flexDirection: 'column' }}>
                                <img src={notFound} alt="no encontrado" />
                                <h2 style={{ fontFamily: "Poppins", fontWeight: "700", fontSize: "14px", color: "#151515FC" }}>
                                  {lang === 'es' ? 'No hay datos para mostrar' : 'No data to show'}
                                </h2>
                              </div>
                            )
                        }

                      </div>
                      <div style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "100%",
                        paddingTop: '20%'
                      }}>
                        {
                          proyectosCsv?.length > 0 ? (
                            <label className="new-home-company-save-op-button">
                              <CSVLink {...csvReport} style={{ textDecoration: 'none', color: 'white', textAlign: 'center' }}>
                                {lang === 'es' ? 'Descargar oportunidades' : 'Download opportunities'}
                              </CSVLink>
                            </label>
                          ) : null
                        }
                      </div>
                      <div className="new-home-company-container-tour">
                      <h1 style={{
                        fontSize: '30px',
                        fontWeight: '700',
                        fontFamily: 'Roboto',
                        color: '#202256',
                      }}>
                        {
                          lang === 'es' ? '¿Aún no sabes cómo se usa la plataforma?' : 'Don\'t you know how to use the platform yet?'
                        }
                      </h1>
                      <button onClick={() => handleTour()} className='tour-button'>
                        {
                          lang === 'es' ? '¡Haz el tour!' : 'Take the tour!'
                        }
                        <EastOutlinedIcon style={{marginLeft: '10px'}}/>
                      </button>
                      <div className="tour-circle"></div>
                    </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : redirect()
      }
    </div>
  )
}