import React from 'react';
import {Page, Text, View, Document, Image} from '@react-pdf/renderer'
import './Certificado.css'

import logo from '../../assets/logos/logo_coally_plf.jpeg'

const hoja = {
  height: '21cm',
  width: '29.7cm',
  border: '1px solid #D3D3D3',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
}

const marco = {
  border: '1px solid rgba(0, 0, 0, 0.5)',
  width: '92%',
  height: '89%',
}

const imagen = {
  position: 'absolute',
  top: '-1px',
  left: '-1px',
  marginTop: '-1px',
  marginLeft: '-1px',
  width: '200px',
}

const contenedorFirmas = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'flex-end',
  width: '100%',
  marginTop: '30px',
}

const contenedorFirmas2 = {
 display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
  width: '100%',
  marginLeft: '100px',
  marginRight: '100px',
  marginBottom: '100px',
}

const underline = {
  width: '100%',
  height: '1px',
  backgroundColor: 'black',
  // marginTop: '-15px',
  marginBottom: '30px',
}

const fecha = {
  fontStyle: 'italic',
  fontWeight: '400',
  fontSize: '14px',
}

const sublineText = {
  fontStyle: 'normal',
  fontWeight: '700',
  fontSize: '14px',
  marginTop: '-30px',
  textAlign: 'center',
}

const sublineText2 = {
  fontStyle: 'normal',
  fontWeight: '400',
  fontSize: '14px',
  // marginTop: '-10px',
  textAlign: 'center',
}

const contenedor = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  marginLeft: '100px',
  marginRight: '100px',
  marginTop: '100px',
}

const detalle = {
  fontStyle: 'normal',
  fontWeight: '400',
  fontSize: '14px',
}

const detalle2 = {
  fontStyle: 'normal',
  fontWeight: '400',
  fontSize: '14px',
  // marginTop: '-20px',
}

const estudiante = {
  fontWeight: '700',
  fontSize: '45px',
  fontStyle: 'italic',
}

const bootcampTitle = {
  fontWeight: '400',
  fontWize: '30px',
}

const tituloCertif = {
  fontStyle: 'normal',
  fontWeight: '700',
  fontSize: '72px',
}



export default function CertificadoTemplate({userData}) {
  return (
    <Document>
      <Page Size={"A4"} orientation={"landscape"}>

        <View style={hoja}>
          <View style={marco}>

            <View>
              <Image style={imagen} src={logo} alt="coally"/>
            </View>

            <View style={contenedor}>
              <Text style={tituloCertif}>CERTIFICADO</Text>
              <Text style={bootcampTitle}>BOOTCAMP ADMINISTRADORES DE EMPRESAS</Text>
              <Text style={detalle2}>SE OTORGA A:</Text>
              <Text style={estudiante}>{userData}</Text>
              <View style={underline}></View>
              <Text style={detalle}>Por sus logros y participación en BOOTCAMP ADMINISTRADORES DE EMPRESAS 2023.
                Emitido por COALLY</Text>
            </View>

            <View style={contenedorFirmas}>
              <View style={contenedorFirmas2}>
                <View>
                  <Text style={fecha}>1 de febrero del 2022</Text>
                  <View style={underline}></View>
                  <Text style={sublineText}>Fecha</Text>
                </View>
                <View>
                  <Text style={fecha}>Aca va la firma de Andres</Text>
                  <View style={underline}></View>
                  <Text style={sublineText}>Andrés Joya</Text>
                  <Text style={sublineText2}>CEO DE COALLY</Text>
                </View>
              </View>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  )
}