/*eslint-disable*/
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import './profesionalCareerIA.css';
import axios from "axios";
import { useHistory } from "react-router-dom";
import Sidebar from '../../../components/Sidebar/Sidebar';
import Navbar from "../../../components/Navbar/Navbar";
import Us from '../../../assets/icons/user.svg';
import LogoCoally from '../../../assets/logos/footer_logo.png'
import LogoCoallyOscuro from '../../../assets/logos/Logo-coally-oscuro.png'
import OpenAiLogo from '../../../assets/logos/open-ai-logo.svg'
import SendIcon from '@mui/icons-material/Send';
import { toast } from "react-toastify";
import Aos from "aos";
import "aos/dist/aos.css";
import Spinner from 'react-bootstrap/Spinner'
import Typewriter from 'typewriter-effect';
import { clearFormationModel, getFormationModel } from "../../../actions";


//https://www.figma.com/file/bRCsb0kTVy7fsHDPrWlxTn/Coally?t=AaZCeKhUGr1lOubN-0

const ProfesionalCareerIA = () => {
  const user = useSelector((state) => state.user);
  const lang = useSelector((state) => state.lang);
  const { formationModel } = useSelector((state) => state);
  const requestsToApi = Number(JSON.parse(localStorage.getItem("resquestToApi-flag")) ? JSON.parse(localStorage.getItem("resquestToApi-flag")).value : 0);
  const history = useHistory();
  const [message, setMessage] = useState("");
  const [response, setResponse] = useState("");
  const [loading, setLoading] = useState(false);
  const [formationLoading, setFormationLoading] = useState(false);
  const storageKey = "requestsToApi";
  const validationKey = "resquestToApi-flag";
  const [expirationDate, setExpirationDate] = useState(null);
  const dispatch = useDispatch();
  const [validated, setValidated] = useState(() => {
    const storedValidation = localStorage.getItem(validationKey);
    return storedValidation ? JSON.parse(storedValidation) : false;
  });
  const [request, setRequest] = useState({
    "role": "",
    "empresa": ""
  });
  const expiration = 24 * 60 * 60 * 1000;

  if (!validated) {
    for (let i = 0; i < localStorage.length; i++) {
      const key = localStorage.key(i);
      if (key.includes(storageKey)) {
        const storedData = localStorage.getItem(key);
        localStorage.setItem(validationKey, JSON.stringify({
          value: storedData ? storedData : 0,
          timestamp: Date.now() + expiration
        }))
        localStorage.removeItem(key);
      } else if (!key.includes(storageKey)) {
        localStorage.setItem(validationKey, JSON.stringify({
          value: 0,
          timestamp: Date.now() + expiration
        }));
      }
    }
    setValidated(true);
  }

  useEffect(() => {
    const storedVarible = localStorage.getItem(validationKey);

    if (storedVarible) {
      const storedExpiration = JSON.parse(storedVarible).timestamp;
      setExpirationDate(storedExpiration);
    }

    const intervalId = setInterval(() => {
      if (expirationDate && expirationDate <= Date.now()) {
        localStorage.removeItem(validationKey);
        localStorage.setItem(validationKey, JSON.stringify({
          value: 0,
          timestamp: Date.now() + expiration
        }))
      }
    }, 60000)

    return () => clearInterval(intervalId);
  }, [expirationDate])

  useEffect(() => {
    Aos.init({
      duration: 1500,
    });
  }, []);

  const redirect = () => {
    if (!user.id) {
      return history.push("/");
    }
    history.push("/");
  };


  function ChatTopCard({ message }) {
    return (
      message &&
      <div className={"chat-top-card-container"} data-aos={message ? 'fade-out' : ''}>
        <div className={"chat-top-card-container-left"}>
          <img src={user && user.avatar ? user.avatar : Us} alt="avatar"
            className={"chat-top-card-container-left-img"} />
        </div>
        <div className={"chat-top-card-container-right"}>
          {message}
        </div>
      </div>
    )
  }

  function ChatBottomCard({ response }) {
    return (
      response && !loading && <div className={"chat-bottom-card-container"} data-aos={response ? 'fade-out' : ''}>
        <div className={"chat-top-card-container-left"}>
          <img src={LogoCoally} alt="avatar" className={"chat-top-card-container-left-img"} />
        </div>
        <div className={"chat-top-card-container-right-ia"}>
          <Typewriter
            options={{
              delay: 5,
            }}
            onInit={(typewriter) => {
              typewriter.typeString(response?.value_roles?.value_roles?.text)
                .pauseFor(250)
                .typeString(response?.value_topics?.value_topics?.text)
                .pauseFor(250)
                .typeString(response?.value_time?.value_time?.text)
                .pauseFor(250)
                .typeString(response?.value_companies?.value_companies?.text)
                .stop()
                .start()
            }}></Typewriter>
        </div>
      </div>
    )
  }

  function FormationMessage() {
    const handleClick = () => {
      history.push("/consultor/formacion/recomendacion");
    };
    return (
      <>
        <p>
          {lang === 'es'
            ? `Espere mientras se carga la información sobre recomendaciones de capacitación para lograr el rol que está buscando. `
            : `Please wait while information on training recommendations to achieve the role you are looking for is loaded. `}
          <span className="formation-link" onClick={handleClick}>
            {lang === 'es' ? `Haz clic aquí` : `Click here`}
          </span>
        </p>
      </>
    );
  }





  function ChatBottomCardLoading() {
    return (
      <div className={"chat-bottom-card-container"} data-aos={loading ? 'fade-out' : ''}>
        <div className={"chat-top-card-container-left"}>
          <img src={LogoCoally} alt="avatar" className={"chat-top-card-container-left-img"} />
        </div>
        <div className={"chat-top-card-container-right"}>
          <p>{lang === 'es' ? 'Esperando respuesta. Esto puede demorar un tiempo' : 'Waiting answer. This may take a while'}</p>
          <Spinner animation="border" size={"sm"} />
        </div>
      </div>
    )
  }
  const regex = /\s/g;
  const resultado = request?.role?.replace(regex, '_');

  const sendMessage = () => {
    /*if(requestsToApi >= 5){
        return toast.error(lang === "es" ? "Has llegado al límite de consultas diarias." : "You have reached the daily limit of queries.", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        })
    }*/
    if (!request.role || !request.empresa) {
      return toast.error(lang === "es" ? "Por favor, completa los espacios en blanco." : "Please, complete the blanks.", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
    }
    lang === "es" ? setMessage(`En que roles debo trabajar para llegar a ser ${request.role} en la empresa ${request.empresa}?`) : setMessage(`In what roles should I work to become a ${request.role} in the company ${request.empresa}?`)
    setLoading(true)
    setFormationLoading(true)
    dispatch(clearFormationModel())
    axios.post('https://rutaaprendizaje-production.up.railway.app/save_chatgpt_query', {
      id_user: user.id,
      role: resultado,
      answer: ''
    })
      .then((res) => {
        console.log(res);        
        setTimeout(() => {
          dispatch(getFormationModel(user.id))
        }, 60000)
      })
      .catch((err) => {
        console.log(err);
      })

    const makeRequests = () => {
      axios.all([
        axios.post("https://coally-backend.com/api/roles/recomedation/gpt", request),
        axios.post("https://coally-backend.com/api/topics/recomedation/gpt", request),
        axios.post("https://coally-backend.com/api/time/recomedation/gpt", request),
        axios.post("https://coally-backend.com/api/companies/recomedation/gpt", request)
      ]).then(axios.spread((response1, response2, response3, response4) => {
        setResponse({
          "status": "success",
          "value_roles": response1.data,
          "value_topics": response2.data,
          "value_time": response3.data,
          "value_companies": response4.data
        })
        dispatch(getFormationModel(user.id))
        axios.post('https://rutaaprendizaje-production.up.railway.app/save_chatgpt_query', {
          id_user: user.id,
          role: resultado,
          answer: response1?.data?.value_roles?.text 
          + response2?.data?.value_topics?.text 
          + response3?.data?.value_time?.text + 
          response4?.data?.value_companies?.text
        })
          .then((res) => {
            console.log(res);
          })
          .catch((err) => {
            console.log(err);
          })
        setLoading(false)
        localStorage.setItem(validationKey, JSON.stringify({
          value: requestsToApi + 1,
          timestamp: Date.now() + expiration
        }))
      })).catch(error => {
        console.log(error)
        makeRequests(); // Retry the requests
      });
    };
    makeRequests();

    /*setTimeout(() => {
        setResponse({
            "status": "success",
            "value_roles":{
            "value_roles": {
                "text": "\n\nPara llegar a ser CTO en Google, es necesario tener una amplia experiencia en tecnología y liderazgo. Esto significa que deberás trabajar en una variedad de roles relacionados con la tecnología, como desarrollador de ",
                "index": 0,
                "logprobs": null,
                "finish_reason": "stop"
            }
            },
            "value_topics": {
            "value_topics": {
                "text": "\n\nRespuesta: \n\nPara llegar a ser CTO, deberás estudiar una variedad de temas relacionados con la tecnología y la administración de empresas. ",
                "index": 0,
                "logprobs": null,
                "finish_reason": "length"
            }},
            "value_time": {
            "value_time": {
                "text": "\n\nEl tiempo de experiencia necesario para llegar a ser CTO depende de la empresa y del puesto. En algunos casos, una persona puede llegar a ser CTO con sólo un año de experiencia, mientras que otros requieren al menos cinco años de experiencia en el área de tecnología. Además, algunas empresas exigen un título de posgrado en tecnología o una combinación de experiencia y educación para llegar a ser CTO.",
                "index": 0,
                "logprobs": null,
                "finish_reason": "stop"
            }},
            "value_companies": {
            "value_companies": {
                "text": "\n\n1. Airbnb\n2. Uber\n3. Dropbox\n4. Microsoft\n5. Amazon",
                "index": 0,
                "logprobs": null,
                "finish_reason": "stop"
            }}
        })
        localStorage.setItem(validationKey, JSON.stringify({
            value: requestsToApi + 1,
            timestamp: Date.now() + expiration
        }))
        setLoading(false)
    }, 3500)*/
  }

  return (
    <div className={"contenedor-oportunidad"}>
      {user && user.roles === "coallier" ? <div>
        <Sidebar />
        <div
          className="BodyWeb"
          style={{
            padding: "0rem 2rem",
            position: "absolute",
            right: "0",
          }}>
          <Navbar />
          <div className="Profesional-Home-Contenedor-Global">
            <div className={"profesional-career-ia-container-row-chat"}>
              <div className={"profesional-career-ia-container-row-izq-logo"}>
                <div className={"profesional-career-ia-container-row-izq-logo-container"}>
                  <img src={LogoCoallyOscuro} alt="logo coally izquierda" className={"logo-coally-izq-chat-ia"} />
                </div>
              </div>
              <div className={"profesional-career-ia-container-career-chat"}>
                <div className={"chat-ia-container-space-beetween"}>
                  <div className={"profesional-career-ia-chat-top"}>
                    <ChatTopCard message={message} />
                    {response && !loading ? (
                      <>
                        <ChatBottomCard response={response} />
                      </>
                    ) : !response && loading ? (
                      <ChatBottomCardLoading />

                    ) : null}
                    {formationLoading ?
                      formationModel?.length > 0 ?
                        <FormationMessage />
                        :
                        <p>
                          {lang === 'es'
                            ? `Espere mientras se carga la información sobre recomendaciones de capacitación para lograr el rol que está buscando. `
                            : `Please wait while information on training recommendations to achieve the role you are looking for is loaded. `}
                        </p>
                      : null}
                  </div>
                  <div className={"profesional-career-ia-chat-bottom-input-background-blurred"} style={
                    response && !loading ? { display: "none" } : {}
                  } data-aos={response && !loading ? "fade-out" : ''}>
                    <div className={"profesional-career-ia-chat-bottom-input-container"}>
                      <h2 style={{
                        color: "#c9c9c9",
                        fontSize: "18px",
                        fontWeight: "400",
                        padding: "1rem",
                      }}>{
                          lang === "es" ? "Completa los espacios en blanco para obtener una respuesta." : "Complete the blanks to get an answer."
                        }</h2>
                      <div className={"profesional-career-ia-chat-input"}>
                        <p style={{
                          color: "#fff",
                          fontSize: "18px",
                          fontWeight: "400",
                        }}>{
                            lang === "es" ? "En que roles debo trabajar para llegar a ser " : "In what roles should I work to become a "
                          } <input type="text" className="blank" id="role" autoComplete="off" onChange={(e) => {
                            setRequest({
                              ...request,
                              [e.target.id]: e.target.value
                            })
                          }} /> {
                            lang === "es" ? "en la empresa " : "in the company "
                          } <input type="text" className="blank" id="empresa" autoComplete="off" onChange={(e) => {
                            setRequest({
                              ...request,
                              [e.target.id]: e.target.value
                            })
                          }} /> ?</p>
                        <div className={"input-chat-ia-send-button"} onClick={!loading ? sendMessage : null}>
                          <SendIcon className={"send-button-chat-ia"} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={"profesional-career-ia-container-row-der-logo"}>
                <div className={"profesional-career-ia-container-row-izq-logo-container"}>
                  <img src={OpenAiLogo} alt="logo coally izquierda" className={"logo-coally-izq-chat-ia"} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> : redirect()}
    </div>
  )
}

export default ProfesionalCareerIA;
