/* eslint-disable */
import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import './newRegister.css';
import { useHistory, Link } from "react-router-dom";
import Select from "react-select";
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import Spinner from 'react-bootstrap/Spinner'
import { changeLang, registerUser } from "../../../actions";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import Wave from "../../../assets/imagenes/Vector385.svg";
import Wave2 from "../../../assets/imagenes/Vector 384.svg";
import Creativity from "../../../assets/imagenes/CREATIVIDAD.svg";
import ImgCodigo from "../../../assets/imagenes/CODIGO.svg";
import Edit from "../../../assets/imagenes/edit.svg";
import TermsAndConditions from "../../../components/termsAndConditions/TermsAndConditions";
import Logo from "../../../assets/imagenes/nuevo_logo.svg";
import axios from "axios";
import Cropper from 'react-easy-crop'
import getCroppedImg from "./cropImage";
import {resizeImage} from "./resizeImage";
import EditIcon from '@mui/icons-material/Edit';

const customStyles = {
  option: (provided, state) => ({
    ...provided,
    color: state.isSelected ? 'white' : 'black',
    backgroundColor: state.isSelected ? '#202256' : 'white',
    cursor: 'pointer',
    padding: 10,
  }),
  control: (provided, state) => ({
    ...provided,
    width: '201px',
    display: 'flex',
    alignItems: 'flex-start',
    padding: '0',
    justifyContent: 'center',
    borderRadius: '8px',
    backgroundColor: state.isSelected ? '#202256' : '#F5F5F5',
    color: 'white'
  }),
  menu: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? '#202256' : 'white',
    color: 'white',
    padding: '0',
    marginTop: '-1em',
    width: '201px',
  }),

  //change the color of the select when selected

}
export default function NewRegister() {
  const [user, setUser] = useState({
    roles: [],
    username: "",
    email: "",
    password: "",
    phoneNumber: "",
    num_id: "",
  });
  const history = useHistory();
  const userV = useSelector((state) => state.userToVerify);
  const dispatch = useDispatch();
  const [locallang, setLocallang] = useState(localStorage.getItem("langlocal"));
  const [cargando, setcargando] = useState(false)
  const [passwordShown, setPasswordShown] = useState(false);
  const [checked, setChecked] = useState(false);
  const [popUp, setPopUp] = useState(false);
  const [error, setError] = useState({});
  const [crop, setCrop] = useState({ x: 0, y: 0})
  const [zoom, setZoom] = useState(1)
  const [img, setImg] = useState(null);
  const [imgPrev, setImgPrev] = useState(null);
  const [croppedImage, setCroppedImage] = useState(null)
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)
  const [openPopUpCropper, setOpenPopUpCropper] = useState(false)



  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels)
  }, [])
  const showCroppedImage = useCallback(async (croppedArea, croppedAreaPixel) => {
    try {
      const croppedImage = await getCroppedImg(
        imgPrev,
        croppedAreaPixels,
        0
      )
      console.log('done', { croppedImage })
      setCroppedImage(croppedImage)
      setOpenPopUpCropper(false)
    } catch (e) {
      console.error(e)
    }
  }, [croppedAreaPixels])
  const sendAvatar = async () => {
    console.log(croppedImage)
    const response = await fetch(croppedImage);
    const blob = await response.blob();
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onload = async () => {
      const dataUrl = reader.result;
      const image = new Image();
      image.src = dataUrl;
      image.onload = async () => {
        const resizedBlob = await resizeImage(image, 200, 200);
        const file = new File([resizedBlob], 'avatar.png', { type: 'image/png' })
        const formData = new FormData();
        formData.append("upload_avatar", file);
        console.log('formdata', formData);
        axios.post(`https://findally-backend.herokuapp.com/api/user/uploadAvatar/${userV.id}`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }).then(res => {
          // console.log('posteo de imagen', res)
          history.push('/auth/verify');
        }).catch(err => {
          console.log(err);
          toast.error('error cargando el avatar', {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        });
      };
    };
  };

  useEffect(() => {
    if (userV.email && croppedImage) {
     return void sendAvatar()
    } else if (userV.email) {
      history.push('/auth/verify')
    }
  }, [userV.email, history])

  const handleCheck = (event) => {
    setChecked(event.target.checked);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setcargando(true)
    if (error.password) {
      setcargando(false)
      return toast.error(error.password)
    }
    if (!user.username || !user.email || !user.password || !user.phoneNumber || !user.roles || (user.roles === 'company' && img === null)) {
      setcargando(false)
      return locallang === 'en' ? toast.error('All fields are required, including your logo') : toast.error('Todos los campos son obligatorios, inclusive tu logo')
    }
    if(user.roles === 'coallier' && user.num_id === ''){
        setcargando(false)
        return locallang === 'en' ? toast.error('Please enter your identity number') : toast.error('Por favor ingrese su número de identidad')
    }
    dispatch(registerUser(user, locallang))
      .then((res) => {
        console.log(res)
        setcargando(false)
      })
      .catch((err) => {
        console.log(err);
        setcargando(false)
      });
  }

  const validatePassword = (password) => {
    if (password.length < 6) {
      setError({
        ...error,
        password: locallang === 'en' ? "Password must be at least 6 characters long" : "La contraseña debe tener al menos 6 caracteres",
      });
      return false;
    }
    setError({
      ...error,
      password: "",
    });
  }


  const togglePassword = () => {
    setPasswordShown(!passwordShown);
  }
  const roles = [
    { value: "coallier", label: <span>{locallang === "es" ? "Profesional" : "Professional"}</span> },
    { value: "company", label: <span>{locallang === "es" ? "Empresa" : "Company"}</span> },
  ]

  const handlePassword = (e) => {
    validatePassword(e.target.value);
    setUser({
      ...user,
      password: e.target.value,
    })
  }

  const handleChange = (selectedOption, { name }) => {
    if (selectedOption) {
      setUser({
        ...user,
        [name]: selectedOption.value
      });
    }
    console.log(user)
  };

  const cambiarlanges = (camlang) => {
    dispatch(changeLang(camlang))
    localStorage.setItem("langlocal", camlang);
    setLocallang(localStorage.getItem("langlocal"));
    if (error.password) {
      camlang === 'en' ? setError({
        ...error,
        password: "Password must be at least 6 characters long"
      }) : setError({ ...error, password: "La contraseña debe tener al menos 6 caracteres" })
    }
  };
  const [width, setWidth] = useState(window.innerWidth);
  const [height, setHeight] = useState(window.innerHeight);


  const updateWidthAndHeight = () => {
    setWidth(window.innerWidth);
    setHeight(window.innerHeight);

  }
  useEffect(() => {
    window.addEventListener("resize", updateWidthAndHeight);
    return () => window.removeEventListener("resize", updateWidthAndHeight);
  }, [width, height]);

  const handleImageChange = (e) => {
    e.preventDefault
    const file = e.target.files[0];
    const reader = new FileReader();
    if (file.size > 4000000) {
      locallang === 'es' ?
      toast.error('El tamaño de la imagen no puede superar los 4MB', {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      }) : toast.error('The size of the image cannot exceed 4MB', {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        })
      return;
    }
    // Load file as data URL
    reader.readAsDataURL(file);
    // Create a new image object to get the image dimensions
    const image = new Image();
    image.src = URL.createObjectURL(file);
    image.onload = () => {
      // Set the selected image and preview image
      setImg(file);
      setImgPrev(image.src)
      setOpenPopUpCropper(true)
    }
    // Limit image format to JPEG, PNG, or GIF
    if (file.type !== 'image/jpeg' && file.type !== 'image/png' && file.type !== 'image/gif') {
      return locallang === 'es' ?
      toast.error('El formato de la imagen debe ser JPG, PNG o GIF', {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      }) : toast.error('The image format must be JPG, PNG or GIF', {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        })
    }
  }


  return (
    <div style={{
      backgroundColor: '#F5F5F5',
      display: 'flex',
      justifyContent: 'space-evenly',
      alignItems: 'center',
      width: '100%',
      height: '100vh',
      flexDirection: 'column'
    }}>
      <div style={{
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        height: '80%'
      }}>
        {
          width > 768 ? <div style={{
            width: '25%',
            height: '80%', display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-start'
          }}>
            <img src={Creativity} alt="creativity" style={{ width: '235px', height: '235px' }} />
          </div> : null
        }

        <div style={{
          display: 'flex', justifyContent: 'center', alignItems: 'center', width: width > 768 ? '33%' : '100%',
          marginTop: "10em"
        }}>
          {/* <ToastContainer/> */}
          {popUp && <Dialog
            style={{}}
            open={popUp}
            onClose={() => setPopUp(false)}
            // scroll={scroll}
            aria-labelledby="scroll-dialog-title"
            aria-describedby="scroll-dialog-description"
          >
            <DialogTitle id="scroll-dialog-title">{
              locallang === 'es' ? 'Acepto los terminos y condiciones' : 'I accept the terms and conditions'
            } </DialogTitle>
            <DialogContent>
              <TermsAndConditions />
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setPopUp(false)}>
                {locallang === 'es' ? 'Cerrar' : 'Close'}
              </Button>
            </DialogActions>
          </Dialog>}
          <div className="container-register"
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '442px',
              width: '365px',
              borderRadius: '8px',
              zIndex: '2',
            }}>
            <div style={{
              display: 'flex',
              // alignItems: 'center',
              justifyContent: 'center',
              width: '100%',
              height: '100%',
              flexDirection: 'column',
            }}>
              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
                <img src={Logo} alt="logo" style={{ width: '234px', height: '84px', marginBottom: '5%' }} />
              </div>
              <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-start', width: '100%' }}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "flex-end",
                    justifyContent: 'flex-end',
                    color: "#C5C5C5",
                    width: '100%',
                    paddingRight: '5px',

                  }}
                >
                  <h3
                    style={
                      localStorage.getItem("langlocal") === "es"
                        ? {
                          margin: "0.3rem",
                          fontSize: "18px",
                          fontFamily: "Poppins",
                          cursor: "pointer",
                          color: "#202256",
                          fontWeight: "700",
                          userSelect: "none",
                        }
                        : { margin: "0.3rem", cursor: "pointer", fontWeight: '300', fontSize: '18px' }
                    }
                    onClick={() => cambiarlanges("es")}
                  >
                    SP
                  </h3>
                  <h3
                    style={
                      localStorage.getItem("langlocal") === "en"
                        ? {
                          margin: "0.3rem",
                          fontSize: "18px",
                          fontFamily: "Poppins",
                          cursor: "pointer",
                          color: "#202256",
                          fontWeight: "700",
                          userSelect: "none",
                        }
                        : { margin: "0.3rem", cursor: "pointer", fontWeight: '300', fontSize: '18px' }
                    }
                    onClick={() => cambiarlanges("en")}
                  >
                    EN
                  </h3>
                </div>
              </div>
              <div style={{ width: '100%' }}>
                <h1 style={{ fontFamily: 'Poppins', fontSize: '24px', fontWeight: '700', color: '#393939' }}>{
                  locallang === 'es' ? "Regístrate" : "Sign up"
                }</h1>
              </div>
              <div style={{ width: '100%', alignItems: 'flex-start' }}>
                <p style={{ color: '#5D5D5D', fontWeight: '400', fontSize: '14px', marginTop: "1em" }}>
                  {
                    locallang === 'es' ? 'Tipo de usuario' : 'Type of user'
                  }
                </p>
              </div>
              <div style={{ width: '100%', padding: '0', marginTop: "-1.2em" }}>
                <Select
                  className="ConsultorFor-input2 mediumfont"
                  classNamePrefix="select"
                  defaultValue={""}
                  isDisabled={false}
                  styles={customStyles}
                  isLoading={false}
                  isSearchable={true}
                  name="roles"
                  options={roles}
                  onChange={handleChange}
                />
              </div>
              {
                user.roles === 'company'
                  ? <div  style={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginBottom: '16px'
                  }}>
                    <Dialog
                      open={openPopUpCropper}
                    >
                      <DialogTitle id="scroll-dialog-title">{
                        locallang === 'es' ? 'Selecciona y recorta tu logo' : 'Select and crop your logo'
                      } </DialogTitle>
                      <DialogContent>
                        <div className={'cropContainer'} style={{
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'center',
                          alignItems: 'center',
                          marginBottom: '16px'
                        }}>
                          <Cropper
                            image={imgPrev}
                            crop={crop}
                            zoom={zoom}
                            onCropChange={setCrop}
                            onCropComplete={onCropComplete}
                            onZoomChange={setZoom}
                            aspect={1 / 1}
                          />
                        </div>
                      </DialogContent>
                      <DialogActions>
                        <Button onClick={showCroppedImage}>
                          {locallang === 'es' ? 'Guardar' : 'Save'}
                        </Button>
                      </DialogActions>
                    </Dialog>
                    {
                      croppedImage ? <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', marginBottom: '20px'}}>
                        <img src={croppedImage} alt="cropped image" style={{
                          maxWidth: '100px',
                          maxHeight: '100px',
                        }}/>
                        <EditIcon onClick={()=>setOpenPopUpCropper(true)} style={{
                          color: '#5D5D5D',
                          cursor: 'pointer',
                          marginLeft: '10px'
                        }}/>
                      </div> : null
                    }
                    <label style={{
                      width: '100%',
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      alignItems: 'center',
                      cursor: 'pointer',
                      backgroundColor: '#F07130',
                      borderRadius: '5px',
                      fontSize: '14px',
                      color: '#fff',
                      height: '40px',
                    }}>
                      {locallang === 'es' ? 'Cargar Logo de la empresa' : 'Upload company logo'}
                      <input style={{ display: 'none' }} type="file" name="file" onChange={(e) => handleImageChange(e)} />
                    </label>
                    {img ? null : <ul>
                      <li style={{color: '#5D5D5D', fontWeight: '400', fontSize: '14px', marginTop: "1em"}}>
                        {
                          locallang === 'es' ? 'La imagen debe ser en formato PNG, JPG o GIF' : 'The image must be in PNG, JPG or GIF format'
                        }
                      </li>
                      <li style={{color: '#5D5D5D', fontWeight: '400', fontSize: '14px', marginTop: "1em"}}>
                        {
                          locallang === 'es' ? 'La imagen debe pesar menos de 4MB' : 'The image must weigh less than 4MB'
                        }
                      </li>
                    </ul>}
                  </div>
                  : null
              }
              <div style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%',
                height: '41px',
                marginBottom: '1em'
              }}>

                <input type='text' style={{
                  background: '#F5F5F5',
                  fontstyle: 'normal',
                  fontWeight: '400',
                  width: '100%',
                  height: '41px',
                  paddingLeft: '22px',
                  fontSize: '16px',
                  border: '1px solid #C5C5C5',
                }} placeholder={
                  user.roles === 'company' ? locallang === 'es' ? 'Nombre de empresa' : 'Company Name' : locallang === 'es' ? 'Nombre' : 'Name'
                } onChange={
                  (e) => {
                    setUser({
                      ...user,
                      username: e.target.value
                    })
                  }
                } />
                <div style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  alignItems: 'flex-end',
                  width: '0%'
                }}>
                  <img src={Edit} alt="editar" />
                </div>
              </div>
              <div style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%',
                height: '41px',
                marginBottom: '1em'
              }}>

                <input type='text' style={{
                  background: '#F5F5F5',
                  fontstyle: 'normal',
                  fontWeight: '400',
                  width: '100%',
                  height: '41px',
                  paddingLeft: '22px',
                  fontSize: '16px',
                  border: '1px solid #C5C5C5',
                }} placeholder={
                  locallang === 'es' ? 'Correo electrónico' : 'Email'
                } onChange={
                  (e) => {
                    setUser({
                      ...user,
                      email: e.target.value
                    })
                  }
                } />
                <div style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  alignItems: 'flex-end',
                  width: '0%'
                }}>
                  <img src={Edit} alt="editar" />
                </div>
              </div>

              <div style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%',
                height: '41px',
                marginBottom: '1em'
              }}>

                <PhoneInput
                  country={'us'}
                  regions={'america'}
                  enableLongNumbers={true}
                  countryCodeEditable={false}
                  placeholder={locallang === "es" ? 'Teléfono' : 'Phone number'}
                  inputStyle={{
                    border: '1px solid #C5C5C5',
                    borderRadius: '0px',
                    width: '100%',
                    background: '#F5F5F5',
                    fontstyle: 'normal',
                    fontWeight: '400',
                    height: '41px',
                    fontSize: '16px',
                  }}
                  onChange={
                    (phone, country, event, formattedValue) => {
                      setUser({
                        ...user,
                        phoneNumber: formattedValue,
                      })
                    }
                  }
                />
              </div>

               {user.roles === 'coallier' ? <div style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%',
                height: '41px',
                marginBottom: '1em'
              }}>
                <input
                    type='text'
                    style={{
                      background: '#F5F5F5',
                      fontstyle: 'normal',
                      fontWeight: '400',
                      width: '100%',
                      height: '41px',
                      paddingLeft: '22px',
                      fontSize: '16px',
                      border: '1px solid #C5C5C5',
                    }}
                    placeholder={
                      locallang === 'es' ? 'Número de identidad' : 'Identity number'
                    }
                    onChange={(e) => {
                      setUser({...user, num_id: e.target.value})
                    }}
                    onKeyPress={(e) => {
                      if (!/[0-9]/.test(e.key)) {
                        e.preventDefault();
                      }
                    }}/>
              </div> : null}

              <div style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%',
                height: '41px',
                marginBottom: '1em',
                border: error.password ? '1px solid red' : 'none'

              }}>

                <input type={passwordShown ? "text" : "password"} placeholder={
                  locallang === 'es' ? 'Contraseña' : 'Password'
                } onChange={
                  (e) => handlePassword(e)
                } style={{
                  background: '#F5F5F5',
                  fontstyle: 'normal',
                  fontWeight: '400',
                  width: '100%',
                  height: '41px',
                  paddingLeft: '22px',
                  fontSize: '16px',
                  border: '1px solid #C5C5C5',
                }} />
                <div style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  alignItems: 'flex-end',
                  width: '0%'
                }}>
                  <button style={{ background: 'transparent', borderRadius: '0px 1.5rem 1.5rem 0px' }}
                    onClick={() => togglePassword()}>{passwordShown ? <VisibilityOffIcon /> :
                      <VisibilityIcon />}</button>
                </div>
              </div>
              {error && error.password &&
                <p style={{ color: 'red', fontSize: '12px', marginTop: '2%' }}>{error.password}</p>}


              <div className="terms-condition" style={{ width: '100%', display: 'flex', marginTop: '5%' }}>
                <label className="b-contain">
                  <a onClick={() => setPopUp(true)} className="buttoooon">{
                    locallang === 'es' ? 'Acepto términos y condiciones' : 'I accept terms and conditions'}
                  </a>

                  <input name="radio" type="radio" checked={checked} style={{ height: "1px", width: "1px" }}
                    onChange={(e) => {
                      handleCheck(e)
                    }} onClick={() => setChecked(!checked)} />
                  <div className="b-input"></div>
                </label>
              </div>
              <div style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column',
                marginTop: '4%',
                width: "100%"
              }}>
                {
                  user.email.includes('@') && user.email.includes('.') && user.password && user.username.length > 0 && user.phoneNumber.length > 0 && checked ? (
                    <div style={{ width: "100%" }}>
                      <button className='register-button-new' onClick={(e) => handleSubmit(e)}>
                        {cargando ? (
                          <Spinner animation="border" size="sm" />
                        ) :
                          <span>
                            {
                              locallang === 'es' ? 'Regístrate' : 'Sign up'
                            }
                          </span>}
                      </button>
                    </div>
                  ) : (
                    <div style={{ width: "100%" }}>
                      <button className='register-button-new' disabled>
                        {
                          locallang === 'es' ? 'Regístrate' : 'Sign up'
                        }
                      </button>
                    </div>
                  )
                }
              </div>
              <hr style={{
                width: '80%',
                height: '1px',
                backgroundColor: '#D6D6D6',
                border: 'none',
                marginTop: '4%'
              }} />

              <div style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'row',
                marginTop: '2%',
                width: '100%'
              }}>
                <div>
                  <p style={{
                    color: '#5D5D5D',
                    fontWeight: '400',
                    fontSize: '14px',
                    textAlign: 'center',
                  }}>
                    {
                      locallang === 'es' ? 'Si ya tienes cuenta,' : 'If you already have an account,'
                    }
                  </p>
                </div>
                <div style={locallang === 'es' ? { marginLeft: '0%' } : { width: '10%', marginLeft: '0%' }}>
                  <div>
                    <Link to='/' style={{ textDecoration: 'none' }}>
                      <p className="register-register-new">
                        {
                          locallang === 'es' ? 'Inicia sesión' : 'Login'
                        }
                      </p>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {
          width > 768 ? <div style={{
            width: '25%',
            height: '85%', display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-end', zIndex: '1'
          }}>
            <img src={ImgCodigo} alt="logo" style={{ width: '235px', height: '235px' }} />
          </div> : null
        }
      </div>
      <div style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        alignItems: 'flex-end',
        width: '100%',
        height: '20%',
        backgroundColor: '#F5F5F5'
      }}>
        <div style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%',
        }}>
          <img src={Wave2} alt="wave 2" style={{
            width: '100%',
            height: 'auto',
          }} />
          <img src={Wave} alt="wave" style={{
            width: '100%',
            height: 'auto',
            position: 'absolute',
            bottom: '0'
          }} />
        </div>
      </div>
    </div>
  );
}
