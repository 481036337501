import React from 'react'
import axios from 'axios'

export default class ButtonP extends React.Component {
  constructor(props) {
    super(props)
    this.type = props.type;
    this.user = props.user;
    this.state = { status: "" };
    this.price = props.price
  }
 
  async componentDidMount() {
    const response = await axios.get("https://findally-backend.herokuapp.com/epayco/api/something")
    const data = await response.data
    this.setState({ status: data.status })


    //const user = useSelector(state => state.user)
    const s = document.createElement('script');
    s.src = "https://checkout.epayco.co/checkout.js"
    //s.classList.add("epayco-button")
    s.setAttribute('class', "epayco-button")
    s.setAttribute('data-epayco-key', "c8bed202de8acb75758ec2f1a43cd469")
    s.setAttribute('data-epayco-amount', this.price)
    s.setAttribute('data-epayco-name', this.type?.nombre_paquete)
    s.setAttribute('data-epayco-description', `${this.type?.nombre_paquete} coally`)
    s.setAttribute('data-epayco-currency', "cop")
    s.setAttribute('data-epayco-country', "co")
    s.setAttribute('data-epayco-test', "false")
    s.setAttribute('data-epayco-external', "true") //
    s.setAttribute('data-epayco-confirmation', `https://findally-backend.herokuapp.com/epayco/confirmation?userId=${this.user}&planId=${this.type?._id}`)
    s.setAttribute('data-epayco-acepted', "https://coally-platform.com/company/home")
    s.setAttribute('data-epayco-response', "https://coally-platform.com/company/home")
    s.setAttribute('data-epayco-rejected', "https://coally-platform.com/company/home")
    s.setAttribute('data-epayco-pending', "https://coally-platform.com/company/home")
    this.instance.appendChild(s)
  }

  render () {
    return (
      <>
        <div ref={el => (this.instance = el)} />
      </>
    )
  }
}