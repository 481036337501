/*eslint-disable*/
import React, {createContext, useEffect, useState} from 'react';
import axios from 'axios';
// Crea el contexto

const University = {
    name: '',
    address: '',
    city: '',
    country: '',
    color: {
        primary: '',
        secondary: '',
        tertiary: '',
        quaternary: ''
    },
    logo: '',
    route: ''
}
export const UniversityContext = createContext({
    university: University,
});

// Crea un componente de contexto que proporciona la información de la universidad

const UniversityContextProvider = ({ children, match }) => {
    const [university, setUniversity] = useState({});
    //TODO: match.params deberiamos pasarle a la futura ruta para llamar a la universidad
    console.log(match.params);
    useEffect(() => {
        const storedUniversity = localStorage.getItem('university');
        if (storedUniversity && storedUniversity.color) {
           return setUniversity(JSON.parse(storedUniversity));
        } else {
            /*setTimeout(() => {
                setUniversity({
                    name: 'Universidad de los Andes',
                    address: 'Av. Reina Mercedes, s/n',
                    city: 'Bogotá',
                    country: 'Colombia',
                    color: {
                        primary: '#fff200',
                        secondary: '#181818',
                        tertiary: '#fff'
                    },
                    logo: /!*'https://uniandes.edu.co/sites/default/files/logo-uniandes.png'*!/'https://uniandes.edu.co/sites/all/themes/donaciones/img/logo-andes.svg',
                    route: 'uniandes'
                })
                localStorage.setItem('university', JSON.stringify(university));
            }, 1000);*/
            const {universitie} = match.params;
            axios.get(`https://findally-backend.herokuapp.com/api/get/university/${universitie}`)
                .then(res => {
                    setUniversity(res.data);
                    localStorage.setItem('university', JSON.stringify(res.data));
                }).catch(err => {
                console.log(err);
                window.location.href = '/';
            });
        }
    }, []);

    return (
        <UniversityContext.Provider value={{ university }}>
            {children}
        </UniversityContext.Provider>
    );
};

export default UniversityContextProvider;
