import React, { useState } from 'react'
import '../Styles/CardLegalInfo.css'
import DriveFileRenameOutlineOutlinedIcon from "@mui/icons-material/DriveFileRenameOutlineOutlined";
import PopUpLegal from '../PopUpLegal';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import { useSelector } from 'react-redux';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '574px',
  height: '95vh',
  bgcolor: '#FFFFFF',
  borderRadius: '8px',
  overflow: 'auto',
};

const CardLegalInfo = ({ dataUser, lang, userInfo }) => {

  const [open, setOpen] = useState(false);
  const { user } = useSelector((state) => state)
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);


  return (
    <div className='container-cardlegal'>
      <div style={{
        display: 'flex',
        justifyContent: 'flex-end',
        width: '100%'
      }}>
        <div style={{
          position: 'absolute',
        }}>
          {user?.id === userInfo?.id &&
            <button
              className='button-edit-perfil-company'
              onClick={handleOpen}
            >
              <DriveFileRenameOutlineOutlinedIcon />
            </button>
          }
        </div>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <PopUpLegal user={userInfo?.id} handleClose2={handleClose} dataUser={dataUser} />
          </Box>
        </Modal>
      </div>
      <div className='subcontainer-cardlegal'>
        <h3 className='card-legal-title'>
          {lang === 'es' ? 'Persona jurídica' : 'Legal person'}
        </h3>
        <h6>Nit: <span> {dataUser?.nit ? dataUser.nit : 'N/A'}</span></h6>

        <h6>{lang === 'es' ? 'Domicilio: ' : 'Address: '}<span>{dataUser?.direccion ? dataUser?.direccion : 'N/A'}</span></h6>

        <h6>{lang === 'es' ? 'Dirección de notificación: ' : 'Notification address: '}<span>{dataUser?.direccion_notificacion ? dataUser?.direccion_notificacion : 'N/A'}</span></h6>

        <h6>{lang === 'es' ? 'Teléfono de contacto : ' : 'Contact phone : '}<span>{dataUser?.telefono[0]?.telefono1 ? dataUser?.telefono[0]?.telefono1 : 'N/A'}</span></h6>

        {/*<span>{lang === 'es' ? 'Teléfono de contacto 2: ' : 'Contact phone 2: '}</span>*/}
        <hr />
        <h3 className='card-legal-title'>
          {lang === 'es' ? 'Representante legal' : 'Legal representative'}
        </h3>
        <h6>{lang === 'es' ? 'Nombres y apellidos: ' : 'Name and last name: '}<span>{dataUser?.rep_legal ? dataUser?.rep_legal : 'N/A'}</span></h6>

        <h6>{lang === 'es' ? 'Teléfono de contacto : ' : 'Contact phone : '}<span>{dataUser?.telefono[0]?.telefono2 ? dataUser?.telefono[0]?.telefono2 : 'N/A'}</span></h6>

        {/*<span>{lang === 'es' ? 'Teléfono de contacto 2: ' : 'Contact phone 2: '}</span>*/}
        <h6>{lang === 'es' ? 'Tipo de Identification: ' : 'Id type: '}<span> {dataUser?.tipo_ident_rep ? dataUser?.tipo_ident_rep : 'N/A'}</span></h6>

        <h6>{lang === 'es' ? 'Número de identificación: ' : 'Id number: '}<span>{dataUser?.identificacion_rep ? dataUser?.identificacion_rep : 'N/A'}</span> </h6>

      </div>
    </div>
  )
}

export default CardLegalInfo
