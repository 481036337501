import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import Popup from "../../components/popUp/Modal.js"
import Header from "../../components/Header/Header";
import { getPostedProjects, getAllUsers, match ,Legal} from "../../actions";
import "./Styles/Match.css";
import en from "../../lang/en-US";
import es from "../../lang/es-COL"
import counterpart from "counterpart";
import Translate from "react-translate-component";

function Match(props) {
  const [form, setValues] = useState({
    id_proyecto: "",
    id_usuarios: [],
  });

  
  const [buttonPopup, setButtonPopup] = useState(false);

  const [legalForm, setLegasVaues] = useState({
    id_proyecto: "",
    Coallier_form: "",
    Company_form: "",
    fecha_inicio: "",
    fecha_fin: "",
    precio: "",
  });

  const handleFormLegalSubmit = () => {
   
   props.Legal(legalForm)
  };

  const handleLegalFormChange = (e) => {
    setLegasVaues({
      ...legalForm,
      [e.target.name]: e.target.value,
    });
  };
  const handleLegalFormChangePlaceholder = (e) => {
    setLegasVaues({
      ...legalForm,
      [e.target.name]: e.target.placeholder,
    });
  };
  const handleClick = (e) => {
    e.preventDefault();
    setValues({
      ...form,
      [e.target.name]: [...form[e.target.name], e.target.placeholder],
    });
    setLegasVaues({
      ...legalForm,
      [e.target.name]: e.target.placeholder,
    });
  };

  const handleProyectClick = (e) => {
    //  e.preventDefault()
    setValues({
      ...form,
      [e.target.name]: e.target.placeholder,
    });
    setLegasVaues({
      ...legalForm,
      [e.target.name]: e.target.placeholder,
    });
  };

  const handlleMatch = () => {
    props.match(form);
  };

  useEffect(() => {
    // eslint-disable-next-line
    const getproyects = () => {
      props.getPostedProjects();
      props.getAllUsers();
    };
    getproyects(); // eslint-disable-next-line
  }, []);

  counterpart.registerTranslations('en',en) 
  counterpart.registerTranslations('es',es)  
  counterpart.setLocale(localStorage.getItem('langlocal'));
  return (
    <>
      <Header />
      <h2 className="text-center">{<Translate content="Match" component="span"/>}</h2>
      <div className="match-form">
        <div className="match-projects__container">
          <div className="match-projects__list">
            <h3 className="projects-label">{<Translate content="Proyectosdisponibles" component="span"/>}</h3>
            <div className="match-list">
              {props.project
                ? props.project.map((e) => {
                    return (
                      <input
                        placeholder={e._id}
                        readOnly
                        value={e.nombre}
                        name="id_proyecto"
                        onClick={handleProyectClick}
                        key={e._id}
                        className="math-list"
                      />
                    );
                  })
                : null}
            </div>
          </div>
          <div className="match-users__list">
            <h3 className="projects-label">{<Translate content="Usuarios" component="span"/>}</h3>
            <div className="match-list">
              {props.allUsers
                ? props.allUsers.map((e) => {
                    return (
                      <input
                        placeholder={e._id}
                        readOnly
                        value={e.username}
                        name="id_usuarios"
                        onClick={handleClick}
                        className="math-list"
                        key={e._id}
                      />
                    );
                  })
                : null}
            </div>
          </div>
        </div>
        <div>
        
          <button onClick={() => {
                    setButtonPopup(true);
                    handlleMatch();
                   }} className="match-btn">
            {" "}
            Match{" "}
          </button>
        </div>
      </div>
      <Popup trigger={buttonPopup} setTrigger={setButtonPopup}>
              <h2 className="tittle-popup-apply">{<Translate content="Matchcompletadoconexito" component="span"/>}</h2>
            </Popup>
      <h2 className="text-center">{<Translate content="Legall" component="span"/>}</h2>
      <div className="legarlform match-form">
        <div className="match-projects__list">
          <h3 className="projects-label">{<Translate content="Proyectosdisponibles" component="span"/>}</h3>
          <div className="match-list">
            {props.project
              ? props.project.map((e) => {
                  return (
                    <input
                      placeholder={e._id}
                      readOnly
                      value={e.nombre}
                      name="id_proyecto"
                      onClick={handleLegalFormChangePlaceholder}
                      key={e._id}
                      className="math-list"
                    />
                  );
                })
              : null}
          </div>
        </div>
        <div className="match-users__list">
          <h3 className="projects-label">Coalliers</h3>
          <div className="match-list">
            {props.allUsers
              ? props.allUsers
                  .filter((element) => element.roles[0].name === "coallier")
                  .map((e) => {
                    return (
                      <input
                        placeholder={e._id}
                        readOnly
                        value={e.username}
                        name="Coallier_form"
                        onClick={handleLegalFormChangePlaceholder}
                        className="math-list"
                        key={e._id}
                      />
                    );
                  })
              : null}
          </div>
        </div>

        <div className="match-users__list">
          <h3 className="projects-label">{<Translate content="Companies" component="span"/>}</h3>
          <div className="match-list">
            {props.allUsers
              ? props.allUsers
                  .filter((element) => element.roles[0].name === "company")
                  .map((e) => {
                    return (
                      <input
                        placeholder={e._id}
                        readOnly
                        value={e.username}
                        name="Company_form"
                        onClick={handleLegalFormChangePlaceholder}
                        className="math-list"
                        key={e._id}
                      />
                    );
                  })
              : null}
          </div>
        </div>
        <div className="match-users__list">
          <input
            type="number"
            name="precio"
            id="precio"
            placeholder="Inserte precio del proyecto"
            onChange={(e) => {
              handleLegalFormChange(e);
            }}
          />
          <input
            type="date"
            name="fecha_inicio"
            id="fecha_inicio"
            placeholder="Inserte fecha de inicio"
            onChange={(e) => {
              handleLegalFormChange(e);
            }}
          />
          <input
            type="date"
            name="fecha_fin"
            id="fecha_fin"
            placeholder="Inserte fecha de fin"
            onChange={(e) => {
              handleLegalFormChange(e);
            }}
          />
          <input type="button" value="enviar" onClick={() => {
                    setButtonPopup(true);
                    handleFormLegalSubmit();
                   }} />
        </div>
      </div>
    </>
  );
}

const mapDispatchToProps = {
  getPostedProjects,
  getAllUsers,
  match,
  Legal,
};

function mapStateToProps(state) {
  return {
    project: state.projects,
    allUsers: state.allUsers,
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(Match);
