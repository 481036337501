import sectorAgro from '../../../../../assets/imagenes/sectorAgropecuario.png';
import sectorInd from '../../../../../assets/imagenes/sectorIndustrial.png';
import sectorEdu from '../../../../../assets/imagenes/sectorEducativo.png';

const infoSectores = [
  {
    image: sectorAgro,
    title_es: 'Sector agropecuario',
    title_en: 'Agricultural sector',
    cant: 11,
    link: '/schneider/formacion/agriculture'
  },
  {
    image: sectorInd,
    title_es: 'Sector industrial',
    title_en: 'Industrial sector',
    cant: 12,
    link: '/schneider/formacion/industrial'
  },
  {
    image: sectorEdu,
    title_es: 'Sector educativo',
    title_en: 'Education sector',
    cant: 10,
    link: '/schneider/formacion/educational'
  }
];

export default infoSectores;