export const changeCourseAdm = (course, setCourse, setLoading, setOpen, image) => {
    const loadingCourse = async (video) => {
        setLoading(true);
        await setCourse(video);
    }
    switch (course) {
        case  'Linkedin' :
            loadingCourse([{
                title: 'LinkedIn palabras clave',
                image: 'https://cdn-icons-png.flaticon.com/512/179/179330.png',
                video: 'https://www.loom.com/share/80e81a84fc4d4a76b132a6debcb8b749',
            }, {
                title: 'Foto de Perfil y Banner',
                image: 'https://cdn-icons-png.flaticon.com/512/179/179330.png',
                video: 'https://www.loom.com/share/198ffe3996bb4a2f9b43c83c9e2873d6',
            },{
                title: 'Snappr Photo Analyzer',
                image: 'https://cdn-icons-png.flaticon.com/512/179/179330.png',
                video: 'https://www.loom.com/share/402a829760504a698cc0fe3e2160e4c8',
            },{
                title: 'Acerca de',
                image: 'https://cdn-icons-png.flaticon.com/512/179/179330.png',
                video: 'https://www.loom.com/share/7e08720db7484cfab8217c257a83a983',
            },{
                title: 'Redacción de Experiencias y Logros',
                image: 'https://cdn-icons-png.flaticon.com/512/179/179330.png',
                video: 'https://www.loom.com/share/d6739e305901420daafd5f9089ca7315',
            },{
                title: 'Recomendaciones y Red de Contactos',
                image: 'https://cdn-icons-png.flaticon.com/512/179/179330.png',
                video: 'https://www.loom.com/share/ba9eec1bf467417ba71d30c76d2f1e95',
            }]).then(() => {
                setTimeout(() => {
                    setLoading(false);
                    setOpen(true);
                }, 1500);
            });
            break;
        case  'ux' :
            loadingCourse('https://www.youtube.com/watch?v=8ZtInClXe1Q').then(() => {
                setTimeout(() => {
                    setLoading(false);
                    setOpen(true);
                }, 1500);
            });
            break;
        case  'data' :
            loadingCourse('https://www.youtube.com/watch?v=8ZtInClXe1Q').then(() => {
                setTimeout(() => {
                    setLoading(false);
                    setOpen(true);
                }, 1500);
            });
            break;
        case  'Hoja de vida y cover letters' :
            loadingCourse([{
                title: 'Hoja de vida',
                image: 'https://images.pexels.com/photos/5989925/pexels-photo-5989925.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',
                video: 'https://www.loom.com/share/b0772870e31c42e2aa0fd77aa809bc64',
            },{
                title: 'Manual Hoja de vida',
                file: 'Manual Hoja de vida'
            },{
                title: 'Formato hoja de vida ATS',
                file: 'Formato hoja de vida'
            },{
                title: 'Cover Letter Inglés',
                file: 'Motivacion 1'
            },{
                title: 'Cover Letter con experiencia',
                file: 'Motivacion 2'
            },{
                title: 'Cover Letter sin experiencia',
                file: 'Motivacion 3'
            }]).then(() => {
                setTimeout(() => {
                    setLoading(false);
                    setOpen(true);
                }, 1500);
            });
            break;
        case 'Networking' :
            loadingCourse([{
                title: 'Mapear contactos',
                image: 'https://images.pexels.com/photos/2566581/pexels-photo-2566581.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',
                video: 'https://youtu.be/U_ntS2ApFyg',
            },{
                title: 'Busqueda de empresas',
                image: 'https://images.pexels.com/photos/2566581/pexels-photo-2566581.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',
                video: 'https://youtu.be/rhBeAFV5frA',
            },{
                title: 'Interacción',
                image: 'https://images.pexels.com/photos/2566581/pexels-photo-2566581.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',
                video: 'https://youtu.be/mTb8kMtZb0k',
            },{
                title: 'Publicación de contenido',
                image: 'https://images.pexels.com/photos/2566581/pexels-photo-2566581.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',
                video: 'https://youtu.be/jbFlSWcnEPI',
            }]).then(() => {
                setTimeout(() => {
                    setLoading(false);
                    setOpen(true);
                }, 1500);
            });
            break;
        case 'Perfilamiento' :
            loadingCourse([{
                title: 'DOFA personal',
                image: 'https://images.pexels.com/photos/2566581/pexels-photo-2566581.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',
                video: 'https://www.loom.com/share/3cda5325ac6249baaa0fb3b6c99f1d80',
            },{
                title: 'Archivo DOFA personal',
                file: 'DOFA personal'
            },{
                title: 'CAME Personal para la Empleabiliad:',
                image: 'https://images.pexels.com/photos/2566581/pexels-photo-2566581.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',
                video: 'https://www.loom.com/share/639ff5b9915e40608e317eb32b1807f8'
            },{
                title: 'Analisís CAME personal',
                file: 'analisis CAME personal'
            },{
                title: 'Estrategia de conexión',
                image: 'https://images.pexels.com/photos/2566581/pexels-photo-2566581.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',
                video: 'https://www.loom.com/share/e6a1f9857edb42cb9fc6df9b2bbe10e8',
            },{
                title: 'Estrategia de conexión',
                file: 'Estrategia de conexión'
            }]).then(() => {
                setTimeout(() => {
                    setLoading(false);
                    setOpen(true);
                }, 1500);
            });
            break;
        default:
            setCourse('');
            setLoading(false)
    }
}
