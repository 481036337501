import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import SmallCardLinked from "../../../../components/cards/oportunitycard/linkedcards/smallcardlinked/SmallCardLinked";
import { SmallCardContainerWrapper } from "../../../../components/cards/oportunitycard/linkedcards/smallcardlinked/SmallCardLinkedStyles";

interface SmallCardLinkedWrapperProps {
  e: any;
  i: number;
  width: number;
  handleClickOportunity?: (e: any) => void;
  selectedOportunity?: any;
}

const SmallCardLinkedWrapper: React.FC<SmallCardLinkedWrapperProps> = ({
  e,
  i,
  width,
  handleClickOportunity,
  selectedOportunity,
}) => {
  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    setIsActive(e?._id === selectedOportunity?._id);
  }, [e, selectedOportunity]);

  const commonProps = {
    place: e?.municipio || "",
    aplicantes: e?.aplicantes ? e.aplicantes.length : "",
    image: e?.usuario.avatar ? e.usuario.avatar : e.imageUrl ? e.imageUrl : "",
    title: e?.NombreOportunidad
      ? e.NombreOportunidad
      : e?.nombre === "Unnamed Opportunity"
      ? "Oportunidad sin nombre"
      : e.nombre,
    date: e?.fecha_inicio || "",
    company: e?.NombreEmpresa || "",
    width: width,
    modalidadDeTrabajo: e?.modalidad_de_trabajo || "",
  };

  if (width < 951) {
    return (
      <Link
        to={`/schneider/project/${e._id}`}
        style={{ width: "100%" }}
        key={i}
      >
        <SmallCardLinked {...commonProps} active={false} />
      </Link>
    );
  } else {
    return (
      <SmallCardContainerWrapper
        onClick={() => handleClickOportunity(e)}
        key={i}
      >
        <SmallCardLinked
          {...commonProps}
          active={isActive}
          handleClickOportunity={handleClickOportunity}
          oportunity={e}
          selectedOportunity={selectedOportunity}
        />
      </SmallCardContainerWrapper>
    );
  }
};

export default SmallCardLinkedWrapper;
