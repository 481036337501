/* eslint-disable */
import React, { useState, useEffect, useContext } from "react";
import SearchIcon from "@mui/icons-material/Search";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import proyectoimagen from "../../profesionalpag/oportunidad/proyectos.webp"
import { getPostedProjects, ConsultorData, loginReq, tourStatus, tourViewedStatus } from "../../actions";
import UniversitiesNavbar from "../components/navbar/UniversitiesNavbar";
import "./home.css";
import "./homeCardv1.css";
import Loader from "../../profesionalpag/Loader/LoaderHome";
import * as PropTypes from "prop-types";
import axios from "axios";
import counterpart from "counterpart";
import en from "../../lang/en-US";
import es from "../../lang/es-COL"
import './HomeprofesionalV2.css';
import OportunidadCardGrid from "./cards/OportunidadCardGrid";
import OportunidadCardList from "./cards/OportunidadCardList";
import TestCard from "../../profesionalpag/home/TestCard/TestCard";
import Chip from '@mui/material/Chip';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/material/styles';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import WindowIcon from '@mui/icons-material/Window';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import UniversitiesSideBar from "../components/sidebar/UniversitiesSideBar";
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import HomeCarousel from "../../profesionalpag/home/HomeCarousel";
import EastOutlinedIcon from '@mui/icons-material/EastOutlined';
import { UniversityContext } from "../context/UniversitiesContext";
import { set } from "date-fns";
import UseTour from "../components/tour/UseTour";
import { Dialog } from "@mui/material";

function ExploreOutlinedIcon() {
    return null;
}

ExploreOutlinedIcon.propTypes = { color: PropTypes.string };

function ListOutlinedIcon() {
    return null;
}

ListOutlinedIcon.propTypes = { color: PropTypes.string };
const UniversitiesHome = () => {
    const { university } = useContext(UniversityContext);
    const history = useHistory();
    const dispatch = useDispatch();
    const [mounted, setMounted] = useState(false);
    let { cv, user, projects, tourViewed } = useSelector((state) => state);
    const [filtro, setfiltro] = useState("");
    const [projectosFiltrados, setProjectosFiltrados] = useState("");
    const [proyectosMl, setProyectosMl] = useState();
    const [loading, setLoading] = useState(true);
    const [popUp, setPopUp] = useState(false);
    const [cvError, setCvError] = useState(false);
    const { lang } = useSelector((state) => state);
    //eslint-disable-next-line
    const [popupPersonalidad, setPopUpPersonalidad] = useState(false)
    const [resultadoTest, setResultadoTest] = useState('');
    const [isOpenTestPersonalidad, setOpenTestPersonalidad] = useState(false);
    const [popUpCv, setPopUpCv] = useState(false)


    useEffect(() => {
        setTimeout(() => {
            setMounted(true);
        }, 1000);
    }, []);
    const [orderProyectos, setOrderProyectos] = useState("grid");
    const [showMore, setShowMore] = useState(false);
    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);
    const updateWidthAndHeight = () => {
        setWidth(window.innerWidth);
        setHeight(window.innerHeight);
    }




    useEffect(() => {
        window.addEventListener("resize", updateWidthAndHeight);
        if (width < 650) {
            setOrderProyectos("grid")
        }
        return () => window.removeEventListener("resize", updateWidthAndHeight);
    }, [width, height]);

    const mandarpersonalizad = (e) => {
        e.preventDefault()
        let nuevadata = user
        nuevadata.calificaion_aptitudes = resultadoTest
        axios.put("https://findally-backend.herokuapp.com/update/user/" + user.id, { calificaion_aptitudes: resultadoTest }).then(r => {
            localStorage.setItem("user", JSON.stringify(nuevadata))
            dispatch(loginReq(nuevadata))
            setPopUpPersonalidad(false)
        })
    }

    //pagination
    const [page, setPage] = useState({
        currentPage: 0,
        totalPage: 0,
        totalElements: 0
    });
    const cancel = new AbortController();
    useEffect(() => {
        if (!user.id) {
            return history.push("/");
        }
        axios.get((`https://findally-backend.herokuapp.com/api/Project?limit=&status=En+espera`)).then(r => {
            setPage({
                currentPage: r.data.page,
                totalPage: r.data.TotalPage,
                totalElements: r.data.total
            })
        })
            .catch((r) => {
                if (r.name === 'AbortError') return console.log('fetch aborted');
            })
        return () => {
            cancel.abort();
        }
        //eslint-disable-next-line
    }, [page.totalPage])

    // useEffect(() => {
    //     if (university && university.name) {
    //         axios.post("https://findally-backend.herokuapp.com/api/university/oportunities", { name: university.name })
    //             .then(r => {
    //                 setProjectosFiltrados(r.data.data)
    //                 setLoading(false)
    //             }).catch(e => {
    //                 console.log(e)
    //                 setLoading(false)
    //             })
    //     }
    // }, [university]);

    const handlePageClick = (e) => {
        page.currentPage = e.selected;
        setPage({
            ...page,
            currentPage: page.currentPage,
        })
    }

    const arrayfiltrado = async () => {
        const h = await projects.filter((e) => e.status === "En espera");
        setProjectosFiltrados(h.reverse());
        setLoading(false)
    };

    const redirect = () => {
        if (!user.id) {
            return history.push("/");
        }
        history.push("/company/home");
    };

    useEffect(() => {
        if (!user.id) {
            return history.push("/");
        }
        const getData = () => {
            dispatch(ConsultorData(user.id));
        };
        getData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []); // eslint-disable-next-line

    useEffect(() => {
        dispatch(getPostedProjects(page.currentPage));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page]);

    useEffect(() => {
        if (projects && !cv && !proyectosMl) {
            arrayfiltrado();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [projects]);


    const [selectedFile, setSelectedFile] = useState();
    const [isFilePicked, setIsFilePicked] = useState(false);


    const changeHandler = (e) => {
        setCvError(false)
        setSelectedFile(e.target.files[0])
        const fileData = new FormData();
        fileData.append("upload_file", e.target.files[0]);
        fileData.append("usuario", user.id);
        axios.post(`https://findally-backend.herokuapp.com/api/user/validateCV?lang=${lang}`, fileData)
            .then((res) => {
                if (res.status === 200) {
                    toast.success(res.data.message, {
                        position: 'top-center',
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    return setIsFilePicked(true)
                }
            }).catch((err) => {
                if (err.response.status === 400) {
                    setSelectedFile(null)
                    return setCvError(true)
                }
            })
    }

    //eslint-disable-next-line
    const Orden = (e) => {
        e.preventDefault();
        if (e.target.value === "asc") {
            const h = projects.slice().sort((a, b) => a.presupuesto - b.presupuesto);
            return setProjectosFiltrados(h.reverse());
        }
        if (e.target.value === "desc") {
            const h = projects.slice().sort((a, b) => a.presupuesto - b.presupuesto);
            return setProjectosFiltrados(h);
        }
        if (e.target.value === "def") {
            return setProjectosFiltrados(projects)
        }
    }

    if (!localStorage.getItem('langlocal')) {
        localStorage.setItem('langlocal', 'es')
    }

    const changePopUp = (e) => {
        e.preventDefault()
        return setPopUp(!popUp)
    }

    counterpart.registerTranslations('en', en)
    counterpart.registerTranslations('es', es)
    counterpart.setLocale(localStorage.getItem('langlocal'));


    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
    };
    //popup de test
    const style1 = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: width > 600 ? 772 : '90%',
        height: width > 600 ? 721 : '80vh',
        bgcolor: '#FFFFFF',
        border: '2px solid #000',
        borderRadius: '8px',
        boxShadow: 24,
        overflow: 'auto',
    };

    //popup resultado
    const styles2 = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: width > 600 ? 772 : '90%',
        height: width > 600 ? 603 : '80vh',
        bgcolor: '#FFFFFF',
        border: '2px solid #000',
        borderRadius: '8px',
        boxShadow: 24,
        overflow: 'auto',
    };

    const tests = [
        {
            id: 1,
            name: 'Test de personalidad',
            real: 'personalidad',
            description: 'Realice este test gratuito para saber más sobre sí mismo y sus puntos fuertes.',
        },
        {
            id: 2,
            name: 'Test Vocacional',
            description: 'Realice este test gratuito para saber más sobre sí mismo y sus puntos fuertes.',
        },
        {
            id: 3,
            name: 'Test de Inteligencia',
            description: 'Realice este test gratuito para saber más sobre sí mismo y sus puntos fuertes.',
        },
        {
            id: 4,
            name: 'Habilidades sociales',
            description: 'Realice este test gratuito para saber más sobre sí mismo y sus puntos fuertes.',
        }
    ]

    const testsENG = [
        {
            id: 1,
            name: 'Personality test',
            real: 'personalidad', description: 'Take this free trial to learn more about yourself and your strengths.',
        },
        {
            id: 2,
            name: 'Vocational test',
            description: 'Take this free trial to learn more about yourself and your strengths.',
        },
        {
            id: 3,
            name: 'Inteligence test',
            description: 'Take this free trial to learn more about yourself and your strengths.',
        },
        {
            id: 4,
            name: 'Social skills',
            description: 'Take this free trial to learn more about yourself and your strengths.',
        }
    ]

    //filter

    const handleOnClick = (e) => {
        axios.post(`https://findally-backend.herokuapp.com/api/Project/text`, { text: filtro })
            .then((res) => {
                setProjectosFiltrados(res.data)
                setLoading(false)
            })
            .catch((err) => {
                console.log(err)
            })
    }

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            axios.post(`https://findally-backend.herokuapp.com/api/Project/text`, { text: filtro })
                .then((res) => {
                    setProjectosFiltrados(res.data)
                    setLoading(false)
                })
                .catch((err) => {
                    console.log(err)
                })
        }
    }
    const infoExperiencia = cv?.experiencia?.map((e) => {
        // return {
        //     nombre_cargo: e.cargos[0].nombrecargo,
        //     duracion: e.cargos[0].duracion ? e.cargos[0].duracion : '',
        //     ubicacion: e.cargos[0].ubicacion,
        //     descripcion: e.cargos[0].descripcion
        // }
        return {
            nombre_cargo: '',
            duracion: '',
            ubicacion: '',
            descripcion: ''
        }
    })


    useEffect(() => {
        if (cv) {
            axios.post('https://resume-matching-deploy.herokuapp.com/n-mejores-empleos', {
                n: 4,
                ID: cv._id,
                Descripcion: cv?.extracto || "",
                Experiencia: infoExperiencia || [{ nombre_cargo: "", duracion: "", ubicacion: "", descripcion: "" }],
                Estudios: cv?.educacion[0]?.Titulo_Certificacion || "",
                Habilidades_tecnicas: "",
                Habilidades_Blandas: cv?.aptitudes_principales?.map((habilidad) => habilidad).join(", ") || '',
                Logros: "",
                Trabajo_Social: "",
                Universidad: "",
                Idiomas: cv?.languages?.map((idioma) => idioma.Language).join(", ") || '',
                Salario: cv?.aspiracion_salarial || '',
                Tipo_de_empleo: "",
                Modalidad: ""
            })
                .then((res) => {
                    const { response } = res.data;
                    const result = {};
                    if (Object.keys(response).length === 0 && projects) {
                        arrayfiltrado()
                    } else {
                        for (const key in response) {
                            const value = response[key];
                            if (typeof value === "object" && value !== null) {
                                const fitKey = "fit" + key;
                                if (fitKey in response) {
                                    value.fit = response[fitKey];
                                }
                                result[key] = value;
                            }
                        }
                        setProyectosMl(result);
                    }
                })
                .catch((err) => {
                    console.log(err)
                })
        }
    }, [cv, projects])

    const proyectos = proyectosMl && Object.values(proyectosMl).map((proyecto) => proyecto.ID)

    useEffect(() => {
        if (proyectos) {
            Promise.all(proyectos.map((id) => axios.get(`https://findally-backend.herokuapp.com/api/Project/${id}`)))
                .then((res) => {
                    //sumar el fit a cada proyecto
                    const proyectosConFit = res.map((proyecto, index) => {
                        const indexProyecto = index + 1
                        return {
                            ...proyecto.data,
                            fit: proyectosMl[indexProyecto]?.fit
                        }
                    })
                    setProjectosFiltrados(proyectosConFit)
                    // setLoading(false)
                })
                .catch((err) => {
                    console.log(err)
                })
        }
    }, [proyectosMl, filtro])


    // useEffect(() => {
    //     if (!filtro) {
    //         setProjectosFiltrados(projects);
    //     }
    // }, [filtro])


    function ChipsArray() {
        const handleDelete = () => {
            setfiltro('');
        };
        return (

            <Chip
                sx={{
                    background: '#E5DCF7',
                    borderRadius: '5px',
                    marginLeft: '20px',
                    fontWeight: 400,
                    fontSize: '14px',
                    lineHeight: '20px',
                    color: '#202256',
                    flexDirection: 'row-reverse',
                    padding: '10px',
                }}
                label={filtro}
                deleteIcon={<CloseIcon style={{ margin: '0px', color: '#202256' }} />}
                onDelete={handleDelete}
            />

        );
    }


    //progress bar

    const BorderLinearProgress = styled(LinearProgress)(() => ({
        height: 14,
        borderRadius: 5,
        width: 640,
        marginRight: '1em',
        [`&.${linearProgressClasses.colorPrimary}`]: {
            backgroundColor: '#C5C5C5',
        },
        [`& .${linearProgressClasses.bar}`]: {
            borderRadius: 5,
            backgroundColor: '#202256'
        },
    }));


    // Popups
    const [open3, setOpen3] = useState(false);
    const [open2, setOpen2] = useState(false);
    const handleClose = () => setOpen2(false);
    const handleClose2 = () => setOpen3(false);
    const handleOpen = () => setOpen2(true);
    const handleOpen2 = () => setOpen3(true);

    const stylePopUp = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '721px',
        height: '478px',
        bgcolor: '#FFFFFF',
        borderRadius: '8px',
    };

    const [progreso, setProgreso] = useState(0); //progreso para la barra
    const [faltante, setFaltante] = useState(); // faltantes para resaltar debajo de la barra
    const [perfil, setPerfil] = useState(); //copia depurada de los elementos de un cv

    //con este uEffect se actualiza y copia el perfil
    useEffect(() => {
        setPerfil({
            Aptitudes: cv?.aptitudes_principales,
            Certificaciones: cv?.certifications,
            Contacto: cv?.contacto,
            Educación: cv?.educacion,
            Experiencia: cv?.experiencia,
            Estracto: cv?.extracto,
            Lenguajes: cv?.languages,
            Info: cv?.info_personal
        })
    }, [cv])

    //con este uEffect se actualiza el progreso de la barra
    useEffect(() => {
        if (perfil) {
            let contador = 0;
            let faltantes = [];
            for (const key in perfil) {
                if (perfil[key]) {
                    contador = contador + 12.5;
                } else {
                    faltantes.push(key);
                }
            }
            setProgreso(contador);
            setFaltante(faltantes);
        }
    }, [perfil])

    useEffect(() => {
        if (tourViewed === true) {
            return
        }
        setTimeout(() => {
            dispatch(tourStatus({ run: true }))
        }, 1500);
    }, [])

    //este translator es para los campos de la barra de progreso!
    const translator = (word) => {
        if (lang === 'es') {
            return word;
        } else {
            switch (word) {
                case 'Aptitudes':
                    return 'Skills';
                case 'Certificaciones':
                    return 'Certifications';
                case 'Contacto':
                    return 'Contact';
                case 'Educación':
                    return 'Education';
                case 'Experiencia':
                    return 'Experience';
                case 'Estracto':
                    return 'Extract';
                case 'Lenguajes':
                    return 'Languages';
                case 'Info':
                    return 'Info';
            }
        }
    }

    const handleTour = () => {
        localStorage.setItem('tourViewed', false)
        dispatch(tourViewedStatus(false))
        dispatch(tourStatus({ run: true }))
    }

    useEffect(() => {
        let timeoutId;
        if (cv === '' && tourViewed === true) {
            timeoutId = setTimeout(() => {
                setPopUpCv(true);
            }, 1000);
        }
        return () => {
            clearTimeout(timeoutId);
        };
    }, [cv, tourViewed]);

    return (
        <>
            {university && university.color &&
                <div className={"contenedor-oportunidad"}>
                    {/* <ToastContainer /> */}
                    {user && user.roles === "coallier" ? (
                        <div>
                            <UseTour />
                            {/*<Header setOpenTestPersonalidad={setOpenTestPersonalidad} setPopUpPersonalidad={setPopUpPersonalidad}*/}
                            {/*        popupPersonalidad={popupPersonalidad}></Header>*/}
                            <UniversitiesSideBar location={'home'} />
                            <div
                                className="BodyWeb"
                                style={{
                                    padding: "0rem 2rem",
                                    position: "absolute",
                                    right: "0",
                                }}
                                id='home'
                            >
                                <UniversitiesNavbar locate={'home'} />
                                <div className="Profesional-Home-Contenedor-Global">
                                    <div className="Profesional-Home-container-inner">
                                        <div className="Profesional-Home-Contenedor-1-new">
                                            <h1 style={{
                                                fontSize: '20px',
                                                fontWeight: '400',
                                                color: '#393939',
                                                textAlign: 'center'
                                            }}>
                                                {lang === 'es' ? 'Es momento de buscar' : 'It´s time to look for'}
                                            </h1>
                                        </div>
                                        <div className="Profesional-Home-Contenedor-2-new">
                                            <h1 style={{
                                                color: '#393939',
                                                fontSize: '40px',
                                                fontWeight: '700',
                                                textAlign: 'left'
                                            }}>
                                                {lang === 'es' ? 'nuevas oportunidades laborales' : 'new job opportunities'}
                                            </h1>
                                        </div>
                                        <div className="Profesional-Home-Contenedor-3-new">
                                            <div className="Profesional-Home-Contenedor-3-inner-1">
                                                <div style={{ width: '100%' }}>
                                                    <SearchIcon className={'lupa'} />
                                                    <input
                                                        className={'Profesional-Home-Search'}
                                                        style={{
                                                            border: 'none',
                                                            marginRight: 0
                                                        }}
                                                        placeholder={lang === 'es' ? 'Buscar oportunidades' : 'Search opportunities'}
                                                        onChange={(e) => setfiltro(e.target.value)}
                                                        onKeyPress={handleKeyPress} />
                                                </div>
                                                <button style={{
                                                    backgroundColor: university.color.primary,
                                                    height: '33px',
                                                    width: '118px',
                                                    borderRadius: '5px',
                                                    color: 'white',
                                                    textAlign: 'center'
                                                }}
                                                    onClick={handleOnClick}>
                                                    {lang === 'es' ? 'Buscar' : 'Search'}
                                                </button>
                                            </div>
                                            {width >= 650 ?
                                                <div className="Profesional-Home-Contenedor-3-inner-2">
                                                    <div
                                                        className={`${orderProyectos === 'list' ? 'Profesional-Home-Contenedor-3-inner-2-1-active' : 'Profesional-Home-Contenedor-3-inner-2-1'}`}
                                                        style={{
                                                            backgroundColor: `${orderProyectos === 'list' ? university.color.primary : 'transparent'}`
                                                        }}
                                                        onClick={() => {
                                                            setOrderProyectos('list')
                                                        }}>
                                                        <FormatListBulletedIcon sx={{
                                                            fontSize: 24,
                                                            color: `${orderProyectos === 'list' ? 'white' : '#C5C5C5'}`
                                                        }} />
                                                    </div>
                                                    <div
                                                        className={`${orderProyectos === 'grid' ? 'Profesional-Home-Contenedor-3-inner-2-2-active' : 'Profesional-Home-Contenedor-3-inner-2-2'}`}
                                                        style={{
                                                            backgroundColor: `${orderProyectos === 'grid' ? university.color.primary : 'transparent'}`
                                                        }}
                                                        onClick={() => {
                                                            setOrderProyectos('grid')
                                                        }}>
                                                        <WindowIcon sx={{
                                                            fontSize: 24,
                                                            color: `${orderProyectos === 'grid' ? 'white' : '#C5C5C5'}`
                                                        }} />
                                                    </div>
                                                </div> : null
                                            }
                                        </div>

                                        <div className={
                                            orderProyectos === 'list' ? 'Profesional-Home-Contenedor-4-new' : 'Profesional-Home-Contenedor-4-new-grid'
                                        }>
                                            {!projectosFiltrados?.length && (
                                                <div>
                                                    <Loader render={loading} array={projectosFiltrados} altura={15} />
                                                </div>
                                            )}
                                            {orderProyectos === 'grid' && projectosFiltrados && !showMore && projectosFiltrados.slice(0, 4).map((p, index) => {
                                                return (
                                                    <OportunidadCardGrid key={index}
                                                        descripcion={p.empleos_alternativos[0] ? p.empleos_alternativos[0] : ''}
                                                        empresa={p.NombreEmpresa}
                                                        titulo={p.NombreOportunidad}
                                                        titulo2={p.nombre}
                                                        salario={p.presupuesto}
                                                        dir={p.link}
                                                        id={p._id}
                                                        lenguaje={lang}
                                                        imagen={p.imageUrl ? p.imageUrl : proyectoimagen}
                                                        Anonimo={p.Anonimo ? p.Anonimo : null}
                                                        ciudad={p.ciudad ? p.ciudad : null}
                                                        order={orderProyectos}
                                                        profesionales={p.profesionales}
                                                        status={p.status}
                                                        modalidadDeTrabajo={p.modalidadDeTrabajo}
                                                        porcentaje={p.fit}
                                                    />
                                                )
                                            })}
                                            {orderProyectos === 'list' && projectosFiltrados && !showMore && projectosFiltrados.slice(0, 4).map((p, index) => {
                                                return (
                                                    <OportunidadCardList key={index}
                                                        width={width}
                                                        empresa={p.NombreEmpresa}
                                                        titulo={p.NombreOportunidad}
                                                        titulo2={p.nombre}
                                                        salario={p.presupuesto}
                                                        dir={p.link}
                                                        id={p._id}
                                                        lenguaje={lang}
                                                        imagen={p.imageUrl ? p.imageUrl : proyectoimagen}
                                                        Anonimo={p.Anonimo ? p.Anonimo : null}
                                                        ciudad={p.ciudad ? p.ciudad : null}
                                                        fecha={p.fecha_inicio.slice(0, 10)}
                                                        order={orderProyectos}
                                                        profesionales={p.profesionales}
                                                        descripcion={p.empleos_alternativos[0] ? p.empleos_alternativos[0] : ''}
                                                        porcentaje={p.fit}
                                                    />
                                                )
                                            })}
                                            {orderProyectos === 'grid' && projectosFiltrados && showMore && projectosFiltrados.map((p, index) => {
                                                return (
                                                    <OportunidadCardGrid key={index}
                                                        descripcion={p.empleos_alternativos[0] ? p.empleos_alternativos[0] : ''}
                                                        empresa={p.NombreEmpresa}
                                                        titulo={p.NombreOportunidad}
                                                        titulo2={p.nombre}
                                                        salario={p.presupuesto}
                                                        dir={p.link}
                                                        id={p._id}
                                                        lenguaje={lang}
                                                        imagen={p.imageUrl ? p.imageUrl : proyectoimagen}
                                                        Anonimo={p.Anonimo ? p.Anonimo : null}
                                                        ciudad={p.ciudad ? p.ciudad : null}
                                                        order={orderProyectos}
                                                        profesionales={p.profesionales}
                                                        status={p.status}
                                                        modalidadDeTrabajo={p.modalidadDeTrabajo}
                                                    />
                                                )
                                            })}
                                            {orderProyectos === 'list' && projectosFiltrados && showMore && projectosFiltrados.map((p, index) => {
                                                return (
                                                    <OportunidadCardList key={index}
                                                        width={width}
                                                        descripcion={p.empleos_alternativos[0] ? p.empleos_alternativos[0] : ''}
                                                        empresa={p.NombreEmpresa}
                                                        titulo={p.NombreOportunidad}
                                                        titulo2={p.nombre}
                                                        salario={p.presupuesto}
                                                        dir={p.link}
                                                        id={p._id}
                                                        lenguaje={lang}
                                                        imagen={p.imageUrl ? p.imageUrl : proyectoimagen}
                                                        Anonimo={p.Anonimo ? p.Anonimo : null}
                                                        ciudad={p.ciudad ? p.ciudad : null}
                                                        order={orderProyectos}
                                                        fecha={p.fecha_inicio.slice(0, 10)}
                                                        profesionales={p.profesionales}
                                                    />
                                                )
                                            })}
                                        </div>
                                        <div style={{
                                            width: '100%',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            textAlign: 'center',
                                            marginTop: '50px',
                                        }}>
                                            <Link to={'/consultor/projects'} style={{
                                                border: 'none',
                                                backgroundColor: 'transparent',
                                                color: '#5D5D5D',
                                                fontSize: '16px',
                                                fontWeight: '400',
                                            }}
                                            // onClick={() => {
                                            //   setShowMore(!showMore)
                                            // }}
                                            >
                                                {showMore ? lang === 'es' ? 'Ver menos' : 'See less' : lang === 'es' ? 'Cargar más' : 'See more'}
                                            </Link>
                                        </div>
                                        <div style={{
                                            width: '100%', height: '100%', marginTop: '30px'
                                        }}>
                                            <div style={{
                                                width: '100%',
                                                display: 'flex',
                                                flexDirection: 'column',
                                                alignItems: 'center',
                                                justifyContent: 'flex-start',
                                                height: '100%'
                                            }}>
                                                <h2 style={{
                                                    color: '#5D5D5D',
                                                    fontSize: '20px',
                                                    fontWeight: '400',
                                                }}>
                                                    {lang === 'es' ? 'Coally integra la inteligencia artificial de ChatGPT para que tú puedas recibir indicaciones de en donde debes trabajar y que debes estudiar para cumplir tus objetivos.' : 'Coally integrates ChatGPT artificial intelligence so that you can receive indications of where you should work and what you should study to achieve your goals.'}
                                                </h2>
                                                <Link to={`/${university.route}/career-ia`}>
                                                    <button className={"home-profesional-ai-button"} style={{ color: '#fff', backgroundColor: university.color.primary }}>
                                                        {lang === 'es' ? 'Llevame allí' : 'Take me there'}
                                                    </button>
                                                </Link>
                                            </div>
                                        </div>
                                        <div style={{ width: '100%', height: '100%', marginTop: '30px' }}>
                                            <div style={{
                                                width: '100%',
                                                display: 'flex',
                                                flexDirection: 'row',
                                                alignItems: 'center',
                                                justifyContent: 'flex-start',
                                                height: '100%'
                                            }}>
                                                <h2 className={'Profesional-Home-SubTitle-2'} style={{ margin: 0 }}>
                                                    Test
                                                </h2>
                                                {/* <div style={{
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'flex-end',
                  justifyContent: 'flex-end',
                  marginLeft: '30px'
                }}>
                <Whisper placement="right" trigger="hover" controlId="control-id-hover" speaker={<Popover title={`${lang === 'es' ? '¡Importante!' : 'Important!'}`}>
        <p>{lang === 'es' ? 'Con esta información serán más efectivas las oportunidades que tenemos para ofrecerte y serás priorizado en las ofertas de elección.' : 'With this information, the opportunities we have to offer you will be more effective and you will be prioritized in the offers of your choice.'}</p>
      </Popover>}>
                  <WarningAmberOutlinedIcon sx={{ fontSize: 24, color: '#B0B0B0', marginLeft: '10px', borderRadius: '5px' }} />
                </Whisper>
                </div> */}
                                            </div>
                                            <div className={'Home-Profesional-Test-Container'}>
                                                {lang === 'es'
                                                    ? tests.map((test, i) => {
                                                        return (
                                                            <TestCard key={i} id={test.id} name={test.name}
                                                                description={test.description} user={user}
                                                                setOpenTestPersonalidad={setOpenTestPersonalidad}
                                                                width={width} real={test.real} />
                                                        )
                                                    })
                                                    : testsENG.map((test, i) => {
                                                        return (
                                                            <TestCard key={i} id={test.id} name={test.name}
                                                                description={test.description} user={user}
                                                                setOpenTestPersonalidad={setOpenTestPersonalidad}
                                                                width={width} real={test.real} />
                                                        )
                                                    })
                                                }
                                            </div>
                                        </div>

                                        {/* <div className="new-home-profesiona-bottom-container">
                                    <div className="new-home-profesional-bottom-container-carousel">
                                        <div className="new-home-profesional-bottom-container-carousel-title">
                                            <h2 style={{
                                                fontSize: '24px',
                                                fontWeight: '700',
                                                color: '#393939',
                                            }}>
                                                {lang === 'es' ? '¡Fórmate con nosotros!' : 'Train with us!'}
                                            </h2>
                                        </div>
                                        <div className="new-home-profesional-bottom-container-carousel-container">
                                            <HomeCarousel lang={lang}/>
                                        </div>
                                    </div>
                                    <div className="new-home-profesional-bottom-container-tour">
                                        <h1 style={{
                                            fontSize: '30px',
                                            fontWeight: '700',
                                            fontFamily: 'Roboto',
                                            color: '#FFFFFF',
                                            padding: '20px',
                                        }}>
                                            {
                                                lang === 'es' ? '¿Aún no sabes cómo se usa la plataforma?' : 'Don\'t you know how to use the platform yet?'
                                            }
                                        </h1>
                                        <div className="tour-button-container">
                                            <button onClick={() => handleTour()} className='tour-button-activate'>
                                                {
                                                    lang === 'es' ? '¡Haz el tour!' : 'Take the tour!'
                                                }
                                                <EastOutlinedIcon style={{marginLeft: '10px'}}/>
                                            </button>
                                              <div className="tour-circle-container">
                    <div className="tour-circle">

                    </div>
                  </div>
                                        </div>
                                    </div>
                                </div>*/}

                                    </div>
                                </div>
                            </div>
                            <Dialog open={popUpCv} onClose={() => setPopUpCv(false)}>
                                <div style={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', padding: '2rem' }}>
                                    <div style={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                                        <h2>{lang === "es" ? "¡Bienvenido!" : "Welcome"}</h2>
                                        <p>
                                            {lang === "es" ? "Necesitamos que completes tu perfil para poder brindarte una mejor experiencia." : "We need you to complete your profile in order to provide you with a better experience."}
                                        </p>
                                        <Link to={`/${university.route}/profile/${user?.id}`} style={{ textDecoration: 'none' }}>
                                            <button className="home-profesional-ai-button" style={{ color: '#fff', backgroundColor: university.color.primary, margin:'10px 0px' }}>
                                                {lang === 'es' ? 'Completar perfil' : 'Complete profile'}
                                            </button>
                                        </Link>
                                    </div>
                                </div>
                            </Dialog>




                        </div>
                    ) : (
                        redirect()
                    )
                    }
                </div>
            }</>
    );
}
    ;

export default UniversitiesHome;
