import React, {useEffect, useState, useContext} from "react";
import {useSelector} from "react-redux";
import CircularProgress from "@mui/material/CircularProgress";
import userImg from "../../../../../assets/icons/user.svg";
import {CircularFrameContainer, AvatarContainer} from "./CircularFrameStyles";
import {SchneiderContext} from "../../../../context/SchneiderContext";

export default function SchneiderCircularFrame({ userInfo, cv, progressCompany }) {
  const {schneider} = useContext(SchneiderContext)
  const [progress, setProgress] = useState(0)
  const {user} = useSelector((state) => state)

  useEffect(() => {
    let counter = 0;
    if (userInfo?.roles === 'coallier' ) {
      if (cv?.aptitudes_principales && cv?.aptitudes_principales?.length > 0) counter += 12.5
      if (cv?.certifications && cv?.certifications?.length > 0) counter += 12.5
      if (cv?.contacto && cv?.contacto?.correo?.length > 0) counter += 12.5
      if (cv?.educacion && cv?.educacion?.length > 0 && cv?.educacion[0]?.NombreInstitucion !== "") counter += 12.5
      if (cv?.experiencia && cv?.experiencia?.length > 0 && cv?.experiencia[0]?.Nombreempresa !== "") counter += 12.5
      if (cv?.extracto && cv?.extracto !== "") counter += 12.5
      if (cv?.languages && cv?.languages?.length > 0) counter += 12.5
      if( userInfo?.avatar && userInfo?.avatar !== "")counter += 12.5
      setProgress(counter);
    }
    else {
      setProgress(progressCompany);
    }
    //eslint-disable-next-line
  }, [cv, progressCompany])

  return (
    <CircularFrameContainer color={schneider.color.primary}>
      { user?.id === userInfo?.id &&
        <>
          <CircularProgress variant="determinate" value={100} id='SecondCircle' />
          <CircularProgress variant="determinate" value={progress} />
        </>
      }
      <AvatarContainer color={schneider.color.primary}>
        <img id='non-used' src={userInfo?.avatar ? userInfo?.avatar : userImg} alt={'user-pic'} />
      </AvatarContainer>
    </CircularFrameContainer>
  )
}
