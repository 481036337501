import React, { useEffect, useState } from "react";
import { useSwipeable } from "react-swipeable";
import logoCoally from '../../assets/logos/Logo-Coally-Aliados.png';
import logoDisruptia from '../../assets/logos/Logo-Disruptia-Aliados.png';
import logoEnel from '../../assets/logos/Logo-Enel-Aliados.png';
import logoCompromiso from '../../assets/logos/Logo-Compromiso-Aliados.png';
import logoTalent from '../../assets/logos/Logo-Talent-Aliados.png';
import logoSena from '../../assets/logos/Logo-Sena-Aliados.png';
import logoGlobant from '../../assets/logos/Logo-Globant-Aliados.png'
import './CarrouselAliados.css'


export default function CarrouselAliados({ lang }) {


    const sliderData = [
        (
            <div className="Container-Logos-Aliados">
                <div>
                <img src={logoCoally} alt='Coally' />
                </div>
                <div>
                <img src={logoDisruptia} alt='Disruptia' />
                </div>
                <div>
                <img src={logoEnel} alt='Enel' />
                </div>                
            </div>
        ),
        (
            <div className="Container-Logos-Aliados">
                <img src={logoTalent} alt='Talent' />
                <img src={logoCompromiso} alt='Compromiso'/>    
                <img src={logoGlobant} alt='Globant' style={{width:'260px'}} />
            </div>
        ),
        (
            <div className="Container-Logos-Aliados">
                <img src={logoSena} alt='Sena' className="logo-sena-responsive"/>
            </div>
        )

    ]

    const [current, setCurrent] = useState(0);
    const [paused, setPaused] = useState(false);
    const length = sliderData.length;

    useEffect(() => {
        const interval = setInterval(() => {
            if (!paused) {
                setCurrent(current === length - 1 ? 0 : current + 1);
            }
        }, 3000);
        return () => clearInterval(interval);
    }, [current, length, paused]);


    const nextSlide = () => {
        setCurrent(current === length - 1 ? 0 : current + 1);
    }

    const prevSlide = () => {
        setCurrent(current === 0 ? length - 1 : current - 1);
    }

    const handlers = useSwipeable({
        onSwipedLeft: () => nextSlide(),
        onSwipedRight: () => prevSlide(),
    });


    return (
        <div
            {...handlers}
            onMouseEnter={() => setPaused(true)}
            onMouseLeave={() => setPaused(false)}
            className="container-carrousel"
        >
            {
                sliderData.map((slide, index) => {
                    return (
                        <div key={index} className={
                            index === current ? 'slide-home active' : 'slide-home'
                        }>
                            {index === current && (
                                slide
                            )}
                        </div>
                    )
                })
            }
        </div>

    )
}