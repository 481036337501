import React from 'react';
import { ResponsiveContainer, PieChart, Pie, Tooltip, Cell } from 'recharts';
import Title from './Title';



export default function PieChartUsers({Total_profesional, Total_company, Empty_roles}) {
  const data = [{name: "Profesionales", proyectos: Total_profesional, color: "#202256"},
                {name: "Compañias", proyectos: Total_company, color: "#FE994A" },
                {name: "Sin Rol", proyectos: Empty_roles, color: "#4B42A1"}]
  return (
    <React.Fragment>
      <Title>USUARIOS</Title>
      <ResponsiveContainer>
      <PieChart width={730} height={250}>
      <Tooltip />
        <Pie data={data} dataKey="proyectos" nameKey="name" cx="50%" cy="50%" outerRadius={50}>
        {data.map((entry, index) => (
              <Cell fill={data[index].color} />
            ))}
        </Pie>
        <Pie data={data } dataKey="proyectos" nameKey="name" cx="50%" cy="50%" innerRadius={60} outerRadius={80} label >
        {data.map((entry, index) => (
              <Cell fill={data[index].color} />
            ))}
        </Pie>
      </PieChart>
      </ResponsiveContainer>
    </React.Fragment>
  );
}