import React from "react";
import './adminLayout.css';
import AdminSideBar from "../adminSideBar/AdminSideBar";

export default function AdminLayout({children, location}) {
    return (
        <div className={'Admin-Layout-Cont'}>
            <AdminSideBar location={location}/>
            <div className={'Admin-Layout-Sub-Cont'}>
                {children}
            </div>
        </div>
    )
}