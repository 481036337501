import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import Navbar from '../../../components/Navbar/Navbar'
import Sidebar from '../../../components/Sidebar/Sidebar'
import BannerUnileverHome from '../../../components/unilever/banner/BannerUnileverHome';
import './feedbackStyle/feedback.css'
import Question1 from './feedbackCards/Question1';
import axios from 'axios'
import { toast } from 'react-toastify'
import Checkbox from '@mui/material/Checkbox'
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';



export default function FeedbackProfesional() {


    const { lang } = useSelector(state => state)

    const [question1, setQuestion1] = useState('')
    const [descripcion, setDescripcion] = useState('')
    const [assist, setAssist] = useState(null)

    const [comentario, setComentario] = useState({})
    const [id] = useState(JSON.parse(localStorage.getItem('unilever_id')))

    const { mentor, mentoring } = useParams()

    useEffect(() => {
        setComentario({
            mentoria: mentoring,
            pregunta_satisfaccion: question1,
            asistencia: assist,
            comentarios: descripcion,
            mentor: mentor,
            student: id,
        })
    }, [question1, descripcion, mentor, mentoring, id, assist])

    const handleOnChange = (e) => {
        setDescripcion(e.target.value)
    }

//TODO: INTEGRAR CON LA API LA ASSISTENCIA
    const handleOnClick = () => {
        axios.post('https://findally-backend.herokuapp.com/api/feedback_student/', comentario)
            .then(res => {
                console.log(res)
                toast.success('Feedback enviado correctamente', {
                    position: "top-center",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                })
                setTimeout(handleRedirect, 2500)
            }
            )
            .catch(err => console.log(err))
    }

    const handleRedirect = () => {
        window.location.href = '/unilever/calendario'
    }

    return (
        <div>
            <Sidebar />
            <div className="BodyWeb"
                style={{
                    padding: "0rem 2rem",
                    position: "absolute",
                    right: "0",
                }}
            >

                <Navbar />
                <div className="home-unilever-banner">
                    <BannerUnileverHome />
                </div>
                <div className='form-container-feedback'>
                    <div className='container-feedback'>
                        <h1>Feedback</h1>
                        <hr />
                        <div>
                            <p className='text-feed'>{lang === 'es' ? 'Por favor, tómate unos momentos para responder el siguiente formulario de Evaluación Mentoría'
                                : 'Please take a few moments to answer the following Mentoring Evaluation form.'}</p>
                            {/* <p className='font-bold-estudiante'>{lang === 'es' ? '(Nombre de la mentoría).'
                                : '(Name of the Mentoring)'}</p> */}
                        </div>
                        <div className='puntuacion-card'>
                            <div className='text-puntuacion'>
                                <p className='sub-title-feedback'>{lang === 'es' ? 'Mentoría' : 'Mentoring'}</p>

                                <p>{lang === 'es' ? '¿Qué tan satisfecho estás hoy con tu experiencia en el programa?' : 'How satisfied are you today with your experience in the program?'}</p>
                                <Question1 question1={question1} setQuestion1={setQuestion1} />
                            </div>
                        </div>
                        <div>
                            <p className='sub-title-feedback'>{lang === 'es' ? '¿Se llevo a cabo la sesión?' : 'Was the session held?'}</p>
                            <hr />
                            <div className='checkbox-container'>
                            <FormGroup value={assist}>
                                <FormControlLabel control={<Checkbox  checked={assist}
                                    onChange={(e) => setAssist(e.target.checked)}

                                 />} label="Si" />
                                <FormControlLabel control={<Checkbox checked={!assist}
                                    onChange={(e) => setAssist(!e.target.checked)}
                                />} label="No" />
                            </FormGroup>
                            </div>
                            
                        </div>
                        <div>
                            <p className='sub-title-feedback'>{lang === 'es' ? '¿Qué observaciones, comentarios, dudas o sugerencias tienes?' : 'What observations, comments, doubts or suggestions do you have?'}</p>
                            <hr />

                            <textarea className='feedback-descripcion' placeholder={lang === 'es' ? 'Comentarios del feedback' : 'Feedback comments'} onChange={handleOnChange} />
                            <div className='button-container-feedback'>
                                <button onClick={handleRedirect} className='volver-mentoria'>{lang === 'es' ? 'Volver a las mentorías' : 'Back to mentoring'}</button>
                                <button onClick={handleOnClick} className='button-container-feedback-send' >{lang === 'es' ? 'Enviar' : 'Send'}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}