import React, { useState, useEffect, useContext }  from 'react';
import { useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import axios from "axios";
import {toast} from "react-toastify";
import Dialog from "@mui/material/Dialog";
import {ReduxStateInterface} from "../../../../types/reduxInterface";
import {SchneiderContext} from "../../../../context/SchneiderContext";
import {SelectApplicantButton} from "../../../buttons/ButtonStyles";
import ApplicantCvModalButton from "../../../buttons/cvmodalbutton/ApplicantCvModalButton";
export default function OpportunityApplicantCard({
                                         keynum,
                                         compatibility,
                                         name,
                                         job,
                                         image,
                                         id,
                                         nameEntero,
                                         jobEntero,
                                         LinkedInCv,
                                         generalCv,
                                                   opportunityId
                                       }: any) {
  const { schneider } = useContext(SchneiderContext)
  const { lang } = useSelector((state: ReduxStateInterface) => state)
  const ideproy = useParams<any>().id

  const [generalCvData, setGeneralCvData] = useState(null)
  useEffect(() => {
    if (generalCv) {
      setGeneralCvData(generalCv)
    }
  }, [generalCv])

  const handleContact = async (e: any) => {
    e.preventDefault()
    await axios.post("https://findally-backend.herokuapp.com/api/applicants/contact/coallier", {
      proyecto: ideproy,
      usuarios: [id]
    }).then(res => {
      console.log(res)
      return window.location.reload()
    }).catch(err => {
      console.log(err)
    })
  }

  return (
    <div className={'aplicantes-card-glo-cont'}>
      <div className={'aplic-card-glob-sub-cont'}>
        {/*img*/}
        <div className={'aplic-card-info-subcont-2'}>
          <div className={keynum === 1 ? 'blob-cont-1' : keynum === 2 ? 'blob-cont-2' : 'blob-cont-3'}>
            <h2
              className={'aplic-blob-num'}>{keynum === 1 ? lang === 'es' ? '1er' : '1st' : keynum === 2 ? lang === 'es' ? '2do' : '2nd' : lang === 'es' ? '3er' : '3rd'}</h2>
          </div>
          <div
            className={/*userPlan?.nombre_paquete === 'Paquete Free' ? 'aplic-card-img-filtro-2 ' : */'aplic-card-img-filtro'}>
            <img className={'aplic-card-img'}
                 src={image ? image : 'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png'}
                 alt={'profile-pic'} />
          </div>
        </div>
        {/*info*/}
        <div className={'aplic-card-info-subcont'}>

          <Link to={`/${schneider.route}/company/applicant/${id}?op=${opportunityId}`}
                className={'aplic-card-name'}>{/*userPlan?.nombre_paquete !== 'Paquete Free' ? */name /*: lang === 'es' ? `Aplicante ${keynum}` : `Applicant'${keynum}`*/}
          </Link>
          <Link to={`/${schneider.route}/company/applicant/${id}?op=${opportunityId}`}
                className={'aplic-card-name-ent'}>{/*userPlan?.nombre_paquete !== 'Paquete Free' ?*/ nameEntero /*: lang === 'es' ? `Aplicante ${keynum}` : `Applicant'${keynum}`*/}
          </Link>
          <h2 className={'aplic-card-job'}>{job}</h2>
          <h2 className={'aplic-card-job-ent'}>{jobEntero}</h2>

          <div className={'aplic-card-cont'}>
            {/*<img src={Advise} alt={'advise-icon'}/>*/}
            <h2 className={'aplic-card-compat'}>
              {lang === 'es' ? `${compatibility} Compatibilidad` : `${compatibility} Compatibility`}
            </h2>
          </div>
        </div>
      </div>
      <div className={'aplic-buttons-cont'}>
        <button className={'aplic-card-ver-perfil-cont'}>
          <OcultarButton userId={id} />
        </button>
        <ApplicantCvModalButton idUser={id} LinkedInCv={LinkedInCv} generalCv={generalCvData} />
      </div>
      <div className={'foot-but-cont'}>
        <SelectApplicantButton onClick={(e) => handleContact(e)} color={schneider.color.primary}>{lang === 'es' ? 'Seleccionar' : 'Select'}</SelectApplicantButton>
      </div>
    </div>
  )
}

export const OcultarButton = ({ userId }: any) => {
  const { schneider } = useContext(SchneiderContext)
  const [diaglog, setDialog] = useState(false)
  const { lang } = useSelector((state: ReduxStateInterface) => state)
  const projectId = useParams<any>().id
  const [feedback, setFeedback] = useState('')
  const ocultHandler = async (e: any) => {
    e.preventDefault()
    console.log("userId", userId, "project", projectId, feedback)
    await axios.post("https://findally-backend.herokuapp.com/api/applicants/hide", {
      userId,
      projectId,
      feedback
    }).then(res => {
      toast.success(lang === 'es' ? 'Aplicante descartado' : 'Applicant discarted', {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
      console.log(res)
      return window.location.reload()
    }).catch(err => {
      toast.error(lang === 'es' ? 'hubo un error descartando al aplicante' : 'There was an error discarting the applicant', {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
      console.log(err)
    })
  }

  return (
    <div>
      <Dialog open={diaglog} onClose={() => setDialog(false)}>
        <div style={{
          padding: '20px',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}>
          <div style={{
            width: '100%',
            paddingBottom: '10px',
          }}>
            <button style={{ backgroundColor: "transparent" }} onClick={() => setDialog(false)}>
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M17.6574 17.6568L6.34367 6.34309" stroke={schneider.color.primary} strokeLinecap="round" />
                <path d="M17.6563 6.34309L6.34262 17.6568" stroke={schneider.color.primary}  strokeLinecap="round" />
              </svg>
            </button>
          </div>
          <h2 style={{
            fontWeight: '700',
            fontSize: '24px',
            textAlign: 'center',
          }}>¿Estás seguro de que quieres descartar este perfil?</h2>
          <textarea style={{
            width: '100%',
            height: '100px',
            marginTop: '20px',
            marginBottom: '20px',
            padding: '10px',
            borderRadius: '10px',
            border: `1px solid ${schneider.color.primary}`,
            resize: "vertical",
          }}
                    onChange={(e) => setFeedback(e.target.value)}
                    placeholder={lang === 'es' ? 'Escribe un mensaje para el aplicante' : 'Write a message to the applicant'} />
          <div style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
          }}>
            <button style={{
              backgroundColor: "transparent",
              border: `1px solid ${schneider.color.primary}`,
              borderRadius: '5px',
              color: schneider.color.primary,
              width: '48%',
              height: '40px',
            }} onClick={() => setDialog(false)}>Cancelar
            </button>
            <button style={{
              backgroundColor: schneider.color.primary,
              border: `1px solid ${schneider.color.primary}`,
              borderRadius: '5px',
              color: 'white',
              width: '48%',
              height: '40px',
            }} onClick={(e) => ocultHandler(e)}>Aceptar
            </button>
          </div>
        </div>
      </Dialog>
      <button onClick={() => setDialog(true)} style={{ backgroundColor: "transparent" }}
              className={'aplic-card-ver-perfil'}>
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g id="bin">
            <path id="Path 2" d="M19 6H5" stroke={schneider.color.primary}  strokeLinecap="round" />
            <path id="Path 2_2" d="M14 5H10" stroke={schneider.color.primary}  strokeLinecap="round" />
            <path id="Path 8" d="M6 10V21H18C18 20 18 10 18 10" stroke={schneider.color.primary}  strokeLinecap="round" />
          </g>
        </svg>
      </button>
    </div>
  )
}
