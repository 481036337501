import React, {useState} from 'react';
import './precourseModalCard.css'
import cartaMotivacionIngles from '../administracion/files/cv/CARTA DE MOTIVACION EN INGLES.docx'
import cartaMotivacioExperiencia from '../administracion/files/cv/FORMATO CARTA DE MOTIVACION CON EXPERIENCIA.docx'
import cartaMotivacionSinExperiencia from '../administracion/files/cv/FORMATO CARTA DE MOTIVACION SIN EXPERIENCIA.docx'
import formatoHojadeVida from '../administracion/files/cv/FORMATO CV ATS.docx'
import manualHojadeVida from '../administracion/files/cv/Manual Hoja de vida.pdf'
import analisisCamePersonal
  from '../administracion/files/perfilamiento/ANALISIS CAME PERSONAL PARA LA EMPLEABILIDAD.xlsx'
import DOFAPersonal from '../administracion/files/perfilamiento/DOFA PERSONAL DE EMPLEABILIDAD.xlsx'
import estrategiadeConexion from '../administracion/files/perfilamiento/ESTRATEGIA  DE CONEXION LINKEDIN.xlsx'
import DownloadIcon from '../../../assets/icons/download-icon.svg'
import axios from "axios";
import {useSelector} from "react-redux";

const selectFile = (file) => {
  switch (file) {
    case 'Motivacion 1':
      return cartaMotivacionIngles
    case 'Motivacion 2':
      return cartaMotivacioExperiencia
    case 'Motivacion 3':
      return cartaMotivacionSinExperiencia
    case 'Formato hoja de vida':
      return formatoHojadeVida
    case 'Manual Hoja de vida':
      return manualHojadeVida
    case 'analisis came personal':
      return analisisCamePersonal
    case 'DOFA personal':
      return DOFAPersonal
    case 'Estrategia de conexión':
      return estrategiadeConexion
    default:
      return ''
  }
}

const PrecourseModalCard = ({title, video, image, file}) => {
  const {user} = useSelector((state) => state);
  const [form] = useState({
    usuario: user.id,
    buttonName: title,
  })
  return (
    <div className={'bootcamp-modal-precourses-card-container'}>
      {image?.length ?
        <div className={'bootcamp-modal-precourses-card-image-container'}>
          <img src={image} alt={title} className={'bootcamp-modal-precourses-card-image'}/>
        </div>
        : <div className={'bootcamp-modal-precourses-card-image-container'}>
          <img src={DownloadIcon} alt={title} className={'bootcamp-modal-precourses-card-image-2'}/>
        </div>}
      <div className={'bootcamp-modal-precourses-card-title-container'}>
        <h1 className={'bootcamp-modal-precourses-card-title'}>{title}</h1>
      </div>
      {video?.length ? <div className={'bootcamp-modal-precourses-card-video-container'}>
        <a className={'modal-precourse-card-anchor-video'} href={video} target={'_blank'}
           rel="noopener noreferrer">
          <button
            onClick={() => {
              axios.post('https://findally-backend.herokuapp.com/api/metricsBootcamp/SaveClick', form)
                .catch(err => console.log(err))
            }}
            className={'bootcamp-modal-precourses-card-video-button'}>Ver video
          </button>
        </a>
      </div> : null}
      {
        file?.length ?
          <div className={'bootcamp-modal-precourses-card-file-container'}>
            <a className={'modal-precourse-card-anchor-file'} href={selectFile(file)} target={'_blank'} download
               rel={"noopener noreferrer"}>
              <button
                onClick={() => {
                  axios.post('https://findally-backend.herokuapp.com/api/metricsBootcamp/SaveClick', form)
                    .catch(err => console.log(err))
                }}
                className={'bootcamp-modal-precourses-card-file-button'}>Descargar archivo</button>
            </a>
          </div> : null
      }
    </div>
  )
}

export default PrecourseModalCard
