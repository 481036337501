/*eslint-disable*/
import image1 from '../../../assets/imagenes/unilever-card-1.png';
import image2 from '../../../assets/imagenes/unilever-card-2.png';
import image3 from '../../../assets/imagenes/unilever-card-3.png';
import image4 from '../../../assets/imagenes/unilever-card-4.png';
import mentorToolkit from '../files/mentor-toolkit-img.png';
import mentorEquipaje from '../files/mentor-equipaje.png';
import mapaSuenos from '../files/mapa-sueños.png';
import tallerSuenos from '../files/taller-sueños.png';


export const CardDataEspMentores = [
  {
    id: 1,
    title: 'Educación financiera',
    description: 'Aprende a manejar tus finanzas para que logres tus objetivos.',
    image: 'https://www.bancolombia.com/wcm/connect/www.bancolombia.com-26918/a51d975e-ba8c-4617-acfa-6f6fb7e30bf3/Mercado+en+Colombia.jpg?MOD=AJPERES&amp;CACHEID=ROOTWORKSPACE.Z18_K9HC1202P864E0Q30449MS3000-a51d975e-ba8c-4617-acfa-6f6fb7e30bf3-od7eOO2',
  },
  {
    id: 2,
    title: 'Finanzas personales',
    description: 'Las finanzas en la cotidianidad',
    image: 'https://www.bancolombia.com/wcm/connect/www.bancolombia.com-26918/1c35fd1e-b212-4629-9fe2-0cdbe3b76381/Tasas+de+Inter%C3%A9s.jpg?MOD=AJPERES&amp;CACHEID=ROOTWORKSPACE.Z18_K9HC1202P864E0Q30449MS3000-1c35fd1e-b212-4629-9fe2-0cdbe3b76381-od7esH0',
  },
  {
    id: 3,
    title: '¿Cómo funciona el Mercado Cambiario en Colombia?',
    description: 'El mercado cambiario en Colombia puede ser confuso, pero aquí te contamos todo lo que debes saber para dominarlo.',
    image: 'https://www.bancolombia.com/wcm/connect/www.bancolombia.com-26918/a51d975e-ba8c-4617-acfa-6f6fb7e30bf3/Mercado+en+Colombia.jpg?MOD=AJPERES&amp;CACHEID=ROOTWORKSPACE.Z18_K9HC1202P864E0Q30449MS3000-a51d975e-ba8c-4617-acfa-6f6fb7e30bf3-od7eOO2',
  },
  {
    id: 4,
    title: '¿Por qué suben y bajan las tasas de interés?',
    description: 'Aquí te contamos por qué suben o bajan las tasas de interés. Aprende junto a nosotros en este nuevo artículo',
    image: 'https://www.bancolombia.com/wcm/connect/www.bancolombia.com-26918/1c35fd1e-b212-4629-9fe2-0cdbe3b76381/Tasas+de+Inter%C3%A9s.jpg?MOD=AJPERES&amp;CACHEID=ROOTWORKSPACE.Z18_K9HC1202P864E0Q30449MS3000-1c35fd1e-b212-4629-9fe2-0cdbe3b76381-od7esH0',
  },
]

export const CardDataEng = [
  {
    id: 1,
    title: 'Educación financiera',
    description: 'Aprende a manejar tus finanzas para que logres tus objetivos.',
    image: 'https://www.bancolombia.com/wcm/connect/www.bancolombia.com-26918/a51d975e-ba8c-4617-acfa-6f6fb7e30bf3/Mercado+en+Colombia.jpg?MOD=AJPERES&amp;CACHEID=ROOTWORKSPACE.Z18_K9HC1202P864E0Q30449MS3000-a51d975e-ba8c-4617-acfa-6f6fb7e30bf3-od7eOO2',
  },
  {
    id: 2,
    title: 'Finanzas personales',
    description: 'Las finanzas en la cotidianidad',
    image: 'https://www.bancolombia.com/wcm/connect/www.bancolombia.com-26918/1c35fd1e-b212-4629-9fe2-0cdbe3b76381/Tasas+de+Inter%C3%A9s.jpg?MOD=AJPERES&amp;CACHEID=ROOTWORKSPACE.Z18_K9HC1202P864E0Q30449MS3000-1c35fd1e-b212-4629-9fe2-0cdbe3b76381-od7esH0',
  },
  {
    id: 3,
    title: '¿Cómo funciona el Mercado Cambiario en Colombia?',
    description: 'El mercado cambiario en Colombia puede ser confuso, pero aquí te contamos todo lo que debes saber para dominarlo.',
    image: 'https://www.bancolombia.com/wcm/connect/www.bancolombia.com-26918/a51d975e-ba8c-4617-acfa-6f6fb7e30bf3/Mercado+en+Colombia.jpg?MOD=AJPERES&amp;CACHEID=ROOTWORKSPACE.Z18_K9HC1202P864E0Q30449MS3000-a51d975e-ba8c-4617-acfa-6f6fb7e30bf3-od7eOO2',
  },
  {
    id: 4,
    title: '¿Por qué suben y bajan las tasas de interés?',
    description: 'Aquí te contamos por qué suben o bajan las tasas de interés. Aprende junto a nosotros en este nuevo artículo',
    image: 'https://www.bancolombia.com/wcm/connect/www.bancolombia.com-26918/1c35fd1e-b212-4629-9fe2-0cdbe3b76381/Tasas+de+Inter%C3%A9s.jpg?MOD=AJPERES&amp;CACHEID=ROOTWORKSPACE.Z18_K9HC1202P864E0Q30449MS3000-1c35fd1e-b212-4629-9fe2-0cdbe3b76381-od7esH0',
  },
]