import React, { useState } from 'react'
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import ReactPlayer from 'react-player'

const TutorialCvLinkedin = ({lang}) => {
  const [openTutorial, setOpenTutorial] = useState(false)

  const handleOpenTutorial = () => {
    setOpenTutorial(true);
  };

  const handleCloseTutorial = () => {
    setOpenTutorial(false);
  };

  const style = { 
    position: "absolute",
    flexDirection: 'column',    
    zIndex: 10,
    transform: "translate(-50%, -50%)",
    width: "80%",
    height:   "50%",
    top: "50%",
    left: "50%",
  };

  return (
    <>
      <button
        className="perfil-tutorial-button"
        onClick={handleOpenTutorial}>
        {lang === 'es' ? 'Tutorial descarga Cv LinkedIn' : 'Tutorial download Cv LinkedIn'}
      </button>
      <Modal
        open={openTutorial}
        onClose={handleCloseTutorial}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <Box
          sx={style}
        >
          <ReactPlayer
            url={"https://youtu.be/9NIF-vD-zvI"}
            controls={true}
            width={"100%"}
            height={"100%"}
          />
        </Box>
      </Modal>
    </>
  )
}

export default TutorialCvLinkedin