import React, {useEffect} from 'react';
import FormControlLabel from '@mui/material/FormControlLabel';
import CircleIcon from '@mui/icons-material/Circle';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Typography from '@mui/material/Typography';
import './CrearOportunidadNew.css'
import {useDispatch} from "react-redux";
import { saveOportunityForm } from '../../actions';

export default function Anonimo(props) {
  const {form, step, handleStep, handleBackStep, lang, oportunityForm, handleForm} = props;
  const [button, setButton] = React.useState(true);
  const dispatch = useDispatch();
  useEffect(() => {
    if (oportunityForm?.Anonimo ? oportunityForm?.Anonimo !== '' : form?.Anonimo !== '') {
      setButton(false)
    }
    //eslint-disable-next-line
  }, [form.Anonimo])

  const handleChange = (event) => {
    handleForm({...form, Anonimo: event.target.value});
    dispatch(saveOportunityForm({Anonimo: event.target.value}, step));
    setButton(false)
  }
  return (
    <>
      <div className='contenedorSetAnonimo'>
        <h3
          className='contenedorSetAnonimo_title'>{lang === 'es' ? '¿Deseas publicar de forma anónima?' : 'Do you want to post anonymously?'}</h3>
        <div>
          <RadioGroup
            aria-labelledby="demo-controlled-radio-buttons-group"
            name="tipoDeServicioDeseado"
            className='setAnonimoRadio'
            value={oportunityForm?.Anonimo ? oportunityForm?.Anonimo : form?.Anonimo}
          >
            <FormControlLabel name="Anonimo" value="Si"
                              control={<Radio icon={<CircleIcon className='radio_unchecked_icon'/>}
                                              checkedIcon={<CircleIcon className='radio_checked_icon'/>}/>}
                              onChange={handleChange}
                              label={<Typography sx={{fontSize: '14px', fontFamily: 'Poppins', fontWeight: '400'}}>
                                {lang === 'es' ? 'Sí' : 'Yes'}
                              </Typography>}/>

            <FormControlLabel value="No" name="Anonimo" onChange={handleChange}
                              control={<Radio icon={<CircleIcon className='radio_unchecked_icon'/>}
                                              checkedIcon={<CircleIcon className='radio_checked_icon'/>}/>}
                              label={<Typography sx={{fontSize: '14px', fontFamily: 'Poppins', fontWeight: '400'}}>
                                {lang === 'es' ? 'No' : 'No'}
                              </Typography>}/>
          </RadioGroup>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          width: "100%",
          justifyContent: "space-between",
          padding: "1rem 3rem 0rem 3rem",
          alignItems: "center",
        }}
      >
        <button className='button_siguiente_create_B'
                onClick={handleBackStep}>{lang === 'es' ? 'Anterior' : 'Previous'}</button>
        <h3 className='stepCounter'>{`${step}/14`}</h3>
        <button className='button_siguiente_create'
                disabled={button} onClick={() => handleStep('Anonimo')}>{lang === 'es' ? 'Siguiente' : 'Next'}</button>
      </div>
    </>
  )
}