
export interface video {
  id: number;
  title: string;
  title_en: string;
  title2?: string;
  title2_en?: string;
  duration?: string;
  duration2?: string;
  subModules?: subModule[];
  subModules2?: subModule[];
}

export interface subModule {
  title: string;
  title_en?: string;
  description?: string;
  url: string;
}

const video1: video = {
  id: 1,
  title: 'Agropecuario',
  title_en: 'Agricultural',
  duration: '11 Módulos',
  subModules: [
      {
          title: 'Agropecuaria y seguridad alimentaria',
          title_en: 'Agriculture and food security',
          url: 'https://www.youtube.com/watch?v=n18i6wWptx0',
      },
      {
          title: 'Productos agropecuarios de exportacion',
          title_en: 'Agricultural products for export',
          url: 'https://www.youtube.com/watch?v=dV_pfC_ZUvs'
      },
      {
          title: 'Exportaciones agroindustriales',
          title_en: 'Agricultural products for export',
          url: 'https://www.youtube.com/watch?v=NFeevFHGISw'
      },{
          title: 'Transformación del sistema de alimentos global',
          title_en: 'Transformation of the global food system',
          url: 'https://es.coursera.org/learn/transformation-global-food-system'
      },{
          title: 'Empresarios del campo en Colombia',
          title_en: 'entrepreneurs in the field in Colombia',
          url: 'https://www.youtube.com/watch?v=McIFmb0Ra7A'
      },{
          title: 'Impacto de la inflación en el sector',
          title_en: 'Impact of inflation in the sector',
          url: 'https://www.youtube.com/watch?v=snf0GbdO31I'
      },{
          title: 'Sostenibilidad en la agricultura',
          title_en: 'Sustainability in agriculture',
          url: 'https://es.coursera.org/learn/sustainable-agriculture'
      },{
          title: 'Retos en el negocio de agricultura',
          title_en: 'Challenges in the agriculture business',
          url: 'https://es.coursera.org/learn/agribusiness-management-challenges'
      },{
          title: 'Agricultura, economia y naturaleza',
          title_en: 'Agriculture, economy and nature',
          url: 'https://es.coursera.org/learn/agriculture-economics-nature'
      },{
          title: 'Economia de la agro-comida',
          title_en: 'Agricultural food economy',
          url: 'https://es.coursera.org/learn/valuechains'
      },{
          title: 'Innovación del sector agro',
          title_en: 'Innovation in the agricultural sector',
          url: 'https://www.youtube.com/watch?v=zXz7H_rtdbU1'
      },
  ]
}

const video2: video = {
  id: 2,
  title: 'Industrial',
  title_en: 'Industrial',
  duration: '12 Módulos',
  subModules: [
      {
          title: 'Innovación del sector agroindustrial',
          title_en: 'Innovation in the agricultural sector',
          url: 'https://www.youtube.com/watch?v=zXz7H_rtdbU',
      },
      {
          title: 'Ingeniero industrial en el sector publico',
          title_en: 'Industrial engineer in the public sector',
          url: 'https://www.youtube.com/watch?v=CgVfDdjdscA'
      },
      {
          title: 'Gas natural y sector industrial',
          title_en: 'Natural gas and industrial sector',
          url: 'https://www.youtube.com/watch?v=D1EUW5rHCdQ'
      },{
          title: 'Beneficios tributarios en el sector industrial',
          title_en: 'Benefits of the industrial sector',
          url: 'https://www.youtube.com/watch?v=eVFn-DVTdGk'
      },{
          title: 'Automatización de procesos para el sector industrial',
          title_en: 'Automation of processes for the industrial sector',
          url: 'https://www.youtube.com/watch?v=iii2iRCam3g'
      },{
          title: 'Transformacion digital de las industrias',
          title_en: 'Digital transformation of industries',
          url: 'https://www.youtube.com/watch?v=_dKjGXIamrQ'
      },{
          title: 'Industria 4.0',
          title_en: 'Industry 4.0',
          url: 'https://www.youtube.com/watch?v=V1T7A96xGR8'
      },{
          title: 'Data science en la industria',
          title_en: 'Data science in the industry',
          url: 'https://www.youtube.com/watch?v=JOR7bzvXp-0'
      },{
          title: 'Proyectos de Ingenieria',
          title_en: 'Engineering projects',
          url: 'https://es.coursera.org/learn/major-engineering-projects'
      },{
          title: 'Impacto y transformación de la industria',
          title_en: 'Impact and transformation of the industry',
          url: 'https://es.coursera.org/learn/esg-investing-industry-impacts-transformations'
      },{
          title: 'Big data',
          title_en: 'Big data',
          url: 'https://es.coursera.org/learn/adquisicion-almacenamiento-de-datos'
      },{
          title: 'Construcción de la industria',
          title_en: 'Construction of the industry',
          url: 'https://es.coursera.org/learn/construction-industry-forward'
      }
  ]
}

const video3: video = {
  id: 3,
  title: 'Educativo',
  title_en: 'Education',
  duration: '10 Módulos',
  subModules: [
      {
          title: 'Sistema educativo en Colombia',
          title_en: 'Educational system in Colombia',
          url: 'https://www.youtube.com/watch?v=lTNsgmBix0M',
      },
      {
          title: 'Educación y tecnología en Colombia',
          title_en: 'Education and technology in Colombia',
          url: 'https://www.youtube.com/watch?v=6LKldc15gBQ'
      },
      {
          title: 'Instituciones ágiles y digitalizadas',
          title_en: 'Agile and digitized institutions',
          url: 'https://www.youtube.com/watch?v=SqeYNFrFTAs'
      },{
          title: 'Transformación digital del sector educativo',
          title_en: 'Digital transformation of the educational sector',
          url: 'https://www.youtube.com/watch?v=GvALfSUIHUg'
      },{
          title: 'Mercadeo para el sector educativo',
          title_en: 'Marketing for the educational sector',
          url: 'https://www.youtube.com/watch?v=HmMN0E7Q_WI'
      },{
          title: 'Diversidad e inclusión en la educación',
          title_en: 'Diversity and inclusion in education',
          url: 'https://es.coursera.org/learn/diversity-and-inclusion-education'
      },{
          title: 'Futuro de la educación',
          title_en: 'Future of education',
          url: 'https://es.coursera.org/learn/future-education'
      },{
          title: 'Inteligencia Artificial para profesores',
          title_en: 'Artificial Intelligence for Teachers',
          url: 'https://es.coursera.org/learn/artificial-intelligence-education-for-teachers'
      },{
          title: 'Administración educativa',
          title_en: 'Educational administration',
          url: 'https://www.youtube.com/watch?v=kGO-oV0upEE'
      },{
          title: 'Inclusión en educación',
          title_en: 'Inclusion in education',
          url: 'https://es.coursera.org/learn/disability-inclusion-education'
      }
  ]
}

const video4: video = {
  id: 4,
  title: 'Diseño gráfico / web',
  title_en: 'Graphic design / web',
  duration: '14 Módulos',
  subModules: [
      {
          title: 'Principios de diseño',
          title_en: 'Design principles',
          url: 'https://www.youtube.com/watch?v=4fUbj8DzT6Q',
      },
      {
          title: 'Branding',
          title_en: 'Branding',
          url: 'https://www.youtube.com/watch?v=X_84aTyzBlQ'
      },
      {
          title: 'Caracteristicas de un logo',
          title_en: 'Characteristics of a logo',
          url: 'https://www.youtube.com/watch?v=blsrqSEsJAA'
      },
      {
          title: 'Paleta de colores',
          title_en: 'Color palette',
          url: 'https://www.youtube.com/watch?v=QWuh5SkHT-A'
      },
      {
          title: 'Psicologia del color',
          title_en: 'Color psychology',
          url: 'https://www.youtube.com/watch?v=-kP-p_42SoA'
      },
      {
          title: 'Teoria de la gestalt',
          title_en: 'Gestalt theory',
          url: 'https://www.youtube.com/watch?v=UnenlTAGuFg'
      },
      {
          title: 'Trucos de diseño',
          title_en: 'Design tricks',
          url: 'https://www.youtube.com/watch?v=DPuaiKu0f60'
      },
      {
          title: 'Edicion con capcut',
          title_en: 'Editing with capcut',
          url: 'https://www.youtube.com/watch?v=tsjbsOiYHKA'
      },
      {
          title: 'Tipografias esenciales',
          title_en: 'Essential fonts',
          url: 'https://www.youtube.com/watch?v=POeQZvzFf8A'
      },
      {
          title: 'Presentación de marca',
          title_en: 'Brand presentation',
          url: 'https://www.youtube.com/watch?v=2jmZeLlaCDc'
      },
      {
          title: 'Proceso creativo',
          title_en: 'Creative process',
          url: 'https://www.coursera.org/specializations/branding-the-creative-journey'
      },
      {
          title: 'Técnicas de persuasión',
          title_en: 'Persuasion techniques',
          url: 'https://www.coursera.org/learn/como-persuadir'
      },
      {
          title: 'Introducción a image making',
          title_en: 'Introduction to image making',
          url: 'https://www.coursera.org/learn/image-making'
      },
      {
          title: 'Diseño UX / UI',
          title_en: 'UX / UI design',
          url: 'https://www.coursera.org/specializations/ui-ux-design'
      }
  ],
  duration2: '9 Módulos',
  title2: 'Desarrollo web',
  title2_en: 'Web development',
  subModules2: [{
      title: 'HTML, CSS y Javascript',
      title_en: 'HTML, CSS and Javascript',
      url: 'https://www.coursera.org/learn/html-css-javascript-for-web-developers'
  },{
      title: 'Desarrollo web IBM',
      title_en: 'IBM web development',
      url: 'https://www.coursera.org/professional-certificates/ibm-full-stack-cloud-developer'
  },{
      title: 'Front end',
      title_en: 'Front end',
      url: 'https://www.coursera.org/professional-certificates/meta-front-end-developer'
  },{
      title: 'Back end',
      title_en: 'Back end',
      url: 'https://www.coursera.org/professional-certificates/meta-back-end-developer'
  },{
      title: 'Introduccion al desarrollo web',
      title_en: 'Introduction to web development',
      url: 'https://www.coursera.org/learn/web-development'
  },{
      title: 'Introduccion al desarrollo web II',
      title_en: 'Introduction to web development II',
      url: 'https://www.coursera.org/specializations/web-design'
  },{
      title: 'Diseño responsivo',
      title_en: 'Responsive design',
      url: 'https://www.coursera.org/specializations/website-development'
  },{
      title: 'Crea una página web con HTML y CSS',
      title_en: 'Create a web page with HTML and CSS',
      url: 'https://www.youtube.com/watch?v=HH_SMpxV7qQ'
  },{
      title: 'DOM de Javascript',
      title_en: 'Javascript DOM',
      url: 'https://www.freecodecamp.org/espanol/news/el-dom-de-javascript-un-tutorial-practico/'
  },]
}

const video5: video = {
  id: 5,
  title: 'Finanzas',
  title_en: 'Finance',
  duration: '10 Módulos',
  subModules: [
      {
          title: 'Indicadores económicos',
          title_en: 'Economic indicators',
          url: 'https://www.youtube.com/watch?v=rEH_cJaCOT8',
      },{
          title: 'Mercados financieros',
          title_en: 'Financial markets',
          url: 'https://www.coursera.org/learn/financial-markets-global',
      },{
          title: 'Finanzas corporativas',
          title_en: 'Corporate finance',
          url: 'https://www.coursera.org/learn/wharton-finance',
      },{
          title: 'Indicadores económicos',
          title_en: 'Economic indicators',
          url: 'https://www.youtube.com/watch?v=rEH_cJaCOT8',
      },{
          title: 'Fundamentos de finanzas empresariales',
          title_en: 'Fundamentals of corporate finance',
          url: 'https://www.coursera.org/learn/finanzas-empresariales',
      },{
          title: 'Analitica financiera',
          title_en: 'Financial analytics',
          url: 'https://www.coursera.org/learn/python-statistics-financial-analysis',
      },{
          title: 'Modelado cuantitativo',
          title_en: 'Quantitative modeling',
          url: 'https://www.coursera.org/specializations/finance-quantitative-modeling-analysts',
      },{
          title: 'Modelado financiero',
          title_en: 'Financial modeling',
          url: 'https://www.coursera.org/specializations/wharton-business-financial-modeling',
      },{
          title: 'Finanzas personales',
          title_en: 'Personal finance',
          url: 'https://www.coursera.org/learn/finanzas-personales',
      },{
          title: 'Gestion de inversiones',
          title_en: 'Investment management',
          url: 'https://www.coursera.org/specializations/investment-management',
      },
],
  title2: 'Marketing',
  title2_en: 'Marketing',
  duration2: '11 Módulos',
  subModules2: [{
      title: 'Marketing para ecommerce',
      title_en: 'Marketing for ecommerce',
      url: 'https://www.coursera.org/professional-certificates/google-digital-marketing-ecommerce',
  },{
      title: 'Social media',
      title_en: 'Social media',
      url: 'https://www.coursera.org/professional-certificates/facebook-social-media-marketing',
  },{
      title: 'Introduccion al marketing',
      title_en: 'Introduction to marketing',
      url: 'https://www.coursera.org/learn/wharton-marketing',
  },{
      title: 'Fundamentos de marketing',
      title_en: 'Marketing fundamentals',
      url: 'https://www.coursera.org/learn/foundations-of-digital-marketing-and-e-commerce',
  },{
      title: 'Estrategias de mercadeo',
      title_en: 'Marketing strategies',
      url: 'https://www.coursera.org/specializations/marketing-strategy',
  },{
      title: 'Estrategias de negocio y mercadeo',
      title_en: 'Business and marketing strategies',
      url: 'https://www.coursera.org/specializations/uol-business-and-marketing-strategies',
  },{
      title: 'Data analytics',
      title_en: 'Data analytics',
      url: 'https://www.coursera.org/professional-certificates/google-data-analytics',
  },{
      title: 'Engagement de clientes',
      title_en: 'Customer engagement',
      url: 'https://www.coursera.org/learn/attract-and-engage-customers',
  },{
      title: 'Marketing en tik tok',
      title_en: 'Marketing on tik tok',
      url: 'https://www.coursera.org/specializations/marketing-with-tiktok',
  },{
      title: 'Marketing para retail',
      title_en: 'Marketing for retail',
      url: 'https://www.coursera.org/learn/wharton-retail-marketing-strategy',
  },{
      title: 'Evolucion del marketing',
      title_en: 'Marketing evolution',
      url: 'https://www.youtube.com/watch?v=QyrL-K8AUuU',
  }]
}

const video6: video = {
  id: 6,
  title: 'Desarrollo de producto',
  title_en: 'Product development',
  duration: '14 Módulos',
  subModules: [{
      title: 'Product marketing',
      title_en: 'Product marketing',
      url: 'https://www.youtube.com/watch?v=ewfXd-jUOrA',
  },{
      title: 'Construir un producto',
      title_en: 'Build a product',
      url: 'https://www.youtube.com/watch?v=6IFR3WYSBFM&pp=ugMICgJlcxABGAE%3D',
  },{
      title: 'Ideacion, diseño y gestión',
      title_en: 'Ideation, design and management',
      url: 'coursera.org/specializations/product-ideation-design-and-management',
  },{
      title: 'Introduccion al product management',
      title_en: 'Introduction to product management',
      url: 'https://www.youtube.com/watch?v=XfQGeK34vvU',
  },{
      title: 'Creative thinking',
      title_en: 'Creative thinking',
      url: 'https://www.coursera.org/learn/creative-thinking-techniques-and-tools-for-success',
  },{
      title: 'Digital product management',
      title_en: 'Digital product management',
      url: 'https://www.coursera.org/learn/uva-darden-digital-product-management',
  },{
      title: 'Mapa de empatia para productos',
      title_en: 'Empathy map for products',
      url: 'https://www.coursera.org/projects/product-empathy',
  },{
      title: 'Metodologia Lean',
      title_en: 'Lean methodology',
      url: 'https://www.youtube.com/watch?v=laEeulu4ynY',
  },{
      title: 'Desarrollo de nuevos productos',
      title_en: 'New product development',
      url: 'https://www.coursera.org/learn/new-product-development',
  },{
      title: 'Gestion de productos de software',
      title_en: 'Software product management',
      url: 'https://www.coursera.org/specializations/product-management',
  },{
      title: 'Productos de IA',
      title_en: 'AI products',
      url: 'https://www.coursera.org/specializations/ai-product-management-duke',
  },{
      title: 'Diseño de producto en Miro',
      title_en: 'Product design in Miro',
      url: 'https://www.coursera.org/projects/create-a-product-design-brainstorming-with-miro',
  },{
      title: 'Esenciales de gestion de producto',
      title_en: 'Product management essentials',
      url: 'https://www.coursera.org/learn/product-management-essentials',
  },{
      title: 'Estrategia de producto',
      title_en: 'Product strategy',
      url: 'https://www.coursera.org/projects/product-strategy',
  },]
}

export function videoSwitcher(video: string){
  switch (video) {
      case 'agriculture':
          return video1;
      case 'industrial':
          return video2;
      case 'educational':
          return video3;
      case 'design':
          return video4;
      case 'financial':
          return video5;
          case 'product':
          return video6;
  }
}


