import "./Styles/CompaniesCreateProject.css";
import { CreateUnregisterProject } from "../../actions/index";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Paper from "@material-ui/core/Paper";
import Divider from "@material-ui/core/Divider";
import StepForm from "../../components/UnregisterProyectForm/UnregisterProjectForm";
import { connect, } from "react-redux";
import en from "../../lang/en-US";
import es from "../../lang/es-COL"
import counterpart from "counterpart";
import Translate from "react-translate-component";

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
    paddingRight: 10,
    paddingLeft: 10
  },
  svg: {
    verticalAlign: "middle"
  },
  layout: {
    width: "auto",
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
      width: 600,
      marginLeft: "auto",
      marginRight: "auto"
    }
  },
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(8),
      marginBottom: theme.spacing(8),
      padding: theme.spacing(3)
    }
  }
}));



function VerticalLinearStepper(props) {

  const classes = useStyles();
  counterpart.registerTranslations('en',en) 
      counterpart.registerTranslations('es',es)
      counterpart.setLocale(localStorage.getItem('langlocal'));
  return (
    <>

      <div className="section-tittle-out-form">
        <h2 className="tittle-out-form">{<Translate content="Publicarproyecto" component="span"/>}</h2>
      </div>
      <div className="CompanyForm">
        <section className="CompanyForm-container">
          <div className="App">
            <CssBaseline />
            <main className={classes.layout}>
              <Paper className={classes.paper}>
                <StepForm />
              </Paper>
              <Divider style={{ marginTop: 100 }} />

            </main>
          </div>

        </section>
      </div>
    </>
  );
}

const mapDispatchToProps = {
  CreateUnregisterProject,
};
const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(VerticalLinearStepper);
