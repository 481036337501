import React, {useEffect} from 'react'
import axios from "axios";
import ReactPaginate from "react-paginate";
import '../../../src/profesionalpag/oportunidad/OportunidadesNewLinked.css'
import {toast} from "react-toastify";
// http://localhost:3001/consultor/bootcamp/dashboard
export default function BootcampAdmin() {
  const [usersData, setUsersData] = React.useState([])
  const [filteredUsersData, setFilteredUsersData] = React.useState([])
  const [cantidad, setCantidad] = React.useState(0)
  const [pagina, setPagina] = React.useState(0)

  const [fechaInicio, setFechaInicio] = React.useState('')
  const [fechaFin, setFechaFin] = React.useState('')

  const handleFechaInicio = (e) => {
    setFechaInicio(e.target.value)
  }

  const handleFechaFin = (e) => {
    setFechaFin(e.target.value)
  }

  const handleFiltrar = () => {
    if (!fechaInicio || !fechaFin) return toast.error('Debe ingresar ambas fechas')
    axios.post('https://findally-backend.herokuapp.com/api/metricsBootcamp/findByDate', {
      fecha_inicio: fechaInicio,
      fecha_fin: fechaFin
    })
      .then(r => {
        console.log(r.data)
        setFilteredUsersData(r.data.data)
      })
      .catch(e => console.log(e))
  }

  const handleResetear = () => {
    axios.get('https://findally-backend.herokuapp.com/api/metricsBootcamp/findAll?skip=0&limit=25')
      .then(r => {
        setUsersData(r.data.data)
        setCantidad(r.data.total)
        setPagina(r.data.TotalPage)
        setFilteredUsersData([])
        setFechaInicio('')
        setFechaFin('')
      })
      .catch(e => console.log(e))
  }

  useEffect(() => {
    axios.get('https://findally-backend.herokuapp.com/api/metricsBootcamp/findAll?skip=0&limit=25')
      .then(r => {
        console.log(r.data)
        setUsersData(r.data.data)
        setCantidad(r.data.total)
        setPagina(r.data.TotalPage)
      })
      .catch(e => console.log(e))
  }, [])


  return (
    <div style={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%',
    }}>
      <div style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        maxWidth: '800px',
        minWidth: '300px',
      }}>
        <h1>Dashboard Bootcamp Metricas</h1>
        <h2 style={{color: 'green'}}>Cantidad de Interactores Registrados: {cantidad}</h2>
        <div style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          width: '100%',
        }}>
          <label>
            Inicio:
            <input style={{marginLeft: '10px'}} type={'date'} name={'fecha_inicio'}
                   value={fechaInicio}
                   onChange={(e) => handleFechaInicio(e)}/>
          </label>
          -
          <label>
            Fin:
            <input style={{marginLeft: '10px'}} type={'date'} name={'fecha_fin'} value={fechaFin} onChange={(e) => handleFechaFin(e)}/>
          </label>
          <button
            style={{
              padding: '3px',
              borderRadius: '10px',
              backgroundColor: 'green',
              color: 'white',
            }}
            onClick={(e) => handleFiltrar(e)}>
            Filtrar
          </button>
          <button
            style={{
              padding: '3px',
              borderRadius: '10px',
              backgroundColor: 'blue',
              color: 'white',
            }}
            onClick={(e) => handleResetear(e)}>
            Resetear
          </button>
        </div>
        {
          filteredUsersData.length ? filteredUsersData?.map((user, index) => {
            return (
              <div key={index} style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                justifyContent: 'center',
                border: '1px solid black',
                borderRadius: '10px',
                padding: '10px',
                margin: '10px 0',
                width: '100%',
              }}>
                <h3>Botón: {user.buttonName}</h3>
                <h3>Cantidad: {user.cantidad}</h3>
                <h3>Cantidad Usuarios: {user.cant_usuarios}</h3>
                <hr/>
                {user?.usuarios?.map((u, i) => {
                  return (
                    <div key={i}>
                      <p>
                        Usuario:
                        <b> {u} </b>
                      </p>
                    </div>
                  )
                })}
              </div>
            )
          }) : null
        }
        {
          !filteredUsersData.length && usersData.length && usersData?.map((user, index) => {
            return (
              <div key={index} style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                justifyContent: 'center',
                border: '1px solid black',
                borderRadius: '10px',
                padding: '10px',
                margin: '10px 0',
                width: '100%',
              }}>
                <h3>User: {user.usuario}</h3>
                <hr/>
                {user?.metrics?.map((m, i) => {
                  return (
                    <div key={i}>
                      <p>
                        Botón:
                        <b> {m.buttonName} </b>
                        Cant: {m.cantidad}
                      </p>
                    </div>
                  )
                })}
              </div>
            )
          })
        }
        <div style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%',
        }}>
          {!filteredUsersData.length &&
            <ReactPaginate
              previousLabel="<"
              nextLabel=">"
              breakLabel={"..."}
              breakClassName={"break-me"}
              pageCount={pagina}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={(e) => {
                console.log(e)
                axios.get(`https://findally-backend.herokuapp.com/api/metricsBootcamp/findAll?skip=${e.selected}&limit=25`)
                  .then(r => {
                    console.log(r.data)
                    setUsersData(r.data.data)
                    setCantidad(r.data.total)
                    setPagina(r.data.TotalPage)
                  })
                  .catch(e => console.log(e))
              }}
              activeClassName="pageAct"
              pageClassName="pageB"
              nextClassName="nextB"
              previousClassName="previB"
              className="paginateStyleDashboard"
            />}
        </div>
      </div>
    </div>
  )
}
