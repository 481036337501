import React, { useState } from "react";
//import logo from "../../assets/logos/coally_footer.jpeg";
import "./Footer.css";
import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import en from "../../lang/en-US";
import es from "../../lang/es-COL"
import counterpart from "counterpart";
import Translate from "react-translate-component";
import footer from '../../pages/Consultor/Styles/imagenes/footer.png'

import { Link } from "react-router-dom";

function Footer() {
  counterpart.registerTranslations('en',en) 
      counterpart.registerTranslations('es',es)
      counterpart.setLocale(localStorage.getItem('langlocal'));
      
      const [idioma, setidioma] = useState(localStorage.getItem('langlocal'))
     let url=window.location.href;
  return (
    <div onMouseOver={()=>{setidioma(localStorage.getItem('langlocal'))}}>
    <div className="footer-general">
    <div className="footer_bg_container">
    <div className='coAlly_data__footer'>
      <Link to="/consultor/home">
          <img
            src={footer}
            alt="logo img"
            className="footer-logo-img"
            width="200px"
          />
        </Link>
    </div>  
      {/* <div className="coAlly_data__footer">   
      <h3>{<Translate content="Categorias" component="span"/>}</h3>
      <Link className="footer-item" to="/" style={{color:'#8e8f9f',fontSize:'13px', paddingTop:'7px'}}>{<Translate content="INGENIERIAA" component="span"/>}</Link>
         <Link className="footer-item" to="/" style={{color:'#8e8f9f',fontSize:'13px', paddingTop:'7px'}}>{<Translate content="ESTRATEGIAA" component="span"/>}</Link>
         <Link className="footer-item" to="/" style={{color:'#8e8f9f',fontSize:'13px', paddingTop:'7px'}}>{<Translate content="MARKETINGYVENTAS" component="span"/>}</Link>
         <Link className="footer-item" to="/" style={{color:'#8e8f9f',fontSize:'13px', paddingTop:'7px'}}>{<Translate content="TRABAJOREMOTOO" component="span"/>}</Link>
      </div>      */}
        <div className="coAlly_data__footer">
        <h3>Quick Links</h3>
         <Link className="footer-item" to={url.includes('company')?"/company/home":"/consultor/home"} style={{color:'#8e8f9f',fontSize:'13px', paddingTop:'7px'}}>{<Translate content="HOMEEE" component="span"/>}</Link>
         <a className="footer-item" href={idioma==="es"?'https://coally.com/nosotros/':"https://coally.com/about-us/"} style={{color:'#8e8f9f',fontSize:'13px', paddingTop:'7px'}}>{<Translate content="NOSOTROSS" component="span"/>}</a>
         <a className="footer-item" href={idioma==="es"?'https://coally.com/servicios/':'https://coally.com/services/#ourservices'} style={{color:'#8e8f9f',fontSize:'13px', paddingTop:'7px'}}>{<Translate content="SERVICIOSS" component="span"/>}</a>
         {/* <Link className="footer-item" to="/" style={{color:'#8e8f9f',fontSize:'13px', paddingTop:'7px'}}>{<Translate content="ÚNETEEE" component="span"/>}</Link> */}
         <Link className="footer-item" to={url.includes('company')?"/company/project":"/consultor/projects"} style={{color:'#8e8f9f',fontSize:'13px', paddingTop:'7px'}}>{<Translate content="PROYECTOSSS" component="span"/>}</Link>
        </div>
        <div className="coAlly_data__footer__socials">
        <h3>{<Translate content="Socials" component="span"/>}</h3>
        <div className='container__socials'>
          <a className="footer-link" href="https://www.facebook.com/coallylatam/" style={{ color:'#8e8f9f',marginBottom:'1rem'}}>
            <FacebookIcon/>
          </a>
          <a className="footer-link" href={idioma==="es"?"https://www.instagram.com/coallylatam/?hl=es":"https://www.instagram.com/coally.usa/</div>"} style={{ color:'#8e8f9f',marginBottom:'1rem'}}>
            <InstagramIcon/>
          </a>
          <a className="footer-link" href="https://www.linkedin.com/company/coally/" style={{color:'#8e8f9f',marginBottom:'1rem'}}>
            <LinkedInIcon/>
          </a>
        </div>
        </div>
        </div>
        <div className='border_bottom__footer'>
                <p className="footer-copyright">Copyrigths @ 2021 CoAlly | Desarrollado por CoAlly</p>
        </div>
    </div>
   </div>
  );
}

export default Footer;
