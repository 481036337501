import React, { useState } from "react";
import { logoutUser, changeview } from "../../actions";
import { connect } from "react-redux";
import en from "../../lang/en-US";
import es from "../../lang/es-COL"
import counterpart from "counterpart";
import "./Headertop.css";
import MenuIcon from '@mui/icons-material/Menu';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Headerprofesional from "../header/Headerprofesional";

const Headertop = (props) => {

  if (!localStorage.getItem('langlocal')) {
    localStorage.setItem('langlocal', 'es')
  }
  const [locallang] = useState(localStorage.getItem('langlocal'))

  counterpart.registerTranslations('en', en)
  counterpart.registerTranslations('es', es)
  counterpart.setLocale(localStorage.getItem('langlocal'));
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });
  const toggleDrawer =
  (anchor, open) =>
    (event) => {
      if (
        event.type === 'keydown' &&
        ((event).key === 'Tab' ||
          (event).key === 'Shift')
      ) {
        return;
      }

      setState({...state, [anchor]: open});
    };
const list = (anchor) => (
  <Box
    sx={{width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250}}
    role="presentation"
    onKeyDown={toggleDrawer(anchor, false)}
  >
    <Headerprofesional></Headerprofesional>
  </Box>
);
  return (
    <>

      {locallang ? null : null}
      <div style={{
          display: "flex",
          width: "100%",
          height: "80px",
          alignItems: "center",
          justifyContent: "end",
          marginBottom: "-10px",
        }}>
          <div  onClick={toggleDrawer("left", true)}
             style={{position: 'absolute', left: '0', cursor: 'pointer', backgroundColor:'green', width: '100%', height: '100vh'}}><MenuIcon sx={{fontSize: "2.5rem"}}/></div>
        <Drawer
          anchor={"left"}
          open={state["left"]}
          onClose={toggleDrawer("left", false)}
        >{list("left")}</Drawer>
      </div>
      <hr />
    </>
  );
};
const mapDispatchToProps = {
  logoutUser,
  changeview,
};
export default connect(null, mapDispatchToProps)(Headertop);
