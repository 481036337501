import React, {useState} from "react";
import {useSelector} from "react-redux";
import "./SidebarPQR.css";
import Modal from "@mui/material/Modal";
import {Box} from "@mui/material";
import close from "../../../../assets/icons/close.svg";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import {toast} from "react-toastify";
import {ReduxStateInterface} from "../../../types/reduxInterface";
import {DefaultButton, FormInput, FormTextArea} from "../../buttons/ButtonStyles";

export default function SidebarPQR({color}: { color?: string }) {

  const {lang} = useSelector((state: ReduxStateInterface) => state);
  const [open, setOpen] = useState(false);
  const [form, setForm] = useState({
    nombre: "",
    apellido: "",
    email: "",
    solicitud: "",
  })

  const cvButton = () => {
    setOpen(true);
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "100%",
    maxWidth: "722px",
    maxHeight: "672px",
    bgcolor: "#FFFFFF",
    borderRadius: "8px",
    borderColor: "#FFFFFF",
    boxShadow: "0px 7px 32px rgba(0, 0, 0, 0.1)",
    transition: "all 0.3s ease",
    overflow: "auto",
    margin: 0,
    padding: 0,
  };

  const changeHandler = (e: any) => {
    setForm({...form, [e.target.name]: e.target.value});
  };

  const handleUpload = () => {
    if (form.apellido === "" || form.nombre === "" || form.email === "" || form.solicitud === "") {
      return toast.error("Por favor complete todos los campos del formulario PQR", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
    axios.post<any>(`https://findally-backend.herokuapp.com/api/schneider/admin/sendMailPQR`, form)
      .then((res) => {
        if (res.status === 200) {
          toast.success("Formulario enviado", {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          return setOpen(false)
        }
      })
      .catch((err) => {
        if (err.response.status === 400) {
          return toast.error("Hubo un error enviando el formulario PQR", {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      });
  };

  return (
    <>
      <Modal open={open} onClose={() => setOpen(false)}>
        <Box sx={style}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "space-between",
              width: "100%",
              flexDirection: "column",
            }}
          >
            <div>
              <button
                style={{backgroundColor: "transparent"}}
                onClick={() => setOpen(false)}
              >
                <img src={close} alt="close"/>
              </button>
              <div style={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
                padding: "20px",
              }}>
                <h1 style={{
                  textAlign: 'center'
                }}>
                  {lang === "es" ? "Formulario PQR" : "PQR Form"}
                </h1>
                <p>
                  {lang === "es" ? "Nombre" : "Name"}
                </p>
                <FormInput
                  placeholder={lang === "es" ? "Juan" : "Jhon"}
                  type="text"
                  name="nombre"
                  required
                  onChange={(e) => changeHandler(e)}
                />
                <p>
                  {lang === "es" ? "Apellido" : "Last Name"}
                </p>
                <FormInput
                  placeholder={lang === "es" ? "Gomez" : "Snow"}
                  type="text"
                  name="apellido"
                  required
                  onChange={(e) => changeHandler(e)}
                />
                <p>
                  Email
                </p>
                <FormInput
                  placeholder={lang === "es" ? "GomezJuan@gmail.com" : "SnowJhon@gmail.com"}
                  type="text"
                  name="email"
                  required
                  onChange={(e) => changeHandler(e)}
                />
                <p>
                  {lang === "es" ? "Solicitud" : "Request"}
                </p>
                <FormTextArea
                  placeholder={lang === "es" ? "Escribe tu solicitud" : "Write your request"}
                  name="solicitud"
                  required
                  onChange={(e) => changeHandler(e)}
                />
              </div>
              <div style={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
                padding: "0 20px",
                justifyContent: "flex-end",
              }}>
                <DefaultButton onClick={() => handleUpload()} background={color}>
                  {lang === "es" ? "Enviar Formulario" : "Send Form"}
                </DefaultButton>
              </div>
            </div>
          </div>
        </Box>
      </Modal>
      <DefaultButton onClick={() => cvButton()} background={color}>
        {lang === "es" ? "Contáctanos" : "Contact us"}
      </DefaultButton>
    </>
  );
}
