import React from 'react'
import { CardsProps } from '../utils/interfaceCards'


const CardSector = ({image , title , cant , lang}:CardsProps) => {
  return (
    <div style={{ marginRight: '20px' }}>
    <div className='cardSector-container'
      style={{ backgroundImage: `url(${image})` }}>
      <div className='cardSector-subContainer'>
        <div>
          <h3 className='cardSector-title'>
            {title}
          </h3>
          <h3 className='cardSector-cant'>
            {cant} {lang === 'es' ? 'cursos' : 'courses'}
          </h3>
        </div>
      </div>
    </div>
  </div>
  )
}

export default CardSector