import React, {useState, useEffect} from 'react';
import FormControlLabel from '@mui/material/FormControlLabel';
import CircleIcon from '@mui/icons-material/Circle';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Typography from '@mui/material/Typography';
import './CrearOportunidadNew.css'


export default function Payment(props) {
const {handleChange, form, step, handleStep, handleBackStep, lang} = props;
const [button, setButton] = useState(true);

useEffect(() => {
  if(form.modalidaddepago !== ''){
    setButton(false)
  }
}, [form.modalidaddepago])


    return (
        <>
        <div className='contenedorDuration'>
       <h3 className='contenedorDuration_title'>{lang === 'es' ? '¿Qué modalidad de pago vas a usar?' : 'What payment method are you going to use?'}</h3>
     <div>
      <RadioGroup
        aria-labelledby="demo-controlled-radio-buttons-group"
        name="estimadoDuración"
        className='durationRadio'
        value={form.modalidaddepago}
      >
        <FormControlLabel name="modalidaddepago" value="gratis" control={<Radio icon={<CircleIcon className='radio_unchecked_icon' />} checkedIcon={<CircleIcon className='radio_checked_icon'/>}/>}onChange={handleChange} label={<Typography sx={{ fontSize: '14px', fontFamily: 'Poppins', fontWeight: '400' }}>
        {lang === 'es' ? 'Gratis' : 'Free'}
    </Typography>} />

        <FormControlLabel value="standard" name="modalidaddepago"  onChange={handleChange} control={<Radio icon={<CircleIcon  className='radio_unchecked_icon' />} checkedIcon={<CircleIcon className='radio_checked_icon'/>}/>} label={<Typography sx={{ fontSize: '14px', fontFamily: 'Poppins', fontWeight: '400'}}>
        Standard
    </Typography>} />

    <FormControlLabel value="Bussiness" name="modalidaddepago"  onChange={handleChange} control={<Radio icon={<CircleIcon  className='radio_unchecked_icon' />} checkedIcon={<CircleIcon className='radio_checked_icon'/>}/>} label={<Typography sx={{ fontSize: '14px', fontFamily: 'Poppins', fontWeight: '400'}}>
    Bussiness
    </Typography>} />
      </RadioGroup>
    </div>
            </div>
            <div
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "space-between",
              padding: "1rem 3rem 0rem 3rem",
              alignItems: "center",
            }}
          >
             <button className='button_siguiente_create_B' onClick={handleBackStep}>{lang === 'es' ? 'Anterior' : 'Previous'}</button>
            <h3 className='stepCounter'>{`${step}/14`}</h3>
            <button className='button_siguiente_create'
            onClick={handleStep} disabled={button}>{lang === 'es' ?'Siguiente' : 'Next'}</button>
          </div>
          </>
    )
}
