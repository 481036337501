import React, { useState } from 'react'
import { useDispatch,  } from 'react-redux'
import SignatureCanvas from 'react-signature-canvas'
import Popup from "../../components/popUp/Modal.js"
import {
  sendFirma,
} from "../../actions/index";
import './Firma.css'
import en from "../../lang/en-US";
import es from "../../lang/es-COL"
import counterpart from "counterpart";
import Translate from "react-translate-component";

export default function Firma(props) {

  const dispatch = useDispatch()
  
  const [buttonPopup, setButtonPopup] = useState(false);
 
    const [trimmedDataURL, setDataUrl] = useState()

    let sigPad = {}
  
    const clear = () => {
      sigPad.clear()
    }
    const setsend =()=>{
      const data ={
        sign: trimmedDataURL.trimmedDataURL,
        idUsuario: props.idUsuario,
      }
      dispatch(sendFirma(data)) 
    }
    const trim = () => {
      setDataUrl({ trimmedDataURL: sigPad.getTrimmedCanvas()
        .toDataURL('image/png') })
        
      }

      counterpart.registerTranslations('en',en) 
      counterpart.registerTranslations('es',es)
      counterpart.setLocale(localStorage.getItem('langlocal'));

    return (
        <>
        <div style={{maxWidth:"450px"}}>
      <div className="canva" style={{ maxWidth:'300px', maxHeight:'100px'}}>
        <SignatureCanvas 
          ref={(ref) => { sigPad = ref }} />
      </div>
      <Popup trigger={buttonPopup} setTrigger={setButtonPopup}>
              <h2 className="tittle-popup-apply">{<Translate content="Firmaingresadacorrectamente" component="span"/>}</h2>
            </Popup>
      <div className='firma-botones'>
        <button className='boton-firma-co' onClick={clear}>
        {<Translate content="Borrar" component="span"/>}
        </button>
        <button className='boton-firma-co'  onClick={trim}>
        {<Translate content="Firmar" component="span"/>}
        </button>
        <button className='boton-firma-co'  onClick={(e) => {
                    setButtonPopup(true);
                    setsend(e);
                   }}>
          {<Translate content="Enviar" component="span"/>}
        </button>
      </div>
      {trimmedDataURL
        ? <img 
          src={trimmedDataURL.trimmedDataURL} alt="-s-" />
        : null}
        </div>
         </>
    )
}
