import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.css";
import { Form, /* Button, Row, Col */ } from 'react-bootstrap';
import { CreateProjectWhithoutPayment, CreateUnregisterProject } from "../../actions";
import { connect } from "react-redux";
import TextField from "@material-ui/core/TextField";
import '../../pages/styles/ContactUs.css';
import en from "../../lang/en-US";
import es from "../../lang/es-COL"
import counterpart from "counterpart";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { Link } from 'react-router-dom'
import { createBrowserHistory } from 'history'

 import Translate from "react-translate-component";
 
/* import Popup from "../../components/popUp/Modal.js"
 */


function FormCompanyNewCreateProjectUnregister(props) {
    let history = createBrowserHistory();

    const [selected, setSelected] = React.useState("");
    const changeSelectOptionHandler = (event) => {
        setSelected(event.target.value);
    };

/*     const [buttonPopup, setButtonPopup] = useState(false);
 */    /* const [tipoProyecto, settipoProyecto] = useState(false); */
    const [statusProyecto, setstatusProyecto] = useState(false);
    const [otroCausa, setotroCausa] = useState("")
/*     const [periodico, setPeriodico] = useState("")
 */
    const softwaredevelopment = [
        "Pagina web",
        "Tienda online",
        "Aplicacion web",
        "Aplicacion movil"
    ];
    const digitalmarketing = ["Número de publicaciones", "Documento de estrategia de contenidos", "Piezas gráficas hechas", "Interacción con las publicaciones", "Incremento en ventas"];
    const bussinesanalyst = ["Desarrollo hecho", "El estudio terminado", "La estrategia diseñada", "El pitchdeck ejecutado", "Estudio de precios hecho", "Tabla competidores", "Ventas ejecutadas"];
    const langProgramming = ["C++", "C#", "C", "Java", "Python", "PHP", "SQL", "Ruby", "Visual Basic", ".NET", "R", "Javascript", "TypeScript", "Swift", "Kotlin", "Rust", "Go", "Postscript", "Scheme", "Erlang", "Elixir", "Pascal", "Objective-C"];

    /** Type variable to store different array for different dropdown */
    let type = null;
    /** Type variable to store different array for different dropdown */
    let typeservice = null;

    /** This will be used to create set of options that user will see */
    let options = null;

    /** This will be used to create set of options that user will see */
    let optionslang = null;


    /** Setting Type variable according to dropdown */
    if (selected === "Software Development") {
        type = softwaredevelopment;
    } else if (selected === "Business Analyst services") {
        type = bussinesanalyst;
    } else if (selected === "Digital marketing y diseño") {
        type = digitalmarketing;
    }



    /** Setting Type variable according to dropdown */
    if (selected === "Software Development") {
        typeservice = langProgramming;
    }

    const [form, setValues] = useState({
        usuario: props.user.id,
        confidencial: [],
        presupuesto: "",
        empresa: "",
        nombre: "",
        email: "",
        comentario: "",
        idioma: "",
        ciudad: "",
        metricas: "",
        lenguajes_prog: "",
        problema_empresa: "",
        causa_empresa: "",
        linea_negocio: "",
        codigo_promo: "",
        habilidades_requeridas: [],
        campo: {},
        error: {},
        enviado: false
    });


/* 
    // Valido los campos del formulario 
    const validarFormulario = () => {
        let campo = this.state.campo;
        let error = {};
        let formularioValido = true;

        // Nombres y Apellidos 
        if (!campo["nya"]) {
            formularioValido = false;
            error["nya"] = "Por favor, ingresa tus Nombres y Apellidos.";
        }

        // Asunto
        if (!campo["asunto"]) {
            formularioValido = false;
            error["asunto"] = "Por favor, ingresa un Asunto.";
        }

        // Área o Departamento
        if (!campo["area"]) {
            formularioValido = false;
            error["area"] = "Por favor, selecciona un área o Departamento.";
        }

        // Archivo 
        if (!campo["archivo"]) {
            formularioValido = false;
            error["archivo"] = "Por favor, selecciona un Archivo.";
        }

        // Mensaje
        if (!campo["mensaje"]) {
            formularioValido = false;
            error["mensaje"] = "Por favor, ingresa tu Mensaje.";
        }

        // Seteo el estado de error 
        this.setState({
            error: error
        });

        return formularioValido;
    }; */

    const handleInput = (event) => {
        setValues({
            ...form,
            [event.target.name]: event.target.value,
        });
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        console.log(form)
        props.CreateProjectWhithoutPayment(form);
        history.push("../../company/payment");
    };

    const handleSubmitCreate = (event) => {
        event.preventDefault();
        console.log(form)
        props.CreateUnregisterProject(form, "https://coally.com/"); 
    };

    //const lang = useSelector(state => state.lang)
    /*     
     */

    counterpart.registerTranslations('en', en)
    counterpart.registerTranslations('es', es)
    counterpart.setLocale(localStorage.getItem('langlocal'));


    if (type) {
        options = type.map((el) => <option key={el}>{el}</option>);
    }


    if (typeservice) {
        optionslang = typeservice.map((el) => <option key={el}>{el}</option>);
    }

    const Nombreempresa =counterpart.translate('Nombreempresa')
    const Nombreproyecto =counterpart.translate('Nombreproyecto')
    const Ciudad =counterpart.translate('Ciudad')
    const Necesidad =counterpart.translate('Necesidad')
    const Causas =counterpart.translate('Causas')
    const Comentarioss =counterpart.translate('Comentarioss')
    const Presupuestototal =counterpart.translate('Presupuestototal')
    const CorreoElectrónico =counterpart.translate('CorreoElectrónico')
    const Codigopromocional =counterpart.translate('Codigopromocional')
    return (

        <>
            <div className="section-tittle-out-form">
                <h2 className="tittle-out-form">{<Translate content="Publicarproyecto" component="span"/>}</h2>
            </div>

            <div className="create-company-form">
                <Form className="creeate-project-form-container">
                    <div className="section-tittle-create-project-form">
                        <h2 className="tittle-create-project-form">{<Translate content="Informacióngeneral" component="span"/>}</h2>
                    </div>
                    <div className="select-area-create-project" style={{display:'flex',flexDirection:'column',alignItems:'center'}}>
                        <div className="col-one-create-project">
                            <div className="tittle-section-question">
                                <h4 className="tittle-question-create-project">{<Translate content="Deseaspublicaresteproyectodemaneraanonima" component="span"/>}</h4>
                            </div>
                            <Select name="confidencial" className="select-create-project" onChange={handleInput}>
                                <MenuItem value="Select">{<Translate content="Selecciona" component="span"/>}</MenuItem>
                                <MenuItem value="Si">{<Translate content="Si" component="span"/>}</MenuItem>
                                <MenuItem value="No">No</MenuItem>
                            </Select>
                        </div>
                        <div className="col-two-create-project">
                            <div className="tittle-section-question">
                                <h4 className="tittle-question-create-project">{<Translate content="Ingreseelnombredelaempresa" component="span"/>}</h4>
                            </div>
                            <input
                                className="input_create_project_new_form"
                                type="text"
                                placeholder={Nombreempresa}
                                name="empresa"
                                onChange={handleInput}
                            />
                        </div>
                    </div>


                    <div className="select-area-create-project" style={{display:'flex',flexDirection:'column',alignItems:'center'}}>
                        <div className="col-one-create-project">
                            <div className="tittle-section-question">
                                <h4 className="tittle-question-create-project">{<Translate content="Ingresaelnombredelproyecto" component="span"/>}</h4>
                            </div>
                            <input
                                className="input_create_project_new_form"
                                type="text"
                                placeholder={Nombreproyecto}
                                name="nombre"
                                onChange={handleInput}
                            />
                        </div>

                        <div className="col-two-create-project">
                            <div className="tittle-section-question">
                                <h4 className="tittle-question-create-project">{<Translate content="EnqueCiudadserealizaraelproyecto" component="span"/>}</h4>
                            </div>
                            <input
                                className="input_create_project_new_form"
                                type="text"
                                placeholder={Ciudad}
                                name="ciudad"
                                onChange={handleInput}
                            />
                        </div>
                    </div>

                    <div className="select-area-create-project" style={{display:'flex',flexDirection:'column',alignItems:'center'}}>
                        <div className="col-one-create-project">
                            <div className="tittle-section-question">
                                <h4 className="tittle-question-create-project">{<Translate content="Líneadenegocioenlaquenecesitasapoyo" component="span"/>}</h4>
                            </div>
                            <Select name="linea_negocio" className="select-create-project" onChange={(e) => {
                                handleInput(e);
                                changeSelectOptionHandler(e)
                            }}>
                                <MenuItem value="Software Development">{<Translate content="Selecciona" component="span"/>}</MenuItem>
                                <MenuItem value="Software Development">Software Development</MenuItem>
                                <MenuItem value="Business Analyst services">Business Analyst services</MenuItem>
                                <MenuItem value="Digital marketing y diseño">Digital marketing y diseño</MenuItem>
                                <MenuItem value="Otro">{<Translate content="Otroo" component="span"/>}</MenuItem>
                            </Select>
                        </div>

                        <div className="col-two-create-project">
                            <div className="tittle-section-question">
                                <h4 className="tittle-question-create-project">{<Translate content="Cuálestunecesidadytúobjetivo" component="span"/>}</h4>
                            </div>
                            <input
                                className="input_create_project_new_form"
                                type="text"
                                placeholder={Necesidad}
                                name="problema_empresa"
                                onChange={handleInput}
                            />
                        </div>
                    </div>

                    
                    <div className="select-area-create-project" style={{display:'flex',flexDirection:'column',alignItems:'center'}}>
                        <div className="col-one-create-project">

                        <div className="tittle-section-question">
                                <h4 className="tittle-question-create-project">{<Translate content="Ingresatucorreoelectronico" component="span"/>}</h4>
                            </div>
                            <input
                                className="input_create_project_new_form"
                                type="text"
                                placeholder={CorreoElectrónico}
                                name="email"
                                onChange={handleInput}
                            />
                        </div>

                        <div className="col-two-create-project">
                        </div>
                    </div>

                    <div className="section-tittle-create-project-form">
                        <h2 className="tittle-create-project-form">{<Translate content="Requerimientosespecificos" component="span"/>}</h2>
                    </div>
                    <div className="select-area-create-project" style={{display:'flex',flexDirection:'column',alignItems:'center'}}>
                        <div className="col-one-create-project">

                            <div className="tittle-section-question">
                                <h4 className="tittle-question-create-project">{<Translate content="Seleccioneelareadeconocimientorequeridasegunsusrequerimientos" component="span"/>}</h4>
                            </div>

                            <Select onChange={handleInput} name="habilidades_requeridas">
                                <MenuItem value="Finanzas y negociacion">{<Translate content="Finanzasynegociacion" component="span"/>}</MenuItem>
                                <MenuItem value="Ingenierias">{<Translate content="Ingenieriass" component="span"/>}</MenuItem>
                                <MenuItem value="Planeacion de negocios">{<Translate content="Planeaciondenegocios" component="span"/>}</MenuItem>
                                <MenuItem value="Impacto social y sostenibilidad">{<Translate content="Impactosocialysostenibilidadd" component="span"/>}</MenuItem>
                                <MenuItem value="Quiero reestructrar las bases de mi negocio">{<Translate content="Quieroreestructrarlasbasesdeminegocioo" component="span"/>}</MenuItem>
                                <MenuItem value={otroCausa} onClick={() => setotroCausa("otro")}>{<Translate content="Otroo" component="span"/>}</MenuItem>
                            </Select>
                            {otroCausa === 'otro' ?
                                <TextField
                                    fullWidth
                                    label={<Translate content="Otroo" component="span"/>}
                                    name="habilidades_requeridas"
                                    placeholder=""
                                    margin="normal"
                                    onChange={handleInput}
                                /> : null}
                        </div>
                        <div className="col-two-create-project">
                            <div className="tittle-section-question">
                                <h4 className="tittle-question-create-project">{<Translate content="Cualescreesquesonlascausasdelproblema" component="span"/>}</h4>
                            </div>
                            <input
                                className="input_create_project_new_form"
                                type="text"
                                placeholder={Causas}
                                name="causa_empresa"
                                onChange={handleInput}
                            />
                        </div>
                    </div>

                    <div className="select-area-create-project" style={{display:'flex',flexDirection:'column',alignItems:'center'}}>
                        <div className="col-one-create-project">
                            <div className="tittle-section-question">
                                <h4 className="tittle-question-create-project">{<Translate content="Cómomediríaseléxitodelproyecto" component="span"/>}</h4>
                            </div>
                            <select name="metricas" className="select-create-project" onChange={handleInput}>
                                {
                                    /** This is where we have used our options variable */
                                    options
                                }
                            </select>
                        </div>
                        <div className="col-two-create-project">
                            <div className="tittle-section-question">
                                <h4 className="tittle-question-create-project">{<Translate content="Seleccionaellenguajedeprogramaciónquedesees" component="span"/>}</h4>
                            </div>
                            <select name="lenguajes_prog" className="select-create-project" onChange={handleInput}>
                                {
                                    /** This is where we have used our options variable */
                                    optionslang
                                }
                            </select>
                        </div>
                    </div>

                    <div className="select-area-create-project" style={{display:'flex',flexDirection:'column',alignItems:'center'}}>
                        <div className="col-one-create-project">
                            <div className="tittle-section-question">
                                <h4 className="tittle-question-create-project">{<Translate content="Seleccionaelidiomarequeridoparaelproyecto" component="span"/>}</h4>
                            </div>
                            <Select name="idioma" className="select-create-project" onChange={handleInput}>
                                <MenuItem value="Ingles">Ingles</MenuItem>
                                <MenuItem value="Español">Español</MenuItem>
                            </Select>
                        </div>
                        <div className="col-two-create-project">
                        </div>
                    </div>
                    <div className="section-tittle-create-project-form">
                        <h2 className="tittle-create-project-form">{<Translate content="Informacionadicional" component="span"/>}</h2>
                    </div>
                    <div className="select-area-create-project" style={{display:'flex',flexDirection:'column',alignItems:'center'}}>
                        <div className="col-one-create-project">
                            <div className="tittle-section-question">
                                <h4 className="tittle-question-create-project">{<Translate content="Ingresauncodigopromocional" component="span"/>}</h4>
                            </div>
                            <input
                                className="input_create_project_new_form"
                                type="text"
                                placeholder={Codigopromocional}
                                name="codigo_promo"
                                onChange={handleInput}
                            />
                        </div>
                        <div className="col-two-create-project">

                            <div className="tittle-section-question">
                                <h4 className="tittle-question-create-project">{<Translate content="Ingresatuscomentarios" component="span"/>}</h4>
                            </div>

                            <input
                                className="input_create_project_new_form"
                                type="text"
                                placeholder={Comentarioss}
                                name="comentario"
                                onChange={handleInput}
                            />
                        </div>
                    </div>


                    <div className="select-area-create-project" style={{display:'flex',flexDirection:'column',alignItems:'center'}}>
                        <div className="col-one-create-project">
                            <div className="tittle-section-question">
                                <h4 className="tittle-question-create-project">{<Translate content="Cuálestupresupuestoparaesteproyecto" component="span"/>}</h4>
                            </div>
                            <p className="p-question-create-project">{<Translate content="Siseleccionastepagoporentregableingresaelmontodeseado" component="span"/>}</p>
                           
                            <input
                                className="input_create_project_new_form"
                                type="number"
                                placeholder={Presupuestototal}
                                name="presupuesto"
                                min="350"
                                onChange={handleInput}
                            />
                        </div>
                        <div className="col-two-create-project">
{/*                             <div className="tittle-section-question">
                                <h4 className="tittle-question-create-project">¿Cuál es tu presupuesto por hora?</h4>
                            </div>
                            <p className="p-question-create-project">Si seleccionaste pago por hora ingresa el monto correspondiente</p>

                            <input
                                className="input_create_project_new_form"
                                type="number"
                                placeholder="Presupuesto Hora"
                                name="presupuesto"
                                onChange={handleInput}
                            /> */}
                        </div>
                    </div>

                    {/* 
                            <Select name="linea_negocio" className="select-create-project" onChange={changeSelectOptionHandler}>
                                <MenuItem value="Selecciona">Selecciona...</MenuItem>
                                <MenuItem value="Por entregable final">Por entregable final: Tenga en cuenta que la comisión que cobra Coally es del 20%</MenuItem>
                                <MenuItem value="Por hora">Por hora: Tenga en cuenta que la comisión que cobra Coally es del 20%</MenuItem>
                                <MenuItem value={periodico} onClick={() => setPeriodico("Periódicamente")}>Periódicamente</MenuItem>
                            </Select>
                            {periodico === 'Periódicamente' ?
                                <Select name="linea_negocio" className="select-create-project" onChange={changeSelectOptionHandler}>
                                    <MenuItem value="Semanal">Semanal</MenuItem>
                                    <MenuItem value="Quincenal">Quincenal</MenuItem>
                                    <MenuItem value="Mensual">Mensual</MenuItem>
                                </Select>
                                : null} */}

                    <div className="tittle-section-question">
                        <h4 className="tittle-question-create-project">{<Translate content="Deseaspagarahoraodeseasunaasesoriapersonalizada" component="span"/>}</h4>
                    </div>
                    <div className="paymet-section-buttons">

                        <Select onChange={handleInput} name="status_proj">
                            <MenuItem value={statusProyecto} onChange={handleInput} onClick={() => setstatusProyecto("pagarahora")}>{<Translate content="DeseopagarAhora" component="span"/>}</MenuItem>
                            <MenuItem value={statusProyecto} onChange={handleInput} onClick={() => setstatusProyecto("personalizada")}>{<Translate content="Deseounaasesoriapersonalizada" component="span"/>}</MenuItem>
                        </Select>


                        {statusProyecto === 'pagarahora' ?

                            <Link to="../../company/payment" onClick={(e) => { handleSubmit(e); }}>
                                <div className="button_div_create_project">
                                    <button className="button_create_project" >{<Translate content="Enviarpagarahora" component="span"/>}</button>
                                </div>
                            </Link> : null}


                        {statusProyecto === 'personalizada' ?
                            <div className="button_div_create_project">
                                <button className="button_create_project" onClick={(e) => { handleSubmitCreate(e); }}>{<Translate content="Enviarpersonalizada" component="span"/>}</button>
                            </div>
                            : null}

                    </div>




                </Form>

                <div className="section-altert-content-payment">
                    <p className="message-alert-create-project">{<Translate content="Coallymantieneeldinerodentrodesuplataforma" component="span"/>}</p>
                    <p style={{color:'black',fontSize:'13px',paddingLeft:'5%',paddingRight:'5%'}}>{<Translate content="Coallymantieneeldinerodentrodesuplataforma2" component="span"/>}</p>
                </div>

            </div>


        </>
    )

}

const mapDispatchToProps = {
    CreateProjectWhithoutPayment,
    CreateUnregisterProject
};
const mapStateToProps = (state) => {
    return {
        user: state.user,
    };
};
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(FormCompanyNewCreateProjectUnregister);
