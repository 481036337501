import React from "react";
import PlaceOutlinedIcon from '@mui/icons-material/PlaceOutlined';
import './CardOportunidad.css'
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import Avatar from "@mui/material/Avatar";
import { AvatarGroup } from "@mui/material";


export default function NewCardOportunidadList({ imagen, id, aplicantes, titulo, titulo2, habilidades, ciudad, width, pais }) {
  const lang = useSelector((state) => state.lang);

  return (
    <div className="CardOportunidad-Container-rectangular">
      <div className={'CardOportunidad-Top-Container-rectangle'}>
        <div className="CardOportunidad-Top-Container-rectangle-inner-1">
          <div className="CardOportunidad-Top-Container-rectangle-inner-1-1">
            <div className="CardOportunidad-Top-subContainer-1">
              <img className={'CardOportunidad-Image'} src={imagen ? imagen : ''} alt={imagen ? imagen : ''} />
            </div>
            <div className="CardOportunidad-Top-subContainer-2">
              {
                width > 710 ?
                  <h1 style={{
                    fontSize: '16px',
                    fontWeight: '700',
                    color: '#393939',
                    textAlign: 'left',
                    width: '100%',
                    marginLeft: '28px',
                  }}>
                    {titulo !== 'Unnamed Oportunity' ? titulo.length > 65 ? titulo.substring(0, 65).charAt(0).toUpperCase() + titulo.substring(0, 65).slice(1).toLowerCase() + '...' : titulo.charAt(0).toUpperCase() + titulo.slice(1).toLowerCase() : titulo2.length > 65 ? titulo2.substring(0, 65).charAt(0).toUpperCase() + titulo2.substring(0, 65).slice(1).toLowerCase() + '...' : titulo2.charAt(0).toUpperCase() + titulo2.slice(1).toLowerCase()}
                  </h1> :
                  <h1 style={{
                    fontSize: '16px',
                    fontWeight: '700',
                    color: '#393939',
                    textAlign: 'left',
                    width: '100%',
                    marginLeft: '28px',
                  }}>
                    {titulo !== 'Unnamed Oportunity' ? titulo.length > 40 ? titulo.substring(0, 40).charAt(0).toUpperCase() + titulo.substring(0, 40).slice(1).toLowerCase() + '...' : titulo.charAt(0).toUpperCase() + titulo.slice(1).toLowerCase() : titulo2.length > 40 ? titulo2.substring(0, 40).charAt(0).toUpperCase() + titulo2.substring(0, 40).slice(1).toLowerCase() + '...' : titulo2.charAt(0).toUpperCase() + titulo2.slice(1).toLowerCase()}
                  </h1>
              }
              {
                ciudad ? (
                  <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-evenly',
                    alignItems: 'center',
                    width: '100%',
                    textAlign: 'center',
                    marginLeft: '28px',
                  }}>
                    <div style={{
                      marginRight: '18px',
                    }}><PlaceOutlinedIcon style={{ color: '#C5C5C5', fontSize: '20px', margin: 0, padding: 0 }} /></div>
                    <div style={{
                      display: 'flex',
                      justifyContent: 'flex-start',
                      alignItems: 'center',
                      width: 'calc(100% - 20px)',
                      height: '100%',
                      color: '#C5C5C5',
                      fontSize: '16px',
                      fontWeight: '400',
                    }}>
                      {ciudad}, {pais}
                    </div>
                  </div>
                ) : null
              }
            </div>
          </div>
          <div className="CardOportunidad-Top-Container-rectangle-inner-1-2">
            <div className="CardOportunidad-Top-subContainer-4">
              <p style={{
                fontSize: '14px',
                fontWeight: '400',
                color: '#5D5D5D',
                textAlign: 'left',
                margin: 0,
                paddingBottom: '30px',
              }}>{lang === 'es' ? 'Profesionales con conocimiento en: ' : 'Professionals with knowledge in: '}{!habilidades || habilidades === 'NA' || habilidades === 'Otro' ? lang === 'es' ? 'No especifica' : 'Not specified' : habilidades.length > 100 ? habilidades.substring(0, 100) + '...' : habilidades}</p>
            </div>
          </div>
        </div>
        <div className="CardOportunidad-Top-Container-rectangle-inner-2">
          <div className="CardOportunidad-Top-Container-rectangle-inner-2-1">
            <div className="CardOportunidad-mid-Container-inner-3-1">
            <AvatarGroup max={4}>
              {aplicantes && aplicantes.map((aplicante, index) => {           
                return (        
                  <Avatar  key={index+'A'} alt={aplicante['username']} src={aplicante?.avatar ? aplicante.avatar : 'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png'} />
                )
              })}
            </AvatarGroup>
            </div>
          </div>
          <div className="CardOportunidad-Top-Container-rectangle-inner-2-2">
            <Link to={`/company/CompanyDashboard/${id}`}>
              <button
                className={'CardOportunidadProfesional-Button'}>
                <span>
                  {lang === 'es' ? 'Ver oportunidad' : 'See Opportunity'}
                </span>
              </button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}