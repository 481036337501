import styled from "styled-components";
import openai from "../../../../../../assets/imagenes/openai.png";
const ChatGptContainer = styled.div`
  width: 100%;
  height: 158px;
  background-image: url(${openai});
  background-size: cover;
  background-position: right;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 1100px) {
    flex-direction: column;
    padding-top: 10px;
    padding-bottom: 10px;
  }

  @media (max-width: 1550px) {
    border-radius: 8px;
  }
`;

const BannerTextContainer = styled.div`
  margin-left: 41px;
`;

const BannerTextH1 = styled.h1`
  font-size: 36px;
  font-family: Roboto, sans-serif;
  font-weight: 700;
  color: #ffffff;
`;

const BannerTextH4 = styled.h4`
  font-size: 20px;
  font-weight: 400;
  color: #ffffff;
  font-family: Poppins, sans-serif;
`;

const BannerButtonContainer = styled.div<{ color?: string }>`
  margin-right: 6rem;
  margin-top: 4rem;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  width: 135px;
  height: 28px;
  background: ${(props) => props.color || "#f07130"};
  border-radius: 5px;

  @media (max-width: 1100px) {
    margin-right: 0;
    margin-top: 0;
  }
`;
const BannerButton = styled.button`
  color: #ffffff;
  background: none;
`;

export {
  ChatGptContainer,
  BannerTextContainer,
  BannerTextH1,
  BannerTextH4,
  BannerButtonContainer,
  BannerButton,
};
