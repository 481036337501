import React, {useEffect, useState} from 'react';
import FormControlLabel from '@mui/material/FormControlLabel';
import CircleIcon from '@mui/icons-material/Circle';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Typography from '@mui/material/Typography';
import './CrearOportunidadNew.css'
import ChipInput from "material-ui-chip-input";
import {useDispatch} from "react-redux";
import { saveOportunityForm } from '../../actions';

export default function SelectCarrera(props) {
  const {form, setform, handleStep, handleBackStep, step, lang, oportunityForm, handleForm} = props;
  const [button, setButton] = React.useState(true);
  const[other,setOther]=useState(false)
  const dispatch = useDispatch();
  useEffect(() => {
    if (oportunityForm?.SeleccionaCarrera ? oportunityForm?.SeleccionaCarrera : form?.SeleccionaCarrera) {
      setButton(false);
    }
    return () => {
      setButton(true);
    }
    //eslint-disable-next-line
  }, [form.SeleccionaCarrera]);

  const handleChange = (event) => {
    handleForm({...form, SeleccionaCarrera: event.target.value, habilidadesRequeridas: []});
    dispatch(saveOportunityForm({SeleccionaCarrera: event.target.value, habilidadesRequeridas: []}, step));
    setButton(false);
  }
  const handleChangeChip = (chip) => {
    setform({
      ...form,
      empleos_alternativos: chip,
    });
  }
  const handleDeleteChip = (chipToDelete) => {
    setform({
      ...form,
      empleos_alternativos: form?.empleos_alternativos?.filter((chip) => chip.key !== chipToDelete.key),
    });
  }


  const handleOther=()=>{
      setOther(true)
      
  }

  const handleNotOther=()=>{
    setOther(false)
    
}

  return (
    <>
      <div className='contenedorModalidad' id={lang !== 'es' ? 'eng' : '#'}>
        <h3 style={{
          fontFamily: 'Poppins',
          fontWeight: '700',
          fontSize: '14px',
          marginBottom: '20px',
          marginLeft: '-10px'
        }}>{lang === 'es' ? 'Selecciona la carrera del profesional o área de conocimiento. ' : "Select the professional's career or area of ​​knowledge"} </h3>
        <RadioGroup
          aria-labelledby="demo-controlled-radio-buttons-group"
          name="tipoDeServicioDeseado"
          value={oportunityForm?.SeleccionaCarrera ? oportunityForm?.SeleccionaCarrera : form?.SeleccionaCarrera}
        >
          <div className='modalidadRadio'>
            <div style={{display: 'flex', flexDirection: 'column'}}>
              <FormControlLabel name="SeleccionaCarrera" value="Agronomía, Veterinaria y afines"
                                control={<Radio icon={<CircleIcon className='radio_unchecked_icon'/>}
                                                checkedIcon={<CircleIcon className='radio_checked_icon'/>}/>}
                                onChange={handleChange}
                                onClick={handleNotOther}
                                label={<Typography sx={{fontSize: '14px', fontFamily: 'Poppins', fontWeight: '400'}}>
                                  {lang === 'es' ? 'Agronomía, Veterinaria y afines' : 'Agronomy, veterinary and related'}
                                </Typography>}/>

              <FormControlLabel value="Bellas Artes" name="SeleccionaCarrera" onChange={handleChange}
              onClick={handleNotOther}
                                control={<Radio icon={<CircleIcon className='radio_unchecked_icon'/>}
                                                checkedIcon={<CircleIcon className='radio_checked_icon'/>}/>}
                                label={<Typography sx={{fontSize: '14px', fontFamily: 'Poppins', fontWeight: '400'}}>
                                  {lang === 'es' ? 'Bellas Artes' : 'Fine Arts'}
                                  
                                </Typography>}/>

              <FormControlLabel value="Ciencias de la educación" name="SeleccionaCarrera" onChange={handleChange}
                                control={<Radio icon={<CircleIcon className='radio_unchecked_icon'/>}
                                                checkedIcon={<CircleIcon className='radio_checked_icon'/>}/>}
                                                onClick={handleNotOther}
                                label={<Typography sx={{fontSize: '14px', fontFamily: 'Poppins', fontWeight: '400'}}>
                                  {lang === 'es' ? 'Ciencias de la educación' : 'Educational sciences'}
                                </Typography>}/>

              <FormControlLabel name="SeleccionaCarrera" value="Ciencias de la salud"
                                control={<Radio icon={<CircleIcon className='radio_unchecked_icon'/>}
                                                checkedIcon={<CircleIcon className='radio_checked_icon'/>}/>}
                                onChange={handleChange}
                                onClick={handleNotOther}
                                label={<Typography sx={{fontSize: '14px', fontFamily: 'Poppins', fontWeight: '400'}}>
                                  {lang === 'es' ? 'Ciencias de la salud' : 'Health sciences'}
                                </Typography>}/>

              <FormControlLabel name="SeleccionaCarrera" value="Ciencias sociales y humanas"
                                control={<Radio icon={<CircleIcon className='radio_unchecked_icon'/>}
                                                checkedIcon={<CircleIcon className='radio_checked_icon'/>}/>}
                                onChange={handleChange}
                                onClick={handleNotOther}
                                label={<Typography sx={{fontSize: '14px', fontFamily: 'Poppins', fontWeight: '400'}}>
                                  {lang === 'es' ? 'Ciencias sociales y humanas' : 'Social and human sciences'}
                                </Typography>}/>
            </div>
            <div style={{display: 'flex', flexDirection: 'column'}}>
              <FormControlLabel value="Economía, administración, contaduría y afines" name="SeleccionaCarrera"
                                onChange={handleChange}
                                onClick={handleNotOther}
                                control={<Radio icon={<CircleIcon className='radio_unchecked_icon'/>}
                                                checkedIcon={<CircleIcon className='radio_checked_icon'/>}/>}
                                label={<Typography sx={{fontSize: '14px', fontFamily: 'Poppins', fontWeight: '400'}}>
                                  {lang === 'es' ? 'Economía, administración, contaduría y afines' : 'Economics, administration, accounting and related'}
                                </Typography>}/>

              <FormControlLabel value="Ingeniería, arquitectura, urbanismo y afines" name="SeleccionaCarrera"
                                onChange={handleChange}
                                onClick={handleNotOther}
                                control={<Radio icon={<CircleIcon className='radio_unchecked_icon'/>}
                                                checkedIcon={<CircleIcon className='radio_checked_icon'/>}/>}
                                label={<Typography sx={{fontSize: '14px', fontFamily: 'Poppins', fontWeight: '400'}}>
                                  {lang === 'es' ? 'Ingeniería, arquitectura, urbanismo y afines' : 'Engineering, architecture, urban planning and related'}
                                </Typography>}/>

              <FormControlLabel value="Matemáticas y ciencias naturales" name="SeleccionaCarrera"
                                onChange={handleChange}
                                onClick={handleNotOther}
                                control={<Radio icon={<CircleIcon className='radio_unchecked_icon'/>}
                                                checkedIcon={<CircleIcon className='radio_checked_icon'/>}/>}
                                label={<Typography sx={{fontSize: '14px', fontFamily: 'Poppins', fontWeight: '400'}}>
                                  {lang === 'es' ? 'Matemáticas y ciencias naturales' : 'Mathematics and natural sciences'}
                                </Typography>}/>

              <FormControlLabel name="SeleccionaCarrera" value="Otro"
                                control={ other ? <Radio icon={<CircleIcon className='radio_checked_icon'/>} checkedIcon={<CircleIcon className='radio_checked_icon'/>}/> : <Radio icon={<CircleIcon className='radio_unchecked_icon'/>}
                                                checkedIcon={<CircleIcon className='radio_checked_icon'/>}/>}
                                onChange={handleChange}
                                onClick={handleOther}
                                label={<Typography sx={{fontSize: '14px', fontFamily: 'Poppins', fontWeight: '400'}}>
                                  {lang === 'es' ? 'Otro' : 'Other'}

                                  {
                                    other ? 

                                    (<div>
                                      <input onChange={handleChange}
                                      
                                      />
                                    </div>)
                                    : null 
                                  }
                                </Typography>}/>

              <FormControlLabel name="SeleccionaCarrera" value="N/A"
                                control={<Radio icon={<CircleIcon className='radio_unchecked_icon'/>}
                                                checkedIcon={<CircleIcon className='radio_checked_icon'/>}/>}
                                onChange={handleChange}
                                onClick={handleNotOther}
                                label={<Typography sx={{fontSize: '14px', fontFamily: 'Poppins', fontWeight: '400'}}>
                                  {lang === 'es' ? 'N/A' : 'N/A'}
                                </Typography>}/>
            </div>
          </div>
        </RadioGroup>
        {oportunityForm?.SeleccionaCarrera ? oportunityForm?.SeleccionaCarrera === "Otro" : form?.SeleccionaCarrera === "Otro" ?
          <div style={{width: '80%'}}>
            <ChipInput onChange={(chips) => handleChangeChip(chips)}
                       onDelete={(chip, index) => handleDeleteChip(chip, index)}
                       style={{width: '80%'}}
                       placeholder={lang === 'es' ? 'introduce una profesión y luego enter!' : 'introduce a profession and then enter!'}
                       fullWidthInput={true} disableUnderline={true} alwaysShowPlaceholder={false}
                       defaultValue={oportunityForm?.SeleccionaCarrera ? oportunityForm?.SeleccionaCarrera : form?.empleos_alternativos}
                       allowDuplicates={false}
            />
          </div> : null}
      </div>
      <div
        style={{
          display: "flex",
          width: "100%",
          justifyContent: "space-between",
          padding: "1rem 3rem 0rem 3rem",
          alignItems: "center",
          // marginBottom: '-50px'
        }}
      >
        <button className='button_siguiente_create_B'
                onClick={handleBackStep}>{lang === 'es' ? 'Anterior' : 'Previous'}</button>
        <h3 className='stepCounter'>{`${step}/14`}</h3>
        <button className='button_siguiente_create'
                onClick={() => handleStep('NombreOportunidad')}
                disabled={button}>{lang === 'es' ? 'Siguiente' : 'Next'}</button>
      </div>
    </>
  )
}
