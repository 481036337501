/*eslint-disable*/
import React, {useEffect, useState} from 'react'
import Sidebar from "../../../components/Sidebar/Sidebar";
import Navbar from "../../../components/Navbar/Navbar";
import TestCardBootcamp from "./TestCardBootcamp";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import {useHistory} from 'react-router';
import {useSelector} from 'react-redux';
import './TestsBootcamp.css';
import {Link} from 'react-router-dom';
import axios from 'axios';


const TestsBootcamp = () => {

  const history = useHistory();
  const {lang, user} = useSelector(state => state);
  const [userBootcamp, setUserBootcamp] = useState()

  // useEffect(() => {
  //   if (!user?.bootcampAprobado) {
  //     history.push('/consultor/home')
  //   }
  // }, [user])

  useEffect(() => {
    axios.get(`https://findally-backend.herokuapp.com/api/userBootcamp/${user.id}`)
      .then(r => {
        setUserBootcamp(r.data.data)
      })
  }, [user.id, userBootcamp])


  const tests = [
    {
      id: 1,
      name: 'Test de CI',
      description: 'Realice este test de Cl rápido para hacerse una idea de cómo es un tests de Cl real, el resultado que debes colocar es la cantidad de respuestas correctas que obtuviste, te saldrá este calor al terminar la prueba.',
    },
    {
      id: 2,
      name: 'Test de personalidad',
      description: 'Realice este test para saber más sobre si mismo y sus puntos fuertes. Por favor una vez lo completes compártenos la URL del informe de resultados. ',
    },
    {
      id: 3,
      name: 'Test de orientación profesional',
      description: ' Este test de orientación le ofrecerá como resultado una lista de profesiones y ocupaciones que encajan con su perfil profesional. Una vez termines esta prueba por favor consorte en los resultados la URL donde muestran los resultados que obtuviste.',
    },
    {
      id: 4,
      name: 'Test de inteligencia',
      description: 'A continuación se te mostrarán distintas placas, tendrás que decidir cuál es la figura que completa a cada una. El test consta de 60 placas, te recomendamos que lo hagas con tiempo. Para completar el test cuentas con 45 minutos, ellos son suficientes así que no te apures. Al finalizar obtendrás tu coeficiente intelectual en base a los valores estadísticos para tu edad.',
    },
    {
      id: 5,
      name: 'Test vocacional',
      description: 'El test le mostrara las áreas en las que tiene interés y las áreas en las que, según el test, tiene más aptitudes para desempeñar. Puede que le gusten las carreras de una área determinada pero que tenga mejores aptitudes para desempeñarse en otra. O puede que sus intereses coincidan con sus aptitudes.',
    }
  ]

  const testsENG = [
    {
      id: 1,
      name: 'IQ test',
      real: 'IQ',
      description: 'Take this quick IQ test to get an idea of ​​what a real IQ test is like, the result you must enter is the number of correct answers you got, this heat will come out when you finish the test.',
    },
    {
      id: 2,
      name: 'Personality test',
      description: 'Take this test to find out more about yourself and your strengths. Please, once you complete it, share the URL of the results report.',
    },
    {
      id: 3,
      name: 'Vocational orientation test',
      description: 'This orientation test will offer you as a result a list of professions and occupations that fit your      professional profile. Once you finish this test please consort in the results the URL where they show the results you obtained.',
    },
    {
      id: 4,
      name: 'Intelligence test',
      description: '',
    },
    {
      id: 5,
      name: 'Vocational test',
      description: '',
    }
  ]

  return (
    <div className='container-tests'>
      <div className='Bootcamp-Subcontainer-Tests'>
        {lang === 'es'
          ? tests.map((test, i) => {
            return (
              <TestCardBootcamp key={i} id={test.id} name={test.name} description={test.description} user={user}
              />
            )
          })
          : testsENG.map((test, i) => {
            return (
              <TestCardBootcamp key={i} id={test.id} name={test.name} description={test.description} user={user}
              />
            )
          })
        }
      </div>
    </div>
  )
}

export default TestsBootcamp