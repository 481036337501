import { useContext, useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { SchneiderContext } from "../../../../context/SchneiderContext";
import { ReduxStateInterface } from "../../../../types/reduxInterface";
import { fetchSchneiderData, fetchOpportunitiesData } from "./fetchData";

export const useRankedOpportunities = () => {
  const { cv } = useSelector((state: ReduxStateInterface) => state);
  const { schneider } = useContext(SchneiderContext);
  const [opportunities, setOpportunities] = useState([]);

  useEffect(() => {
    fetchSchneiderData({ name: schneider.name }).then((data) => {
      if (cv && data.elements) {
        fetchOpportunitiesData(data.elements, cv).then((data) => {
          setOpportunities(data);
        });
      } else {
        setOpportunities(data.elements);
      }
    });
  }, [opportunities, schneider.name, cv]);

  return { opportunities };
};
