import styled from "styled-components";

const TestCardButton = styled.button<{ color?: string }>`
  margin-top: 15px;
  width: 90%;
  background-color: ${(props) => (props.color ? props.color : "#F07130")};
  color: white;
  border-radius: 5px;
`;

export default TestCardButton;
