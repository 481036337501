import React from "react";
/* import StripeContainer from "./StripeContainer";
 */import HeaderEmpresa from "../../../components/HeaderEmpresa/HeaderEmpresa";
import "./Styles/styles.css";
import PaymentComponent from "./PaymentComponent";

/* import { BrowserRouter as Link } from "react-router-dom";
 */
function PaymentCompany(props) {

    return (
        <>
        {/* pk_live_51IBVN9GrrihNCHnFY2lPJjMZeLcqAiPLAjEaqGQ9444428026NUxcCAGFWUKVneGQaWJb4sPHNFvGdXoLF9N8liw00m5UqU5G6 */}
        {/* pk_test_51IBVN9GrrihNCHnF69oHWbXCbpwJFpF2taGwA4lBwESnpeE2j1BOBiVNzEE8DueABcyAlOlmNI63d0SzbFGOjQF800iURjUyuh */}
        {!document.cookie.includes('Aid=')?null:<HeaderEmpresa />}
            <div className="section-tittle-out-form">
                <h2 className="tittle-out-form">Checkout Coally</h2>
            </div>
            <div className="PaymentRoot">
                <PaymentComponent
                    keys={{
                        stripe: "pk_test_51IBVN9GrrihNCHnF69oHWbXCbpwJFpF2taGwA4lBwESnpeE2j1BOBiVNzEE8DueABcyAlOlmNI63d0SzbFGOjQF800iURjUyuh"
                    }}
                />
            </div>
        </>
    );
}

export default (PaymentCompany);