import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import '../Styles/CardInfoCompany.css'
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import axios from 'axios';

function CardInfoCompany({ user, dataUser }) {

    const lang = useSelector((state) => state.lang)
    const [open, setOpen] = useState(false)
    const [projectsAll, setProjectsAll] = useState()


    useEffect(() => {
        axios.get(`https://findally-backend.herokuapp.com/api/user/projects/Company/${user}`)
            .then((res) => {
                setProjectsAll(res.data.projects)
            })
            .catch((err) => {
                console.log(err)
            })
    }, [user])



    const handleClick = () => {
        setOpen(!open)
    }

    return (
        <div className='container-newinfoCompany'>
            <div className='subcontainer-newinfoCompany'>
                <div className='newprojects-company'>
                    <h3>{lang === 'es' ? 'Oportunidades publicadas' : 'Posted opportunities'}</h3>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <h1>{projectsAll?.length}</h1>
                        <button style={{ backgroundColor: 'transparent' }}
                            onClick={handleClick}>
                            <KeyboardArrowDownIcon />
                        </button>
                    </div>
                    {open ? projectsAll && projectsAll.map((project, index) => {
                        return (
                            <div className='detalles-oportunidades' key={index}>
                                <p>{project.NombreOportunidad === 'Unnamed Oportunity' ? project.nombre : project.NombreOportunidad}</p>
                                <span>{project.fecha_inicio.slice(0, 10)}</span>
                            </div>
                        )
                    }) : null}
                    <hr></hr>
                </div>
                <div className='newinfo-company'>
                    <div className='newinfo-subcontainer-company'>
                        <h3>{lang === 'es' ? 'Sector' : 'Sector'}</h3>
                        <p>{dataUser?.sector ? dataUser?.sector : '-'}</p>
                        <hr style={{width: '90%' , color: '#ececec'}} />
                        <h3>{lang === 'es' ? 'Especialidades' : 'Specialties'}</h3>
                        {dataUser?.especialidades ? dataUser?.especialidades.map((especialidad, index) => {
                            return (
                                <p key={index}>{especialidad}</p>
                            )
                        })
                            : <p>-</p>}
                    </div>
                    <div className='newinfo-subcontainer-company'>
                        <h3>{lang === 'es' ? 'Tamaño de la empresa' : `Company's size`}</h3>
                        <p>{dataUser?.tamanio ? dataUser?.tamanio : '-'}</p>
                        <hr style={{width: '90%' , color: '#ececec'}} />
                        <h3>{lang === 'es' ? 'Sitio WEB' : 'Website'}</h3>
                        <p>{dataUser?.sitio_web ? dataUser?.sitio_web : '-'}</p>
                        <hr style={{width: '90%' , color: '#ececec'}} />
                        <h3>{lang === 'es' ? 'Fundación' : 'Foundation'}</h3>
                        <p>{dataUser?.fecha_fundacion ? dataUser.fecha_fundacion : '-'}</p>
                        <p>{dataUser?.ciudad_fundacion ? dataUser?.ciudad_fundacion : '-'}</p>

                    </div>
                </div>
            </div>
        </div>
    )
}

export default CardInfoCompany
