import styled, { createGlobalStyle } from "styled-components";
import { IoIosSearch } from "react-icons/io";
const GlobalStyle = createGlobalStyle<{ color?: string }>`
  h1 {
    color: ${(props) => (props.color ? props.color : "#202256")};
  }
  h2 {
    color: ${(props) => (props.color ? props.color : "#202256")};
  }
  button {
    color: ${(props) => (props.color ? props.color : "#202256")};
  }
`;

const ComponentWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
`;

const BodyWebPapa = styled.div`
  width: calc(100vw - 237px);
  padding: 0 2rem;
  position: absolute;
  right: 0;

  @media (max-width: 1300px) {
    width: 100vw;
  }

  @media (max-width: 768px) {
    padding: 0 1rem;
  }
`;

const DefaultColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

const SearchIcon = styled(IoIosSearch)<{ color?: string }>`
  font-size: 20px;
  color: ${(props) => (props.color ? props.color : "#202256")};
  position: absolute;
  margin-top: 5px;
  margin-left: 5px;
`;

export {
  GlobalStyle,
  BodyWebPapa,
  ComponentWrapper,
  SearchIcon,
  DefaultColumnContainer,
};
