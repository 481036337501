import React from 'react';
import {
    LineChart,
    ResponsiveContainer,
    Legend, Tooltip,
    Line,
    XAxis,
    YAxis,
    CartesianGrid
} from 'recharts';
import Title from './Title';
  
function LineChartGrap({data, title, type}) {
    return (
        <React.Fragment>
            <Title>{title}</Title>
            <ResponsiveContainer width="100%" aspect={3}>
                {}
                <LineChart data={data} margin={{ right: 300 }}>
                    <CartesianGrid />
                    <XAxis dataKey="date" 
                        interval={'preserveStartEnd'} />
                    <YAxis></YAxis>
                    <Legend />
                    <Tooltip />
                    <Line dataKey={type? "Company": "Proyectos"}
                        stroke="black" activeDot={{ r: 8 }} />
                { type? <Line dataKey="Profesional"
                        stroke="red" activeDot={{ r: 8 }} />: null}
                </LineChart>
            </ResponsiveContainer>
        </React.Fragment>
    );
}
  
export default LineChartGrap;