/* eslint-disable  */
import React, {useState, useEffect} from "react";
import axios from "axios";
import {useHistory} from "react-router-dom";
import HeadertopV2 from "../headertop/HeadertopV2";
import Header from "../header/Headerprofesional";
import SearchIcon from "@mui/icons-material/Search";
import EducationCard from "./educationCard";
import {useSelector} from "react-redux";
import "./educationCompany.css";
import Sidebar from "../../components/Sidebar/Sidebar";
import Navbar from "../../components/Navbar/Navbar";

export default function EducationCompany() {
  const {lang, user} = useSelector(state => state)
  const history = useHistory();
  const [courses, setCourses] = useState([])
  const [searchCourses, setSearchCourses] = useState([])
  const [categories, setCategories] = useState([])
  const [tab, setTab] = useState(0)
  const [search, setSearch] = useState('')
  const cancel = new AbortController()
  const engCourses = ['Personal Development', 'Profesional Development', 'Resume', 'Interviews', 'Professional Profile']

  useEffect(() => {
    axios.get('https://findally-backend.herokuapp.com/api/Courses/filter?tipo=empresa', {
      signal: cancel.signal
    })
      .then(res => {
        console.log('asdasd', res)
        setCourses(res.data)
      })
      .catch((r) => {
        if (r.name === 'AbortError') return console.log('fetch aborted');
        console.log(r)
      })
    return () => {
      cancel.abort()
    }
  }, [])

  useEffect(() => {
    if (courses.length > 0) {
      let categorias = courses.map(course => course.categoria)
      setCategories(categorias.filter((item, index) => categorias.indexOf(item) === index))
    }
  }, [courses])

  console.log(categories)

  function handleSearch(e) {
    setSearch(e.target.value)
    setSearchCourses(courses?.filter(course => course.titulo.toLowerCase().includes(e.target.value.toLowerCase())) || course.description.toLowerCase().includes(e.target.value.toLowerCase()))
  }

  return (
    <div className="contenedor-oportunidad">
      {user && user.roles === 'company' ? null : history.push('/consultor/home')}
      <div style={{backgroundColor: '#F5F5F5'}}>
        <Sidebar location={'formación'}/>
        <div
          className="BodyWeb"
          style={{padding: "0rem 2rem", position: "absolute", right: "0"}}>
          <Navbar locate={'formación'}/>
          <h1
            style={{
              margin: "0",
              fontSize: "40px",
              fontFamily: "Poppins",
              fontWeight: "700",
              color: "#202256",
              marginBottom: "10px",
            }}
          >{lang === 'es' ? 'Formación' : 'Training'}
          </h1>
          <div style={{display: "flex", flexDirection: 'column'}}>
            <div
              className={'sub-search-responsive'}>
              <h2 style={{
                width: "100%",
                fontSize: "24px",
                fontWeight: "700",
                color: "#393939",
              }}>
                {lang === 'es' ? 'Plataforma Coally' : 'Coally platform'}
              </h2>
              <div
                style={{marginBottom: '30px', display: 'flex', justifyContent: 'space-between'}}>
                <SearchIcon
                  style={{
                    fontSize: "30px",
                    color: "#B3B6B6",
                    position: "absolute",
                    marginTop: "3px",
                    marginLeft: "5px",
                  }}
                />
                <input
                  className={'input-responsive-box'}
                  type={"search"}
                  placeholder={lang === 'es' ? 'Buscar...' : 'Search...'}
                  onChange={(e) => handleSearch(e)}
                />
              </div>
            </div>
            <div
              className={'sub-video-responsive'}>
              <div className={'iframe-video-container'}>
                <iframe
                  className={'iframe-responsive-education'}
                  src="https://www.youtube.com/embed/l1lqPBxANFY"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
              </div>
              <div className={'grid-view-responsive-2'}>
                {courses?.slice(0, 3).map((item, index) => (
                  <EducationCard name={item.titulo} video={item.video}></EducationCard>
                ))}
              </div>
            </div>
          </div>
          {search.length ?
            <div className="second-section">
              <div style={{display: "flex", flexDirection: 'row', alignItems: 'flex-start'}}>
                <h3 style={{
                  fontSize: "16px",
                  fontWeight: "700",
                  color: "#393939",
                  marginTop: "-20px",
                  marginRight: "70px"
                }}>
                  {lang === 'es' ? 'Resultados para la búsqueda: ' : 'Search results: '}
                  {search}
                </h3>
              </div>
              <div style={{borderTop: 'solid', width: '83%', borderColor: '#D6D6D6'}}/>
              <div style={{marginTop: '3%'}}>
                <div className={'grid-view-responsive'}>
                  {searchCourses?.map((item, index) => (
                    <EducationCard name={item.titulo} video={item.video}></EducationCard>
                  ))}
                </div>
              </div>
            </div> :
            <div className="second-section">
              {lang === 'es' ? <div className={"tab-title-responsive"}>
                  {categories?.map((item, index) => (
                    <a href="#/" onClick={() => setTab(index)}>
                      <h3 className={'tab-title-responsive-2'}
                          key={index}
                          style={{fontWeight: tab === index ? "700" : "400",}}>{item}</h3>
                      <div style={{borderTop: tab === index ? '5px solid #202256' : '0', width: '79px'}}/>
                    </a>
                  ))}
                </div> :
                <div className={"tab-title-responsive"} style={{justifyContent: "space-between"}}>
                  {engCourses?.map((item, index) => (
                    <a href="#/" onClick={() => setTab(index)}>
                      <h3 key={index}
                          className={'tab-title-responsive-2'}
                          style={{
                            // fontSize: "16px",
                            fontWeight: tab === index ? "700" : "400",
                          
                        
                            // color: "#393939",
                            // marginTop: "-20px",
                            // marginRight: index < 2 ? '70px' : '0',
                          }}>{item}</h3>
                      <div style={{borderTop: tab === index ? '5px solid #202256' : '0', width: '79px'}}/>
                    </a>
                  ))}
                </div>
              }
              <div style={{borderTop: 'solid', width: '100%', borderColor: '#D6D6D6'}}/>
              <div style={{display: "flex", alignItems: "center", justifyContent: "center", marginTop: '3%'}}>
                {tab === 0 && (<div className={'grid-view-responsive'}>
                  {courses?.filter(el => el.categoria === categories[0]).map((item, index) => (
                    <EducationCard name={item.titulo} video={item.video}></EducationCard>
                  ))}
                </div>)}
                {tab === 1 && (<div className={'grid-view-responsive'}>
                  {courses?.filter(el => el.categoria === categories[1]).map((item, index) => (
                    <EducationCard name={item.titulo} video={item.video}></EducationCard>
                  ))}
                </div>)}
                {tab === 2 && (<div className={'grid-view-responsive'}>
                  {courses?.filter(el => el.categoria === categories[2]).map((item, index) => (
                    <EducationCard name={item.titulo} video={item.video}></EducationCard>
                  ))}
                </div>)}
                {tab === 3 && (<div className={'grid-view-responsive'}>
                  {courses?.filter(el => el.categoria === categories[3]).map((item, index) => (
                    <EducationCard name={item.titulo} video={item.video}></EducationCard>
                  ))}
                </div>)}
                {tab === 4 && (<div className={'grid-view-responsive'}>
                  {courses?.filter(el => el.categoria === categories[4]).map((item, index) => (
                    <EducationCard name={item.titulo} video={item.video}></EducationCard>
                  ))}
                </div>)}
              </div>
            </div>}
        </div>
      </div>
    </div>
  )
}