import React, { Fragment, useState, useMemo, useCallback, useEffect } from 'react';
import differenceBy from 'lodash/differenceBy';
import DataTable from 'react-data-table-component';
import { connect } from "react-redux";
import { getProjectsAdmin } from "../../../actions";
import EditIcon from '@mui/icons-material/Edit';
import StarOutlineIcon from '@mui/icons-material/StarOutline';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
//import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { MenuItem, Rating, Select } from '@mui/material';
import axios from 'axios';
import { toast } from 'react-toastify';

function DataTableComponent(props) {

  useEffect(() => {
    const getData = () => {
      props.getProjectsAdmin()
    }

    getData();  // eslint-disable-next-line
  }, []); // eslint-disable-next-line


  const [selectedRows, setSelectedRows] = useState([]);
  const [toggleCleared, setToggleCleared] = useState(false);
  const [data, setData] = useState(props.projects_admin);
  const [edit, setEdit] = useState({});
  const [open, setOpen] = useState(false);
  const [openScore, setOpenScore] = useState(false);
  const [score, setScore] = useState();
  const [stars, setStars] = useState();
  const [idProyecto, setIdProyecto] = useState({});

  //Edit 
  const onEdit = (e) => {
    setEdit(e);
    setOpen(true)

  };
  const handleClose = () => {
    setOpen(false);
    setOpenScore(false);
  };

  const handleStatusChange = (event) => {
    setEdit(

      { ...edit, status: event.target.value }
    );
  };

  const handleOnChange = (e) => {
    e.preventDefault();
    setEdit(
      { ...edit, [e.target.name]: e.target.value }
    );
  };

  const handleOnChangeLenguaje = (e) => {
    e.preventDefault();
    setEdit(
      { ...edit, Lenguaje: [e.target.value.split(' ')] }
    );
  };


  const handleOnSubmit = (e) => {
    e.preventDefault();

    axios.put(`https://findally-backend.herokuapp.com/api/project/${edit._id}`, edit)
      .then(res => {
        toast.success('Project updated successfully',{
          position:"top-right",         
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setOpen(false);
        props.getProjectsAdmin();
      })
      .catch(err => {
        toast.error('Error updating project',{
          position:"top-right",         
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  };

  //Score
  const onScore = (e) => {
    setIdProyecto(e);
    axios(`https://findally-backend.herokuapp.com/api/Project/match/recomendation/${e._id}`)
      .then(res => {
        setScore(res.data.applicants);
        console.log(res.data.applicants);
        setOpenScore(true);
      })
  };

  const handleOnChangeStars = (e) => {
    e.preventDefault();
    setStars(Number(e.target.value));
  };

  const handleOnSubmitStars = (e) => {   
    axios.put(`https://findally-backend.herokuapp.com/api/Project/update/hr/${idProyecto._id}`,
      {
        applicants_hr_recommendation: [{
          calification: stars,
          user: e.usuario
        }]
      })
      .then(res => {
        toast.success('Usuario calificado correctamente',{
          position:"top-right",         
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
      )
      .catch(err => {
        toast.error('Usuario no calificado',{
          position:"top-right",         
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
      );
    setStars(score.stars)
  };


  const customStyles = {
    headCells: {
      style: {
        fontWeight: 'bold',
        fontSize: '14px',
      },
    },
    cells: {
      style: {
        textTransform: 'capitalize',
      },
    },
  };


  const columns = [

    {
      name: 'ID',
      selector: '_id',
      sortable: true,
      center: true,
    },
    {
      name: 'Nombre',
      selector: row => row.NombreOportunidad === 'Unnamed Oportunity' ? row.nombre.toLowerCase() : row.NombreOportunidad.toLowerCase(),
      sortable: true,
      center: true,
    },
    {
      name: 'Status',
      selector: 'status',
      sortable: true,
      center: true,
    },
    {
      name: 'Ciudad',
      selector: 'ciudad',
      sortable: true,
      center: true,
    },
    {
      name: 'Fecha_inicio',
      selector: row => row.fecha_inicio.slice(0, 10),
      sortable: true,
      center: true,
    },
    {
      name: 'Idioma',
      selector: row => row.Lenguaje.length > 1 ? row.Lenguaje[0] + '-' + row.Lenguaje[1] : row.Lenguaje,
      sortable: true,
      center: true,
    },
    {
      key: "action",
      text: "Action",
      className: "action",
      width: 100,
      align: "left",
      sortable: false,
      cell: (record) => {
        return (
          <Fragment>
            <button
              onClick={() => {
                onEdit(record);
              }}
              style={{ marginRight: '10px' }}
            >
              <EditIcon />
            </button>
            <button
              onClick={() => {
                onScore(record);
              }}
            >
              <StarOutlineIcon />
            </button>
          </Fragment>
        );
      },
    },


    // {
    //   name: 'Usuario',
    //   selector: 'usuario',
    //   sortable: true,
    //   center: true,
    // },
    // {
    //   name: 'Codigo_promocional',
    //   selector: 'codigo_promocional',
    //   sortable: true,
    //   center: true,
    // },
  ];



  const handleRowSelected = useCallback(state => {
    setSelectedRows(state.selectedRows);
  }, []);



  //Delete
  const contextActions = useMemo(() => {
    const handleDelete = () => {

      if (window.confirm(`Are you sure you want to delete:\r ${selectedRows.map(r => r.name)}?`)) {
        setToggleCleared(!toggleCleared);
        setData(differenceBy(data, selectedRows, 'name'));
        toast.success("Successfully Deleted !",{
          position:"top-right",         
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        })
      }
    };

    return <button key="delete" onClick={handleDelete}>{"Delete"}</button>;
  }, [data, selectedRows, toggleCleared]);

  //Loading
  const [pending, setPending] = useState(true);
  const estado = props.projects_admin?.length
  useEffect(() => {
    if (estado > 0) {
      setPending(false);
    }
  }, [estado]);

  //search
  const [filterText, setFilterText] = useState('');
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const filteredItems = props.projects_admin?.filter(
    item => item.NombreOportunidad.toLowerCase().includes(filterText.toLowerCase()) || item._id.toLowerCase().includes(filterText.toLowerCase())
  );

  const subHeaderComponentMemo = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText('');
      }
    };


    return (
      <div>
        <input placeholder='Search by Name or Id' onChange={(e) => setFilterText(e.target.value)} value={filterText} style={{
          borderRadius: '3px',
          borderTopLeftRadius: '5px',
          borderBottomLeftRadius: '5px',
          borderTopRightRadius: 0,
          borderBottomRightRadius: 0,
          border: '1px solid #e5e5e5',
          height: '32px',
          width: '200px',
          padding: '0 32px 0 16px',
        }} />
        <button onClick={handleClear} style={{
          borderTopLeftRadius: 0,
          borderBottomLeftRadius: 0,
          borderTopRightRadius: '5px',
          borderBottomRightRadius: '5px',
          height: '34px',
          width: '32px',
        }}>
          X
        </button>
      </div>
    );
  }, [filterText, resetPaginationToggle]);


  return (
    <Fragment>
      <div>
        <div>
          <div>
            <div>
              <div>
                <DataTable
                  title='Proyectos'
                  progressPending={pending}
                  customStyles={customStyles}
                  columns={columns}
                  data={filteredItems}
                  subHeader
                  subHeaderComponent={subHeaderComponentMemo}
                  striped={true}
                  center={true}
                  selectableRows
                  persistTableHead
                  contextActions={contextActions}
                  onSelectedRowsChange={handleRowSelected}
                  clearSelectedRows={toggleCleared}
                  pagination
                />
              </div>
              <div>
                <Dialog
                  open={open}
                  onClose={handleClose}
                  scroll="paper"
                  fullWidth={true}
                  maxWidth="md"
                  aria-labelledby="scroll-dialog-title"
                  aria-describedby="scroll-dialog-description"
                >
                  <DialogTitle id="scroll-dialog-title" sx={{ fontSize: '24px', fontWeight: 700, color: '#202256' }}>
                    Editar Proyecto
                  </DialogTitle>
                  <DialogContent dividers={true} style={{ height: '600px', fontSize: '20px' }}>

                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                      <span>
                        Nombre:
                      </span>
                      <TextField
                        id="name"
                        label={edit.NombreOportunidad}
                        type="text"
                        name='NombreOportunidad'
                        onChange={handleOnChange}
                      />
                    </div>

                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                      <span>Status:</span>
                      <Select
                        autoFocus
                        value={edit.status}
                        onChange={handleStatusChange}
                        sx={{ width: '195px' }}
                      >
                        <MenuItem value="En espera">En espera</MenuItem>
                        <MenuItem value="En desarrollo">En desarrollo</MenuItem>
                        <MenuItem value="Finalizado">Finalizado</MenuItem>
                      </Select>
                    </div>

                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginRight: '10px' }}>
                      <span>
                        Ciudad:
                      </span>
                      <TextField
                        id="ciudad"
                        label={edit.ciudad}
                        type="text"
                        name='ciudad'
                        onChange={handleOnChange}
                      />
                    </div>

                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '20px', marginRight: '20px' }}>
                      <span>
                        Fecha inicio:
                      </span>
                      <TextField
                        id="name"
                        // label={edit.fecha_inicio?.slice(0, 10)}
                        type="date"
                        name='fecha_inicio'
                        onChange={handleOnChange}
                      />
                    </div>

                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '20px', marginRight: '10px' }}>
                      <span>
                        Idioma:
                      </span>
                      <TextField
                        id="name"
                        label={edit.Lenguaje}
                        type="text"
                        name='Lenguaje'
                        onChange={handleOnChangeLenguaje}
                      />
                    </div>


                    <div style={{ marginTop: '5%', display: 'flex', justifyContent: 'space-around', alignItems: 'center' }}>
                      <Button
                        onClick={handleOnSubmit}
                        sx={{ backgroundColor: 'transparent', height: '28px', width: '141px', borderRadius: '5px', borderStyle: 'solid', borderWidth: '1px', fontSize: '14px', fontWeight: '400', color: '#202256' }}

                      >
                        Guardar
                      </Button>
                      <Button
                        onClick={handleClose}
                        sx={{ backgroundColor: 'transparent', height: '28px', width: '141px', borderRadius: '5px', borderStyle: 'solid', borderWidth: '1px', fontSize: '14px', fontWeight: '400', color: '#202256' }}
                      >
                        Cancelar
                      </Button>
                    </div>
                  </DialogContent>
                </Dialog>
              </div>
              <div>
                <Dialog
                  open={openScore}
                  onClose={handleClose}
                  scroll="paper"
                  fullWidth={true}
                  maxWidth="md"
                  aria-labelledby="scroll-dialog-title"
                  aria-describedby="scroll-dialog-description"
                >
                  <DialogTitle id="scroll-dialog-title" sx={{ fontSize: '24px', fontWeight: 700, color: '#202256' }}>
                    Calificación RRHH Aplicantes
                  </DialogTitle>
                  <DialogContent dividers={true} style={{ height: '600px', fontSize: '20px' }}>
                    {score && score.length > 0 ? score?.map((item, index) => (
                      < div key={index} style={{ display: 'flex', justifyContent: 'center', marginTop: '20px', marginRight: '10px' }}>
                        <p>
                          {item.cv_file.info_personal.nombre}:
                        </p>
                        <Rating
                          name={item.cv_file.info_personal.nombre}
                          defaultValue={item.stars}
                          value={stars}
                          onChange={handleOnChangeStars}
                          sx={{ marginLeft: '10px', marginRight: '30px', marginTop: '2px', width: '100px', height: '30px', fontWeight: '400', color: '#202256' }}
                        />
                        <Button
                          onClick={() => handleOnSubmitStars(item.cv_file)}
                          sx={{ backgroundColor: 'transparent', height: '28px', width: '141px', borderRadius: '5px', borderStyle: 'solid', borderWidth: '1px', fontSize: '14px', fontWeight: '400', color: '#202256' }}

                        >
                          Enviar
                        </Button>
                      </div>
                    )) :
                      <span>No tiene aplicantes</span>
                    }

                  </DialogContent>
                </Dialog>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment >
  );
}
const mapDispatchToProps = {
  getProjectsAdmin,
};


const mapStateToProps = (state) => {
  return {
    projects_admin: state.projects_admin
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DataTableComponent);



