import React from "react";
import axios from "axios";
import "./CarruselCards.css";
// --------------- //
/*ESTUDIANTES FILES */
import mapaSuenos from "../files/mapa sueños.pdf";
import tallerSuenos from "../files/taller sueños.png";
import liderazgo from "../files/Descubriendo tu Liderazgo.pdf";
// ----------------- //
import ReactPlayer from "react-player";
const CarruselCardsStudent = ({
  image,
  title,
  desc,
  rol,
  video,
  link,
  id,
  user,
}) => {
  /*const downloadFile = (rol, title) => {
    if (rol === "ESTUDIANTE") {
      switch (title) {
        case "Mapa de los sueños":
          return window.open(mapaSuenos);
        case "Taller de los sueños":
          return window.open(tallerSuenos);
        case "Descubriendo tu liderazgo":
          return window.open(liderazgo);
        default:
          return;
      }
    }
  };*/

  const handleClickWAssistance = (id, title, video) => {
    if (!video) {
      switch (title) {
        case "Mapa de los sueños":
          return window.open(mapaSuenos);
        case "Taller de los sueños":
          return window.open(tallerSuenos);
        case "Descubriendo tu liderazgo":
          return window.open(liderazgo);
        default:
          return;
      }
    } else {
      axios
        .post(
          "https://findally-backend.herokuapp.com/api/unilever/assistance/click",
          {
            id: id,
            user_id: user,
          }
        )
        .then((res) => {
          console.log(res);
        });
    }
  };

  return (
    <div
      className={"CarruselCards-global-container"}
      style={{ cursor: "pointer" }}
      onClick={() => handleClickWAssistance(id, title, video)}
    >
      {image ? (
        <>
          {" "}
          <div
            className={"CarruselCards-image-container"}
            style={{
              backgroundImage: `url(${image})`,
            }}
          ></div>
          <div className={"CarruselCards-sub-container"}>
            <h1 className={"CarruselCards-global-title"}>{title}</h1>
            <p className={"CarruselCards-global-desc"}>{desc}</p>
          </div>{" "}
        </>
      ) : (
        <label className={"CarruselCards-video-container"}>
          <ReactPlayer
            url={link}
            controls={true}
            width={"100%"}
            height={"100%"}
            onStart={() => handleClickWAssistance(id, title, video)}
          />
        </label>
      )}
    </div>
  );
};

export default CarruselCardsStudent;
