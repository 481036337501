const economySector_es = [
    {value: 'Agricultura', label: 'Agricultura'},
    {value: 'Ganadería', label: 'Ganadería'},
    {value: 'Pesca', label: 'Pesca'},
    {value: 'Minería', label: 'Minería'},
    {value: 'Industria manufacturera', label: 'Industria manufacturera'},
    {value: 'Electricidad, gas y agua', label: 'Electricidad, gas y agua'},
    {value: 'Construcción', label: 'Construcción'},
    {value: 'Comercio', label: 'Comercio'},
    {value: 'Transporte y comunicaciones', label: 'Transporte y comunicaciones'},
    {value: 'Servicios financieros', label: 'Servicios financieros'},
    {value: 'Servicios inmobiliarios', label: 'Servicios inmobiliarios'},
    {value: 'Servicios empresariales', label: 'Servicios empresariales'},
    {value: 'Servicios personales', label: 'Servicios personales'},
    {value: 'Servicios sociales', label: 'Servicios sociales'},
    {value: 'Servicios comunitarios', label: 'Servicios comunitarios'},
    {value: 'Servicios públicos', label: 'Servicios públicos'},
    {value: 'Arquitectura', label: 'Arquitectura'},
    {value: 'Ingeniería', label: 'Ingeniería'},
    {value: 'Diseño', label: 'Diseño'},
    {value: 'Consultoría', label: 'Consultoría'},
    {value: 'Investigación y desarrollo', label: 'Investigación y desarrollo'},
    {value: 'Servicios de TI', label: 'Servicios de TI'},
    {value: 'Software', label: 'Software'},
    {value: 'Hardware', label: 'Hardware'},
    {value: 'Telecomunicaciones', label: 'Telecomunicaciones'},
    {value: 'Medios de comunicación', label: 'Medios de comunicación'},
    {value: 'Publicidad', label: 'Publicidad'},
    {value: 'Marketing', label: 'Marketing'},
    {value: 'Ventas', label: 'Ventas'},
    {value: 'Recursos humanos', label: 'Recursos humanos'},
    {value: 'Administración', label: 'Administración'},
    {value: 'Finanzas', label: 'Finanzas'},
    {value: 'Contabilidad', label: 'Contabilidad'},
    {value: 'Legal', label: 'Legal'},
    {value: 'Compras', label: 'Compras'},
    {value: 'Logística', label: 'Logística'},
    {value: 'Producción', label: 'Producción'},
    {value: 'Calidad', label: 'Calidad'},
    {value: 'Operaciones', label: 'Operaciones'},
    {value: 'Mantenimiento', label: 'Mantenimiento'},
    {value: 'Seguridad', label: 'Seguridad'},
    {value: 'Salud', label: 'Salud'},
    {value: 'Educación', label: 'Educación'},
    {value: 'Investigación', label: 'Investigación'},
    {value: 'Desarrollo', label: 'Desarrollo'},
    {value: 'Gobierno', label: 'Gobierno'},
    {value: 'Defensa', label: 'Defensa'},
    {value: 'Seguros', label: 'Seguros'},
    {value: 'Servicios', label: 'Servicios'},
]

const economySector_en = [
    {value: 'Agriculture', label: 'Agriculture'},
    {value: 'Livestock', label: 'Livestock'},
    {value: 'Fishing', label: 'Fishing'},
    {value: 'Mining', label: 'Mining'},
    {value: 'Manufacturing industry', label: 'Manufacturing industry'},
    {value: 'Electricity, gas and water', label: 'Electricity, gas and water'},
    {value: 'Construction', label: 'Construction'},
    {value: 'Trade', label: 'Trade'},
    {value: 'Transport and communications', label: 'Transport and communications'},
    {value: 'Financial services', label: 'Financial services'},
    {value: 'Real estate services', label: 'Real estate services'},
    {value: 'Business services', label: 'Business services'},
    {value: 'Personal services', label: 'Personal services'},
    {value: 'Social services', label: 'Social services'},
    {value: 'Community services', label: 'Community services'},
    {value: 'Public services', label: 'Public services'},
    {value: 'Architecture', label: 'Architecture'},
    {value: 'Engineering', label: 'Engineering'},
    {value: 'Design', label: 'Design'},
    {value: 'Consulting', label: 'Consulting'},
    {value: 'Research and development', label: 'Research and development'},
    {value: 'IT services', label: 'IT services'},
    {value: 'Software', label: 'Software'},
    {value: 'Hardware', label: 'Hardware'},
    {value: 'Telecommunications', label: 'Telecommunications'},
    {value: 'Media', label: 'Media'},
    {value: 'Advertising', label: 'Advertising'},
    {value: 'Marketing', label: 'Marketing'},
    {value: 'Sales', label: 'Sales'},
    {value: 'Human resources', label: 'Human resources'},
    {value: 'Administration', label: 'Administration'},
    {value: 'Finance', label: 'Finance'},
    {value: 'Accounting', label: 'Accounting'},
    {value: 'Legal', label: 'Legal'},
    {value: 'Purchasing', label: 'Purchasing'},
    {value: 'Logistics', label: 'Logistics'},
    {value: 'Production', label: 'Production'},
    {value: 'Quality', label: 'Quality'},
    {value: 'Operations', label: 'Operations'},
    {value: 'Maintenance', label: 'Maintenance'},
    {value: 'Security', label: 'Security'},
    {value: 'Health', label: 'Health'},
    {value: 'Education', label: 'Education'},
    {value: 'Research', label: 'Research'},
    {value: 'Development', label: 'Development'},
    {value: 'Government', label: 'Government'},
    {value: 'Defense', label: 'Defense'},
    {value: 'Insurance', label: 'Insurance'},
    {value: 'Services', label: 'Services'},
]
economySector_es.sort((a, b) => a.label.localeCompare(b.label));
economySector_en.sort((a, b) => a.label.localeCompare(b.label));
export const economySector = {
    es: economySector_es,
    en: economySector_en
}

//ordenar alfabeticamente
