/* eslint-disable  */
// window.localStorage.setItem('langlocal', 'es');
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import '../../../pages/Unilever/PostulateUnilever.css';
import LayerTop from "../../../assets/logos/layer-unilever.png";
import Logo from "../../../assets/logos/unilever.png";
import { FaFacebookF, FaInstagram, FaLinkedinIn } from "react-icons/fa";
import RingIzq from '../../../assets/logos/anillo-izquierda.png'
import RingDer from '../../../assets/logos/anillo-derecha.png'
import Firulete1 from '../../../assets/logos/firulete3.png'
import Firulete2 from '../../../assets/logos/firulete5.png'
import Select from 'react-select';
import Creatable from 'react-select/creatable';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import { useSelector } from 'react-redux';
import TermsAndConditions from '../../../components/termsAndConditions/TermsAndConditions';
import LogoCoally from '../../../assets/logos/Logo-Coally.png';
const customStyles = {
  option: (provided, state) => ({
    ...provided,
    color: state.isSelected ? 'white' : '#4a4a4a',
    backgroundColor: state.isSelected ? '#1f36c7' : '#f2f2f2',
    cursor: 'pointer',
    padding: 10,
  }),
  control: (provided, state) => ({
    ...provided,
    width: '100%',
    display: 'flex',
    alignItems: 'flex-start',
    padding: '0',
    justifyContent: 'center',
    borderRadius: '8px',
    background: 'transparent',
    backgroundColor: state.isSelected ? '#005eef' : '#F5F5F5',
    color: '#4a4a4a',
  }),
  menu: (provided, state) => ({
    ...provided,
    color: 'white',
    padding: '0',
    width: '98%',
    marginTop: '-1em'
  }),
  placeholder: (provided, state) => ({
    ...provided,
    color: 'white',
  }),
  singleValue: (provided, state) => ({
    ...provided,
    color: 'white',
  }),
  input: (provided, state) => ({
    ...provided,
    color: 'white',
  }),
  menuList: (provided, state) => ({
    ...provided,
    background: '#f2f2f2',
  }),
  multiValueRemove: (provided, state) => ({
    ...provided,
    color: 'white',
    cursor: 'pointer',
  }),
  multiValue: (provided, state) => ({
    ...provided,
    background: 'transparent',
    color: 'white',
  }),
  multiValueLabel: (provided, state) => ({
    ...provided,
    color: 'white',
  }),
}

export default function RegisterMentorUnilever() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);
  const [experienceCoach, setExperienceCoach] = useState(false);
  const [tyc, setTyc] = useState(false);
  const [popUp, setPopUp] = useState(false);
  const lang = useSelector(state => state.lang);
  const [form, setForm] = useState({
    name: '',
    lastName: '',
    email: '',
    password: '',
    phone: '',
    city: '',
    address: '',
    Date_Birth: '',
    gender: '',
    occupation: '',
    type_user_unilever: "MENTOR",
    mentoring_area: "",
    mentor_skills: [],
    professional_description: "",
    organization: "",
    rol: "",
    interest: "",
    years_experience: "",
    experience_coach: [],
    accept_policy: true,
    motivational_question: "",
    themes_interest: "",
  })

  const acceptTyC = () => {
    setTyc(true)
    setPopUp(false)
  }

  const sendForm = async (e) => {
    e.preventDefault();
    if (tyc === false) {
      toast.error('Debes aceptar los términos y condiciones', {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
    }
    if (form.name === '' || form.lastName === '' || form.email === '' || form.password === '' || form.Date_Birth === '') {
      return toast.error('Por favor, rellene todos los campos', {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
    }
    if(form.occupation === ''){
      return toast.error('Por favor, complete su ocupación', {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
    }
    if (form.password.length < 6) {
      return toast.error('La contraseña debe tener al menos 6 caracteres', {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
    }
    if (form.mentoring_area === '') {
      return toast.error('Por favor, complete el área de mentoría', {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
    }
    if (form.professional_description === '') {
      return toast.error('Por favor, complete la descripción profesional', {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
    }
    if (form.motivational_question === '') {
      return toast.error('Por favor, complete la pregunta motivacional', {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
    }
    if (form.mentor_skills.length === 0) {
      return toast.error('Por favor, seleccione al menos un tema que te gustaría asesorar', {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
    } if(form.themes_interest === ''){
      return toast.error('Por favor, complete el tema de interés', {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
    } else {
      await axios.post('https://findally-backend.herokuapp.com/api/auth/signupUnilever', { ...form, username: form.name + form.lastName })
        .then(res => {
          if (res.status === 200) {
            if (res.data.message === 'Failed! Email is already in use!' && res.data.status === 'error') {
              return toast.error('El email ya está en uso', {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              })
            }
            toast.success('Postulación enviada con éxito', {
              position: "top-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            setTimeout(() => {
              window.location.reload();
            }, 2000);
          }
        }).catch(err => {
          toast.error('Error al registrar', {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        })
    }
  }

  /* EXPERIENCE_COACH FUNCTIONS */
  const addExperienceCoach = () => {
    const newExperienceCoach = [...form.experience_coach, ''];
    setForm({ ...form, experience_coach: newExperienceCoach });
  }
  const handleChangeExperienceCoach = (e, index) => {
    const newExperienceCoach = [...form.experience_coach];
    newExperienceCoach[index] = e.target.value;
    setForm({ ...form, experience_coach: newExperienceCoach });
  }
  const handleDeleteExperienceCoach = (index) => {
    const newExperienceCoach = [...form.experience_coach];
    newExperienceCoach.splice(index, 1);
    setForm({ ...form, experience_coach: newExperienceCoach });
  }
  /* EXTRA CURRICULAR FUNCTIONS */
  const addExtraCurricular = () => {
    const newExtraCurricular = [...form.extracurricular, ''];
    setForm({ ...form, extracurricular: newExtraCurricular });
  }

  const handleChangeExtraCurricular = (e, index) => {
    const newExtraCurricular = [...form.extracurricular];
    newExtraCurricular[index] = e.target.value;
    setForm({ ...form, extracurricular: newExtraCurricular });
  }

  const handleDeleteExtraCurricular = (index) => {
    const newExtraCurricular = [...form.extracurricular];
    newExtraCurricular.splice(index, 1);
    setForm({ ...form, extracurricular: newExtraCurricular });
  }

  const interestAreas = [
    {value: 'Comunicaciones', label: 'Comunicaciones'},
    {value: 'IT', label: 'IT'},
    {value: 'Marketing', label: 'Marketing'},
    {value: 'Ventas', label: 'Ventas'},
    {value: 'Cadena de Abastecimiento', label: 'Cadena de Abastecimiento'},
    {value: 'Gestión Humana', label: 'Gestión Humana'},
    {value: 'E-commerce', label: 'E-commerce'},
    {value: 'Investigación o Desarrollo', label: 'Investigación o Desarrollo'},
    {value: 'Conocimiento Consumidor', label: 'Conocimiento Consumidor'},
    {value: 'Finanzas', label: 'Finanzas'},
  ]

  const habilities = [
    { value: 'Diseño', label: 'Diseño' },
    { value: 'Marketing y Publicidad', label: 'Marketing y Publicidad' },
    { value: 'Redacción', label: 'Redacción' },
    { value: 'Vídeo y Animación', label: 'Vídeo y Animación' },
    { value: 'Música y audio', label: 'Música y audio' },
    { value: 'Programación y tecnología', label: 'Programación y tecnología' },
    { value: 'Negocios', label: 'Negocios' },
    { value: 'Análisis de datos', label: 'Análisis de datos' },
    { value: 'Legal', label: 'Legal' },
  ]

  const genders = [
    { value: 'Femenino', label: 'Femenino' },
    { value: 'Masculino', label: 'Masculino' },
    { value: 'Otro', label: 'Otro' },
    { value: 'Prefiero no decirlo', label: 'Prefiero no decirlo' },
  ]

  const mentorsector = [
    { value: 'Emprendimiento', label: 'Emprendimiento' },
    { value: 'Empleabilidad', label: 'Empleabilidad' },
  ]

  return (
    <div className={'postulate-global-container'}>
      {popUp && lang && <Dialog
        style={{}}
        open={popUp}
        onClose={() => setPopUp(false)}
        // scroll={scroll}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title">{
          lang === 'es' ? 'Acepto los terminos y condiciones' : 'I accept the terms and conditions'
        } </DialogTitle>
        <DialogContent>
          <TermsAndConditions />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => acceptTyC()}>
            {lang === 'es' ? 'Cerrar' : 'Close'}
          </Button>
        </DialogActions>
      </Dialog>}
      <div className={'postulate-header'}>
      </div>
      {/**/}
      <div className='postulate-firulete1'>
        <img src={Firulete1} alt={'firulete1'} />
      </div>
      <div className='postulate-ring1'>
        <img src={RingIzq} alt={'ring-izq'} />
      </div>
      <div className='postulate-firulete2'>
        <img src={Firulete2} alt={'firulete2'} />
      </div>
      <div className='postulate-ring2'>
        <img src={RingDer} alt={'ring-der'} />
      </div>
      <div className={'postulate-global-sub-container'}>
        <div className={'postulate-section-1-container'}>
          <div>
            <img src={LayerTop} alt={"layer-unilever-top"} className={'postulate-freelo'} />
          </div>
          <div className='unilever-left-logo-container'>
            <div className='unilever-left-logo-container-1'>
              <img src={Logo} alt="logo-unilever" className="postulate-unilever-logo" />
              <img src={LogoCoally} alt="logo-coally" className='pos tulate-unilever-logo' />
            </div>
          </div>
        </div>
        <div className={'postulate-section-2-container'}>
          {/*form*/}
          <form className={'postulate-section-2-sub-container'} onSubmit={sendForm}>
            <h2 className={'postulate-section-2-sub-text'}>Nombres<span style={{ color: '#FEC022' }}>*</span>
            </h2>
            <input className={'postulate-section-2-sub-input'} type={'text'} placeholder={'Nombres'}
              value={form.name} onChange={(e) => setForm(
                { ...form, name: e.target.value }
              )} />
            <h2 className={'postulate-section-2-sub-text'}>Apellidos<span style={{ color: '#FEC022' }}>*</span>
            </h2>
            <input className={'postulate-section-2-sub-input'} type={'text'} placeholder={'Apellidos'}
              value={form.lastName} onChange={(e) => setForm(
                { ...form, lastName: e.target.value }
              )} />
            <h2 className={'postulate-section-2-sub-text'}>Fecha de nacimiento<span style={{ color: '#FEC022' }}>*</span>
            </h2>
            <input className={'postulate-section-2-sub-input-date'} type={'date'} placeholder={'Nombres y apellidos'}
              value={form.Date_Birth} onChange={
                (e) => setForm({ ...form, Date_Birth: e.target.value })
              } />
            <h2 className={'postulate-section-2-sub-text'}>Correo electrónico<span style={{ color: '#FEC022' }}>*</span>
            </h2>
            <input className={'postulate-section-2-sub-input'} type={'text'} placeholder={'Correo electrónico'}
              value={form.email} onChange={(e) => setForm(
                { ...form, email: e.target.value }
              )} />
            <h2 className={'postulate-section-2-sub-text'}>Contraseña<span style={{ color: '#FEC022' }}>*</span>
            </h2>
            <input className={'postulate-section-2-sub-input'} type={'password'} placeholder={'Contraseña'}
              value={form.password} onChange={(e) => setForm(
                { ...form, password: e.target.value }
              )} />
            <h2 className={'postulate-section-2-sub-text'}>Celular<span style={{ color: '#FEC022',fontSize: '12px', marginLeft: '12px' }}>opcional</span></h2>
            <input className={'postulate-section-2-sub-input'} type={'number'} placeholder={'Celular'}
              value={form.phone} onChange={(e) => setForm(
                { ...form, phone: e.target.value }
              )} />
            <h2 className={'postulate-section-2-sub-text'}>¿En que organización trabajas?<span
              style={{ color: '#FEC022' }}>*</span></h2>
            <input className={'postulate-section-2-sub-input'} type={'text'} placeholder={'Organización'}
              value={form.organization} onChange={(e) => setForm(
                { ...form, organization: e.target.value }
              )} />
              <h2 className={'postulate-section-2-sub-text'}>¿Cuál es tu rol?<span
              style={{ color: '#FEC022' }}>*</span></h2>
            <input className={'postulate-section-2-sub-input'} type={'text'} placeholder={'Ocupación'}
              value={form.occupation} onChange={(e) => setForm(
                { ...form, occupation: e.target.value }
              )} />
            <h2 className={'postulate-section-2-sub-text'}>¿Cuál es tu área de mentoria?<span
              style={{ color: '#FEC022' }}>*</span></h2>
            <Select
              options={mentorsector}
              placeholder={'Escribe tu área de mentoria'}
              isSearchable={true}
              isClearable={true}
              styles={customStyles}
              onChange={(e) => setForm({ ...form, mentoring_area: e === null ? '' : e.value })}
            />
            <h2 className={'postulate-section-2-sub-text'}>¿Área de interés?<span style={{color: '#FEC022'}}>*</span>
            </h2>
            <Select
              options={interestAreas}
              placeholder={'Selecciona tu área de interés'}
              isSearchable={true}
              isClearable={true}
              styles={customStyles}
              onChange={(e) => setForm({...form, themes_interest: e === null ? '' : e.value})}
            />
            <h2 className={'postulate-section-2-sub-text'}>¿En qué temas te gustaría  asesorar a través de Mentoring a un joven?<span
              style={{ color: '#FEC022' }}>*</span>
            </h2>
            <div style={{width: '100%', marginBottom: '5%'}}>
            <Creatable
              options={habilities}
              placeholder={'Seleccione una o mas habilidades'}
              isMulti
              formatCreateLabel={(inputValue) => `Agregar "${inputValue}"`}
              styles={customStyles}
              onChange={e => setForm({ ...form, mentor_skills: e === null ? [] : e.map(item => item.value) })}
            />
            </div>
            <h2 className={'postulate-section-2-sub-text'}>Compártenos un poco de tu perfil<span
              style={{ color: '#FEC022' }}>*</span></h2>
            <textarea className={'postulate-section-2-sub-input-textarea'} type={'text'} placeholder={'Escriba aquí'}
              onChange={(e) => setForm(
                { ...form, professional_description: e.target.value }
              )} />
            <h2 className={'postulate-section-2-sub-text'}>¿Qué te mueve a participar como Mentor en este Programa?</h2>
            <textarea className={'postulate-section-2-sub-input-textarea'} type={'text'} placeholder={'Escriba aquí'}
              onChange={(e) => setForm(
                { ...form, motivational_question: e.target.value }
              )} />
            <h2 className={'postulate-section-2-sub-text'}>¿Con cuantos años de experiencia cuentas?<span
              style={{ color: '#FEC022' }}>*</span></h2>
            <input className={'postulate-section-2-sub-input'} type={'number'} placeholder={'Años de experiencia'}
              value={form.years_experience} onChange={(e) => setForm(
                { ...form, years_experience: e.target.value }
              )} />
            <h2 className={'postulate-section-2-sub-text'}>¿Tienes experiencia como mentor o coach?<span
              style={{ color: '#FEC022' }}>*</span>
            </h2>
            <div className='unilever-postulate-radio-section-container2'>
              <div className='unilever-postulate-radio-section-inner'>
                <input name="experience" id="experience1" type="radio" className="css-checkbox"
                       onChange={() => setExperienceCoach(true)}/>
                <label htmlFor="experience1" style={{marginLeft: '10px'}}><span
                  style={{marginLeft: '10px'}}>Si</span></label>
              </div>
              <div className='unilever-postulate-radio-section-inner'>
                <input name="experience" id="experience" type="radio" className="css-checkbox"
                       onChange={() => setExperienceCoach(false)}/>
                <label htmlFor="experience" style={{marginLeft: '10px'}}><span
                  style={{marginLeft: '10px'}}>No</span></label>
              </div>
            </div>
            {experienceCoach && <div style={{display: 'flex', flexDirection: 'column', marginBottom: '5%'}}>
              <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start'}}>
                <h2 className={'postulate-section-sub-text-add'}>Agrega tus experiencias</h2>
                <label className={'postulate-section-add-sub-button'} style={{marginLeft: '20px'}}
                       onClick={() => addExperienceCoach()}>Agregar</label>
              </div>
              {
                form.experience_coach.map((item, index) => {
                  return (
                    <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: '40px'}}
                         key={index}>
                      <input value={item} className={'postulate-section-sub-input-added'} type={'text'}
                             placeholder={'Escriba aquí'} onChange={e => handleChangeExperienceCoach(e, index)}/>
                      <span onClick={() => handleDeleteExperienceCoach(index)}
                            className='postulate-delete-button'>x</span>
                    </div>
                  )
                })
              }
            </div>}
            <div className='unilever-postulate-radio-section-container2'>
              <div>
                <label style={{ color: 'white', marginBottom: '10px' }}>
                  <input value={tyc} onChange={() => {
                    setTyc(!tyc)
                  }} type={'checkbox'} />
                  <span style={{ marginLeft: '15px' }}>He leído y acepto los <span onClick={() => setPopUp(true)} style={{ color: '#FEC022', fontWeight: 'bold', cursor: 'pointer' }}>términos
                    y condiciones.</span></span>
                </label>
              </div>
            </div>
            <button type={'submit'} className={'postulate-section-2-sub-button'}>Enviar</button>
          </form>
        </div>
        <div className={'postulate-section-3-global-container'}>
          <div className={'postulate-section-3-container'}>
            <div className={'postulate-section-3-sub-sub-container-2'}>
              <h3 className={'postulate-section-3-text'}>Síguenos en </h3>
            </div>
            <div className={'postulate-section-3-sub-container'}>
              <a href="https://www.linkedin.com/company/unilever/" target="_blank" rel="noopener noreferrer">
                <button className={'postulate-section-3-sub-sub-container'}>
                  <FaLinkedinIn className={'postulate-section-3-social'} />
                </button>
              </a>
              <a href="https://www.facebook.com/unilever/" target="_blank" rel="noopener noreferrer">
                <button className={'postulate-section-3-sub-sub-container'}>
                  <FaFacebookF className={'postulate-section-3-social'} />
                </button>
              </a>
              <a href="https://www.instagram.com/unilevercolombia/" target="_blank" rel="noopener noreferrer">
                <button className={'postulate-section-3-sub-sub-container'}>
                  <FaInstagram className={'postulate-section-3-social'} />
                </button>
              </a>
            </div>
          </div>
        </div>
      </div>
      {/**/}
      <div className={'postulate-footer'}>
        <h3 className={'postulate-header-text'} style={{ margin: 0 }}>© 2022 Coally - All rights reserved</h3>
      </div>
    </div>
  );
}