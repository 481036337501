import React, { useEffect, useState } from "react";
import "./styles/Confidencialidad.css";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { Img } from 'react-image'
import { connect, useSelector } from "react-redux";
import {
  Methodology,
  getProject,
  getPhase,
  ConsultorData1,
  ConsultorData,
  getNewEtapas,
  getEtapas,
  savePdf,
  firmaNda,
  getSinglePhase,
  getCertificados,
  getProjectPhases,
  setEtapasNull,
} from "../../actions/index";
import en from "../../lang/en-US";
import es from "../../lang/es-COL"
import counterpart from "counterpart";
import Translate from "react-translate-component";
import { useParams } from "react-router";
import axios from 'axios'

function ConfidencialidadContrato(props) {
  // const {
  //   match: { params },
  // } = props;
  const {id}=useParams()
  const getMethodology = () => {
    if (props.projectDetailsDone) {
      props.getProject(id);
    }
  };
  const getlegal = () => {
    if (props.actual) {
      props.getCertificados(props.actual.legal[0]);
    }
  };
  const proyectoactual=useSelector((state) => state.ActualProject)
  const [documentos, setdocumentos] = useState('')
    const info=()=>{
      axios.get(`https://findally-backend.herokuapp.com/api/legal/${proyectoactual.legal[0]}`).then(response=>setdocumentos(response.data))
    }
  useEffect(() => {
    getMethodology();
    if(proyectoactual===""){
      window.history.back()
    }
    getlegal(); // eslint-disable-next-line
  }, []);

  if(proyectoactual.legal && documentos===''){
    console.log('h')
        info()
      }

  let ref = React.createRef();

  const fecha = new Date();

  const año = fecha.getFullYear();
  const mes = fecha.getMonth() + 1;
  const dia = fecha.getDay()

  const [status, setStatusValue] = useState("show");
  
  const handdleStatusChange =(value)=>{
    if (value ==="show"){
      setStatusValue("done")
    }
    if(value==="done"){
      setStatusValue("show")
    }
  }

  const printDocument = () => {
    const input = ref.current;
    html2canvas(input, {
      useCORS: true
    }).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
     // const pdf = new jsPDF("l", "px");     
    const pdf = new jsPDF("p", "mm", "a3"); 
     const width = pdf.internal.pageSize.getWidth();
      console.log(width);
      const height = pdf.internal.pageSize.getHeight();
      console.log(height);

      pdf.addImage(imgData, "JPEG", 0, 0, width, height);
      pdf.save("confidencialidad_coally.pdf");
      const payload = {
        doc: imgData,
        proyecto: documentos.project._id,
        usuario: documentos.company_user._id,
      };
      props.firmaNda(payload);
    });
  };

  counterpart.registerTranslations('en', en)
  counterpart.registerTranslations('es', es)
  counterpart.setLocale(localStorage.getItem('langlocal'));
  return (
    <>
      {/* <Header /> */}
      {documentos ? (
        <div>
          

          <div
            ref={ref}
            style={{
              borderRadius: "5px",/* 
              width: "790px", */
              marginLeft: "5%",
              marginRight: "5%",/* 
              height: "2220px", */
              paddingTop: "8mm",
              paddingLeft: "10mm",
              paddingRight: "10mm",
              paddingBottom: "5mm",
            }}
          >
            <div className="tittle-contrato-conf">
              <h2 className="conf-tittle-sec">{<Translate content="ACUERDODECONFIDENCIALIDAD" component="span" />}</h2>
            </div>
            <div className="conf-description">
              <p className="conf-desc-in">
                {" "}
                {<Translate content="PorLapartereveladoraAndresFelipe" component="span" />}{" "}
              </p>
              <article className="conf-company">
                {<Translate content="Porlapartereceptoradelainformación" component="span" />}{" "}
                <p className="conf-company">
                  {"--"}{" "}
                </p>
                {<Translate content="NombreEE" component="span" />}{" "}
                <p className="conf-company">
                  {" "}
                  <strong>
                  {props.userform.nombre || "--"}{" "}
                  </strong>
                </p>{" "}
                {<Translate content="Dirección" component="span" />}{" "}
                <p className="conf-company">
                  {" "}
                  <strong>
                  {props.userform.direccion || "--"}{" "}
                  </strong>

                </p>
                {<Translate content="Teléfono" component="span" />}{" "}
                <p className="conf-company">
                  {" "}
                  <strong>
                  {props.userform.telefono || "--"}{" "}
                  </strong>
                </p>{" "}
                {<Translate content="EMAIL" component="span" />}{": "}
                <p className="conf-company">
                  {" "}
                  <strong>
                  {props.userform.email || "--"}{" "}
                  </strong>
                </p>
              </article>
              <article>
                {<Translate content="Identificacióndelproyecto" component="span" />}{" "}
                <p className="conf-company">
                  {" "}
                  <strong>
                  {documentos.project.linea_negocio || "--"}{" "}
                  </strong>
                </p>
              </article>
            </div>
            <div className="conf-content-contract">
              <article>
                {" "}
                {<Translate content="Entrelosfirmantesidentificadosanteriormentehemos" component="span" />}
                <h3 className="conf-tittle-consid">{<Translate content="CONSIDERACIONES" component="span" />}</h3>
                <br></br>
                <strong>1</strong>{<Translate content="Quelainformacióncompartidaenvirtuddelpresenteacuerdo" component="span" />}
                <br></br>
                2{<Translate content="Quelainformacióndepropiedad" component="span" />}
                <br></br>
                <strong>3</strong>{<Translate content="Queelpresente" component="span" />}
                <br></br>
                {<Translate content="PrimeraObjetoenvirtuddel" component="span" />}
                <br></br>
                {<Translate content="Segunda" component="span" />}
                <br></br>
                {<Translate content="Definicióndeinformación" component="span" />}
                <br></br>
                <strong>1</strong>{<Translate content="Lainformaciónquenosea" component="span" />}
                <br></br>
                2{<Translate content="Cualquierinformación" component="span" />}
                <br></br>
                <strong>3</strong>{<Translate content="Laquecorrespondaodebaconsiderarsecomotalpara" component="span" />}
                <br></br>
                {<Translate content="TerceraOrigendelainformaciónconfidencial" component="span" />}
                <br></br>
                {<Translate content="CuartaObligaciones" component="span" />}
                <br></br>
                <strong>1</strong>.
                {<Translate content="Mantenerlainformaciónconfidencialsegurausarlasolamente" component="span" />}
                <br></br>
                2.
                {<Translate content="Protegerlainformaciónconfidencialseaverbalescrita" component="span" />}
                <br></br>
                <strong>3</strong>{<Translate content="Abstenersedepublicarla" component="span" />}
                <br></br>
                <strong>4</strong>{<Translate content="Usarlainformación" component="span" />}
                <br></br>
                <strong>5</strong>.
                {<Translate content="Mantenerlainformaciónconfidencialenreservahastatanto" component="span" />}
                <br></br>
                <strong>6</strong>{<Translate content="Responderporelmalusoque" component="span" />}
                <br></br>
                <strong>7</strong>{<Translate content="Guardarlareservadelainformaciónconfidencialcomomínimo" component="span" />}
                <br></br>
                <strong>8</strong>{<Translate content="Lapartereceptoraseobligaanotransmitir" component="span" />}
                <br></br>
                {<Translate content="QuintaObligacionesdelapartereveladora" component="span" />}
                <br></br>
                <strong>1</strong>{<Translate content="Mantenerlareservade" component="span" />}
                <br></br>
                2{<Translate content="Documentartodalainformaciónconfidencialque" component="span" />}
                <br></br>
                {<Translate content="SextaExclusionesalaconfidencialidadLaparte" component="span" />}
                <br></br>
                <strong>1</strong>{<Translate content="lainformaciónconfidencialhayasidooseadedominiopúblico" component="span" />}
                <br></br>
                <strong>2</strong>{<Translate content="Cuandolainformaciónconfidencialdeba" component="span" />}
                <br></br>
                <strong>3</strong>{<Translate content="Cuandolaparte" component="span" />}
                <br></br>
                <strong>4</strong>{<Translate content="Cuandolainformación" component="span" />}
                <br></br>
                {<Translate content="Séptima" component="span" />}
                <br></br>
                {<Translate content="Octava" component="span" />}
                <br></br>
                {<Translate content="NovenaLegislaciónaplicableEsteacuerdose" component="span" />}
              </article>
            </div>
            <div className="conf-personal-data">
              <article>
                {<Translate content="EnBogotáDCalos" component="span" />}{" "}
                <p className="conf-company">
                  {dia || "--"}{" "}
                </p>{" "}
                {<Translate content="díasdelmesde" component="span" />}{" "}
                <p className="conf-company">
                  {" "}
                  {mes || "--"}{" "}
                </p>{" "}
                {<Translate content="de" component="span" />}{" "}
                <p className="conf-company">
                  {" "}
                  {año || "--"}{" "}
                </p>
              </article>

              <p>
                {<Translate content="PorlapartereveladoraAndresFelipeJoyaMosqueraCCNode" component="span" />}
              </p>
            </div>
            <div>
                  {
                    status === "done" ?
                    <Img  style={{ width: '300px', height:'100px'}}   src={props.user.signature} crossorigin="anonymous" /> 
                    : 
                    <button className="button-comments" style={{backgroundColor:'#d0d0d0',borderRadius:'7px',color:'white',padding:'6px',border:'solid 1px #d0d0d0'}} onClick={()=>handdleStatusChange(status)}>{<Translate content="Firmaar" component="span" />}</button>

                  }
                  </div>
            <br></br>
                <br></br>
                <br></br>
                <br></br>
          </div>
          <div className="div-button-pdf-gen">
            <button className="doc-button-pdf" onClick={printDocument}>
              {<Translate content="DESCARGARDOCUMENTOO" component="span" />}
            </button>
          </div>
        </div>
      ) : (
        <div>
          <h1>{<Translate content="cargando" component="span" />}</h1>
        </div>
      )}
    </>
  );
}

function mapStateToProps(state) {
  return {
    user : state.user,
    actual: state.ActualProject,
    methodology: state.methodology,
    Phases: state.Phases,
    company:state.company_form,
    userform: state.userform,
    documentos: state.documentos,
    Etapas: state.Etapas,
    etapasDone: state.etapasDone,
    projectDetailsDone: state.projectDetailsDone,
  };
}
const mapDispatchToProps = {
  Methodology,
  getProject,
  getPhase,
  savePdf,
  getEtapas,
  ConsultorData,
  firmaNda,
  ConsultorData1,
  getCertificados,
  getNewEtapas,
  getSinglePhase,
  getProjectPhases,
  setEtapasNull,
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ConfidencialidadContrato);
/* 
  let docToPrint = React.createRef();

    const printDocument = () => {
        const input = docToPrint.current;
        html2canvas(input).then(canvas => {
            const imgData = canvas.toDataURL("image/png");
            const pdf = new jsPDF({
                orientation: "landscape",
                unit: "px",
            });
            pdf.addImage(imgData, "JPEG", 0, 0);
            // pdf.output("dataurlnewwindow");
            pdf.save("acuerdo-confidencialidad-coally.pdf");
        });
    };

    return (
        <>
            
        </>
    );
} */
