import React, { useState } from "react";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import FirstStep from "./StepOne";
import SecondStep from "./StepTwo";
import Confirm from "./Confirm";
import Success from "./Success";
import formValidation from "../../utils/validationForm";

// Step titles
const labels = ["First Step", "Second Step", "Confirmation"];

const initialValues = {
  nombre: "",
  comentario: "",
  email: "",
  descripcion: "",
  idioma: "Español",
  ciudad: "",
  objetivos: "",
  metricas: "",
  problema_empresa: "",
  problema_seleccion: "",
  causa_empresa: "",
  causa_seleccion: "",
  linea_negocio: "",
  codigo_promo: "",
  habilidades_requeridas: [],
  nivel_ingles: "",
};

const fieldsValidation = {
  email: {
    error: "",
    validate: "email",
    minLength: 2
  },
  nombre: {
    error: "",
    minLength: 2,
    maxLength: 200
  },
  habilidades_requeridas: {
    error: "",
    minLength: 2,
    maxLength: 200
  },
  linea_negocio: {
    error: "",
    minLength: 2,
    maxLength: 200
  },
  causa_empresa: {
    error: "",
    minLength: 2,
    maxLength: 200
  },
  problema_seleccion: {
    error: "",
    minLength: 2,
    maxLength: 200
  },
  idioma: {
    error: "",
    minLength: 2,
    maxLength: 200
  },
  metricas: {
    error: "",
    minLength: 2,
    maxLength: 200
  },
  objetivos: {
    error: "",
    minLength: 2,
    maxLength: 200
  },
  codigo_promo: {
    error: "",
    minLength: 2,
    maxLength: 200
  },
  problema_empresa: {
    error: "",
    minLength: 2,
    maxLength: 200
  },
  ciudad: {
    error: "",
    minLength: 2,
    maxLength: 200
  },
  comentario: {
    error: "",
    minLength: 2,
    maxLength: 200
  },
  nivel_ingles: {
    error: "",
    minLength: 1,
  },
  descripcion: {
    error: "",
    minLength: 3,
    maxLength: 20
  },
  phone: {
    error: "",
    validate: "phone",
    maxLength: 15
  }
};

const UnregisterProjectForm = () => {
  const [activeStep, setActiveStep] = useState(0);
  const [formValues, setFormValues] = useState(initialValues);
  const [formErrors, setFormErrors] = useState({});

  // Proceed to next step
  const handleNext = () => setActiveStep((prev) => prev + 1);
  // Go back to prev step
  const handleBack = () => setActiveStep((prev) => prev - 1);

  // Handle form change
  const handleChange = (e) => {
    const { name, value } = e.target;

    // Set values
    setFormValues((prev) => ({
      ...prev,
      [name]: value
    }));

    // set errors
    const error = formValidation(name, value, fieldsValidation) || "";

    setFormErrors({
      [name]: error
    });
  };

  const handleSteps = (step) => {
    switch (step) {
      case 0:
        return (
          <FirstStep
            handleNext={handleNext}
            handleChange={handleChange}
            values={formValues}
            formErrors={formErrors}
          />
        );
      case 1:
        return (
          <SecondStep
            handleNext={handleNext}
            handleBack={handleBack}
            handleChange={handleChange}
            values={formValues}
            formErrors={formErrors}
          />
        );
      case 2:
        return (
          <Confirm
            handleNext={handleNext}
            handleBack={handleBack}
            values={formValues}
          />
        );
      default:
        break;
    }
  };

  return (
    <>
      {activeStep === labels.length ? (
        // Last Component
        <Success values={formValues} />
      ) : (
        <>
          <Box style={{ margin: "30px 0 50px" }}>
            <Typography
              variant="subtitle2"
              align="center"
              style={{ margin: "10px 0" }}
            >

            </Typography>
          </Box>
          <Stepper
            activeStep={activeStep}
            style={{ margin: "30px 0 15px" }}
            alternativeLabel
          >
            {labels.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          {handleSteps(activeStep)}
        </>
      )}
    </>
  );
};

export default UnregisterProjectForm;
