import React from 'react';


const getRandomUrl = (value) => {
    const firstLetter = value.charAt(0);
    if (firstLetter === 'A' || firstLetter === 'B' || firstLetter === 'C') {
        return 'https://coally.com/wp-content/uploads/2022/09/pexels-vinicius-wiesehofer-1130626.jpg';
    }
    if (firstLetter === 'D' || firstLetter === 'E' || firstLetter === 'F') {
        return 'https://coally.com/wp-content/uploads/2022/09/pexels-simon-robben-614810.jpg';
    }
    if (firstLetter === 'G' || firstLetter === 'H' || firstLetter === 'I') {
        return 'https://coally.com/wp-content/uploads/2022/09/pexels-pixabay-220453.jpg';
    }
    if (firstLetter === 'J' || firstLetter === 'K' || firstLetter === 'L') {
        return 'https://coally.com/wp-content/uploads/2022/09/pexels-nitin-khajotia-1516680.jpg';
    }
    if (firstLetter === 'M' || firstLetter === 'N' || firstLetter === 'O') {
        return 'https://coally.com/wp-content/uploads/2022/09/pexels-kebs-visuals-3992656.jpg';
    }
    if (firstLetter === 'P' || firstLetter === 'Q' || firstLetter === 'R') {
        return 'https://coally.com/wp-content/uploads/2022/09/pexels-italo-melo-2379005.jpg';
    }
    if (firstLetter === 'S' || firstLetter === 'T' || firstLetter === 'U') {
        return 'https://coally.com/wp-content/uploads/2022/09/pexels-creation-hill-1681010.jpg';
    }
    if (firstLetter === 'V' || firstLetter === 'W' || firstLetter === 'X') {
        return 'https://coally.com/wp-content/uploads/2022/09/pexels-andrea-piacquadio-774909.jpg';
    }
    if (firstLetter === 'Y' || firstLetter === 'Z') {
        return 'https://coally.com/wp-content/uploads/2022/09/pexels-andrea-piacquadio-762020.jpg';
    }
    if (firstLetter === 'Ñ') {
        return 'https://coally.com/wp-content/uploads/2022/09/pexels-andrea-piacquadio-762020.jpg';
    }
    return 'https://coally.com/wp-content/uploads/2022/09/pexels-andrea-piacquadio-762020.jpg';
};


/* function split(sentence){
    var arrayPosition = 0;
    var oneWord = "";
    var newSentence = sentence + " ";
    var split = new Array();
    for(var j = 0; j < newSentence.length; j++){
      if(newSentence[j] === " "){
          split.push(oneWord);
          arrayPosition++;
          oneWord = "";
      }else{
          if(!isNaN(newSentence[j])){
              //don't add to the string
          }else{
              oneWord += newSentence[j];
          }
      }
    }
    return split;
  } */

const getInitials = (name) => {
    const [firstName, lastName] = name.split(' ');
    if (firstName && lastName) {
        return `${firstName.charAt(0)}${lastName.charAt(0)}`.toUpperCase();
    }
    return firstName.charAt(0).toUpperCase();
};




const Avatar = ({
    name,
    className,
    src,
    alt,
}) => {
    let url;
    if (name) {
        const initials = getInitials(name);
        url = getRandomUrl(initials);
    }
    return (
        <div

        >
            {src ? (
                <img
                    src={src}
                    alt={alt}
                    style={{  backgroundImage: `url(${url})`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat'}}
                    className={`${className} pointer rounded-full`}
                />
            ) : (
                <div
                    aria-label={name}
                    role="img"
                    style={{  backgroundImage: `url(${url})`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat' , color: 'white', borderRadius: '9999px ', height: '34px', width: '34px' }}
                    className={`${className}`}
                >
                </div>
            )}
        </div>
    )
};

const AvatarUser = ({name}) => {
    return (
        <Avatar
            style={{ fontSize: '25px', marginLeft: '-40px' }}
            //sx={{ bgcolor: deepOrange[500] }}
            name={name['username']}
        >
            {getInitials(name['username'])}
        </Avatar>
    );
}

export { Avatar, AvatarUser };