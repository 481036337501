import React, {Fragment, useState, useMemo, useCallback, useEffect} from 'react';
import differenceBy from 'lodash/differenceBy';
import {toast} from 'react-toastify';
import DataTable from 'react-data-table-component';
// import { connect } from "react-redux";
// import { getUsersAdmin } from "../../../actions";
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import EditIcon from '@mui/icons-material/Edit';
import SearchIcon from '@mui/icons-material/Search';
import axios from 'axios';
import EmailIcon from "@material-ui/icons/Email";

export default function CoallierTableComponent(props) {

  // useEffect(() => {
  //     const getData = () => {
  //         props.getUsersAdmin()
  //     }
  //     getData();  // eslint-disable-next-line
  // }, []); // eslint-disable-next-line

  const [selectedRows, setSelectedRows] = useState([]);
  const [toggleCleared, setToggleCleared] = useState(false);
  const [data, setData] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [edit, setEdit] = useState({});
  const [open, setOpen] = useState(false)
  const [dataTotal, setDataTotal] = useState([])

  const [popUpMailing, setPopUpMailing] = useState(false)
  const [emailUser, setEmailUser] = useState()
  const [emailSubject, setEmailSubject] = useState('')
  const [emailMessage, setEmailMessage] = useState('')
  const [emailTitle, setEmailTitle] = useState('')

  //Edit
  const onEdit = (e) => {
    setEdit(e);
    setOpen(true)

  };

  const handlePopUpMailing = (record) => {
    setEmailUser(record._id)
    setPopUpMailing(true)
  }

  const handleClose = () => {
    setOpen(false);
  };

  const handleOnChange = (e) => {
    e.preventDefault();
    setEdit(
      {...edit, [e.target.name]: e.target.value}
    );
  };

  const handleSendMail = (e) => {
    e.preventDefault();
    console.log()
    axios.post(`https://findally-backend.herokuapp.com/api/admin/sendPersonalizedMail`, {
      id_user: emailUser,
      subject: emailSubject,
      description_mail: emailMessage,
      title_mail: emailTitle
    })
      .then(() => {
        toast.success('Mail enviado', {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        })
        setPopUpMailing(false)
      })
      .catch(() => {
        toast.error('Error enviando mail', {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        })
      })
  }

  const handleOnSubmit = (e) => {
    console.log(edit)
    e.preventDefault();
    axios.put(`https://findally-backend.herokuapp.com/update/user/${edit._id}`, edit)
      .then(res => {
        toast.success('User updated successfully', {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        })
        setOpen(false);
        fetchUsers();

      })
      .catch(err => {
        toast.error('Error updating user', {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        })
      });
  };


  const customStyles = {
    headCells: {
      style: {
        fontWeight: 'bold',
        fontSize: '14px',
      },
    },
    cells: {
      style: {
        textTransform: 'capitalize',
      },
    },
  };


  const columns = [
    {
      name: 'ID',
      selector: '_id',
      sortable: true,
      center: true,
    },
    {
      name: 'Nombre',
      selector: 'username',
      sortable: true,
      center: true,
    },
    {
      name: 'Profesión',
      selector: row => row.roles[0]?.name,
      sortable: true,
      center: true,
    },
    {
      key: "action",
      text: "Action",
      className: "action",
      width: 100,
      align: "left",
      sortable: false,
      cell: (record) => {
        return (
          <Fragment>
            <button
              onClick={() => {
                onEdit(record);
              }}
            >
              <EditIcon/>
            </button>
            <button
              style={{
                marginLeft: "10px",
                //background orange
                backgroundColor: "#FFA500",
                color: "white",
                border: "none",
                borderRadius: "5px",
                padding: "5px",
              }}
              onClick={(e) => {
                handlePopUpMailing(record);
              }}
            >
              <EmailIcon/>
            </button>
          </Fragment>
        );
      },
    },
  ];


  //Pagination
  const fetchUsers = useCallback(async page => {

    const page1 = page - 1
    const response = await axios.get(`https://findally-backend.herokuapp.com/user/role/60bb8041e470ea44900d2008?skip=${page1}&limit=${perPage}`)
    console.log(response)

    const {data} = response;
    setData(data);
    const total = await axios.get(`https://findally-backend.herokuapp.com/user/role/60bb8041e470ea44900d2008?limit=`)
    setDataTotal(total.data)
    setTotalRows(total.data.length);

  }, [perPage]);

  const handlePageChange = page => {

    fetchUsers(page);
  };

  const handlePerRowsChange = async (newPerPage, page) => {

    const response = await axios.get(`https://findally-backend.herokuapp.com/user/role/60bb8041e470ea44900d2008?skip=${page}&limit=${newPerPage}`);

    setData(response.data);
    setPerPage(newPerPage);

  };

  useEffect(() => {
    fetchUsers(1); // fetch page 1 of users

  }, [fetchUsers]);


  //Delete
  const handleRowSelected = useCallback(state => {
    setSelectedRows(state.selectedRows);
  }, []);

  const contextActions = useMemo(() => {
    const handleDelete = () => {

      if (window.confirm(`Are you sure you want to delete:\r ${selectedRows.map(r => r.name)}?`)) {
        setToggleCleared(!toggleCleared);
        setData(differenceBy(data, selectedRows, 'name'));
        toast.success("Successfully Deleted !", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        })
      }
    };

    return <button key="delete" onClick={handleDelete}>{"Delete"}</button>;
  }, [data, selectedRows, toggleCleared]);

  //loading
  const [pending, setPending] = useState(true);
  const estado = data.length
  useEffect(() => {
    if (estado > 0) {
      setPending(false);
    }
  }, [estado]);

  //Search
  const [filterText, setFilterText] = useState('');
  const [filterData, setFilterData] = useState([])
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const filteredItems = filterData.length === 0 ? data : filterData

  const subHeaderComponentMemo = useMemo(() => {
    const handleSearch = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterData(dataTotal.filter(
          item => item.username.toLowerCase().includes(filterText.toLowerCase()) ||
            item._id.toLowerCase().includes(filterText.toLowerCase())))
      } else {
        setFilterData([])
      }
    };

    return (
      <div>
        <input placeholder='Search by Name or Id' onChange={(e) => setFilterText(e.target.value)} value={filterText}
               style={{
                 borderRadius: '3px',
                 borderTopLeftRadius: '5px',
                 borderBottomLeftRadius: '5px',
                 borderTopRightRadius: 0,
                 borderBottomRightRadius: 0,
                 border: '1px solid #e5e5e5',
                 height: '32px',
                 width: '200px',
                 padding: '0 32px 0 16px',
               }}/>
        <button onClick={handleSearch} style={{
          borderTopLeftRadius: 0,
          borderBottomLeftRadius: 0,
          borderTopRightRadius: '5px',
          borderBottomRightRadius: '5px',
          height: '34px',
          width: '32px',
        }}>
          <SearchIcon/>
        </button>
      </div>
    );
  }, [filterText, resetPaginationToggle, dataTotal]);

  return (
    <Fragment>
      <div>
        <div>
          <div>
            <div>
              <div>
                <DataTable
                  title='Usuarios'
                  progressPending={pending}
                  customStyles={customStyles}
                  columns={columns}
                  data={filteredItems}
                  subHeader
                  subHeaderComponent={subHeaderComponentMemo}
                  striped={true}
                  center={true}
                  selectableRows
                  persistTableHead
                  contextActions={contextActions}
                  onSelectedRowsChange={handleRowSelected}
                  clearSelectedRows={toggleCleared}
                  pagination
                  paginationServer
                  paginationTotalRows={totalRows}
                  onChangeRowsPerPage={handlePerRowsChange}
                  onChangePage={handlePageChange}
                />
              </div>
              <div>
                <Dialog
                  open={open}
                  onClose={handleClose}
                  scroll="paper"
                  fullWidth={true}
                  maxWidth="md"
                  aria-labelledby="scroll-dialog-title"
                  aria-describedby="scroll-dialog-description"
                >
                  <DialogTitle id="scroll-dialog-title" sx={{fontSize: '24px', fontWeight: 700, color: '#202256'}}>
                    Editar Usuario
                  </DialogTitle>
                  <DialogContent dividers={true} style={{height: '600px', fontSize: '20px'}}>

                    <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                            <span>
                                                Nombre:
                                            </span>
                      <TextField
                        id="name"
                        label={edit.username}
                        type="text"
                        name='username'
                        onChange={handleOnChange}
                      />
                    </div>

                    <div
                      style={{marginTop: '5%', display: 'flex', justifyContent: 'space-around', alignItems: 'center'}}>
                      <Button
                        onClick={handleOnSubmit}
                        sx={{
                          backgroundColor: 'transparent',
                          height: '28px',
                          width: '141px',
                          borderRadius: '5px',
                          borderStyle: 'solid',
                          borderWidth: '1px',
                          fontSize: '14px',
                          fontWeight: '400',
                          color: '#202256'
                        }}
                      >
                        Guardar
                      </Button>
                      <Button
                        onClick={handleClose}
                        sx={{
                          backgroundColor: 'transparent',
                          height: '28px',
                          width: '141px',
                          borderRadius: '5px',
                          borderStyle: 'solid',
                          borderWidth: '1px',
                          fontSize: '14px',
                          fontWeight: '400',
                          color: '#202256'
                        }}
                      >
                        Cancelar
                      </Button>
                    </div>
                  </DialogContent>
                </Dialog>

                <Dialog
                  open={popUpMailing}
                  onClose={() => setPopUpMailing(false)}
                  scroll="paper"
                  fullWidth={true}
                  maxWidth="md"
                  aria-labelledby="scroll-dialog-title"
                  aria-describedby="scroll-dialog-description"
                >
                  <DialogTitle id="scroll-dialog-title"
                               sx={{fontSize: '24px', fontWeight: 700, color: '#202256', textAlign: 'center'}}>
                    Enviar Email
                  </DialogTitle>

                  <DialogContent dividers={true} style={{fontSize: '20px'}}>
                    <div>
                      <h3>Titulo</h3>
                      <input
                        style={{
                          width: '100%',
                          height: '40px',
                          borderRadius: '5px',
                          border: '2px solid #e5e5e5',
                          padding: '0 32px 0 16px',
                        }}
                        type="text"
                        name='title'
                        onChange={(e) =>  setEmailTitle(e.target.value)}
                      />
                      <h3>Asunto</h3>
                      <input
                        style={{
                          width: '100%',
                          height: '40px',
                          borderRadius: '5px',
                          border: '2px solid #e5e5e5',
                          padding: '0 32px 0 16px',
                        }}
                        type="text"
                        name='title'
                        onChange={(e) => setEmailSubject(e.target.value)}
                      />
                      <h3>Cuerpo</h3>
                      <textarea
                        style={{
                          width: '100%',
                          height: '200px',
                          borderRadius: '5px',
                          border: '2px solid #e5e5e5',
                          padding: '0 32px 0 16px',
                        }}
                        name='body'
                        onChange={(e) => setEmailMessage(e.target.value)}
                      />
                    </div>
                    <div
                      style={{marginTop: '5%', display: 'flex', justifyContent: 'space-around', alignItems: 'center'}}
                    >
                      <Button
                        onClick={(e) => handleSendMail(e)}
                        sx={{
                          marginLeft: "10px",
                          width: '141px',
                          backgroundColor: "#FFA500",
                          color: "white",
                          border: "none",
                          borderRadius: "5px",
                        }}>
                        Enviar
                      </Button>
                      <Button
                        onClick={() => setPopUpMailing(false)}
                        sx={{
                          backgroundColor: 'transparent',
                          width: '141px',
                          borderRadius: '5px',
                          borderStyle: 'solid',
                          borderWidth: '1px',
                          fontSize: '14px',
                          fontWeight: '400',
                          color: '#202256'
                        }}>
                        Cancelar
                      </Button>

                    </div>
                  </DialogContent>
                </Dialog>

              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

// const mapDispatchToProps = {
//     getUsersAdmin
// };


// const mapStateToProps = (state) => {
//     return {
//         users_admin: state.users_admin
//     };
// };

// export default connect(mapStateToProps, mapDispatchToProps)(UserTableComponent);