import styled from "styled-components";
import { OpacityOn, OpacityOff } from "../../styles/AnimationStyles";

const NavbarContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  transition: all 0.5s ease;
`;

const NavbarSideBarContainer = styled.div<{ isClosing?: boolean }>`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 4;
  animation: ${(props) => (props.isClosing ? OpacityOff : OpacityOn)} 0.5s ease;
`;

const NavbarSideBarBackground = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;
  background: #e6e6e6;
  mix-blend-mode: normal;
  opacity: 0.8;
  backdrop-filter: blur(15px);
`;

const NavBarUserInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 105px;
  right: 0;
  z-index: 10;
`;

const NavBarUserInfo = styled.div.attrs({
  "data-testid": "NavBarUserInfo",
})`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  position: absolute;
  right: 150px;
  z-index: 1;
  @media (max-width: 700px) {
    right: 0;
    width: 100%;
  }
`;

const NavBarElementsContainer = styled.div`
  height: 100px;
  width: 100%;
  background-color: transparent;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;

  @media (max-width: 1300px) {
    justify-content: space-between;
  }
`;

const NavBarHamburgerContainer = styled.div`
  cursor: pointer;
  display: none;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 40px;
  height: 20px;
  margin-left: 60px;

  @media (max-width: 1300px) {
    display: flex;
  }
  @media (max-width: 425px) {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 40px;
    height: 20px;
    margin-left: 10%;
  }
`;
const HamburgerTile = styled.div<{ background: string }>`
  height: 2px;
  width: 100%;
  background-color: ${(props) => props.background};
`;

const NavBarLangLogoContainer = styled.div`
  height: 100px;
  background-color: transparent;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
`;

const NavBarLangContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 80px;

  @media (max-width: 425px) {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 80px;
    margin-right: 20%;
  }
`;

const NavBarProfileContainer = styled.div`
  width: 180px;
  margin-right: 75px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;

  @media (max-width: 425px) {
    width: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-right: 10%;
  }
`;
const NavBarProfileSeparator = styled.div`
  background-color: #d6d6d6;
  width: 1px;
  height: 60px;

  @media (max-width: 425px) {
    display: none;
  }
`;

const NavbarLinea = styled.div`
  height: 1px;
  width: 95%;
  background-color: #d6d6d6;
`;

const CompanyProfileImageContainer = styled.div`
  width: 55px;
  height: 55px;
  overflow: hidden;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export {
  NavbarContainer,
  NavbarSideBarContainer,
  NavbarSideBarBackground,
  NavBarUserInfoContainer,
  NavBarUserInfo,
  NavBarElementsContainer,
  NavBarHamburgerContainer,
  HamburgerTile,
  NavBarLangLogoContainer,
  NavBarLangContainer,
  NavBarProfileContainer,
  NavBarProfileSeparator,
  CompanyProfileImageContainer,
  NavbarLinea,
};
