import React from 'react'
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import { CardsProps } from '../utils/interfaceCards';



const CardHabilidad = ({ image, title, cant , lang }: CardsProps) => {
  return (
    <div className="cardHabilidades-Container">
            <div style={{
                display: 'flex',
            }}>
                <div>
                    <img src={image ? image : ''} alt={image ? image : ''} />
                </div>
                <div className="cardHabilidades-SubContainer">
                    <div>
                        <h1 className="cardHabilidades-title">
                            {title}
                        </h1>
                        <h3 className="cardHabilidades-cant">
                            {cant} {lang === 'es' ? 'cursos' : 'courses'}
                        </h3>
                    </div>
                    <div >
                        <button className="cardHabilidades-button">
                            <span>
                                {lang === 'es' ? 'Ver más' : 'See more'}
                            </span>
                            <KeyboardArrowDownIcon />
                        </button>
                    </div>
                </div>
            </div>
        </div>
  )
}

export default CardHabilidad