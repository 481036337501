import React from 'react'
import './Styles/TestCardCompany.css'
import { useSelector } from 'react-redux'
export default function TestCompanyCulturalResult(props) {
  const lang = useSelector((state) => state.lang)
  const resultTest = 'A'
    return (
        <div>
            <div >
                <div style={{ marginLeft: '93px', marginRight: '96px', marginTop: '51px' }}>
                    <h1 style={{ fontSize: '20px', fontWeight: '700', lineHeight: '30px', color: '#151515', }}>
                        {lang === 'es' ? 'Resultados del test de cultura organizacional' : 'Results of the organizational culture test'}
                    </h1>
                    <div style={{ fontSize: '14px', fontWeight: '400' }}>
                        <p>{lang === 'es' ? 'El tipo de personalidad ' : 'Personality type ' } <span style={{fontWeight:'700'}}>A</span>
                        {lang === 'es' ? ' corresponde a aquellas personas que necesitan afirmarse permanentemente a través de logros personales para alcanzar la percepción de auto-control. Experimentan una constante urgencia de tiempo que les hace intolerable el reposo y la inactividad.' : '  corresponds to those people who need to constantly affirm themselves through personal achievements to achieve the perception of self-control. They experience a constant urgency of time that makes them intolerable rest and inactivity.'}
                        </p>
                        <p>
                          {lang === 'es' ? 'Estas personas también presentan una percepción de amenaza casi contínua a su autoestima, eligiendo la acción como estrategia de enfrentamiento a sus problemas. Tienen tendencia a la dominación, una profunda inclinación a competir y una elevada agresividad que les induce a vivir en un constante estado de lucha.' : 'These people also present a perception of almost continuous threat to their self-esteem, choosing action as a strategy to confront their problems. They tend to dominate, a deep inclination to compete and a high aggressiveness that leads them to live in a constant state of struggle.'}
                        </p>
                        <p>
                        {lang === 'es' ? 'El tipo de personalidad ' : 'Personality type ' } <span style={{fontWeight:'700'}}>B</span>
                        {lang === 'es' ? ' es, evidentemente, lo opuesto al anterior. Son personas con un adecuado nivel de auto-control y autoestima que no les hace falta mantener actitudes compensadoras para reafirmarse. No son competitivas ni tan fácilmente irritables, y en general se toman la vida con mayor tranquilidad.' : ' is, obviously, the opposite of the previous one. They are people with an adequate level of self-control and self-esteem that does not need to maintain compensatory attitudes to reaffirm themselves. They are not competitive or so easily irritated, and generally take life more calmly.'}
                        </p>
                        <h2 style={{fontSize:'14px', fontWeight:'700', lineHeight:'20px', color:'#E7BF00'}}>
                            {lang === 'es' ? 'Tienes una personalidad de tipo' : 'You have a personality type'} <span style={{fontWeight:'700'}}>{resultTest}</span>
                        </h2>
                        <p>
                            {
                            resultTest === 'A' && lang === 'es' ? 'Deberías relajarte y tomarte las cosas con más filosofía, pues tienes mayor peligro de padecer enfermedades coronarias, problemas psicosomáticos y estados de ansiedad.'
                            : resultTest === 'A' && lang === 'en' ? 'You should relax and take things more philosophically, because you have a greater risk of suffering coronary diseases, psychosomatic problems and states of anxiety.'
                            : resultTest === 'B' && lang === 'es' ? 'Eres bastante menos vulnerable a sufrir ansiedad que otras personas, no te muestras ambicioso ni dominante, dejas que las cosas sigan su cauce sin preocuparte en exceso. No quiere decir que nunca te muestres nervioso o angustiado si la situación te desborda, pero en general tienes un temperamento templado.'
                            : resultTest === 'B' && lang === 'en' ? 'You are much less vulnerable to suffering anxiety than other people, you are not ambitious or dominant, you let things follow their course without worrying too much. It does not mean that you are never nervous or distressed if the situation overwhelms you, but in general you have a temperate temperament.'
                            : null
                          }
                        </p>

                    </div>
                    <div style={{ display: 'flex', justifyContent: 'end', marginRight: '80px' }}>
                          <button className='Company-Test-Popup-button' onClick={props.handleCloseTestCulturalResult}>
                              { lang === 'es' ? 'Guardar' : 'Save' }
                          </button>
                    </div>
                </div>
            </div>
        </div>
    )
}
