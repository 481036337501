/*eslint-disable*/
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './adminunilever.css'
import AdminLayout from "../../components/adminLayout/AdminLayout";
import AdminNavBar from "../../components/adminNavbar/AdminNavBar";
import { CartesianGrid, Legend, Line, LineChart, Tooltip, XAxis, YAxis } from "recharts";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import Table from "@mui/material/Table";
import TablaAgendadoReunion from './tablas/TablaAgendadoReunion';
import TablaUserLogin from './tablas/TablaUserLogin';
import TablaMentorLogin from './tablas/TablaMentorLogin';
import TablaAplicadoOfertas from './tablas/TablaAplicadoOfertas';
import TablaAsistenciaMasterclass from "./tablas/TablaAsistenciaMasterclass";

const AdminUnileverGraphs = () => {
  const [mentors, setMentors] = useState([]);
  const [students, setStudents] = useState([]);
  const [assistances, setAssistances] = useState([]);
  const [meetingsDate, setMeetingsDate] = useState(new Date());
  const [studentsAssitance, setStudentsAssitance] = useState([]);
  const [ready, setReady] = useState(false);
  const [feedbacks, setFeedbacks] = useState([]);
  useEffect(() => {
    axios.get('https://findally-backend.herokuapp.com/api/unilever/admin/mentors?limit=1000')
      .then(res => {
        setMentors(res.data.response)
      })
      .catch(err => {
        console.log(err)
      })
  }, [])

  useEffect(() => {
    axios.get('https://findally-backend.herokuapp.com/api/unilever/admin/students?limit=1000')
      .then(res => {
        setStudents(res.data.response)
      })
      .catch(err => {
        console.log(err)
      })
  }, [])

  useEffect(() => {
    axios.get('https://findally-backend.herokuapp.com/api/unilever/reservation')
      .then(res => {
        setAssistances(res.data.data)
      }).catch(err => {
        console.log(err)
      })
  }, [])

  useEffect(() => {
    axios.get('https://findally-backend.herokuapp.com/api/unilever/admin/assitance')
      .then(res => {
        setStudentsAssitance(res.data)
      })
      .catch(err => {
        console.log(err)
      })
  }, [])

  useEffect(() => {
    axios.get('https://findally-backend.herokuapp.com/api/unilever/admin/feedback')
      .then(res => {
        setFeedbacks(res.data)
      }).catch(err => {
        console.log(err)
      })
  }, [])



  const assitanceParsedDataForGraphs = () => {
    if (assistances.length > 0) {
      let dateArray = [];
      for (let i = 0; i < assistances.length; i++) {
        let obj = assistances[i];
        let date = obj.date.slice(8, 10) + '/' + assistances[i].date.slice(5, 7);
        let dateExists = false;
        for (let j = 0; j < dateArray.length; j++) {
          if (dateArray[j].date === date) {
            dateArray[j].asistencias++;
            dateExists = true;
            break;
          }
        }
        if (!dateExists && obj.feedback) {
          dateArray.push({ date: date, asistencias: 1 });
        }
      }
      console.log(dateArray)
      return dateArray;
    }
  }


  const countAssistances = () => {
    let count = 0;
    for (let i = 0; i < assistances.length; i++) {
      if (assistances[i].feedback) {
        count++;
      }
    }
    return count;
  }
  //sort minor to major assitance and make and array of objects with name and assitance

  const sortAssitance = () => {
    if (studentsAssitance) {
      const newStudentsArrayAssistance = Object.keys(studentsAssitance).map((key) => ({ key, assistance: studentsAssitance[key] }))
      const sortedArrayAssistance = newStudentsArrayAssistance.sort((a, b) => b.assistance - a.assistance)
      console.log(sortedArrayAssistance)
      return sortedArrayAssistance
    }
  }


  function CardEstudiante(props) {
    return (
      <div style={{ display: 'flex', width: '100%', flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-start', textAlign: 'left' }}>
        <span style={{ color: '#697a8d', fontSize: '20px' }}>{props.nombre}</span>
      </div>
    )
  }


  function CardAsistencias(props) {
    return (
      <div style={{ display: 'flex', width: '100%', flexDirection: 'row', justifyContent: 'center', alignItems: 'flex-start', backgroundColor: 'ffe0db', borderRadius: '8px' }}>
        <span style={{ color: '#ff3e1d', fontSize: '20px', margin: '0 10px' }}>{
          props.asistencias
        }</span>
      </div>
    )
  }


  const rows = sortAssitance().map((student, index) => {
    return {
      estudiantes: <CardEstudiante nombre={student.key} />,
      asistencias: <CardAsistencias asistencias={student.assistance} />
    }
  })

  const rows2 = feedbacks && feedbacks.map((feedback, index) => {
    return {
      estudiantes: <CardEstudiante nombre={feedback?.student ? feedback?.student?.name ? feedback.student.name : 'No hay nombre' : 'No hay nombre'} />,
      mentor: <CardEstudiante nombre={feedback?.mentor ? feedback?.mentor?.name ? feedback.mentor.name : 'No hay nombre' : 'No hay nombre'} />,
      puntuacion: <CardEstudiante nombre={feedback?.pregunta_satisfaccion ? feedback.pregunta_satisfaccion : 'No hay puntuacion'} />,
      comentarios: <CardAsistencias asistencias={feedback.comentarios ? feedback.comentarios : 'No hay comentarios'} />
    }
  })



  return (
    <AdminLayout location={'dashboard'}>
      <div className="BodyWeb"
        style={{
          padding: "0rem 2rem",
          position: "absolute",
          right: "0",
          backgroundColor: '#dde9f5',
          height: 'fit-content',
        }}>
        <AdminNavBar locate={'dashboard'} />
        <div className="admin-unilever-container-graphs">
          <div className={"admin-unilever-graphs-subcontainer"} style={{
            marginTop: '0',
          }}>
            <div className={"mentor-graphs-card"}>
              <div className={"mentors-graphs-card-title"}>
                <h1 style={{
                  margin: '0',
                  fontSize: '16px',
                  fontWeight: '400',
                  color: '#393939',
                }}>
                  Asistencias a mentorías
                </h1>
                {assistances ?
                  <LineChart width={600} height={600} data={assitanceParsedDataForGraphs()}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="date" style={{
                      marginTop: '10px',
                    }} />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    <Line type="monotone" dataKey="asistencias" stroke="#8884d8" activeDot={{ r: 8 }} />
                  </LineChart>
                  : null}
              </div>
            </div>
          </div>
          <div className={"admin-unilever-graphs-row-container"}>
            <div className={"admin-unilever-graphs-subcontainer"}>
              <div className={"mentor-users-card"}>
                <div className={"mentors-users-card-title"}>
                  <h1 style={{
                    margin: '0',
                    fontSize: '16px',
                    fontWeight: '400',
                    color: '#393939',
                  }}>
                    Mentores
                  </h1>
                </div>
                <div className={"mentors-users-card-quantity"}>
                  {mentors ? <h1 style={{
                    margin: '0',
                    fontSize: '20px',
                    fontWeight: 'bold',
                    color: '#7293bb',
                  }}>
                    {mentors.length}
                  </h1> : null}
                </div>
              </div>
            </div>
            <div className={"admin-unilever-graphs-subcontainer"}>
              <div className={"mentor-users-card"}>
                <div className={"mentors-users-card-title"}>
                  <h1 style={{
                    margin: '0',
                    fontSize: '16px',
                    fontWeight: '400',
                    color: '#393939',
                  }}>
                    Estudiantes
                  </h1>
                </div>
                <div className={"mentors-users-card-quantity"}>
                  {students ? <h1 style={{
                    margin: '0',
                    fontSize: '20px',
                    fontWeight: 'bold',
                    color: '#7293bb',
                  }}>
                    {students.length}
                  </h1> : null}
                </div>
              </div>
            </div>
            <div className={"admin-unilever-graphs-subcontainer"}>
              <div className={"mentor-users-card"} style={{
                height: '100px',
              }}>
                <div className={"mentors-users-card-title"}>
                  <h1 style={{
                    margin: '0',
                    fontSize: '16px',
                    fontWeight: '400',
                    color: '#393939',
                  }}>
                    Reuniones realizadas hasta la fecha ({" " + meetingsDate.getDate()}/{meetingsDate.getMonth() + 1 + " "})
                  </h1>
                </div>
                <div className={"mentors-users-card-quantity"}>
                  {students ? <h1 style={{
                    margin: '0',
                    fontSize: '20px',
                    fontWeight: 'bold',
                    color: '#7293bb',
                  }}>
                    {countAssistances()}
                  </h1> : null}
                </div>
              </div>
            </div>
          </div>

          <div className={"admin-unilever-graphs-column-container"} style={{ paddingBottom: '5%', justifyContent: 'flex-start' }}>
            <div className={"admin-unilever-graphs-subcontainer"} style={{ alignSelf: 'center', borderRadius: '8px' }}>
              <Table sx={{ minWidth: 650, backgroundColor: '#f9f9f9', alignSelf: 'flex-start', borderRadius: '8px' }} aria-label="simple table">
                <TableHead style={{ borderBottom: '1px solid #697a8d' }}>
                  <TableRow>
                    <TableCell align="left" style={{ color: '#697a8d', fontSize: '20px', backgroundColor: '#f9f9f9' }}>Estudiante</TableCell>
                    <TableCell align="left" style={{ color: '#697a8d', fontSize: '20px', backgroundColor: '#f9f9f9' }}>Asistencias</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.map((row, index) => {
                    return (
                      <TableRow
                        key={index}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                      >
                        <TableCell align="left" component="th" scope="row" style={{ color: '#697a8d', fontSize: '16px', backgroundColor: '#f9f9f9' }}>
                          {row.estudiantes}
                        </TableCell>
                        <TableCell align="left" style={{ color: '#697a8d', fontSize: '16px', backgroundColor: '#f9f9f9' }}>{row.asistencias}</TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </div>
            <div className={"admin-unilever-graphs-subcontainer"}>
              <div className={"mentor-graphs-card"}>
                <Table sx={{ minWidth: 650, backgroundColor: '#f9f9f9', alignSelf: 'flex-start', borderRadius: '8px' }} aria-label="simple table">
                  <TableHead style={{ borderBottom: '1px solid #697a8d' }}>
                    <TableRow>
                      <TableCell align="left" style={{ color: '#697a8d', fontSize: '20px', backgroundColor: '#f9f9f9' }}>Estudiante</TableCell>
                      <TableCell align="left" style={{ color: '#697a8d', fontSize: '20px', backgroundColor: '#f9f9f9' }}>Mentor</TableCell>
                      <TableCell align="left" style={{ color: '#697a8d', fontSize: '20px', backgroundColor: '#f9f9f9' }}>Puntuacion</TableCell>
                      <TableCell align="left" style={{ color: '#697a8d', fontSize: '20px', backgroundColor: '#f9f9f9' }}>Comentarios</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rows2 && rows2.map((row, index) => {
                      return (
                        <TableRow
                          key={index}
                          sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                          <TableCell align="left" component="th" scope="row" style={{ color: '#697a8d', fontSize: '16px', backgroundColor: '#f9f9f9' }}>
                            {row.estudiantes}
                          </TableCell>
                          <TableCell align="left" style={{ color: '#697a8d', fontSize: '16px', backgroundColor: '#f9f9f9' }}>{row.mentor}</TableCell>
                          <TableCell align="left" component="th" scope="row" style={{ color: '#41e055', fontSize: '16px', backgroundColor: '#f9f9f9' }}>
                            {row.puntuacion}
                          </TableCell>
                          <TableCell align="left" component="th" scope="row" style={{ color: '#697a8d', fontSize: '16px', backgroundColor: '#f9f9f9' }}>
                            {row.comentarios}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </div>
            </div>
          </div>
        </div>
        <div style={{
          marginBottom: '50px',
        }}>
          <TablaAgendadoReunion />

          <TablaUserLogin />

          <TablaMentorLogin />

          <TablaAplicadoOfertas />

          <TablaAsistenciaMasterclass />
        </div>
      </div>
    </AdminLayout>
  )
}


export default AdminUnileverGraphs;

