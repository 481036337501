import React, { useContext } from "react";
import { useSelector } from "react-redux";
import { SchneiderContext } from "../../../../context/SchneiderContext";
import { Link } from "react-router-dom";
import {
  GridCardContainer,
  GridReusableContainer,
  GridCardImageContainer,
  GridCardImage,
  GridCardLinkText,
  GridCardTitleContainer,
  GridCardTitle,
  PlaceIcon,
  GridPlaceTextContainer,
  PercentageContainer,
  GridCardMiddleContainer,
  GridCardDescriptionContainer,
  GridCardDescription,
  GridCardAdditionalInfoContainer,
  GridCardStopInfo,
  GridCardBottomContainer,
  GridCardSalaryContainer,
  SalaryText,
  TimeSpan,
  ApplyContainer,
  GridPlaceContainer,
} from "./GridCardStyles";
import { CustomizableTitle } from "../../../../styles/TitleStyles";
import { ReduxStateInterface } from "../../../../types/reduxInterface";
import { ApplyButton } from "../../../buttons/ButtonStyles";

interface GridCardProps {
  imagen?: string;
  lenguaje?: string;
  id?: string;
  salario?: string;
  titulo?: string;
  titulo2?: string;
  descripcion?: string;
  ciudad?: string;
  profesionales?: string;
  modalidadDeTrabajo?: string;
  porcentaje?: number;
}

const GridCard = ({
  imagen,
  lenguaje,
  id,
  salario,
  titulo,
  titulo2,
  descripcion,
  ciudad,
  profesionales,
  modalidadDeTrabajo,
  porcentaje,
}: GridCardProps) => {
  const { lang } = useSelector((state: ReduxStateInterface) => state);
  const { schneider } = useContext(SchneiderContext);

  function formatTitulo(titulo: string, titulo2: string): string {
    if (titulo !== "Unnamed Oportunity") {
      if (titulo.length > 30) {
        return (
          titulo.substring(0, 30).charAt(0).toUpperCase() +
          titulo.substring(0, 30).slice(1).toLowerCase() +
          "..."
        );
      }
      return titulo.charAt(0).toUpperCase() + titulo.slice(1).toLowerCase();
    }
    if (titulo2.length > 30) {
      return (
        titulo2.substring(0, 30).charAt(0).toUpperCase() +
        titulo2.substring(0, 30).slice(1).toLowerCase() +
        "..."
      );
    }
    return titulo2.charAt(0).toUpperCase() + titulo2.slice(1).toLowerCase();
  }

  const modalidadDeTrabajoTraducir = (modalidad: string) => {
    if (modalidad === "Presencial") {
      return lang === "es" ? "Presencial" : "On-site";
    } else if (modalidad === "Remoto") {
      return lang === "es" ? "Remoto" : "Remote";
    } else if (modalidad === "Híbrida") {
      return lang === "es" ? "Híbrida" : "Hybrid";
    }
  };

  const formatterPeso = new Intl.NumberFormat("es-CO", {
    style: "currency",
    currency: "COP",
    minimumFractionDigits: 0,
  });

  return (
    <GridCardContainer>
      <GridReusableContainer>
        <GridCardImageContainer>
          <GridCardImage
            src={imagen ? imagen : ""}
            alt={imagen ? imagen : ""}
          />
        </GridCardImageContainer>

        <GridCardLinkText to={`/${schneider.route}/project/${id}`}>
          <GridCardTitleContainer>
            <GridCardTitle color={schneider.color.primary}>
              {formatTitulo(titulo, titulo2)}
            </GridCardTitle>
          </GridCardTitleContainer>
        </GridCardLinkText>
      </GridReusableContainer>
      {ciudad ? (
        <GridPlaceContainer>
          <PlaceIcon color={schneider.color.primary} />
          <GridPlaceTextContainer>{ciudad}</GridPlaceTextContainer>
        </GridPlaceContainer>
      ) : null}
      {porcentaje ? (
        <PercentageContainer>
          {porcentaje.toFixed(2)}%{" "}
          {lang === "es" ? "Compatibilidad" : "Compatibility"}
        </PercentageContainer>
      ) : null}
      <GridCardMiddleContainer>
        <GridCardDescriptionContainer>
          <GridCardDescription>
            {lang === "es"
              ? "Profesionales con conocimiento en: "
              : "Professionals with knowledge in: "}
          </GridCardDescription>
          {!descripcion || descripcion === "NA" || descripcion === "Otro"
            ? lenguaje === "es"
              ? "No especifica"
              : "Not specified"
            : descripcion.length > 100
              ? descripcion.substring(0, 100) + "..."
              : descripcion}
        </GridCardDescriptionContainer>
        <GridCardAdditionalInfoContainer>
          <CustomizableTitle
            color={schneider.color.primary}
            size={"14px"}
            weight={"400"}
          >
            {lang === "es" ? "Abierta" : "Open"}
          </CustomizableTitle>
          {modalidadDeTrabajo &&
            <>
              <GridCardStopInfo>•</GridCardStopInfo>
              <CustomizableTitle color={"#989898"} size={"14px"} weight={"400"}>
                {modalidadDeTrabajoTraducir(modalidadDeTrabajo)}
              </CustomizableTitle>
            </>
          }
          <GridCardStopInfo>•</GridCardStopInfo>
          <CustomizableTitle color={"#989898"} size={"14px"} weight={"400"}>
            {profesionales} {lang === "es" ? "aplicantes" : "applicants"}
          </CustomizableTitle>
        </GridCardAdditionalInfoContainer>
        <GridCardBottomContainer>
          <GridCardSalaryContainer>
            {!salario.length ? (
              lang === "es" ? (
                <SalaryText
                  color={schneider.color.primary}
                  weight={"300"}
                  margin={"10px"}
                  size={"14px"}
                >
                  Salario no especificado
                </SalaryText>
              ) : (
                <SalaryText
                  color={schneider.color.primary}
                  weight={"300"}
                  margin={"10px"}
                  size={"14px"}
                >
                  Salary not specified
                </SalaryText>
              )
            ) : typeof salario === "number" ? (
              <SalaryText
                color={schneider.color.primary}
                weight={"700"}
                margin={"10px"}
                size={"14px"}
              >
                {formatterPeso.format(salario)}
              </SalaryText>
            ) : (
              <SalaryText
                color={schneider.color.primary}
                weight={"700"}
                margin={"10px"}
                size={"14px"}
              >
                {/*TODO: DESCOMENTAR CUANDO SE UTILICE EN EL MODELO EN FORMATO NUMERO*/}
                {/*{salario.split(" ")[0] === "COP" ||
                salario.split(" ")[0] === "USD"
                  ? salario.split(" ")[0] === "Divisa"
                    ? `$ ${salario.split(" ")[1]}`
                    : salario.split(" ")[1] === "0"
                    ? lang === "es"
                      ? "Salario no especificado"
                      : "Salary not specified"
                    : `${salario.split(" ")[0]} ${salario.split(" ")[1]}`
                  : `${salario.split(" ")[1]}`}*/}
                {salario}
                <TimeSpan>
                  {lang === "es" && salario.split(" ")[1] !== "0"
                    ? "/mensual"
                    : lang === "en" && salario.split(" ")[1] !== "0"
                      ? "/month"
                      : ""}
                </TimeSpan>
              </SalaryText>
            )}
          </GridCardSalaryContainer>
          <ApplyContainer>
            <Link to={`/${schneider.route}/project/${id}`}>
              <ApplyButton color={schneider.color.primary}>
                {lang === "es" ? "Aplicar" : "Apply"}
              </ApplyButton>
            </Link>
          </ApplyContainer>
        </GridCardBottomContainer>
      </GridCardMiddleContainer>
    </GridCardContainer>
  );
};

export default GridCard;
