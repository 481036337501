import React, { useState } from "react";
import { Link } from "react-router-dom";
import { logoutUser, changeview } from "../../actions";
import { connect } from "react-redux";
import logo from "../../pages/Consultor/Styles/imagenes/headerlogo.png";
// import { Icon } from 'react-icons-kit'
// import {pie_chart} from 'react-icons-kit/ikons/pie_chart'
// import CreateOutlinedIcon from '@material-ui/icons/CreateOutlined';
/* import CheckCircleIcon from '@material-ui/icons/CheckCircle'; */
import PersonOutlineIcon from '@material-ui/icons/PersonOutline';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import PaymentIcon from '@material-ui/icons/Payment';
// import PhoneIcon from '@material-ui/icons/Phone';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import LanguageIcon from '@material-ui/icons/Language';
import LiveHelpIcon from '@material-ui/icons/LiveHelp';
import PolicyIcon from '@material-ui/icons/Policy';
import AssignmentIcon from '@material-ui/icons/Assignment';
/* import NotificationsNoneIcon from '@material-ui/icons/NotificationsNone'; */
import en from "../../lang/en-US";
import es from "../../lang/es-COL"
import counterpart from "counterpart";
import Translate from "react-translate-component";

/* import LocalOfferIcon from '@material-ui/icons/LocalOffer';
 */import ExitToAppIcon from '@material-ui/icons/ExitToApp';
//import CallIcon from '@material-ui/icons/Call';

import "./Header.css";

const Header = (props) => {
  const handleLogout = () => {
    document.cookie =
      "ActualProject=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/";
    document.cookie =
      "ActualProjec=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/";
    document.cookie = "Aemail=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/";
    document.cookie = "Aname=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/";
    document.cookie = "Aid=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/";
    document.cookie = "Atoken=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/";
    document.cookie =
      "Aprojects=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/";
    document.cookie = "Aform=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/";
    document.cookie = "Aroles=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/";
    document.cookie = "Methodology=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/";
    document.cookie = "singleEtapa=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/";
    document.cookie = "ActualProject=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/";
    document.cookie = "casos_exito=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/";
    document.cookie = "signature=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/";
    document.cookie = "AprojectsConsultor=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/";
    document.cookie = "tickets=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/";
    document.cookie = "certificados=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/";
    document.cookie = "kps=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/";
    document.cookie = "nda=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/";
    document.cookie = "Atareas=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/";
    document.cookie = "calificaion_aptitudes=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/";

    props.logoutUser({}, "/");
  };


  const [showTitleProyectos, setShowTitleProyectos] = useState(false);
  const [showTitleEducacion, setShowTitleEducacion] = useState(false);

  //const lang = useSelector(state => state.lang)

  if(!localStorage.getItem('langlocal')){
    localStorage.setItem('langlocal','es')
  }
  const [locallang,setLocallang]=useState(localStorage.getItem('langlocal'))
  const cambiarlanges=(camlang)=>{
    localStorage.setItem('langlocal',camlang)
    setLocallang(localStorage.getItem('langlocal'))
  }
  
  counterpart.registerTranslations('en',en) 
  counterpart.registerTranslations('es',es)  
  counterpart.setLocale(localStorage.getItem('langlocal'));
  return (
    <>
    
{locallang?null:null}
   {showTitleProyectos?null:null}       
   {showTitleEducacion?null:null}       
   <div style={{display:'flex',background:'white',paddingLeft:'15.3%',width:'100%'}}>

    <div style={{display:'flex',justifyContent:'space-between',padding:'10px',width:'78%'}}>
      {/* logo */}
      <div className="grap" style={{display:'flex',width:'50%',justifyContent:'space-between',alignItems:'center'}}>
      <div className="header-logo" >
        <Link to="/consultor/home">
          <img
            src={logo}
            alt="logo img"
            width="155px"
            height="40px"
          />
        </Link>
      </div>
      <Link to="/consultor/education">
              <div className="semiboldfont"  onMouseOver={() => setShowTitleEducacion(true)} onMouseLeave={() => setShowTitleEducacion(false)}>{window.location.href.includes("education")?<Translate className="educacion-header-selecionado semiboldfont" content="Educación" component="span" />:<Translate className="educacion-header semiboldfont" content="Educación" component="span" />}</div>
            </Link>
            <Link to="/consultor/projects">
            <div className="semiboldfont" onMouseOver={() => setShowTitleProyectos(true)} onMouseLeave={() => setShowTitleProyectos(false)} >{window.location.href.includes("projects")?<Translate className="educacion-header-selecionado semiboldfont" content="oportunidadess" component="span" />:<Translate className="educacion-header semiboldfont" content="oportunidadess" component="span" />}</div>
            </Link>
            </div>
{/*       <input className='search_consultor' placeholder='&#x1f50d;&#xfe0e; Buscar en CoAlly'></input>  */}{/* Esto es solo presentacional, hay que hace el componente sarch de consultores y colocarlo en lugar de este input*/}
      {/*header navigation */}
      <div className="header__icons-group">
        <ul className="header__icons__group-ul">
          <li className="header__icons__group-li">
            
          </li>

          <li className="header__icons__group-li">
            
          </li>

          {/* <li className="header__icons__group-li">
          <NotificationsNoneIcon style={{ color: '#212156' }} />
          <ul className="header__display-menu" style={{width:'300px', marginLeft:'-200px', marginTop:'0px'}}>
          <li className="header__display__menu-item">signin</li>
          <li className="header__display__menu-item">Hola user</li>
          </ul>
        </li> */} 
          {/* <select className="Header-lang" value={lang} onChange={(e)=>dispatch(changeLang(e.target.value))}>
        <option value="en">en-US</option>
        <option value="es">es-COL</option>
      </select> */}
          <li className="header__icons__group-li">
            <PersonOutlineIcon style={{  fontSize:'30px' ,color: '#696a7c' }} />
           
            <ul className="header__display-menu" style={{ zIndex: '300', width:'300px', marginLeft:'-270px', marginTop:'0px'}}>
              <li className="header__display__menu-perfil-ver-perfil">
                <AccountCircleIcon style={{ color: '#212156', fontSize: '20vh' }} />
                <div className="header__display__menu__perfil-data">
                  {/*<p>Username</p>*/}
                  <p className="header-display-name-coallier">COALLIER</p>
                 {/* <Link to="/consultor/profile">
                     <p className="header-display-see-profile">{<Translate content="Verperfil" component="span" />}</p></Link> */}
                </div>
              </li>
              <Link to="/consultor/certificados">
                <li className="header__display__menu__item-pagos">
                  <PaymentIcon style={{ color: '#212156', fontSize: '20px' }} />
                  <p className="menu-text" >{<Translate content="Pagosycertificados" component="span" />}</p>
                </li>
              </Link>
{/*               <Link onClick={() => props.changeview('coallier')} to="/consultor/home">
                <li className="header__display__menu-item_coallier">
                  <PersonOutlineIcon style={{ color: '#212156', fontSize: '20px' }} />
                  <p className="menu-text">Coallier</p>
                </li>
              </Link> */}
              <Link onClick={() => props.changeview('company')} to="/company/home">
                <li className="header__display__menu-item_company">
                  <PersonOutlineIcon style={{ color: '#212156', fontSize: '20px' }} />
                  <p className="menu-text">{<Translate content="Empresa" component="span" />}</p>
                </li>
              </Link>
{/*               <Link to="/casos-exito">
                <li className="header__display__menu-item">
                <CheckCircleIcon style={{ color: '#212156', fontSize: '20px' }} />
                  <p className="menu-text">{<Translate content="Casosdeexito" component="span" />}</p>
                  </li>
              </Link> */}
              {/* <li className="header__display__menu-item">
                  <PhoneIcon style={{ color: '#212156', fontSize: '20px' }} />
                  <p className="menu-text">{<Translate content="Contáctanos" component="span" />}</p>
                </li> */}
              {/* <Link to="/ContactUs">
                <li className="header__display__menu-item">
                  <PhoneIcon style={{ color: '#212156', fontSize: '20px' }} />
                  <p className="menu-text">{<Translate content="Contáctanos" component="span" />}</p>
                </li>
              </Link> */}
              <Link to="/Support_ticket">
                <li className="header__display__menu-item">
                  <LiveHelpIcon style={{ color: '#212156', fontSize: '20px' }} />
                  <p className="menu-text">{<Translate content="SoporteCoally" component="span" />}</p>
                </li>
              </Link>
              <Link to="/Faq">
                <li className="header__display__menu-item">
                  <HelpOutlineIcon style={{ color: '#212156', fontSize: '20px' }} />
                  <p className="menu-text">{<Translate content="PreguntasFrecuentes" component="span" />}</p>
                </li>
              </Link>
              <Link to="/DataPolicy">
                <li className="header__display__menu-item">
                  <PolicyIcon style={{ color: '#212156', fontSize: '20px' }} />
                  <p className="menu-text">{<Translate content="Politicadeprivacidad" component="span" />}</p>
                </li>
              </Link>
              <Link to="/TyC">
                <li className="header__display__menu-item">
                  <AssignmentIcon style={{ color: '#212156', fontSize: '20px' }} />
                  <p className="menu-text">{<Translate content="Terminosycondiciones" component="span" />}</p>
                </li>
              </Link>
              <Link to="#" onClick={handleLogout}>
                <li className="header__display__menu-item">
                  <ExitToAppIcon style={{ color: '#212156', fontSize: '20px' }} />
                  <p className="menu-text" >{<Translate content="Cerrarsesión" component="span" />}</p>
                </li>
              </Link>
              <p className="header__display_copy">© 2021 CoAlly | Powered by CoAlly</p>
            </ul>
          </li>
        </ul>
      </div>
        <div className="idioma0" style={{position:'absolute',right:'0',marginRight:'15%',zIndex:'160',marginTop:'0.4rem'}}>{'EN'}
            <div className="idioma" > <button style={{background:'none'}} onClick={()=>cambiarlanges('es')}>ES</button> </div>
            <div className="idioma1" > <button style={{background:'none'}} onClick={()=>cambiarlanges('en')}>EN</button> </div>
            <div className="idioma2" > <button  style={{cursor:"default",background:'none'}}><LanguageIcon></LanguageIcon></button> </div>
          </div>
    </div>
</div>
    </>
  );
};
const mapDispatchToProps = {
  logoutUser,
  changeview,
};
export default connect(null, mapDispatchToProps)(Header);
