import React from "react";
import "./VideoList.css";
import { useSelector } from "react-redux";
import en from "../../lang/en-US";
import es from "../../lang/es-COL"
import counterpart from "counterpart";
import Translate from "react-translate-component";

function Video(props) {

  counterpart.registerTranslations('en',en) 
  counterpart.registerTranslations('es',es)
  counterpart.setLocale(localStorage.getItem('langlocal'));
    return (
      <>
      {/*video card component */}
        <div className="video">
          <div className="video-media">
          <iframe
            className="education-iframe-box"
            width="100%"
            height="250px"
            src={
              `https://www.youtube.com/embed/${props.video.video}` ||
              "https://www.youtube.com/embed/onV54dQZgSg"
            }
            title="video coally"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          ></iframe>
          </div>
          <div className="video-data">
            <div className="video__data-head">
            <p className="video-data-p"> {props.video.titulo || <Translate content="Titulodevideo" component="span"/> }</p>
            </div>
            <p className="video-data-desc"> {props.video.descripcion || <Translate content="Descripcion" component="span"/> } </p>
            <p className="video-data-leng">{props.video.idioma || <Translate content="IDIOMA" component="span"/>}</p>
            <p className="video-data-prof">{props.video.profesion || <Translate content="profesion" component="span"/>}</p>
          </div>
        </div>
      </>
    );
}

function VideoList (props){ 

  const videos = useSelector(state => state.videos)
  return (
    <div className="videolist">
        {props.filter !== "" ? 
        <div className="videoslist__container">
          {
          videos.filter((e)=>{
            return (
             e.categoria === props.filter
            )
          }).map((video) => {
            return (
              <div className="videoslist__container-cpn" key={video._id}>
                <Video video={video} />
              </div>
            );
          })}
        </div> :
        <div className="videoslist__container">{
          props.video ? props.video.map((video) => {
            return (
              <div className="videoslist__container-cpn" key={video._id}>
                <Video video={video} />
              </div>
            );
          }) : null     
          }
        </div>
      } 
    </div>
  );
}

export default VideoList;
