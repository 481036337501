import React, { Fragment, useState } from "react";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button";
import en from "../../lang/en-US";
import es from "../../lang/es-COL"
import counterpart from "counterpart";
import Translate from "react-translate-component";

// Destructuring props
const FirstStep = ({
  handleNext,
  handleChange,
  values: { linea_negocio, codigo_promo, nivel_ingles,  habilidades_requeridas, comentario },
  formErrors
}) => {
  // Check if all values are not empty or if there are some error
  const isValid =
  linea_negocio.length > 0 &&
    !formErrors.linea_negocio &&
    nivel_ingles.length > 0 &&
    !formErrors.nivel_ingles &&
    habilidades_requeridas.length > 0 &&
    !formErrors.habilidades_requeridas &&
    comentario.length > 0 &&
    !formErrors.comentario;

    const [otroCausa, setotroCausa] = useState("")
    //const lang = useSelector(state => state.lang)

  
    counterpart.registerTranslations('en',en) 
    counterpart.registerTranslations('es',es)  
    counterpart.setLocale(localStorage.getItem('langlocal'));

    const Ciudaddelproyecto =counterpart.translate('FechaFin')

  return (
    <Fragment>
      <Grid container spacing={2} noValidate>
      <Grid item xs={12} sm={6}>
          <FormControl fullWidth  margin="normal">
            <InputLabel>{<Translate content="LÍNEADELNEGOCIO" component="span"/>}</InputLabel>
            <Select value={linea_negocio} onChange={handleChange} name="linea_negocio">
              <MenuItem value={"Desarrollo de software"}>{<Translate content="Desarrollodesoftwareybigdata" component="span"/>}</MenuItem>
              <MenuItem value={"Marketing digital"}>{<Translate content="Marketingdigitalyestudiosdemercado" component="span"/>}</MenuItem>
              <MenuItem value={"Innovacion e intraemprendimiento"}>{<Translate content="Innovacióneintraemprendimiento" component="span"/>}</MenuItem>
            </Select> 
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label={<Translate content="InsertaacatuCodigoPromocional" component="span"/>}
            name="codigo_promo"
            placeholder={Ciudaddelproyecto}
            margin="normal"
            value={codigo_promo || ""}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label={<Translate content="Quéniveldeinglésserequiere" component="span"/>}
            name="nivel_ingles"
            placeholder=""
            margin="normal"
            value={nivel_ingles || ""}
            onChange={handleChange}
            error={!!formErrors.nivel_ingles}
            helperText={formErrors.nivel_ingles}
            required
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label={<Translate content="CUALESSONLOSOBJETIVOSQUEQUIERES" component="span"/>}
            name="comentario"
            placeholder=""
            type=""
            value={comentario || ""}
            onChange={handleChange}
            margin="normal"
            required
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth  margin="normal">
            <InputLabel>{<Translate content="Habilidadesrequeridas" component="span"/>}</InputLabel>
            <Select value={habilidades_requeridas} onChange={handleChange} name="habilidades_requeridas">
              <MenuItem value={"Finanzas y negociacion"}>{<Translate content="FinanzasNegociaciónyliderazgo" component="span"/>}</MenuItem>
              <MenuItem value={"Ingenierias"}>{<Translate content="Ingenierias" component="span"/>}</MenuItem>
              <MenuItem value={"Planeacion de negocios"}>{<Translate content="Planeaciónymodelajedenegocios" component="span"/>}</MenuItem>
              <MenuItem value={"Impacto social y sostenibilidad"}>{<Translate content="Impactosocialysostenibilidad" component="span"/>}</MenuItem>
              <MenuItem value={"Quiero reestructrar las bases de mi negocio"}>{<Translate content="Quieroreestructrarlasbasesdeminegocio" component="span"/>}</MenuItem>
              <MenuItem value={otroCausa} onClick={()=>setotroCausa("otro")} >{<Translate content="Otro" component="span"/>}</MenuItem>
            </Select>
            { otroCausa==='otro' ? 
               <TextField
               fullWidth
               label={<Translate content="Otro" component="span"/>}
               name="habilidades_requeridas"
               placeholder=""
               margin="normal"
               value={habilidades_requeridas || ""}
               onChange={handleChange}
               error={!!formErrors.habilidades_requeridas}
               helperText={formErrors.habilidades_requeridas}
               
             />: null}
          </FormControl>
        </Grid>
      </Grid>
      <div
        style={{ display: "flex", marginTop: 50, justifyContent: "flex-end" }}
      >
        <Button
          variant="contained"
          disabled={!isValid}
          color="primary"
          onClick={isValid ? handleNext : null}
        >
          {<Translate content="siguienteee" component="span"/>}
        </Button>
      </div>
    </Fragment>
  );
};

export default FirstStep;
