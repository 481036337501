import styled from "styled-components";
import { Link } from "react-router-dom";
const HomeContainer = styled.div`
  width: 100%;
  padding-left: 100px;
  padding-right: 100px;

  @media (max-width: 930px) {
    padding-left: 0;
    padding-right: 0;
  }
`;

const HomeContent = styled.div`
  width: 100%;
  margin: 40px auto 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const HomeContentTop = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
`;

const HomeOpportunitiesContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(324px, 1fr));
  place-content: center;
  grid-gap: 30px;
  margin-top: 32px;
  width: 100%;
`;

const SeeMoreContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-top: 50px;
`;

const SeeMoreLink = styled(Link)`
  border: none;
  background-color: transparent;
  color: #5d5d5d;
  font-size: 16px;
  font-weight: 400;
`;

const HomeTestsContainer = styled.div`
  width: 100%;
  height: 100%;
  margin-top: 50px;
`;

const HomeTestTextContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  height: 100%;
`;

const TestText = styled.h2`
  font-weight: 700;
  font-size: 24px;
  color: #393939;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  margin: 0;
`;

const HomeTestsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(324px, 1fr));
  place-content: center;
  grid-gap: 30px;
  margin-top: 32px;
  width: 100%;
`;

const HomeBottomContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 40px;
  justify-content: flex-start;
  align-items: center;
  margin: 32px 0;
  width: 100%;

  @media (max-width: 1420px) {
    flex-direction: column;
  }

  @media (max-width: 1300px) {
    flex-direction: row;
  }
  @media (max-width: 1200px) {
    flex-direction: column;
  }
`;

const HomeCarouselContainer = styled.div`
  width: 75%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  @media (max-width: 1420px) {
    width: 100%;
  }

  @media (max-width: 1200px) {
    width: 100%;
  }
`;

const CarouselTextContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
`;

const CarouselText = styled.h2`
  font-size: 24px;
  font-weight: 700;
  color: #393939;
`;

const CarouselContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  margin-top: 32px;

  @media (max-width: 740px) {
    margin-bottom: 32px;
    margin-right: 0;
  }
`;

const HomeCvPopupContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
`

const HomeCvPopupButtons = styled.button`
  height: 45px;
  border-radius: 5px;
  border: none;
  background-color: #f5f5f5;
  color: #393939;
  font-size: 16px;
  font-weight: 400;
  cursor: pointer;
  transition: 0.3s;
`

export {
  HomeContainer,
  HomeContent,
  HomeContentTop,
  HomeOpportunitiesContainer,
  SeeMoreContainer,
  SeeMoreLink,
  HomeTestsContainer,
  HomeTestTextContainer,
  TestText,
  HomeTestsGrid,
  HomeBottomContainer,
  HomeCarouselContainer,
  CarouselTextContainer,
  CarouselText,
  CarouselContainer,
  HomeCvPopupContainer,
  HomeCvPopupButtons
};
