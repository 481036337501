export default {
  login: "Inicio de sesión",
  Ingresamail: "Correo electrónico",
  contraseña: "Contraseña",
  entrar: "Entrar",
  aunno: "¿No tienes una cuenta aún?",
  registrarse: "Regístrate",
  olvidado: "¿Has olvidado tu contraseña?",
  donwloadfile: 'Descargar archivo',
  Pagos: 'Pagos',
  nombre: "Nombre",
  registrate: "Registrate",
  recuperatucontrasena: "Recuperar Contraseña",
  tipodeusuario: "Tipo de usuario",
  tyc: "Acepto los terminos y condiciones",
  alredyuser: "¿Ya eres un usuario?",
  AboutUs: "About Us.",
  plataformaconectaequipos: "Coally es una plataforma que conecta equipos de jovenes profesionales con emprendedoras para añadir valor a las economias emergentes",
  tecnologiainnovadora: "Usando tecnologia innovadora, estudiantes iinterdiciplinares y ejecutando un aprendizaje exponencial entregamos soluciones a nuestros clientes",
  Conocemas: "Conoce mas",
  Conocealgunos: "Conoce algunos de nuestros consultores",
  OurFocus: "Nuestro Focus.",
  Elproposito: "El proposito de Coally es unir Capital humano con compañias que lo necesitan",
  Impactosocial: "Impacto social",
  Tomamoseltalento: "Tomamos el talento de las mejores universidades de colombia y les damos la oportunidad de impactar compañias y NGOs en diferentes sectores economicos",
  Compañias: "Compañias",
  Generamosimpacto: "Generamos impacto, usamos el conocimiento como catalizador de efectividad",
  Estudiantes: "Estudiantes",
  Coallyofrece: "Coally ofrece a los estudiantes desarrollar sus habilidades y aportar a los proyectos de manera activa",
  Nuestrosservicios: "Nuestros servicios",
  Encoallyteayudados: "En coally te ayudados a alcanzar tus metas",
  Quierosabermas: "Quiero saber mas",
  Reinvenciondespuesdelacrisis: "1. Reinvencion despues de la crisis",
  Queremosayudarteatomarventaja: "Queremos ayudarte a tomar ventaja de la crisis diseñando una estrategia para responder al COVID.",
  CrearProyecto: "Crear Proyecto",
  Estrategiadenegocios: "2 . Estrategia de negocios",
  Ofrecemosunaampliacantidad: "Ofrecemos una amplia cantidad de herramientas y servicios que apoyan cada etapa del desarrollo de estrategias.",
  Estructuraciondeproyectos: "3. Estructuracion de proyectos",
  Puedequetengasunagranidea: "Puede que tengas una gran idea, nosotros te ayudamos a estructurarla.",
  Emprendedores: "4. Emprendedores",
  Cremosquehaymejores: "Cremos que hay mejores ideas en camino.",
  proyectosrecomendados: "Proyectos recomendados para ti",
  Trabajoremoto: "5. Trabajo remoto",
  buscarr: "Buscar",
  buscarr2: "Buscar profesionales",
  cv: "Hoja de vida",
  profesionco: 'Profesión',
  Creemosquelosnegociosestan: "Creemos que los negocios estan migrando hacia una transformacion digital.",

  Habilidadesrequeridas: "Habilidades requeridas",
  Aplicarantesde: "Aplicar antes de:",
  Vermas: "Ver más",
  Aplicar: "Aplicar",
  Aplicaciónrealizadaconexito: "Aplicaste exitosamente",
  ESTUDIOS: "Profesión",

//PAGINA PROYECTOS EMPRESA
  Enespera: 'Proyectos en espera',
  Proyectosendesarrollo: 'Proyectos en Desarrollo',
  Proyectofinalizado: 'Proyectos completados',
  Entregas: 'Entregas',
  noproject: 'Aun no tienes proyectos',
  Comentarios: 'Comentarios',
  DETAILSPRO: 'Ver detalles',
  Avances: 'Avances',
  Equipo: 'Equipo',
  TerminarProyecto: 'Terminar Proyecto',
  Titulo: 'Title',
  GUARDAR: 'SAVE',
  familiarices: 'Queremos que te familiarices con la plataforma, por eso queremos invitarte a que pruebes participar en un proyecto de prueba. ¡Enviamos tus comentarios!',
  Aplicaaqui: 'Aplica Aquí',
  closesesion: 'Cierra sesión y vuelve a ingresar para visualizar tu proyecto de prueba',

//FORMULARIO REGISTRO EMPRESA
  nitidempresa: 'NIT / ID de la empresa',

//MENU EMPRESA

  Verperfil: 'Ver perfil',
  Contáctanos: 'Contáctanos',
  PreguntasFrecuentes: 'Preguntas frecuentes',
  Feedback: 'Retroalimentación',
  Terminosycondiciones: 'Terminos y condiciones',
  Cerrarsesión: 'Cerrar sesión',
  Habilidades: "Habilidades",
//PAGINA PERFIL EMPRESA
  PERFIL: 'Perfil',
  PROYECTOS: 'Proyectos',
  SECTOR: 'Sector',
  UBICACIÓN: 'Ubicación',
  ENLAWEB: 'En la web',
  REDES: 'Redes sociales',
  ConsultoriasRealizadas: 'Proyectos Creados',
  CREARPROYECTO: 'Crea un proyecto',
  EDITARPERFIL: 'EDITAR PERFIL',
  PROFESION: "Profesión",
  acercademi: "Sobre mi",
//PAGINA CREAR PROYECTO
  capital: 'Necesito capital',
  QUENOMBRELEPONDRAS: '¿Nombre del proyecto?',
  Estenombreseutilizara: 'This name will be used as a reference when publishing the Project',
  ENQUECIUDADSEREALIZARA: 'In which city will the project be made?',
  EstaCiudadseutilizaracomo: 'This city will be used as a reference to find the consultantsm',
  CUALESSONLOSOBJETIVOSQUEQUIERES: 'Objetivos que quieres ver cumplidos',
  Estosseranlosprincipalesobjetivosdelproyecto: 'These will be the main objectives of the Project',
  objetivos: 'objectives',
  ciudad: 'Ciudad',
  CUALESSONLASMETRICASQUEQUIERES: 'Metricas para el proyecto',
  Estosseranlosprincipalesmetricasdelproyecto: 'These will be the main Metrics of the Project',
  Métricas: 'Metrics',
  pandemia: 'La pandemia acabó mi negocio',
  cual: '¿Cual?',
  caupromblema: 'Cause of the problem',
  DESCRIBETUPROBLEMAENUNAORACIÓN: 'Describe tu problema',
  Lainformaciónquesediligenciará: 'The information that will be diligent is specifically the challenge or challenge with which we are going to work.',
  Describetuproblemaacá: 'Describe your problem here',
  CAUSASDELPROBLEMA: 'Causes of the problem',


  Cuálescreesquesonlascausasdeesteproblema: '¿Causas del problema?',
  Otro: 'Otro?',
  Elpersonalnoestátrabajandocomoesperaba: 'El personal no esta rindiendo como me gustaria',
  Sientoqueestamosestancados: 'Siento que estamos atascados',
  Quieroreestructrarlasbasesdeminegocio: 'Quiero reestructurar mi negocio',

  LÍNEADELNEGOCIO: 'Linea de negocio',
  Líneadenegocioenlaquenecesitasapoyo: 'Línea de negocio en la que necesita apoyo',

  Desarrollodesoftwareybigdata: 'Software Development and Big Data',
  Marketingdigitalyestudiosdemercado: 'Digital Marketing and Market Studies',


  Innovacióneintraemprendimiento: 'Innovation and intraemployment',
  CONOCIMIENTOSESPECÍFICO: 'Specific knowledge',


  Conocimientosespecíficosquerequiereelproyecto: 'Specific knowledge required by the project',

  FinanzasNegociaciónyliderazgo: 'Finance Negotiation and Leadership',
  Ingenierias: 'Ingenieria',
  Planeaciónymodelajedenegocios: 'Planning and business modeling',
  Impactosocialysostenibilidad: 'Social impact and sustainability',
  RecursosHumanos: 'Recursos humanos',
  Estrategia: 'Estrategia',
  Legal: 'Legal',
  Marketingyventas: 'Marketing y ventas',
  Categorias: 'Categorias',
  Trabajoremotoonly: "Trabajo remoto",
  NIVELDEINGLÉS: 'Nivel de ingles',

  Quéniveldeinglésserequiere: 'Que nivel de ingles se requiere en el proyecto?',
  Nulo: 'Nulo',
  Básico: 'Basico',
  Intermedio: 'Intermedio',
  Avanzado: 'Avanzado',

  CODIGOPROMOCIONAL: 'Codigo promocional',
  InsertaacatuCodigoPromocional: 'Inserta aca tu codigo promocional',
  Codigo: 'Codigo',
  COMENTARIOS: 'Comentarios',
  Comentariosoinformaciónadicionalrelevante: 'Comentarios o información adicional relevante',


//PAGINA REGISTRO EMPRESA
  COMPLETAELFORMULARIO: 'Completa el formulario',
  NOMBREDELAEMPRESA: 'Nombre de la empresa',
  Completaturegistro: "Completa tu registro",
  Estedatoserautilizadoenelcontratoydemas: 'Esta información sera utilizada en contratos y otros documentos legales',
  Nombreempresa: 'Nombre de la empresa',

  NOMBREDELREPRESENTANTELEGAL: 'Name of the legal representative',
  NUMERODECEDULADELREPRESENTANTELEGAL: 'Cedula number of the legal representative',
  CIUDADDEEXPEDICIONDELACEDULADELREPRESENTANTELEGAL: 'Expedition city of the Cedula of the Legal Representative',

  NIT: 'Nit',
  IngreseelNITdelaempresa: 'Ingrese el NIT/ ID de la empresa',
  SITIOWEB: 'Sitio web',

  Compartenosellinkdelapaginawebdelaempresa: 'Share the link of the company s website',


  TELEFONO: 'Telefono',

  Telefonodelaempresa: 'Telefono de la empresa',
  EMAIL: 'Correo electrónico',

  Emaildelaempresa: 'Email de la empresa',
  Ubicacióndelaempresa: 'Ubicación de la empresa',

  TIEMPOENELMERCADO: 'Tiempo en el mercado',
  Cuantotiempollevalaempresaenelmercado: 'How long does the company take in the market?',
  Menosde1año: 'Menos de un año',
  De1a3años: 'de 1 a 3 años',
  Masde3años: 'Más de 3 años',

  IDIOMA: 'Idioma',
  idio: 'Idioma',
  Queidiomasemanejaenlaempresa: 'Cuales idiomas manejan en la empresa?',

  Ingles: 'Ingles',
  chino: 'Chino',
  Portugues: 'Portugues',
  Español: 'Español',

  Enquemercadoestalaempresa: 'En cual mercado esta la empresa?',

  TAMAÑODELAEMPRESA: 'Tamaño de la empresa',

  Cuentanoseltamañodelaempresasegunlacantidad: 'Tell us the size of the company according to the number of employees',
  Microempresa: 'Micro company',
  Pequeñaempresa: 'Small company',
  Medianaempresa: 'Medium enterprises',
  GranEmpresa: 'Big company',


  DESCRIPCIONDELAEMPRESA: 'Descripción de la empresa',

  Unabrevedescripciondelaempresa: 'A brief description of the company',

  Descripcion: 'Descripción',

  ATRÁS: 'Volver',
  Definicióndelproyecto: "Definición del proyecto",
  SIGUIENTE: 'SIGUIENTE',

  FINALIZAR: 'FINALIZAR',

  ENVIAR: 'ENVIAR',


//PAGINA HOME COALLIER
  Proyectosrecomendadosparati: 'Projects recommended for you',

//HEADERCOALLIER
  Educación: 'Educación',
  Proyectos: 'Proyectos',
  Notificaciones: 'Notificaciones',
  Perfil: 'Perfil',


//MENU COALLIER

  Pagosycertificados: 'Pagos',
  Casosdeexito: 'Casos de exito',
  SoporteCoally: 'Soporte',
  Politicadeprivacidad: 'Politica de privacidad',
  Idioma: 'Idioma',


//PAGINA EDUCACION COALLIERS
  EducacionCoAlly: 'Educación CoAlly',
  Ademáshemosdiseñadoesteespacioconcontenidogratuitoeducativoparapotencializartusconocimientoscapacitarteeimpulsarteprofesionalmente: 'Además hemos diseñado este espacio con contenido gratuito educativo para potencializar tus conocimientos capacitarte e impulsarte profesionalmente',
  Paraaplicaralasofertasquetenemosparatiesnecesarioquetecapacitesviendolossiguientesvideos: 'Te recomendamos que mires estos videos para familiarizarte con nuestra plataforma',
  Seleccionalacategoríadelproblema: 'Selecciona la categoría del problema',
  soporte: 'Soporte',
  coally: 'Coally',
//project details
  checkcomments: "COMENTARIOS",
  checkcalendar: "CALENDARIO",
//PAGINA DE LEGAL COALLY
  tienescomentario: ' ¿Tienes algún comentario para tu equipo consultor o el proceso consultoría?',
  DocumentosLegalesCoally: 'LegaldocumentsCOALLY',
  continuarioas: '¿Continuarías trabajando con CoAlly?',
  CERTIFICADODEPROYECTO: 'Project certificate',
  CONTRATODECONFIDENCIALIDAD: 'Confidentiality contract',
  CONTRATODEPRESTACIÓNDESERVICIOS: 'CONTRACT OF SERVICE',
  SEGURIDADSOCIAL: 'SOCIAL SECURITY',
  Añadeunafirmaestaserautilizadaenlosdocumentoslegales: 'Add a signature, this will be used in legal documents:',
  Borrar: 'Delete',
  Firmar: 'Sign',
  Enviar: 'enviar',
  Hemosllegado: '¡Hemos llegado al final!',
  Continuaríastrabajando: '¿Continuarías trabajando con CoAlly?',


  estamosfelices: 'Estamos felices de haber logrado resolver este desafío juntos. Gracias por ser parte de este equipo; CoAlly consultoría consciente para todos.',
//PAGINA DE PAGOS COALLIERS
  califica: 'Califica al equipo consultor',

  Certificados: 'Certificados',
  Casosdeéxito: 'Casos de exito',
  Descargarcertificado: 'Download certificate',
  Descargarcasodeéxito: 'Download Success Case',
  direct: 'Mensaje Directo',
  SOPORTECOALLY: 'SOPORTE COALLY',
//PAGINA SUBIR CASOS DE ÉXITO
  Enesteespaciode: 'En este espacio de',
  bienestar: 'Bienestar',
  Seleccionaelproyectoysubeledocumentoconladescripciondetucasodeexito: 'Click abajo para subir un caso de exito, Recuerda debe estar en pdf',
  Selecttheprojectanduploadthedocumentwiththedescriptionofyoursuccesscase: 'Click below to upload a success case, remember it must be in pdf',
  puedescompartircon: ', puedes compartir con nosotros tus requerimientos, estamos para escucharte y ayudarte.',
  EscríbenosporWhatsApp: 'Escríbenos por WhatsApp',
//PAGINA CONTACTANOS COALLIER

  Enesteespaciodebienestarpuedescompartirconnosotrostusrequerimientosestamosparaescucharteyayudarte: 'In this space of well-being, you can share with us your requirements, we are here to listen to you and help you.',
  EscríbenosporWhatsApp018003453298: 'Write us by whatsapp 01800-345-3298.',
  contactofindallycom: 'contacto@findally.com.',
  MensajeDirecto: 'Mensaje',
  Nombrecompleto: 'Full name',
  Asunto: 'Subject',

  //no se usa
  tycT: 'Términos y Condiciones de Uso de la Plataforma coally',
  thisdoc: 'El presente documento establece las condiciones mediante las cuales se regirá el uso de la aplicación móvil: coally Colombia (en adelante la plataforma), la cual es operada por coally S.A.S compañía constituida en Colombia y domiciliada en la ciudad de Bogotá.',
  carring: 'La aplicación funcionará como un nuevo canal para la realización de ciertas actividades descritas más adelante, con el objeto de conectar las necesidades de quienes se suscriban como clientes (en adelante Clientes), y quienes se suscriban como Prestadores de servicio (en adelante Consultor).',
  comousted: 'Como usuario usted se compromete a leer los términos y condiciones aquí establecidos antes de hacer uso de la plataforma. Una vez ingrese a ésta, bien sea como Cliente o como Consultor, usted consiente quedar vinculado por estas Condiciones y por la Política de Protección de Datos personales, por lo que, si no está de acuerdo con todas las condiciones o las políticas acá establecidas no debe hacer uso de la plataforma.',
  cuertoparr: 'El usuario reconoce que el ingreso de su información, y los datos que contiene la aplicación, se realizan de manera voluntaria. Quienes optan por acceder a esta aplicación en Colombia o desde fuera del territorio nacional, lo hacen por iniciativa propia y son responsables del cumplimiento de las leyes locales, en la medida en que dichas leyes sean aplicables a su correspondiente país. El uso de esta plataforma está limitado a mayores de edad. En el evento en que no se cumpla esta condición, le agradecemos no hacer uso de la aplicación.',
  //se usa
  servicio: '1. Presentación',
  esunaplataforma: 'COALLY S.A.S. es una compañía colombiana que tiene como actividad principal\n' +
    'conectar empresas y/o personas naturales con profesionales jóvenes por medio\n' +
    'de una plataforma virtual, compuesta por una página web y una aplicación móvil,\n' +
    'para que se desarrolle un servicio de consultoría que aporte valor a los clientes. (\n' +
    'empresas y/o personas naturales).',
  siustedescon: 'COALLY S.A.S., con la finalidad de dar cumplimiento estricto a la normatividad\n' +
    'vigente de protección de Datos Personales, de acuerdo a lo establecido en la Ley\n' +
    '1581 del 2012, Decreto 1074 de 2015 y demás disposiciones que las modifiquen,\n' +
    'adicionen o complementen, presenta la siguiente POLÍTICA DE PROTECCIÓN Y\n' +
    'TRATAMIENTO DE DATOS PERSONALES (en adelante “Política de\n' +
    'Tratamiento”) con el propósito de proteger la información personal brindada por los\n' +
    'Titulares que tengan relación con COALLY S.A.S. como lo son clientes,\n' +
    'consultores y cualquier otra persona natural o jurídica de la cual COALLY S.A.S.,\n' +
    'obtenga, recolecta, procesa o trate datos personales, sea que dicho tratamiento\n' +
    'sea realizado por COALLY S.A.S. o por terceras personas que lo hagan por\n' +
    'encargo de ésta.',
  adicional: 'El objeto de la Política de Tratamiento es proteger el derecho constitucional del\n' +
    'Habeas Data que tienen todas las personas para conocer, actualizar, y rectificar la\n' +
    'información que se haya recogido y almacenado en las distintas bases de datos\n' +
    'de COALLY S.A.S., y en virtud del cumplimiento de dicho derecho sólo recolecta y\n' +
    'da Tratamiento a Datos Personales, cuando así haya sido autorizado previamente\n' +
    'por su Titular, implementando para tal efecto, medidas claras sobre\n' +
    'confidencialidad y privacidad de los Datos Personales. Así mismo, detalla los\n' +
    'lineamientos generales corporativos que se tienen en cuenta con el fin de proteger\n' +
    'los Datos Personales de los Titulares.',
  //no se usa
  siustedes: ' b.	Si usted es Cliente deberá registrarse como tal y hacer uso de esta plataforma para conectarse con los Consultores que estén disponibles para prestar un servicio de consultoría. Para esto, usted deberá publicar un proyecto acorde a sus necesidades. Posteriormente, usted será conectado con un Consultor de acuerdo con la convergencia entre sus necesidades y las capacidades del Consultor.',
  portanto: 'Por tanto el servicio que ofrece la plataforma se limita a ser uno de intermediación entre las partes mencionadas anteriormente, con la intención de suplir las necesidades inherentes a las mismas. Por medio de esta plataforma se accede al contrato a suscribir entre Consultor, Consultor Gold, y Cliente. Al aceptar estos Términos usted acepta que coally vincula a las partes involucradas en el proyecto –entiéndase Cliente con Consultor, Consultor Gold– por medio de un contrato de prestación de servicios, del que esta compañía no hace parte; y del que deriva una comisión por intermediación para coally y los honorarios estipulados en el contrato de prestación de servicio para para los prestadores. Dicha vinculación surte efecto una vez el algoritmo de la aplicación haya establecido el equipo consultor encargado de desarrollar la consultoría solicitada por el Cliente.',
  adicionalment: 'Adicionalmente al aceptar estos términos usted entiende que esta plataforma no cumple el rol de empleador, por lo que ningún Consultor, o Consultor Gold gozará de la calidad trabajador de la compañía.',
  //se usa
  alcanceyusu: '2. Ámbito de aplicación',
  paraquellos: 'Estos términos y condiciones y todo lo que tenga que ver con esta aplicación y las\n' +
    'relaciones jurídicas que surgen de esta, se rigen por las leyes colombianas,\n' +
    'específicamente a la Ley 1581 del 2012 y el Decreto 1074 de 2015',
  //no se usa
  paragold: 'Para aquellos usuarios que se registren como Gold Con el fin de usar los servicios ofrecidos por la plataforma, usted deberá registrarse como persona natural, mayor de edad. Para esta categoría usted puede ser estudiante universitario de últimos semestres, recién graduado o profesional. Adicionalmente, usted entiende por medio de estos términos y condiciones que la presente categoría solo se habilita si ha participado anteriormente como Consultor tres (3) veces, dadas las exigencias de la posición. Usted se compromete a mantener la información en su Cuenta de forma exacta, completa y actualizada; de lo contrario, usted no podrá acceder y utilizar los servicios proporcionados por la plataforma, y devendrá la resolución del presente contrato. Usted es responsable de toda la actividad que ocurre en su Cuenta y se compromete a mantener en todo momento de forma segura y secreta el nombre de usuario y la contraseña de su Cuenta. A menos que coally  permita otra cosa por escrito, usted solo puede poseer una Cuenta.',
  paraclientes: 'Para aquellos usuarios que se registren como Clientes: Con el fin de usar los servicios ofrecidos por la plataforma, usted deberá registrarse como persona jurídica e ingresar los datos correspondientes a esta; o podrá registrarse como persona natural y proporcionar cierta información personal como su nombre, dirección, número de teléfono móvil. El registro como persona jurídica igualmente requiere que usted comunique a coally cierta información empresarial como el nombre, la dirección, y el número de contacto entre otros. Usted se compromete a mantener la información en su Cuenta de forma exacta, completa y actualizada; de lo contrario, usted no podrá acceder y utilizar los servicios proporcionados por la plataforma, y devendrá la resolución del presente contrato. Usted es responsable e toda la actividad que ocurre en su Cuenta y se compromete a mantener en todo momento de forma segura y secreta el nombre de usuario y la contraseña de su Cuenta.',
  //se usa
  requisitosuso: '3. Definiciones',
  elusuariodeber: 'a. Titular: Persona natural cuyos datos son objeto de tratamiento. Para de\n' +
    'la presente política de tratamiento de datos personales los titulares\n' +
    'podrán ser: (i) Clientes de la plataforma; (ii) Consultores y todas\n' +
    'aquellas personas no vinculadas a COALLY',
  paraacceder: 'b. Cliente: Persona natural o jurídica',
  paraacceder2: 'c. Usuario: toda persona que haya descargado y haga uso de la plataforma ya sea como cliente o como consultor.',
  paraacceder3: 'd. Plataforma: la recolección de datos por parte Coally S.A.S será a través de las plataformas Coally App y Coally Web utilizadas para el desarrollo de la actividad de esta compañía.',
  paraacceder4: 'e. Dato personal: Cualquier información vinculada o que pueda asociarse\n' +
    'a una o varias personas naturales determinadas o determinables.',
  paraacceder5: 'f. Base de Datos: Conjunto organizado de datos que sean objeto de\n' +
    'Tratamiento;',
  paraacceder6: 'g. Base de datos personales: Conjunto organizado de datos personales\n' +
    'que son objeto de tratamiento por una persona natural o jurídica.',
  paraacceder7: 'h. Dato sensible: Es aquel dato personal que afecta la intimidad del Titular\n' +
    'y cuyo uso incorrecto podría generar discriminación. Son considerados\n' +
    'datos sensibles entre otros, los datos de salud, los datos de orientación\n' +
    'sexual, origen racial y étnico, opiniones políticas, convicciones\n' +
    'religiosas, filosóficas o morales.',
  paraacceder8: 'i. Dato privado: Es aquel dato personal que por su carácter íntimo o\n' +
    'reservado es relevante para el Titular.',
  paraacceder9: 'j. Dato Semiprivado: Es aquel dato personal conocido y de interés tanto para el titular como para un determinado sector de personas o para la sociedad en general, por lo que no es de carácter íntimo, reservado o público.',
  paraacceder10: 'k. Dato público: Es aquel dato personal calificado como tal según la\n' +
    'Constitución y la ley, y que no se ha clasificado como dato personal\n' +
    'privado o semiprivado.',
  paraacceder11: 'l. Habeas data: Es el derecho que tiene el Titular de los datos personales\n' +
    'de exigir de las administradoras de los mismos el acceso, inclusión,\n' +
    'exclusión, corrección, adición, actualización y rectificación de los datos,\n' +
    'así como la limitación en su divulgación, publicación o cesión.',
  paraacceder12: 'm. Autorización: Consentimiento previo, expreso e informado del Titular\n' +
    'para llevar a cabo el tratamiento de datos personales.',
  paraacceder13: 'n. Aviso de privacidad: Comunicación verbal o escrita dirigida a los\n' +
    'Titulares de los datos personales que están siendo tratados por la\n' +
    'empresa, en la cual se le informa acerca de la existencia de las políticas\n' +
    'de tratamiento de datos personales que le serán aplicadas, la forma de\n' +
    'acceder a la mismas, y las finalidades para las cuales serán usados sus\n' +
    'datos personales.',
  paraacceder14: 'o. Tratamiento: Cualquier operación o conjunto de operaciones sobre\n' +
    'datos personales, tales como la recolección, almacenamiento, uso,\n' +
    'circulación o supresión.',
  paraacceder15: 'p. Responsable del tratamiento: Persona natural o jurídica de carácter\n' +
    'público o privado que por sí misma o en asocio con otro u otros decide\n' +
    'sobre el tratamiento de datos personales. En este caso, será COALLY\n' +
    'S.A.S. el Responsable del tratamiento.',
  //se usa
  obligacionesusuario: '4. Principios rectores para el tratamiento de los datos personales',
  seobligaa: 'Según lo establecido en el Título II de la Ley Estatutaria 1581 de 2012, la\n' +
    'protección de datos personales se regirá por la aplicación armónica e integral de\n' +
    'los siguientes principios:',
  Utilizarlosconteni: 'a. Principio de legalidad en el tratamiento de datos personales: El\n' +
    'tratamiento de datos personales a que se refiere la Ley Estatutaria 1581 de\n' +
    '2012 es una actividad reglada que debe sujetarse a lo establecido en ella y\n' +
    'en las demás disposiciones que la desarrollen.',
  Utilizarlosconteni2: 'b. Principio de finalidad: El tratamiento de los datos personales debe obedecer a una finalidad legítima de acuerdo con la Constitución y la ley, la cual debe ser informada al Titular.',
  Utilizarlosconteni3: 'c. Principio de libertad: El tratamiento de los datos personales sólo puede ejercerse con el consentimiento, previo, expreso e informado del Titular.Los datos personales no podrán ser obtenidos o divulgados sin previa autorización, o en ausencia de mandato legal o judicial que releve el consentimiento.',
  Utilizarlosconteni4: 'd. Principio de veracidad o calidad: La información sujeta a tratamiento debe ser veraz, completa, exacta, actualizada, comprobable y comprensible. Se prohíbe el Tratamiento de datos parciales, incompletos, fraccionados o que induzcan a error.',
  Utilizarlosconteni5: 'e. Principio de transparencia: En el Tratamiento de los datos personales debe garantizarse el derecho del Titular a obtener del Responsable del tratamiento o del Encargado del tratamiento, en cualquier momento y sin restricciones, información acerca de la existencia de datos que le concierne.',
  Utilizarlosconteni6: 'f. Principio de seguridad: La información sujeta a tratamiento por el Responsable del Tratamiento o Encargado del tratamiento a que se refiere la Ley Estatutaria 1581 de 2012, se deberá manejar con las medidas técnicas, humanas y administrativas que sean necesarias para otorgar seguridad a los registros evitando su adulteración, pérdida, consulta, uso o acceso no autorizado o fraudulento.',
  Utilizarlosconteni7: 'g. Principio de confidencialidad: Todas las personas que intervengan en el tratamiento de datos personales que no tengan la naturaleza de públicos están obligadas a garantizar la reserva de la información, inclusive después de finalizada su relación con alguna de las labores que comprende el tratamiento, pudiendo sólo realizar suministro o comunicación de datos personales cuando ello corresponda al desarrollo de las actividades autorizadas en la Ley Estatutaria 1581 de 2012 y en los términos de la misma.',
  Utilizarlosconteni8: 'h. Principio de acceso y circulación restringida: El tratamiento se sujeta a los límites que se derivan de la naturaleza de los datos personales, de las disposiciones de la Ley Estatutaria 1581 de 2012 y la Constitución. En este sentido, el tratamiento sólo podrá hacerse por personas autorizadas por el Titular y/o por las personas previstas en la mencionada ley.',
  //se usa
  condiparacli: '5. Autorización para el tratamiento de datos personales y casos en\n' +
    'los que no es necesaria la autorización',
  alentrarcon: 'El consentimiento y autorización por parte del Titular de la información es un\n' +
    'requisito constitucional y legal que deben cumplir las personas responsables del\n' +
    'tratamiento de datos personales. El consentimiento debe cumplir con los\n' +
    'siguientes presupuestos:',
  ionalmentesiust: 'Previo: La autorización la debe dar el Titular de información de manera previa a\n' +
    'cualquier tipo de Tratamiento de datos personales.',
  ionalmentesiust2: 'Expreso: La autorización debe otorgarse de forma inequívoca, clara y específica.',
  ionalmentesiust3: 'Informado: El Titular debe comprender claramente para qué serán tratados sus\n' +
    'datos personales y las finalidades que pueden derivarse del Tratamiento de los\n' +
    'mismos.',
  ionalmentesiust4: 'Todos los visitantes de las Plataformas de COALLY S.A.S. deben registrarse y autorizar el tratamiento de los datos personales para poder hacer uso de los servicios ofrecidos. Por tanto, en cada uno de los sistemas se encuentra una casilla que dice “Política de privacidad y Tratamiento de Datos Personales” la cual debe ser leída y aceptada para poder continuar con el uso de los servicios de COALLY S.A.S.',
  //se usa
  condiconsulto: '6. TIPOS DE DATOS RECOPILADOS POR COALLY S.A.S',
  alentraralaplat: 'Coally S.A.S recopilará datos proporcionados por los usuarios de Coally, como los\n' +
    'generados durante la creación de cuentas, aquellos proporcionados en el uso de\n' +
    'los servicios.',
  Adicionalmentealaceptares: 'a. Perfil del usuario: datos generados cuando se crean o actualizan las\n' +
    'cuentas. Esto puede incluir nombre, correo electrónico, número de teléfono,\n' +
    'nombre de usuario, contraseña, documento de identificación, fotos de perfil.',
  SiustedesConsultoresta: 'b. Datos demográficos: se recopilarán datos demográficos de los usuarios\n' +
    'como dirección, edad, género, lugar de residencia, carrera y áreas de\n' +
    '\n' +
    'interés. También podrá recolectarse a través de las encuestas destinadas a\n' +
    'ello',
  Seguirlaguiaque: 'c. Datos de uso: se recopilan datos sobre el servicio prestado o el servicio\n' +
    'solicitado, esto incluye el tipo de servicio solicitado o prestado, detalles del\n' +
    'servicio, información sobre el momento en el que se prestó el servicio y la\n' +
    'frecuencia de solicitud o prestación del mismo.',
  Seguirlaguiaque2: 'd. Otros datos: comentarios del cliente, calificaciones y opiniones del servicio prestado.',
  //se usa
  condigold: '7. Finalidad de tratamiento de los datos personales',
  Alaceptarestostérmi: 'Los Datos Personales que recolecta  Coally S.A.S., son incluidos en una Base de\n' +
    'Datos a la cual tiene acceso el personal autorizado de COALLY S.A.S. en\n' +
    'ejercicio de sus funciones, advirtiendo que en ningún caso está autorizado el\n' +
    'Tratamiento de la información para fines diferentes a los aquí descritos, y que le\n' +
    'sean comunicados al Titular directamente a más tardar al momento de la\n' +
    'recolección.',
  Adicionalmentesuste: 'La recolección, almacenamiento, uso y/o circulación de los datos personales\n' +
    'recolectados por Coally S.A.S tiene como finalidad principal prestar los servicios\n' +
    'ofrecidos de manera adecuada y lograr el cumplimiento de las expectativas de los\n' +
    'usuarios de la plataforma web y móvil.',
  Adicionalmentesuste2: 'Adicionalmente, con el fin de desarrollar los objetos social de la compañía, Coally S.A.S podrá hacer tratamiento de datos con las siguientes finalidades:',
  Adicionalmentesuste3: 'Finalidades del tratamiento de los datos personales de los Usuarios',
  Adicionalmentesuste4: '1. Recolectar los datos personales de los usuarios e incorporarlos y\n' +
    'almacenarlos en la base de datos de Coally S.A.S.',
  Adicionalmentesuste5: '2. Ordenar, catalogar, clasificar, dividir o separar la información suministrada.',
  Adicionalmentesuste6: '3. Utilizar los datos suministrados en campañas de comunicación, divulgación\n' +
    'y promoción u oferta de productos, actividades y/o servicios desarrollados\n' +
    'como parte de estrategias internas de la compañía.',
  Adicionalmentesuste7: '4. Conservar registros históricos de la compañía y mantener contacto con los titulares del dato.',
  Adicionalmentesuste8: '5. Verificar, comprobar o validar los datos suministrados.',
  Adicionalmentesuste9: '6. Transferir los datos personales a cualquier país o servidor en otro país.',
  Adicionalmentesuste10: '7. Compartir, transferir los datos personales a aliados y a los aliados de nuestros aliados.',
  Adicionalmentesuste11: '8.  Comunicar y permitir el acceso a los datos personales suministrados a terceros proveedores de servicios de apoyo general y a las personas naturales o jurídicas accionistas de COALLY S.A.S.',
  Adicionalmentesuste12: '9. Realizar de conformidad con la ley los reportes a centrales de riesgo por incumplimiento de las obligaciones financieras derivadas de la relación comercial.',
  Adicionalmentesuste13: '10. Envío de comunicaciones a través de cualquier canal incluyendo, pero sin limitarse a, redes sociales, mensajes de texto, notificaciones push, correo electrónico, llamada telefónica, etc., relacionadas con el objeto social de COALLY S.A.S. tales como actividades de mercadeo y/o cualquier solicitud realizada por el titular a COALLY S.A.S, entre otros.',
  Adicionalmentesuste14: '11. Prestar los servicios ofrecidos y/o contratados de forma adecuada y con una excelente calidad',
   Adicionalmentesuste15: '12. Recomendar de acuerdo a la información facilitada por el consultor, proyectos específicos que responda a sus gustos, preferencias o necesidad',

  aceptasentrega: 'Aceptas los resultados de la primera entrega para poder continuar con el proceso',
  aceptar: 'Aceptar',
  creaComentarios: 'Envia comentarios a tus consultores',
  desarrollopro: 'Desarrollo Profesional',
  desarrolloper: 'Desarrollo Personal',
  actualidad: 'Actualidad',
  profe: 'Profesíon',
  econo: 'Economía',
  admin: 'Administración',
  negoci: 'Negocios',
  der: 'Derecho',
  ingind: 'Ingenieria industrial',
  disñeo: 'Diseño',
  infsis: 'Ingenieria de sistemas',
  dataanali: 'Data development',
  niv: 'Nivel',
  princi: 'Principiante',
  inter: 'Intermedio',
  avanz: 'Avanzado',
  home: 'inicio',
//avances
  Entrega: 'Entrega',
  InformacionActualizada: 'Informacion actualizada',
//commentsconsultor.jsx
  RespuestasEmpresa: 'Respuestas empresa',
  faseActual: 'Fase actual',
  comentariosdelosconsultores: 'Comentarios de los consultores',
//companyequipo.jsx
  Consultor: 'Consultor',
  PROGRESO: 'PROGRESO',
  proyecto: 'Proyecto',
  Ventas: 'Ventas',
  Dashboard: 'Tablero',
  Managment: 'Gestion',
  ManageUsers: 'Administrar usuario',
  ManageProjects: 'Administrar proyectos',
  Match: 'Conexion',
  Cerrarsesion: 'Cerrar sesion',
  INGRESOSTOTALES: 'INGRESOS TOTALES',
  Gestiondeproyectos: 'Gestion de proyectos',
  Proyectosrecientes: 'Proyectos recientes',
  Fecha: 'Fecha',
  Empresa: 'Cliente',
  Tipodepago: 'Tipo de pago',
  MontoUSD: 'Monto USD',
  Vertodoslosproyectos: 'Ver todos los proyectos',
  Titulodevideo: 'Titulo de video',
  profesion: 'Profesion',
  descripciondelatarea: 'descripcion de la tarea',
  INGRESAUNCOMENTARIOREFERENTEALATAREA: 'INGRESA UN COMENTARIO REFERENTE A LA TAREA',
  Firmaingresadacorrectamente: 'Firma ingresada correctamente',
  INGENIERIAA: 'INGENIERIA',
  ESTRATEGIAA: 'ESTRATEGIA',
  MARKETINGYVENTAS: 'MARKETING Y VENTAS',
  TRABAJOREMOTOO: 'TRABAJO REMOTO',
  HOMEEE: 'INICIO',
  NOSOTROSS: 'NOSOTROS',
  SERVICIOSS: 'SERVICIOS',
  ÚNETEEE: 'ÚNETE',
  PROYECTOSSS: 'PROYECTOS',
  Socials: 'Sociales',
  Seleccionaelsectorenelcualtedesempeñas: 'Selecciona tu campo laboral',
  Preguntascoally: 'Preguntas coally',
  Cuantashorasalasemanalededicariasaunproyecto: '¿Cuantas horas a la semana le dedicarias a un proyecto?',
  Algunaactividadextraquedesempeñarasenlauniversidad: 'Alguna(s) actividad extra que desempeñaras en la universidad',
  Cuantassemanasestariasdispuestoatrabajarcomovoluntario: '¿Cuantas semanas estarias dispuesto a trabajar como voluntario?',
  Cuantassemanasestariasdispuestoatrabajarenunmismoproyecto: '¿Cuantas semanas estarias dispuesto a trabajar en un mismo proyecto?',
  Añadeunapequeñadescripciondetuperfil: 'Añade una pequeña descripcion de tu perfil',
  Seleccionalosidiomasqueconoces: 'Slecciona los idiomas que conoces',
  atras: 'atras',
  siguienteee: 'siguiente',
  paracontinuarllenaelcampodescripcion: 'para continuar llena el campo descripcion',
  educacion: 'educacion',
  FromYear: 'Del año',
  ToYear: 'Al año',
  Career: 'Carrera',
  School: 'Escuela',
  paracontinuarllenaelcampoCareer: 'para continuar llena el campo Career',
  Pagina25: 'Pagina 2 / 5',
  Experiencia: 'Experiencia ',
  Position: 'Posicion',
  Duration: 'Duracion',
  Pagina45: 'Pagina 4 / 5',
  Detallesextra: 'Detalles extra',
  Skill1: 'Habilidad 1',
  Skill2: 'Habilidad 2',
  Skill3: 'Habilidad 3',
  Skill4: 'Habilidad 4',
  Skill5: 'Habilidad 5',
  Skill6: 'Habilidad 6',
  Intereses: 'Intereses',
  Intereses1: 'Intereses 1',
  Intereses2: 'Intereses 2',
  Intereses3: 'Intereses 3',
  Intereses4: 'Intereses 4',
  Intereses5: 'Intereses 5',
  Intereses6: 'Intereses 6',
  Terminar: 'Terminar',
  Pagina55: 'Pagina 5 / 5',

  Detallespersonales: 'Detalles personales',
  Direccion: 'Direccion',
  CedulaId: 'Cedula / Id',
  cedula: 'cedula',
  Fechadeexpediciondelacedula: 'Fecha de expedicion de la cedula',
  YourWebsite: 'Your Website',
  paracontinuarllenaelcamponombre: 'para continuar llena el campo nombre',
  Pagina15: 'Pagina 1 / 5',
  Proyectosdesarrollados: 'Proyectos desarrollados',
  Proyecto1: 'Proyecto 1 (opcional)',
  Title: 'Titulo',
  Proyecto2: 'Proyecto 2',
  Proyecto3: 'Proyecto 3',
  Pagina35: 'Pagina 3 / 5',
  Company: 'Empresa',
  EMPRESA: 'EMPRESA',
  Consultores: 'Consultores',
  listadetags: 'lista de tags',
  descripciondelproyecto: 'descripcion del proyecto',
  Lineadenegocio: 'Linea de negocio',
  Nombredelproyecto: 'Nombre del proyecto',
  Iniciodeproyecto: 'Inicio de proyecto',
  Findeproyecto: 'Fin de proyecto',
  Certificado: 'Certificado',
  Remuneracion: 'Remuneracion',
  Si: 'Si',
  textpredeterminado: 'text predeterminado ',
  Habilidadesrecomendadas: 'Habilidades recomendadas',
  Numerodeetapas: 'Numero de etapas',
  Verdetalles: 'Ver detalles',
  Nombredelprojecto: 'Nombre del projecto',
  Cerrar: 'Cerrar',
  Inicia: 'Inicia',
  Remuneración: 'Remuneración',
  estárevisandotuperfilparaesteproyecto: 'El proyecto esta en espera',
  Thankyou: '¡Gracias!',
  Youwillgetanemailwithfurtherinstructions: 'Recibirás un correo electrónico con más instrucciones.',
  Back: 'atras',
  Clear: 'limpiar',
  Close: 'cerrar',
  PaymentSucceeded: 'Pago exitoso',
  Yourcardpaymenthasbeenconfirmed: 'Su pago con tarjeta ha sido confirmado',
  Publicarproyecto: 'Publicar proyecto',
  FrequentlyAskedQuestion: 'Preguntas frecuentes',
  Cómopagamos: '¿Cómo pagamos?',
  Somoscompletamente: 'Somos completamente digitales, por esto, nuestro pago se hace por PSE y tarjetas de crédito.',
  Cómofuncionalacontratación: '¿Cómo funciona la contratación?',
  Contratoporprestacióndeservicios: 'Contrato por prestación de servicios',
  CómofuncionaTpaga: '¿Cómo funciona Tpaga?',
  Tpagaeslaplataforma: 'Tpaga es la plataforma por medio de la cual recibimos nuestros pagos. Cuando pagas nuestro fee, se queda congelado y no lo recibimos hasta que verifiques que estés contento con nuestro resultado. Esto te da la confianza de tu inversión en nuestros servicios, y nos asegura un pago a tiempo.',
  Recibimosproyectosinternacionales: '¿Recibimos proyectos internacionales?',
  Sirecibimospagosporpaypal: 'Sí, recibimos pagos por paypal',
  Decuantotiemposonlosproyectos: '¿De cuánto tiempo son los proyectos?',
  Nuestrosproyectossuelen: 'Nuestros proyectos suelen ser de 5 semanas, y ponemos todos nuestro esfuerzo por cumplir con estos tiempos. Sin embargo, al publicar tu proyecto hacemos un análisis de cuánto tiempo nos va a tomar resolver tu reto, y depende de la complejidad del mismo.',
  Cómohacemoslaselección: '¿Cómo hacemos la selección?',
  Coallydesarrollaunproceso: 'Coally desarrolla un proceso automático en donde evalúa, entre otras cosas, las habilidades del joven y su fit con el proyecto.',
  Encuantotiempomeavisansimeaceptaron: '¿En cuánto tiempo me avisan si me aceptaron?',
  MiproductoperteneceaCoally: '¿Mi producto pertenece a Coally?',
  Cómohagoseguimientodemiequipo: '¿Cómo hago seguimiento de mi equipo?',
  Puedocontrataraalgúnmiembrodelequipodirectamente: '¿Puedo contratar a algún miembro del equipo directamente?',
  Sideberáspagarelfeederetenciónde300USD: 'Sí, deberás pagar el fee de retención de 300 USD',
  Enquémomentodebopagar: '¿En qué momento debo pagar?',
  Elpagoserealizaantesdepublicartuproyecto: 'El pago se realiza antes de publicar tu proyecto.',
  Quénosdiferenciadeotrasplataformasfreelancesyconsultoras: '¿Qué nos diferencia de otras plataformas freelances y consultoras?',
  SomosunaPlataformaEdtech: 'Somos una Plataforma Edtech y freelance que resuelve los problemas de tu negocio¿Cómo? , Conectamos empresas con equipos de trabajo de calidad que brindan soluciones direccionadas por las metodologías de Coally',
  Quéserviciosprestamos: '¿Qué servicios prestamos?',
  DESARROLLODESOFTWAREYBIGDATA: 'DESARROLLO DE SOFTWARE Y BIG DATA',
  MARKETINGDIGITALYESTUDIOSDEMERCADO: 'MARKETING DIGITAL Y ESTUDIOS DE MERCADO',
  INNOVACIÓNEINTRAEMPRENDIMIENTO: 'INNOVACIÓN E INTRAEMPRENDIMIENTO',
  Quédebohacersinoestoydeacuerdoconunentregable: '¿Qué debo hacer si no estoy de acuerdo con un entregable?',
  Deberásenviarnosunemailacoally3gmailcom: 'Deberás enviarnos un email a coally3@gmail.com',
  CuáleselmargendeCoally: '¿Cuál es el margen de Coally?',
  Coallygenerael20demargenporproyecto: 'Coally genera el 20% de margen por proyecto.',
  Cómofunciona: '¿Cómo funciona?',
  Registroenlaplataformacoallycomypaga720USD: 'Registro en la plataforma coally.com y paga 720USD.',
  Coallyseleccionaunequipointerdisciplinariode3jóvenes: 'Coally selecciona un equipo interdisciplinario de 3 jóvenes y direcciona su trabajo usando la metodología Coally, transmitida con inteligencia artificial.',
  Ellostrabajaránentre2y3horasaldíadurante4semanas: 'Ellos trabajarán entre 2 y 3 horas al día durante 4 semanas.',
  Puedeshacerseguimientoalavancedetu: 'Puedes hacer seguimiento al avance de tu proyecto por medio de la plataforma, con notificaciones diarias y entregas semanales.',
  Propuestadevalor: 'Propuesta de valor',
  Permitequeprofesionalesjóvenesy: 'Permite que profesionales jóvenes y la tecnología Coally resuelvan tus problemas de manera rápida y con calidad.',
  Cuéntanostudesafío: 'Cuéntanos tu desafío',
  Testimonios: 'Testimonios',
  comonosconociste: '¿Cómo nos conociste?',
  Companyprofile: 'Company profile',
  Resume: 'Resume',
  Aquívaladescripcióndelaempresa: 'Aquí va la descripción de la empresa, de qué es, a qué se dedica, una breve descripción de sí misma.',
  BogotáColombia: 'Bogotá, Colombia',
  wwwtusitiowebcom: 'www.tusitioweb.com',
  años3: '3 años',
  Empleados1050: '10-50 Empleados',


  Aunnohacompledoalgunaconsultoria: 'Aun no ha compledo alguna consultoria',
  Formularioderegistro: 'Formulario de registro',

//TYC
  //se usa
  TérminosyCondicionesdeUsodelaPlataformacoally: 'Términos y Condiciones de Uso de la Plataforma coally',
  tyc1: '8. Normatividad aplicable',
  tyc2: 'El tratamiento de los Datos personales del titular se realizará con base en lo\n' +
    'dictado en la presente política de privacidad y lo estipulado en la ley 1581 de\n' +
    '2012, el Decreto 1377 de 2013, el Decreto 886 de 2014, normas compiladas en el\n' +
    'Decreto 1074 de 2015 de la República de Colombia, y las demás normas que\n' +
    'adicionen o modifiquen.',
  //no se usa
  tyc3: 'Exceptuando lo expresamente estipulado en estos Términos y Condiciones, queda prohibido todo acto de copia, reproducción, modificación, creación de trabajos derivados, venta o distribución, exhibición de los contenidos de esta aplicación, de manera o por medio alguno, incluyendo, más no limitado a, medios electrónicos, mecánicos, de fotocopiado, de grabación o de cualquier otra índole, sin el permiso previo y por escrito de coally o del titular de los respectivos derechos que serán exclusivamente los clientes de coally.',
  tyc4: 'En ningún caso estos Términos y Condiciones confieren derechos, licencias ni autorizaciones para realizar los actos anteriormente prohibidos. Cualquier uso no autorizado de los contenidos constituirá una violación del presente documento y a las normas vigentes sobre derechos de autor, a las normas vigentes nacionales e internacionales sobre Propiedad Industrial, y a cualquier otra que sea aplicable.',
  //se usa
  tyc5: '9. Derechos de los titulares',
  tyc6: 'En cumplimiento de las garantías fundamentales consagradas en la Constitución y\n' +
    'la ley, y sin perjuicio de lo dispuesto en las demás normas que regulen la materia,\n' +
    'los Titulares de los datos personales podrán ejercer de forma gratuita e\n' +
    'ilimitadamente los siguientes derechos:',
  tyc7: 'a. Derecho al acceso a su información personal objeto de tratamiento.',
  tyc71: 'b. Derecho a la actualización de los datos personales objeto de tratamiento.',
  tyc72: 'c. Derecho a la rectificación de los datos personales objeto de tratamiento.',
  tyc73: 'd. Derecho de oposición a que los datos personales sean objeto de\n' +
    'tratamiento',
  tyc74: 'e. Derecho a solicitar la supresión de los datos personales cuando en el\n' +
    'tratamiento no se respeten los principios, derechos y garantías\n' +
    'constitucionales y legales.',
  tyc75: 'f. Derecho a solicitar prueba de la autorización otorgada para el\n' +
    'tratamiento.',
  tyc76: 'g. Derecho a revocar el consentimiento para el tratamiento de los datos\n' +
    'personales.',
  tyc77: 'h. Derecho a presentar quejas y reclamos ante la Superintendencia de\n' +
    'Industria y Comercio por infracciones a lo dispuesto en la Ley Estatutaria\n' +
    '1581 de 2012 y las demás normas que la modifiquen, adicionen o\n' +
    'complementen.',
  tyc78: 'i. Derecho a ser informado por parte del Responsable y/o Encargado del\n' +
    'uso y tratamiento que se les dará a los datos personales, así como de las\n' +
    'modificaciones y actualizaciones de las políticas de protección, medidas de\n' +
    'seguridad y finalidades.',
  //no se usa
  tyc8: 'Adicionalmente, al aceptar estos Términos y Condiciones usted entiende que la información recolectada por la plataforma, es suministrada de forma libre y voluntaria, para que esta sea administrada por la plataforma. Así mismo, los datos recolectados podrán ser objeto de análisis para fines de mejorar la estrategia de negocios de la plataforma, apoyada en herramientas de inteligencia de negocios y minería de datos, que permiten adquirir conocimientos prospectivos para fines de predicción, clasificación y segmentación.',
  tyc9: 'El Consumidor podrá ejercer su derecho de conocer, actualizar, modificar y suprimir los datos personales existentes en las bases de datos asociadas a la Plataforma. Para ello deberá realizar la solicitud de consulta, reclamo o supresión a la dirección electrónica Coally3@gmail.com detallando las modificaciones a realizar y aportando los documentos que lo soportan.',
  tyc10: 'Coally S.A.S es responsable del tratamiento de la información personal recolectada a través del portal web, responsabilidad que podrá delegar en un tercero, en calidad de responsable o encargado de la información, asegurando contractualmente el adecuado tratamiento de la misma.',
  //se usa
  tyc11: '10. Identificación del responsable y/o encargado del tratamiento:',
  tyc12: 'Coally S.A.S es responsable del tratamiento de sus datos personales y hará uso\n' +
    'de estos únicamente para las finalidades que se encuentra facultado.\n' +
    'Especialmente las señaladas en la presente política',
  tyc13: 'Coally S.A.S\n' +
    'NIT: 901. 420 57 4 - 6\n' +
    'Domicilio: calle 19 #4 56 apartamento 1403\n' +
    'Sitio web: coally.com\n' +
    'Correo electrónico: coally3@gmail.com\n' +
    'Teléfono: 316 359 05 61',
  //no se usa
  tyc14: 'Coally no será responsable por: a) Fuerza mayor o caso fortuito; b) Por la pérdida, extravío o hurto de su dispositivo móvil que implique el acceso de terceros a la aplicación móvil; c) Por errores en la digitación o por fallas de conectividad por parte del usuario, que impidan el normal desarrollo de la consultoría dentro de los tiempos establecidos; d) Por los perjuicios, lucro cesante, daño emergente o moral, y en general sumas a cargo de Coally por los retrasos, no procesamiento de información o suspensión del servicio del operador móvil o daños en los dispositivos móviles.',
  tyc15: 'Coally no  compromete su responsabilidad frente a los servicios que se comercialicen por estos terceros, ya que la Plataforma sirve como canal de comunicación y publicidad, mas no como herramienta de prestación de servicios. En consecuencia, es total responsabilidad de los Consumidores el acceso a los sitios que remite la publicidad, asumiendo la obligación de verificar y conocer los términos de los servicios ofrecidos por los terceros.',
  //se usa
  tyc16: '11. Deberes del responsable de los datos personales.',
  tyc17: 'En línea con la normatividad vigente en tratamiento de datos Coally S.A.S deberá\n' +
    'cumplir con los siguientes deberes:',
  tyc171: 'a. Garantizar al titular, en todo tiempo, el pleno y efectivo ejercicio del derecho de hábeas data',
  tyc172: 'b. Solicitar y conservar la reserva y autorización otorgada por el titular',
  tyc173: 'c. Informar al titular sobre la finalidad de la recolección de datos y los derechos que le asisten en virtud de la autorización otorgada.',
  tyc174: 'd. Conservar la información bajo las condiciones de seguridad necesarias para impedir adulteración, pérdida, o acceso no autorizado',
  tyc175: 'e. Actualizar la información y adoptar las medidas necesarias para que la información suministrada se mantenga actualizada',
  //se usa
  tyc18: '12. Consultas y reclamos',
  tyc19: 'Las consultas, quejas o reclamos podrán ser presentados a través de un mensaje\n' +
    'de datos:',
  tyc20: 'Consultas',
  tyc21: 'Tratándose del derecho de petición en calidad de información y/o consultas, Coally\n' +
    'S.A.S. dará respuesta en un término máximo de diez (10) días hábiles contados a\n' +
    'partir del día siguiente a la fecha de recepción de la petición o consulta.',
  tyc22: 'Cuando no fuere posible atender la consulta dentro de dicho término se informará\n' +
    'al interesado, indicando los motivos de la demora y señalando la fecha en que\n' +
    'esta se atenderá, la cual en ningún caso podrá superar cinco (5) días hábiles\n' +
    'siguientes al vencimiento del primer término. El documento de consulta deberá\n' +
    'contener lo siguiente:',
  tyc221: '1. Área de Coally S.A.S a la cual va dirigida (Servicio al Cliente)',
  tyc2212: '2. El nombre y número de identificación del Titular.',
  tyc2213: '3. Copia documento de identidad del titular',
  tyc222: 'Quejas y/o reclamos',
  tyc223: 'Cuando el Titular considere que su información debe ser objeto de corrección, actualización o supresión o cuando advierta un presunto incumplimiento de cualquiera de sus derechos, el término máximo para atender la queja o reclamoserá de quince (15) días hábiles contados a partir del día siguiente a la fecha de recepción del documento.',
  tyc224: 'Cuando no fuere posible atender el reclamo dentro de dicho término, se informará\n' +
    'al interesado los motivos de la demora y la fecha en que será atendido, la cual en\n' +
    'ningún caso podrá superar los ocho (8) días hábiles siguientes al vencimiento del\n' +
    'primer término. Si el reclamo resulta incompleto, se requerirá al interesado dentro\n' +
    'de los cinco (5) días siguientes a la recepción de la queja y/o reclamo para que\n' +
    'subsane las fallas. Transcurridos dos (2) meses desde la fecha del requerimiento\n' +
    '\n' +
    'sin que el solicitante presente la información requerida, se entenderá desistida la\n' +
    'queja o reclamo. El documento de queja o reclamo deberá contener:',
  tyc2241: '1. Área de Coally S.A.S a la cual va dirigida (Servicio al cliente)',
  tyc2242: '2. El nombre y número de identificación del Titular.',
  tyc2243: '3. Copia documento de identidad del titular.',
  tyc23: '13. Modificaciones',
  tyc24: 'Es posible que actualicemos la presente Política de Datos ocasionalmente. En\n' +
    'caso de realizarse cambios se le notificará a los usuarios a través de la aplicación\n' +
    'o la página web. Por lo tanto, aceptar el uso de los servicios después del aviso de\n' +
    'una actualización constituye el consentimiento del usuario sobre el contenido de\n' +
    'esta.',
  tyc25: '14. Vigencia',
  tyc26: 'La presente Política entra en vigencia a partir del día 01 del mes de Enero de\n' +
    '2021.',
  tyc261: 'Los Datos Personales que sean almacenados, utilizados o transmitidos permanecerán en las bases de datos de Coally S.A.S durante el tiempo que sea necesario para cumplir con las finalidades expuestas en este manual o para que la Empresa pueda cumplir con sus deberes legales.',
  tyc262: 'Sin embargo, la información será revisada cada año para verificar la veracidad del dato y finalidad de seguir con su tratamiento. De ser necesario Coally S.A.S se reserva el derecho a modificar la presente Política de forma unilateral; sin perjuicio de esto, en caso de generarse algún cambio sustancial sobre la Política, se comunicará a los Titulares.',
  tycTableTitle: 'POLÍTICA TRATAMIENTO DE DATOS COALLY S.A.S',
  tycTableContent: 'Tabla de contenido',
  tycTableContent1: '1. PRESENTACIÓN',
  tycTableContent2: '2. ÁMBITO DE APLICACIÓN',
  tycTableContent3: '3. DEFINICIONES',
  tycTableContent4: '4. PRINCIPIOS RECTORES PARA EL TRATAMIENTO DE LOS DATOS PERSONALES',
  tycTableContent5: '5. AUTORIZACIÓN PARA EL TRATAMIENTO DE DATOS PERSONALES Y CASOS EN LOS QUE NO ES NECESARIA LA AUTORIZACIÓN',
  tycTableContent6: '6. TIPOS DE DATOS RECOPILADOS POR COALLY S.A.S',
  tycTableContent7: '7. FINALIDAD DE TRATAMIENTO DE LOS DATOS PERSONALES',
  tycTableContent8: '8. NORMATIVIDAD APLICABLE',
  tycTableContent9: '9. DERECHOS DEL TITULAR DE LOS DATOS PERSONALES',
  tycTableContent10: '10. RESPONSABLE DE LOS DATOS PERSONALES',
  tycTableContent11: '11.  DEBERES DE LOS ENCARGADOS DEL TRATAMIENTO',
  tycTableContent12: '12. CONSULTAS Y RECLAMOS',
  tycTableContent13: '13. MODIFICACIONES',
  tycTableContent14: '14. VIGENCIA',
  //no se usa
  tyc27: 'Uso de Direcciones IP',
  tyc28: 'Una dirección de Protocolo de Internet (IP) es un conjunto de números que se asigna automáticamente a su o dispositivo móvil cuando usted accede a su proveedor de servicios de internet, o a través de la red de área local (LAN) de su organización o la red de área amplia (WAN). Los servidores web automáticamente identifican su dispositivo móvil por la dirección IP asignada a él durante su sesión en línea.',
  tyc29: 'Coally podrá recolectar direcciones IP para propósitos de administración de sistemas y para auditar el uso de su sitio, todo lo anterior de acuerdo con la autorización de protección de datos que se suscriba para tal efecto. Normalmente no se vincula la dirección IP de un usuario con la información personal de este, lo que significa que cada sesión de usuario se registra, pero el usuario sigue siendo anónimo para Coally. Sin embargo, Coally podrá usar las direcciones IP para identificar a los usuarios de nuestro sitio cuando sea necesario con el objeto de exigir el cumplimiento de los términos de uso del sitio, o para proteger su servicio, sitio u otros usuarios.',

  Nombredelaempresa: 'Nombre de la empresa',
  Nombredelrepresentantelegal: 'Nombre del representante legal',
  Numerodeceduladelrepresentantelegal: 'Numero de cedula del representante legal',
  Ciudaddeexpediciondelaceduladelrepresentantelegal: 'Ciudad de expedicion de la cedula del representante legal',
  Sitioweb: 'Sitio web',
  Telefono: 'Telefono',
  Ubicación: 'Ubicación',
  Tiempoenelmercado: 'Tiempo en el mercado',
  Sector: 'Sector',
  Tamañodelaempresa: 'Tamaño de la empresa',
  Descripciondelaempresa: 'Descripcion de la empresa',
  Completaelformulario: 'Completa el formulario',
  Registrofinalizadoconexito: 'Registro finalizado con exito',


  Informacióngeneral: 'Información general',
  Deseaspublicaresteproyectodemaneraanonima: '¿Deseas publicar este proyecto de manera anonima?',
  Selecciona: 'Selecciona...',
  Ingreseelnombredelaempresa: 'Ingrese el nombre de la empresa',
  Ingresaelnombredelproyecto: 'Nombre del proyecto/oportunidad',
  EnqueCiudadserealizaraelproyecto: '¿En que Ciudad se realizara el proyecto?',
  Cuálestunecesidadytúobjetivo: '¿Cuál es tu necesidad y tú objetivo?',
  Requerimientosespecificos: 'Requerimientos especificos',
  Seleccioneelareadeconocimientorequeridasegunsusrequerimientos: 'Selecciona la carrera del profesional/área de conocimiento si la deseas',
  Finanzasynegociacion: 'Finanzas y negociacion',
  Ingenieriass: 'Ingenierias',
  Planeaciondenegocios: 'Planeacion de negocios',
  Impactosocialysostenibilidadd: 'Impacto social y sostenibilidad',
  Quieroreestructrarlasbasesdeminegocioo: 'Quiero reestructrar las bases de mi negocio',
  Otroo: 'Otro',
  Cualescreesquesonlascausasdelproblema: '¿Cuales crees que son las  causas del problema?',
  Cómomediríaseléxitodelproyecto: 'Lista con numerales cuales son los requerimientos mínimos de funcionamiento Ejemplo: Página web informativa, 4 posts de marketing',
  Seleccionaellenguajedeprogramaciónquedesees: 'Selecciona el lenguaje de programación que desees',
  Seleccionaelidiomarequeridoparaelproyecto: 'Lenguaje',
  Informacionadicional: 'Informacion adicional',
  Ingresauncodigopromocional: 'Ingresa un codigo promocional (Opcional)',
  Ingresatuscomentarios: 'Ingresa tus comentarios (Opcional)',
  Cuálestupresupuestoparaesteproyecto: '¡Cual es tu presupuesto mínimo para el proyecto/ rol?',
  Elpresupuestoingresadodebeserigualomayora370USDsegunelproyecto: 'El presupuesto ingresado debe ser igual o mayor a (370 USD o 1.423.637 COP) segun el proyecto',
  Deseaspagarahoraodeseasunaasesoriapersonalizada: 'Selecciona modalidad de pago',
  DeseopagarAhora: 'Deseo pagar Ahora',
  Deseounaasesoriapersonalizada: 'Deseo una asesoria personalizada',
  Enviarpagarahora: 'Enviar pagar ahora',
  Enviarpersonalizada: 'Enviar personalizada',
  Coallymantieneeldinerodentrodesuplataforma: 'Coally mantiene el dinero dentro de su plataforma hasta la culminación del proyecto, Coally garantiza la devolución parcial o total del dinero si no se realiza a cabalidad',
  Ingresatucorreoelectronico: 'Ingresa tu correo electronico',
  Siseleccionastepagoporentregableingresaelmontodeseado: 'Si seleccionaste pago por entregable ingresa el monto deseado',
  ContactUs: 'Contacta con nosotros',
  NombreCompleto: 'Nombre Completo',
  DocumentosLegalesCoallyy: 'Documentos Legales Coally',
  Certificadodeproyecto: 'Certificado de proyecto',
  Contratodeconfidencialidad: 'Contrato de confidencialidad',
  Seguridadsocial: 'Seguridad social',
  Contratodeprestacióndeservicios: 'Contrato de prestación de servicios',
  Aunnotienesproyectosenproceso: 'Aun no tienes proyectos en proceso',
  Añadeunafirmaestaserautilizadaenlosdocumentoslegaless: 'Añade una firma, esta sera utilizada en los documentos legales:',
  saldo: 'Saldo',
  montoo: 'Monto',
  Proyectofinalizadoo: 'Proyecto finalizado',
  Casodeexito: 'Caso de exito',
  Aunnohassubidoalguncasodeexito: 'Aun no has subido algun caso de exito',
  Ingresatucomentariooavanceenelsiguientecampo: 'Ingresa tu comentario o avance en el siguiente campo',
  Avanceregistradoconexito: 'Avance registrado con exito',
  Lenguajes: 'Lenguajes',
  Cargando: 'Cargando',

  Empiezaaaplicartusconocimientos: 'Empieza a aplicar tus conocimientos',
  SéconsultorenCoAlly: 'Sé consultor en CoAlly',
  Ningunproyectoencontradoconesefiltro: 'Ningun proyecto encontrado con ese filtro',
  Companies: 'Companies',
  Proyectosdisponibles: 'Proyectos disponibles',
  Legall: 'Legal',
  Matchcompletadoconexito: 'Match completado con exito',
  Usuarios: 'Usuarios',
  projectdescription: 'descripcion del proyecto',
  projectname: 'nombre del proyecto',
  DESCARGARDOCUMENTO: 'DESCARGAR DOCUMENTO',
  RepresentanteLegalde: 'Representante Legal de',
  CERTIFICA: 'CERTIFICA: ',
  Queelseñor: 'Que el(la) señor(a)',
  identificadoconcéduladeciudadaníaNo: 'identificado(a) con cédula de ciudadanía No',
  expedidaen: 'expedida en',
  desarrollóycompletódemaneraexitosalaconsultoriade: 'desarrolló y completó de manera exitosa la consultoria de',
  comopartedelastartupdeimpactoCoallyparala: 'como parte de la startup de impacto Coally para la ',
  atravésdeunamodalidaddeprestación: 'a través de una modalidad de prestación de servicios, con las siguientes actividades',
  FIRMAREPRESENTANTELEGAL: 'FIRMA REPRESENTANTE LEGAL ',
  NOMBREYAPELLIDOS: 'NOMBRE Y APELLIDOS:',
  CEDULA: 'CEDULA :',
  DIRECCIÓN: 'DIRECCIÓN:',
  TELEFONOO: 'TELEFONO:',
  Firmaar: 'Firmar',
  Cargandodatos: 'Cargando datos...',
  Subeaquilosdocumentos: 'Sube aqui los documentos',


  DESCARGARDOCUMENTOO: 'DESCARGAR DOCUMENTO',
  ACUERDODECONFIDENCIALIDAD: ' ACUERDO DE CONFIDENCIALIDAD ',
  PorLapartereveladoraAndresFelipe: 'Por la parte reveladora.Andres Felipe Joya Mosquera Dirección:544 washington street, Wellesley, MA. USA Teléfono:7742903687 E-mail: ajoya1@babson.edu Fax: n/a ',
  Porlapartereceptoradelainformación: 'Por la parte receptora de la información ',
  NombreEE: 'Nombre: ',
  Dirección: 'Dirección: ',
  Teléfono: 'Teléfono: ',
  Identificacióndelproyecto: 'Identificación del proyecto ',
  Entrelosfirmantesidentificadosanteriormentehemos: 'Entre los firmantes, identificados anteriormente, hemos convenido en celebrar el presente acuerdo de confidencialidad previa las siguientes',
  CONSIDERACIONES: 'CONSIDERACIONES ',
  Quelainformacióncompartidaenvirtuddelpresenteacuerdo: '. Que la información compartida en virtud del presente acuerdo pertenece a la startup Coally, y la misma es considerada sensible y de carácter restringido en su divulgación, manejo y utilización. Dicha información es compartida en virtud del desarrollo del emprendimiento y prototipo como quedo identificado anteriormente. ',
  Quelainformacióndepropiedad: '. Que la información de propiedad de la Coally ha sido desarrollada u obtenido legalmente, como resultado de sus procesos, programas o proyectos y, en consecuencias abarca documentos, datos, tecnología y/o material que considera único y confidencial, o que es objeto de protección a título de secreto industrial. ',
  Queelpresente: '. Que el presente acuerdo se realiza por un lado entre la parte receptora de la información como integrante del emprendimiento y por otro lado Andres Felipe Joya Mosquera que para el presente caso actual como revelador, guarda y administrados de la información de propiedad de la Coally. En consecuencia, las partes se suscriben a las siguientes cláusulas: ',
  PrimeraObjetoenvirtuddel: 'Primera. Objeto: en virtud del presente acuerdo de confidencialidad, la parte receptora, se obliga a no divulgar directa, indirecta, próxima a remotamente, ni a través de ninguna otra persona o de sus subalternos o funcionarios, asesores o cualquier persona relacionada con ella, la información confidencial perteneciente a la startup Coally, así como también a no utilizar dicha información en beneficio propio ni de terceros. ',
  Segunda: 'Segunda. ',
  Definicióndeinformación: 'Definición de información confidencial: se entiende como Información Confidencial, para los efectos del presente acuerdo: ',
  Lainformaciónquenosea: '. La información que no sea pública y sea conocida por la parte receptora con ocasión de del proyecto de investigación y/ extensión. ',
  Cualquierinformación: '. Cualquier información societaria, técnica, jurídica, financiera, comercial, de mercado, estratégica, de productos, nuevas tecnologías, patentes, modelos de utilidad, diseños industriales, modelos de negocios y/o cualquier otra relacionada con el modelo de negocio y emprendimiento lograr tales fines, y/o cualquier otro ente relacionado con la estructura organizacional, bien sea que la misma sea escrita, oral o visual, o en cualquier forma tangible o no, incluidos los mensajes de datos (en la forma definida en la ley), de la cual, la parte receptora tenga conocimiento o a la que tenga acceso por cualquier medio o circunstancia en virtud de las reuniones sostenidas y/o documentos suministrados.',
  Laquecorrespondaodebaconsiderarsecomotalpara: '. La que corresponda o deba considerarse como tal para garantizar el derecho constitucional a la intimidad, la honra y el buen nombre de las personas y deba guardarse la debida diligencia en su discreción y manejo en el desempeño de sus funciones. ',
  TerceraOrigendelainformaciónconfidencial: 'Tercera. Origen de la información confidencial: provendrá de documentos suministrados en el desarrollo del proyecto y que tiene que ver con las creaciones del intelecto, a la naturaleza, medios, formas de distribución, comercialización de productos o de prestación de servicios, transmitida verbal, visual o materialmente, por escrito en los documentos, medios electrónicos, discos ópticos, microfilmes, películas, e-mail u otros elementos similares suministrados de manera tangible o intangible, independiente de su fuente o soporte y sin que requiera advertir su carácter confidencial. ',
  CuartaObligaciones: 'Cuarta. Obligaciones de la parte receptora: Se considerará como parte receptora de la información confidencial a la persona que recibe la información, o que tenga acceso a ella. La parte receptora se obliga a: ',
  Mantenerlainformaciónconfidencialsegurausarlasolamente: 'Mantener la información confidencial segura, usarla solamente para los propósitos relacionados con él, en caso de ser solicitada, devolverla toda (incluyendo copias de esta) en el momento en que ya no requiera hacer uso de la misma o cuando termine la relación, caso en el cual, deberá entregar dicha información antes de la terminación de la vinculación. ',
  Protegerlainformaciónconfidencialseaverbalescrita: 'Proteger la información confidencial, sea verbal, escrita, visual, tangible, intangible o que por cualquier otro medio reciba, siendo legitima poseedora de la misma la startup Coally, restringiendo su uso exclusivamente a las personas que tengan absoluta necesidad de conocerla. ',
  Abstenersedepublicarla: '. Abstenerse de publicar la información confidencial que conozca, reciba o intercambie con ocasión de las reuniones sostenidas. ',
  Usarlainformación: '. Usar la información confidencial que se le entregue, únicamente para los efectos señalados al momento de la entrega de dicha información. ',
  Mantenerlainformaciónconfidencialenreservahastatanto: 'Mantener la información confidencial en reserva hasta tanto adquiera el carácter de pública. ',
  Responderporelmalusoque: '. Responder por el mal uso que le den sus representantes a la información confidencial. ',
  Guardarlareservadelainformaciónconfidencialcomomínimo: '. Guardar la reserva de la información confidencial como mínimo, con el mismo cuidado con la que protege la información confidencial. ',
  Lapartereceptoraseobligaanotransmitir: '. La parte receptora se obliga a no transmitir, comunicar revelar o de cualquier otra forma divulgar total o parcialmente, pública o privadamente, la información confidencial sin el previo consentimiento por escrito por parte de la Coally. vParágrafo: Cualquier divulgación autorizada de la información confidencial a terceras personas estará sujeta a las mismas obligaciones de confidencialidad derivadas del presente Acuerdo y la parte receptora deberá informar estas restricciones incluyendo la identificación de la información cono confidencial. ',
  QuintaObligacionesdelapartereveladora: 'Quinta. Obligaciones de la parte reveladora: Son obligaciones de la parte reveladora: ',
  Mantenerlareservade: '. Mantener la reserva de la información confidencial hasta tanto adquiera el carácter de pública. ',
  Documentartodalainformaciónconfidencialque: '. Documentar toda la información confidencial que transmita de manera escrita, oral o visual, mediante documentos, medios electrónicos, discos ópticos, microfilmes, películas, e-mails u otros elementos similares o en cualquier forma tangible o no, incluidos los mensajes de datos, como registro de la misma para la determinación de sus alcance, e indicar específicamente y de manera clara e inequívoca el carácter confidencia de la información suministrada de la parte receptora. ',
  SextaExclusionesalaconfidencialidadLaparte: 'Sexta. Exclusiones a la confidencialidad: La parte receptora queda relevada o eximida de la obligación de confidencialidad, únicamente en los siguientes casos: ',
  lainformaciónconfidencialhayasidooseadedominiopúblico: '. Cuando la información confidencial haya sido o sea de dominio público. Si la información se hace de dominio público durante el plazo del presente acuerdo, por un hecho ajeno a la parte receptora, esta conservará su deber de reserva sobre la información que no haya sido afectada. ',
  Cuandolainformaciónconfidencialdeba: '. Cuando la información confidencial deba ser revelada por sentencia en firme de un tribunal o autoridades competentes en desarrollo de sus funciones que ordenen el levantamiento de la reserva y soliciten el suministro de esta información. No obstante, en este caso la parte reveladora será la encargada de dar cumplimiento a la orden, restringiendo la divulgación a la información estrictamente necesaria, y en el evento de que la confidencialidad se mantenga, no eximirá a la parte receptora del deber de reserva. ',
  Cuandolaparte: '. Cuando la parte receptora pruebe que la información confidencial ha sido obtenida por otras fuentes. ',
  Cuandolainformación: '. Cuando la información confidencial ya la tenía en su poder la parte receptora antes de la entrega de la información reservada. ',
  Séptima: 'Séptima. Responsabilidad: la parte que contravenga el acuerdo será responsable ante la otra parte o ante los terceros de buena fe sobre los cuales se demuestre que se han visto afectados por la inobservancia del presente acuerdo, por los perjuicios morales y económicos que estos puedan sufrir como resultado del incumplimiento de las obligaciones aquí contenidas. ',
  Octava: 'Octava. Solución de controversias: Las partes se comprometen a esforzarse en resolver mediante los mecanismos alternativos de solución de conflictos cualquier diferencia que surja con motivo de la ejecución del presente acuerdo. En caso de no llegar a una solución directa para la controversia planteada, someterán la cuestión controvertida a las leyes colombianas y a la jurisdicción competente en el momento de presentarse la diferencia. ',
  NovenaLegislaciónaplicableEsteacuerdose: 'Novena. Legislación aplicable: Este acuerdo se regirá por las leyes de la República de Colombia y se interpretará de acuerdo con las mismas. Décima. Aceptación del Acuerdo: Las partes han leído y estudiado de manera detenida los términos y el contenido del presente Acuerdo y por tanto manifiestan estar conformes y aceptan todas las condiciones.',
  díasdelmesde: 'días del mes de',
  EnBogotáDCalos: 'En Bogotá D.C., a los',
  de: 'de',
  PorlapartereveladoraAndresFelipeJoyaMosqueraCCNode: 'Por la parte reveladora: Andres Felipe Joya Mosquera C.C. No. de C.C. No. 1019106268 de Bogota',
  cargando: 'cargando...',
  CONTRATODEPRESTACIÓNDESERVICIOSSUSCRITOENTRE: 'CONTRATO DE PRESTACIÓN DE SERVICIOS SUSCRITO ENTRE',
  y: 'y',
  Porunaparte: 'Por una parte, ',
  sociedadidentificadaconNITn: ' sociedad identificada con NIT n.° ',
  representadalegalmentepor: 'representada legalmente por',
  mayordeedad: 'mayor de edad, identificado(a) con cédula de ciudadanía n.° ',
  expedidaenlaciudadde: 'expedida en la ciudad de',
  personamayordeedad: ' persona mayor de edad, identificado(a) con cédula de ciudadanía n.° ',
  quienparaefectosde: ', quien para efectos de este documento se denominará EL CONTRATANTE; y por otro lado,',
  quienenadelantesedenominaráELCONTRATISTAsuscribenun: 'quien en adelante se denominará EL CONTRATISTA, suscriben un CONTRATO DE PRESTACIÓN DE SERVICIOS, el cual se regirá por las siguientes cláusulas:',
  Elcontratistaconformasusconocimientosen: 'El contratista conforma sus conocimientos en ',
  prestarálosserviciosde: 'prestará los servicios de consultoría de manera autónoma y con pleno conocimiento técnico para EL CONTRATANTE. ',
  SEGUNDAA: 'SEGUNDA',
  PLAZOElplazoparalaejecucióndelpresente: '. PLAZO. El plazo para la ejecución del presente contrato será de ',
  semanascomprendidoentrelafecha: 'semanas comprendido entre la fecha ',
  Parágrafo1Lodispuestoenlapresentecláusulapodráestar: 'Parágrafo 1. Lo dispuesto en la presente cláusula podrá estar sujeto a prórroga por mutuo acuerdo y deberá constar por escrito.',
  TERCERAA: 'TERCERA',
  VALORElvalordelpresentecontratoseráporla: '. VALOR. El valor del presente contrato será por la suma de ',
  CUARTAA: 'CUARTA',
  FORMADEPAGOElvalorpactadoenlacláusula: '. FORMA DE PAGO. El valor pactado en la cláusula anterior será cancelado en dos momentos. Un cincuenta por ciento ( 50%) al iniciar el proyecto de consultoría y el cincuenta por ciento (50%) restante a satisfacción del cliente, una vez esta haya terminado.',
  QUINTAA: 'QUINTA',
  OBLIGACIONESDELCONTRATANTEÉsteseobligaráa: '. OBLIGACIONES DEL CONTRATANTE. Éste se obligará a: 1.Entregar oportunamente a EL CONTRATISTA las herramientas e información requerida para el desarrollo del proceso de consultoría para garantizar el desarrollo eficiente de la misma; 2. Designar a una persona encargada de acompañar durante 4 horas a la semana el desarrollo de la consultoría, lo que contempla la asistencia a las reuniones acordadas previamente con el contratista para la revisión de avances; 3. Informar a EL CONTRATISTA inmediatamente tenga conocimiento de cualquier irregularidad con la ejecución de este contrato; 4. EL CONTRATANTE deberá verificar que EL CONTRATISTA está cumpliendo con sus obligaciones inherentes al pago de la seguridad social como independiente; 5. Enviar las observaciones de cada uno de los productos dentro del día hábil siguiente a la recepción del mismo.De no ser así deberá realizar un pago adicional que equivaldrá al costo correspondiente del tiempo de su retraso.',
  SEXTAA: 'SEXTA',
  OBLIGACIONESDELCONTRATISTAElContratistadeberá: '. OBLIGACIONES DEL CONTRATISTA. El Contratista deberá cumplir de forma perfecta y oportuna los trabajos encomendados propios de la naturaleza del servicio. El contratista se obliga a: 1. Ejecutar de buena fe el servicio de consultoría en que este documento se contrata 2. Desarrollar el proceso de consultoría tomando como guía metodológica las herramientas  que le hayan sido entregadas por coally y seguir las  recomendaciones frente a la forma de implementación de las  mismas; 3. Asistir a reuniones acordadas previamente con el  contratante para la revisión de avances 4. Atender las  recomendaciones que el contratante le haga respecto al  servicio desarrollado; 5. Cumplir con los plazos de avance y  de entrega de los avances del producto, según hayan sido  estipulados; 6. Cumplir con sus responsabilidades de pago a la  seguridad social so pena de que el contratante suspenda o  retenga el pago de sus honorarios. 7. Responder a las  observaciones hechas por el contratante en no más de dos (2)  días contados a partir de la entrega de las mismas.',
  OCTAVAA: 'OCTAVA',
  DURACIÓNDELSERVICIOEstecontratoesporeltiempo: '. DURACIÓN DEL SERVICIO. Este contrato es por el tiempo de la ejecución de la labor de consultoría contratada, pero cualquiera de las partes lo puede dar por terminado por incumplimiento injustificado de las obligaciones estipuladas en el presente contrato, avisando a la otra con una antelación no menor a cinco(5) días.',
  CLÁUSULANOVENA: 'CLÁUSULA NOVENA',
  TERMINACIÓNDELCONTRATOElpresente: '. TERMINACIÓN DEL CONTRATO. El presente contrato podrá darse por terminado por mutuo acuerdo entre las partes, o en forma unilateral por coally en cualquier momento por, entre otras, el incumplimiento de las obligaciones derivadas del presente contrato. Parágrafo 1: En ambos casos, el contratante deberá liquidar hasta el punto de ejecución de la consultoría por los servicios del contratista.',
  CLÁUSULADÉCIMA: 'CLÁUSULA DÉCIMA',
  INDEPENDENCIADELASPARTESEndesarrollo: '. INDEPENDENCIA DE LAS PARTES . En desarrollo del presente Contrato, la partes actuarán por su propia cuenta, con autonomía y por tanto no estarán sometidas a subordinación laboral de ninguna otra parte, y cada una de ellas será la única responsable por el cumplimiento de sus obligaciones civiles, mercantiles, laborales, y cualquier otra derivada de la ley.',
  Laspartescumpliránlasobligacionesobjetodeestecontrato: 'Las partes cumplirán las obligaciones objeto de este contrato utilizando sus propios recursos humanos. Por lo tanto, entre el CONTRATANTE y el CONTRATISTA, no existe ni existirá vínculo laboral alguno. Por lo tanto, entre el CONTRATANTE y (i) el CONTRATISTA, (ii) los empleados, trabajadores, obreros y/o operarios de las partes y (iii) demás personas que intervengan en la ejecución del Contrato, no existe ni existirá vínculo laboral o civil alguno, ni relación de solidaridad o responsabilidad a cualquier título. En consecuencia, las partes asumen toda la responsabilidad que les corresponde como únicos empleadores o contratantes de las personas que llegaren a utilizar siendo de su cargo, entre otros, los salarios, prestaciones sociales, indemnizaciones, honorarios y demás obligaciones a que hubiere lugar. En caso de presentarse reclamación o litigio contra alguna de las partes por causa de alguna de tales obligaciones, la parte estará obligada a concurrir en la defensa de la otra parte y a pagar al reclamante la suma que se le adeude. ',
  CLÁUSULADÉCIMASEGUNDA: 'CLÁUSULA DÉCIMA SEGUNDA',
  CESIÓNDELCONTRATONingunadelaspartespodrácederparcial: 'CESIÓN DEL CONTRATO. Ninguna de las partes podrá ceder parcial ni totalmente la ejecución del presente contrato a un tercero salvo previa autorización expresa y escrita de la otra parte y de la sociedad coally S.A.S',
  CLÁUSULADÉCIMACUARTA: 'CLÁUSULA DÉCIMA CUARTA',
  MODIFICACIÓNOPRÓRROGACualquier: '. MODIFICACIÓN O PRÓRROGA. Cualquier modificación o prórroga a este contrato deberá hacerse por escrito y por mutuo acuerdo de las partes.',
  CLÁUSULADÉCIMAQUINTA: 'CLÁUSULA DÉCIMA QUINTA',
  CONFIDENCIALIDADElpresente: '. CONFIDENCIALIDAD. El presente Contrato, así como toda la información, especificaciones, proyectos, diseños, investigaciones, informes, conocimientos o datos, técnicos o no, presentados verbalmente, en medio físico o en formato electrónico o magnético, que hayan sido o que puedan ser revelados a cualquiera de las Partes y/o sus socios, empleados, agentes y/o contratados en razón de la relación entre las Partes serán consideradas información confidencial, la cual no podrá ser usada, reproducida o revelada a terceros, a menos que la Parte que desee hacer uso de la Información Confidencial obtenga autorización previa específica y por escrito de la otra Parte, con observancia de las reservas abajo establecidas.',
  LasPartessussociosempleadosagentesyocontratados: 'Las Partes, sus socios, empleados, agentes y/o contratados deberán mantener en secreto y prevenir la divulgación de cualquier Información Confidencial, excepto aquello que fuera necesario para el desarrollo adecuado del presente Contrato.',
  Adicionalmentelaobligacióndeconfidencialidadaquí: 'Adicionalmente, la obligación de confidencialidad aquí establecida no será aplicable para:',
  aAquellainformaciónqueresulteaccesiblealpúblicopor: 'a. Aquella información que resulte accesible al público por causas distintas del incumplimiento del presente Contrato;',
  bAquellainformaciónqueestélegítimamenteenpoderdela: 'b. Aquella información que esté legítimamente en poder de la parte destinataria de la misma antes de ser comunicada por la parte reveladora;',
  cAquellainformaciónquehayasidoobtenidalegítimamentede: 'c. Aquella información que haya sido obtenida legítimamente de un tercero sin mediar restricción alguna; y',
  dAquellainformaciónquedebasercomunicadaporordendeun: ' d. Aquella información que deba ser comunicada por orden de un tribunal competente, organismo gubernamental o la autoridad reguladora correspondiente siempre y cuando la parte que venga obligada a comunicar dicha información trate por todos los medios razonables de notificar a la otra parte dicha comunicación con la mayor antelación posible.',
  Estasobligacionesdeconfidencialidadsobreviviránla: 'Estas obligaciones de confidencialidad sobrevivirán la terminación del presente Contrato y permanecerán vigentes por un periodo de tres (3) años contados a partir de su terminación.',
  CLÁUSULADÉCIMOSEXTA: 'CLÁUSULA DÉCIMO SEXTA',
  NATURALEZAElpresenteContratoesde: '. NATURALEZA. El presente Contrato es de naturaleza eminentemente comercial y, por consiguiente, se regirá en todo lo no estipulado en él, por las normas del Código Civil y Comercial',


  Rating: 'Clasificacion',
  Consultorprofile: 'Consultor perfil',
  Presentación: '.	Presentación',
  COALLYSASesunacompañíacolombianaquetienecomoactividadprincipalconectarempresasy: 'COALLY S.A.S. es una compañía colombiana que tiene como actividad principal conectar empresas y/o  personas naturales con profesionales jóvenes por medio de una plataforma virtual, compuesta por una página web y una aplicación móvil, para que se desarrolle un servicio de consultoría  que aporte valor a los clientes. ( empresas y/o personas naturales). ',
  COALLYSASconlafinalidaddedarcumplimientoestrictoalanormatividadvigentedeprotección: 'COALLY S.A.S., con la finalidad de dar cumplimiento estricto a la normatividad vigente de protección de Datos Personales, de acuerdo a lo establecido en la Ley 1581 del 2012, Decreto 1074 de 2015 y demás disposiciones que las modifiquen, adicionen o complementen, presenta la siguiente POLÍTICA DE PROTECCIÓN Y TRATAMIENTO DE DATOS PERSONALES (en adelante “Política de Tratamiento”) con el propósito de proteger la información personal brindada por los Titulares que tengan relación con COALLY S.A.S. como lo son clientes, consultores y  cualquier otra persona natural o jurídica de la cual COALLY S.A.S., obtenga, recolecta, procesa o trate datos personales, sea que dicho tratamiento sea realizado por COALLY S.A.S. o por terceras personas que lo hagan por encargo de ésta. ',
  ElobjetodelaPolíticadeTratamientoesprotegerelderechoconstitucionaldelHabeasDataque: 'El objeto de la Política de Tratamiento es proteger el derecho constitucional del Habeas Data que tienen todas las personas para conocer, actualizar, y rectificar la información que se haya recogido y almacenado en las distintas bases de datos de COALLY S.A.S., y en virtud del cumplimiento de dicho derecho sólo recolecta y da Tratamiento a Datos Personales, cuando así haya sido autorizado previamente por su Titular, implementando para tal efecto, medidas claras sobre confidencialidad y privacidad de los Datos Personales. Así mismo, detalla los lineamientos generales corporativos que se tienen en cuenta con el fin de proteger los Datos Personales de los Titulares. ',
  Estostérminosycondicionesytodoloquetengaqueverconestaaplicaciónylasrelaciones: 'Estos términos y condiciones y todo lo que tenga que ver con esta aplicación y las relaciones jurídicas que surgen de esta, se rigen por las leyes colombianas, específicamente a la Ley 1581 del 2012 y el Decreto 1074 de 2015',
  Ámbitodeaplicación: '.	Ámbito de aplicación',
  Definiciones: '.	Definiciones ',
  TitularPersonanaturalcuyosdatossonobjetodetratamientoParadelapresentepolíticade: '.	Titular: Persona natural cuyos datos son objeto de tratamiento. Para de la presente política de tratamiento de datos personales los titulares podrán ser: (i) Clientes de la plataforma; (ii) Consultores  y todas aquellas personas no vinculadas a COALLY S.A.S. de quien se haga tratamiento de los datos personales. ',
  ClientePersonanaturalojurídica: '.	Cliente: Persona natural o jurídica',
  Usuariotodapersonaquehayadescargadoyhagausodelaplataformayaseacomoclienteocomo: '.	Usuario: toda persona que haya descargado y haga uso de la plataforma ya sea como cliente o como consultor. ',
  PlataformalarecoleccióndedatosporparteCoallySASseráatravésdelasplataformasCoally: '.	Plataforma: la recolección de datos por parte Coally S.A.S será a través de las plataformas Coally App y Coally Web utilizadas para el desarrollo de la actividad de esta compañía.',
  DatopersonalCualquierinformaciónvinculadaoquepuedaasociarseaunaovariaspersonas: '.	Dato personal: Cualquier información vinculada o que pueda asociarse a una o varias personas naturales determinadas o determinables.',
  BasedeDatosConjuntoorganizadodedatosqueseanobjetodeTratamiento: '.	Base de Datos: Conjunto organizado de datos que sean objeto de Tratamiento;',
  BasededatospersonalesConjuntoorganizadodedatospersonalesquesonobjetodetratamiento: '.	Base de datos personales: Conjunto organizado de datos personales que son objeto de tratamiento por una persona natural o jurídica.',
  DatosensibleEsaqueldatopersonalqueafectalaintimidaddelTitularycuyousoincorrecto: '.	Dato sensible: Es aquel dato personal que afecta la intimidad del Titular y cuyo uso incorrecto podría generar discriminación. Son considerados datos sensibles entre otros, los datos de salud, los datos de orientación sexual, origen racial y étnico, opiniones políticas, convicciones religiosas, filosóficas o morales.',
  DatoprivadoEsaqueldatopersonalqueporsucarácteríntimooreservadoesrelevanteparael: '.	Dato privado: Es aquel dato personal que por su carácter íntimo o reservado es relevante para el Titular.',
  DatoSemiprivadoEsaqueldatopersonalconocidoydeinteréstantoparaeltitularcomoparaun: '.	Dato Semiprivado: Es aquel dato personal conocido y de interés tanto para el titular como para un determinado sector de personas o para la sociedad en general, por lo que no es de carácter íntimo, reservado o público.',
  DatopúblicoEsaqueldatopersonalcalificadocomotalsegúnlaConstituciónylaleyyqueno: '.	Dato público: Es aquel dato personal calificado como tal según la Constitución y la ley, y que no se ha clasificado como dato personal privado o semiprivado.',
  HabeasdataEselderechoquetieneelTitulardelosdatospersonalesdeexigirdelas: '.	Habeas data: Es el derecho que tiene el Titular de los datos personales de exigir de las administradoras de los mismos el acceso, inclusión, exclusión, corrección, adición, actualización y rectificación de los datos, así como la limitación en su divulgación, publicación o cesión.',
  AutorizaciónConsentimientoprevioexpresoeinformadodelTitularparallevaracaboel: '.	Autorización: Consentimiento previo, expreso e informado del Titular para llevar a cabo el tratamiento de datos personales.',
  AvisodeprivacidadComunicaciónverbaloescritadirigidaalosTitularesdelosdatos: '.	Aviso de privacidad: Comunicación verbal o escrita dirigida a los Titulares de los datos personales que están siendo tratados por la empresa, en la cual se le informa acerca de la existencia de las políticas de tratamiento de datos personales que le serán aplicadas, la forma de acceder a la mismas, y las finalidades para las cuales serán usados sus datos personales.',
  TratamientoCualquieroperaciónoconjuntodeoperacionessobredatospersonalestalescomola: '.	Tratamiento: Cualquier operación o conjunto de operaciones sobre datos personales, tales como la recolección, almacenamiento, uso, circulación o supresión.',
  ResponsabledeltratamientoPersonanaturalojurídicadecarácterpúblicooprivadoqueporsí: '.	Responsable del tratamiento: Persona natural o jurídica de carácter público o privado que por sí misma o en asocio con otro u otros decide sobre el tratamiento de datos personales. En este caso, será COALLY S.A.S. el Responsable del tratamiento.',
  Principiosrectoresparaeltratamientodelosdatospersonales: '.	Principios rectores para el tratamiento de los datos personales ',
  SegúnloestablecidoenelTítuloIIdelaLeyEstatutaria1581de2012laproteccióndedatos: 'Según lo establecido en el Título II de la Ley Estatutaria 1581 de 2012, la protección de datos personales se regirá por la aplicación armónica e integral de los siguientes principios:',
  PrincipiodelegalidadeneltratamientodedatospersonalesEltratamientodedatos: '.	Principio de legalidad en el tratamiento de datos personales: El tratamiento de datos personales a que se refiere la Ley Estatutaria 1581 de 2012 es una actividad reglada que debe sujetarse a lo establecido en ella y en las demás disposiciones que la desarrollen.',
  PrincipiodefinalidadEltratamientodelosdatospersonalesdebeobedeceraunafinalidad: '.	Principio de finalidad: El tratamiento de los datos personales debe obedecer a una finalidad legítima de acuerdo con la Constitución y la ley, la cual debe ser informada al Titular.',


  PrincipiodelibertadEltratamientodelosdatospersonalessólopuedeejercerseconel: '.	Principio de libertad: El tratamiento de los datos personales sólo puede ejercerse con el consentimiento, previo, expreso e informado del Titular. Los datos personales no podrán ser obtenidos o divulgados sin previa autorización, o en ausencia de mandato legal o judicial que releve el consentimiento.',
  PrincipiodeveracidadocalidadLainformaciónsujetaatratamientodebeserverazcompleta: '.	Principio de veracidad o calidad: La información sujeta a tratamiento debe ser veraz, completa, exacta, actualizada, comprobable y comprensible. Se prohíbe el Tratamiento de datos parciales, incompletos, fraccionados o que induzcan a error.',
  PrincipiodetransparenciaEnelTratamientodelosdatospersonalesdebegarantizarseel: '.	Principio de transparencia: En el Tratamiento de los datos personales debe garantizarse el derecho del Titular a obtener del Responsable del tratamiento o del Encargado del tratamiento, en cualquier momento y sin restricciones, información acerca de la existencia de datos que le concierne.',
  PrincipiodeseguridadLainformaciónsujetaatratamientoporelResponsabledelTratamiento: '.	Principio de seguridad: La información sujeta a tratamiento por el Responsable del Tratamiento o Encargado del tratamiento a que se refiere la Ley Estatutaria 1581 de 2012, se deberá manejar con las medidas técnicas, humanas y administrativas que sean necesarias para otorgar seguridad a los registros evitando su adulteración, pérdida, consulta, uso o acceso no autorizado o fraudulento.',
  PolíticadetratamientodedatosCoallySAS: 'Política de tratamiento de datos Coally S.A.S',
  PrincipiodeconfidencialidadTodaslaspersonasqueintervenganeneltratamientodedatos: '.	Principio de confidencialidad: Todas las personas que intervengan en el tratamiento de datos personales que no tengan la naturaleza de públicos están obligadas a garantizar la reserva de la información, inclusive después de finalizada su relación con alguna de las labores que comprende el tratamiento, pudiendo sólo realizar suministro o comunicación de datos personales cuando ello corresponda al desarrollo de las actividades autorizadas en la Ley Estatutaria 1581 de 2012 y en los términos de la misma.',
  PrincipiodeaccesoycirculaciónrestringidaEltratamientosesujetaaloslímitesquese: '.	Principio de acceso y circulación restringida: El tratamiento se sujeta a los límites que se derivan de la naturaleza de los datos personales, de las disposiciones de la Ley Estatutaria 1581 de 2012 y la Constitución. En este sentido, el tratamiento sólo podrá hacerse por personas autorizadas por el Titular y/o por las personas previstas en la mencionada ley.',
  Autorizaciónparaeltratamientodedatospersonalesycasosenlosquenoesnecesarialaautorización: '.	Autorización para el tratamiento de datos personales y casos en los que no es necesaria la autorización ',
  ElconsentimientoyautorizaciónporpartedelTitulardelainformaciónesunrequisitoconstitucional: 'El consentimiento y autorización por parte del Titular de la información es un requisito constitucional y legal que deben cumplir las personas responsables del tratamiento de datos personales. El consentimiento debe cumplir con los siguientes presupuestos:',
  PrevioLaautorizaciónladebedarelTitulardeinformacióndemanerapreviaacualquiertipode: 'Previo: La autorización la debe dar el Titular de información de manera previa a cualquier tipo de Tratamiento de datos personales.',
  ExpresoLaautorizacióndebeotorgarsedeformainequívocaclarayespecífica: 'Expreso: La autorización debe otorgarse de forma inequívoca, clara y específica.',
  InformadoElTitulardebecomprenderclaramenteparaquéserántratadossusdatospersonalesylas: 'Informado: El Titular debe comprender claramente para qué serán tratados sus datos personales y las finalidades que pueden derivarse del Tratamiento de los mismos.',
  TodoslosvisitantesdelasPlataformasdeCOALLYSASdebenregistrarseyautorizareltratamiento: 'Todos los visitantes de las Plataformas de COALLY S.A.S. deben registrarse y autorizar el tratamiento de los datos personales para poder hacer uso de los servicios ofrecidos. Por tanto, en cada uno de los sistemas se encuentra una casilla que dice “Política de privacidad y Tratamiento de Datos Personales” la cual debe ser leída y aceptada para poder continuar con el uso de los servicios de COALLY S.A.S.',
  TIPOSDEDATOSRECOPILADOSPORCOALLYSAS: '.	TIPOS DE DATOS RECOPILADOS POR COALLY S.A.S',
  CoallySASrecopilarádatosproporcionadosporlosusuariosdeCoallycomolosgeneradosdurante: 'Coally S.A.S recopilará datos proporcionados por los usuarios de Coally, como los generados durante la creación de cuentas, aquellos proporcionados en el uso de los servicios. ',
  PerfildelusuariodatosgeneradoscuandosecreanoactualizanlascuentasEstopuede: '.	Perfil del usuario: datos generados cuando se crean o actualizan las cuentas. Esto puede incluir nombre, correo electrónico, número de teléfono, nombre de usuario, contraseña, documento de identificación, fotos de perfil.',
  Datosdemográficosserecopilarándatosdemográficosdelosusuarioscomodirecciónedad: '.	Datos demográficos: se recopilarán datos demográficos de los usuarios como dirección, edad, género, lugar de residencia, carrera y áreas de interés. También podrá recolectarse a través de las encuestas destinadas a ello',
  Datosdeusoserecopilandatossobreelservicioprestadooelserviciosolicitadoesto: '.	Datos de uso: se recopilan datos sobre el servicio prestado o el servicio solicitado, esto incluye el tipo de servicio solicitado o prestado, detalles del servicio, información sobre el momento en el que se prestó el servicio y la frecuencia de solicitud o prestación del mismo. ',
  Otrosdatoscomentariosdelclientecalificacionesyopinionesdelservicioprestado: '.	Otros datos: comentarios del cliente, calificaciones y opiniones del servicio prestado. ',
  Finalidaddetratamientodelosdatospersonales: '.	Finalidad de tratamiento de los datos personales',
  LosDatosPersonalesquerecolectaCoallySASsonincluidosenunaBasedeDatosalacual: 'Los Datos Personales que recolecta  Coally S.A.S., son incluidos en una Base de Datos a la cual tiene acceso el personal autorizado de COALLY S.A.S. en ejercicio de sus funciones, advirtiendo que en ningún caso está autorizado el Tratamiento de la información para fines diferentes a los aquí descritos, y que le sean comunicados al Titular directamente a más tardar al momento de la recolección.',
  LarecolecciónalmacenamientousoyocirculacióndelosdatospersonalesrecolectadosporCoally: 'La recolección, almacenamiento, uso y/o circulación de los datos personales recolectados por Coally S.A.S tiene como finalidad principal prestar los servicios ofrecidos de manera adecuada y lograr el cumplimiento de las expectativas de los usuarios de la plataforma web y móvil.',
  AdicionalmenteconelfindedesarrollarlosobjetossocialdelacompañíaCoally: 'Adicionalmente, con el fin de desarrollar los objetos social de la compañía, Coally S.A.S podrá hacer tratamiento de datos con las siguientes finalidades: Finalidades del tratamiento de los datos personales de los Usuarios',
  RecolectarlosdatospersonalesdelosusuarioseincorporarlosyalmacenarlosenlabasededatosdeCoallySAS: '.	Recolectar los datos personales de los usuarios e incorporarlos y almacenarlos en  la base de datos de Coally S.A.S.',
  Ordenarcatalogarclasificardividirosepararlainformaciónsuministrada: '.	Ordenar, catalogar, clasificar, dividir o separar la información suministrada. ',
  Utilizarlosdatossuministradosencampañasdecomunicacióndivulgaciónypromociónuoferta: '.	Utilizar los datos suministrados en campañas de comunicación, divulgación y promoción u oferta de productos, actividades y/o servicios desarrollados como parte de estrategias internas de la compañía. ',
  Conservarregistroshistóricosdelacompañíaymantenercontactoconlostitularesdeldato: '.	Conservar registros históricos de la compañía y mantener contacto con los titulares del dato. ',
  Verificarcomprobarovalidarlosdatossuministrados: '.	Verificar, comprobar o validar los datos suministrados. ',
  Transferirlosdatospersonalesacualquierpaísoservidorenotropaís: '. Transferir los datos personales a cualquier país o servidor en otro país.',
  Comunicarypermitirelaccesoalosdatospersonalessuministradosatercerosproveedoresde: '.	Comunicar y permitir el acceso a los datos personales suministrados a terceros proveedores de servicios de apoyo general y a las personas naturales o jurídicas accionistas de COALLY S.A.S.',
  Realizardeconformidadconlaleylosreportesacentralesderiesgoporincumplimientode: '.	Realizar de conformidad con la ley los reportes a centrales de riesgo por incumplimiento de las obligaciones financieras derivadas de la relación comercial. ',
  Envíodecomunicacionesatravésdecualquiercanalincluyendoperosinlimitarsearedes: '.	Envío de comunicaciones a través de cualquier canal incluyendo, pero sin limitarse a, redes sociales, mensajes de texto, notificaciones push, correo electrónico, llamada telefónica, etc., relacionadas con el objeto social de COALLY S.A.S. tales como actividades de mercadeo y/o cualquier solicitud realizada por el titular a  COALLY S.A.S, entre otros.',
  Prestarlosserviciosofrecidosyocontratadosdeformaadecuadayconunaexcelentecalidad: '.	Prestar los servicios ofrecidos y/o contratados de forma adecuada y con una excelente calidad',
  Recomendardeacuerdoalainformaciónfacilitadaporelconsultorproyectosespecíficos: '.	Recomendar de acuerdo a la información facilitada por el consultor, proyectos específicos que responda a sus gustos, preferencias o necesidad',
  Normatividadaplicable: '.	Normatividad aplicable',
  EltratamientodelosDatospersonalesdeltitularserealizaráconbaseenlodictadoenlapresente: 'El tratamiento de los Datos personales del titular se realizará con base en lo dictado en la presente política de privacidad y lo estipulado en la ley 1581 de 2012, el Decreto 1377 de 2013, el Decreto 886 de 2014, normas compiladas en el Decreto 1074 de 2015 de la República de Colombia, y las demás normas que adicionen o modifiquen.',
  Derechosdelostitulares: '.	Derechos de los titulares',
  EncumplimientodelasgarantíasfundamentalesconsagradasenlaConstituciónylaleyysin: 'En cumplimiento de las garantías fundamentales consagradas en la Constitución y la ley, y sin perjuicio de lo dispuesto en las demás normas que regulen la materia, los Titulares de los datos personales podrán ejercer de forma gratuita e ilimitadamente los siguientes derechos:',
  Derechoalaccesoasuinformaciónpersonalobjetodetratamiento: '.	Derecho al acceso a su información personal objeto de tratamiento.',
  Derechoalaactualizacióndelosdatospersonalesobjetodetratamiento: '.	Derecho a la actualización de los datos personales objeto de tratamiento.',
  Derechoalarectificacióndelosdatospersonalesobjetodetratamiento: '.	Derecho a la rectificación de los datos personales objeto de tratamiento.',
  Derechodeoposiciónaquelosdatospersonalesseanobjetodetratamiento: '.	Derecho de oposición a que los datos personales sean objeto de tratamiento',
  Derechoasolicitarlasupresióndelosdatospersonalescuandoeneltratamientonose: '.	Derecho a solicitar la supresión de los datos personales cuando en el tratamiento no se respeten los principios, derechos y garantías constitucionales y legales.',
  Derechoasolicitarpruebadelaautorizaciónotorgadaparaeltratamiento: '.	Derecho a solicitar prueba de la autorización otorgada para el tratamiento. ',
  Derechoarevocarelconsentimientoparaeltratamientodelosdatospersonales: '.	Derecho a revocar el consentimiento para el tratamiento de los datos personales.',
  DerechoapresentarquejasyreclamosantelaSuperintendenciadeIndustriayComerciopor: '.	Derecho a presentar quejas y reclamos ante la Superintendencia de Industria y Comercio por infracciones a lo dispuesto en la Ley Estatutaria 1581 de 2012 y las demás normas que la modifiquen, adicionen o complementen.',
  DerechoaserinformadoporpartedelResponsableyoEncargadodelusoytratamientoquese: '.	Derecho a ser informado por parte del Responsable y/o Encargado del uso y tratamiento que se les dará a los datos personales, así como de las modificaciones y actualizaciones de las políticas de protección, medidas de seguridad y finalidades',
  Identificacióndelresponsableyoencargadodeltratamiento: '. Identificación del responsable y/o encargado del tratamiento',
  CoallySASesresponsabledeltratamientodesusdatospersonalesyharáusodeestosúnicamente: 'Coally S.A.S es responsable del tratamiento de sus datos personales y hará uso de estos únicamente para las finalidades que se encuentra facultado. Especialmente las señaladas en la presente política.',
  CoallySASNIT9014205746: 'Coally S.A.S NIT: 901. 420 57 4 - 6 Domicilio: calle 19 #4 56 apartamento 1403 Sitio web: coally.com Correo electrónico: coally3@gmail.com Teléfono: 316 359 05 61',
  Deberesdelresponsabledelosdatospersonales: '. Deberes del responsable de los datos personales. ',
  EnlíneaconlanormatividadvigenteentratamientodedatosCoallySASdeberácumplirconlos: 'En línea con la normatividad vigente en tratamiento de datos Coally S.A.S deberá cumplir con los siguientes deberes: ',
  Garantizaraltitularentodotiempoelplenoyefectivoejerciciodelderechodehábeasdata: '.	Garantizar al titular, en todo tiempo, el pleno y efectivo ejercicio del derecho de hábeas data ',

  Solicitaryconservarlareservayautorizaciónotorgadaporeltitular: '.	Solicitar y conservar la reserva y autorización otorgada por el titular ',
  Informaraltitularsobrelafinalidaddelarecoleccióndedatosylosderechosquele: '.	Informar al titular sobre la finalidad de la recolección de datos y los derechos que le asisten en virtud de la autorización otorgada. ',
  Conservarlainformaciónbajolascondicionesdeseguridadnecesariasparaimpedir: '.	Conservar la información bajo las condiciones de seguridad necesarias para impedir adulteración, pérdida, o acceso no autorizado ',
  Actualizarlainformaciónyadoptarlasmedidasnecesariasparaquelainformaciónsuministrada: '.	Actualizar la información y adoptar las medidas necesarias para que la información suministrada se mantenga actualizada',
  Consultasyreclamos: '. Consultas y reclamos ',
  Lasconsultasquejasoreclamospodránserpresentadosatravésdeunmensajededatos: 'Las consultas, quejas o reclamos podrán ser presentados a través de un mensaje de datos: ',
  Consultas: 'Consultas',
  TratándosedelderechodepeticiónencalidaddeinformaciónyoconsultasCoallySASdarárespuestaenuntérmino: 'Tratándose del derecho de petición en calidad de información y/o consultas, Coally S.A.S. dará respuesta en un término máximo de diez (10) días hábiles contados a partir del día siguiente a la fecha de recepción de la petición o consulta.',
  Cuandonofuereposibleatenderlaconsultadentrodedichotérminoseinformaráalinteresado: 'Cuando no fuere posible atender la consulta dentro de dicho término se informará al interesado, indicando los motivos de la demora y señalando la fecha en que esta se atenderá, la cual en ningún caso podrá superar cinco (5) días hábiles siguientes al vencimiento del primer término. El documento de consulta deberá contener lo siguiente:',
  ÁreadeCoallySASalacualvadirigidaServicioalCliente: '.	Área de Coally S.A.S a la cual va dirigida (Servicio al Cliente)',
  ElnombreynúmerodeidentificacióndelTitular: '.	El nombre y número de identificación del Titular.',
  Copiadocumentodeidentidaddeltitular: '.	Copia documento de identidad del titular',
  Quejasyoreclamos: 'Quejas y/o reclamos',
  CuandoelTitularconsiderequesuinformacióndebeserobjetodecorrecciónactualizaciónosupresiónocuandoadvierta: 'Cuando el Titular considere que su información debe ser objeto de corrección, actualización o supresión o cuando advierta un presunto incumplimiento de cualquiera de sus derechos, el término máximo para atender la queja o reclamo será de quince (15) días hábiles contados a partir del día siguiente a la fecha de recepción del documento. ',
  Cuandonofuereposibleatenderelreclamodentrodedichotérminoseinformaráalinteresadolos: 'Cuando no fuere posible atender el reclamo dentro de dicho término, se informará al interesado los motivos de la demora y la fecha en que será atendido, la cual en ningún caso podrá superar los ocho (8) días hábiles siguientes al vencimiento del primer término. Si el reclamo resulta incompleto, se requerirá al interesado dentro de los cinco (5) días siguientes a la recepción de la queja y/o reclamo para que subsane las fallas. Transcurridos dos (2) meses desde la fecha del requerimiento sin que el solicitante presente la información requerida, se entenderá desistida la queja o reclamo. El documento de queja o reclamo deberá contener: ',
  ÁreadeCoallySASalacualvadirigidaServicioalcliente: '.	Área de Coally S.A.S  a la cual va dirigida (Servicio al cliente) ',
  Modificaciones: '. Modificaciones ',
  EsposiblequeactualicemoslapresentePolíticadeDatosocasionalmenteEncasoderealizarse: 'Es posible que actualicemos la presente Política de Datos ocasionalmente. En caso de realizarse cambios se le notificará a los usuarios a través de la aplicación o la página web. Por lo tanto, aceptar el uso de los servicios después del aviso de una actualización constituye el consentimiento del usuario sobre el contenido de esta.',
  Vigencia: '. Vigencia ',
  LapresentePolíticaentraenvigenciaapartirdeldía01delmesdeEnerode2021: 'La presente Política entra en vigencia a partir del día 01 del mes de Enero de 2021. ',
  LosDatosPersonalesqueseanalmacenadosutilizadosotransmitidospermaneceránenlasbasesdedatosdeCoallySAS: 'Los Datos Personales que sean almacenados, utilizados o transmitidos permanecerán en las bases de datos de Coally S.A.S durante el tiempo que sea necesario para cumplir con las finalidades expuestas en este manual o para que la Empresa pueda cumplir con sus deberes legales. ',
  Sinembargolainformaciónserárevisadacadaañoparaverificarlaveracidaddeldatoyfinalidad: 'Sin embargo, la información será revisada cada año para verificar la veracidad del dato y finalidad de seguir con su tratamiento. De ser necesario Coally S.A.S  se reserva el derecho a modificar la presente Política de forma unilateral; sin perjuicio de esto, en caso de generarse algún cambio sustancial sobre la Política, se comunicará a los Titulares.',


  pagarnequi: 'Pagar con nequi',
  Mensaje: 'Mensaje',
  Vacio: 'Vacio',
  Ingresatuemail: 'Ingresa tu email',
  SoftwareDevelopment: 'Desarrollo de software',
  BusinessAnalystservices: 'Servicios de analista empresarial',
  Digitalmarketinganddesign: 'Diseño y marketing digital',

  AgronomíaVeterinariayafines: 'Agronomía, Veterinaria y afines',
  BellasArtes: 'Bellas Artes',
  CienciasdelaEducación: 'Ciencias de la Educación',
  CienciasdelaSalud: 'Ciencias de la Salud',
  CienciasSocialesyHumanas: 'Ciencias Sociales y Humanas',
  EconomíaAdministraciónContaduríayafines: 'Economía, Administración, Contaduría y afines',
  IngenieríaArquitecturaUrbanismoyafines: 'Ingeniería, Arquitectura, Urbanismo y afines',
  MatemáticasyCienciasNaturales: 'Matemáticas y Ciencias Naturales',
  Crearproyectoahoraypagardespués: 'Crear proyecto ahora y pagar después',
  pagarconstripe: 'Pagar con stripe',

  Modalidaddecontratatacion: '¿Que modalidad de trabajo deseas?',
  parttime: ' Medio tiempo ',
  fulltime: 'Tiempo completo',
  porproyecto: 'Por proyecto',
  vercv: 'Ver perfil',
  impulsenegocio: 'Impulsa tu negocio al siguiente nivel',
  transformdigitla: 'con transformación digital',
  titulo: 'titulo',
  Descripción: "Descripción",
  MeetURL: 'Meet URL',
  FechaInicio: 'Fecha Inicio',
  FechaFin: 'Fecha Fin',
  Ciudaddelproyecto: 'Ciudad del proyecto',
  CorreoElectrónico: 'Correo Electrónico',
  Asuntoo: 'Asunto',
  Turespuesta: 'Tu respuesta',
  Comentario: 'Comentario',
  Contraseña: 'Contraseña',
  Nombre: 'Nombre',
  Problema: 'Problema',
  Profesion: 'Profesion',
  Nombreproyecto: 'Nombre proyecto',
  Ciudad: 'Ciudad',
  Necesidad: 'Necesidad',
  Causas: 'Causas',
  Comentarioss: 'Comentarios',
  Presupuestototal: 'Presupuesto total',
  Codigopromocional: 'Codigo promocional',
  Busca: 'Busca',
  ingresatucorreo: 'Ingresa tu email',
  Enterthecompanyname: 'Ingrese el nombre de la empresa',
  Deseaspublicaresteproyectodemaneraanonimai: 'ingresa:¿Deseas publicar este proyecto de manera anonima?',
  Cuálestupresupuestoparaesteproyectoi: 'ingresa:¿Cual es tu presupuesto mínimo para el proyecto/ rol?',


  QuétansatisfechoestásconlaconsultoríallevadaacaboporCoally: '¿Qué tan satisfecho estás con la consultoría llevada a cabo por Coally?',
  Muyinsatisfecho: 'Muy insatisfecho',
  Insatisfecho: 'Insatisfecho',
  Satisfecho: 'Satisfecho',
  Muysatisfecho: 'Muy satisfecho',
  SinoestássatisfechoconeltrabajorealizadoquéconsiderasquepudieramejorarsiturespuestaesMuysatisfechoponNA: 'Si no estás satisfecho con el trabajo realizado, ¿qué consideras que pudiera mejorar ? - si tu respuesta es "Muy satisfecho" pon N/A',
  Quétansatisfechoestásconeldesempeñoquetuvoelequipodetrabajo: '¿Qué tan satisfecho estás con el desempeño que tuvo el equipo de trabajo?',
  Quéniveldeinteraccióntuvoconlosconsultores: '¿Qué nivel de interacción tuvo con los consultores?',
  Nunca: 'Nunca',
  Pocasveces: 'Pocas veces',
  Lamayoriadelasveces: 'La mayoria de las veces',
  Siempre: 'Siempre',
  Quétansatisfechoestásconestainteracción: '¿Qué tan satisfecho estás con esta interacción?',
  Conrespectoalacalidadpreciodelaconsultoríaconsiderasestar: 'Con respecto a la calidad-precio de la consultoría, consideras estar:',
  Volveríasausarnuestrosservicios: '¿Volverías a usar nuestros servicios?',
  si: 'si',
  Porque: '¿Por que?',
  Considerasquelaconsultoríarespondióatusnecesidades: '¿Consideras que la consultoría respondió a tus necesidades?',
  Quétansatisfechoestásconladuracióndeldesarrollodelproyecto: '¿Qué tan satisfecho estás con la duración del desarrollo del proyecto?',
  AntesdeescogeraCoallyparallevaracaboelproyectotuvisteotraopción: 'Antes de escoger a Coally para llevar a cabo el proyecto, ¿tuviste otra opción?',
  Silarespuestaesafirmativabajoquécriteriotomasteladecisión: 'Si la respuesta es afirmativa, ¿bajo qué criterio tomaste la decisión?',
  Siendo1lacalificaciónmásbajay4lamásaltacuáleselimpactopositivoquecreesquevasaobtenerdeestaconsultoría: 'Siendo 1 la calificación más baja y 4 la más alta, ¿cuál es el impacto positivo que crees que vas a obtener de esta consultoría?',
  CuáldelassiguientespalabrasutilizaríasparadescribirnuestroservicioSeleccionatodaslasopcionesqueconsideres: '¿Cuál de las siguientes palabras utilizarías para describir nuestro servicio? - Selecciona todas las opciones que consideres',
  Confiable: 'Confiable',
  Innovador: 'Innovador',
  Ineficaz: 'Ineficaz',
  Pocofiable: 'Poco fiable',
  Pocoinnovador: 'Poco innovador',
  Leinteresaríacontrataraalgúnmiembrodelequipoquehizopartedeesteproyecto: '¿Le interesaría contratar a algún miembro del equipo que hizo parte de este proyecto?',
  Sisurespuestaesafirmativaaquiénleinteresaríavincular: 'Si su respuesta es afirmativa, ¿a quién le interesaría vincular?',
  RecomendaríasCoallyatuscolegasuotrasempresas: '¿Recomendarías Coally a tus colegas u otras empresas?',
  EnestecasoQuemensajeledejaríasafuturosclientesqueentrenanuestrapágina: 'En este caso, Que mensaje le dejarías a futuros clientes que entren a nuestra página?',
  impulsatu: 'Impulsa tu negocio',
  impulsatu2: 'al siguiente nivel',
  trasnformaciondigital: 'con transformación digital',
  Profesiones: 'Profesiones',
  Todos: 'Todos',


  Aceptado: 'Aceptado',
  Rechazado: 'Rechazado',
  Enesperatareas: 'En espera',
  Aceptar: 'Aceptar',
  Rechazar: 'Rechazar',
  Crearproyecto: "Crear Proyecto",
  quenecesitas: 'Describe el proyecto/rol',
  paginaweb: 'pagina web',
  desarrollohecho: 'Desarrollo hecho',
  incrementoenventas: 'incremento en ventas',
  Tiendaonline: 'Tienda online',
  instrucciones: 'Instrucciones',


  Aceptacion: 'Aceptacion',
  Clasificacionn: 'Clasificacion',
  Enviarr: 'Enviar',
  Cargandooo: 'Cargando...',
  Aceptarentrega: 'Aceptar entrega',
  Cancelacion: 'Cancelacion',
  Motivo: 'Motivo',
  Rechazarentrega: 'Rechazar entrega',
  itappearsthattherewassanissussubmittingyourreuqest: 'Parece que hubo un problema al enviar su solicitud',
  Success: 'Éxito',
  Yourrequestwassubmittedsuccesfully: 'Su solicitud fue enviada con éxito',
  Done: 'Listo',
  Descargarplantillaaqui: 'Descargar plantilla aqui',
  SubirTarea: 'Subir Tarea',
  Mensajee: 'Mensaje:',
  DELIVERABLES: 'ENTREGABLES',
  Subircasodeexito: 'Subir caso de exito',
  Reunion: 'Reunion',
  Mensajeeee: 'Mensaje',
  Enviarmensaje: 'Enviar mensaje',
  Asuntooo: 'Asunto',
  Enviaunmensajealaempresa: 'Envia un mensaje a la empresa',
  Subirproyecto: 'Subir proyecto',
  Proyecto: 'Proyecto',
  Subircasodeexitooo: 'Subir caso de exito',
  Terminado: 'Terminado',
  porquequieresterminar: '¿porque quieres terminar?',
  Creaunareuniónconlaempresa: 'Crea una reunión con la empresa',
  Nuevareunión: 'Nueva reunión',
  Titulooo: 'Titulo',
  Descripcionnn: 'Descripcion',
  Fechaaa: 'Fecha',
  Hora: 'Hora',
  Enlacedereunion: 'Enlace de reunion',
  Creaunareunionconlaempresa: 'Crea una reunion con la empresa',
  COMENTARIOSSOBRETUENTREGABLE: 'COMENTARIOS SOBRE TU ENTREGABLE:',
  Subirarchivo: 'Subir archivo',
  FormatoPDF: 'Formato PDF',
  Enviaunmensajeatuequipodetrabajo: 'Envia un mensaje a tu equipo de trabajo',
  Terminarproyecto: 'Terminar proyecto',
  Porqueterminasteelproyecto: '¿Porque terminaste el proyecto?',
  Yatengotodosmisentregables: 'Ya tengo todos mis entregables',
  Devoluciondepago: 'Devolucion de pago',
  Finalizarproyecto: 'Finalizar proyecto',
  Consultorprofilee: 'Consultor perfil',
  EMPRESAaa: 'EMPRESA',
  CORREOoo: 'CORREO',
  NOMBREee: 'NOMBRE',
  FECHACREACION: 'FECHA CREACION',
  FECHAPAGO: 'FECHA PAGO',
  MONTO: 'MONTO',


  Empieceaaplicarsus: 'Empieza a aplicar tus ',
  conocimientos: 'conocimientos',
  SeaunconsultorenCoally: 'Se un consultor en Coally',
  Buscaproyectosquenecesites: 'Busca tu proximo proyecto',
  enprocesoo: 'En proceso',
  enesperaa: 'En Espera',
  finalizadoo: 'Finalizado',
  lugarr: 'Lugar',
  verperfil: 'Ver Perfil',
  Profesionales: 'Profesionales',
  Entregas2: 'Entregas',
  Finalizarr: 'Finalizar',
  telefonocontacto: '+57 311 8868852',
  Tarearechazada: 'Tarea rechazada',
  Motivo0: 'Motivo',
  Contrataelmejor: 'Contrata el mejor talento latino, lleva a tus proyectos a otro nivel',
  Trabajaremoto: 'Trabaja remoto, obtén experiencia y construye tus sueños',
  PLATAFORMABAJODEMANDAPARA: 'PLATAFORMA BAJO DEMANDA PARA',
  BUSINESSTRANSFORMATIONSERVICES: 'SERVICIOS DE TRANSFORMACIÓN EMPRESARIAL',
  Pagarconwenjoy: 'Pagar con wenjoy',
  Duration2: 'Duración (meses)',
  agregarotro: 'Agregar otro',
  Coallymantieneeldinerodentrodesuplataforma2: 'Es importante que sepas que en nuestros terminos y condiciones se encuentra que cualquier variacion de alcance, tiene repercucion en tiempo y en costo, al postear el proyecto nos indicaras que estás de acuerdo',
  Recomendaciones: 'Recomendaciones',
  Aplicara: 'Aplicar',
  Quieresaplicara: '¿Quieres aplicar a ',
  Confirmar: 'Confirmar',
  Cancelar: 'Cancelar',
  EligeTuRespuesta: 'choose your answer',
  creatucuentaa: 'Crea tu cuenta',
  recuperarcontrasena: 'Recuperar Contraseña',
  describeenelmayordetalletuobjetivo: 'describe en el mayor detalle tu objetivo, si gustas envíanos una nota de voz con lo que requieres',
  deseasincorporarunadescripcióndetalladadelospasosrequeridos: 'Deseas incorporar una descripción detallada de los pasos requeridos',
  pasos: "Paso",
  agregarotraetapa: "Agregar otro paso",
  hometexto1: "Trabaja en tu área de conocimiento y aplica lo aprendido",
  hometexto2: "Te brindamos oportunidades de trabajo para que puedas demostrar las habilidades adquiridas en tu formación",
  hometexto3: "Encuentra tu próxima oportunidad",
  oportunidadess: "Oportunidades",
  Sieresnuevousuario: "Si eres nuevo usuario",
  Iniciosesión: "Inicio sesión",
  Recoverypassword: "Recuperar contraseña",
  BienvenidoaCoally: "Bienvenido a Coally!",
  Impulsatunegocioalsiguientenivel: "Impulsa tu negocio al siguiente nivel.",
  TusOportunidades: 'Tus Oportunidades',
  Estado: 'Estado',
  Modificar: 'Modificar',
  Fórmateconnosotros: 'Fórmate con nosotros',
  TipsDeReclutamiento: 'Tips de Reclutamiento',
  OnboardingOInducción: 'Onboarding o Inducción',
  AnálisisDeDesempeño: 'Análisis de Desempeño',
  FuturoDelTrabajo: 'Futuro del trabajo',
  CvyCartaDePresentacion: 'CV y Carta de presentación',
  Entrevistas: 'Entrevistas',
  PerfilProfesional: 'Perfil Profesional',
  TipsDeDesempeño: 'Tips de Desempeño',
  Oportunidades: 'Oportunidades',
  Publicadas: 'Publicadas',
  Profesionalesconconocimientoen: 'Profesionales con conocimiento en',
  EnEspera: 'En Espera',
  Finalizadas: 'Finalizadas',
  Inicio: 'Inicio',
  PublicarOportunidad: 'Publicar Oportunidad',
  goback: 'Volver',
  iniciarsesion: 'iniciar sesion',
  registro: 'registro',
  llenarformulario: 'Llenar formulario',
  Queteapasionadetutrabajo: '¿Qué te motiva de este rol?',
  SubirCV: 'Subir CV',
  Proyectooooo: 'Proyecto',
  Fechadeinicio: 'Fecha de inicio',
  Profesional: "Profesional",
  Empresaaaaa: 'Empresa',
// nuevo
  BienvenidoaCoallyv1: "¡Bienvenido a Coally!",
  Impulsatunegocioalsiguientenivelv1: "Impulsa tu negocio al siguiente nivel.",
  TusOportunidadesv1: "Tus Oportunidades",
  Habilidadesv1: "Habilidades",
  Vermásv1: "Ver más",
  Modificarv1: "Modificar",
  Descripcióndelproyectov1: "Descripción del proyecto",
  Salariov1: "Salario",
  Salarionodefinidov1: "Salario no definido",
  Descripciónnoespecificadav1: "Descripción no especificada",
  Carrerav1: "Carrera",
  Carreranoespecificadav1: "Carrera no especificada",
  Modalidaddecontrataciónv1: "Modalidad de contratación",
  Modalidadnoespecificadav1: "Modalidad no especificada",
  Habilidadesnoespecificadasv1: "Habilidades no especificadas.",
  Inglesrequeridov1: "Inglés requerido.",
  Linkalaoportunidadv1: "Link a la oportunidad",
  Editaroportunidadv1: "Editar oportunidad",
  Guardarv1: "Guardar",
  Cancelarv1: "Cancelar",
  Filtrosv1: "Filtros",
  NombredelaAZv1: "Nombre de la A - Z",
  NombredelaZAv1: "Nombre de la Z - A",
  Porcalificaciónv1: "Por calificación",
  Confirmadov1: "Confirmado",
  estáenentrevistav1: "está en entrevista",
  Trabajaentuáreadeconocimientoyaplicaloaprendidov1: "Trabaja en tu área de conocimiento y aplica lo aprendido",
  Resultadosv1: "Resultados",
  Aplicarv1: "Aplicar",
  Oportunidadesv1: "Oportunidades",
  listav1: "lista",
  Totalv1: "Total",
  Enesperav1: "En espera",
  Iniciadosv1: "Iniciados",
  Finalizadosv1: "Finalizados",
  Progresov1: "Progreso",
  Verdetallesv1: "Ver detalles",
  Enprocesov1: "En proceso",
  Completadov1: "Completado",
  Yaaplicastev1: "Ya aplicaste",
  llenarformulariov1: "Llenar formulario",
  Queteapasionadetutrabajov1: "¿Qué te motiva de este rol?",
  SubirCVpdfdescargadodelinkedinv1: "Subir CV (pdf descargado de linkedin)",
  Llenamanualmenteelformulario: "Llena manualmente el formulario",
  Continuarv1: "Continuar",
  Otrasoportunidadesv1: "Otras oportunidades",
  Acercadev1: "Acerca de",
  Educaciónv1: "Educación",
  Experienciav1: "Experiencia",
  OportunidadesFinalizadas: "Oportunidades Finalizadas",
  empresav1: "Empresa",
  AdministradordeEmpresasv1: "Administrador de Empresas",
  Idiomav1: "Idioma",
  Guardarv2: "Guardar!",
  Subirhojadevida: "Subir hoja de vida",
  Cargatuhojadevidav1: "Carga tu hoja de vida",
  Subirv1: "Subir",
  RecuerdacompletartuperfilconelTestdePersonalidadv1: "¡Recuerda completar tu perfil con el Test de Personalidad!",
  irahorav1: "ir ahora",
  PuntuarTestv1: "Puntuar Test",
  MásTestv1: "Más Test",
  Siyaterminastetutestdepersonalizadadingresaturesultadov1: "Si ya terminaste tu test de personalidad,ingresa tu resultado:",
  Enviarv1: "Enviar",
  Iniciov1: "Inicio",
  Formaciónv1: "Formación",
  Cerrarsesiónv1: "Cerrar sesión",


}
