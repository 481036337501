import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { hiringModality, jornadas, modalidadContratacion, modalidadEn, modalidadEs, schedules, seleccionLenguajes, selectionLanguages } from './dataSelects';
import Select from 'react-select';
import { useCvForm } from '../../newForm/utils/useCvForm';
import { useHistory } from 'react-router';

const FiltersOportunities = () => {
  const { lang } = useSelector(state => state);

  const { profesions } = useCvForm()

  const customStyles = {
    container: (provided, state) => ({
      ...provided,
      borderRadius: '5px',
      border: '1px solid #202256',
      width: '157px',
      marginInline: '5px',
    }),
    control: (provided, state) => ({
      ...provided,
      backgroundColor: state.hasValue ? '#D3C7EF' : 'white',
    }),
    indicatorSeparator: (provided, state) => ({
      ...provided,
      backgroundColor: '#202256',
    }),
    dropdownIndicator: (provided, state) => ({
      ...provided,
      color: '#202256',
    }),
    clearIndicator: (provided, state) => ({
      ...provided,
      color: '#202256',
    }),
  };

  const [selectedOption, setSelectedOption] = useState({
    modalidad: null,
    jornada: null,
    contrato: null,
    profesion: null,
    lenguaje: null,
  });

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const currentQuery = new URLSearchParams(window.location.search);
    const modalidadDefault = currentQuery.get('modalidad') || null;
    const jornadaDefault = currentQuery.get('jornada') || null;
    const contratoDefault = currentQuery.get('contrato') || null;
    const profesionsDefault = currentQuery.get('profesion') || null;
    const lenguajeDefault = currentQuery.get('lenguaje') || null;

    setSelectedOption({
      modalidad: modalidadDefault,
      jornada: jornadaDefault,
      contrato: contratoDefault,
      profesion: profesionsDefault,
      lenguaje: lenguajeDefault,
    });
    setIsLoading(true);
  }, []);

  const history = useHistory();

  const handleSelectChange = (option, selectId) => {
    setSelectedOption(prevState => ({
      ...prevState,
      [selectId]: option ? option.value : null,
    }));
    const currentQuery = new URLSearchParams(window.location.search);
    if (option) {
      currentQuery.set(selectId, option ? option.value : '');
    } else {
      currentQuery.delete(selectId);
    }
    const newUrl = `?${currentQuery.toString()}`;
    history.push(newUrl);
  };

  const mayusculaString = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  }

  return (
    <>
      {isLoading &&
        <>
          <Select
            id="modalidadSelect"
            options={lang === 'es' ? modalidadEs : modalidadEn}
            isClearable={true}
            placeholder={lang === 'es' ? 'Modalidad' : 'Modality'}
            styles={customStyles}
            onChange={option => handleSelectChange(option, 'modalidad')}
            defaultValue={selectedOption.modalidad ? { value: selectedOption.modalidad, label: selectedOption.modalidad } : null}
          />

          <Select
            id="jornadaSelect"
            options={lang === 'es' ? jornadas : schedules}
            isClearable={true}
            placeholder={lang === 'es' ? 'Jornada' : 'Schedule'}
            styles={customStyles}
            onChange={option => handleSelectChange(option, 'jornada')}
            defaultValue={selectedOption.jornada ? { value: selectedOption.jornada, label: selectedOption.jornada } : null}
          />

          <Select
            id='contratoSelect'
            options={lang === 'es' ? modalidadContratacion : hiringModality}
            isClearable={true}
            placeholder={lang === 'es' ? 'Contrato' : 'Contract'}
            styles={customStyles}
            onChange={option => handleSelectChange(option, 'contrato')}
            defaultValue={selectedOption.contrato ? { value: selectedOption.contrato, label: selectedOption.contrato } : null}
          />

          <Select
            id='profesionSelect'
            options={profesions.map(
              (profesion) => ({ value: profesion?.name, label: mayusculaString(profesion?.name) })
            )}
            isClearable={true}
            placeholder={lang === 'es' ? 'Profesión' : 'Profession'}
            styles={customStyles}
            onChange={option => handleSelectChange(option, 'profesion')}
            defaultValue={selectedOption.profesion ? { value: selectedOption.profesion, label: mayusculaString(selectedOption.profesion) } : null}
          />

          <Select
            id='lenguajeSelect'
            options={lang === 'es' ? seleccionLenguajes : selectionLanguages}
            isClearable={true}
            placeholder={lang === 'es' ? 'Idioma' : 'Language'}
            styles={customStyles}
            onChange={option => handleSelectChange(option, 'lenguaje')}
            defaultValue={selectedOption.lenguaje ? { value: selectedOption.lenguaje, label: selectedOption.lenguaje } : null}
          />
        </>
      }

    </>
  );
};

export default FiltersOportunities;
