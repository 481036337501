import { toast } from "react-toastify";
import axios from "axios";

export const preset = {
  position: "top-center",
  autoClose: 3000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
};
export const sendPromoCode = (e, promoCode, setValidCode, userId) => {
  e.preventDefault();
  if (promoCode === "") {
    return toast.error("Debes ingresar un código promocional");
  }
  axios
    .post(
      `https://findally-backend.herokuapp.com/api/user/validate/code/${userId}`,
      {
        code: promoCode.toUpperCase().trim(),
      }
    )
    .then((res) => {
      setValidCode(true);
      toast.success("Código promocional aplicado", preset);
    })
    .catch((err) => {
      toast.error("Código promocional no válido", preset);
      console.log(err);
    });
};

export const handleFormChange = (e, setForm, form) => {
  // e.preventDefault()
  setForm({
    ...form,
    [e.target.name]: e.target.value,
  });
};

export const handleChangeLanguage = (e, form, setForm) => {
  if (e.target.checked) {
    setForm({
      ...form,
      Lenguaje: [...form?.Lenguaje, e.target.value],
    });
  } else {
    setForm({
      ...form,
      Lenguaje: form?.Lenguaje?.filter((item) => item !== e.target.value),
      nivel_ingles: "",
    });
  }
};

export const handleChangeNivel = (e, form, setForm) => {
  if (e.target.checked) {
    setForm({
      ...form,
      Niveldeconocimiento: [...form?.Niveldeconocimiento, e.target.value],
    });
    console.log(form);
  } else {
    setForm({
      ...form,
      Niveldeconocimiento: form?.Niveldeconocimiento?.filter(
        (item) => item !== e.target.value
      ),
    });
    console.log(form);
  }
};

export const handleChangeAcademico = (e, form, setForm) => {
  if (e.target.checked) {
    setForm({
      ...form,
      academico: [...form?.academico, e.target.value],
    });
    console.log(form);
  } else {
    setForm({
      ...form,
      academico: form?.academico?.filter((item) => item !== e.target.value),
    });
    console.log(form);
  }
};

const checkForm = async (
  form,
  lang,
  editable,
  pubId,
  plan,
  userEmail,
  validCode,
  userId,
  montoMinimo,
  setisSubmit,
  img,
  promoCode
) => {
  if (
    form.tipoDeServicioDeseado === "" ||
    form.tipoDeServicioDeseado === undefined
  ) {
    setisSubmit(false);
    return toast.error(
      lang === "es"
        ? "Debes elegir un tipo de servicio"
        : "You must choose a service type",
      preset
    );
  }
  if (form.modalidadDeTrabajo === "" || form.modalidadDeTrabajo === undefined) {
    setisSubmit(false);
    return toast.error(
      lang === "es"
        ? "Debes elegir una modalidad de trabajo"
        : "You must choose a type of work",
      preset
    );
  }
  if (
    form.modalidadDeContratacion === "" ||
    form.modalidadDeContratacion === undefined
  ) {
    setisSubmit(false);
    return toast.error(
      lang === "es"
        ? "Debes seleccionar una modalidad de contratación"
        : "You must select a hiring modality",
      preset
    );
  }
  if (form.modalidadDeJornada === "" || form.modalidadDeJornada === undefined) {
    setisSubmit(false);
    return toast.error(
      lang === "es"
        ? "Debes seleccionar un tipo de jornada laboral"
        : "You must select a type of working day",
      preset
    );
  }
  if (form.Anonimo === "" || form.Anonimo === undefined) {
    setisSubmit(false);
    return toast.error(
      lang === "es"
        ? "Debes seleccionar si quieres que tu nombre sea publicado"
        : "You must select if you want your name to be published",
      preset
    );
  }
  if (form.nombreEncargado === "" || form.nombreEncargado === undefined) {
    setisSubmit(false);
    return toast.error(
      lang === "es" ? "Debes ingresar tu nombre" : "You must enter your name",
      preset
    );
  }
  if (form.numeroEncargado === "" || form.numeroEncargado === undefined) {
    setisSubmit(false);
    return toast.error(
      lang === "es"
        ? "Debes ingresar tu número de contacto"
        : "You must enter your contact number",
      preset
    );
  }
  if (form.tipo_documento === "" || form.tipo_documento === undefined) {
    setisSubmit(false);
    return toast.error(
      lang === "es"
        ? "Debes seleccionar un tipo de documento"
        : "You must select a document type",
      preset
    );
  }
  if (form.documento === "" || form.documento === undefined) {
    setisSubmit(false);
    return toast.error(
      lang === "es"
        ? "Debes ingresar tu número de documento"
        : "You must enter your document number",
      preset
    );
  }
  if (form.NombreOportunidad === "" || form.NombreOportunidad === undefined) {
    setisSubmit(false);
    return toast.error(
      lang === "es"
        ? "Debes ingresar un nombre para la oportunidad"
        : "You must enter a name for the opportunity",
      preset
    );
  }
  if (form.profesionales === "" || form.profesionales === undefined) {
    setisSubmit(false);
    return toast.error(
      lang === "es"
        ? "Debes seleccionar una cantidad de vacantes"
        : "You must select a number of vacancies",
      preset
    );
  }
  if (!montoMinimo) {
    setisSubmit(false);
    return toast.error(
      lang === "es"
        ? "Debes escribir el salario base"
        : "You must write the base salary",
      preset
    );
  }
  if (form.estimadoDuración === "" || form.estimadoDuración === undefined) {
    setisSubmit(false);
    return toast.error(
      lang === "es"
        ? "Debes seleccionar un estimado de duración"
        : "You must select an estimated duration",
      preset
    );
  }
  if (form.DescribeProyecto === "" || form.DescribeProyecto === undefined) {
    setisSubmit(false);
    return toast.error(
      lang === "es"
        ? "Debes ingresar una descripción del proyecto"
        : "You must enter a project description",
      preset
    );
  }
  if (form.DescribeProyecto.length < 100) {
    setisSubmit(false);
    return toast.error(
      lang === "es"
        ? "La descripción del proyecto debe ser mayor a 100 caracteres"
        : "Project description must be longer than 100 characters",
      preset
    );
  }
  if (
    form.responsabilidadYfunciones.length === 0 ||
    form.responsabilidadYfunciones === undefined
  ) {
    setisSubmit(false);
    return toast.error(
      lang === "es"
        ? "Debes ingresar responsabilidades y funciones"
        : "You must enter responsibilities and functions",
      preset
    );
  }
  if (form.responsabilidadYfunciones.length < 3) {
    setisSubmit(false);
    return toast.error(
      lang === "es"
        ? "Las responsabilidades y funciones debe ser al menos 3"
        : "The responsibilities and functions must be at least 3",
      preset
    );
  }
  if (form.listadoBeneficios.includes("Otro" || "Other")) {
    if (
      form.descripcionBeneficios === "" ||
      form.descripcionBeneficios === undefined
    ) {
      setisSubmit(false);
      return toast.error(
        lang === "es"
          ? "Debes ingresar una descripción de los beneficios"
          : "You must enter a description of the benefits",
        preset
      );
    }
  }
  if (
    form.listadoBeneficios.includes("Comisión / bono" || "Commission / bond")
  ) {
    if (
      form.descripcionComision === "" ||
      form.descripcionComision === undefined
    ) {
      setisSubmit(false);
      return toast.error(
        lang === "es"
          ? "Debes ingresar una descripción de la comisión"
          : "You must enter a description of the commission",
        preset
      );
    }
  }
  if (
    (form.departamento === "" || form.departamento === undefined) &&
    !/^United States/.test(form.country)
  ) {
    setisSubmit(false);
    return toast.error(
      lang === "es"
        ? "Debes seleccionar un departamento"
        : "You must select a department",
      preset
    );
  }
  if (
    (form.municipio === "" || form.municipio === undefined) &&
    /^Colombia/.test(form.country)
  ) {
    setisSubmit(false);
    return toast.error(
      lang === "es"
        ? "Debes seleccionar un municipio"
        : "You must select a municipality",
      preset
    );
  }
  if (
    form.empleos_alternativos === "" ||
    form.empleos_alternativos === undefined
  ) {
    setisSubmit(false);
    return toast.error(
      lang === "es"
        ? "Debes seleccionar al menos una profesión"
        : "You must select at least one profession",
      preset
    );
  }
  if (
    form.Niveldeconocimiento === "" ||
    form.Niveldeconocimiento === undefined
  ) {
    setisSubmit(false);
    return toast.error(
      lang === "es"
        ? "Debes seleccionar un nivel de conocimiento"
        : "You must select a level of knowledge",
      preset
    );
  }
  if (form.habilidadesBlandas === "" || form.habilidadesBlandas === undefined) {
    setisSubmit(false);
    return toast.error(
      lang === "es"
        ? "Debes seleccionar una cantidad de habilidades blandas"
        : "You must select an amount of soft skills",
      preset
    );
  }
  if (
    form.habilidadesTecnicas === "" ||
    form.habilidadesTecnicas === undefined
  ) {
    setisSubmit(false);
    return toast.error(
      lang === "es"
        ? "Debes seleccionar una cantidad de habilidades técnicas"
        : "You must select an amount of technical skills",
      preset
    );
  }
  if (form.Lenguaje === "" || form.Lenguaje === undefined) {
    setisSubmit(false);
    return toast.error(
      lang === "es"
        ? "Debes seleccionar un lenguaje"
        : "You must select a language",
      preset
    );
  }
  if (form.modalidaddepago === "" || form.modalidaddepago === undefined) {
    setisSubmit(false);
    return toast.error(
      lang === "es"
        ? "Debes seleccionar una modalidad de pago"
        : "You must select a payment method",
      preset
    );
  } else {
    if (editable) {
      return axios
        .put(
          `https://findally-backend.herokuapp.com/api/Project/${pubId}`,
          form
        )
        .then(async (res) => {
          toast.success(
            lang === "es"
              ? "Formulario actualizado correctamente"
              : "Form updated successfully",
            preset
          );
        })
        .then(async (res) => {
          if (img) {
            const formData = new FormData();
            formData.append("upload_avatar", img);
            return axios
              .post(
                `https://findally-backend.herokuapp.com/api/user/uploadAvatar/${userId}`,
                formData,
                {
                  headers: {
                    "Content-Type": "multipart/form-data",
                  },
                }
              )
              .then(async (res) => {
                toast.success(
                  lang === "es"
                    ? "Imagen actualizada correctamente"
                    : "Image updated successfully",
                  preset
                );
                await timeout(5000);
                return (window.location.href = "/company/pricing");
              })
              .catch((err) => {
                toast.error(
                  lang === "es"
                    ? "Error al actualizar la imagen"
                    : "Error updating image",
                  preset
                );
              });
          } else {
            await timeout(5000);
            return (window.location.href = "/company/pricing");
          }
        })
        .catch((err) => {
          console.log(err);
          setisSubmit(false);
          return toast.error(
            lang === "es"
              ? "Error al actualizar el formulario"
              : "Error updating form",
            preset
          );
        });
    } else {
      if (plan === "Plan empresarial") {
        return axios
          .post(
            "https://findally-backend.herokuapp.com/api/Project/create/new",
            form
          )
          .then(async (res) => {
            console.log(res);
            toast.success(
              lang === "es"
                ? "Formulario enviado correctamente"
                : "Form sent successfully",
              preset
            );
          })
          .then(async (res) => {
            if (img) {
              const formData = new FormData();
              formData.append("upload_avatar", img);
              return axios
                .post(
                  `https://findally-backend.herokuapp.com/api/user/uploadAvatar/${userId}`,
                  formData,
                  {
                    headers: {
                      "Content-Type": "multipart/form-data",
                    },
                  }
                )
                .then(async (res) => {
                  toast.success(
                    lang === "es"
                      ? "Imagen actualizada correctamente"
                      : "Image updated successfully",
                    preset
                  );
                  await timeout(5000);
                  return (window.location.href = "/company/pricing");
                })
                .catch((err) => {
                  toast.error(
                    lang === "es"
                      ? "Error al actualizar la imagen"
                      : "Error updating image",
                    preset
                  );
                });
            } else {
              await timeout(5000);
              return (window.location.href = "/company/pricing");
            }
          })
          .catch((err) => {
            console.log("aca error 1");
            console.log(err);
            setisSubmit(false);
            return toast.error(
              lang === "es"
                ? "Error al enviar el formulario"
                : "Error sending form",
              preset
            );
          });
      }
      if (userEmail) {
        axios
          .post(
            "https://findally-backend.herokuapp.com/api/user/createOffer/" +
              userId,
            {
              cuponId: promoCode,
            }
          )
          .then((res) => {
            console.log(res);
            if (res.data.status === "success") {
              console.log(res.data);
              return axios
                .post(
                  "https://findally-backend.herokuapp.com/api/Project/create/new",
                  form
                )
                .then(async (response) => {
                  toast.success(
                    lang === "es"
                      ? "Formulario enviado correctamente"
                      : "Form sent successfully",
                    preset
                  );
                  // if (((form.modalidaddepago !== 'gratis' || form.modalidaddepago !== '') && !validCode)) {
                  //   return window.location.href = "/company/pricing"
                  // }
                  // await timeout(5000);
                  // return window.location.href = "/"
                })
                .then(async (res) => {
                  if (img) {
                    const formData = new FormData();
                    formData.append("upload_avatar", img);
                    return axios
                      .post(
                        `https://findally-backend.herokuapp.com/api/user/uploadAvatar/${userId}`,
                        formData,
                        {
                          headers: {
                            "Content-Type": "multipart/form-data",
                          },
                        }
                      )
                      .then(async (res) => {
                        toast.success(
                          lang === "es"
                            ? "Imagen actualizada correctamente"
                            : "Image updated successfully",
                          preset
                        );
                        if (
                          (form.modalidaddepago !== "gratis" ||
                            form.modalidaddepago !== "") &&
                          !validCode
                        ) {
                          return (window.location.href = "/company/pricing");
                        }
                        await timeout(5000);
                        return (window.location.href = "/company/pricing");
                      })
                      .catch((err) => {
                        toast.error(
                          lang === "es"
                            ? "Error al actualizar la imagen"
                            : "Error updating image",
                          preset
                        );
                      });
                  } else {
                    await timeout(5000);
                    return (window.location.href = "/company/pricing");
                  }
                })
                .catch((error) => {
                  console.log("aca error 2");
                  console.log(error);
                  setisSubmit(false);
                  return toast.error(
                    lang === "es"
                      ? "Error al enviar el formulario"
                      : "Error sending form",
                    preset
                  );
                });
            } else if (res.data.status === "limit excedeed") {
              setisSubmit(false);
              return toast.error(
                "Limite de ofertas alcanzado, por favor mejore su plan!",
                preset
              );
            } else if (res.data.status === "error") {
              setisSubmit(false);
              return toast.error("hubo un error en el servidor!", preset);
            }
          })
          .catch((err) => {
            console.log("aca error 3");
            console.log(err);
            setisSubmit(false);
            return toast.error(
              lang === "es"
                ? "Error al enviar el formulario"
                : "Error sending form",
              preset
            );
          });
      }
    }
  }
};

function timeout(delay) {
  return new Promise((res) => setTimeout(res, delay));
}

export const handleSubmit = (
  e,
  form,
  lang,
  editable,
  pubId,
  plan,
  userEmail,
  validCode,
  userId,
  montoMinimo,
  montoMaximo,
  setisSubmit,
  img
) => {
  e.preventDefault();
  checkForm(
    form,
    lang,
    editable,
    pubId,
    plan,
    userEmail,
    validCode,
    userId,
    montoMinimo,
    montoMaximo,
    setisSubmit,
    img
  );
};

export const formatterPeso = new Intl.NumberFormat("es-CO", {
  minimumFractionDigits: 0,
});
