/* eslint-disable */
import React, { useEffect, useState } from "react";
import { getPlans } from "../../../actions";
import Headerprofesional from "../../header/Headerprofesional";
import HeadertopV2 from "../../headertop/HeadertopV2"
import { useParams, useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import './pricingDetails.css'
import CircleIcon from '@mui/icons-material/Circle';
import AditionalServices from "./aditionalServices";
import ButtonP from "../../../pages/Companies/ButtonP";
import Sidebar from "../../../components/Sidebar/Sidebar";
import Navbar from "../../../components/Navbar/Navbar";
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';



const PricingDetails = () => {
  const dispatch = useDispatch();
  const { plan } = useParams();
  const history = useHistory();
  const planes = useSelector(state => state.planes);
  const user = useSelector(state => state.user);
  const { additional } = useSelector(state => state);
  const [open, setOpen] = useState(false);

  if (plan !== 'standard' && plan !== 'bussiness') {
    history.push('/company/pricing')
  }
  useEffect(() => {
    dispatch(getPlans());
  }, [dispatch]);

  const lang = useSelector(state => state.lang);

  let sum = 0;
  for (let key in additional) {

    if (additional[key] === false) continue
    sum += additional[key]?.price;

  }


  const services = []
  for (let key in additional) {
   if (additional[key] === false) continue
   services.push(additional[key]?._id)
 }
 localStorage.setItem('additionals', JSON.stringify(services))

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => setOpen(true);


  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '574px',
    height: '25vh',
    bgcolor: '#FFFFFF',
    borderRadius: '8px',
    overflow: 'auto',
  };

  const price = Number(planes[0]?.precio) + sum;
  const price2 = Number(planes[2]?.precio) + sum;

  return (
    <div className="contenedor-pricing-empresa">
      <div>
        <Sidebar />
        <div className="BodyWeb"
          style={{
            padding: "0rem 2rem",
            position: "absolute",
            right: "0",
          }}>
          <Navbar locate={''} />
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <h1 style={{
              margin: "0",
              fontSize: "20px",
              fontFamily: "Poppins",
              fontWeight: "700",
              color: "#202256",
              marginBottom: "27px",
            }}>
              {lang === "es" ? "Elegiste" : "You choose"}
            </h1>
            <h1 style={{
              margin: "0",
              fontSize: "40px",
              fontFamily: "Poppins",
              fontWeight: "700",
              color: "#202256",
              marginBottom: "27px",
            }}>
              {plan.charAt(0).toUpperCase() + plan.slice(1)}
            </h1>
            <h1 style={{
              margin: "0",
              fontSize: "12px",
              fontFamily: "Poppins",
              fontWeight: "400",
              color: "#393939",
              marginBottom: "27px",
            }}>
           {lang === "es" ? "Se aplican términos y condiciones" : "Apply terms and conditions"} 
            </h1>
          </div>
          <div className="pricing-details-body">
            <div className="pricing-details-body-card">
              <div className="pricing-details-card-inner-1">
                <div className="pricing-details-card-inner-1-1">
                  <h1 style={{
                    fontFamily: "Roboto",
                    fontWeight: "700",
                    fontSize: "36px",
                    color: "#5D5D5D",
                    textAlign: "center",
                  }}>
                    $ {plan === 'standard' ? '220 K' : '2.2 M'} <span style={{
                      fontFamily: "Roboto",
                      fontWeight: "700",
                      fontSize: "24px",
                      color: "#5D5D5D",
                      textAlign: "center",
                    }}>
                      /{plan === 'standard' ? "post" : lang === "es" ? "año" : "year"}
                    </span>
                  </h1>
                </div>
                <div className="pricing-details-card-inner-1-2">
                  <h1 style={{
                    fontFamily: "Poppins",
                    fontWeight: "700",
                    fontSize: "16px",
                    color: "#5D5D5D",
                    textAlign: "center",
                  }}>
                    {plan === 'standard' ?
                      lang === 'es' ? '+ 4% al contratar*' : '+ 4% when hiring*' :
                      lang === 'es' ? '+ 2% al contratar*' : '+ 2% when hiring*'
                    }
                  </h1>
                </div>
              </div>
              <div className="pricing-details-card-inner-2">
                <div style={{ display: 'flex', alignItems: 'center', texAlign: 'center', justifyContent: 'center', paddingLeft: '10px' }}>
                  <span style={{
                    fontFamily: "Poppins",
                    fontWeight: "400",
                    fontSize: "16px",
                    color: "#5D5D5D",
                  }}>
                    <span style={{ marginRight: '10px', textAlign: 'center', width: '100%' }}>
                      <CircleIcon style={{ color: '#5D5D5D', fontSize: 6 }} />
                    </span>
                    {
                      lang === 'es' && plan === 'standard' ? `Puedes contactar a todos los aplicantes.` : lang === 'es' && plan === 'bussiness' ? ' Publicaciones ilimitadas' : lang === 'en' && plan === 'standard' ? 'You can contact all applicants.' : lang === 'en' && plan === 'bussiness' ? 'Unlimited posts.' : ''
                    }
                  </span>
                </div>
                <div style={{ display: 'flex', alignItems: 'center', texAlign: 'center', justifyContent: 'center', paddingLeft: '10px', marginTop: '3%' }}>
                  <span style={{
                    fontFamily: "Poppins",
                    fontWeight: "400",
                    fontSize: "16px",
                    color: "#5D5D5D",
                  }}>
                    <span style={{ marginRight: '10px', textAlign: 'center', width: '100%' }}>
                      <CircleIcon style={{ color: '#5D5D5D', fontSize: 6 }} />
                    </span>
                    {
                      lang === 'es' && plan === 'standard' ? 'Difusión en redes sociales.' : lang === 'es' && plan === 'bussiness' ? 'Analítica de datos' : lang === 'en' && plan === 'standard' ? 'Dissemination in social networks.' : lang === 'en' && plan === 'bussiness' ? 'Data analytics' : ''
                    }
                  </span>
                </div>
                <div style={{ display: 'flex', alignItems: 'center', texAlign: 'center', justifyContent: 'center', paddingLeft: '10px', marginTop: '3%' }}>
                  <span style={{
                    fontFamily: "Poppins",
                    fontWeight: "400",
                    fontSize: "16px",
                    color: "#5D5D5D",
                  }}>
                    <span style={{ marginRight: '10px', textAlign: 'center', width: '100%' }}>
                      <CircleIcon style={{ color: '#5D5D5D', fontSize: 6 }} />
                    </span>
                    {
                      lang === 'es' && plan === 'standard' ? 'Personalización de proceso.' : lang === 'es' && plan === 'bussiness' ? 'Marca empleador, landing empresa, webinars.' : lang === 'en' && plan === 'standard' ? 'Process customization' : lang === 'en' && plan === 'bussiness' ? 'Employer brand, landing company,webinars.' : ''
                    }
                  </span>
                </div>
              </div>
            </div>
            <div className="pricing-details-body-card-aditional">
              <AditionalServices />
              <div className='pricing-footer-body-total-container'>
                <div style={{ width: '90%' }}>
                  <h1 style={{
                    fontFamily: "Poppins",
                    fontWeight: "700",
                    fontSize: "24px",
                    color: "#393939",
                    width: "100%",
                  }}>
                    Total:  <span style={{
                      fontFamily: "Roboto",
                      fontWeight: "700",
                      fontSize: "24px",
                      color: "#5D5D5D",
                      marginLeft: '5%',
                      width: '100%'
                    }}>
                      $ {plan === 'standard' ? (0.22 + sum / 1000000).toFixed(3) + 'M' : (2.2 + sum / 1000000).toFixed(3) + 'M'}
                    </span>
                  </h1>
                </div>
                <div style={{ width: '90%', marginTop: '3%' }}>

                  <div style={{ width: '100%' }}>
                    <button
                      className="label-popup-pago"
                      onClick={handleOpen}
                    > {lang === 'es' ? 'Pagar' : 'Buy'}</button>
                    <Modal
                      open={open}
                      onClose={handleClose}
                      aria-labelledby="modal-modal-title"
                      aria-describedby="modal-modal-description"
                    >
                      <Box sx={style}>
                        <div className="container-popup-pago">
                          <h3>{lang === 'es' ? '¿Quieres confirmar el pago?' : 'Do you want to confirm the payment?'} </h3>
                          <div className="container-buttons-popup">
                          <label className="label-popup-pago">
                            {lang === 'es' ? 'Aceptar' : 'Agree'}
                            <div style={{ display: 'none' }}>
                              {plan === 'standard' && planes[0] ?
                                <ButtonP type={planes[0]} user={user.id} price={price} />
                                : <ButtonP type={planes[2]} user={user.id} price={price2} />
                              }
                            </div>
                          </label>
                            <button className="button-cerrar-popup" onClick={handleClose}>{lang === 'es' ? 'Cancelar' : 'Cancel'}</button>
                          </div>

                        </div>
                        {/* :     
                         <div className="container-popup-pago">
                         <label className="label-popup-pago">
                         {lang === 'es' ? 'Aceptar' : 'Agree'}
                       <div style={{display: 'none'}}>
                       <ButtonP type={planes[2]} user={user.id} price={price2}/>
                       </div>

                       </label>
                         </div> 
                          } */}
                      </Box>
                    </Modal>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PricingDetails;
