/* eslint-disable  */
import axios from "axios";
//const urlprod = ' https://backend-production-1bc8.up.railway.app/'
//actions sended to reducers
//metho changes
//login actionscx
import { toast } from "react-toastify";

export const getUserPlan = (id) => {
  return (dispatch) => {
    axios("https://backend-production-1bc8.up.railway.app/user/" + id)
      .then((res) => {
        dispatch({
          type: "USER_PLANS",
          payload: res.data.paquete_premium,
        });
      })
      .catch((err) => console.log(err));
  };
};

export const getPlans = () => {
  return (dispatch) => {
    axios
      .get("https://backend-production-1bc8.up.railway.app/api/premium_payment")
      .then((res) => {
        console.log("llego");
        dispatch({
          type: "GET_PLANS",
          payload: res.data,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
};
export const loginReq = (payload) => ({
  type: "LOGIN_REQ",
  payload,
});
export const createprojectReq = (payload) => ({
  type: "CREATE_PROJECT_REQ",
  payload,
});
export const viewReq = (payload) => ({
  type: "VIEW-REQ",
  payload,
});
export const changeLang = (payload) => ({
  type: "LANG_REQ",
  payload,
});
export const logoutReq = (payload) => ({
  type: "LOGOUT_REQ",
  payload,
});
export const setProjectReq = (payload) => ({
  type: "SET-USER-PROJECT",
  payload,
});
export const setPhaseReq = (payload) => ({
  type: "SET-PHASE",
  payload,
});

export const projectApplyReq = (payload) => ({
  type: "SET-PROJECT-APPLY",
  payload,
});

export const setSinglePhaseReq = (payload) => ({
  type: "SET-SINGLE-PHASE",
  payload,
});

export const setSingleEtapaReq = (payload) => ({
  type: "SET-SINGLE-ETAPA",
  payload,
});

export const setEtapasReq = (payload) => ({
  type: "SET-ETAPAS",
  payload,
});

export const setSubEtapasReq = (payload) => ({
  type: "SET-SUB-ETAPAS",
  payload,
});

export const setInitialPhaseReq = (payload) => ({
  type: "SET-INITIAL-PHASE-REQ",
  payload,
});
export const setActualPhaseReq = (payload) => ({
  type: "SET-ACTUAL-PHASE-REQ",
  payload,
});

export const setEtapasNull = () => ({
  type: "SET-ETAPAS-NULL",
});

export const registerReq = (payload) => ({
  type: "REGISTER_REQ",
  payload,
});
export const formTicketReq = (payload) => ({
  type: "FORM_TICKET_REQ",
  payload,
});
export const formAvancesReq = (payload) => ({
  type: "FORM_AVANCES_REQ",
  payload,
});
export const formContactReq = (payload) => ({
  type: "FORM_CONTACT_REQ",
  payload,
});
export const formFeedbackReq = (payload) => ({
  type: "FORM_FEEDBACK_REQ",
  payload,
});
export const formPaymentReq = (payload) => ({
  type: "FORM_PAYMENT_REQ",
  payload,
});
export const formEventReq = (payload) => ({
  type: "FORM_EVENT_REQ",
  payload,
});
export const formConsultorReq = (payload) => ({
  type: "FORM_CONSULTOR_REQ",
  payload,
});
export const formUserReq = (payload) => ({
  type: "FORM_USER_REQ",
  payload,
});
export const formCompanyReqTho = (payload) => ({
  type: "FORM_COMPANY_REQ_THO",
  payload,
});
export const formCompanyReq = (payload) => ({
  type: "FORM_COMPANY_REQ",
  payload,
});

export const getVideosReq = (payload) => ({
  type: "GET-VIDEOS",
  payload,
});

export const usersAdminReq = (payload) => ({
  type: "GET-USERS-ADMIN",
  payload,
});

export const usersCompanyReq = (payload) => ({
  type: "GET-USERS-COMPANY",
  payload,
});

export const eventsProjectReq = (payload) => ({
  type: "GET-EVENTS-PROJECT",
  payload,
});

export const projectsAdminReq = (payload) => ({
  type: "GET-PROJECTS-ADMIN",
  payload,
});

export const deleteprojectsAdminReq = (payload) => ({
  type: "DELETE-PROJECT-ADMIN",
  payload,
});

export const deleteusersAdminReq = (payload) => ({
  type: "DELETE-USER-ADMIN",
  payload,
});

export const projectsReq = (payload) => ({
  type: "GET-PROJECTS",
  payload,
});
export const projectsRecomendedReq = (payload) => ({
  type: "GET-PROJECTS-RECOMENDED",
  payload,
});
export const userReq = (payload) => ({
  type: "GET-All-USERS",
  payload,
});
export const userProjectReq = (payload) => ({
  type: "GET-USER-PROJECT",
  payload,
});
export const userProjectReq2 = (payload) => ({
  type: "GET-USER-PROJECT2",
  payload,
});
export const MethodologyReq = (payload) => ({
  type: "METHODOLOGY",
  payload,
});

export const setEtapaReq = (payload) => ({
  type: "SET-ETAPA-REQ",
  payload,
});
export const setEtapaData = (payload) => ({
  type: "SET-ETAPADATA",
  payload,
});
export const resetReq = (payload) => ({
  type: "USER-PROJECT-RESET",
  payload,
});
export const applycation = (payload) => ({
  type: "APLICATION",
  payload,
});
export const sendTareaReq = (payload) => ({
  type: "SET-TAREA-DONE",
  payload,
});

export const SavePdfReq = (payload) => ({
  type: "SET-PDF",
  payload,
});

export const actualTareaReq = (payload) => ({
  type: "CHANGE-ACTUAL-TAREA-REQ",
  payload,
});
export const setSelectionProcess = (payload) => ({
  type: "SET-SELECTION-PROCESS",
  payload,
});

export function filterSelectionProcess(payload) {
  return function (dispatch) {
    dispatch({
      type: "FILTER-SELECTION-PROCESS",
      payload,
    });
  };
}
export function mirarfilterSelectionProcess() {
  return function (dispatch) {
    dispatch({
      type: "MIRAR-FILTER-SELECTION-PROCESS",
    });
  };
}
export function faltainfoinitial() {
  return function (dispatch) {
    dispatch({
      type: "FALTA-INFO-INITIAL",
    });
  };
}

export const actualTarea = (payload) => {
  return (dispatch) => {
    dispatch(actualTareaReq(payload));
  };
};
export const saveProjectId = (payload) => {
  return {
    type: "PROJECT_CONTACT",
    payload,
  };
};

export const setAdditional = (payload) => ({
  type: "SET_ADDITIONAL",
  payload,
});

function dataURItoBlob(dataURI) {
  // convert base64 to raw binary data held in a string
  // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
  var byteString = atob(dataURI.split(",")[1]);
  // separate out the mime component
  var mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];
  // write the bytes of the string to an ArrayBuffer
  var ab = new ArrayBuffer(byteString.length);
  // create a view into the buffer
  var ia = new Uint8Array(ab);
  // set the bytes of the buffer to the correct values
  for (var i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }
  // write the ArrayBuffer to a blob, and you're done
  var blob = new Blob([ab], { type: mimeString });
  return blob;
}

export const logoutUser = (payload, redirectUrl) => {
  return (dispatch) => {
    dispatch(logoutReq(payload));
    window.location.href = redirectUrl;
  };
};

export const setvideolist = (payload) => {
  return (dispatch) => {
    dispatch(getVideosReq(payload));
  };
};

export const changeview = (payload) => {
  return (dispatch) => {
    dispatch(viewReq(payload));
  };
};

export const sendFirma = (payload) => {
  const archivo = dataURItoBlob(payload.sign);
  var FormData = require("form-data");
  var data = new FormData();
  data.append("sign", archivo);
  data.append("usuario", payload.idUsuario);

  var config = {
    method: "post",
    url: "https://backend-production-1bc8.up.railway.app/api/signature/create",
    data: data,
  };
  return (dispatch) => {
    axios(config)
      .then(function (response) {
        console.log(JSON.stringify(response.data));
      })
      .catch(function (error) {
        console.log(error);
      });
  };
};

export const savePdf = (payload) => {
  return (dispatch) => {
    console.log(payload);
  };
};
export const firmakps = (payload, redirectUrl) => {
  const archivo = dataURItoBlob(payload.doc);
  var FormData = require("form-data");
  var data = new FormData();
  data.append("kps", archivo);
  data.append("usuario", payload.usuario);
  data.append("proyecto", payload.proyecto);

  var config = {
    method: "post",
    url: "https://backend-production-1bc8.up.railway.app/api/kps/create",
    data: data,
  };

  return (dispatch) => {
    axios(config)
      .then(function (response) {
        console.log(JSON.stringify(response.data));
      })
      .catch(function (error) {
        console.log(error);
      });
  };
};

export const firmaNda = (payload, redirectUrl) => {
  const archivo = dataURItoBlob(payload.doc);
  var FormData = require("form-data");
  var data = new FormData();
  data.append("nda", archivo);
  data.append("usuario", payload.usuario);
  data.append("proyecto", payload.proyecto);

  var config = {
    method: "post",
    url: "https://backend-production-1bc8.up.railway.app/api/nda/create",
    data: data,
  };

  return (dispatch) => {
    axios(config)
      .then(function (response) {
        console.log(JSON.stringify(response.data));
      })
      .catch(function (error) {
        console.log(error);
      });
  };
};

export const firmaCertificado = (payload, redirectUrl) => {
  const archivo = dataURItoBlob(payload.doc);
  var FormData = require("form-data");
  var data = new FormData();
  data.append("certificado", archivo);
  data.append("usuario", payload.usuario);
  data.append("proyecto", payload.proyecto);

  var config = {
    method: "post",
    url: "https://backend-production-1bc8.up.railway.app/api/certificado/create",
    data: data,
  };

  return (dispatch) => {
    axios(config)
      .then(function (response) {
        console.log(JSON.stringify(response.data));
      })
      .catch(function (error) {
        console.log(error);
      });
  };
};

export const getDocumentosReq = (payload) => ({
  type: "GET-DOCUMENTOS",
  payload,
});

//not completed yet
export const setError = (payload) => ({
  type: "SET_RROR",
  payload,
});
export const errors = (payload) => ({
  type: "SET_ERROR",
  payload,
});
export const getCertificados = (payload) => {
  return (dispatch) => {
    //fetch to backend register service
    axios
      .get(`https://backend-production-1bc8.up.railway.app/api/legal/${payload}`)
      .then(({ data }) => dispatch(getDocumentosReq(data)))
      .then((data) => {
        dispatch(CompanyData(data.payload.company_user.Company_Form[0]));
        dispatch(ConsultorData(data.payload.coallier_user._id));
      })
      // .then((data) =>
      .catch((err) => dispatch(setError(err)));
  };
};

export const Legal = (payload, redirectUrl) => {
  const request = {
    project: payload.id_proyecto,
    coallier_user: payload.Coallier_form,
    company_user: payload.Company_form,
    fecha_inicio: payload.fecha_inicio,
    fecha_fin: payload.fecha_fin,
    precio: payload.precio,
  };
  return (dispatch) => {
    axios
      //fetch to backend register service
      .post("https://backend-production-1bc8.up.railway.app/api/Legal", request)
      .then((res) => console.log(res))
      .then(() => {
        //   window.location.href = redirectUrl;
      })
      .catch((err) => dispatch(setError(err)));
  };
};
export const setStatusTask = (payload) => {
  const data = {
    status: true,
  };

  return (dispatch) => {
    axios
      .put(
        `https://backend-production-1bc8.up.railway.app/api/Task/update/${payload}`,
        data
      )
      .then((res) => console.log(res))
      .catch((err) => dispatch(setError(err)));
  };
};
export const setCommment = (ids, comment) => {
  console.log("id", ids, "comment", comment);
  const com = {
    comCompany: comment,
  };
  return (dispatch) => {
    axios
      .put(
        `https://backend-production-1bc8.up.railway.app/api/Task/update/comment/company/${ids}`,
        com
      )
      .then((res) => console.log(res))
      .catch((err) => dispatch(setError(err)));
  };
};

export const setCommmentAvance = (ids, comment) => {
  console.log("id", ids, "comment", comment);
  const com = {
    respuesta: comment,
  };
  return (dispatch) => {
    axios
      .put(`https://backend-production-1bc8.up.railway.app/api/avances/${ids}`, com)
      .then((res) => console.log(res))
      .catch((err) => dispatch(setError(err)));
  };
};

export const setCoallierCommment = (ids, comment) => {
  console.log("id", ids, "comment", comment);
  const com = {
    comCoallier: comment,
  };
  return (dispatch) => {
    axios
      .put(
        `https://backend-production-1bc8.up.railway.app/api/Task/update/comment/coallier/${ids}`,
        com
      )
      .then((res) => console.log(res))
      .catch((err) => dispatch(setError(err)));
  };
};

//se usara en admin para ver todos los usuarios
export const getUsersAdmin = () => {
  return (dispatch) => {
    //fetch to backend get users for admin
    //"https://backend-production-1bc8.up.railway.app/user/admin/table"
    axios
      .get("https://backend-production-1bc8.up.railway.app/users")
      .then(({ data }) => dispatch(usersAdminReq(data)))
      .catch((err) => dispatch(setError(err)));
  };
};

//se usara en la compania para ver todos los usuarios
export const getUsersCompany = () => {
  return (dispatch) => {
    //fetch to backend get users for company
    axios
      .get("https://backend-production-1bc8.up.railway.app/user/company/coalliers")
      .then(({ data }) => dispatch(usersCompanyReq(data)))
      .catch((err) => dispatch(setError(err)));
  };
};

//se usara en admin para ver todos los eventos
export const getEventsProject = (payload) => {
  return (dispatch) => {
    //fetch to backend register service
    axios
      .get("https://backend-production-1bc8.up.railway.app/api/Events")
      .then(({ data }) => dispatch(eventsProjectReq(data)))
      .catch((err) => dispatch(setError(err)));
  };
};

export const getProjectsAdmin = (page, perPage) => {
  return (dispatch) => {
    //fetch to backend register service
    axios
      .get(
        `https://backend-production-1bc8.up.railway.app/api/Project/?skip=${page}&limit=${perPage}`
      )
      .then(({ data }) => dispatch(projectsAdminReq(data.elements)))
      .catch((err) => dispatch(setError(err)));
  };
};

export const DeleteProjectsAdmin = (id) => {
  return (dispatch) => {
    //fetch to backend delete project
    axios
      .delete(`https://backend-production-1bc8.up.railway.app/api/Project/${id}`)
      .then(({ data }) => dispatch(deleteprojectsAdminReq(data)))
      .catch((err) => dispatch(setError(err)));
  };
};

export const DeleteUsersAdmin = (id) => {
  return (dispatch) => {
    //fetch to backend delete project
    axios
      .delete(`https://backend-production-1bc8.up.railway.app/user/delete/${id}`)
      .then(({ data }) => dispatch(deleteusersAdminReq(data)))
      .catch((err) => dispatch(setError(err)));
  };
};

export const ProjectApply = (payload, redirectUrl, lang) => {
  const send = {
    usuarios: [payload.usuario],
    proyecto: payload.proyecto,
  };
  console.log("hola", send);
  if (payload.form) {
    return (dispatch) => {
      axios
        //fetch to backend register service
        .post(
          `https://backend-production-1bc8.up.railway.app/api/applicants?lang=${lang}`,
          send
        )
        .then((res) => {
          if (res.status === 200) {
            console.log(res);
            dispatch(applycation(true));
          } else {
            console.log(res);
            dispatch(applycation(false));
          }
        })
        .catch((err) => {
          dispatch(setError(err));
          console.log(err);
        });
    };
  } else {
    return (dispatch) => {
      dispatch(applycation(false));
      window.location = "/consultor/form/" + payload.proyecto;
    };
  }
};

export const getPostedProjects = (payload) => {
  return (dispatch) => {
    //fetch to backend register service
    axios
      .get(
        `https://backend-production-1bc8.up.railway.app/api/Project?skip=${payload}&limit=10&status=En+espera`
      )
      .then(({ data }) => dispatch(projectsReq(data.elements)))
      .catch((err) => dispatch(setError(err)));
  };
};
export const projectsRecomended = (payload) => {
  return (dispatch) => {
    axios
      .get(
        `https://backend-production-1bc8.up.railway.app/api/Project?skip=${payload}&limit=4&status=En+espera`
      )
      .then(({ data }) => dispatch(projectsRecomendedReq(data.elements)))
      .catch((err) => dispatch(setError(err)));
  };
};

export const userLogOut = () => {
  document.cookie =
    "ActualProject=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/";
  document.cookie =
    "ActualProjec=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/";
  document.cookie = "Aemail=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/";
  document.cookie = "Aname=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/";
  document.cookie = "Aid=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/";
  document.cookie = "Atoken=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/";
  document.cookie = "Aprojects=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/";
  document.cookie = "Aform=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/";
  document.cookie = "Aroles=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/";
  document.cookie =
    "Methodology=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/";
  document.cookie =
    "singleEtapa=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/";
  document.cookie =
    "ActualProject=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/";
  document.cookie =
    "casos_exito=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/";
  document.cookie = "signature=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/";
  document.cookie =
    "AprojectsConsultor=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/";
  document.cookie = "tickets=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/";
  document.cookie =
    "certificados=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/";
  document.cookie = "kps=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/";
  document.cookie = "nda=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/";
  document.cookie = "Atareas=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/";
  document.cookie =
    "calificaion_aptitudes=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/";
  document.cookie =
    "sector_test=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/";
  document.cookie =
    "performance_test=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/";

  logoutUser({}, "/");
};

export const sendCv = (payload, id, lang) => {
  const newObject = {
    usuario: id,
    infoCV: {
      info_personal: {
        nombre: payload.info_personal.nombre
          ? payload.info_personal.nombre
          : "",
        profesion_actual: payload.info_personal.profesion_actual
          ? payload.info_personal.profesion_actual
          : "",
        sexo: payload.info_personal.sexo ? payload.info_personal.sexo : "",
        fecha_nacimiento: payload.info_personal.fecha_nacimiento
          ? payload.info_personal.fecha_nacimiento
          : "",
        pais_nacimiento: payload.info_personal.pais_nacimiento
          ? payload.info_personal.pais_nacimiento
          : "",
        departamento_nacimiento: payload.info_personal.departamento_nacimiento
          ? payload.info_personal.departamento_nacimiento
          : "",
        municipio_nacimiento: payload.info_personal.municipio_nacimiento
          ? payload.info_personal.municipio_nacimiento
          : "",
        ubicacion: payload.info_personal.ubicacion
          ? payload.info_personal.ubicacion
          : "",
      },
      contacto: [
        payload.contacto.email ? payload.contacto.email : "",
        ,
        payload.contacto.linkedin ? payload.contacto.linkedin : "",
        payload.contacto.telefono ? payload.contacto.telefono : "",
        payload.contacto.facebook ? payload.contacto.facebook : "",
        payload.contacto.instagram ? payload.contacto.instagram : "",
      ],
      aptitudes_principales: payload.aptitudes_principales.map(
        (e) => e.aptitud
      ),
      languages: payload.languages,
      certifications: payload.certifications.map((e) => e.Certificacion),
      extracto: payload.extracto,
      experiencia: payload.experiencia,
      educacion: payload.educacion,
      aspiracion_salarial: payload.aspiracion_salarial
        ? payload.aspiracion_salarial
        : "",
    },
  };

  return () => {
    console.log("payload", newObject);
    toast
      .promise(
        axios.post(
          `https://backend-production-1bc8.up.railway.app/api/user/readCVFromBody`,
          newObject
        ),
        {
          loading: lang === "es" ? "Enviando..." : "Sending...",
          success: lang === "es" ? "CV enviado" : "CV sent",
          error: lang === "es" ? "Error al enviar CV" : "Error sending CV",
        }
      )
      .then(({ data }) => {
        console.log(data);
        setTimeout(() => {
          window.location.href = `/consultor/profile/${id}`;
        });
      }, 3000)
      .catch((err) => console.log(err));
  };
};
export const getAllUsers = () => {
  return (dispatch) => {
    //fetch to backend register service
    axios
      .get("https://backend-production-1bc8.up.railway.app/users")
      .then(({ data }) => dispatch(userReq(data)))
      .catch((err) => dispatch(setError(err)));
  };
};

export const getUserProjects = (id) => {
  if (!id) {
    return {
      type: "GET_USER_PROJECTS4",
    };
  }
  return (dispatch) => {
    axios
      .get(`https://backend-production-1bc8.up.railway.app/api/user/projects/${id}`)
      .then((r) =>
        dispatch({ type: "GET_USER_PROJECTS", payload: r.data.projects })
      )
      .catch((err) => dispatch(setError(err)));
  };
};

export const getVideos = () => {
  return (dispatch) => {
    //fetch to backend register service
    axios
      .get("https://backend-production-1bc8.up.railway.app/api/Courses")
      .then(({ data }) => dispatch(getVideosReq(data)))
      .catch((err) => dispatch(setError(err)));
  };
};

export const getProject = (id) => {
  function setCookie(cName, cValue, expDays) {
    let date = new Date();
    date.setTime(date.getTime() + expDays * 24 * 60 * 60 * 1000);
    const expires = "expires=" + date.toUTCString();
    document.cookie = cName + "=" + cValue + "; " + expires + "; path=/";
  }
  return (dispatch) => {
    //fetch to backend register service
    axios
      .get(`https://backend-production-1bc8.up.railway.app/api/Project/${id}`)
      .then(({ data }) => {
        dispatch(setProjectReq(data));
        setCookie("ActualProject", data._id, 30);
      })

      .catch((err) => dispatch(setError(err)));
  };
};

export const getPhase = (id) => {
  return (dispatch) => {
    //fetch to backend register service
    axios
      .get(`https://backend-production-1bc8.up.railway.app/api/Phase/${id}`)
      .then(({ data }) => dispatch(setPhaseReq(data)))
      .catch((err) => dispatch(setError(err)));
  };
};

export const getEtapa = (id) => {
  return (dispatch) => {
    //fetch to backend register service
    axios
      .get(`https://backend-production-1bc8.up.railway.app/api/Etapa/${id}`)
      .then(({ data }) => dispatch(setEtapasReq(data)))
      .catch((err) => dispatch(setError(err)));
  };
};
export const getSubEtapa = (id) => {
  return (dispatch) => {
    //fetch to backend register service
    axios
      .get(`https://backend-production-1bc8.up.railway.app/api/Subetapa/${id}`)
      .then(({ data }) => dispatch(setSubEtapasReq(data)))
      .catch((err) => dispatch(setError(err)));
  };
};
export const getNewEtapas = (id) => {
  return (dispatch) => {
    //fetch to backend register service
    axios
      .get(`https://backend-production-1bc8.up.railway.app/api/Etapa/${id}`)
      .then(({ data }) => dispatch(setEtapasReq(data)))
      .catch((err) => dispatch(setError(err)));
  };
};

export const getEtapas = (SinglePhase) => {
  return (dispatch) => {
    SinglePhase.etapas.forEach((element) => {
      dispatch(getEtapa(element));
    });
  };
};

export const getSubEtapas = (SinglePhase) => {
  return (dispatch) => {
    SinglePhase.subetapas.forEach((element) => {
      dispatch(getSubEtapa(element));
    });
  };
};

export const getSinglePhase = (id) => {
  return (dispatch) => {
    //fetch to backend register service
    axios
      .get(`https://backend-production-1bc8.up.railway.app/api/etapa/${id}`)
      .then(({ data }) => dispatch(setSinglePhaseReq(data)))
      .then((data) => dispatch(getSubEtapas(data.payload)))
      .catch((err) => dispatch(setError(err)));
  };
};

export const getSingleEtapa = (id) => {
  return (dispatch) => {
    //fetch to backend register service
    axios
      .get(`https://backend-production-1bc8.up.railway.app/api/Subetapa/${id}`)
      .then(({ data }) => {
        dispatch(setSingleEtapaReq(data));
        document.cookie = `sigleEtapa=${data}`;
      })
      .catch((err) => dispatch(setError(err)));
  };
};

export const getProjectPhases = (metodologia) => {
  return (dispatch) => {
    metodologia.fases.forEach((element) => {
      dispatch(getPhase(element));
    });
  };
};

export const formCompanyUpdate = (payload, redirectUrl) => {
  const coallier_form = payload.userform; //añadir en la page de peticion
  const company = {
    usuario: payload.usuario,
    nit: payload.nit,
    sector: payload.sector,
    ubicacion: payload.ubicacion,
    descripcion: payload.descripcion,
    telefono: payload.telefono,
    idioma: payload.idioma,
    email: payload.email,
    tiempo_mercado: payload.tiempo_mercado,
    sitio_web: payload.sitio_web,
    tamaño: payload.tamaño,
  };
  return (dispatch) => {
    axios
      //fetch to backend register service
      .put(
        `https://backend-production-1bc8.up.railway.app/api/Company_Form/${coallier_form}`,
        company
      )
      .then(({ data }) => dispatch(formConsultorReq(data)))
      .then(() => {
        window.location.href = redirectUrl;
      })
      .catch((err) => dispatch(setError(err)));
  };
};

export const formCompany = (payload, redirectUrl) => {
  const company = {
    usuario: payload.usuario,
    nit: payload.nit,
    sector: payload.sector,
    ubicacion: payload.ubicacion,
    descripcion: payload.descripcion,
    telefono: payload.telefono,
    idioma: payload.idioma,
    email: payload.email,
    tiempo_mercado: payload.tiempo_mercado,
    sitio_web: payload.sitio_web,
    tamaño: payload.tamaño,
  };
  return (dispatch) => {
    axios
      //fetch to backend register service
      .post("https://backend-production-1bc8.up.railway.app/api/Company_Form", company)
      .then(({ data }) => dispatch(formConsultorReq(data)))
      .then(() => {
        window.location.href = redirectUrl;
      })
      .catch((err) => dispatch(setError(err)));
  };
};

export const projecTryOut = (payload, redirectUrl) => {
  const data = {
    usuario: payload,
  };
  return (dispatch) => {
    axios

      //fetch to backend register service
      .put(
        "https://backend-production-1bc8.up.railway.app/api/Project/asignarusuario/6092f361961cf87ed851c6f3",
        data
      )
      //   .then(({ data }) => dispatch(formConsultorReq(data)))
      .then(() => {
        window.history.back();
      })
      .catch((err) => dispatch(setError(err)));
  };
};

export const match = (payload, redirectUrl) => {
  const id = payload.id_proyecto;
  return (dispatch) => {
    payload.id_usuarios.forEach((element) => {
      let usuario = {
        usuario: element,
      };
      axios
        //fetch to backend register service
        .put(
          `https://backend-production-1bc8.up.railway.app/api/Project/asignarusuario/${id}`,
          usuario
        )
        .then((res) => console.log(res))
        .then(() => {
          //  window.history.back();
        })
        .catch((err) => dispatch(setError(err)));
    });
  };
};

export const formConsultor = (payload, redirectUrl) => {
  return (dispatch) => {
    axios
      //fetch to backend register service
      .post("https://backend-production-1bc8.up.railway.app/api/Coallier_Form", payload)
      .then(({ data }) => dispatch(formConsultorReq(data)))
      .then(() => {
        window.location.href = redirectUrl;
      })
      .catch((err) => dispatch(setError(err)));
  };
};

export const formConsultorUpdate = (payload, redirectUrl) => {
  return (dispatch) => {
    axios
      //fetch to backend register service
      .put(
        `https://backend-production-1bc8.up.railway.app/api/Coallier_Form${payload.userform}`,
        payload
      )
      .then(({ data }) => dispatch(formConsultorReq(data)))
      .then(() => {
        window.location.href = redirectUrl;
      })
      .catch((err) => dispatch(setError(err)));
  };
};

export const registerTicket = (payload) => {
  const ticket = {
    usuario: payload.usuario,
    problema: payload.problema,
    categoria: payload.categoria,
    descripcion: payload.descripcion,
  };

  return (dispatch) => {
    axios
      //fetch to backend register ticket
      .post("https://backend-production-1bc8.up.railway.app/api/Ticket", ticket)
      .then(({ data }) => dispatch(formTicketReq(data)))
      .then(() => {
        window.history.back();
      })
      .catch((err) => dispatch(setError(err)));
  };
};

export const registerComment = (payload) => {
  const avance = {
    coallier_user: payload.coallier_user,
    comentario: payload.comentario,
    respuesta: payload.respuesta,
    proyecto: payload.descripcion,
    project_id: payload.project_id,
  };

  return (dispatch) => {
    axios
      //fetch to backend register avance
      .post("https://backend-production-1bc8.up.railway.app/api/avances", avance)
      .then(({ data }) => dispatch(formAvancesReq(data)))
      .then(() => {
        window.history.back();
      })
      .catch((err) => dispatch(setError(err)));
  };
};

export const registerContact = (payload) => {
  const contacto = {
    tipo: payload.tipo,
    correo: payload.correo,
    asunto: payload.asunto,
    descripcion: payload.descripcion,
  };

  return (dispatch) => {
    axios
      //fetch to backend register Contact
      .post("http://localhost:3000/api/Contact", contacto)
      .then(({ data }) => dispatch(formContactReq(data)))
      .then(() => {
        window.history.back();
      })
      .catch((err) => dispatch(setError(err)));
  };
};

export const registerEvent = (payload) => {
  const event = {
    proyecto: payload.proyecto,
    title: payload.title,
    description: payload.description,
    url: payload.url,
    start: payload.start,
    end: payload.end,
  };
  return (dispatch) => {
    axios
      //fetch to backend register feedback
      .post("https://backend-production-1bc8.up.railway.app/api/Events/create", event)
      .then(({ data }) => dispatch(formEventReq(data)))
      .then(() => {
        window.history.back();
      })
      .catch((err) => dispatch(setError(err)));
  };
};

export const registerFeedback = (payload) => {
  const feedback = {
    proyecto: payload.proyecto,
    usuario: payload.usuario,
    calificacion: payload.calificacion,
    continuarias: payload.continuarias,
    comentario: payload.comentario,
    empresa: payload.empresa,
    satisfaccion: payload.satisfaccion,
    motivo: payload.motivo,
    nivel_satisfaccion: payload.nivel_satisfaccion,
    interaccion: payload.interaccion,
    nivel_interaccion: payload.nivel_interaccion,
    calidad_precio: payload.calidad_precio,
    retorno: payload.retorno,
    motivo_retorno: payload.motivo_retorno,
    solucion: payload.solucion,
    satisfaccion_tiempo: payload.satisfaccion_tiempo,
    opcion: payload.opcion,
    porque_opcion: payload.porque_opcion,
    calificacion_final: payload.calificacion_final,
    palabra_servicio: payload.palabra_servicio,
    contratar: payload.contratar,
    motivo_contratar: payload.motivo_contratar,
    recomendacion: payload.recomendacion,
    mensaje: payload.mensaje,
  };
  return (dispatch) => {
    axios
      //fetch to backend register feedback
      .post("https://backend-production-1bc8.up.railway.app/api/Feedback", feedback)
      .then(({ data }) => dispatch(formFeedbackReq(data)))
      .then(() => {
        window.location.href = "/company/home";
      })
      .catch((err) => dispatch(setError(err)));
  };
};

/*
export const registerPayment = (payload) => {
  const feedback = {
    usuario: payload.usuario,
    calificacion: payload.calificacion,
    continuarias: payload.continuarias,
    comentario: payload.comentario
  }
  return (dispatch) => {
    axios
      //fetch to backend register feedback
      .post("https://backend-production-1bc8.up.railway.app/api/Feedback", feedback)
      .then(({
        data
      }) => dispatch(formPaymentReq(data)))
      .then(() => {
        window.history.back();
      })
      .catch((err) => dispatch(setError(err)));
  };
};
 */
export const LoadCv = (payload) => ({
  type: "LOAD_CV",
  payload,
});
export const ConsultorData = (id) => {
  return (dispatch) => {
    axios
      //fetch to backend register service
      .get(`https://backend-production-1bc8.up.railway.app/user/${id}`)
      .then((res) => dispatch(formUserReq(res.data.Coallier_Form)))
      .then(() => {
        //     window.location.href = redirectUrl;
      })
      .catch((err) => dispatch(setError(err)));
  };
};

export const CompanyData = (id) => {
  return (dispatch) => {
    axios
      //fetch to backend register service
      .get(`https://backend-production-1bc8.up.railway.app/api/Company_Form/${id}`)

      .then((res) => dispatch(formCompanyReqTho(res.data)))
      .catch((err) => dispatch(setError(err)));
  };
};

export const ConsultorData1 = (id, redirectUrl) => {
  return (dispatch) => {
    axios
      //fetch to backend register service
      .get(`https://backend-production-1bc8.up.railway.app/user/${id}`)
      .then((res) => dispatch(formUserReq(res.data.Company_Form)))
      .then(() => { })
      .catch((err) => dispatch(setError(err)));
  };
};
export const ConsultorProjects = (id) => {
  return (dispatch) => {
    id?.map(async (id) => {
      await axios
        .get(`https://backend-production-1bc8.up.railway.app/api/Project/${id}`)
        .then(({ data }) => dispatch(userProjectReq(data)))
        .catch((err) => dispatch(setError(err)));
    });
  };
};
export const ConsultorProjects2 = (id) => {
  let ids = id.split(",");
  return (dispatch) => {
    ids.forEach((id) => {
      axios
        //fetch to backend register service
        .get(`https://backend-production-1bc8.up.railway.app/api/Project/${id}`)
        .then(({ data }) => dispatch(userProjectReq2(data)))
        .catch((err) => dispatch(setError(err)));
    });
  };
};

export const Methodology = (id) => {
  return (dispatch) => {
    axios
      //fetch to backend register service
      .get(`https://backend-production-1bc8.up.railway.app/api/metodology/${id}`)
      .then(({ data }) => dispatch(MethodologyReq(data)))
      .then((data) => {
        document.cookie = `Methodology=${data.payload._id}`;
        document.cookie = `sigleEtapa=${data.payload.fases[0].etapas}`;
        dispatch(setInitialPhase(data.payload.fases[0].etapas));
      })
      .then(() => {
        //     window.location.href = redirectUrl;
      })
      .catch((err) => dispatch(setError(err)));
  };
};
export const setInitialPhase = (payload) => {
  return (dispatch) => {
    dispatch(setInitialPhaseReq(payload));
  };
};
export const setEtapa = (payload) => {
  return (dispatch) => {
    dispatch(setEtapaReq(payload.subetapas));
  };
};
export const setActualPhase = (payload) => {
  return (dispatch) => {
    dispatch(setActualPhaseReq(payload.etapas));
  };
};
export const setActualEtapa = (payload) => {
  return (dispatch) => {
    dispatch(setEtapaData(payload));
  };
};
export const DeleteProject = (payload, redirectUrl) => {
  return (dispatch) => {
    axios
      //fetch to backend register service
      .delete("https://backend-production-1bc8.up.railway.app/api/Project", payload)
      .then((res) => {
        console.log(res);
      })
      //   .then(({ data }) => dispatch(formCompanyReq(data)))
      .then(() => {
        window.location.href = redirectUrl;
      })
      .catch((err) => dispatch(setError(err)));
  };
};

export const CreateProject = (payload, redirectUrl) => {
  const project = {
    nombre: payload.nombre,
    confidencial: payload.confidencial,
    presupuesto: payload.presupuesto,
    empresa: payload.empresa,
    lenguajes_prog: payload.lenguajes_prog,
    descripcion: payload.descripcion,
    idioma: payload.idioma,
    problema_empresa: payload.problema_empresa,
    ciudad: payload.ciudad,
    objetivos: payload.objetivos,
    metricas: payload.metricas,
    modalidad: payload.modalidad,
    problema_seleccion: payload.problema_seleccion,
    causa_empresa: payload.causa_empresa,
    causa_seleccion: payload.causa_seleccion,
    linea_negocio: payload.linea_negocio,
    habilidades_requeridas: payload.habilidades_requeridas,
    nivel_ingles: payload.nivel_ingles,
    comentario: payload.comentario,
    usuario: payload.usuario,
    codigo_promo: payload.codigo_promo,
  };
  return (dispatch) => {
    axios
      //fetch to backend register service
      .post("https://backend-production-1bc8.up.railway.app/api/Project", project)
      .then((res) => {
        console.log(res);
      })
      //   .then(({ data }) => dispatch(formCompanyReq(data)))
      .then(() => {
        window.location.href = redirectUrl;
      })
      .catch((err) => dispatch(setError(err)));
  };
};

export const CreateProjectWhithoutPayment = (payload, redirectUrl) => {
  const project = {
    nombre: payload.nombre,
    confidencial: payload.confidencial,
    email: payload.email,
    presupuesto: payload.presupuesto,
    empresa: payload.empresa,
    lenguajes_prog: payload.lenguajes_prog,
    descripcion: payload.descripcion,
    idioma: payload.idioma,
    problema_empresa: payload.problema_empresa,
    ciudad: payload.ciudad,
    objetivos: payload.objetivos,
    metricas: payload.metricas,
    modalidad: payload.modalidad,
    problema_seleccion: payload.problema_seleccion,
    causa_empresa: payload.causa_empresa,
    causa_seleccion: payload.causa_seleccion,
    linea_negocio: payload.linea_negocio,
    habilidades_requeridas: payload.habilidades_requeridas,
    nivel_ingles: payload.nivel_ingles,
    comentario: payload.comentario,
    usuario: payload.usuario,
    codigo_promo: payload.codigo_promo,
  };
  return (dispatch) => {
    dispatch(createprojectReq(project));
  };
};

export const CreateUnregisterProject = (payload, redirectUrl) => {
  const project = {
    nombre: payload.nombre,
    confidencial: payload.confidencial,
    email: payload.email,
    presupuesto: payload.presupuesto,
    empresa: payload.empresa,
    lenguajes_prog: payload.lenguajes_prog,
    descripcion: payload.descripcion,
    idioma: payload.idioma,
    problema_empresa: payload.problema_empresa,
    ciudad: payload.ciudad,
    objetivos: payload.objetivos,
    metricas: payload.metricas,
    modalidad: payload.modalidad,
    problema_seleccion: payload.problema_seleccion,
    causa_empresa: payload.causa_empresa,
    causa_seleccion: payload.causa_seleccion,
    linea_negocio: payload.linea_negocio,
    habilidades_requeridas: payload.habilidades_requeridas,
    nivel_ingles: payload.nivel_ingles,
    comentario: payload.comentario,
    usuario: payload.usuario,
    codigo_promo: payload.codigo_promo,
  };

  return (dispatch) => {
    axios
      //fetch to backend register service
      .post(
        "https://backend-production-1bc8.up.railway.app/api/Project/user/project",
        project
      )
      .then((res) => {
        console.log(res);
      })
      //   .then(({ data }) => dispatch(formCompanyReq(data)))
      .then(() => {
        window.location.href = "https://coally.com/proyecto-creado/";
      })
      .catch((err) => dispatch(setError(err)));
  };
};

//actions (fetch req) with redux thrunk
export const registerUser = (payload, lang) => {
  try {
    const newUser = {
      username: payload.username,
      email: payload.email.toLowerCase(),
      password: payload.password,
      phoneNumber: payload.phoneNumber,
      roles: [payload.roles],
      verifyEmail: "este es de registro",
      num_id: payload.num_id ? payload.num_id : "",
      Schneider: payload.Schneider ? payload.Schneider : false,
    };
    return async (dispatch) => {
      const firstResponse = await axios.post(
        `https://backend-production-1bc8.up.railway.app/api/auth/signup?lang=${lang}`,
        newUser
      );
      if (firstResponse.data.status === "success") {
        dispatch(registerReq(firstResponse.data));
        const secondResponse = await axios.post(
          "https://backend-production-1bc8.up.railway.app/api/user/email/info",
          { email: newUser.email }
        );
        console.log(secondResponse.data.user);
        return dispatch(setUserInfo(secondResponse.data.user));
      } else {
        lang === "es"
          ? toast.error("Error, Email ya registrado.", {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          })
          : toast.error("Error, Email already registered.", {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
      }
    };
  } catch (err) {
    console.log(err);
  }
};
export const registerUserUniversity = (payload, lang, university) => {
  try {
    const newUser = {
      username: payload.username,
      email: payload.email.toLowerCase(),
      password: payload.password,
      phoneNumber: payload.phoneNumber,
      roles: [payload.roles === "coaliier estudiante" ? 'coallier' : payload.roles],
      verifyEmail: "este es de registro",
      num_id: payload.num_id ? payload.num_id : "",
      Schneider: payload.Schneider ? payload.Schneider : false,
      university: {
        name: university,
        value: true,
        type_user: payload.roles === "coaliier estudiante" ? 'estudiante' : payload.roles,
      },
    };
    return async (dispatch) => {
      const firstResponse = await axios.post(
        `https://backend-production-1bc8.up.railway.app/api/auth/signupUniversity?lang=${lang}`,
        newUser
      );
      if (firstResponse.data.status === "success") {
        dispatch(registerReq(firstResponse.data));
        const secondResponse = await axios.post(
          "https://backend-production-1bc8.up.railway.app/api/user/email/info",
          { email: newUser.email }
        );
        console.log(secondResponse.data.user);
        return dispatch(setUserInfo(secondResponse.data.user));
      } else {
        lang === "es"
          ? toast.error("Error, Email ya registrado.", {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          })
          : toast.error("Error, Email already registered.", {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
      }
    };
  } catch (err) {
    console.log(err);
  }
};
export const setUserInfo = (payload) => ({
  type: "SET_USER_INFO",
  payload,
});
export function otpRegister(obj, lang, schneider) {
  const newObj = {
    username: obj.username,
    email: obj.email,
    id: obj.id,
    Schneider: schneider ? schneider : false,
    verifyEmail: "este es de registro",
  };
  return function (dispatch) {
    axios
      .post(
        `https://backend-production-1bc8.up.railway.app/api/auth/verifyemail?lang=${lang}`,
        newObj
      )
      .catch((err) => dispatch(setError(err)));
  };
}

export const otpVerify = (payload, redirectUrl) => {
  const newObj = {
    email: payload.email,
    otp: payload.otp,
  };
  return (dispatch) => {
    axios
      .post("https://backend-production-1bc8.up.railway.app/api/auth/verifyotp", newObj)
      .then((window.location.href = redirectUrl))
      .catch((err) => dispatch(setError(err)));
  };
};

export const loginSocial = (payload, redirectUrl) => {
  return (dispatch) => {
    document.cookie = `Aemail=${payload.email}`;
    document.cookie = `Aname=${payload.name}`;
    document.cookie = `Aid=${payload.id}`;
    //login req to update state
    dispatch(loginReq(payload));
    //sending to de redirect page
    window.location.href = redirectUrl;
  };
};

export const getUserInfo = (id) => {
  return (dispatch) => {
    axios
      .get(`https://backend-production-1bc8.up.railway.app/user/${id}`)
      .then((res) => {
        const userProf = {
          id: res.data.id,
          email: res.data.email,
          roles: res.data.roles[0].name,
          calificaion_aptitudes: res.data.calificaion_aptitudes
            ? res.data.calificaion_aptitudes
            : "",
          motivational_history: res.data.motivational_history
            ? res.data.motivational_history
            : "",
          performance_test: res.data.performance_test
            ? res.data.performance_test
            : "",
          sector_test: res.data.sector_test ? res.data.sector_test : "",
          avatar: res.data.avatar ? res.data.avatar : "",
          CVPersonalized: res.data.urlCVPersonalized
            ? res.data.urlCVPersonalized
            : "",
          bootcamp: res?.data?.bootcamp ? res?.data?.bootcamp?.name : "",
          phoneNumber: res.data.phoneNumber ? res.data.phoneNumber : "",
        };
        localStorage.removeItem("user");
        localStorage.setItem("user", JSON.stringify(userProf));
        dispatch(loginReq(userProf));
        window.location.reload();
      })
      .catch((err) => console.log(err));
  };
};
export const getUserInfoWithoutReload = (id) => {
  return (dispatch) => {
    axios
      .get(`https://backend-production-1bc8.up.railway.app/user/${id}`)
      .then((res) => {
        const userProf = {
          id: res.data.id,
          email: res.data.email,
          roles: res.data.roles[0].name,
          calificaion_aptitudes: res.data.calificaion_aptitudes
            ? res.data.calificaion_aptitudes
            : "",
          motivational_history: res.data.motivational_history
            ? res.data.motivational_history
            : "",
          performance_test: res.data.performance_test
            ? res.data.performance_test
            : "",
          sector_test: res.data.sector_test ? res.data.sector_test : "",
          avatar: res.data.avatar ? res.data.avatar : "",
          CVPersonalized: res.data.urlCVPersonalized
            ? res.data.urlCVPersonalized
            : "",
          bootcamp: res?.data?.bootcamp ? res?.data?.bootcamp?.name : "",
          phoneNumber: res.data.phoneNumber ? res.data.phoneNumber : "",
        };
        localStorage.removeItem("user");
        localStorage.setItem("user", JSON.stringify(userProf));
        dispatch(loginReq(userProf));
      })
      .catch((err) => console.log(err));
  };
};

export const reLoginProf = (payload) => {
  return (dispatch) => {
    axios
      .get(`https://backend-production-1bc8.up.railway.app/user/${payload}`)
      .then((res) => {
        const userProf = {
          id: res.data.id,
          email: res.data.email,
          roles: res.data.roles[0].name,
          calificaion_aptitudes: res.data.calificaion_aptitudes
            ? res.data.calificaion_aptitudes
            : "",
          motivational_history: res.data.motivational_history
            ? res.data.motivational_history
            : "",
          performance_test: res.data.performance_test
            ? res.data.performance_test
            : "",
          sector_test: res.data.sector_test ? res.data.sector_test : "",
          avatar: res.data.avatar ? res.data.avatar : "",
          phoneNumber: res.data.phoneNumber ? res.data.phoneNumber : "",
        };
        localStorage.removeItem("user");
        localStorage.setItem("user", JSON.stringify(userProf));
        dispatch(loginReq(userProf));
        window.reload();
      });
  };
};

export const newLoginUser = (payload) => {
  //log the user and then storage the data in the local storage
  const user = {
    email: payload.email.toLowerCase(),
    password: payload.password,
  };
  let unileverData = "";
  return (dispatch) => {
    axios
      .post("https://backend-production-1bc8.up.railway.app/api/auth/signin", user)
      .then(async (res) => {
        localStorage.setItem("token", res.data.accessToken);
        localStorage.setItem(
          "plan_premium",
          JSON.stringify(
            res.data.data_plan_premium ? res.data.data_plan_premium : ""
          )
        );
        if (res.data.unilever) {
          localStorage.setItem(
            "unilever",
            JSON.stringify(res.data.unilever ? res.data.unilever : "")
          );
          axios
            .get(
              `https://backend-production-1bc8.up.railway.app/api/unilever/users/${res.data.id}`
            )
            .then((res) => {
              localStorage.setItem(
                "unilever_rol",
                JSON.stringify(res.data.data.type_user_unilever)
              );
              unileverData = res.data.data._id;
              localStorage.setItem(
                "unilever_id",
                JSON.stringify(res.data.data._id)
              );
            });
        }
        axios
          .get(`https://backend-production-1bc8.up.railway.app/user/${res.data.id}`)
          .then((res) => {
            if (res.data.roles[0].name === "coallier") {
              const userProf = {
                id: res.data.id,
                email: res.data.email,
                roles: res.data.roles[0].name,
                calificaion_aptitudes: res.data.calificaion_aptitudes
                  ? res.data.calificaion_aptitudes
                  : "",
                motivational_history: res.data.motivational_history
                  ? res.data.motivational_history
                  : "",
                performance_test: res.data.performance_test
                  ? res.data.performance_test
                  : "",
                sector_test: res.data.sector_test ? res.data.sector_test : "",
                avatar: res.data.avatar ? res.data.avatar : "",
                CVPersonalized: res.data.urlCVPersonalized
                  ? res.data.urlCVPersonalized
                  : "",
                bootcamp: res?.data?.bootcamp ? res?.data?.bootcamp?.name : "",
                bootcampAprobado: res?.data?.bootcampApproved
                  ? res?.data?.bootcampApproved
                  : "",
                unilever: unileverData,
                phoneNumber: res.data.phoneNumber ? res.data.phoneNumber : "",
              };
              localStorage.setItem("user", JSON.stringify(userProf));
              dispatch(loginReq(userProf));
            }
            if (
              res.data.roles[0].name === "company" ||
              res.data.roles === "company"
            ) {
              const userCom = {
                id: res.data.id,
                roles: res.data.roles[0].name
                  ? res.data.roles[0].name
                  : res.data.roles,
                email: res.data.email,
                name: res.data.username,
                calificaion_aptitudes: res.data.calificaion_aptitudes
                  ? res.data.calificaion_aptitudes
                  : "",
                performance_test: res.data.performance_test
                  ? res.data.performance_test
                  : "",
                sector_test: res.data.sector_test ? res.data.sector_test : "",
                kps: res.data.kps ? res.data.kps : "",
                nda: res.data.nda ? res.data.nda : "",
                certificados: res.data.certificados
                  ? res.data.certificados
                  : "",
                projects: res.data.projects ? res.data.projects : "",
                avatar: res.data.avatar ? res.data.avatar : "",
                phoneNumber: res.data.phoneNumber ? res.data.phoneNumber : "",
              };
              localStorage.setItem("user", JSON.stringify(userCom));
              dispatch(loginReq(userCom));
            }
          });
      })
      .catch((err) => dispatch(errors(err)));
  };
};

export const loginUserUniversitie = (payload, setcargando) => {
  const user = {
    email: payload.email.toLowerCase(),
    password: payload.password,
  };
  let university = {};
  return (dispatch) => {
    axios
      .post("https://backend-production-1bc8.up.railway.app/api/auth/signin", user)
      .then(async (res) => {
        res.data.university
          ? (university = res.data.university)
          : (university = "");
        localStorage.setItem("token", res.data.accessToken);
        localStorage.setItem(
          "plan_premium",
          JSON.stringify(
            res.data.data_plan_premium ? res.data.data_plan_premium : ""
          )
        );
        axios
          .get(`https://backend-production-1bc8.up.railway.app/user/${res.data.id}`)
          .then((res) => {
            res?.data?.roles[1]?.name === "admin"
              ? (university.admin = true)
              : (university.admin = false);
            if (res.data.roles[0].name === "coallier") {
              const userProf = {
                id: res.data.id,
                email: res.data.email,
                roles: res.data.roles[0].name,
                calificaion_aptitudes: res.data.calificaion_aptitudes
                  ? res.data.calificaion_aptitudes
                  : "",
                motivational_history: res.data.motivational_history
                  ? res.data.motivational_history
                  : "",
                performance_test: res.data.performance_test
                  ? res.data.performance_test
                  : "",
                sector_test: res.data.sector_test ? res.data.sector_test : "",
                avatar: res.data.avatar ? res.data.avatar : "",
                CVPersonalized: res.data.urlCVPersonalized
                  ? res.data.urlCVPersonalized
                  : "",
                bootcamp: res?.data?.bootcamp ? res?.data?.bootcamp?.name : "",
                bootcampAprobado: res?.data?.bootcampApproved
                  ? res?.data?.bootcampApproved
                  : "",
                phoneNumber: res.data.phoneNumber ? res.data.phoneNumber : "",
                university: university,
              };
              localStorage.setItem("user", JSON.stringify(userProf));
              dispatch(loginReq(userProf));
            }
            if (
              res.data.roles[0].name === "company" ||
              res.data.roles === "company"
            ) {
              const userCom = {
                id: res.data.id,
                roles: res.data.roles[0].name
                  ? res.data.roles[0].name
                  : res.data.roles,
                email: res.data.email,
                name: res.data.username,
                calificaion_aptitudes: res.data.calificaion_aptitudes
                  ? res.data.calificaion_aptitudes
                  : "",
                performance_test: res.data.performance_test
                  ? res.data.performance_test
                  : "",
                sector_test: res.data.sector_test ? res.data.sector_test : "",
                kps: res.data.kps ? res.data.kps : "",
                nda: res.data.nda ? res.data.nda : "",
                certificados: res.data.certificados
                  ? res.data.certificados
                  : "",
                projects: res.data.projects ? res.data.projects : "",
                avatar: res.data.avatar ? res.data.avatar : "",
                phoneNumber: res.data.phoneNumber ? res.data.phoneNumber : "",
                university: university,
              };
              localStorage.setItem("user", JSON.stringify(userCom));
              dispatch(loginReq(userCom));
            }
          });
      })
      .catch((err) => dispatch(errors(err)));
  };
};

export const loginUser = ({ email, password, url }, redirectUrl) => {
  return (dispatch) => {
    const data = {
      email: email,
      password: password,
    };
    const data2 = {
      username: email,
      password: password,
    };
    var config = {
      method: "post",
      url: "https://backend-production-1bc8.up.railway.app/api/auth/signin",
      data: data,
    };
    var config2 = {
      method: "post",
      url: "https://backend-production-1bc8.up.railway.app/api/auth/signin",
      data: data2,
    };
    axios(email.includes("@") ? config : config2).then(({ data }) => {
      if (data.roles === "coallier") {
        const user = {
          method: "get",
          url: `https://backend-production-1bc8.up.railway.app/user/${data.id}`,
        };
        axios(user)
          .then(async ({ data }) => {
            //creating the cookie
            document.cookie = `Aroles=${data.roles[0].name}`;
            document.cookie = `Aapplied_projects=${data.applied_projects}`;
            document.cookie = `Aemail=${data.email}`;
            document.cookie = `Aform=${data.Coallier_Form}`;
            document.cookie = `Aprojects=${data.projects}`;
            document.cookie = `AprojectsConsultor=${data.projectsConsultor}`;
            document.cookie = `Atareas=${data.tareas}`;
            document.cookie = `tickets=${data.tickets}`;
            document.cookie = `signature=${data.signature}`;
            document.cookie = `casos_exito=${data.casos_exito}`;
            document.cookie = `kps=${data.kps}`;
            document.cookie = `certificados=${data.certificados}`;
            document.cookie = `nda=${data.nda}`;
            document.cookie = `Aname=${data.username}`;
            document.cookie = `calificaion_aptitudes=${data.calificaion_aptitudes}`;
            document.cookie = `Aid=${data.id}`;
            /*               await axios.get(`https://backend-production-1bc8.up.railway.app/user/CVprofile/${data.id}`)
                .then(r => document.cookie = `Acv=${JSON.stringify(r.data.cv_file)}`).catch(err => console.log(err)) */
            document.cookie = `Atoken=${data.accessToken}`;
            document.cookie = `ActualProject=${data.ActualProject}`;
            dispatch(loginReq(data)); //sended to login req and added to the state
          })
          .then(() => {
            if (!url) window.location.href = redirectUrl;
          })
          .catch(function (error) {
            console.log(error);
          });
      } else if (data.roles === "company") {
        const user = {
          method: "get",
          url: `https://backend-production-1bc8.up.railway.app/user/${data.id}`,
        };
        axios(user)
          .then(({ data }) => {
            document.cookie = `Aroles=${data.roles[0].name}`;
            document.cookie = `Aapplied_projects=${data.applied_projects}`;
            document.cookie = `Aform=${data.Company_Form}`;
            document.cookie = `Aemail=${data.email}`;
            document.cookie = `Aprojects=${data.projects}`;
            document.cookie = `AprojectsConsultor=${data.projectsConsultor}`;
            document.cookie = `Atareas=${data.tareas}`;
            document.cookie = `tickets=${data.tickets}`;
            document.cookie = `signature=${data.signature}`;
            document.cookie = `casos_exito=${data.casos_exito}`;
            document.cookie = `kps=${data.kps}`;
            document.cookie = `certificados=${data.certificados}`;
            document.cookie = `nda=${data.nda}`;
            document.cookie = `Aname=${data.username}`;
            document.cookie = `Aid=${data.id}`;
            document.cookie = `Atoken=${data.accessToken}`;
            document.cookie = `ActualProject=${data.ActualProject}`;
            dispatch(loginReq(data)); //sended to login req and added to the state
          })
          .then(() => {
            window.location.href = "/company/home";
          })
          .catch(function (error) {
            console.log(error);
          });
      } else {
        return null;
      }
    });
  };
};

export const loginEmpresa = ({ email, password }, redirectUrl) => {
  return (dispatch) => {
    const data = {
      email: email,
      password: password,
    };
    var config = {
      method: "post",
      url: "https://backend-production-1bc8.up.railway.app/api/auth/signin",
      data: data,
    };

    axios(config)
      .then(({ data }) => {
        //creating the cookie
        document.cookie = `Aemail=${data.email}`;
        document.cookie = `Aroles=${data.roles}`;
        document.cookie = `Aname=${data.username}`;
        document.cookie = `Aid=${data.id}`;
        document.cookie = `Atoken=${data.accessToken}`;
        const user = {
          method: "get",
          url: `https://backend-production-1bc8.up.railway.app/user/${data.id}`,
        };
        axios(user).then(({ data }) => {
          document.cookie = `Aform=${data.Coallier_Form}`;
          document.cookie = `Aprojects=${data.projects}`;
        });
        dispatch(loginReq(data)); //sended to login req and added to the state
      })
      .then(() => {
        window.location.href = redirectUrl;
      })

      .catch(function (error) {
        console.log(error);
      });
  };
};


export const getFormationModel = (userId) => {
  return dispatch => {
    axios.get(`https://rutaaprendizaje-production.up.railway.app/ruta_educativa/${userId}`, { timeout: 120000 })
      .then(res => {
        dispatch({
          type: "FORMATION_MODEL",
          payload: res.data.ruta
        })
      })
      .catch(error => {
        console.log(error);
      });
  };
};

export const clearFormationModel = () => ({
  type: "CLEAR_FORMATION_MODEL"
});


/**
 * @version 1.0
 * @author Nicolas Suarez (Coally)
 * This method dispatches the email request with the
 * token to change the password due to forgetfulness
 * @param {{email: example@gmail.com}} payload
 * @returns "code send your email" <- example
 */

export function sendMailRecovery(payload, lang) {
  return async function () {
    try {
      let response = await axios.post(
        `https://backend-production-1bc8.up.railway.app/api/auth/verifyemail?lang=${lang}`,
        payload
      );
      return response.data;
    } catch (error) {
      return error.response.data;
    }
  };
}
export function setPage(payload) {
  return {
    type: "SET_PAGE",
    payload,
  };
}
/* export function setProjectsv2(payload){
  return async function(){
    try{
      let response = await axios.get(`https://backend-production-1bc8.up.railway.app/api/Project?skip=${}&limit=1&status=En+espera`)
    }catch(error){
      console.log(error)
    }
  }
} */
/**
 *
 * This method is responsible for sending the otp
 * code and the new passwords together with the email to let the user start again
 * @param {{email, otp, pass,}} payload
 * @returns "New pass asigned" or "Error logical not found"
 */
export function sendNewPass(payload) {
  return async function () {
    try {
      let response = await axios.post(
        `https://backend-production-1bc8.up.railway.app/api/auth/verifyOTP`,
        payload
      );
      return response.data;
    } catch (error) {
      return error.response.data;
    }
  };
}

/**
 * Route edit CvUSer dispacth
 * @param {*} payload
 * @param {*} id
 * @param lang
 * @param userId
 * @returns
 */
export function sendEditUserCv(payload, id, lang, userId) {
  const newObject = {
    info_personal: {
      nombre: payload.info_personal.nombre ? payload.info_personal.nombre : "",
      profesion_actual: payload.info_personal.profesion_actual
        ? payload.info_personal.profesion_actual
        : "",
      sexo: payload.info_personal.sexo ? payload.info_personal.sexo : "",
      fecha_nacimiento: payload.info_personal.fecha_nacimiento
        ? payload.info_personal.fecha_nacimiento
        : "",
      pais_nacimiento: payload.info_personal.pais_nacimiento
        ? payload.info_personal.pais_nacimiento
        : "",
      departamento_nacimiento: payload.info_personal.departamento_nacimiento
        ? payload.info_personal.departamento_nacimiento
        : "",
      municipio_nacimiento: payload.info_personal.municipio_nacimiento
        ? payload.info_personal.municipio_nacimiento
        : "",
      ubicacion: payload.info_personal.ubicacion
        ? payload.info_personal.ubicacion
        : "",
    },
    contacto: [
      payload.contacto.email ? payload.contacto.email : "",
      payload.contacto.linkedin ? payload.contacto.linkedin : "",
      payload.contacto.telefono ? payload.contacto.telefono : "",
      payload.contacto.facebook ? payload.contacto.facebook : "",
      payload.contacto.instagram ? payload.contacto.instagram : "",
    ],
    aptitudes_principales: payload?.aptitudes_principales?.length
      ? payload?.aptitudes_principales?.map((e) => e.aptitud)
      : [],
    languages: payload.languages,
    certifications: payload?.certifications?.length
      ? payload?.certifications.map((e) => e.Certificacion)
      : [],
    extracto: payload?.extracto ? payload?.extracto : "",
    experiencia: payload.experiencia,
    educacion: payload.educacion,
    aspiracion_salarial: payload.aspiracion_salarial
      ? payload.aspiracion_salarial
      : "",
  };
  return async function () {
    toast
      .promise(
        axios.put(
          `https://backend-production-1bc8.up.railway.app/update/cv/${id}`,
          newObject
        ),
        {
          loading: lang === "es" ? "Guardando..." : "Saving...",
          success: lang === "es" ? "Guardado" : "Saved",
          error:
            lang === "es"
              ? "Error, intenta más tarde."
              : "Error, try again later.",
        }
      )
      .then(async () => {
        setTimeout(() => {
          window.location.href = `/consultor/profile/${userId}`;
        }, 3000);
      });
  };
}

export function joyrideStep(payload) {
  return {
    type: "JOYRIDE_STEP",
    payload,
  };
}
export function tourStatus(payload) {
  return {
    type: "TOUR_STATUS",
    payload,
  };
}
export function tourViewedStatus(payload) {
  return {
    type: "TOUR_VIEWED_STATUS",
    payload,
  };
}
export function tourViewedCompanyStatus(payload) {
  return {
    type: "TOUR_VIEWED_COMPANY_STATUS",
    payload,
  };
}

export function saveOportunityForm(payload, step) {
  const paso = {
    step: step,
  };
  console.log(payload);
  return {
    type: "SAVE_OPORTUNITY_FORM",
    payload,
    paso,
  };
}
export function cleanOportunityForm() {
  return {
    type: "CLEAN_OPORTUNITY_FORM",
  };
}

export function setDataUnilever(payload) {
  return {
    type: "SET_DATA_UNILEVER",
    payload,
  };
}

export function setErrorMessageUnilever(payload) {
  return {
    type: "SET_ERROR_MESSAGE_UNILEVER",
    payload,
  };
}

export { logoutReq as default };
