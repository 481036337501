/*eslint-disable*/
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { newLoginUser } from "../../../actions";
import { toast } from 'react-toastify';
import Spinner from 'react-bootstrap/Spinner'
import logoUnilever from '../../../assets/logos/Unilever-Azul.png';
import './LoginAdminUnilever.css';

export default function LoginAdminUnilever () {

  const user = useSelector((state) => state.user);
  const error = useSelector((state) => state.Error)
  const dispatch = useDispatch();
  const history = useHistory();

  let [userLog, setUserLog] = useState({
    email: "",
    password: "",
  });
  let [cargando, setCargando] = useState(false);

  const handleOnChange = (e) => {
    e.preventDefault();
    setUserLog({
      ...userLog,
      [e.target.id]: e.target.value,
    });
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    setCargando(true);
    dispatch(newLoginUser(userLog));
  }

  useEffect(() => {
    if (user.roles === "company") {
      history.push("/unilever/admin");
    }
    if (user.roles === "coallier") {
      history.push("/unilever/admin");
    }
  }, [user.id]);

  useEffect(() => {
    if (error.message) {
      toast.error('Email o contraseña incorrectos.', {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
    setCargando(false);
  }, [error]);

  return(
    <div className='loginadmin'>
      <img className={'loginadmin__logo'} src={logoUnilever} alt={'Logo Unilever'} />
      <form className='loginadmin__form'>
        <label className='loginadmin__label' htmlFor='email'>
          Correo electrónico
          <input
            className='loginadmin__input'
            onChange={handleOnChange}
            id='email'
            type='text'
            placeholder='Correo electrónico'
            />
        </label>
        <label className='loginadmin__label' htmlFor='clave'>
          Contraseña
          <input
            className='loginadmin__input'
            onChange={handleOnChange}
            id='password'
            type='password'
            placeholder='Contraseña'
            />
        </label>
        <button
          onClick={handleSubmit}
          className='loginadmin__button'
          type='submit'
          disabled={!userLog.email.includes('@') || !userLog.email.includes('.') || !userLog.password ? true : false}>
          {cargando ? <Spinner animation="border" size="sm" /> : 'Iniciar sesión'}
        </button>
      </form>
    </div>
  );
}
