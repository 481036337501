/*eslint-disable*/
import React from "react";
import PlaceOutlinedIcon from '@mui/icons-material/PlaceOutlined';
import './cardsLinked.css'
import { format } from "date-fns";
import { es } from "date-fns/locale";
import { useSelector } from "react-redux";




const SmallCardLinked = ({ image, title, company, place, date, active, aplicantes, modalidad }) => {

  const lang = useSelector(state => state.lang)

  function tiempoTranscurrido(fechaStr) {
    const fecha = new Date(fechaStr);
    const ahora = new Date();
    const diferencia = ahora.getTime() - fecha.getTime();
    const segundos = Math.floor(diferencia / 1000);
    const minutos = Math.floor(segundos / 60);
    const horas = Math.floor(minutos / 60);
    const dias = Math.floor(horas / 24);
    const meses = Math.floor(dias / 30);
    const años = Math.floor(meses / 12);

    if (lang === 'en') {
      if (años > 0) {
        return `${años} year${años > 1 ? 's' : ''} ago`;
      } else if (meses > 0) {
        return `${meses} month${meses > 1 ? 's' : ''} ago`;
      } else if (dias > 0) {
        return `${dias} day${dias > 1 ? 's' : ''} ago`;
      } else if (horas > 0) {
        return `${horas} hour${horas > 1 ? 's' : ''} ago`;
      } else if (minutos > 0) {
        return `${minutos} minute${minutos > 1 ? 's' : ''} ago`;
      } else {
        return 'A few seconds ago';
      }
    } else {
      if (años > 0) {
        return `Hace ${años} año${años > 1 ? 's' : ''}`;
      } else if (meses > 0) {
        return `Hace ${meses} mes${meses > 1 ? 'es' : ''}`;
      } else if (dias > 0) {
        return `Hace ${dias} día${dias > 1 ? 's' : ''}`;
      } else if (horas > 0) {
        return `Hace ${horas} hora${horas > 1 ? 's' : ''}`;
      } else if (minutos > 0) {
        return `Hace ${minutos} minuto${minutos > 1 ? 's' : ''}`;
      } else {
        return 'Hace unos segundos';
      }
    }
  }

  const modalidadDeTrabajoTraducir = (modalidad) => {
    if (modalidad === 'Presencial') {
      return lang === 'es' ? 'Presencial' : 'On-site'
    } else if (modalidad === 'Remoto') {
      return lang === 'es' ? 'Remoto' : 'Remote'
    } else if (modalidad === 'Híbrida') {
      return lang === 'es' ? 'Híbrida' : 'Hybrid'
    }
  }


  return (
    <div className={active} style={{ position: 'relative', cursor: 'pointer' }}>
      <div style={{
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        flexDirection: 'row',
        padding: '0.8rem',
        width: '100%',
      }}>
        <div style={{
          display: 'flex',
          justifyContent: 'flex-start',
          alignItems: 'flex-start',
          width: '56px',
          height: '56px',
          overflow: 'hidden',
          position: 'relative',
        }}>
          <img className={'small-card-linked-image'} src={image ? image : ''} alt={image ? image : ''} />
        </div>
        <div style={{
          display: 'flex',
          justifyContent: 'flex-start',
          flexDirection: 'column',
          alignItems: 'flex-start',
          width: '100%',
          paddingLeft: '20px',
        }}>
          <h1 style={{
            fontSize: '16px',
            fontWeight: '700',
            color: active === 'small-card-linked-container-active' ? '#fff' : '#393939',
            textAlign: 'left',
            width: '100%',
          }}>
            {title}
          </h1>
          <h1 style={{
            fontSize: '16px',
            fontWeight: '400',
            color: active === 'small-card-linked-container-active' ? '#fff' : '#393939',
            margin: 0,
          }}>
            {company}
          </h1>
          {
            place ? (
              <div style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
                width: '100%',
                textAlign: 'center',
                marginTop: '10px',
              }}>
                <PlaceOutlinedIcon style={{ color: active === 'small-card-linked-container-active' ? '#fff' : '#C5C5C5', fontSize: '20px' }} />
                <div style={{
                  display: 'flex',
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                  width: '90%',
                  height: '100%',
                  color: active === 'small-card-linked-container-active' ? '#fff' : '#C5C5C5',
                  fontSize: '16px',
                  marginLeft: '15px',
                }}>
                  {place}
                </div>
              </div>
            ) : null
          }
          <div className="small-card-linked-container-babag">
            <div style={{
              display: 'flex',
              justifyContent: 'flex-start',
              flexDirection: 'row',
              alignItems: 'center',
            }}>
              <p style={{
                color: '#24D054',
                fontSize: '14px',
                fontWeight: '400',
                margin: 0,
              }}>
                {lang === 'es' ? 'Abierta' : 'Open'}
              </p>
              <p style={{
                color: active === 'small-card-linked-container-active' ? '#fff' : '#5D5D5D',
                fontSize: '14px',
                fontWeight: '400',
                marginLeft: '5px',
                marginRight: '5px',
                marginBottom: 0,
              }}>•</p>
              {modalidad &&
                <>
                  <p style={{
                    color: active === 'small-card-linked-container-active' ? '#fff' : '#5D5D5D',
                    fontSize: '14px',
                    fontWeight: '400',
                    margin: 0,
                  }}>
                    {modalidadDeTrabajoTraducir(modalidad)}
                  </p>
                  <p style={{
                    color: active === 'small-card-linked-container-active' ? '#fff' : '#5D5D5D',
                    fontSize: '14px',
                    fontWeight: '400',
                    marginLeft: '5px',
                    marginRight: '5px',
                    marginBottom: 0,
                  }}>•</p>
                </>
              }
              <p style={{
                color: active === 'small-card-linked-container-active' ? '#fff' : '#5D5D5D',
                fontSize: '14px',
                fontWeight: '400',
                margin: 0,
              }}>
                {aplicantes} {aplicantes === 1 ? lang === 'es' ? 'aplicante' : 'applicant' : lang === 'es' ? 'aplicantes' : 'applicants'}
              </p>
            </div>
            <h1 style={{
              fontSize: '14px',
              fontWeight: '400',
              color: active === 'small-card-linked-container-active' ? '#fff' : '#C5C5C5',
              paddingTop: '10px',
            }}>
              {tiempoTranscurrido(date)}
            </h1>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SmallCardLinked;
