import React, { useRef, useEffect, useState } from "react";
import './chart1.css'
import { Bar } from 'react-chartjs-2';
import Chart from 'chart.js/auto';

const Chart1 = ({ stages }) => {
  const data = {
    labels: ['Aplicando', 'En entrevista', 'En pruebas', 'Contratación'],
    datasets: [
      {
        label: 'Aplicantes',
        data: [stages?.etapa_aplicante, stages?.etapa_entrevistado, stages?.etapa_prueba, stages?.etapa_contratado],
        backgroundColor: 'rgba(54, 162, 235, 0.2)',
        borderColor: 'rgba(54, 162, 235, 1)',
        borderWidth: 1,
      },
    ],
  };

  const options = {
    scales: {
      y: {
        beginAtZero: true,
        max: 100,
      },
    },
    plugins: {
      legend: {
        position: 'bottom',
      },
    },
  };

  return (
    <div>
      <Bar style={{
        width: '100%',
      }} data={data} options={options} />
    </div>
  )
}

export default Chart1;

export const PaginatedBarChart = ({ careers }) => {
  const [page, setPage] = useState(0);
  const itemsPerPage = 10;

  const chartRef = useRef(null);

  const truncateLabel = (label, maxLength) => {
    if (label.length > maxLength) {
      return `${label.substring(0, maxLength)}...`;
    }
    return label;
  }


  useEffect(() => {
    const myChartRef = chartRef.current.getContext('2d');
    let myChart = null;
    if (myChartRef.chart) {
      myChart = myChartRef.chart;
      myChart.destroy();
    }

    const start = page * itemsPerPage;
    const end = start + itemsPerPage;

    const labels = careers.slice(start, end).map(career => truncateLabel(career, 20));

    myChart = new Chart(myChartRef, {
      type: 'bar',
      data: {
        labels: labels,
        datasets: [
          {
            label: 'Aplicantes',
            data: Array.isArray(careers) ? careers.slice(start, end) : [careers?.count],
            backgroundColor: 'rgba(54, 162, 235, 0.2)',
            borderColor: 'rgba(54, 162, 235, 1)',
            borderWidth: 1,
          },
        ],
      },
      options: {
        scales: {
          y: {
            beginAtZero: true,
            max: 100,
          },
        },
        plugins: {
          legend: {
            position: 'bottom',
          },
        },
      },
    });

    myChartRef.chart = myChart;
  }, [page, careers]);

  const nextPage = () => {
    if (page < Math.floor(careers.length / itemsPerPage)) {
      setPage(page + 1);
    }
  }

  const prevPage = () => {
    if (page > 0) {
      setPage(page - 1);
    }
  }

  return (
    <div style={{
      width: '100%',
    }}>
      <canvas id="myChart" ref={chartRef} />
      <div style={{
        display: 'flex',
        justifyContent: 'space-between',
      }}>
        <button onClick={prevPage}>Página anterior</button>
        <button onClick={nextPage}>Página siguiente</button>
      </div>
    </div>
  );
};


export const Chart2 = ({ fecha, cant }) => {
  const data = {
    labels: [fecha],
    datasets: [
      {
        label: 'Oportunidades',
        data: [cant?.data?.length],
        backgroundColor: 'rgba(54, 162, 235, 0.2)',
        borderColor: 'rgba(54, 162, 235, 1)',
        borderWidth: 1,
      },
    ],
  };

  const options = {
    scales: {
      y: {
        beginAtZero: true,
        max: 100,
      },
    },
    plugins: {
      legend: {
        position: 'bottom',
      },
    },
  };

  return (
    <div>
      <Bar style={{
        width: '100%',
      }} data={data} options={options} />
    </div>
  )
}


export const BarChart = ({ name, careers }) => {  
  const chartRef = useRef(null); 

  useEffect(() => {
    const myChartRef = chartRef.current.getContext('2d');
    let myChart = null;
    if (myChartRef.chart) {
      myChart = myChartRef.chart;
      myChart.destroy();
    }
    myChart = new Chart(myChartRef, {
      type: 'bar',
      data: {
        labels: Array.isArray(name) ? name : [name],
        datasets: [
          {
            label: 'Aplicantes',
            data: Array.isArray(careers) ? careers : [careers?.count],
            backgroundColor: 'rgba(54, 162, 235, 0.2)',
            borderColor: 'rgba(54, 162, 235, 1)',
            borderWidth: 1,
          },
        ],
      },
      options: {
        scales: {
          y: {
            beginAtZero: true,
            max: 100,
          },
        },
        plugins: {
          legend: {
            position: 'bottom',
          },
        },
      },
    });

    myChartRef.chart = myChart;
  }, [name, careers]);

  return (
    <div>
      <canvas id="myChart" ref={chartRef} />
    </div>
  );
};