import React, { useContext } from "react";
import { usePopupContext } from "../../../../../context/popups/PopupContext";
import {
  ChatGptContainer,
  BannerTextH4,
  BannerTextH1,
  BannerTextContainer,
  BannerButtonContainer,
  BannerButton,
} from "./ChatGptStyles";
import { useSelector } from "react-redux";
import { SchneiderContext } from "../../../../../context/SchneiderContext";
import { ReduxStateInterface } from "../../../../../types/reduxInterface";

const ChatGptBanner = () => {
  const { lang } = useSelector((state: ReduxStateInterface) => state);
  const { addPopup, removePopup } = usePopupContext();
  const { schneider } = useContext(SchneiderContext);
  const handleOpenChatGptPopup = () => {
    addPopup({
      id: "chat-gpt",
      content: (
        <div>
          <h1>Chat GPT</h1>
        </div>
      ),
      onClose: () => removePopup({ id: "chat-gpt" }),
    });
  };

  return (
    <ChatGptContainer>
      <BannerTextContainer>
        <BannerTextH4>
          {lang === "es"
            ? "Inteligencia artificial de"
            : "Artificial intelligence of"}
        </BannerTextH4>
        <BannerTextH1>{lang === "es" ? "ChatGPT" : "ChatGPT"}</BannerTextH1>
      </BannerTextContainer>

      <BannerButtonContainer color={schneider.color.primary}>
        <BannerButton onClick={handleOpenChatGptPopup}>
          {lang === "es" ? "Conoce más" : "Know more"}
        </BannerButton>
      </BannerButtonContainer>
    </ChatGptContainer>
  );
};

export default ChatGptBanner;
