/* eslint-disable */
import React, { useState, useContext } from 'react'
import { useDispatch } from 'react-redux'
import './newRegister.css'
import { useHistory, Link } from 'react-router-dom'
import Select from 'react-select'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import VisibilityIcon from '@mui/icons-material/Visibility'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'
import Spinner from 'react-bootstrap/Spinner'
import { changeLang, registerUserUniversity } from '../../actions'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import Button from '@mui/material/Button'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import Edit from '../../assets/imagenes/edit.svg'
import TermsAndConditions from '../../components/termsAndConditions/TermsAndConditions'
import { UniversityContext } from '../context/UniversitiesContext'
import {
  AuthGlobalContainer,
  AuthFormContainer,
  AuthButton,
  AuthButtonContainer,
  AuthButtonDisabled,
  AuthFooterContainer,
  AuthLink,
  AuthFooterText,
  AuthLogoUniv,
  LanguageContainer,
  LanguageButton,
  AuthTitle,
  AuthInputContainer,
  AuthInput,
  AuthInputSubContainer,
  AuthInputIconContainer
} from '../../schneider/pages/auth/AuthStyles'

const customStyles = {
  // @ts-ignore
  option: (provided, state) => ({
    ...provided,
    color: state.isSelected ? 'white' : 'black',
    backgroundColor: state.isSelected ? '#202256' : 'white',
    cursor: 'pointer',
    padding: 10
  }),
  // @ts-ignore
  control: (provided, state) => ({
    ...provided,
    width: '250px',
    display: 'flex',
    alignItems: 'flex-start',
    padding: '0',
    justifyContent: 'center',
    borderRadius: '8px',
    backgroundColor: state.isSelected ? '#202256' : '#F5F5F5',
    color: 'white'
  }),
  // @ts-ignore
  menu: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? '#202256' : 'white',
    color: 'white',
    padding: '0',
    marginTop: '-1em',
    width: '250px'
  })
}

interface user {
  roles: string
  username: string
  email: string
  password: string
  phoneNumber: string
  num_id: string
  Schneider: boolean
}

export default function Register(): JSX.Element {
  const { university } = useContext(UniversityContext)
  const [user, setUser] = useState<user>({
    roles: '',
    username: '',
    email: '',
    password: '',
    phoneNumber: '',
    num_id: '',
    Schneider: true
  })
  const router = useHistory()
  const dispatch: any = useDispatch()
  const [localLang, setLocalLang] = useState(localStorage.getItem('langlocal'))
  const [cargando, setCargando] = useState<boolean>(false)
  const [passwordShown, setPasswordShown] = useState<boolean>(false)
  const [checked, setChecked] = useState<boolean>(false)
  const [popUp, setPopUp] = useState<boolean>(false)

  const handleCheck = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked)
  }

  const handleSubmit = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault()
    setCargando(true)
    if (
      !user.username ||
      !user.email ||
      !user.password ||
      !user.phoneNumber ||
      !user.roles
    ) {
      setCargando(false)
      return localLang === 'en'
        ? toast.error('All fields are required')
        : toast.error('Todos los campos son obligatorios')
    }
    if (user.roles === 'coallier' && user.num_id === '') {
      setCargando(false)
      return localLang === 'en'
        ? toast.error('Please enter your identity number')
        : toast.error('Por favor ingrese su número de identidad')
    }
    dispatch(registerUserUniversity(user, localLang, university.name))
      .then((res: any) => {
        router.push(`/${university.route}/verify`)
        setCargando(false)
      })
      .catch((err: any) => {
        console.log(err)
        setCargando(false)
      })
  }

  const togglePassword = () => {
    setPasswordShown(!passwordShown)
  }

  const roles = [
    {
      value: 'coallier',
      label: (
        <span>
          {localLang === 'es'
            ? 'Profesional o graduado'
            : 'Professional or graduate'}
        </span>
      )
    },
    {
      value: 'coaliier estudiante',
      label: <span>{localLang === 'es' ? 'Estudiante' : 'Student'}</span>
    },
    {
      value: 'company',
      label: <span>{localLang === 'es' ? 'Empresa' : 'Company'}</span>
    }
  ]

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault()
    setUser({
      ...user,
      [e.target.name]: e.target.value
    })
    console.log(user)
  }

  const changeLanguages = (lang: string) => {
    dispatch(changeLang(lang))
    localStorage.setItem('langlocal', lang)
    setLocalLang(localStorage.getItem('langlocal'))
  }

  return (
    <>
      {university && university?.color && (
        <AuthGlobalContainer>
          <AuthFormContainer>
            <AuthLogoUniv src={university.logo} alt='logo' color='#5D5D5D' />
            <LanguageContainer>
              <LanguageButton
                onClick={() => changeLanguages('es')}
                color={
                  localLang === 'es' ? university.color.primary : '#000000'
                }
                weight={localLang === 'es' ? 700 : 300}
              >
                SP
              </LanguageButton>
              <LanguageButton
                onClick={() => changeLanguages('en')}
                color={
                  localLang === 'en' ? university.color.primary : '#000000'
                }
                weight={localLang === 'en' ? 700 : 300}
              >
                EN
              </LanguageButton>
            </LanguageContainer>

            <div style={{ width: '100%' }}>
              <AuthTitle color={university.color.primary}>
                {localLang === 'es' ? '¡Regístrate!' : 'Sign up!'}
              </AuthTitle>
            </div>

            <div style={{ width: '100%', alignItems: 'flex-start' }}>
              <p
                style={{
                  color: '#5D5D5D',
                  fontWeight: '400',
                  fontSize: '14px',
                  marginTop: '1em'
                }}
              >
                {localLang === 'es' ? 'Tipo de usuario' : 'Type of user'}
              </p>
            </div>
            <div style={{ width: '100%', padding: '0', marginTop: '-1.2em' }}>
              <Select
                className='ConsultorFor-input2 mediumfont'
                classNamePrefix='select'
                defaultValue={''}
                isDisabled={false}
                styles={customStyles}
                isLoading={false}
                isSearchable={true}
                name='roles'
                options={roles}
                onChange={(e: any) => {
                  setUser({
                    ...user,
                    roles: e.value
                  })
                }}
              />
            </div>

            <AuthInputContainer>
              <AuthInputSubContainer>
                <AuthInput
                  type='text'
                  name={'username'}
                  placeholder={localLang === 'es' ? 'Nombre' : 'Name'}
                  onChange={(e: any) => handleChange(e)}
                />
                <AuthInputIconContainer>
                  <img src={Edit} alt='editar' />
                </AuthInputIconContainer>
              </AuthInputSubContainer>
            </AuthInputContainer>

            <AuthInputContainer>
              <AuthInputSubContainer>
                <AuthInput
                  type='text'
                  name={'email'}
                  placeholder={localLang === 'es' ? 'Correo' : 'Email'}
                  onChange={(e: any) => handleChange(e)}
                />
                <AuthInputIconContainer>
                  <img src={Edit} alt='editar' />
                </AuthInputIconContainer>
              </AuthInputSubContainer>
            </AuthInputContainer>

            <AuthInputContainer>
              <AuthInputSubContainer>
                <PhoneInput
                  country={'us'}
                  regions={'america'}
                  enableLongNumbers={true}
                  countryCodeEditable={false}
                  placeholder={localLang === 'es' ? 'Teléfono' : 'Phone number'}
                  inputStyle={{
                    border: '1px solid #C5C5C5',
                    borderRadius: '0px',
                    width: '100%',
                    fontWeight: '400',
                    height: '41px',
                    fontSize: '16px'
                  }}
                  onChange={(phone, country, event, formattedValue) => {
                    setUser({
                      ...user,
                      phoneNumber: formattedValue
                    })
                  }}
                />
              </AuthInputSubContainer>
            </AuthInputContainer>

            <AuthInputContainer>
              <AuthInputSubContainer>
                <AuthInput
                  type='text'
                  name={'num_id'}
                  placeholder={
                    localLang === 'es' ? 'Número de identidad' : 'Id number'
                  }
                  onChange={(e: any) => handleChange(e)}
                />
                <AuthInputIconContainer>
                  <img src={Edit} alt='editar' />
                </AuthInputIconContainer>
              </AuthInputSubContainer>
            </AuthInputContainer>

            <AuthInputContainer>
              <AuthInputSubContainer>
                <AuthInput
                  type={passwordShown ? 'text' : 'password'}
                  name={'password'}
                  placeholder={localLang === 'es' ? 'Contraseña' : 'Password'}
                  onChange={(e: any) => handleChange(e)}
                />
                <AuthInputIconContainer>
                  <button
                    style={{
                      background: 'transparent',
                      borderRadius: '0px 1.5rem 1.5rem 0px'
                    }}
                    onClick={() => togglePassword()}
                  >
                    {passwordShown ? <VisibilityOffIcon /> : <VisibilityIcon />}
                  </button>
                </AuthInputIconContainer>
              </AuthInputSubContainer>
            </AuthInputContainer>

            <div
              className='terms-condition'
              style={{ width: '100%', display: 'flex', marginTop: '5%' }}
            >
              <label className='b-contain'>
                <a onClick={() => setPopUp(true)} className='buttoooon'>
                  {localLang === 'es'
                    ? 'Acepto términos y condiciones'
                    : 'I accept terms and conditions'}
                </a>
                <input
                  name='radio'
                  type='radio'
                  checked={checked}
                  style={{ height: '1px', width: '1px' }}
                  onChange={(e: any) => {
                    handleCheck(e)
                  }}
                  onClick={() => setChecked(!checked)}
                />
                <div className='b-input' />
              </label>
            </div>

            <AuthButtonContainer>
              {checked ? (
                <AuthButton
                  background={university.color.primary}
                  onClick={(e: any) => handleSubmit(e)}
                >
                  {cargando ? (
                    <Spinner animation='border' size='sm' />
                  ) : localLang === 'es' ? (
                    'Regístrate'
                  ) : (
                    'Sign up'
                  )}
                </AuthButton>
              ) : (
                <AuthButtonDisabled>
                  {localLang === 'es' ? 'Regístrate' : 'Sign up'}
                </AuthButtonDisabled>
              )}
            </AuthButtonContainer>

            <AuthFooterContainer>
              <AuthFooterText color={university.color.tertiary}>
                {localLang === 'es'
                  ? 'Si ya tienes cuenta, puedes'
                  : 'If you already have an account,'}
              </AuthFooterText>
              <Link to={`/${university.route}/`}>
                <AuthLink color={university.color.primary} weight={600}>
                  {localLang === 'es' ? 'Inicia sesión' : 'Login'}
                </AuthLink>
              </Link>
            </AuthFooterContainer>
          </AuthFormContainer>
        </AuthGlobalContainer>
      )}
      {popUp && (
        <Dialog
          style={{}}
          open={popUp}
          onClose={() => setPopUp(false)}
          aria-labelledby='scroll-dialog-title'
          aria-describedby='scroll-dialog-description'
        >
          <DialogTitle id='scroll-dialog-title'>
            {localLang === 'es'
              ? 'Acepto los terminos y condiciones'
              : 'I accept the terms and conditions'}{' '}
          </DialogTitle>
          <DialogContent>
            <TermsAndConditions />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setPopUp(false)}>
              {localLang === 'es' ? 'Cerrar' : 'Close'}
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  )
}
