import {useEffect, useState} from 'react';
import axios from 'axios';
import {useSelector} from "react-redux";
import {ReduxStateInterface} from "../../../../types/reduxInterface";



export const useDashboardData = (id: string) => {
  const { lang } = useSelector((state: ReduxStateInterface) => state)
  const [oportunidad, setOportunidad] = useState(null);
  const [profesionales1, setProfesionales1] = useState(null);
  const [aplicantes, setAplicantes] = useState(null);
  const [enpruebas, setEnpruebas] = useState(null);
  const [seleccionados, setSeleccionados] = useState(null);
  const [ocultos, setOcultos] = useState(null);
  const [loadnum, setLoadnum] = useState(6);
  const [readyQueryMl, setReadyQueryMl] = useState(false);
  const [loading, setLoading] = useState(false);
  const [usuariosRecomendados, setUsuariosRecomendados] = useState(null);


  useEffect(() => {
    axios.get('https://findally-backend.herokuapp.com/api/Project/' + id)
      .then((res: any) => {
        console.log(res)
        setOportunidad(res.data)
        return res?.data?.hiddenAplicants?.length ?
          axios.get(`https://findally-backend.herokuapp.com/api/applicants/hidden/${id}`)
            .then((res: any) => {
              // console.log(res)
              return setOcultos(res.data.data)
            })
            .catch(err => {
              return console.log(err)
            })
          : null
      })
      .catch(err => {
        console.log(err)
      })
      .catch(err => {
        console.log(err)
      })
    axios.get('https://findally-backend.herokuapp.com/api/Project/selected/' + id)
      .then(res => {
        setEnpruebas(res.data)
        // console.log('setEnpruebas', res.data)
      })
      .catch(err => {
        console.log(err)
      })
    axios.post('https://findally-backend.herokuapp.com/api/applicants/find/all/contacted/coalliers', { proyecto: id })
      .then(res => {
        setAplicantes(res.data)
        // console.log('setAplicantes', res.data)
      })
      .catch(err => {
        console.log(err)
      })
    axios.get('https://findally-backend.herokuapp.com/api/applicants/choosen/' + id)
      .then(res => {
        setSeleccionados(res.data)
        // console.log('setSeleccionados', res.data)
      })
      .catch(err => {
        console.log(err)
      })
  }, [id])

  useEffect(() => {
    if (!oportunidad || !oportunidad.aplicantes || oportunidad.aplicantes.length === 0) {
      return;
    }
    setLoading(true);
    axios.get('https://findally-backend.herokuapp.com/api/Project/match/recomendation/' + id)
      .then((res: any) => {
        setProfesionales1(res.data)
        const empleoData = {
          ID: oportunidad._id,
          Descripcion: oportunidad?.DescribeProyecto || '',
          Experiencia: oportunidad?.experienciaAnos ? `${oportunidad.experienciaAnos} años` : '',
          Habilidades_tecnicas: oportunidad?.habilidadesTecnicas?.join(', ') || '',
          Habilidades_Blandas: oportunidad?.habilidadesBlandas?.join(', ') || '',
          Idiomas: oportunidad?.Lenguaje?.join(', ') || '',
          Salario: oportunidad?.presupuesto || '',
          Beneficios: oportunidad?.listadoBeneficios?.join(', ') || '',
          Tipo_de_empleo: oportunidad?.modalidadDeContratacion || '',
          Modalidad: oportunidad?.modalidadDeTrabajo || '',
        };

        const aplicantesData = res?.data?.applicants?.map((aplicante: any) => {
          return {
            ID: aplicante.cv_file._id,
            Descripcion: aplicante.cv_file?.extracto || '',
            Experiencia: aplicante.cv_file?.experiencia?.flatMap((exp: any) => {
              return exp.cargos?.map((cargo: any) => {
                return {
                  nombre_cargo: cargo.nombrecargo ? cargo.nombrecargo : '',
                  duracion: cargo.duracion ? cargo.duracion : '',
                  ubicacion: cargo.ubicacion ? cargo.ubicacion : '',
                  descripcion: cargo.descripcion ? cargo.descripcion : '',
                };
              }) || []
            }) || [],
            Estudios: oportunidad?.educacion?.map((edu: any) => edu.Titulo_Certificacion).join(", ") || '',
            Habilidades_tecnicas: aplicante.cv_file?.aptitudes_principales?.join(', ') || '',
            Habilidades_Blandas: '',
            Logros: '',
            Trabajo_Social: '',
            Universidad: '',
            Idiomas: aplicante.cv_file?.languages?.map((idioma: any) => idioma.Language).join(", ") || '',
            Salario: aplicante.cv_file?.aspiracion_salarial || '',
            Tipo_de_empleo: '',
            Modalidad: '',
            Puntaje_hr: 0
          };
        });

        const postData = { empleo: empleoData, aplicantes: aplicantesData };
        axios.post('https://resume-matching-deploy.herokuapp.com/aplicantes-empleo', postData, { timeout: 3 * 60 * 1000 })
          .then((res: any) => {
            const { response } = res.data;
            const result = {};
            //recorre la respuesta y va agregando los fits a los objetos
            for (const key in response) {
              const value = response[key];
              if (typeof value === "object" && value !== null) {
                const fitKey = "fit" + key;
                if (fitKey in response) {
                  value.fit = response[fitKey];
                }
                // @ts-ignore
                result[key] = value;
              }
            }
            // iterar sobre result para buscar cada usuario en el estado y agregarle el fit
            setProfesionales1((prev: any) => {
              // Copiar el estado anterior para evitar mutar el estado original
              const newState = [...prev.applicants];

              // Recorrer cada usuario en el nuevo objeto result
              for (const id in result) {
                // Buscar el índice del usuario con la id correspondiente
                // @ts-ignore
                const userIndex = newState.findIndex(user => user.cv_file._id === result[id].ID);

                // Si se encontró el usuario, agregarle el fit
                if (userIndex !== -1) {
                  // @ts-ignore
                  newState[userIndex].fit = result[id].fit;
                }
              }

              // Retornar el nuevo estado actualizado
              return { ...prev, applicants: newState };
            })
            setLoading(false)
            setReadyQueryMl(true)
            console.log(result);
          })
      })
      .catch(err => {
        setLoading(false)
        console.log(err);
      });
  }, [oportunidad, id]);


  const redondeo = (num: number) => {
    console.log(num)
    return Math.floor(num)
  }

  //Descargar aplicantes

  const headers = [
    { label: "Nombre", key: "cv_file.info_personal.nombre" },
    { label: "Email", key: "email" },
    { label: "Profesión", key: "cv_file.info_personal.profesion_actual" },
    { label: "Ubicación", key: "cv_file.info_personal.ubicacion" },
    { label: "Contacto", key: "cv_file.contacto" },
  ]

  const csvReport = {
    filename: 'Reporte de aplicantes.csv',
    headers: headers,
    data: profesionales1?.applicants ? profesionales1?.applicants : []
  };

  useEffect(() => {
    if (!oportunidad || !oportunidad.aplicantes || oportunidad.aplicantes.length === 0) {
      return;
    }
    oportunidad && axios.post('https://resume-matching-deploy.herokuapp.com/n-mejores-perfiles', {
      n: 6,
      ID: oportunidad?._id || "",
      Descripcion: oportunidad?.DescribeProyecto || "",
      Experiencia: oportunidad?.experienciaAnos || "",
      Habilidades_tecnicas: oportunidad?.habilidadesTecnicas?.join(', ') || '',
      Habilidades_Blandas: oportunidad?.habilidadesBlandas?.join(', ') || '',
      Idiomas: oportunidad?.Lenguaje?.join(', ') || '',
      Salario: oportunidad?.presupuesto ? oportunidad?.presupuesto : '',
      Beneficios: oportunidad?.listadoBeneficios?.join(', ') || '',
      Tipo_de_empleo: oportunidad?.modalidadDeContratacion || '',
      Modalidad: oportunidad?.modalidadDeTrabajo || ''
    }, { timeout: 3 * 60 * 1000 })
      .then((res: any) => {
        const { response } = res.data;
        const result = [];
        //recorre la respuesta y va agregando los fits a los objetos
        for (const key in response) {
          const value = response[key];
          if (typeof value === "object" && value !== null) {
            const fitKey = "fit" + key;
            if (fitKey in response) {
              value.fit = response[fitKey];
            }
            result.push(value);
          }
        }
        setUsuariosRecomendados(result)
      })
      .catch(err => console.log(err))
  }, [oportunidad])

  return {
    loading,
    profesionales1,
    setProfesionales1,
    redondeo,
    csvReport,
    usuariosRecomendados,
    readyQueryMl,
    setReadyQueryMl,
    aplicantes,
    enpruebas,
    seleccionados,
    ocultos,
    loadnum,
    setLoadnum,
    lang,
    oportunidad
  }
}
