import styled from "styled-components";


export const SelectApplicantButtonList = styled.button<{color?: string}>`
  margin-left: 20px;
  background-color: ${(props) => (props.color ? props.color : "#f07130")};
  border-radius: 5px;
  color: #FFFFFF;
  font-size: 14px;
  font-weight: 400;
  padding-right: 20px;
  padding-left: 20px;
`
