import React from 'react'
import './cards.css'

export default function OportunidadIdiomaCard({user,cv , lang}){
    
    return(
        <div className='Perfil-container-cards-coally'>
            {/* <div className='oportunidad-cantidad'>
                <p className='title-op'>{ lang === 'es' ? 'Oportunidades completadas ': 'Finished opportunities'}</p>
                <p className='text-contador'>20</p>
            </div>
                <hr className='herre'/> */}

            <div className='innerCards'>
                <p className='title-op'>{lang === 'es'? 'Idiomas' : 'Languages'}</p>
                <div className='idiomas-container'>
                    {
                        cv?.languages&&
                        cv?.languages.map((el,index)=> (<p key={index}>{el.Language}</p>))
                    }
                </div>
            </div>
                <hr className='herre'/>

            <div className='innerCards'>
                <p className='title-op'>{lang === 'es'? 'Habilidades' : 'Skills'}</p>
                <div>
                    {
                        <div>
                        {
                            cv?.aptitudes_principales&&
                            cv?.aptitudes_principales.map((el, index)=> (<p key={index}>{el}</p>))
                        }
                    </div>
                    }
                </div>
            </div>
        </div>
    )
}
