import styled from "styled-components"


const AuthGlobalContainer = styled.div<{background?: string}>`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100vh;
`

const AuthFormContainer = styled.form`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 450px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    padding: 20px;

    @media (max-width: 460px) {
        width: 100%;
        border-radius: 0;
        box-shadow: none;
    }
`

const AuthLogo = styled.img`
    width: 100%;
    margin-bottom: 10px;
    // aca pueden haber problemas con el responsive
    @media (max-width: 425px) {
        width: 60%;
    }
`

function hexToRGBA(hex: string, opacity: number): string {
    // Remover el símbolo # del valor hexadecimal
    hex = hex.replace("#", "");

    // Convertir los componentes de color de hexadecimal a decimal
    const r = parseInt(hex.substring(0, 2), 16);
    const g = parseInt(hex.substring(2, 4), 16);
    const b = parseInt(hex.substring(4, 6), 16);

    // Devolver el valor RGBA con la opacidad especificada
    return `rgba(${r}, ${g}, ${b}, ${opacity})`;
  }

const AuthLogoUniv = styled.img<{color?: string}>`
background-color: ${(props) => (props.color ? hexToRGBA(props.color, 0.5) : "#d6d6d6")};
width: 55%;
padding: 10px;
border-radius: 5px;
margin-bottom: 10px;
box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);

@media (max-width: 425px) {
    width: 60%;
}
`

const LanguageContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    margin-bottom: 10px;
    width: 100%;
`

const AuthTitle = styled.h1<{color?: string}>`
    font-weight: 700;
    font-size: 24px;
    color: ${(props) => (props.color ? props.color : "#202256")};
`

const LanguageButton = styled.h3<{color?: string, weight?: number}>`
    cursor: pointer;
    margin: 5px;
    font-weight: ${(props) => (props.weight ? props.weight : 400)};
    font-size: 18px;
    color: ${(props) => (props.color ? props.color : "#000000")};
`

const AuthButtonContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
    width: 100%;
`

const AuthButton = styled.button<{ background?: string }>`
    background-color: ${(props) => (props.background ? props.background : "#202256")};
    border-radius: 5px;
    width: 100%;
    height: 33px;
    color: #FFFFFF;
    text-align: center;
    font-size: 14px;
    font-weight: 400;
    border: none;
`

const AuthButtonDisabled = styled.button`
    cursor: not-allowed !important;
    background-color: #949494;
    border-radius: 5px;
    width: 100%;
    height: 33px;
    color: #FFFFFF;
    text-align: center;
    font-size: 14px;
    font-weight: 400;
    border: none;
`

const AuthFooterContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    margin-top: 10px;
    width: 100%;
`

const AuthFooterText = styled.p<{ color?: string}>`
    font-weight: 400;
    font-size: 14px;
    color: ${(props) => (props.color ? props.color : "#000000")};
`

const AuthLink = styled.p<{ color?: string, weight?: number}>`
    color: ${(props) => (props.color ? props.color : "#202256")};
    font-size: 14px;
    font-weight: ${(props) => (props.weight ? props.weight : 400)};
    cursor: pointer;
    text-align: center;
`

const AuthInputContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
    margin-top: 10px;
    font-weight: 400;
    font-size: 14px;
`

const AuthInputSubContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
`

const AuthInput = styled.input`
    width: 100%;
    height: 45px;
    padding-left: 20px;
    border: 1px solid #C5C5C5;
    font-size: 14px;
`

const AuthInputIconContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    width: 0;
`

const AuthOtpContainer = styled.div`
  padding-bottom: 50px;
  padding-top: 50px;
  display: flex;
  justify-content: center;

  input {
    margin: 5px;
    height: 35px;
    width: 40px;
    border: none;
    border-radius: 5px;
    text-align: center;
    font-size: 1.2rem;
    background: #c8c8c8;
  }
`

const AuthOtpSubTitle = styled.h1`
  font-weight: 400;
  font-size: 16px;
  color: #393939;
`
export {
    AuthGlobalContainer,
    AuthTitle,
    AuthFormContainer,
    LanguageContainer,
    AuthLogo,
    AuthButtonContainer,
    AuthButton,
    AuthButtonDisabled,
    AuthFooterContainer,
    AuthFooterText,
    AuthLink,
    LanguageButton,
    AuthInputContainer,
    AuthInput,
    AuthInputSubContainer,
    AuthInputIconContainer,
    AuthOtpSubTitle,
    AuthOtpContainer,
    AuthLogoUniv
}
