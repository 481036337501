import React,{useRef, useState, useEffect} from "react";
import Spinner from "react-bootstrap/Spinner";
import { useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { sendNewPass } from "../../../../actions";
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import Wave from "../../../../assets/imagenes/Vector385.svg";
import Wave2 from "../../../../assets/imagenes/Vector 384.svg";
import Creativity from "../../../../assets/imagenes/CREATIVIDAD.svg";
import ImgCodigo from "../../../../assets/imagenes/CODIGO.svg";
// import Edit from "../../../../assets/imagenes/edit.svg";
import Logo from "../../../../assets/imagenes/nuevo_logo.svg";

export default function ChangePassOtp() {
  const dispatch = useDispatch();
  const [cargando, setcargando] = useState(false);
  let location = useLocation();
  const history = useHistory();
  const [locallang, setLocallang] = useState(localStorage.getItem("langlocal"));
  const [passwordShown, setPasswordShown] = useState(false);
  const [error, setError] = useState(false);
  const form = useRef({
    password: "",
    passwordRepeat: "",
    otp: "",
  });
  const [width, setWidth] = useState(window.innerWidth);
  const [height, setHeight] = useState(window.innerHeight);


  const updateWidthAndHeight = () => {
    setWidth(window.innerWidth);
    setHeight(window.innerHeight);

  }
  useEffect(() => {
    window.addEventListener("resize", updateWidthAndHeight);
    return () => window.removeEventListener("resize", updateWidthAndHeight);
  }, [width, height]);


  const validate = (form) => {
    let error = {};
    form = form.current;
    if (!form.otp) {
      error.otp = locallang === 'es' ? "el campo OTP es requerido" : "OTP field is required";
      return error;
    }
    if (form.otp.length !== 6) {
      error.otp =  locallang === 'es' ?  "el campo OTP debe tener 6 caracteres" : "OTP field must have 6 characters";
      return error;
    }
    if (!form.password) {
      error.password = locallang === 'es' ?  "el campo contraseña es requerido" : "password field is required";
      return error;
    }
    if (form.password.length < 6) {
      error.password = locallang === 'es' ?  "La contraseña bebe tener al menos 6 caracteres" : "Password must have at least 6 characters";
      return error;
    }
    if (!form.passwordRepeat) {
      error.passwordRepeat = locallang === 'es' ?  "Debes repetir la contraseña" : "You must repeat the password";
      return error;
    }
    if (form.passwordRepeat.length < 6) {
      error.passwordRepeat = locallang === 'es' ?  "La contraseña bebe tener al menos 6 caracteres" : "Password must have at least 6 characters";
      return error;
    }
    if (form.password !== form.passwordRepeat) {
      error.passwordRepeat = locallang === 'es' ?  "Las contraseñas no coinciden" : "Passwords do not match";
      return error;
    }
    return error;
  };

  const handleInput = (event) => {
    event.preventDefault();
    form.current[event.target.name] = event.target.value;
    setError(validate(form));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (Object.entries(error).length === 0) {
      location = location.search.split("=")[1];
      setcargando(true);
      delete form.current.passwordRepeat;
      let response = await dispatch(sendNewPass({
        ...form.current,
        email: location,
      }));
      history.push("/");
      alert(response.message);
    }
    if(error.otp) toast.error(error.otp )
    if(error.password) toast.error(error.password)
    if(error.passwordRepeat) toast.error(error.passwordRepeat)
  };

  const cambiarlanges = (camlang) => {
    localStorage.setItem("langlocal", camlang);
    setLocallang(localStorage.getItem("langlocal"));
  };

  const togglePassword = () => {
    setPasswordShown(!passwordShown);
  }

  return (
    <div style={{backgroundColor: '#F5F5F5', display: 'flex', justifyContent: 'space-evenly', alignItems: 'center', width: '100%', height: '100vh', flexDirection: 'column'}}>
      {/* <ToastContainer /> */}
      <div style={{width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', height: '80%'}}>
      {
        width > 768 ? <div style={{
          width: '25%',
          height: '80%', display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-start'
        }}>
          <img src={Creativity} alt="creativity" style={{width: '235px', height: '235px'}}/>
        </div> : null
      }
      <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', width: width > 768 ? '33%' : '100%',
    marginTop: "10em", zIndex: '2'}}>
        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', height: '100%', flexDirection: 'column'}}>
        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%'}}>
            <img src={Logo} alt="logo" style={{width: '234px', height: '84px', marginBottom: '5%'}}/>
          </div>
          <div style={{display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-start', width: '100%'}}>
        <div
          style={{
            display: "flex",
            alignItems: "flex-end",
            justifyContent: 'flex-end',
            color: "#C5C5C5",
            width: '100%',
            paddingRight: '30px'
          }}
        >
                        <h3
                    style={
                      localStorage.getItem("langlocal") === "es"
                        ? {
                          margin: "0.3rem",
                          fontSize: "18px",
                          fontFamily: "Poppins",
                          cursor: "pointer",
                          color: "#202256",
                          fontWeight: "700",
                          userSelect: "none",
                        }
                        : { margin: "0.3rem", cursor: "pointer", fontWeight: '300', fontSize: '18px' }
                    }
                    onClick={() => cambiarlanges("es")}
                  >
                    SP
                  </h3>
                  <h3
                    style={
                      localStorage.getItem("langlocal") === "en"
                        ? {
                          margin: "0.3rem",
                          fontSize: "18px",
                          fontFamily: "Poppins",
                          cursor: "pointer",
                          color: "#202256",
                          fontWeight: "700",
                          userSelect: "none",
                        }
                        : { margin: "0.3rem", cursor: "pointer", fontWeight: '300', fontSize: '18px' }
                    }
                    onClick={() => cambiarlanges("en")}
                  >
                    EN
                  </h3>
        </div>
        </div>
        <div style={{width: '80%', marginLeft: "9%"}}>
          <h1 style={{fontFamily: 'Poppins', fontSize: '24px', fontWeight: "700", color: '#393939'}}>{
         locallang === 'es' ?  "Recuperar Contraseña" : "Recover Password"
          }</h1>
          </div>
        <div style={{display: 'flex', alignItems: 'flex-start', justifyContent: 'center', flexDirection: 'column'}}>
          <div>
            <p style={{color: '#5D5D5D', fontWeight: '400', fontSize: '14px'}}>
             {
                locallang === 'es' ? 'Codigo OTP' : 'OTP Code'
             }
            </p>
          </div>
          <div className="input-login-new">
            <input type='text' name="otp" placeholder={
                locallang === 'es' ? 'Codigo de verificación' : 'Verification code'
            } onChange={
              handleInput
            } onKeyPress={(event) => {
              if (!/[0-9]/.test(event.key)) {
                event.preventDefault();
              }
            }}/>
          </div>
        </div>
        <div style={{display: 'flex', alignItems: 'flex-start', justifyContent: 'center', flexDirection: 'column', marginTop: '4%'}}>
            <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center'}}>
              <p style={{color: '#5D5D5D', fontWeight: '400', fontSize: '14px'}}>
                {
                  locallang === 'es' ? 'Contraseña nueva,  minímo 6 caracteres.' : 'New password,  minimum 6 characters.'
                }
              </p>
            </div>
            <div className="input-login-new" style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', width: '348px', border: error.password ? '1px solid red' : 'none'}}>
              <input type={passwordShown ? "text" : "password" } name='password' placeholder={
                locallang === 'es' ? 'Contraseña nueva' : 'New password'
              } onChange={
                handleInput
              } />
            </div>
            {error && error.password && <p style={{color: 'red', fontSize: '12px', marginTop: '2%'}}>{error.password}</p>}
        </div>
        <div style={{display: 'flex', alignItems: 'flex-start', justifyContent: 'center', flexDirection: 'column', marginTop: '4%'}}>
            <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center'}}>
              <p style={{color: '#5D5D5D', fontWeight: '400', fontSize: '14px'}}>
                {
                  locallang === 'es' ? 'Repetir contraseña' : 'Repeat password'
                }
              </p>
            </div>
            <div className="input-login-new" style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', width: '348px', border: error.password ? '1px solid red' : 'none'}}>
              <input type={passwordShown ? "text" : "password" } name='passwordRepeat' placeholder={
                locallang === 'es' ? 'Repetir contraseña' : 'Repeat password'
              } onChange={
                handleInput
              } />
              <div style={{
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'flex-end',
                width: '0%'
              }}>
                <button style={{background:'transparent',borderRadius:'0px 1.5rem 1.5rem 0px'}}  onClick={()=> togglePassword()}>{passwordShown ? <VisibilityOffIcon/> : <VisibilityIcon/> }</button>
              </div>
            </div>
            {error && error.password && <p style={{color: 'red', fontSize: '12px', marginTop: '2%'}}>{error.password}</p>}
        </div>

        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', marginTop: '4%',}}>
        {
              form.current.password && form.current.passwordRepeat ? (
              <div>
            <button className='register-button-new' onClick={(e) => handleSubmit(e)}>
            {cargando?(
                <Spinner animation="border" size="sm" />
                ):
                <span>
                  {
                    locallang === 'es' ? 'Recuperar contraseña' : 'Recover password'
                  }
                  </span>}
            </button>
            </div>
            ) : (
              <div>
            <button className='register-button-new' disabled>
              {
                locallang === 'es' ? 'Recuperar contraseña' : 'Recover password'
              }
            </button>
              </div>
            )
            }
        </div>
        <hr style={{
          width: '80%',
          height: '1px',
          backgroundColor: '#D6D6D6',
          border: 'none',
          marginTop: '4%'
        }}/>
      </div>
      </div>
      {
        width > 768 ? <div style={{
          width: '25%',
          height: '85%', display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-end', zIndex: '1'
        }}>
          <img src={ImgCodigo} alt="logo" style={{width: '235px', height: '235px'}}/>
        </div> : null
      }
      </div>
      <div style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        alignItems: 'flex-end',
        width: '100%',
        height: '20%',
        backgroundColor:'#F5F5F5'
      }}>
        <div style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%',
        }}>
          <img src={Wave2} alt="wave 2"  style={{
            width: '100%',
            height: 'auto',
          }}/>
          <img src={Wave} alt="wave" style={{
            width: '100%',
            height: 'auto',
            position: 'absolute',
            bottom: '0'
          }} />
        </div>
      </div>
    </div>
  );
}
