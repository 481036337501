import React from 'react'
import './ProfesionalesListado.css'
import { useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
// import Eye from "../../../assets/icons/eye.svg";
import Advise from "../../../assets/icons/advise.svg";
import CvModalButton from "../CvModalButton/CvModalButton";
import axios from "axios";
import { OcultarButton } from "../AplicantesCard/AplicantesCard";
import { AiFillEye } from 'react-icons/ai';

export default function ProfesionalesListado({ keynum, compatibility, name, job, image, id, LinkedInCv, generalCv, idProyecto, monedaOportunidad }) {
  const { lang } = useSelector(state => state)
  const ideproy = useParams().id

  const handleContact = async (e) => {
    e.preventDefault()
    await axios.post("https://findally-backend.herokuapp.com/api/applicants/contact/coallier", {
      proyecto: ideproy,
      usuarios: [id]
    }).then(res => {
      console.log(res)
      return window.location.reload()
    }).catch(err => {
      console.log(err)
    })
  }

  const storedIds = JSON.parse(localStorage.getItem('aplicanteIds')) || [];
  const idExists = storedIds.includes(id);

  const handleLinkClick = (id) => {
    if (!idExists) {
      storedIds.push(id);
      localStorage.setItem('aplicanteIds', JSON.stringify(storedIds));
    }
  };

  return (
    <div>
      <div className={'prof-list-global-cont'}>
        <div className={'prof-list-rig-cont'} style={{ position: 'relative' }}>
          {
            idExists && 
            <div style={{ position: 'absolute', top: 0, left: 0 }}>
            <AiFillEye size={24} color="#202256" />
          </div>
          }
          <div className={idExists ? 'prof-list-rig-sub-cont-visited' : 'prof-list-rig-sub-cont'}>
            <h2 className={'prof-list-number'}>{keynum}{lang === 'es' ? 'to' : 'th'}</h2>
            <div className={'prof-list-card-image-filtro'}>
              <img className={'prof-list-img'}
                src={image ? image : 'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png'}
                alt={'profile-pic'} />
            </div>
            <div className='container-name-user'>
              <Link
                to={`/company/profile/aplicant/${id}?op=${idProyecto}&$=${monedaOportunidad}`}
                className={'prof-list-name'}
                onClick={() => handleLinkClick(id)}
              >{name}</Link>
              <h2 className={'prof-list-job'}>{job}</h2>
            </div>
          </div>
        </div>
        <div className={'prof-list-compat-cont'}>
          <img src={Advise} alt={'advise-icon'} />
          <h2 className={'prof-list-compat'}>
            {lang === 'es' ? `${compatibility} Compatibilidad` : `${compatibility} Compatibility`}
          </h2>
        </div>
        <div className={'prof-list-button-cont'}>
          <div className={'prof-list-ver-perfil-cont'}>
            <OcultarButton userId={id} />
          </div>
          <CvModalButton idUser={id} LinkedInCv={LinkedInCv} generalCv={generalCv} />
          <button onClick={(e) => handleContact(e)}
            className={'prof-list-but-selec'}>{lang === 'es' ? 'Seleccionar' : 'Select'}</button>
        </div>
      </div>
      <hr />
    </div>
  )
}
