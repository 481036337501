import React from "react";
import "bootstrap/dist/css/bootstrap.css";
import HeaderEmpresa from "../../components/HeaderEmpresa/HeaderEmpresa";
import { CreateProjectWhithoutPayment, CreateProject } from "../../actions";
import { connect } from "react-redux";
import '../../pages/styles/ContactUs.css';
import en from "../../lang/en-US";
import es from "../../lang/es-COL"
import counterpart from "counterpart";
 import Translate from "react-translate-component";
 import {useSelector} from 'react-redux';

/* import Popup from "../../components/popUp/Modal.js"
 */


function FormCompanyNewCreateProject(props) {

 const lang = useSelector(state => state.lang);
    
 


    
    counterpart.registerTranslations('en', en)
    counterpart.registerTranslations('es', es)
    counterpart.setLocale(localStorage.getItem('langlocal'));


    return (

        <>
            <HeaderEmpresa />
            <div style={{marginBottom:'2rem'}} className="section-tittle-out-form">
                <h2 className="tittle-out-form">{<Translate content="instrucciones" component="span"/>}</h2>
            </div>
            <div style={{width:'100%',display:'flex',justifyContent:'center',flexDirection:'column',alignItems:'center'}}>
            <div style={{width:'95%',marginBottom:'2rem'}}>
                <div style={{padding:'20px',marginBottom:'1rem',background:'#23224c',color:'white'}}>{lang === "es" ? '1-Correo electronico' : "1-Email"}</div>
                <div>{lang === "es" ? "Revisa tu correo electronico con la confirmacion del proyecto" : "Check your email with the confirmation of the project"}</div>
            </div>
            <div style={{width:'95%',marginBottom:'2rem'}}>
                <div style={{padding:'20px',marginBottom:'1rem',background:'#23224c',color:'white'}}>{lang === 'es' ? '2-Inicia sesión' : '2-Log In'}</div>
                <div>{lang === 'es' ? 'Inicia sesion y verifica el estado de tu proyecto' : 'Log in and check your project\'s status'}</div>
            </div>
            <div style={{width:'95%',marginBottom:'2rem'}}>
                <div style={{padding:'20px',marginBottom:'1rem',background:'#23224c',color:'white'}}>{lang === 'es' ? '3-Seleccion coallier' : '3-Coallier selection'}</div>
                <div>{lang === 'es' ? 'Después de una semana, Coally va a seleccionar al coallier o equipo de coalliers para ti' : 'After a week, Coally will select the coallier or team of coalliers for you'}</div>
            </div>
            <div style={{width:'95%',marginBottom:'2rem'}}>
                <div style={{padding:'20px',marginBottom:'1rem',background:'#23224c',color:'white'}}>{lang === 'es' ? '4-Segumiento proyecto' : '4-Project monitoring'}</div>
                <div>{lang === 'es' ? 'Una vez seleccionado, ve a la seccion de proyectos para que puedas hacer el seguimiento de los entregables' : 'Once selected, go to the projects section so you can track deliverables'}</div>
            </div>
            <div style={{width:'95%',marginBottom:'2rem'}}>
                <div style={{padding:'20px',marginBottom:'1rem',background:'#23224c',color:'white'}}>{lang === 'es' ? '5-Estado de entregables' : '5-Deliverables status'}</div>
                <div>{lang === 'es' ? 'Aprueba o rechaza los entregables' : 'Approve or reject deliverables'}</div>
            </div>
            <div style={{width:'95%',marginBottom:'2rem'}}>
                <div style={{padding:'20px',marginBottom:'1rem',background:'#23224c',color:'white'}}>{lang === 'es' ? '6-Mensajes internos' : '6-Internal messages'}</div>
                <div>{lang === 'es' ? 'Escribe mensajes o cita a los coalliers a reuniones' : 'Write messages or schedule coaliers to meetings'}</div>
            </div>
            <div style={{width:'95%',marginBottom:'2rem'}}>
                <div style={{padding:'20px',marginBottom:'1rem',background:'#23224c',color:'white'}}>{lang === 'es' ? '7-Aprobación final' : '7-Final approval'}</div>
                <div>{lang === 'es' ? 'Desarrolla la aprobacion final para finalizar el proyecto' : 'Develops final approval to finish the project'}</div>
            </div>

</div>

        </>
    )

}

const mapDispatchToProps = {
    CreateProjectWhithoutPayment,
    CreateProject
};
const mapStateToProps = (state) => {
    return {
        user: state.user,
    };
};
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(FormCompanyNewCreateProject);
