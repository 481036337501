import styled from 'styled-components';

export const CompanyOpportunityCardContainer = styled.div`
  background: #FFFFFF;
  width: 324px;
  box-shadow: 0 7px 32px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  margin: 22px;
`
