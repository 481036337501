import React, {useContext, useEffect, useState} from 'react';
import axios from 'axios'
import './Navbar.css';
import Us from '../../../assets/icons/user.svg';
// import Camp from '../../assets/icons/campana.svg';
import UniversitiesSideBar from "../sidebar/UniversitiesSideBar";
import {useDispatch, useSelector} from "react-redux";
import {changeLang, LoadCv} from "../../../actions";
import counterpart from "counterpart";
import en from "../../../lang/en-US";
import es from "../../../lang/es-COL";
import UserInfo from "./UserInfo/UserInfo";
import CircularFrameNav from '../../../components/CircularFrame/NavFrame';
import {useHistory} from 'react-router-dom';
import {UniversityContext} from "../../context/UniversitiesContext";

export default function UniversitiesNavbar({locate}) {
    const {university} = useContext(UniversityContext);
    const {lang, user, cv} = useSelector(state => state)
    const history = useHistory();

    const [dataUser, setDataUser] = useState()
    const [show, setShow] = useState(false);
    const [sidebar, setSidebar] = useState(false);
    const [showInfo, setShowInfo] = useState(false);
    const dispatch = useDispatch()

    const showSideBar = (e) => {
        e.preventDefault();
        setShowInfo(false);
        setShow(true);
        setSidebar(true);
    }
    const hideSideBar = (e) => {
        e.preventDefault();
        setShow(false);
        setSidebar(false);
    }
    const showUserInfo = (e) => {
        e.preventDefault();
        if (!user.id) {
            history.push('/')
        } else {
            setShow(false);
            setSidebar(false);
            setShowInfo(!showInfo);
        }
    }
    //eslint-disable-next-line
    const cambiarlanges = (camlang) => {
        dispatch(changeLang(camlang))
        localStorage.setItem('langlocal', camlang)
        setLocallang(localStorage.getItem('langlocal'))
    }
    if (!localStorage.getItem('langlocal')) {
        localStorage.setItem('langlocal', 'es')
    }
    //eslint-disable-next-line
    const [locallang, setLocallang] = useState(localStorage.getItem('langlocal'))

    counterpart.registerTranslations('en', en)
    counterpart.registerTranslations('es', es)
    counterpart.setLocale(localStorage.getItem('langlocal'));

    useEffect(() => {
        function handleResize() {
            if (window.innerWidth > 1300) {
                setShow(false);
                setSidebar(false);
            }
        }

        window.addEventListener('resize', handleResize)
        return _ => {
            window.removeEventListener('resize', handleResize)
        }
    }, [])


    useEffect(() => {
        if (user.roles !== 'company') {
            user.id && !cv && axios.get(`https://findally-backend.herokuapp.com/user/CVprofile/${user.id}`)
                .then(res => {
                    dispatch(LoadCv(res.data.cv_file))
                });
        }
    }, [dispatch, user.id, cv, user.roles])

    useEffect(() => {
        axios.get(`https://findally-backend.herokuapp.com/api/user/Companyform/${user.id}`)
            .then((res) => {
                setDataUser(res.data.Company_Form[0])
            })
            .catch((err) => {
                console.log(err)
            })
    }, [user])

    return (
        <>
            {university && university.color && <div className={'navbar-global-container'}>
                {/*popUp Sidebar*/}
                {show === true ? <div className={'navbar-sidebar-container'}>
                    <div>
                        <UniversitiesSideBar location={locate} style={sidebar}/>
                        <button onClick={(e) => hideSideBar(e)} className={'navbar-close-button'}>&times;</button>
                    </div>
                    <div className={'navbar-sidebar-container2'}></div>
                </div> : null}
                {/*popUp User*/}
                {showInfo === true ? <div className={'navbar-userInfo-container'}>
                    <div className={'navbar-userInfo-sub-container'}>
                        <UserInfo dataUser={dataUser} route={university.route}/>
                    </div>
                </div> : null}
                {/*Resto del navbar*/}
                <div className={'navbar-container'}>
                    <div onClick={(e) => showSideBar(e)} className={'navbar-container-sub-sub2'}>
                        <div className={'burguer-tile'}></div>
                        <div className={'burguer-tile'}></div>
                        <div className={'burguer-tile'}></div>
                    </div>
                    <div className={'navbar-container2'}>
                        <div className={'navbar-container-sub-sub'}>
                            <button onClick={() => cambiarlanges("es")}
                                    className={lang === 'es' ? 'lang-active' : 'lang-unactive'} style={{
                                        color: university.color.secondary
                            }}>SP
                            </button>
                            <button onClick={() => cambiarlanges("en")}
                                    className={lang === 'en' ? 'lang-active' : 'lang-unactive'} style={{
                                color: university.color.secondary
                            }}>EN
                            </button>
                        </div>
                        <div className={'navbar-container-sub'}>
                            {/*<img src={Camp} alt={'user icon'}/>*/}
                            <div className={'navbar-linea-vert'}></div>
                            <button onClick={(e) => showUserInfo(e)} className={'navbar-orange-circle-universitie'}>
                                {user.roles === 'company' ?
                                    <div className='contenedor-imagen-company-nav'><img
                                        src={user.avatar ? user.avatar : Us}
                                        alt={'user icon'}/></div> :
                                    <CircularFrameNav user={user} cv={cv}/>}
                            </button>
                        </div>
                    </div>
                </div>
                <div className={'navbar-linea'}></div>
            </div>}
        </>
    );
}
