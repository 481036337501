import styled from "styled-components";

const AddButton = styled.button<{ color?: string }>`
  width: 30%;
  height: 40px;
  background-color: ${(props) => props.color || "#27165b"};
  color: white;
  border: none;
  border-radius: 5px;
  margin-bottom: 10px;
  margin-top: 10px;
  white-space: nowrap;
  @media (max-width: 768px) {
    width: auto;
  }
`;

const RemoveButton = styled.button<{ color?: string }>`
  width: 40px;
  height: 40px;
  background-color: ${(props) => props.color || "#27165b"};
  color: white;
  border: none;
  border-radius: 5px;
  margin-bottom: 10px;
  margin-top: 10px;
  white-space: nowrap;
`;

const CreateButton = styled.button<{ color?: string }>`
  width: 100%;
  height: 40px;
  background-color: ${(props) => props.color || "#27165b"};
  color: white;
  margin-top: 10px;
  margin-bottom: 30px;
  font-size: 14px;
  border: none;
  font-weight: 400;
  border-radius: 5px;
`;

export { AddButton, RemoveButton, CreateButton };
