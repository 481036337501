/*eslint-disable*/
import React, { useState, useEffect } from "react";
import PlaceOutlinedIcon from '@mui/icons-material/PlaceOutlined';
import './cardsLinked.css'
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import { toast } from "react-toastify";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Translate from "react-translate-component";
import Button from "@mui/material/Button";
// import { format } from "date-fns";
// import { es } from "date-fns/locale";
import ContentPasteOutlinedIcon from '@mui/icons-material/ContentPasteOutlined';
import CottageOutlinedIcon from '@mui/icons-material/CottageOutlined';
import HandshakeOutlinedIcon from '@mui/icons-material/HandshakeOutlined';
import AttachMoneyOutlinedIcon from '@mui/icons-material/AttachMoneyOutlined';
import PushPinOutlinedIcon from '@mui/icons-material/PushPinOutlined';
import LanguageOutlinedIcon from '@mui/icons-material/LanguageOutlined';
import UseTour from "../../home/useTour";
import Spinner from 'react-bootstrap/Spinner';
import { getUserInfoWithoutReload } from "../../../actions";
import upload from "../../../assets/icons/Upload.svg";
import file from "../../../assets/icons/file.svg";
import { useCvForm } from "../../newForm/utils/useCvForm";
import Select from "react-select";
import { set } from "date-fns";

const BigCardLinked = ({
  image,
  title,
  company,
  place,
  date,
  requirements,
  description,
  companyDescription,
  habilities,
  language,
  nivelIngles,
  contract,
  idProyect,
  applied,
  setaplicable,
  setRefresh,
  modalidad,
  salary,
  responsabilities,
  experience,
  aplicantes,
  listadoBeneficios,
  descripcionBeneficios,
  descripcionComisiones,
  fit,
  userId
}) => {
  const { lang } = useSelector(state => state);
  const dispatch = useDispatch();
  const user = useSelector(state => state.user);
  const userCV = useSelector(state => state.cv);
  const [openCvPopUp, setOpenCvPopUp] = useState(false);
  const [fileform, setfileform] = useState("")
  const [fileform2, setfileform2] = useState(false)
  const [motivate, setmotivate] = useState("")
  const [loading, setloading] = useState(false)
  const [showMotivate, setshowMotivate] = useState(false)
  const [loadingCvPersonal, setLoadingCvPersonal] = useState(false)
  const [selectedFileCvGeneral, setSelectedFileCvGeneral] = useState(null)
  const [isFilePickedCvGeneral, setIsFilePickedCvGeneral] = useState(false)
  const [divisa, setDivisa] = useState("");
  const [montoMin, setMontoMin] = useState("");

  const {
    monedas
  } = useCvForm();

  const currency = salary?.split(' ')[0];
  const selectedCurrency = monedas.find((moneda) => moneda.value === currency);

  useEffect(() => {
    if (selectedCurrency) {
      setDivisa(selectedCurrency.label);
    }
  }, [selectedCurrency]);

  const handleCopyToClipboard = () => {
    navigator.clipboard.writeText(`https://coally-platform.com/consultor/project/${idProyect}`)
      .then(() => {
        return lang === "es" ? toast.success("Enlace copiado al portapapeles") : toast.success("Link copied to clipboard")
      })
  }


  function tiempoTranscurrido(fechaStr) {
    const fecha = new Date(fechaStr);
    const ahora = new Date();
    const diferencia = ahora.getTime() - fecha.getTime();
    const segundos = Math.floor(diferencia / 1000);
    const minutos = Math.floor(segundos / 60);
    const horas = Math.floor(minutos / 60);
    const dias = Math.floor(horas / 24);
    const meses = Math.floor(dias / 30);
    const años = Math.floor(meses / 12);

    if (lang === 'en') {
      if (años > 0) {
        return `${años} year${años > 1 ? 's' : ''} ago`;
      } else if (meses > 0) {
        return `${meses} month${meses > 1 ? 's' : ''} ago`;
      } else if (dias > 0) {
        return `${dias} day${dias > 1 ? 's' : ''} ago`;
      } else if (horas > 0) {
        return `${horas} hour${horas > 1 ? 's' : ''} ago`;
      } else if (minutos > 0) {
        return `${minutos} minute${minutos > 1 ? 's' : ''} ago`;
      } else {
        return 'A few seconds ago';
      }
    } else {
      if (años > 0) {
        return `Hace ${años} año${años > 1 ? 's' : ''}`;
      } else if (meses > 0) {
        return `Hace ${meses} mes${meses > 1 ? 'es' : ''}`;
      } else if (dias > 0) {
        return `Hace ${dias} día${dias > 1 ? 's' : ''}`;
      } else if (horas > 0) {
        return `Hace ${horas} hora${horas > 1 ? 's' : ''}`;
      } else if (minutos > 0) {
        return `Hace ${minutos} minuto${minutos > 1 ? 's' : ''}`;
      } else {
        return 'Hace unos segundos';
      }
    }
  }

  const aplicarOportunidad = async () => {
    const data = {
      usuario: user.id,
      proyecto: idProyect,
      form: true,
      aspiracion_salarial: Number(montoMin),
      moneda: divisa,
    }

    console.log(data)
    await axios.post('https://findally-backend.herokuapp.com/api/applicants', data)
      .then(() => {
        lang === 'es' ? toast.success("Felicitaciones quedaste inscrito a la vacante. A partir de ahora, debes estar atento a tu correo.", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }) : toast.success("Congratulations, you have registered for the vacancy. From now on, you should be attentive to your mail.", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        })
        handleClosePopUp()
        setaplicable(true);
        setRefresh(true);
      })
      .catch(e => {
        console.log(e)
        lang === 'es' ?
          toast.error("ocurrio un error", {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          })
          : toast.error("An error has occurred", {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          })
      })
  }

  const enviarMotivacion = async () => {
    setloading(true)
    console.log(divisa, montoMin)
    if (motivate === "") {
      toast.error(lang === 'es' ? "Por favor escribe una motivación" : "Please write a motivation", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
      return
    }
    if (divisa.length === 0) {
      toast.error(lang === 'es' ? "Por favor selecciona una divisa" : "Please select a currency", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
      setloading(false)
      return
    }
    if (montoMin.length === 0) {
      toast.error(lang === 'es' ? "Por favor escribe un monto mínimo" : "Please write a minimum amount", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
      setloading(false)
      return
    }
    setloading(false)
    aplicarOportunidad()
    // toast.promise(axios.post('https://findally-backend.herokuapp.com/api/motivation/gpt', {
    //     motivacion: motivate,
    // }), {
    //     pending: "Estamos calculando tu motivación...",
    //     success: "Motivación generada correctamente",
    //     error: "Error al generar motivación"
    // }).then(() => {
    //     setloading(false)
    //     aplicarOportunidad()
    // })
  }

  const enviarform = () => {
    const f2 = new FormData()
    f2.append("upload_file", fileform)
    f2.append("usuario", user.id)
    f2.append("pasion", motivate)
    axios.post("https://findally-backend.herokuapp.com/api/user/readCV", f2).then(() => {
      setshowMotivate(true)
    })
      .catch((res) => {
        console.log(res)
        toast.error("CV invalido", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        })
      })

  }


  const vercv = (e) => {
    const f3 = new FormData()
    f3.append("upload_file", e.target.files[0])
    f3.append("usuario", user.id)
    axios.post("https://findally-backend.herokuapp.com/api/user/validateCV", f3).then(() => {
      setfileform2(true)
    }).catch(() => {
      setfileform2(false);
      toast.error("CV invalido", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
    })
  }
  const insertarArchivo = async (e) => {
    console.log("e", e.target)
    console.log("de", e.target.files[0])
    setfileform(e.target.files[0])
    vercv(e)
    console.log("mira", fileform)
  }

  const handleClosePopUp = () => {
    setOpenCvPopUp(false);
  }

  const handleUploadCvGeneral = async (e) => {
    setLoadingCvPersonal(true)
    const fd2 = new FormData();
    fd2.append("upload_file", selectedFileCvGeneral);
    fd2.append("usuario", user.id);
    axios.post("https://findally-backend.herokuapp.com/api/user/readCvPersonalized", fd2)
      .then((res) => {
        setLoadingCvPersonal(false)
        toast.success("Cv personalizado subido correctamente", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        })
        dispatch(getUserInfoWithoutReload(user.id))
      })
  }

  const changeHandler2 = (e) => {
    setSelectedFileCvGeneral(e.target.files[0])
    setIsFilePickedCvGeneral(true)
  }

  const handleCancel2 = (e) => {
    e.preventDefault()
    setSelectedFileCvGeneral(null);
    setIsFilePickedCvGeneral(false);
  }

  const MostrarCompatibilidad = ({ fit }) => {
    let color
    if (fit >= 80) {
      color = '#4EEC7C'
    }
    else if (fit >= 30) {
      color = '#F2CD1F'
    } else {
      color = '#FC4D35'
    }
    return (
      <div style={{
        color: color,
        marginTop: '10px',
        width: '100%'
      }}>
        {fit?.toFixed(2)}% {lang === 'es' ? 'Compatibilidad' : 'Compatibility'}
      </div>
    )
  }

  return (
    <div className={'big-card-linked-container'}>
      <div className={"contenedor-tour"}>
        <UseTour />
      </div>
      <div className="oportunidades-big-card-top-container">
        <div className="oportunidades-big-card-top-image">
          <img src={image ? image : ''} alt="oportunitie image" style={{
            width: '164px',
            height: '164px',
            borderRadius: '8px',
            objectFit: 'scale-down',
            transition: 'all 0.3s ease 0s',
          }} />
        </div>
        <div className="oportunidades-big-card-top-body">
          <div style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%',
          }}>
            <Link to={`/consultor/project/${idProyect}`} style={{
              fontSize: '16px',
              fontWeight: '700',
              color: '#393939',
              textAlign: 'left',
              width: '100%',
            }}>
              {title}

            </Link>
            {fit ?
              <MostrarCompatibilidad fit={fit} />
              : null}
          </div>
          <Link to={`/company/profile/${userId}`}>
            <h1 style={{
              fontSize: '16px',
              fontWeight: '400',
              color: '#393939',
              margin: 0,
            }}>
              {company}
            </h1>
          </Link>
          {
            place ? (
              <div style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-start',
                alignItems: 'center',
                width: '100%',
                textAlign: 'center',
                paddingTop: '8px',
              }}>
                <PlaceOutlinedIcon style={{ color: '#5D5D5D', fontSize: '20px' }} />
                <div style={{
                  display: 'flex',
                  justifyContent: 'flex-start',
                  flexDirection: 'row',
                  alignItems: 'center',
                  width: '90%',
                  height: '100%',
                }}>
                  <p style={{
                    color: '#5D5D5D',
                    marginLeft: '10px',
                    marginBottom: 0,
                    fontSize: '16px'
                  }}>{place.charAt(0).toUpperCase() + place.slice(1)}</p>
                  <div style={{
                    display: 'flex',
                    justifyContent: 'flex-start',
                    flexDirection: 'row',
                    alignItems: 'center',
                    marginLeft: '20px',
                  }}>
                    <p style={{
                      color: '#24D054',
                      fontSize: '14px',
                      fontWeight: '400',
                      margin: 0,
                    }}>
                      {lang === 'es' ? 'Abierta' : 'Open'}
                    </p>
                    <p style={{
                      color: '#5D5D5D',
                      fontSize: '14px',
                      fontWeight: '400',
                      marginLeft: '5px',
                      marginRight: '5px',
                      marginBottom: 0,
                    }}>•</p>
                    <p style={{
                      color: '#5D5D5D',
                      fontSize: '14px',
                      fontWeight: '400',
                      margin: 0,
                    }}>
                      {aplicantes} {aplicantes === 1 ? lang === 'es' ? 'aplicante' : 'applicant' : lang === 'es' ? 'aplicantes' : 'applicants'}
                    </p>
                  </div>
                </div>
              </div>
            ) : null
          }
          <h1 style={{
            fontSize: '14px',
            fontWeight: '400',
            color: '#C5C5C5',
            paddingTop: '10px',
          }}>
            {tiempoTranscurrido(date)}
          </h1>
          {
            !applied && userCV && userCV?._id ? (
              <button
                className={'CardOportunidadProfesional-Button'} onClick={() => {
                  setOpenCvPopUp(true)
                }} id='applyButton'>
                {lang === 'es' ? 'Aplicar' : 'Apply'}
              </button>
            ) : !applied && !userCV && !userCV?._id ? (
              <button
                className={'CardOportunidadProfesional-Button'} onClick={() => {
                  setOpenCvPopUp(true)
                }
                } id='applyButton'>
                {lang === 'es' ? 'Llenar formulario' : 'Fill form'}
              </button>
            ) : (
              <button
                className={'CardOportunidadProfesional-Button-applied'}
                disabled={true} id='applyButton'>
                {lang === 'es' ? 'Ya aplicaste' : 'You already applied'}
              </button>
            )
          }
          <Dialog
            open={openCvPopUp}
            onClose={() => setOpenCvPopUp(false)}
            scroll={'paper'}
            aria-labelledby="scroll-dialog-title"
            aria-describedby="scroll-dialog-description"
          >
            <DialogTitle
              id="scroll-dialog-title"
              className="mediumfont"
            >
              {
                !userCV ?
                  lang === 'es' ? 'No tienes un CV' : 'You don\'t have a CV'
                  :
                  lang === 'es' ? 'Aplicar a la oportunidad' : 'Apply to the opportunity'
              }
            </DialogTitle>
            <DialogContent dividers={true}>
              {!applied && !userCV && !userCV?._id && !showMotivate ?
                <>
                  <div>
                    <h3>
                      {lang === 'es' ? 'Subir Cv (descargado de LinkedIn)' : 'Upload CV (downloaded from LinkedIn)'}
                    </h3>
                    <input type="file" name="files" onChange={e => insertarArchivo(e)} />

                  </div>
                  <br />
                  <div>
                    <h3>O</h3>
                  </div>
                  <br />
                  <div>
                    <h3>
                      {lang === 'es' ? 'Llenar formulario manualmente' : 'Fill form manually'}
                    </h3>
                    <Link to='/consultor/cv'>
                      <button className='consultor-cv-button-form' type="button"><Translate className="mediumfont"
                        content="Continuarv1"
                        component="span" /></button>
                    </Link>
                  </div>
                </>
                : !applied && userCV && userCV?._id && !showMotivate && user.CVPersonalized ? (
                  <div>
                    <h3>{lang === "es" ? "Aspiración salarial" : "Base salary"}</h3>
                    <Select
                      options={monedas}
                      placeholder={lang === 'es' ? 'Seleccione divisa' : 'Select currency'}
                      isSearchable={true}
                      isClearable={true}
                      value={selectedCurrency}
                      isDisabled={selectedCurrency !== undefined}
                      onChange={(e) => { setDivisa(e?.value) }}
                    />
                    <input
                      className={"input-crear-oportunidad"}
                      style={{ marginBottom: '20px' }}
                      type={"text"}
                      placeholder={
                        lang === "es" ? "Escriba el salario" : "Write the salary"
                      }
                      name={"aspiracion_salarial"}
                      onKeyPress={(e) => {
                        if (!/[0-9]/.test(e.key)) {
                          e.preventDefault();
                        }
                      }}
                      onChange={(e) => { setMontoMin(e.target.value) }}
                    />
                    <h3>
                      {lang === 'es' ? '¿Qué te motiva aplicar en este rol?' : 'What motivates you to apply for this role?'}
                    </h3>
                    <textarea onChange={e =>
                      setmotivate(e.target.value)} rows="5" cols="50"
                      style={{ width: '100%', border: '0.5px solid #C5C5C5' }} />
                  </div>
                ) : !applied && !userCV && !userCV?._id && showMotivate && user.CVPersonalized ? (
                  <div>
                    <h3>{lang === "es" ? "Aspiración salarial" : "Base salary"}</h3>
                    <Select
                      options={monedas}
                      placeholder={lang === 'es' ? 'Seleccione divisa' : 'Select currency'}
                      isSearchable={true}
                      isClearable={true}
                      value={selectedCurrency}
                      isDisabled={selectedCurrency !== undefined}
                      onChange={(e) => { setDivisa(e?.value) }}
                    />
                    <input
                      className={"input-crear-oportunidad"}
                      style={{ marginBottom: '20px' }}
                      type={"text"}
                      placeholder={
                        lang === "es" ? "Escriba el salario" : "Write the salary"
                      }
                      name={"aspiracion_salarial"}
                      onKeyPress={(e) => {
                        if (!/[0-9]/.test(e.key)) {
                          e.preventDefault();
                        }
                      }}
                      onChange={(e) => { setMontoMin(e.target.value) }}
                    />
                    <h3>
                      {lang === 'es' ? '¿Qué te motiva aplicar en este rol?' : 'What motivates you to apply for this role?'}
                    </h3>
                    <textarea onChange={e =>
                      setmotivate(e.target.value)} rows="5" cols="50"
                      style={{ width: '100%', border: '0.5px solid #C5C5C5' }} />
                  </div>
                ) : !applied && userCV && userCV?._id && showMotivate && user.CVPersonalized ? (
                  <div>
                    <h3>{lang === "es" ? "Aspiración salarial" : "Base salary"}</h3>
                    <Select
                      options={monedas}
                      placeholder={lang === 'es' ? 'Seleccione divisa' : 'Select currency'}
                      isSearchable={true}
                      isClearable={true}
                      value={selectedCurrency}
                      isDisabled={selectedCurrency !== undefined}
                      onChange={(e) => { setDivisa(e?.value) }}
                    />
                    <input
                      className={"input-crear-oportunidad"}
                      style={{ marginBottom: '20px' }}
                      type={"text"}
                      placeholder={
                        lang === "es" ? "Escriba el salario" : "Write the salary"
                      }
                      name={"aspiracion_salarial"}
                      onKeyPress={(e) => {
                        if (!/[0-9]/.test(e.key)) {
                          e.preventDefault();
                        }
                      }}
                      onChange={(e) => { setMontoMin(e.target.value) }}
                    />
                    <h3>
                      {lang === 'es' ? '¿Qué te motiva aplicar en este rol?' : 'What motivates you to apply for this role?'}
                    </h3>
                    <textarea onChange={e =>
                      setmotivate(e.target.value)} rows="5" cols="50"
                      style={{ width: '100%', border: '0.5px solid #C5C5C5' }} />
                  </div>
                ) : !user.CVPersonalized && (userCV || !userCV) && (userCV?._id || !userCV?._id) ? (
                  <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}>
                    <label className={'perfil-cv-input-contenedor'} style={{ margin: '0' }}>
                      <div className={'perfil-cv-input-subcontenedor'}>
                        {!loadingCvPersonal ? <img src={upload} alt={'img-upload'} /> :
                          <Spinner animation="border" variant="light" />}
                      </div>
                      <div>
                        {!loadingCvPersonal ? <><h3
                          className={'perfil-cv-input-title'}>{lang === 'es' ? 'Subir CV general' : 'Upload general CV'}</h3>
                          <h3 className={'perfil-cv-input-subtitle'}>PDF</h3> </> : <><h3
                            className={'perfil-cv-input-title'}
                            style={{ marginLeft: '-40px' }}>{lang === 'es' ? 'Leyendo CV ...' : 'Reading resume...'}</h3>
                          <h3 className={'perfil-cv-input-subtitle'} style={{ marginLeft: '-40px' }}>PDF</h3>
                        </>}
                      </div>
                      <input onChange={(e) => changeHandler2(e)} style={{ display: 'none' }} type={'file'} />
                    </label>
                    {isFilePickedCvGeneral
                      ? <div className={'perfil-cv-input-contenedor2'}>
                        <div className={'perfil-cv-input-subcontenedor2'}>
                          <img src={file} alt={'img-upload'} />
                        </div>
                        <div>
                          <h3
                            className={'perfil-cv-input-title2'}>{selectedFileCvGeneral.name}</h3>
                          <h3 className={'perfil-cv-input-subtitle2'}>{selectedFileCvGeneral?.size}KB</h3>
                        </div>
                        <div>
                          <button style={{
                            backgroundColor: '#fff',
                            color: '#202256',
                            position: 'absolute',
                            marginTop: '-50px',
                            fontWeight: 600
                          }} onClick={(e) => handleCancel2(e)}>
                            X
                          </button>
                        </div>
                      </div>
                      : null}
                  </div>
                ) : null}

            </DialogContent>
            <DialogActions>
              <Button onClick={() => {
                handleClosePopUp()
              }}>
                {lang === 'es' ? 'Cancelar' : 'Cancel'}
              </Button>
              {fileform2 && !loading && !showMotivate ?
                <Button
                  onClick={() => {
                    enviarform()
                  }}>
                  {lang === 'es' ? 'Enviar' : 'Send'}
                </Button> : userCV && userCV?._id && motivate && !loading && user.CVPersonalized ? (
                  <Button
                    onClick={() => {
                      void enviarMotivacion()
                    }}>
                    {lang === 'es' ? 'Enviar' : 'Send'}
                  </Button>
                ) : loading ? (
                  <Spinner animation={'border'} variant={'primary'} />
                ) : !userCV && !userCV?._id && showMotivate && user.CVPersonalized ? (
                  <Button
                    onClick={() => {
                      void enviarMotivacion()
                    }}
                  >
                    {lang === 'es' ? 'Enviar' : 'Send'}
                  </Button>
                ) : userCV && userCV?._id && !loading && !user.CVPersonalized && selectedFileCvGeneral ? (
                  <Button
                    onClick={() => {
                      void handleUploadCvGeneral()
                    }}>
                    {lang === 'es' ? 'Subir Cv' : 'Send Cv'}
                  </Button>
                ) : !userCV && !userCV?._id && !user.CVPersonalized && selectedFileCvGeneral ? (
                  <Button
                    onClick={() => {
                      void handleUploadCvGeneral()
                    }}>
                    {lang === 'es' ? 'Subir Cv' : 'Send Cv'}
                  </Button>
                ) : null}
            </DialogActions>
          </Dialog>

        </div>
        <div className={'clipboard-opportunitie-card'} onClick={() => {
          handleCopyToClipboard()
        }}>
          <ContentPasteOutlinedIcon style={{ color: '#5D5D5D', fontSize: '20px' }} />
        </div>
      </div>
      <div className="oportunidades-big-card-body-container" id='applyProject'>
        <div className={"oportunidades-big-card-body-inner"}>
          {
            requirements?.length ? (
              <div style={{
                fontWeight: '400',
                fontSize: '16px',
                color: '#5D5D5D',
              }}>
                <span style={{
                  fontWeight: '700',
                  fontSize: '16px',
                  color: '#5D5D5D',
                  paddingRight: '10px',
                }}>
                  {lang === 'es' ? 'Requisitos' : 'Requirements'} :
                </span>
                {requirements}
              </div>
            ) : null
          }
          {habilities?.length ? <>
            <div style={{
              fontWeight: '400',
              fontSize: '16px',
              color: '#5D5D5D',
            }}><span style={{
              fontWeight: '700',
              fontSize: '16px',
              color: '#5D5D5D',
            }}>{
                  lang === 'es' ? 'Habilidades técnicas: ' : 'Technical skills: '
                }
              </span>
              <ul>
                {habilities.map((item, index) => {
                  return <li key={index} style={{
                    listStyle: 'disc',
                  }}>{item.charAt(0).toUpperCase() + item.slice(1).toLowerCase()}</li>
                })}</ul>
            </div>
          </> : null}
          {
            description?.length ? (
              <p style={{
                fontWeight: '400',
                fontSize: '16px',
                color: '#5D5D5D',
                overflowWrap: 'anywhere',
                marginTop: '5%'
              }}>
                <span style={{
                  fontWeight: '700',
                  fontSize: '16px',
                  color: '#5D5D5D',
                  paddingRight: '10px',
                }}>
                  {lang === 'es' ? 'Descripción:' : 'Description:'}
                </span>
                {description}
              </p>
            ) : null
          }
          {
            companyDescription?.length ? (
              <p style={{
                fontWeight: '400',
                fontSize: '16px',
                color: '#5D5D5D',
                overflowWrap: 'anywhere',
                marginTop: '2%'
              }}>
                <span style={{
                  fontWeight: '700',
                  fontSize: '16px',
                  color: '#5D5D5D',
                  paddingRight: '10px',
                }}>
                  {lang === 'es' ? 'Descripción de la Empresa:' : 'Company Description:'}
                </span>
                {companyDescription}
              </p>
            ) : null
          }
          {responsabilities?.length ? <>
            <div style={{
              fontWeight: '400',
              fontSize: '16px',
              color: '#5D5D5D',
            }}>
              <span style={{
                fontWeight: '700',
                fontSize: '16px',
                color: '#5D5D5D',
              }}>
                {lang === 'es' ? 'Responsabilidades y funciones: ' : 'Responsabilities and functions: '}
              </span>
              <ul>
                {Array.isArray(responsabilities) ? responsabilities.map((item, index) => {
                  return <li key={index} style={{
                    listStyle: 'disc',
                  }}>{item.charAt(0).toUpperCase() + item.slice(1).toLowerCase()}</li>
                }) : responsabilities}
              </ul>
            </div>
          </> : null}
          {
            listadoBeneficios?.length ? (
              <>
                <div style={{
                  fontWeight: '400',
                  fontSize: '16px',
                  color: '#5D5D5D',
                  overflowWrap: 'anywhere',
                  marginTop: '5%'
                }}>
                  <span style={{
                    fontWeight: '700',
                    fontSize: '16px',
                    color: '#5D5D5D',
                    paddingRight: '10px',
                  }}>
                    {lang === 'es' ? 'Beneficios:' : 'Benefits:'}
                  </span>
                  <ul>
                    {listadoBeneficios.map((item, index) => {
                      return <li key={index} style={{
                        listStyle: 'disc',
                      }}>{item}</li>
                    })}
                  </ul>
                </div>
              </>
            ) : null
          }
          {
            descripcionComisiones ? (
              <p style={{
                fontWeight: '400',
                fontSize: '16px',
                color: '#5D5D5D',
                overflowWrap: 'anywhere',
                marginTop: '5%'
              }}>
                <span style={{
                  fontWeight: '700',
                  fontSize: '16px',
                  color: '#5D5D5D',
                  paddingRight: '10px',
                }}>
                  {lang === 'es' ? 'Comisiones:' : 'Commissions:'}
                </span>
                {descripcionComisiones}
              </p>
            ) : null
          }
          {
            descripcionBeneficios ? (
              <p style={{
                fontWeight: '400',
                fontSize: '16px',
                color: '#5D5D5D',
                overflowWrap: 'anywhere',
                marginTop: '5%'
              }}>
                <span style={{
                  fontWeight: '700',
                  fontSize: '16px',
                  color: '#5D5D5D',
                  paddingRight: '10px',
                }}>
                  {lang === 'es' ? 'Descripcion de beneficios:' : 'Benefits description:'}
                </span>
                {descripcionBeneficios}
              </p>
            ) : null
          }
        </div>
        <div className={"oportunidades-big-card-body-inner-2"}>
          <div style={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            alignItems: 'flex-start',
            justifyContent: 'flex-start',
            marginLeft: '5%'
          }}>
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start' }}>
              <LanguageOutlinedIcon style={{ color: '#D8D8D8', fontSize: '24px', fontWeight: '400' }} />
              <p style={{
                fontWeight: '700',
                fontSize: '14px',
                color: '#5D5D5D',
                marginLeft: '5px'
              }}>
                {lang === 'es' ? 'Idiomas: ' : 'Languages: '}
              </p>
            </div>
            <div style={{ display: 'flex', alignItems: 'flex-start', marginLeft: '10%' }}>
              <p>
                {/*map the language array [ingles, español] and for each item put a comma if there is only 1 just put the language*/}
                {language.map((item, index) => {
                  if (item.toLowerCase() === 'inglés' || item.toLowerCase() === 'english') {
                    return (
                      <span key={index}>
                        {item} {nivelIngles}
                        {index !== language.length - 1 ? ', ' : ''}
                      </span>
                    );
                  } else {
                    return (
                      <span key={index}>
                        {item}
                        {index !== language.length - 1 ? ', ' : ''}
                      </span>
                    );
                  }
                })}

              </p>
            </div>
            <hr style={{
              width: '80%',
              height: '1px',
              margin: '0'
            }} />
          </div>
          {modalidad ?
            <div style={{
              display: 'flex',
              flexDirection: 'column',
              width: '100%',
              alignItems: 'flex-start',
              justifyContent: 'flex-start',
              marginLeft: '5%',
              marginTop: '5%'
            }}>
              <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start' }}>
                <CottageOutlinedIcon style={{ color: '#202256', fontSize: '24px', fontWeight: '400' }} />
                <p style={{
                  fontWeight: '700',
                  fontSize: '14px',
                  color: '#5D5D5D',
                  marginLeft: '5px'
                }}>
                  {lang === 'es' ? 'Modalidad: ' : 'Modality: '}
                </p>
              </div>
              <div style={{ display: 'flex', alignItems: 'flex-start', marginLeft: '10%' }}>
                <p>
                  {modalidad}
                </p>
              </div>
              <hr style={{
                width: '80%',
                height: '1px',
                margin: '0'
              }} />
            </div> : null}
          <div style={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            alignItems: 'flex-start',
            justifyContent: 'flex-start',
            marginLeft: '5%',
            marginTop: '5%'
          }}>
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start' }}>
              <HandshakeOutlinedIcon style={{ color: '#202256', fontSize: '24px', fontWeight: '400' }} />
              <p style={{
                fontWeight: '700',
                fontSize: '14px',
                color: '#5D5D5D',
                marginLeft: '5px'
              }}>
                {lang === 'es' ? 'Tipo de contrato: ' : 'Contract type: '}
              </p>
            </div>
            <div style={{ display: 'flex', alignItems: 'flex-start', marginLeft: '10%' }}>
              <p>
                {contract}
              </p>
            </div>
            <hr style={{
              width: '80%',
              height: '1px',
              margin: '0'
            }} />
          </div>
          <div style={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            alignItems: 'flex-start',
            justifyContent: 'flex-start',
            marginLeft: '5%',
            marginTop: '5%'
          }}>
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start' }}>
              <AttachMoneyOutlinedIcon style={{ color: '#202256', fontSize: '24px', fontWeight: '400' }} />
              <p style={{
                fontWeight: '700',
                fontSize: '14px',
                color: '#5D5D5D',
                marginLeft: '5px'
              }}>
                {lang === 'es' ? 'Sueldo base: ' : 'Base salary: '}
              </p>
            </div>
            <div style={{ display: 'flex', alignItems: 'flex-start', marginLeft: '10%' }}>
              {salary.length ? <p className={'CardOportunidadProfesional-Salario'}>
                {salary.split(' ')[0] === 'COP' || salary.split(' ')[0] === 'USD' ? salary.split(' ')[0] === 'Divisa' ? `$ ${salary.split(' ')[1]}` : salary.split(' ')[1] === '0' ? lang === 'es' ? 'Salario no especificado' : 'Salary not specified' : `${salary.split(' ')[0]} ${salary.split(' ')[1]}` : `${salary.split(' ')[1]}`}
                <span style={{
                  fontSize: '14px',
                  fontWeight: '700',
                  color: '#C3CCFF'
                }}>
                  {lang === 'es' && salary.split(' ')[1] !== '0' ? '/mensual' : lang === 'en' && salary.split(' ')[1] !== '0' ? '/month' : ''}
                </span>
              </p> : <p className={'CardOportunidadProfesional-Salario'}>
                {lang === 'es' ? 'Salario no especificado' : 'Salary not specified'}
              </p>}
            </div>
            <hr style={{
              width: '80%',
              height: '1px',
              margin: '0'
            }} />
          </div>
          {experience.length ? <div style={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            alignItems: 'flex-start',
            justifyContent: 'flex-start',
            marginLeft: '5%',
            marginTop: '5%'
          }}>
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start' }}>
              <PushPinOutlinedIcon style={{ color: '#202256', fontSize: '24px', fontWeight: '400' }} />
              <p style={{
                fontWeight: '700',
                fontSize: '14px',
                color: '#5D5D5D',
                marginLeft: '5px'
              }}>
                {lang === 'es' ? 'Experiencia requerida: ' : 'Required experience: '}
              </p>
            </div>
            <div style={{ display: 'flex', alignItems: 'flex-start', marginLeft: '10%' }}>
              <p>
                {experience === "0.5" && lang === 'es' ? '6 meses' : experience === "0.5" && lang === 'en' ? '6 months' : experience} {experience.includes('años') || experience === "0.5" ? '' :
                  <span>{lang === 'es' ? 'años' : 'years'}</span>}
              </p>
            </div>
            <hr style={{
              width: '80%',
              height: '1px',
              margin: '0'
            }} />
          </div> : null}
        </div>
      </div>
    </div>
  )
}

export default BigCardLinked;
