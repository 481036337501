import React, { Fragment } from "react";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Divider from "@material-ui/core/Divider";
import Button from "@material-ui/core/Button";
import { CreateUnregisterProject } from "../../actions/index";
import { useDispatch } from "react-redux";
import en from "../../lang/en-US";
import es from "../../lang/es-COL"
import counterpart from "counterpart";
import Translate from "react-translate-component";


// Destructure props
const Confirm = ({
  handleNext,
  handleBack,
  values: {email,  nivel_ingles, nombre, ciudad, metricas, problema_empresa, linea_negocio, causa_empresa, objetivos }
}) => {
  const handleSubmit = () => {
    console.log({email, nombre, ciudad,nivel_ingles, metricas,linea_negocio, problema_empresa, causa_empresa, objetivos });
    dispatch(CreateUnregisterProject({  email, nombre,nivel_ingles, ciudad,linea_negocio, metricas, problema_empresa, causa_empresa, objetivos}, ''))
    handleNext();
  };

  const dispatch = useDispatch()


  counterpart.registerTranslations('en',en) 
      counterpart.registerTranslations('es',es)
      counterpart.setLocale(localStorage.getItem('langlocal'));
  return (
    <Fragment>
      <List disablePadding>
        <ListItem>
          <ListItemText primary="Nombre" secondary={nombre} />
        </ListItem>

        <Divider />

        <ListItem>
          <ListItemText primary="Ciudad" secondary={ciudad} />
        </ListItem>

        <Divider />

        <ListItem>
          <ListItemText primary="Metricas" secondary={metricas} />
        </ListItem>

        <Divider />

        <ListItem>
          <ListItemText primary="Problema" secondary={problema_empresa} />
        </ListItem>

        <Divider />

        <ListItem>
          <ListItemText primary="Causa" secondary={causa_empresa} />
        </ListItem>

        <Divider />

        <ListItem>
          <ListItemText primary="Objetivos" secondary={objetivos} />
        </ListItem>

        <Divider />
        
      </List>

      <div
        style={{ display: "flex", marginTop: 50, justifyContent: "flex-end" }}
      >
        <Button variant="contained" color="default" onClick={handleBack}>
        {<Translate content="Back" component="span"/>}
        </Button>
        <Button
          style={{ marginLeft: 20 }}
          variant="contained"
          color="secondary"
          onClick={handleSubmit}
        >
          Confirm & Continue
        </Button>
      </div>
    </Fragment>
  );
};

export default Confirm;
