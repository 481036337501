import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { ConsultorProjects, resetReq, projecTryOut} from "../actions/index";
import { connect } from "react-redux";
import Header from "../components/Header/Header";
import UpdateCasos from "../components/upload/UploadCaso";
import "./styles/CasosExito.css";

import en from "../lang/en-US";
import es from "../lang/es-COL"
import counterpart from "counterpart";
import Translate from "react-translate-component";


function ConsultorCertificados(props) {
  useEffect(() => {
    const getUser = () => {
      if (!props.done) {
        props.ConsultorProjects(props.user.projectsConsultor);
      }
    };
    // eslint-disable-next-line
    getUser();
    // eslint-disable-next-line
  }, []);
  const [colorboton,setColorboton] = useState('')
  const [idProject, setProjectValue] = useState("");
  const handdleprojectChange = (value) => {
    setColorboton(value)
    setProjectValue(value);
  };

  //const lang = useSelector(state => state.lang)
  


  counterpart.registerTranslations('en',en) 
  counterpart.registerTranslations('es',es)  
  counterpart.setLocale(localStorage.getItem('langlocal'));

  return (
    <> 
      <Header />
      {/* <select className="Header-lang" value={lang} onChange={(e)=>dispatch(changeLang(e.target.value))}>
        <option value="en">en-US</option>
        <option value="es">es-COL</option>
      </select> */}
      <div className="volver-legal" style={{ height: '150px' }}>
        <Link className="volver-leg-icon" to="consultor/home">
          {" "}
          🠸 {<Translate content="home" component="span"/>}
        </Link>
        <p style={{display:'flex',margin:'50px',marginLeft:'190px',fontSize:'30px'}}>{<Translate content="Casosdeéxito" component="span"/>}</p>
      </div>
       <div className="div-contenedor-botones">
        {props.userProjects !== []
          ? 
          props.userProjects
              .filter((filter) => {
                return filter.status !== "done"; 
              })
              .map((e) => {
                return (
                  <button style={colorboton===e._id?{background:'white',color:'#212156',borderBottom:'solid 0.4em #212156'}:{background:'white',color:'#464545',borderBottom:'solid 0.4em #afafaf'}}
                    className="projects-success-case security-space-min"
                    key={e._id}
                    onClick={() => {
                      handdleprojectChange(e._id);
                    }}
                  >
                    {e.nombre}
                  </button>
                );
              })
          : null}
          </div>
      <div className="container-casos-exito">
        <div className="container-casos">
        {/* <h3 className="subtittle-casos-exito">{<Translate content="Seleccionaelproyectoysubeledocumentoconladescripciondetucasodeexito" component="span"/>}</h3> */}
        <UpdateCasos idUsuario={props.user.id} idProyecto={idProject} />
        </div>
      </div>
    </>
  );
}
function mapStateToProps(state) {
  return {
    user: state.user,
    project: state.projectsConsultor,
    userProjects: state.userProjects,
    done: state.doneUserProject,
  };
}
const mapDispatchToProps = {
  ConsultorProjects,
  resetReq,
  projecTryOut,
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ConsultorCertificados);
