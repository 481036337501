/*eslint-disable*/
import React, { useEffect, useState } from "react";
import './pricingDetails.css'
import { useDispatch, useSelector } from "react-redux";
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';
import IndeterminateCheckBoxOutlinedIcon from '@mui/icons-material/IndeterminateCheckBoxOutlined';
import axios from "axios";
import { setAdditional } from "../../../actions/index.js";

export default function AditionalServices() {
  const [expanded, setExpanded] = useState(false);
  const [data, setData] = useState({})
  const [checkedMo, setCheckedMo] = useState(false);
  const [checkedHa, setCheckedHa] = useState(false);
  const [checkedEn, setCheckedEn] = useState(false);
  const [checkedRi, setCheckedRi] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [checkedLe, /*setCheckedLe*/] = useState(false);
  const [checkedTe, setCheckedTe] = useState(false);
  const [checkedMl, setCheckedMl] = useState(false);
  const dispatch = useDispatch();
  const [priceAdd, setPriceAdd] = useState()
  const lang = useSelector(state => state.lang);
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  }

  useEffect(() => {
    setPriceAdd({
      motivacion: checkedMo &&{
        price: Number(data[0]?.price),
        _id: data[0]?._id,
      } ,
      testSkils: checkedHa && {
        price: Number(data[1]?.price),
        _id: data[1]?._id
      } ,
      entrevista: checkedEn && {
        price: Number(data[2]?.price),
        _id: data[2]?._id
      } ,
      riesgos: checkedRi && {
        price: Number(data[3]?.price),
        _id: data[3]?._id
      } ,
      legal: checkedLe && {
        price: Number(data[6]?.price),
        _id: data[6]?._id
      } ,
      testTecnicos: checkedTe && {
        price: Number(data[4]?.price),
        _id: data[4]?._id
      } ,
      pesosMl: checkedMl && {
        price: Number(data[5]?.price),
        _id: data[5]?._id
      } ,
    })
  }, [checkedMo, checkedTe, checkedEn, checkedRi, checkedHa, checkedMl, checkedLe, data])

  useEffect(() => {
    dispatch(setAdditional(priceAdd))
  }, [priceAdd, dispatch])





  useEffect(() => {
    axios.get('https://findally-backend.herokuapp.com/api/premium_payment/additional-service')
      .then(res => {
        setData(res.data)
      })
      .catch(err => {
        console.log(err)
      })
  }, [])
 

  return (
    <div className="aditional-services-container">
      <div className="aditional-services-container-title">
        <h1 style={{
          margin: "0",
          fontSize: "24px",
          fontFamily: "Poppins",
          fontWeight: "700",
          color: "#393939",
        }}>
          {lang === "es" ? "Servicios adicionales" : "Additional services"}
        </h1>
      </div>
      <div className="aditional-services-dropdows-container">
        <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')} style={{ width: '100%', boxShadow: 'none', borderBottom: '1px solid #D6D6D6', borderRadius: '0px' }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
          >
            <div className="aditional-services-dropdows-container-title">
              <div onClick={() => {
                setCheckedMo(!checkedMo)
              }} style={{ marginRight: '3%' }}>
                {checkedMo ? <CheckBoxOutlinedIcon sx={{ color: '#202256' }} /> : <IndeterminateCheckBoxOutlinedIcon sx={{ color: '#202256' }} />}
              </div>
              <h1 style={{
                margin: "0",
                fontSize: "16px",
                fontFamily: "Poppins",
                fontWeight: "400",
                color: "#393939",
              }}>
                {lang === "es" ? "Motivación" : "Motivation"}
                <span style={{
                  color: "#202256",
                  fontWeight: "700",
                }}>
                  / $ {data[0]?.price}
                </span>
              </h1>
            </div>
          </AccordionSummary>
          <AccordionDetails>
            <p style={{
              margin: "0",
              fontSize: "14px",
              fontFamily: "Poppins",
              fontWeight: "400",
            }}>
              {
                lang === 'es' ? 'Incluye Test para ver qué tan motivado está el profesional para trabajar en el rol y en tu compañía dentro de tu algoritmo. ' : 'Includes a test to see how motivated the professional is to work in the role and in your company within your algorithm.'
              }
            </p>
          </AccordionDetails>
        </Accordion>
      </div>
      <div className="aditional-services-dropdows-container">
        <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')} style={{ width: '100%', boxShadow: 'none', borderBottom: '1px solid #D6D6D6', borderRadius: '0px' }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2bh-content"
            id="panel2bh-header"
          >
            <div className="aditional-services-dropdows-container-title">
              <div onClick={() => {
                setCheckedHa(!checkedHa)
              }} style={{ marginRight: '3%' }}>
                {checkedHa ? <CheckBoxOutlinedIcon sx={{ color: '#202256' }} /> : <IndeterminateCheckBoxOutlinedIcon sx={{ color: '#202256' }} />}
              </div>
              <h1 style={{
                margin: "0",
                fontSize: "16px",
                fontFamily: "Poppins",
                fontWeight: "400",
                color: "#393939",
              }}>
                {lang === "en" ? "Test Skills" : "Test Skills"} <span style={{
                  color: "#202256",
                  fontWeight: "700",
                }}>
                  / $ {data[1]?.price}
                </span>
              </h1>
            </div>
          </AccordionSummary>
          <AccordionDetails>
            <p style={{
              margin: "0",
              fontSize: "14px",
              fontFamily: "Poppins",
              fontWeight: "400",
            }}>
              {
                lang === 'es' ? 'Elige el Test de habilidades blandas que creas conveniente que incorpora sus resultados dentro de tu modelo de evaluación y ranking' : 'Choose the Soft Skills Test that you think is convenient to incorporate its results within your evaluation and ranking model'
              }
            </p>
          </AccordionDetails>
        </Accordion>
      </div>
      <div className="aditional-services-dropdows-container">
        <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')} style={{ width: '100%', boxShadow: 'none', borderBottom: '1px solid #D6D6D6', borderRadius: '0px' }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2bh-content"
            id="panel2bh-header"
          >
            <div className="aditional-services-dropdows-container-title">
              <div onClick={() => {
                setCheckedEn(!checkedEn)
              }} style={{ marginRight: '3%' }}>
                {checkedEn ? <CheckBoxOutlinedIcon sx={{ color: '#202256' }} /> : <IndeterminateCheckBoxOutlinedIcon sx={{ color: '#202256' }} />}
              </div>
              <h1 style={{
                margin: "0",
                fontSize: "16px",
                fontFamily: "Poppins",
                fontWeight: "400",
                color: "#393939",
              }}>
                {lang === "es" ? "Entrevista" : "Interview"} <span style={{
                  color: "#202256",
                  fontWeight: "700",
                }}>
                  / $ {data[2]?.price}
                </span>
              </h1>
            </div>
          </AccordionSummary>
          <AccordionDetails>
            <p style={{
              margin: "0",
              fontSize: "14px",
              fontFamily: "Poppins",
              fontWeight: "400",
            }}>
              {
                lang === 'es' ? 'Realizamos una entrevista automatizada por video vía WhatsApp y te enviamos los resultados de la misma junto con nuestro ranking interno' : 'We carry out an automated interview via WhatsApp video and send you the results of the same along with our internal ranking'
              }
            </p>
          </AccordionDetails>
        </Accordion>
      </div>
      <div className="aditional-services-dropdows-container">
        <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')} style={{ width: '100%', boxShadow: 'none', borderBottom: '1px solid #D6D6D6', borderRadius: '0px' }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2bh-content"
            id="panel2bh-header"
          >
            <div className="aditional-services-dropdows-container-title">
              <div onClick={() => {
                setCheckedRi(!checkedRi)
              }} style={{ marginRight: '3%' }}>
                {checkedRi ? <CheckBoxOutlinedIcon sx={{ color: '#202256' }} /> : <IndeterminateCheckBoxOutlinedIcon sx={{ color: '#202256' }} />}
              </div>
              <h1 style={{
                margin: "0",
                fontSize: "16px",
                fontFamily: "Poppins",
                fontWeight: "400",
                color: "#393939",
              }}>
                {lang === "es" ? "Riesgos" : "Risks"} <span style={{
                  color: "#202256",
                  fontWeight: "700",
                }}>
                  / $ {data[3]?.price}
                </span>
              </h1>
            </div>
          </AccordionSummary>
          <AccordionDetails>
            <p style={{
              margin: "0",
              fontSize: "14px",
              fontFamily: "Poppins",
              fontWeight: "400",
            }}>
              {
                lang === 'es' ? 'Realiza con nosotros el estudio de antecedentes y riesgos profesionales he incorporalo al ranking de los candidatos. ' : 'Do with us the background and professional risk study and incorporate it into the ranking of the candidates.'
              }
            </p>
          </AccordionDetails>
        </Accordion>
      </div>
      {/* Descomentar cuando se agregue la descripcion */}
      
      {/* <div className="aditional-services-dropdows-container">
        <Accordion expanded={expanded === 'panel5'} onChange={handleChange('panel5')} style={{ width: '100%', boxShadow: 'none', borderBottom: '1px solid #D6D6D6', borderRadius: '0px' }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2bh-content"
            id="panel2bh-header"
          >
            <div className="aditional-services-dropdows-container-title">
              <div onClick={() => {
                setCheckedLe(!checkedLe)
              }} style={{ marginRight: '3%' }}>
                {checkedLe ? <CheckBoxOutlinedIcon sx={{ color: '#202256' }} /> : <IndeterminateCheckBoxOutlinedIcon sx={{ color: '#202256' }} />}
              </div>
              <h1 style={{
                margin: "0",
                fontSize: "16px",
                fontFamily: "Poppins",
                fontWeight: "400",
                color: "#393939",
              }}>
                {lang === "es" ? "Legal" : "Legal"} <span style={{
                  color: "#202256",
                  fontWeight: "700",
                }}>
                  / $ {data[6]?.price}
                </span>
              </h1>
            </div>
          </AccordionSummary>
          <AccordionDetails>
            <p style={{
              margin: "0",
              fontSize: "14px",
              fontFamily: "Poppins",
              fontWeight: "400",
            }}>
              {
                lang === 'es' ? 'Legal descripcion ' : 'description legal'
              }
            </p>
          </AccordionDetails>
        </Accordion>
      </div> */}
      <div className="aditional-services-dropdows-container">
        <Accordion expanded={expanded === 'panel6'} onChange={handleChange('panel6')} style={{ width: '100%', boxShadow: 'none', borderBottom: '1px solid #D6D6D6', borderRadius: '0px' }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2bh-content"
            id="panel2bh-header"
          >
            <div className="aditional-services-dropdows-container-title">
              <div onClick={() => {
                setCheckedTe(!checkedTe)
              }} style={{ marginRight: '3%' }}>
                {checkedTe ? <CheckBoxOutlinedIcon sx={{ color: '#202256' }} /> : <IndeterminateCheckBoxOutlinedIcon sx={{ color: '#202256' }} />}
              </div>
              <h1 style={{
                margin: "0",
                fontSize: "16px",
                fontFamily: "Poppins",
                fontWeight: "400",
                color: "#393939",
              }}>
                {lang === "es" ? "Test técnicos" : " Technical tests"} <span style={{
                  color: "#202256",
                  fontWeight: "700",
                }}>
                  / $ {data[4]?.price}
                </span>
              </h1>
            </div>
          </AccordionSummary>
          <AccordionDetails>
            <p style={{
              margin: "0",
              fontSize: "14px",
              fontFamily: "Poppins",
              fontWeight: "400",
            }}>
              {
                lang === 'es' ? '(Coally) incorporará test técnico específico para evaluar la capacidad del profesional en el área de la posición.' : '(Coally) will incorporate specific technical test to evaluate the professional capacity in the area of the position.'
              }
            </p>
          </AccordionDetails>
        </Accordion>
      </div>
      <div className="aditional-services-dropdows-container">
        <Accordion expanded={expanded === 'panel7'} onChange={handleChange('panel7')} style={{ width: '100%', boxShadow: 'none', borderBottom: '1px solid #D6D6D6', borderRadius: '0px' }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2bh-content"
            id="panel2bh-header"
          >
            <div className="aditional-services-dropdows-container-title">
              <div onClick={() => {
                setCheckedMl(!checkedMl)
              }} style={{ marginRight: '3%' }}>
                {checkedMl ? <CheckBoxOutlinedIcon sx={{ color: '#202256' }} /> : <IndeterminateCheckBoxOutlinedIcon sx={{ color: '#202256' }} />}
              </div>
              <h1 style={{
                margin: "0",
                fontSize: "16px",
                fontFamily: "Poppins",
                fontWeight: "400",
                color: "#393939",
              }}>
                {lang === "es" ? "Pesos de ML" : "ML Weights"}
                <span style={{
                  color: "#202256",
                  fontWeight: "700",
                }}>
                  / $ {data[5]?.price}
                </span>
              </h1>
            </div>
          </AccordionSummary>
          <AccordionDetails>
            <p style={{
              margin: "0",
              fontSize: "14px",
              fontFamily: "Poppins",
              fontWeight: "400",
            }}>
              {
                lang === 'es' ? 'Varía los pesos de cada una de las variables de nuestro algoritmo de inteligencia artificial con lo cual puede determinar cuál de las variables es más importante para tu posición' : 'Vary the weights of each of the variables of our artificial intelligence algorithm so that you can determine which of the variables is most important for your position'
              }
            </p>
          </AccordionDetails>
        </Accordion>
      </div>
    </div>
  )
}