import React, {useEffect, useState} from 'react'
import {useSelector} from 'react-redux'
import Navbar from '../../components/Navbar/Navbar'
import Sidebar from '../../components/Sidebar/Sidebar'
import './PruebasTecnicas.css'
import {useParams} from "react-router";
import axios from "axios";
import {toast} from "react-toastify";
import {Dialog} from "@mui/material";

const PruebasTecnicasPage = () => {

  const {lang, user} = useSelector(state => state)
  const {id} = useParams()
  //eslint-disable-next-line
  const [oportunidad, setOportunidad] = useState({})
  //eslint-disable-next-line
  const [usuariosSeleccionados, setUsuariosSeleccionados] = useState()
  const [cargo, setCargo] = useState()
  //eslint-disable-next-line
  const [test, setTest] = useState()
  const [loaded, setLoaded] = useState(false)
  const [items, setItems] = useState()
  const [items2, setItems2] = useState()
  //eslint-disable-next-line
  const [respuestas, setRespuestas] = useState([])

  const [resp1, setResp1] = useState('')
  const [resp2, setResp2] = useState('')
  const [resp3, setResp3] = useState('')
  const [resp4, setResp4] = useState('')
  const [resp5, setResp5] = useState('')

  const [promesa1, setPromesa1] = useState('')
  const [promesa2, setPromesa2] = useState('')
  const [promesa3, setPromesa3] = useState('')
  const [promesa4, setPromesa4] = useState('')
  const [promesa5, setPromesa5] = useState('')

  const [entrevista, setEntrevista] = useState([])
  const [refresh, setRefresh] = useState(false)
//eslint-disable-next-line
  const [permiso, setPermiso] = useState(false)
  const [block, setBlock] = useState(false)

  const [countdown, setCountdown] = useState(300);
  const minutes = Math.floor(countdown / 60);
  const seconds = countdown % 60;

  // http://localhost:3001/consultor/pruebastecnicas/63c81c0dbbc9110018912286


  useEffect(() => {
    void requestGPT(cargo)
  },[cargo])
  const requestGPT = async (cargo) => {
    if(cargo === undefined) {
      return false
    } else {
      await axios.post('https://findally-backend.herokuapp.com/api/create/interview/gpt', {
         titulo: Array.isArray(cargo) ? cargo.map((e)=>{
           return e
         }).join(',') : cargo
       })
           .then(res => {
             setTest(res.data.value_questions.text)
             setItems(res.data.value_questions.text.match(/(\d+\.)(.+)/g))
             setItems2(res.data.value_questions.text.match(/(\d+\.)(.+)/g).map((item) => item.replace(/\d+\./, '')))
             setLoaded(true)
           })
           .then(() => {
             const intervalId = setInterval(() => {
               setCountdown(prevCountdown => {
                 if (prevCountdown === 0) {
                   clearInterval(intervalId);
                   window.localStorage.setItem('block test', '1');
                   setBlock(true)
                 }
                 return prevCountdown - 1;
               });
             }, 1000);

             return () => clearInterval(intervalId);
           })
           .catch(err => {
             console.log(err)
           })
    }
  }

  useEffect(() => {
    axios.get('https://findally-backend.herokuapp.com/api/Project/' + id)
      .then(res => {
        if (window.localStorage.getItem('block test') === '1') {
          setBlock(true)
        }
        setOportunidad(res.data)
        //set local storage si visitó la página
        window.localStorage.setItem('visited test', '1');
        void kickUser(res.data.selected_users)
        // setItems(resp.match(/(\d+\.)(.+)/g))
        // setItems2(resp.match(/(\d+\.)(.+)/g).map((item) => item.replace(/\d+\./, '')))
        // setLoaded(true)
        return setCargo(res.data.habilidadesTecnicas ? res.data.habilidadesTecnicas : res.data.NombreOportunidad)
      })
      .catch(err => {
        console.log(err)
      })
    // eslint-disable-next-line
  }, [id])

  // useEffect(() => {
  //   const intervalId = setInterval(() => {
  //     setCountdown(prevCountdown => {
  //       if (prevCountdown === 0) {
  //         clearInterval(intervalId);
  //       }
  //       return prevCountdown - 1;
  //     });
  //   }, 1000);
  //
  //   return () => clearInterval(intervalId);
  // }, []);

  // const log = async (elem) => {
  //   let reRender = 0
  //   if (elem === undefined) {
  //     reRender += 1
  //     console.log(reRender)
  //   } else {
  //     console.log('CUSTOM LOG', elem)
  //   }
  // }


  const kickUser = async (arr) => {
    // if(window.localStorage.getItem('visited test') === '1') {
    //   toast.error('Ya has realizado esta prueba',
    //     {
    //       position: 'top-center',
    //       autoClose: 3000,
    //       hideProgressBar: false,
    //       closeOnClick: true,
    //       pauseOnHover: true,
    //       draggable: true,
    //       progress: undefined,
    //     })
    //   return window.location.href = '/consultor/home'
    // }
    // if(window.localStorage.getItem('test time out') === '1') {
    //   toast.error('Se venció el tiempo para realizar esta prueba',
    //     {
    //       position: 'top-center',
    //       autoClose: 3000,
    //       hideProgressBar: false,
    //       closeOnClick: true,
    //       pauseOnHover: true,
    //       draggable: true,
    //       progress: undefined,
    //     })
    //   return window.location.href = '/consultor/home'
    // }
    if (arr === undefined) return
    if (arr.length === 0) return
    else {
      if (!arr?.includes(user?.id)) {
        toast.error('No tienes permiso para acceder a esta página',
          {
            position: 'top-center',
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          })
        return window.location.href = '/consultor/home'
      } else {
        setPermiso(true)
        return toast.success('Bienvenido a la prueba Técnica',
          {
            position: 'top-center',
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          })
      }
    }
  }

  const submitHandler = (e) => {
    e.preventDefault()
    for (let i = 0; i < items2?.length; i++) {
      switch (i) {
        case 0:
          setPromesa1({
            pregunta: items2[i],
            respuesta: resp1
          })

          break
        case 1:
          setPromesa2({
            pregunta: items2[i],
            respuesta: resp2
          })

          break
        case 2:
          setPromesa3({
            pregunta: items2[i],
            respuesta: resp3
          })

          break
        case 3:
          setPromesa4({
            pregunta: items2[i],
            respuesta: resp4
          })
          break
        case 4:
          setPromesa5({
            pregunta: items2[i],
            respuesta: resp5
          })
          break
        default:
          break
      }
    }
    Promise.all([axios.post('https://findally-backend.herokuapp.com/api/validate/interview/gpt', promesa1),
      axios.post('https://findally-backend.herokuapp.com/api/validate/interview/gpt', promesa2),
      axios.post('https://findally-backend.herokuapp.com/api/validate/interview/gpt', promesa3),
      axios.post('https://findally-backend.herokuapp.com/api/validate/interview/gpt', promesa4),
      axios.post('https://findally-backend.herokuapp.com/api/validate/interview/gpt', promesa5)
    ]).then(values => {
      setEntrevista(values.map((item, index) => {
        return {
          pregunta: items2[index],
          respuesta: resp1,
          //quitar salto de linea a la respuesta
          resultado: item?.data?.value_questions?.text?.replace(/\n/g, '')
        }
      }))
      setRefresh(true)
    })
      .catch(err => {
        console.log(err)
      })
  }


  const onchangeHandler = (e, index) => {
    e.preventDefault()
    switch (index) {
      case 0:
        setResp1(e.target.value)
        break
      case 1:
        setResp2(e.target.value)
        break
      case 2:
        setResp3(e.target.value)
        break
      case 3:
        setResp4(e.target.value)
        break
      case 4:
        setResp5(e.target.value)
        break
      default:
        break
    }
  }
  const guardarEnDb = (e) => {
    e.preventDefault()
    return axios.post('https://findally-backend.herokuapp.com/api/save/interview/gpt', {
      aplicante: user.id,
      proyecto: id,
      entrevista
    })
      .then(res => {
        toast.success('Entrevista enviada', {
          position: 'top-center',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        })
        console.log(res.data)
      })
      .catch(err => {
        console.log(err)
      })
      .finally(() => {
        window.location.href = '/consultor/home'
      })
  }

  return (
    <div>
      <Dialog open={refresh} onClose={() => setRefresh(false)}>
        <div style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          padding: '1rem'
        }}>
          <h3>{lang === 'es' ? '¿Deseas enviar los resultados?' : 'Do you want to send the results?'}</h3>
          <button onClick={(e) => guardarEnDb(e)} className={'button-Abrir-popup'}>
            {lang === 'es' ? 'Aceptar' : 'Accept'}
          </button>
        </div>
      </Dialog>
      <Sidebar location={'pruebastecnicas'}/>
      <div className="BodyWeb"
           style={{
             padding: "0rem 2rem",
             position: "absolute",
             right: "0",
           }}>
        <Navbar locate={'pruebastecnicas'}/>
        <div className='Container-Global-Pruebas'>
          <h1 className='title-pruebas-tec'>{lang === 'es' ? 'Prueba técnica' : 'Technical test'}</h1>
          <h2 className={'subtitle-pruebas-tec'}>{oportunidad.NombreOportunidad ? oportunidad.NombreOportunidad : ''}</h2>
          <h3>{lang === 'es' ? 'Por favor, lee atentamente y responde las siguientes preguntas' : 'Please read carefully and answer the following questions'}</h3>
          <h3 style={{
            fontWeight: 'bold',
            textAlign: 'center',
            marginTop: '1.5rem',
            marginBottom: '1.5rem'
          }}>{lang === 'es' ? 'Tiempo restante:' : 'Time left:'} {minutes}:{seconds < 10 ? `0${seconds}` : seconds}</h3>
          <hr/>

          <div style={{maxWidth: '100%'}}>
            {
              loaded === true ? items.map((item, index) => {
                return (
                  <div key={index}>
                    <p>{item}</p>
                    <textarea
                      name={item}
                      //disable if block is true
                      disabled={block}
                      onChange={(e) => onchangeHandler(e, index)}
                      className='textarea-pruebas-tec'
                    />
                  </div>
                )
              }) : <h3>{lang === 'es' ? 'Cargando Test...' : 'Loading Test'}</h3>
            }
          </div>

          <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
            <button className={'button-Abrir-popup'}
                    onClick={(e) => submitHandler(e)}>{lang === 'es' ? 'Enviar resultados' : 'Send results'}</button>
          </div>

        </div>
      </div>
    </div>
  )
}

export default PruebasTecnicasPage