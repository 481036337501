import React from "react";
import './styles/TyC.css';
import Header from "../empresa/header/Headerprofesional";
// import { Link } from 'react-router-dom';

import en from "../lang/en-US";
import es from "../lang/es-COL"
import counterpart from "counterpart";
import Translate from "react-translate-component";
import Headertop from "../empresa/headertop/Headertop";

function TyC(props) {


  //const lang = useSelector(state => state.lang)



  counterpart.registerTranslations('en', en)
  counterpart.registerTranslations('es', es)
  counterpart.setLocale(localStorage.getItem('langlocal'));

  return (
    <div>
      <div>
        <Header />
        {/* <select className="Header-lang" value={lang} onChange={(e)=>dispatch(changeLang(e.target.value))}>
        <option value="en">en-US</option>
        <option value="es">es-COL</option>
      </select> */}
        <div style={{ padding: "0rem 2rem", width: "calc(99vw - 237px)", position: "absolute", right: "0" }}>
        <Headertop></Headertop>

          {/* <Link className="volver-leg-icon" to="/consultor/home">
            {" "}
            🠸 {<Translate content="ATRÁS" component="span" />}
          </Link>
          <p style={{ display: 'flex', margin: '50px', marginLeft: '390px', fontSize: '20px' }}> <h2 className="title">{<Translate content="tycT" component="span" />}</h2></p> */}
          <h1 style={{
                        margin: '0',
                        fontSize: "40px",
                        fontFamily: 'Poppins',
                        fontWeight: '700',
                        color: '#202256',
                        marginBottom: '27px'
                    }}>Términos y Condiciones de Uso de la Plataforma coally</h1>
          
       
        <div className="tyc">
          <form className="tyc_form">
            <div className="section">
              <p className="content">{<Translate content="thisdoc" component="span" />}</p>

              <p className="content">{<Translate content="carring" component="span" />}</p>

              <p className="content">{<Translate content="comousted" component="span" />}</p>

              <p className="content">{<Translate content="cuertoparr" component="span" />}</p>
            </div>

            <div className="section">
              <h3 className="title">{<Translate content="servicio" component="span" />}</h3>
              <p className="content">{<Translate content="esunaplataforma" component="span" />}</p>

              <p className="sub-content">{<Translate content="siustedescon" component="span" />}</p>

              <p className="sub-content">{<Translate content="adicional" component="span" />}</p>

              <p className="sub-content">{<Translate content="siustedes" component="span" />} </p>

              <p className="content">{<Translate content="portanto" component="span" />} </p>

              <p className="content">{<Translate content="adicionalment" component="span" />} </p>
            </div>

            <div className="section">
              <h3 className="title">{<Translate content="alcanceyusu" component="span" />}  </h3>
              <p className="content">{<Translate content="paraquellos" component="span" />}</p>

              <p className="content">{<Translate content="paragold" component="span" />}</p>

              <p className="content"></p>

              <p className="content">{<Translate content="paraclientes" component="span" />}</p>
            </div>

            <div className="section">
              <h3 className="title">{<Translate content="requisitosuso" component="span" />}</h3>
              <p className="content">{<Translate content="elusuariodeber" component="span" />}</p>

              <p className="content">{<Translate content="paraacceder" component="span" />}</p>
            </div>

            <div className="section">
              <h3 className="title">{<Translate content="obligacionesusuario" component="span" />}</h3>
              <p className="content">{<Translate content="seobligaa" component="span" />}</p>

              <p className="sub-content">{<Translate content="Utilizarlosconteni" component="span" />}</p>
            </div>

            <div className="section">
              <h3 className="title">{<Translate content="condiparacli" component="span" />}</h3>
              <p className="content">{<Translate content="alentrarcon" component="span" />}</p>

              <p className="content">{<Translate content="ionalmentesiust" component="span" />}</p>
            </div>

            <div className="section">
              <h3 className="title">{<Translate content="condiconsulto" component="span" />}</h3>
              <p className="content">{<Translate content="alentraralaplat" component="span" />}</p>

              <p className="content">{<Translate content="Adicionalmentealaceptares" component="span" />}</p>

              <p className="content">{<Translate content="SiustedesConsultoresta" component="span" />}</p>

              <p className="content">{<Translate content="Seguirlaguiaque" component="span" />} </p>
            </div>

            <div className="section">
              <h3 className="title">{<Translate content="condigold" component="span" />}</h3>
              <p className="content">{<Translate content="Alaceptarestostérmi" component="span" />}</p>

              <p className="content">{<Translate content="Adicionalmentesuste" component="span" />}</p>
            </div>

            <div className="section">
              <h3 className="title">{<Translate content="tyc1" component="span" />}</h3>
              <p className="content">{<Translate content="tyc2" component="span" />}</p>

              <p className="content">{<Translate content="tyc3" component="span" />}</p>

              <p className="content">{<Translate content="tyc4" component="span" />}</p>
            </div>

            <div className="section">
              <h3 className="title">{<Translate content="tyc5" component="span" />}</h3>
              <p className="content">{<Translate content="tyc6" component="span" />}</p>

              <p className="content">{<Translate content="tyc7" component="span" />}</p>

              <p className="content">{<Translate content="tyc8" component="span" />}</p>

              <p className="content">{<Translate content="tyc9" component="span" />}</p>

              <p className="content">{<Translate content="tyc10" component="span" />}</p>
            </div>

            <div className="section">
              <h3 className="title">{<Translate content="tyc11" component="span" />}</h3>
              <p className="content">{<Translate content="tyc12" component="span" />}</p>

              <p className="content">{<Translate content="tyc13" component="span" />}</p>

              <p className="content">{<Translate content="tyc14" component="span" />}</p>

              <p className="content">{<Translate content="tyc15" component="span" />}</p>
            </div>

            <div className="section">
              <h3 className="title">{<Translate content="tyc16" component="span" />}</h3>
              <p className="content">{<Translate content="tyc17" component="span" />}</p>
            </div>

            <div className="section">
              <h3 className="title">{<Translate content="tyc18" component="span" />}</h3>
              <p className="content">{<Translate content="tyc19" component="span" />}</p>

              <p className="content">{<Translate content="tyc20" component="span" />}</p>

              <p className="content">{<Translate content="tyc21" component="span" />}</p>

              <p className="content">{<Translate content="tyc22" component="span" />}</p>
            </div>

            <div className="section">
              <h3 className="title">{<Translate content="tyc23" component="span" />}</h3>
              <p className="content">{<Translate content="tyc24" component="span" />}</p>
            </div>

            <div className="section">
              <h3 className="title">{<Translate content="tyc25" component="span" />}</h3>
              <p className="content">{<Translate content="tyc26" component="span" />}</p>
            </div>

            <div className="section">
              <h3 className="title">{<Translate content="tyc27" component="span" />}</h3>
              <p className="content">{<Translate content="tyc28" component="span" />}</p>

              <p className="content">{<Translate content="tyc29" component="span" />}</p>
            </div>
          </form>
        </div>
        </div>
      </div>
    </div>
  );
}

export default (TyC);