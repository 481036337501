import React from "react";
import '../styles/DataPolicy.css';
import Header from '../../components/HeaderEmpresa/HeaderEmpresa';
import { Link } from 'react-router-dom';

import en from "../../lang/en-US";
import es from "../../lang/es-COL"
import counterpart from "counterpart";
import Translate from "react-translate-component";
function DataPolicy(props) {

  counterpart.registerTranslations('en', en)
  counterpart.registerTranslations('es', es)
  counterpart.setLocale(localStorage.getItem('langlocal'));
  return (
    <>
          <Header />
            <div className="volver-legal" style={{ height: '150px' }}>
                <Link className="volver-leg-icon" to="/consultor/home">
                    {" "}
                    🠸 {<Translate content="ATRÁS" component="span"/>}
                </Link>
                <p style={{display:'flex',margin:'50px',marginLeft:'190px',fontSize:'30px'}}><h2 className="title">{<Translate content="PolíticadetratamientodedatosCoallySAS" component="span"/>}</h2></p>
            </div>
      <div className="datapolicy">
          <form className="datapolicy_form">
            <div className="section">
              <h3 className="title">1{<Translate content="Presentación" component="span"/>}</h3>
              <p className="content">{<Translate content="COALLYSASesunacompañíacolombianaquetienecomoactividadprincipalconectarempresasy" component="span"/>}</p>

              <p className="content">{<Translate content="COALLYSASconlafinalidaddedarcumplimientoestrictoalanormatividadvigentedeprotección" component="span"/>}</p>

              <p className="content">{<Translate content="ElobjetodelaPolíticadeTratamientoesprotegerelderechoconstitucionaldelHabeasDataque" component="span"/>}</p>
            </div>

            <div className="section">
              <h3 className="title">2{<Translate content="Ámbitodeaplicación" component="span"/>}</h3>
              <p className="content">{<Translate content="Estostérminosycondicionesytodoloquetengaqueverconestaaplicaciónylasrelaciones" component="span"/>}</p>
            </div>

            <div className="section">
              <h3 className="title">3{<Translate content="Definiciones" component="span"/>}</h3>
              <p className="sub-content">a{<Translate content="TitularPersonanaturalcuyosdatossonobjetodetratamientoParadelapresentepolíticade" component="span"/>}</p>
              <p className="sub-content">b{<Translate content="ClientePersonanaturalojurídica" component="span"/>} </p>
              <p className="sub-content">c{<Translate content="Usuariotodapersonaquehayadescargadoyhagausodelaplataformayaseacomoclienteocomo" component="span"/>}</p>
              <p className="sub-content">d{<Translate content="PlataformalarecoleccióndedatosporparteCoallySASseráatravésdelasplataformasCoally" component="span"/>}</p>
              <p className="sub-content">e{<Translate content="DatopersonalCualquierinformaciónvinculadaoquepuedaasociarseaunaovariaspersonas" component="span"/>}</p>
              <p className="sub-content">f{<Translate content="BasedeDatosConjuntoorganizadodedatosqueseanobjetodeTratamiento" component="span"/>}</p>
              <p className="sub-content">g{<Translate content="BasededatospersonalesConjuntoorganizadodedatospersonalesquesonobjetodetratamiento" component="span"/>}</p>
              <p className="sub-content">h{<Translate content="DatosensibleEsaqueldatopersonalqueafectalaintimidaddelTitularycuyousoincorrecto" component="span"/>}</p>
              <p className="sub-content">i{<Translate content="DatoprivadoEsaqueldatopersonalqueporsucarácteríntimooreservadoesrelevanteparael" component="span"/>}</p>
              <p className="sub-content">j{<Translate content="DatoSemiprivadoEsaqueldatopersonalconocidoydeinteréstantoparaeltitularcomoparaun" component="span"/>}</p>
              <p className="sub-content">k{<Translate content="DatopúblicoEsaqueldatopersonalcalificadocomotalsegúnlaConstituciónylaleyyqueno" component="span"/>}</p>
              <p className="sub-content">l{<Translate content="HabeasdataEselderechoquetieneelTitulardelosdatospersonalesdeexigirdelas" component="span"/>}</p>
              <p className="sub-content">m{<Translate content="AutorizaciónConsentimientoprevioexpresoeinformadodelTitularparallevaracaboel" component="span"/>}</p>
              <p className="sub-content">n{<Translate content="AvisodeprivacidadComunicaciónverbaloescritadirigidaalosTitularesdelosdatos" component="span"/>}</p>
              <p className="sub-content">o{<Translate content="TratamientoCualquieroperaciónoconjuntodeoperacionessobredatospersonalestalescomola" component="span"/>}</p>
              <p className="sub-content">p{<Translate content="ResponsabledeltratamientoPersonanaturalojurídicadecarácterpúblicooprivadoqueporsí" component="span"/>}</p>
            </div>

            <div className="section">
              <h3 className="title">4{<Translate content="Principiosrectoresparaeltratamientodelosdatospersonales" component="span"/>}</h3>
              <p className="content">{<Translate content="SegúnloestablecidoenelTítuloIIdelaLeyEstatutaria1581de2012laproteccióndedatos" component="span"/>}</p>
              <p className="sub-content">a{<Translate content="PrincipiodelegalidadeneltratamientodedatospersonalesEltratamientodedatos" component="span"/>}</p>
              <p className="sub-content">a{<Translate content="PrincipiodelegalidadeneltratamientodedatospersonalesEltratamientodedatos" component="span"/>}</p>
              <p className="sub-content">b{<Translate content="PrincipiodefinalidadEltratamientodelosdatospersonalesdebeobedeceraunafinalidad" component="span"/>}</p>
              <p className="sub-content">c{<Translate content="PrincipiodelibertadEltratamientodelosdatospersonalessólopuedeejercerseconel" component="span"/>}</p>
              <p className="sub-content">d{<Translate content="PrincipiodeveracidadocalidadLainformaciónsujetaatratamientodebeserverazcompleta" component="span"/>}</p>
              <p className="sub-content">e{<Translate content="PrincipiodetransparenciaEnelTratamientodelosdatospersonalesdebegarantizarseel" component="span"/>}</p>
              <p className="sub-content">f{<Translate content="PrincipiodeseguridadLainformaciónsujetaatratamientoporelResponsabledelTratamiento" component="span"/>}</p>
              <p className="sub-content">g{<Translate content="PrincipiodeconfidencialidadTodaslaspersonasqueintervenganeneltratamientodedatos" component="span"/>}</p>
              <p className="sub-content">h{<Translate content="PrincipiodeaccesoycirculaciónrestringidaEltratamientosesujetaaloslímitesquese" component="span"/>}</p>
            </div>

            <div className="section">
              <h3 className="title">5{<Translate content="Autorizaciónparaeltratamientodedatospersonalesycasosenlosquenoesnecesarialaautorización" component="span"/>}</h3>
              <p className="content">{<Translate content="ElconsentimientoyautorizaciónporpartedelTitulardelainformaciónesunrequisitoconstitucional" component="span"/>}</p>

              <p className="content">{<Translate content="PrevioLaautorizaciónladebedarelTitulardeinformacióndemanerapreviaacualquiertipode" component="span"/>}</p>
              <p className="content">{<Translate content="ExpresoLaautorizacióndebeotorgarsedeformainequívocaclarayespecífica" component="span"/>}</p>
              <p className="content">{<Translate content="InformadoElTitulardebecomprenderclaramenteparaquéserántratadossusdatospersonalesylas" component="span"/>}</p>

              <p className="content">{<Translate content="TodoslosvisitantesdelasPlataformasdeCOALLYSASdebenregistrarseyautorizareltratamiento" component="span"/>}</p>
            </div>

            <div className="section">
              <h3 className="title">6{<Translate content="TIPOSDEDATOSRECOPILADOSPORCOALLYSAS" component="span"/>}</h3>
              <p className="content">{<Translate content="CoallySASrecopilarádatosproporcionadosporlosusuariosdeCoallycomolosgeneradosdurante" component="span"/>}</p>

              <p className="sub-content">a{<Translate content="PerfildelusuariodatosgeneradoscuandosecreanoactualizanlascuentasEstopuede" component="span"/>}</p>
              <p className="sub-content">{<Translate content="Datosdemográficosserecopilarándatosdemográficosdelosusuarioscomodirecciónedad" component="span"/>}</p>
              <p className="sub-content">c{<Translate content="Datosdeusoserecopilandatossobreelservicioprestadooelserviciosolicitadoesto" component="span"/>}</p>
              <p className="sub-content">d{<Translate content="Otrosdatoscomentariosdelclientecalificacionesyopinionesdelservicioprestado" component="span"/>}</p>
            </div>

            <div className="section">
              <h3 className="title">7{<Translate content="Finalidaddetratamientodelosdatospersonales" component="span"/>}</h3>
              <p className="content">{<Translate content="LosDatosPersonalesquerecolectaCoallySASsonincluidosenunaBasedeDatosalacual" component="span"/>}</p>

              <p className="content">{<Translate content="LarecolecciónalmacenamientousoyocirculacióndelosdatospersonalesrecolectadosporCoally" component="span"/>}</p>

              <p className="content">{<Translate content="AdicionalmenteconelfindedesarrollarlosobjetossocialdelacompañíaCoally" component="span"/>}</p>

              <p className="sub-content">1{<Translate content="RecolectarlosdatospersonalesdelosusuarioseincorporarlosyalmacenarlosenlabasededatosdeCoallySAS" component="span"/>}</p>
              <p className="sub-content">2{<Translate content="Ordenarcatalogarclasificardividirosepararlainformaciónsuministrada" component="span"/>}</p>
              <p className="sub-content">3{<Translate content="Utilizarlosdatossuministradosencampañasdecomunicacióndivulgaciónypromociónuoferta" component="span"/>}</p>
              <p className="sub-content">4{<Translate content="Conservarregistroshistóricosdelacompañíaymantenercontactoconlostitularesdeldato" component="span"/>}</p>
              <p className="sub-content">5{<Translate content="Verificarcomprobarovalidarlosdatossuministrados" component="span"/>}</p>
              <p className="sub-content">6{<Translate content="Transferirlosdatospersonalesacualquierpaísoservidorenotropaís" component="span"/>}</p>
              <p className="sub-content">7{<Translate content="Comunicarypermitirelaccesoalosdatospersonalessuministradosatercerosproveedoresde" component="span"/>}</p>
              <p className="sub-content">8{<Translate content="Realizardeconformidadconlaleylosreportesacentralesderiesgoporincumplimientode" component="span"/>}</p>
              <p className="sub-content">9{<Translate content="Envíodecomunicacionesatravésdecualquiercanalincluyendoperosinlimitarsearedes" component="span"/>}</p>
              <p className="sub-content">10{<Translate content="Prestarlosserviciosofrecidosyocontratadosdeformaadecuadayconunaexcelentecalidad" component="span"/>}</p>
              <p className="sub-content">11{<Translate content="Recomendardeacuerdoalainformaciónfacilitadaporelconsultorproyectosespecíficos" component="span"/>}</p>
            </div>

            <div className="section">
              <h3 className="title">8{<Translate content="Normatividadaplicable" component="span"/>}</h3>
              <p className="content">{<Translate content="EltratamientodelosDatospersonalesdeltitularserealizaráconbaseenlodictadoenlapresente" component="span"/>}</p>
            </div>

            <div className="section">
              <h3 className="title">9{<Translate content="Derechosdelostitulares" component="span"/>}</h3>
              <p className="content">{<Translate content="EncumplimientodelasgarantíasfundamentalesconsagradasenlaConstituciónylaleyysin" component="span"/>}</p>

              <p className="sub-content">a{<Translate content="Derechoalaccesoasuinformaciónpersonalobjetodetratamiento" component="span"/>}</p>
              <p className="sub-content">b{<Translate content="Derechoalaactualizacióndelosdatospersonalesobjetodetratamiento" component="span"/>}</p>
              <p className="sub-content">c{<Translate content="Derechoalarectificacióndelosdatospersonalesobjetodetratamiento" component="span"/>}</p>
              <p className="sub-content">d{<Translate content="Derechodeoposiciónaquelosdatospersonalesseanobjetodetratamiento" component="span"/>}</p>
              <p className="sub-content">e{<Translate content="Derechoasolicitarlasupresióndelosdatospersonalescuandoeneltratamientonose" component="span"/>}</p>
              <p className="sub-content">f{<Translate content="Derechoasolicitarpruebadelaautorizaciónotorgadaparaeltratamiento" component="span"/>}</p>
              <p className="sub-content">g{<Translate content="Derechoarevocarelconsentimientoparaeltratamientodelosdatospersonales" component="span"/>}</p>
              <p className="sub-content">h{<Translate content="DerechoapresentarquejasyreclamosantelaSuperintendenciadeIndustriayComerciopor" component="span"/>}</p>
              <p className="sub-content">i{<Translate content="DerechoaserinformadoporpartedelResponsableyoEncargadodelusoytratamientoquese" component="span"/>}</p>
            </div>

            <div className="section">
              <h3 className="title">10{<Translate content="Identificacióndelresponsableyoencargadodeltratamiento" component="span"/>}</h3>
              <p className="content">{<Translate content="CoallySASesresponsabledeltratamientodesusdatospersonalesyharáusodeestosúnicamente" component="span"/>}</p>

              <p className="content">              
              {<Translate content="CoallySASNIT9014205746" component="span"/>}</p>
            </div>

            <div className="section">
              <h3 className="title">11{<Translate content="Deberesdelresponsabledelosdatospersonales" component="span"/>}</h3>
              <p className="content">{<Translate content="EnlíneaconlanormatividadvigenteentratamientodedatosCoallySASdeberácumplirconlos" component="span"/>}</p>

              <p className="sub-content">a{<Translate content="Garantizaraltitularentodotiempoelplenoyefectivoejerciciodelderechodehábeasdata" component="span"/>}</p>
              <p className="sub-content">b{<Translate content="Solicitaryconservarlareservayautorizaciónotorgadaporeltitular" component="span"/>}</p>
              <p className="sub-content">c{<Translate content="Informaraltitularsobrelafinalidaddelarecoleccióndedatosylosderechosquele" component="span"/>}</p>
              <p className="sub-content">d{<Translate content="Conservarlainformaciónbajolascondicionesdeseguridadnecesariasparaimpedir" component="span"/>}</p>
              <p className="sub-content">e{<Translate content="Actualizarlainformaciónyadoptarlasmedidasnecesariasparaquelainformaciónsuministrada" component="span"/>}</p>
            </div>

            <div className="section">
              <h3 className="title">12{<Translate content="Consultasyreclamos" component="span"/>}</h3>
              <p className="content">{<Translate content="Lasconsultasquejasoreclamospodránserpresentadosatravésdeunmensajededatos" component="span"/>}</p>
              <p className="content"><b>{<Translate content="Consultas" component="span"/>}</b><br></br>
              {<Translate content="TratándosedelderechodepeticiónencalidaddeinformaciónyoconsultasCoallySASdarárespuestaenuntérmino" component="span"/>}</p>
              <p className="content">{<Translate content="Cuandonofuereposibleatenderlaconsultadentrodedichotérminoseinformaráalinteresado" component="span"/>}</p>
              <p className="content">1{<Translate content="ÁreadeCoallySASalacualvadirigidaServicioalCliente" component="span"/>}<br></br>
              2{<Translate content="ElnombreynúmerodeidentificacióndelTitular" component="span"/>}<br></br>
              3{<Translate content="Copiadocumentodeidentidaddeltitular" component="span"/>}</p>

              <p className="content"><b>{<Translate content="Quejasyoreclamos" component="span"/>}</b><br></br>
              {<Translate content="CuandoelTitularconsiderequesuinformacióndebeserobjetodecorrecciónactualizaciónosupresiónocuandoadvierta" component="span"/>}</p>

              <p className="content">{<Translate content="Cuandonofuereposibleatenderelreclamodentrodedichotérminoseinformaráalinteresadolos" component="span"/>}<br></br>
              1{<Translate content="ÁreadeCoallySASalacualvadirigidaServicioalcliente" component="span"/>}<br></br>
              2{<Translate content="ElnombreynúmerodeidentificacióndelTitular" component="span"/>}<br></br>
              3{<Translate content="Copiadocumentodeidentidaddeltitular" component="span"/>}</p>
            </div>

            <div className="section">
              <h3 className="title">13{<Translate content="Modificaciones" component="span"/>}</h3>
              <p className="content">{<Translate content="EsposiblequeactualicemoslapresentePolíticadeDatosocasionalmenteEncasoderealizarse" component="span"/>}</p>
            </div>

            <div className="section">
              <h3 className="title">14{<Translate content="Vigencia" component="span"/>}</h3>
              <p className="content">{<Translate content="LapresentePolíticaentraenvigenciaapartirdeldía01delmesdeEnerode2021" component="span"/>}<br></br>
              {<Translate content="LosDatosPersonalesqueseanalmacenadosutilizadosotransmitidospermaneceránenlasbasesdedatosdeCoallySAS" component="span"/>}</p>

              <p className="content">{<Translate content="Sinembargolainformaciónserárevisadacadaañoparaverificarlaveracidaddeldatoyfinalidad" component="span"/>}</p>
            </div>
        </form>
    </div>
    </>
    );
}

export default (DataPolicy);