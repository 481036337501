/* eslint-disable */
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import Headertop from "../../profesionalpag/headertop/Headertop";
import HeadertopComp from '../../empresa/headertop/HeadertopV2'
import './styles/Config.css'
import ConfigContactUs from "./ConfigContactUs";
import ConfigFaq from "./ConfigFaq";
import Sidebar from "../Sidebar/Sidebar";
import Navbar from "../Navbar/Navbar";

export default function Config() {
  const [isOpen, setIsOpen] = useState({
    isOpen1: false,
    isOpen2: false,
    isOpen3: false,
    isOpen4: false,
    isOpen5: false,
    isOpen6: false,
  })
  const history = useHistory()
  const user = useSelector(state => state.user)
  console.log(user)
  const { lang } = useSelector((state) => state)
  // const [selected, setSelected] = useState(null)

  function handleClick(number) {
    switch (number) {
      case "1":
        setIsOpen({ ...isOpen, isOpen1: !isOpen.isOpen1 })
        break;
      case "2":
        setIsOpen({ ...isOpen, isOpen2: !isOpen.isOpen2 })
        break;
      case "3":
        setIsOpen({ ...isOpen, isOpen3: !isOpen.isOpen3 })
        break;
      case "4":
        setIsOpen({ ...isOpen, isOpen4: !isOpen.isOpen4 })
        break;
      case "5":
        setIsOpen({ ...isOpen, isOpen5: !isOpen.isOpen5 })
        break;
      case "6":
        setIsOpen({ ...isOpen, isOpen6: !isOpen.isOpen6 })
        break;
      default:
        break;
    }
  }


  return (
    <div>
      <Sidebar location={'configuración'} />
      <div className='config-headertop' >
        {/*{*/}
        {/*  user.roles === 'coallier' ? <Headertop /> : <HeadertopComp />*/}
        {/*}*/}
        <Navbar locate={'configuración'}/>
      </div>
      <div className="container-configuracion"
        style={{
          display: 'flex',
          flexDirection: 'column',
          minWidth: 'calc(99vw - 237px)',
          maxWidth: 'calc(99vw - 237px)',
          position: 'absolute', right: '0',
          padding: '5vw',
          justifyContent: 'space-evenly',        
        }}>
        <h1 style={{
          color: '#202256',
          fontWeight: '700',
          fontSize: '40px'
        }}>{lang === 'es' ? 'Configuración' : 'Settings'}</h1>
        <div style={{
          color: '#393939',
          fontWeight: '700',
          fontSize: '24px',
          marginTop: '40px',
        }}>
          {/* <p >{lang === 'es' ? 'De la cuenta' : 'From account'}</p>
          <div className="container-cuenta"  >
            <button>
              <span>{lang === 'es' ? 'Preferencias de la cuenta' : 'Account preferences'}</span><KeyboardArrowDownIcon />
            </button>
            <hr className="division-hr" />
            <button>
              <span>{lang === 'es' ? 'Inicio de sesión y seguridad' : 'Login and security'}</span><KeyboardArrowDownIcon />
            </button>
            <hr className="division-hr" />
            <button>
              <span>{lang === 'es' ? 'Visibilidad' : 'Visibility'}</span><KeyboardArrowDownIcon />
            </button>
            <hr className="division-hr" />
            <button>
              <span>{lang === 'es' ? 'Comunicaciones' : 'Communications'}</span><KeyboardArrowDownIcon />
            </button>
          </div> */}
        </div>
        <div style={{ color: '#393939', fontWeight: '700', fontSize: '24px', marginTop: '40px' }} >
          <p >{lang === 'es' ? 'Sobre Coally' : 'About Coally'}</p>
          <div className="container-cuenta" >
            {/* <button onClick={() => handleClick("1")}>
              <span>{lang === 'es' ? 'Contáctenos' : 'Contact Us'}</span><KeyboardArrowDownIcon />
            </button> */}
            {
              isOpen.isOpen1 ?
                <div className="container-dropdown" onClick={() => handleClick("1")}>
                  <ul>
                    <li></li>
                  </ul>
                </div>
                : null}
            <hr className="division-hr" />
            <button onClick={() => handleClick("2")}>
              <span>{lang === 'es' ? 'Preguntas frecuentes' : 'FAQ'}</span><KeyboardArrowDownIcon />
            </button>
            {
              isOpen.isOpen2 ?
                <ConfigFaq />
                : null}
            {/* <hr className="division-hr" />
            <button>
              <span>{lang === 'es' ? 'Política de privacidad' : 'Privacy Policy'}</span><KeyboardArrowDownIcon />
            </button>
            <hr className="division-hr" />
            <button>
              <span>{lang === 'es' ? 'Términos y condiciones' : 'Terms and Conditions'}</span><KeyboardArrowDownIcon />
            </button> */}
            <hr className="division-hr" />
            <button onClick={() => handleClick('3')}>
              <span>{lang === 'es' ? 'Soporte Coally' : 'Coally support'}</span><KeyboardArrowDownIcon />
            </button>
            {isOpen.isOpen3 ?
              <ConfigContactUs email={user.email}/>
              : null}
          </div>
        </div>
      </div>
      <div className='idiomas'></div>


    </div>
  )
}

