import React from 'react'
import image1 from '../../assets/slider/img.png'
import image2 from '../../assets/slider/img_1.png'
import image3 from '../../assets/slider/img_2.png'
import image4 from '../../assets/slider/img_3.png'
import image5 from '../../assets/slider/img_4.png'
import image6 from '../../assets/slider/img_5.png'
import image7 from '../../assets/slider/img_6.png'
import image8 from '../../assets/slider/img_7.png'
import image9 from '../../assets/slider/img_8.png'
import image10 from '../../assets/slider/img_9.png'
import image11 from '../../assets/slider/img_10.png'
import image12 from '../../assets/slider/img_11.png'
import image13 from '../../assets/slider/img_12.png'
import image14 from '../../assets/slider/img_13.png'
import image15 from '../../assets/slider/img_14.png'
import image16 from '../../assets/slider/img_15.png'
import image17 from '../../assets/slider/img_16.png'
import image18 from '../../assets/slider/img_17.png'
import image19 from '../../assets/slider/img_18.png'
import image20 from '../../assets/slider/img_19.png'
import image21 from '../../assets/slider/img_20.png'
import image22 from '../../assets/slider/img_21.png'
import image23 from '../../assets/slider/img_22.png'
import image24 from '../../assets/slider/img_23.png'
import image25 from '../../assets/slider/img_24.png'
import image26 from '../../assets/slider/img_25.png'
import image27 from '../../assets/slider/img_26.png'
import image28 from '../../assets/slider/img_27.png'
import image29 from '../../assets/slider/img_28.png'
import image30 from '../../assets/slider/img_29.png'
import image31 from '../../assets/slider/img_30.png'
import image32 from '../../assets/slider/img_31.png'
import image33 from '../../assets/slider/img_32.png'
import image34 from '../../assets/slider/img_33.png'
import image35 from '../../assets/slider/img_34.png'
import image36 from '../../assets/slider/img_35.png'
import image37 from '../../assets/slider/img_36.png'
import image38 from '../../assets/slider/img_37.png'
import image39 from '../../assets/slider/img_38.png'
import image40 from '../../assets/slider/img_39.png'
import image41 from '../../assets/slider/img_40.png'
import image42 from '../../assets/slider/img_41.png'
import image43 from '../../assets/slider/img_42.png'
import './AliadosSlider.css'

const images = [
  {
    image: image1,
    link: ''
  },
  {
    image: image2,
    link: ''
  },
  {
    image: image3,
    link: ''
  },
  {
    image: image4,
    link: ''
  },
  {
    image: image5,
    link: ''
  },
  {
    image: image6,
    link: ''
  },
  {
    image: image7,
    link: ''
  },
  {
    image: image8,
    link: ''
  },
  {
    image: image9,
    link: ''
  },
  {
    image: image10,
    link: ''
  },
  {
    image: image11,
    link: ''
  },
  {
    image: image12,
    link: ''
  },
  {
    image: image13,
    link: ''
  },
  {
    image: image14,
    link: ''
  },
  {
    image: image15,
    link: ''
  },
  {
    image: image16,
    link: ''
  },
  {
    image: image17,
    link: ''
  },
  {
    image: image18,
    link: ''
  },
  {
    image: image19,
    link: ''
  },
  {
    image: image20,
    link: ''
  },
  {
    image: image21,
    link: ''
  },
  {
    image: image22,
    link: ''
  },
  {
    image: image23,
    link: ''
  },
  {
    image: image24,
    link: ''
  },
  {
    image: image25,
    link: ''
  },
  {
    image: image26,
    link: ''
  },
  {
    image: image27,
    link: ''
  },
  {
    image: image28,
    link: ''
  },
  {
    image: image29,
    link: ''
  },
  {
    image: image30,
    link: ''
  },
  {
    image: image31,
    link: ''
  },
  {
    image: image32,
    link: ''
  },
  {
    image: image33,
    link: ''
  },
  {
    image: image34,
    link: ''
  },
  {
    image: image35,
    link: ''
  },
  {
    image: image36,
    link: ''
  },
  {
    image: image37,
    link: ''
  },
  {
    image: image38,
    link: ''
  },
  {
    image: image39,
    link: ''
  },
  {
    image: image40,
    link: ''
  },
  {
    image: image41,
    link: ''
  },
  {
    image: image42,
    link: ''
  },
  {
    image: image43,
    link: ''
  }
]


export default function AliadosSlider() {
  return (
    <div style={{display: 'flex', width: '100%', marginBottom: '20px'}}>
      {
        images.map((image, index) => {
          return (
            <a key={index} href={image.link}>
              <img src={image.image} alt={image.link} style={{height: '50px', marginLeft: '15px', marginRight: '15px', marginTop:'20px', marginBottom:'20px'}} />
            </a>
          )
        })
      }
    </div>
  )
}
