/*eslint-disable*/
import React, { useEffect } from "react";
import "./BootCamp.css";
import Sidebar from "../../components/Sidebar/Sidebar";
import Navbar from "../../components/Navbar/Navbar";
import { useSelector } from "react-redux";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AccordionDetails from "@mui/material/AccordionDetails";
import alarm from "../../assets/icons/alarm.svg";
// import alarmInactive from '../../assets/icons/alarm-inactive.svg'
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { useHistory } from "react-router-dom";
import bootcampModules from "./bootcampModules";
import SnakeProgress from "../Snake/SnakeProgress";
import { PDFDownloadLink } from "@react-pdf/renderer";
import CertificadoTemplate from "../../components/certificado/CertificadoTemplate";

// http://localhost:3001/consultor/bootcamp
// https://www.figma.com/file/bRCsb0kTVy7fsHDPrWlxTn/Coally?node-id=2947%3A18051&t=qHPou8xG6LDNZW6m-4
// 1015
const BootCamp = () => {
  const { lang } = useSelector((state) => state);
  const history = useHistory();
  const { user } = useSelector((state) => state);
  /*useEffect(()=>{
    if(!user?.bootcampAprobado){
        history.push('/consultor/home')
    }
  },[user])*/
  return (
    <div>
      <Sidebar location={"unilever home"} />
      <div
        className="BodyWeb"
        style={{
          padding: "0rem 2rem",
          position: "absolute",
          right: "0",
        }}
      >
        <Navbar locate={"home"} />
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
          }}
        >
          <div className={"bootcamp-contenedor-title"}>
            <div
              onClick={() => history.goBack()}
              className={"profile-title-cont"}
            >
              <ArrowBackIosIcon />
              <h1
                style={{
                  fontFamily: "Poppins",
                  color: "#202256",
                  fontSize: "40px",
                  fontWeight: "700",
                  marginLeft: "20px",
                }}
              >
                {lang === "es"
                  ? "Bootcamp Administradores de empresas"
                  : "Bootcamp Business administrators"}
              </h1>
            </div>
            <h2 className={"bootcamp-subtitle"}>
              {lang === "es" ? "Ruta de aprendizaje" : "Learning path"}
            </h2>
          </div>
          {/*<div style={{width: '100%'}}>*/}
          {/*  <Accordion*/}
          {/*    style={{*/}
          {/*      backgroundColor: 'transparent',*/}
          {/*      width: '100%',*/}
          {/*      boxShadow: 'none',*/}
          {/*      borderBottom: '1px solid #d6d6d7',*/}
          {/*      borderRadius: '0px'*/}
          {/*    }}>*/}
          {/*    <AccordionSummary*/}
          {/*      expandIcon={<ExpandMoreIcon/>}*/}
          {/*      aria-controls="panel1a-content"*/}
          {/*      id="panel1a-header"*/}
          {/*    >*/}
          {/*      <img*/}
          {/*        style={{*/}
          {/*          width: '55px',*/}
          {/*          height: '55px',*/}
          {/*          marginRight: '20px',*/}
          {/*          backgroundColor: 'white',*/}
          {/*          borderRadius: '50%'*/}
          {/*        }}*/}
          {/*        src={'https://d3puay5pkxu9s4.cloudfront.net/pages/edutin-isotype-positive.svg'}*/}
          {/*        alt={'logo'}/>*/}
          {/*      <div>*/}
          {/*        <h1 style={{*/}
          {/*          fontFamily: "Poppins",*/}
          {/*          fontWeight: "700",*/}
          {/*          fontSize: "24px",*/}
          {/*          color: "#393939"*/}
          {/*        }}>*/}
          {/*          {lang === 'es' ? 'Comercial y Ventas' : 'Comercial y Ventas'}*/}
          {/*        </h1>*/}
          {/*        <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>*/}
          {/*          <img src={alarm} alt={'alarm-icon'} style={{height: 'auto'}}/>*/}
          {/*          <h2 style={{*/}
          {/*            marginLeft: '5px',*/}
          {/*            fontFamily: "Poppins",*/}
          {/*            fontWeight: "700",*/}
          {/*            marginTop: '8px',*/}
          {/*            fontSize: "16px",*/}
          {/*            color: "#202256"*/}
          {/*          }}>*/}
          {/*            {lang === 'es' ? '10 horas' : '10 hours'}*/}
          {/*          </h2>*/}
          {/*        </div>*/}
          {/*      </div>*/}
          {/*    </AccordionSummary>*/}
          {/*    <AccordionDetails>*/}
          {/*      <div style={{marginTop: '45px'}}>*/}
          {/*        <Snake/>*/}
          {/*      </div>*/}
          {/*    </AccordionDetails>*/}
          {/*  </Accordion>*/}
          {/*</div>*/}
          {bootcampModules.map((module, index) => {
            return (
              <div style={{ width: "100%" }}>
                <Accordion
                  style={{
                    backgroundColor: "transparent",
                    width: "100%",
                    boxShadow: "none",
                    borderBottom: "1px solid #d6d6d7",
                    borderRadius: "0px",
                  }}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <img
                      style={{
                        width: "55px",
                        height: "55px",
                        marginRight: "20px",
                        backgroundColor: "white",
                        borderRadius: "50%",
                      }}
                      src={
                        "https://d3puay5pkxu9s4.cloudfront.net/pages/edutin-isotype-positive.svg"
                      }
                      alt={"logo"}
                    />
                    <div>
                      <h1
                        style={{
                          fontFamily: "Poppins",
                          fontWeight: "700",
                          fontSize: "24px",
                          color: "#393939",
                        }}
                      >
                        {module.title}
                      </h1>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                        }}
                      >
                        <img
                          src={alarm}
                          alt={"alarm-icon"}
                          style={{ height: "auto" }}
                        />
                        <h2
                          style={{
                            marginLeft: "5px",
                            fontFamily: "Poppins",
                            fontWeight: "700",
                            marginTop: "8px",
                            fontSize: "16px",
                            color: "#202256",
                          }}
                        >
                          {module.duration}
                        </h2>
                      </div>
                    </div>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div style={{ marginTop: "45px" }}>
                      <SnakeProgress data={module.subModules} />
                    </div>
                  </AccordionDetails>
                </Accordion>
              </div>
            );
          })}
          <PDFDownloadLink
            document={<CertificadoTemplate userData={user.name} />}
            filename={"certificado.pdf"}
            style={{
              padding: "10px",
              marginTop: "50px",
              height: "35px",
              borderRadius: "5px",
              borderStyle: "solid",
              borderWidth: "2px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            CERTIFICADO
          </PDFDownloadLink>
        </div>
      </div>
    </div>
  );
};

export default BootCamp;
