import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import { Box } from '@mui/material';

const CircularFrameAbility = ({percentage , title , color}) => {

  return (
    <Box sx={{ position: 'relative', display: 'inline-flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '200px', width: '200px' }}>
      <CircularProgress
        variant="determinate"
        color="primary"
        size={130}
        thickness={6}
        value={percentage}
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          zIndex: 1,
          color,
        }}
      />
      <CircularProgress
        variant="determinate"
        color="secondary"
        size={130}
        thickness={6}
        value={100}
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          color: '#E6E6E6',
        }}
      />
      <div
        style={{
          position: 'absolute',
          top: '44px',
          left: '42px',
          zIndex: 2,
          color: 'black',
          fontWeight: 700,
          fontSize: '28px',
        }}
      >
        {percentage}%
      </div>
      <h3 style={{ marginTop: '120px', marginLeft: '-64px'}}>{title}</h3>
    </Box>
  );
};

export default CircularFrameAbility;
