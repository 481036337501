//@ts-nocheck
import {useSelector} from "react-redux";
import {useParams} from "react-router";
import React, {useEffect, useState, useContext} from "react";
import axios from "axios";
import {toast} from "react-toastify";
import {
  formatterPeso,
  handleChangeAcademico, handleChangeLanguage, handleChangeNivel,
  handleFormChange, handleSubmit,
  preset, sendPromoCode
} from "../../../../empresa/formOportunidad/formUtils";
import Sidebar from "../../../components/sidebar/Sidebar"
import Navbar from "../../../components/navbar/Navbar"
import PhoneInput from "react-phone-input-2";
import {economySector} from "../../../../empresa/formOportunidad/economySector";
import Chip from "@mui/material/Chip";
import Creatable from "react-select/creatable";
import Select from "react-select";
import {SchneiderContext} from "../../../context/SchneiderContext";
import {CreateButton} from "../../professional/cvform/CvFormStyles";
import {AddButtonOpportunity} from "./CreateOpportunityStyles";

const CreateOpportunity = () => {
  const {schneider} = useContext(SchneiderContext)
  const { lang, user, userPlan } = useSelector((state: any) => state);
  const { id } = useParams<any>();
  const [promoCode, setPromoCode] = useState('');
  const [validCode, setValidCode] = useState(false)
  const [editable, setEditable] = useState(false)
  const [profesions, setProfesiones] = useState([])
  const [isSubmit, setisSubmit] = useState(false);
  const [divisa, setDivisa] = useState('');
  const [montoMin, setMontoMin] = useState('');
  const [img, setImg] = useState(null);
  const [imgPrev, setImgPrev] = useState(null);
  const [otherLang, setOtherLang] = useState(false);
  const [addOtherLang, setAddOtherLang] = useState({
    idioma: '',
    nivel: ''
  });
  const [responsabilidades, setResponsabilidades] = useState();
  // https://findally-backend.herokuapp.com/api/Project/data/63a4a2b799a63d6c7c48b9c6



  const [form, setForm] = useState({
    tipoDeServicioDeseado: '',
    modalidadDeTrabajo: '',
    modalidadDeContratacion: '',
    modalidadDeJornada: '',
    otraModalidadDeContratacion: '',
    Anonimo: '',
    NombreEmpresa: user?.name,
    Email: user?.email,
    usuario: user?.id,
    NombreOportunidad: '',
    DescribeProyecto: '',
    SeleccionaCarrera: '',
    Niveldeconocimiento: '',
    Lenguaje: '',
    otroLenguaje: [],
    nivel_ingles: '',
    habilidadesRequeridas: '',
    estimadoDuración: '',
    profesionales: '',
    presupuesto: '',
    extra_salario: '',
    codigoPromocional: '',
    modalidaddepago: '',
    link: '',
    empleos_alternativos: '',
    tiempo_experiencia: '',
    departamento: '',
    municipio: '',
    sector_economico: '',
    tipo_documento: '',
    documento: '',
    nombreEncargado: '',
    numeroEncargado: '',
    listadoBeneficios: '',
    descripcionBeneficios: '',
    descripcionComision: '',
    experienciaAnos: '',
    habilidadesTecnicas: '',
    habilidadesBlandas: [],
    country: '',
    responsabilidadYfunciones: [],
    academico: [],
  });

  useEffect(() => {
    if (id === 'new') {
      setEditable(false)
    } else {
      setEditable(true)
      axios.get(`https://findally-backend.herokuapp.com/api/Project/${id}`)
        .then((res: any) => {
          if (form) {
            setForm(res?.data)
            const parseo = res?.data.presupuesto?.split(' ')
            setDivisa(parseo[0])
            // @ts-ignore
            return setMontoMin(Number(parseo[1].replace(/\./g, '')))
          }
        })
        .catch(err => {
          console.log(err)
          // @ts-ignore
          return toast.error(lang === 'es' ? 'No se puede conseguir la información de la oportunidad, intentalo mas tarde' : 'Can\'t get the opportunity information, please try again later', preset)
        })
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])
  useEffect(() => {
    if (userPlan && form) {
      setForm({
        ...form,
        modalidaddepago: userPlan?.nombre_paquete
      })
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userPlan])

  useEffect(() => {
    axios.get('https://findally-backend.herokuapp.com/profesions')
      .then(res => {
        setProfesiones(res?.data)
      })
  }, [])

  const monedas = [
    { value: 'COP', label: 'COP' },
    { value: 'USD', label: 'USD' },
    { value: 'ARS', label: 'ARS' },
    { value: 'BRL', label: 'BRL' },
    { value: 'CLP', label: 'CLP' },
    { value: 'MXN', label: 'MXN' },
    { value: 'PEN', label: 'PEN' },
    { value: 'UYU', label: 'UYU' },
    { value: 'VEF', label: 'VEF' },
    { value: 'BOB', label: 'BOB' },
    { value: 'PYG', label: 'PYG' },
    { value: 'GTQ', label: 'GTQ' },
    { value: 'HNL', label: 'HNL' },
    { value: 'NIO', label: 'NIO' },
    { value: 'SVC', label: 'SVC' }
  ]

  const handleDelete = (fieldName: any, name: any) => {
    setForm((prevForm) => {
      return {
        ...prevForm,
        [fieldName]: prevForm[fieldName].filter((item: any) => {
          if (fieldName === 'otroLenguaje') {
            return item.idioma !== name.idioma || item.nivel !== name.nivel;
          } else if (fieldName === 'responsabilidadYfunciones') {
            return item !== name;
          } else if (fieldName === 'habilidadesBlandas') {
            return item.name !== name;
          }
          return true;
        })
      }
    });
  }


  const handleSelectChange = (selectedOptions) => {
    const alreadyExists = form.habilidadesBlandas.some(item => item.name === selectedOptions);
    if (!alreadyExists) {
      setForm({
        ...form,
        habilidadesBlandas: [
          ...form.habilidadesBlandas,
          {
            name: selectedOptions,
            value: '0'
          }
        ]
      });
    } else {
      toast.error(lang === 'es' ? 'Ya has agregado esta habilidad' : 'You have already added this ability', preset)
    }
  };

  const handleRangeChange = (event, index) => {
    const { value } = event.target;

    // Crear una copia del array habilidadesBlandas
    const updatedHabilidadesBlandas = [...form.habilidadesBlandas];

    // Actualizar el estado correspondiente
    updatedHabilidadesBlandas[index].value = value;

    // Actualizar el estado del formulario
    setForm({
      ...form,
      habilidadesBlandas: updatedHabilidadesBlandas
    });
  };


  /*  const professions = [
      {value: 'Agronomy, veterinary and related', label: 'Agronomy, veterinary and related'},
      {value: 'Fine Arts', label: 'Fine Arts'},
      {value: 'Educational sciences', label: 'Educational sciences'},
      {value: 'Health sciences', label: 'Health sciences'},
      {value: 'Social and human sciences', label: 'Social and human sciences'},
      {
        value: 'Economics, administration, accounting and related',
        label: 'Economics, administration, accounting and related'
      },
      {
        value: 'Engineering, architecture, urban planning and related',
        label: 'Engineering, architecture, urban planning and related'
      },
      {value: 'Mathematics and natural sciences', label: 'Mathematics and natural sciences'},
    ];*/

  const habilidadesBlandas = [
    { value: 'Capacidad de respuesta', label: 'Capacidad de respuesta' },
    { value: 'Adaptabilidad', label: 'Adaptabilidad' },
    { value: 'Atención a los detalles', label: 'Atención a los detalles' },
    { value: 'Colaboración', label: 'Colaboración' },
    { value: 'Habilidad para las comunicaciones', label: 'Habilidad para las comunicaciones' },
    { value: 'Creatividad', label: 'Creatividad' },
    { value: 'Pensamiento crítico', label: 'Pensamiento crítico' },
    { value: 'Inteligencia emocional', label: 'Inteligencia emocional' },
    { value: 'Empatía', label: 'Empatía' },
    { value: 'Flexibilidad', label: 'Flexibilidad' },
    { value: 'Innovación', label: 'Innovación' },
    { value: 'Liderazgo', label: 'Liderazgo' },
    { value: 'Organización', label: 'Organización' },
    { value: 'Paciencia', label: 'Paciencia' },
    { value: 'Habilidades para socializar', label: 'Habilidades para socializar' },
    { value: 'Resolución de problemas', label: 'Resolución de problemas' },
    { value: 'Responsabilidad', label: 'Responsabilidad' },
    { value: 'Autocritica', label: 'Autocritica' },
    { value: 'Pensamiento estratégico', label: 'Pensamiento estratégico' },
    { value: 'Trabajo en equipo', label: 'Trabajo en equipo' },
    { value: 'Gestión del tiempo', label: 'Gestión del tiempo' },
    { value: 'Ética laboral', label: 'Ética laboral' },
    { value: 'Confiabilidad', label: 'Confiabilidad' },
    { value: 'Sistemático', label: 'Sistemático' },
    { value: 'Imparcialidad', label: 'Imparcialidad' },
    { value: 'Practicidad', label: 'Practicidad' },
    { value: 'Aventurero', label: 'Aventurero' },
    { value: 'Orientación a personas', label: 'Orientación a personas' },
    { value: 'Búsqueda de crecimiento', label: 'Búsqueda de crecimiento' },
    { value: 'Proactividad', label: 'Proactividad' },
  ]
  const softSkills = [
    { value: 'Response capacity', label: 'Response capacity' },
    { value: 'Adaptability', label: 'Adaptability' },
    { value: 'Attention to detail', label: 'Attention to detail' },
    { value: 'Collaboration', label: 'Collaboration' },
    { value: 'Communication skills', label: 'Communication skills' },
    { value: 'Creativity', label: 'Creativity' },
    { value: 'Critical thinking', label: 'Critical thinking' },
    { value: 'Emotional intelligence', label: 'Emotional intelligence' },
    { value: 'Empathy', label: 'Empathy' },
    { value: 'Flexibility', label: 'Flexibility' },
    { value: 'Innovation', label: 'Innovation' },
    { value: 'Leadership', label: 'Leadership' },
    { value: 'Organization', label: 'Organization' },
    { value: 'Patience', label: 'Patience' },
    { value: 'Social skills', label: 'Social skills' },
    { value: 'Problem solving', label: 'Problem solving' },
    { value: 'Responsibility', label: 'Responsibility' },
    { value: 'Self-criticism', label: 'Self-criticism' },
    { value: 'Strategic thinking', label: 'Strategic thinking' },
    { value: 'Teamwork', label: 'Teamwork' },
    { value: 'Time management', label: 'Time management' },
    { value: 'Work ethics', label: 'Work ethics' },
    { value: 'Reliability', label: 'Reliability' },
    { value: 'Systematic', label: 'Systematic' },
    { value: 'Impartiality', label: 'Impartiality' },
    { value: 'Practicality', label: 'Practicality' },
    { value: 'Adventurous', label: 'Adventurous' },
    { value: 'People orientation', label: 'People orientation' },
    { value: 'Growth mindset', label: 'Growth mindset' },
    { value: 'Proactivity', label: 'Proactivity' },
  ]

  const tipoDocumento = [
    { value: 'C.C', label: 'Cédula de ciudadanía' },
    { value: 'NIT', label: 'NIT' },
    { value: 'Pasaporte', label: 'Pasaporte' },
  ]
  const documentType = [
    { value: 'C.C', label: 'Citizenship card' },
    { value: 'NIT', label: 'NIT' },
    { value: 'Pasaporte', label: 'Passport' },
  ]

  const modalidades = [
    { value: 'Término indefinido', label: 'Término indefinido' },
    { value: 'Término fijo', label: 'Término fijo' },
    { value: 'Por proyecto', label: 'Por proyecto' },
    { value: 'Prestación de servicios', label: 'Prestación de servicios' },
    { value: 'Contrato por hora o labor', label: 'Contrato por hora o labor' },
    { value: 'Otro', label: 'Otro' },
  ]

  const modalityes = [
    { value: 'Indefinite', label: 'Indefinite term' },
    { value: 'Fixed term', label: 'Fixed term' },
    { value: 'Project', label: 'Project' },
    { value: 'Contractor provision of services', label: 'Contractor provision of services' },
    { value: 'Contract by hour or labor', label: 'Contract by hour or labor' },
    { value: 'Other', label: 'Other' },
  ]

  const jornadas = [
    { value: 'Tiempo completo', label: 'Tiempo completo' },
    { value: 'Tiempo parcial', label: 'Tiempo parcial' },
    { value: 'Por horas', label: 'Por horas' },
  ]

  const schedules = [
    { value: 'Full time', label: 'Full time' },
    { value: 'Part time', label: 'Part time' },
    { value: 'By hours', label: 'By hours' },
  ]

  const experienciaRequerida = [
    { value: '0', label: 'Sin experiencia' },
    { value: '0.5', label: '6 meses' },
    { value: '1', label: '1 año' },
    { value: '2', label: '2 años' },
    { value: '3', label: '3 años' },
    { value: '4', label: '4 años' },
    { value: '5', label: '5 años' },
    { value: '6', label: '6 años' },
    { value: '7', label: '7 años' },
    { value: '8', label: '8 años' },
    { value: '9', label: '9 años' },
    { value: '10', label: '10 años' },
    { value: 'más de 10', label: 'más de 10 años' },
  ]


  const requiredExperience = [
    { value: '0', label: 'No experience' },
    { value: '0.5', label: '6 months' },
    { value: '1', label: '1 year' },
    { value: '2', label: '2 years' },
    { value: '3', label: '3 years' },
    { value: '4', label: '4 years' },
    { value: '5', label: '5 years' },
    { value: '6', label: '6 years' },
    { value: '7', label: '7 years' },
    { value: '8', label: '8 years' },
    { value: '9', label: '9 years' },
    { value: '10', label: '10 years' },
    { value: 'more than 10', label: 'more than 10 years' },
  ]

  const nivelIngles = [
    { value: 'Lectura', label: 'Lectura' },
    { value: 'Conversacional', label: 'Conversacional' },
    { value: 'Fluido', label: 'Fluido' },
    { value: 'Nativo', label: 'Nativo' },
  ]


  const englishLevel = [
    { value: 'Reading', label: 'Reading' },
    { value: 'Conversational', label: 'Conversational' },
    { value: 'Fluent', label: 'Fluent' },
    { value: 'Native', label: 'Native' },
  ]

  const handleImageChange = (e) => {
    setImg(e.target.files[0]);
    setImgPrev(URL.createObjectURL(e.target.files[0]))
  }

  const beneficios = [
    { value: 'Salud', label: 'Salud' },
    { value: 'Comisión / bono', label: 'Comisión / bono' },
    { value: 'Caja de compensación', label: 'Caja de compensación' },
    { value: 'Seguro de vida', label: 'Seguro de vida' },
    { value: 'Seguro de accidentes', label: 'Seguro de accidentes' },
    { value: 'Seguro de desempleo', label: 'Seguro de desempleo' },
    { value: 'Otro', label: 'Otro' },
  ]
  const benefits = [
    { value: 'Health', label: 'Health' },
    { value: 'Commission / bond', label: 'Commission / bond' },
    { value: 'Compensation box', label: 'Compensation box' },
    { value: 'Life insurance', label: 'Life insurance' },
    { value: 'Accident insurance', label: 'Accident insurance' },
    { value: 'Unemployment insurance', label: 'Unemployment insurance' },
    { value: 'Other', label: 'Other' },
  ]

  return (
    <div>
      <Sidebar location={'unilever home'} />
      <div
        className="BodyWeb"
        style={{
          padding: "0rem 2rem",
          position: "absolute",
          right: "0",
        }}
      >
        <Navbar locate={'home'} />
        <div style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
        }}>
          <form>
            <div className={'card-grupo-preguntas2'}>
              <div className={'new-empresa-home-banner'}>
                <h1>{lang === 'es' ? '¡Estas a un paso de encontrar al candidato ideal!' : 'You are one step away from finding the ideal candidate!'}</h1>
              </div>
              <div style={{ padding: '10px 20px' }}>
                <h2
                  className={'banner-crear-oportunidad-st'}>{lang === 'es' ? 'Por favor, lee y responde el siguiente formulario' : 'Please read and answer the next form'}</h2>
                <h3 className={'Obligatorio'}>{lang === 'es' ? '* Campos obligatorios' : '* Required Fields'}</h3>
              </div>
            </div>
            <hr />
            {!user.avatar ? <div>
              <div className={'card-grupo-preguntas'}>
                <h3 className='title-crear-oportunidad'>
                  {lang === 'es' ? '¿Deseas agregar el logo de tu empresa?' : 'Do you want to add your company logo?'}
                </h3>
                {
                  img ?
                    <div style={{
                      width: '100%',
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      alignItems: 'center',
                      marginBottom: '16px'
                    }}>
                      <img src={imgPrev} alt="logo" style={{ width: '150px', height: '150px', objectFit: "cover" }} />
                    </div>
                    : null
                }
                <label style={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  cursor: 'pointer',
                  backgroundColor: '#F07130',
                  borderRadius: '5px',
                  fontSize: '14px',
                  color: '#fff',
                  height: '40px',
                }}>
                  {lang === 'es' ? 'Cargar logo de tu empresa' : 'Upload your company logo'}
                  <input style={{ display: 'none' }} type="file" name="file" onChange={(e) => handleImageChange(e)} />
                </label>
              </div>
              <hr />
            </div> : null}
            {/*tipo de servicio*/}
            <div className={'card-grupo-preguntas'}>
              <h3 className='title-crear-oportunidad'>
                {lang === 'es' ? '¿Qué tipo de servicio deseas?' : 'What type of service do you want to offer?'}
                <span className={'Obligatorio'}> *</span>
              </h3>
              <div style={{ marginBottom: '25px' }}>
                <label className="control control-radio">
                  {lang === 'es' ? 'Contratación' : 'Contract'}
                  <input
                    type="radio"
                    name="tipoDeServicioDeseado"
                    value={'Contratación'}
                    checked={form?.tipoDeServicioDeseado === 'Contratación'}
                    onChange={(e) => handleFormChange(e, setForm, form)}
                  />
                  <div className="control_indicator"></div>
                </label>
                <label className="control control-radio">
                  Outsourcing (BPO) – Personalized services
                  <input
                    type="radio"
                    name="tipoDeServicioDeseado"
                    value={'Outsourcing (BPO) – Personalized services'}
                    checked={form?.tipoDeServicioDeseado === 'Outsourcing (BPO) – Personalized services'}
                    onChange={(e) => handleFormChange(e, setForm, form)}
                  />
                  <div className="control_indicator"></div>
                </label>
              </div>

              <h3 className='title-crear-oportunidad'>
                {lang === 'es' ? '¿Qué modalidad de contratación deseas?' : 'What type of contract would you like?'}
                <span className={'Obligatorio'}> *</span>
              </h3>
              {form?.modalidadDeContratacion && editable && <Select
                options={lang === 'es' ? modalidades : modalityes}
                placeholder={lang === 'es' ? 'Selecciona la modalidad' : 'Select the modality'}
                isSearchable={true}
                isClearable={true}
                defaultValue={{ value: form?.modalidadDeContratacion, label: form?.modalidadDeContratacion }}
                name={'modalidadDeContratacion'}
                onChange={(e) => setForm({ ...form, modalidadDeContratacion: e === null ? '' : e.value })}
              />}
              {(form?.modalidadDeContratacion === 'Otro' || form?.modalidadDeContratacion === 'Other') && editable && <input
                type="text"
                name="otraModalidadDeContratacion"
                value={form?.otraModalidadDeContratacion}
                onChange={(e) => handleFormChange(e, setForm, form)}
                placeholder={lang === 'es' ? 'Especifica la modalidad' : 'Specify the modality'}
                className={'input-crear-oportunidad'}
              />}
              {!editable && <Select
                options={lang === 'es' ? modalidades : modalityes}
                placeholder={lang === 'es' ? 'Selecciona la modalidad' : 'Select the modality'}
                isSearchable={true}
                isClearable={true}
                name={'modalidadDeContratacion'}
                onChange={(e) => setForm({ ...form, modalidadDeContratacion: e === null ? '' : e.value })}
              />}
              {
                !editable && (form?.modalidadDeContratacion === 'Otro' || form?.modalidadDeContratacion === 'Other') && <input
                  type="text"
                  name="otraModalidadDeContratacion"
                  value={form?.otraModalidadDeContratacion}
                  onChange={(e) => handleFormChange(e, setForm, form)}
                  placeholder={lang === 'es' ? 'Especifica la modalidad' : 'Specify the modality'}
                  className={'input-crear-oportunidad'}
                />
              }

              <h3 className='title-crear-oportunidad'>
                {lang === 'es' ? '¿Qué modalidad de trabajo?' : 'What type of work?'}
                <span className={'Obligatorio'}> *</span>
              </h3>
              <div style={{ marginBottom: '25px' }}>
                <label className="control control-radio">
                  {lang === 'es' ? 'Presencial' : 'On-site'}
                  <input
                    type="radio"
                    name="modalidadDeTrabajo"
                    value={'Presencial'}
                    checked={form?.modalidadDeTrabajo === 'Presencial'}
                    onChange={(e) => handleFormChange(e, setForm, form)}
                  />
                  <div className="control_indicator"></div>
                </label>
                <label className="control control-radio">
                  {lang === 'es' ? 'Remoto' : 'Remote'}
                  <input
                    type="radio"
                    name="modalidadDeTrabajo"
                    value={'Remoto'}
                    checked={form?.modalidadDeTrabajo === 'Remoto'}
                    onChange={(e) => handleFormChange(e, setForm, form)}
                  />
                  <div className="control_indicator"></div>
                </label>
                <label className="control control-radio">
                  {lang === 'es' ? 'Híbrida' : 'Hybrid'}
                  <input
                    type="radio"
                    name="modalidadDeTrabajo"
                    value={'Híbrida'}
                    checked={form?.modalidadDeTrabajo === 'Híbrida'}
                    onChange={(e) => handleFormChange(e, setForm, form)}
                  />
                  <div className="control_indicator"></div>
                </label>
              </div>
              <h3 className='title-crear-oportunidad'>
                {lang === 'es' ? '¿Tipo de jornada laboral?' : 'Type of working day?'}
                <span className={'Obligatorio'}> *</span>
              </h3>
              {
                form?.modalidadDeJornada.length >= 0 &&
                editable && <Select
                  options={lang === 'es' ? jornadas : schedules}
                  placeholder={lang === 'es' ? 'Selecciona la modalidad' : 'Select the modality'}
                  isSearchable={true}
                  isClearable={true}
                  value={{ value: form?.modalidadDeJornada, label: form?.modalidadDeJornada }}
                  name={'modalidadDeJornada'}
                  onChange={(e) => setForm({ ...form, modalidadDeJornada: e.value })}
                />}
              {!editable && <Select
                options={lang === 'es' ? jornadas : schedules}
                placeholder={lang === 'es' ? 'Selecciona la modalidad' : 'Select the modality'}
                isSearchable={true}
                isClearable={true}
                name={'modalidadDeJornada'}
                onChange={(e) => setForm({ ...form, modalidadDeJornada: e.value })}
              />}


              <h3 className='title-crear-oportunidad'>
                {lang === 'es' ? '¿Deseas publicar de forma anónima?' : 'Do you want to post anonymously?'}
                <span className={'Obligatorio'}> *</span>
              </h3>
              <div>
                <label className="control control-radio">
                  {lang === 'es' ? 'Si' : 'Yes'}
                  <input
                    type="radio"
                    name="Anonimo"
                    value={'Si'}
                    checked={form?.Anonimo === 'Si'}
                    onChange={(e) => handleFormChange(e, setForm, form)}
                  />
                  <div className="control_indicator"></div>
                </label>
                <label className="control control-radio">
                  No
                  <input
                    type="radio"
                    name="Anonimo"
                    value={'No'}
                    checked={form?.Anonimo === 'No'}
                    onChange={(e) => handleFormChange(e, setForm, form)}
                  />
                  <div className="control_indicator"></div>
                </label>
              </div>
            </div>
            <hr />

            {/*data del encargado y empresa*/}
            <div className={'card-grupo-preguntas'}>
              <h3 className='title-crear-oportunidad'>
                {lang === 'es' ? 'Nombre del encargado del proceso.' : 'Name of the person in charge of the process.'}
                <span className={'Obligatorio'}> *</span>
              </h3>
              <input
                style={{ marginBottom: '25px' }}
                className={'input-crear-oportunidad'}
                placeholder={'Ej: Mario Lopez'}
                name={'nombreEncargado'}
                value={form.nombreEncargado}
                onChange={(e) => handleFormChange(e, setForm, form)}
              />

              <h3 className='title-crear-oportunidad'>
                {lang === 'es' ? '¿Cuál es el tipo de documento?' : 'What is the type of document?'}
                <span className={'Obligatorio'}> *</span>
              </h3>
              {form?.tipo_documento && editable && <Select
                options={lang === 'es' ? tipoDocumento : documentType}
                placeholder={lang === 'es' ? 'Selecciona el tipo de documento' : 'Select the type of document'}
                isSearchable={true}
                isClearable={true}
                name={'tipo_documento'}
                defaultValue={{ value: form?.tipo_documento, label: form?.tipo_documento }}
                onChange={(e) => setForm({ ...form, tipo_documento: e === null ? '' : e.value })}
              />}

              {!editable && <Select
                options={lang === 'es' ? tipoDocumento : documentType}
                placeholder={lang === 'es' ? 'Selecciona el tipo de documento' : 'Select the type of document'}
                isSearchable={true}
                isClearable={true}
                name={'tipo_documento'}
                defaultValue={{ value: form?.tipo_documento, label: form?.tipo_documento }}
                onChange={(e) => setForm({ ...form, tipo_documento: e === null ? '' : e.value })}
              />
              }

              <h3 className='title-crear-oportunidad'>
                {lang === 'es' ? '¿Cuál es su número de identificación?' : 'What is your identification number?'}
                <span className={'Obligatorio'}> *</span>
              </h3>
              <input
                style={{ marginBottom: '25px' }}
                type={'number'}
                className={'input-crear-oportunidad'}
                placeholder={''}
                name={'documento'}
                value={form.documento}
                onChange={(e) => handleFormChange(e, setForm, form)}
              />

              <h3 className='title-crear-oportunidad'>
                {lang === 'es' ? 'Numero de teléfono del encargado del proceso.' : 'Phone number of the person in charge of the process.'}
                <span className={'Obligatorio'}> *</span>
              </h3>
              <PhoneInput
                country={'us'}
                regions={'america'}
                enableLongNumbers={true}
                placeholder={lang === "es" ? 'Teléfono' : 'Phone number'}
                value={form.numeroEncargado}
                inputStyle={{
                  width: '100%',
                  height: '40px',
                  background: '#FFFFFF',
                  marginBottom: '25px',
                  border: '1px solid #C5C5C5',
                  textIndent: '5px',
                  borderRadius: '0px',
                }}
                onChange={
                  (phone, country, event, formatNumber) => {
                    setForm({
                      ...form,
                      numeroEncargado: formatNumber,
                    })
                  }
                } />
            </div>
            <hr />

            {/*data de la oportunidad*/}
            <div className={'card-grupo-preguntas'}>
              <h3 className='title-crear-oportunidad'>
                {lang === 'es' ? 'Nombre de la oportunidad, cargo, proyecto o rol.' : 'Name of opportunity, position or project.'}
                <span className={'Obligatorio'}> *</span>
              </h3>
              <input
                className={'input-crear-oportunidad'}
                placeholder={'Ej: Jefe de ventas'}
                name={'NombreOportunidad'}
                value={form.NombreOportunidad}
                onChange={(e) => handleFormChange(e, setForm, form)}
              />

              <h3 className='title-crear-oportunidad'>
                {lang === 'es' ? '¿Cantidad de vacantes disponibles?' : 'How many professionals do you want to hire?'}
                <span className={'Obligatorio'}> *</span>
              </h3>
              <input
                className={'input-crear-oportunidad'}
                type={'number'}
                min={0}
                placeholder={'Ej: 5'}
                name={'profesionales'}
                value={form.profesionales}
                onChange={(e) => handleFormChange(e, setForm, form)}
              />

              <h3 className='title-crear-oportunidad'>
                {lang === 'es' ? '¿Cuál es el sector económico de la oportunidad?' : 'What is the economic sector of the opportunity?'}
                <span className={'Obligatorio'} style={{ marginLeft: '10px', fontSize: '10px' }}>opcional</span>
              </h3>
              <Creatable
                isClearable={true}
                isSearchable={true}
                options={lang === 'es' ? economySector.es : economySector.en}
                placeholder={lang === 'es' ? 'Selecciona el sector económico, si no encuentras el sector puedes crearlo' : 'Select the economic sector, if you do not find the sector you can create it'}
                onChange={(e) => setForm({ ...form, sector_economico: e === null ? '' : e.value })}
                formatCreateLabel={lang === 'es' ? (inputValue) => `Agregar "${inputValue}"` : (inputValue) => `Add "${inputValue}"`}
                value={form.sector_economico ? { value: form.sector_economico, label: form.sector_economico } : null}
              />

              <h3 className='title-crear-oportunidad'>
                {lang === 'es' ? 'Salario base' : 'Base salary'}
                <span className={'Obligatorio'}></span></h3>
              {divisa && editable && <Select
                options={monedas}
                placeholder={lang === 'es' ? 'Selecciona la divisa que quieres usar' : 'Select the currency you want to use'}
                isSearchable={true}
                isClearable={true}
                defaultValue={{ value: divisa, label: divisa }}
                onChange={(e) => {
                  setDivisa(e.value)
                  setForm({
                    ...form,
                    presupuesto: `${e.value} ${formatterPeso.format(montoMin)}`
                  })
                }}
              />}
              {!editable && <Select
                options={monedas}
                placeholder={lang === 'es' ? 'Selecciona la divisa que quieres usar' : 'Select the currency you want to use'}
                isSearchable={true}
                isClearable={true}
                onChange={(e) => {
                  setDivisa(e.value)
                  setForm({
                    ...form,
                    presupuesto: `${e.value} ${formatterPeso.format(montoMin)}`
                  })
                }}
              />
              }
              <input
                className={'input-crear-oportunidad'}
                type={'number'}
                min={0}
                placeholder={lang === 'es' ? 'Escriba el salario' : 'Write the salary'}
                name={'montoMin'}
                value={montoMin}
                onKeyPress={(e) => {
                  if (!/[0-9]/.test(e.key)) {
                    e.preventDefault();
                  }
                }}
                // onChange={(e) => setMontoMin(e.target.value)}
                onChange={(e) => {
                  if (!divisa) {
                    return toast.error(lang === 'es' ? 'Por favor selecciona una divisa' : 'Please select a currency', preset)
                  }
                  setMontoMin(e.target.value)
                  return setForm({
                    ...form,
                    presupuesto: `${divisa} ${formatterPeso.format(e.target.value)}`
                  })
                }}
              />
              <h3 className='title-crear-oportunidad'>
                {lang === 'es' ? '¿Si el profesional excede tus expectativas, cuanto estarías dispuesto a pagar?'
                  : 'If the professional exceeds your expectations, how much would you be willing to pay?'}
              </h3>
              <input
                className={'input-crear-oportunidad'}
                type={'number'}
                min={0}
                placeholder={lang === 'es' ? 'Escriba el salario extra' : 'Write the extra salary'}
                name={'montoExtra'}
                onKeyPress={(e) => {
                  if (!/[0-9]/.test(e.key)) {
                    e.preventDefault();
                  }
                }}
                onChange={(e) => {
                  if (!divisa) {
                    return toast.error(lang === 'es' ? 'Por favor selecciona una divisa' : 'Please select a currency', preset)
                  }
                  return setForm({
                    ...form,
                    extra_salario: `${divisa} ${formatterPeso.format(e.target.value)}`
                  })
                }}
                defaultValue={form.extra_salario ? Number(form.extra_salario.split(' ')[1].replace(/\./g, '')) : ''}
              />


              <h3 className='title-crear-oportunidad'>
                {lang === 'es' ? 'Experiencia mínima requerida' : 'Minimum experience required'}
                <span className={'Obligatorio'} style={{ marginLeft: '10px', fontSize: '10px' }}>opcional</span></h3>
              <Select
                options={lang === 'es' ? experienciaRequerida : requiredExperience}
                placeholder={lang === 'es' ? 'Selecciona la experiencia requerida' : 'Select the required experience'}
                isSearchable={true}
                isClearable={true}
                onChange={e => setForm({ ...form, experienciaAnos: e === null ? '' : e.value })}
                value={form.experienciaAnos ? { value: form.experienciaAnos, label: form.experienciaAnos } : null}
              />

              <h3 className='title-crear-oportunidad'>
                {lang === 'es' ? '¿Cuál es el tiempo de duración de la oportunidad, cargo, proyecto o rol?' : 'What is your estimate for the duration of the opportunity?'}
                <span className={'Obligatorio'}> *</span></h3>
              <div>
                <label className="control control-radio">
                  {lang === 'es' ? 'Indefinida' : 'Indefinite'}
                  <input
                    type="radio"
                    name={'estimadoDuración'}
                    value={lang === 'es' ? 'Indefinida' : 'Indefinite'}
                    checked={form.estimadoDuración === (lang === 'es' ? 'Indefinida' : 'Indefinite')}
                    onChange={(e) => handleFormChange(e, setForm, form)}
                  />
                  <div className="control_indicator"></div>
                </label>
                <label className="control control-radio">
                  {lang === 'es' ? '1 mes' : '1 month'}
                  <input
                    type="radio"
                    name={'estimadoDuración'}
                    value={lang === 'es' ? '1 mes' : '1 month'}
                    checked={form.estimadoDuración === (lang === 'es' ? '1 mes' : '1 month')}
                    onChange={(e) => handleFormChange(e, setForm, form)}
                  />
                  <div className="control_indicator"></div>
                </label>
                <label className="control control-radio">
                  {lang === 'es' ? 'Otro' : 'Other'}
                  <input
                    type="radio"
                    name={'estimadoDuración'}
                    value={lang === 'es' ? 'Otro' : 'Other'}
                    checked={form.estimadoDuración === (lang === 'es' ? 'Otro' : 'Other')}
                    onChange={(e) => handleFormChange(e, setForm, form)}
                  />
                  <div className="control_indicator"></div>
                </label>
              </div>

              <h3 className='title-crear-oportunidad'>
                {lang === 'es' ? 'Haz una pequeña descripción de la oportunidad, cargo, proyecto o rol.' : 'Make a brief description of the opportunity, position, project or role.'}
                <span className={'Obligatorio'}> *</span></h3>
              <textarea
                className={'text-crear-oportunidad'}
                name={'DescribeProyecto'}
                value={form.DescribeProyecto}
                onChange={(e) => handleFormChange(e, setForm, form)}
              />

              <h3 className='title-crear-oportunidad'>
                {lang === 'es' ? 'Responsabilidades y funciones del puesto.' : 'Responsibilities and functions of the position.'}
                <span className={'Obligatorio'}> *</span></h3>
              <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                {form?.responsabilidadYfunciones?.length > 0 ?
                  form?.responsabilidadYfunciones?.map((item, index) => {
                    return (
                      <div key={index} style={{ marginRight: '20px', marginBottom: '20px' }}>
                        <Chip
                          sx={{
                            background: '#E5DCF7',
                            borderRadius: '5px',
                            fontSize: '14px',
                            color: '#202256',
                            padding: '10px',
                          }}
                          label={item}
                          onDelete={() => handleDelete('responsabilidadYfunciones', item)}
                          style={{ textTransform: 'capitalize' }} />
                      </div>)
                  })
                  : null}
              </div>
              <div>
                <form style={{ marginBottom: '20px', display: 'flex', justifyContent: 'space-between' }} onKeyPress={
                  (e) => {
                    if (e.key === 'Enter') {
                      e.preventDefault();
                    }
                  }
                }>
                  <input
                    className='input-add-resp'
                    type="text"
                    placeholder={lang === 'es' ? 'Escribe aquí' : 'Write here'}
                    onChange={(e) => setResponsabilidades(e.target.value)} />
                  <AddButtonOpportunity
                    color={schneider.color.primary}
                    disabled={!responsabilidades}
                    onClick={() => setForm({
                      ...form,
                      responsabilidadYfunciones: [...form.responsabilidadYfunciones, responsabilidades]
                    })
                    }>{lang === 'es' ? 'Agregar' : 'Add'}</AddButtonOpportunity>
                </form>
              </div>
              {/* <textarea
                className={'text-crear-oportunidad'}
                name={'responsabilidadYfunciones'}
                value={form.responsabilidadYfunciones}
                onChange={(e) => handleFormChange(e, setForm, form)}
              /> */}

              <h3 className='title-crear-oportunidad'>
                {lang === 'es' ? 'Detallar comisiones o beneficios, si los hay.' : 'Detail commissions or benefits, if exist.'}
              </h3>
              {!editable && beneficios && <Creatable
                isMulti
                isDisabled={false}
                isSearchable={true}
                isClearable={true}
                options={lang === 'es' ? beneficios : benefits}
                placeholder={lang === 'es' ? 'Escriba los beneficios' : 'Write the benefits'}
                onChange={(e) => {
                  setForm({ ...form, listadoBeneficios: e === null ? [] : e.map(e => e.value) })
                }}
                formatCreateLabel={lang === 'es' ? (inputValue) => `Agregar "${inputValue}"` : (inputValue) => `Add "${inputValue}"`}
              />}
              {
                form.listadoBeneficios &&
                editable && beneficios &&
                <Creatable
                  options={lang === 'es' ? beneficios : benefits}
                  isMulti
                  isDisabled={false}
                  placeholder={lang === 'es' ? 'Selecciona o escribe las profesiones necesarias' : 'Select or write the necessary professions'}
                  isSearchable={true}
                  isClearable={true}
                  defaultValue={form?.listadoBeneficios?.map((item) => {
                    return { value: item, label: item }
                  })}
                  onChange={e => setForm({ ...form, listadoBeneficios: e === null ? [] : e.map(item => item.value) })}
                  formatCreateLabel={lang === 'es' ? (inputValue) => `Agregar "${inputValue}"` : (inputValue) => `Add "${inputValue}"`}
                />}

              {
                form?.listadoBeneficios?.includes('Otro' || 'Other') &&
                <div>
                  <h3 className='title-crear-oportunidad'>
                    {lang === 'es' ? 'Especifique el beneficio' : 'Specify the benefit'}
                    <span className={'Obligatorio'}> *</span></h3>
                  <textarea
                    className={'text-crear-oportunidad'}
                    name={'descripcionBeneficios'}
                    value={form.descripcionBeneficios}
                    onChange={(e) => handleFormChange(e, setForm, form)}
                  />
                </div>
              }
              {
                form?.listadoBeneficios?.includes('Comisión / bono' || 'Commission / bond') &&
                <div>
                  <h3 className='title-crear-oportunidad'>
                    {lang === 'es' ? 'Especifique comisión / bono' : 'Specify commission / bond'}
                    <span className={'Obligatorio'}> *</span></h3>
                  <textarea
                    className={'text-crear-oportunidad'}
                    name={'descripcionComision'}
                    value={form.descripcionComision}
                    onChange={(e) => handleFormChange(e, setForm, form)}
                  />
                </div>
              }

              <h3 className='title-crear-oportunidad'>
                {lang === 'es' ? 'Si tu oportunidad reposa en una plataforma externa, coloca el link aquí.' : 'If your opportunity rests on an external platform, place the link here.'}
              </h3>
              <input
                className={'input-crear-oportunidad'}
                placeholder={'Ej: https://empleos.net/123123123'}
                name={'link'}
                value={form.link}
                onChange={(e) => handleFormChange(e, setForm, form)}
              />

              {/*TODO: REVISAR SI ESTO ES LO MISMO QUE LA EXPERIENCIA REQUERIDA, DE MOMENTO SE COMENTA*/}
              {/*<h3 className='title-crear-oportunidad'>
                {lang === 'es' ? '¿Cuál es el tiempo de la experiencia relacionada?' : 'What is the time of the related experience?'}
                <span className={'Obligatorio'}> *</span></h3>
              <input
                className={'input-crear-oportunidad'}
                name={'tiempo_experiencia'}
                value={form.tiempo_experiencia}
                onChange={(e) => handleFormChange(e, setForm, form)}
                placeholder={'2 meses'}/>*/}
              <h3 className='title-crear-oportunidad'>
                {lang === 'es' ? 'Ingrese el país donde se registrará la oportunidad' : 'Enter the country where the opportunity will be registered'}
                <span className={'Obligatorio'}> *</span></h3>
              <input
                className={'input-crear-oportunidad'}
                name={'country'}
                value={form.country}
                onChange={(e) => handleFormChange(e, setForm, form)}
                placeholder={'Ej: Colombia'} />

              <h3 className='title-crear-oportunidad'>
                {lang === 'es' ? 'Ingrese el departamento donde se registrará la oportunidad' : 'Enter the department where the opportunity will be registered'}
                <span className={'Obligatorio'}> *</span></h3>
              <input
                className={'input-crear-oportunidad'}
                name={'departamento'}
                value={form.departamento}
                onChange={(e) => handleFormChange(e, setForm, form)}
                placeholder={'Ej: Valle del Cauca'} />

              <h3 className='title-crear-oportunidad'>
                {lang === 'es' ? 'Ingrese el municipio donde se registrará la oportunidad' : 'Enter the municipality where the opportunity will be registered'}
                <span className={'Obligatorio'}> *</span></h3>
              <input
                className={'input-crear-oportunidad'}
                name={'municipio'}
                value={form.municipio}
                onChange={(e) => handleFormChange(e, setForm, form)}
                placeholder={'Ej: Cali'} />
            </div>
            <hr />

            {/*data empleado*/}
            <div className={'card-grupo-preguntas'}>
              <h3 className='title-crear-oportunidad'>
                {lang === 'es' ? 'Selecciona la carrera del profesional o área de conocimiento. ' : "Select the professional's career or area of knowledge"}
                <span className={'Obligatorio'}> *</span></h3>
              <p className={'item-crear-oportunidad'}>
                {lang === 'es' ? 'Puedes seleccionar varias de la lista o crearlas' : "You can select several from the list or create them"}
              </p>
              {form.empleos_alternativos && editable && profesions &&
                <Creatable
                  options={profesions.map((item) => {
                    return { value: item.name, label: item.name }
                  })}
                  isMulti
                  isDisabled={false}
                  placeholder={lang === 'es' ? 'Selecciona o escribe las profesiones necesarias' : 'Select or write the necessary professions'}
                  isSearchable={true}
                  isClearable={true}
                  defaultValue={form.empleos_alternativos.map((item) => {
                    return { value: item, label: item }
                  })}
                  isOptionDisabled={() => form.empleos_alternativos.length >= 5}
                  onChange={e => setForm({ ...form, empleos_alternativos: e === null ? [] : e.map(item => item.value) })}
                  formatCreateLabel={lang === 'es' ? (inputValue) => `Agregar "${inputValue}"` : (inputValue) => `Add "${inputValue}"`}
                />}
              {!editable && profesions && <Creatable
                options={profesions.map((item) => {
                  return { value: item.name, label: item.name }
                })}
                isMulti
                isDisabled={false}
                placeholder={lang === 'es' ? 'Selecciona o escribe las profesiones necesarias' : 'Select or write the necessary professions'}
                isSearchable={true}
                isClearable={true}
                isOptionDisabled={() => form.empleos_alternativos.length >= 5}
                onChange={e => setForm({ ...form, empleos_alternativos: e === null ? [] : e.map(item => item.value) })}
                formatCreateLabel={lang === 'es' ? (inputValue) => `Agregar "${inputValue}"` : (inputValue) => `Add "${inputValue}"`}
              />}

              <h3 className='title-crear-oportunidad'>
                {lang === 'es' ? 'Deseas que el candidato tenga titulo?' : "Do you want the candidate to have a degree"}
                <span className={'Obligatorio'}> *</span></h3>
              <div style={{ marginBottom: '20px' }}>
                <label className="control control-radio">
                  {lang === 'es' ? 'Con título o Egresado' : 'With title or Graduated'}
                  <input
                    type="checkbox"
                    name="Niveldeconocimiento1"
                    value={lang === 'es' ? 'Con titulo' : 'Professional'}
                    checked={form?.academico?.includes(lang === 'es' ? 'Con titulo' : 'Professional')}
                    onChange={(e) => handleChangeAcademico(e, form, setForm)}
                  />
                  <div className="control_indicator"></div>
                </label>
                <label className="control control-radio">
                  {lang === "es" ? "Sin título" : "No title"}
                  <input
                    type="checkbox"
                    name="Niveldeconocimiento2"
                    value={lang === "es" ? "Sin titulo" : "No title"}
                    checked={form?.academico?.includes(lang === "es" ? "Sin titulo" : "No title")}
                    onChange={(e) => handleChangeAcademico(e, form, setForm)}
                  />
                  <div className="control_indicator"></div>
                </label>
              </div>

              <h3 className='title-crear-oportunidad'>
                {lang === 'es' ? 'Selecciona la carrera del profesional o área de conocimiento. ' : "Select the professional's career or area of knowledge"}
                <span className={'Obligatorio'}> *</span></h3>
              <div style={{ marginBottom: '20px' }}>
                <label className="control control-radio">
                  {lang === 'es' ? 'Profesional' : 'Professional'}
                  <input
                    type="checkbox"
                    name="Niveldeconocimiento1"
                    value={lang === 'es' ? 'Profesional' : 'Professional'}
                    checked={form?.Niveldeconocimiento?.includes(lang === 'es' ? 'Profesional' : 'Professional')}
                    onChange={(e) => handleChangeNivel(e, form, setForm)}
                  />
                  <div className="control_indicator"></div>
                </label>
                <label className="control control-radio">
                  {lang === "es" ? "Técnico/tecnólogo" : "Technical"}
                  <input
                    type="checkbox"
                    name="Niveldeconocimiento2"
                    value={lang === "es" ? "Técnico/tecnólogo" : "Technical"}
                    checked={form?.Niveldeconocimiento?.includes(lang === "es" ? "Técnico/tecnólogo" : "Technical")}
                    onChange={(e) => handleChangeNivel(e, form, setForm)}
                  />
                  <div className="control_indicator"></div>
                </label>
              </div>

              <h3 className='title-crear-oportunidad'>
                {lang === 'es' ? 'Selecciona las habilidades blandas necesarias para el puesto.' : "Select the soft skills needed for the position."}
                <span className={'Obligatorio'}> *</span></h3>
              <p className={'item-crear-oportunidad'}>
                {lang === 'es' ? 'Puedes seleccionar varias de la lista' : "You can select several from the list"}
              </p>


              <Select
                options={lang === 'es' ? habilidadesBlandas : softSkills}
                placeholder={
                  lang === 'es'
                    ? 'Selecciona o escribe las habilidades blandas'
                    : 'Select or write soft skills'
                }
                isSearchable={true}
                isClearable={true}
                onChange={(e) => handleSelectChange(e.value, 'habilidadesBlandas')}
              />

              {form.habilidadesBlandas.length > 0 ? (
                form.habilidadesBlandas.map((item, index) => {
                  if (typeof item === 'object') {
                    return (
                      <div key={index}
                           style={{
                             marginBottom: '10px',
                             display: 'flex',
                             alignItems: 'center',

                           }}
                      >
                        <Chip
                          sx={{
                            background: '#E5DCF7',
                            borderRadius: '5px',
                            fontSize: '14px',
                            color: '#202256',
                            padding: '10px',
                          }}
                          label={item.name}
                          onDelete={() => handleDelete('habilidadesBlandas', item.name)}
                          style={{ textTransform: 'capitalize' }}
                        />
                        <input
                          type="range"
                          className='Range-Ability-Form-Create'
                          value={item.value ? item.value : '0'} // El valor se carga aquí
                          onChange={(event) => handleRangeChange(event, index)} // Pasamos el índice del array
                        />
                        <span style={{ marginLeft: '10px' }}>{item.value}%</span>
                      </div>
                    );
                  } else {
                    return null;
                  }
                })
              ) : null}

              <h3 className='title-crear-oportunidad'>
                {lang === 'es' ? 'Selecciona las habilidades técnicas necesarias para el puesto.' : "Select the hard skills needed for the position."}
                <span className={'Obligatorio'}> *</span></h3>
              <p className={'item-crear-oportunidad'}>
                {lang === 'es' ? 'Puedes seleccionar varias de la lista o crearlas' : "You can select several from the list or create them"}
              </p>
              {form?.habilidadesTecnicas && editable &&
                <Creatable
                  isMulti
                  options={profesions.map((item) => {
                    return { value: item.name, label: item.name }
                  })}
                  placeholder={lang === 'es' ? 'Selecciona o escribe las habilidades técnicas' : 'Select or write technical skills'}
                  isSearchable={true}
                  isClearable={true}
                  defaultValue={form.habilidadesTecnicas.map((item) => {
                    return { value: item, label: item }
                  })}
                  // isOptionDisabled={() => form.habilidadesTecnicas.length >= 5}
                  formatCreateLabel={lang === 'es' ? (inputValue) => `Agregar "${inputValue}"` : (inputValue) => `Add "${inputValue}"`}
                  onChange={e => setForm({ ...form, habilidadesTecnicas: e === null ? [] : e.map(item => item.value) })}
                />}
              {!editable &&
                <Creatable
                  isMulti
                  options={profesions.map((item) => {
                    return { value: item.name, label: item.name }
                  })}
                  placeholder={lang === 'es' ? 'Selecciona o escribe las habilidades técnicas' : 'Select or write technical skills'}
                  isSearchable={true}
                  isClearable={true}
                  // isOptionDisabled={() => form.habilidadesTecnicas.length >= 5}
                  formatCreateLabel={lang === 'es' ? (inputValue) => `Agregar "${inputValue}"` : (inputValue) => `Add "${inputValue}"`}
                  onChange={e => setForm({ ...form, habilidadesTecnicas: e === null ? [] : e.map(item => item.value) })}
                />
              }

              <h3 className='title-crear-oportunidad'>
                {lang === 'es' ? 'Idioma (selección múltiple)' : 'Language (multiple choice)'}
                <span className={'Obligatorio'}> *</span></h3>
              <div style={{ marginBottom: '20px' }}>
                <label className="control control-radio">
                  {lang === "es" ? "Inglés" : "English"}
                  <input
                    type="checkbox"
                    name="Lenguaje1"
                    value={lang === "es" ? "Inglés" : "English"}
                    checked={form?.Lenguaje?.includes(lang === "es" ? "Inglés" : "English")}
                    onChange={(e) => handleChangeLanguage(e, form, setForm)}
                  />
                  <div className="control_indicator"></div>
                </label>
                {form?.Lenguaje?.includes(lang === "es" ? "Inglés" : "English") ?
                  <div style={{ marginBottom: '20px' }}>
                    <Select
                      options={lang === 'es' ? nivelIngles : englishLevel}
                      placeholder={lang === 'es' ? 'Selecciona el nivel de inglés' : 'Select english level'}
                      isSearchable={true}
                      isClearable={true}
                      onChange={e => setForm({ ...form, nivel_ingles: e === null ? '' : e.value })}
                      defaultValue={form.nivel_ingles ? { value: form.nivel_ingles, label: form.nivel_ingles } : null}
                    />
                  </div>
                  : null}
                <label className="control control-radio">
                  {lang === 'es' ? 'Español' : 'Spanish'}
                  <input
                    type="checkbox"
                    name="Lenguaje2"
                    value={lang === 'es' ? 'Español' : 'Spanish'}
                    checked={form?.Lenguaje?.includes(lang === 'es' ? 'Español' : 'Spanish')}
                    onChange={(e) => handleChangeLanguage(e, form, setForm)}
                  />
                  <div className="control_indicator"></div>
                </label>
                <label className="control control-radio">
                  {lang === "es" ? "Otro" : "Other"}
                  <input
                    type="checkbox"
                    onClick={() => setOtherLang(!otherLang)}
                  />
                  <div className="control_indicator"></div>
                </label>
                <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                  {form?.otroLenguaje?.length > 0 ?
                    form?.otroLenguaje?.map((item, index) => {
                      if (typeof item === 'object') {
                        return (
                          <div key={index} style={{ marginRight: '20px', marginBottom: '20px' }}>
                            <Chip
                              sx={{
                                background: '#E5DCF7',
                                borderRadius: '5px',
                                fontSize: '14px',
                                color: '#202256',
                                padding: '10px',
                              }}
                              label={item.idioma + '-' + item.nivel}
                              onDelete={() => handleDelete('otroLenguaje', item)}
                              style={{ textTransform: 'capitalize' }} />
                          </div>)
                      } else {
                        return null
                      }
                    })
                    : null}
                </div>
                {otherLang ?
                  <div style={{ marginBottom: '20px' }}>
                    <form>
                      <input
                        className='input-add-language'
                        type="text"
                        placeholder={lang === 'es' ? 'Escribe el idioma' : 'Write the language'}
                        onChange={(e) => setAddOtherLang(
                          {
                            ...addOtherLang,
                            idioma: e.target.value
                          }
                        )}
                      />
                      <div style={{ marginBottom: '20px' }}>
                        <Select
                          options={lang === 'es' ? nivelIngles : englishLevel}
                          placeholder={lang === 'es' ? `Selecciona el nivel` : `Select the level`}
                          isSearchable={true}
                          isClearable={true}
                          onChange={e => setAddOtherLang({
                            ...addOtherLang,
                            nivel: e === null ? '' : e.value
                          })
                          }
                        />
                        <AddButtonOpportunity
                          color={schneider.color.primary}
                          type="reset"
                          disabled={addOtherLang.idioma === '' || addOtherLang.nivel === ''}
                          onClick={() => setForm({
                            ...form,
                            otroLenguaje: [...form.otroLenguaje, addOtherLang]
                          })
                          }>{lang === 'es' ? 'Agregar' : 'Add'}</AddButtonOpportunity>
                      </div>
                    </form>
                  </div>

                  : null}

              </div>
            </div>
            <hr />
            {
              userPlan && userPlan?.nombre_paquete !== 'Plan empresarial' ?
                <div>
                  <div className={'card-grupo-preguntas'}>
                    <h3 className='title-crear-oportunidad'>
                      {lang === 'es' ? '¿Qué modalidad de pago vas a usar?' : 'What payment method are you going to use?'}
                      <span className={'Obligatorio'}> *</span></h3>
                    <label className="control control-radio">
                      {lang === 'es' ? 'Gratis' : 'Free'}
                      <input
                        type="radio"
                        name={'modalidaddepago'}
                        value={lang === 'es' ? 'Gratis' : 'Free'}
                        onChange={(e) => handleFormChange(e, setForm, form)}
                      />
                      <div className="control_indicator"></div>
                    </label>
                    <label className="control control-radio">
                      Standard
                      <input
                        type="radio"
                        name={'modalidaddepago'}
                        value={'Standard'}
                        onChange={(e) => handleFormChange(e, setForm, form)}
                      />
                      <div className="control_indicator"></div>
                    </label>
                    <label className="control control-radio">
                      Bussiness
                      <input
                        type="radio"
                        name={'modalidaddepago'}
                        value={'Bussiness'}
                        onChange={(e) => handleFormChange(e, setForm, form)}
                      />
                      <div className="control_indicator"></div>
                    </label>
                  </div>
                  <hr />
                </div>
                : null
            }
            {/*codigo promocional*/}
            {
              userPlan && userPlan?.nombre_paquete !== 'Plan empresarial' ?
                <div>
                  <div className={'card-grupo-preguntas'}>
                    <h3 className='title-crear-oportunidad'>
                      {lang === 'es' ? 'Si tienes un código promocional puedes validarlo aquí' : 'If you have a promotional code you can validate it here'}
                    </h3>
                    <input className={'input-crear-oportunidad'}
                           placeholder={lang === 'es' ? 'Código promocional...' : 'Promotional code...'}
                           onChange={(e) => setPromoCode(e.target.value)} />
                    <button
                      onClick={(e) => sendPromoCode(e, promoCode, setValidCode, user.id)}
                      className={'submit-button-crear-oportunidad'}
                    >{lang === 'es' ? 'Comprobar código' : 'Check code'}</button>
                  </div>
                  <hr />
                </div>
                : null
            }
            <CreateButton
              color={schneider.color.primary}
              onClick={(e) => {
                console.log(form)
                setisSubmit(true)
                handleSubmit(e, form, lang, editable, id, userPlan?.nombre_paquete, user?.email, validCode, user.id, montoMin, setisSubmit, img, promoCode)
              }}
               disabled={isSubmit}>
              {editable
                ? lang === 'es' ? 'Actualizar oportunidad' : 'Update opportunity'
                : lang === 'es' ? 'Crear oportunidad' : 'Create opportunity'}
            </CreateButton>
          </form>
        </div>
      </div>
    </div>
  );
}

export default CreateOpportunity;
