import React, {useEffect} from 'react';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import CircleIcon from '@mui/icons-material/Circle';
import Checkbox from '@mui/material/Checkbox';
import Typography from '@mui/material/Typography';
import './CrearOportunidadNew.css'
import { useDispatch } from 'react-redux';
import { saveOportunityForm } from '../../actions';

export default function Knowledge(props) {
  const {form, setform, step, handleStep, lang, handleBackStep, oportunityForm} = props;
  const [button, setButton] = React.useState(true);
  const dispatch = useDispatch();
  useEffect(() => {
    if (oportunityForm?.Niveldeconocimiento?.length ? oportunityForm?.Niveldeconocimiento?.length > 0 : form?.Niveldeconocimiento?.length > 0) {
      setButton(false);
    }
    return () => {
      setButton(true);
    }
    //eslint-disable-next-line
  }, [form.Niveldeconocimiento]);

  const handleChangeKnowledge = (e) => {
    if (e.target.checked) {
       setform({
        ...form,
        Niveldeconocimiento: [...form?.Niveldeconocimiento, e.target.value]
      })
      dispatch(saveOportunityForm({Niveldeconocimiento: [...oportunityForm?.Niveldeconocimiento, e.target.value]}, step));
    } else {
     setform({
        ...form,
        Niveldeconocimiento: form?.Niveldeconocimiento?.filter((item) => item !== e.target.value)
      })
      dispatch(saveOportunityForm({Niveldeconocimiento: oportunityForm?.Niveldeconocimiento?.filter((item) => item !== e.target.value)}, step));
    }
  }
  return (
    <>
      <div className='contenedorChooseType'>
        <h3
          className='contenedorChooseType_title'>{lang === 'es' ? ' Nivel de conocimiento (selección multiple)' : 'Knowledge level (multiple choice)'}</h3>
        <div>
          <FormGroup
            aria-labelledby="demo-controlled-radio-buttons-group"
            name="tipoDeServicioDeseado"
            className='chooseTypeRadio'
          >
            <FormControlLabel name="Niveldeconocimiento" value="Profesional" control={<Checkbox
              checked={oportunityForm?.Niveldeconocimiento?.length ? oportunityForm?.Niveldeconocimiento?.includes('Profesional') : form?.Niveldeconocimiento?.includes('Profesional')}
              icon={<CircleIcon className='radio_unchecked_icon'/>}
              checkedIcon={<CircleIcon className='radio_checked_icon'/>}/>} onChange={(e) => handleChangeKnowledge(e)}
                              label={<Typography sx={{fontSize: '14px', fontFamily: 'Poppins', fontWeight: '400'}}>
                                {lang === 'es' ? 'Profesional' : 'Professional'}
                              </Typography>}/>

            <FormControlLabel value="Técnico/tecnólogo" name="Niveldeconocimiento"
                              onChange={(e) => handleChangeKnowledge(e)} control={<Checkbox
              checked={oportunityForm?.Niveldeconocimiento?.length ? oportunityForm?.Niveldeconocimiento?.includes('Técnico/tecnólogo') : form?.Niveldeconocimiento?.includes('Técnico/tecnólogo')}
              icon={<CircleIcon className='radio_unchecked_icon'/>}
              checkedIcon={<CircleIcon className='radio_checked_icon'/>}/>}
                              label={<Typography sx={{fontSize: '14px', fontFamily: 'Poppins', fontWeight: '400'}}>
                                {lang === "es" ? "Técnico/tecnólogo" : "Technical"}
                              </Typography>}/>
          </FormGroup>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          width: "100%",
          justifyContent: "space-between",
          padding: "1rem 3rem 0rem 3rem",
          alignItems: "center",
        }}
      >
        <button className='button_siguiente_create_B'
                onClick={handleBackStep}>{lang === 'es' ? 'Anterior' : 'Previous'}</button>
        <h3 className='stepCounter'>{`${step}/14`}</h3>
        <button className='button_siguiente_create'
                onClick={()=>handleStep('Niveldeconocimiento')} disabled={button}>{lang === 'es' ? 'Siguiente' : 'Next'}</button>
      </div>
    </>
  )
}