import React, {useEffect, useState} from 'react';
//import UserTable from '../components/UserData';
import '../Styles/manage.css';
import clsx from 'clsx';
import {makeStyles} from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Badge from '@material-ui/core/Badge';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Link from '@material-ui/core/Link';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import {mainListItems} from '../../../components/Dashboard/lisItems';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import GroupIcon from '@material-ui/icons/Group';
import AccountTreeIcon from '@material-ui/icons/AccountTree';
import AddIcon from '@material-ui/icons/Add';
import "../../../components/Dashboard/Styles/styles.css";
import CoallierTableComponent from '../components/CoallierTableComponent';
// import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'
import axios from "axios";
import {CSVLink} from "react-csv";

const drawerWidth = 240;

const modelo = {
  "contacto": [
    "www.linkedin.com/in/alvaro-eljach-movilla  (LinkedIn)"
  ],
  "aptitudes_principales": [
    "Asesor",
    "Estrategia empresarial",
    "Liderazgo de equipos"
  ],
  "languages": [
    {
      "Language": "Inglés  ",
      "Nivel": "(Elementary)"
    },
    {
      "Language": "Español  ",
      "Nivel": "(Native or Bilingual)"
    }
  ],
  "certifications": [
    "EF SET English Certificate 53/100 (B2 Upper Intermediate)"
  ],
  "honors": [],
  "extracto": " Proactivo, con excelente manejo de trabajo en equipo, buscando la efectividad en los procesos y optimización de recursos, responsable, motivado a un aprendizaje continuo. Cuento con experiencia en mantenimiento preventivo y correctivo de maquinarias pesadas, equipos industriales, móviles y de taller, programas (TPM). Excelente capacidad en planificación y ejecución de programas de mantenimiento que incrementen la eficiencia de los equipos, reducción de costos y la aplicación adecuada de las normas de calidad y seguridad. Con conocimientos en el área administrativa, logística y de supervisión de personal.",
  "experiencia": [
    {
      "Nombreempresa": "ALTECA Alta Tecnología en Calefacción",
      "totalDuracion": "(undefined",
      "cargos": [
        {
          "nombrecargo": "Ejecutivo comercial",
          "duracion": "marzo de 2022 - Present  (1 año)",
          "ubicacion": "Colombia",
          "descripcion": "- Programar reuniones con nuevos clientes para ofrecerles el equipo que se adapte a sus necesidades. - Realizar presentaciones ofreciendo productos y servicios. - Preparar cotizaciones, negociar y cerrar contratos. - Colaborar con el equipo de trabajo para obtener resultados óptimos. "
        },
        {
          "nombrecargo": "Logros: Implementación de cambio en el formato de cotización lo cual",
          "duracion": "optimizo en alrededor de un 40% su tiempo de realización, contribuyendo a",
          "ubicacion": "una mayor eficiencia del equipo de ventas.",
          "descripcion": ""
        }
      ]
    },
    {
      "Nombreempresa": "Carmax Colombia",
      "totalDuracion": "(undefined",
      "cargos": [
        {
          "nombrecargo": "Asesor comercial",
          "duracion": "mayo de 2021 - febrero de 2022  (10 meses)",
          "ubicacion": "Colombia",
          "descripcion": "- Asesorar y negociar con el cliente en la compra y/o venta del vehículo. - Seguimiento a tramites administrativo por la compra o venta de vehículo. - Lograr la fidelización de los clientes. - Impulsar la apertura a nuevos clientes. "
        },
        {
          "nombrecargo": "Logros:  Modificar el sistema de inducción al personal nuevo agilizando su",
          "duracion": "aprendizaje en las labores de compra-venta.",
          "ubicacion": "",
          "descripcion": ""
        }
      ]
    },
    {
      "Nombreempresa": "EQUIPMED MANAGEMENT S.A",
      "totalDuracion": "(undefined",
      "cargos": [
        {
          "nombrecargo": "Coordinador comercial",
          "duracion": "febrero de 2019 - octubre de 2019  (9 meses)",
          "ubicacion": "Venezuela",
          "descripcion": "Empresa de suministros al mayor, de insumos y  equipos de protección y seguridad industrial. - Desarrollar estrategias de Marketing. - Definir la estrategia comercial de la compañía. - Realizar presupuestos y/o cotizaciones. - Apertura y cierre de negocios. - Establecer objetivos de ventas y supervisar sus resultados. - Supervisar de las labores del personal a cargo. "
        },
        {
          "nombrecargo": "Logros: Incorporación de nuevos clientes lo cual significo un 30% de aumento",
          "duracion": "en las ventas y genero mas estabilidad a la empresa.",
          "ubicacion": "",
          "descripcion": ""
        }
      ]
    },
    {
      "Nombreempresa": "Ventas independiente",
      "totalDuracion": "(undefined",
      "cargos": [
        {
          "nombrecargo": "Representante de ventas",
          "duracion": "junio de 2017 - enero de 2019  (1 año 8 meses)",
          "ubicacion": "Venezuela",
          "descripcion": "Ventas al mayor y al detal de productos de higiene y cuidado bucal. "
        },
        {
          "nombrecargo": "- Estudio de mercado.",
          "duracion": "- Impulsar la apertura a nuevos clientes.",
          "ubicacion": "- Estrategias de ventas según el movimiento del mercado.",
          "descripcion": "- Brindar servicio a los clientes. - Contacto con Proveedores. - Cobro de cartera. - Control del inventario.  - Asesoramiento a los clientes sobre los nuevos productos."
        }
      ]
    },
    {
      "Nombreempresa": "LINGORCA, C.A",
      "totalDuracion": "(undefined",
      "cargos": [
        {
          "nombrecargo": "Coordinador de montaje",
          "duracion": "julio de 2016 - octubre de 2017  (1 año 4 meses)",
          "ubicacion": "Pto Ordaz, Edo Bolivar, Venezuela",
          "descripcion": "Empresa dedicada a la fundición de metales. Proyecto: Instalación de máquina lingotera que comprende la estructura, componentes mecánicos, tuberías, electricidad entre otros.                                                             - Supervisar el cumplimiento de la programación de la obra tales como soldadura de estructuras, ensamblaje y montaje de equipos. - Inspección de los equipos previo a su montaje. - Inspección diaria de herramientas y equipos a utilizar. - Velar por la seguridad del personal y correcto uso de equipos y herramientas. - Administración de insumos al personal a cargo. "
        },
        {
          "nombrecargo": "Logros: Implemente mejoras en el proceso de montaje el cual ayudo a",
          "duracion": "acelerar en un 30% la construcción de la lingotera.",
          "ubicacion": "",
          "descripcion": ""
        }
      ]
    },
    {
      "Nombreempresa": "Montaje Reconstruccion Y Mantenimiento, c,a",
      "totalDuracion": "(4 años 3 meses)",
      "cargos": [
        {
          "nombrecargo": "11 años",
          "duracion": "Coordinador de mantenimiento",
          "ubicacion": "enero de 2013 - abril de 2016  (3 años 4 meses)",
          "descripcion": "Pto Ordaz, Bolviar, Venezuela Empresa dedicada al montaje, reconstrucción y mantenimiento de plantas industriales y alquiler de equipos móviles. Servicios prestados principalmente a la Siderúrgica del Orinoco en diferentes áreas de mantenimiento así como alquiler de grúas sobre camión. "
        },
        {
          "nombrecargo": "- Elaboración del plan anual de mantenimiento preventivo y predictivo de los",
          "duracion": "equipos de la empresa.",
          "ubicacion": "-  Realizar el presupuesto anual de gastos de mantenimiento y supervisar su",
          "descripcion": "ejecución mensual. - Supervisar las actividades de mantenimiento preventivo y predictivo a equipos del taller y a los vehículos de la empresa. - Velar que el personal a cargo realice su trabajo bajo lineamientos de seguridad y calidad. -  Control mensual del inventario. "
        },
        {
          "nombrecargo": "Logros: Se logró un 60% más de eficiencia en el rendimiento de las maquinas",
          "duracion": "gracias al plan de mantenimiento (TPM) preventivo desarrollado.",
          "ubicacion": "",
          "descripcion": ""
        },
        {
          "nombrecargo": "Supervisor de logistica",
          "duracion": "febrero de 2012 - diciembre de 2012  (11 meses)",
          "ubicacion": "Puerto Ordaz, Venezuela",
          "descripcion": "Proyecto: Montaje de marcos de sellos en compuertas de la represa TOCOMA, en el que fui encargado de toda el área de logística que implicaba solicitar y entregar a tiempo los insumos necesarios para la ejecución de la obra así como los implementos de seguridad de los trabajadores "
        },
        {
          "nombrecargo": "- Control del inventario requerido para la ejecución del trabajo.",
          "duracion": "- Realizar libro diario de avance de la obra.",
          "ubicacion": "- Elaborar solicitudes de compras de insumos requeridos para el buen",
          "descripcion": "funcionamiento de equipos y maquinarias del proyecto. - Hacer seguimiento a solicitudes de compra hasta su entrega - Planificar y supervisar las actividades del personal a cargo. "
        },
        {
          "nombrecargo": "Logros: Se llevo la logística al 100% contribuyendo al avance y culminación a",
          "duracion": "tiempo de la obra.",
          "ubicacion": "",
          "descripcion": ""
        },
        {
          "nombrecargo": "Técnico de mantenimiento",
          "duracion": "agosto de 2009 - enero de 2012  (2 años 6 meses)",
          "ubicacion": "Puerto Ordaz, Venezuela",
          "descripcion": "- Elaborar el plan de mantenimiento, verificando su correcta ejecución en equipos del taller y vehículos de la empresa. -  Supervisar labores del personal a cargo. -  Estimación de costos de mantenimiento mensual. -  Control mensual del inventario. "
        },
        {
          "nombrecargo": "Logros: Se diseño e implemento la plataforma de un camión con todos los",
          "duracion": "insumos necesarios para realizar los mantenimientos en las diferentes áreas",
          "ubicacion": "donde se encontraran las grúas aumentando su productividad.",
          "descripcion": ""
        },
        {
          "nombrecargo": "Analista Administrativo",
          "duracion": "mayo de 2005 - julio de 2009  (4 años 3 meses)",
          "ubicacion": "Puerto Ordaz Venezuela",
          "descripcion": "- Elaboración Libros de Ventas y Compras en Excel. - Preparación Nómina bajo Sistema Saint  - Solicitud de cotizaciones y análisis de selección de proveedores. - Elaborar y adjudicar órdenes de compra. - Generar, analizar y administrar reportes de ahorro y gastos para mejorar la rentabilidad. "
        }
      ]
    }
  ],
  "educacion": [
    {
      "NombreInstitucion": "Universidad Nororiental Gran Mariscal de Ayacucho",
      "Titulo_Certificacion": "Ingenieria, Mantenimiento mención Industrial",
      "duracion": "(2005 - 2010)"
    },
    {
      "NombreInstitucion": "Universidad Católica Andrés Bello",
      "Titulo_Certificacion": "Diplomatura, Marketing Estratégico",
      "duracion": "(2016 - 2016)"
    },
    {
      "NombreInstitucion": "Instituto Universitario de tecnologia Antonio Jose de Sucre",
      "Titulo_Certificacion": "Tecnico, Mecanico",
      "duracion": "(2006 - 2009)"
    }
  ],
  "info_personal": {
    "nombre": "Alvaro Eljach Movilla",
    "profesion_actual": "| Ingeniero de Mantenimiento | Industrial | Asesor Comercial |",
    "ubicacion": "Distrito Capital, Colombia"
  },
  "usuario": {
    "phoneNumber": "+57 315 305 0759",
    "_id": "63dc11c54a6f99001814e73a",
    "username": "Alvaro Eljach Movilla",
    "email": "alvaro_eljach@hotmail.com"
  },
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: 'none',
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    width: 1180,
    height: 760,
  },
}));

export default function ManageusersCoallier(props) {

  let headers1 = []
  for (let key in modelo) {

    if (key === 'languages' || key === 'educacion') {
      modelo[key].forEach((item, index) => {

        if (typeof item === 'object' && !Array.isArray(item)) {
          for (let key2 in item) {
            headers1.push({label: key2, key: `${key}.${index}.${key2}`})
          }
          return
        }
        for (let key2 in item) {
          headers1.push({label: key2, key: `${key}.${index}.${key2}`})
        }
      })
      continue
    }

    if (key === 'experiencia') {
      modelo[key].forEach((item, index) => {
        for (let key2 in item) {
          // si el key es cargos, aplanar el array
          if (key2 === 'cargos') {
            item[key2].forEach((item2, index2) => {
              for (let key3 in item2) {
                headers1.push({label: key3, key: `${key}.${index}.${key2}.${index2}.${key3}`})
              }
            })
            continue
          }
          headers1.push({label: key2, key: `${key}.${index}.${key2}`})
        }
      })
      continue
    }

    //si el key === 'info_personal', aplanar el objeto
    if (key === 'info_personal' || key === 'info_personal') {
      for (let key2 in modelo[key]) {
        headers1.push({label: key2, key: `${key}.${key2}`})
      }
      continue
    }
    //si el key === 'usuario', aplanar el objeto
    if (key === 'usuario') {
      for (let key2 in modelo[key]) {
        headers1.push({label: key2, key: `${key}.${key2}`})
      }
      continue
    }
    headers1.push({label: key, key: key})
  }

  //key === 'experiencia'

  const handleLogout = () => {
    document.cookie =
      "ActualProject=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/";
    document.cookie =
      "ActualProjec=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/";
    document.cookie = "Aemail=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/";
    document.cookie = "Aname=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/";
    document.cookie = "Aid=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/";
    document.cookie = "Atoken=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/";

    document.cookie =
      "Aprojects=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/";

    document.cookie = "Aform=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/";

    document.cookie = "Aroles=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/";

    props.logoutUser({}, "/");
  };

  const classes = useStyles();
  const [open, setOpen] = React.useState(true);
  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };
  const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);

  const [cvs, setCvs] = useState();
  const [refresh, setRefresh] = useState(false);

  useEffect(() => {
    axios.get('https://findally-backend.herokuapp.com/api/user/getAllCV/ ')
      .then(
        res => {
          setCvs(res.data.data)
          setRefresh(true)
        }
      )
  }, [])

  return (
    <div className={classes.root}>
      {/* <ToastContainer /> */}
      <CssBaseline/>
      <AppBar position="absolute" className={clsx(classes.appBar, open && classes.appBarShift)}>
        <Toolbar className={classes.toolbar}>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            className={clsx(classes.menuButton, open && classes.menuButtonHidden)}
          >
            <MenuIcon/>
          </IconButton>
          <Typography component="h1" variant="h6" color="inherit" noWrap className={classes.title}>
            Dashboard
          </Typography>
          <IconButton color="inherit">
            <Badge color="secondary">
              <li className="header__icons__group-li">
                <GroupIcon style={{color: '#FFFFFF'}}/>
                <p>Administración</p>
                <ul className="header__display-menu_admin">
                  <li className="header__display__menu-perfil-ver-perfil">
                    <AccountCircleIcon style={{color: '#540E87', fontSize: '40px'}}/>
                    <div className="header__display__menu__perfil-data">
                      {/*<p>Username</p>*/}
                      <p className="header-display-name-coallier">ADMIN</p>
                      <Link to="/admin/managment/users">
                        <p className="header-display-see-profile">Managment</p></Link>
                    </div>
                  </li>

                  <Link to="/admin/managment/users">
                    <li className="header__display__menu-item">
                      <GroupIcon style={{color: '#540E87', fontSize: '20px'}}/>
                      <p className="menu-text">Manage users</p>
                    </li>
                  </Link>
                  <Link to="/admin/managment/projects">
                    <li className="header__display__menu-item">
                      <AccountTreeIcon style={{color: '#540E87', fontSize: '20px'}}/>
                      <p className="menu-text">Manage projects</p>
                    </li>
                  </Link>
                  <Link to="/admin/match">
                    <li className="header__display__menu-item">
                      <AddIcon style={{color: '#540E87', fontSize: '20px'}}/>
                      <p className="menu-text">Match</p>
                    </li>
                  </Link>

                  <Link to="#" onClick={handleLogout}>
                    <li className="header__display__menu-item-cerrar">
                      <ExitToAppIcon style={{color: '#540E87', fontSize: '20px'}}/>
                      <p className="menu-text">Cerrar sesion</p>
                    </li>
                  </Link>

                  <p className="header__display_copy">© 2021 CoAlly | Powered by CoAlly</p>
                </ul>
              </li>
            </Badge>
          </IconButton>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        classes={{
          paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
        }}
        open={open}
      >
        <div className={classes.toolbarIcon}>
          <IconButton onClick={handleDrawerClose}>
            <ChevronLeftIcon/>
          </IconButton>
        </div>
        <Divider/>
        <List>{mainListItems}</List>
        <Divider/>
      </Drawer>
      <main className={classes.content}>
        <div className={classes.appBarSpacer}/>
        <Container maxWidth="lg" className={classes.container}>
          <Grid container spacing={3}>
            {/* Chart */}
            <Grid item xs={12} md={8} lg={9}>
              <Paper className={fixedHeightPaper}>
                <CoallierTableComponent/>
                {refresh && cvs.length > 0
                  ? (<CSVLink {...{
                    filename: 'CVs.csv',
                    data: cvs,
                    headers: headers1
                  }}
                              style={{
                                backgroundColor: "#540E87",
                                color: "white",
                                borderRadius: "5px",
                                padding: "10px",
                                fontSize: "15px",
                                marginTop: "-10px",
                                width: "200px",
                              }}>
                    Descargar CVS
                  </CSVLink>)
                  : null}
              </Paper>
            </Grid>
          </Grid>
        </Container>
      </main>
    </div>
  );
}
