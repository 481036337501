import React, { useEffect, useState } from 'react'
import DataTable from 'react-data-table-component';
import axios from 'axios'


const TablaAsistenciaMasterclass = () => {

    const [data, setData] = useState([])

  const tableColumns = [
    {
      name: 'Nombre',
      selector: row => row.name,
      sortable: true,
    },
    {
      name: 'Asistentes',
      selector: row => row.count,
      sortable: true,
    },
    {
      name: 'Detalles',
      cell: row => <div>{row?.clicked_by?.map(user => user?.name).join(', ')}</div>,
    },
  ];

    useEffect(() => {
      axios.get('https://findally-backend.herokuapp.com/api/unilever/assistance')
        .then(res => {
          setData(res.data.data)
        })
        .catch(err => console.log(err))
    }, [])

  const customStyles = {
    table: {
      style: {
        minHeight: '150px',
      },
    },
    headCells: {
      style: {
        fontWeight: 'bold',
        fontSize: '14px',
      },
    },
    cells: {
      style: {
        // textTransform: 'capitalize',
      },
    },
  };

  return (
    <div style={{
      display: 'flex',
      paddingInline: '3em',
      alignItems: 'center',
      justifyContent: 'space-between',
      marginTop:'2em'
    }}>
      <div style={{
        width: '70%',
      }}>
        <DataTable
          title="Asistencias a Masterclass"
          columns={tableColumns}
          data={data}
          customStyles={customStyles}
          pagination
          paginationPerPage={5}
          paginationRowsPerPageOptions={[5, 10, 15, 20, 25, 30]}
          paginationComponentOptions={{
            rowsPerPageText: 'Filas por página:',
            rangeSeparatorText: 'de',
            noRowsPerPage: false,
            selectAllRowsItem: true,
            selectAllRowsItemText: 'Todos',
          }}
          fixedHeader
          fixedHeaderScrollHeight="600px"
          highlightOnHover
          pointerOnHover
          noDataComponent="No hay datos"
        />
      </div>
    </div>
  );
}

export default TablaAsistenciaMasterclass
