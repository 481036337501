import React, { useEffect, useRef } from "react";
import { connect } from "react-redux";
import { formConsultor, ConsultorData } from "../../actions";
import "./Styles/ConsultorResume.css";
import HeaderEmpresa from "../../components/HeaderEmpresa/HeaderEmpresa";
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import BeenhereIcon from '@material-ui/icons/Beenhere';
import LanguageIcon from '@material-ui/icons/Language';
import PersonIcon from '@material-ui/icons/Person';
import Footer from "../../components/Footer/Footer";
//import img_profile from '../../assets/imagenes/profile_pic.jpeg'
import en from "../../lang/en-US";
import es from "../../lang/es-COL"
import counterpart from "counterpart";
import Translate from "react-translate-component";
import avatarborroso from '../Companies/Styles/avatarborroso.png'

function ConsultorResume(props) {

  const {
    match: { params }, 
  } = props;
  const messageRef = useRef()
  useEffect(() => {
    const getUser = () => {
      props.ConsultorData(params.coallyform);
      //console.log(params)
    };
    // eslint-disable-next-line
    getUser();
    // eslint-disable-next-line
  }, []);
  if (messageRef.current) {
    messageRef.current.scrollIntoView(
      {
        behavior: 'smooth',
        block: 'end',
        inline: 'nearest'
      })
  }
  counterpart.registerTranslations('en', en)
  counterpart.registerTranslations('es', es)
  counterpart.setLocale(localStorage.getItem('langlocal'));
  return (
    <>
      <div style={{background:'rgb(33 33 86)',position:'fixed',top:'0',right:'0',width:'28%',height:'100vh',zIndex:'-1'}}>

      </div>
      {props.userform ?
      <>
      <div className='container_header__resume'>
      <div className='header_resume'><HeaderEmpresa/></div>
        
      <img className="logo_resume_derecha" src="https://coally.com/wp-content/uploads/2021/10/Logo-0-1.png" alt="logo" />
      <div className="perfil-resume">
        <div className="perfil-header-resume" ref={messageRef}>
          <div className="perfil__header-profile-resume">
            <img style={{width:'20%',height:'17rem',borderRadius:'10%',border:'0.2rem solid rgb(33 33 86)',zIndex:'3'}} src={avatarborroso} alt='#'></img>
            <div className="lineamedioperfil" style={{width:'87%',background:'rgb(44 46 117)',height:'11rem',position:'absolute',marginLeft:'3%',zIndex:'-2',display:'flex',flexDirection:'column'}}>
            <h2 className="perfil__header__profile-txt-resume">
            {props.userform.nombre?props.userform.nombre.split(" ")[0] || "Consultor":"Consultor"}
            </h2>
            <h5 className="perfil__header__profile-txt-resume2">
            {props.userform.calificacionUno || <Translate content="Consultor" component="span"/>}
            </h5>
            </div>
            {/* <img alt='profile_pic' src={img_profile} style={{height:'110px', width:'110px', backgroundColor:'gray', borderRadius:'5px'}}/> */}
            {/* <h2 className="perfil__header__profile-txt-resume">
            {props.userform.nombre?props.userform.nombre.split(" ")[0] || "Consultor":"Consultor"}
            </h2> */}
            {/* 
            <h3 className="perfil__header__profile-profesion-txt-resume">
                {props.userform.descripcionEducacionUno || "Consultor"}
            </h3> */}
          </div>
        </div>
      </div>
      </div>
      <div className="content-resume-columns">
        <div className="general-resume-tittle">
          <div className="about-resume-content">
            <div className='titles_right_column'>
            <PersonIcon style={{color:'#F0814A'}}/>
            <h4 className="tittle-left-resume">{<Translate content="PERFIL" component="span"/>}</h4>
            </div>
            <p className="description-left-resume">
            {props.userform.description_profile || ""}
            </p>
            <div className='titles_right_column'>
            <LanguageIcon style={{color:'#F0814A'}}/>
            <h4 className="tittle-left-resume">{<Translate content="Lenguajes" component="span"/>}</h4>
            </div>
            <p className="description-left-resume">
              <ul className="list-left-resume">
                <li className="id1">{props.userform.Idioma
                  ? props.userform.Idioma.value || /* : */ "Español"
                  : "español"}</li>
              </ul>
            </p>
            <div className='titles_right_column'>
            <BeenhereIcon style={{color:'#F0814A'}}/>
            <h4 className="tittle-left-resume">{<Translate content="Habilidades" component="span"/>}</h4>
            </div>
            <p className="description-left-resume">
              {props.userform.skill1?
              <ul className="list-left-resume">
                <li className="id1">{props.userform.skill1 || ""}</li><br/>
                <li className="id1">{props.userform.skill2 || ""}</li><br/>
                <li className="id1">{props.userform.skill3 || ""}</li><br/>
                <li className="id1">{props.userform.skill4 || ""}</li><br/>
                <li className="id1">{props.userform.skill5 || ""}</li><br/>
                <li className="id1">{props.userform.skill6 || ""}</li>

              </ul>
              :"--"
              }
            </p>
          </div>
        </div>
        <div className="general-resume-content">
          <div className="content-one-education">
          <h4 className="tittle-left-resume2">{<Translate content="Descripcion" component="span"/>}</h4>
            <p className="description-left-resume"  style={{paddingLeft:'3rem'}}>
            {props.userform.descripcionEducacionUno || ""}
            </p>
          <h4 className="tittle-rigth-one-resume">{<Translate content="educacion" component="span"/>}</h4>
          <div className='calificacion_resume' style={{paddingLeft:'3rem'}}><RadioButtonUncheckedIcon style={{color:'#F0814A'}}/><p className="education-content-resume">          
          {props.userform.calificacionUno || <Translate content="Consultor" component="span"/>}
          </p></div>  
          <div className='calificacion_resume' style={{paddingLeft:'3rem'}}><RadioButtonUncheckedIcon style={{color:'#F0814A'}}/><p className="education-content-resume">
          {props.userform.descripcionEducacionUno || <Translate content="Consultor" component="span"/>}</p>
          </div></div>
          <div className="content-one-experience">
          <h4 className="tittle-rigth-one-resume">{<Translate content="Experiencia" component="span"/>}</h4>
          <div className='calificacion_resume' style={{paddingLeft:'3rem'}}><RadioButtonUncheckedIcon style={{color:'#F0814A'}}/><p className="experience-content-resume">
          {props.userform.position1 || ""}</p></div>
          <p className='institute_form'  style={{paddingLeft:'3rem'}}>
          {props.userform.institute1 || ""}</p>
          <p className='experience_form' style={{paddingLeft:'3rem'}}>
          {props.userform.experienceDescription1 || ""}</p>
          <div className='calificacion_resume' style={{paddingLeft:'3rem'}}><RadioButtonUncheckedIcon style={{color:'#F0814A'}}/><p className="experience-content-resume">
          {props.userform.position2 || ""}</p></div>
          <p className='institute_form' style={{paddingLeft:'3rem'}}>
          {props.userform.institute2 || ""}</p>
          <p className='experience_form' style={{paddingLeft:'3rem'}}>
          {props.userform.experienceDescription2 || ""}</p>
          </div>
        </div>
      </div>
    
      </>
      : <p>{<Translate content="Cargando" component="span"/>}</p>
    }
    
    <Footer />
    </>
  );
}
const mapDispatchToProps = {
  formConsultor,
  ConsultorData,
};

const mapStateToProps = (state) => {
  return {
    user: state.user,
    projects: state.projects,
    userform: state.userform,
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ConsultorResume);
