import React, { useState, useContext } from "react";
import { useSelector } from "react-redux";
import "./SidebarCv.css";
import Modal from "@mui/material/Modal";
import { Box } from "@mui/material";
import close from "../../../../assets/icons/close.svg";
import Share from "../../../../assets/icons/share.svg";
import { Link, useHistory } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import TourProfessional from "../../../components/tour/TourProfessional";
import axios from "axios";
import { toast } from "react-toastify";
import { ReduxStateInterface } from "../../../types/reduxInterface";
import { DefaultButton } from "../../buttons/ButtonStyles";
import {
  UploadCvButton,
  // UploadCvDisabled,
  TutorialPopupContainer,
  TutorialSpan,
} from "../SidebarStyles";
import { SchneiderContext } from "../../../context/SchneiderContext";
import { usePopupContext } from "../../../context/popups/PopupContext";
import ReactPlayer from "react-player";
import Popup from "../../popup/Popup";
export default function SidebarCv({ color }: { color?: string }) {
  const { popups, addPopup, removePopup } = usePopupContext();
  const { schneider } = useContext(SchneiderContext);
  const { lang, user, cv } = useSelector((state: ReduxStateInterface) => state);
  const [open, setOpen] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [message, setMessage] = useState(false);
  const history = useHistory();
  const cvButton = () => {
    setOpen(true);
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "100%",
    maxWidth: "722px",
    maxHeight: "672px",
    bgcolor: "#FFFFFF",
    borderRadius: "8px",
    borderColor: "#FFFFFF",
    boxShadow: "0px 7px 32px rgba(0, 0, 0, 0.1)",
    transition: "all 0.3s ease",
    overflow: "auto",
    margin: 0,
    padding: 0,
  };

  const changeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    // si el archivo no es pdf tirar error
    if (e.target.files[0].type !== "application/pdf") {
      setSelectedFile(null);
      return toast.error("El archivo debe ser un PDF", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
    setSelectedFile(e.target.files[0]);
    const fileData = new FormData();
    fileData.append("upload_file", e.target.files[0]);
    fileData.append("usuario", user.id);
    axios
      .post<any>(
        `https://findally-backend.herokuapp.com/api/user/validateCV?lang=${lang}`,
        fileData
      )
      .then((res) => {
        if (res.status === 200) {
          toast.success(res.data.message, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          return;
        }
      })
      .catch((err) => {
        if (err.response.status === 400) {
          setSelectedFile(null);
          setMessage(true);
          return toast.error("El archivo debe ser un Cv de linkedin", {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });

        }
      });
  };

  const handleUpload = () => {
    if (cv) {
      return toast.success("Ya tienes un CV subido", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
    const fd = new FormData();

    fd.append("upload_file", selectedFile);
    fd.append("usuario", user.id);

    axios
      .post("https://findally-backend.herokuapp.com/api/user/readCv", fd)
      .then((res) => {
        if (res.status === 200) {
          window.location.reload();
          return setOpen(false);
        }
      })
      .then((res) => {
        return toast.success("CV subido con éxito", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      })
      .then(() => {
        history.push(`/consultor/profile/${user.id}`);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleOpenTutorialPopUp = () => {
    setOpen(false);
    addPopup({
      id: "tutorial-cv",
      content: (
        <TutorialPopupContainer>
          <ReactPlayer
            url={"https://youtu.be/9NIF-vD-zvI"}
            controls={true}
            width={"100%"}
            height={"100%"}
          />
        </TutorialPopupContainer>
      ),
      onClose: () => removePopup({ id: "tutorial-cv" }),
    });
  };

  return (
    <>
      <Modal open={open} onClose={() => setOpen(false)}>
        <Box sx={style}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "space-between",
              width: "100%",
              flexDirection: "column",
            }}
          >
            <div className={"SidebarCV-cont"}>
              <button
                style={{ backgroundColor: "transparent" }}
                onClick={() => setOpen(false)}
              >
                <img src={close} alt="close" />
              </button>
              <div className={"SidebarCV-Sub-Cont"}>
                {!selectedFile ? (
                  <UploadCvButton
                    message={message}
                    color={schneider.color.primary}
                  >
                    {lang === "es" ? "Cargar CV LinkedIn" : "Charge LinkedIn CV"}
                    <img src={Share} alt="share" />
                    <input
                      onChange={(e) => changeHandler(e)}
                      type="file"
                      id="fileLi"
                      style={{ display: "none" }}
                    />
                  </UploadCvButton>
                ) : (
                  <UploadCvButton
                    onClick={() => setSelectedFile(null)}
                    textColor={schneider.color.primary}
                  >
                    <img src={close} alt="share" />
                    {lang === "es" ? "PDF Doc..." : "PDF Doc..."}
                  </UploadCvButton>
                )}
                {!selectedFile ? null : (
                  <UploadCvButton
                    onClick={() => handleUpload()}
                    color={schneider.color.primary}
                  >
                    {lang === "es" ? "Enviar CV Linkedin" : "Send Linkedin CV"}
                    <img src={Share} alt="share" />
                  </UploadCvButton>
                )}
              </div>
              <div className={"SidebarCV-Sub-Cont-2"}>
                {message ? (
                  <h2 className={"SidebarCV-Err"}>
                    {lang === "es"
                      ? "PDF descargado de LinkedIn. Si no sabes como hacerlo, mira el siguiente, "
                      : "PDF downloaded from LinkedIn. If you don't know how to do it, look at the following, "}
                    <TutorialSpan
                      color={schneider.color.primary}
                      onClick={() => handleOpenTutorialPopUp()}
                    >
                      {lang === "es"
                        ? " Tutorial de descarga"
                        : " Download tutorial"}
                    </TutorialSpan>
                  </h2>
                ) : (
                  <h2 className={"SidebarCV-Err"}>
                    {lang === "es"
                      ? "PDF descargado de LinkedIn, "
                      : "PDF downloaded from LinkedIn, "}
                    <TutorialSpan
                      color={schneider.color.primary}
                      onClick={() => handleOpenTutorialPopUp()}
                    >
                      {lang === "es"
                        ? " Tutorial de descarga"
                        : " Download tutorial"}
                    </TutorialSpan>
                  </h2>
                )}
                <hr style={{ width: "80%" }} />
                <h2 className={"SidebarCV-Sub-Text"}>
                  {lang === "es"
                    ? "Si no tienes Hoja de vida, puedes llenar el"
                    : "If you don't have a resume, you can fill out the"}
                  <Link
                    style={{ color: schneider.color.primary }}
                    to={`/${schneider.route}/cv`}
                  >
                    {lang === "es" ? " siguiente formulario." : " next form."}
                  </Link>
                </h2>
              </div>
            </div>
          </div>
        </Box>
      </Modal>
      <>
        {popups.map((popup) => (
          <Popup key={popup.id} id={popup.id} onClose={popup.onClose}>
            {popup.content}
          </Popup>
        ))}
      </>
      <div className={"contenedor-tour"}>
        <TourProfessional />
      </div>
      {cv ? null : (
        <DefaultButton onClick={() => cvButton()} background={color}>
          {lang === "es" ? "Subir hoja de vida" : "Upload CV"}
        </DefaultButton>
      )}
    </>
  );
}
