import React, { useState, useEffect } from 'react'
import CircularProgress from '@mui/material/CircularProgress';
import './CircularFrame.css'
import userImg from '../../assets/icons/user.svg'
import { useSelector } from 'react-redux';

export default function CircularFrame({ userInfo, cv, progressCompany, color }) {

  const [progress, setProgress] = useState(0)
  const {user} = useSelector((state) => state)

  useEffect(() => {
    let counter = 0;
    if (userInfo?.roles === 'coallier' ) {
      if (cv?.aptitudes_principales && cv?.aptitudes_principales?.length > 0) counter += 12.5
      if (cv?.certifications && cv?.certifications?.length > 0) counter += 12.5
      if (cv?.contacto && (cv?.contacto?.correo?.length > 0 || user?.email?.length !== 0)) counter += 12.5
      if (cv?.educacion && cv?.educacion?.length > 0 && cv?.educacion[0]?.NombreInstitucion !== "") counter += 12.5
      if (cv?.experiencia && cv?.experiencia?.length > 0 && cv?.experiencia[0]?.Nombreempresa !== "") counter += 12.5
      if (cv?.extracto && cv?.extracto !== "") counter += 12.5
      if (cv?.languages && cv?.languages?.length > 0) counter += 12.5
      if( userInfo?.avatar && userInfo?.avatar !== "")counter += 12.5
      setProgress(counter);
    }
    else {
      setProgress(progressCompany);
    }
    //eslint-disable-next-line
  }, [cv, progressCompany])

  return (
    <div className='CircularProfile'>
      { user?.id === userInfo?.id &&
      <>
      <CircularProgress variant="determinate" value={100} id='SecondCirclie' />
      <CircularProgress variant="determinate" value={progress} />
      </>
      }
      <div className='Avatar-container'>
        <img id='non-used' src={userInfo?.avatar ? userInfo?.avatar : userImg} alt={'user-pic'} />
      </div>
    </div>
  )
}
