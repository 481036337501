import React, { useEffect, useRef, useState } from 'react'
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import CloseIcon from '@mui/icons-material/Close';
import image from "./ImgPopUpPay.gif";

export default function PopUpPayInfo(props) {
  const { open, handleClose } = props;
  const Ref = useRef(null);
  const [timer, setTimer] = useState('50');

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '100%',
    maxWidth: '722px',
    height:  '460px',
    maxHeight: '672px',
    bgcolor: '#FFFFFF',
    borderRadius: '8px',
    borderColor: '#FFFFFF',
    boxShadow: '0px 7px 32px rgba(0, 0, 0, 0.1)',
    transition: 'all 0.3s ease',
    overflow: 'auto',
    margin: 0,
    padding: 0,
  };

  const getTimeRemaining = (e) => {
        const total = Date.parse(e) - Date.parse(new Date());
        const seconds = Math.floor((total / 1000) % 60);
        return {
            total, seconds
        };
    }

    const startTimer = (e) => {
        let { total, seconds } = getTimeRemaining(e);
        if (total > 0) {
            setTimer(
               (seconds > 9 ? seconds : '0' + seconds)
            )
        }else{
            handleClose();
        }
    }


    const clearTimer = (e) => {  
        setTimer('50');
        if (Ref.current) clearInterval(Ref.current);
        const id = setInterval(() => {
            startTimer(e);
        }, 1000)
        Ref.current = id;
    }

    const getDeadTime = () => {
        let deadline = new Date();
        deadline.setSeconds(deadline.getSeconds() + 50);
        return deadline;
    }

    useEffect(() => {
        clearTimer(getDeadTime());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
      <>
        <Modal
          className={'contenedorcrearOportunidadNew2'}
          open={open}
          disableAutoFocus={false}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <div style={{
              display: 'flex',
              justifyContent: 'space-evenly',
              alignItems: 'center',
              height: '100%',
              flexDirection: 'column',
            }}>
              <div>
                <CloseIcon onClick={() => { handleClose() }} style={{ cursor: 'pointer', position: 'absolute', left: 20, top: 20, color: '#C5C5C5' }} />
              </div>
                <img style={{
                    height: '50%'
                }} src={image} alt='moneda'/>
                <p style={{
                    padding: '2em',
                    textAlign: 'justify'
                }}>Estamos procesando su pago. <br/> El pago está sujeto a las restricciones de su banco, por lo que los cambios pueden visualizarse hasta una hora después. Desde Coally, lamentamos los inconvenientes que pueda tener</p>
                <h6>Este mensaje desaparece automaticamente en {timer} segundos</h6>
            </div>
          </Box>
        </Modal>
      </>
    )
  }