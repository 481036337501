import axios from "axios";

export const loadData = async (id) => {
  try {
    const response = await axios.get(
      `https://findally-backend.herokuapp.com/user/CVprofile/${id}`
    );
    return response.data;
  } catch (error) {
    console.error("Error loading data:", error);
    return null;
  }
};
