import React, { useContext } from "react";
import { useSelector } from "react-redux";
import { CvInterface } from "../../../types/cvInterface";
import { ReduxStateInterface } from "../../../types/reduxInterface";
import ProfessionalCard from "./cardconsultor/ProfessionalCard";
import {
  UserInfoContainer,
  UserInfoImageContainer,
  CompanyInfoImage,
  ViewProfileLinkContainer,
  ViewProfileText,
  UserInfoName,
  UserCompanyPlanContainer,
  UserCompanyPlan,
  UserInfoSeparator,
  UpgradeYourPLanLink,
} from "./UserInfoStyles";
import CircularFrameDropdown from "../circularframe/CircularFrameDropdown";
import { SchneiderContext } from "../../../context/SchneiderContext";

interface UserInfoProps {
  cv_file: CvInterface;
  status: string;
  message: string;
  nombre_empresa?: string;
}

interface UserInfoSelector extends ReduxStateInterface {
  userPlan: {
    nombre_paquete: string;
  };
}

const UserInfo = ({
  dataUser,
  UserInfoIsClosing,
}: {
  dataUser: UserInfoProps;
  UserInfoIsClosing: boolean;
}) => {
  const { schneider } = useContext(SchneiderContext);
  const { lang, user, userPlan, cv } = useSelector(
    (state: UserInfoSelector) => state
  );

  const planLanguage = (plan: string): string => {
    return plan === "Plan empresarial"
      ? "Business plan"
      : plan === "Plan Basico"
      ? "Standard plan"
      : "Free plan";
  };

  return (
    <UserInfoContainer isClosing={UserInfoIsClosing}>
      <UserInfoImageContainer>
        {user.roles === "company" ? (
          <CompanyInfoImage src={user.avatar} alt={"company user image"} />
        ) : (
          <CircularFrameDropdown user={user} cv={cv} />
        )}
      </UserInfoImageContainer>
      <ViewProfileLinkContainer
        to={
          user?.roles === "company"
            ? `/${schneider.route}/company/profile`
            : `/${schneider.route}/profile/${user?.id}`
        }
      >
        <ViewProfileText>
          {lang === "es" ? "Ver perfil" : "View profile"}
        </ViewProfileText>
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M22 12C22 12 19 18 12 18C5 18 2 12 2 12C2 12 5 6 12 6C19 6 22 12 22 12Z"
            strokeLinecap="round"
          />
          <circle cx="12" cy="12" r="3" strokeLinecap="round" />
        </svg>
      </ViewProfileLinkContainer>
      <UserInfoName>
        {dataUser?.nombre_empresa ? dataUser?.nombre_empresa : user?.name}
      </UserInfoName>
      {user?.roles === "company" ? (
        <UserCompanyPlanContainer>
          <UserInfoSeparator />
          <UserCompanyPlan>
            {planLanguage(userPlan?.nombre_paquete)}
          </UserCompanyPlan>
          <UpgradeYourPLanLink to="/company/upgrade">
            {lang === "es" ? "Mejora tu plan" : "Upgrade your plan"}
          </UpgradeYourPLanLink>
        </UserCompanyPlanContainer>
      ) : null}
      {cv ? (
        <ProfessionalCard
          nombre={
            cv?.info_personal?.nombre ? cv?.info_personal?.nombre : "Nombre"
          }
          profesion={cv?.info_personal?.profesion_actual}
          idiomas={cv?.languages}
          habilidades={cv?.aptitudes_principales?.slice(0, 3)}
          experiencia={cv?.experiencia}
          language={lang}
        />
      ) : null}
    </UserInfoContainer>
  );
};

export default UserInfo;
