import React, { Fragment, useState } from "react";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button";
import en from "../../lang/en-US";
import es from "../../lang/es-COL"
import counterpart from "counterpart";
import Translate from "react-translate-component";

// Destructuring props
const FirstStep = ({
  handleNext,
  handleChange,
  values: { email, linea_negocio, codigo_promo, nivel_ingles, habilidades_requeridas, comentario },
  formErrors
}) => {
  // Check if all values are not empty or if there are some error
  const isValid =
    email.length > 0 &&
    !formErrors.email &&
    linea_negocio.length > 0 &&
    !formErrors.linea_negocio &&
    nivel_ingles.length > 0 &&
    !formErrors.nivel_ingles &&
    habilidades_requeridas.length > 0 &&
    !formErrors.habilidades_requeridas &&
    comentario.length > 0 &&
    !formErrors.comentario;

  const [otroCausa, setotroCausa] = useState("")
  //const lang = useSelector(state => state.lang)


  counterpart.registerTranslations('en', en)
  counterpart.registerTranslations('es', es)
  counterpart.setLocale(localStorage.getItem('langlocal'));



  return (
    <Fragment>
      <Grid container spacing={2} noValidate>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label="Email"
            name="email"
            placeholder=""
            margin="normal"
            value={email || ""}
            onChange={handleChange}
            error={!!formErrors.email}
            helperText={formErrors.email}
            required
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label={<Translate content="QUENOMBRELEPONDRAS" component="span" />}
            name="linea_negocio"
            placeholder=""
            margin="normal" 
            value={linea_negocio || ""}
            onChange={handleChange}
            error={!!formErrors.linea_negocio}
            helperText={formErrors.linea_negocio}
            required
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label={<Translate content="CODIGOPROMOCIONAL" component="span" />}
            name="codigo_promo"
            placeholder="codigo promocional"
            margin="normal"
            value={codigo_promo || ""}
            onChange={handleChange} 
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label={<Translate content="CUALESSONLASMETRICASQUEQUIERES" component="span" />}
            name="nivel_ingles"
            placeholder=""
            margin="normal"
            value={nivel_ingles || ""}
            onChange={handleChange}
            error={!!formErrors.nivel_ingles}
            helperText={formErrors.nivel_ingles}
            required
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label={<Translate content="CUALESSONLOSOBJETIVOSQUEQUIERES" component="span" />}
            name="comentario"
            placeholder=""
            type=""
            value={comentario || ""}
            onChange={handleChange}
            margin="normal"
            required
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth margin="normal">
            <InputLabel>{<Translate content="Cuálescreesquesonlascausasdeesteproblema" component="span" />}</InputLabel>
            <Select value={habilidades_requeridas} onChange={handleChange} name="habilidades_requeridas">
              <MenuItem value={"Me hace falta capital"}>{<Translate content="capital" component="span" />}</MenuItem>
              <MenuItem value={"La pandemia acabó con mi negocio"}>{<Translate content="pandemia" component="span" />}</MenuItem>
              <MenuItem value={"El personal no está trabajando como esperaba"}>{<Translate content="Elpersonalnoestátrabajandocomoesperaba" component="span" />}</MenuItem>
              <MenuItem value={"Siento que estamos estancados"}>{<Translate content="Sientoqueestamosestancados" component="span" />}</MenuItem>
              <MenuItem value={"Quiero reestructrar las bases de mi negocio"}>{<Translate content="Quieroreestructrarlasbasesdeminegocio" component="span" />}</MenuItem>
              <MenuItem value={otroCausa} onClick={() => setotroCausa("otro")} >{<Translate content="Otro" component="span" />}</MenuItem>
            </Select>
            {otroCausa === 'otro' ?
              <TextField
                fullWidth
                label={<Translate content="Otro" component="span" />}
                name="habilidades_requeridas"
                placeholder=""
                margin="normal"
                value={habilidades_requeridas || ""}
                onChange={handleChange}
                error={!!formErrors.habilidades_requeridas}
                helperText={formErrors.habilidades_requeridas}

              /> : null}
          </FormControl>
        </Grid>
      </Grid>
      <div
        style={{ display: "flex", marginTop: 50, justifyContent: "flex-end" }}
      >
        <Button
          variant="contained"
          disabled={!isValid}
          color="primary"
          onClick={isValid ? handleNext : null}
        >
          {<Translate content="siguienteee" component="span"/>}
        </Button>
      </div>
    </Fragment>
  );
};

export default FirstStep;
