import React, { Fragment,  } from "react";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button";
import en from "../../lang/en-US";
import es from "../../lang/es-COL"
import counterpart from "counterpart";
import Translate from "react-translate-component";

// Destructuring props
const FirstStep = ({
  handleNext,
  handleChange,
  values: { ubicacion, telefono, idioma, sitio_web, tamaño },
  formErrors
}) => {
  // Check if all values are not empty or if there are some error
  const isValid =
    ubicacion.length > 0 &&
    !formErrors.ubicacion &&
    telefono.length > 0 &&
    !formErrors.telefono &&
    idioma.length > 0 &&
    !formErrors.idioma &&
    sitio_web.length > 0 &&
    !formErrors.sitio_web &&
    tamaño.length > 0 &&
    !formErrors.tamaño;
/* 
    const [otroCausa, setotroCausa] = useState("") */
    //const lang = useSelector(state => state.lang)

  
    counterpart.registerTranslations('en',en) 
    counterpart.registerTranslations('es',es)  
    counterpart.setLocale(localStorage.getItem('langlocal'));



  return (
    <Fragment>
      <Grid container spacing={2} noValidate>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label={<Translate content="Ubicacióndelaempresa" component="span" />}
            name="ubicacion"
            placeholder=""
            margin="normal"
            value={ubicacion || ""}
            onChange={handleChange}
            error={!!formErrors.ubicacion}
            helperText={formErrors.ubicacion}
            required
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label={<Translate content="TELEFONO" component="span" />}
            name="telefono"
            placeholder=""
            margin="normal"
            value={telefono || ""}
            onChange={handleChange}
            error={!!formErrors.telefono}
            helperText={formErrors.telefono}
            required
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label={<Translate content="SITIOWEB" component="span" />}
            name="sitio_web"
            placeholder=""
            margin="normal"
            value={sitio_web || ""}
            onChange={handleChange}
            error={!!formErrors.sitio_web}
            helperText={formErrors.sitio_web}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth  margin="normal">
            <InputLabel>{<Translate content="IDIOMA" component="span" />}</InputLabel>
            <Select value={idioma} onChange={handleChange} name="idioma">
              <MenuItem value={"Ingles"}>{<Translate content="Ingles" component="span" />}</MenuItem>
              <MenuItem value={"Español"}>{<Translate content="Español" component="span" />}</MenuItem>
              <MenuItem value={"Portugues"}>{<Translate content="Portugues" component="span" />}</MenuItem>
              <MenuItem value={"Chino"}>{<Translate content="chino" component="span" />}</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth  margin="normal">
            <InputLabel>{<Translate content="TAMAÑODELAEMPRESA" component="span" />}</InputLabel>
            <Select value={tamaño} onChange={handleChange} name="tamaño">
              <MenuItem value={"0-10"}>0-10</MenuItem>
              <MenuItem value={"10-20"}>10-20</MenuItem>
              <MenuItem value={"+20"}>+20</MenuItem>
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      <div
        style={{ display: "flex", marginTop: 50, justifyContent: "flex-end" }}
      >
        <Button
          variant="contained"
          disabled={!isValid}
          color="primary"
          onClick={isValid ? handleNext : null}
        >
          {<Translate content="siguienteee" component="span"/>}
        </Button>
      </div>
    </Fragment>
  );
};

export default FirstStep;
