const reducer = (state, action) => {
  switch (action.type) {
  case "LANG_CHANGE":
    return {
      ...state,
      lang: action.payload
    };
    case "SET_ERROR_MESSAGE_UNILEVER": 
    return {
      ...state,
      errorMessageUnilever: action.payload
    }
    case "SET_DATA_UNILEVER":
      return {
        ...state,
        unileverMatch: action.payload
      }
    case "JOYRIDE_STEP": 
    return {
      ...state,
      steps: action.payload
    };
    case "TOUR_STATUS": 
    return {
      ...state,
      tour_status: action.payload
    }
    case "TOUR_VIEWED_STATUS" :
    return {
      ...state,
      tourViewed: action.payload
    }
    case 'TOUR_VIEWED_COMPANY_STATUS':
    return {
      ...state,
      tourViewedCompany: action.payload
    }
    case 'SAVE_OPORTUNITY_FORM':
      return {
        ...state,
        oportunityForm: {...state.oportunityForm, ...action.payload, ...action.paso}
      }
      case 'CLEAN_OPORTUNITY_FORM':
        return {
          ...state,
          oportunityForm: {}
        }
    case "LOGIN_REQ":
      return {
        ...state,
        user: action.payload,
      };
      case "SET_PAGE":
      return {
        ...state,
        page: action.payload,
      }
      case "LANG_REQ":
        return{
          ...state,
          lang: action.payload,
          messages: action.payload.menssages
        }
        case "CHANGE-ACTUAL-TAREA-REQ":
          return{
            ...state,
            actualTareaShow: action.payload,
          }
      case "FORM_USER_REQ":
        return {
          ...state,
          userform: action.payload
        };
        case "VIEW-REQ":
          return{
            ...state,
            view: action.payload
          };
        case "FORM_COMPANY_REQ_THO":
          return {
            ...state,
            company_form: action.payload
          };
        case "SET-SELECTION-PROCESS":
          return {
            ...state,
            selectionProcess: action.payload
          }
        case "FILTER-SELECTION-PROCESS":
          return {
            ...state,
            //return as an object
            filterSelectionProcess: state.selectionProcess?.find(item => item.usuario === action.payload)
          }
        case "MIRAR-FILTER-SELECTION-PROCESS":
          return {
            ...state,
            //return as an object
            faltainformacion: state.selectionProcess.length>0?'no':'si'
          }
        case "FALTA-INFO-INITIAL":
          return {
            ...state,
            //return as an object
            faltainformacion: 'default'
          }
        case "SET_ERROR":
          return{
            ...state,
            Error: action.payload,
          }

        case "CREATE_PROJECT_REQ":
            return { 
              ...state,
              create_form: action.payload,
            };
          case "GET-PROJECTS-RECOMENDED":
            return {
              ...state,
              projectsRecomended: action.payload,
            }
    case "LOGOUT_REQ":
      return {
        ...state,
        user: action.payload,
      };
    case "REGISTER_REQ":
      return {
        ...state,
        user: action.payload,
      };
    case "SET_USER_INFO":
      return {
        ...state,
        userToVerify: action.payload,
      }
    case "GET_USER_PROJECTS":
      return {
        ...state,
        userProjects: action.payload,
      }
    case "FORM_CONSULTOR_REQ":
      return {
        ...state,
        user: action.payload,
      };
    case "FORM_COMPANY_REQ":
      return {
        ...state,
        user: action.payload,
      };
    case "APLICATION":
      return {
        ...state,
        aplication: action.payload,
      }
    case "LOAD_CV":
      return {
        ...state,
        cv: action.payload,
      }
    case "GET-PROJECTS":
      return {
        ...state,
        projects: action.payload,
      }
    case "SET-INITIAL-PHASE-REQ":
      return {
        ...state,
        initialFase: action.payload,
      }  
      case"SET-ACTUAL-PHASE-REQ":
      return{
        ...state,
        initialFase: action.payload,
      }  
    case "GET-EVENTS-PROJECT":
      return {
        ...state,
        events: action.payload,
      }
    case "GET-All-USERS":
      return {
        ...state,
        allUsers: action.payload,
      }

      case "GET-DOCUMENTOS":
        return {
          ...state,
          documentos: action.payload,
        }
  

    case "GET-USERS-ADMIN":
      return {
        ...state,
        users_admin: action.payload,
      }


    case "GET-USERS-COMPANY":
      return {
        ...state,
        users_company: action.payload,
      }

    case "DELETE-PROJECT-ADMIN":
      return {
        ...state,
        delete_project_admin: action.payload,
      }

    case "GET-PROJECTS-ADMIN":
      return {
        ...state,
        projects_admin: action.payload,
      }

    case "GET-VIDEOS":
      return {
        ...state,
        videos: action.payload,
      }
    case "GET-USER-PROJECT":
      return {
        ...state,
        userProjects: [...state.userProjects, action.payload],
        doneUserProject: true
      };

    case "USER-PROJECT-RESET":
      return {
        ...state,
        userProjects: "",
      };
      case 'GET_PLANS':
        return {
          ...state,
          planes: action.payload
        }
      case 'USER_PLANS':
        return {
          ...state,
          userPlan: action.payload
        }
    case "SET-TAREA-DONE":
      return {
        ...state,
        tareaDone: "",

      };
    case "METHODOLOGY":
      return {
        ...state,
        methodology: action.payload,
      };
    case "SET-USER-PROJECT":
      return {
        ...state,
        ActualProject: action.payload,
        projectDetailsDone: true

      };
    case "SET-PHASE":
      return {
        ...state,
        Phases: [...state.Phases, action.payload]
      };
    case "SET-SINGLE-PHASE":
      return {
        ...state,
        SinglePhase: action.payload,
        doneMetho: true
      };
    case "SET-ETAPA-REQ":
      return{
        ...state,
        subEtapas: action.payload
      }
    case "SET-SINGLE-ETAPA":
      return {
        ...state,
        SingleEtapa: action.payload
      };
    case "SET-ETAPAS":
      return {
        ...state,
        Etapas: [...state.Etapas, action.payload],
        etapasDone: true

      };
    case "SET-ETAPADATA":
      return{
        ...state,
        EtapaData: action.payload
      }
    case "SET-SUB-ETAPAS":
      return {
        ...state,
        subEtapas: [...state.subEtapas, action.payload],
        subEtapasDone: true

      };
    case "SET-SUB-ETAPAS-NULL":
      return {
        ...state,
        subEtapas: []

      };
    case "SET-ETAPAS-NULL":
      return {
        ...state,
        Etapas: []

      };
    case "SET-PROJECT-APPLY":
      return {
        ...state,
        application: action.payload
      };
    case "SET-PDF":
      return {
        ...state,
        pdf: action.payload
      };
    case "PROJECT_CONTACT":
      return {
        ...state,
        projectContact: action.payload
      }
    case "SET_ADDITIONAL":
        return {
          ...state,
          additional: action.payload
        }
    case 'FORMATION_MODEL':
          return {
            ...state,
            formationModel: action.payload
          }
    case 'CLEAR_FORMATION_MODEL':
          return {
            ...state,
            formationModel: [] // Limpiar el estado formationModel
          };
    default:
      return state;
  }
};
export default reducer;