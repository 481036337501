import React from "react";
import PlaceOutlinedIcon from '@mui/icons-material/PlaceOutlined';
import './cardsLinked.css'
import { format } from "date-fns";
import { es } from "date-fns/locale";
import { useSelector } from "react-redux";

const SmallCardLinked = ({ image, title, company, place, date, active }) => {

  const lang = useSelector(state => state.lang)

  return (
    <div className={active} style={{ position: 'relative', cursor: 'pointer' }}>
      <div style={{
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        flexDirection: 'row',
        padding: '0.8rem',
        width: '100%',
      }}>
        <div style={{
          display: 'flex',
          justifyContent: 'flex-start',
          alignItems: 'flex-start',
          width: '56px',
          height: '56px',
          overflow: 'hidden',
          position: 'relative',
        }}>
          <img className={'small-card-linked-image'} src={image ? image : ''} alt={image ? image : ''} />
        </div>
        <div style={{
          display: 'flex',
          justifyContent: 'flex-start',
          flexDirection: 'column',
          alignItems: 'flex-start',
          width: '70%',
          paddingLeft: '20px',
        }}>
          <h1 style={{
            fontSize: '16px',
            fontWeight: '700',
            color: active === 'small-card-linked-container-active' ? '#fff' : '#393939',
            textAlign: 'left',
            width: '100%',
          }}>
            {title}
          </h1>
          <h1 style={{
            fontSize: '16px',
            fontWeight: '400',
            color: active === 'small-card-linked-container-active' ? '#fff' : '#393939',
            margin: 0,
          }}>
            {company}
          </h1>
          {
            place ? (
              <div style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
                width: '100%',
                textAlign: 'center',
                marginTop: '10px',
              }}>
                <PlaceOutlinedIcon style={{ color: '#C5C5C5', fontSize: '20px' }} />
                <div style={{
                  display: 'flex',
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                  width: '90%',
                  height: '100%',
                  color: '#C5C5C5',
                  fontSize: '16px',
                  marginLeft: '15px',
                }}>
                  {place}
                </div>
              </div>
            ) : null
          }
          <h1 style={{
            fontSize: '14px',
            fontWeight: '400',
            color: '#C5C5C5',
            paddingTop: '10px',
          }}>
            {format(new Date(date), 'dd MMMM, yyyy', { locale: lang === 'es' ? es : null })}
          </h1>
        </div>
      </div>
      <hr style={{
        width: 'calc(100% - 89px)',
        border: 'none',
        height: '1px',
        backgroundColor: '#D6D6D6',
        display: 'flex',
        alignSelf: 'flex-end',
        justifyContent: 'flex-start',
        margin: 0,
      }} />
    </div>
  )
}

export default SmallCardLinked;