import React, {useEffect, useState} from 'react';
//import Microphone from '../../assets/icons/microphone.svg'
import './CrearOportunidadNew.css'
import {useDispatch, useSelector} from "react-redux";
import { saveOportunityForm } from '../../actions';

export default function OportunityInfo(props) {
  const {form, handleStep, handleBackStep, step, lang, handleForm} = props;
  const oportunityForm = useSelector(state => state.oportunityForm);
  const [bottom, setbottom] = useState(true)
  const dispatch = useDispatch();
  useEffect(() => {
    if (oportunityForm?.NombreOportunidad && oportunityForm?.DescribeProyecto && oportunityForm?.profesionales ? oportunityForm?.NombreOportunidad !== '' && oportunityForm?.DescribeProyecto !== '' && oportunityForm?.profesionales !== '' : form?.NombreOportunidad !== '' && form?.DescribeProyecto !== '' && form?.profesionales !== '') {
      setbottom(false)
    }
    return () => {
      setbottom(true)
    }
    //eslint-disable-next-line
  }, [form, oportunityForm])

  const handleChange = (e) => {
    handleForm({...form, [e.target.name]: e.target.value});
    dispatch(saveOportunityForm({[e.target.name]: e.target.value}, step));
  }
  const handleNext = () => {
    if(oportunityForm?.NombreOportunidad && oportunityForm?.DescribeProyecto && oportunityForm?.profesionales ? oportunityForm?.NombreOportunidad !== '' && oportunityForm?.DescribeProyecto !== '' && oportunityForm?.profesionales !== '' : form?.NombreOportunidad !== '' && form?.DescribeProyecto !== '' && form?.profesionales !== ''){
      setbottom(false)
      handleStep()
    }
  }

  return (<>
    <div className='contenedorOportunityInfo'>
      <div style={{marginBottom: '30px'}}>
        <h3 style={{
          fontFamily: 'Poppins', fontWeight: '700', fontSize: '14px', marginBottom: '20px', marginLeft: '-5px'
        }}>{lang === 'es' ? 'Nombre de la oportunidad, cargo, proyecto o rol.' : 'Name of opportunity, position or project.'}</h3>
        <input type="text" placeholder={lang === 'es' ? 'Nombre' : 'Name'} name="NombreOportunidad"
               onChange={handleChange}
               defaultValue={oportunityForm?.NombreOportunidad ? oportunityForm?.NombreOportunidad : form?.NombreOportunidad}
               className="contenedorOportunityInfo_input"></input>
      </div>

      <div style={{marginBottom: '30px'}}>
        <h3 style={{
          fontFamily: 'Poppins', fontWeight: '700', fontSize: '14px', marginBottom: '20px', marginLeft: '-5px'
        }}>{lang === 'es' ? '¿Cuántos profesionales deseas contratar?' : 'How many professionals do you want to hire?'}</h3>
        <input type="text" placeholder={lang === 'es' ? 'Cantidad' : 'Quantity'} name="profesionales"
               onChange={handleChange}
               /*  */
               value={oportunityForm?.profesionales ? oportunityForm?.profesionales : form?.profesionales}
               onKeyPress={(e) => {
                 if (!/[0-9]/.test(e.key)) {
                   e.preventDefault();
                   return
                 }
               }}
               className="contenedorOportunityInfo_input"></input>
      </div>

      <div style={{display: 'flex', flexDirection: 'column', marginBottom: '30px'}}>
        <h3
          className='contenedorOportunityInfo_title'>{lang === 'es' ? 'Haz una pequeña descripción de la oportunidad, cargo, proyecto o rol.' : 'Make a brief description of the opportunity, position, project or role.'} </h3>
        <textarea type="text" placeholder={lang === 'es' ? 'Texto' : 'Text'} name="DescribeProyecto"
                  onChange={handleChange}
                  className="contenedorOportunityInfo_TextArea"
                  defaultValue={oportunityForm?.DescribeProyecto ? oportunityForm?.DescribeProyecto : form?.DescribeProyecto}></textarea>
        {/* <span style={{color: form.DescribeProyecto.length > 320 ? 'red':'#C5C5C5', fontFamily: 'Poppins', fontWeight: '400', fontSize: '12px', marginLeft: '10px'}}>{lang === 'es' ? `Máximo de carácteres (${form.DescribeProyecto?.length}/320)` : `maximum characters (${form.DescribeProyecto?.length}/320)`}</span> */}
      </div>
      {/* <div style={{display: 'flex', flexDirection: 'row'}}>
        <h4 style={{fontFamily: 'Poppins', fontSize: '14px', fontWeight: '400', color: '#0B27E6', marginLeft: '10px'}}>Añadir nota de voz</h4>
        <img src={Microphone} alt="microphone" style={{width: '27px', height: '30px', marginLeft: '35px', marginTop: '-7px'}}/>
        </div> */}
    </div>
    <div
      style={{
        display: "flex",
        width: "100%",
        justifyContent: "space-between",
        padding: "1rem 3rem 0rem 3rem",
        alignItems: "center",
        // marginBottom: '-92px'
      }}
    >
      <button className='button_siguiente_create_B'
              onClick={handleBackStep}>{lang === 'es' ? 'Anterior' : 'Previous'}</button>
      <h3 className='stepCounter'>{`${step}/14`}</h3>
      <button className='button_siguiente_create'
              onClick={() => handleNext()}
              disabled={bottom}>{lang === 'es' ? 'Siguiente' : 'Next'}</button>
    </div>
  </>)
}