import React from "react";
import ReactDOM from "react-dom";
//pages
import App from "./pages/App";
import "./globals.css";
import { Provider } from "react-redux";
import { Router } from "react-router";
import { createBrowserHistory } from "history";
import { createStore, compose, applyMiddleware } from "redux";
import reducer from "./reducers/index";
import thunk from "redux-thunk";
import { saveState , } from "./actions/State.js";

const userLocal = JSON.parse(localStorage.getItem('user'))
const tourViewed = JSON.parse(localStorage.getItem('tourViewed'))
const tourViewedCompany = JSON.parse(localStorage.getItem('tourViewedCompany'))
const plan_premium = JSON.parse(localStorage.getItem('plan_premium'))
const unileverLocal = JSON.parse(localStorage.getItem('unilever'))
const state =
  {
    cv:"",
    user: userLocal || {},
    unilever: unileverLocal || false,
    errorMessageUnilever: '',
    unileverMatch: '',
    lang:localStorage.getItem('langlocal') || 'es',
    tour_status: {},
    tourViewed: tourViewed || false,
    tourViewedCompany:  tourViewedCompany || false,
    plan_premium: plan_premium || '',
    steps: [],
    userToVerify: {
      email: "",
      id:"",
      username:""
    },
    oportunityForm: {
      tipoDeServicioDeseado: '',
    modalidadDeContratacion:  '',
    Anonimo:  false,
    NombreEmpresa: userLocal ? userLocal.name : '',
    Email:  userLocal ? userLocal.email : '',
    Oportunidad: '',
    Datalle: '',
    NombreOportunidad: '',
    DescribeProyecto: '',
    DescribeProyectoAudio: '',
    SeleccionaCarrera: [],
    Niveldeconocimiento: [],
    Lenguaje: [],
    habilidadesRequeridas: [],
    estimadoDuración: '',
    idrut: '',
    profesionales: [],
    modalidadPago: '',
    presupuesto: '',
    codigoPromocional: '',
    modalidaddepago: '',
    link: '',
    empleos_alternativos: [],
    tiempo_experiencia: '',
    departamento: '',
    municipio: '',
    sector_economico: '',
    tipo_documento: '',
    documento: '',
    editable: 0,
    step: 1,
    },
    Error: "",
    doneUserProject:'',
    projects: '',
    selectionProcess:'',
    filterSelectionProcess:'',
    faltainformacion:'default',
    methodology: {},
    initialFase:'',
    ActualProject:'',
    Phases: [],
    view: '',
    videos:'',
    userProjects:[],
    SinglePhase:'',
    SingleEtapa:'',
    company_form:'',
    documentos:'',
    subEtapas:[],
    Etapas:[],
    page: 0,
    projectContact: '',
    planes: [],
    userPlan: {},
    additional: {},
    formationModel: [],
  }
   || {};

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(
  reducer,
  state,
  composeEnhancers(applyMiddleware(thunk))
);
store.subscribe(function () {
  saveState(store.getState());
});
const history = createBrowserHistory();

ReactDOM.render(
  <Provider store={store}>
    <Router history={history}>
      <App />
    </Router>
  </Provider>,
  document.getElementById("root")
);


