import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./styles/RegisterEmpresa.css";
//assets
import { connect } from "react-redux";
import { registerUser } from "../actions";
import facebook from "../assets/logos/facebook login.png";
import linkedin from "../assets/logos/linkedin.png";
import google from "../assets/logos/logo google.png";
import en from "../lang/en-US";
import es from "../lang/es-COL"
import counterpart from "counterpart";

function Register(props) {
  if(!localStorage.getItem('langlocal')){
    localStorage.setItem('langlocal','es')
  }
  
  const [form, setValues] = useState({
    userTipe: "",
    name: "",
    email: "",
    password: "",
  });

  const handleInput = (event) => {
    setValues({
      ...form,
      [event.target.name]: event.target.value,
    });
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    props.registerUser(form, "/company/profile");
  };
  counterpart.registerTranslations('en',en) 
  counterpart.registerTranslations('es',es)  
  counterpart.setLocale(localStorage.getItem('langlocal'));
const Nombre =counterpart.translate('Nombre')
const CorreoElectrónico =counterpart.translate('CorreoElectrónico')
const Contraseña =counterpart.translate('Contraseña')

  return (
    <>
      {/*form*/}
      <section className="register-empresa">
        <section className="register__container">
          <p
            className=".register__container-p"
            style={{ fontSize: "32px", fontWeight: "700" }}
          >
            {" "}
            Registrate
          </p>
          <form className="register__container-form" onSubmit={handleSubmit}>
            <div className="register__form-radio">
              <div>
                <input
                  type="radio"
                  name="usertipe"
                  id="consultor"
                  onChange={handleInput}
                />
                <label htmlFor="consultor">Consultor</label>
              </div>
              <div>
                <input
                  type="radio"
                  name="usertipe"
                  id="manager"
                  onChange={handleInput}
                />
                <label htmlFor="manager">Project Manager</label>
              </div>
              <div>
                <input
                  type="radio"
                  name="usertipe"
                  id="empresa"
                  onChange={handleInput}
                />
                <label htmlFor="empresa">Empresa</label>
              </div>
            </div>
            <input
              className="input"
              type="text"
              placeholder={Nombre}
              name="name"
              onChange={handleInput}
            />
            <input
              className="input"
              type="text"
              placeholder={CorreoElectrónico}
              name="email"
              onChange={handleInput}
            />
            <input
              className="input"
              type="password"
              placeholder={Contraseña}
              name="password"
              onChange={handleInput}
            />
            <p className="register__form-conditions">
              Al hacer click en Registrate aceptas las Condiciones de uso y
              Politica de privacidad de findally
            </p>
            <div className="register__form-social">
              <Link className="register__form-social-btn-fg" to="#">
                <img
                  className="register__form-social-img"
                  src={facebook}
                  alt="facebook-login"
                  height="17px"
                />
                <p className="register__form-social-txt">Facebook</p>
              </Link>
              <Link className="register__form-social-btn-lin" to="#">
                <img
                  className="register__form-social-img"
                  src={linkedin}
                  alt="linkedin-login"
                  height="17px"
                />
                <p className="register__form-social-txt">Linkedin</p>
              </Link>
              <Link className="register__form-social-btn-go" to="#">
                <img
                  className="register__form-social-img"
                  src={google}
                  alt="google-login"
                  height="17px"
                />
                <p className="register__form-social-txt">Google</p>
              </Link>
            </div>
            <button
              type="submit"
              className="register__form__button"
              name="Registrate"
            >
              Registrate
            </button>
          </form>
          <div className="form__notregister">
            <p className="form__notregister-text">
              ¿Ya tienes cuenta?{" "}
              <Link className="form__noregister-text-link" to="/auth/company/login">
                Inicia sesión
              </Link>{" "}
            </p>
          </div>
        </section>
      </section>
    </>
  );
}
const mapDispatchToProps = {
  registerUser,
};

export default connect(null, mapDispatchToProps)(Register);
