/* eslint-disable  */
import React, { useContext, useEffect, useState }  from "react";
import Chart from "react-apexcharts";
import { SchneiderContext } from "../../context/SchneiderContext";
import './Dashboard.css';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { Age } from "../../../pages/Admin/constant";
const axios = require('axios');

export const Dashboard = () => {
    const [dataGenero, setDataGenero] = useState([])
    const [dataAplicantes, setDataAplicantes] = useState([])
    const [dataCountry, setDataCountry] = useState([])

    const [option, setOption] = useState("")
    const [consulta, setConsulta] = useState([])
    const [num, setNum] = useState(0)
    const { schneider } = useContext(SchneiderContext);
    const [generos, setGeneros] = useState({
        series: [0,0],
        chartOptions: {
          labels: ['Masculino', 'Femenino']
        }})
    const [countries, setCountries] = useState({
        paises: ["Colombia", "Ecuador", "Perú"],
        numero: [0, 0, 0]
    })
   
    const [dataAge, setDataAge] = useState({
      rangos: ["18-25", "26-35", "36-45", "46+"],
      numero: [0, 0, 0, 0]
  })
 
   /* Paises general */
  const infoInicialPaises = () => {
    axios.get('https://findally-backend.herokuapp.com/api/schneider/admin/aplicants/country/all')
    .then(function (response) {
      const info = response.data
      const objPaises = Object.keys(info.data)
     const objnumeros = Object.values(info.data)

    setTimeout(() => {
      setCountries({paises: objPaises , numero: objnumeros})
    }, 3000);
    })
    .catch(function (error) {
      // handle error
      console.log(error);
    })      
  }
    useEffect(() => {
      infoInicialPaises()
  }, []);

    /*Edades*/
    const infoGeneralEdades = () => {
      axios.get('https://findally-backend.herokuapp.com/api/schneider/admin/aplicants/age')
      .then(function (response) {
        const info = response.data
        const objRangos = Object.keys(info.data)
       const objnumeros = Object.values(info.data)
  
      setTimeout(() => {
        setDataAge({rangos: objRangos , numero: objnumeros})
      }, 3000);
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      })        
    }

  useEffect(() => {
    infoGeneralEdades()
}, []);

    /* Genero */
    useEffect(() => {
        axios.get('https://findally-backend.herokuapp.com/api/schneider/admin/aplicants/genre')
        .then(function (response) {
          // handle success
          const info = response.data
          setDataGenero(info.data)
          console.log("genero:", info.data);
        })
        .catch(function (error) {
          // handle error
          console.log(error);
        })      
    }, []);

    useEffect(() => {
        axios.get('https://findally-backend.herokuapp.com/api/schneider/admin/aplicants')
        .then(function (response) {
          // handle success
          const info = response.data
          setDataAplicantes(info.data)
          console.log("aplicantes", info.data);
        })
        .catch(function (error) {
          // handle error
          console.log(error);
        })      
    }, []);

    /*Proyectos - Paises */
    useEffect(() => {
        axios.get('https://findally-backend.herokuapp.com/api/schneider/admin/aplicants/country')
        .then(function (response) {
          // handle success
          const info = response.data
          setDataCountry(info.data)
          console.log("proyectos - Paises", info.data);
        })
        .catch(function (error) {
          // handle error
         console.log(error);
        })      
    }, []);

    const handleChange = (event) => {
        setOption(event.target.value)
        filtrar(event.target.value)
    }

    const filtrar = (busqueda) => {
        const newValue = dataGenero.filter((element) => {
            if(element.project.toString().toLowerCase().includes(busqueda.toLowerCase())){
                return element
            }
        })
        setConsulta(newValue)
        const aplicantes = dataAplicantes.filter((element) => {
            if(element.NombreOportunidad.toString().toLowerCase().includes(busqueda.toLowerCase())){
                return element
            }
        })
        const country = dataCountry.filter((element) => {
           if(element.project.toString().toLowerCase().includes(busqueda.toLowerCase())){
                return element
            }
        })
        if(busqueda !== "Información General"){
          activarDashboard(newValue, aplicantes, country)
          setDataAge({ rangos: ["18-25", "26-35", "36-45", "46+"], numero: [0, 0, 0, 0]})
        } else {
          infoInicialPaises()
          infoGeneralEdades()
        }

    }

    const activarDashboard = (value, aplicantes, country) => {
        const info = value[0]
        const numApli = aplicantes[0]
        const pais = country[0]

        const genero = info.aplicantes_por_genero;
        const numero = numApli.aplicantes
        const paises = pais.aplicantes_por_pais
        
       setGeneros({...generos, series: [genero.masculino, genero.femenino]})
       setNum(numero.length)

       const objPaises = Object.keys(paises)
       const objnumeros = Object.values(paises)

      setCountries({paises: objPaises , numero: objnumeros})
    }

    /*Graficas */
    
    const tableCountries = {
        options: {
          chart: {
            id: "bar",
            height: 300,
          },
          dropShadow: {
            enabled: false,
            enabledOnSeries: undefined,
            top: 0,
            left: 0,
            blur: 3,
            color: '#000',
            opacity: 0.35
        },
        fill: {
            colors:[schneider.color.primary], //Color de barras
            type: "gradient"
        },
        dataLabels: {
            enabled: true,
            formatter: function (val) {
              return val + "%";
            },
            offsetY: -20,
            style: {
              fontSize: '12px',
              alingSelf: "center",
              colors: ["#304758"]
            }    },
        stroke: {
            colors: ["pink"],
        },
        plotOptions: {
            bar: {
              borderRadius: 4,
              horizontal: true,
            }
        },
        xaxis: {
            categories: countries.paises//paises
          }
        },
        series: [
          {
            name: "Candidatos",
            data: countries.numero //candidatos x paises
          }
        ]
      };
    
      const tableAges = {
        options: {
          chart: {
            id: "bar",
            height: 300,
          },
          dropShadow: {
            enabled: false,
            enabledOnSeries: undefined,
            top: 0,
            left: 0,
            blur: 3,
            color: '#000',
            opacity: 0.35
        },
        fill: {
            colors:[schneider.color.primary], //Color de barras
            type: "gradient"
        },
        dataLabels: {
            enabled: true,
            formatter: function (val) {
              return val + "%";
            },
            offsetY: -20,
            style: {
              fontSize: '12px',
              alingSelf: "center",
              colors: ["#304758"]
            }    },
        stroke: {
            colors: ["pink"],
        },
        plotOptions: {
            bar: {
              borderRadius: 4,
              horizontal: true,
            }
        },
        xaxis: {
            categories: dataAge.rangos // rangos Edades
          }
        },
        series: [
          {
            name: "Candidatos",
            data: dataAge.numero // edades
          }
        ]
      };


    return(
        <div className="container-dashboard">
            <div className="container-dashboard-glass">
                <div className="dashboard">
                    <Typography sx={{ fontSize: 36, margin: 3, fontWeight: "bold" }} color={schneider.color.primary} variant="h1" gutterBottom>
                      Dashboard
                    </Typography>
                    <Typography sx={{ fontSize: 16, margin: 3, fontWeight: "bold", marginTop: 0 }} color={schneider.color.primary} variant="h5" gutterBottom>
                      {option === "" ? "Información General" : option}
                    </Typography>
                    <div className="tables-dashboard">
                      <Card sx={{ minWidth: 275, maxHeight:300, width: "350px", alignSelf: "center", background:"linear-gradient(to top, #e6e9f0 0%, #eef1f5 100%)", marginLeft: "25px"}}>
                        <CardContent>
                          <Typography sx={{ fontSize: 16, margin: 3, fontWeight: "bold" }} color={schneider.color.primary} variant="h2" gutterBottom>
                            Aplicantes por País
                          </Typography>
                          <div className="chartContainer">
                            <Chart options={tableCountries.options} series={tableCountries.series} type="bar"/>
                          </div>
                        </CardContent>
                      </Card>
                      <Card sx={{ minWidth: 275, maxHeight:300, width: "350px", alignSelf: "center", background:"linear-gradient(to top, #e6e9f0 0%, #eef1f5 100%)", marginLeft: "25px"}}>
                        <CardContent>
                          <Typography sx={{ fontSize: 16, margin: 3, fontWeight: "bold" }} color={schneider.color.primary} variant="h2" gutterBottom>
                            Aplicantes por Edad
                          </Typography>
                          <div className="chartContainer">
                              <Chart options={tableAges.options} series={tableAges.series} type="bar"/>
                          </div>
                        </CardContent>
                      </Card>
                    </div>
                </div>
                <div className="dashboard">
                  <div style={{display: "flex", justifyContent: "center" ,alignItems: "center", marginBlock: "30px"}}>
                      <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={option}
                            label="Projectos"
                            onChange={handleChange}
                            style={{width:250}}
                        >
                            <MenuItem value="Información General">Información General</MenuItem>
                            { dataAplicantes.length > 0 ? dataAplicantes.map((element)=>(
                                <MenuItem value={element.NombreOportunidad} key={element._id} >{element.NombreOportunidad + " " + "(" + element.country + ")"}</MenuItem>
                                )):
                                <MenuItem value={0}>No tienes Proyectos</MenuItem>
                                }
                      </Select>
                  </div>
                  <Card sx={{ display:"flex", flexDirection: "column", justifyContent: "center",height: "308px",width: "100%", background:"linear-gradient(to top, #e6e9f0 0%, #eef1f5 100%)", marginTop: "5px"}}>                   
                    <div className="list-projects">
                      <Typography sx={{ fontSize: 16, marginY: 2, fontWeight: "bold", color: schneider.color.primary, alignSelf: "center" }} color={schneider.color.primary} variant="h2" gutterBottom>
                        Aplicantes por Género
                      </Typography>
                      <Chart  options={generos.chartOptions} series={generos.series} type="donut" />
                    </div>
                    <div className="list-projects" >
                      <Typography sx={{ fontSize: 16, marginTop: 2, fontWeight: "bold", color: schneider.color.primary, alignSelf: "center" }} color={schneider.color.primary} variant="h2" gutterBottom>
                      Número de Aplicantes
                      </Typography>
                      <div className="only-h3" style={{border: '0.8px solid' + schneider.color.primary}}>
                          <Typography sx={{ fontSize: 30, fontWeight: "bold", color: schneider.color.primary, alignSelf: "center", paddingTop: "15px" }} color={schneider.color.primary} variant="h3" gutterBottom>
                          {num}
                          </Typography>
                      </div>
                    </div>
                  </Card>
                </div>
            </div>
        </div>
    )
}