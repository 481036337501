// desactivar eslint de react
/* eslint-disable react-hooks/exhaustive-deps */
/*eslint-disable*/
import React, { useEffect, useState } from "react";
import "./DashboardOportunidad.css";
import { useSelector } from "react-redux";
import ProfesionalesListado from "./ProfesionalesListado/ProfesionalesListado";
import AplicantesCard from "./AplicantesCard/AplicantesCard";
import arrow from "../../assets/icons/l-arrow.svg";
import { Link, useHistory, useParams } from "react-router-dom";
import axios from "axios";
import AditionalServices from "../pricing/pricingDetails/aditionalServices.jsx";
import DashboardTabs from "./DashboardTabs/DashboardTabs";
import Sidebar from "../../components/Sidebar/Sidebar";
import { CSVLink } from "react-csv";
import Navbar from "../../components/Navbar/Navbar";
import ProfesionalesSimilaresCard from "./ProfesionalesSimilaresCard/ProfesionalesSimilaresCard";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Spinner from "react-bootstrap/Spinner";
import AccordionDetails from "@mui/material/AccordionDetails";
import CardInfoOportunidad from "./cardinfo/CardInfoOportunidad";
import { CircularProgress } from "@mui/material";
// https://www.figma.com/file/bRCsb0kTVy7fsHDPrWlxTn/Coally?node-id=6211-28557&t=BCTvPCLBoufvSfGu-4

export default function DashboardOportunidad() {
  const { lang, userPlan } = useSelector((state) => state);
  const { id } = useParams();
  const history = useHistory();
  const [oportunidad, setOportunidad] = useState(null);
  const [profesionales1, setProfesionales1] = useState(null);
  const [aplicantes, setAplicantes] = useState(null);
  const [enpruebas, setEnpruebas] = useState(null);
  const [seleccionados, setSeleccionados] = useState(null);
  const [ocultos, setOcultos] = useState(null);
  const [loadnum, setLoadnum] = useState(6);
  const [readyQueryMl, setReadyQueryMl] = useState(false);
  const [loading, setLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [usuariosRecomendados, setUsuariosRecomendados] = useState(null);

  useEffect(() => {
    axios
      .get("https://findally-backend.herokuapp.com/api/Project/" + id)
      .then((res) => {
        setOportunidad(res.data);
        return res?.data?.hiddenAplicants?.length
          ? axios
            .get(
              `https://findally-backend.herokuapp.com/api/applicants/hidden/${id}`
            )
            .then((res) => {
              return setOcultos(res.data.data);
            })
            .catch((err) => {
              return console.log(err);
            })
          : null;
      })
      .catch((err) => {
        console.log(err);
      })
      .catch((err) => {
        console.log(err);
      });
    axios
      .get("https://findally-backend.herokuapp.com/api/Project/selected/" + id)
      .then((res) => {
        setEnpruebas(res.data);
        // console.log('setEnpruebas', res.data)
      })
      .catch((err) => {
        console.log(err);
      });
    axios
      .post(
        "https://findally-backend.herokuapp.com/api/applicants/find/all/contacted/coalliers",
        { proyecto: id }
      )
      .then((res) => {
        setAplicantes(res.data);
        // console.log('setAplicantes', res.data)
      })
      .catch((err) => {
        console.log(err);
      });
    axios
      .get(
        "https://findally-backend.herokuapp.com/api/applicants/choosen/" + id
      )
      .then((res) => {
        setSeleccionados(res.data);
        // console.log('setSeleccionados', res.data)
      })
      .catch((err) => {
        console.log(err);
      });
  }, [id]);

  useEffect(() => {
    if (
      !oportunidad ||
      !oportunidad.aplicantes ||
      oportunidad.aplicantes.length === 0
    ) {
      return;
    }
    setLoading(true);
    axios
      .get(
        "https://findally-backend.herokuapp.com/api/Project/match/recomendation/" +
        id
      )
      .then((res) => {      
        setProfesionales1(res.data);
        const empleoData = {
          ID: oportunidad._id,
          Descripcion: oportunidad?.DescribeProyecto || "",
          Experiencia: oportunidad?.experienciaAnos
            ? `${oportunidad.experienciaAnos} años`
            : "",
          Habilidades_tecnicas:
            oportunidad?.habilidadesTecnicas?.join(", ") || "",
          Habilidades_Blandas:
            oportunidad?.habilidadesBlandas?.join(", ") || "",
          Idiomas: oportunidad?.Lenguaje?.join(", ") || "",
          Salario: oportunidad?.presupuesto || "",
          Beneficios: oportunidad?.listadoBeneficios?.join(", ") || "",
          Tipo_de_empleo: oportunidad?.modalidadDeContratacion || "",
          Modalidad: oportunidad?.modalidadDeTrabajo || "",
        };

        const aplicantesData = res?.data?.applicants?.map((aplicante) => {
          return {
            ID: aplicante.cv_file._id,
            Descripcion: aplicante.cv_file?.extracto || "",
            Experiencia:
              aplicante.cv_file?.experiencia?.flatMap((exp) => {
                return (
                  exp.cargos?.map((cargo) => {
                    return {
                      nombre_cargo: cargo.nombrecargo ? cargo.nombrecargo : "",
                      duracion: cargo.duracion ? cargo.duracion : "",
                      ubicacion: cargo.ubicacion ? cargo.ubicacion : "",
                      descripcion: cargo.descripcion ? cargo.descripcion : "",
                    };
                  }) || []
                );
              }) || [],
            Estudios:
              oportunidad?.educacion
                ?.map((edu) => edu.Titulo_Certificacion)
                .join(", ") || "",
            Habilidades_tecnicas:
              aplicante.cv_file?.aptitudes_principales?.join(", ") || "",
            Habilidades_Blandas: "",
            Logros: "",
            Trabajo_Social: "",
            Universidad: "",
            Idiomas:
              aplicante.cv_file?.languages
                ?.map((idioma) => idioma.Language)
                .join(", ") || "",
            Salario: aplicante.cv_file?.aspiracion_salarial || "",
            Tipo_de_empleo: "",
            Modalidad: "",
            Puntaje_hr: 0,
          };
        });

        const postData = { empleo: empleoData, aplicantes: aplicantesData };
        axios
          .post(
            "https://resume-matching-deploy.herokuapp.com/aplicantes-empleo",
            postData,
            { timeout: 3 * 60 * 1000 }
          )
          .then((res) => {
            const { response } = res.data;
            const result = {};
            //recorre la respuesta y va agregando los fits a los objetos
            for (const key in response) {
              const value = response[key];
              if (typeof value === "object" && value !== null) {
                const fitKey = "fit" + key;
                if (fitKey in response) {
                  value.fit = response[fitKey];
                }
                result[key] = value;
              }
            }
            // iterar sobre result para buscar cada usuario en el estado y agregarle el fit
            setProfesionales1((prev) => {
              // Copiar el estado anterior para evitar mutar el estado original
              const newState = [...prev.applicants];

              // Recorrer cada usuario en el nuevo objeto result
              for (const id in result) {
                // Buscar el índice del usuario con la id correspondiente
                const userIndex = newState.findIndex(
                  (user) => user.cv_file._id === result[id].ID
                );

                // Si se encontró el usuario, agregarle el fit
                if (userIndex !== -1) {
                  newState[userIndex].fit = result[id].fit;
                }
              }

              // Retornar el nuevo estado actualizado
              return { ...prev, applicants: newState };
            });
            setLoading(false);
            setReadyQueryMl(true);
          });
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  }, [oportunidad, id]);

  const redondeo = (num) => {
    return Math.floor(num);
  };

  //Descargar aplicantes

  const headers = [
    { label: "Nombre", key: "cv_file.info_personal.nombre" },
    { label: "Email", key: "email" },
    { label: "Profesión", key: "cv_file.info_personal.profesion_actual" },
    { label: "Ubicación", key: "cv_file.info_personal.ubicacion" },
    { label: "Contacto", key: "cv_file.contacto" },
  ];

  const csvReport = {
    filename: "Reporte de aplicantes.csv",
    headers: headers,
    data: profesionales1?.applicants ? profesionales1?.applicants : [],
  };

  useEffect(() => {
    if (
      !oportunidad ||
      !oportunidad.aplicantes ||
      oportunidad.aplicantes.length === 0
    ) {
      return;
    }
    oportunidad &&
      axios
        .post(
          "https://resume-matching-deploy.herokuapp.com/n-mejores-perfiles",
          {
            n: 6,
            ID: oportunidad?._id || "",
            Descripcion: oportunidad?.DescribeProyecto || "",
            Experiencia: oportunidad?.experienciaAnos || "",
            Habilidades_tecnicas:
              oportunidad?.habilidadesTecnicas?.join(", ") || "",
            Habilidades_Blandas:
              oportunidad?.habilidadesBlandas?.join(", ") || "",
            Idiomas: oportunidad?.Lenguaje?.join(", ") || "",
            Salario: oportunidad?.presupuesto ? oportunidad?.presupuesto : "",
            Beneficios: oportunidad?.listadoBeneficios?.join(", ") || "",
            Tipo_de_empleo: oportunidad?.modalidadDeContratacion || "",
            Modalidad: oportunidad?.modalidadDeTrabajo || "",
          },
          { timeout: 3 * 60 * 1000 }
        )
        .then((res) => {
          const { response } = res.data;
          const result = [];
          //recorre la respuesta y va agregando los fits a los objetos
          for (const key in response) {
            const value = response[key];
            if (typeof value === "object" && value !== null) {
              const fitKey = "fit" + key;
              if (fitKey in response) {
                value.fit = response[fitKey];
              }
              result.push(value);
            }
          }
          setUsuariosRecomendados(result);
        })
        .catch((err) => console.log(err));
  }, [oportunidad]);

  return (
    <>
      <>
        <Sidebar location={"oportunidades"} />
        <div className={"dashOp-global-cont"}>
          {/*<HeadertopV2></HeadertopV2>*/}
          <Navbar locate={"oportunidades"} />
          {/*aplicantes*/}
          <div>
            <div
              style={{ display: "flex", alignItems: "center", width: "100%" }}
            >
              <img
                onClick={history.goBack}
                style={{ marginRight: "31px", cursor: "pointer" }}
                src={arrow}
                alt={"l-arrow"}
              />
              <h1 className={"dashOp-title"}>
                {lang === "es" ? "Oportunidad" : "Opportunity"}{" "}
                {oportunidad?.nombre === "Unnamed Oportunity"
                  ? oportunidad?.NombreOportunidad
                  : oportunidad?.nombre}
              </h1>
            </div>
            {/*<h2 className={'dashOp-subtitle'}>*/}
            {/*  {lang === 'es' ? 'Aplicantes' : 'Applicants'}*/}
            {/*</h2>*/}
            {profesionales1?.applicants?.length === 0 ? null : (
              <h2 className={"dashOp-subtitle"}>
                {lang === "es" ? "Mejores aplicantes" : "Best applicants"}
              </h2>
            )}
            <div className="container-dashOp-cards-cont">
              <div className={"dashOp-cards-cont"}>
                {/*cards aplicantes*/}
                {loading ? (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "100%",
                      height: "100%",
                    }}
                  >
                    <CircularProgress size={60} sx={{ color: "#F07130" }} />
                    <p style={{ margin: "0 20px" }}>
                      {lang === "es"
                        ? "Cargando aplicantes..."
                        : "Loading applicants..."}
                    </p>
                  </div>
                ) : null}
                {profesionales1 &&
                  readyQueryMl &&
                  profesionales1?.applicants
                    ?.filter((e) => e.fit !== undefined)
                    .sort((a, b) => b.fit - a.fit)
                    .concat(
                      profesionales1?.applicants?.filter(
                        (e) => e.fit === undefined
                      )
                    )
                    .slice(0, 3)
                    .map((e, i) => {
                      // let person = Math.floor(Math.random() * 78)
                      // let gender = Math.floor(Math.random() * 2)                     
                      return (
                        <AplicantesCard
                          key={i}
                          keynum={i + 1}
                          name={`${e?.username.slice(
                            0,
                            15
                          )}...`}
                          // nameEntero={e?.cv_file?.info_personal?.nombre}
                          job={`${e?.cv_file?.info_personal?.profesion_actual?.slice(
                            0,
                            20
                          )}...`}
                          jobEntero={
                            e?.cv_file?.info_personal?.profesion_actual
                          }
                          compatibility={e.fit ? redondeo(e?.fit) + "%" : "0%"}
                          image={e?.avatar}
                          id={e.id}
                          LinkedInCv={e?.cv_LinkedIn_uri}
                          generalCv={e?.cv_Personalized_uri}
                          idProyecto={oportunidad?._id}
                          monedaOportunidad={
                            oportunidad?.presupuesto?.split(" ")[0]
                          }
                        />
                      );
                    })}
              </div>
              <CardInfoOportunidad data={oportunidad} lang={lang} />
            </div>
            <div>
              {/*listado aplicantes*/}
              {profesionales1?.applicants?.length === 0 &&
                profesionales1?.applicants?.length < 3 ? null : (
                <h2 className={"dashOp-subtitle"}>
                  {lang === "es" ? "Otros aplicantes" : "Other applicants"}
                </h2>
              )}
              {profesionales1 &&
                readyQueryMl &&
                profesionales1?.applicants
                  ?.filter((e) => e.fit !== undefined)
                  .sort((a, b) => b.fit - a.fit)
                  .concat(
                    profesionales1?.applicants?.filter(
                      (e) => e.fit === undefined
                    )
                  )
                  .slice(3, loadnum)
                  .map((e, i) => {
                    // let person = Math.floor(Math.random() * 78)
                    // let gender = Math.floor(Math.random() * 2)
                    return (
                      <ProfesionalesListado
                        keynum={i + 4}
                        key={i}
                        name={e?.username}
                        job={e?.cv_file?.info_personal?.profesion_actual}
                        compatibility={e.fit ? redondeo(e?.fit) + "%" : "0%"}
                        image={e?.avatar}
                        id={e.id}
                        LinkedInCv={e?.cv_LinkedIn_uri}
                        generalCv={e?.cv_Personalized_uri}
                        idProyecto={oportunidad?._id}
                        monedaOportunidad={
                          oportunidad?.presupuesto?.split(" ")[0]
                        }
                      />
                    );
                  })}
            </div>
            {profesionales1 &&
              profesionales1?.applicants?.length > 0 &&
              profesionales1?.applicants?.length > 3 ? (
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {profesionales1 &&
                  profesionales1?.applicants?.length > loadnum ? (
                  <button
                    onClick={() => setLoadnum(loadnum + 3)}
                    className={"button-orange-dashOp"}
                  >
                    {lang === "es" ? "Cargar más aplicantes" : "Load more applicants"}
                  </button>
                ) : (
                  <button
                    onClick={() => setLoadnum(6)}
                    className={"SidebarCV-CV"}
                  >
                    {lang === "es" ? "Ver menos" : "See less"}
                  </button>
                )}
              </div>
            ) : null}
          </div>

          {/*aplicantes ocultos*/}
          {oportunidad?.hiddenAplicants?.length ? (
            <Accordion
              style={{
                marginTop: "30px",
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <h2 style={{ marginTop: "0" }} className={"dashOp-subtitle"}>
                  {lang === "es"
                    ? "Aplicantes descartados"
                    : "Discarded applicants"}
                </h2>
              </AccordionSummary>
              <div>
                <AccordionDetails>
                  <div className={"perfiles-de-prof-similares"}>
                    {ocultos?.map((prof, index) => {
                      return (
                        <ProfesionalesSimilaresCard
                          key={index}
                          name={prof?.cv_file?.info_personal?.nombre}
                          job={prof?.cv_file?.info_personal?.profesion_actual}
                          // compatibility={redondeo(prof?.stars) + '%'}
                          image={prof.avatar}
                          recos={false}
                          id={prof._id}
                          LinkedInCv={prof?.urlCVLinkedIn}
                          generalCv={
                            prof?.cv_Personalized_uri
                              ? prof?.cv_Personalized_uri
                              : null
                          }
                        />
                      );
                    })}
                  </div>
                </AccordionDetails>
              </div>
            </Accordion>
          ) : null}

          {/*Aplicantes en espera*/}
          {aplicantes &&
            (aplicantes?.contacted_users?.length > 0 ||
              enpruebas?.data?.length > 0) ? (
            <div style={{ marginTop: "45px" }}>
              <h2 className={"dashOp-subtitle"}>
                {lang === "es" ? "Aplicantes en espera" : "Waiting Applicants"}
              </h2>
              <div>
                <DashboardTabs
                  entrevista={aplicantes}
                  enPruebas={enpruebas}
                  opId={id}
                  seleccionados={seleccionados}
                  oportunidad={oportunidad}
                />
              </div>
            </div>
          ) : null}
          {/*INFO EMPRESA*/}
          <div className={"dashOp-cont-info-emp"}>
            {/*info empresa y puesto*/}
            <div style={{ width: "100%" }}>
              <div>
                <div>
                  <h2 className={"dashOp-subtitle"}>
                    {lang === "es"
                      ? "Descripción de la oportunidad"
                      : "Opportunity Description"}
                  </h2>
                  {oportunidad?.aplicantes?.length > 0 ? (
                    <CSVLink {...csvReport} style={{ textDecoration: "none" }}>
                      <button
                        style={{
                          backgroundColor: "#F07130",
                          borderRadius: "5px",
                          color: "white",
                          fontSize: "14px",
                          fontWeight: 400,
                          height: "33px",
                        }}
                      >
                        {lang === "es"
                          ? "Descargar aplicantes"
                          : "Download applicants"}
                      </button>
                    </CSVLink>
                  ) : null}
                </div>
                <div className={"dasOp-card"}>
                  <div className="Sub-dasOp-card">
                    <div>
                      <p
                        style={{
                          fontSize: "20px",
                          fontWeight: 700,
                          color: "#393939",
                        }}
                      >
                        {oportunidad?.NombreOportunidad}
                      </p>
                      <p
                        style={{
                          fontSize: "16px",
                          fontWeight: 400,
                          color: "#393939",
                          marginTop: "-10px",
                        }}
                      >
                        {oportunidad?.NombreEmpresa}
                      </p>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          marginTop: "-10px",
                        }}
                      >
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M12 21C12 21 18 14.7 18 9C18 5.68629 15.3137 3 12 3C8.68629 3 6 5.68629 6 9C6 14.7 12 21 12 21Z"
                            stroke="#5D5D5D"
                          />
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M12 10C12.5523 10 13 9.55228 13 9C13 8.44772 12.5523 8 12 8C11.4477 8 11 8.44772 11 9C11 9.55228 11.4477 10 12 10Z"
                            stroke="#5D5D5D"
                          />
                        </svg>
                        <p className={"dashOp-text-sub"}>
                          {oportunidad?.country === "vacio"
                            ? null
                            : oportunidad?.country}
                        </p>
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >
                      <p
                        style={{
                          fontSize: "14px",
                          color: "#24D054",
                        }}
                      >
                        {oportunidad?.isVisible === true
                          ? lang === "es"
                            ? "Abierta"
                            : "Open"
                          : lang === "es"
                            ? "Cerrada"
                            : "Close"}
                      </p>
                      <p
                        style={{
                          fontSize: "14px",
                          color: "#989898",
                        }}
                      >
                        • {oportunidad?.modalidadDeTrabajo} •
                      </p>
                      <p
                        style={{
                          fontSize: "14px",
                          color: "#989898",
                        }}
                      >
                        {oportunidad?.aplicantes?.length}{" "}
                        {lang === "es" ? "Aplicantes" : "Applications"}
                      </p>
                    </div>
                  </div>
                  <p className={"dasOp-card-title"}>ÁREA DE CONOCIMIENTO:</p>
                  <ul>
                    {oportunidad?.empleos_alternativos?.map((e, i) => {
                      return (
                        <li key={i} className={"dasOp-card-desc"}>
                          {e}
                        </li>
                      );
                    })}
                  </ul>
                  <h3 className={"dasOp-card-title"}>
                    {lang === "es"
                      ? "RESPONSABILIDADES Y FUNCIONES:"
                      : "RESPONSIBILITIES AND FUNCTIONS:"}
                  </h3>
                  <ul>
                    {Array.isArray(oportunidad?.responsabilidadYfunciones)
                      ? oportunidad?.responsabilidadYfunciones?.map(
                        (item, index) => {
                          return (
                            <li key={index} className={"dasOp-card-desc"}>
                              {item.charAt(0).toUpperCase() +
                                item.slice(1).toLowerCase()}
                            </li>
                          );
                        }
                      )
                      : oportunidad?.responsabilidadYfunciones}
                  </ul>
                  <h3 className={"dasOp-card-title"}>
                    {lang === "es"
                      ? "DESCRIPCIÓN DEL CARGO"
                      : "POSITION DESCRIPTION"}
                  </h3>
                  <ul>
                    <li
                      key={oportunidad?.DescribeProyecto}
                      className={"dasOp-card-desc"}
                    >
                      {oportunidad?.DescribeProyecto}
                    </li>
                  </ul>
                </div>
              </div>
              {/*<div>*/}
              {/*  <h2*/}
              {/*    className={'dashOp-empresa'}>{oportunidad?.empresa ? oportunidad?.empresa : oportunidad?.NombreEmpresa}</h2>*/}
              {/*  <h2 className={'dashOp-text-sub'}>{oportunidad?.ciudad === 'vacio' ? null : oportunidad?.ciudad}</h2>*/}
              {/*  <h2 className={'dashOp-time'}>{oportunidad?.fecha_inicio?.slice(0, 10)} - <span*/}
              {/*    className={'dashOp-solicitudes'}>*/}
              {/*    {oportunidad?.aplicantes?.length} {lang === 'es' ? 'Solicitudes' : 'Applications'}*/}
              {/*  </span></h2>*/}
              {/*  <h2*/}
              {/*    className={'dashOp-text-sub'}>{lang === 'es' ? 'Cantidad de profesionales: ' : 'Number of professionals: '} {oportunidad?.profesionales}</h2>*/}

              {/*  <h2*/}
              {/*    className={'dashOp-salary'}>{lang === 'es' ? 'Salario: $ ' : 'Salary: $ '}{oportunidad?.presupuesto}</h2>*/}
              {/*  <h2*/}
              {/*    className={'dashOp-text-sub'}>{lang === 'es' ? 'Modalidad de contratación: ' : 'Modality of contracting: '}{oportunidad?.modalidadDeContratacion}</h2>*/}
              {/*  <h2*/}
              {/*    className={'dashOp-text-sub'}>{lang === 'es' ? 'Tipo de servicio deseado: ' : 'Desired type of service: '}{oportunidad?.tipoDeServicioDeseado}</h2>*/}
              {/*</div>*/}
              {/*cards verticales info laboral*/}
              <div style={{ width: "100%" }}>
                <div className={"dasOp-card"}>
                  <h3 className={"dasOp-card-title"}>
                    {lang === "es" ? "INFORMACIÓN LEGAL" : "LEGAL INFORMATION"}
                  </h3>
                  <div style={{ paddingLeft: "40px", marginTop: "10px" }}>
                    <h3
                      key={oportunidad?.experienciaAnos}
                      className={"legal-card-desc"}
                    >
                      {lang === "es"
                        ? "Experiencia requerida:"
                        : "Experience required:"}
                      <span className="legal-information-company">
                        {oportunidad?.experienciaAnos}
                        {oportunidad?.experienciaAnos.includes("años")
                          ? ""
                          : lang === "es"
                            ? " años"
                            : " years"}
                      </span>
                    </h3>
                    <h3
                      key={oportunidad?.fecha_inicio}
                      className={"legal-card-desc"}
                    >
                      {lang === "es"
                        ? "Fecha de publicación:"
                        : "Publication date:"}
                      <span className="legal-information-company">
                        {oportunidad?.fecha_inicio !== "no definido"
                          ? oportunidad?.fecha_inicio.slice(0, 10)
                          : "-"}
                      </span>
                    </h3>
                    <h3
                      key={oportunidad?.fecha_vencimiento}
                      className={"legal-card-desc"}
                    >
                      {lang === "es"
                        ? "Fecha de vencimiento:"
                        : "Expiration date:"}
                      <span className="legal-information-company">
                        {oportunidad?.fecha_vencimiento !== "no definido"
                          ? oportunidad?.fecha_vencimiento.slice(0, 10)
                          : "-"}
                      </span>
                    </h3>
                    <h3
                      key={oportunidad?.departamento}
                      className={"legal-card-desc"}
                    >
                      {lang === "es" ? "Departamento:" : "Department:"}
                      <span className="legal-information-company">
                        {oportunidad?.departamento !== "no definido"
                          ? oportunidad?.departamento
                          : "-"}
                      </span>
                    </h3>
                    <h3
                      key={oportunidad?.municipio}
                      className={"legal-card-desc"}
                    >
                      {lang === "es" ? "Municipio:" : "Municipality:"}
                      <span className="legal-information-company">
                        {oportunidad?.municipio !== "no definido"
                          ? oportunidad?.municipio
                          : "-"}
                      </span>
                    </h3>
                    <h3
                      key={oportunidad?.sector_economico}
                      className={"legal-card-desc"}
                    >
                      {lang === "es" ? "Sector económico:" : "Economic sector:"}
                      <span className="legal-information-company">
                        {oportunidad?.sector_economico !== "no definido"
                          ? oportunidad?.sector_economico
                          : "-"}
                      </span>
                    </h3>
                    <h3
                      key={oportunidad?.tipo_documento}
                      className={"legal-card-desc"}
                    >
                      {lang === "es" ? "Tipo de documento:" : "Document type:"}
                      <span className="legal-information-company">
                        {oportunidad?.tipo_documento !== "no definido"
                          ? oportunidad?.tipo_documento
                          : "-"}
                      </span>
                    </h3>
                    <h3
                      key={oportunidad?.documento}
                      className={"legal-card-desc"}
                    >
                      {lang === "es" ? "Documento:" : "Document:"}
                      <span className="legal-information-company">
                        {oportunidad?.documento !== "no definido"
                          ? oportunidad?.documento
                          : "-"}
                      </span>
                    </h3>
                  </div>
                </div>
              </div>
            </div>
            {/*Das SERVICIOS OCULTO MIENTRAS TANTO*/}
            <div
              className={"dashOp-cont-info-emp-serv"}
              style={{ display: "none" }}
            >
              <div>
                <h2 className={"dashOp-subtitle"}>
                  {lang === "es" ? "Metodologías" : "Methodologies"}
                </h2>
                <button className={"dashOp-met-button"}>
                  {lang === "es"
                    ? "Subir metodologías"
                    : "Upload methodologies"}
                </button>
                <h3 className={"dashOp-met-text"}>
                  {lang === "es"
                    ? "*Este espacio se habilitará cuando selecciones algún aplicante."
                    : "*This space will be enabled when you select an applicant."}
                </h3>
              </div>
              <AditionalServices />
              <Link to={"/company/pricing"}>
                <button className={"dashOp-adq-button"}>
                  {lang === "es" ? "Adquirir" : "Acquire"}
                </button>
              </Link>
            </div>
          </div>
          {/*PERFILES DE PROFESIONALES SIMILARES*/}
          <div>
            <hr />
            <h2 className={"dashOp-subtitle"}>
              {lang === "es"
                ? "Perfiles de profesionales similares"
                : "Similar professional profiles"}
              {usuariosRecomendados?.length ? null : (
                <Spinner
                  animation="border"
                  size="l"
                  style={{ marginLeft: "10px" }}
                />
              )}
            </h2>
            <div className={"perfiles-de-prof-similares"}>
              {usuariosRecomendados?.length &&
                usuariosRecomendados?.map((prof, index) => {
                  return (
                    <ProfesionalesSimilaresCard
                      key={index}
                      name={""}
                      job={""}
                      compatibility={`${Math.floor(prof.fit)}%`}
                      image={""}
                      recos={true}
                      id={prof.ID}
                    />
                  );
                })}
            </div>
          </div>
        </div>
      </>
    </>
  );
}
