import styled from 'styled-components';

export const CircularFrameContainer = styled.div<{color?: string}>`
  width:100%;
  height:100%;
  display:flex;
  align-items:center;
  justify-content: center;
  
  span{
    color: ${props => props.color ? props.color : '#dfdfdf'};
    width: 8.7em !important;
    margin-left: -6.1em;
  }
  
  #SecondCircle{
    color:#dfdfdf;
    position:absolute;
    width: 8.7em !important;
  }
`

export const AvatarContainer = styled.div<{color?: string}>`
  width:6.5em;
  height: 6.5em;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  position:absolute;

  border-radius:50%;
  margin-left:-.5em;

  img{
    width:100%;
    position:absolute;
    background-color: ${props => props.color ? props.color : '#dfdfdf'};
  }
`
