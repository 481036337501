import React from 'react';

const ColaboratorInvite = ({color}) => {
  return (
    <div>
      <h2 style={{
        textAlign: 'center',
        fontSize: '1.5rem',
        fontWeight: 'normal',
        color: '#000',
        marginBottom: '1rem',
        marginTop: '1rem',
      }}
      >Invita a un colaborador para que sea admin</h2>
      <div style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
      }}>
        <input style={{
          width: '90%',
          height: '2rem',
          borderRadius: '0.5rem',
          border: '1px solid #000',
          padding: '0.5rem',
          marginBottom: '1rem',
        }} type="email" placeholder={'Email del colaborador'}/>
        <button
          style={{
            width: '90%',
            height: '2rem',
            borderRadius: '0.5rem',
            border: '1px solid #000',
            marginBottom: '1rem',
            backgroundColor: color.primary,
            color: '#fff',
            cursor: 'pointer',
          }}
        >Enviar</button>
      </div>
    </div>
  )
}

export default ColaboratorInvite;
