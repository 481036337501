import React from "react";
import { connect } from "react-redux";
import "./styles/ContractDocs.css";
import Upload from "../../components/upload/UploadContract";
import Header from "../../components/Header/Header";
import en from "../../lang/en-US";
import es from "../../lang/es-COL"
import counterpart from "counterpart";
import Translate from "react-translate-component";

export const ContractDocs = (props) => {
  console.log(props.userform);
  counterpart.registerTranslations('en',en) 
  counterpart.registerTranslations('es',es)  
  counterpart.setLocale(localStorage.getItem('langlocal'));
  return (
    <>
    <Header />
    <div className="container-casos-exito">
    <div style={{marginTop:"0.3rem"}} className="legal-tittle-ini">
        <p className="legal-tittle-content">{<Translate content="Subeaquilosdocumentos" component="span"/>}</p>
      </div>
      <div className="container">
        <div className="upload-container">
          <Upload idUsuario={props.actual._id} />
        </div>
      </div>
    </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  actual: state.ActualProject,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ContractDocs);
