import React, { useEffect, useState } from 'react'
import DataTable from 'react-data-table-component';
import axios from 'axios'
import ReceiptOutlinedIcon from '@mui/icons-material/ReceiptOutlined';
import CardCantidad from '../cards/CardCantidad';

function TablaAplicadoOfertas() {

  const [data, setData] = useState([])
  const [count, setCount] = useState(0)

  const columns = [
    {
      name: 'Nombre',
      selector: 'name',
      sortable: true,
    },
    {
      name: 'Apellido',
      selector: 'lastName',
      sortable: true,
    },
    {
      name: 'Email',
      selector: 'email',
      sortable: true,
    },
  ];

  useEffect(() => {
    axios.get('https://findally-backend.herokuapp.com/api/unilever/users/appliedProjects')
      .then(res => {
        setData(res.data.data)
        setCount(res.data.count)
      })
      .catch(err => console.log(err))
  }, [])


  const customStyles = {
    table: {
      style: {
        minHeight: '150px', 
      },
    },
    headCells: {
      style: {
        fontWeight: 'bold',
        fontSize: '14px',
      },
    },
    cells: {
      style: {
        // textTransform: 'capitalize',
      },
    },
  };





  return (
    <div style={{
      display: 'flex',
      paddingInline: '3em',
      alignItems: 'center',
      justifyContent: 'space-between',
      marginTop:'2em'
    }}>
      <div style={{
        width: '70%',
      }}>
        <DataTable
          title="Aplicado a ofertas"
          columns={columns}
          data={data}
          customStyles={customStyles}
          pagination
          paginationPerPage={5}
          paginationRowsPerPageOptions={[5, 10, 15, 20, 25, 30]}
          paginationComponentOptions={{
            rowsPerPageText: 'Filas por página:',
            rangeSeparatorText: 'de',
            noRowsPerPage: false,
            selectAllRowsItem: true,
            selectAllRowsItemText: 'Todos',
          }}
          fixedHeader
          fixedHeaderScrollHeight="600px"
          highlightOnHover
          pointerOnHover
          noDataComponent="No hay datos"
        />
      </div>
        <CardCantidad title='Cantidad' value={count} Icon={ReceiptOutlinedIcon} color={'#ff7f50'} />
    </div>
  )
}

export default TablaAplicadoOfertas