import React from "react";
import PlaceOutlinedIcon from '@mui/icons-material/PlaceOutlined';
import './NewCardOportunidad.css';
import {Link} from "react-router-dom";
import {useSelector} from "react-redux";

export default function NewCardOportunidadList({
                                                 imagen,
                                                 lenguaje,
                                                 id,
                                                 dir,
                                                 salario,
                                                 titulo,
                                                 titulo2,
                                                 empresa,
                                                 descripcion,
                                                 Anonimo,
                                                 ciudad,
                                                 width,
                                                 fecha,
                                                 profesionales,
                                                 porcentaje
                                               }) {
  const lang = useSelector((state) => state.lang);
  const formatterPeso = new Intl.NumberFormat('es-CO', {
    style: 'currency',
    currency: 'COP',
    minimumFractionDigits: 0
  })
  return (
    <div className="NewCardOportunidad-Container-rectangular">
      <div className={'NewCardOportunidad-Top-Container-rectangle'}>
        <div className="NewCardOportunidad-Top-subContainer-1">
          <img className={'NewCardOportunidad-Image-rectangle'} src={imagen ? imagen : ''} alt={imagen ? imagen : ''}/>
        </div>
        <div className="NewCardOportunidad-Top-Container-rectangle-inner-1">
          <div className="NewCardOportunidad-Top-Container-rectangle-inner-1-1">
          <Link to={`/consultor/project/${id}`} style={{
          width: '80%',
          cursor: 'pointer'
        }}>
            <div className="NewCardOportunidad-Top-subContainer-2">
              {width > 710 ?
                <Link to={`/consultor/project/${id}`} style={{
                  fontSize: '18px',
                  fontWeight: '700',
                  color: '#393939',
                  width: '100%',
                  margin: '0',
                }}>
                  {titulo !== 'Unnamed Oportunity' ? titulo.length > 65 ? titulo.substring(0, 65).charAt(0).toUpperCase() + titulo.substring(0, 65).slice(1).toLowerCase() + '...' : titulo.charAt(0).toUpperCase() + titulo.slice(1).toLowerCase() : titulo2.length > 65 ? titulo2.substring(0, 65).charAt(0).toUpperCase() + titulo2.substring(0, 65).slice(1).toLowerCase() + '...' : titulo2.charAt(0).toUpperCase() + titulo2.slice(1).toLowerCase()}
                </Link>
                : <Link to={`/consultor/project/${id}`} style={{
                  fontSize: '18px',
                  fontWeight: '700',
                  color: '#393939',
                  width: '100%',
                  margin: '0',
                }}>
                  {titulo !== 'Unnamed Oportunity' ? titulo.length > 40 ? titulo.substring(0, 40).charAt(0).toUpperCase() + titulo.substring(0, 40).slice(1).toLowerCase() + '...' : titulo.charAt(0).toUpperCase() + titulo.slice(1).toLowerCase() : titulo2.length > 40 ? titulo2.substring(0, 40).charAt(0).toUpperCase() + titulo2.substring(0, 40).slice(1).toLowerCase() + '...' : titulo2.charAt(0).toUpperCase() + titulo2.slice(1).toLowerCase()}
                </Link>
              }
              <div style={{
                display: 'flex',
                width: '100%',
                justifyContent: 'space-between',
                alignItems: 'center',
                margin: '10px 0px'
              }}>
                {
                  ciudad ? (
                    <div style={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-evenly',
                      alignItems: 'center',
                      textAlign: 'center',
                    }}>
                      <div style={{
                        marginRight: '18px',
                      }}><PlaceOutlinedIcon style={{color: '#C5C5C5', fontSize: '20px', margin: 0, padding: 0}}/></div>
                      <div style={{
                        display: 'flex',
                        justifyContent: 'flex-start',
                        alignItems: 'center',
                        width: 'calc(100% - 20px)',
                        height: '100%',
                        color: '#C5C5C5',
                        fontSize: '16px',
                        fontWeight: '400',
                      }}>
                        {ciudad}
                      </div>
                    </div>
                  ) : null
                }
                <div className="container-info-apli-fecha">
                  <h3 className="text-card-info3">
                    {fecha.split('-').reverse().join('-')}
                  </h3>
                  <h3 className="text-card-info3">
                    /
                  </h3>
                  <h3 className="text-card-info3">
                    {profesionales} {lang === 'es' ? 'aplicantes' : 'applicants'}
                  </h3>

                </div>
              </div>
            </div>
          </Link>
          </div>
          <div className="NewCardOportunidad-Top-Container-rectangle-inner-1-2">
            <div className="NewCardOportunidad-Top-subContainer-3">
              {/* { width > 710 ?
                  <h1 style={{
                fontSize: '20px',
                fontWeight: '700',
                color: '#393939',
                width: '100%',
                margin: '0',
              }}>
                {titulo !== 'Unnamed Oportunity' ? titulo.length > 65 ? titulo.substring(0, 65).charAt(0).toUpperCase() + titulo.substring(0, 65).slice(1).toLowerCase() + '...' : titulo.charAt(0).toUpperCase() + titulo.slice(1).toLowerCase() : titulo2.length > 65 ? titulo2.substring(0, 65).charAt(0).toUpperCase() + titulo2.substring(0, 65).slice(1).toLowerCase() + '...' : titulo2.charAt(0).toUpperCase() + titulo2.slice(1).toLowerCase()}
              </h1>
              : <h1 style={{
                fontSize: '20px',
                fontWeight: '700',
                color: '#393939',
                width: '100%',
                margin: '0',
              }}>
                {titulo !== 'Unnamed Oportunity' ? titulo.length > 40 ? titulo.substring(0, 40).charAt(0).toUpperCase() + titulo.substring(0, 40).slice(1).toLowerCase() + '...' : titulo.charAt(0).toUpperCase() + titulo.slice(1).toLowerCase() : titulo2.length > 40 ? titulo2.substring(0, 40).charAt(0).toUpperCase() + titulo2.substring(0, 40).slice(1).toLowerCase() + '...' : titulo2.charAt(0).toUpperCase() + titulo2.slice(1).toLowerCase()}
              </h1>} */}
            </div>
            <div className="NewCardOportunidad-Top-subContainer-4">
              <p style={{
                fontSize: '14px',
                fontWeight: '400',
                color: '#5D5D5D',
                textAlign: 'left',
                margin: 0,
                paddingBottom: '30px',
              }}>{lang === 'es' ? 'Profesionales con conocimiento en: ' : 'Professionals with knowledge in: '}{!descripcion || descripcion === 'NA' || descripcion === 'Otro' ? lenguaje === 'es' ? 'No especifica' : 'Not specified' : descripcion.length > 100 ? descripcion.substring(0, 100) + '...' : descripcion}</p>
            </div>
          </div>
        </div>
        <div className="NewCardOportunidad-Top-Container-rectangle-inner-2">
          <div className="NewCardOportunidad-Top-Container-rectangle-inner-2-1">
            <div className="NewCardOportunidad-mid-Container-inner-3-1">
              {
                !salario.length ? lenguaje === 'es' ?
                  <p className={'CardOportunidadProfesional-Salario-Null'}>Salario no especificado</p> :
                  <p className={'CardOportunidadProfesional-Salario-Null'}>'Salary not
                    specified</p> : typeof salario === 'number' ?
                  <p className={'CardOportunidadProfesional-Salario'}>`${formatterPeso.format(salario)}`</p> :
                  <p className={'CardOportunidadProfesional-Salario'}>
                    {salario.split(' ')[0] === 'COP' || salario.split(' ')[0] === 'USD' ? salario.split(' ')[0] === 'Divisa' ? `$ ${salario.split(' ')[1]}` : salario.split(' ')[1] === '0' ? lang === 'es' ? 'Salario no especificado' : 'Salary not specified' : `${salario.split(' ')[0]} ${salario.split(' ')[1]}` : `${salario.split(' ')[1]}`}
                    <span style={{
                      fontSize: '14px',
                      fontWeight: '700',
                      color: '#C3CCFF'
                    }}>
                      {lang === 'es' && salario.split(' ')[1] !== '0' ? '/mensual' : lang === 'en' && salario.split(' ')[1] !== '0' ? '/month' : ''}
                    </span>
                  </p>
              }
            </div>
          </div>
          {
            porcentaje ?
              <div style={{
                color: '#F07130',
                marginTop: '10px',
                marginLeft: '25px',
                fontSize: '14px'
              }}>
                {porcentaje.toFixed(2)}% {lang === 'es' ? 'Compatibilidad' : 'Compatibility'}
              </div>
              : null
          }
          <div className="NewCardOportunidad-Top-Container-rectangle-inner-2-2">
            <Link to={`/consultor/project/${id}`}>
              <button
                className={'CardOportunidadProfesional-Button'}>
                {lenguaje === 'es' ? 'Aplicar' : 'Apply'}
              </button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}
