import React, { useEffect, useState } from "react";
import "./PerfilProfesional.css";
import Sidebar from "../../components/Sidebar/Sidebar";
import Navbar from "../../components/Navbar/Navbar";
import { Link } from "react-router-dom";
import larrow from "../../assets/icons/l-arrow.svg";
import TestCard from "../home/TestCard/TestCard";
import { useDispatch, useSelector } from "react-redux";
import { Modal } from "@material-ui/core";
import { Box } from "@mui/material";
import PopUpTest from "../header/PopUpTest";
import PopUpResultado from "../header/PopUpResultado";
import axios from "axios";
import { LoadCv, loginReq, tourStatus } from "../../actions";
import work from "../../assets/icons/work-image.svg";
import upload from "../../assets/icons/Upload.svg";
import download from "../../assets/icons/download-icon.svg";
import file from "../../assets/icons/file.svg";
import PerfilCard from "./sideCards/PerfilCard";
import OportunidadIdiomaCard from "./sideCards/oportunidadIdiomaCard";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { CVdocument } from "../../utils/CvCreator";
/*import PortfolioCard from './sideCards/portfolioCard';*/
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getUserInfo } from "../../actions";
import { AffindaAPI, AffindaCredential } from "@affinda/affinda";
import Spinner from "react-bootstrap/Spinner";
import UseTour from "../home/useTour";
import TutorialCvLinkedin from "./TutorialCvLinkedin/TutorialCvLinkedin";

// https://www.figma.com/file/bRCsb0kTVy7fsHDPrWlxTn/Coally?node-id=4332%3A29797&t=zGSd2G2j5vkFtXfq-4

export default function PerfilProfesional() {
  const dispatch = useDispatch();
  const [isOpenTestPersonalidad, setOpenTestPersonalidad] = useState(false);
  const [openTestPersonalidadResultado, setOpenTestPersonalidadResultado] =
    useState(false);
  const [resultadoTest, setResultadoTest] = useState(null);
  const [ready, setReady] = useState(false);
  const [loading, setLoading] = useState(false);
  //eslint-disable-next-line
  const [popupPersonalidad, setPopUpPersonalidad] = useState(false);

  /*Affinda credentials*/
  const credential = new AffindaCredential(
    "aff_a3af49e5d81e299427886b847d84b3d72b6eb061"
  );
  const client = new AffindaAPI(credential);

  const { lang, user, cv, tourViewed } = useSelector((state) => state);
  const tests = [
    {
      id: 1,
      name: "Test de personalidad",
      real: "personalidad",
      description:
        "Realice este test gratuito para saber más sobre sí mismo y sus puntos fuertes.",
    },
    {
      id: 2,
      name: "Test Vocacional",
      description:
        "Realice este test gratuito para saber más sobre sí mismo y sus puntos fuertes.",
    },
    {
      id: 3,
      name: "Test de Inteligencia",
      description:
        "Realice este test gratuito para saber más sobre sí mismo y sus puntos fuertes.",
    },
    {
      id: 4,
      name: "Habilidades sociales",
      description:
        "Realice este test gratuito para saber más sobre sí mismo y sus puntos fuertes.",
    },
  ];
  const testsENG = [
    {
      id: 1,
      name: "Personality test",
      real: "personalidad",
      description:
        "Take this free trial to learn more about yourself and your strengths.",
    },
    {
      id: 2,
      name: "Vocational test",
      description:
        "Take this free trial to learn more about yourself and your strengths.",
    },
    {
      id: 3,
      name: "Inteligence test",
      description:
        "Take this free trial to learn more about yourself and your strengths.",
    },
    {
      id: 4,
      name: "Social skills",
      description:
        "Take this free trial to learn more about yourself and your strengths.",
    },
  ];

  const [width, setWidth] = useState(window.innerWidth);
  const updateWidthAndHeight = () => {
    setWidth(window.innerWidth);
  };
  useEffect(() => {
    window.addEventListener("resize", updateWidthAndHeight);
    return () => window.removeEventListener("resize", updateWidthAndHeight);
  }, [width]);

  const handleClosePersonalidad = () => setOpenTestPersonalidad(false);

  const handleOpenPersonalidadResultado = (result) => {
    setOpenTestPersonalidad(false);
    setResultadoTest(result);
    setOpenTestPersonalidadResultado(true);
  };
  const handleCloseResultado = () => setOpenTestPersonalidadResultado(false);

  const mandarpersonalizad = (e) => {
    e.preventDefault();
    let nuevadata = user;
    nuevadata.calificaion_aptitudes = resultadoTest;
    axios
      .put("https://findally-backend.herokuapp.com/update/user/" + user.id, {
        calificaion_aptitudes: resultadoTest,
      })
      .then((r) => {
        localStorage.setItem("user", JSON.stringify(nuevadata));
        dispatch(loginReq(nuevadata));
        setPopUpPersonalidad(false);
      });
  };
  //popup de test
  const style1 = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: width > 600 ? 772 : "90%",
    height: width > 600 ? 721 : "80vh",
    bgcolor: "#FFFFFF",
    border: "2px solid #000",
    borderRadius: "8px",
    boxShadow: 24,
    overflow: "auto",
  };
  //popup resultado
  const styles2 = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: width > 600 ? 772 : "90%",
    height: width > 600 ? 603 : "80vh",
    bgcolor: "#FFFFFF",
    border: "2px solid #000",
    borderRadius: "8px",
    boxShadow: 24,
    overflow: "auto",
  };

  useEffect(() => {
    async function fetchData() {
      if (user.id && !cv) {
        try {
          const res = await axios.get(
            `https://findally-backend.herokuapp.com/user/CVprofile/${user.id}`
          );
          dispatch(LoadCv(res.data.cv_file));
          setReady(true);
        } catch (err) {
          console.log(err);
          setReady(true);
        }
      }
    }

    fetchData();
  }, [dispatch, user.id, cv]);

  const [selectedFile, setSelectedFile] = useState(null);
  const [isFilePicked, setIsFilePicked] = useState(false);
  const [selectedFile2, setSelectedFile2] = useState(null);
  const [isFilePicked2, setIsFilePicked2] = useState(false);

  const changeHandler = (e) => {
    console.log(e.target.files[0]);
    setSelectedFile(e.target.files[0]);
    console.log(selectedFile);
    const fileData = new FormData();
    fileData.append("upload_file", e.target.files[0]);
    fileData.append("usuario", user.id);
    axios
      .post(
        `https://findally-backend.herokuapp.com/api/user/validateCV?lang=${lang}`,
        fileData
      )
      .then((res) => {
        console.log(
          `https://findally-backend.herokuapp.com/api/user/validateCV?lang=${lang}`
        );
        if (res.status === 200) {
          toast.success(res.data.message, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          return setIsFilePicked(true);
        }
      })
      .catch((err) => {
        if (err.response.status === 400) {
          setSelectedFile(null);
          toast.error(
            lang === "es"
              ? "Formato invalido de cv linkedin"
              : "Invalid linkedin cv format",
            {
              position: "top-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            }
          );
        }
        // setMessage(true)
      });
  };

  const changeHandler2 = (e) => {
    setSelectedFile2(e.target.files[0]);
    setIsFilePicked2(true);
  };

  const handleUpload = (e) => {
    const fd = new FormData();
    fd.append("upload_file", selectedFile);
    fd.append("usuario", user.id);
    axios
      .post("https://findally-backend.herokuapp.com/api/user/readCv", fd)
      .then((res) => {
        if (res.status === 200) {
        }
      })
      .catch((err) => {
        console.log(err);
        return toast.error(
          lang === "es"
            ? "Hubo un error al subir tu cv"
            : "There was an error uploading your cv",
          {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          }
        );
      });
  };

  const handleUpload2 = (e) => {
    setLoading(true);
    const fd2 = new FormData();
    fd2.append("upload_file", selectedFile2);
    fd2.append("usuario", user.id);
    axios
      .post(
        "https://findally-backend.herokuapp.com/api/user/readCvPersonalized",
        fd2
      )
      .then((res) => {
        if (res.status === 200) {
          /*Affinda Api client*/
          client.createResume({ file: fd2.get("upload_file") }).then((r) => {
            toast
              .promise(
                axios.post(
                  `https://findally-backend.herokuapp.com/api/user/readCVFromBody`,
                  {
                    usuario: user.id,
                    infoCV: {
                      info_personal: {
                        nombre: r?.data?.name
                          ? r.data.name?.first
                            ? r?.data?.name?.last
                              ? r?.data?.name?.first + " " + r?.data?.name?.last
                              : r?.data?.name?.first
                            : r?.data?.name?.last
                          : "-",
                        sexo: "-",
                        profesion_actual: r?.data?.profession
                          ? r?.data?.profession
                          : "-",
                        fecha_nacimiento: r?.data?.dateOfBirth
                          ? r?.data?.dateOfBirth
                          : "-",
                        pais_nacimiento: r?.data?.location
                          ? r?.data?.location.country
                            ? r?.data?.location.country
                            : "-"
                          : "-",
                        departamento_nacimiento: r?.data?.location
                          ? r?.data?.location.state
                            ? r?.data?.location.state
                            : "-"
                          : "-",
                        municipio_nacimiento: r?.data?.location
                          ? r?.data?.location.state
                            ? r?.data?.location.state
                            : "-"
                          : "-",
                        ubicacion: r?.data?.location
                          ? r?.data?.location.country
                            ? r?.data?.location.country
                            : "-"
                          : "-",
                      },
                      contacto: [
                        r.data?.phoneNumbers ? r.data.phoneNumbers[0] : null,
                        r.data?.emails ? r.data.emails[0] : null,
                        r.data?.websites ? r.data.websites[0] : null,
                      ],
                      aptitudes_principales: r?.data?.skills
                        ? r?.data?.skills.map((skill) => {
                          return skill.name;
                        })
                        : [],
                      languages: r?.data?.languages
                        ? r?.data?.languages.map((language) => {
                          return language;
                        })
                        : [],
                      certifications: r?.data?.certifications
                        ? r?.data?.certifications.map((certification) => {
                          return certification;
                        })
                        : [],
                      extracto: r?.data?.summary ? r?.data?.summary : "-",
                      experiencia: r?.data?.workExperience
                        ? r?.data?.workExperience.map((experience) => {
                          return {
                            Nombreempresa: experience?.organization
                              ? experience?.organization
                              : "-",
                            fecha_inicio: experience?.dates
                              ? experience?.dates.startDate
                                ? experience?.dates.startDate
                                : "-"
                              : "-",
                            fecha_finalizacion: experience?.dates
                              ? experience?.dates.endDate
                                ? experience?.dates.endDate
                                : "-"
                              : "-",
                            totalDuracion: experience?.dates
                              ? experience?.dates.monthsInPosition !== null
                                ? experience?.dates.monthsInPosition
                                : "-"
                              : "-",
                            actualJob: experience?.dates
                              ? experience?.dates.isCurrent
                                ? experience?.dates.isCurrent
                                : false
                              : false,
                            cargos: [
                              {
                                nombrecargo: experience?.jobTitle
                                  ? experience?.jobTitle
                                  : "-",
                                duracion: experience?.dates
                                  ? experience?.monthsInPosition !== null
                                    ? experience?.monthsInPosition
                                    : "-"
                                  : "-",
                                ubicacion: experience?.location
                                  ? experience?.location.country
                                  : "-",
                                descripcion: experience?.jobDescription
                                  ? experience?.jobDescription
                                  : "-",
                              },
                            ],
                          };
                        })
                        : [],
                      educacion: r?.data?.education
                        ? r?.data?.education.map((education) => {
                          return {
                            NombreInstitucion: education?.organization
                              ? education?.organization
                              : "-",
                            TipoEduacion: education?.accreditation
                              ? education?.accreditation.educationLevel
                                ? education?.accreditation.educationLevel
                                : "-"
                              : "-",
                            Titulo_Certificacion: education?.accreditation
                              ? education?.accreditation.education
                                ? education?.accreditation.education
                                : "-"
                              : "-",
                            fecha_finalizacion: education?.dates
                              ? education?.completionDate !== null
                                ? education?.completionDate
                                : "-"
                              : "-",
                            duracion: education?.dates
                              ? education?.monthsInPosition !== null
                                ? education?.monthsInPosition
                                : "-"
                              : "-",
                          };
                        })
                        : [],
                    },
                  }
                ),
                {
                  pending:
                    lang === "es" ? "Leyendo tu cv..." : "Reading your cv...",
                  success:
                    lang === "es"
                      ? "Tu cv se ha leido correctamente"
                      : "Your cv has been read correctly",
                  error:
                    lang === "es"
                      ? "Hubo un error al subir tu cv"
                      : "There was an error uploading your cv",
                }
              )
              .then(() => {
                setLoading(false);
                dispatch(getUserInfo(user.id));
              });
          });
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
        return toast.error(
          lang === "es"
            ? "Hubo un error al subir tu cv"
            : "There was an error uploading your cv",
          {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          }
        );
      });
  };


  useEffect(() => {
    if (!!window?.location.search) {
      toast.success(lang === 'es' ? 'Cargando test de personalidad' : 'Loading personality test')
      const id = 'testPers'
      const test = document?.getElementById(id);
      setTimeout(() => {
        test.click();
      }, 3000)
    } else {
      return
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window?.location.search, lang]);


  const handleCancel = (e) => {
    e.preventDefault();
    setSelectedFile(null);
    setIsFilePicked(false);
    setSelectedFile2(null);
    setIsFilePicked2(false);
  };
  const handleCancel1 = (e) => {
    e.preventDefault();
    setSelectedFile(null);
    setIsFilePicked(false);
  };
  const handleCancel2 = (e) => {
    e.preventDefault();
    setSelectedFile2(null);
    setIsFilePicked2(false);
  };

  const handleDownloadATS = () => {
    if (cv?.usuario) {
      document.getElementById("download456").click();
    }
  };
  useEffect(() => {
    if (tourViewed === true) {
      return;
    }
    setTimeout(() => {
      dispatch(tourStatus({ run: true }));
    }, 1000);
  }, [tourViewed, dispatch]);


  return (
    <>
      {/* <ToastContainer/> */}
      <div className={"contenedor-oportunidad"}>
        <Sidebar location={"home"} />
        <div
          className="BodyWeb"
          style={{
            padding: "0rem 2rem",
            position: "absolute",
            right: "0",
          }}
        >
          <Navbar locate={"home"} />
          {/*head perfil: los (links under the hood son un div)*/}
          <div style={{ paddingInline: "2.5%" }}>
            <Link className={"perfil-prof-header"} to={""}>
              <img src={larrow} alt={""} />
              <h1 className={"perfil-prof-titulo"}>
                {lang === "es" ? "Perfil" : "Profile"}
              </h1>
            </Link>
            {/*body perfil*/}
            <div className={"perfil-prof-body"}>
              <div className={"perfil-prof-body-izq"}>
                {/*acá van tus componentes y sections @nahuel*/}
                <PerfilCard user={user} cv={cv} lang={lang} />
                <OportunidadIdiomaCard user={user} cv={cv} lang={lang} />
                {/*<PortfolioCard user={user} cv={cv} lang={lang}/>*/}
              </div>
              <div className={"perfil-prof-body-der"}>
                <div>
                  <h1 className={"perfil-prof-nombre"}>
                    {cv?.info_personal?.nombre
                      ? cv?.info_personal?.nombre
                      : lang === "es"
                        ? "Completar tu nombre"
                        : "Complete your name"}
                  </h1>
                  <h2 className={"perfil-prof-empleo"}>
                    {cv?.info_personal?.profesion_actual
                      ? cv?.info_personal?.profesion_actual
                      : lang === "es"
                        ? "Completar tu profesión"
                        : "complete your profession"}
                  </h2>
                </div>
                <div className={"perfil-prof-sub-cont"}>
                  <h2 className={"perfil-prof-sub-subtitulo"}>
                    {lang === "es" ? "Acerca de" : "About"}
                  </h2>
                  <p className={"perfil-prof-textos"}>
                    {cv?.extracto
                      ? cv?.extracto
                      : lang === "es"
                        ? "Completar tu extracto"
                        : "Complete your extract"}
                  </p>
                  <hr />
                  <div className="container-hv-tutorial">
                    <h2 className={"perfil-prof-sub-subtitulo-coally"}>
                      {lang === "es" ? "Hoja de vida" : "Resume"}
                    </h2>
                    <TutorialCvLinkedin lang={lang} />
                  </div>
                  <div className={"contenedor-tour"}>
                    <UseTour />
                  </div>
                  <div className={"perfil-cv-input-contenedor-global"} id="cv">
                    {!cv && ready && (
                      <label className={"perfil-cv-input-contenedor"}>
                        <div className={"perfil-cv-input-subcontenedor"}>
                          <img src={upload} alt={"img-upload"} />
                        </div>

                        <div>
                          <h3 className={"perfil-cv-input-title"}>
                            {lang === "es"
                              ? "Subir CV LinkedIn"
                              : "Upload LinkedIn CV"}
                          </h3>
                          <h3 className={"perfil-cv-input-subtitle"}>PDF</h3>
                        </div>
                        <input
                          onChange={(e) => changeHandler(e)}
                          style={{ display: "none" }}
                          type={"file"}
                        />
                      </label>
                    )}
                    <label className={"perfil-cv-input-contenedor"}>
                      <div className={"perfil-cv-input-subcontenedor"}>
                        {!loading ? (
                          <img src={upload} alt={"img-upload"} />
                        ) : (
                          <Spinner animation="border" variant="light" />
                        )}
                      </div>
                      <div>
                        {!loading ? (
                          <>
                            <h3 className={"perfil-cv-input-title"}>
                              {lang === "es"
                                ? "Subir CV general"
                                : "Upload general CV"}
                            </h3>
                            <h3 className={"perfil-cv-input-subtitle"}>PDF</h3>{" "}
                          </>
                        ) : (
                          <>
                            <h3
                              className={"perfil-cv-input-title"}
                              style={{ marginLeft: "-40px" }}
                            >
                              {lang === "es"
                                ? "Leyendo CV ..."
                                : "Reading resume..."}
                            </h3>
                            <h3
                              className={"perfil-cv-input-subtitle"}
                              style={{ marginLeft: "-40px" }}
                            >
                              PDF
                            </h3>
                          </>
                        )}
                      </div>
                      <input
                        onChange={(e) => changeHandler2(e)}
                        style={{ display: "none" }}
                        type={"file"}
                      />
                    </label>
                    {cv && cv?.usuario && (
                      <label
                        className={"perfil-cv-input-contenedor"}
                        onClick={handleDownloadATS}
                      >
                        <div className={"perfil-cv-input-subcontenedor"}>
                          <img src={download} alt={"img-upload"} />
                        </div>
                        <div>
                          <h3 className={"perfil-cv-input-title"}>
                            {lang === "es"
                              ? "Descargar CV ATS"
                              : "Download ATS CV"}
                          </h3>
                          <h3 className={"perfil-cv-input-subtitle"}>PDF</h3>
                        </div>
                      </label>
                    )}
                    {isFilePicked ? (
                      <div className={"perfil-cv-input-contenedor2"}>
                        <div className={"perfil-cv-input-subcontenedor2"}>
                          <img src={file} alt={"img-upload"} />
                        </div>
                        <div>
                          <h3 className={"perfil-cv-input-title2"}>
                            {selectedFile.name}
                          </h3>
                          <h3 className={"perfil-cv-input-subtitle2"}>
                            {selectedFile?.size}KB
                          </h3>
                        </div>
                        <div>
                          <button
                            style={{
                              backgroundColor: "#fff",
                              color: "#202256",
                              position: "absolute",
                              marginTop: "-50px",
                              fontWeight: 600,
                            }}
                            onClick={(e) => handleCancel1(e)}
                          >
                            X
                          </button>
                        </div>
                      </div>
                    ) : null}
                    {isFilePicked2 ? (
                      <div className={"perfil-cv-input-contenedor2"}>
                        <div className={"perfil-cv-input-subcontenedor2"}>
                          <img src={file} alt={"img-upload"} />
                        </div>
                        <div>
                          <h3 className={"perfil-cv-input-title2"}>
                            {selectedFile2.name}
                          </h3>
                          <h3 className={"perfil-cv-input-subtitle2"}>
                            {selectedFile2?.size}KB
                          </h3>
                        </div>
                        <div>
                          <button
                            style={{
                              backgroundColor: "#fff",
                              color: "#202256",
                              position: "absolute",
                              marginTop: "-50px",
                              fontWeight: 600,
                            }}
                            onClick={(e) => handleCancel2(e)}
                          >
                            X
                          </button>
                        </div>
                      </div>
                    ) : null}
                  </div>
                  <div style={{ display: "none" }}>
                    <PDFDownloadLink
                      document={<CVdocument user={{ cv_file: cv }} />}
                      fileName={`Cv-${cv?.info_personal?.nombre}.pdf`}
                    >
                      <button
                        id="download456"
                        style={{
                          backgroundColor: "transparent",
                          height: "35px",
                          borderRadius: "5px",
                          borderStyle: "solid",
                          borderWidth: "2px",
                        }}
                      ></button>
                    </PDFDownloadLink>
                  </div>
                  <div>
                    {ready && !cv && (
                      <h2 className={"perfil-cv-advise"}>
                        {lang === "es"
                          ? "Si no tienes Hoja de vida, puedes llenar el"
                          : "If you do not have a CV, you can fill out the"}{" "}
                        {lang === "es" ? (
                          <Link
                            to={"/consultor/cv"}
                            className={"perfil-cv-advise2"}
                          >
                            siguiente formulario.
                          </Link>
                        ) : (
                          <Link
                            to={"/consultor/cv"}
                            className={"perfil-cv-advise2"}
                          >
                            following form.
                          </Link>
                        )}
                      </h2>
                    )}
                    <div className={"perfil-cv-button-group"}>
                      {isFilePicked ? (
                        <button
                          onClick={(e) => handleUpload(e)}
                          className={"perfil-cv-subir"}
                        >
                          {lang === "es" ? "Subir" : "Upload"}
                        </button>
                      ) : (
                        <button
                          onClick={(e) => handleUpload(e)}
                          className={"perfil-cv-subir"}
                          disabled
                        >
                          {lang === "es" ? "Subir" : "Upload"}
                        </button>
                      )}
                      {isFilePicked2 ? (
                        <button
                          style={{ backgroundColor: "#F07130" }}
                          onClick={(e) => handleUpload2(e)}
                          className={"perfil-cv-subir"}
                        >
                          {lang === "es"
                            ? "Subir cv general"
                            : "Upload general cv"}
                        </button>
                      ) : null}
                      <button
                        onClick={(e) => handleCancel(e)}
                        className={"perfil-cv-cancel"}
                      >
                        {lang === "es" ? "Cancelar" : "Cancel"}
                      </button>
                    </div>
                  </div>
                </div>
                <div className={"perfil-prof-sub-cont"}>
                  <h2 className={"perfil-prof-sub-subtitulo"}>
                    {lang === "es" ? "Educación" : "Education"}
                  </h2>
                  {cv?.educacion &&
                    cv.educacion.length > 0 &&
                    cv.educacion[0].NombreInstitucion !== "" ? (
                    cv?.educacion?.map((e, i) => {
                      return (
                        <div className={"perfil-edu-container"} key={i}>
                          {e.NombreInstitucion ? (
                            <h3
                              className={
                                "perfil-prof-sub-subtitulo-edu-trabajo"
                              }
                            >
                              {e.NombreInstitucion}
                            </h3>
                          ) : null}
                          {e.Titulo_Certificacion ? (
                            <h3
                              className={
                                "perfil-prof-sub-subtitulo-edu-trabajo2"
                              }
                            >
                              {e.Titulo_Certificacion}
                            </h3>
                          ) : null}
                          {e.TipoEduacion ? (
                            <h3
                              className={
                                "perfil-prof-sub-subtitulo-edu-trabajo2"
                              }
                            >
                              {e.TipoEduacion}
                            </h3>
                          ) : null}
                          {e.fecha_finalizacion ? (
                            <h3
                              className={
                                "perfil-prof-sub-subtitulo-edu-trabajo2"
                              }
                            >
                              {e.fecha_finalizacion}
                            </h3>
                          ) : null}
                          {e.duracion ? (
                            <h3
                              className={
                                "perfil-prof-sub-subtitulo-edu-trabajo3"
                              }
                            >
                              {e.duracion}
                            </h3>
                          ) : null}
                          {i + 1 < cv?.experiencia?.length ? <hr /> : null}
                        </div>
                      );
                    })
                  ) : (
                    <p className={"perfil-prof-textos"}>
                      {lang === "es"
                        ? "Completar tu educación"
                        : "Complete your education"}
                    </p>
                  )}
                  <h2 className={"perfil-prof-sub-subtitulo"}>
                    {lang === "es" ? "Experiencia laboral" : "Work experience"}
                  </h2>
                  {cv?.experiencia &&
                    cv.experiencia.length > 0 &&
                    cv.experiencia[0].Nombreempresa !== "" ? (
                    cv?.experiencia?.map((e, i) => {
                      console.log(
                        cv?.experiencia &&
                        cv.experiencia.length > 0 &&
                        cv.experiencia[0].Nombreempresa !== ""
                      );
                      return (
                        <div key={i}>
                          <div className={"perfil-trab-container"}>
                            <div>
                              <img src={work} alt={"asd"} />
                            </div>
                            <div>
                              {e.Nombreempresa ? (
                                <h3
                                  className={
                                    "perfil-prof-sub-subtitulo-edu-trabajo"
                                  }
                                >
                                  {e.Nombreempresa}
                                </h3>
                              ) : null}
                              {e?.cargos?.length
                                ? e.cargos.map((c, i) => {
                                  return (
                                    <div key={i}>
                                      {c.nombrecargo ? (
                                        <h3
                                          className={
                                            "perfil-prof-sub-subtitulo-edu-trabajo2"
                                          }
                                        >
                                          {lang === "es"
                                            ? `Cargo ${i + 1}: `
                                            : `Charge ${i + 1}: `}{" "}
                                          {c.nombrecargo}
                                        </h3>
                                      ) : null}

                                      {c?.duracion ? (
                                        <h3
                                          className={
                                            "perfil-prof-sub-subtitulo-edu-trabajo3"
                                          }
                                        >
                                          {c?.duracion}
                                        </h3>
                                      ) : null}
                                      {/* <h3
                              className={'perfil-prof-sub-subtitulo-edu-trabajo2'}>{lang === 'es' ? `Descripción: ` : `Description: `} {c.descripcion ? c.descripcion : 'N/A'}</h3> */}
                                    </div>
                                  );
                                })
                                : null}
                              {/* <h3
                          className={'perfil-prof-sub-subtitulo-edu-trabajo3'}>{lang === 'es' ? 'Duración' : 'Duration'} {e.totalDuracion}</h3> */}
                            </div>
                          </div>
                          {i + 1 < cv?.experiencia?.length ? <hr /> : null}
                        </div>
                      );
                    })
                  ) : (
                    <p className={"perfil-prof-textos"}>
                      {lang === "es"
                        ? "Completar tu experiencia laboral"
                        : "Complete your work experience"}
                    </p>
                  )}
                </div>
              </div>
            </div>
            {/*footer perfil*/}
            <h2 className={"perfil-prof-subtitulo"}>Test</h2>
            <div className={"perfil-prof-footer"}>
              {lang === "es"
                ? tests.map((test, i) => {
                  return (
                    <TestCard
                      key={i}
                      id={test.id}
                      name={test.name}
                      description={test.description}
                      user={user}
                      setOpenTestPersonalidad={setOpenTestPersonalidad}
                      width={width}
                      real={test.real}
                    />
                  );
                })
                : testsENG.map((test, i) => {
                  return (
                    <TestCard
                      key={i}
                      id={test.id}
                      name={test.name}
                      description={test.description}
                      user={user}
                      setOpenTestPersonalidad={setOpenTestPersonalidad}
                      width={width}
                      real={test.real}
                    />
                  );
                })}
              <div>
                <Modal
                  open={isOpenTestPersonalidad}
                  onClose={handleClosePersonalidad}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Box sx={style1}>
                    <PopUpTest
                      handleOpenPersonalidadResultado={
                        handleOpenPersonalidadResultado
                      }
                      width={width}
                    />
                  </Box>
                </Modal>

                <Modal
                  open={openTestPersonalidadResultado}
                  onClose={handleCloseResultado}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Box sx={styles2}>
                    <PopUpResultado resultTest={resultadoTest} />
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-around",
                        marginTop: "40px",
                        paddingBottom: "40px",
                      }}
                    >
                      <button
                        style={{
                          backgroundColor: "transparent",
                          height: "28px",
                          width: "141px",
                          borderRadius: "5px",
                          borderStyle: "solid",
                          borderWidth: "1px",
                          fontSize: "14px",
                          fontWeight: "400",
                          color: "#202256",
                        }}
                      >
                        {lang === "es" ? "Más tests" : "More tests"}
                      </button>
                      <button
                        style={{
                          backgroundColor: "transparent",
                          height: "28px",
                          width: "141px",
                          borderRadius: "5px",
                          borderStyle: "solid",
                          borderWidth: "1px",
                          fontSize: "14px",
                          fontWeight: "400",
                          color: "#202256",
                        }}
                        onClick={mandarpersonalizad}
                      >
                        {lang === "es" ? "Guardar" : "Save"}
                      </button>
                    </div>
                  </Box>
                </Modal>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
