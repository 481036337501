import React, {useEffect, useState} from 'react';
import './adminNavbar.css'
import SideBarAdmin from "../adminSideBar/AdminSideBar";


export default function AdminNavBar({locate}) {

    const [show, setShow] = useState(false);
    const [sidebar, setSidebar] = useState(false);

    const showSideBar = (e) => {
        e.preventDefault();
        setShow(true);
        setSidebar(true);
    }
    const hideSideBar = (e) => {
        e.preventDefault();
        setShow(false);
        setSidebar(false);
    }

    useEffect(() => {
        function handleResize() {
            if (window.innerWidth > 1300) {
                setShow(false);
                setSidebar(false);
            }
        }

        window.addEventListener('resize', handleResize)
        return _ => {
            window.removeEventListener('resize', handleResize)
        }
    }, [])

    return (
        <div className={'navbar-global-container'}>
            {show === true ? <div className={'navbar-sidebar-container'}>
                <div>
                    <SideBarAdmin location={locate} style={sidebar}/>
                    <button onClick={(e) => hideSideBar(e)} className={'navbar-close-button'}>&times;</button>
                </div>
                <div className={'navbar-sidebar-container2'}></div>
            </div> : null}
            <div className={'navbar-container'}>
                <div onClick={(e) => showSideBar(e)} className={'navbar-container-sub-sub2'}>
                    <div className={'burguer-tile'}></div>
                    <div className={'burguer-tile'}></div>
                    <div className={'burguer-tile'}></div>
                </div>
            </div>
        </div>
    )
}
