/* eslint-disable */
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import Joyride from "react-joyride";
import { joyrideStep, tourStatus, tourViewedStatus } from "../../actions";
const joyrideStyles = {
  options: {
    zIndex: 10000000,
  },
};

export default function UseTour() {
  const dispatch = useDispatch()
  const { tour_status, steps, lang, user } = useSelector(state => state)
  const history = useHistory()
  const tourViewed = JSON.parse(localStorage.getItem('tourViewed'))

  const [width, setWidth] = useState(window.innerWidth);
  const [height, setHeight] = useState(window.innerHeight);

  const updateWidthAndHeight = () => {
    setWidth(window.innerWidth);
    setHeight(window.innerHeight);
  }
  useEffect(() => {
    window.addEventListener("resize", updateWidthAndHeight);
    return () => window.removeEventListener("resize", updateWidthAndHeight);
  }, [width, height]);

  useEffect(() => {
    if (tourViewed) {
      dispatch(tourStatus(false))
    }
    width > 768 ?
      dispatch(joyrideStep({
        steps: [
          {
            target: "#home",
            content: (
              <>
                <h2>
                  {lang === "es" ? "Bienvenido al tour de Coally!" : "Welcome to Coally's tour!"}
                </h2>
                <p>
                  {lang === "es" ? "En este tour te mostraremos las funcionalidades de la plataforma" : "In this tour we will show you the functionalities of the platform"}
                </p>
              </>
            ),
            disableBeacon: true,
          },
          {
            target: "#cv",
            content: (
              <>
                <h2>
                  {lang === "es" ? "Aqui podras subir tu hoja de vida" : "Here you can upload your resume"}
                </h2>
                <p>
                  {lang === "es" ? "Podras subir tu hoja de vida en formato linkedin pdf o manual" : "You can upload your resume in linkedin pdf or manual format"}
                </p>
              </>
            )
          },
          {
            target: "#projects",
            content: (
              <>
                <h2>
                  {lang === "es" ? "Aqui podras ver las oportunidades que te interesan" : "Here you can see the opportunities that interest you"}
                </h2>
              </>
            )
          },
          {
            target: "#applyProject",
            content: (
              <>
                <h2>
                  {lang === "es" ? "Aplicar a una oportunidad" : "Apply to an opportunity"}
                </h2>
                <p>
                  {lang === "es" ? "Aqui podrás visualizar toda la información de la oportunidad" : "Here you can see all the information about the opportunity"}
                </p>
              </>
            )
          },
          {
            target: "#applyButton",
            content: (
              <>
                <h2>
                  {lang === "es" ? "Si haces click en este botón podras aplicar" : "If you click on this button you can apply"}
                </h2>
                <p>
                  {lang === "es" ? "Para poder aplicar deberas subir tu hoja de vida" : "To apply you must upload your resume"}
                </p>
              </>
            )
          }
        ]
      }))
      :
      dispatch(joyrideStep({
        steps: [
          {
            target: "#home",
            content: (
              <>
                <h2>
                  {lang === "es" ? "Bienvenido al tour de Coally!" : "Welcome to Coally's tour!"}
                </h2>
                <p>
                  {lang === "es" ? "En este tour te mostraremos las funcionalidades de la plataforma" : "In this tour we will show you the functionalities of the platform"}
                </p>
              </>
            ),
            disableBeacon: true,
          },
          {
            target: "#cv",
            content: (
              <>
                <h2>
                  {lang === "es" ? "Aqui podras subir tu hoja de vida" : "Here you can upload your resume"}
                </h2>
                <p>
                  {lang === "es" ? "Podras subir tu hoja de vida en formato linkedin pdf o manual" : "You can upload your resume in linkedin pdf or manual format"}
                </p>
              </>
            )
          },
          {
            target: "#projects",
            content: (
              <>
                <h2>
                  {lang === "es" ? "Aqui podras ver las oportunidades que te interesan" : "Here you can see the opportunities that interest you"}
                </h2>
              </>
            )
          },
        ]
      }))

  }, []);

  const handleCallBack = (data) => {
    const { action, index, lifecycle, type, step } = data;
    if (tourViewed) {
      return;
    }
    if (step.target === '#home' && action === 'close') {
      dispatch(tourStatus({ run: false }));
      dispatch(tourViewedStatus(true));
      localStorage.setItem('tourViewed', true);
      history.push('/consultor/home');
    }
    if (step.target === '#cv') {
      if (action === 'close') {
        dispatch(tourStatus({ run: false }));
      }
      if (action === 'next' && index === 1) {
        dispatch(tourStatus({ run: false }));
        history.push(`/consultor/profile/${user.id}`);
      } else if (action === 'next' && index === 1 && lifecycle === 'complete') {
        dispatch(tourStatus({ run: true, stepIndex: 2 }));
      } else if (action === 'prev' && index === 1 && lifecycle === 'complete') {
        dispatch(tourStatus({ run: false, stepIndex: 0 }));
        history.push('/consultor/home');
      } else if (action === 'skip') {
        dispatch(tourStatus({ run: false }));
      }
    }

    if (step.target === '#projects') {
      if (action === 'close') {
        dispatch(tourStatus({ run: false }));
      }
      if (action === 'next' && index === 2 && lifecycle === 'init') {
        dispatch(tourStatus({ run: false }));
        history.push('/consultor/projects');
      } else if (action === 'next' && index === 2 && lifecycle === 'complete') {
        dispatch(tourStatus({ run: true, stepIndex: 3 }));
      } else if (action === 'prev' && index === 2 && lifecycle === 'complete') {
        dispatch(tourStatus({ run: false, stepIndex: 1 }));
        history.push(`/consultor/profile/${user.id}`);
      } else if (action === 'skip') {
        dispatch(tourStatus({ run: false }));
      }
    }
    if (step.target === '#applyProject') {
      if (action === 'close') {
        dispatch(tourStatus({ run: false }));
      }
      if (action === 'prev' && index === 3 && lifecycle === 'complete') {
        dispatch(tourStatus({ run: true, stepIndex: 2 }));
      } else if (action === 'next' && index === 3 && lifecycle === 'complete') {
        dispatch(tourStatus({ run: true, stepIndex: 4 }));
      }
    }
    if (step.target === '#applyButton') {
      if (action === 'prev' && index === 4 && lifecycle === 'complete') {
        dispatch(tourStatus({ run: true, stepIndex: 3 }));
      } else if (action === 'next' && index === 4 && lifecycle === 'complete') {
        dispatch(tourStatus({ run: false }));
        dispatch(tourViewedStatus(true));
        localStorage.setItem('tourViewed', true);
        history.push('/consultor/home');
      }
    }
  }

  return (
    <div style={{ width: '100%' }}>
      {tour_status.run ? <Joyride
        callback={handleCallBack}
        continuous={true}
        locale={{
          back: lang === "es" ? "Atras" : "Back",
          close: lang === "es" ? "Cerrar" : "Close",
          last: lang === "es" ? "Finalizar" : "Finish",
          next: lang === "es" ? "Siguiente" : "Next",
          skip: lang === "es" ? "Saltar" : "Skip",
        }}
        run={tour_status.run}
        stepIndex={tour_status.stepIndex}
        showProgress={true}
        showSkipButton={true}
        steps={steps.steps}
        //change button color
        styles={{
          options: {
            zIndex: 10000000,
            primaryColor: "#F07130",
          }
        }}
      /> : null}
    </div>
  )
}
