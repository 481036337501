import React from 'react';
import "./CardOportunidadProfesional.css";
import eye from '../../../assets/icons/eye.svg';
import {Link} from "react-router-dom";

const CardOportunidadProfesional = ({imagen, lenguaje, id, dir, salario, titulo, titulo2, empresa, descripcion, Anonimo}) => {

  const formatterPeso = new Intl.NumberFormat('es-CO', {
    style: 'currency',
    currency: 'COP',
    minimumFractionDigits: 0
  })
  return (
    <div className={'CardOportunidadProfesional-Container'}>
      <div>
        <img className={'CardOportunidadProfesional-Image'} src={imagen} alt={imagen}/>
      </div>
      <div className={'CardOportunidadProfesional-Sub-Container'}>
        <div className={'CardOportunidadProfesional-Data-Container'}>
          <h2
            className={'CardOportunidadProfesional-Titulo'}>{titulo !== 'Unnamed Oportunity' ? titulo : titulo2 }{Anonimo === "No" ? empresa ? ` - ${empresa}` : null : null}</h2>
          <div>
            <div className={'CardOportunidadProfesional-Descripcion-Container'}>
              <p className={'CardOportunidadProfesional-Descripcion'}>{lenguaje === 'es' ? 'Profesionales con conocimiento en: ' : 'Professionals with knowledge in: '}</p>
              <p className={'CardOportunidadProfesional-Descripcion'}>{!descripcion || descripcion === 'NA' || descripcion === 'Otro' ? lenguaje === 'es' ? 'No especifica' : 'Not specified' : descripcion}</p>
            </div>
            {
              !salario.length ? lenguaje === 'es' ?
                <p className={'CardOportunidadProfesional-Salario-Null'}>Salario no especificado</p> :
                <p className={'CardOportunidadProfesional-Salario-Null'}>'Salary not
                  specified</p> : typeof salario === 'number' ?
                <p className={'CardOportunidadProfesional-Salario'}>`${formatterPeso.format(salario)}`</p> :
                <p className={'CardOportunidadProfesional-Salario'}>${salario}</p>
            }
          </div>
        </div>
        <div className={'CardOportunidadProfesional-Button-Container'}>
          <Link to={`/consultor/project/${id}`} className={'CardOportunidadProfesional-Button-2'}>
            <div >
            {lenguaje === 'es' ? 'Leer más' : 'Read more'}
            <img src={eye} alt={eye} style={{marginLeft: "20px"}}/>
            </div>
          </Link>
          {
            !dir?.length && (
              <Link to={`/consultor/project/${id}`}>
                <button
                  className={'CardOportunidadProfesional-Button'}>
                  {lenguaje === 'es' ? 'Aplicar' : 'Apply'}
                </button>
              </Link>
            )
          }
          {
            dir?.length > 0 &&
            dir?.includes("https://") ? (
              <a href={`${dir}`} rel="noopener noreferrer" target={"_blank"}>
                <button className={'CardOportunidadProfesional-Button'}>
                  {lenguaje === 'es' ? 'Aplicar' : 'Apply'}
                </button>
              </a>
            ) : null
          }
          {
            dir?.length > 0 &&
            !dir?.includes("https://") ? (
              <a href={`https://${dir}`} rel="noopener noreferrer" target={"_blank"}>
                <button className={'CardOportunidadProfesional-Button'}>
                  {lenguaje === 'es' ? 'Aplicar' : 'Apply'}
                </button>
              </a>
            ) : null
          }
        </div>
      </div>
    </div>
  )
}

export default CardOportunidadProfesional;