/*eslint-disable*/
import image1 from "../../../assets/imagenes/unilever-card-1.png";
import image2 from "../../../assets/imagenes/unilever-card-2.png";
import image3 from "../../../assets/imagenes/unilever-card-3.png";
import image4 from "../../../assets/imagenes/unilever-card-4.png";

import mentorToolkit from "../files/mentor-toolkit-img.png";
import mentorEquipaje from "../files/mentor-equipaje.png";

import mapaSuenos from "../files/mapa-sueños.png";
import tallerSuenos from "../files/taller-sueños.png";
import liderazgo from "../files/liderazgo.png";

export const CardDataEspMentores = [
  {
    id: 1,
    title: "1er Café de Mentores - Métele Pedal a Tu futuro",
    isVideo: true,
    link: "https://www.youtube.com/watch?v=PDCBx_UzBW4",
  },
  {
    id: 2,
    title: "2do Café de Mentores - Métele Pedal a Tu futuro",
    isVideo: true,
    link: "https://www.youtube.com/watch?v=8s8ZYVLhKMk",
  },
  {
    id: 3,
    title: "Toolkit de mentoría",
    description:
      "Conoce las herramientas que te ayudarán a acompañar a los jóvenes en su proceso de desarrollo.",
    image: mentorToolkit,
  },
  {
    id: 4,
    title: "Tu equipaje como mentor",
    description:
      "Conoce las herramientas que te ayudarán a acompañar a los jóvenes en su proceso de desarrollo.",
    image: mentorEquipaje,
  },
  {
    id: 5,
    title: "Capacitación Programa Mentores Métele Pedal a tu Futuro",
    isVideo: true,
    link: "https://www.youtube.com/watch?v=2iNcwy5b1B4",
  },
  {
    id: 56,
    title: "Guía complementaria en video",
    isVideo: true,
    link: "https://www.youtube.com/watch?v=eEwweexb4-c",
  },
];

export const CardDataEng = [
  {
    id: "643ffecaeb81f8b832f9a372",
    title: "Clase: El poder e Impacto de Contar Buenas Historias- Storytelling",
    isVideo: true,
    link: "https://www.youtube.com/watch?v=EwiU9cbSBLQ",
  },
  {
    id: "643ff294eb81f8b832f9a36d",
    title: "MasterClass Estrategias clave para la búsqueda de empleo II",
    isVideo: true,
    link: "https://www.youtube.com/watch?v=RAaZDGgap0g",
  },
  {
    id: "643ff2a3eb81f8b832f9a36e",
    title: "Cómo impactar entendiendo los retos de la diversidad",
    isVideo: true,
    link: "https://www.youtube.com/watch?v=GpRD4cvS1t8",
  },
  {
    id: "643ff2aeeb81f8b832f9a36f",
    title: "MasterClass Emprendimiento como opción de vida",
    isVideo: true,
    link: "https://www.youtube.com/watch?v=38trgPw4XPA",
  },
  {
    id: "643ff2baeb81f8b832f9a370",
    title: "¿Cómo ser innovador en un mundo competitivo?",
    isVideo: true,
    link: "https://www.youtube.com/watch?v=3ZjWiIJbIlg",
  },
  {
    id: 5,
    title: "Mapa de los sueños",
    description: "Reconoce la persona mas importante de tu vida. Tú",
    image: mapaSuenos,
  },
  {
    id: 6,
    title: "Taller de los sueños",
    description: "Reconoce la persona mas importante de tu vida. Tú",
    image: tallerSuenos,
  },
  {
    id: "643ff30feb81f8b832f9a371",
    title: "MasterClass Estrategias clave para la búsqueda de empleo",
    isVideo: true,
    link: "https://www.youtube.com/watch?v=R-eKq0nEjVE",
  },
  {
    id: 8,
    title: "Descubriendo tu liderazgo",
    description:
      "Descubre tu Propósito reflexionando sobre quién eres, de dónde \n" +
      "vienes y hacia dónde vas.",
    image: liderazgo,
  },
];
