export function resizeImage(image, maxWidth, maxHeight) {
  return new Promise((resolve, reject) => {
    if (!image || !image.width || !image.height) {
      reject(new Error('Invalid image object'));
      return;
    }

    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');

    let width = image.width;
    let height = image.height;

    if (width > maxWidth) {
      height *= maxWidth / width;
      width = maxWidth;
    }

    if (height > maxHeight) {
      width *= maxHeight / height;
      height = maxHeight;
    }

    canvas.width = width;
    canvas.height = height;

    ctx.drawImage(image, 0, 0, width, height);

    canvas.toBlob(blob => {
      resolve(blob);
    }, 'image/jpeg', 0.9);
  });
}
