import React, { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import { useDispatch } from 'react-redux';
import { getUserInfo } from '../../../actions';
import axios from 'axios';
import { toast } from "react-toastify";
import { Dialog } from '@mui/material';
import { DialogTitle } from '@material-ui/core';
import DialogContent from "@mui/material/DialogContent";
import Badge from '@mui/material/Badge';
import Avatar from "@mui/material/Avatar";
import { Link, useParams } from 'react-router-dom'
import edit from '../../../assets/icons/edit.svg'
import phone from '../../../assets/icons/phone.svg'
import mail from '../../../assets/icons/mail.svg'
import camara from '../../../assets/icons/camera.svg'
import './cards.css'
import CircularFrame from '../../../components/CircularFrame/CircularFrame';
import PopoverInfo from '../popoverinfo/PopoverInfo'


export default function PerfilCard({ user, cv, lang }) {
  console.log(cv)
  const { id } = useParams()
  const dispatch = useDispatch()
  //eslint-disable-next-line
  const [contacto, setContacto] = useState(false)
  const [progress, setProgress] = useState(0)
  const [changeAvatar, setChangeAvatar] = useState(false)
  const [avatar, setAvatar] = useState(null)
  const [preview, setPreview] = useState(null)


  const SmallAvatar = styled(Avatar)(({ theme }) => ({
    width: 35,
    height: 35,
    backgroundColor: "#bdbdbd",
    cursor: "pointer",
  }));


  const handleFile = (e) => {
    setAvatar(e.target.files[0]);
  }

  const handleAvatarClose = () => {
    setChangeAvatar(false);
    setAvatar(null)
  };

  const handleAvatarSubmit = () => {
    const formData = new FormData();
    formData.append("upload_avatar", avatar);
    axios.post(`https://findally-backend.herokuapp.com/api/user/uploadAvatar/${user.id}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }).then(res => {
      setChangeAvatar(false);
      dispatch(getUserInfo(user.id))
    }).catch(err => {
      toast.error('error cargando el avatar', {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
    })
  }

  useEffect(() => {
    let counter = 0;
    if (cv?.aptitudes_principales && cv?.aptitudes_principales?.length > 0) counter += 12.5
    if (cv?.certifications && cv?.certifications?.length > 0) counter += 12.5
    if (cv?.contacto && (cv?.contacto?.correo?.length > 0 || user?.email?.length !== 0)) counter += 12.5
    if (cv?.educacion && cv?.educacion?.length > 0 && cv?.educacion[0]?.NombreInstitucion !== "") counter += 12.5
    if (cv?.experiencia && cv?.experiencia?.length > 0 && cv?.experiencia[0]?.Nombreempresa !== "") counter += 12.5
    if (cv?.extracto && cv?.extracto !== "") counter += 12.5
    if (cv?.languages && cv?.languages?.length > 0) counter += 12.5
    if (user?.avatar && user?.avatar !== "") counter += 12.5
    setProgress(counter);
    //eslint-disable-next-line
  }, [cv, cv.contacto])

  useEffect(() => {
    if (cv.contacto) setContacto(true)
  }, [cv.contacto])


  useEffect(() => {
    if (!avatar) {
      setPreview(undefined)
      return
    }

    const objectUrl = URL.createObjectURL(avatar)
    setPreview(objectUrl)

    // free memory when ever this component is unmounted
    return () => URL.revokeObjectURL(objectUrl)
  }, [avatar])

  return (
    <div className='Perfil-container-cards-coally'>

      <div className='porcentaje-editar-container'>
        <CircularFrame userInfo={user} cv={cv} />
        {/* <div className='editar-foto-perfil'>
              <button onClick={()=>handleAvatarSubmit()} >
                <img src={camera} alt={'camera'}/>
              </button>
            </div> */}
        <div className='Avatar-camara-perfil'>
          <Badge
            overlap="circular"
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            badgeContent={
              <SmallAvatar
                sx={{ width: "30px", height: "30px", background: '#ec7332' }}
                alt="Remy Sharp" src={camara} onClick={() => setChangeAvatar(true)} />
            }
            style={{ zIndex: 0 }}
          >
          </Badge>
        </div>
        <div className='nosequees'>
          <Dialog
            open={changeAvatar}
            onClose={handleAvatarClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            fullWidth={true}
            maxWidth="sm"
          /* sx={ {height: "50%", maxHeight: '50%'} } */
          >
            <DialogTitle id="alert-dialog-title">
              {
                lang === "es" ? "Cambiar Avatar" : "Change Avatar"
              }
            </DialogTitle>
            <DialogContent sx={{ height: "300px" }}>
              {/* make 2 inputs  */}
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  alignItems: "center",
                  height: "100%",
                }}
              >
                <div style={{ width: '200px', height: '200px', display: 'flex', alignItems: 'center' }}>
                  <img src={!preview ? camara : preview} alt='profile-avatar-new'
                    style={{ width: '100%', height: '100%', display: !preview ? 'hidden' : null }} />
                </div>
                <div>
                  <input
                    style={{
                      width: "100%",
                    }}
                    onChange={(e) => handleFile(e)}
                    type="file" />
                </div>
                <div>
                  <button
                    onClick={() => handleAvatarSubmit()}
                    style={{
                      backgroundColor: "#202256",
                      color: "white",
                      borderRadius: "5px",
                      fontFamily: "Poppins",
                      height: "2rem",
                      width: "15.62rem",
                      marginTop: "20px",
                      marginBottom: "5px",
                      textAlign: "center",
                      fontSize: "14px",
                    }}
                  >
                    Enviar
                  </button>
                </div>
              </div>
            </DialogContent>
          </Dialog>
        </div>
        <div className='porcentaje-complete'>
          <p className='porcentaje-p'>{progress}%</p>
          <PopoverInfo user={user} cv={cv} />
        </div>
        {cv ? <Link to={`/consultor/cv/${id}`} className='link-edit-image'>{lang === 'es' ? 'Editar perfil' : 'Edit profile'}
          <img src={edit} alt={'edit'} /></Link> : <Link to={`/consultor/cv`} className='link-edit-image'>{lang === 'es' ? 'Editar perfil' : 'Edit profile'}
          <img src={edit} alt={'edit'} /></Link>}
        <hr className='herre' />
      </div>
      <div className='contact-data'>
        <p><img src={phone} alt='phone' className='icon-cards' />{cv?.contacto?.telefono ? cv?.contacto?.telefono : user?.phoneNumber ? user.phoneNumber : lang === 'es' ? 'no especifica' : 'does not specify'}</p>
        <p><img src={mail} alt='mail' className='icon-cards' />{cv?.contacto?.correo ? cv?.contacto?.correo : user?.email ? user?.email : lang === 'es' ? 'no especifica' : 'does not specify'}</p>
        <p>{cv?.contacto?.linkedin ? cv?.contacto?.linkedin?.split(' ')?.[0]?.slice(4) : null}</p>
      </div>
    </div>
  )
}
