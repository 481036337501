import React, {useEffect, useState} from "react";
import "./styles/kps.css";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { Img } from 'react-image'
import { connect, useSelector } from "react-redux";
import {
  Methodology,
  getProject,
  getPhase,
  ConsultorData1,
  ConsultorData,
  getNewEtapas,
  getEtapas,
  savePdf,
  firmakps,
  getSinglePhase,
  getCertificados,
  getProjectPhases,
  setEtapasNull,
} from "../../actions/index";
import en from "../../lang/en-US";
import es from "../../lang/es-COL"
import counterpart from "counterpart";
import Translate from "react-translate-component";
import { useParams } from "react-router";
import axios from 'axios'

function KpsGenerator(props) {
  // const {
  //   match: { params },
  // } = props;
  const {id}=useParams()
  const getMethodology = () => {
    if (props.projectDetailsDone) {
      props.getProject(id);
    }
  };
  const [status, setStatusValue] = useState("show");
  
  const handdleStatusChange =(value)=>{
    if (value ==="show"){
      setStatusValue("done")
    }
    if(value==="done"){
      setStatusValue("show")
    }
  }
  const getlegal = () => {
    if (props.actual) {
      props.getCertificados(props.actual.legal[0]);
    }
  };
  const proyectoactual=useSelector((state) => state.ActualProject)
  const [documentos, setdocumentos] = useState('')
    const info=()=>{
      axios.get(`https://findally-backend.herokuapp.com/api/legal/${proyectoactual.legal[0]}`).then(response=>setdocumentos(response.data))
    }
  useEffect(() => {
    getMethodology();
    if(proyectoactual===""){
      window.history.back()
    }
    getlegal(); // eslint-disable-next-line
  }, []);

  if(proyectoactual.legal && documentos===''){
    console.log('h')
        info()
      }
let ref = React.createRef();

const fecha = new Date();

const año = fecha.getFullYear();
const mes = fecha.getMonth() + 1;
const dia = fecha.getDay()



const printDocument = () => {
  const input = ref.current;
  html2canvas(input, {
    useCORS: true
  }).then((canvas) => {
    const imgData = canvas.toDataURL("image/png");
    const pdf = new jsPDF("p", "mm", "a3");
    const width = pdf.internal.pageSize.getWidth();
    console.log(width);
    const height = pdf.internal.pageSize.getHeight();
    console.log(height);
    pdf.addImage(imgData, "JPEG", 0, 0, width, height);
    pdf.save("kps_coally.pdf");
    const payload = {
      doc: imgData,
      proyecto: props.actual._id,
      usuario: props.user.id,
    };
    props.firmakps(payload);
    console.log(pdf)
  });
  };

  counterpart.registerTranslations('en', en)
  counterpart.registerTranslations('es', es)
  counterpart.setLocale(localStorage.getItem('langlocal'));
  console.log('sadasd')
  console.log(documentos)
  console.log('sadasd')
  return (
    <>
      {/* <Header /> */}
      {documentos ? (
        <div>
          

          <div
            ref={ref}
            style={{
              borderRadius: "5px",/* 
              width: "800px", */
              marginLeft: "15%",
              marginRight: "15%",/* 
              height: "2210px", */
              paddingTop: "8mm",
               paddingLeft: "10mm",
               paddingRight: "10mm",
              paddingBottom: "5mm",
            }}
          >
            <div className="kps">
              <div className="kps-location-date">
                <p>BOGOTÁ D.C.</p>
                <p>{`${dia}-${mes}-${año}`  }</p>
              </div>
              <div className="tittle-kps">
                <h3 className="kps-tittle-sec">
                  {" "}
                  {<Translate content="CONTRATODEPRESTACIÓNDESERVICIOSSUSCRITOENTRE" component="span" />}{" "}
                </h3>{" "}
                <h3 className="kps-company"> {documentos.company_user.username || "--"} </h3>{" "}
                <p className="kps-company"> {<Translate content="y" component="span" />}</p>{" "}
                <h3 className="kps-company"> {props.userform.nombre || "--"} </h3>{" "}
              </div>
              <div className="kps-description">
                <article className="kps-company">
                  {<Translate content="Porunaparte" component="span" />}<strong className="kps-company"> {" "} {documentos.company_user.username || "--"} {" "}</strong>{" "}
                  {<Translate content="sociedadidentificadaconNITn" component="span" />}{" "}<strong className="kps-company"> {props.company.nit || "--"} </strong>{" "}
                  {<Translate content="representadalegalmentepor" component="span" />}{" "} <strong>{props.company.rep_legal || "--"}</strong> {" "}
                  {<Translate content="mayordeedad" component="span" />}{" "}<strong>{props.company.cedula_rep || "--"}</strong>{" "}
                  {<Translate content="expedidaenlaciudadde" component="span" />}{" "} <strong>{props.company.ciudad_expedicion_rep || "--"}</strong>{" "}
                  {<Translate content="quienparaefectosde" component="span" />}{" "}
                  <p className="kps-company"> {" "}{props.userform.nombre || "--"} </p>{" "}
                  {<Translate content="personamayordeedad" component="span" />}{" "} {props.userform.cedula || "--"}{" "}
                  {<Translate content="expedidaenlaciudadde" component="span" />}{" "} {props.userform.cedula_exp || "-- "}{" "}
                  
                  {<Translate content="quienenadelantesedenominaráELCONTRATISTAsuscribenun" component="span" />}
                </article>
              </div>
              <div className="kps-personal-data">
                <article>
                  {<Translate content="Elcontratistaconformasusconocimientosen" component="span" />}<p className="kps-company"> {" "}{documentos.project.linea_negocio || "--"}{" "} </p> 
                  {<Translate content="prestarálosserviciosde" component="span" />}<br></br><br></br>
                  <strong>{<Translate content="SEGUNDAA" component="span" />}</strong>
                  {<Translate content="PLAZOElplazoparalaejecucióndelpresente" component="span" />}{" "}<p className="kps-company"> {""|| " cuatro semanas"} </p> {" "}
                  {<Translate content="semanascomprendidoentrelafecha" component="span" />}<p className="kps-company"> {" "}{documentos.project.fecha_inicio || "--"} {" "} y{" "} {documentos.project.fecha_fin || "--"}</p>{" "}
                  {<Translate content="Parágrafo1Lodispuestoenlapresentecláusulapodráestar" component="span" />}<br></br><br></br>
                  <strong>{<Translate content="TERCERAA" component="span" />}</strong>
                  {<Translate content="VALORElvalordelpresentecontratoseráporla" component="span" />}{" "}<p className="kps-company"> {documentos.project.precio || ""} </p>{" "}
                </article>
              </div>
              <div className="kps-general-content">
                <article>
                  <strong>{<Translate content="CUARTAA" component="span" />}</strong>
                  {<Translate content="FORMADEPAGOElvalorpactadoenlacláusula" component="span" />}<br></br><br></br>
                  <strong>{<Translate content="QUINTAA" component="span" />}</strong>
                  {<Translate content="OBLIGACIONESDELCONTRATANTEÉsteseobligaráa" component="span" />}
                  <br></br><br></br>
                  <strong>{<Translate content="SEXTAA" component="span" />}</strong>
                  {<Translate content="OBLIGACIONESDELCONTRATISTAElContratistadeberá" component="span" />}
                  <br></br><br></br>
                  <strong>{<Translate content="OCTAVAA" component="span" />}</strong>
                  {<Translate content="DURACIÓNDELSERVICIOEstecontratoesporeltiempo" component="span" />}
                  <br></br><br></br>
                  <strong>{<Translate content="CLÁUSULANOVENA" component="span" />}</strong>
                  {<Translate content="TERMINACIÓNDELCONTRATOElpresente" component="span" />}
                  <br></br><br></br>
                  <strong>{<Translate content="CLÁUSULADÉCIMA" component="span" />}</strong>
                  {<Translate content="INDEPENDENCIADELASPARTESEndesarrollo" component="span" />}
                  <br></br><br></br>
                  {<Translate content="Laspartescumpliránlasobligacionesobjetodeestecontrato" component="span" />}<strong>{<Translate content="CLÁUSULADÉCIMASEGUNDA" component="span" />}</strong>.
                  {<Translate content="CESIÓNDELCONTRATONingunadelaspartespodrácederparcial" component="span" />}
                  <br></br><br></br>
                  <strong>{<Translate content="CLÁUSULADÉCIMACUARTA" component="span" />}</strong>
                  {<Translate content="MODIFICACIÓNOPRÓRROGACualquier" component="span" />}
                  <br></br><br></br>
                  <strong>{<Translate content="CLÁUSULADÉCIMAQUINTA" component="span" />}</strong>{<Translate content="CONFIDENCIALIDADElpresente" component="span" />}
                  <br></br><br></br>
                  {<Translate content="LasPartessussociosempleadosagentesyocontratados" component="span" />}
                  <br></br><br></br>
                  {<Translate content="Adicionalmentelaobligacióndeconfidencialidadaquí" component="span" />}
                  <br></br><br></br>
                  {<Translate content="aAquellainformaciónqueresulteaccesiblealpúblicopor" component="span" />}
                  <br></br><br></br>
                  {<Translate content="bAquellainformaciónqueestélegítimamenteenpoderdela" component="span" />}
                  <br></br><br></br>
                  {<Translate content="cAquellainformaciónquehayasidoobtenidalegítimamentede" component="span" />}<br></br><br></br>
                  {<Translate content="dAquellainformaciónquedebasercomunicadaporordendeun" component="span" />}
                  <br></br><br></br>
                  {<Translate content="Estasobligacionesdeconfidencialidadsobreviviránla" component="span" />}
                  <br></br><br></br>
                  <strong>{<Translate content="CLÁUSULADÉCIMOSEXTA" component="span" />}</strong>
                  {<Translate content="NATURALEZAElpresenteContratoesde" component="span" />}
                  <br></br><br></br>
                </article>
                <div>
                  {
                    status === "done" ?
                    <Img  style={{ width: '300px', height:'100px'}}   src={props.user.signature} crossorigin="anonymous" /> 
                    : 
                    <button className="button-comments" style={{backgroundColor:'#d0d0d0',borderRadius:'7px',color:'white',padding:'6px',border:'solid 1px #d0d0d0'}} onClick={()=>handdleStatusChange(status)}>{<Translate content="Firmaar" component="span" />}</button>

                  }
                  </div>
              <br></br>
                <br></br>
                <br></br>
                <br></br>
              </div>
            </div>
          </div>
          <div className="div-button-pdf-gen">
            <button className="doc-button-pdf" onClick={printDocument}>
            {<Translate content="DESCARGARDOCUMENTOO" component="span" />}
            </button>
          </div>
        </div>
      ) : (
        <div>{<Translate content="cargando" component="span" />}</div>
      )}
    </>
  );
}

function mapStateToProps(state) {
    return {
      user : state.user,
      actual: state.ActualProject,
      methodology: state.methodology,
      Phases: state.Phases,
      company:state.company_form,
      userform: state.userform,
      documentos: state.documentos,
      Etapas: state.Etapas,
      etapasDone: state.etapasDone,
      projectDetailsDone: state.projectDetailsDone,
    };
  }
  const mapDispatchToProps = {
    Methodology,
    getProject,
    getPhase,
    savePdf,
    getEtapas,
    ConsultorData,
    firmakps,
    ConsultorData1,
    getCertificados,
    getNewEtapas,
    getSinglePhase,
    getProjectPhases,
    setEtapasNull,
  };
  export default connect(
    mapStateToProps,
    mapDispatchToProps
  )(KpsGenerator);
