/* eslint-disable */
import React from 'react';
import logo_coally from '../../assets/logos/logo_coally_plf.jpeg';
// import {Link} from "react-router-dom";

export default function EducationCard(props) {
  return (
    <div style={{
      backgroundColor: '#FFFF',
      width: "100%",
      height: "236px",
      borderRadius: "8px",
      marginLeft: "10px",
      marginRight: "10px",
      overflow: "hidden",
      boxShadow: '0px 7px 32px rgba(0, 0, 0, 0.1)',
      marginBottom: '15%'
    }}>
      <div/>
      {!props.video.includes('https') ? <iframe
          width="100%"
          height="150px"
          src={`https://www.youtube.com/embed/${props.video}`}
          object-fit="cover"
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe> :
        <img style={{height: "150px", objectFit: 'scale-down', width: '100%'}} src={'https://coally.com/wp-content/uploads/2022/11/formacion-coally-img.png'} alt={props.video}/>
      }
      <div style={{
        marginTop: '10px',
        marginLeft: '15px',
        marginRight: '15px',
        marginBottom: '10%'
      }}>
        {
          !props.video.includes('https') ?
            <h1 style={{
              color: "black",
              fontFamily: "Poppins",
              fontSize: "14px",
              fontWeight: "400",
              lineHeight: "20px",
              paddingBottom: props.name.length < 32 ? '17px' : '0px',
            }}
            >{props.name.length < 40 ? props.name : props.name.slice(0, 30) + '...'}</h1> :
            <a href={props.video} target={'_blank'}>
              <h1 style={{
                color: "black",
                fontFamily: "Poppins",
                fontSize: "14px",
                fontWeight: "400",
                lineHeight: "20px",
                paddingBottom: props.name.length < 32 ? '17px' : '0px',
              }}
              >{props.name.length < 40 ? props.name : props.name.slice(0, 30) + '...'}</h1>
            </a>
        }
      </div>
    </div>
  )
}