import React, {useState, useEffect} from 'react';
// import {changeCourseAdm} from "./DataMasterclass"
import Spinner from "react-bootstrap/Spinner";
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import './MasterclassCards.css'
import ReactPlayer from "react-player";


const WelcomeCoursesCard = ({title, setCourse, course, image}) => {
  //eslint-disable-next-line
  const [loading, setLoading] = useState(null);
  const [open, setOpen] = useState(false);
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);

  const updateWidthAndHeight = () => {
    setWidth(window.innerWidth);
    setHeight(window.innerHeight);
  }

  useEffect(() => {
    window.addEventListener("resize", updateWidthAndHeight);
    return () => window.removeEventListener("resize", updateWidthAndHeight);
  }, [width, height]);

  return (
    <div
      onClick={() => setOpen(true)}
      className={'masterclass-card-container'} style={{
      backgroundImage: `url(${image})`,
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat'
    }}>
      <div className={'masterclass-card-title-container'}
        //      onClick={() => {
        //   changeCourseAdm(title, setCourse, setLoading, setOpen, image)
        // }
      >
        <h1 style={{
          margin: '0',
          padding: '10px'
        }} className={'card-masterclass-title'}>
          {loading ? <Spinner animation="border" variant="light"/> : title.charAt(0).toUpperCase() + title.slice(1)}
        </h1>
      </div>
      {course && !loading &&
        <Dialog open={open} onClose={() => setOpen(false)}>
          <DialogTitle>{title.charAt(0).toUpperCase() + title.slice(1)}</DialogTitle>
          <DialogContent>
            <ReactPlayer
              controls={true}
              url={course}
              width={width > 580 ? '500px' : width < 410 ? '350px' : '350px'}
              height={width > 580 ? '500px' : width < 410 ? '250px' : '350px'}
            />
          </DialogContent>
        </Dialog>}
    </div>
  )

}

export default WelcomeCoursesCard
