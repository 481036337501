import React from 'react';
import { BarChart, XAxis, YAxis, ResponsiveContainer, CartesianGrid, Tooltip, Legend, Bar, Cell } from 'recharts';
import Title from './Title';



export default function Chart({Wait_projects, Development_projects, Finished_projects}) {
  const data = [{name: "En espera", proyectos: Wait_projects, color: "#017fb1"},
                {name: "Finalizados", proyectos: Finished_projects, color: "#01678e" },
                {name: "En desarrollo", proyectos: Development_projects, color: "#015677"}]
  return (
    <React.Fragment>
      <Title>PROYECTOS</Title>
      <ResponsiveContainer>
      <BarChart width={730} height={250} data={data} >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip />
        <Legend />
       <Bar dataKey="proyectos" >
       {data.map((entry, index) => (
              <Cell fill={data[index].color} />
            ))}
      </Bar>
      </BarChart>
      </ResponsiveContainer>
    </React.Fragment>
  );
}