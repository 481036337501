import React, { useState } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { loginUser, loginReq, loginSocial } from "../actions";
import axios from "axios";
import FacebookLogin from "react-facebook-login";
import linkedin from "../assets/logos/linkedin.png";
import google from "../assets/logos/logo google.png";
import en from "../lang/en-US";
import es from "../lang/es-COL"
import counterpart from "counterpart";
import "./styles/LoginEmpresa.css";

function Login(props) {
  //setting th e state
  const [form, setValues] = useState({
    role:[],
    email: "",
    password: "",
  });

  if(!localStorage.getItem('langlocal')){
    localStorage.setItem('langlocal','es')
  }
  //form handles
  const handleInput = (event) => {
    setValues({
      ...form,
      [event.target.name]: event.target.value,
    });
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    props.loginUser(form, "/company/profile");
  };

  const mediadata = (data) => {
    if (data) props.loginSocial(data, "/consultor/home");
  };
  const responseFacebook = (response) => {
    const data = {
      name: response.name,
      email: response.email,
      id: response.userID,
      apiKeyToken:
        "45e9b46a21c4b55e0ccf1f024a55d5ddf57f983a21091e3327b8616ed2582c62",
    };
    if (data)
      axios
        .post("http://localhost:3000/provider", data)
        .then(({ data }) => mediadata(data.user))
        .catch((err) => err);
  };

  const media = () => {
    axios
      .get("http://localhost:3000/auth/google-oauth")
      .then((res) => console.log(res));
  };
  counterpart.registerTranslations('en',en) 
  counterpart.registerTranslations('es',es)  
  counterpart.setLocale(localStorage.getItem('langlocal'));
  const CorreoElectrónico =counterpart.translate('CorreoElectrónico')
  const Contraseña =counterpart.translate('Contraseña')

  return (
    <>
      <section className="login-emp">
        <section className="login__container">
        <div className="select-user">
          <Link to="/auth/company/login">empresa</Link>
          <Link to="">consultor</Link>
        </div>
          <p
            className=".login__container-p"
            style={{
              fontSize: "32px",
              fontWeight: "700",
              marginBottom: "15px",
            }}
          >
            Iniciar Sesión
          </p>      
          <form className="login__container-form" onSubmit={handleSubmit}>
            <input
              className="input"
              type="email"
              placeholder={CorreoElectrónico}
              name="email"
              onChange={handleInput}
            />
            <input
              className="input"
              type="password"
              placeholder={Contraseña}
              name="password"
              onChange={handleInput}
            />
            <div className="form__validate">
              <Link className="login__form-olvidado" to="/">
                ¿Has olvidado tu contraseña?
              </Link>
            </div>
            <button
              type="submit"
              to="Home"
              className="login__form__button"
              name="Registrate"
            >
              Entrar
            </button>
            <div className="login__form-social">
              <FacebookLogin
                appId="317035492712396"
                icon="fa-facebook"
                textButton="facebook"
                autoLoad={false}
                fields="name,email,picture"
                onClick={"hi"}
                callback={responseFacebook}
                render={(renderProps) => (
                  <button className="media" onClick={renderProps.onClick}>
                    Facebook
                  </button>
                )}
              />
              <Link className="login__form-social-btn-lin" to="#">
                <img
                  className="login__form-social-img"
                  src={linkedin}
                  alt="linkedin-login"
                  height="17px"
                />
                <p className="login__form-social-txt">Ingresa con Linkedin</p>
              </Link>
              <Link
                onClick={media}
                className="login__form-social-btn-go"
                to="#"
              >
                <img
                  className="login__form-social-img"
                  src={google}
                  alt="google-login"
                  height="17px"
                />
                <p className="login__form-social-txt">Ingresa con Google</p>
              </Link>
            </div>
            <Link to="/company/profile" style={{width :100 }}>
                    PASAR
                  </Link>
          </form>
          <div className="form__notregister">
            <p className="form__notregister-text">
              ¿Aún no tienes una cuenta?{" "}
              <Link className="form__noregister-text-link" to="/auth/company/register">
                Registrate
              </Link>
            </p>
                 
          </div>
        </section>
      </section>
    </>
  );
}
const mapDispatchToProps = {
  loginUser,
  loginReq,
  loginSocial,
};

export default connect(null, mapDispatchToProps)(Login);
