import React, { Fragment, useState } from "react";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button";
import en from "../../lang/en-US";
import es from "../../lang/es-COL"
import counterpart from "counterpart";
import Translate from "react-translate-component";

// Destructuring props
const FirstStep = ({
  handleNext,
  handleChange,
  values: { facebook, instagram, linkedin, email, descripcion, tiempo_mercado, nit, sector },
  formErrors
}) => {
  // Check if all values are not empty or if there are some error
  const isValid =
    email.length > 0 &&
    !formErrors.email &&
    descripcion.length > 0 &&
    !formErrors.descripcion &&
    tiempo_mercado.length > 0 &&
    !formErrors.tiempo_mercado &&
    nit.length > 0 &&
    !formErrors.nit &&
    sector.length > 0 &&
    !formErrors.sector;

    const [otroCausa, setotroCausa] = useState("")
    //const lang = useSelector(state => state.lang)

  
    counterpart.registerTranslations('en',en) 
    counterpart.registerTranslations('es',es)  
    counterpart.setLocale(localStorage.getItem('langlocal'));



  return (
    <Fragment>
      <Grid container spacing={2} noValidate>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label="Facebook"
            name="facebook"
            placeholder=""
            margin="normal"
            value={facebook || ""}
            onChange={handleChange}
            error={!!formErrors.facebook}
            helperText={formErrors.facebook}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label="Instagram"
            name="instagram"
            placeholder=""
            margin="normal"
            value={instagram || ""}
            onChange={handleChange}
            error={!!formErrors.instagram}
            helperText={formErrors.instagram}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label="Linkedin"
            name="linkedin"
            placeholder=""
            margin="normal"
            value={linkedin || ""}
            onChange={handleChange}
            error={!!formErrors.linkedin}
            helperText={formErrors.linkedin}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label="Email"
            name="email"
            placeholder=""
            value={email || ""}
            onChange={handleChange}
            margin="normal"
            error={!!formErrors.email}
            helperText={formErrors.email}
            required
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label={<Translate content="Descripcion" component="span" />}
            name="descripcion"
            placeholder=""
            type=""
            value={descripcion || ""}
            onChange={handleChange}
            margin="normal"
            error={!!formErrors.descripcion}
            helperText={formErrors.descripcion}
            required
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth  margin="normal">
            <InputLabel>{<Translate content="TIEMPOENELMERCADO" component="span" />}</InputLabel>
            <Select value={tiempo_mercado} onChange={handleChange} name="tiempo_mercado">
              <MenuItem value={"Menos de 1 año"}>{<Translate content="Menosde1año" component="span" />}</MenuItem>
              <MenuItem value={"De 1 a 3 años"}>{<Translate content="De1a3años" component="span" />}</MenuItem>
              <MenuItem value={"Mas de 3 años"}>{<Translate content="Masde3años" component="span" />}</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label={<Translate content="nitidempresa" component="span" />}
            name="nit"
            placeholder="" 
            type=""
            value={nit || ""}
            onChange={handleChange}
            margin="normal"
            error={!!formErrors.nit}
            helperText={formErrors.nit}
            required
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth  margin="normal">
            <InputLabel>{<Translate content="SECTOR" component="span" />}</InputLabel>
            <Select value={sector} onChange={handleChange} name="sector">
              <MenuItem value={"Finanzas"}>{<Translate content="FinanzasNegociaciónyliderazgo" component="span" />}</MenuItem>
              <MenuItem value={"Negociación y liderazgo"}>{<Translate content="Ingenierias" component="span" />}</MenuItem>
              <MenuItem value={"Ingenierias"}>{<Translate content="SECTOR" component="span" />}</MenuItem>
              <MenuItem value={"Planeación y modelaje de negocios"}>{<Translate content="Planeaciónymodelajedenegocios" component="span" />}</MenuItem>
              <MenuItem value={"Impacto social y sostenibilidad"}>{<Translate content="Impactosocialysostenibilidad" component="span" />}</MenuItem>
              <MenuItem value={"Recursos Humanos"}>{<Translate content="RecursosHumanos" component="span" />}</MenuItem>
              <MenuItem value={"Estrategia"}>{<Translate content="Estrategia" component="span" />}</MenuItem>
              <MenuItem value={"Legal"}>{<Translate content="Legal" component="span" />}</MenuItem>
              <MenuItem value={"Marketing y ventas"}>{<Translate content="Marketingyventas" component="span" />}</MenuItem>
              <MenuItem value={otroCausa} onClick={()=>setotroCausa("otro")} >{<Translate content="Otro" component="span"/>}</MenuItem>
            </Select>
            { otroCausa==='otro' ? 
               <TextField
               fullWidth
               label={<Translate content="SECTOR" component="span" />}
               name="sector"
               placeholder=""
               margin="normal"
               value={sector || ""}
               onChange={handleChange}
               error={!!formErrors.sector}
               helperText={formErrors.sector}
               
             />: null}
          </FormControl>
        </Grid>
      </Grid>
      <div
        style={{ display: "flex", marginTop: 50, justifyContent: "flex-end" }}
      >
        <Button
          variant="contained"
          disabled={!isValid}
          color="primary"
          onClick={isValid ? handleNext : null}
        >
          {<Translate content="siguienteee" component="span"/>}
        </Button>
      </div>
    </Fragment>
  );
};

export default FirstStep;
