/* eslint-disable */
import React, {useState, useEffect} from 'react';
import FormControlLabel from '@mui/material/FormControlLabel';
import CircleIcon from '@mui/icons-material/Circle';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Typography from '@mui/material/Typography';
import './CrearOportunidadNew.css'
import { useDispatch } from 'react-redux';
import { saveOportunityForm } from '../../actions';

export default function Duration(props) {
const { form, step, handleStep, handleBackStep, lang, oportunityForm, handleForm} = props;
const [button, setbutton] = useState(true);
const dispatch = useDispatch();
useEffect(() => {
  if( oportunityForm?.estimadoDuración ?  oportunityForm?.estimadoDuración !== '' : form?.estimadoDuración !== ''){
    setbutton(false)
  }
},[form.estimadoDuración])

const handleChange = (e) => {
  handleForm({...form, estimadoDuración: e.target.value});
  dispatch(saveOportunityForm({estimadoDuración: e.target.value}, step));
  setbutton(false)
}

    return (
        <>
        <div className='contenedorDuration'>
       <h3 className='contenedorDuration_title'>{lang === 'es' ? '¿Cuál es el tiempo de duración de la oportunidad, cargo, proyecto o rol?' : 'What is your estimate for the duration of the opportunity?'}</h3>
     <div>
      <RadioGroup
        aria-labelledby="demo-controlled-radio-buttons-group"
        name="estimadoDuración"
        className='durationRadio'
        value={oportunityForm?.estimadoDuración ? oportunityForm?.estimadoDuración : form?.estimadoDuración}
      >
        <FormControlLabel name="estimadoDuración" value="Indefinida" control={<Radio icon={<CircleIcon className='radio_unchecked_icon' />} checkedIcon={<CircleIcon className='radio_checked_icon'/>}/>}onChange={handleChange} label={<Typography sx={{ fontSize: '14px', fontFamily: 'Poppins', fontWeight: '400' }}>
        {lang === 'es' ? 'Indefinida' : 'Indefinite'}
    </Typography>} />

        <FormControlLabel value="1 mes" name="estimadoDuración"  onChange={handleChange} control={<Radio icon={<CircleIcon className='radio_unchecked_icon' />} checkedIcon={<CircleIcon className='radio_checked_icon'/>}/>} label={<Typography sx={{ fontSize: '14px', fontFamily: 'Poppins', fontWeight: '400'}}>
        {lang === 'es' ? '1 mes' : '1 month'}
    </Typography>} />

    <FormControlLabel value="Otro" name="estimadoDuración"  onChange={handleChange} control={<Radio icon={<CircleIcon  className='radio_unchecked_icon' />} checkedIcon={<CircleIcon className='radio_checked_icon'/>}/>} label={<Typography sx={{ fontSize: '14px', fontFamily: 'Poppins', fontWeight: '400'}}>
    {lang === 'es' ? 'Otro' : 'Other'}
    </Typography>} />
      </RadioGroup>
    </div>
            </div>
            <div
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "space-between",
              padding: "1rem 3rem 0rem 3rem",
              alignItems: "center",
            }}
          >
             <button className='button_siguiente_create_B' onClick={handleBackStep}>{lang === 'es' ? 'Anterior' : 'Previous'}</button>
            <h3 className='stepCounter'>{`${step}/14`}</h3>
            <button className='button_siguiente_create'
            onClick={()=>handleStep('estimadoDuración')} disabled={button}>{lang === 'es' ? 'Siguiente' : 'Next'}</button>
          </div>
          </>
    )
}