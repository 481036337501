/*eslint-disable*/
import React, {useEffect, useState, useContext} from 'react'
import {useSelector} from "react-redux";
import './ProfesionalesSimilaresCard.css'
import CvModalButton from "../CvModalButton/CvModalButton";
import {Link} from "react-router-dom";
import axios from "axios";
import {UniversityContext} from "../../../context/UniversitiesContext";

export default function ProfesionalesSimilaresCard({
                                                     name,
                                                     job,
                                                     image,
                                                     LinkedInCv,
                                                     generalCv,
                                                     id,
                                                     compatibility,
                                                     recos
                                                   }) {
  const [data, setData] = useState(null)
const {university} = useContext(UniversityContext);
  useEffect(() => {
    if (recos === false) {
      return
    }
    axios.get(`https://findally-backend.herokuapp.com/api/user/CV/${id}`)
      .then(res => {
        setData(res.data.data)
      })
      .catch(err => {
        console.log(err)
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])

  const {userPlan} = useSelector(state => state);
  return (
    <div className={'prof-sim-card-container'}>
      <div>
        <div>
          <div
            className={/*userPlan?.nombre_paquete === 'Paquete Free' ? 'aplic-card-img-filtro-2 ' : */'aplic-card-img-filtro'}>
            <img className={'prof-sim-card-image'}
                 src={image ? image : 'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png'}
                 alt="imagen de perfil"/>
          </div>
        </div>
      </div>
      <div>
        <Link to={`/${university.route}/company/profile/${data?.usuario?._id}`}
              className={'prof-sim-card-name'}>{recos === true ? data?.info_personal?.nombre : name}</Link>
        <h3 className={'prof-sim-card-job'}>{recos === true ? data?.info_personal?.profesion_actual : job}</h3>
        {compatibility ? <h3 className={'prof-sim-card-comp'}>{compatibility}</h3> : null}
        {recos === false ? <CvModalButton idUser={id}  LinkedInCv={LinkedInCv} generalCv={generalCv}/> : null}
      </div>
    </div>
  )
}
