import styled from "styled-components";
import { Link } from "react-router-dom";
import {
  OpacityOff,
  OpacityOn,
  ToLeft,
  FromLeft,
} from "../../styles/AnimationStyles";
import { HiOutlineHome } from "react-icons/hi";
import { FiBook } from "react-icons/fi";
import { IoIosLaptop } from "react-icons/io";
import { BiTrendingUp } from "react-icons/bi";
import { BsGearFill } from "react-icons/bs";
import { MdOutlineLogout } from "react-icons/md";
const SidebarContainer = styled.div<{
  color?: string;
  isClosing?: boolean;
  responsive?: boolean;
}>`
  background: ${(props) => props.color};
  width: 237px;
  display: ${(props) => (props.responsive ? "none" : "flex")};
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
  animation: ${(props) => (props.isClosing ? ToLeft : FromLeft)} 0.5s ease;

  @media (min-width: 1300px) {
    display: flex;
  }
`;

const SidebarLogo = styled.img`
  width: 180px;
  margin-bottom: 60px;
`;

const SidebarLinksContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 100%;
  margin-top: 72px;
`;

const SidebarLink = styled(Link)<{
  "data-isactive"?: boolean;
  color?: string;
  background?: string;
}>`
  padding-left: 28px;
  font-weight: 400;
  font-size: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  color: ${(props) => (props["data-isactive"] ? props.color : "#C5C5C5")};
  background: ${(props) =>
    props["data-isactive"] ? props.background : "transparent"};
  width: 90%;
  height: 55px;
  margin-left: 10%;
  border-top-left-radius: ${(props) =>
    props["data-isactive"] ? "40px" : "0px"};
  border-bottom-left-radius: ${(props) =>
    props["data-isactive"] ? "40px" : "0px"};
`;

const CloseButton = styled.button<{ isClosing?: boolean }>`
  background-color: transparent;
  margin-left: 180px;
  margin-top: 10px;
  position: relative;
  color: #5d5d5d;
  font-weight: 300;
  font-size: 40px;
  z-index: 5;
  opacity: 0;
  animation: ${(props) => (props.isClosing ? OpacityOff : OpacityOn)} 0.5s ease
    forwards;
  animation-delay: 0.5s;
`;

const HomeIcon = styled(HiOutlineHome)`
  margin-right: 15px;
`;

const BookIcon = styled(FiBook)`
  margin-right: 15px;
`;

const LaptopIcon = styled(IoIosLaptop)`
  margin-right: 15px;
`;

const TrendingUpIcon = styled(BiTrendingUp)`
  margin-right: 15px;
`;

const GearIcon = styled(BsGearFill)`
  margin-right: 15px;
`;

const LogoutIcon = styled(MdOutlineLogout)`
  margin-right: 15px;
`;

const SidebarBottomContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-bottom: 35px;
`;

const LogoutButton = styled(Link)`
  padding-left: 28px;
  font-weight: 700;
  font-size: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  color: #c5c5c5;
  width: 90%;
  height: 55px;
  background: transparent;
  margin-bottom: 1.5em;
  margin-left: 10%;
  &:hover {
    text-decoration: none;
    color: #c5c5c5;
  }
`;

const UploadCvButton = styled.label<{
  message?: boolean;
  color?: string;
  textColor?: string;
  margin?: string
}>`
  cursor: pointer;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  height: 35px;
  width: 185px;
  background: ${(props) => (props.message ? "#E71C00" : props.color)};
  border-radius: 5px;
  font-weight: 400;
  font-size: 14px;
  color: ${(props) => (props.textColor ? props.textColor : "#ffffff")};
  margin: ${(props) => props.margin};
`;

const UploadCvDisabled = styled.label`
  display: flex;
  cursor: not-allowed;
  justify-content: space-evenly;
  align-items: center;
  height: 35px;
  width: 120px;
  background: #868686;
  border-radius: 5px;
  font-weight: 400;
  font-size: 14px;
  color: #ffffff;
`;

const TutorialPopupContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 80%;
  height: 50%;
  position: absolute;
  transform: translate(-50%, -50%);
  z-index: 10;
`;

const TutorialSpan = styled.span<{ color?: string }>`
  color: ${(props) => props.color};
  cursor: pointer;
  margin-left: 5px;
`;

export {
  CloseButton,
  SidebarContainer,
  SidebarLinksContainer,
  SidebarLink,
  SidebarLogo,
  HomeIcon,
  BookIcon,
  LaptopIcon,
  GearIcon,
  LogoutIcon,
  TrendingUpIcon,
  SidebarBottomContainer,
  LogoutButton,
  UploadCvButton,
  UploadCvDisabled,
  TutorialPopupContainer,
  TutorialSpan,
};
