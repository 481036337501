import ReactPaginate from "react-paginate";
import Loader from "../../../../../profesionalpag/Loader/LoaderHome";
import React, { useContext } from "react";
import "../../../../../profesionalpag/oportunidad/cards/cardsLinked.css";
import { SchneiderContext } from "../../../../context/SchneiderContext";

interface PaginateComponentProps {
  projects: any;
  handlePageClick: (selectedItem: { selected: number }) => void;
  page: {
    totalPage: number;
  };
  loading: boolean;
}

const RenderPaginate: React.FC<PaginateComponentProps> = ({
  projects,
  handlePageClick,
  page,
  loading,
}) => {
  const { schneider } = useContext(SchneiderContext);

  const paginationActualStyles = document.createElement("style");
  paginationActualStyles.innerHTML = `.pageActSchneider {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: ${schneider.color.primary};
        color: ${schneider.color.secondary};
    }`;
  document.head.appendChild(paginationActualStyles);

  const paginationPrevStyles = document.createElement("style");
  paginationPrevStyles.innerHTML = `.previBSchneider {
        display: flex;
        justify-content: center;
        align-items: center;
        color: #000;
        width: 40px;
        border-radius: 100%;
        box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
    }`;
  document.head.appendChild(paginationPrevStyles);

  return (
    <div className="paginate-oportunities-new-linked">
      {projects ? (
        <ReactPaginate
          className="paginateStyle"
          breakLabel="..."
          nextLabel=">"
          onPageChange={handlePageClick}
          pageRangeDisplayed={3}
          pageCount={page.totalPage}
          previousLabel="<"
          marginPagesDisplayed={1}
          activeClassName="pageActSchneider"
          pageClassName="previBSchneider"
          nextClassName="nextB"
          previousClassName="previB"
        />
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
          }}
        >
          <Loader render={loading} array={projects} altura={15} />
        </div>
      )}
    </div>
  );
};

export default RenderPaginate;
