import styled from "styled-components";

const ProfessionalCardContainer = styled.div`
  padding: 15px;
  display: flex;
  align-items: center;
  flex-direction: column;
`;

const DataUserContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: -1em;
  width: fit-content;
  padding: 5px;
`;

const NameText = styled.h1`
  font-weight: bold;
  margin-bottom: 2em;
  font-size: 20px;
`;

const InternText = styled.p`
  color: #737373;
  margin-top: -1em;
`;

const TitleSection = styled.h1`
  font-size: 16px;
  margin-bottom: 12px;
  font-weight: bold;
`;

const SectionContainer = styled.div`
  width: 100%;
  margin-bottom: 30px;
  margin-left: 20px;
`;

const SectionSeparator = styled.div`
  color: #ececec;
  width: 100%;
`;

const LanguagesContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

export {
  ProfessionalCardContainer,
  DataUserContainer,
  InternText,
  NameText,
  TitleSection,
  SectionContainer,
  SectionSeparator,
  LanguagesContainer,
};
