import React from "react";
import { Link } from 'react-router-dom';
import "./CompanyDemo.css";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/swiper.min.css";
import "swiper/components/pagination/pagination.min.css"
import CancelPresentationIcon from '@material-ui/icons/CancelPresentation';

// import Swiper core and required modules
import SwiperCore, {
  Pagination
} from 'swiper/core';

// install Swiper modules
SwiperCore.use([Pagination]);

const pagination = {
  "clickable": true,
  "renderBullet": function (index, className) {

    return '<span class="' + className + '">' + (index + 1) + '</span>';

  }
}

function CompanyDemoEspañol(props) {

  return (
    <>
      <div className="volver-demo">
        <Link className="volver-leg-icon" to="/">
          {" "}
          <CancelPresentationIcon style={{ color: '#8C00DF' ,fontSize: '30px', marginRight: '1em'}} />
        </Link>
      </div>
      <div className="carrusel-demo">
        <Swiper pagination={pagination} className="mySwiper">
          <SwiperSlide><img alt="img-demo-consultor" className="img-demo-content" src="https://coally-images.s3.amazonaws.com/DEMO+CONSULTOR/slideregistrousuarioconsu.png" /></SwiperSlide>
          <SwiperSlide><img alt="img-demo-consultor" className="img-demo-content" src="https://coally-images.s3.amazonaws.com/DEMO+CONSULTOR/slideiniciarusuarioconsu.png" /></SwiperSlide>
          <SwiperSlide><img alt="img-demo-consultor" className="img-demo-content" src="https://coally-images.s3.amazonaws.com/DEMO+EMPRESA/firstslidecomp.png" /></SwiperSlide>
          <SwiperSlide><img alt="img-demo-consultor" className="img-demo-content" src="https://coally-images.s3.amazonaws.com/DEMO+EMPRESA/secondslidecompany.png" /></SwiperSlide>
          <SwiperSlide><img alt="img-demo-consultor" className="img-demo-content" src="https://coally-images.s3.amazonaws.com/DEMO+EMPRESA/createprojectcompany.png" /></SwiperSlide>
          <SwiperSlide><img alt="img-demo-consultor" className="img-demo-content" src="https://coally-images.s3.amazonaws.com/DEMO+EMPRESA/slidepaymentcompany.png" /></SwiperSlide>
          <SwiperSlide><img alt="img-demo-consultor" className="img-demo-content" src="https://coally-images.s3.amazonaws.com/DEMO+EMPRESA/slidematchcompany.png" /></SwiperSlide>
          <SwiperSlide><img alt="img-demo-consultor" className="img-demo-content" src="https://coally-images.s3.amazonaws.com/DEMO+EMPRESA/slideseguimientocompany.png" /></SwiperSlide>
          <SwiperSlide><img alt="img-demo-consultor" className="img-demo-content" src="https://coally-images.s3.amazonaws.com/DEMO+EMPRESA/slidefeedbackcompany.png" /></SwiperSlide>
        </Swiper>
      </div>
    </>
  );
}

export default (CompanyDemoEspañol);