import "./Styles/CompaniesCreateProject.css";
import { CreateProject } from "../../actions/index";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Paper from "@material-ui/core/Paper";
import Divider from "@material-ui/core/Divider";
import StepForm from "../../components/ProyectForm/ProjectForm";
import HeaderEmpresa from "../../components/HeaderEmpresa/HeaderEmpresa";
import { connect, } from "react-redux";
import en from "../../lang/en-US";
import es from "../../lang/es-COL"
import counterpart from "counterpart";
import Translate from "react-translate-component";

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
    paddingRight: 10,
    paddingLeft: 10
  },
  svg: {
    verticalAlign: "middle"
  },
  layout: {
    width: "auto",
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
      width: 600,
      marginLeft: "auto",
      marginRight: "auto"
    }
  },
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(8),
      marginBottom: theme.spacing(8),
      padding: theme.spacing(3)
    }
  }
}));



function VerticalLinearStepper(props) {
/*
  const [form, setValues] = useState({
  const [form, setValues] = useState({
    usuario: props.user.id,
    nombre_completo:"",
    profesion: "",
    facebook: "",
    instagram: "",
    cedula:"",
    cedula_exp:"",
    telefono: "",
    direccion:"",
    linkedin: "",
    youtube: "",
    estudios: "",
    cargo:"",
    idioma : "",
    conocimientos : "",
    intereses :"",
    horas : "",
    actividadesExtra: true,
    tiempo_voluntario:"",
    tiempo_proyecto:"",
    description:"",
    sectores_interes :"",
  });
    //   fecha:
    //   date.getDate() + "/" + (date.getMonth() + 1) + "/" + date.getFullYear(),
  });

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleInput = (event) => {
    if (event.target.options) {
      console.log(event.target.name);
      setValues({
        ...form,
        [event.target.name]:
          event.target.options[event.target.options.selectedIndex].value,
      });
    } else {
      setValues({
        ...form,
        [event.target.name]: event.target.value,
      });
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    console.log(form);
     props.CreateProject(form, "/company/profile");
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  /* const handleReset = () => {
    setActiveStep(0);
  }; */

  const classes = useStyles();
  counterpart.registerTranslations('en',en) 
      counterpart.registerTranslations('es',es)
      counterpart.setLocale(localStorage.getItem('langlocal'));
  return (
    <>
    <HeaderEmpresa/>
    <div className="section-tittle-out-form">
        <h2 className="tittle-out-form">{<Translate content="Publicarproyecto" component="span"/>}</h2>
      </div>
      <div className="CompanyForm">
        <section className="CompanyForm-container">
    <div className="App">
      <CssBaseline />
      <main className={classes.layout}>
        <Paper className={classes.paper}>
          <StepForm />
        </Paper>
        <Divider style={{ marginTop: 100 }} />

      </main>
    </div>
  
        </section>
      </div>
    </>
  );
}

const mapDispatchToProps = {
  CreateProject,
};
const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(VerticalLinearStepper);
