import React, {useContext} from "react";
import CircularProgress from "@mui/material/CircularProgress";
import userImg from "../../../../assets/icons/user.svg";
import { ReduxStateInterface } from "../../../types/reduxInterface";
import {
  CircularFrameContainerDropdown,
  AvatarContainerDropdown,
} from "./CircularFrameStyles";
import useCircularProgress from "./utils/useCircularProgress";
import {SchneiderContext} from "../../../context/SchneiderContext";

const CircularFrameDropdown = ({ user }: ReduxStateInterface) => {
  const {schneider} = useContext(SchneiderContext)
  const progress = useCircularProgress();
  return (
    <CircularFrameContainerDropdown color={schneider.color.primary}>
      <CircularProgress
        thickness={2}
        variant="determinate"
        value={100}
        id="second-circle-progress-dropdown"
      />
      <CircularProgress
        thickness={1.5}
        variant="determinate"
        value={progress}
      />

      <AvatarContainerDropdown>
        <img src={user.avatar ? user.avatar : userImg} alt={"profile-pic"} />
      </AvatarContainerDropdown>
    </CircularFrameContainerDropdown>
  );
};

export default CircularFrameDropdown;
