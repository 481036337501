/* eslint-disable  */
import React, {useContext, useEffect, useState} from "react";
import Navbar from "../../../components/navbar/Navbar";
import Sidebar from "../../../components/sidebar/Sidebar";
import {ComponentWrapper, BodyWebPapa} from "../../../styles/GlobalStyles";
import {
  HomeContainer,
  HomeContent,
  HomeContentTop,
  HomeOpportunitiesContainer,
  SeeMoreContainer,
  SeeMoreLink,
  HomeTestsContainer,
  HomeTestTextContainer,
  TestText,
  HomeTestsGrid,
  HomeBottomContainer,
  HomeCvPopupContainer,
  HomeCvPopupButtons,
  /* HomeCarouselContainer,
  CarouselText,
  CarouselTextContainer,
  CarouselContainer,*/
} from "./HomeStyles";
import {usePopupContext} from "../../../context/popups/PopupContext";
import Popup from "../../../components/popup/Popup";
import ChatGptBanner from "./partials/banner/ChatGptBanner";
import {useSelector, useDispatch} from "react-redux";
import {ReduxStateInterface} from "../../../types/reduxInterface";
import {CustomizableTitle} from "../../../styles/TitleStyles";
import {SchneiderContext} from "../../../context/SchneiderContext";
import {useRankedOpportunities} from "./utils/useRankedOpportunities";
import GridCard from "../../../components/cards/oportunitycard/grid/GridCard";
import proyectoimagen from "../../../../profesionalpag/oportunidad/proyectos.webp";
import TestsInfo from "../../../../profesionalpag/home/TestCard/TestsInfo";
import {Link} from "react-router-dom";
import TourProfessional from "../../../components/tour/TourProfessional";
import {tourStatus} from "../../../../actions";
/*import HomeCarousel from "../../../../profesionalpag/home/HomeCarousel";*/
const Home = () => {
  const {lang, user, cv, tourViewed} = useSelector((state: ReduxStateInterface) => state);
  const {schneider} = useContext(SchneiderContext);
  const {popups, addPopup, removePopup} = usePopupContext();
  const {opportunities} = useRankedOpportunities();
  const dispatch = useDispatch();

  const [width, setWidth] = useState(window.innerWidth);
  const updateWidthAndHeight = () => {
    setWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", updateWidthAndHeight);

    return () => window.removeEventListener("resize", updateWidthAndHeight);
  }, [width]);

  useEffect(() => {
    // const tourv = localStorage.getItem("tourViewed");
    // if ( tourv === null) {
    //   localStorage.setItem("tourViewed", 'false');
    // }
    if (tourViewed === true) {
      return;
    }
    setTimeout(() => {
      dispatch(tourStatus({run: true}));
    }, 1500);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!user) return;

    if (cv) {
      localStorage.setItem("cv", "true");
      localStorage.setItem("popupBienvenido", "true");
    } else {
      localStorage.setItem("cv", "false");
      localStorage.setItem("popupBienvenido", "false");
    }
    const cvLoad = localStorage.getItem("cv");
    const popUp = localStorage.getItem("popupBienvenido");
    const visit = localStorage.getItem("homeVisited");
    if (visit === null) {
      localStorage.setItem("homeVisited", "false");
    }
    if (cv === null && cvLoad === "false") {
      addPopup({
        id: "CvRouter",
        content: (
          <div>
            <h2>{lang === "es" ? "¡Bienvenido!" : "Welcome"}</h2>
            <p>
              {lang === "es" ? "Necesitamos que completes tu perfil para poder brindarte una mejor experiencia." : "We need you to complete your profile in order to provide you with a better experience."}
            </p>
            <HomeCvPopupContainer>
              <Link onClick={() => removePopup({id: "CvRouter"})} to={`/schneider/profile/${user.id}`}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      padding: "0 20px",
                      height: "40px",
                      borderRadius: "5px",
                      border: "none",
                      backgroundColor: "#f5f5f5",
                      color: "#393939",
                    }}
              >
                {lang === "es" ? "Completar Perfil" : "Complete Profile"}
              </Link>
            </HomeCvPopupContainer>
          </div>
        ),
        onClose: () => removePopup({id: "CvRouter"}),
      });
    } else if (cv !== null && popUp === "true" && visit === "false") {
      addPopup({
        id: "WelcomePop",
        content: (
          <div style={{textAlign: "center"}}>
            <h2>¡Bienvenido!</h2>
            <p>Conoce las oportunidades que en <span style={{color: schneider.color.primary, fontWeight: "bold"}}>Schneider Electric</span> tenemos
              para ti</p>
            <HomeCvPopupContainer>
              <Link to={`/schneider/projects`} onClick={() => removePopup({id: "WelcomePop"})}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      padding: "0 20px",
                      height: "40px",
                      borderRadius: "5px",
                      border: "none",
                      backgroundColor: "#f5f5f5",
                      color: "#393939",
                    }}
              >Ver Oportunidades</Link>
            </HomeCvPopupContainer>
          </div>
        ),
        onClose: () => {
          localStorage.setItem("homeVisited", "true");
          removePopup({id: "WelcomePop"});
        },
      });
    }
    return () => removePopup({id: "CvRouter"});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cv]);

  return (
    <ComponentWrapper>
      <TourProfessional/>
      <Sidebar location={"home"}/>
      <BodyWebPapa>
        <Navbar locate={"home"}/>
        <HomeContainer id={"home"}>
          {popups.map((popup) => (
            <Popup key={popup.id} id={popup.id} onClose={popup.onClose}>
              {popup.content}
            </Popup>
          ))}
          <ChatGptBanner/>
          <HomeContent>
            <HomeContentTop>
              <CustomizableTitle
                color={schneider.color.primary}
                size={"20px"}
                weight={"400"}
                align={"center"}
              >
                {lang === "es"
                  ? "Es momento de buscar"
                  : "It´s time to look for"}
              </CustomizableTitle>
            </HomeContentTop>
            <HomeContentTop>
              <CustomizableTitle
                color={schneider.color.primary}
                size={"25px"}
                weight={"700"}
                align={"left"}
              >
                {lang === "es"
                  ? "nuevas oportunidades laborales"
                  : "new job opportunities"}
              </CustomizableTitle>
            </HomeContentTop>
            <HomeOpportunitiesContainer>
              {opportunities.map((p, index) => {
                return (
                  <GridCard
                    key={index}
                    descripcion={
                      p.empleos_alternativos[0] ? p.empleos_alternativos[0] : ""
                    }
                    titulo={p.NombreOportunidad}
                    titulo2={p.nombre}
                    salario={p.presupuesto}
                    id={p._id}
                    lenguaje={lang}
                    imagen={p.imageUrl ? p.imageUrl : proyectoimagen}
                    ciudad={p.municipio ? p.municipio : null}
                    profesionales={p?.aplicantes?.length}
                    modalidadDeTrabajo={p.modalidadDeTrabajo}
                    porcentaje={p.fit}
                  />
                );
              })}
            </HomeOpportunitiesContainer>
            <SeeMoreContainer>
              <SeeMoreLink to={`/${schneider.route}/projects`}>
                {lang === "es" ? "Ver más" : "See more"}
              </SeeMoreLink>
            </SeeMoreContainer>
            {/* <HomeTestsContainer>
              <HomeTestTextContainer>
                <TestText>Tests</TestText>
              </HomeTestTextContainer>
              <HomeTestsGrid>
                <TestsInfo
                  width={width}
                  lang={lang}
                  color={schneider.color.primary}
                />
              </HomeTestsGrid>
            </HomeTestsContainer> */}
            <HomeBottomContainer>
              {/*<HomeCarouselContainer>
                <CarouselTextContainer>
                  <CarouselText>
                    {lang === "es"
                      ? "¡Fórmate con nosotros!"
                      : "Train with us!"}
                  </CarouselText>
                </CarouselTextContainer>
                <CarouselContainer>
                  <HomeCarousel lang={lang} title={""} children={""} />
                </CarouselContainer>
              </HomeCarouselContainer>*/}
            </HomeBottomContainer>
          </HomeContent>
        </HomeContainer>
      </BodyWebPapa>
    </ComponentWrapper>
  );
};

export default Home;
