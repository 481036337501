/* eslint-disable  */
// window.localStorage.setItem('langlocal', 'es');
import React, {useState, useEffect} from 'react';
import axios from 'axios';
import './PostulateUnilever.css';
import LayerTop from "../../assets/logos/layer-unilever.png";
import Logo from "../../assets/logos/unilever.png";
import {FaFacebookF, FaInstagram, FaLinkedinIn} from "react-icons/fa";
import RingIzq from '../../assets/logos/anillo-izquierda.png'
import RingDer from '../../assets/logos/anillo-derecha.png'
import Firulete1 from '../../assets/logos/firulete3.png'
import Firulete2 from '../../assets/logos/firulete5.png'
import Select from 'react-select';
import Creatable from 'react-select/creatable';
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import universities from './universities';
import {useSelector} from 'react-redux';
import careers from './careers';
import TermsAndConditions from '../../components/termsAndConditions/TermsAndConditions';
import LogoCoally from '../../assets/logos/Logo-Coally.png';

const customStyles = {
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? '#1f36c7' : '#f2f2f2',
    cursor: 'pointer',
    padding: 10,
    color: state.isSelected ? '#fff' : '#4a4a4a',
  }),
  control: (provided, state) => ({
    ...provided,
    width: '100%',
    display: 'flex',
    alignItems: 'flex-start',
    padding: '0',
    justifyContent: 'center',
    borderRadius: '8px',
    background: 'transparent',
    backgroundColor: state.isSelected ? '#005eef' : '#F5F5F5',
    color: state.isSelected ? '#fff' : '#4a4a4a',
  }),
  menu: (provided, state) => ({
    ...provided,
    color: 'white',
    padding: '0',
    width: '98%',
    marginTop: '-1em'
  }),
  placeholder: (provided, state) => ({
    ...provided,
    color: 'white',
  }),
  singleValue: (provided, state) => ({
    ...provided,
    color: 'white',
  }),
  input: (provided, state) => ({
    ...provided,
    color: 'white',
  }),
  menuList: (provided, state) => ({
    ...provided,
    background: '#f2f2f2',
  }),
  multiValueRemove: (provided, state) => ({
    ...provided,
    color: 'white',
    cursor: 'pointer',
  }),
  multiValue: (provided, state) => ({
    ...provided,
    background: 'transparent',
    color: 'white',
  }),
  multiValueLabel: (provided, state) => ({
    ...provided,
    color: 'white',
  }),
}

export default function PostulateUnilever() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);

  const [honores, setHonores] = useState(false)
  const [occupation, setOccupation] = useState(false)
  const [extraCurricular, setExtraCurricular] = useState(false);
  const [volunteering, setVolunteering] = useState(false);
  const [tyc, setTyc] = useState(false);
  const [popUp, setPopUp] = useState(false);
  const lang = useSelector(state => state.lang);
  const [form, setForm] = useState({
    name: '',
    lastName: '',
    email: '',
    password: '',
    phone: '',
    city: '',
    address: '',
    Date_Birth: '',
    gender: '',
    university: '',
    occupation: '',
    time_graduate: '',
    interest: '',
    sector_area: '',
    Honor: [],
    skills: [],
    skills_personal: [],
    part_program: '',
    social_level: '',
    careers: '',
    volunteering: '',
    extracurricular: [],
    objetives: [],
    personality: '',
    motivational_question: '',
    cominitary_work: '',
    academic_level: '',
  })

  const acceptTyC = () => {
    setTyc(true)
    setPopUp(false)
  }

  const sendForm = async (e) => {
    e.preventDefault();
      toast.info('Este registro ha finalizado, este atento a nuevos programas de Unilever!', {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
  }

  /* HONORS FUNCTIONS */
  const addHonores = () => {
    const newHonores = [...form.Honor, ''];
    setForm({...form, Honor: newHonores});
  }
  const handleChangeHonores = (e, index) => {
    const newHonores = [...form.Honor];
    newHonores[index] = e.target.value;
    setForm({...form, Honor: newHonores});
  }

  const handleDeleteHonores = (index) => {
    const newHonores = [...form.Honor];
    newHonores.splice(index, 1);
    setForm({...form, Honor: newHonores});
  }
  /* EXTRA CURRICULAR FUNCTIONS */
  const addExtraCurricular = () => {
    const newExtraCurricular = [...form.extracurricular, ''];
    setForm({...form, extracurricular: newExtraCurricular});
  }

  const handleChangeExtraCurricular = (e, index) => {
    const newExtraCurricular = [...form.extracurricular];
    newExtraCurricular[index] = e.target.value;
    setForm({...form, extracurricular: newExtraCurricular});
  }

  const handleDeleteExtraCurricular = (index) => {
    const newExtraCurricular = [...form.extracurricular];
    newExtraCurricular.splice(index, 1);
    setForm({...form, extracurricular: newExtraCurricular});
  }


  const habilities = [
    {value: 'Diseño', label: 'Diseño'},
    {value: 'Marketing y Publicidad', label: 'Marketing y Publicidad'},
    {value: 'Redacción', label: 'Redacción'},
    {value: 'Vídeo y Animación', label: 'Vídeo y Animación'},
    {value: 'Música y audio', label: 'Música y audio'},
    {value: 'Programación y tecnología', label: 'Programación y tecnología'},
    {value: 'Negocios', label: 'Negocios'},
    {value: 'Análisis de datos', label: 'Análisis de datos'},
    {value: 'Legal', label: 'Legal'},
  ]

  const interestAreas = [
    {value: 'Comunicaciones', label: 'Comunicaciones'},
    {value: 'IT', label: 'IT'},
    {value: 'Marketing', label: 'Marketing'},
    {value: 'Ventas', label: 'Ventas'},
    {value: 'Cadena de Abastecimiento', label: 'Cadena de Abastecimiento'},
    {value: 'Gestión Humana', label: 'Gestión Humana'},
    {value: 'E-commerce', label: 'E-commerce'},
    {value: 'Investigación o Desarrollo', label: 'Investigación o Desarrollo'},
    {value: 'Conocimiento Consumidor', label: 'Conocimiento Consumidor'},
    {value: 'Finanzas', label: 'Finanzas'},
  ]

  const longTermGoals = [
    {value: 'Director Ejecutivo', label: 'Director Ejecutivo'},
    {value: 'Director de Desarrollo Tecnológico', label: 'Director de Desarrollo Tecnológico'},
    {value: 'Director de Información', label: 'Director de Información'},
    {value: 'Director de Operaciones', label: 'Director de Operaciones'},
    {value: 'Director de Recursos Humanos', label: 'Director de Recursos Humanos'},
    {value: 'Director de Mercadeo', label: 'Director de Mercadeo'},
    {value: 'Director de Ventas', label: 'Director de Ventas'},
    {value: 'Director de Marketing', label: 'Director de Marketing'},
    {value: 'Director de Finanzas', label: 'Director de Finanzas'},
    {value: 'Gerente Comercial', label: 'Gerente Comercial'},
    {value: 'Gerente de Servicios Administrativos', label: 'Gerente de Servicios Administrativos'},
    {value: 'Gerente de Marketing', label: 'Gerente de Marketing'},
    {value: 'Gerente de Recursos Humanos.', label: 'Gerente de Recursos Humanos.'},
  ]

  const habilitiesToImprove = [
    {value: 'Diseño', label: 'Diseño'},
    {value: 'Marketing y Publicidad', label: 'Marketing y Publicidad'},
    {value: 'Redacción', label: 'Redacción'},
    {value: 'Vídeo y Animación', label: 'Vídeo y Animación'},
    {value: 'Música y audio', label: 'Música y audio'},
    {value: 'Programación y tecnología', label: 'Programación y tecnología'},
    {value: 'Negocios', label: 'Negocios'},
    {value: 'Análisis de datos', label: 'Análisis de datos'},
    {value: 'Legal', label: 'Legal'},
  ]
  const honors = [
    {value: 'Cum Laude', label: 'Cum Laude'},
    {value: 'Magna Cum Laude', label: 'Magna Cum Laude'},
    {value: 'Summa Cum Laude', label: 'Summa Cum Laude'},
  ]

  const genders = [
    {value: 'Femenino', label: 'Femenino'},
    {value: 'Masculino', label: 'Masculino'},
    {value: 'Otro', label: 'Otro'},
    {value: 'Prefiero no decirlo', label: 'Prefiero no decirlo'},
  ]

  const academicLevels = [
    {value: 'Técnico o tecnólogo - En curso', label: 'Técnico o tecnólogo - En curso'},
    {value: 'Técnico o tecnólogo - Finalizado', label: 'Técnico o tecnólogo - Finalizado'},
    {value: 'Pregrado - En curso', label: 'Pregrado - En curso'},
    {value: 'Pregrado - Finalizado', label: 'Pregrado - Finalizado'},
    {value: 'Bachiller', label: 'Bachiller'},
  ]

  return (
    <div className={'postulate-global-container'}>
      {popUp && lang && <Dialog
        style={{}}
        open={popUp}
        onClose={() => setPopUp(false)}
        // scroll={scroll}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title">{
          lang === 'es' ? 'Acepto los terminos y condiciones' : 'I accept the terms and conditions'
        } </DialogTitle>
        <DialogContent>
          <TermsAndConditions/>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => acceptTyC()}>
            {lang === 'es' ? 'Cerrar' : 'Close'}
          </Button>
        </DialogActions>
      </Dialog>}
      <div className={'postulate-header'}>
      </div>
      {/**/}
      <div className='postulate-firulete1'>
        <img src={Firulete1} alt={'firulete1'}/>
      </div>
      <div className='postulate-ring1'>
        <img src={RingIzq} alt={'ring-izq'}/>
      </div>
      <div className='postulate-firulete2'>
        <img src={Firulete2} alt={'firulete2'}/>
      </div>
      <div className='postulate-ring2'>
        <img src={RingDer} alt={'ring-der'}/>
      </div>
      <div className={'postulate-global-sub-container'}>
        <div className={'postulate-section-1-container'}>
          <div>
            <img src={LayerTop} alt={"layer-unilever-top"} className={'postulate-freelo'}/>
          </div>
          <div className='unilever-left-logo-container'>
            <div className='unilever-left-logo-container-1'>
              <img src={Logo} alt="logo-unilever" className="postulate-unilever-logo"/>
              <img src={LogoCoally} alt="logo-coally" className='pos tulate-unilever-logo'/>
            </div>
          </div>
        </div>
        <div className={'postulate-section-2-container'}>
          {/*form*/}
          <form className={'postulate-section-2-sub-container'} onSubmit={sendForm}>
            <h2 className={'postulate-section-2-sub-text'}>Nombres<span style={{color: '#FEC022'}}>*</span>
            </h2>
            <input className={'postulate-section-2-sub-input'} type={'text'} placeholder={'Nombres'}
                   value={form.name} onChange={(e) => setForm(
              {...form, name: e.target.value}
            )}/>
            <h2 className={'postulate-section-2-sub-text'}>Apellidos<span style={{color: '#FEC022'}}>*</span>
            </h2>
            <input className={'postulate-section-2-sub-input'} type={'text'} placeholder={'Apellidos'}
                   value={form.lastName} onChange={(e) => setForm(
              {...form, lastName: e.target.value}
            )}/>
            <h2 className={'postulate-section-2-sub-text'}>Fecha de nacimiento<span style={{color: '#FEC022'}}>*</span>
            </h2>
            <input className={'postulate-section-2-sub-input-date'} type={'date'} placeholder={'Nombres y apellidos'}
                   value={form.Date_Birth} onChange={
              (e) => setForm({...form, Date_Birth: e.target.value})
            }/>
            <h2 className={'postulate-section-2-sub-text'}>Correo electrónico<span style={{color: '#FEC022'}}>*</span>
            </h2>
            <input className={'postulate-section-2-sub-input'} type={'text'} placeholder={'Correo electrónico'}
                   value={form.email} onChange={(e) => setForm(
              {...form, email: e.target.value}
            )}/>
            <h2 className={'postulate-section-2-sub-text'}>Contraseña<span style={{color: '#FEC022'}}>*</span>
            </h2>
            <input className={'postulate-section-2-sub-input'} type={'password'} placeholder={'Contraseña'}
                   value={form.password} onChange={(e) => setForm(
              {...form, password: e.target.value}
            )}/>
            <h2 className={'postulate-section-2-sub-text'}>Celular<span style={{color: '#FEC022'}}>*</span></h2>
            <input className={'postulate-section-2-sub-input'} type={'number'} placeholder={'Celular'}
                   value={form.phone} onChange={(e) => setForm(
              {...form, phone: e.target.value}
            )}/>
            <h2 className={'postulate-section-2-sub-text'}>Ciudad<span style={{color: '#FEC022'}}>*</span></h2>
            <input className={'postulate-section-2-sub-input'} type={'text'} placeholder={'Ciudad'} value={form.city}
                   onChange={(e) => setForm(
                     {...form, city: e.target.value}
                   )}/>
            <h2 className={'postulate-section-2-sub-text'}>Dirección<span style={{color: '#FEC022'}}>*</span></h2>
            <input className={'postulate-section-2-sub-input'} type={'text'} placeholder={'Dirección'}
                   value={form.address} onChange={(e) => setForm(
              {...form, address: e.target.value}
            )}/>
            <h2 className={'postulate-section-2-sub-text'}>Género<span style={{color: '#FEC022'}}>*</span></h2>
            <Select
              options={genders}
              placeholder={'Seleccione su género'}
              isSearchable={true}
              isClearable={true}
              styles={customStyles}
              onChange={(e) => setForm({...form, gender: e === null ? '' : e.value})}
            />
            <h2 className={'postulate-section-2-sub-text'}>Institución educativa<span
              style={{color: '#FEC022'}}>*</span></h2>
            <Creatable
              options={universities}
              placeholder={'Escribe tu institución educativa'}
              isSearchable={true}
              isClearable={true}
              formatCreateLabel={(inputValue) => `Agregar "${inputValue}"`}
              styles={customStyles}
              onChange={(e) => setForm({...form, university: e === null ? '' : e.value})}
            />
            <h2 className={'postulate-section-2-sub-text'}>¿Cuál es tu carrera?<span
              style={{color: '#FEC022'}}>*</span></h2>
            <Creatable
              options={careers}
              placeholder={'Escribe tu carrera o programa técnico'}
              isSearchable={true}
              isClearable={true}
              formatCreateLabel={(inputValue) => `Agregar "${inputValue}"`}
              styles={customStyles}
              onChange={(e) => setForm({...form, careers: e === null ? '' : e.value})}
            />
            {/*             <h2 className={'postulate-section-2-sub-text'}>¿Hiciste parte de asociaciones, voluntariado, otro?</h2>
            <Creatable
              options={voluntaries}
              placeholder={'Selecciona o escribe tu respuesta'}
              isClearable={true}
              isSearchable={true}
              formatCreateLabel={(inputValue) => `Agregar "${inputValue}"`}
              styles={customStyles}
              onChange={(e) => setForm({...form, volunteering: e === null ? '' : e.value})}
            /> */}
            <h2 className={'postulate-section-2-sub-text'}>¿Has hecho algún proyecto extra curricular o trabajo previo,
              cuál?</h2>
            <div className='unilever-postulate-radio-section-container2'>
              <div className='unilever-postulate-radio-section-inner'>
                <input name="curricular" id="extracurricular" type="radio" className="css-checkbox"
                       onChange={() => setExtraCurricular(true)}/>
                <label htmlFor="extracurricular" style={{marginLeft: '10px'}}><span
                  style={{marginLeft: '10px'}}>Si</span></label>
              </div>
              <div className='unilever-postulate-radio-section-inner'>
                <input name="curricular" id="extracurricular1" type="radio" className="css-checkbox"
                       onChange={() => setExtraCurricular(false)}/>
                <label htmlFor="extracurricular1" style={{marginLeft: '10px'}}><span
                  style={{marginLeft: '10px'}}>No</span></label>
              </div>
            </div>
            {extraCurricular && <div style={{display: 'flex', flexDirection: 'column', marginBottom: '5%'}}>
              <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start'}}>
                <h2 className={'postulate-section-sub-text-add'}>Agrega tus respuestas</h2>
                <label className={'postulate-section-add-sub-button'} style={{marginLeft: '20px'}}
                       onClick={() => addExtraCurricular()}>Agregar</label>
              </div>
              {
                form.extracurricular.map((item, index) => {
                  return (
                    <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: '40px'}}
                         key={index}>
                      <input value={item} className={'postulate-section-sub-input-added'} type={'text'}
                             placeholder={'Escribe aquí'} onChange={e => handleChangeExtraCurricular(e, index)}/>
                      <span onClick={() => handleDeleteExtraCurricular(index)}
                            className='postulate-delete-button'>x</span>
                    </div>
                  )
                })
              }
            </div>}

            <h2 className={'postulate-section-2-sub-text'}>¿Cuál es tu ocupación hoy?<span
              style={{color: '#FEC022'}}>*</span></h2>
            <div className='unilever-postulate-radio-section-container2'>
              <div className='unilever-postulate-radio-section-inner'>
                <input name="ocupacion" id="demo_opt_1" type="radio" className="css-checkbox" onChange={() => {
                  setOccupation(false)
                  setForm(
                    {...form, occupation: 'Estoy estudiando'}
                  )
                }}/>
                <label htmlFor="demo_opt_1" style={{marginLeft: '10px'}}><span style={{marginLeft: '10px'}}>Estoy estudiando</span></label>
              </div>
              <div className='unilever-postulate-radio-section-inner'>
                <input name="ocupacion" id="demo_opt_4" type="radio" className="css-checkbox" onChange={() => {
                  setOccupation(false)
                  setForm(
                    {...form, occupation: 'Estoy trabajando actualmente', time_graduate: ''}
                  )
                }}/>
                <label htmlFor="demo_opt_4" style={{marginLeft: '10px'}}><span style={{marginLeft: '10px'}}>Estoy trabajando actualmente</span></label>
              </div>
              <div className='unilever-postulate-radio-section-inner'>
                <input name="ocupacion" id="demo_opt_3" type="radio" className="css-checkbox" onChange={() => {
                  setOccupation(false)
                  setForm(
                    {...form, occupation: 'Estoy estudiando y soy empleado', time_graduate: ''}
                  )
                }}/>
                <label htmlFor="demo_opt_3" style={{marginLeft: '10px'}}><span style={{marginLeft: '10px'}}>Estoy estudiando y soy empleado</span></label>
              </div>
              <div className='unilever-postulate-radio-section-inner'>
                <input name="ocupacion" id="demo_opt_2" type="radio" className="css-checkbox" onChange={() => {
                  setOccupation(false)
                  setForm(
                    {...form, occupation: 'Acabé mis estudios y estoy en búsqueda de trabajo', time_graduate: ''}
                  )
                }}/>
                <label htmlFor="demo_opt_2" style={{marginLeft: '10px'}}><span style={{marginLeft: '10px'}}>Acabé mis estudios y estoy en búsqueda de trabajo
            </span></label>
              </div>
              <div className='unilever-postulate-radio-section-inner'>
                <input name="ocupacion" id="demo_opt_6" type="radio" className="css-checkbox" onChange={() => {
                  setOccupation(false)
                  setForm(
                    {...form, occupation: 'Acabé mis estudios y tengo mi propio negocio', time_graduate: ''}
                  )
                }}/>
                <label htmlFor="demo_opt_6" style={{marginLeft: '10px'}}><span style={{marginLeft: '10px'}}>Acabé mis estudios y tengo mi propio negocio</span></label>
              </div>
              <div className='unilever-postulate-radio-section-inner'>
                <input name="ocupacion" id="demo_opt_5" type="radio" className="css-checkbox" onChange={() => {
                  setOccupation(!occupation)
                  setForm({...form, occupation: '', time_graduate: ''})
                }}/>
                <label htmlFor="demo_opt_5" style={{marginLeft: '10px'}}><span
                  style={{marginLeft: '10px'}}>Ninguna</span></label>
              </div>
            </div>
            <div className={'postulate-separator'}></div>
            {form.occupation === 'Estoy estudiando' &&
              <>
                <h2 className={'postulate-section-2-sub-text'}>¿Cuánto te falta para graduarte?<span
                  style={{color: '#FEC022'}}>*</span></h2>
                <input className={'postulate-section-2-sub-input'} type={'text'} placeholder={'Escribe aquí'}
                       onChange={(e) => setForm(
                         {...form, time_graduate: e.target.value}
                       )}/>
              </>
            }
            {occupation &&
              <>
                <h2 className={'postulate-section-2-sub-text'}>¿Cuál es tu ocupación?<span
                  style={{color: '#FEC022'}}>*</span></h2>
                <input className={'postulate-section-2-sub-input'} type={'text'} placeholder={'Escribe aquí'}
                       onChange={(e) => setForm(
                         {...form, occupation: e.target.value}
                       )}/>
              </>
            }
            <h2 className={'postulate-section-2-sub-text'}>¿Has recibido algún reconocimiento académico, social,
              deportivo etc?</h2>
            <div className='unilever-postulate-radio-section-container2'>
              <div className='unilever-postulate-radio-section-inner'>
                <input name="Honores" id="honor" type="radio" className="css-checkbox"
                       onChange={() => setHonores(true)}/>
                <label htmlFor="honor" style={{marginLeft: '10px'}}><span style={{marginLeft: '10px'}}>Si</span></label>
              </div>
              <div className='unilever-postulate-radio-section-inner'>
                <input name="Honores" id="honor1" type="radio" className="css-checkbox"
                       onChange={() => setHonores(false)}/>
                <label htmlFor="honor1" style={{marginLeft: '10px'}}><span
                  style={{marginLeft: '10px'}}>No</span></label>
              </div>
            </div>
            {honores && <div style={{display: 'flex', flexDirection: 'column', marginBottom: '25px'}}>
              <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start'}}>
                <h2 className={'postulate-section-sub-text-add'}>Agrega tus respuestas<span
                  style={{color: '#FEC022'}}>*</span></h2>
                <label className={'postulate-section-add-sub-button'} style={{marginLeft: '20px'}}
                       onClick={() => addHonores()}>Agregar</label>
              </div>
              {
                form.Honor.map((item, index) => {
                  return (
                    <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: '40px'}}
                         key={index}>
                      <input value={item} className={'postulate-section-sub-input-added'} type={'text'}
                             placeholder={'Escribe aquí'} onChange={e => handleChangeHonores(e, index)}/>
                      <span onClick={() => handleDeleteHonores(index)}
                            className='postulate-delete-button'>x</span>
                    </div>
                  )
                })
              }
            </div>}
            <h2 className={'postulate-section-2-sub-text'}>¿En qué área te gustaría desarrollarte o cuál es tu área de
              interés?<span
                style={{color: '#FEC022'}}>*</span></h2>
            <div className='unilever-postulate-radio-section-container2'>
              <div className='unilever-postulate-radio-section-inner'>
                <input name="interestsector" id="emprendimiento" type="radio" className="css-checkbox" onChange={() => {
                  setOccupation(false)
                  setForm(
                    {...form, sector_area: 'Emprendimiento'}
                  )
                }}/>
                <label htmlFor="emprendimiento" style={{marginLeft: '10px'}}><span
                  style={{marginLeft: '10px'}}>Emprendimiento</span></label>
              </div>
              <div className='unilever-postulate-radio-section-inner'>
                <input name="interestsector" id="emprendimiento1" type="radio" className="css-checkbox"
                       onChange={() => {
                         setOccupation(false)
                         setForm(
                           {...form, sector_area: 'Empleabilidad'}
                         )
                       }}/>
                <label htmlFor="emprendimiento1" style={{marginLeft: '10px'}}><span
                  style={{marginLeft: '10px'}}>Empleabilidad</span></label>
              </div>
            </div>
            <h2 className={'postulate-section-2-sub-text'}>¿Cuál es el nivel académico que tienes actualmente?<span
              style={{color: '#FEC022'}}>*</span>
            </h2>
            <Select
              options={academicLevels}
              placeholder={'Seleccione su nivel academico'}
              styles={customStyles}
              onChange={e => setForm({...form, academic_level: e === null ? '' : e.value})}
            />
            <h2 className={'postulate-section-2-sub-text'}>¿Cuáles son las habilidades que te caracterizan?<span
              style={{color: '#FEC022'}}>*</span>
            </h2>
            <Creatable
              options={habilities}
              placeholder={'Seleccione una o mas habilidades'}
              isMulti
              formatCreateLabel={(inputValue) => `Agregar "${inputValue}"`}
              styles={customStyles}
              onChange={e => setForm({...form, skills_personal: e === null ? [] : e.map(item => item.value)})}
            />
            <h2 className={'postulate-section-2-sub-text'}>¿Qué habilidades te gustaría desarrollar en este
              programa?<span
                style={{color: '#FEC022'}}>*</span></h2>
            <Creatable
              options={habilitiesToImprove}
              placeholder={'Seleccione una o mas habilidades'}
              isMulti
              formatCreateLabel={(inputValue) => `Agregar "${inputValue}"`}
              styles={customStyles}
              onChange={e => setForm({...form, skills: e === null ? [] : e.map(item => item.value)})}
            />
            <h2 className={'postulate-section-2-sub-text'}>¿Área de interés?<span style={{color: '#FEC022'}}>*</span>
            </h2>
            <Select
              options={interestAreas}
              placeholder={'Seleccione su área de interés'}
              isSearchable={true}
              isClearable={true}
              styles={customStyles}
              onChange={(e) => setForm({...form, interest: e === null ? '' : e.value})}
            />
            <h2 className={'postulate-section-2-sub-text'}>¿Cuáles son tus objetivos a largo plazo?<span
              style={{color: '#FEC022'}}>*</span></h2>
            <Select
              options={longTermGoals}
              placeholder={'Seleccione uno o más objetivos'}
              isMulti
              styles={customStyles}
              onChange={e => setForm({...form, objetives: e === null ? [] : e.map(item => item.value)})}
            />
            <h2 className={'postulate-section-2-sub-text'}>¿Por qué quisieras ser parte de este programa?<span
              style={{color: '#FEC022'}}>*</span></h2>
            <textarea className={'postulate-section-2-sub-input-textarea'} placeholder={'Escribe aquí'}
                   onChange={(e) => setForm(
                     {...form, part_program: e.target.value}
                   )}/>
            <h2 className={'postulate-section-2-sub-text'}>¿Has hecho trabajo comunitario?</h2>
            <div className='unilever-postulate-radio-section-container2'>
              <div className='unilever-postulate-radio-section-inner'>
                <input name="comunitario" id="comunitario" type="radio" className="css-checkbox"
                       onChange={() => setVolunteering(true)}/>
                <label htmlFor="comunitario" style={{marginLeft: '10px'}}><span
                  style={{marginLeft: '10px'}}>Si</span></label>
              </div>
              <div className='unilever-postulate-radio-section-inner'>
                <input name="comunitario" id="comunitario1" type="radio" className="css-checkbox"
                       onChange={() => setVolunteering(false)}/>
                <label htmlFor="comunitario1" style={{marginLeft: '10px'}}><span style={{marginLeft: '10px'}}>No</span></label>
              </div>
            </div>
            {
              volunteering && <>
                <h2 className={'postulate-section-2-sub-text'}>¿Cuál fue tu trabajo comunitario?</h2>
                  <textarea className={'postulate-section-2-sub-input-textarea'}  placeholder={'Escribe aquí'}
                       onChange={(e) => setForm(
                         {...form, cominitary_work: e.target.value}
                       )}/>
              </>
            }
            <h2 className={'postulate-section-2-sub-text'}>¿Qué te motiva realizar este programa?<span
              style={{color: '#FEC022'}}>*</span></h2>
            <textarea className={'postulate-section-2-sub-input-textarea'} type={'text'} placeholder={'Escribe aquí'}
                      onChange={(e) => setForm(
                        {...form, motivational_question: e.target.value}
                      )}/>
            <h2 className={'postulate-section-2-sub-text'}>Te identificas con alguna de estas afirmaciones:</h2>
            <div className='unilever-postulate-radio-section-container2'>
              <div className='unilever-postulate-radio-section-inner'>
                <input name="auto" id="nivel3" type="radio" className="css-checkbox" onChange={() => setForm(
                  {...form, social_level: 'Vivo en estrato 1, 2 o 3'}
                )}/>
                <label htmlFor="nivel3" style={{marginLeft: '10px'}}><span style={{marginLeft: '10px'}}>Vivo en estrato 1, 2 o 3</span></label>
              </div>
              <div className='unilever-postulate-radio-section-inner'>
                <input name="auto" id="nivel2" type="radio" className="css-checkbox" onChange={() => setForm(
                  {...form, social_level: 'Sisbén igual o menor a C'}
                )}/>
                <label htmlFor="nivel2" style={{marginLeft: '10px'}}><span style={{marginLeft: '10px'}}>Tener un puntaje Sisbén igual o menor a C</span></label>
              </div>
              <div className='unilever-postulate-radio-section-inner'>
                <input name="auto" id="nivel1" type="radio" className="css-checkbox" onChange={() => setForm(
                  {...form, social_level: 'población indígena, afro, raizal o rrom'}
                )}/>
                <label htmlFor="nivel1" style={{marginLeft: '10px'}}><span style={{marginLeft: '10px'}}>Me autorreconozco como: Indígena, Afro, Palenquera, Raizal o Rrom</span></label>
              </div>

              <div className='unilever-postulate-radio-section-inner'>
                <input name="auto" id="nivel4" type="radio" className="css-checkbox" onChange={() => setForm(
                  {...form, social_level: 'Ninguno'}
                )}/>
                <label htmlFor="nivel4" style={{marginLeft: '10px'}}><span
                  style={{marginLeft: '10px'}}>Ninguno</span></label>
              </div>
              <div>
                <label style={{color: 'white', marginBottom: '10px'}}>
                  <input value={tyc} onChange={() => {
                    setTyc(!tyc)
                  }} type={'checkbox'}/>
                  <span style={{marginLeft: '15px'}}>He leído y acepto los <span onClick={() => setPopUp(true)} style={{
                    color: '#FEC022',
                    fontWeight: 'bold',
                    cursor: 'pointer'
                  }}>términos
                  y condiciones.</span></span>
                </label>
              </div>
            </div>
            <button type={'submit'} className={'postulate-section-2-sub-button'}>Enviar</button>
          </form>
        </div>
        <div className={'postulate-section-3-global-container'}>
          <div className={'postulate-section-3-container'}>
            <div className={'postulate-section-3-sub-sub-container-2'}>
              <h3 className={'postulate-section-3-text'}>Síguenos en </h3>
            </div>
            <div className={'postulate-section-3-sub-container'}>
              <a href="https://www.linkedin.com/company/unilever/" target="_blank" rel="noopener noreferrer">
                <button className={'postulate-section-3-sub-sub-container'}>
                  <FaLinkedinIn className={'postulate-section-3-social'}/>
                </button>
              </a>
              <a href="https://www.facebook.com/unilever/" target="_blank" rel="noopener noreferrer">
                <button className={'postulate-section-3-sub-sub-container'}>
                  <FaFacebookF className={'postulate-section-3-social'}/>
                </button>
              </a>
              <a href="https://www.instagram.com/unilevercolombia/" target="_blank" rel="noopener noreferrer">
                <button className={'postulate-section-3-sub-sub-container'}>
                  <FaInstagram className={'postulate-section-3-social'}/>
                </button>
              </a>
            </div>
          </div>
        </div>
      </div>
      {/**/}
      <div className={'postulate-footer'}>
        <h3 className={'postulate-header-text'} style={{margin: 0}}>© 2022 Coally - All rights reserved</h3>
      </div>
    </div>
  );
}
