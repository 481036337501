const careers = [
  {
   "value": "Ingeniería Industrial",
   "label": "Ingeniería Industrial"
  },
  {
   "value": "Ingeniería Mecánica",
   "label": "Ingeniería Mecánica"
  },
  {
   "value": "Ingeniería Robótica y Mecatrónica",
   "label": "Ingeniería Robótica y Mecatrónica"
  },
  {
   "value": "Ingeniería de Sistemas",
   "label": "Ingeniería de Sistemas"
  },
  {
   "value": "Ingeniería Civil",
   "label": "Ingeniería Civil"
  },
  {
   "value": "Ingeniería Informática",
   "label": "Ingeniería Informática"
  },
  {
   "value": "Ingeniería Electrónica",
   "label": "Ingeniería Electrónica"
  },
  {
   "value": "Ingeniería Ambiental o Medio Ambiental",
   "label": "Ingeniería Ambiental o Medio Ambiental"
  },
  {
   "value": "Ingeniería en Telecomunicaciones",
   "label": "Ingeniería en Telecomunicaciones"
  },
  {
   "value": "Ingeniería Agropecuaria",
   "label": "Ingeniería Agropecuaria"
  },
  {
   "value": "Ingeniería de Minas",
   "label": "Ingeniería de Minas"
  },
  {
   "value": "Ingeniería de Software",
   "label": "Ingeniería de Software"
  },
  {
   "value": "Ingeniería Química",
   "label": "Ingeniería Química"
  },
  {
   "value": "Ingeniería Geomática",
   "label": "Ingeniería Geomática"
  },
  {
   "value": "Ingeniería en Sonido",
   "label": "Ingeniería en Sonido"
  },
  {
   "value": "Ingeniería Hidráulica",
   "label": "Ingeniería Hidráulica"
  },
  {
   "value": "Ingeniería Metalúrgica",
   "label": "Ingeniería Metalúrgica"
  },
  {
   "value": "Ingeniería Física",
   "label": "Ingeniería Física"
  },
  {
   "value": "Ingeniería Zootecnista",
   "label": "Ingeniería Zootecnista"
  },
  {
   "value": "Ingeniería Naval",
   "label": "Ingeniería Naval"
  },
  {
   "value": "Ingeniería Biomédica",
   "label": "Ingeniería Biomédica"
  },
  {
   "value": "Ingeniería Forestal",
   "label": "Ingeniería Forestal"
  },
  {
   "value": "Ingeniería Petrolera",
   "label": "Ingeniería Petrolera"
  },
  {
   "value": "Ingeniería de Producción por Ciclos Propedéuticos",
   "label": "Ingeniería de Producción por Ciclos Propedéuticos"
  },
  {
   "value": "Ingeniería Agroforestal",
   "label": "Ingeniería Agroforestal"
  },
  {
   "value": "Ingeniería de Producción",
   "label": "Ingeniería de Producción"
  },
  {
   "value": "Ingeniería Eléctrica",
   "label": "Ingeniería Eléctrica"
  },
  {
   "value": "Ingeniería Electromecánica",
   "label": "Ingeniería Electromecánica"
  },
  {
   "value": "Ingeniería de Materiales",
   "label": "Ingeniería de Materiales"
  },
  {
   "value": "Ingeniería en Ecología",
   "label": "Ingeniería en Ecología"
  },
  {
   "value": "Ingeniería Aeronáutica",
   "label": "Ingeniería Aeronáutica"
  },
  {
   "value": "Ingeniería de Fabricación",
   "label": "Ingeniería de Fabricación"
  },
  {
   "value": "Bioingeniería",
   "label": "Bioingeniería"
  },
  {
   "value": "Ingeniería Pesquera",
   "label": "Ingeniería Pesquera"
  },
  {
   "value": "Ingeniería de Agrimensura",
   "label": "Ingeniería de Agrimensura"
  },
  {
   "value": "Medicina general",
   "label": "Medicina general"
  },
  {
   "value": "Enfermería",
   "label": "Enfermería"
  },
  {
   "value": "Biomedicina",
   "label": "Biomedicina"
  },
  {
   "value": "Odontología",
   "label": "Odontología"
  },
  {
   "value": "Oftalmología",
   "label": "Oftalmología"
  },
  {
   "value": "Farmacia",
   "label": "Farmacia"
  },
  {
   "value": "Bacteriología y laboratorio clínico",
   "label": "Bacteriología y laboratorio clínico"
  },
  {
   "value": "Optometría",
   "label": "Optometría"
  },
  {
   "value": "Psiquiatría",
   "label": "Psiquiatría"
  },
  {
   "value": "Paramédico",
   "label": "Paramédico"
  },
  {
   "value": "Nutrición y dietetica",
   "label": "Nutrición y dietetica"
  },
  {
   "value": "Fisioterapia o Terapia física",
   "label": "Fisioterapia o Terapia física"
  },
  {
   "value": "Quiropráctica",
   "label": "Quiropráctica"
  },
  {
   "value": "Genética",
   "label": "Genética"
  },
  {
   "value": "Atención prehospitalaria",
   "label": "Atención prehospitalaria"
  },
  {
   "value": "Terapia respiratoria",
   "label": "Terapia respiratoria"
  },
  {
   "value": "Salud ocupacional",
   "label": "Salud ocupacional"
  },
  {
   "value": "Arquitecto paisajista",
   "label": "Arquitecto paisajista"
  },
  {
   "value": "Planificador urbano",
   "label": "Planificador urbano"
  },
  {
   "value": "Arquitecto de restauración",
   "label": "Arquitecto de restauración"
  },
  {
   "value": "Arquitecto de investigación",
   "label": "Arquitecto de investigación"
  },
  {
   "value": "Arquitecto de iluminación",
   "label": "Arquitecto de iluminación"
  },
  {
   "value": "Arquitecto político",
   "label": "Arquitecto político"
  },
  {
   "value": "Arquitecto extremo",
   "label": "Arquitecto extremo"
  },
  {
   "value": "Diseño industrial",
   "label": "Diseño industrial"
  },
  {
   "value": "Diseñador gráfico",
   "label": "Diseñador gráfico"
  },
  {
   "value": "Diseñador de videojuegos",
   "label": "Diseñador de videojuegos"
  },
  {
   "value": "Diseño textiles",
   "label": "Diseño textiles"
  },
  {
   "value": "Diseño de muebles",
   "label": "Diseño de muebles"
  },
  {
   "value": "Diseño publicitario",
   "label": "Diseño publicitario"
  },
  {
   "value": "Arte contemporáneo",
   "label": "Arte contemporáneo"
  },
  {
   "value": "Diseño naval",
   "label": "Diseño naval"
  },
  {
   "value": "De interiores",
   "label": "De interiores"
  },
  {
   "value": "De marcas",
   "label": "De marcas"
  },
  {
   "value": "Diseño y desarrollo web",
   "label": "Diseño y desarrollo web"
  },
  {
   "value": "De moda",
   "label": "De moda"
  },
  {
   "value": "Diseño y decoración",
   "label": "Diseño y decoración"
  },
  {
   "value": "Otros",
   "label": "Otros"
  },
  {
   "value": "Administración de Empresas",
   "label": "Administración de Empresas"
  },
  {
   "value": "Administración en Recursos Humanos.",
   "label": "Administración en Recursos Humanos."
  },
  {
   "value": "Administración y Finanzas",
   "label": "Administración y Finanzas"
  },
  {
   "value": "Administración en Salud Ocupacional",
   "label": "Administración en Salud Ocupacional"
  },
  {
   "value": "Administración Comercial y de Mercadeo",
   "label": "Administración Comercial y de Mercadeo"
  },
  {
   "value": "Administración en Mercadeo",
   "label": "Administración en Mercadeo"
  },
  {
   "value": "Administración de Logística Internacionales",
   "label": "Administración de Logística Internacionales"
  },
  {
   "value": "Administración de Negocios",
   "label": "Administración de Negocios"
  },
  {
   "value": "Administración Financiera",
   "label": "Administración Financiera"
  },
  {
   "value": "Negocios internacionales",
   "label": "Negocios internacionales"
  }
 ]

 export default careers