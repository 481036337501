import styled from "styled-components";
import { MdOutlinePlace } from "react-icons/md";
import { Link } from "react-router-dom";
const GridCardContainer = styled.div`
  background: #ffffff;
  width: 350px;
  box-shadow: 0 7px 32px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  height: 350px;
  margin: 22px;

  @media (max-width: 725px) {
    margin: auto;
  }
`;

const GridReusableContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
  margin-top: 25px;
`;

const GridPlaceContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  text-align: center;
  margin-left: 25px;
`;

const GridCardImageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
`;

const GridCardImage = styled.img`
  object-fit: cover;
  height: 55px;
  width: 60px;
  border-radius: 8px;
`;

const GridCardLinkText = styled(Link)`
  width: 80%;
  cursor: pointer;
`;

const GridCardTitleContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

const GridCardTitle = styled.h1<{ color?: string }>`
  font-size: 18px;
  font-weight: 700;
  color: ${({ color }) => (color ? color : "#393939")};
  text-align: left;
  width: 100%;
  text-transform: capitalize;
  margin-right: 20px;
`;

const PlaceIcon = styled(MdOutlinePlace)<{ color?: string }>`
  font-size: 20px;
  color: ${({ color }) => (color ? color : "#C5C5C5")};
`;

const GridPlaceTextContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 90%;
  height: 100%;
  color: #5d5d5d;
  font-size: 16px;
  margin-left: 15px;
`;

const PercentageContainer = styled.div<{ color?: string }>`
  color: ${({ color }) => (color ? color : "#F07130")};
  margin-top: 10px;
  margin-left: 25px;
`;

const GridCardMiddleContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 38px;
  margin-top: 15px;
  margin-left: 25px;
`;

const GridCardDescriptionContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin-bottom: 15px;
  word-wrap: break-word;
  padding-right: 40px;
  font-size: 14px;
  color: #5d5d5d;
  text-align: left;
  font-weight: 400;
  height: 50px;
`;

const GridCardDescription = styled.p`
  font-weight: 400;
  color: #5d5d5d;
  text-align: left;
  margin: 0;
  padding: 0;
`;

const GridCardAdditionalInfoContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
`;

const GridCardStopInfo = styled.h3`
  margin-left: 5px;
  margin-right: 5px;
  color: #989898;
  margin-bottom: 0;
`;

const GridCardBottomContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin-bottom: 15px;
`;

const GridCardSalaryContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;

const SalaryText = styled.p<{
  color?: string;
  size?: string;
  weight?: string;
  margin?: string;
}>`
  color: ${({ color }) => (color ? color : "#5D5D5D")};
  font-size: ${({ size }) => (size ? size : "16px")};
  font-weight: ${({ weight }) => (weight ? weight : "400")};
  margin-top: ${({ margin }) => (margin ? margin : "0")};
`;

const TimeSpan = styled.span`
  font-size: 14px;
  font-weight: 700;
  color: #c3ccff;
`;

const ApplyContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 40px;
`;

export {
  GridCardContainer,
  GridReusableContainer,
  GridCardImageContainer,
  GridCardImage,
  PlaceIcon,
  GridCardLinkText,
  GridCardTitleContainer,
  GridCardTitle,
  GridPlaceTextContainer,
  PercentageContainer,
  GridCardMiddleContainer,
  GridCardDescriptionContainer,
  GridCardDescription,
  GridCardAdditionalInfoContainer,
  GridCardStopInfo,
  GridCardBottomContainer,
  GridCardSalaryContainer,
  SalaryText,
  TimeSpan,
  ApplyContainer,
  GridPlaceContainer,
};
