import  { useState, useEffect } from "react";
import { connect } from "react-redux";
import HeaderEmpresa from "../../components/HeaderEmpresa/HeaderEmpresa";
import "./Styles/CompanyForm.css";
import Popup from "../../components/popUp/Modal.js"
import * as React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import StepContent from "@material-ui/core/StepContent";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Select from "@material-ui/core/Select";
import { formCompany , ConsultorData1} from "../../actions"
import en from "../../lang/en-US";
import es from "../../lang/es-COL"
import counterpart from "counterpart";
import Translate from "react-translate-component";

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 10000,
  },
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  actionsContainer: {
    marginBottom: theme.spacing(2),
  },
  resetContainer: {
    padding: theme.spacing(3),
  },
  circle: {
    backgroundImage:
      "linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)",
  },
}));

const TiempoMercado = [
  "Menos de 1 año",
  "De 1 a 3 años",
  "Mas de 3 años",
];

const seleccionTiempoMercado = (
  <Select multiple native  name="tiempo_mercado">
    {TiempoMercado.map((causa) => (
      <option key={causa} value={causa} name={"Causa"} class={'option-btn'}>
        {causa}
      </option>
    ))}
  </Select>
);

const Idiomas = [ 
  "Ingles",
  "Chino",
  "Portugues",
  "Español",
];

const seleccionIdiomasocio = (
  <Select multiple native name="idioma">
    {Idiomas.map((linea) => (
      <option key={linea} value={linea} class={'option-btn'}>
        {linea}
      </option>
    ))}
  </Select>
);

const Sector = [
  "Finanzas",
  "Negociación y liderazgo",
  "Ingenierias",
  "Planeación y modelaje de negocios",
  "Impacto social y sostenibilidad",
  "Recursos Humanos",
  "Estrategia",
  "Legal",
  "Marketing y ventas",
];

const seleccionconocimientos = (
  <Select multiple native name="sector">
    {Sector.map((conocimientos) => (
      <option key={conocimientos} value={conocimientos} class={'option-btn'}>
        {conocimientos}
      </option>
    ))}
  </Select>
);

const Tamaño = ["0-10", "10-20", "20+"];

const seleccionTamaño = (
  <Select multiple native name="tamaño">
    {Tamaño.map((ingles) => (
      <option key={ingles} value={ingles} class={'option-btn'}>
        {ingles}
      </option>
    ))}
  </Select>
);

counterpart.registerTranslations('en',en) 
    counterpart.registerTranslations('es',es)
    counterpart.setLocale(localStorage.getItem('langlocal'));

const steps = [
  {
    label: <Translate content="Nombredelaempresa" component="span"/>,
    tittle :"Este dato sera utilizado en el contrato y demas documentos legales",
    description: (
      <TextField
      name="nombre_empresa"
      id="standard-basic"
      label="Nombre empresa"
      variant="standard"
      />
      ),
    },
    {
      label: <Translate content="Nombredelrepresentantelegal" component="span"/>,
      tittle :"Este dato sera utilizado en el contrato y demas documentos legales",
      description: (
        <TextField
        name="rep_legal"
        id="standard-basic"
        label="Nombre del representante legal"
        variant="standard"
        />
        ),
      }, 
      {
        label: <Translate content="Numerodeceduladelrepresentantelegal" component="span"/>,
        tittle :"Este dato sera utilizado en el contrato y demas documentos legales",
        description: (
          <TextField
          name="cedula_rep"
          id="standard-basic"
          label="Numero de cedula del resentante legal"
          variant="standard"
          />
          ),
        }, 
        {
          label: <Translate content="Ciudaddeexpediciondelaceduladelrepresentantelegal" component="span"/>,
          tittle :"Este dato sera utilizado en el contrato y demas documentos legales",
          description: (
            <TextField
            name="ciudad_expedicion_rep"
            id="standard-basic"
            label="Ciudad de expedicion de la cedula del representante legal"
            variant="standard"
            />
            ),
          }, 
  {
    label: "NIT",
    tittle :"Ingrese el NIT/ID de la empresa",
    description: (
      <TextField
        name="nit"
        id="standard-basic"
        label="Nit"
        variant="standard"
      />
    ),
  },
  {
    label: <Translate content="Sitioweb" component="span"/>,
    tittle :"Compartenos el link de la pagina web de la empresa",
    description: (
      <TextField
        id="standard-basic"
        name="sitio_web"
        label="Sitio web"
        variant="standard"
      />
    ),
  },
  {
    label: <Translate content="Telefono" component="span"/>,
    tittle :"Telefono de la empresa",
    description: (
      <TextField
        id="standard-basic"
        name="telefono"
        type="number"
        label="telefono"
        variant="standard"
      />
    ),
  },
  {
    label: <Translate content="EMAIL" component="span"/>,
    tittle :"Email de la empresa",
    description: (
      <TextField
        id="standard-basic"
        name="email"
        label="Email"
        variant="standard"
      />
    ),
  },
  
  {
    label:<Translate content="Ubicación" component="span"/>,
    tittle :"Ubicación de la empresa",
    description: (
      <TextField
        id="standard-basic"
        name="ubicacion"
        label="Ubicacion"
        variant="standard"
      />
    ),
  },
  {
    label: <Translate content="Tiempoenelmercado" component="span"/>,
    tittle:"¿Cuanto tiempo lleva la empresa en el mercado?",
    description: seleccionTiempoMercado,
  },
  {
    label: <Translate content="IDIOMA" component="span"/>,
    tittle :"¿Que idioma se maneja en la empresa?",
    description: seleccionIdiomasocio,
  },
  {
    label: <Translate content="Sector" component="span"/>,
    tittle :"¿En que mercado esta la empresa?",
    description: seleccionconocimientos,
  },
  {
    label: <Translate content="Tamañodelaempresa" component="span"/>,
    tittle :"Numero de empleados",
    description: seleccionTamaño,
  },
  {
    label: <Translate content="Descripciondelaempresa" component="span"/>,
    tittle :"Una breve descripcion de la empresa",
    description: (
      <TextField
        id="standard-basic"
        name="descripcion"
        label="Descripcion"
        variant="standard"
      />
    ),
  },

];


function CompanyForm(props) {
  //setting th e state
  const [form, setValues] = useState({
    usuario : props.user.id,
    nit: 0,
    sector: "",
    nombre_empresa:"",
    rep_legal:"",
    cedula_rep:"",
    ciudad_expedicion_rep:"",
    direccion:"",
    ubicacion: "",
    descripcion:"",
    email:"",
    telefono:0,
    tiempo_mercado:"",
    idioma : "",
    sitio_web : "",
    tamaño : "",
   });


  //form handles

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };
  const handleInput = (event) => {
    if (event.target.options) {
      console.log(event.target.name);
      setValues({
        ...form,
        [event.target.name]:
          event.target.options[event.target.options.selectedIndex].value,
      });
    } else {
      setValues({
        ...form,
        [event.target.name]: event.target.value,
      });
    }
  };

  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);  
  const [buttonPopup, setButtonPopup] = useState(false);

 
  const handleSubmit = (event) => {
    event.preventDefault();
    console.log(form)
    props.formCompany(form, "/company/profile");
  };

  useEffect(() => {
    const getUser=()=>{
      console.log(props.user.id)
        props.ConsultorData1(props.user.id)
      
    }
    if(props.userform){
      window.location.href  = '/company/profile'
    }
    // eslint-disable-next-line
    getUser();  
    // eslint-disable-next-line
  }, []); 


  return (
    <>
    <HeaderEmpresa/>
    <div className="section-tittle-out-form">
        <h2 className="tittle-out-form">{<Translate content="Formularioderegistro" component="span"/>}</h2>
      </div>
      <section className="CompanyForm">
        <section className="CompanyForm-container">    
          <p className="CompanyForm-tittle">{<Translate content="Completaelformulario" component="span"/>}</p>
          <div className={classes.root}>
          <Stepper activeStep={activeStep} orientation="vertical">
            {steps.map((step, index) => (
              <Step key={step.label}>
                <StepLabel>{step.label}</StepLabel>
                <StepContent>
                <p className="step-tittle">{step.tittle || <Translate content="Title" component="span"/>}</p>
                  <Typography
                    onChange={(e) => {
                      handleInput(e);
                    }}
                  >
                    {step.description}
                  </Typography>
                  <div className={classes.actionsContainer}>
                    <div>
                      <Button
                        disabled={index === 0}
                        onClick={handleBack}
                        className={classes.button}
                      >
                      {<Translate content="atras" component="span"/>}
                      </Button>
                      <Button
                        variant="contained"
                        onClick={handleNext}
                        className={classes.button}
                      >
                        {index === steps.length - 1 ? <Translate content="FINALIZAR" component="span"/> : <Translate content="SIGUIENTE" component="span"/>}
                      </Button>
                    </div>
                  </div>
                </StepContent>
                {index === steps.length - 1 ? (
              
                    <button className="CompanyForm-button-submit" onClick={(e) => {
                    setButtonPopup(true);
                    handleSubmit(e);
                   }}>{<Translate content="Enviar" component="span"/>}</button>
                ) : null}
              </Step>
            ))}
          </Stepper>
          {activeStep === steps.length && (
            <Paper
              square
              elevation={0}
              className={classes.resetContainer}
            ></Paper>
          )}
        </div>
            <Popup trigger={buttonPopup} setTrigger={setButtonPopup}>
              <h2 className="tittle-popup-apply">{<Translate content="Registrofinalizadoconexito" component="span"/>}</h2>
            </Popup>
    
          </section>
      </section>
    </>
  );
}
const mapDispatchToProps = {
  formCompany,
  ConsultorData1
};

const mapStateToProps = (state) => {
  return {
    user: state.user,
    projects : state.projects,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CompanyForm);
