import React, {useContext, useState} from 'react';
import {useSelector} from "react-redux";
import './SidebarCv.css'
import Modal from "@mui/material/Modal";
import {Box} from "@mui/material";
import close from "../../../../assets/icons/close.svg";
import Share from '../../../../assets/icons/share.svg'
import {Link} from "react-router-dom";
import axios from "axios";
import { toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { UniversityContext } from '../../../context/UniversitiesContext';

export default function SidebarCv() {
  const {lang, user, cv} = useSelector(state => state)
  const [open, setOpen] = useState(false)
  const [selectedFile, setSelectedFile] = useState()
  //eslint-disable-next-line no-unused-vars
  const [isFilePicked, setIsFilePicked] = useState(false);
  const [message, setMessage] = useState(false)
  const { university } = useContext(UniversityContext);
  const cvButton = () => {
    setOpen(true)
  }

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '100%',
    maxWidth: '722px',
    maxHeight: '672px',
    bgcolor: '#FFFFFF',
    borderRadius: '8px',
    borderColor: '#FFFFFF',
    boxShadow: '0px 7px 32px rgba(0, 0, 0, 0.1)',
    transition: 'all 0.3s ease',
    overflow: 'auto',
    margin: 0,
    padding: 0,
  };

  const changeHandler = (e) => {
    setSelectedFile(e.target.files[0])
    const fileData = new FormData();
    fileData.append("upload_file", e.target.files[0]);
    fileData.append("usuario", user.id);
    axios.post(`https://findally-backend.herokuapp.com/api/user/validateCV?lang=${lang}`, fileData)
      .then((res) => {
        if (res.status === 200) {
          toast.success(res.data.message,{
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          return setIsFilePicked(true)
        }
      }).catch((err) => {
      if (err.response.status === 400) {
        setSelectedFile(null)
        setMessage(true)
      }
    })
  }

  const handleUpload = (e) => {
    if (cv) {
      setIsFilePicked(false);
      return toast.success('Ya tienes un CV subido',{
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
    }
    const fd = new FormData();

    fd.append("upload_file", selectedFile);
    fd.append("usuario", user.id);

    axios.post("https://findally-backend.herokuapp.com/api/user/readCv", fd)
      .then((res) => {
        if (res.status === 200) {
          window.location.reload();
          return setOpen(false)
        }
      })
    return toast.success('CV subido con éxito',{
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    })
  };

  return (
    <>
    {/* <ToastContainer/> */}
      <Modal open={open} onClose={() => setOpen(false)}>
        <Box sx={style}>
          <div style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'space-between',
            width: '100%',
            flexDirection: 'column',
          }}>
            <div className={'SidebarCV-cont'}>
              <button style={{backgroundColor: 'transparent'}} onClick={() => setOpen(false)}>
                <img src={close} alt="close"/>
              </button>
              <div className={'SidebarCV-Sub-Cont'}>
                {!selectedFile
                  ? <label className={!message ? 'SidebarCV-LI' : 'SidebarCV-LI-Err'}>
                    {lang === 'es' ? 'Subir CV LinkedIn' : 'Upload LinkedIn CV'}
                    <img src={Share} alt="share"/>
                    <input onChange={(e) => changeHandler(e)} type="file" id="fileLi" style={{display: 'none'}}/>
                  </label>
                  : <label onClick={() => setSelectedFile('')} className={'SidebarCV-LI-2'}>
                    <img src={close} alt="share"/>
                    {lang === 'es' ? 'PDF Doc...' : 'PDF Doc...'}
                  </label>
                }
                {!selectedFile ? <label className={'SidebarCV-CV-disabled'}>
                  {lang === 'es' ? 'Subir CV ' : 'Upload CV'}
                  <img src={Share} alt="share"/>
                  {/*<input type="file" id="fileCv" style={{display: 'none'}}/>*/}
                </label> : <label onClick={(e) => handleUpload(e)} className={'SidebarCV-CV'}>
                  {lang === 'es' ? 'Subir CV ' : 'Upload CV'}
                  <img src={Share} alt="share"/>
                  {/*<input type="file" id="fileCv" style={{display: 'none'}}/>*/}
                </label>}
              </div>
              <div className={'SidebarCV-Sub-Cont-2'}>
                {
                  message ? <h2 className={'SidebarCV-Err'}>{lang === 'es'
                    ? 'PDF descargado de LinkedIn. Si no sabes como hacerlo, mira el siguiente '
                    : 'PDF downloaded from LinkedIn. If you don\'t know how to do it, look at the following'}<Link
                    style={{color: '#202256'}}>video</Link></h2> : <h2
                    className={'SidebarCV-Err'}>{lang === 'es' ? 'PDF descargado de LinkedIn' : 'PDF downloaded from LinkedIn'}</h2>
                }
                <hr style={{width: '80%'}}/>
                <h2
                  className={'SidebarCV-Sub-Text'}>{lang === 'es' ? 'Si no tienes Hoja de vida, puedes llenar el' : 'If you don\'t have a resume, you can fill out the'}
                  <Link style={{color: '#202256'}} to={'/consultor/cv'}>
                    {lang === 'es' ? ' siguiente formulario.' : ' next form.'}</Link></h2>
              </div>
            </div>
          </div>
        </Box>
      </Modal>
      {
        cv ? null : (
          <button onClick={() => cvButton()} className={'SidebarCV-Button'} style={{backgroundColor: university.color.primary}}>
            {lang === 'es' ? 'Subir hoja de vida' : 'Upload CV'}
          </button>
        )
      }
    </>
  )
}