import styled, { keyframes } from "styled-components";

const ActiveLangButton = styled.button`
  font-weight: 700;
  font-size: 18px;
  background-color: transparent;
`;

const InactiveLangButton = styled.button`
  font-weight: 300;
  font-size: 18px;
  color: #c5c5c5;
  background-color: transparent;
`;

const ProfileButton = styled.button<{ background?: string }>`
  height: 55px;
  width: 55px;
  border-radius: 50%;
  background-color: #c5c5c5;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 0;

  &:hover {
    background-color: ${(props) =>
      props.background ? props.background : "#202256"};
  }

  &:focus {
    background-color: ${(props) =>
      props.background ? props.background : "#202256"};
  }
`;

const DefaultButton = styled.button<{ background?: string; margin?: string }>`
  background: ${(props) => (props.background ? props.background : "#f07130")};
  border-radius: 5px;
  font-weight: 400;
  font-size: 14px;
  color: #ffffff;
  height: 35px;
  width: 170px;
  margin-bottom: ${(props) => (props.margin ? props.margin : "50px")};
`;

const DisabledButton = styled.button`
  cursor: not-allowed !important;
  background: #949494;
  border-radius: 5px;
  font-weight: 400;
  font-size: 14px;
  color: #000000;
  height: 35px;
  width: 170px;
  margin-bottom: 50px;
`;

const colorAnimation = (color: string) => keyframes`
  from {
    background-color: transparent;
    opacity: 1;
  }
  to {
    background-color: ${color};
    color: #ffffff;
    opacity: 1;
  }
`;

const ApplyButton = styled.button<{ color?: string; width?: string }>`
  height: 30px;
  width: ${(props) => (props.width ? props.width : "120px")};
  background: transparent;
  border: 0.5px solid #5d5d5d;
  border-radius: 5px;
  font-weight: 400;
  font-size: 14px;
  color: #5d5d5d;
  white-space: nowrap;
  &:hover {
    animation: ${(props) =>
        colorAnimation(props.color ? props.color : "#f07130")}
      0.5s forwards;
  }
`;

const AutoWidthButton = styled.button<{ color?: string }>`
  height: 30px;
  width:auto;
  background: transparent;
  border: 0.5px solid ${(props) => (props.color ? props.color : "#5d5d5d")};
  border-radius: 5px;
  font-weight: 400;
  font-size: 14px;
  color: ${(props) => (props.color ? props.color : "#5d5d5d")};
`

const DownloadLabelButton = styled.label<{color?: string; width?: string}>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background: ${(props) => (props.color ? props.color : "#f07130")};
  border-radius: 5px;
  font-weight: 400;
  font-size: 14px;
  color: #FFFFFF;
  height: 40px;
  padding: 10px;
  margin-bottom: 50px;
  width: ${(props) => (props.width ? props.width : "")};
`
const SelectApplicantButton = styled.button<{color?: string}>`
  width: 100%;
  height: 33px;
  background-color: ${(props) => (props.color ? props.color : "#f07130")};
  border-radius: 5px;
  border: none;
  color: #FFFFFF;
  font-size: 14px;
  font-weight: 400;
`

const CvModalButton = styled.button<{color?: string}>`
  border: ${(props) => (props.color ? `1px solid ${props.color}` : "1px solid #f07130")};
  border-radius: 5px;
  background: transparent;
  font-weight: 400;
  font-size: 14px;
  display: flex;
  align-items: center;
  text-align: center;
  color: ${(props) => (props.color ? props.color : "#f07130")};
  padding-right: 20px;
  padding-left: 20px;
  margin-left: 10px;
  
  &:hover {
    background-color: ${(props) => (props.color ? props.color : "#f07130")};
    color: #FFFFFF;
  }
`

const FormInput = styled.input`
  width: 100%;
  height: 33px;
  border-radius: 5px;
  border: 1px solid #c5c5c5;
  padding-left: 10px;
  margin-bottom: 10px;
  font-size: 14px;
  font-weight: 400;
  color: #000000;
`
const FormTextArea = styled.textarea`
  width: 100%;
  min-height: 100px;
  border-radius: 5px;
  border: 1px solid #c5c5c5;
  padding-left: 10px;
  margin-bottom: 10px;
  font-size: 14px;
  font-weight: 400;
  color: #000000;
`

export {
  ActiveLangButton,
  InactiveLangButton,
  ProfileButton,
  DefaultButton,
  DisabledButton,
  ApplyButton,
  AutoWidthButton,
  DownloadLabelButton,
  SelectApplicantButton,
  CvModalButton,
  FormInput,
  FormTextArea
};
