import "./Styles/CompaniesCreateProject.css";
import Popup from "../../components/popUp/Modal.js"
import { CreateUnregisterProject } from "../../actions/index";
import { connect } from "react-redux";
import { useState } from "react";
import * as React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import StepContent from "@material-ui/core/StepContent";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Select from "@material-ui/core/Select";


const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 10000,
  },
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  actionsContainer: {
    marginBottom: theme.spacing(2),
  },
  resetContainer: {
    padding: theme.spacing(3),
  },
  circle: {
    backgroundImage:
      "linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)",
  },
}));

const Causasproblema = [
  "Me hace falta capital",
  "La pandemia acabó con mi negocio",
  "El personal no está trabajando como esperaba",
  "Siento que estamos estancados",
  "Quiero reestructrar las bases de mi negocio",
  "¿Otro?",
];

const seleccioncausasproblema = (
  <Select multiple native name="problema_seleccion">
    {Causasproblema.map((causa) => (
      <option key={causa} value={causa} name={"Causa"} class={'option-btn'}>
        {causa}
      </option>
    ))}
  </Select>
);

const lineaneg = [
  "Desarrollo de software y big data",
  "Marketing digital y estudios de mercado",
  "Innovación e intraemprendimiento",
];

const seleccionlineanegocio = (
  <Select multiple native name="linea_negocio">
    {lineaneg.map((linea) => (
      <option key={linea} value={linea} class={'option-btn'}>
        {linea}
      </option>
    ))}
  </Select>
);

const Conocimientosespecificos = [
  "Finanzas",
  "Nagociación y liderazgo",
  "Ingenierias",
  "Planeación y modelaje de negocios",
  "Impacto social y sostenibilidad",
  "Recursos Humanos",
  "Estrategia",
  "Legal",
  "Marketing y ventas",
];

const seleccionconocimientos = (
  <Select multiple native name="habilidades_requeridas">
    {Conocimientosespecificos.map((conocimientos) => (
      <option key={conocimientos} value={conocimientos} class={'option-btn'}>
        {conocimientos}
      </option>
    ))}
  </Select>
);

const nivelingles = ["Nulo", "Básico", "Intermedio", "Avanzado"];

const seleccionnivelingles = (
  <Select multiple native name="nivel_ingles">
    {nivelingles.map((ingles) => (
      <option key={ingles} value={ingles} class={'option-btn'}>
        {ingles}
      </option>
    ))}
  </Select>
);

const steps = [
  {
    label: "¿Que nombre le pondras al proyecto?",
    tittle: "Este nombre se utilizara como referencia al publicar el proyecto",
    description: (
      <TextField
        name="nombre"
        id="standard-basic"
        label="nombre"
        variant="standard"
      />
    ),
  },
  {
    label: "Correo electronico",
    tittle: "Este correo se usara para enviar credenciales y notificaciones del proyecto",
    description: (
      <TextField
        name="email"
        id="standard-basic"
        label="email"
        variant="standard"
      />
    ),
  },
  {
    label: "¿En que Ciudad se realizara el proyecto?",
    tittle: "Esta Ciudad se utilizara como referencia para buscar a los consultores",
    description: (
      <TextField
        name="ciudad"
        id="standard-basic"
        label="ciudad"
        variant="standard"
      />
    ),
  },
  {
    label: "¿Cuales son los objetivos que quieres cumplir con este proyecto?",
    tittle: "Estos seran los principales objetivos del proyecto",
    description: (
      <TextField
        name="objetivos"
        id="standard-basic"
        label="objetivos"
        variant="standard"
      />
    ),
  },
  {
    label: "¿Cuales son las metricas que quieres medir en este proyecto?",
    tittle: "Estos seran los principales metricas del proyecto",
    description: (
      <TextField
        name="metricas"
        id="standard-basic"
        label="metricas"
        variant="standard"
      />
    ),
  },
  {
    label: "Describe tu problema en una oración",
    tittle: "La información que se diligenciará es concretamente del reto o desafío con el que vamos a trabajar.",
    description: (
      <TextField
        name="problema_empresa"
        id="standard-basic"
        label="Describe tu problema aca"
        variant="standard"
      />
    ),
  },
  {
    label: "Causas del problema",
    tittle: "¿Cuáles crees que son las causas de este problema?",
    description: seleccioncausasproblema,
  },
  {
    label: "Línea del negocio",
    tittle: "Línea de negocio en la que necesitas apoyo",
    description: seleccionlineanegocio,
  },
  {
    label: "Conocimientos específicos",
    tittle: "Conocimientos específicos que requiere el proyecto",
    description: seleccionconocimientos,
  },
  {
    label: "Nivel de inglés",
    tittle: "¿Qué nivel de inglés se requiere por parte de los consultores para llevar a cabo el proyecto?",
    description: seleccionnivelingles,
  },
  {
    label: "Codigo Promocional",
    tittle: "Inserta aca tu Codigo Promocional",
    description: (
      <TextField
        id="standard-basic"
        name="codigo_promo"
        label="Codigo"
        variant="standard"
      />
    ),
  },
  {
    label: "Comentarios",
    tittle: "Comentarios o información adicional relevante",
    description: (
      <TextField
        id="standard-basic"
        name="comentario"
        label="Comentarios"
        variant="standard"
      />
    ),
  },
];

function VerticalLinearStepper(props) {
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);
  const [buttonPopup, setButtonPopup] = useState(false);
  const [form, setValues] = useState({
    usuario: props.user.id,
    nombre: "",
    email: "",
    comentario: "",
    descripcion: "",
    idioma: "Español",
    ciudad: "",
    objetivos: "",
    metricas: "",
    problema_empresa: "",
    problema_seleccion: "",
    causa_empresa: "",
    causa_seleccion: "",
    linea_negocio: "",
    codigo_promo: "",
    habilidades_requeridas: [],
    nivel_ingles: "",
    //   fecha:
    //   date.getDate() + "/" + (date.getMonth() + 1) + "/" + date.getFullYear(),
  });

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleInput = (event) => {
    if (event.target.options) {
      console.log(event.target.name);
      setValues({
        ...form,
        [event.target.name]:
          event.target.options[event.target.options.selectedIndex].value,
      });
    } else {
      setValues({
        ...form,
        [event.target.name]: event.target.value,
      });
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    console.log(form);
    props.CreateUnregisterProject(form, "https://coally.com/");
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  /* const handleReset = () => {
    setActiveStep(0);
  }; */

  return (
    <>
      <div className="section-tittle-out-form">
        <h2 className="tittle-out-form">Publicar proyecto</h2>
      </div>
      <div className=".CompanyForm-container">
        <section className="CompanyForm-container">

          <div className={classes.root}>
            <Stepper activeStep={activeStep} orientation="vertical">
              {steps.map((step, index) => (
                <Step key={step.label}>
                  <StepLabel>{step.label}</StepLabel>
                  <StepContent>
                    <p className="step-tittle">{step.tittle || "titulo"}</p>
                    <Typography
                      onChange={(e) => {
                        handleInput(e);
                      }}
                    >
                      {step.description}
                    </Typography>
                    <div className={classes.actionsContainer}>
                      <div>
                        <Button
                          disabled={index === 0}
                          onClick={handleBack}
                          className={classes.button}
                        >
                          ATRÁS
                        </Button>
                        <Button
                          variant="contained"
                          onClick={handleNext}
                          className={classes.button}
                        >
                          {index === steps.length - 1 ? "FINALIZAR" : "SIGUIENTE"}
                        </Button>
                      </div>
                    </div>
                  </StepContent>
                  {index === steps.length - 1 ? (

                    <button className="CompanyForm-button-submit" onClick={(e) => {
                      setButtonPopup(true);
                      handleSubmit(e);
                    }}>Enviar</button>
                  ) : null}
                </Step>
              ))}
            </Stepper>
            {activeStep === steps.length && (
              <Paper
                square
                elevation={0}
                className={classes.resetContainer}
              ></Paper>
            )}
          </div>
          <Popup trigger={buttonPopup} setTrigger={setButtonPopup}>
            <h2 className="tittle-popup-apply">Proyecto creado con exito</h2>
          </Popup>

        </section>
      </div>
    </>
  );
}

const mapDispatchToProps = {
  CreateUnregisterProject,
};
const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(VerticalLinearStepper);
