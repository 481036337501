import React, { useState } from 'react'
import '../../Companies/Payment/PaymentComponent.css';
import Header from "../../../components/Header/Header";
import { Link } from 'react-router-dom';
import en from "../../../lang/en-US";
import es from "../../../lang/es-COL"
import counterpart from "counterpart";
import Translate from "react-translate-component";
import axios from 'axios';

export default function Consultornequidispersion(props) {
    const [cargando, setCargando] = useState(false)
    const [responsee, setResponsee] = useState('')
    const [input, setInput] = useState({
        phoneNumber: '',
        value: '',
        ref1: '',
        ref2: '',
        ref3: ''
    })
    counterpart.registerTranslations('en', en)
    counterpart.registerTranslations('es', es)
    counterpart.setLocale(localStorage.getItem('langlocal'));
    const enviado = () => {
        axios.post('https://findally-backend.herokuapp.com/api/nequidispersion', input).then(response => {
            setResponsee(response.data)
        })

        setCargando(true)
    }
    const error = () => {
        setResponsee('')
        setCargando(false)
    }
    return (
        <>
            {cargando === false ? <>
                <Header />
                <div className="volver-legal" style={{ height: '150px' }}>
                    <Link className="volver-leg-icon" to="/company/new/project">
                        {" "}
                        🠸 {<Translate content="ATRÁS" component="span" />}
                    </Link>
                    <p style={{ display: 'flex', margin: '50px', marginLeft: '190px', fontSize: '30px' }}>Cobrar con nequi</p>
                </div>
                <div className="paymennequi">
                    <div className="caja2paymen">
                        <div >
                            <p className="pnequi">Celular nequi*</p>
                            <input placeholder="5551234" value={input.phoneNumber} className="inputnequi" type="number" onChange={(event) => { setInput({ ...input, phoneNumber: event.target.value }) }}></input>
                        </div>
                        <div>
                            <p className="pnequi">Valor*</p>
                            <input placeholder="0" type="number" value={input.value} className="inputnequi" onChange={(event) => { setInput({ ...input, value: event.target.value }) }}></input>
                        </div>
                        <div>
                            <p className="pnequi">Nombre</p>
                            <input placeholder="x" type="text" value={input.ref1}maxlength="8" className="inputnequi" onChange={(event) => { setInput({ ...input, ref1: event.target.value }) }}></input>
                        </div>
                        <div>
                            <p className="pnequi">referencia2</p>
                            <input placeholder="x" type="text"value={input.ref2} className="inputnequi" onChange={(event) => { setInput({ ...input, ref2: event.target.value }) }}></input>
                        </div>
                        <div>
                            <p className="pnequi">referencia3</p>
                            <input placeholder="x" type="text"value={input.ref3} className="inputnequi" onChange={(event) => { setInput({ ...input, ref3: event.target.value }) }}></input>
                        </div>
                        <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                            <button onClick={enviado} className="btnnequi">Cobrar</button>
                        </div>
                    </div>
                </div></>
                :
                responsee.slice(0, 5) === 'error' ?
                    <>
                        <Header />
                        <div className="volver-legal" style={{ height: '150px' }}>
                            <Link className="volver-leg-icon" to="/company/new/project">
                                {" "}
                                🠸 {<Translate content="ATRÁS" component="span" />}
                            </Link>
                            <p style={{ display: 'flex', margin: '50px', marginLeft: '190px', fontSize: '30px' }}>{<Translate content="pagarnequi" component="span" />}</p>
                        </div>
                        <div className="paymennequi">
                            <div className="caja2paymen2">
                                <p>ERROR-Comprueba que los datos ingresados son correctos o intente mas tarde</p>
                                <div style={{ maxWith: '100%', wordBreak: 'break-word' }}>
                                    <p>Celular nequi:{input.phoneNumber}</p>
                                    <p>Valor:{input.value}</p>
                                    <p>referencia1:{input.ref1}</p>
                                    <p>referencia2:{input.ref2}</p>
                                    <p>referencia3:{input.ref3}</p>
                                </div>
                                <div className="butonesnequi">
                                    <button className="btnnequi"><Link className="btnnequi" to="/company/home">
                                        {" "}
                                        Inicio
                                    </Link></button>
                                    <button onClick={error} className="btnnequi">Editar</button>
                                </div>
                            </div>
                        </div>
                    </>
                    :
                    responsee.slice(0, 20) === 'Transacción Aceptada' ?
                        <>
                            <Header />
                            <div className="volver-legal" style={{ height: '150px' }}>
                                <Link className="volver-leg-icon" to="/company/new/project">
                                    {" "}
                                    🠸 {<Translate content="ATRÁS" component="span" />}
                                </Link>
                                <p style={{ display: 'flex', margin: '50px', marginLeft: '190px', fontSize: '30px' }}>{<Translate content="pagarnequi" component="span" />}</p>
                            </div>
                            <div className="paymennequi">
                                <div className="caja2paymen2">
                                    <p>{responsee}</p>
                                    <p>Guardalo</p>
                                    <button className="btnnequi"><Link className="btnnequi" to="/company/home">
                                        {" "}
                                        Inicio
                                    </Link></button>
                                </div>
                            </div>
                        </>
                        :
                        <>
                            <Header />
                            <div className="volver-legal" style={{ height: '150px' }}>
                                <Link className="volver-leg-icon" to="/company/new/project">
                                    {" "}
                                    🠸 {<Translate content="ATRÁS" component="span" />}
                                </Link>
                                <p style={{ display: 'flex', margin: '50px', marginLeft: '190px', fontSize: '30px' }}>{<Translate content="pagarnequi" component="span" />}</p>
                            </div>
                            <div className="paymennequi2">
                                <div class="loadingio-spinner-spinner-9bz1nhdfjp"><div class="ldio-ft58je84is8">
                                    <div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div>
                                </div></div>
                                <div style={{ display: 'flex' }}>
                                    <p className="C">C</p>
                                    <p className="a">a</p>
                                    <p className="r">r</p>
                                    <p className="g">g</p>
                                    <p className="a2">a</p>
                                    <p className="n">n</p>
                                    <p className="d">d</p>
                                    <p className="o">o</p>
                                    <p className="punto1">.</p>
                                    <p className="punto2">.</p>
                                    <p className="punto3">.</p>
                                </div>
                            </div>
                        </>
            }
        </>
    );

}

