import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import en from "../../lang/en-US";
import es from "../../lang/es-COL"
import counterpart from "counterpart";
import Translate from "react-translate-component";

const useStyles = makeStyles((theme) => ({
  box: {
    padding: theme.spacing(3)
  },
  title: {
    marginTop: 30
  }
}));

const Success = () => {
  const classes = useStyles();
  counterpart.registerTranslations('en',en) 
      counterpart.registerTranslations('es',es)
      counterpart.setLocale(localStorage.getItem('langlocal'));
  return (
    <Box className={classes.box}>
      <Typography variant="h2" align="center">
      {<Translate content="Thankyou" component="span"/>}
      </Typography>
      <Typography component="p" align="center" className={classes.title}>
        {<Translate content="Youwillgetanemailwithfurtherinstructions" component="span"/>}
      </Typography>
    </Box>
  );
};

export default Success;
