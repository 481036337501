import React from "react";
import HeaderEmpresa from "../../components/HeaderEmpresa/HeaderEmpresa";
import UsersListCompany from '../Companies/CompaniesCoalliersList';

function CoalliersListCompany(props) {

    return (
        <>
            <HeaderEmpresa />
            <div>
                <div className="section-coalliers-companies">
                    <UsersListCompany />
                </div>
            </div>
        </>
    );
}

export default (CoalliersListCompany);