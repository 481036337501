/*eslint-disable*/
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import SearchIcon from "@mui/icons-material/Search";
import Sidebar from "../../components/Sidebar/Sidebar";
import Navbar from "../../components/Navbar/Navbar";
import { Link, useHistory } from "react-router-dom";
import sectorAgro from '../../assets/imagenes/sectorAgropecuario.png';
import sectorInd from '../../assets/imagenes/sectorIndustrial.png';
import sectorEdu from '../../assets/imagenes/sectorEducativo.png'
import CardSector from "./CardSector";
import arrow from '../../assets/icons/arrow-orange.svg';
import CardHabilidades from './CardHabilidades';
import disenio from '../../assets/imagenes/diseño.png';
import desarrolloProductos from '../../assets/imagenes/desarrolloProductos.png';
import finanzasCard from '../../assets/imagenes/finanzasCard.png';
import sectorRecomendacion from "../../assets/imagenes/man-791049_1920.jpg";
import './Formacion.css'
import { getFormationModel } from "../../actions";


export default function NewEducation() {
  const { lang } = useSelector(state => state);
  const { user, formationModel } = useSelector(state => state);
  const [screen, setScreen] = useState(window.screen.width);
  const history = useHistory()
  const dispatch = useDispatch()

  const [tab, setTab] = useState(0)

  const left = () => {
    document.getElementById('cards').scrollLeft -= 250;
  }

  const right = () => {
    document.getElementById('cards').scrollLeft += 250;
  }

  //capturar el tamaño de la pantalla
  useEffect(() => {
    const handleResize = () => {
      setScreen(window.screen.width);
    }
    window.addEventListener('resize', handleResize)
  }, [])

  useEffect(() => {    
    dispatch(getFormationModel(user.id))
  }, [user.id])

  return (
    <div className="contenedor-formacion">
      {
        user.roles === 'coallier' ? null : history.push('/company/home')
      }
      <div style={{ backgroundColor: '#F5F5F5' }}>
        <Sidebar location={'formación'} />
        <div className="BodyWeb"
          style={{ padding: "0rem 2rem", position: "absolute", right: "0" }}>
          <Navbar locate={'formación'} />
          <div style={{
            paddingInline: '2.5%',
          }}>
            <h1 className="formacion-title">
              {lang === 'es' ? 'Formación' : 'Training'}
            </h1>
            {/* <div style={{ display: "flex", flexDirection: 'column' }}>
              <div
                className={'formacion-search-responsive'}>
                <div
                  style={{ marginBottom: '30px', display: 'flex', justifyContent: 'space-between' }}>
                  <SearchIcon
                    style={{
                      fontSize: "30px",
                      color: "#B3B6B6",
                      position: "absolute",
                      marginTop: "3px",
                      marginLeft: "5px",
                    }}
                  />
                  <input
                    className={'input-responsive-box'}
                    type={"search"}
                    placeholder={lang === 'es' ? 'Buscar...' : 'Search...'}
                    // onChange=''
                  />
                </div>
              </div>
            </div> */}
            {/*seccion sector*/}
            <div>
              <h2 style={{
                width: "100%",
                fontSize: "24px",
                fontWeight: "700",
                color: "#393939",
              }}>
                {lang === 'es' ? 'Formación por sector' : 'Training by sector'}
              </h2>
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <button className={'home-unilever-arrow-button'} onClick={() => left()}>
                  <img src={arrow} alt={'left'} style={{ rotate: '180deg' }} />
                </button>
                <div id={'cards'}
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-start',
                    marginTop: '2%',
                    width: '100%',
                    overflowX: 'scroll',
                  }}>
                  <Link to={'/consultor/formacion/agriculture'}>
                    <CardSector image={sectorAgro} title={lang === 'es' ? 'Sector agropecuario' : 'Agricultural sector'} cant={11} />
                  </Link>
                  <Link to={'/consultor/formacion/industrial'}>
                    <CardSector image={sectorInd} title={lang === 'es' ? 'Sector industrial' : 'Industrial sector'} cant={12} />
                  </Link>
                  <Link to={'/consultor/formacion/educational'}>
                    <CardSector image={sectorEdu} title={lang === 'es' ? 'Sector educativo' : 'Education sector'} cant={10} />
                  </Link>
                  {
                    formationModel?.length > 0 &&
                    <Link to={'/consultor/formacion/recomendacion'}>
                      <CardSector image={sectorRecomendacion} title={lang === 'es' ? 'Recomendaciones' : 'Recommendations'} cant={formationModel?.length} />
                    </Link>
                  }
                </div>
                <button className={'home-unilever-arrow-button'} onClick={() => right()}>
                  <img src={arrow} alt={'right'} />
                </button>
              </div>
            </div>
            <div style={{
              marginTop: '4%',
            }}>
              <h2 style={{
                width: "100%",
                fontSize: "24px",
                fontWeight: "700",
                color: "#393939",
              }}>
                {lang === 'es' ? 'Formación por Habilidades' : 'Training based on skills'}
              </h2>
              <div style={{
                marginTop: '2%',
              }}>
                <div>
                  <button onClick={() => setTab(0)} style={{ background: 'transparent' }}>
                    <h3 style={{ fontSize: '16px', fontWeight: tab === 0 ? 700 : null }}>{lang === 'es' ? 'Técnicas' : 'Techniques'}(3)</h3>
                    <div style={{ borderTop: tab === 0 ? '5px solid #202256' : '0', width: '79px' }} />
                  </button>
                  {/* <button onClick={() => setTab(1)} style={{ background: 'transparent' }}>
                    <h3 style={{fontSize:'16px', fontWeight: tab === 1 ? 700 : null}}>{lang === 'es' ?  'Blandas' : 'Soft' }(3)</h3>
                    <div style={{ borderTop: tab === 1 ? '5px solid #202256' : '0', width: '79px' }} />
                  </button> */}
                </div>
                <div style={{ borderTop: 'solid', width: '100%', borderColor: '#D6D6D6' }} />
              </div>
              {/* {
                tab === 0 ? */}
              <div style={{
                display: 'flex',
                justifyContent: 'space-between',
                marginTop: '2%',
                marginBottom: '2%',
                height: '250px',
                flexDirection: 'column',
              }}>
                <Link to={'/consultor/formacion/design'}>
                  <CardHabilidades image={disenio} title={'Diseño gráfico / Web '} cant={23} />
                </Link>
                <Link to={'/consultor/formacion/product'}>
                  <CardHabilidades image={desarrolloProductos} title={'Desarrollo de productos'} cant={14} />
                </Link>
                <Link to={'/consultor/formacion/financial'}>
                  <CardHabilidades image={finanzasCard} title={'Finanzas'} cant={21} />
                </Link>
              </div>
              {/* :
                  <div style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    marginTop: '2%',
                    marginBottom: '2%',
                    height: '250px',
                    flexDirection: 'column',
                  }}>
                    <CardHabilidades image={disenio} title={'Comunicación'} cant={5} />
                    <CardHabilidades image={desarrolloProductos} title={'Trabajo en equipo'} cant={5} />
                    <CardHabilidades image={finanzasCard} title={'Proactividad y capacidad resolutiva'} cant={5} />
                  </div>
              } */}
            </div>
          </div>

        </div>
      </div>
    </div>
  )
}
