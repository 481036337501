import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { changeLang, LoadCv } from "../../../../actions";
import { ReduxStateInterface } from "../../../types/reduxInterface";
import { fetchProfessionalProfile, fetchCompanyForm } from "./fetchData";

const useNavbarData = () => {
  const { lang, user, cv } = useSelector((state: ReduxStateInterface) => state);
  const dispatch = useDispatch();
  const [dataUser, setDataUser] = useState();
  const [show, setShow] = useState(false);
  const [sidebar, setSidebar] = useState(false);
  const [showInfo, setShowInfo] = useState(false);
  const history = useHistory();
  const showSideBar = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.preventDefault();
    setShowInfo(false);
    setShow(true);
    setSidebar(true);
  };
  const hideSideBar = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setShow(false);
    setSidebar(false);
  };
  const showUserInfo = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    if (!user.id) {
      history.push("/");
    } else {
      setShow(false);
      setSidebar(false);
      setShowInfo(!showInfo);
    }
  };

  useEffect(() => {
    function handleResize() {
      if (window.innerWidth > 1300) {
        setShow(false);
        setSidebar(false);
      }
    }

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (user.roles !== "company") {
      user.id &&
        !cv &&
        fetchProfessionalProfile(user.id).then((res) => {
          if (res?.cv_file) {
            console.log(res);
            dispatch(LoadCv(res?.cv_file));
          } else {
            dispatch(LoadCv(null));
          }
        });
    }
  }, [dispatch, user.id, cv, user.roles]);

  useEffect(() => {
    fetchCompanyForm(user.id)
      .then((res) => {
        setDataUser(res.Company_Form[0]);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [user]);

  const switchLanguages = (lang: string) => {
    dispatch(changeLang(lang));
    localStorage.setItem("langlocal", lang);
  };
  if (!localStorage.getItem("langlocal")) {
    localStorage.setItem("langlocal", "es");
  }

  return {
    dataUser,
    show,
    sidebar,
    setSidebar,
    setShow,
    showInfo,
    setShowInfo,
    showSideBar,
    hideSideBar,
    showUserInfo,
    lang,
    switchLanguages,
    user,
    cv,
  };
};

export default useNavbarData;
