import React from 'react'
import './CardInfoOportunidad.css'
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import HomeWorkOutlinedIcon from '@mui/icons-material/HomeWorkOutlined';
import HandshakeOutlinedIcon from '@mui/icons-material/HandshakeOutlined';
import AttachMoneyOutlinedIcon from '@mui/icons-material/AttachMoneyOutlined';

function CardInfoOportunidad({ data, lang }) {  
  return (
    <div className='Container-Card-Oportunidad'>
      <div className='Subcontainer-Card-Oportunidad'>
        <h3 className='Title-Card-Oportunidad'>{lang === 'es' ? 'Aplicantes:' : 'Applicants:'}</h3>
        <p className='Text-Habilidades-Info'>
          {data?.aplicantes?.length}
        </p>
        <hr className='H-erre' />

        <h3 className='Title-Card-Oportunidad'>{lang === 'es' ? 'Habilidades:' : 'Skills:'}</h3>
        {data?.habilidadesTecnicas?.slice(0, 5).map((habilidad, index) => {
          return (
            <li className='List-Habilidades-Info' key={index}>
              {habilidad.charAt(0).toUpperCase() + habilidad.slice(1).toLowerCase()}
            </li>
          )
        })}


        <h3 className='Title-Card-Oportunidad' style={{ marginTop: '1em' }}>
          <LocationOnOutlinedIcon />
          <span>{lang === 'es' ? 'Idiomas:' : 'Languages:'}</span>
        </h3>
        {data?.Lenguaje?.map((idioma, index) => {
          return (
            <li className='List-Habilidades-Info' key={index}>
              {idioma}
            </li>
          )
        })}
        <hr className='H-erre' />
        <h3 className='Title-Card-Oportunidad'>
          <HomeWorkOutlinedIcon />
          <span>{lang === 'es' ? 'Modalidad:' : 'Modality:'}</span>
        </h3>
        <p className='Text-Habilidades-Info'>
          {data?.modalidadDeTrabajo}
        </p>
        <hr className='H-erre' />
        <h3 className='Title-Card-Oportunidad'>
          <HandshakeOutlinedIcon />
          <span>{lang === 'es' ? 'Tipo de contrato:' : 'Type of contract:'}</span>
        </h3>
        <p className='Text-Habilidades-Info'>
          {data?.modalidadDeContratacion}
        </p>
        <hr className='H-erre' />
        <h3 className='Title-Card-Oportunidad'>
          <AttachMoneyOutlinedIcon />
          <span>{lang === 'es' ? 'Salario:' : 'Salary:'}</span>
        </h3>
        <p className='Text-Habilidades-Info'>
          <span style={{ color: '#F07130' }}>{data?.presupuesto}</span> /{lang === 'es' ? 'mensual' : 'monthly'}
        </p>

      </div>
    </div>
  )
}

export default CardInfoOportunidad