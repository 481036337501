import React from "react";
import { useSelector } from "react-redux";


const TermsAndConditions = () => {
  const lang = useSelector((state) => state.lang);
  return(
    <>
    <div className="section">
                <h3 className="title">{
                  lang === 'es' ? 'POLÍTICA TRATAMIENTO DE DATOS COALLY S.A.S' : 'COALLY S.A.S DATA TREATMENT POLICY'
                }</h3>
                <p className="content">{
                  lang === 'es' ? 'Tabla de contenido' : 'Table of contents'
                }</p>
                <p className="sub-content">{
                  lang === 'es' ? '1. PRESENTACIÓN' : '1. PRESENTATION'
                }</p>
                <p className="sub-content">{
                  lang === 'es' ? '2. ÁMBITO DE APLICACIÓN' : '2. SCOPE OF APPLICATION'
                }</p>
                <p className="sub-content">{
                  lang === 'es' ? '3. DEFINICIONES' : '3. DEFINITIONS'
                }</p>
                <p className="sub-content">{
                  lang === 'es' ? '4. PRINCIPIOS RECTORES PARA EL TRATAMIENTO DE LOS DATOS PERSONALES' : '4. GUIDING PRINCIPLES FOR THE TREATMENT OF PERSONAL DATA'
                }</p>
                <p className="sub-content">{
                  lang === 'es' ? '5. AUTORIZACIÓN PARA EL TRATAMIENTO DE DATOS PERSONALES Y CASOS EN LOS QUE NO ES NECESARIA LA AUTORIZACIÓN' : '5. AUTHORIZATION FOR THE TREATMENT OF PERSONAL DATA AND CASES IN WHICH AUTHORIZATION IS NOT REQUIRED'
                }</p>
                <p className="sub-content">{
                  lang === 'es' ? '6. TIPOS DE DATOS RECOPILADOS POR COALLY S.A.S' : '6. TYPES OF DATA COLLECTED BY COALLY S.A.S'
                }</p>
                <p className="sub-content">{
                  lang === 'es' ? '7. FINALIDAD DE TRATAMIENTO DE LOS DATOS PERSONALES' : '7. PURPOSE OF TREATMENT OF PERSONAL DATA'
                }</p>
                <p className="sub-content">{
                  lang === 'es' ? '8. NORMATIVIDAD APLICABLE' : '8. APPLICABLE REGULATION'
                }</p>
                <p className="sub-content">{
                  lang === 'es' ? '9. DERECHOS DEL TITULAR DE LOS DATOS PERSONALES' : '9. RIGHTS OF THE HOLDER OF PERSONAL DATA'
                }</p>
                <p className="sub-content">{
                  lang === 'es' ? '10. RESPONSABLE DE LOS DATOS PERSONALES' : '10. RESPONSIBLE FOR PERSONAL DATA'
                }</p>
                <p className="sub-content">{
                  lang === 'es' ? '11.  DEBERES DE LOS ENCARGADOS DEL TRATAMIENTO' : '11. DUTIES OF THE PERSONS IN CHARGE OF TREATMENT'
                }</p>
                <p className="sub-content">{
                  lang === 'es' ? '12. CONSULTAS Y RECLAMOS' : '12. QUERIES AND COMPLAINTS'
                }</p>
                <p className="sub-content">{
                  lang === 'es' ? '13. MODIFICACIONES' : '13. MODIFICATIONS'
                }</p>
                <p className="sub-content">{
                  lang === 'es' ? '14. VIGENCIA' : '14. VALIDITY'
                }</p>
              </div>
              <div className="section">
                <h3 className="title">{
                  lang === 'es' ? '1. Presentación' : '1. Presentation'
                }</h3>
                <p className="content">{
                  lang === 'es' ? 'COALLY S.A.S. es una compañía colombiana que tiene como actividad principal\n' +
                  'conectar empresas y/o personas naturales con profesionales jóvenes por medio\n' +
                  'de una plataforma virtual, compuesta por una página web y una aplicación móvil,\n' +
                  'para que se desarrolle un servicio de consultoría que aporte valor a los clientes. (\n' +
                  'empresas y/o personas naturales).' : 'COALLY S.A.S. is a Colombian company that has as its main activity\n' + 
                  'to connect companies and / or natural persons with young professionals through\n' +
                  'a virtual platform, composed of a website and a mobile application,\n' +
                  'so that a consulting service is developed that adds value to the clients. (\n' +
                  'companies and / or natural persons).'
                }</p>
                <p className="sub-content">{
                  lang === 'es' ? 'COALLY S.A.S., con la finalidad de dar cumplimiento estricto a la normatividad\n' +
                  'vigente de protección de Datos Personales, de acuerdo a lo establecido en la Ley\n' +
                  '1581 del 2012, Decreto 1074 de 2015 y demás disposiciones que las modifiquen,\n' +
                  'adicionen o complementen, presenta la siguiente POLÍTICA DE PROTECCIÓN Y\n' +
                  'TRATAMIENTO DE DATOS PERSONALES (en adelante “Política de\n' +
                  'Tratamiento”) con el propósito de proteger la información personal brindada por los\n' +
                  'Titulares que tengan relación con COALLY S.A.S. como lo son clientes,\n' +
                  'consultores y cualquier otra persona natural o jurídica de la cual COALLY S.A.S.,\n' +
                  'obtenga, recolecta, procesa o trate datos personales, sea que dicho tratamiento\n' +
                  'sea realizado por COALLY S.A.S. o por terceras personas que lo hagan por\n' +
                  'encargo de ésta.' : 'COALLY S.A.S., in order to strictly comply with the current Data Protection\n' +
                  'regulation, in accordance with what is established in Law 1581 of 2012, Decree 1074 of 2015 and other provisions that modify,\n' +
                  'add or complement it, presents the following PERSONAL DATA PROTECTION AND\n' +
                  'TREATMENT POLICY (hereinafter "Treatment Policy") with the purpose of protecting the personal information provided by the\n' +
                  'Holders who have a relationship with COALLY S.A.S. such as customers,\n' +
                  'consultants and any other natural or legal person from which COALLY S.A.S.,\n' +
                  'obtains, collects, processes or treats personal data, whether such treatment\n' +
                  'is performed by COALLY S.A.S. or by third parties who do so on\n' +
                  'behalf of it.'
                }</p>
                <p className="sub-content">{
                  lang === 'es' ? 'El objeto de la Política de Tratamiento es proteger el derecho constitucional del\n' +
                  'Habeas Data que tienen todas las personas para conocer, actualizar, y rectificar la\n' +
                  'información que se haya recogido y almacenado en las distintas bases de datos\n' +
                  'de COALLY S.A.S., y en virtud del cumplimiento de dicho derecho sólo recolecta y\n' +
                  'da Tratamiento a Datos Personales, cuando así haya sido autorizado previamente\n' +
                  'por su Titular, implementando para tal efecto, medidas claras sobre\n' +
                  'confidencialidad y privacidad de los Datos Personales. Así mismo, detalla los\n' +
                  'lineamientos generales corporativos que se tienen en cuenta con el fin de proteger\n' +
                  'los Datos Personales de los Titulares.' : 'The purpose of the Treatment Policy is to protect the constitutional right of\n' +
                  'Habeas Data that all people have to know, update, and correct the\n' +
                  'information that has been collected and stored in the different databases\n' +
                  'of COALLY S.A.S., and in accordance with the fulfillment of said right only collects and\n' +
                  'gives Treatment to Personal Data, when so authorized previously\n' +
                  'by its Holder, implementing for such purpose, clear measures on\n' +
                  'confidentiality and privacy of Personal Data. It also details the\n' +
                  'corporate general guidelines that are taken into account in order to protect\n' +
                  'the Personal Data of the Holders.'
                }</p>
              </div>
              <div className="section">
                <h3 className="title">{
                  lang === 'es' ? '2. Ámbito de aplicación' : '2. Ámbito de aplicación'
                }  </h3>
                <p className="content">{
                  lang === 'es' ? 'Estos términos y condiciones y todo lo que tenga que ver con esta aplicación y las\n' +
                  'relaciones jurídicas que surgen de esta, se rigen por las leyes colombianas,\n' +
                  'específicamente a la Ley 1581 del 2012 y el Decreto 1074 de 2015' : 
                  'These terms and conditions and everything related to this application and the\n' +
                  'legal relationships that arise from it, are governed by Colombian laws,\n' +
                  'specifically by Law 1581 of 2012 and Decree 1074 of 2015'
                }</p>
              </div>
              <div className="section">
                <h3 className="title">{
                  lang === 'es' ? '3. Definiciones' : '3. Definiciones'
                }</h3>
                <p className="content">{
                  lang === 'es' ? 'a. Titular: Persona natural cuyos datos son objeto de tratamiento. Para de\n' +
                  'la presente política de tratamiento de datos personales los titulares\n' +
                  'podrán ser: (i) Clientes de la plataforma; (ii) Consultores y todas\n' +
                  'aquellas personas no vinculadas a COALLY' : 
                  'a. Holder: Natural person whose data is the object of treatment. For the\n' +
                  'present personal data treatment policy the holders\n' +
                  'may be: (i) Platform customers; (ii) Consultants and all\n' +
                  'those persons not linked to COALLY'
                }</p>
                <p className="content">{
                  lang === 'es' ? 'b. Cliente: Persona natural o jurídica' : 
                  'b. Client: Natural or legal person'
                }</p>
                <p className="content">{
                  lang === 'es' ? 'c. Usuario: toda persona que haya descargado y haga uso de la plataforma ya sea como cliente o como consultor.' :
                  'c. User: any person who has downloaded and uses the platform either as a client or as a consultant.'
                }</p>
                <p className="content">{
                  lang === 'es' ? 'd. Plataforma: la recolección de datos por parte Coally S.A.S será a través de las plataformas Coally App y Coally Web utilizadas para el desarrollo de la actividad de esta compañía.' : 
                  'd. Platform: the collection of data by Coally S.A.S will be through the Coally App and Coally Web platforms used for the development of this company\'s activity.'
                }</p>
                <p className="content">{
                  lang === 'es' ? 'e. Dato personal: Cualquier información vinculada o que pueda asociarse\n' +
                  'a una o varias personas naturales determinadas o determinables.' :
                  'e. Personal data: Any information linked or that can be associated\n' +
                  'to one or more natural persons determined or determinable.'
                }</p>
                <p className="content">{
                  lang === 'es' ? 'f. Base de Datos: Conjunto organizado de datos que sean objeto de\n' +
                  'Tratamiento;' : 
                  'f. Database: Organized set of data that are the object of\n' +
                  'Treatment;'
                }</p>
                <p className="content">{
                  lang === 'es' ? 'g. Base de datos personales: Conjunto organizado de datos personales\n' +
                  'que son objeto de tratamiento por una persona natural o jurídica.'
                  : 'g. Personal database: Organized set of personal data\n' +
                  'that are the object of treatment by a natural or legal person.'
                }</p>
                <p className="content">{
                  lang === 'es' ? 'h. Dato sensible: Es aquel dato personal que afecta la intimidad del Titular\n' +
                  'y cuyo uso incorrecto podría generar discriminación. Son considerados\n' +
                  'datos sensibles entre otros, los datos de salud, los datos de orientación\n' +
                  'sexual, origen racial y étnico, opiniones políticas, convicciones\n' +
                  'religiosas, filosóficas o morales.'
                  : 'h. Sensitive data: It is that personal data that affects the Holder\'s\n' +
                  'intimacy and whose incorrect use could generate discrimination. They are considered\n' +
                  'sensitive data among others, health data, sexual orientation, racial and ethnic origin, political opinions, religious convictions, philosophical or moral.'
                }</p>
                <p className="content">{
                  lang === 'es' ? 'i. Dato privado: Es aquel dato personal que por su carácter íntimo o\n' +
                  'reservado es relevante para el Titular.' : 
                  'i. Private data: It is that personal data that by its intimate or\n' +
                  'reserved is relevant to the Holder.'
                }</p>
                <p className="content">{
                  lang === 'es' ? 'j. Dato Semiprivado: Es aquel dato personal conocido y de interés tanto para el titular como para un determinado sector de personas o para la sociedad en general, por lo que no es de carácter íntimo, reservado o público.' : 'j. Semiprivate data: It is that personal data known and of interest both to the holder and to a certain sector of people or to society in general, so it is not intimate, reserved or public.'
                }</p>
                <p className="content">{
                  lang === 'es' ? 'k. Dato público: Es aquel dato personal calificado como tal según la\n' +
                  'Constitución y la ley, y que no se ha clasificado como dato personal\n' +
                  'privado o semiprivado.' : 'k. Public data: It is that personal data classified as such according to the\n' +
                  'Constitution and the law, and that has not been classified as personal\n' +
                  'private or semiprivate.'
                }</p>
                <p className="content">{
                  lang === 'es' ? 'l. Habeas data: Es el derecho que tiene el Titular de los datos personales\n' +
                  'de exigir de las administradoras de los mismos el acceso, inclusión,\n' +
                  'exclusión, corrección, adición, actualización y rectificación de los datos,\n' +
                  'así como la limitación en su divulgación, publicación o cesión.' : 
                  'l. Habeas data: It is the right that the Holder of the personal data\n' +
                  'of the administrators of the same to demand the access, inclusion,\n' +
                  'exclusion, correction, addition, updating and correction of the data,\n' +
                  'as well as the limitation in its disclosure, publication or cession.'
                }</p>
                <p className="content">{
                  lang === 'es' ? 'm. Autorización: Consentimiento previo, expreso e informado del Titular\n' +
                  'para llevar a cabo el tratamiento de datos personales.' : 
                  'm. Authorization: Consent prior, express and informed of the Holder\n' +
                  'to carry out the treatment of personal data.'
                }</p>
                <p className="content">{
                  lang === 'es' ? 'n. Aviso de privacidad: Comunicación verbal o escrita dirigida a los\n' +
                  'Titulares de los datos personales que están siendo tratados por la\n' +
                  'empresa, en la cual se le informa acerca de la existencia de las políticas\n' +
                  'de tratamiento de datos personales que le serán aplicadas, la forma de\n' +
                  'acceder a la mismas, y las finalidades para las cuales serán usados sus\n' +
                  'datos personales.' : 
                  'n. Privacy notice: Verbal or written communication addressed to the\n' +
                  'holders of the personal data that are being treated by the\n' +
                  'company, in which it is informed about the existence of the policies\n' +
                  'of treatment of personal data that will be applied to them, the way to\n' +
                  'access them, and the purposes for which their\n' +
                  'personal data will be used.'
                }</p>
                <p className="content">{
                  lang === 'es' ? 'o. Tratamiento: Cualquier operación o conjunto de operaciones sobre\n' +
                  'datos personales, tales como la recolección, almacenamiento, uso,\n' +
                  'circulación o supresión.' : 
                  'o. Treatment: Any operation or set of operations on\n' +
                  'personal data, such as the collection, storage, use,\n' +
                  'circulation or suppression.'
                }</p>
                <p className="content">{
                  lang === 'es' ? 'p. Responsable del tratamiento: Persona natural o jurídica de carácter\n' +
                  'público o privado que por sí misma o en asocio con otro u otros decide\n' +
                  'sobre el tratamiento de datos personales. En este caso, será COALLY\n' +
                  'S.A.S. el Responsable del tratamiento.' : 'p. Treatment responsible: Natural or legal person of public or private character\n' +
                  'that by itself or in association with another or others decides\n' +
                  'on the treatment of personal data. In this case, COALLY\n' +
                  'S.A.S. will be the Treatment Responsible.'
                }</p>
              </div>
              <div className="section">
                <h3 className="title">{
                  lang === 'es' ? '4. Principios rectores para el tratamiento de los datos personales' : 
                  '4. Guiding principles for the treatment of personal data'
                }</h3>
                <p className="content">{
                  lang === 'es' ? 'Según lo establecido en el Título II de la Ley Estatutaria 1581 de 2012, la\n' +
                  'protección de datos personales se regirá por la aplicación armónica e integral de\n' +
                  'los siguientes principios:': 
                  'According to what is established in Title II of Law 1581 of 2012, the\n' +
                  'protection of personal data will be governed by the harmonious and integral application of\n' +
                  'the following principles:'
                }</p>
                <p className="sub-content">{
                  lang === 'es' ? 'a. Principio de legalidad en el tratamiento de datos personales: El\n' +
                  'tratamiento de datos personales a que se refiere la Ley Estatutaria 1581 de\n' +
                  '2012 es una actividad reglada que debe sujetarse a lo establecido en ella y\n' +
                  'en las demás disposiciones que la desarrollen.' :
                  'a. Principle of legality in the treatment of personal data: The\n' +
                  'treatment of personal data referred to in Law 1581 of\n' +
                  '2012 is a regulated activity that must be subject to what is established in it and\n' +
                  'in the other provisions that develop it.'
                }</p>
                <p className="sub-content">{
                  lang === 'es' ? 'b. Principio de finalidad: El tratamiento de los datos personales debe obedecer a una finalidad legítima de acuerdo con la Constitución y la ley, la cual debe ser informada al Titular.' : 
                  'b. Purpose principle: The treatment of personal data must be in accordance with a legitimate purpose in accordance with the Constitution and the law, which must be informed to the Holder.'
                }</p>
                <p className="sub-content">{
                  lang === 'es' ? 'c. Principio de libertad: El tratamiento de los datos personales sólo puede ejercerse con el consentimiento, previo, expreso e informado del Titular.Los datos personales no podrán ser obtenidos o divulgados sin previa autorización, o en ausencia de mandato legal o judicial que releve el consentimiento.' : 
                  'c. Freedom principle: The treatment of personal data can only be exercised with the consent, prior, express and informed of the Holder. Personal data may not be obtained or disclosed without prior authorization, or in the absence of a legal or judicial mandate that relieves the consent.'
                }</p>
                <p className="sub-content">{
                  lang === 'es' ? 'd. Principio de veracidad o calidad: La información sujeta a tratamiento debe ser veraz, completa, exacta, actualizada, comprobable y comprensible. Se prohíbe el Tratamiento de datos parciales, incompletos, fraccionados o que induzcan a error.' : 
                  'd. Principle of truth or quality: The information subject to treatment must be true, complete, accurate, updated, verifiable and understandable. Partial, incomplete, fragmented or misleading data is prohibited.'
                }</p>
                <p className="sub-content">{
                  lang === 'es' ? 'e. Principio de transparencia: En el Tratamiento de los datos personales debe garantizarse el derecho del Titular a obtener del Responsable del tratamiento o del Encargado del tratamiento, en cualquier momento y sin restricciones, información acerca de la existencia de datos que le concierne.' :
                  'e. Principle of transparency: In the Treatment of personal data, the right of the Holder to obtain from the Treatment Responsible or the Treatment Manager, at any time and without restrictions, information about the existence of data that concerns him must be guaranteed.'
                }</p>
                <p className="sub-content">{
                  lang === 'es' ? 'f. Principio de seguridad: La información sujeta a tratamiento por el Responsable del Tratamiento o Encargado del tratamiento a que se refiere la Ley Estatutaria 1581 de 2012, se deberá manejar con las medidas técnicas, humanas y administrativas que sean necesarias para otorgar seguridad a los registros evitando su adulteración, pérdida, consulta, uso o acceso no autorizado o fraudulento.' :
                  'f. Security principle: The information subject to treatment by the Treatment Responsible or Treatment Manager referred to in Law 1581 of 2012, must be handled with the technical, human and administrative measures that are necessary to provide security to the records preventing its alteration, loss, consultation, use or unauthorized or fraudulent access.'
                }</p>
                <p className="sub-content">{
                  lang === 'es' ? 'g. Principio de confidencialidad: Todas las personas que intervengan en el tratamiento de datos personales que no tengan la naturaleza de públicos están obligadas a garantizar la reserva de la información, inclusive después de finalizada su relación con alguna de las labores que comprende el tratamiento, pudiendo sólo realizar suministro o comunicación de datos personales cuando ello corresponda al desarrollo de las actividades autorizadas en la Ley Estatutaria 1581 de 2012 y en los términos de la misma.' :
                  'g. Principle of confidentiality: All persons who intervene in the treatment of personal data that do not have the nature of public are obliged to guarantee the confidentiality of the information, even after the end of their relationship with any of the tasks that comprise the treatment, may only make supply or communication of personal data when it corresponds to the development of the activities authorized in Law 1581 of 2012 and in the terms of the same.'
                }</p>
                <p className="sub-content">{
                  lang === 'es' ? 'h. Principio de acceso y circulación restringida: El tratamiento se sujeta a los límites que se derivan de la naturaleza de los datos personales, de las disposiciones de la Ley Estatutaria 1581 de 2012 y la Constitución. En este sentido, el tratamiento sólo podrá hacerse por personas autorizadas por el Titular y/o por las personas previstas en la mencionada ley.' :
                  'h. Principle of restricted access and circulation: The treatment is subject to the limits that derive from the nature of the personal data, the provisions of Law 1581 of 2012 and the Constitution. In this sense, the treatment can only be done by authorized persons by the Holder and/or by the persons provided for in the aforementioned law.'
                }</p>
              </div>
              <div className="section">
                <h3 className="title">{
                  lang === 'es' ? '5. Autorización para el tratamiento de datos personales y casos en\n' +
                  'los que no es necesaria la autorización' : 
                  '5. Authorization for the treatment of personal data and cases in\n' +
                   'which no authorization is required'
                }</h3>
                <p className="content">{
                  lang === 'es' ? 'El consentimiento y autorización por parte del Titular de la información es un\n' +
                  'requisito constitucional y legal que deben cumplir las personas responsables del\n' +
                  'tratamiento de datos personales. El consentimiento debe cumplir con los\n' +
                  'siguientes presupuestos:' : 
                  'Consent and authorization by the Holder of the information is a\n' +
                  'constitutional and legal requirement that must be met by the persons responsible for\n' +
                  'the treatment of personal data. Consent must meet the following premises:'
                }</p>
                <p className="content">{
                  lang === 'es' ? 'Previo: La autorización la debe dar el Titular de información de manera previa a\n' +
                  'cualquier tipo de Tratamiento de datos personales.' :
                  'Previous: The authorization must be given by the Holder of information prior to any type of Treatment of personal data.'
                }</p>
                <p className="content">{
                  lang === 'es' ? 'Expreso: La autorización debe otorgarse de forma inequívoca, clara y específica.' :
                  'Express: The authorization must be granted in an unequivocal, clear and specific manner.'
                }</p>
                <p className="content">{
                  lang === 'es' ? 'Informado: El Titular debe comprender claramente para qué serán tratados sus\n' +
                  'datos personales y las finalidades que pueden derivarse del Tratamiento de los\n' +
                  'mismos.' :
                  'Informed: The Holder must clearly understand for what his personal data will be treated and the purposes that may arise from the Treatment of the same.'
                }</p>
                <p className="content">{
                  lang === 'es' ? 'Todos los visitantes de las Plataformas de COALLY S.A.S. deben registrarse y autorizar el tratamiento de los datos personales para poder hacer uso de los servicios ofrecidos. Por tanto, en cada uno de los sistemas se encuentra una casilla que dice “Política de privacidad y Tratamiento de Datos Personales” la cual debe ser leída y aceptada para poder continuar con el uso de los servicios de COALLY S.A.S.' :
                  'All visitors to the Platforms of COALLY S.A.S. must register and authorize the treatment of personal data in order to use the services offered. Therefore, in each of the systems there is a box that says "Privacy Policy and Treatment of Personal Data" which must be read and accepted in order to continue using the services of COALLY S.A.S.'
                }</p>
              </div>
              <div className="section">
                <h3 className="title">{
                  lang === 'es' ? '6. TIPOS DE DATOS RECOPILADOS POR COALLY S.A.S' :
                  '6. TYPES OF DATA COLLECTED BY COALLY S.A.S'
                }</h3>
                <p className="content">{
                  lang === 'es' ? 'Coally S.A.S recopilará datos proporcionados por los usuarios de Coally, como los\n' +
                  'generados durante la creación de cuentas, aquellos proporcionados en el uso de\n' +
                  'los servicios.' : 
                  'Coally S.A.S will collect data provided by Coally users, such as those generated during the creation of accounts, those provided in the use of services.'
                }</p>
                <p className="content">{
                  lang === 'es' ? 'a. Perfil del usuario: datos generados cuando se crean o actualizan las\n' +
                  'cuentas. Esto puede incluir nombre, correo electrónico, número de teléfono,\n' +
                  'nombre de usuario, contraseña, documento de identificación, fotos de perfil.' :
                  'a. User profile: data generated when accounts are created or updated. This may include name, email, phone number, username, password, identification document, profile photos.'
                }</p>
                <p className="content">{
                  lang === 'es' ? 'b. Datos demográficos: se recopilarán datos demográficos de los usuarios\n' +
                  'como dirección, edad, género, lugar de residencia, carrera y áreas de\n' +
                  '\n' +
                  'interés. También podrá recolectarse a través de las encuestas destinadas a\n' +
                  'ello' :
                  'b. Demographic data: demographic data from users will be collected such as address, age, genre, place of residence, career and areas of interest. It can also be collected through surveys intended for this purpose'
                }</p>
                <p className="content">{
                  lang === 'es' ? 'c. Datos de uso: se recopilan datos sobre el servicio prestado o el servicio\n' +
                  'solicitado, esto incluye el tipo de servicio solicitado o prestado, detalles del\n' +
                  'servicio, información sobre el momento en el que se prestó el servicio y la\n' +
                  'frecuencia de solicitud o prestación del mismo.' :
                  'c. Usage data: data on the service provided or the service requested is collected, this includes the type of service requested or provided, details of the service, information about the time when the service was provided and the frequency of request or provision of the same.'
                } </p>
                <p className="content">{
                  lang === 'es' ? 'd. Otros datos: comentarios del cliente, calificaciones y opiniones del servicio prestado.' :
                  'd. Other data: customer comments, ratings and opinions of the service provided.'
                } </p>
              </div>
              <div className="section">
                <h3 className="title">{
                  lang === 'es' ? '7. Finalidad de tratamiento de los datos personales' : 
                  '7. Purpose of treatment of personal data'
                }</h3>
                <p className="content">{
                  lang === 'es' ? 'Los Datos Personales que recolecta  Coally S.A.S., son incluidos en una Base de\n' +
                  'Datos a la cual tiene acceso el personal autorizado de COALLY S.A.S. en\n' +
                  'ejercicio de sus funciones, advirtiendo que en ningún caso está autorizado el\n' +
                  'Tratamiento de la información para fines diferentes a los aquí descritos, y que le\n' +
                  'sean comunicados al Titular directamente a más tardar al momento de la\n' +
                  'recolección.' : 'The Personal Data collected by Coally S.A.S., are included in a Database to which the authorized personnel of COALLY S.A.S. has access in the exercise of their functions, warning that in no case is the Treatment of the information for purposes different from those described here, and that they are communicated to the Holder directly at the latest at the time of collection.'
                }</p>
                <p className="content">{
                  lang === 'es' ? 'La recolección, almacenamiento, uso y/o circulación de los datos personales\n' +
                  'recolectados por Coally S.A.S tiene como finalidad principal prestar los servicios\n' +
                  'ofrecidos de manera adecuada y lograr el cumplimiento de las expectativas de los\n' +
                  'usuarios de la plataforma web y móvil.' : 
                  'The collection, storage, use and / or circulation of the personal data collected by Coally S.A.S has as its main purpose to provide the services offered in an appropriate manner and achieve the fulfillment of the expectations of the users of the web and mobile platform.'
                }</p>
                <p className="content">{
                  lang === 'es' ? 'Adicionalmente, con el fin de desarrollar los objetos social de la compañía, Coally S.A.S podrá hacer tratamiento de datos con las siguientes finalidades:' :
                  'In addition, in order to develop the social objects of the company, Coally S.A.S may make data treatment with the following purposes:'
                }</p>
                <p className="content">{
                  lang === 'es' ? 'Finalidades del tratamiento de los datos personales de los Usuarios' :
                  'Purposes of the treatment of the personal data of the Users'
                }</p>
                <p className="content">{
                  lang === 'es' ? '1. Recolectar los datos personales de los usuarios e incorporarlos y\n' +
                  'almacenarlos en la base de datos de Coally S.A.S.' :
                  '1. Collect the personal data of the users and incorporate them and store them in the Coally S.A.S database.'
                }</p>
                <p className="content">{
                  lang === 'es' ? '2. Ordenar, catalogar, clasificar, dividir o separar la información suministrada.' :
                  '2. Order, catalog, classify, divide or separate the information provided.'
                }</p>
                <p className="content">{
                  lang === 'es' ? '3. Utilizar los datos suministrados en campañas de comunicación, divulgación\n' +
                  'y promoción u oferta de productos, actividades y/o servicios desarrollados\n' +
                  'como parte de estrategias internas de la compañía.' :
                  '3. Use the data provided in communication, dissemination and promotion campaigns or offer of products, activities and / or services developed as part of internal strategies of the company.'
                }</p>
                <p className="content">{
                  lang === 'es' ? '4. Conservar registros históricos de la compañía y mantener contacto con los titulares del dato.' :
                  '4. Keep historical records of the company and maintain contact with the holders of the data.'
                }</p>
                <p className="content">{
                  lang === 'es' ? '5. Verificar, comprobar o validar los datos suministrados.' :
                  '5. Verify, check or validate the data provided.'
                }</p>
                <p className="content">{
                  lang === 'es' ? '6. Transferir los datos personales a cualquier país o servidor en otro país.' : 
                  '6. Transfer the personal data to any country or server in another country.'
                }</p>
                <p className="content">{
                  lang === 'es' ? '7. Compartir, transferir los datos personales a aliados y a los aliados de nuestros aliados.' : 
                  '7. Share, transfer personal data to allies and to the allies of our allies.'
                }</p>
                <p className="content">{
                  lang === 'es' ? '8.  Comunicar y permitir el acceso a los datos personales suministrados a terceros proveedores de servicios de apoyo general y a las personas naturales o jurídicas accionistas de COALLY S.A.S.' :
                  '8. Communicate and allow access to the personal data provided to third party general support service providers and to natural or legal persons shareholders of COALLY S.A.S.'
                }</p>
                <p className="content">{
                  lang === 'es' ? '9. Realizar de conformidad con la ley los reportes a centrales de riesgo por incumplimiento de las obligaciones financieras derivadas de la relación comercial.' :
                  '9. Make the reports to credit bureaus in accordance with the law for non-compliance with financial obligations arising from the commercial relationship.'
                }</p>
                <p className="content">{
                  lang === 'es' ? '10. Envío de comunicaciones a través de cualquier canal incluyendo, pero sin limitarse a, redes sociales, mensajes de texto, notificaciones push, correo electrónico, llamada telefónica, etc., relacionadas con el objeto social de COALLY S.A.S. tales como actividades de mercadeo y/o cualquier solicitud realizada por el titular a COALLY S.A.S, entre otros.' : 
                  '10. Sending communications through any channel including, but not limited to, social networks, text messages, push notifications, email, telephone call, etc., related to the social object of COALLY S.A.S. such as marketing activities and / or any request made by the holder to COALLY S.A.S, among others.'
                }</p>
                <p className="content">{
                  lang === 'es' ? '11. Prestar los servicios ofrecidos y/o contratados de forma adecuada y con una excelente calidad' : '11. Provide the services offered and / or contracted in an appropriate and excellent quality manner'
                }</p>
              </div>
              <div className="section">
                <h3 className="title">{
                  lang === 'es' ? '8. Normatividad aplicable' : '8. Applicable legislation'
                }</h3>
                <p className="content">{
                  lang === 'es' ? 'El tratamiento de los Datos personales del titular se realizará con base en lo\n' +
                  'dictado en la presente política de privacidad y lo estipulado en la ley 1581 de\n' +
                  '2012, el Decreto 1377 de 2013, el Decreto 886 de 2014, normas compiladas en el\n' +
                  'Decreto 1074 de 2015 de la República de Colombia, y las demás normas que\n' +
                  'adicionen o modifiquen.' :
                  'The treatment of the personal data of the holder will be based on what is dictated in this privacy policy and stipulated in Law 1581 of 2012, Decree 1377 of 2013, Decree 886 of 2014, compiled in the Decree 1074 of 2015 of the Republic of Colombia, and the other rules that add or modify.'
                }</p>
              </div>
              <div className="section">
                <h3 className="title">{
                  lang === 'es' ? '9. Derechos de los titulares' : '9. Rights of the holders'
                }</h3>
                <p className="content">{
                  lang === 'es' ? 'En cumplimiento de las garantías fundamentales consagradas en la Constitución y\n' +
                  'la ley, y sin perjuicio de lo dispuesto en las demás normas que regulen la materia,\n' +
                  'los Titulares de los datos personales podrán ejercer de forma gratuita e\n' +
                  'ilimitadamente los siguientes derechos:' :
                  'In compliance with the fundamental guarantees enshrined in the Constitution and the law, and without prejudice to what is provided in the other rules that regulate the matter, the holders of the personal data may exercise free of charge and unlimitedly the following rights:'
                }</p>
                <p className="content">{
                  lang === 'es' ? 'a. Derecho al acceso a su información personal objeto de tratamiento.' :
                  'a. Right to access your personal information subject to treatment.'
                }</p>
                <p className="content">{
                  lang === 'es' ? 'b. Derecho a la actualización de los datos personales objeto de tratamiento.' : 
                  'b. Right to update the personal data subject to treatment.'
                }</p>
                <p className="content">{
                  lang === 'es' ? 'c. Derecho a la rectificación de los datos personales objeto de tratamiento.' :
                  'c. Right to rectify the personal data subject to treatment.'
                }</p>
                <p className="content">{
                  lang === 'es' ? 'd. Derecho de oposición a que los datos personales sean objeto de\n' +
                  'tratamiento' : 
                  'd. Right of opposition to the personal data being subject to treatment'
                }</p>
                <p className="content">{
                  lang === 'es' ? 'e. Derecho a solicitar la supresión de los datos personales cuando en el\n' +
                  'tratamiento no se respeten los principios, derechos y garantías\n' +
                  'constitucionales y legales.' :
                  'e. Right to request the deletion of the personal data when in the treatment the constitutional and legal principles, rights and guarantees are not respected.'
                }</p>
                <p className="content">{
                  lang === 'es' ? 'f. Derecho a solicitar prueba de la autorización otorgada para el\n' +
                  'tratamiento.' :
                  'f. Right to request proof of the authorization granted for the treatment.'
                }</p>
                <p className="content">{
                  lang === 'es' ? 'g. Derecho a revocar el consentimiento para el tratamiento de los datos\n' +
                  'personales.' :
                  'g. Right to revoke the consent for the treatment of the personal data.'
                }</p>
                <p className="content">{
                  lang === 'es' ? 'h. Derecho a presentar quejas y reclamos ante la Superintendencia de\n' +
                  'Industria y Comercio por infracciones a lo dispuesto en la Ley Estatutaria\n' +
                  '1581 de 2012 y las demás normas que la modifiquen, adicionen o\n' +
                  'complementen.' : 
                  'h. Right to present complaints and claims before the Superintendence of Industry and Commerce for infractions to what is provided in Law 1581 of 2012 and the other rules that modify, add or complement it.'
                }</p>
                <p className="content">{
                  lang === 'es' ? 'i. Derecho a ser informado por parte del Responsable y/o Encargado del\n' +
                  'uso y tratamiento que se les dará a los datos personales, así como de las\n' +
                  'modificaciones y actualizaciones de las políticas de protección, medidas de\n' +
                  'seguridad y finalidades.' : 
                  'i. Right to be informed by the Responsible and / or Holder of the use and treatment that will be given to the personal data, as well as the modifications and updates of the protection policies, security measures and purposes.'
                }</p>
              </div>
              <div className="section">
                <h3 className="title">{
                  lang === 'es' ? '10. Identificación del responsable y/o encargado del tratamiento:' : 
                  '10. Identification of the responsible and / or holder of the treatment:'
                }</h3>
                <p className="content">{
                  lang === 'es' ? 'Coally S.A.S es responsable del tratamiento de sus datos personales y hará uso\n' +
                  'de estos únicamente para las finalidades que se encuentra facultado.\n' +
                  'Especialmente las señaladas en la presente política' : 'Coally S.A.S is responsible for the treatment of your personal data and will only use these for the purposes for which it is empowered.'
                }</p>
                <p className="content">{
                  lang === 'es' ? 'Coally S.A.S\n' +
                  'NIT: 901. 420 57 4 - 6\n' +
                  'Domicilio: calle 19 #4 56 apartamento 1403\n' +
                  'Sitio web: coally.com\n' +
                  'Correo electrónico: coally3@gmail.com\n' +
                  'Teléfono: 316 359 05 61' :
                  'Coally S.A.S\n' +
                  'NIT: 901. 420 57 4 - 6\n' +
                  'Address: street 19 #4 56 apartment 1403\n' +
                  'Website: coally.com\n' +
                  'Email: coally3@gmail.com\n' +
                  'Phone: 316 359 05 61'
                }</p>
              </div>
              <div className="section">
                <h3 className="title">{
                  lang === 'es' ? '11. Deberes del responsable de los datos personales.' :
                  '11. Duties of the holder of the personal data.'
                }</h3>
                <p className="content">{
                  lang === 'es' ? 'En línea con la normatividad vigente en tratamiento de datos Coally S.A.S deberá\n' +
                  'cumplir con los siguientes deberes:' :
                  'In line with the current regulations on data treatment, Coally S.A.S must comply with the following duties:'
                }</p>
                <p className="content">{
                  lang === 'es' ? 'a. Garantizar al titular, en todo tiempo, el pleno y efectivo ejercicio del derecho de hábeas data' : 
                  'a. Guarantee the holder, at all times, the full and effective exercise of the right of habeas data'
                }</p>
                <p className="content">{
                  lang === 'es' ? 'b. Solicitar y conservar la reserva y autorización otorgada por el titular' :
                  'b. Request and keep the reservation and authorization granted by the holder'
                }</p>
                <p className="content">{
                  lang === 'es' ? 'c. Informar al titular sobre la finalidad de la recolección de datos y los derechos que le asisten en virtud de la autorización otorgada.' :
                  'c. Inform the holder about the purpose of the data collection and the rights that he has in virtue of the authorization granted.'
                }</p>
                <p className="content">{
                  lang === 'es' ? 'd. Conservar la información bajo las condiciones de seguridad necesarias para impedir adulteración, pérdida, o acceso no autorizado' : 
                  'd. Keep the information under the necessary security conditions to prevent tampering, loss, or unauthorized access'
                }</p>
                <p className="content">{
                  lang === 'es' ? 'e. Actualizar la información y adoptar las medidas necesarias para que la información suministrada se mantenga actualizada' :
                  'e. Update the information and take the necessary measures so that the information provided is kept up to date'
                }</p>
              </div>
              <div className="section">
                <h3 className="title">{
                  lang === 'es' ? '12. Consultas y reclamos' : 
                  '12. Queries and complaints'
                }</h3>
                <p className="content">{
                  lang === 'es' ? 'Las consultas, quejas o reclamos podrán ser presentados a través de un mensaje\n' +
                  'de datos:' :
                  'Queries, complaints or claims may be presented through a message of data:'
                }</p>
                <p className="content">{
                  lang === 'es' ? 'Consultas' : 'Queries'
                }</p>
                <p className="content">{
                  lang === 'es' ? 'Tratándose del derecho de petición en calidad de información y/o consultas, Coally\n' +
                  'S.A.S. dará respuesta en un término máximo de diez (10) días hábiles contados a\n' +
                  'partir del día siguiente a la fecha de recepción de la petición o consulta.' : 'In the case of the right of petition in the quality of information and / or queries, Coally S.A.S. will give an answer in a maximum term of ten (10) working days counted from the day after the date of receipt of the request or query.'
                }</p>
                <p className="content">{
                  lang === 'es' ? 'Cuando no fuere posible atender la consulta dentro de dicho término se informará\n' +
                  'al interesado, indicando los motivos de la demora y señalando la fecha en que\n' +
                  'esta se atenderá, la cual en ningún caso podrá superar cinco (5) días hábiles\n' +
                  'siguientes al vencimiento del primer término. El documento de consulta deberá\n' +
                  'contener lo siguiente:' : 
                  'When it is not possible to attend the consultation within said term, it will be informed to the interested party, indicating the reasons for the delay and indicating the date on which this will be attended, which in no case may exceed five (5) working days following the expiration of the first term. The consultation document must contain the following:'
                }</p>
                <p className="content">{
                  lang === 'es' ? '1. Área de Coally S.A.S a la cual va dirigida (Servicio al Cliente)' :
                  '1. Coally S.A.S area to which it is addressed (Customer Service)'
                }</p>
                <p className="content">{
                  lang === 'es' ? '2. El nombre y número de identificación del Titular.' :
                  '2. The name and identification number of the Holder.'
                }</p>
                <p className="content">{
                  lang === 'es' ? '3. Copia documento de identidad del titular' :
                  '3. Copy of the identity document of the holder'
                }</p>
                <p className="content">{
                  lang === 'es' ? 'Quejas y/o reclamos' : 'Complaints and / or claims'
                }</p>
                <p className="content">{
                  lang === 'es' ? 'Cuando el Titular considere que su información debe ser objeto de corrección, actualización o supresión o cuando advierta un presunto incumplimiento de cualquiera de sus derechos, el término máximo para atender la queja o reclamoserá de quince (15) días hábiles contados a partir del día siguiente a la fecha de recepción del documento.' :
                  'When the Holder considers that his information should be subject to correction, updating or deletion or when he notices a possible breach of any of his rights, the maximum term to attend the complaint or claim will be fifteen (15) working days counted from the day after the date of receipt of the document.'
                }</p>
                <p className="content">{
                  lang === 'es' ? 'Cuando no fuere posible atender el reclamo dentro de dicho término, se informará\n' +
                  'al interesado los motivos de la demora y la fecha en que será atendido, la cual en\n' +
                  'ningún caso podrá superar los ocho (8) días hábiles siguientes al vencimiento del\n' +
                  'primer término. Si el reclamo resulta incompleto, se requerirá al interesado dentro\n' +
                  'de los cinco (5) días siguientes a la recepción de la queja y/o reclamo para que\n' +
                  'subsane las fallas. Transcurridos dos (2) meses desde la fecha del requerimiento\n' +
                  '\n' +
                  'sin que el solicitante presente la información requerida, se entenderá desistida la\n' +
                  'queja o reclamo. El documento de queja o reclamo deberá contener:' :
                  'When it is not possible to attend the claim within said term, the interested party will be informed of the reasons for the delay and the date on which it will be attended, which in no case may exceed the eight (8) working days following the expiration of the first term. If the claim is incomplete, the interested party will be required within five (5) days following the receipt of the complaint and / or claim to remedy the shortcomings. After two (2) months from the date of the request without the applicant presenting the required information, the complaint or claim will be understood to have been withdrawn. The complaint or claim document must contain:'
                }</p>
                <p className="content">{
                  lang === 'es' ? '1. Área de Coally S.A.S a la cual va dirigida (Servicio al cliente)' :
                  '1. Coally S.A.S area to which it is addressed (Customer Service)'
                }</p>
                <p className="content">{
                  lang === 'es' ? '2. El nombre y número de identificación del Titular.' :
                  '2. The name and identification number of the Holder.'
                }</p>
                <p className="content">{
                  lang === 'es' ? '3. Copia documento de identidad del titular.' :
                  '3. Copy of the identity document of the holder.'
                }</p>
              </div>
              <div className="section">
                <h3 className="title">{
                  lang === 'es' ? '13. Modificaciones' : '13. Modifications'
                }</h3>
                <p className="content">{
                  lang === 'es' ? 'Es posible que actualicemos la presente Política de Datos ocasionalmente. En\n' +
                  'caso de realizarse cambios se le notificará a los usuarios a través de la aplicación\n' +
                  'o la página web. Por lo tanto, aceptar el uso de los servicios después del aviso de\n' +
                  'una actualización constituye el consentimiento del usuario sobre el contenido de\n' +
                  'esta.' :
                  'We may occasionally update this Data Policy. In case of changes, users will be notified through the application or the website. Therefore, accepting the use of the services after the notice of an update constitutes the user\'s consent to the content of this.'
                }</p>
              </div>
              <div className="section">
                <h3 className="title">{
                  lang === 'es' ? '14. Vigencia' : '14. Validity'
                }</h3>
                <p className="content">{
                  lang === 'es' ? 'La presente Política entra en vigencia a partir del día 01 del mes de Enero de\n' +
                  '2021.' :
                  'This Policy comes into force as of January 01, 2021.'
                }</p>
                <p className="content">{
                  lang === 'es' ? 'Los Datos Personales que sean almacenados, utilizados o transmitidos permanecerán en las bases de datos de Coally S.A.S durante el tiempo que sea necesario para cumplir con las finalidades expuestas en este manual o para que la Empresa pueda cumplir con sus deberes legales.' :
                  'Personal Data that are stored, used or transmitted will remain in the databases of Coally S.A.S for the time necessary to comply with the purposes set out in this manual or for the Company to be able to comply with its legal obligations.'
                }</p>
                <p className="content">{
                  lang === 'es' ? 'Sin embargo, la información será revisada cada año para verificar la veracidad del dato y finalidad de seguir con su tratamiento. De ser necesario Coally S.A.S se reserva el derecho a modificar la presente Política de forma unilateral; sin perjuicio de esto, en caso de generarse algún cambio sustancial sobre la Política, se comunicará a los Titulares.' :
                  'However, the information will be reviewed every year to verify the accuracy of the data and purpose of continuing with its treatment. If necessary, Coally S.A.S reserves the right to unilaterally modify this Policy; without prejudice to this, in case of generating any substantial change in the Policy, it will be communicated to the Holders.'
                }</p>
              </div>

    </>
  )
}

export default TermsAndConditions