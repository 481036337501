import React from 'react';
import { Route, Switch } from "react-router-dom";
import UniversitiesLogin from "../login/UniversitiesLogin";
import UniversityContextProvider from "../context/UniversitiesContext";
import UniversitiesHome from "../home/UniversitiesHome";
import UniversitiesOportunidades from "../oportunidades/UniversitiesOpotunidades";
import UniversitiesCareerIa from "../home/careerIA/UniversitiesCareerIa";
import UniversitiesStudentProfile from "../profile/UniversitiesStudentProfile";
import UniversitiesDetalleoportunidad from "../oportunidades/detail/UniversitiesDetalleoportunidad";
import DashboardCard from "../components/dashboardCard/DashboardCard";
import UniversitiesAdmin from "../admin/UniversitieAdmin";
import HomeAdmBootcamp from "../bootcamp/administracion/HomeAdmBootcamp";
import EmpresaCustomHome from "../empresas/home/EmpresaCustomHome";
import OportunidadescompanyCustom from "../empresas/oportunidades.jsx/Oportunidadescompany";
import DashboardOportunidadCustom from "../empresas/dashboardOportunidad/DashboardOportunidadCustom";
import CompanyProfileCustom from "../empresas/CompanyProfile/CompanyProfile";
import ProfessionalProfileCustom from "../empresas/professionalprofile/ProfessionalProfileNew";
import NewEducationUniversities from "../newEducacion/Formacion";
import { SectorVideosUniversities } from "../newEducacion/SectorVideos";
import UniversitiesRegister from "../register/UniversitiesRegister.tsx";
import VerificationOtp from "../verificationOtp/VerificationOtp";
import UniversitiesRecover from '../recover/UniversitiesRecover';

const UniversitiesRoutes = () => {
  return (
    <Switch>
      {/*<---------------------------PROFESIONALES-------------------->*/}
      <Route exact path="/:universitie/home" render={({ match }) => (
        <WithUniversity match={match}>
          <UniversitiesHome />
        </WithUniversity>
      )} />
      <Route exact path="/:universitie" render={({ match }) => (
        <WithUniversity match={match}>
          <UniversitiesLogin />
        </WithUniversity>
      )} />
      <Route exact path='/:universitie/register' render={({ match }) => (
        <WithUniversity match={match}>
          <UniversitiesRegister />
        </WithUniversity>
      )} />
      <Route exact path='/:universitie/verify' render={({ match }) => (
        <WithUniversity match={match}>
          <VerificationOtp />
        </WithUniversity>
      )} />
      <Route exact path="/:universitie/recover" render={({ match }) => (
        <WithUniversity match={match}>
          <UniversitiesRecover />
        </WithUniversity>
      )} />
      <Route exact path="/:universitie/oportunidades" render={({ match }) => (
        <WithUniversity match={match}>
          <UniversitiesOportunidades />
        </WithUniversity>
      )} />
      <Route exact path="/:universitie/career-ia" render={({ match }) => (
        <WithUniversity match={match}>
          <UniversitiesCareerIa />
        </WithUniversity>
      )} />
      <Route exact path="/:universitie/profile/:id" render={({ match }) => (
        <WithUniversity match={match}>
          <UniversitiesStudentProfile />
        </WithUniversity>
      )} />
      <Route exact path="/:universitie/project/:id" render={({ match }) => (
        <WithUniversity match={match}>
          <UniversitiesDetalleoportunidad />
        </WithUniversity>
      )} />
      <Route exact path="/:universitie/formacion" render={({ match }) => (
        <WithUniversity match={match}>
          <NewEducationUniversities />
        </WithUniversity>
      )} />
      <Route exact path="/:universitie/formacion/:sector" render={({ match }) => (
        <WithUniversity match={match}>
          <SectorVideosUniversities />
        </WithUniversity>
      )} />
      {/*  test routes*/}
      <Route exact path="/:universitie/test" render={({ match }) => (
        <WithUniversity match={match}>
          <DashboardCard />
        </WithUniversity>
      )} />
      <Route exact path="/:universitie/admin" render={({ match }) => (
        <WithUniversity match={match}>
          <UniversitiesAdmin />
        </WithUniversity>
      )} />

      {/*<---------------------------EMPRESAS-------------------->*/}
      <Route exact path="/:universitie/company/Home" render={({ match }) => (
        <WithUniversity match={match}>
          <EmpresaCustomHome />
        </WithUniversity>
      )} />
      <Route exact path="/:universitie/company/project" render={({ match }) => (
        <WithUniversity match={match}>
          <OportunidadescompanyCustom />
        </WithUniversity>
      )} />
      <Route exact path="/:universitie/company/profile" render={({ match }) => (
        <WithUniversity match={match}>
          <CompanyProfileCustom />
        </WithUniversity>
      )} />
      <Route exact path="/:universitie/company/profile/:id" render={({ match }) => (
        <WithUniversity match={match}>
          <ProfessionalProfileCustom />
        </WithUniversity>
      )} />
      <Route exact path="/:universitie/company/CompanyDashboard/:id" render={({ match }) => (
        <WithUniversity match={match}>
          <DashboardOportunidadCustom />
        </WithUniversity>
      )} />
      {/*<---------------------------BOOTCAMP-------------------->*/}
      <Route exact path="/:universitie/bootcamp/administracion" render={({ match }) => (
        <WithUniversity match={match}>
          <HomeAdmBootcamp />
        </WithUniversity>
      )} />
    </Switch>
  );
}

const WithUniversity = ({ match, children }) => (
  <UniversityContextProvider match={match}>
    {children}
  </UniversityContextProvider>
)

export default UniversitiesRoutes;
