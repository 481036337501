import axios from 'axios'
import React, { useEffect, useState } from 'react'
import cesdeimg from '../../src/pages/styles/cesde.png'
import en from "../lang/en-US";
import es from "../lang/es-COL"
import counterpart from "counterpart";
import Translate from "react-translate-component";

export default function Cesde() {
    
  counterpart.registerTranslations('en',en) 
  counterpart.registerTranslations('es',es)  
  counterpart.setLocale(localStorage.getItem('langlocal'));
    const [data, setData] = useState('')
    const llamada=async()=>{
       await axios.get("https://findally-backend.herokuapp.com/api/Project/cesde/data").then(res=>{
           setData(res.data)
        })
    }
    
    console.log(data)
useEffect(() => {
    llamada()
    
}, [])
    return (
        <div style={{display:'flex',justifyContent:'center',alignItems:'center',width:'100%',flexDirection:'column',margin:'0'}}>
            <div style={{display:'flex',width:'100%'}}>
                <div style={{display:'flex',justifyContent:'center',width:'100%',border:'solid 5px #21204a',padding:'10px',background:'#21204a'}}>
                    
                    <img style={{width:'20%'}} src={cesdeimg} alt="cesdeimg" />
                </div>
            </div>
            <div style={{display:'flex',width:'80%',flexDirection:'column',marginTop:'10%'}}>
            <div style={{display:'flex',width:'100%',height:'max-content'}}>
                    <div style={{width:'16.66%',textAlign:'center',border:'solid 4px #21204a'}}>
                      <div style={{overflowWrap:'break-word',color:'white',background:'#21204a'}}>  {<Translate content="EMPRESAaa" component="span"/>}</div></div>
                    <div style={{width:'16.66%',textAlign:'center',border:'solid 4px #21204a'}}>
                      <div style={{overflowWrap:'break-word',color:'white',background:'#21204a'}}>  {<Translate content="CORREOoo" component="span"/>}</div>
                        </div>
                    <div style={{width:'16.66%',textAlign:'center',border:'solid 4px #21204a'}}>
                       <div style={{overflowWrap:'break-word',color:'white',background:'#21204a'}}> {<Translate content="NOMBREee" component="span"/>}</div>
                       <div></div>
                        </div>
                    <div style={{width:'16.66%',textAlign:'center',border:'solid 4px #21204a'}}>
                       <div style={{overflowWrap:'break-word',color:'white',background:'#21204a'}}> {<Translate content="FECHACREACION" component="span"/>}</div></div>
                    <div style={{width:'16.66%',textAlign:'center',border:'solid 4px #21204a'}}>
                       <div style={{overflowWrap:'break-word',color:'white',background:'#21204a'}}> {<Translate content="FECHAPAGO" component="span"/>}</div>
                        </div>
                    <div style={{width:'16.66%',textAlign:'center',border:'solid 4px #21204a'}}>
                       <div style={{overflowWrap:'break-word',color:'white',background:'#21204a'}}> {<Translate content="MONTO" component="span"/>}</div>
                        </div>
                        </div>
                        {data?data.map((e,index)=>{
                            return (
                <div style={{display:'flex',width:'100%',height:'max-content'}} key={index}>
                    <div style={{width:'16.66%',textAlign:'center',borderRight:'solid 4px #21204a',borderBottom:'solid 4px #21204a',borderLeft:'solid 4px #21204a'}}>
                      <div style={{overflowWrap:'break-word'}}>{e.empresa}</div>
                      </div>
                    <div style={{width:'16.66%',textAlign:'center',borderRight:'solid 4px #21204a',borderBottom:'solid 4px #21204a',borderLeft:'solid 4px #21204a'}}>
                    <div style={{overflowWrap:'break-word'}}>{e.usuario}</div>
                        </div>
                    <div style={{width:'16.66%',textAlign:'center',borderRight:'solid 4px #21204a',borderBottom:'solid 4px #21204a',borderLeft:'solid 4px #21204a'}}>
                       <div style={{overflowWrap:'break-word'}}>{e.nombre}</div>
                        </div>
                    <div style={{width:'16.66%',textAlign:'center',borderRight:'solid 4px #21204a',borderBottom:'solid 4px #21204a',borderLeft:'solid 4px #21204a'}}>
                       <div></div>
                        </div>
                    <div style={{width:'16.66%',textAlign:'center',borderRight:'solid 4px #21204a',borderBottom:'solid 4px #21204a',borderLeft:'solid 4px #21204a'}}>
                        </div>
                    <div style={{width:'16.66%',textAlign:'center',borderRight:'solid 4px #21204a',borderBottom:'solid 4px #21204a',borderLeft:'solid 4px #21204a'}}>
                        <div style={{overflowWrap:'break-word'}}>{e.monto}</div>
                        </div>
                </div>
                        
                            )
                        }):null}
                
            </div>
        </div>
    )
}
