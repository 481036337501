import React from "react";
import { useSelector } from "react-redux";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import "./CardHabilidades.css";

export default function NewCardOportunidadList({ image, title, cant }) {
    const { lang } = useSelector((state) => state);

    return (
        <div className="cardHabilidades-Container">
            <div style={{
                display: 'flex',
            }}>
                <div>
                    <img src={image ? image : ''} alt={image ? image : ''} />
                </div>
                <div className="cardHabilidades-SubContainer">
                    <div>
                        <h1 className="cardHabilidades-title">
                            {title}
                        </h1>
                        <h3 className="cardHabilidades-cant">
                            {cant} {lang === 'es' ? 'cursos' : 'courses'}
                        </h3>
                    </div>
                    <div >
                        <button className="cardHabilidades-button">
                            <span>
                                {lang === 'es' ? 'Ver más' : 'See more'}
                            </span>
                            <KeyboardArrowDownIcon />
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}