import React, { useState, useEffect } from 'react'
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import ChooseType from './ChooseType';
import Modalidad from './Modalidad';
import Anonimo from './setAnonimo';
import OportunityInfo from './OportunityInfo';
import Duration from './Duration';
import External from './External';
import Presupuesto from './Presupuesto';
import { saveOportunityForm, cleanOportunityForm } from '../../actions';
import { useSelector, useDispatch } from 'react-redux';
import StepOportunidad from "./StepOportunidad/StepOportunidad";
import StepOportunidadPre from './StepOportunidad/StepOportunidadPre';
import CloseIcon from '@mui/icons-material/Close';
import SelectCarrera from './SelectCarrera';
import Habilities from './Habilities';
import Language from './Language';
import Knowledge from './Knowledge';
import Payment from './Payment';
import Finished from './Finished';
import axios from 'axios';
import InfoCompany from './InfoCompany';
import DocuCompany from './DocuCompany';
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import PromotionalCode from './PromotionalCode';
export default function CrearOportunidad(props) {
  const { open, handleClose, handleFinish, id = null } = props;
  const oportunityForm = useSelector((state) => state.oportunityForm);
  const [step, setStep] = React.useState(oportunityForm.step ? oportunityForm.step  :  1);
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const lang = useSelector(state => state.lang);
  const plan = useSelector(state => state.userPlan);
  const [validCode, setValidCode] = useState(false)
  const [promotionalCode, setPromotionalCode] = useState('')
  const [form3, setform3] = useState({
    tipoDeServicioDeseado: oportunityForm.tipoDeServicioDeseado ? oportunityForm.tipoDeServicioDeseado : '',
    modalidadDeContratacion:  oportunityForm.modalidadDeContratacion ? oportunityForm.modalidadDeContratacion : '',
    Anonimo: oportunityForm.Anonimo ? oportunityForm.Anonimo : false,
    NombreEmpresa: user.name,
    Email: user.email,
    usuario: user.id,
    Oportunidad: oportunityForm.Oportunidad ? oportunityForm.Oportunidad : '',
    Datalle: oportunityForm.Datalle ? oportunityForm.Datalle : '',
    NombreOportunidad: oportunityForm.NombreOportunidad ? oportunityForm.NombreOportunidad : '',
    DescribeProyecto: oportunityForm.DescribeProyecto ? oportunityForm.DescribeProyecto : '',
    DescribeProyectoAudio: oportunityForm.DescribeProyectoAudio ? oportunityForm.DescribeProyectoAudio : '',
    SeleccionaCarrera: oportunityForm.SeleccionaCarrera ? oportunityForm.SeleccionaCarrera : [],
    Niveldeconocimiento: oportunityForm.Niveldeconocimiento ? oportunityForm.Niveldeconocimiento : [],
    Lenguaje: oportunityForm.Lenguaje ? oportunityForm.Lenguaje : [],
    habilidadesRequeridas: oportunityForm.habilidadesRequeridas ? oportunityForm.habilidadesRequeridas : [],
    estimadoDuración: oportunityForm.estimadoDuración ? oportunityForm.estimadoDuración : '',
    idrut: oportunityForm.idrut ? oportunityForm.idrut : '',
    profesionales: oportunityForm.profesionales ? oportunityForm.profesionales : [],
    modalidadPago: oportunityForm.modalidadPago ? oportunityForm.modalidadPago : '',
    presupuesto: oportunityForm.presupuesto ? oportunityForm.presupuesto : '',
    codigoPromocional: oportunityForm.codigoPromocional ? oportunityForm.codigoPromocional : '',
    modalidaddepago: oportunityForm.modalidaddepago ? oportunityForm.modalidaddepago : '',
    link: oportunityForm.link ? oportunityForm.link : '',
    empleos_alternativos: oportunityForm.empleos_alternativos ? oportunityForm.empleos_alternativos : [],
    tiempo_experiencia: oportunityForm.tiempo_experiencia ? oportunityForm.tiempo_experiencia : '',
    departamento: oportunityForm.departamento ? oportunityForm.departamento : '',
    municipio: oportunityForm.municipio ? oportunityForm.municipio : '',
    sector_economico: oportunityForm.sector_economico ? oportunityForm.sector_economico : '',
    tipo_documento: oportunityForm.tipo_documento ? oportunityForm.tipo_documento : '',
    documento: oportunityForm.documento ? oportunityForm.documento : '',
    editable: 0
  });

  useEffect(() => {
    if (plan.nombre_paquete === 'Plan empresarial' && step === 11) {
      setStep(step + 1)
    }
    if(id){
      axios.get('https://findally-backend.herokuapp.com/api/Project/' + id)
      .then(res => {
        setform3({
          ...form3, ...res.data, editable: 1
        })
       
      })
      .catch(err => {
        console.log(err)
      })
      console.log(form3)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [step.nombre_paquete, plan])

  const handleChange = (event) => {
    if(oportunityForm[event.target.name]){
      console.log(oportunityForm[event.target.name])
      if(event.target.name === 'profesionales'){
        setform3({
          ...form3,
          [event.target.name]: event.target.value.split(','),
        });
        return dispatch(saveOportunityForm({ ...oportunityForm, [event.target.name]: event.target.value.split(',') }, step));
      }
      if(Array.isArray(oportunityForm[event.target.name])){
        setform3({ ...form3, [event.target.name]: [...oportunityForm[event.target.name], event.target.value] });
        dispatch(saveOportunityForm({ ...oportunityForm, [event.target.name]: [...oportunityForm[event.target.name], event.target.value] }, step));
      }
      setform3({ ...form3, [event.target.name]: event.target.value });
      return dispatch(saveOportunityForm({ ...oportunityForm, [event.target.name]: event.target.value }, step));
      
    }
    if(!oportunityForm[event.target.name]){
      setform3({
      ...form3,
      [event.target.name]: event.target.value,
    });
    return dispatch(saveOportunityForm({ ...oportunityForm, [event.target.name]: event.target.value }, step));
    }
  };

  const handleNext = () => {
    if(validCode){
     return setStep(16)
    }
    setStep(step + 1)
  };

  const handleBack = () => {
    if(validCode){
      return setStep(14)
    }
    setStep(step - 1);
  };

  const handleReset = () => {
    handleClose()
  }

  const handleChangePromotionalCode = (event) => {
    setPromotionalCode(event.target.value)
  }

  const sendPromotionalCode = () => {
    if(promotionalCode === '') {
      return toast.error('Debes ingresar un código promocional')
    }
    axios.post(`https://findally-backend.herokuapp.com/api/user/validate/code/${user.id}`, {
      code: promotionalCode.toUpperCase().trim()
    }).then(res => {
      setValidCode(true)
      toast.success('Código promocional aplicado',{
        position: 'top-center',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
    }).catch(err => {
      toast.error('Código promocional no válido',{
        position: 'top-center',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
      console.log(err)
    })
  }

  const handleSubmit = async () => {
    if(form3.editable === 0){
    if (plan.nombre_paquete === 'Plan empresarial') {
      return axios.post("https://findally-backend.herokuapp.com/api/Project", form3)
        .then((res) => {
          dispatch(cleanOportunityForm());
          handleFinish()
        })
        .catch((err) => {
          console.log(err);
        });
    }
    if (user.email) {
      axios.post("https://findally-backend.herokuapp.com/api/user/createOffer/" + user.id)
        .then((res) => {
          console.log(res);
          if (res.data.status === "success") {
            console.log(res.data)
            return axios.post("https://findally-backend.herokuapp.com/api/Project", form3)
              .then((response) => {
                if (((form3.modalidaddepago !== 'gratis' || form3.modalidaddepago !== '') && !validCode)) {
                  return window.location.href = "/company/pricing"
                } else {
                  handleFinish()
                }
              })
              .catch(error => console.log(error));
          } else if (res.data.status === "limit excedeed") {
            return toast.error("Limite de ofertas alcanzado, por favor mejore su plan!",{
              position: "top-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            })
          } else if (res.data.status === "error") {
            return toast.error("hubo un error en el servidor!",{
              position: "top-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            })
          }
        }).catch((err) => {
          console.log(err)
        })
    }
    }else if(form3.editable === 1){
      return axios.put(`https://findally-backend.herokuapp.com/api/Project/${id}`, form3)
      .then((res) => {
        console.log(res.data);
        handleFinish()
      })
      .catch((err) => {
        console.log(err);
      });
    }
  }

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '100%',
    maxWidth: '722px',
    bgcolor: '#FFFFFF',
    height: 'fit-content',
    borderRadius: '8px',
    borderColor: '#FFFFFF',
    boxShadow: '0px 7px 32px rgba(0, 0, 0, 0.1)',
    transition: 'all 0.3s ease',
    overflow: 'auto',
    margin: 0,
    padding: 0,
  };

  if (plan.nombre_paquete !== 'Plan empresarial') {
    return (
      <>
        <Modal
          sx={{ backgroundColor: 'rgba(230, 230, 230, 0.9)' }}
          className={step === 4 || 6 ? 'contenedorcrearOportunidadNew2' : 'contenedorcrearOportunidadNew'}
          open={open}
          onClose={handleReset}
          disableAutoFocus={false}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <div style={{
              display: 'flex',
              justifyContent: 'space-evenly',
              alignItems: 'center',
              height: '100%',
              flexDirection: 'column',
            }}>
              {/* <ToastContainer /> */}
              <div>
                <CloseIcon onClick={() => { handleClose() }} style={{ cursor: 'pointer', position: 'absolute', left: 20, top: 20, color: '#C5C5C5', height: '30px', width: '30px' }} />
              </div>
              <StepOportunidad step={step} lang={lang} />
              {step === 1 && <ChooseType handleChange={handleChange} form={form3} handleStep={handleNext} step={step} lang={lang} oportunityForm={oportunityForm} handleForm={setform3}/>}
              {step === 2 && <Modalidad handleChange={handleChange} form={form3} handleStep={handleNext} handleBackStep={handleBack} step={step} lang={lang} oportunityForm={oportunityForm} handleForm={setform3} />}
              {step === 3 && <Anonimo handleChange={handleChange} form={form3} handleStep={handleNext} handleBackStep={handleBack} step={step} lang={lang} oportunityForm={oportunityForm} handleForm={setform3} />}
              {step === 4 && <OportunityInfo handleChange={handleChange} form={form3} handleStep={handleNext} handleBackStep={handleBack} step={step} lang={lang} oportunityForm={oportunityForm} handleForm={setform3} />}
              {step === 5 && <SelectCarrera handleChange={handleChange} form={form3}  handleStep={handleNext} handleBackStep={handleBack} step={step} lang={lang} oportunityForm={oportunityForm} handleForm={setform3} />}
              {step === 6 && <Habilities setform={setform3} form={form3} handleStep={handleNext} handleBackStep={handleBack} step={step} lang={lang} oportunityForm={oportunityForm} handleForm={setform3} />}
              {step === 7 && <Knowledge handleChange={handleChange} form={form3} setform={setform3} handleStep={handleNext} handleBackStep={handleBack} step={step} lang={lang} oportunityForm={oportunityForm} handleForm={setform3} />}
              {step === 8 && <Language handleChange={handleChange} form={form3} setform={setform3} handleStep={handleNext} handleBackStep={handleBack} step={step} lang={lang} oportunityForm={oportunityForm} handleForm={setform3} />}
              {step === 9 && <Duration handleChange={handleChange} form={form3} handleStep={handleNext} handleBackStep={handleBack} step={step} lang={lang} oportunityForm={oportunityForm} handleForm={setform3}/>}
              {step === 10 && <External handleChange={handleChange} form={form3} handleStep={handleNext} handleBackStep={handleBack} step={step} lang={lang} oportunityForm={oportunityForm} handleForm={setform3} />}
              {step === 11 && <Presupuesto setform={setform3} form={form3} handleStep={handleNext} handleBackStep={handleBack} step={step} lang={lang} oportunityForm={oportunityForm} handleForm={setform3} />}
              {step === 12 && <InfoCompany handleChange={handleChange} form={form3} handleStep={handleNext} handleBackStep={handleBack} step={step} lang={lang} oportunityForm={oportunityForm} handleForm={setform3} />}
              {step === 13 && <DocuCompany handleChange={handleChange} form={form3} handleStep={handleNext} handleBackStep={handleBack} step={step} lang={lang} oportunityForm={oportunityForm} handleForm={setform3} />}
              {step === 14 && <PromotionalCode handleChange={handleChangePromotionalCode} promotionalCode={promotionalCode} handleStep={handleNext} handleBackStep={handleBack} step={step} lang={lang} oportunityForm={oportunityForm} handleForm={setform3} sendPromotionalCode={sendPromotionalCode}/>}
              {step === 15 && !validCode  && <Payment handleChange={handleChange} form={form3} handleStep={handleNext} handleBackStep={handleBack} step={step} lang={lang} oportunityForm={oportunityForm} handleForm={setform3} />}
              {step === 16 && <Finished form={form3} handleSubmit={handleSubmit} handleBackStep={handleBack} step={step} lang={lang} oportunityForm={oportunityForm} handleForm={setform3} />}

            </div>
          </Box>
        </Modal>
      </>
    );
  }
  if(plan.nombre_paquete === 'Plan empresarial') {
    return (
      <>
        <Modal
          sx={{ backgroundColor: 'rgba(230, 230, 230, 0.9)' }}
          open={open}
          onClose={handleReset}
          disableAutoFocus="true"
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <div style={{
              display: 'flex',
              justifyContent: 'space-evenly',
              alignItems: 'center',
              height: '100%',
              flexDirection: 'column',
            }}>
              {/* <ToastContainer /> */}
              <StepOportunidadPre step={step} lang={lang} />
              {/*listo tipo*/}
              {step === 1 && <ChooseType handleChange={handleChange} form={form3} handleStep={handleNext} step={step} lang={lang} oportunityForm={oportunityForm} handleForm={setform3}/>}
              {/*listo modalida*/}
              {step === 2 && <Modalidad handleChange={handleChange} form={form3} handleStep={handleNext} handleBackStep={handleBack} step={step} lang={lang} oportunityForm={oportunityForm} handleForm={setform3} />}
              {/*listo anonimo*/}
              {step === 3 && <Anonimo handleChange={handleChange} form={form3} handleStep={handleNext} handleBackStep={handleBack} step={step} lang={lang} oportunityForm={oportunityForm} handleForm={setform3} />}
              {/*listo info oport*/}
              {step === 4 && <OportunityInfo handleChange={handleChange} form={form3} handleStep={handleNext} handleBackStep={handleBack} step={step} lang={lang} oportunityForm={oportunityForm} handleForm={setform3} />}
              {/*listo carrera*/}
              {step === 5 && <SelectCarrera handleChange={handleChange} form={form3}  handleStep={handleNext} handleBackStep={handleBack} step={step} lang={lang} oportunityForm={oportunityForm} handleForm={setform3} />}
              {/*FALTA HARDSKILLS*/}
              {/*FALTA HARDSKILLS*/}
              {step === 6 && <Habilities setform={setform3} form={form3} handleStep={handleNext} handleBackStep={handleBack} step={step} lang={lang} oportunityForm={oportunityForm} handleForm={setform3} />}
              {/*listo knowledge*/}
              {step === 7 && <Knowledge handleChange={handleChange} form={form3} setform={setform3} handleStep={handleNext} handleBackStep={handleBack} step={step} lang={lang} oportunityForm={oportunityForm} handleForm={setform3} />}
              {/*listo lenguaje*/}
              {step === 8 && <Language handleChange={handleChange} form={form3} setform={setform3} handleStep={handleNext} handleBackStep={handleBack} step={step} lang={lang} oportunityForm={oportunityForm} handleForm={setform3} />}
              {/*listo duraciones*/}
              {step === 9 && <Duration handleChange={handleChange} form={form3} handleStep={handleNext} handleBackStep={handleBack} step={step} lang={lang} oportunityForm={oportunityForm} handleForm={setform3}/>}
              {/*listo Link externo*/}
              {step === 10 && <External handleChange={handleChange} form={form3} handleStep={handleNext} handleBackStep={handleBack} step={step} lang={lang} oportunityForm={oportunityForm} handleForm={setform3} />}
              {/*listo Presupuesto*/}
              {step === 11 && <Presupuesto setform={setform3} form={form3} handleStep={handleNext} handleBackStep={handleBack} step={step} lang={lang} oportunityForm={oportunityForm} handleForm={setform3} />}
              {/*listo infoCompany*/}
              {step === 12 && <InfoCompany handleChange={handleChange} form={form3} handleStep={handleNext} handleBackStep={handleBack} step={step} lang={lang} oportunityForm={oportunityForm} handleForm={setform3} />}
              {/*listo docuCompany*/}
              {step === 13 && <DocuCompany handleChange={handleChange} form={form3} handleStep={handleNext} handleBackStep={handleBack} step={step} lang={lang} oportunityForm={oportunityForm} handleForm={setform3} />}
              {/*listo Submit*/}
              {step === 14 && <Finished form={form3} handleSubmit={handleSubmit} handleBackStep={handleBack} step={step} lang={lang} oportunityForm={oportunityForm} handleForm={setform3} />}
            </div>
          </Box>
        </Modal>
      </>
    )
  }
}