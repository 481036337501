import React, {useContext} from "react";
import {
  SidebarContainer,
  SidebarLinksContainer,
  SidebarLink,
  SidebarLogo,
  /*TrendingUpIcon,*/
  HomeIcon,
  LaptopIcon,
  BookIcon,
  LogoutIcon,
  SidebarBottomContainer,
  LogoutButton,
} from "./SidebarStyles";
import {Link} from "react-router-dom";
import {SchneiderContext} from "../../context/SchneiderContext";
import {useSelector, useDispatch} from "react-redux";
import {ReduxStateInterface} from "../../types/reduxInterface";
import {logoutUser} from "../../../actions";
import SidebarCv from "./sidebarcv/SidebarCv";
import SidebarOp from "./sidebarop/SidebarOp";
import SidebarPQR from "./sidebarpqr/SidebarPQR";

const Sidebar = ({
                   location,
                   isClosing,
                   responsive,
                 }: {
  location?: string;
  isClosing?: boolean;
  responsive?: boolean;
}) => {
  const {schneider} = useContext(SchneiderContext);
  const {lang, user} = useSelector((state: ReduxStateInterface) => state);
  const dispatch = useDispatch();

  const handleLogout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    localStorage.removeItem("redirect");
    localStorage.removeItem("plan_premium");

    dispatch(logoutUser({}, "/schneider/login") as any);
  };

  return (
    <SidebarContainer
      color={schneider.color.secondary}
      isClosing={isClosing}
      responsive={!responsive}
    >
      <SidebarLinksContainer>
        <Link
          to={
            user.roles === "coallier"
              ? `/${schneider.route}/home`
              : `/${schneider.route}/company/home`
          }
        >
          <SidebarLogo src={schneider.logo} alt={"Logo Coally"}/>
        </Link>
        <SidebarLink
          to={
            user.roles === "coallier"
              ? `/${schneider.route}/home`
              : `/${schneider.route}/company/home`
          }
          data-isactive={location === "home"}
          color={schneider.color.secondary}
          background={schneider.color.primary}
        >
          <HomeIcon/>
          {lang === "es" ? "Inicio" : "Home"}
        </SidebarLink>
        <SidebarLink
          to={
            user.roles === "coallier"
              ? `/${schneider.route}/projects`
              : `/${schneider.route}/company/project`
          }
          data-isactive={location === "oportunidades"}
          color={schneider.color.secondary}
          background={schneider.color.primary}
        >
          <BookIcon/>
          {lang === "es" ? "Oportunidades" : "Opportunities"}
        </SidebarLink>
        {user.roles === "coallier" ? (
          <SidebarLink
            to={
              user.roles === "coallier"
                ? `/${schneider.route}/formacion`
                : `/${schneider.route}/company/formacion`
            }
            data-isactive={location === "formación"}
            color={schneider.color.secondary}
            background={schneider.color.primary}
          >
            <LaptopIcon/>
            {lang === "es" ? "Formación" : "Education"}
          </SidebarLink>
        ) : null}
        {/*{user &&
        user.bootcamp &&
        user.bootcamp === "Administracion Bootcamp" &&
        user.bootcampAprobado ? (
          <SidebarLink
            to={`/${schneider.route}/bootcamp/administracion`}
            data-isactive={location === "bootcamp administracion"}
            color={schneider.color.secondary}
            background={schneider.color.primary}
          >
            <TrendingUpIcon />
            {lang === "es" ? "Bootcamp" : "Bootcamp"}
          </SidebarLink>
        ) : null}*/}
      </SidebarLinksContainer>
      <SidebarBottomContainer>
        <label
          id="pusblish"
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
          }}
        >
          {user.roles === "coallier" ? (
            <SidebarCv color={schneider.color.primary}/>
          ) : (
            <SidebarOp color={schneider.color.primary}/>
          )}
          <SidebarPQR color={schneider.color.primary}/>
        </label>
        {/* <SidebarLink
          to={
            user.roles === "coallier"
              ? `/${schneider.route}/config`
              : `/${schneider.route}/company/configuracion`
          }
          data-isactive={location === "configuración"}
          color={schneider.color.secondary}
          background={schneider.color.primary}
        >
          <GearIcon />
          {lang === "es" ? "Configuración" : "Settings"}
        </SidebarLink> */}
        <LogoutButton to="/schneider/login" onClick={() => handleLogout()}>
          <LogoutIcon/>
          {lang === "es" ? "Cerrar sesión" : "Log out"}
        </LogoutButton>
      </SidebarBottomContainer>
    </SidebarContainer>
  );
};

export default Sidebar;
