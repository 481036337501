/* eslint-disable */
import React, { useEffect, useState } from 'react'
import { saveOportunityForm } from '../../actions';
import { useDispatch } from 'react-redux';
function InfoCompany(props) {
  const { form, handleStep, handleBackStep, step, lang, oportunityForm, handleForm} = props;
  const [bottom, setbottom] = useState(true)
  const dispatch = useDispatch();
  useEffect(() => {
    if ((oportunityForm?.tiempo_experiencia && oportunityForm?.departamento && oportunityForm?.municipio) ? oportunityForm?.tiempo_experiencia !== '' && oportunityForm?.departamento !== '' && oportunityForm?.municipio !== '': form.tiempo_experiencia !== '' && form.departamento !== '' && form.municipio !== '') {
      setbottom(false)
    }
    return () => {
      setbottom(true)
    }
  }, [form])

  const handleChange = (e) => {
    handleForm({
      ...form,
      [e.target.name]: e.target.value
    })
    dispatch(saveOportunityForm({
      ...oportunityForm,
      [e.target.name]: e.target.value
    }, step))
  }
  return (
    <>
      <div className='contenedorCompanyInfo'>
        <div style={{ marginBottom: '20px' }}>
          <h3 className='contenedorCompanyInfo_title'>{lang === 'es' ? '¿Cuál es el tiempo de la experiencia relacionada?' : 'What is the time of the related experience?'}</h3>
          <input type="text" name="tiempo_experiencia" onChange={handleChange}
          value={oportunityForm?.tiempo_experiencia ? oportunityForm?.tiempo_experiencia : form.tiempo_experiencia}
            className="contenedorCompanyInfo_input" ></input>
        </div>

        <div style={{ marginBottom: '20px' }}>
          <h3 className='contenedorCompanyInfo_title'>{lang === 'es' ? 'Ingrese el departamento donde se registrará la oportunidad' : 'Enter the department where the opportunity will be registered'}</h3>
          <input type="text" name="departamento" onChange={handleChange} className="contenedorCompanyInfo_input"
          value={oportunityForm?.departamento ? oportunityForm?.departamento : form.departamento}></input>
        </div>

        <div style={{ marginBottom: '20px' }}>
          <h3 className='contenedorCompanyInfo_title'>{lang === 'es' ? 'Ingrese el municipio donde se registrará la oportunidad' : 'Enter the municipality where the opportunity will be registered'} </h3>
          <input type="text" name="municipio" onChange={handleChange}
            className="contenedorCompanyInfo_input" value={oportunityForm?.municipio ? oportunityForm?.municipio : form.municipio} ></input>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          width: "100%",
          justifyContent: "space-between",
          padding: "1rem 3rem 0rem 3rem",
          alignItems: "center",         
        }}
      >
        <button className='button_siguiente_create_B' onClick={handleBackStep}>{lang === 'es' ? 'Anterior' : 'Previous'}</button>
        <h3 className='stepCounter'>{`${step}/14`}</h3>
        <button className='button_siguiente_create'
          onClick={()=>handleStep()} disabled={bottom}>{lang === 'es' ? 'Siguiente' : 'Next'}</button>
      </div>
    </>
  )
}

export default InfoCompany