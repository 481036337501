import React from 'react';
import './CrearOportunidadNew.css'

export default function PromotionalCode(props) {
const {step, handleStep, handleBackStep, lang, promotionalCode, sendPromotionalCode, handleChange} = props;

    return (
        <>
        <div style={{display: 'flex', alignItems: 'flex-start', flexDirection: 'column', marginBottom: '50px'}}>
        <div style={{marginBottom: '30px', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
        <h3 style={{ fontFamily: 'Poppins', fontWeight: '700', fontSize: '14px', marginBottom: '20px', marginLeft: '-5px' }}>{lang === 'es' ? 'Si tienes un código promocional puedes validarlo aquí' : 'If you have a promotional code you can validate it here'}</h3>
        <input type="text" placeholder='Código promocional' name="Código" onChange={(e)=>handleChange(e)} value={promotionalCode}
        className="contenedorExternal_input"></input>
        <button className='button_siguiente_create' style={{marginTop: '5%'}} onClick={sendPromotionalCode}>
        {lang === 'es' ?'Validar' : 'Validate'}
        </button>
        </div>
            </div>
            <div
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "space-between",
              padding: "1rem 3rem 0rem 3rem",
              alignItems: "center",
            }}
          >
             <button className='button_siguiente_create_B' onClick={handleBackStep}>Anterior</button>
            <h3 className='stepCounter'>{`${step}/15`}</h3>
            <button className='button_siguiente_create'
            onClick={()=>handleStep()}>{lang === 'es' ?'Siguiente' : 'Next'}</button>
          </div>
          </>
    )
}