import './DashboardCard.css'
import React from 'react'


/**
 *
 * @param title - título del componente, no es obligatorio
 * @param children - se debe envolver el componente anidado es como si fuera un div
 * @returns {JSX.Element}
 * @constructor
 * @description Componente que muestra un título y un componente anidado en formato de card
 */

const DashboardCard = ({title, children}) => {
  return (
    <div className={'DashboardCardContainer'}>
      {title ?
        <div className={'DashboardCardContainerTitle'}>
          <h2 className={'DashboardCardTitle'}>
            {title}
          </h2>
        </div>
        : null}
      <div className={'DashboardCardContainerChildren'}>
        <div style={{
          width: '100%',
        }}>
          {children}
        </div>
      </div>
    </div>
  )
}

export default DashboardCard
