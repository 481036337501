import React from 'react'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { useSelector } from 'react-redux';
export default function PopUpForm(props) {
    const {handleClose } = props;
  const lang = useSelector(state => state.lang);
  return (
    <div>
      <div>
      <div style={{ marginLeft: '93px', marginRight: '96px', marginTop: '51px', height: '100%' }}>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', flexDirection: 'column' }}>
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', marginBottom: '5%' }}>
            <CheckCircleOutlineIcon style={{ fontSize: '100px', color: '#00B87C' }} />
          </div>
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', textAlign: 'center', width: '100%', marginBottom: '5%'}}>
            <h1 style={{ fontSize: '24px', fontWeight: 'bold', color: '#000000' }}>
              {
              lang === 'es' ? 'Tu oportunidad ha sido publicada, a continuación te presentamos las instrucciones para hacerle seguimiento a tu oportunidad' : 
              'Your opportunity has been published, below we present the instructions to follow up on your opportunity'
            }</h1>
          </div>
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', textAlign: 'center', marginBottom: '5%' }}>
          <button style={{ backgroundColor: 'transparent', height: '28px', width: '141px', borderRadius: '5px', borderStyle: 'solid', borderWidth: '1px', fontSize: '14px', fontWeight: '400', color: '#202256' }} onClick={handleClose}>
            {lang === 'es' ? 'Aceptar' : 'Accept'}
            </button>
          </div>  
        </div>
      </div>
      </div>
    </div>
  )
}