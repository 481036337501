const universities = [
    {
        "value": "UNIVERSIDAD NACIONAL DE COLOMBIA",
        "label": "UNIVERSIDAD NACIONAL DE COLOMBIA"
    },
    {
        "value": "UNIVERSIDAD PEDAGOGICA NACIONAL",
        "label": "UNIVERSIDAD PEDAGOGICA NACIONAL"
    },
    {
        "value": "UNIVERSIDAD PEDAGOGICA Y TECNOLOGICA DE COLOMBIA - UPTC",
        "label": "UNIVERSIDAD PEDAGOGICA Y TECNOLOGICA DE COLOMBIA - UPTC"
    },
    {
        "value": "UNIVERSIDAD DEL CAUCA",
        "label": "UNIVERSIDAD DEL CAUCA"
    },
    {
        "value": "UNIVERSIDAD TECNOLOGICA DE PEREIRA - UTP",
        "label": "UNIVERSIDAD TECNOLOGICA DE PEREIRA - UTP"
    },
    {
        "value": "UNIVERSIDAD DE CALDAS",
        "label": "UNIVERSIDAD DE CALDAS"
    },
    {
        "value": "UNIVERSIDAD DE CORDOBA",
        "label": "UNIVERSIDAD DE CORDOBA"
    },
    {
        "value": "UNIVERSIDAD SURCOLOMBIANA",
        "label": "UNIVERSIDAD SURCOLOMBIANA"
    },
    {
        "value": "UNIVERSIDAD DE LA AMAZONIA",
        "label": "UNIVERSIDAD DE LA AMAZONIA"
    },
    {
        "value": "UNIVERSIDAD MILITAR-NUEVA GRANADA",
        "label": "UNIVERSIDAD MILITAR-NUEVA GRANADA"
    },
    {
        "value": "UNIVERSIDAD TECNOLOGICA DEL CHOCO-DIEGO LUIS CORDOBA",
        "label": "UNIVERSIDAD TECNOLOGICA DEL CHOCO-DIEGO LUIS CORDOBA"
    },
    {
        "value": "UNIVERSIDAD DE LOS LLANOS",
        "label": "UNIVERSIDAD DE LOS LLANOS"
    },
    {
        "value": "UNIVERSIDAD POPULAR DEL CESAR",
        "label": "UNIVERSIDAD POPULAR DEL CESAR"
    },
    {
        "value": "UNIVERSIDAD-COLEGIO MAYOR DE CUNDINAMARCA",
        "label": "UNIVERSIDAD-COLEGIO MAYOR DE CUNDINAMARCA"
    },
    {
        "value": "UNIVERSIDAD DEL PACIFICO",
        "label": "UNIVERSIDAD DEL PACIFICO"
    },
    {
        "value": "UNIVERSIDAD DE ANTIOQUIA",
        "label": "UNIVERSIDAD DE ANTIOQUIA"
    },
    {
        "value": "UNIVERSIDAD DEL ATLANTICO",
        "label": "UNIVERSIDAD DEL ATLANTICO"
    },
    {
        "value": "UNIVERSIDAD DEL VALLE",
        "label": "UNIVERSIDAD DEL VALLE"
    },
    {
        "value": "UNIVERSIDAD INDUSTRIAL DE SANTANDER",
        "label": "UNIVERSIDAD INDUSTRIAL DE SANTANDER"
    },
    {
        "value": "UNIVERSIDAD DE CARTAGENA",
        "label": "UNIVERSIDAD DE CARTAGENA"
    },
    {
        "value": "UNIVERSIDAD DE NARIÑO",
        "label": "UNIVERSIDAD DE NARIÑO"
    },
    {
        "value": "UNIVERSIDAD DEL TOLIMA",
        "label": "UNIVERSIDAD DEL TOLIMA"
    },
    {
        "value": "UNIVERSIDAD DEL QUINDIO",
        "label": "UNIVERSIDAD DEL QUINDIO"
    },
    {
        "value": "UNIVERSIDAD FRANCISCO DE PAULA SANTANDER",
        "label": "UNIVERSIDAD FRANCISCO DE PAULA SANTANDER"
    },
    {
        "value": "UNIVERSIDAD DE PAMPLONA",
        "label": "UNIVERSIDAD DE PAMPLONA"
    },
    {
        "value": "UNIVERSIDAD DEL MAGDALENA - UNIMAGDALENA",
        "label": "UNIVERSIDAD DEL MAGDALENA - UNIMAGDALENA"
    },
    {
        "value": "UNIVERSIDAD DE CUNDINAMARCA-UDEC",
        "label": "UNIVERSIDAD DE CUNDINAMARCA-UDEC"
    },
    {
        "value": "UNIVERSIDAD DE SUCRE",
        "label": "UNIVERSIDAD DE SUCRE"
    },
    {
        "value": "UNIVERSIDAD DE LA GUAJIRA",
        "label": "UNIVERSIDAD DE LA GUAJIRA"
    },
    {
        "value": "UNIVERSIDAD DISTRITAL-FRANCISCO JOSE DE CALDAS",
        "label": "UNIVERSIDAD DISTRITAL-FRANCISCO JOSE DE CALDAS"
    },
    {
        "value": "PONTIFICIA UNIVERSIDAD JAVERIANA",
        "label": "PONTIFICIA UNIVERSIDAD JAVERIANA"
    },
    {
        "value": "UNIVERSIDAD INCCA DE COLOMBIA",
        "label": "UNIVERSIDAD INCCA DE COLOMBIA"
    },
    {
        "value": "UNIVERSIDAD SANTO TOMAS",
        "label": "UNIVERSIDAD SANTO TOMAS"
    },
    {
        "value": "UNIVERSIDAD EXTERNADO DE COLOMBIA",
        "label": "UNIVERSIDAD EXTERNADO DE COLOMBIA"
    },
    {
        "value": "FUNDACION UNIVERSIDAD DE BOGOTA - JORGE TADEO LOZANO",
        "label": "FUNDACION UNIVERSIDAD DE BOGOTA - JORGE TADEO LOZANO"
    },
    {
        "value": "UNIVERSIDAD CENTRAL",
        "label": "UNIVERSIDAD CENTRAL"
    },
    {
        "value": "UNIVERSIDAD PONTIFICIA BOLIVARIANA",
        "label": "UNIVERSIDAD PONTIFICIA BOLIVARIANA"
    },
    {
        "value": "UNIVERSIDAD DE LA SABANA",
        "label": "UNIVERSIDAD DE LA SABANA"
    },
    {
        "value": "UNIVERSIDAD EAFIT-",
        "label": "UNIVERSIDAD EAFIT-"
    },
    {
        "value": "UNIVERSIDAD DEL NORTE",
        "label": "UNIVERSIDAD DEL NORTE"
    },
    {
        "value": "COLEGIO MAYOR DE NUESTRA SEÑORA DEL ROSARIO",
        "label": "COLEGIO MAYOR DE NUESTRA SEÑORA DEL ROSARIO"
    },
    {
        "value": "FUNDACION UNIVERSIDAD DE AMERICA",
        "label": "FUNDACION UNIVERSIDAD DE AMERICA"
    },
    {
        "value": "UNIVERSIDAD DE SAN BUENAVENTURA",
        "label": "UNIVERSIDAD DE SAN BUENAVENTURA"
    },
    {
        "value": "UNIVERSIDAD CATOLICA DE COLOMBIA",
        "label": "UNIVERSIDAD CATOLICA DE COLOMBIA"
    },
    {
        "value": "UNIVERSIDAD MARIANA",
        "label": "UNIVERSIDAD MARIANA"
    },
    {
        "value": "UNIVERSIDAD DE MANIZALES",
        "label": "UNIVERSIDAD DE MANIZALES"
    },
    {
        "value": "FUNDACION UNIVERSIDAD AUTONOMA DE COLOMBIA -FUAC-",
        "label": "FUNDACION UNIVERSIDAD AUTONOMA DE COLOMBIA -FUAC-"
    },
    {
        "value": "UNIVERSIDAD CATOLICA DE ORIENTE -UCO",
        "label": "UNIVERSIDAD CATOLICA DE ORIENTE -UCO"
    },
    {
        "value": "UNIVERSIDAD SERGIO ARBOLEDA",
        "label": "UNIVERSIDAD SERGIO ARBOLEDA"
    },
    {
        "value": "UNIVERSIDAD EL BOSQUE",
        "label": "UNIVERSIDAD EL BOSQUE"
    },
    {
        "value": "UNIVERSIDAD DE BOYACA UNIBOYACA",
        "label": "UNIVERSIDAD DE BOYACA UNIBOYACA"
    },
    {
        "value": "UNIVERSIDAD MANUELA BELTRAN-UMB-",
        "label": "UNIVERSIDAD MANUELA BELTRAN-UMB-"
    },
    {
        "value": "UNIVERSIDAD LA GRAN COLOMBIA",
        "label": "UNIVERSIDAD LA GRAN COLOMBIA"
    },
    {
        "value": "UNIVERSIDAD DE LA SALLE",
        "label": "UNIVERSIDAD DE LA SALLE"
    },
    {
        "value": "UNIVERSIDAD AUTONOMA DEL CARIBE- UNIAUTONOMA",
        "label": "UNIVERSIDAD AUTONOMA DEL CARIBE- UNIAUTONOMA"
    },
    {
        "value": "UNIVERSIDAD SANTIAGO DE CALI",
        "label": "UNIVERSIDAD SANTIAGO DE CALI"
    },
    {
        "value": "UNIVERSIDAD LIBRE",
        "label": "UNIVERSIDAD LIBRE"
    },
    {
        "value": "UNIVERSIDAD DE MEDELLIN",
        "label": "UNIVERSIDAD DE MEDELLIN"
    },
    {
        "value": "UNIVERSIDAD DE LOS ANDES",
        "label": "UNIVERSIDAD DE LOS ANDES"
    },
    {
        "value": "UNIVERSIDAD AUTONOMA LATINOAMERICANA-UNAULA-",
        "label": "UNIVERSIDAD AUTONOMA LATINOAMERICANA-UNAULA-"
    },
    {
        "value": "CORPORACION UNIVERSIDAD PILOTO DE COLOMBIA",
        "label": "CORPORACION UNIVERSIDAD PILOTO DE COLOMBIA"
    },
    {
        "value": "UNIVERSIDAD COOPERATIVA DE COLOMBIA",
        "label": "UNIVERSIDAD COOPERATIVA DE COLOMBIA"
    },
    {
        "value": "UNIVERSIDAD AUTONOMA DE BUCARAMANGA-UNAB-",
        "label": "UNIVERSIDAD AUTONOMA DE BUCARAMANGA-UNAB-"
    },
    {
        "value": "UNIVERSIDAD METROPOLITANA",
        "label": "UNIVERSIDAD METROPOLITANA"
    },
    {
        "value": "UNIVERSIDAD AUTONOMA DE MANIZALES",
        "label": "UNIVERSIDAD AUTONOMA DE MANIZALES"
    },
    {
        "value": "UNIVERSIDAD ANTONIO NARIÑO",
        "label": "UNIVERSIDAD ANTONIO NARIÑO"
    },
    {
        "value": "UNIVERSIDAD CATOLICA DE MANIZALES",
        "label": "UNIVERSIDAD CATOLICA DE MANIZALES"
    },
    {
        "value": "UNIVERSIDAD ICESI",
        "label": "UNIVERSIDAD ICESI"
    },
    {
        "value": "UNIVERSIDAD AUTONOMA DE OCCIDENTE",
        "label": "UNIVERSIDAD AUTONOMA DE OCCIDENTE"
    },
    {
        "value": "UNIVERSIDAD DE IBAGUE",
        "label": "UNIVERSIDAD DE IBAGUE"
    },
    {
        "value": "UNIVERSIDAD TECNOLOGICA DE BOLIVAR",
        "label": "UNIVERSIDAD TECNOLOGICA DE BOLIVAR"
    },
    {
        "value": "UNIVERSIDAD DEL SINU - ELIAS BECHARA ZAINUM - UNISINU -",
        "label": "UNIVERSIDAD DEL SINU - ELIAS BECHARA ZAINUM - UNISINU -"
    },
    {
        "value": "UNIVERSIDAD DE CIENCIAS APLICADAS Y AMBIENTALES - UDCA",
        "label": "UNIVERSIDAD DE CIENCIAS APLICADAS Y AMBIENTALES - UDCA"
    },
    {
        "value": "UNIVERSIDAD NACIONAL ABIERTA Y A DISTANCIA UNAD",
        "label": "UNIVERSIDAD NACIONAL ABIERTA Y A DISTANCIA UNAD"
    },
    {
        "value": "ESCUELA SUPERIOR DE ADMINISTRACION PUBLICA-ESAP-",
        "label": "ESCUELA SUPERIOR DE ADMINISTRACION PUBLICA-ESAP-"
    },
    {
        "value": "DIRECCION NACIONAL DE ESCUELAS",
        "label": "DIRECCION NACIONAL DE ESCUELAS"
    },
    {
        "value": "COLEGIO MAYOR DE ANTIOQUIA",
        "label": "COLEGIO MAYOR DE ANTIOQUIA"
    },
    {
        "value": "ESCUELA NACIONAL DEL DEPORTE",
        "label": "ESCUELA NACIONAL DEL DEPORTE"
    },
    {
        "value": "INSTITUTO DEPARTAMENTAL DE BELLAS ARTES",
        "label": "INSTITUTO DEPARTAMENTAL DE BELLAS ARTES"
    },
    {
        "value": "INSTITUTO UNIVERSITARIO DE LA PAZ",
        "label": "INSTITUTO UNIVERSITARIO DE LA PAZ"
    },
    {
        "value": "CONSERVATORIO DEL TOLIMA",
        "label": "CONSERVATORIO DEL TOLIMA"
    },
    {
        "value": "POLITECNICO COLOMBIANO JAIME ISAZA CADAVID",
        "label": "POLITECNICO COLOMBIANO JAIME ISAZA CADAVID"
    },
    {
        "value": "INSTITUCION UNIVERSITARIA BELLAS ARTES Y CIENCIAS DE BOLIVAR",
        "label": "INSTITUCION UNIVERSITARIA BELLAS ARTES Y CIENCIAS DE BOLIVAR"
    },
    {
        "value": "UNIDAD CENTRAL DEL VALLE DEL CAUCA",
        "label": "UNIDAD CENTRAL DEL VALLE DEL CAUCA"
    },
    {
        "value": "INSTITUCION UNIVERSITARIA DE ENVIGADO",
        "label": "INSTITUCION UNIVERSITARIA DE ENVIGADO"
    },
    {
        "value": "INSTITUCION UNIVERSITARIA COLEGIOS DE COLOMBIA - UNICOC",
        "label": "INSTITUCION UNIVERSITARIA COLEGIOS DE COLOMBIA - UNICOC"
    },
    {
        "value": "FUNDACION UNIVERSITARIA DE CIENCIAS DE LA SALUD",
        "label": "FUNDACION UNIVERSITARIA DE CIENCIAS DE LA SALUD"
    },
    {
        "value": "COLEGIO DE ESTUDIOS SUPERIORES DE ADMINISTRACION-CESA-",
        "label": "COLEGIO DE ESTUDIOS SUPERIORES DE ADMINISTRACION-CESA-"
    },
    {
        "value": "FUNDACIÓN UNIVERSITARIA JUAN N. CORPAS",
        "label": "FUNDACIÓN UNIVERSITARIA JUAN N. CORPAS"
    },
    {
        "value": "UNIVERSIDAD CES",
        "label": "UNIVERSIDAD CES"
    },
    {
        "value": "FUNDACION UNIVERSITARIA SAN MARTIN",
        "label": "FUNDACION UNIVERSITARIA SAN MARTIN"
    },
    {
        "value": "FUNDACION UNIVERSITARIA MONSERRATE -UNIMONSERRATE",
        "label": "FUNDACION UNIVERSITARIA MONSERRATE -UNIMONSERRATE"
    },
    {
        "value": "UNIVERSIDAD CATOLICA DE PEREIRA",
        "label": "UNIVERSIDAD CATOLICA DE PEREIRA"
    },
    {
        "value": "FUNDACION UNIVERSITARIA KONRAD LORENZ",
        "label": "FUNDACION UNIVERSITARIA KONRAD LORENZ"
    },
    {
        "value": "FUNDACION UNIVERSITARIA LOS LIBERTADORES",
        "label": "FUNDACION UNIVERSITARIA LOS LIBERTADORES"
    },
    {
        "value": "FUNDACION UNIVERSITARIA DE POPAYAN",
        "label": "FUNDACION UNIVERSITARIA DE POPAYAN"
    },
    {
        "value": "INSTITUTO UNIVERSITARIO DE HISTORIA DE COLOMBIA",
        "label": "INSTITUTO UNIVERSITARIO DE HISTORIA DE COLOMBIA"
    },
    {
        "value": "FUNDACION UNIVERSITARIA DE GARCIA ROVIRA,NORTE Y GUTIERREZ",
        "label": "FUNDACION UNIVERSITARIA DE GARCIA ROVIRA,NORTE Y GUTIERREZ"
    },
    {
        "value": "UNIVERSIDAD CATÓLICA LUIS AMIGÓ",
        "label": "UNIVERSIDAD CATÓLICA LUIS AMIGÓ"
    },
    {
        "value": "FUNDACION UNIVERSITARIA JUAN DE CASTELLANOS",
        "label": "FUNDACION UNIVERSITARIA JUAN DE CASTELLANOS"
    },
    {
        "value": "FUNDACION UNIVERSITARIA MARIA CANO",
        "label": "FUNDACION UNIVERSITARIA MARIA CANO"
    },
    {
        "value": "FUNDACION CENTRO UNIVERSITARIO DE BIENESTAR RURAL",
        "label": "FUNDACION CENTRO UNIVERSITARIO DE BIENESTAR RURAL"
    },
    {
        "value": "FUNDACION UNIVERSITARIA AGRARIA DE COLOMBIA -UNIAGRARIA-",
        "label": "FUNDACION UNIVERSITARIA AGRARIA DE COLOMBIA -UNIAGRARIA-"
    },
    {
        "value": "FUNDACION UNIVERSITARIA DE SAN GIL - UNISANGIL -",
        "label": "FUNDACION UNIVERSITARIA DE SAN GIL - UNISANGIL -"
    },
    {
        "value": "POLITECNICO GRANCOLOMBIANO",
        "label": "POLITECNICO GRANCOLOMBIANO"
    },
    {
        "value": "FUNDACION UNIVERSITARIA-CEIPA-",
        "label": "FUNDACION UNIVERSITARIA-CEIPA-"
    },
    {
        "value": "FUNDACION UNIVERSITARIA DEL AREA ANDINA",
        "label": "FUNDACION UNIVERSITARIA DEL AREA ANDINA"
    },
    {
        "value": "FUNDACION ESCUELA COLOMBIANA DE REHABILITACION",
        "label": "FUNDACION ESCUELA COLOMBIANA DE REHABILITACION"
    },
    {
        "value": "FUNDACION UNIVERSITARIA CATOLICA LUMEN GENTIUM - UNICATÓLICA - CALI",
        "label": "FUNDACION UNIVERSITARIA CATOLICA LUMEN GENTIUM - UNICATÓLICA - CALI"
    },
    {
        "value": "FUNDACION UNIVERSITARIA CATOLICA DEL NORTE",
        "label": "FUNDACION UNIVERSITARIA CATOLICA DEL NORTE"
    },
    {
        "value": "FUNDACIÓN UNIVERSITARIA SAN ALFONSO- FUSA-",
        "label": "FUNDACIÓN UNIVERSITARIA SAN ALFONSO- FUSA-"
    },
    {
        "value": "FUNDACION UNIVERSITARIA SEMINARIO BIBLICO DE COLOMBIA - FUSBC",
        "label": "FUNDACION UNIVERSITARIA SEMINARIO BIBLICO DE COLOMBIA - FUSBC"
    },
    {
        "value": "FUNDACION UNIVERSITARIA EMPRESARIAL DE LA CAMARA DE COMERCIO DE BOGOTA- UNIEMPRESARIAL",
        "label": "FUNDACION UNIVERSITARIA EMPRESARIAL DE LA CAMARA DE COMERCIO DE BOGOTA- UNIEMPRESARIAL"
    },
    {
        "value": "FUNDACION DE ESTUDIOS SUPERIORES UNIVERSITARIOS DE URABA ANTONIO ROLDAN BETANCUR",
        "label": "FUNDACION DE ESTUDIOS SUPERIORES UNIVERSITARIOS DE URABA ANTONIO ROLDAN BETANCUR"
    },
    {
        "value": "INSTITUCION UNIVERSITARIA COLOMBO AMERICANA - UNICA",
        "label": "INSTITUCION UNIVERSITARIA COLOMBO AMERICANA - UNICA"
    },
    {
        "value": "FUNDACION DE ESTUDIOS SUPERIORES - MONSEÑOR ABRAHAM ESCUDERO MONTOYA  - FUNDES",
        "label": "FUNDACION DE ESTUDIOS SUPERIORES - MONSEÑOR ABRAHAM ESCUDERO MONTOYA  - FUNDES"
    },
    {
        "value": "UNIVERSIDAD INTERNACIONAL DEL TRÓPICO AMERICANO",
        "label": "UNIVERSIDAD INTERNACIONAL DEL TRÓPICO AMERICANO"
    },
    {
        "value": "UNIVERSIDAD CESMAG - UNICESMAG",
        "label": "UNIVERSIDAD CESMAG - UNICESMAG"
    },
    {
        "value": "FUNDACIÓN UNIVERSITARIA COMPENSAR",
        "label": "FUNDACIÓN UNIVERSITARIA COMPENSAR"
    },
    {
        "value": "FUNDACION UNIVERSITARIA SANITAS",
        "label": "FUNDACION UNIVERSITARIA SANITAS"
    },
    {
        "value": "INSTITUCIÓN UNIVERSITARIA VISIÓN DE LAS AMÉRICAS",
        "label": "INSTITUCIÓN UNIVERSITARIA VISIÓN DE LAS AMÉRICAS"
    },
    {
        "value": "FUNDACION UNIVERSITARIA SEMINARIO TEOLOGICO BAUTISTA INTERNACIONAL",
        "label": "FUNDACION UNIVERSITARIA SEMINARIO TEOLOGICO BAUTISTA INTERNACIONAL"
    },
    {
        "value": "INSTITUCION UNIVERSITARIA  SALAZAR Y HERRERA",
        "label": "INSTITUCION UNIVERSITARIA  SALAZAR Y HERRERA"
    },
    {
        "value": "UNIVERSIDAD SIMON BOLIVAR",
        "label": "UNIVERSIDAD SIMON BOLIVAR"
    },
    {
        "value": "CORPORACION UNIVERSIDAD DE LA COSTA CUC",
        "label": "CORPORACION UNIVERSIDAD DE LA COSTA CUC"
    },
    {
        "value": "UNIVERSIDAD ESCUELA COLOMBIANA DE INGENIERIA JULIO GARAVITO",
        "label": "UNIVERSIDAD ESCUELA COLOMBIANA DE INGENIERIA JULIO GARAVITO"
    },
    {
        "value": "UNIVERSIDAD EAN",
        "label": "UNIVERSIDAD EAN"
    },
    {
        "value": "UNIVERSIDAD EIA",
        "label": "UNIVERSIDAD EIA"
    },
    {
        "value": "CORPORACION UNIVERSITARIA ADVENTISTA - UNAC",
        "label": "CORPORACION UNIVERSITARIA ADVENTISTA - UNAC"
    },
    {
        "value": "CORPORACION UNIVERSITARIA DE SANTA ROSA DE CABAL-UNISARC-",
        "label": "CORPORACION UNIVERSITARIA DE SANTA ROSA DE CABAL-UNISARC-"
    },
    {
        "value": "CORPORACION UNIVERSITARIA LASALLISTA",
        "label": "CORPORACION UNIVERSITARIA LASALLISTA"
    },
    {
        "value": "ESCUELA SUPERIOR DE OFTALMOLOGIA, INSTITUTO BARRAQUER DE AMERICA",
        "label": "ESCUELA SUPERIOR DE OFTALMOLOGIA, INSTITUTO BARRAQUER DE AMERICA"
    },
    {
        "value": "CORPORACION UNIVERSITARIA DEL CARIBE - CECAR",
        "label": "CORPORACION UNIVERSITARIA DEL CARIBE - CECAR"
    },
    {
        "value": "CORPORACION UNIVERSITARIA DE COLOMBIA IDEAS",
        "label": "CORPORACION UNIVERSITARIA DE COLOMBIA IDEAS"
    },
    {
        "value": "CORPORACION UNIVERSITARIA RAFAEL NUÑEZ",
        "label": "CORPORACION UNIVERSITARIA RAFAEL NUÑEZ"
    },
    {
        "value": "CORPORACION UNIVERSITARIA DEL META - UNIMETA",
        "label": "CORPORACION UNIVERSITARIA DEL META - UNIMETA"
    },
    {
        "value": "CORPORACION UNIVERSITARIA DEL HUILA-CORHUILA-",
        "label": "CORPORACION UNIVERSITARIA DEL HUILA-CORHUILA-"
    },
    {
        "value": "CORPORACION UNIVERSITARIA MINUTO DE DIOS -UNIMINUTO-",
        "label": "CORPORACION UNIVERSITARIA MINUTO DE DIOS -UNIMINUTO-"
    },
    {
        "value": "CORPORACION UNIVERSITARIA IBEROAMERICANA",
        "label": "CORPORACION UNIVERSITARIA IBEROAMERICANA"
    },
    {
        "value": "CORPORACION UNIVERSITARIA DE CIENCIA Y DESARROLLO - UNICIENCIA",
        "label": "CORPORACION UNIVERSITARIA DE CIENCIA Y DESARROLLO - UNICIENCIA"
    },
    {
        "value": "UNIVERSIDAD DE SANTANDER - UDES",
        "label": "UNIVERSIDAD DE SANTANDER - UDES"
    },
    {
        "value": "CORPORACION UNIVERSITARIA REMINGTON",
        "label": "CORPORACION UNIVERSITARIA REMINGTON"
    },
    {
        "value": "UNIVERSITARIA AGUSTINIANA- UNIAGUSTINIANA",
        "label": "UNIVERSITARIA AGUSTINIANA- UNIAGUSTINIANA"
    },
    {
        "value": "CORPORACION CRISTIANA UNIVERSITARIA DE SAN ANDRES, PROVIDENCIA Y SANTA CATALINA",
        "label": "CORPORACION CRISTIANA UNIVERSITARIA DE SAN ANDRES, PROVIDENCIA Y SANTA CATALINA"
    },
    {
        "value": "CORPORACION UNIVERSITARIA EMPRESARIAL DE SALAMANCA",
        "label": "CORPORACION UNIVERSITARIA EMPRESARIAL DE SALAMANCA"
    },
    {
        "value": "CORPORACION UNIVERSITARIA REPUBLICANA",
        "label": "CORPORACION UNIVERSITARIA REPUBLICANA"
    },
    {
        "value": "CORPORACION COLEGIATURA COLOMBIANA",
        "label": "CORPORACION COLEGIATURA COLOMBIANA"
    },
    {
        "value": "CORPORACION UNIVERSITARIA EMPRESARIAL ALEXANDER VON HUMBOLDT - CUE",
        "label": "CORPORACION UNIVERSITARIA EMPRESARIAL ALEXANDER VON HUMBOLDT - CUE"
    },
    {
        "value": "CORPORACION UNIVERSITARIA REFORMADA - CUR -",
        "label": "CORPORACION UNIVERSITARIA REFORMADA - CUR -"
    },
    {
        "value": "UNIVERSIDAD DE INVESTIGACION Y DESARROLLO - UDI",
        "label": "UNIVERSIDAD DE INVESTIGACION Y DESARROLLO - UDI"
    },
    {
        "value": "CORPORACION UNIVERSITARIA  UNITEC",
        "label": "CORPORACION UNIVERSITARIA  UNITEC"
    },
    {
        "value": "CORPORACION UNIVERSITARIA AUTONOMA DEL CAUCA",
        "label": "CORPORACION UNIVERSITARIA AUTONOMA DEL CAUCA"
    },
    {
        "value": "CORPORACION UNIVERSITARIA ANTONIO JOSE DE SUCRE - CORPOSUCRE",
        "label": "CORPORACION UNIVERSITARIA ANTONIO JOSE DE SUCRE - CORPOSUCRE"
    },
    {
        "value": "ESCUELA DE INTELIGENCIA Y CONTRAINTELIGENCIA BRIGADIER GENERAL RICARDO CHARRY SOLANO",
        "label": "ESCUELA DE INTELIGENCIA Y CONTRAINTELIGENCIA BRIGADIER GENERAL RICARDO CHARRY SOLANO"
    },
    {
        "value": "ESCUELA DE LOGISTICA",
        "label": "ESCUELA DE LOGISTICA"
    },
    {
        "value": "ESCUELA DE COMUNICACIONES",
        "label": "ESCUELA DE COMUNICACIONES"
    },
    {
        "value": "ESCUELA SUPERIOR DE GUERRA GENERAL RAFAEL REYES PRIETO",
        "label": "ESCUELA SUPERIOR DE GUERRA GENERAL RAFAEL REYES PRIETO"
    },
    {
        "value": "CENTRO DE EDUCACION MILITAR - CEMIL",
        "label": "CENTRO DE EDUCACION MILITAR - CEMIL"
    },
    {
        "value": "ESCUELA DE POSTGRADOS DE LA FAC-EPFAC",
        "label": "ESCUELA DE POSTGRADOS DE LA FAC-EPFAC"
    },
    {
        "value": "INSTITUTO SUPERIOR DE EDUCACION RURAL-ISER-",
        "label": "INSTITUTO SUPERIOR DE EDUCACION RURAL-ISER-"
    },
    {
        "value": "INSTITUCIÓN UNIVERSITARIA MAYOR DE CARTAGENA",
        "label": "INSTITUCIÓN UNIVERSITARIA MAYOR DE CARTAGENA"
    },
    {
        "value": "COLEGIO MAYOR DEL CAUCA",
        "label": "COLEGIO MAYOR DEL CAUCA"
    },
    {
        "value": "INSTITUCIÓN UNIVERSITARIA PASCUAL BRAVO",
        "label": "INSTITUCIÓN UNIVERSITARIA PASCUAL BRAVO"
    },
    {
        "value": "ESCUELA NAVAL DE SUBOFICIALES ARC BARRANQUILLA",
        "label": "ESCUELA NAVAL DE SUBOFICIALES ARC BARRANQUILLA"
    },
    {
        "value": "INSTITUTO TECNOLOGICO DEL PUTUMAYO",
        "label": "INSTITUTO TECNOLOGICO DEL PUTUMAYO"
    },
    {
        "value": "INSTITUCIÓN UNIVERSITARIA ITSA",
        "label": "INSTITUCIÓN UNIVERSITARIA ITSA"
    },
    {
        "value": "UNIDADES TECNOLOGICAS DE SANTANDER",
        "label": "UNIDADES TECNOLOGICAS DE SANTANDER"
    },
    {
        "value": "TECNOLOGICO DE ANTIOQUIA",
        "label": "TECNOLOGICO DE ANTIOQUIA"
    },
    {
        "value": "INSTITUCION UNIVERSITARIA ANTONIO JOSE CAMACHO",
        "label": "INSTITUCION UNIVERSITARIA ANTONIO JOSE CAMACHO"
    },
    {
        "value": "INSTITUTO TECNOLOGICO METROPOLITANO",
        "label": "INSTITUTO TECNOLOGICO METROPOLITANO"
    },
    {
        "value": "TECNOLÓGICO DE ARTES DÉBORA ARANGO INSTITUCIÓN REDEFINIDA",
        "label": "TECNOLÓGICO DE ARTES DÉBORA ARANGO INSTITUCIÓN REDEFINIDA"
    },
    {
        "value": "FUNDACION TECNOLOGICA AUTONOMA DE BOGOTA-FABA-",
        "label": "FUNDACION TECNOLOGICA AUTONOMA DE BOGOTA-FABA-"
    },
    {
        "value": "INSTITUCION UNIVERSITARIA ESCOLME",
        "label": "INSTITUCION UNIVERSITARIA ESCOLME"
    },
    {
        "value": "FUNDACION UNIVERSITARIA TECNOLOGICO COMFENALCO - CARTAGENA",
        "label": "FUNDACION UNIVERSITARIA TECNOLOGICO COMFENALCO - CARTAGENA"
    },
    {
        "value": "FUNDACION CENTRO COLOMBIANO DE ESTUDIOS PROFESIONALES, -F.C.E.C.E.P.",
        "label": "FUNDACION CENTRO COLOMBIANO DE ESTUDIOS PROFESIONALES, -F.C.E.C.E.P."
    },
    {
        "value": "FUNDACION UNIVERSITARIA ANTONIO DE AREVALO - UNITECNAR",
        "label": "FUNDACION UNIVERSITARIA ANTONIO DE AREVALO - UNITECNAR"
    },
    {
        "value": "FUNDACION CENTRO DE INVESTIGACION DOCENCIA Y CONSULTORIA ADMINISTRATIVA-F-CIDCA-",
        "label": "FUNDACION CENTRO DE INVESTIGACION DOCENCIA Y CONSULTORIA ADMINISTRATIVA-F-CIDCA-"
    },
    {
        "value": "FUNDACION UNIVERSITARIA PARA EL DESARROLLO HUMANO - UNINPAHU",
        "label": "FUNDACION UNIVERSITARIA PARA EL DESARROLLO HUMANO - UNINPAHU"
    },
    {
        "value": "FUNDACION TECNOLOGICA AUTONOMA DEL PACIFICO",
        "label": "FUNDACION TECNOLOGICA AUTONOMA DEL PACIFICO"
    },
    {
        "value": "TECNOLOGICA FITEC",
        "label": "TECNOLOGICA FITEC"
    },
    {
        "value": "FUNDACION DE ESTUDIOS SUPERIORES COMFANORTE -F.E.S.C.-",
        "label": "FUNDACION DE ESTUDIOS SUPERIORES COMFANORTE -F.E.S.C.-"
    },
    {
        "value": "INSTITUCION UNIVERSITARIA LATINA - UNILATINA",
        "label": "INSTITUCION UNIVERSITARIA LATINA - UNILATINA"
    },
    {
        "value": "FUNDACION UNIVERSITARIA ESUMER",
        "label": "FUNDACION UNIVERSITARIA ESUMER"
    },
    {
        "value": "FUNDACIÓN EUROCOLOMBIANA DE EDUCACIÓN SUPERIOR - EUROCOL",
        "label": "FUNDACIÓN EUROCOLOMBIANA DE EDUCACIÓN SUPERIOR - EUROCOL"
    },
    {
        "value": "FUNDACION TECNOLOGICA DE MADRID",
        "label": "FUNDACION TECNOLOGICA DE MADRID"
    },
    {
        "value": "FUNDACION DE EDUCACION SUPERIOR ALBERTO MERANI",
        "label": "FUNDACION DE EDUCACION SUPERIOR ALBERTO MERANI"
    },
    {
        "value": "CORPORACION DE ESTUDIOS TECNOLOGICOS DEL NORTE DEL VALLE",
        "label": "CORPORACION DE ESTUDIOS TECNOLOGICOS DEL NORTE DEL VALLE"
    },
    {
        "value": "CENTRO EDUCACIONAL DE COMPUTOS Y SISTEMAS-CEDESISTEMAS-",
        "label": "CENTRO EDUCACIONAL DE COMPUTOS Y SISTEMAS-CEDESISTEMAS-"
    },
    {
        "value": "CORPORACION UNIVERSITARIA CENTRO SUPERIOR - UNICUCES",
        "label": "CORPORACION UNIVERSITARIA CENTRO SUPERIOR - UNICUCES"
    },
    {
        "value": "INSTITUTO DE EDUCACION EMPRESARIAL-IDEE-",
        "label": "INSTITUTO DE EDUCACION EMPRESARIAL-IDEE-"
    },
    {
        "value": "CORPORACION ESCUELA SUPERIOR DE ADMINISTRACION Y ESTUDIOS TECNOLOGICOS- EAE",
        "label": "CORPORACION ESCUELA SUPERIOR DE ADMINISTRACION Y ESTUDIOS TECNOLOGICOS- EAE"
    },
    {
        "value": "ESCUELA DE TECNOLOGIAS DE ANTIOQUIA -ETA-",
        "label": "ESCUELA DE TECNOLOGIAS DE ANTIOQUIA -ETA-"
    },
    {
        "value": "CORPORACION TECNOLOGICA DE BOGOTA - CTB",
        "label": "CORPORACION TECNOLOGICA DE BOGOTA - CTB"
    },
    {
        "value": "INSTITUTO SUPERIOR DE CIENCIAS SOCIALES Y ECONOMICO FAMILIARES-ICSEF-",
        "label": "INSTITUTO SUPERIOR DE CIENCIAS SOCIALES Y ECONOMICO FAMILIARES-ICSEF-"
    },
    {
        "value": "CORPORACION EDUCATIVA -ITAE-",
        "label": "CORPORACION EDUCATIVA -ITAE-"
    },
    {
        "value": "CORPORACION DE EDUCACION DEL NORTE DEL TOLIMA - COREDUCACION",
        "label": "CORPORACION DE EDUCACION DEL NORTE DEL TOLIMA - COREDUCACION"
    },
    {
        "value": "INSTITUCION UNIVERSITARIA MARCO FIDEL SUAREZ - IUMAFIS",
        "label": "INSTITUCION UNIVERSITARIA MARCO FIDEL SUAREZ - IUMAFIS"
    },
    {
        "value": "CORPORACION UNIVERSITARIA AUTONOMA DE NARIÑO -AUNAR-",
        "label": "CORPORACION UNIVERSITARIA AUTONOMA DE NARIÑO -AUNAR-"
    },
    {
        "value": "CORPORACION TECNOLOGICA INDUSTRIAL COLOMBIANA - TEINCO",
        "label": "CORPORACION TECNOLOGICA INDUSTRIAL COLOMBIANA - TEINCO"
    },
    {
        "value": "CORPORACION ACADEMIA TECNOLOGICA DE COLOMBIA -ATEC-",
        "label": "CORPORACION ACADEMIA TECNOLOGICA DE COLOMBIA -ATEC-"
    },
    {
        "value": "CORPORACION POLITECNICO DE LA COSTA ATLANTICA",
        "label": "CORPORACION POLITECNICO DE LA COSTA ATLANTICA"
    },
    {
        "value": "POLITECNICO ICAFT",
        "label": "POLITECNICO ICAFT"
    },
    {
        "value": "UNION AMERICANA DE EDUCACION SUPERIOR- UNION AMERICANA",
        "label": "UNION AMERICANA DE EDUCACION SUPERIOR- UNION AMERICANA"
    },
    {
        "value": "CENTRO DE CONOCIMIENTO ALEJANDRÍA - ALEJANDRÍA",
        "label": "CENTRO DE CONOCIMIENTO ALEJANDRÍA - ALEJANDRÍA"
    },
    {
        "value": "CORPORACION INTERNACIONAL PARA EL DESARROLLO EDUCATIVO -CIDE-",
        "label": "CORPORACION INTERNACIONAL PARA EL DESARROLLO EDUCATIVO -CIDE-"
    },
    {
        "value": "POLITECNICO SANTAFE DE BOGOTA",
        "label": "POLITECNICO SANTAFE DE BOGOTA"
    },
    {
        "value": "CORPORACIÓN TECNOLÓGICA DE EDUCACIÓN SUPERIOR SAPIENZA -CTE-",
        "label": "CORPORACIÓN TECNOLÓGICA DE EDUCACIÓN SUPERIOR SAPIENZA -CTE-"
    },
    {
        "value": "CORPORACIÓN JOHN F. KENNEDY",
        "label": "CORPORACIÓN JOHN F. KENNEDY"
    },
    {
        "value": "CORPORACION UNIVERSAL DE INVESTIGACION Y TECNOLOGIA -CORUNIVERSITEC-",
        "label": "CORPORACION UNIVERSAL DE INVESTIGACION Y TECNOLOGIA -CORUNIVERSITEC-"
    },
    {
        "value": "CORPORACION UNIVERSITARIA COMFACAUCA - UNICOMFACAUCA",
        "label": "CORPORACION UNIVERSITARIA COMFACAUCA - UNICOMFACAUCA"
    },
    {
        "value": "CORPORACION TECNOLOGICA  SUPERIOR SIGLO  XXI",
        "label": "CORPORACION TECNOLOGICA  SUPERIOR SIGLO  XXI"
    },
    {
        "value": "CORPORACION TECNOLOGICO COPACABANA - TECO -",
        "label": "CORPORACION TECNOLOGICO COPACABANA - TECO -"
    },
    {
        "value": "CORPORACION TECNOLOGICA CATOLICA DE OCCIDENTE - TECOC -",
        "label": "CORPORACION TECNOLOGICA CATOLICA DE OCCIDENTE - TECOC -"
    },
    {
        "value": "ESCUELA DE FORMACION DE INFANTERIA DE MARINA",
        "label": "ESCUELA DE FORMACION DE INFANTERIA DE MARINA"
    },
    {
        "value": "ESCUELA  MILITAR DE SUBOFICIALES SARGENTO INOCENCIO CHINCA",
        "label": "ESCUELA  MILITAR DE SUBOFICIALES SARGENTO INOCENCIO CHINCA"
    },
    {
        "value": "INSTITUTO DE EDUCACION TECNICA PROFESIONAL DE ROLDANILLO",
        "label": "INSTITUTO DE EDUCACION TECNICA PROFESIONAL DE ROLDANILLO"
    },
    {
        "value": "INSTITUTO NACIONAL DE FORMACION TECNICA PROFESIONAL DE SAN JUAN DEL CESAR",
        "label": "INSTITUTO NACIONAL DE FORMACION TECNICA PROFESIONAL DE SAN JUAN DEL CESAR"
    },
    {
        "value": "INSTITUTO NACIONAL DE FORMACION TECNICA PROFESIONAL DE SAN ANDRES",
        "label": "INSTITUTO NACIONAL DE FORMACION TECNICA PROFESIONAL DE SAN ANDRES"
    },
    {
        "value": "INSTITUTO TECNICO AGRICOLA ITA",
        "label": "INSTITUTO TECNICO AGRICOLA ITA"
    },
    {
        "value": "ESCUELA TECNOLOGICA INSTITUTO TECNICO CENTRAL",
        "label": "ESCUELA TECNOLOGICA INSTITUTO TECNICO CENTRAL"
    },
    {
        "value": "INSTITUTO TECNICO NACIONAL DE COMERCIO SIMON RODRIGUEZ - INTENALCO",
        "label": "INSTITUTO TECNICO NACIONAL DE COMERCIO SIMON RODRIGUEZ - INTENALCO"
    },
    {
        "value": "INSTITUTO TOLIMENSE DE FORMACION TECNICA PROFESIONAL",
        "label": "INSTITUTO TOLIMENSE DE FORMACION TECNICA PROFESIONAL"
    },
    {
        "value": "INSTITUTO NACIONAL DE FORMACION TECNICA PROFESIONAL - HUMBERTO VELASQUEZ GARCIA",
        "label": "INSTITUTO NACIONAL DE FORMACION TECNICA PROFESIONAL - HUMBERTO VELASQUEZ GARCIA"
    },
    {
        "value": "COLEGIO INTEGRADO NACIONAL ORIENTE DE CALDAS - IES CINOC",
        "label": "COLEGIO INTEGRADO NACIONAL ORIENTE DE CALDAS - IES CINOC"
    },
    {
        "value": "UNIDAD TECNICA PROFESIONAL DE SEVILLA-UNITEPS-",
        "label": "UNIDAD TECNICA PROFESIONAL DE SEVILLA-UNITEPS-"
    },
    {
        "value": "FUNDACION ACADEMIA DE DIBUJO PROFESIONAL",
        "label": "FUNDACION ACADEMIA DE DIBUJO PROFESIONAL"
    },
    {
        "value": "FUNDACION DE EDUCACION SUPERIOR SAN JOSE -FESSANJOSE-",
        "label": "FUNDACION DE EDUCACION SUPERIOR SAN JOSE -FESSANJOSE-"
    },
    {
        "value": "FUNDACION PARA LA EDUCACION SUPERIOR REAL DE COLOMBIA",
        "label": "FUNDACION PARA LA EDUCACION SUPERIOR REAL DE COLOMBIA"
    },
    {
        "value": "FUNDACIÓN DE EDUCACIÓN SUPERIOR UPARSISTEM",
        "label": "FUNDACIÓN DE EDUCACIÓN SUPERIOR UPARSISTEM"
    },
    {
        "value": "FUNDACION ESCUELA COLOMBIANA DE HOTELERIA Y TURISMO-ECOTET-",
        "label": "FUNDACION ESCUELA COLOMBIANA DE HOTELERIA Y TURISMO-ECOTET-"
    },
    {
        "value": "INSTITUCION UNIVERSITARIA EAM",
        "label": "INSTITUCION UNIVERSITARIA EAM"
    },
    {
        "value": "FUNDACIÓN POLITÉCNICO MINUTO DE DIOS - TEC MD",
        "label": "FUNDACIÓN POLITÉCNICO MINUTO DE DIOS - TEC MD"
    },
    {
        "value": "FUNDACION DE EDUCACION SUPERIOR ESATEC",
        "label": "FUNDACION DE EDUCACION SUPERIOR ESATEC"
    },
    {
        "value": "FUNDACION INTERAMERICANA TECNICA-FIT-",
        "label": "FUNDACION INTERAMERICANA TECNICA-FIT-"
    },
    {
        "value": "FUNDACION INSTITUTO DE CARRERAS TECNICAS PROFESIONALES-FIDCI-",
        "label": "FUNDACION INSTITUTO DE CARRERAS TECNICAS PROFESIONALES-FIDCI-"
    },
    {
        "value": "FUNDACION DE EDUCACION SUPERIOR NUEVA AMERICA",
        "label": "FUNDACION DE EDUCACION SUPERIOR NUEVA AMERICA"
    },
    {
        "value": "FUNDACION UNIVERSITARIA HORIZONTE",
        "label": "FUNDACION UNIVERSITARIA HORIZONTE"
    },
    {
        "value": "FUNDACION DE EDUCACION SUPERIOR SIKUANI - FESI",
        "label": "FUNDACION DE EDUCACION SUPERIOR SIKUANI - FESI"
    },
    {
        "value": "FUNDACION UNIVERSITARIA SAN MATEO - SAN MATEO EDUCACION SUPERIOR",
        "label": "FUNDACION UNIVERSITARIA SAN MATEO - SAN MATEO EDUCACION SUPERIOR"
    },
    {
        "value": "POLITECNICO INTERNACIONAL INSTITUCION DE EDUCACION SUPERIOR",
        "label": "POLITECNICO INTERNACIONAL INSTITUCION DE EDUCACION SUPERIOR"
    },
    {
        "value": "CORPORACION ACADEMIA SUPERIOR DE ARTES",
        "label": "CORPORACION ACADEMIA SUPERIOR DE ARTES"
    },
    {
        "value": "CORPORACION POLITECNICO COLOMBO ANDINO",
        "label": "CORPORACION POLITECNICO COLOMBO ANDINO"
    },
    {
        "value": "CORPORACION EDUCATIVA DEL ORIENTE",
        "label": "CORPORACION EDUCATIVA DEL ORIENTE"
    },
    {
        "value": "CORPORACION TECNICA DE COLOMBIA -CORPOTEC-",
        "label": "CORPORACION TECNICA DE COLOMBIA -CORPOTEC-"
    },
    {
        "value": "CORPORACION CENTRO DE ESTUDIOS ARTISTICOS Y TECNICOS-CEART-",
        "label": "CORPORACION CENTRO DE ESTUDIOS ARTISTICOS Y TECNICOS-CEART-"
    },
    {
        "value": "CORPORACION REGIONAL DE EDUCACION SUPERIOR-CRES-DE CALI",
        "label": "CORPORACION REGIONAL DE EDUCACION SUPERIOR-CRES-DE CALI"
    },
    {
        "value": "CORPORACION CENTRO TECNICO ARQUITECTONICO",
        "label": "CORPORACION CENTRO TECNICO ARQUITECTONICO"
    },
    {
        "value": "CORPORACION UNIVERSITARIA CENDA",
        "label": "CORPORACION UNIVERSITARIA CENDA"
    },
    {
        "value": "CORPORACION DE ESTUDIOS SUPERIORES SALAMANDRA",
        "label": "CORPORACION DE ESTUDIOS SUPERIORES SALAMANDRA"
    },
    {
        "value": "CORPORACION DE EDUCACION SUPERIOR SURAMERICA",
        "label": "CORPORACION DE EDUCACION SUPERIOR SURAMERICA"
    },
    {
        "value": "CORPORACION UNIFICADA NACIONAL DE EDUCACION SUPERIOR-CUN-",
        "label": "CORPORACION UNIFICADA NACIONAL DE EDUCACION SUPERIOR-CUN-"
    },
    {
        "value": "CORPORACION DE EDUCACION SUPERIOR MIGUEL CAMACHO PEREA",
        "label": "CORPORACION DE EDUCACION SUPERIOR MIGUEL CAMACHO PEREA"
    },
    {
        "value": "CORPORACION EDUCATIVA DEL LITORAL",
        "label": "CORPORACION EDUCATIVA DEL LITORAL"
    },
    {
        "value": "CORPORACION UNIVERSITARIA LATINOAMERICANA - CUL",
        "label": "CORPORACION UNIVERSITARIA LATINOAMERICANA - CUL"
    },
    {
        "value": "CORPORACION ESCUELA DE ARTES Y LETRAS",
        "label": "CORPORACION ESCUELA DE ARTES Y LETRAS"
    },
    {
        "value": "CORPORACION INSTITUTO DE ADMINISTRACION Y FINANZAS - CIAF",
        "label": "CORPORACION INSTITUTO DE ADMINISTRACION Y FINANZAS - CIAF"
    },
    {
        "value": "CORPORACION UNIVERSITARIA REGIONAL DEL CARIBE -IAFIC-",
        "label": "CORPORACION UNIVERSITARIA REGIONAL DEL CARIBE -IAFIC-"
    },
    {
        "value": "CORPORACION EDUCATIVA INSTITUTO TECNICO SUPERIOR DE ARTES, IDEARTES",
        "label": "CORPORACION EDUCATIVA INSTITUTO TECNICO SUPERIOR DE ARTES, IDEARTES"
    },
    {
        "value": "CORPORACION INTERAMERICANA DE EDUCACION SUPERIOR-CORPOCIDES",
        "label": "CORPORACION INTERAMERICANA DE EDUCACION SUPERIOR-CORPOCIDES"
    },
    {
        "value": "CORPORACION INSTITUTO SUPERIOR DE EDUCACION SOCIAL-ISES-",
        "label": "CORPORACION INSTITUTO SUPERIOR DE EDUCACION SOCIAL-ISES-"
    },
    {
        "value": "CORPORACION UNIVERSITARIA TALLER CINCO CENTRO DE DISEÑO",
        "label": "CORPORACION UNIVERSITARIA TALLER CINCO CENTRO DE DISEÑO"
    },
    {
        "value": "CORPORACION UNIVERSITARIA DE CIENCIAS EMPRESARIALES, EDUCACION Y SALUD -UNICORSALUD-",
        "label": "CORPORACION UNIVERSITARIA DE CIENCIAS EMPRESARIALES, EDUCACION Y SALUD -UNICORSALUD-"
    },
    {
        "value": "CORPORACION BOLIVARIANA DE EDUCACION SUPERIOR - CORBES -",
        "label": "CORPORACION BOLIVARIANA DE EDUCACION SUPERIOR - CORBES -"
    },
    {
        "value": "CORPORACION ESCUELA TECNOLOGICA DEL ORIENTE",
        "label": "CORPORACION ESCUELA TECNOLOGICA DEL ORIENTE"
    },
    {
        "value": "UNIVERSIDAD ECCI",
        "label": "UNIVERSIDAD ECCI"
    },
    {
        "value": "ESCUELA DE INVESTIGACION CRIMINAL",
        "label": "ESCUELA DE INVESTIGACION CRIMINAL"
    },
    {
        "value": "ESCUELA DE SUBOFICIALES DE LA FUERZA AEREA COLOMBIANA ANDRES M. DIAZ",
        "label": "ESCUELA DE SUBOFICIALES DE LA FUERZA AEREA COLOMBIANA ANDRES M. DIAZ"
    },
    {
        "value": "ESCUELA MILITAR DE AVIACION MARCO FIDEL SUAREZ",
        "label": "ESCUELA MILITAR DE AVIACION MARCO FIDEL SUAREZ"
    },
    {
        "value": "ESCUELA MILITAR DE CADETES GENERAL JOSE MARIA CORDOVA",
        "label": "ESCUELA MILITAR DE CADETES GENERAL JOSE MARIA CORDOVA"
    },
    {
        "value": "ESCUELA NAVAL DE CADETES ALMIRANTE PADILLA",
        "label": "ESCUELA NAVAL DE CADETES ALMIRANTE PADILLA"
    },
    {
        "value": "ESCUELA DE INGENIEROS MILITARES",
        "label": "ESCUELA DE INGENIEROS MILITARES"
    },
    {
        "value": "INSTITUTO CARO Y CUERVO",
        "label": "INSTITUTO CARO Y CUERVO"
    },
    {
        "value": "INSTITUTO TECNOLOGICO DE ELECTRONICA Y COMUNICACIONES-ITEC-",
        "label": "INSTITUTO TECNOLOGICO DE ELECTRONICA Y COMUNICACIONES-ITEC-"
    },
    {
        "value": "SERVICIO NACIONAL DE APRENDIZAJE-SENA-",
        "label": "SERVICIO NACIONAL DE APRENDIZAJE-SENA-"
    },
    {
        "value": "FUNDACION UNIVERSITARIA CLARETIANA - UNICLARETIANA",
        "label": "FUNDACION UNIVERSITARIA CLARETIANA - UNICLARETIANA"
    },
    {
        "value": "ESCUELA INTERNACIONAL DE ESTUDIOS SUPERIORES - INTER",
        "label": "ESCUELA INTERNACIONAL DE ESTUDIOS SUPERIORES - INTER"
    },
    {
        "value": "CORPORACION UNIVERSITARIA AMERICANA",
        "label": "CORPORACION UNIVERSITARIA AMERICANA"
    },
    {
        "value": "FUNDACION UNIVERSITARIA BELLAS ARTES",
        "label": "FUNDACION UNIVERSITARIA BELLAS ARTES"
    },
    {
        "value": "FUNDACION UNIVERSITARIA COLOMBO INTERNACIONAL - UNICOLOMBO",
        "label": "FUNDACION UNIVERSITARIA COLOMBO INTERNACIONAL - UNICOLOMBO"
    },
    {
        "value": "TECNOLOGICO COREDI",
        "label": "TECNOLOGICO COREDI"
    },
    {
        "value": "CORPORACION TECNOLOGICA INDOAMERICA",
        "label": "CORPORACION TECNOLOGICA INDOAMERICA"
    },
    {
        "value": "CORPORACION UNIVERSITARIA DE SABANETA - UNISABANETA",
        "label": "CORPORACION UNIVERSITARIA DE SABANETA - UNISABANETA"
    },
    {
        "value": "LCI - FUNDACION TECNOLOGICA",
        "label": "LCI - FUNDACION TECNOLOGICA"
    },
    {
        "value": "FUNDACION UNIVERSITARIA CAFAM -UNICAFAM",
        "label": "FUNDACION UNIVERSITARIA CAFAM -UNICAFAM"
    },
    {
        "value": "INSTITUTO TECNOLOGICO COMFAMILIAR DE PUTUMAYO",
        "label": "INSTITUTO TECNOLOGICO COMFAMILIAR DE PUTUMAYO"
    },
    {
        "value": "FUNDACIÓN UNIVERSITARIA CERVANTES SAN AGUSTÍN - UNICERVANTES",
        "label": "FUNDACIÓN UNIVERSITARIA CERVANTES SAN AGUSTÍN - UNICERVANTES"
    },
    {
        "value": "FUNDACION UNIVERSITARIA CIEO - UNICIEO",
        "label": "FUNDACION UNIVERSITARIA CIEO - UNICIEO"
    },
    {
        "value": "INSTITUCION UNIVERSITARIA DE COLOMBIA - UNIVERSITARIA DE COLOMBIA",
        "label": "INSTITUCION UNIVERSITARIA DE COLOMBIA - UNIVERSITARIA DE COLOMBIA"
    },
    {
        "value": "CORPORACION UNIVERSITARIA U DE COLOMBIA",
        "label": "CORPORACION UNIVERSITARIA U DE COLOMBIA"
    },
    {
        "value": "INSTITUCION UNIVERSITARIA CENTRO DE ESTUDIOS SUPERIORES MARIA GORETTI",
        "label": "INSTITUCION UNIVERSITARIA CENTRO DE ESTUDIOS SUPERIORES MARIA GORETTI"
    },
    {
        "value": "FUNDACION UNIVERSITARIA COMFENALCO SANTANDER",
        "label": "FUNDACION UNIVERSITARIA COMFENALCO SANTANDER"
    },
    {
        "value": "CORPORACIÓN COLSUBSIDIO EDUCACIÓN TECNOLÓGICA - CET",
        "label": "CORPORACIÓN COLSUBSIDIO EDUCACIÓN TECNOLÓGICA - CET"
    },
    {
        "value": "FUNDACION UNIVERSITARIA COLOMBO GERMANA",
        "label": "FUNDACION UNIVERSITARIA COLOMBO GERMANA"
    },
    {
        "value": "FUNDACION ESCUELA TECNOLOGICA DE NEIVA - JESUS OVIEDO PEREZ -FET",
        "label": "FUNDACION ESCUELA TECNOLOGICA DE NEIVA - JESUS OVIEDO PEREZ -FET"
    },
    {
        "value": "CORPORACION UNIVERSITARIA PARA EL DESARROLLO EMPRESARIAL Y SOCIAL- CUDES",
        "label": "CORPORACION UNIVERSITARIA PARA EL DESARROLLO EMPRESARIAL Y SOCIAL- CUDES"
    },
    {
        "value": "FUNDACION UNIVERSITARIA NAVARRA - UNINAVARRA",
        "label": "FUNDACION UNIVERSITARIA NAVARRA - UNINAVARRA"
    },
    {
        "value": "FUNDACION UNIVERSITARIA LUIS G. PAEZ - UNIPAEZ",
        "label": "FUNDACION UNIVERSITARIA LUIS G. PAEZ - UNIPAEZ"
    },
    {
        "value": "CORPORACION UNIVERSITARIA DE ASTURIAS",
        "label": "CORPORACION UNIVERSITARIA DE ASTURIAS"
    },
    {
        "value": "ESEIT - ESCUELA SUPERIOR DE EMPRESA, INGENIERÍA Y TECNOLOGÍA",
        "label": "ESEIT - ESCUELA SUPERIOR DE EMPRESA, INGENIERÍA Y TECNOLOGÍA"
    },
    {
        "value": "UNIVERSITARIA VIRTUAL INTERNACIONAL",
        "label": "UNIVERSITARIA VIRTUAL INTERNACIONAL"
    },
    {
        "value": "INSTITUCION UNIVERSITARIA CONOCIMIENTO E INNOVACION PARA LA JUSTICIA - CIJ",
        "label": "INSTITUCION UNIVERSITARIA CONOCIMIENTO E INNOVACION PARA LA JUSTICIA - CIJ"
    },
    {
        "value": "TECNOLOGICO DE ENERGIA E INNOVACION-  E-LERNOVA",
        "label": "TECNOLOGICO DE ENERGIA E INNOVACION-  E-LERNOVA"
    },
    {
        "value": "FUNDACION UNIVERSITARIA CATOLICA DEL SUR - UNICATOLICA DEL SUR",
        "label": "FUNDACION UNIVERSITARIA CATOLICA DEL SUR - UNICATOLICA DEL SUR"
    },
    {
        "value": "FUNDACION UNIVERSITARIA COMFAMILIAR RISARALDA",
        "label": "FUNDACION UNIVERSITARIA COMFAMILIAR RISARALDA"
    },
    {
        "value": "CORPORACION UNIVERSITARIA DE CATALUÑA",
        "label": "CORPORACION UNIVERSITARIA DE CATALUÑA"
    },
    {
        "value": "FUNDACION UNIVERSITARIA INTERNACIONAL DE COLOMBIA - UNINCOL",
        "label": "FUNDACION UNIVERSITARIA INTERNACIONAL DE COLOMBIA - UNINCOL"
    },
    {
        "value": "FUNDACION UNIVERSITARIA FCV",
        "label": "FUNDACION UNIVERSITARIA FCV"
    },
    {
        "value": "FUNDACION UNIVERSITARIA INTERNACIONAL DE LA RIOJA - UNIR",
        "label": "FUNDACION UNIVERSITARIA INTERNACIONAL DE LA RIOJA - UNIR"
    },
    {
        "value": "INSTITUCION UNIVERSITARIA DIGITAL DE ANTIOQUIA -IU. DIGITAL",
        "label": "INSTITUCION UNIVERSITARIA DIGITAL DE ANTIOQUIA -IU. DIGITAL"
    },
    {
        "value": "FUNDACIÓN UNIVERSITARIA SALESIANA",
        "label": "FUNDACIÓN UNIVERSITARIA SALESIANA"
    },
    {
        "value": "UNIVERSIDAD AUTÓNOMA INDÍGENA INTERCULTURAL - UAIIN",
        "label": "UNIVERSIDAD AUTÓNOMA INDÍGENA INTERCULTURAL - UAIIN"
    },
    {
        "value": "INSTITUCIÓN UNIVERSITARIA COMANDO DE EDUCACIÓN Y DOCTRINA - CEDOC DEL EJÉRCITO NACIONAL",
        "label": "INSTITUCIÓN UNIVERSITARIA COMANDO DE EDUCACIÓN Y DOCTRINA - CEDOC DEL EJÉRCITO NACIONAL"
    },
    {
        "value": "FUNDACIÓN UNIVERSITARIA PATRICIO SYMES",
        "label": "FUNDACIÓN UNIVERSITARIA PATRICIO SYMES"
    },
    {
        "value": "FUNDACIÓN UNIVERSITARIA SAN PABLO - UNISANPABLO",
        "label": "FUNDACIÓN UNIVERSITARIA SAN PABLO - UNISANPABLO"
    },
    {
        "value": "CENTRO DE ESTUDIOS AERONÁUTICOS - CEA",
        "label": "CENTRO DE ESTUDIOS AERONÁUTICOS - CEA"
    },
    {
        "value": "UNIDAD TECNOLÓGICA DEL MAGDALENA MEDIO - UTEM -",
        "label": "UNIDAD TECNOLÓGICA DEL MAGDALENA MEDIO - UTEM -"
    },
    {
        "value": "CORPORACIÓN UNIVERSITARIA AUTÓNOMA DEL NORTE",
        "label": "CORPORACIÓN UNIVERSITARIA AUTÓNOMA DEL NORTE"
    },
    {
        "value": "CORPORACION TECNICA PROFESIONAL CAMINOS DEL SUROCCIDENTE COLOMBIANO",
        "label": "CORPORACION TECNICA PROFESIONAL CAMINOS DEL SUROCCIDENTE COLOMBIANO"
    },
    {
        "value": "UNIVERSITARIA DEL CHICAMOCHA",
        "label": "UNIVERSITARIA DEL CHICAMOCHA"
    },
    {
        "value": "FUNDACIÓN UNIVERSITARIA CATÓLICA DEL CARIBE",
        "label": "FUNDACIÓN UNIVERSITARIA CATÓLICA DEL CARIBE"
    }
]

export default universities