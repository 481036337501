import React, { useContext } from "react";
import "./Sidebar.css";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { logoutUser } from "../../../actions";
import SidebarCv from "./SidebarCv/SidebarCv";
import { UniversityContext } from "../../context/UniversitiesContext";
import ComputerOutlinedIcon from "@mui/icons-material/ComputerOutlined";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import BarChartIcon from "@mui/icons-material/BarChart";
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import SidebarOp from "./sidebarOp/SidebarOp";
export default function UniversitiesSideBar({ location, style }) {
  const { university } = useContext(UniversityContext);
  const { lang, user } = useSelector((state) => state);
  const dispatch = useDispatch();

  const handleLogout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    localStorage.removeItem("redirect");
    localStorage.removeItem("plan_premium");
    localStorage.removeItem("unilever");
    localStorage.removeItem("unilever_rol");
    localStorage.removeItem("unilever_id");
    dispatch(logoutUser({}, `/${university.route}`));
  };

  return (
    <>
      {university && university.color && (
        <div
          className={style ? "Sidebar-Cont2" : "Sidebar-Cont"}
          style={{
            backgroundColor: university.color.secondary,
          }}
        >
          <div className="contenedor-tour"></div>
          <div className={"Sidebar-Sub-Cont-1"}>
            <Link
              to={
                user.roles === "coallier"
                  ? `/${university.route}/home`
                  : `/${university.route}/company/home`
              }
            >
              <img
                className={"Sidebar-Logo"}
                src={university.logo}
                alt={"Logo Coally"}
              />
            </Link>
            <Link
              to={
                user.roles === "coallier"
                  ? `/${university.route}/home`
                  : `/${university.route}/company/home`
              }
              className={
                location === "home"
                  ? "Sidebar-Sub-Cont-But-active"
                  : "Sidebar-Sub-Cont-But"
              }
              style={
                location === "home"
                  ? {
                    color: university.color.quaternary
                      ? university.color.quaternary
                      : university.color.secondary,
                  }
                  : {
                    color: university.color.quaternary
                      ? university.color.quaternary
                      : "#fff",
                  }
              }
            >
              <div style={{ marginRight: "15px" }}>
                {location === "home" ? (
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M3 22.1818V10.1818L12 2L21 10.1818V22.1818H3Z"
                      stroke={
                        university.color.quaternary
                          ? university.color.quaternary
                          : university.color.secondary
                      }
                    />
                    <rect
                      x="9"
                      y="14"
                      width="6"
                      height="8"
                      rx="1"
                      stroke={
                        university.color.quaternary
                          ? university.color.quaternary
                          : university.color.secondary
                      }
                    />
                  </svg>
                ) : (
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M3 22.1818V10.1818L12 2L21 10.1818V22.1818H3Z"
                      stroke={
                        university.color.quaternary
                          ? university.color.quaternary
                          : "#fff"
                      }
                    />
                    <rect
                      x="9"
                      y="14"
                      width="6"
                      height="8"
                      rx="1"
                      stroke={
                        university.color.quaternary
                          ? university.color.quaternary
                          : "#fff"
                      }
                    />
                  </svg>
                )}
              </div>
              {lang === "es" ? "Inicio" : "Home"}
            </Link>
            <Link
              to={
                user.roles === "coallier"
                  ? `/${university.route}/oportunidades`
                  : `/${university.route}/company/project`
              }
              className={
                location === "oportunidades"
                  ? "Sidebar-Sub-Cont-But-active"
                  : "Sidebar-Sub-Cont-But"
              }
              style={
                location === "oportunidades"
                  ? {
                    color: university.color.quaternary
                      ? university.color.quaternary
                      : university.color.secondary,
                  }
                  : {
                    color: university.color.quaternary
                      ? university.color.quaternary
                      : "#fff",
                  }
              }
            >
              <div style={{ marginRight: "15px" }}>
                {location === "oportunidades" ? (
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M19 3V21H8C6.34315 21 5 19.6569 5 18V6C5 4.34315 6.34315 3 8 3H19Z"
                      stroke={
                        university.color.quaternary
                          ? university.color.quaternary
                          : university.color.secondary
                      }
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M5 18V18C5 16.3431 6.34315 15 8 15H18C18.5523 15 19 15.4477 19 16V17"
                      stroke={
                        university.color.quaternary
                          ? university.color.quaternary
                          : university.color.secondary
                      }
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                ) : (
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M19 3V21H8C6.34315 21 5 19.6569 5 18V6C5 4.34315 6.34315 3 8 3H19Z"
                      stroke={
                        university.color.quaternary
                          ? university.color.quaternary
                          : "#fff"
                      }
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M5 18V18C5 16.3431 6.34315 15 8 15H18C18.5523 15 19 15.4477 19 16V17"
                      stroke={
                        university.color.quaternary
                          ? university.color.quaternary
                          : "#fff"
                      }
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                )}
              </div>
              {lang === "es" ? "Oportunidades" : "Opportunities"}
            </Link>
            {user.roles === "company" ? null : (
              <Link
                to={
                  user.roles === "coallier"
                    ? `/${university.route}/formacion`
                    : "/company/formacion"
                }
                className={
                  location === "formación"
                    ? "Sidebar-Sub-Cont-But-active"
                    : "Sidebar-Sub-Cont-But"
                }
                style={
                  location === "formación"
                    ? {
                      color: university.color.quaternary
                        ? university.color.quaternary
                        : university.color.secondary,
                    }
                    : {
                      color: university.color.quaternary
                        ? university.color.quaternary
                        : "#fff",
                    }
                }
              >
                <ComputerOutlinedIcon
                  style={
                    location === "formación"
                      ? {
                        color: university.color.quaternary
                          ? university.color.quaternary
                          : university.color.secondary,
                        marginRight: "15px",
                      }
                      : {
                        color: university.color.quaternary
                          ? university.color.quaternary
                          : "#fff",
                        marginRight: "15px",
                      }
                  }
                />
                {lang === "es" ? "Formación" : "Training"}
              </Link>
            )}
            {user && user.roles !== "company" ? (
              <Link
                to={`/${university.route}/bootcamp/administracion`}
                className={
                  location === "bootcamp administracion"
                    ? "Sidebar-Sub-Cont-But-active"
                    : "Sidebar-Sub-Cont-But"
                }
                style={{ textAlign: "left" }}
              >
                <TrendingUpIcon
                  className={"Sidebar-Sub-Cont-Img"}
                  style={
                    location === "bootcamp administracion"
                      ? {
                        color: university.color.quaternary
                          ? university.color.quaternary
                          : university.color.secondary,
                        marginRight: "15px",
                      }
                      : {
                        color: university.color.quaternary
                          ? university.color.quaternary
                          : "#fff",
                        marginRight: "15px",
                      }
                  }
                />
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "column",
                  }}
                >
                  <span
                    style={
                      location === "bootcamp administracion"
                        ? {
                          color: university.color.quaternary
                            ? university.color.quaternary
                            : university.color.secondary,
                        }
                        : {
                          color: university.color.quaternary
                            ? university.color.quaternary
                            : "#fff",
                        }
                    }
                  >
                    Bootcamp
                  </span>
                </div>
              </Link>
            ) : null}
            {user &&
              user.university?.admin &&
              user?.university?.name === university?.name ? (
              <Link
                to={`/${university.route}/admin`}
                className={
                  location === "universitie admin"
                    ? "Sidebar-Sub-Cont-But-active"
                    : "Sidebar-Sub-Cont-But"
                }
                style={{ textAlign: "left" }}
              >
                <BarChartIcon
                  className={"Sidebar-Sub-Cont-Img"}
                  style={
                    location === "universitie admin"
                      ? {
                        color: university.color.quaternary
                          ? university.color.quaternary
                          : university.color.secondary,
                        marginRight: "15px",
                      }
                      : {
                        color: university.color.quaternary
                          ? university.color.quaternary
                          : "#fff",
                        marginRight: "15px",
                      }
                  }
                />
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "column",
                  }}
                >
                  <span
                    style={
                      location === "universitie admin"
                        ? {
                          color: university.color.quaternary
                            ? university.color.quaternary
                            : university.color.secondary,
                        }
                        : {
                          color: university.color.quaternary
                            ? university.color.quaternary
                            : "#fff",
                        }
                    }
                  >
                    Dashboard
                  </span>
                </div>
              </Link>
            ) : null}
          </div>

          <div className={"Sidebar-Sub-Cont-2"}>
            <label
              id="pusblish"
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                width: "100%",
              }}
            >
              {user.roles === "coallier" ? <SidebarCv /> : <SidebarOp />}
            </label>
            <Link
              to={"#"}
              onClick={() => handleLogout()}
              className={"Sidebar-Sub-Cont-But-2"}
              style={{
                color: university.color.quaternary             
              }}
            >
              <LogoutOutlinedIcon 
              style={{
                color: university.color.quaternary,
                marginRight: "15px",
              }}
              />
              {/* <img
                className={"Sidebar-Sub-Cont-Img"}
                src={logout}
                alt={"Home-Icon"}
                style={{
                  color: university.color.quaternary             
                }}
              /> */}
              {lang === "es" ? "Cerrar sesión" : "Log out"}
            </Link>
          </div>
        </div>
      )}
    </>
  );
}
