import React, {useContext, useEffect} from "react";
import { useOpportunitiesData } from "./utils/useOportunitiesData";
import {
  ComponentWrapper,
  BodyWebPapa,
  SearchIcon,
} from "../../../styles/GlobalStyles";
import {
  OpportunitiesMainContainer,
  OpportunitiesContent,
  OpportunitiesBody,
  OpportunitiesSearchContainer,
  OpportunitiesInputSearch,
  OpportunitiesCardsContainer,
  OpportunitiesSmallCardsContainer,
  OpportunitiesBigCardContainer,
} from "./OportunitiesStyles";
import { DefaultButton } from "../../../components/buttons/ButtonStyles";
import Sidebar from "../../../components/sidebar/Sidebar";
import Navbar from "../../../components/navbar/Navbar";
import TourProfessional from "../../../components/tour/TourProfessional";
import { CustomizableTitle } from "../../../styles/TitleStyles";
import { HomeContentTop } from "../home/HomeStyles";
import { SchneiderContext } from "../../../context/SchneiderContext";
import Spinner from "react-bootstrap/Spinner";
import SmallCardLinkedWrapper from "./partials/renderSmallCardLinked";
import RenderPaginate from "./partials/renderPaginate";
import BigCardLinked from "../../../components/cards/oportunitycard/linkedcards/bigcardlinked/BigCardLinked";
import FiltersOpportunities from "./filters/FiltersOpportunities";
import { useDispatch, useSelector } from "react-redux";
import {ReduxStateInterface} from "../../../types/reduxInterface";
import { tourStatus } from "../../../../actions";
const Opportunities = () => {
  const {
    searching,
    lang,
    setfiltro,
    handleKeyPress,
    handleOnClickSearch,
    projects,
    width,
    handleClickOportunity,
    selectedOportunity,
    handlePageClick,
    page,
    loading,
    setaplicable,
    aplicable,
    setRefresh,
    countrySelected,
  } = useOpportunitiesData();
  const { schneider } = useContext(SchneiderContext);
  const { tourViewed } = useSelector((state: ReduxStateInterface) => state);
  const dispatch = useDispatch();

  useEffect(() => {
    if (tourViewed === true) {
      return;
    }
    setTimeout(() => {
      dispatch(tourStatus({ run: true }));
    }, 1500);
    //eslint-disable-next-line
  }, []);

  return (
    <ComponentWrapper>
      <Sidebar location={"oportunidades"} />
      <BodyWebPapa>
        <Navbar />
        <div>
          <TourProfessional />
        </div>
        <OpportunitiesMainContainer id={"projects"}>
          <OpportunitiesContent>
            <HomeContentTop>
              <CustomizableTitle
                color={schneider.color.primary}
                size={"20px"}
                weight={"400"}
                align={"center"}
              >
                {lang === "es"
                  ? "Es momento de buscar"
                  : "It´s time to look for"}
              </CustomizableTitle>
            </HomeContentTop>
            <HomeContentTop>
              <CustomizableTitle
                color={schneider.color.primary}
                size={"25px"}
                weight={"700"}
                align={"left"}
              >
                {lang === "es"
                  ? "nuevas oportunidades laborales"
                  : "new job opportunities"}
              </CustomizableTitle>
            </HomeContentTop>

            <OpportunitiesBody>
              <OpportunitiesSearchContainer>
                <SearchIcon color={schneider.color.primary} />
                <OpportunitiesInputSearch
                  placeholder={
                    lang === "es"
                      ? "Buscar oportunidades"
                      : "Search opportunities"
                  }
                  onChange={(e) => setfiltro(e.target.value)}
                  onKeyPress={handleKeyPress}
                />
                <DefaultButton
                  background={schneider.color.primary}
                  margin={"0"}
                  onClick={handleOnClickSearch}
                >
                  {searching ? (
                    <Spinner animation="border" size="sm" />
                  ) : (
                    <span>{lang === "es" ? "Buscar" : "Search"}</span>
                  )}
                </DefaultButton>
              </OpportunitiesSearchContainer>
              <FiltersOpportunities />
            </OpportunitiesBody>
            <OpportunitiesCardsContainer>
              <OpportunitiesSmallCardsContainer>
                {projects?.map((project, index) => (
                  <SmallCardLinkedWrapper
                    key={index}
                    e={project}
                    i={index}
                    width={width}
                    handleClickOportunity={handleClickOportunity}
                    selectedOportunity={selectedOportunity}
                  />
                ))}
                {!countrySelected && (
                  <RenderPaginate
                    projects={projects}
                    handlePageClick={handlePageClick}
                    page={page}
                    loading={loading}
                  />
                )}
              </OpportunitiesSmallCardsContainer>
              <OpportunitiesBigCardContainer>
                {selectedOportunity && (
                  <BigCardLinked
                    setaplicable={setaplicable}
                    place={
                      selectedOportunity?.municipio
                        ? selectedOportunity.municipio
                        : ""
                    }
                    idProyect={
                      selectedOportunity?._id ? selectedOportunity._id : ""
                    }
                    applied={aplicable}
                    setRefresh={setRefresh}
                    habilities={
                      selectedOportunity?.habilidadesTecnicas ||
                      selectedOportunity?.habilidadesRequeridas
                        ? selectedOportunity.habilidadesTecnicas
                        : ""
                    }
                    image={
                      selectedOportunity?.usuario.avatar
                        ? selectedOportunity.usuario.avatar
                        : selectedOportunity.imageUrl
                        ? selectedOportunity.imageUrl
                        : ""
                    }
                    title={
                      selectedOportunity?.NombreOportunidad
                        ? selectedOportunity.NombreOportunidad
                        : selectedOportunity?.nombre === "Unnamed Opportunity"
                        ? "Oportunidad sin nombre"
                        : selectedOportunity.nombre
                    }
                    company={
                      selectedOportunity?.NombreEmpresa
                        ? selectedOportunity.NombreEmpresa
                        : ""
                    }
                    description={
                      selectedOportunity?.DescribeProyecto
                        ? selectedOportunity.DescribeProyecto
                        : ""
                    }
                    companyDescription={
                      selectedOportunity?.descripcionEmpresa
                        ? selectedOportunity.descripcionEmpresa
                        : ""
                    }
                    responsabilities={
                      selectedOportunity?.responsabilidadYfunciones
                        ? selectedOportunity.responsabilidadYfunciones
                        : ""
                    }
                    date={
                      selectedOportunity?.fecha_inicio
                        ? selectedOportunity.fecha_inicio
                        : ""
                    }
                    language={
                      selectedOportunity?.Lenguaje
                        ? selectedOportunity.Lenguaje
                        : ""
                    }
                    contract={
                      selectedOportunity?.modalidadDeContratacion
                        ? selectedOportunity.modalidadDeContratacion
                        : ""
                    }
                    salary={
                      selectedOportunity?.presupuesto
                        ? selectedOportunity.presupuesto
                        : ""
                    }
                    experience={
                      selectedOportunity?.experienciaAnos
                        ? selectedOportunity.experienciaAnos
                        : ""
                    }
                    aplicantes={
                      selectedOportunity?.aplicantes
                        ? selectedOportunity.aplicantes.length
                        : ""
                    }
                    listadoBeneficios={
                      selectedOportunity?.listadoBeneficios
                        ? selectedOportunity.listadoBeneficios
                        : ""
                    }
                    descripcionComisiones={
                      selectedOportunity?.descripcionComision
                        ? selectedOportunity.descripcionComision
                        : ""
                    }
                    descripcionBeneficios={
                      selectedOportunity?.descripcionBeneficios
                        ? selectedOportunity.descripcionBeneficios
                        : ""
                    }
                    softSkills={
                      selectedOportunity?.habilidadesBlandas
                        ? selectedOportunity.habilidadesBlandas
                        : ""
                    }
                    fit={selectedOportunity?.fit ? selectedOportunity.fit : ""}
                  />
                )}
              </OpportunitiesBigCardContainer>
            </OpportunitiesCardsContainer>
          </OpportunitiesContent>
        </OpportunitiesMainContainer>
      </BodyWebPapa>
    </ComponentWrapper>
  );
};

export default Opportunities;
