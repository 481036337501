const bootcampModules = [
  {
    title: 'Comercial y Ventas',
    duration: '6 Módulos',
    subModules: [
      {
        title: 'Curso en Ventas',
        description: 'En este curso de ventas aprenderás a llevar a cabo una serie de técnicas y  estrategias de ventas efectivas, aplicables a cada situación personal, con el fin de desarrollar una serie de competencias comerciales, que permitan asegurar las metas de cualquier negocio. Es fácil de aprender y solo se requiere tener conocimientos elementales.',
        url: 'https://edutin.com/curso-de-ventas-4262',
      },
      {
        title: 'Curso de CRM',
        description: 'En este curso de CRM aprenderás acerca de las funcionalidades que ofrece el modelo de gestión CRM, por medio de sus ventajas y herramientas, para llevar a cabo modelos de ventas efectivas, dentro de un negocio o empresa. Es fácil de aprender y solo se requiere tener conocimientos elementales. ',
        url: 'https://edutin.com/curso-de-crm-2741'
      },
      {
        title: 'Curso de Comercio electrónico',
        description: 'En este curso de comercio electrónico aprenderás aplicar las estrategias asociadas al emprendimiento de su negocio digital , mediante el uso del marketing, las redes sociales, shopify y demás herramientas necesarias. Es fácil de aprender y solo se requiere tener conocimientos elementales. ',
        url: 'https://edutin.com/curso-de-desarrollo-profesional-4418'
      },
      {
        title: 'Curso de Comunicación y Habilidades Verbales',
        description: 'En este curso de comunicación y habilidades sociales aprenderás a utilizar los procesos comunicativos, tanto verbales como no verbales, con el fin de entablar relaciones saludables y asertivas con los demás. Es fácil de aprender y solo se requiere tener conocimientos elementales. ',
        url: 'https://edutin.com/curso-de-comunicacion-y-habilidades-sociales-4316'
      },
      {
        title: 'Curso de Lenguajes no verbales',
        description: 'En este curso de lenguaje no verbal aprenderás a identificar el significado de los gestos, los signos, los mensajes sin palabras, los movimientos del cuerpo, la manera como nos desplazamos en el espacio y las aptitudes de las personas. Todo esto, con el propósito de aprender a predecir y leer el comportamiento de las personas.',
        url: 'https://edutin.com/curso-de-lenguaje-no-verbal-4312'
      },
      {
        title: 'Curso de Liderazgo',
        description: 'En este curso de liderazgo aprenderás una serie de técnicas y estrategias para influenciar en otros sujetos, en miras de lograr un objetivo en común. Es fácil de aprender y solo se requiere tener conocimientos elementales.',
        url: 'https://edutin.com/curso-de-liderazgo-4426'
      }
    ]
  },
  {
    title: 'Recursos Humanos',
    duration: '7 Módulos',
    subModules: [
      {
        title: 'Curso de Recursos Humanos',
        description: 'En este curso de recursos humanos aprenderás aplicar las  herramientas y los conocimientos necesarios para atraer y retener el talento humano, teniendo en cuenta los métodos de selección, reclutamiento, capacitación y liderazgo. Es fácil de aprender y solo se requiere tener conocimientos elementales.',
        url: 'https://edutin.com/curso-de-recursos-humanos-4375',
      },
      {
        title: 'Curso de Talento Humano',
        description: 'En este curso de talento humano aprenderás a gestionar estratégicamente el personal de las organizaciones, desde la realización de un diagnóstico organizacional hasta la aplicación de estrategias para atraer a los mejores talentos y desarrollar su máximo potencial.',
        url: 'https://edutin.com/curso-de-talento-humano',
      },
      {
        title: 'Curso de Desarrollo Profesional',
        description: 'En este curso de desarrollo profesional aprenderás la manera como nos podemos preparar para el mundo laboral, por medio del conocimiento de las competencias transversales, todo esto con la finalidad de desarrollar otras habilidades distintas a las del campo profesional. Es fácil de aprender y solo se requiere tener conocimientos elementales.',
        url: 'https://edutin.com/curso-de-desarrollo-profesional-4418'
      },
      {
        title: 'Curso de Auxiliar de Recursos Humanos',
        description: 'En este curso de auxiliar de recursos humanos aprenderás a realizar reclutamiento, selección, inducción y capacitación del personal de una organización. Así como, la elaboración y administración de nóminas, contratos laborales y la documentación de los procesos, haciendo uso de herramientas ofimáticas.',
        url: 'https://edutin.com/curso-de-auxiliar-de-recursos-humanos'
      },
      {
        title: 'Curso Coaching Empresarial',
        description: 'Asimismo este Diplomado está orientado a que las personas puedan adquirir nuevos conocimientos que potencialicen su desarrollo ejecutivo, realización profesional y liderazgo empresarial; trabajando en las competencias y habilidades que debe desarrollar e incorporar el líder-Coach en su cotidianidad como por ejemplo; trabajo en equipo, resolución de conflictos, inteligencia emocional; entre otros.',
        url: 'https://www.politecnicosuperior.edu.co/diplomados-virtuales-gratis/escuela-de-talento-humano/coaching-empresarial.html'
      },
      {
        title: 'Curso de Programación Neurolingüística',
        description: 'En este curso de programación neurolingüística o PNL aprenderás las aplicaciones, estrategias y técnicas, que ayudan a modificar aquellos patrones de pensamiento y comportamientos que impiden el alcance de metas. Es fácil de aprender y solo se requiere tener conocimientos elementales.',
        url: 'https://edutin.com/curso-de-programacion-neurolinguistica-pnl-4413'
      },
      {
        title: 'Curso de Seguridad y Salud en el Trabajo',
        description: 'En este curso de seguridad y salud en el trabajo aprenderás a diseñar, coordinar e implementar buenas prácticas de seguridad que permitan prevenir lesiones y enfermedades causadas por las condiciones de trabajo; promoviendo el bienestar físico, mental y social de los colaboradores de una empresa. Todo esto lo aprenderás de acuerdo a las normas vigentes ISO 45001:2018.',
        url: 'https://edutin.com/curso-de-seguridad-y-salud-en-el-trabajo'
      }
    ]
  },
  {
    title: 'Servicio al Cliente',
    duration: '5 Módulos',
    subModules: [
      {
        title: 'Curso en Atención al cliente',
        description: 'En este curso de atención al cliente aprenderás a los fundamentos teóricos y pragmáticos de la atención y el servicio efectivo hacia los clientes, con el fin de brindar soluciones frente a sus consultas, reclamos, pedidos, inquietudes o quejas. Es fácil de aprender y solo se requiere tener conocimientos elementales. ',
        url: 'https://edutin.com/curso-de-atencion-al-cliente-4261'
      },
      {
        title: 'Curso de Comunicación y Habilidades Verbales',
        description: 'En este curso de comunicación y habilidades sociales aprenderás a utilizar los procesos comunicativos, tanto verbales como no verbales, con el fin de entablar relaciones saludables y asertivas con los demás. Es fácil de aprender y solo se requiere tener conocimientos elementales. ',
        url: 'https://edutin.com/curso-de-comunicacion-y-habilidades-sociales-4316'
      },
      {
        title: 'Curso de Lenguaje no verbales',
        description: 'En este curso de lenguaje no verbal aprenderás a identificar el significado de los gestos, los signos, los mensajes sin palabras, los movimientos del cuerpo, la manera como nos desplazamos en el espacio y las aptitudes de las personas. Todo esto, con el propósito de aprender a predecir y leer el comportamiento de las personas.',
        url: 'https://edutin.com/curso-de-lenguaje-no-verbal-4312'
      },
      {
        title: 'Curso Gestión Integral del Servicio',
        description: 'En la actualidad, el servicio al cliente marca la diferencia entre una organización y otra que se desempeñe en la misma rama y que cuenta con los mismos o similares productos. Para las empresas constituye un compromiso social y un factor estratégico diferenciador que requiere evidentemente de un liderazgo sobresaliente, que convoque y oriente a todas las personas de la organización hacia el cliente, hacia el servicio y bajo una misma cultura de servicio con calidad, y así trabajar con los mejores colaboradores que la lleven al éxito.',
        url: 'https://www.politecnicosuperior.edu.co/diplomados-virtuales-gratis/escuela-de-administracion-2/gerencia-del-servicio.html'
      },
      {
        title: 'Curso de Servicio y Comunicación con el Cliente',
        description: 'El Diplomado en Servicio y Comunicación con el cliente tiene como objetivo desarrollar las destrezas que requieren todos los colaboradores de una organización que hacen parte del valor del servicio y atención al cliente, emprendedores, independientes, entre otros; para identificar su cliente, conservarlo y fidelizarlo con la organización para el sostenimiento, crecimiento y rentabilidad de la misma.',
        url: 'https://www.politecnicosuperior.edu.co/diplomados-virtuales-gratis/escuela-de-administracion-2/servicio-al-cliente.html'
      }
    ]
  },
  {
    title: 'Logística y Operaciones',
    duration: '6 Módulos',
    subModules: [
      {
        title: 'Curso fundamento de Logística',
        description: 'Te has preguntado cómo llega a nuestras manos desde las prendas de vestir que usamos, hasta los alimentos que consumimos. Estas experiencias han pasado por una serie de eslabones, procesos,operaciones y flujos que entendemos por: logística.',
        url: 'https://www.edx.org/es/course/fundamentos-de-la-logistica?index=spanish_product&queryID=7f83c921928b1ca83f70d17be9f311ba&position=1'
      },
      {
        title: 'Curso Logística del Futuro',
        description: 'La digitalización, las tendencias e implicaciones tecnológicas y la aplicabilidad de big data, omnicanalidad y IoT, son conceptos fundamentales que le ayudarán a buscar soluciones estratégicas a los retos y desafíos logísticos del futuro latinoamericano.',
        url: 'https://www.edx.org/es/course/logistica-del-futuro?index=spanish_product&queryID=f73e53c6ecfab76ab68018d83ca127c9&position=2'
      },
      {
        title: 'Curso de Logística',
        description: 'En este curso de logística de distribución aprenderás a llevar la trazabilidad de la gestión de la cadena de suministro de tus productos, desde su preparación hasta su salida. Asimismo, aprenderás los conceptos relacionados con la gestión de pedidos, gestión de inventarios y el embalaje de productos.',
        url: 'https://edutin.com/curso-de-logistica'
      },
      {
        title: 'Curso de Seguridad y Salud en el Trabajo',
        description: 'En este curso de seguridad y salud en el trabajo aprenderás a diseñar, coordinar e implementar buenas prácticas de seguridad que permitan prevenir lesiones y enfermedades causadas por las condiciones de trabajo; promoviendo el bienestar físico, mental y social de los colaboradores de una empresa. Todo esto lo aprenderás de acuerdo a las normas vigentes ISO 45001:2018.',
        url: 'https://edutin.com/curso-de-seguridad-y-salud-en-el-trabajo'
      },
      {
        title: 'Curso de Operaciones',
        description: 'En este curso de operaciones aprenderás aplicar la administración, planificación, distribución, organización y dirección de recursos, con el fin de promover la producción efectiva de una organización. Es fácil de aprender y solo se requiere tener conocimientos elementales. ',
        url: 'https://edutin.com/curso-de-operaciones-4273'
      },
      {
        title: 'Curso Logística Resiliente',
        description: 'Aprende a transformar la visión logística para hacerla resiliente, a través de la colaboración y la gestión del riesgo para continuar los procesos pese a las disrupciones.',
        url: 'https://www.edx.org/es/course/logistica-resiliente?index=spanish_product&queryID=02c23bfe42a85c4d43dff4752aaa5ae8&position=4'
      }
    ]
  },
  {
    title: 'Marketing',
    duration: '7 Módulos',
    subModules: [
      {
        title: 'Curso de Marketing Digital',
        description: 'En este curso de marketing digital aprenderás aplicar una serie de estrategias orientadas a la comercialización de una marca, por medio del uso de las redes sociales y el Internet. Es fácil de aprender y solo se requiere tener conocimientos elementales.',
        url: 'https://edutin.com/curso-de-marketing-digital-4297'
      },
      {
        title: 'Curso de CRM',
        description: 'En este curso de CRM aprenderás acerca de las funcionalidades que ofrece el modelo de gestión CRM, por medio de sus ventajas y herramientas, para llevar a cabo modelos de ventas efectivas, dentro de un negocio o empresa. Es fácil de aprender y solo se requiere tener conocimientos elementales. ',
        url: 'https://edutin.com/curso-de-crm-2741'
      },
      {
        title: 'Curso de Comercio electrónico',
        description: 'En este curso de comercio electrónico aprenderás aplicar las estrategias asociadas al emprendimiento de su negocio digital , mediante el uso del marketing, las redes sociales, shopify y demás herramientas necesarias. Es fácil de aprender y solo se requiere tener conocimientos elementales. ',
        url: 'https://edutin.com/curso-de-comercio-electronico-4383'
      },
      {
        title: 'Curso de Programación Neurolingüística',
        description: 'En este curso de programación neurolingüística o PNL aprenderás las aplicaciones, estrategias y técnicas, que ayudan a modificar aquellos patrones de pensamiento y comportamientos que impiden el alcance de metas. Es fácil de aprender y solo se requiere tener conocimientos elementales.',
        url: 'https://edutin.com/curso-de-programacion-neurolinguistica-pnl-4413'
      },
      {
        title: 'Curso de Neuromarketing',
        description: 'En este curso de neuromarketing aprenderás acerca de cómo funciona la mente y el comportamiento consumidor, al momento de exponerse en eventos asociados a la venta y publicidad de una marca, producto o servicio. Es fácil de aprender y solo se requiere tener conocimientos elementales.',
        url: 'https://edutin.com/curso-de-neuromarketing-4211'
      },
      {
        title: 'Curso de SEO',
        description: 'En este curso de SEO aprenderás el uso de las técnicas de posicionamiento web con page y off page, con las cuales podrás posicionar tu página en los primeros resultados de los buscadores de Google, Yahoo y Bing, gracias a búsquedas orgánicas relacionadas con el contenido. Es fácil de aprender y solo se requiere tener conocimientos elementales. ',
        url: 'https://edutin.com/curso-de-seo-4063'
      },
      {
        title: 'Fundamentos del Marketing Digital',
        description: 'Conviértete en un experto de marketing digital a un nivel básico con nuestro curso sin coste acreditado por la agencia Interactive Advertising Bureau (IAB). Los 26 módulos que componen el curso han sido creados por instructores de Google e incluyen numerosos ejercicios prácticos y ejemplos reales que te ayudarán a conseguir excelentes resultados.',
        url: 'https://learndigital.withgoogle.com/activate/course/digital-marketing'
      }
    ]
  },
  {
    title: 'Finanzas',
    duration: '6 Módulos',
    subModules: [
      {
        title: 'Curso de introducción a las Finanzas',
        description: 'Aprende de forma rápida y sencilla los principios básicos de valor del dinero en el tiempo y cómo usarlos para la toma de decisiones financieras y evaluación de proyectos de inversión.',
        url: 'https://www.edx.org/es/course/introduccion-a-las-finanzas-principios-de-valoraci'
      },
      {
        title: 'Curso de Finanzas',
        description: 'En este curso de finanzas aprenderás acerca de las bases del campo financiero, sus elementos y fundamentos claves, para la adecuada toma de decisiones de la gestión y distribución de los recursos económicos de una empresa. Es fácil de aprender y solo se requiere tener conocimientos elementales.',
        url: 'https://edutin.com/curso-de-finanzas-3539',
      },
      {
        title: 'Curso de Análisis Financiero Organizacional',
        description: 'El diplomado virtual en Análisis Financiero Organizacional busca brindar los conocimientos, habilidades y elementos técnicos sobre el entendimiento del entorno económico que rodea a la empresa, la estructura de los estados financieros y el entendimiento e interpretación de los indicadores del negocio, lo cual conlleve a la toma de las mejores decisiones para la maximización de las utilidades y la permanencia del negocio en el tiempo.',
        url: 'https://www.politecnicosuperior.edu.co/diplomados-virtuales-gratis/escuela-de-finanzas-y-contabilidad/an%C3%A1lisis-financiero-organizacional.html'
      },
      {
        title: 'Curso de Educación Financiera',
        description: 'En este curso de educación financiera aprenderás aplicar la apropiada gestión financiera, en el ámbito individual y empresarial, de tal forma que las personas (naturales y jurídicas), tomen decisiones asertivas frente al manejo de sus recursos económicos.  Es fácil de aprender y solo se requiere tener conocimientos elementales. ',
        url: 'https://edutin.com/curso-de-educacion-financiera-4325'
      },
      {
        title: 'Curso de Contabilidad',
        description: 'En este curso de contabilidad, aprenderás las bases de esta disciplina, sus elementos fundamentales para la adecuada administración y el control del dinero para regular y supervisar las finanzas de una organización. ',
        url: 'https://edutin.com/curso-de-contabilidad'
      },
      {
        title: 'Curso de Finanzas para no Financieros',
        description: 'El diplomado en Finanzas para no Financieros, busca brindar a los estudiantes el entendimiento de los conceptos, elementos y herramientas necesarios para comprender las finanzas y su aplicación, con términos sencillos que no requieren de un conocimiento previo financiero.',
        url: 'https://www.politecnicosuperior.edu.co/diplomados-virtuales-gratis/escuela-de-finanzas-y-contabilidad/finanzas-para-no-financieros.html'
      }
    ]
  },
  {
    title: 'Gestión de Proyectos',
    duration: '7 Módulos',
    subModules: [
      {
        title: 'Curso de Evaluación de Proyectos',
        description: 'Este curso analiza los principales conceptos vigentes de finanzas para tasar proyectos que involucran decisiones irreversible de inversión, incorporando elementos estratégicos y de análisis de incertidumbre.',
        url: 'https://www.coursera.org/learn/evaluacion-de-proyectos-de-inversion?courseSlug=evaluacion-de-proyectos-de-inversion&isNewUser=true&showOnboardingModal=check'
      },
      {
        title: 'Curso de Dirección y Gestión de Proyectos',
        description: 'En este curso aprenderás distintas herramientas para planificar y dirigir los procesos de un proyecto, entendiendo por proyecto una serie de actividades diseñadas para lograr un objetivo con un alcance, recursos, inicio y final establecidos.',
        url: 'https://www.edx.org/es/course/la-direccion-y-gestion-de-proyectos'
      },
      {
        title: 'Curso de Gestión de Proyectos',
        description: 'En este curso de gestión de proyectos aprenderás cómo administrar, planificar, coordinar, organizar, controlar y hacer seguimiento a todo tipo de proyectos de manera eficiente y efectiva. Es fácil de aprender y solo se requiere tener conocimientos elementales. ',
        url: 'https://edutin.com/curso-de-gestion-de-proyectos-4414'
      },
      {
        title: 'Curso de Planeación Estratégica',
        description: 'En este curso de planeación estratégica aprenderás a tomar decisiones administrativas y operativas que beneficien a toda la organización. De esta forma, podrás mejorar la competitividad del negocio, empresa o emprendimiento, y garantizar su innovación, estabilidad y continuidad en el tiempo.',
        url: 'https://edutin.com/curso-de-planeacion-estrategica'
      },
      {
        title: 'Curso de Resolución de Conflictos',
        description: 'En este curso de resolución de conflictos aprenderás sobre la conflictología, la regulación de conflictos y transformación de conflictos, ​así mismo, ​te proporcionaremos habilidades prácticas para comprender e intervenir en la resolución pacífica y no violenta con base a la resolución de conflictos organizacionales.',
        url: 'https://edutin.com/curso-de-resolucion-de-conflictos'
      },
      {
        title: 'Curso de Liderazgo',
        description: 'En este curso de liderazgo aprenderás una serie de técnicas y estrategias para influenciar en otros sujetos, en miras de lograr un objetivo en común. Es fácil de aprender y solo se requiere tener conocimientos elementales.',
        url: 'https://edutin.com/curso-de-liderazgo-4426'
      },
      {
        title: 'Curso de Coaching',
        description: 'En este curso de coaching aprenderás cómo fomentar el desarrollo personal, académico, laboral y económico de las personas, a través del aprendizaje de una serie de técnicas utilizadas para el establecimiento y logro de metas. Es fácil de aprender y solo se requiere tener conocimientos elementales. ',
        url: 'https://edutin.com/curso-de-coaching-4256'
      }
    ]
  },
]

export default bootcampModules