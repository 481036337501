/* eslint-disable */
import React,{useState, useRef, useEffect} from "react";
import './newRecovery.css';
import {Link, useHistory} from 'react-router-dom';
import { useDispatch } from "react-redux";
import { changeLang, sendMailRecovery } from "../../../actions";
import Spinner from 'react-bootstrap/Spinner';
import Wave from "../../../assets/imagenes/Vector385.svg";
import Wave2 from "../../../assets/imagenes/Vector 384.svg";
import Creativity from "../../../assets/imagenes/CREATIVIDAD.svg";
import ImgCodigo from "../../../assets/imagenes/CODIGO.svg";
import Edit from "../../../assets/imagenes/edit.svg";
import Logo from "../../../assets/imagenes/nuevo_logo.svg";
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

export default function NewRecovery() {
  const user = useRef({
    email: "",
  });
  const dispatch = useDispatch();
  const history = useHistory();
  const [locallang, setLocallang] = useState(localStorage.getItem("langlocal"));
  const [cargando, setcargando] = useState(false)
  const [error, setError] = useState(false);
  const cambiarlanges = (camlang) => {
    dispatch(changeLang(camlang))
    localStorage.setItem("langlocal", camlang);
    setLocallang(localStorage.getItem("langlocal"));
  };
  const [width, setWidth] = useState(window.innerWidth);
  const [height, setHeight] = useState(window.innerHeight);
  
  
  const updateWidthAndHeight = () => {
    setWidth(window.innerWidth);
    setHeight(window.innerHeight);
    
  }
  useEffect(() => {
    window.addEventListener("resize", updateWidthAndHeight);
    return () => window.removeEventListener("resize", updateWidthAndHeight);
  }, [width, height]);
  
  const validate = (form) => {
    let errors = {},
      email = form.current;
      let regexEmail = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;
    if (!email.email) {
      errors.email = locallang === 'en' ? "Email is required" : "El email es requerido";
      return errors;
    }
    if (email.email && !regexEmail.test(email.email)) {
      errors.email = locallang === 'en' ?  "Invalid email" : "Email invalido";
      return errors;
    }
    return errors;
  }
  const handleChange = (e) => {
    user.current[e.target.name] = e.target.value;
    setError(validate(user));
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    setError(validate(user));
    if (Object.entries(error).length === 0) {
      try {
        setcargando(true);
        let response = await dispatch(sendMailRecovery(user.current, locallang));
        if(response.message !== undefined){
          history.push(`/cambiar-contrasena?email=${user.current.email}`);
        }
        else{
          locallang === "es" ? toast.error("Error, Email no encontrado",{
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          }) : toast.error("Error, Email not found.",{
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          setcargando(false);
        }
      } catch (error) {
        console.log(error);
      }
    } 
  };

  return (
    <div style={{backgroundColor:'#F5F5F5', display: 'flex', justifyContent: 'space-evenly', alignItems: 'center', width: '100%', height: '100vh', flexDirection: 'column'}}>
      {/* <ToastContainer /> */}
      <div style={{width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', height: '80%'}}>
      {
        width > 768 ? <div style={{
          width: '25%',
          height: '80%', display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-start'
        }}>
          <img src={Creativity} alt="logo" style={{width: '235px', height: '235px'}}/>
        </div> : null
      }
      <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', width: width > 768 ? '33%' : '100%'}}>
      <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '442px', width: '365px', borderRadius: '8px', zIndex: '2'}}>
        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', height: '100%', flexDirection: 'column'}}>
        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%'}}>
            <img src={Logo} alt="logo" style={{width: '234px', height: '84px', marginBottom: '5%'}}/>
          </div>
          <div style={{display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-start', width: '100%', paddingBottom: '1em'}}>
        <div
          style={{
            display: "flex",
            alignItems: "flex-end",
            justifyContent: 'flex-end',
            color: "#C5C5C5",
            width: '100%',
            paddingRight: '30px'
          }}
        >
                         <h3
                    style={
                      localStorage.getItem("langlocal") === "es"
                        ? {
                          margin: "0.3rem",
                          fontSize: "18px",
                          fontFamily: "Poppins",
                          cursor: "pointer",
                          color: "#202256",
                          fontWeight: "700",
                          userSelect: "none",
                        }
                        : { margin: "0.3rem", cursor: "pointer", fontWeight: '300', fontSize: '18px' }
                    }
                    onClick={() => cambiarlanges("es")}
                  >
                    SP
                  </h3>
                  <h3
                    style={
                      localStorage.getItem("langlocal") === "en"
                        ? {
                          margin: "0.3rem",
                          fontSize: "18px",
                          fontFamily: "Poppins",
                          cursor: "pointer",
                          color: "#202256",
                          fontWeight: "700",
                          userSelect: "none",
                        }
                        : { margin: "0.3rem", cursor: "pointer", fontWeight: '300', fontSize: '18px' }
                    }
                    onClick={() => cambiarlanges("en")}
                  >
                    EN
                  </h3>
        </div>
        </div>
        <div style={{width: '100%'}}>
          <h1 style={{fontFamily: 'Poppins', fontSize: '24px', fontWeight: "700", color: '#393939'}}>{
         locallang === 'es' ?  "Recuperar contraseña" : "Recover password"
          }</h1>
          </div>
        <div style={{display: 'flex', alignItems: 'flex-start', justifyContent: 'center', flexDirection: 'column', width: '100%'}}>
          <div>
            <p style={{color: '#5D5D5D', fontWeight: '400', fontSize: '14px', paddingTop: "1em"}}>
             {
                locallang === 'es' ? 'Correo electrónico' : 'Email'
             }
            </p>
          </div>
          <div className="input-login-new">
            <input type='text' name="email" placeholder={
                locallang === 'es' ? 'nombre@correo.com' : 'name@email.com'
            } onChange={handleChange} />
          </div>
          <label style={{color: '#E7BF00', marginTop: '4%'}}>{ 
            error.email
            }</label>
        </div>
        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', marginTop: '4%'}}>
          {/* <div>
            <button className='login-button-new' onClick={(e)=>{
              handleSubmit(e)
            }}>
              {
                locallang === 'es' ? 'Recuperar contraseña' : 'Recover password'
              }
            </button>
          </div> */}
          {
              user.current.email  ? (
              <div>
            <button className='login-button-new' onClick={(e) => handleSubmit(e)}>
            {cargando?(
                <Spinner animation="border" size="sm" />
                ):
                <span>
                  {
                    locallang === 'es' ? 'Recuperar contraseña' : 'Recover password'
                  }
                  </span>}
            </button>
            </div>
            ) : (
              <div>
            <button className='login-button-new' disabled>
              {
                locallang === 'es' ? 'Recuperar contraseña' : 'Recover password'
              }
            </button>
              </div>
            )
            }
        </div>
        <hr style={{
          width: '94%',
          height: '1px',
          backgroundColor: '#D6D6D6',
          border: 'none',
          marginTop: '4%',
         marginLeft: "-1.2em"
        }}/>
        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'row', marginTop: '4%', width: '100%'}}>
          <div>
            <p style={{
              color: '#5D5D5D',
              fontWeight: '400',
              fontSize: '14px',
              textAlign: 'center',
              marginLeft: "-2.5em"

            }}>
              {
                locallang === 'es' ? 'Si ya tienes cuenta,' : 'If you already have an account,'
              }
            </p>            
          </div>
          <div style={locallang === 'es' ? {width: '20%', marginLeft: '2%'}: {width: '10%', marginLeft: '2%'}}>
            <div>
              <Link to='/' style={{textDecoration: 'none'}}>
              <p className="register-register-new">
                {
                  locallang === 'es' ? 'Inicia sesión' : 'Login'
                }
              </p>
              </Link>
            </div>
          </div>
        </div>
        </div>
      </div>
     
      </div>
      {
        width > 768 ? <div style={{
          width: '25%',
          height: '85%', display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-end', zIndex: '1'
        }}>
          <img src={ImgCodigo} alt="logo" style={{width: '235px', height: '235px'}}/>
        </div> : null
      }
      
      </div>
      <div style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        alignItems: 'flex-end',
        width: '100%',
        height: '20%',
        backgroundColor:'#F5F5F5'
      }}>
        <div style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%',
        }}>
          <img src={Wave2} alt="wave 2"  style={{
            width: '100%',
            height: 'auto',
          }}/>
          <img src={Wave} alt="wave" style={{
            width: '100%',
            height: 'auto',
            position: 'absolute',
            bottom: '0'
          }} />
        </div></div>
    </div>
  );
}