import React,{useContext} from 'react'
import './ProfesionalesListado.css'
import { useSelector } from "react-redux";
import {Link, useParams} from "react-router-dom";
// import Eye from "../../../assets/icons/eye.svg";
import Advise from "../../../../assets/icons/advise.svg";
import CvModalButton from "../CvModalButton/CvModalButton";
import axios from "axios";
import {OcultarButton} from "../AplicantesCard/AplicantesCard";
import {UniversityContext} from "../../../context/UniversitiesContext";

export default function ProfesionalesListado({ keynum, compatibility, name, job, image, id, LinkedInCv, generalCv }) {
  const {university} = useContext(UniversityContext);
  const { lang } = useSelector(state => state)
  const ideproy = useParams().id

  const handleContact = async (e) => {
    e.preventDefault()
    await axios.post("https://findally-backend.herokuapp.com/api/applicants/contact/coallier", {
      proyecto: ideproy,
      usuarios: [id]
    }).then(res => {
      console.log(res)
      return window.location.reload()
    }).catch(err => {
      console.log(err)
    })
  }
  return (
    <div>
      <div className={'prof-list-global-cont'}>
        <div className={'prof-list-rig-cont'}>
          <div className={'prof-list-rig-sub-cont'}>
            <h2 className={'prof-list-number'}>{keynum}{lang === 'es' ? 'to' : 'th'}</h2>
            <div className={'prof-list-card-image-filtro'}>
              <img className={'prof-list-img'}
                src={image ? image : 'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png'}
                alt={'profile-pic'} />
            </div>
            <div>
              <Link to={`/${university.route}/company/profile/${id}`} className={'prof-list-name'}>{name}</Link>
              <h2 className={'prof-list-job'}>{job}</h2>
            </div>
          </div>
        </div>
        <div className={'prof-list-compat-cont'}>
          <img src={Advise} alt={'advise-icon'} />
          <h2 className={'prof-list-compat'}>
            {lang === 'es' ? `${compatibility} Compatibilidad` : `${compatibility} Compatibility`}
          </h2>
        </div>
        <div className={'prof-list-button-cont'}>
          <div className={'prof-list-ver-perfil-cont'}>
            <OcultarButton userId={id} />
          </div>
          <CvModalButton idUser={id} LinkedInCv={LinkedInCv} generalCv={generalCv}/>
          <button onClick={(e) => handleContact(e)}
            className={'prof-list-but-selec'} style={{backgroundColor: university && university.color ? university.color.secondary : '#000'}}>{lang === 'es' ? 'Seleccionar' : 'Select'}</button>
        </div>
      </div>
      <hr />
    </div>
  )
}
