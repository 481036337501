import React, { useState } from 'react'
import { Link } from 'react-router-dom'
// import SearchIcon from "@mui/icons-material/Search";
import { useSelector } from 'react-redux';
import CardSector from './cards/CardSector';
import CardHabilidad from './cards/CardHabilidad';
import Sidebar from '../../../components/sidebar/Sidebar';
import Navbar from '../../../components/navbar/Navbar'
import { BodyWebPapa, ComponentWrapper } from '../../../styles/GlobalStyles';
import { CustomizableTitle } from '../../../styles/TitleStyles';
import {
  StyledEducationSubcontainer,
  StyledSector,
  ArrowLeft,
  ArrowRight,
  StyledCardsSector,
  StyledHabilidades,
} from './EducationStyle';
import infoSectores from './utils/infoSectores';
import infoHabilidades from './utils/infoHabilidades';

const EducationScreen = () => {

  const [tab, setTab] = useState(0)
  const { lang } = useSelector((state: any) => state)

  const left = () => {
    document.getElementById('cards').scrollLeft -= 250;
  }

  const right = () => {
    document.getElementById('cards').scrollLeft += 250;
  }

  return (
    <ComponentWrapper>
      <Sidebar location={'formación'} />
      <BodyWebPapa>
        <Navbar locate={'formación'} />
        <StyledEducationSubcontainer>
          <CustomizableTitle size={'40px'}>
            {lang === 'es' ? 'Formación' : 'Education'}
          </CustomizableTitle>
          {/* <div style={{ display: "flex", flexDirection: 'column' }}>
            <div
          className={'formacion-search-responsive'}>
          <div
            style={{ marginBottom: '30px', display: 'flex', justifyContent: 'space-between' }}>
            <SearchIcon
              style={{
                fontSize: "30px",
                color: "#B3B6B6",
                position: "absolute",
                marginTop: "3px",
                marginLeft: "5px",
              }}
            />
            <input
              className={'input-responsive-box'}
              type={"search"}
              placeholder={lang === 'es' ? 'Buscar...' : 'Search...'}
            // onChange=''
            />
          </div>
        </div>
          </div> */}
          {/*seccion sector*/}
          <div>
            <CustomizableTitle color={'#393939'}>
              {lang === 'es' ? 'Formación por sector' : 'Training by sector'}
            </CustomizableTitle>
            <StyledSector>
              <button style={{ backgroundColor: 'transparent' }} onClick={() => left()}>
                <ArrowLeft />
              </button>
              <StyledCardsSector id={'cards'}>
                {infoSectores.map((item, index) => (
                  <Link to={item.link} key={index}>
                    <CardSector
                      image={item.image}
                      title={lang === 'es' ? item.title_es : item.title_en}
                      cant={item.cant}
                      lang={lang}
                    />
                  </Link>
                ))}              
              </StyledCardsSector>
              <button style={{ backgroundColor: 'transparent' }} onClick={() => right()}>
                <ArrowRight />
              </button>
            </StyledSector>
          </div>
          <div style={{
            marginTop: '4%',
          }}>
        <CustomizableTitle color={'#393939'}>
              {lang === 'es' ? 'Formación por Habilidades' : 'Education based on skills'}
            </CustomizableTitle>
            <div style={{
              marginTop: '2%',
            }}>
              <div>
                <button onClick={() => setTab(0)} style={{ background: 'transparent' }}>
                  <h3 style={{ fontSize: '16px', fontWeight: tab === 0 ? 700 : null }}>{lang === 'es' ? 'Técnicas' : 'Techniques'}(3)</h3>
                  <div style={{ borderTop: tab === 0 ? '5px solid black' : '0', width: '79px' }} />
                </button>
                {/* <button onClick={() => setTab(1)} style={{ background: 'transparent' }}>
                  <h3 style={{ fontSize: '16px', fontWeight: tab === 1 ? 700 : null }}>{lang === 'es' ? 'Blandas' : 'Soft'}(3)</h3>
                  <div style={{ borderTop: tab === 1 ? '5px solid #202256' : '0', width: '79px' }} />
                </button> */}
              </div>
              <div style={{ borderTop: 'solid', width: '100%', borderColor: '#D6D6D6' }} />
            </div>
            {
              tab === 0 ?
              <StyledHabilidades>
              {infoHabilidades.map((item, index) => (
                <Link to={item.link} key={index}>
                  <CardHabilidad
                    image={item.image}
                    title={lang === 'es' ? item.title_es : item.title_en}
                    cant={item.cant}
                    lang={lang}
                  />
                </Link>
              ))}
            </StyledHabilidades>
                :
                <StyledHabilidades>
                  {/* <CardHabilidad image={disenio} title={'Comunicación'} cant={5} lang={lang} />
                  <CardHabilidad image={desarrolloProductos} title={'Trabajo en equipo'} cant={5} lang={lang} />
                  <CardHabilidad image={finanzasCard} title={'Proactividad y capacidad resolutiva'} cant={5} lang={lang} /> */}
                </StyledHabilidades>
            }
          </div>
        </StyledEducationSubcontainer>
      </BodyWebPapa>
    </ComponentWrapper>

  )
}

export default EducationScreen