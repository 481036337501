import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import checkEclipse from '../../assets/icons/checkEclipse.svg'
import checkedEclipse from '../../assets/icons/checkedEclipse.svg'
import checkedEclipse2 from '../../assets/icons/checkedEclipse2.svg'
export default function TestCompanyCultural(props) {
  const lang = useSelector(state => state.lang)
    const [checked, setChecked] = useState(
        {
            question1: { yes: false, no: false },
            question2: { yes: false, no: false },
            question3: { yes: false, no: false },
            question4: { yes: false, no: false },
        }
    )

    const handleOnclick = (e) => {
        console.log(checked)
        const { name, id } = e.target
        if (id === 'yes') {
            setChecked({
                ...checked,
                [name]: { yes: true }
            })
        }
        else if (id === 'no') {
            setChecked({
                ...checked,
                [name]: { no: true }
            })
        }
        else {
            setChecked({
                ...checked,
                [name]: { yes: false, no: false }
            })
        }
    }


    return (
        <div >
            <div style={{ marginLeft: '93px', marginRight: '96px', marginTop: '51px' }}>
                <h1 style={{ fontSize: '20px', fontWeight: '700', lineHeight: '30px', color: '#151515', }}>
                    {lang === 'es' ? 'Test de cultura organizacional' : 'Organizational culture test'}
                </h1>
                <div style={{marginTop: '10%'}}>
                    <p style={{ fontSize: '14px', fontWeight: '400' }}>
                        {lang === 'es' ? 'En la empresa las personas tienen miedo a hablar o a decir las cosas por temor a represalias o aislamiento.' : 'In the company people are afraid to speak or say things out of fear of reprisals or isolation.'}
                    </p>
                    <div style={{ marginLeft: '20px' }}>
                        <label>
                            <input style={{ display: 'none' }} name='question1' id='yes' type="checkbox" onChange={handleOnclick} />
                            {!checked.question1.yes ? <img src={checkEclipse} alt="checkEclipse" />
                                :
                                <div >
                                    <img style={{ position: 'absolute', marginLeft: '4px', marginTop: '7.5px' }} src={checkedEclipse} alt="checkedEclipse" />
                                    <img src={checkedEclipse2} alt="checkEclipse2" />
                                </div>
                            }
                        </label>
                        <label style={{ marginLeft: '20px' }}>
                            {lang === 'es' ? 'Si' : 'Yes'}
                        </label>
                        <br />
                        <label>
                            <input style={{ display: 'none' }} name='question1' id='no' type="checkbox" onChange={handleOnclick} />
                            {!checked.question1.no ? <img src={checkEclipse} alt="checkEclipse" />
                                :
                                <div >
                                    <img style={{ position: 'absolute', marginLeft: '4px', marginTop: '7.5px' }} src={checkedEclipse} alt="checkedEclipse" />
                                    <img src={checkedEclipse2} alt="checkEclipse2" />
                                </div>
                            }
                        </label>
                        <label style={{ marginLeft: '20px' }}>
                            {lang === 'es' ? 'No' : 'No'}
                        </label>
                    </div>
                </div>
                <div style={{marginTop: '10%'}}>
                    <p style={{ fontSize: '14px', fontWeight: '400' }}>
                        {lang === 'es' ? 'En la empresa, los errores se esconden por la alta intolerancia a los fallos y al fracaso.' : 'In the company, errors are hidden by high intolerance to failure and failure.'}
                    </p>
                    <div style={{ marginLeft: '20px' }}>
                        <label>
                            <input style={{ display: 'none' }} name='question2' id='yes' type="checkbox" onChange={handleOnclick} />
                            {!checked.question2.yes ? <img src={checkEclipse} alt="checkEclipse" />
                                :
                                <div >
                                    <img style={{ position: 'absolute', marginLeft: '4px', marginTop: '7.5px' }} src={checkedEclipse} alt="checkedEclipse" />
                                    <img src={checkedEclipse2} alt="checkEclipse2" />
                                </div>
                            }
                        </label>
                        <label style={{ marginLeft: '20px' }}>
                            {lang === 'es' ? 'Si' : 'Yes'}
                        </label>
                        <br />
                        <label>
                            <input style={{ display: 'none' }} name='question2' id='no' type="checkbox" onChange={handleOnclick} />
                            {!checked.question2.no ? <img src={checkEclipse} alt="checkEclipse" />
                                :
                                <div >
                                    <img style={{ position: 'absolute', marginLeft: '4px', marginTop: '7.5px' }} src={checkedEclipse} alt="checkedEclipse" />
                                    <img src={checkedEclipse2} alt="checkEclipse2" />
                                </div>
                            }
                        </label>
                        <label style={{ marginLeft: '20px' }}>
                            {lang === 'es' ? 'No' : 'No'}
                        </label>
                    </div>
                </div>
                <div style={{marginTop: '10%'}}>
                    <p style={{ fontSize: '14px', fontWeight: '400' }}>
                        {lang === 'es' ? 'No vale la pena intentar nuevas cosas, porque aquí "Nada va a cambiar.”' : 'It is not worth trying new things, because here "Nothing is going to change."'}
                    </p>
                    <div style={{ marginLeft: '20px' }}>
                        <label>
                            <input style={{ display: 'none' }} name='question3' id='yes' type="checkbox" onChange={handleOnclick} />
                            {!checked.question3.yes ? <img src={checkEclipse} alt="checkEclipse" />
                                :
                                <div >
                                    <img style={{ position: 'absolute', marginLeft: '4px', marginTop: '7.5px' }} src={checkedEclipse} alt="checkedEclipse" />
                                    <img src={checkedEclipse2} alt="checkEclipse2" />
                                </div>
                            }
                        </label>
                        <label style={{ marginLeft: '20px' }}>
                            {lang === 'es' ? 'Si' : 'Yes'}
                        </label>
                        <br />
                        <label>
                            <input style={{ display: 'none' }} name='question3' id='no' type="checkbox" onChange={handleOnclick} />
                            {!checked.question3.no ? <img src={checkEclipse} alt="checkEclipse" />
                                :
                                <div >
                                    <img style={{ position: 'absolute', marginLeft: '4px', marginTop: '7.5px' }} src={checkedEclipse} alt="checkedEclipse" />
                                    <img src={checkedEclipse2} alt="checkEclipse2" />
                                </div>
                            }
                        </label>
                        <label style={{ marginLeft: '20px' }}>
                            {lang === 'es' ? 'No' : 'No'}
                        </label>
                    </div>
                </div>
                <div style={{marginTop: '10%'}}>
                    <p style={{ fontSize: '14px', fontWeight: '400' }}>
                        {lang === 'es' ? '¿Crees que el día no tiene bastantes horas para todas las cosas que deberías hacer?' : 'Do you think the day does not have enough hours for all the things you should do?'}
                    </p>
                    <div style={{ marginLeft: '20px' }}>
                        <label>
                            <input style={{ display: 'none' }} name='question4' id='yes' type="checkbox" onChange={handleOnclick} />
                            {!checked.question4.yes ? <img src={checkEclipse} alt="checkEclipse" />
                                :
                                <div >
                                    <img style={{ position: 'absolute', marginLeft: '4px', marginTop: '7.5px' }} src={checkedEclipse} alt="checkedEclipse" />
                                    <img src={checkedEclipse2} alt="checkEclipse2" />
                                </div>
                            }
                        </label>
                        <label style={{ marginLeft: '20px' }}>
                            {lang === 'es' ? 'Si' : 'Yes'}
                        </label>
                        <br />
                        <label>
                            <input style={{ display: 'none' }} name='question4' id='no' type="checkbox" onChange={handleOnclick} />
                            {!checked.question4.no ? <img src={checkEclipse} alt="checkEclipse" />
                                :
                                <div >
                                    <img style={{ position: 'absolute', marginLeft: '4px', marginTop: '7.5px' }} src={checkedEclipse} alt="checkedEclipse" />
                                    <img src={checkedEclipse2} alt="checkEclipse2" />
                                </div>
                            }
                        </label>
                        <label style={{ marginLeft: '20px' }}>
                            {lang === 'es' ? 'No' : 'No'}
                        </label>
                    </div>
                </div>
            </div>
            <div style={{ display: 'flex', justifyContent: 'end', marginRight: '80px' }}>
                {
                    !checked.question1.yes && !checked.question1.no && !checked.question2.yes && !checked.question2.no && !checked.question3.yes && !checked.question3.no && !checked.question4.yes && !checked.question4.no ?
                    <button style={{ backgroundColor: 'transparent', height: '28px', width: '141px', borderRadius: '5px', borderStyle: 'solid', borderWidth: '1px', fontSize: '14px', fontWeight: '400', color: '#202256' }} disabled>
                      {lang === 'es' ? 'Puntuar test' : 'Score test'}
                    </button>
                    :
                    <button style={{ backgroundColor: 'transparent', height: '28px', width: '141px', borderRadius: '5px', borderStyle: 'solid', borderWidth: '1px', fontSize: '14px', fontWeight: '400', color: '#202256' }} onClick={props.handleOpenTestCulturalResult}>
                      {lang === 'es' ? 'Puntuar test' : 'Score test'}
                    </button>
                }
                
              </div>
        </div>
    )
}