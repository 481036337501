/* eslint-disable */
import React, { useEffect, useMemo, useState } from "react";
import {useDispatch, useSelector} from 'react-redux'
import {useHistory} from 'react-router-dom'
import Joyride from "react-joyride";
import {joyrideStep, tourStatus, tourViewedCompanyStatus} from "../../../actions";
const joyrideStyles = {
  options: {
    zIndex: 10000000,
  },
};

const TourCompany = () => {
  const dispatch = useDispatch()
  const {tour_status, steps, lang} = useSelector(state => state)
  const history = useHistory()
  const tourViewedCompany = JSON.parse(localStorage.getItem('tourViewedCompany'))

  const [width, setWidth] = useState(window.innerWidth);
  const [height, setHeight] = useState(window.innerHeight);
  const updateWidthAndHeight = () => {
    setWidth(window.innerWidth);
    setHeight(window.innerHeight);
  }
  useEffect(() => {
    window.addEventListener("resize", updateWidthAndHeight);
    return () => window.removeEventListener("resize", updateWidthAndHeight);
  }, [width, height]);
//a

 useEffect(() => {
    if (tourViewedCompany) {
      dispatch(tourStatus(false))
    }
 width > 1300 ? dispatch(joyrideStep({
    steps: [
      {
        target: "#home",
        content: (
          <>  
            <h2>
              {lang === "es" ? "Bienvenido al tour de Schneider Electric!" : "Welcome to Schneider Electric tour!"}
            </h2>
            <p>
              {lang === "es" ? "En este tour te mostraremos las funcionalidades de la plataforma" : "In this tour we will show you the functionalities of the platform"}
            </p>
          </>
        ),
        disableBeacon: true,
      },
      {
        target: "#pusblish",
        content: (
          <>
            <h2>
              {lang === "es" ? "Aquí encontrarás un botón para publicar tu oportunidad" : "Here you will find a button to publish your opportunity"}
            </h2>
            <p>
              {lang === "es" ? "Con tu plan actual podrás publicar un máximo de 1 oportunidad" : "With your current plan you can publish a maximum of 1 opportunity"}
            </p>
          </>
        )
      },
      {
        target: "#projects",
        content: (
          <>
            <h2>
              {lang === "es" ? "Aqui podrás ver las oportunidades que has publicado" : "Here you can see the opportunities you have published"}
            </h2>
            <p>
              {lang === "es" ? "¡También podras ver los aplicantes de cada una!" : "You can also see the applicants for each one!"}
            </p>
          </>
        )
      }
    ]
  })) 
  : dispatch(joyrideStep({
    steps: [
      {
        target: "#home",
        content: (
          <>  
            <h2>
              {lang === "es" ? "Bienvenido al tour de Schneider Electric!" : "Welcome to Schneider Electric tour!"}
            </h2>
            <p>
              {lang === "es" ? "En este tour te mostraremos las funcionalidades de la plataforma" : "In this tour we will show you the functionalities of the platform"}
            </p>
          </>
        ),
        disableBeacon: true,
      },
      {
        target: "#projects",
        content: (
          <>
            <h2>
              {lang === "es" ? "Aqui podrás ver las oportunidades que has publicado" : "Here you can see the opportunities you have published"}
            </h2>
            <p>
              {lang === "es" ? "¡También podras ver los aplicantes de cada una!" : "You can also see the applicants for each one!"}
            </p>
          </>
        )
      }
    ]
  }))
 }, []);

 const handleCallBack = (data) => {  
  const {action, index, lifecycle, type, step} = data;
  if(tourViewedCompany){
    return 
  }
  if(step.target==='#home' && lifecycle === 'complete' && index === 0 && action === 'close'){
    dispatch(tourStatus({run: false}))
    dispatch(tourViewedCompanyStatus(true))
    localStorage.setItem('tourViewedCompany', true)
  }

  if(step.target==='#pusblish' && lifecycle === 'complete' && index === 1 && action === 'close'){
    dispatch(tourStatus({run: false}))
    dispatch(tourViewedCompanyStatus(true))
    localStorage.setItem('tourViewedCompany', true)
  }

  if(step.target==='#projects' && lifecycle === 'complete' && index === 2 && action === 'close'){
    dispatch(tourStatus({run: false}))
    dispatch(tourViewedCompanyStatus(true))
    localStorage.setItem('tourViewedCompany', true)
  }
 }

  
  return (
    <div style={{width: '100%'}}>
    {tour_status.run ?<Joyride
        callback={handleCallBack}
        continuous={true}
        locale={{
          back: lang === "es" ? "Atras" : "Back",
          close: lang === "es" ? "Cerrar" : "Close",
          last: lang === "es" ? "Finalizar" : "Finish",
          next: lang === "es" ? "Siguiente" : "Next",
          skip: lang === "es" ? "Saltar" : "Skip",
        }}
        run={tour_status.run}
        stepIndex={tour_status.stepIndex}
        showProgress={true}
        showSkipButton={true}
        steps={steps.steps}
        //change button color
        styles={{
          options: {
            zIndex: 10000000,
            primaryColor: "#F07130",
          }
        }}
      /> : null}
      </div>
  )
}

export default TourCompany