import styled from "styled-components";

const CircularFrameContainer = styled.div<{color?: string}>`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  span {
    color: ${(props)=>props.color ? props.color : "#dfdfdf"};
    width: 72px !important;
    margin-left: -2em;
  }

  #second-circle-progress {
    color: #dfdfdf;
    position: absolute;
  }
`;

const AvatarContainer = styled.div`
  width: 56px;
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  position: absolute;
  border-radius: 50%;
  margin-left: -1px;

  img{
    width: 100%;
    position: absolute;
  }
`;

const CircularFrameContainerDropdown = styled.div<{color?: string}>`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  span {
    color: ${(props)=>props.color ? props.color : "#dfdfdf"};
    width: 100% !important;
    margin-left: -6.1em;
  }

  #second-circle-progress-dropdown {
    color: #dfdfdf;
    position: absolute;
    width: 8.7em !important;
  }
`;

const AvatarContainerDropdown = styled.div`
  width: 6.5em;
  height: 6.5em;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  position: absolute;
  border-radius: 50%;
  margin-left: -0.5em;

  img {
    width:100%;
    position:absolute;
  }
`;

export {
  CircularFrameContainer,
  AvatarContainer,
  CircularFrameContainerDropdown,
  AvatarContainerDropdown,
};
