/*eslint-disable*/
import React, { useEffect, useState } from "react";
import "./homeAdmBootcamp.css";
import Sidebar from "../../../components/Sidebar/Sidebar";
import Navbar from "../../../components/Navbar/Navbar";
import { useSelector } from "react-redux";
import WelcomeCoursesCard from "../cards/WelcomeCoursesCard";
import { Link } from "react-router-dom";
import ReactPlayer from "react-player";
import welcomeVideo from "./videobienvenidabootcamp1.mp4";
import axios from "axios";
import lock from "../../../assets/icons/lock-alt.svg";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import TestsBootcamp from "../TestsBootcamp/TestsBootcamp";
import imageFooter from "../../../assets/imagenes/unilever-card-1.png";

const HomeAdmBootcamp = () => {
  const lang = useSelector((state) => state.lang);
  const { user } = useSelector((state) => state);
  const [course, setCourse] = useState("");
  const history = useHistory();
  //TODO: Validar que el user tenga el rol de bootcamp
  //   https://www.figma.com/file/bRCsb0kTVy7fsHDPrWlxTn/Coally?node-id=6037%3A31046&t=9rBEeaHJ4VGAZM0M-4
  const [userBootcamp, setUserBootcamp] = useState();

  /*useEffect(() => {
    if (!user?.bootcampAprobado) {
      history.push('/consultor/home')
    }
  }, [user])*/

  useEffect(() => {
    axios
      .get(`https://findally-backend.herokuapp.com/api/userBootcamp/${user.id}`)
      .then((r) => {
        setUserBootcamp(r.data.data);
      });
  }, [user.id]);

  const titles = [
    {
      title: "Perfilamiento",
      image:
        "https://images.unsplash.com/photo-1487611459768-bd414656ea10?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
      desc: "En esta serie de videos encontrarás unas herramientas que te ayudarán a definir tu perfil tanto personal como profesional para ser más asertivos en la búsqueda de nuestro trabajo, ten en cuenta que es mucho más atractivo para las empresas el profesional que sabe para dónde va.",
    },
    {
      title: "Hoja de vida y cover letters",
      image:
        "https://images.unsplash.com/photo-1517245386807-bb43f82c33c4?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
      desc: "En esta sección del Bootcamp encontrarás cómo estructurar tu hoja de vida o curriculum de la mejor manera, esto para poder hacer match con el algoritmo que poseen las diferentes bolsas de empleo, lo cual permitirá que nuestro CV llegue a las manos de los reclutadores y este no sea descartado en el primer filtro.",
    },
    {
      title: "Networking",
      image:
        "https://images.pexels.com/photos/2566581/pexels-photo-2566581.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
      desc: "En esta sesión nuestro CEO te contará la importancia de tener un buen networking a través de diferentes pasos que van desde mapear esos contactos que son de nuestro interés hasta la publicación de contenido de valor en nuestro LinkedIn.",
    },
    {
      title: "Linkedin",
      image:
        "https://images.unsplash.com/photo-1515378791036-0648a3ef77b2?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
      desc: "En la siguiente serie de videos vas a encontrar cómo estructurar tu perfil de LinkedIn según los debidos parámetros, para que tu perfil sea mucho más atractivo y para que el algoritmo de LinkedIn lo haga más visible al momento que un reclutador busque un perfil con tus características.",
    },
  ];
  return (
    <div>
      <Sidebar location={"bootcamp administracion"} />
      <div
        className="BodyWeb"
        style={{
          padding: "0rem 2rem",
          position: "absolute",
          right: "0",
        }}
      >
        <Navbar />
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
          }}
        >
          <div className={"bootcamp-home-administracion-container"}>
            {/*banner*/}
            <div className={"bootcamp-home-administracion-banner"}>
              <div>
                <h1
                  style={{
                    fontSize: "20px",
                    fontWeight: "400",
                    color: "#fff",
                  }}
                >
                  {lang === "es"
                    ? "Acelera tu carrera profesional en"
                    : "Accelerate your career at the"}
                </h1>
                <h1
                  style={{
                    fontSize: "2em",
                    fontWeight: "bold",
                    color: "#fff",
                    fontFamily: "Roboto",
                  }}
                >
                  {lang === "es"
                    ? "bootcamp de empleabilidad"
                    : "employability bootcamp"}
                </h1>
              </div>
              <div>
                {userBootcamp?.test_validation ? (
                  <Link to={"/consultor/bootcamp"}>
                    <button
                      className={"bootcamp-home-administracion-button-courses"}
                    >
                      {lang === "es" ? "Ir al curso" : "Go to course"}
                    </button>
                  </Link>
                ) : (
                  <button
                    className={"bootcamp-home-administracion-button-courses"}
                    style={{
                      justifyContent: "space-evenly",
                      backgroundColor: "#D6D6D6",
                      color: "#393939",
                    }}
                    onClick={() =>
                      toast.error(
                        lang === "es"
                          ? "Debes completar los test para acceder al curso"
                          : "You must complete the tests to access the course"
                      )
                    }
                  >
                    <img src={lock} alt="lock" />
                    {lang === "es" ? "Ir al curso" : "Go to course"}
                  </button>
                )}
              </div>
            </div>

            {/*sección de videos y test*/}
            <div className={"bootcamp-home-administracion-home-subcont1"}>
              <div className={"bootcamp-home-administracion-home-subcont1-1"}>
                <h1 className={"bootcamp-home-administracion-titles"}>
                  ¡Bienvenido al Bootcamp de empleabilidad!
                </h1>
                <ReactPlayer
                  url={welcomeVideo}
                  controls={true}
                  width={"100%"}
                  height={"100%"}
                />
              </div>
              <div className={"bootcamp-home-administracion-home-subcont1-1"}>
                <h1 className={"bootcamp-home-administracion-titles"}>Test</h1>
                <p className={"bootcamp-home-administracion-desc"}>
                  Aquí encontrarás ejemplos de los posibles test que te harán al
                  momento de iniciar algún proceso de selección dentro de una
                  empresa.
                </p>
                <test-cont>
                  <TestsBootcamp />
                </test-cont>
              </div>
            </div>

            {/*  sector de cards */}
            <div className={"bootcamp-home-administracion-videos-cards"}>
              {titles.map((e, index) => {
                return (
                  <WelcomeCoursesCard
                    title={e.title}
                    description={e.desc}
                    setCourse={setCourse}
                    key={index}
                    course={course}
                    image={e.image}
                  />
                );
              })}
            </div>

            {/*  sector de curso segun tu enfoque, no se que va acá*/}
            <div className={"bootcamp-home-administracion-footer"}>
              <div
                style={{
                  width: "60%",
                  marginRight: "5%",
                  objectFit: "cover",
                }}
              >
                <img
                  style={{
                    width: "100%",
                    objectFit: "cover",
                    height: "100%",
                    borderRadius: "20px",
                  }}
                  src={imageFooter}
                  alt={""}
                />
              </div>
              <div>
                <p
                  style={{
                    fontSize: "14px",
                    fontWeight: "400",
                    color: "#5D5D5D",
                  }}
                >
                  Aquí encontrarás diferentes cursos que te enseñarán las
                  habilidades básicas que suelen ser solicitadas en la mayoría
                  de las vacantes según tu enfoque de carrera, es vital
                  conocerlas si no poseemos experiencia previa en el cargo.{" "}
                </p>
                {userBootcamp?.test_validation ? (
                  <Link to={"/consultor/bootcamp"}>
                    <button
                      className={"bootcamp-home-administracion-button-courses"}
                    >
                      {lang === "es" ? "Ir al curso" : "Go to course"}
                    </button>
                  </Link>
                ) : (
                  <button
                    className={"bootcamp-home-administracion-button-courses"}
                    style={{
                      justifyContent: "space-evenly",
                      backgroundColor: "#D6D6D6",
                      color: "#393939",
                    }}
                    onClick={() =>
                      toast.error(
                        lang === "es"
                          ? "Debes completar los test para acceder al curso"
                          : "You must complete the tests to access the course"
                      )
                    }
                  >
                    <img src={lock} alt="lock" />
                    {lang === "es" ? "Ir al curso" : "Go to course"}
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeAdmBootcamp;
