import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import { loadData } from "./fetchFormData";
import moment from "moment";

export const useCvForm = (idUser) => {
  const { lang, user } = useSelector((state) => state);
  const [editable, setIsEditable] = useState(false);
  const [profesions, setProfesiones] = useState([]);
  const [isSubmit, setisSubmit] = useState(false);
  const [divisa, setDivisa] = useState("");
  const [montoMin, setMontoMin] = useState("");
  const [certificados, setCertificados] = useState([]);
  const [educacion, setEducacion] = useState([
    {
      NombreInstitucion: "",
      TipoEduacion: "",
      Titulo_Certificacion: "",
      fecha_inicio: "",
      fecha_finalizacion: "",
      duracion: "",
      actualStudy: false,
    },
  ]);

  const [experiencia, setExperiencia] = useState([
    {
      Nombreempresa: "",
      fecha_inicio: "",
      fecha_finalizacion: "",
      totalDuracion: "",
      actualJob: false,
      cargos: [
        {
          nombrecargo: "",
          functions: "",
          duracion: "",
          ubicacion: "",
          descripcion: "",
        },
      ],
    },
  ]);
  const [aptitudes, setAptitudes] = useState();
  const [languages, setLanguages] = useState(
    {
      Language: "",
      Nivel: "",
    },

  );

  const [form, setForm] = useState({
    info_personal: {
      nombre: "",
      fecha_nacimiento: "",
      sexo: "",
      pais_nacimiento: "",
      departamento_nacimiento: "",
      municipio_nacimiento: "",
      profesion_actual: "",
      ubicacion: "",
    },
    contacto: {
      telefono: "",
      correo: "",
      linkedin: "",
    },
    aptitudes_principales: aptitudes,
    languages: [],
    certifications: certificados,
    extracto: "",
    experiencia: experiencia,
    educacion: educacion,
    pasion: "",
    aspiracion_salarial: "",
  });
  const prefillForm = (data) => {
    setCertificados(data.certifications);
    setEducacion(data.educacion);
    setExperiencia(data.experiencia);
    setAptitudes(data.aptitudes_principales);
    const parseo = data.aspiracion_salarial?.split(' ')
    setDivisa(parseo[0])
    setMontoMin(Number(parseo[1]?.replace(/\./g, '')))
    setForm({
      experiencia: data.experiencia,
      educacion: data.educacion,
      aptitudes_principales: data.aptitudes_principales,
      languages: data.languages,
      certifications: data.certifications,
      info_personal: data.info_personal,
      contacto: data.contacto,
      extracto: data.extracto,
      pasion: data.pasion,
      aspiracion_salarial: data.aspiracion_salarial,
    });
  };

  useEffect(() => {
    if (idUser) {
      const fetchData = async () => {
        const data = await loadData(idUser);
        if (data) {
          prefillForm(data.cv_file);
        }
      };
      fetchData().then(() => {
        setIsEditable(true);
      });
    }
  }, [idUser]);

  useEffect(() => {
    axios
      .get("https://findally-backend.herokuapp.com/profesions")
      .then((res) => {
        setProfesiones(res?.data);
      });
  }, []);

  const handleFormInfoPersonal = (e, setForm, form) => {
    setForm({
      ...form,
      info_personal: {
        ...form.info_personal,
        [e.target.name]: e.target.value,
      },
    });
  };

  const handleFormGenre = (e, setForm, form) => {
    setForm({
      ...form,
      info_personal: {
        ...form.info_personal,
        sexo: e.value,
      },
    });
  };

  const handleFormProfesion = (e, setForm, form) => {
    setForm({
      ...form,
      info_personal: {
        ...form.info_personal,
        profesion_actual: e.value,
      },
    });
  };

  const handleFormContacto = (e, setForm, form) => {
    setForm({
      ...form,
      contacto: {
        ...form.contacto,
        [e.target.name]: e.target.value,
      },
    });
  }

  const handleDelete = (name) => {
    setForm({
      ...form,
      languages: form?.languages.filter((item) => item.Language + item.Nivel !== name)
    })
  }

  const isLanguageExists = (language) => {
    const existingLanguages = form?.languages?.map(lang => lang?.Language?.trim()?.toLowerCase());
    const newLanguage = language?.trim()?.toLowerCase();
    return existingLanguages?.includes(newLanguage);
  };

  const addExperience = () => {
    setExperiencia([
      ...experiencia,
      {
        Nombreempresa: "",
        fecha_inicio: "",
        fecha_finalizacion: "",
        totalDuracion: "",
        actualJob: false,
        cargos: [
          {
            nombrecargo: "",
            functions: "",
            duracion: "",
            ubicacion: "",
            descripcion: "",
          },
        ],
      },
    ]);
  };




  const addCargo = (index) => {
    const newExperience = [...experiencia];
    newExperience[index].cargos.push({
      nombrecargo: "",
      functions: "",
      duracion: "",
      ubicacion: "",
      descripcion: "",
    });
    setExperiencia(newExperience);
  };
  const calculateTotalDuration = (startDate, endDate) => {
    if (endDate === "-") {
      return "Actualidad";
    }
    const start = moment(startDate, "YYYY-MM-DD");
    const end = moment(endDate, "YYYY-MM-DD");
    const duration = moment.duration(end.diff(start));

    const years = duration.years();
    const months = duration.months();
    const days = duration.days();

    if (years === 0 && months === 0 && days === 0) {
      return "Menos de un día";
    }
    let durationStr = "";
    if (years > 0) {
      durationStr += `${years} año${years === 1 ? "" : "s"} `;
    }
    if (months > 0) {
      durationStr += `${months} mes${months === 1 ? "" : "es"} `;
    }
    if (years === 0 && months === 0 && days > 0) {
      durationStr += `${days} día${days === 1 ? "" : "s"}`;
    }

    return durationStr.trim();
  };

  const handleExperienceChange = (e, index, field) => {
    const newExperience = [...experiencia];
    newExperience[index][field] = e.target.value;
    newExperience[index].totalDuracion = calculateTotalDuration(
      newExperience[index].fecha_inicio,
      newExperience[index].fecha_finalizacion
    );
    setExperiencia(newExperience);
    setForm({
      ...form,
      experiencia: newExperience,
    });
  };

  const handleActualJobChange = (e, index) => {
    const newExperience = [...experiencia];
    newExperience[index].actualJob = e.target.checked;
    newExperience[index].fecha_finalizacion = '-';
    newExperience[index].totalDuracion = calculateTotalDuration(
      newExperience[index].fecha_inicio,
      '-'
    );
    setExperiencia(newExperience);
  };

  const removeExperience = (index) => {
    const newExperience = [...experiencia];

    newExperience.splice(index, 1);
    setExperiencia(newExperience);
  };

  const handleCargoChange = (e, expIndex, indexCargo, field) => {
    const newExperience = [...experiencia];
    newExperience[expIndex].cargos[indexCargo][field] = e.target.value;
    setExperiencia(newExperience);
  };

  const handleAptitudChange = (selectedOptions, setForm, form) => {
    const aptitudesSeleccionadas = selectedOptions.map((option) => option.value); // Obtén los valores seleccionados
    setForm({
      ...form,
      aptitudes_principales: aptitudesSeleccionadas,
    });
  }

  const addEducacion = () => {
    setEducacion([
      ...educacion,
      {
        NombreInstitucion: "",
        TipoEduacion: "",
        Titulo_Certificacion: "",
        fecha_finalizacion: "",
        duracion: "",
      },
    ]);
  };

  const removeEducacion = (index) => {
    const newEducacion = [...educacion];
    newEducacion.splice(index, 1);
    setEducacion(newEducacion);
    setForm({
      ...form,
      educacion: newEducacion,
    });
  };

  const handleActualStudy = (e, index) => {
    const newEducacion = [...educacion];
    newEducacion[index].actualStudy = e.target.checked;
    newEducacion[index].fecha_finalizacion = '-';
    newEducacion[index].duracion = calculateTotalDuration(
      newEducacion[index].fecha_inicio,
      '-'
    );
    setEducacion(newEducacion);
  };

  const handleEducacionChange = (e, index, field) => {
    const newEducacion = [...educacion];
    newEducacion[index][field] = e?.target?.value ? e?.target?.value : e.value;
    setEducacion(newEducacion);
    setForm({
      ...form,
      educacion: newEducacion,
    });
  };

  const addCertificado = () => {
    setCertificados([...certificados, ""]);
  };

  const removeCertificado = (index) => {
    const newCertificados = [...certificados];
    newCertificados.splice(index, 1);
    setCertificados(newCertificados);
    setForm({
      ...form,
      certifications: newCertificados,
    });
  };

  const handleCertificadoChange = (e, index) => {
    const newCertificados = [...certificados];
    newCertificados[index] = e.target.value;
    setCertificados(newCertificados);
    setForm({
      ...form,
      certifications: newCertificados,
    });
  };

  const monedas = [
    { value: 'COP', label: 'COP' },
    { value: 'USD', label: 'USD' },
    { value: 'ARS', label: 'ARS' },
    { value: 'BRL', label: 'BRL' },
    { value: 'CLP', label: 'CLP' },
    { value: 'MXN', label: 'MXN' },
    { value: 'PEN', label: 'PEN' },
    { value: 'UYU', label: 'UYU' },
    { value: 'VEF', label: 'VEF' },
    { value: 'BOB', label: 'BOB' },
    { value: 'PYG', label: 'PYG' },
    { value: 'GTQ', label: 'GTQ' },
    { value: 'HNL', label: 'HNL' },
    { value: 'NIO', label: 'NIO' },
    { value: 'SVC', label: 'SVC' }
  ]

  const generos = [
    { value: "Masculino", label: "Masculino" },
    { value: "Femenino", label: "Femenino" },
    { value: "Otro", label: "Otro" },
  ];


  const nivelLenguaje = [
    { value: 'Nivel A1: Principiante', label: 'Nivel A1: Principiante' },
    { value: 'Nivel A2: Básico', label: 'Nivel A2: Básico' },
    { value: 'Nivel B1: Intermedio', label: 'Nivel B1: Intermedio' },
    { value: 'Nivel B2: Intermedio alto', label: 'Nivel B2: Intermedio alto' },
    { value: 'Nivel C1: Avanzado', label: 'Nivel C1: Avanzado' },
    { value: 'Nivel C2: Maestría', label: 'Nivel C2: Maestría' },
  ]

  const levelLanguage = [
    { value: 'Beginner Level A1', label: 'Beginner Level A1' },
    { value: 'Elementary Level A2', label: 'Elementary Level A2' },
    { value: 'Intermediate Level B1', label: 'Intermediate Level B1' },
    { value: 'Upper-Intermediate Level B2', label: 'Upper-Intermediate Level B2' },
    { value: 'Advanced Level C1', label: 'Advanced Level C1' },
    { value: 'Mastery Level C2', label: 'Mastery Level C2' },
  ]

  const seleccionLenguajes = [
    { value: 'Español', label: 'Español' },
    { value: 'Inglés', label: 'Inglés' },
    { value: 'Chino', label: 'Chino' },
    { value: 'Alemán', label: 'Alemán' },
    { value: 'Francés', label: 'Francés' },
    { value: 'Portugués', label: 'Portugués' },
    { value: 'Italiano', label: 'Italiano' }
  ];

  const selectionLanguages = [
    { value: 'Spanish', label: 'Spanish' },
    { value: 'English', label: 'English' },
    { value: 'Chinese', label: 'Chinese' },
    { value: 'German', label: 'German' },
    { value: 'French', label: 'French' },
    { value: 'Portuguese', label: 'Portuguese' },
    { value: 'Italian', label: 'Italian' }
  ];


  const habilidades_blandas = [
    { value: "Capacidad de respuesta", label: "Capacidad de respuesta" },
    { value: "Adaptabilidad", label: "Adaptabilidad" },
    { value: "Atención a los detalles", label: "Atención a los detalles" },
    { value: "Colaboración", label: "Colaboración" },
    {
      value: "Habilidad para las comunicaciones",
      label: "Habilidad para las comunicaciones",
    },
    { value: "Creatividad", label: "Creatividad" },
    { value: "Pensamiento crítico", label: "Pensamiento crítico" },
    { value: "Inteligencia emocional", label: "Inteligencia emocional" },
    { value: "Empatía", label: "Empatía" },
    { value: "Flexibilidad", label: "Flexibilidad" },
    { value: "Innovación", label: "Innovación" },
    { value: "Liderazgo", label: "Liderazgo" },
    { value: "Organización", label: "Organización" },
    { value: "Paciencia", label: "Paciencia" },
    {
      value: "Habilidades para socializar",
      label: "Habilidades para socializar",
    },
    { value: "Resolución de problemas", label: "Resolución de problemas" },
    { value: "Responsabilidad", label: "Responsabilidad" },
    { value: "Autocritica", label: "Autocritica" },
    { value: "Pensamiento estratégico", label: "Pensamiento estratégico" },
    { value: "Trabajo en equipo", label: "Trabajo en equipo" },
    { value: "Gestión del tiempo", label: "Gestión del tiempo" },
    { value: "Ética laboral", label: "Ética laboral" },
    { value: 'Confiabilidad', label: 'Confiabilidad' },
    { value: 'Sistemático', label: 'Sistemático' },
    { value: 'Imparcialidad', label: 'Imparcialidad' },
    { value: 'Practicidad', label: 'Practicidad' },
    { value: 'Aventurero', label: 'Aventurero' },
    { value: 'Orientación a personas', label: 'Orientación a personas' },
    { value: 'Búsqueda de crecimiento', label: 'Búsqueda de crecimiento' },
    { value: 'Proactividad', label: 'Proactividad' },
    { value: "Expresivo", label: "Expresivo" },
    { value: "Autónomo", label: "Autónomo" },
  ];
  const softSkills = [
    { value: "Response capacity", label: "Response capacity" },
    { value: "Adaptability", label: "Adaptability" },
    { value: "Attention to detail", label: "Attention to detail" },
    { value: "Collaboration", label: "Collaboration" },
    { value: "Communication skills", label: "Communication skills" },
    { value: "Creativity", label: "Creativity" },
    { value: "Critical thinking", label: "Critical thinking" },
    { value: "Emotional intelligence", label: "Emotional intelligence" },
    { value: "Empathy", label: "Empathy" },
    { value: "Flexibility", label: "Flexibility" },
    { value: "Innovation", label: "Innovation" },
    { value: "Leadership", label: "Leadership" },
    { value: "Organization", label: "Organization" },
    { value: "Patience", label: "Patience" },
    { value: "Social skills", label: "Social skills" },
    { value: "Problem solving", label: "Problem solving" },
    { value: "Responsibility", label: "Responsibility" },
    { value: "Self-criticism", label: "Self-criticism" },
    { value: "Strategic thinking", label: "Strategic thinking" },
    { value: "Teamwork", label: "Teamwork" },
    { value: "Time management", label: "Time management" },
    { value: "Work ethics", label: "Work ethics" },
    { value: 'Reliability', label: 'Reliability' },
    { value: 'Systematic', label: 'Systematic' },
    { value: 'Impartiality', label: 'Impartiality' },
    { value: 'Practicality', label: 'Practicality' },
    { value: 'Adventurous', label: 'Adventurous' },
    { value: 'People orientation', label: 'People orientation' },
    { value: 'Growth mindset', label: 'Growth mindset' },
    { value: 'Proactivity', label: 'Proactivity' },
    { value: "Expressive", label: "Expressive" },
    { value: "Autonomous", label: "Autonomous" },
  ];

  const nivelFormacion = [
    { value: 'Primaria', label: 'Primaria' },
    { value: 'Secundaria', label: 'Secundaria' },
    { value: 'Técnico', label: 'Técnico' },
    { value: 'Profesional', label: 'Profesional' },
    { value: 'Postgrado', label: 'Postgrado' },
    { value: 'Doctorado', label: 'Doctorado' },
  ]

  const levelEducation = [
    { value: 'Primary', label: 'Primary' },
    { value: 'Secondary', label: 'Secondary' },
    { value: 'Technical', label: 'Technical' },
    { value: 'Professional', label: 'Professional' },
    { value: 'Postgraduate', label: 'Postgraduate' },
    { value: 'Doctorate', label: 'Doctorate' },
  ]



  return {
    form,
    setForm,
    lang,
    user,
    editable,
    isSubmit,
    setisSubmit,
    divisa,
    setDivisa,
    montoMin,
    setMontoMin,
    profesions,
    experiencia,
    setExperiencia,
    educacion,
    setEducacion,
    aptitudes,
    setAptitudes,
    handleFormInfoPersonal,
    certificados,
    setCertificados,
    addExperience,
    addCargo,
    handleExperienceChange,
    handleCargoChange,
    handleAptitudChange,
    addEducacion,
    removeEducacion,
    handleEducacionChange,
    addCertificado,
    handleCertificadoChange,
    monedas,
    habilidades_blandas,
    softSkills,
    generos,
    handleFormGenre,
    handleFormProfesion,
    removeCertificado,
    nivelLenguaje,
    levelLanguage,
    languages,
    setLanguages,
    seleccionLenguajes,
    selectionLanguages,
    handleDelete,
    isLanguageExists,
    nivelFormacion,
    levelEducation,
    handleActualJobChange,
    removeExperience,
    handleActualStudy,
    handleFormContacto
  };
};
