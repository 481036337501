/*eslint-disable*/
import React, { useState, useContext, useEffect } from "react";
import PlaceOutlinedIcon from "@mui/icons-material/PlaceOutlined";
import "../../../../../../profesionalpag/oportunidad/cards/cardsLinked.css";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import { toast } from "react-toastify";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import ContentPasteOutlinedIcon from "@mui/icons-material/ContentPasteOutlined";
import CottageOutlinedIcon from "@mui/icons-material/CottageOutlined";
import HandshakeOutlinedIcon from "@mui/icons-material/HandshakeOutlined";
import AttachMoneyOutlinedIcon from "@mui/icons-material/AttachMoneyOutlined";
import PushPinOutlinedIcon from "@mui/icons-material/PushPinOutlined";
import LanguageOutlinedIcon from "@mui/icons-material/LanguageOutlined";
import TourProfessional from "../../../../../components/tour/TourProfessional";
import Spinner from "react-bootstrap/Spinner";
import { getUserInfoWithoutReload } from "../../../../../../actions";
import upload from "../../../../../../assets/icons/Upload.svg";
import file from "../../../../../../assets/icons/file.svg";
import { ReduxStateInterface } from "../../../../../types/reduxInterface";
import { ApplyButton } from "../../../../buttons/ButtonStyles";
import { SchneiderContext } from "../../../../../context/SchneiderContext";
import { ArrowContainer } from "./BigCardLinkedStyles"
import { CircularProgress } from "@mui/material";
interface BigCardLinkedProps {
  image?: string;
  title?: string;
  company?: string;
  place?: string;
  date?: string;
  requirements?: string;
  description?: string;
  companyDescription?: string;
  habilities?: any;
  language?: any;
  contract?: string;
  idProyect?: string;
  applied?: boolean;
  setaplicable?: any;
  setRefresh?: any;
  modalidad?: string;
  salary?: any;
  responsabilities?: string;
  experience?: any;
  aplicantes?: number;
  listadoBeneficios?: any;
  descripcionBeneficios?: string;
  descripcionComisiones?: string;
  fit?: any;
  softSkills?: any;
}

const BigCardLinked: React.FC<BigCardLinkedProps> = ({
  image,
  title,
  company,
  place,
  date,
  requirements,
  description,
  companyDescription,
  habilities,
  language,
  contract,
  idProyect,
  applied,
  setaplicable,
  setRefresh,
  modalidad,
  salary,
  responsabilities,
  experience,
  aplicantes,
  listadoBeneficios,
  descripcionBeneficios,
  descripcionComisiones,
  fit,
  softSkills,
}) => {
  const { schneider } = useContext(SchneiderContext);
  const lenguaje = useSelector((state: ReduxStateInterface) => state.lang);
  const dispatch = useDispatch();
  const user = useSelector((state: ReduxStateInterface) => state.user);
  const userCV = useSelector((state: ReduxStateInterface) => state.cv);
  const [openCvPopUp, setOpenCvPopUp] = useState(false);
  const [fileform, setfileform] = useState("");
  const [fileform2, setfileform2] = useState(false);
  const [motivate, setmotivate] = useState("");
  const [loading, setloading] = useState(false);
  const [showMotivate, setshowMotivate] = useState(false);
  const [loadingCvPersonal, setLoadingCvPersonal] = useState(false);
  const [selectedFileCvGeneral, setSelectedFileCvGeneral] = useState(null);
  const [isFilePickedCvGeneral, setIsFilePickedCvGeneral] = useState(false);
  const [nextStep, setNextStep] = useState(true);
  const [readyFilterForm, setReadyFilterForm] = useState(false);
  const handleCopyToClipboard = () => {
    navigator.clipboard
      .writeText(`https://coally-platform.com/schneider/project/${idProyect}`)
      .then(() => {
        return lenguaje === "es"
          ? toast.success("Enlace copiado al portapapeles")
          : toast.success("Link copied to clipboard");
      });
  };

  useEffect(() => {
    axios
      .get(`https://findally-backend.herokuapp.com/api/filterForm/${user.id}`)
      .then((r) => {
        // @ts-ignore
        if (r.data.data !== null) {
          setNextStep(false);
          return setReadyFilterForm(true);
        }
        return setReadyFilterForm(true);
      })
      .catch((e) => {
        console.log(e);
      });
  }, [user]);

  function tiempoTranscurrido(fechaStr: string) {
    const fecha = new Date(fechaStr);
    const ahora = new Date();
    const diferencia = ahora.getTime() - fecha.getTime();
    const segundos = Math.floor(diferencia / 1000);
    const minutos = Math.floor(segundos / 60);
    const horas = Math.floor(minutos / 60);
    const dias = Math.floor(horas / 24);
    const meses = Math.floor(dias / 30);
    const años = Math.floor(meses / 12);

    if (lenguaje === "en") {
      if (años > 0) {
        return `${años} year${años > 1 ? "s" : ""} ago`;
      } else if (meses > 0) {
        return `${meses} month${meses > 1 ? "s" : ""} ago`;
      } else if (dias > 0) {
        return `${dias} day${dias > 1 ? "s" : ""} ago`;
      } else if (horas > 0) {
        return `${horas} hour${horas > 1 ? "s" : ""} ago`;
      } else if (minutos > 0) {
        return `${minutos} minute${minutos > 1 ? "s" : ""} ago`;
      } else {
        return "A few seconds ago";
      }
    } else {
      if (años > 0) {
        return `Hace ${años} año${años > 1 ? "s" : ""}`;
      } else if (meses > 0) {
        return `Hace ${meses} mes${meses > 1 ? "es" : ""}`;
      } else if (dias > 0) {
        return `Hace ${dias} día${dias > 1 ? "s" : ""}`;
      } else if (horas > 0) {
        return `Hace ${horas} hora${horas > 1 ? "s" : ""}`;
      } else if (minutos > 0) {
        return `Hace ${minutos} minuto${minutos > 1 ? "s" : ""}`;
      } else {
        return "Hace unos segundos";
      }
    }
  }

  const aplicarOportunidad = async () => {
    const data = {
      usuario: user.id,
      proyecto: idProyect,
      form: true,
    };
    await axios
      .post("https://findally-backend.herokuapp.com/api/applicants", data)
      .then(() => {
        lenguaje === "es"
          ? toast.success("Se ha aplicado correctamente", {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          })
          : toast.success("Application sent successfully", {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        handleClosePopUp();
        setaplicable(true);
        setRefresh(true);
      })
      .catch((e) => {
        console.log(e);
        lenguaje === "es"
          ? toast.error("ocurrio un error", {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          })
          : toast.error("An error has occurred", {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
      });
  };

  const enviarMotivacion = async () => {
    setloading(true);
    if (motivate === "") {
      lenguaje === "es"
        ? toast.error("Por favor escribe una motivación", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        })
        : toast.error("Please write a motivation", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      return;
    }
    setloading(false);
    aplicarOportunidad();
    // toast.promise(axios.post('https://findally-backend.herokuapp.com/api/motivation/gpt', {
    //     motivacion: motivate,
    // }), {
    //     pending: "Estamos calculando tu motivación...",
    //     success: "Motivación generada correctamente",
    //     error: "Error al generar motivación"
    // }).then(() => {
    //     setloading(false)
    //     aplicarOportunidad()
    // })
  };

  const enviarform = () => {
    const f2 = new FormData();
    f2.append("upload_file", fileform);
    f2.append("usuario", user.id);
    f2.append("pasion", motivate);
    axios
      .post("https://findally-backend.herokuapp.com/api/user/readCV", f2)
      .then(() => {
        setshowMotivate(true);
      })
      .catch(() => {
        toast.error("CV invalido", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  };

  const vercv = (e: any) => {
    const f3 = new FormData();
    f3.append("upload_file", e.target.files[0]);
    f3.append("usuario", user.id);
    axios
      .post("https://findally-backend.herokuapp.com/api/user/validateCV", f3)
      .then(() => {
        setfileform2(true);
      })
      .catch(() => {
        setfileform2(false);
        toast.error("CV invalido", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  };
  const insertarArchivo = async (e: any) => {
    setfileform(e.target.files[0]);
    vercv(e);
  };

  const handleClosePopUp = () => {
    setOpenCvPopUp(false);
  };

  const handleUploadCvGeneral = async () => {
    setLoadingCvPersonal(true);
    const fd2 = new FormData();
    fd2.append("upload_file", selectedFileCvGeneral);
    fd2.append("usuario", user.id);
    axios
      .post(
        "https://findally-backend.herokuapp.com/api/user/readCvPersonalized",
        fd2
      )
      .then((res) => {
        setLoadingCvPersonal(false);
        toast.success("Cv personalizado subido correctamente", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        dispatch(getUserInfoWithoutReload(user.id) as any);
      });
  };

  const changeHandler2 = (e: any) => {
    setSelectedFileCvGeneral(e.target.files[0]);
    setIsFilePickedCvGeneral(true);
  };

  const handleCancel2 = (e: any) => {
    e.preventDefault();
    setSelectedFileCvGeneral(null);
    setIsFilePickedCvGeneral(false);
  };

  return (
    <div className={"big-card-linked-container"}>
      <TourProfessional />
      <div className="oportunidades-big-card-top-container">
        <div className="oportunidades-big-card-top-image">
          <img
            src={image ? image : ""}
            alt="oportunitie world hold ooooon, Instead of messing with our future, open up inside"
            style={{
              width: "164px",
              height: "164px",
              borderRadius: "8px",
              objectFit: "scale-down",
              transition: "all 0.3s ease 0s",
            }}
          />
        </div>
        <div className="oportunidades-big-card-top-body">
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
            }}
          >
            <h1
              style={{
                fontSize: "16px",
                fontWeight: "700",
                color: "#393939",
                textAlign: "left",
                width: "100%",
              }}
            >
              {title}
            </h1>
            {fit ? (
              <div
                style={{
                  color: "#F07130",
                  marginTop: "10px",
                  width: "100%",
                }}
              >
                {lenguaje === "es"
                  ? `${fit.toFixed(2)}% Compatibilidad`
                  : `${fit.toFixed(2)}% Compatibility`}
              </div>
            ) : null}
          </div>
          <h1
            style={{
              fontSize: "16px",
              fontWeight: "400",
              color: "#393939",
              margin: 0,
            }}
          >
            {company}
          </h1>
          {place ? (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
                alignItems: "center",
                width: "100%",
                textAlign: "center",
                paddingTop: "8px",
              }}
            >
              <PlaceOutlinedIcon
                style={{ color: "#5D5D5D", fontSize: "20px" }}
              />
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  flexDirection: "row",
                  alignItems: "center",
                  width: "90%",
                  height: "100%",
                }}
              >
                <p
                  style={{
                    color: "#5D5D5D",
                    marginLeft: "10px",
                    marginBottom: 0,
                    fontSize: "16px",
                  }}
                >
                  {place?.charAt(0).toUpperCase() + place.slice(1)}
                </p>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    flexDirection: "row",
                    alignItems: "center",
                    marginLeft: "20px",
                  }}
                >
                  <p
                    style={{
                      color: "#24D054",
                      fontSize: "14px",
                      fontWeight: "400",
                      margin: 0,
                    }}
                  >
                    {lenguaje === "es" ? "Abierta" : "Open"}
                  </p>
                  <p
                    style={{
                      color: "#5D5D5D",
                      fontSize: "14px",
                      fontWeight: "400",
                      marginLeft: "5px",
                      marginRight: "5px",
                      marginBottom: 0,
                    }}
                  >
                    •
                  </p>
                  <p
                    style={{
                      color: "#5D5D5D",
                      fontSize: "14px",
                      fontWeight: "400",
                      margin: 0,
                    }}
                  >
                    {aplicantes}{" "}
                    {aplicantes === 1
                      ? lenguaje === "es"
                        ? "aplicante"
                        : "applicant"
                      : lenguaje === "es"
                        ? "aplicantes"
                        : "applicants"}
                  </p>
                </div>
              </div>
            </div>
          ) : null}
          <h1
            style={{
              fontSize: "14px",
              fontWeight: "400",
              color: "#C5C5C5",
              paddingTop: "10px",
            }}
          >
            {tiempoTranscurrido(date)}
          </h1>
          {!applied && userCV && userCV?._id && readyFilterForm ? (
            <ApplyButton
              color={schneider.color.primary}
              onClick={() => {
                setOpenCvPopUp(true);
              }}
              id="applyButton"
            >
              {lenguaje === "es" ? "Aplicar" : "Apply"}
            </ApplyButton>
          )
            : !applied && !userCV && !userCV?._id && readyFilterForm ? (
              <ApplyButton
                color={schneider.color.primary}
                onClick={() => {
                  setOpenCvPopUp(true);
                }}
                id="applyButton"
                width={'150px'}
              >
                {lenguaje === "es" ? "Llenar formulario" : "Fill form"}
              </ApplyButton>
            )
              : applied && nextStep && readyFilterForm ? (
                <Link to={`/${schneider.route}/complete/form`}>
                  <ApplyButton color={schneider.color.primary} width={"200px"}>
                    {lenguaje === "es" ? "Ir al siguiente paso" : "Next step"}
                  </ApplyButton>
                </Link>
              )
                : (
                  applied &&
                  !nextStep && (
                    <ApplyButton
                      color={schneider.color.primary}
                      disabled={true}
                      id="applyButton"
                    >
                      {lenguaje === "es" ? "Ya aplicaste" : "You already applied"}
                    </ApplyButton>
                  )
                )
          }
          <Dialog
            open={openCvPopUp}
            onClose={() => setOpenCvPopUp(false)}
            scroll={"paper"}
            aria-labelledby="scroll-dialog-title"
            aria-describedby="scroll-dialog-description"
          >
            <DialogTitle id="scroll-dialog-title" className="mediumfont">
              {lenguaje === "es" ? "Aplica a la oportunidad" : "Apply to job"}
            </DialogTitle>
            <DialogContent dividers={true}>
              {!applied && !userCV && !userCV?._id && !showMotivate ? (
                <>
                  <div>
                    <h3>
                      {lenguaje === "es"
                        ? "Subir Cv (descargado de LinkedIn)"
                        : "Upload CV (downloaded from LinkedIn)"}
                    </h3>
                    <input
                      type="file"
                      name="files"
                      onChange={(e) => insertarArchivo(e)}
                    />
                  </div>
                  <br />
                  <div>
                    <h3>O</h3>
                  </div>
                  <br />
                  <div>
                    <h3>
                      {lenguaje === "es"
                        ? "Llenar formulario manualmente"
                        : "Fill form manually"}
                    </h3>
                    <Link to="/schneider/cv">
                      <ApplyButton
                        type="button"
                        color={schneider.color.primary}
                      >
                        {lenguaje === "es" ? "Continuar" : "Continue"}
                      </ApplyButton>
                    </Link>
                  </div>
                </>
              ) : !applied &&
                userCV &&
                userCV?._id &&
                !showMotivate &&
                user.CVPersonalized ? (
                <div>
                  <h3>
                    {lenguaje === "es"
                      ? "¿Qué te motiva aplicar en este rol?"
                      : "What motivates you to apply for this role?"}
                  </h3>
                  <textarea
                    onChange={(e) => setmotivate(e.target.value)}
                    rows={5}
                    cols={50}
                    style={{ width: "100%", border: "0.5px solid #C5C5C5" }}
                  />
                </div>
              ) : !applied &&
                !userCV &&
                !userCV?._id &&
                showMotivate &&
                user.CVPersonalized ? (
                <div>
                  <h3>
                    {lenguaje === "es"
                      ? "¿Qué te motiva aplicar en este rol?"
                      : "What motivates you to apply for this role?"}
                  </h3>
                  <textarea
                    onChange={(e) => setmotivate(e.target.value)}
                    rows={5}
                    cols={50}
                    style={{ width: "100%", border: "0.5px solid #C5C5C5" }}
                  />
                </div>
              ) : !applied &&
                userCV &&
                userCV?._id &&
                showMotivate &&
                user.CVPersonalized ? (
                <div>
                  <h3>
                    {lenguaje === "es"
                      ? "¿Qué te motiva aplicar en este rol?"
                      : "What motivates you to apply for this role?"}
                  </h3>
                  <textarea
                    onChange={(e) => setmotivate(e.target.value)}
                    rows={5}
                    cols={50}
                    style={{ width: "100%", border: "0.5px solid #C5C5C5" }}
                  />
                </div>
              ) : null}
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => {
                  handleClosePopUp();
                }}
              >
                {lenguaje === "es" ? "Cancelar" : "Cancel"}
              </Button>
              {fileform2 && !loading && !showMotivate ? (
                <Button
                  onClick={() => {
                    enviarform();
                  }}
                >
                  {lenguaje === "es" ? "Enviar" : "Send"}
                </Button>
              ) : userCV &&
                userCV?._id &&
                motivate &&
                !loading &&
                user.CVPersonalized ? (
                <Button
                  onClick={() => {
                    void enviarMotivacion();
                  }}
                >
                  {lenguaje === "es" ? "Enviar" : "Send"}
                </Button>
              ) : loading ? (
                <Spinner animation={"border"} variant={"primary"} />
              ) : !userCV &&
                !userCV?._id &&
                showMotivate &&
                user.CVPersonalized ? (
                <Button
                  onClick={() => {
                    void enviarMotivacion();
                  }}
                >
                  {lenguaje === "es" ? "Enviar" : "Send"}
                </Button>
              ) : userCV &&
                userCV?._id &&
                !loading &&
                !user.CVPersonalized &&
                selectedFileCvGeneral ? (
                <Button
                  onClick={() => {
                    void handleUploadCvGeneral();
                  }}
                >
                  {lenguaje === "es" ? "Subir Cv" : "Send Cv"}
                </Button>
              ) : !userCV &&
                !userCV?._id &&
                !user.CVPersonalized &&
                selectedFileCvGeneral ? (
                <Button
                  onClick={() => {
                    void handleUploadCvGeneral();
                  }}
                >
                  {lenguaje === "es" ? "Subir Cv" : "Send Cv"}
                </Button>
              ) : null}
            </DialogActions>
          </Dialog>
        </div>
        <div
          className={"clipboard-opportunitie-card"}
          onClick={() => {
            handleCopyToClipboard();
          }}
        >
          <ContentPasteOutlinedIcon
            style={{ color: "#5D5D5D", fontSize: "20px" }}
          />
        </div>
      </div>
      <div className="oportunidades-big-card-body-container" id="applyProject">
        <div className={"oportunidades-big-card-body-inner"}>
          {requirements?.length ? (
            <div
              style={{
                fontWeight: "400",
                fontSize: "16px",
                color: "#5D5D5D",
              }}
            >
              <span
                style={{
                  fontWeight: "700",
                  fontSize: "16px",
                  color: "#5D5D5D",
                  paddingRight: "10px",
                }}
              >
                {lenguaje === "es" ? "Requisitos" : "Requirements"} :
              </span>
              {requirements}
            </div>
          ) : null}
          {habilities?.length ? (
            <>
              <div
                style={{
                  fontWeight: "400",
                  fontSize: "16px",
                  color: "#5D5D5D",
                }}
              >
                <span
                  style={{
                    fontWeight: "700",
                    fontSize: "16px",
                    color: "#5D5D5D",
                  }}
                >
                  {lenguaje === "es"
                    ? "Habilidades técnicas: "
                    : "Technical skills: "}
                </span>
                <ul>
                  {habilities.map((item: any, index: any) => {
                    return (
                      <li
                        key={index}
                        style={{
                          listStyle: "disc",
                        }}
                      >
                        {item.charAt(0).toUpperCase() +
                          item.slice(1).toLowerCase()}
                      </li>
                    );
                  })}
                </ul>
              </div>
            </>
          ) : null}
          {softSkills?.length ? (
            <>
              <div
                style={{
                  fontWeight: "400",
                  fontSize: "16px",
                  color: "#5D5D5D",
                }}
              >
                <span
                  style={{
                    fontWeight: "700",
                    fontSize: "16px",
                    color: "#5D5D5D",
                  }}
                >
                  {lenguaje === "es"
                    ? "Habilidades blandas: "
                    : "Soft skills: "}
                </span>
                <ul>
                  {softSkills?.map((item: any, index: any) => {
                    return (
                      <li
                        key={index}
                        style={{
                          listStyle: "disc",
                        }}
                      >
                        {item.charAt(0).toUpperCase() +
                          item.slice(1).toLowerCase()}
                      </li>
                    );
                  })}
                </ul>
              </div>
            </>
          ) : null}
          {description?.length ? (
            <p
              style={{
                fontWeight: "400",
                fontSize: "16px",
                color: "#5D5D5D",
                overflowWrap: "anywhere",
                marginTop: "5%",
              }}
            >
              <span
                style={{
                  fontWeight: "700",
                  fontSize: "16px",
                  color: "#5D5D5D",
                  paddingRight: "10px",
                }}
              >
                {lenguaje === "es" ? "Descripción:" : "Description:"}
              </span>
              {description}
            </p>
          ) : null}
          {companyDescription?.length ? (
            <p
              style={{
                fontWeight: "400",
                fontSize: "16px",
                color: "#5D5D5D",
                overflowWrap: "anywhere",
                marginTop: "2%",
              }}
            >
              <span
                style={{
                  fontWeight: "700",
                  fontSize: "16px",
                  color: "#5D5D5D",
                  paddingRight: "10px",
                }}
              >
                {lenguaje === "es"
                  ? "Descripción de la Empresa:"
                  : "Company Description:"}
              </span>
              {companyDescription}
            </p>
          ) : null}
          {responsabilities?.length ? (
            <>
              <div
                style={{
                  fontWeight: "400",
                  fontSize: "16px",
                  color: "#5D5D5D",
                }}
              >
                <span
                  style={{
                    fontWeight: "700",
                    fontSize: "16px",
                    color: "#5D5D5D",
                  }}
                >
                  {lenguaje === "es"
                    ? "Responsabilidades y funciones: "
                    : "Responsabilities and functions: "}
                </span>
                <ul>
                  {Array.isArray(responsabilities)
                    ? responsabilities.map((item, index) => {
                      return (
                        <li
                          key={index}
                          style={{
                            listStyle: "disc",
                          }}
                        >
                          {item.charAt(0).toUpperCase() +
                            item.slice(1).toLowerCase()}
                        </li>
                      );
                    })
                    : responsabilities}
                </ul>
              </div>
            </>
          ) : null}
          {listadoBeneficios?.length ? (
            <>
              <div
                style={{
                  fontWeight: "400",
                  fontSize: "16px",
                  color: "#5D5D5D",
                  overflowWrap: "anywhere",
                  marginTop: "5%",
                }}
              >
                <span
                  style={{
                    fontWeight: "700",
                    fontSize: "16px",
                    color: "#5D5D5D",
                    paddingRight: "10px",
                  }}
                >
                  {lenguaje === "es" ? "Beneficios:" : "Benefits:"}
                </span>
                <ul>
                  {listadoBeneficios.map((item: any, index: any) => {
                    return (
                      <li
                        key={index}
                        style={{
                          listStyle: "disc",
                        }}
                      >
                        {item}
                      </li>
                    );
                  })}
                </ul>
              </div>
            </>
          ) : null}
          {descripcionComisiones ? (
            <p
              style={{
                fontWeight: "400",
                fontSize: "16px",
                color: "#5D5D5D",
                overflowWrap: "anywhere",
                marginTop: "5%",
              }}
            >
              <span
                style={{
                  fontWeight: "700",
                  fontSize: "16px",
                  color: "#5D5D5D",
                  paddingRight: "10px",
                }}
              >
                {lenguaje === "es" ? "Comisiones:" : "Commissions:"}
              </span>
              {descripcionComisiones}
            </p>
          ) : null}
          {descripcionBeneficios ? (
            <p
              style={{
                fontWeight: "400",
                fontSize: "16px",
                color: "#5D5D5D",
                overflowWrap: "anywhere",
                marginTop: "5%",
              }}
            >
              <span
                style={{
                  fontWeight: "700",
                  fontSize: "16px",
                  color: "#5D5D5D",
                  paddingRight: "10px",
                }}
              >
                {lenguaje === "es"
                  ? "Descripcion de beneficios:"
                  : "Benefits description:"}
              </span>
              {descripcionBeneficios}
            </p>
          ) : null}
        </div>
        <div className={"oportunidades-big-card-body-inner-2"}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              alignItems: "flex-start",
              justifyContent: "flex-start",
              marginLeft: "5%",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "flex-start",
              }}
            >
              <LanguageOutlinedIcon
                style={{
                  color: "#D8D8D8",
                  fontSize: "24px",
                  fontWeight: "400",
                }}
              />
              <p
                style={{
                  fontWeight: "700",
                  fontSize: "14px",
                  color: "#5D5D5D",
                  marginLeft: "5px",
                }}
              >
                {lenguaje === "es" ? "Idiomas: " : "Languages: "}
              </p>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "flex-start",
                marginLeft: "10%",
              }}
            >
              <p>
                {/*map the language array [ingles, español] and for each item put a comma if there is only 1 just put the language*/}
                {language.map((item: any, index: any) => {
                  return (
                    <span key={index}>
                      {item}
                      {index !== language.length - 1 ? ", " : ""}
                    </span>
                  );
                })}
              </p>
            </div>
            <hr
              style={{
                width: "80%",
                height: "1px",
                margin: "0",
              }}
            />
          </div>
          {modalidad ? (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
                alignItems: "flex-start",
                justifyContent: "flex-start",
                marginLeft: "5%",
                marginTop: "5%",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "flex-start",
                }}
              >
                <CottageOutlinedIcon
                  style={{
                    color: "#202256",
                    fontSize: "24px",
                    fontWeight: "400",
                  }}
                />
                <p
                  style={{
                    fontWeight: "700",
                    fontSize: "14px",
                    color: "#5D5D5D",
                    marginLeft: "5px",
                  }}
                >
                  {lenguaje === "es" ? "Modalidad: " : "Modality: "}
                </p>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "flex-start",
                  marginLeft: "20%",
                }}
              >
                <p>{modalidad}</p>
              </div>
              <hr
                style={{
                  width: "80%",
                  height: "1px",
                  margin: "0",
                }}
              />
            </div>
          ) : null}
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              alignItems: "flex-start",
              justifyContent: "flex-start",
              marginLeft: "5%",
              marginTop: "5%",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "flex-start",
              }}
            >
              <HandshakeOutlinedIcon
                style={{
                  color: "#202256",
                  fontSize: "24px",
                  fontWeight: "400",
                }}
              />
              <p
                style={{
                  fontWeight: "700",
                  fontSize: "14px",
                  color: "#5D5D5D",
                  marginLeft: "5px",
                }}
              >
                {lenguaje === "es" ? "Tipo de contrato: " : "Contract type: "}
              </p>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "flex-start",
                marginLeft: "10%",
              }}
            >
              <p>{contract}</p>
            </div>
            <hr
              style={{
                width: "80%",
                height: "1px",
                margin: "0",
              }}
            />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              alignItems: "flex-start",
              justifyContent: "flex-start",
              marginLeft: "5%",
              marginTop: "5%",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "flex-start",
              }}
            >
              <AttachMoneyOutlinedIcon
                style={{
                  color: "#202256",
                  fontSize: "24px",
                  fontWeight: "400",
                }}
              />
              <p
                style={{
                  fontWeight: "700",
                  fontSize: "14px",
                  color: "#5D5D5D",
                  marginLeft: "5px",
                }}
              >
                {lenguaje === "es" ? "Sueldo base: " : "Base salary: "}
              </p>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "flex-start",
                marginLeft: "10%",
              }}
            >
              {salary.length ? (
                <p className={"CardOportunidadProfesional-Salario"}>
                  {/*TODO: DESCOMENTAR CUANDO SE UTILICE EN EL MODELO EN FORMATO NUMERO*/}

                  {/* {salary.split(" ")[0] === "COP" ||
                  salary.split(" ")[0] === "USD"
                    ? salary.split(" ")[0] === "Divisa"
                      ? `$ ${salary.split(" ")[1]}`
                      : salary.split(" ")[1] === "0"
                      ? lenguaje === "es"
                        ? "Salario no especificado"
                        : "Salary not specified"
                      : `${salary.split(" ")[0]} ${salary.split(" ")[1]}`
                    : `${salary.split(" ")[1]}`}*/}
                  {salary}
                  <span
                    style={{
                      fontSize: "14px",
                      fontWeight: "700",
                      color: "#C3CCFF",
                    }}
                  >
                    {lenguaje === "es" && salary.split(" ")[1] !== "0"
                      ? "/mensual"
                      : lenguaje === "en" && salary.split(" ")[1] !== "0"
                        ? "/month"
                        : ""}
                  </span>
                </p>
              ) : (
                <p className={"CardOportunidadProfesional-Salario"}>
                  {lenguaje === "es"
                    ? "Salario no especificado"
                    : "Salary not specified"}
                </p>
              )}
            </div>
            <hr
              style={{
                width: "80%",
                height: "1px",
                margin: "0",
              }}
            />
          </div>
          {experience.length ? (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
                alignItems: "flex-start",
                justifyContent: "flex-start",
                marginLeft: "5%",
                marginTop: "5%",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "flex-start",
                }}
              >
                <PushPinOutlinedIcon
                  style={{
                    color: "#202256",
                    fontSize: "24px",
                    fontWeight: "400",
                  }}
                />
                <p
                  style={{
                    fontWeight: "700",
                    fontSize: "14px",
                    color: "#5D5D5D",
                    marginLeft: "5px",
                  }}
                >
                  {lenguaje === "es"
                    ? "Experiencia requerida: "
                    : "Required experience: "}
                </p>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "flex-start",
                  marginLeft: "10%",
                }}
              >
                <p>
                  {experience === "0.5" && lenguaje === "es"
                    ? "6 meses"
                    : experience === "0.5" && lenguaje === "en"
                      ? "6 months"
                      : experience}{" "}
                  {experience.includes("años") || experience === "0.5" ? (
                    ""
                  ) : (
                    <span>{lenguaje === "es" ? "años" : "years"}</span>
                  )}
                </p>
              </div>
              <hr
                style={{
                  width: "80%",
                  height: "1px",
                  margin: "0",
                }}
              />
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default BigCardLinked;
