/* eslint-disable */
import React, { useEffect, useState } from 'react'
import Sidebar from "../../components/Sidebar/Sidebar";
import Navbar from "../../components/Navbar/Navbar";
import { Link, useHistory, useParams } from "react-router-dom";
import { useSelector } from 'react-redux';
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import './Styles/CompanyProfile.css';
import CardInfoCompany from './cards/CardInfoCompany';
import axios from 'axios';
import PerfilCardCompany from './cards/PerfilCardCompany';
import { CardPlanActual } from './cards/CardPlanActual';
import CardDescripcion from './cards/CardDescripcion';
import CardLegalInfo from './cards/CardLegalInfo';

function CompanyProfile() {
  const [userInfo, setUserInfo] = useState()
  const {user} = useSelector((state) => state)
  const lang = useSelector((state) => state.lang);
  const [dataUser, setDataUser] = useState()
  const { id } = useParams()

  const handleCloseTest = () => setOpenTest(false);
  const handleOpenTestCulturalResult = () => {
    setOpenTest(false);
    setOpenTestCulturalResult(true);
  };
  const handleCloseTestCulturalResult = () => setOpenTestCulturalResult(false);

  useEffect(() => {
    axios.get(`https://findally-backend.herokuapp.com/user/${id}`)
      .then((res) => {
        setUserInfo({
          id: res.data.id,
          roles: res.data.roles[0].name ? res.data.roles[0].name : res.data.roles,         
          name: res.data.username,
          email: res.data.email,
          avatar: res.data.avatar ? res.data.avatar : '',
        })
      })
      .catch((err) => {
        console.log(err)
      })
  }, [])


  useEffect(() => {
    axios.get(`https://findally-backend.herokuapp.com/api/user/Companyform/${id}`)
      .then((res) => {
        setDataUser(res.data.Company_Form[0])
      })
      .catch((err) => {
        console.log(err)
      })
  }, [])

  const styleTestCultural = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '721px',
    height: '85vh',
    bgcolor: '#FFFFFF',
    borderRadius: '8px',
    overflow: 'auto',
  }
  const styleTestCulturalResult = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 772,
    height: 603,
    bgcolor: '#FFFFFF',
    border: '2px solid #000',
    borderRadius: '8px',
    boxShadow: 24,
    overflow: 'auto'
  }

  const tests = [{
    id: 1,
    name: 'Test de cultura organizacional',
    description: 'Realice este test gratuito para saber más sobre sí mismo y sus puntos fuertes.',
  }]

  const testsENG = [{
    id: 1,
    name: 'Organizational culture test',
    description: 'Take this free trial to learn more about yourself and your strengths.',
  }]

  const navigate = useHistory();

  return (
    <div style={{
      backgroundColor: "#F5F5F5",
      display: "flex",
    }}>
      <div>
        <Sidebar location={'profile'} />
        <div className="BodyWeb"
          style={{ padding: '0rem 2rem', position: 'absolute', right: '0' }}>
          <Navbar locate={'profile'} />
          <div style={{ paddingInline: '2.5%' }}>
            <div className='body-profile-company' onClick={navigate.goBack}>
              <ArrowBackIosIcon />
              <h1 className='title-body-profile'>
                {lang === 'es' ? 'Perfil' : 'Profile'}
              </h1>
            </div>
            <div className='card-profile-company'>
              <div>
                <PerfilCardCompany userInfo={userInfo} lang={lang} dataUser={dataUser} />
                {userInfo?.id === user?.id && 
                <CardPlanActual lang={lang} />
                 }
              </div>
              <div className='cards-info-profile-company'>
                <h1 className='title-info-profile-company'>
                  {dataUser?.nombre_empresa ? dataUser?.nombre_empresa : userInfo?.name}
                </h1>
                <CardDescripcion dataUser={dataUser} lang={lang} />
                <div className='subcards-info-profile-company'>
                  <CardInfoCompany user={userInfo?.id} dataUser={dataUser} />
                  <CardLegalInfo dataUser={dataUser} lang={lang} userInfo={userInfo} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  )
}

export default CompanyProfile
