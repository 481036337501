import './Modal.css';
import React from 'react'
// import en from "../../lang/en-US";
// import es from "../../lang/es-COL"
// import counterpart from "counterpart";
// import DoneIcon from '@material-ui/icons/Done';
import ClearIcon from '@material-ui/icons/Clear';

function Popup(props){
  console.log(props.children)
// counterpart.registerTranslations('en',en) 
// counterpart.registerTranslations('es',es)
// counterpart.setLocale(localStorage.getItem('langlocal'));
  return(props.trigger) ? (
    <div className="popup">
      <div className="popup-inner">          
          { props.children }
          <ClearIcon style={{cursor:'pointer'}} onClick={() => props.setTrigger(false)}></ClearIcon>
          
      </div>
    </div>
  ) : "";
}

export default Popup;