import React from "react";
import './Loader.css'
import notFound from '../../assets/icons/notFound.svg'
import { useSelector } from "react-redux";

const Loader = (props) => {
  const lang = useSelector(state => state.lang)
  if (props.render === true) {
    return (
      <div style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        height: `${props.altura}vh`
      }}>
        <div className="line-spinner"></div>
      </div>
    )
  } else {
    if(props.array?.length > 0){
      return null
    }
    else {
      return (
        <div className={'contenedor-notFound'}>
          <h3 className={''}>{props.mensaje || lang === "es" ? 'Aún no has publicado una oportunidad': "You haven't posted any opportunity yet"}</h3>
          <img src={notFound} alt={'proyectos no encontrados'}/>
        </div>
      )
    }
  }

}

export default Loader;