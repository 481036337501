import React,{useState}  from "react";
import Header from '../components/Header/Header';
import { registerContact} from "../actions";
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import PhoneIcon from '@material-ui/icons/Phone';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import { connect } from "react-redux";
import { Link } from 'react-router-dom';
import './styles/ContactUs.css';

import en from "../lang/en-US";
import es from "../lang/es-COL"
import counterpart from "counterpart";
import Translate from "react-translate-component";


function FormContactUs(props) {
    const [form, setValues] = useState({
        nombre : "",
        correo : "",
        asunto : "",
        descripcion : ""
      }); 
      
      const handleInput = (event) => {
          setValues({
            ...form,
            [event.target.name]: event.target.value,
          });
        };

        const handleSubmit = (event) => {
          event.preventDefault();
          console.log(form)
          props.registerContact(form);
        };

        //const lang = useSelector(state => state.lang)
        
      
      
        counterpart.registerTranslations('en',en) 
        counterpart.registerTranslations('es',es)  
        counterpart.setLocale(localStorage.getItem('langlocal'));

        const NombreCompleto =counterpart.translate('NombreCompleto')
        const CorreoElectrónico =counterpart.translate('CorreoElectrónico')
        const Asuntoo =counterpart.translate('Asuntoo')
        const Descripción =counterpart.translate('Descripción')

    return (
        <> 
        <Header/>
      <div className="volver-legal" style={{height:'150px'}}>
        <Link className="volver-leg-icon" to="/consultor/home">
          {" "}
          🠸 {<Translate content="ATRÁS" component="span"/>}
        </Link>
        <p style={{display:'flex',margin:'50px',marginLeft:'190px',fontSize:'30px'}}>{<Translate content="ContactUs" component="span"/>}</p>
      </div>
        <div className="contact-us">
            <div className="info">
                <p>
                {<Translate content="puedescompartircon" component="span"/>} </p>
                <ul className="list-contact-coally">
                  <div className="lista-contact-coally-contenedor">
                    <li style={{color:'white'}}><WhatsAppIcon className="iconos-lista-contact"/>{<Translate content="EscríbenosporWhatsApp" component="span"/>}</li>
                  </div>
                  <div className="lista-contact-coally-contenedor">
                    <li style={{color:'white'}}><PhoneIcon  className="iconos-lista-contact"/>{<Translate content="telefonocontacto" component="span"/>}</li>
                    </div>
                    <div className="lista-contact-coally-contenedor">
                    <li style={{color:'white'}}><MailOutlineIcon className="iconos-lista-contact"/>equipoventas@coally.com</li>
                    </div>
                </ul>
            </div>
            <div className="form">
            <form className="direct_message" onSubmit={handleSubmit}>
                <h2 className="title_form_contact">{<Translate content="direct" component="span"/>}</h2>
                <div className="mensaje-directo-forma">
                    <input
                        className="input_contact_us_2"
                        type="text"
                        placeholder={NombreCompleto}
                        name="nombre"
                        onChange={handleInput}
                    />
                    <input
                        className="input_contact_us_2"
                        type="text"
                        placeholder={CorreoElectrónico}
                        name="correo"
                        onChange={handleInput}
                    />
                    </div>
                    <input
                        className="input_contact_us"
                        type="text"
                        placeholder={Asuntoo}
                        name="asunto"
                        onChange={handleInput}
                    />
                    <textarea
                        className="text_contact_us"
                        placeholder={Descripción}
                        name="descripcion"
                        onChange={handleInput}
                    />
                    <div className="button_div_contact">
                    <button className="button_contact_us" onSubmit={handleSubmit}>{<Translate content="Enviar" component="span"/>}</button>
                    </div>
            </form>
            </div>
        </div>
    </>
  );
}

const mapDispatchToProps = {
    registerContact,
  };
  
  export default connect(null, mapDispatchToProps)(FormContactUs);
  