import React, { Fragment } from "react";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Divider from "@material-ui/core/Divider";
import Button from "@material-ui/core/Button";
import { CreateProject } from "../../actions/index";
import { useDispatch , useSelector} from "react-redux";

// Destructure props
const Confirm = ({
  handleNext,
  handleBack,
  values: {nombre, ciudad, metricas, problema_empresa, causa_empresa, objetivos , linea_negocio, habilidades_requeridas, comentario , codigo_promo, nivel_ingles,}
}) => {
  const handleSubmit = () => {
    console.log({ usuario:state.id, nombre, ciudad, metricas, problema_empresa, causa_empresa,linea_negocio,  objetivos,habilidades_requeridas, nivel_ingles, codigo_promo, comentario })
    dispatch(CreateProject({ usuario:state.id, nombre, ciudad, metricas, problema_empresa, causa_empresa,linea_negocio,  objetivos,habilidades_requeridas, nivel_ingles, codigo_promo, comentario }, "/company/profile"))
    handleNext();
  };
  const state = useSelector(state => state.user)
  const dispatch = useDispatch()

  return (
    <Fragment>
      <List disablePadding>
        <ListItem>
          <ListItemText primary="Nombre proyecto" secondary={nombre} />
        </ListItem>

        <Divider />

        <ListItem>
          <ListItemText primary="Ciudad" secondary={ciudad} />
        </ListItem>

        <Divider />

        <ListItem>
          <ListItemText primary="Metricas" secondary={metricas} />
        </ListItem>

        <Divider />

        <ListItem>
          <ListItemText primary="Problema" secondary={problema_empresa} />
        </ListItem>

        <Divider />

        <ListItem>
          <ListItemText primary="Causa" secondary={causa_empresa} />
        </ListItem>

        <Divider />

        <ListItem>
          <ListItemText primary="Objetivos" secondary={objetivos} />
        </ListItem>

        <Divider />
        
      
        <ListItem>
          <ListItemText primary="comentario" secondary={comentario} />
        </ListItem>

        <Divider />

        <ListItem>
          <ListItemText primary="codigo" secondary={codigo_promo} />
        </ListItem>

        <Divider />

        <ListItem>
          <ListItemText primary="habilidades" secondary={habilidades_requeridas} />
        </ListItem>

        <Divider />

        <ListItem>
          <ListItemText primary="nivel ingles" secondary={nivel_ingles} />
        </ListItem>

        <Divider />

        <ListItem>
          <ListItemText primary="linea de negocio" secondary={linea_negocio} />
        </ListItem>

        <Divider />

      </List>

      <div
        style={{ display: "flex", marginTop: 50, justifyContent: "flex-end" }}
      >
        <Button variant="contained" color="default" onClick={handleBack}>
          Back
        </Button>
        <Button
          style={{ marginLeft: 20 }}
          variant="contained"
          color="secondary"
          onClick={handleSubmit}
        >
          Confirm & Continue
        </Button>
      </div>
    </Fragment>
  );
};

export default Confirm;
