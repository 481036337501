import React, {useState} from "react";
import Sidebar from "../../../components/Sidebar/Sidebar";
import Navbar from "../../../components/Navbar/Navbar";
import './homeunileverprofesional.css'
import {useSelector} from "react-redux";
import BannerUnileverHome from "../../../components/unilever/banner/BannerUnileverHome";
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';
import IndeterminateCheckBoxOutlinedIcon from '@mui/icons-material/IndeterminateCheckBoxOutlined';
import SearchIcon from "@mui/icons-material/Search";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import WindowIcon from "@mui/icons-material/Window";
import UnileverMentorCardHomeGrid from "./UnileverMentorCardHomeGrid";
import UnileverMentorCardHomeList from "./UnileverMentorCardHomeList";

const HomeUnileverProfessional = () => {
  const {lang} = useSelector(state => state);
  const [listView, setListView] = useState(true);
  const [gridView, setGridView] = useState(false);
  const [filters, setFilters] = useState([]);
  const handleListView = () => {
    setListView(true);
    setGridView(false);
  }

  const handleGridView = () => {
    setListView(false);
    setGridView(true);
  }

  const handleFilters = (filter) => {
    const newFilters = [...filters];
    const index = newFilters.indexOf(filter);
    if (index === -1) {
      newFilters.push(filter);
    } else {
      newFilters.splice(index, 1);
    }
    setFilters(newFilters);
  }

  const handleClearFilters = () => {
    setFilters([]);
  }


  return (
    <div>
      <Sidebar location={'home'}/>
      <div
        className="BodyWeb"
        style={{
          padding: "0rem 2rem",
          position: "absolute",
          right: "0",
        }}
      >
        <Navbar locate={'home'}/>
        <div className="home-unilever-profesional-banner">
          <BannerUnileverHome/>
        </div>
        <div className="home-unilever-profesional">
          <div className="home-unilever-profesional-container">
            <div className="home-unilever-profesional-inner-1">
              <div className="home-unilever-profesional-inner-1-1">
                <div className="home-profesional-filter-1">
                  <h1 className="filter-title-unilever-1"
                      style={{margin: 0}}>{lang === 'es' ? 'Filtros' : 'Filters'}</h1>
                </div>
                <div className="home-profesional-filter-2" onClick={() => handleClearFilters()}>
                  <h1 className='filter-title-unilever' style={{margin: 0}}>{
                    lang === 'es' ? 'Borrar' : 'Clean'
                  }</h1>
                  <DeleteOutlineIcon sx={{
                    color: '#5D5D5D',
                  }}/>
                </div>
              </div>
              <div className="home-unilever-profesional-inner-1-2">
                <div className="filter-container-inner">
                  <h1 className="filter-category-title"
                      style={{margin: 0}}>{lang === 'es' ? 'Desarrollo e ingeniería' : 'Development and engineering'}</h1>
                  <div className="filter-category-inner" onClick={() => handleFilters(
                    'Frontend'
                  )}>
                    {filters.includes('Frontend') ?
                      <CheckBoxOutlinedIcon sx={{fontSize: '20px', color: '#202256'}}/>
                      :
                      <IndeterminateCheckBoxOutlinedIcon sx={{fontSize: '20px', color: '#C5C5C5'}}/>}
                    <h1 className='filter-title-unilever' style={{margin: 0, marginLeft: '20px'}}>Frontend</h1>
                  </div>
                  <div className="filter-category-inner" onClick={() => handleFilters(
                    'Backend y bases de datos'
                  )}>
                    {filters.includes('Backend y bases de datos') ?
                      <CheckBoxOutlinedIcon sx={{fontSize: '20px', color: '#202256'}}/>
                      :
                      <IndeterminateCheckBoxOutlinedIcon sx={{fontSize: '20px', color: '#C5C5C5'}}/>}
                    <h1 className='filter-title-unilever' style={{margin: 0, marginLeft: '20px'}}>
                      {lang === 'es' ? 'Backend y bases de datos' : 'Backend and databases'}
                    </h1>
                  </div>
                  <div className="filter-category-inner" onClick={() => handleFilters(
                    'QA Autorización de pruebas'
                  )}>
                    {filters.includes('QA Autorización de pruebas') ?
                      <CheckBoxOutlinedIcon sx={{fontSize: '20px', color: '#202256'}}/>
                      :
                      <IndeterminateCheckBoxOutlinedIcon sx={{fontSize: '20px', color: '#C5C5C5'}}/>}
                    <h1 className='filter-title-unilever' style={{margin: 0, marginLeft: '20px'}}>
                      {lang === 'es' ? 'QA Autorización de pruebas' : 'QA Authorization'}
                    </h1>
                  </div>
                  <div className="filter-category-inner" onClick={() => handleFilters(
                    'Game Design'
                  )}>
                    {filters.includes('Game Design') ?
                      <CheckBoxOutlinedIcon sx={{fontSize: '20px', color: '#202256'}}/>
                      :
                      <IndeterminateCheckBoxOutlinedIcon sx={{fontSize: '20px', color: '#C5C5C5'}}/>}
                    <h1 className='filter-title-unilever' style={{margin: 0, marginLeft: '20px'}}>
                      {lang === 'es' ? 'Game Design' : 'Game Design'}
                    </h1>
                  </div>
                </div>
                <div className="filter-container-inner">
                  <h1 className="filter-category-title"
                      style={{margin: 0}}>{lang === 'es' ? 'Marketing' : 'Marketing'}</h1>
                  <div className="filter-category-inner" onClick={() => handleFilters(
                    'Especialista SEO'
                  )}>
                    {filters.includes('Especialista SEO') ?
                      <CheckBoxOutlinedIcon sx={{fontSize: '20px', color: '#202256'}}/>
                      :
                      <IndeterminateCheckBoxOutlinedIcon sx={{fontSize: '20px', color: '#C5C5C5'}}/>}
                    <h1 className='filter-title-unilever' style={{margin: 0, marginLeft: '20px'}}>
                      {lang === 'es' ? 'Especialista SEO' : 'SEO Specialist'}
                    </h1>
                  </div>
                  <div className="filter-category-inner" onClick={() => handleFilters(
                    'Community Manager'
                  )}>
                    {filters.includes('Community Manager') ?
                      <CheckBoxOutlinedIcon sx={{fontSize: '20px', color: '#202256'}}/>
                      :
                      <IndeterminateCheckBoxOutlinedIcon sx={{fontSize: '20px', color: '#C5C5C5'}}/>}
                    <h1 className='filter-title-unilever' style={{margin: 0, marginLeft: '20px'}}>
                      {lang === 'es' ? 'Community Manager' : 'Community Manager'}
                    </h1>
                  </div>
                  <div className="filter-category-inner" onClick={() => handleFilters(
                    'Data Marketer'
                  )}>
                    {filters.includes('Data Marketer') ?
                      <CheckBoxOutlinedIcon sx={{fontSize: '20px', color: '#202256'}}/>
                      :
                      <IndeterminateCheckBoxOutlinedIcon sx={{fontSize: '20px', color: '#C5C5C5'}}/>}
                    <h1 className='filter-title-unilever' style={{margin: 0, marginLeft: '20px'}}>
                      {lang === 'es' ? 'Data Marketer' : 'Data Marketer'}
                    </h1>
                  </div>
                  <div className="filter-category-inner" onClick={() => handleFilters(
                    'Publicidad digital'
                  )}>
                    {filters.includes('Publicidad digital') ?
                      <CheckBoxOutlinedIcon sx={{fontSize: '20px', color: '#202256'}}/>
                      :
                      <IndeterminateCheckBoxOutlinedIcon sx={{fontSize: '20px', color: '#C5C5C5'}}/>}
                    <h1 className='filter-title-unilever' style={{margin: 0, marginLeft: '20px'}}>
                      {lang === 'es' ? 'Publicidad digital' : 'Digital Advertising'}
                    </h1>
                  </div>
                </div>
                <div className="filter-container-inner">
                  <h1 className="filter-category-title"
                      style={{margin: 0}}>{lang === 'es' ? 'Diseño y UX' : 'Design and UX'}</h1>
                  <div className="filter-category-inner" onClick={() => handleFilters(
                    'Branding designer'
                  )}>
                    {filters.includes('Branding designer') ?
                      <CheckBoxOutlinedIcon sx={{fontSize: '20px', color: '#202256'}}/>
                      :
                      <IndeterminateCheckBoxOutlinedIcon sx={{fontSize: '20px', color: '#C5C5C5'}}/>}
                    <h1 className='filter-title-unilever' style={{margin: 0, marginLeft: '20px'}}>
                      {lang === 'es' ? 'Branding designer' : 'Branding designer'}
                    </h1>
                  </div>
                  <div className="filter-category-inner" onClick={() => handleFilters('UX/UI Designer')}>
                    {filters.includes('UX/UI Designer') ?
                      <CheckBoxOutlinedIcon sx={{fontSize: '20px', color: '#202256'}}/>
                      :
                      <IndeterminateCheckBoxOutlinedIcon sx={{fontSize: '20px', color: '#C5C5C5'}}/>}
                    <h1 className='filter-title-unilever' style={{margin: 0, marginLeft: '20px'}}>
                      {lang === 'es' ? 'UX/UI Designer' : 'UX/UI Designer'}
                    </h1>
                  </div>
                  <div className="filter-category-inner" onClick={() => handleFilters('Diseño de interfaces (UI)')}>
                    {filters.includes('Diseño de interfaces (UI)') ?
                      <CheckBoxOutlinedIcon sx={{fontSize: '20px', color: '#202256'}}/>
                      :
                      <IndeterminateCheckBoxOutlinedIcon sx={{fontSize: '20px', color: '#C5C5C5'}}/>}
                    <h1 className='filter-title-unilever' style={{margin: 0, marginLeft: '20px'}}>
                      {lang === 'es' ? 'Diseño de interfaces (UI)' : 'Interface Design (UI)'}
                    </h1>
                  </div>
                  <div className="filter-category-inner" onClick={() => handleFilters('Product Designer')}>
                    {filters.includes('Product Designer') ?
                      <CheckBoxOutlinedIcon sx={{fontSize: '20px', color: '#202256'}}/>
                      :
                      <IndeterminateCheckBoxOutlinedIcon sx={{fontSize: '20px', color: '#C5C5C5'}}/>}
                    <h1 className='filter-title-unilever' style={{margin: 0, marginLeft: '20px'}}>
                      {lang === 'es' ? 'Product Designer' : 'Product Designer'}
                    </h1>
                  </div>
                </div>
                <div className="filter-container-inner">
                  <h1 className="filter-category-title"
                      style={{margin: 0}}>{lang === 'es' ? 'Habilidades blandas' : 'Soft Skills'}</h1>
                  <div className="filter-category-inner" onClick={() => handleFilters('Finanzas personales')}>
                    {filters.includes('Finanzas personales') ?
                      <CheckBoxOutlinedIcon sx={{fontSize: '20px', color: '#202256'}}/>
                      :
                      <IndeterminateCheckBoxOutlinedIcon sx={{fontSize: '20px', color: '#C5C5C5'}}/>}
                    <h1 className='filter-title-unilever' style={{margin: 0, marginLeft: '20px'}}>
                      {lang === 'es' ? 'Finanzas personales' : 'Personal Finance'}
                    </h1>
                  </div>
                  <div className="filter-category-inner" onClick={() => handleFilters('Salud mental y física')}>
                    {filters.includes('Salud mental y física') ?
                      <CheckBoxOutlinedIcon sx={{fontSize: '20px', color: '#202256'}}/>
                      :
                      <IndeterminateCheckBoxOutlinedIcon sx={{fontSize: '20px', color: '#C5C5C5'}}/>}
                    <h1 className='filter-title-unilever' style={{margin: 0, marginLeft: '20px'}}>
                      {lang === 'es' ? 'Salud mental y física' : 'Mental and Physical Health'}
                    </h1>
                  </div>
                  <div className="filter-category-inner" onClick={() => handleFilters('Comunicación asertiva')}>
                    {filters.includes('Comunicación asertiva') ?
                      <CheckBoxOutlinedIcon sx={{fontSize: '20px', color: '#202256'}}/>
                      :
                      <IndeterminateCheckBoxOutlinedIcon sx={{fontSize: '20px', color: '#C5C5C5'}}/>}
                    <h1 className='filter-title-unilever' style={{margin: 0, marginLeft: '20px'}}>
                      {lang === 'es' ? 'Comunicación asertiva' : 'Assertive Communication'}
                    </h1>
                  </div>
                  <div className="filter-category-inner" onClick={() => handleFilters('Diversidad e inclusión')}>
                    {filters.includes('Diversidad e inclusión') ?
                      <CheckBoxOutlinedIcon sx={{fontSize: '20px', color: '#202256'}}/>
                      :
                      <IndeterminateCheckBoxOutlinedIcon sx={{fontSize: '20px', color: '#C5C5C5'}}/>}
                    <h1 className='filter-title-unilever' style={{margin: 0, marginLeft: '20px'}}>
                      {lang === 'es' ? 'Diversidad e inclusión' : 'Diversity and Inclusion'}
                    </h1>
                  </div>
                </div>
                <div className="filter-container-inner">
                  <h1 className="filter-category-title"
                      style={{margin: 0}}>{lang === 'es' ? 'Contenido digital' : 'Digital content'}</h1>
                  <div className="filter-category-inner" onClick={() => handleFilters('Creador de video')}>
                    {filters.includes('Creador de video') ?
                      <CheckBoxOutlinedIcon sx={{fontSize: '20px', color: '#202256'}}/>
                      :
                      <IndeterminateCheckBoxOutlinedIcon sx={{fontSize: '20px', color: '#C5C5C5'}}/>}
                    <h1 className='filter-title-unilever' style={{margin: 0, marginLeft: '20px'}}>
                      {lang === 'es' ? 'Creador de video' : 'Video Creator'}
                    </h1>
                  </div>
                  <div className="filter-category-inner" onClick={() => handleFilters('Estratega de contenidos')}>
                    {filters.includes('Estratega de contenidos') ?
                      <CheckBoxOutlinedIcon sx={{fontSize: '20px', color: '#202256'}}/>
                      :
                      <IndeterminateCheckBoxOutlinedIcon sx={{fontSize: '20px', color: '#C5C5C5'}}/>}
                    <h1 className='filter-title-unilever' style={{margin: 0, marginLeft: '20px'}}>
                      {lang === 'es' ? 'Estratega de contenidos' : 'Content Strategist'}
                    </h1>
                  </div>
                  <div className="filter-category-inner" onClick={() => handleFilters('Fotógrafo')}>
                    {filters.includes('Fotógrafo') ? <CheckBoxOutlinedIcon sx={{fontSize: '20px', color: '#202256'}}/>
                      :
                      <IndeterminateCheckBoxOutlinedIcon sx={{fontSize: '20px', color: '#C5C5C5'}}/>}
                    <h1 className='filter-title-unilever' style={{margin: 0, marginLeft: '20px'}}>
                      {lang === 'es' ? 'Fotógrafo' : 'Photographer'}
                    </h1>
                  </div>
                  <div className="filter-category-inner" onClick={() => handleFilters('Periodista digital')}>
                    {filters.includes('Periodista digital') ?
                      <CheckBoxOutlinedIcon sx={{fontSize: '20px', color: '#202256'}}/>
                      :
                      <IndeterminateCheckBoxOutlinedIcon sx={{fontSize: '20px', color: '#C5C5C5'}}/>}
                    <h1 className='filter-title-unilever' style={{margin: 0, marginLeft: '20px'}}>
                      {lang === 'es' ? 'Periodista digital' : 'Digital Journalist'}
                    </h1>
                  </div>
                </div>
              </div>
            </div>
            <div className="home-unilever-profesional-inner-2">
              <div className="home-unilever-profesional-inner-2-1-container">
                <div className="home-profesional-search-container">
                  <div className="Profesional-Home-unilever-container-top">
                    <SearchIcon className={'lupa'}/>
                    <input
                      className={'Profesional-Home-Search'}
                      style={{
                        border: 'none',
                        marginRight: 0
                      }}
                      placeholder={lang === 'es' ? 'Buscar oportunidades' : 'Search opportunities'}/>
                    <button style={{
                      backgroundColor: '#202256',
                      height: '33px',
                      width: '118px',
                      borderRadius: '5px',
                      color: '#FFFFFF',
                      textAlign: 'center',
                      marginRight: '45px',
                    }}>
                      {lang === 'es' ? 'Buscar' : 'Search'}
                    </button>
                  </div>
                  <div className="home-profesional-view-container">
                    <div
                      className={`${listView ? 'Profesional-Home-Contenedor-3-inner-2-1-active' : 'Profesional-Home-Contenedor-3-inner-2-1'}`}
                      onClick={handleListView}>
                      <FormatListBulletedIcon
                        sx={{fontSize: 24, color: `${listView === true ? '#FFFFFF' : '#C5C5C5'}`}}/>
                    </div>
                    <div
                      className={`${gridView ? 'Profesional-Home-Contenedor-3-inner-2-2-active' : 'Profesional-Home-Contenedor-3-inner-2-2'}`}
                      onClick={handleGridView}>
                      <WindowIcon sx={{fontSize: 24, color: `${gridView === true ? '#FFFFFF' : '#C5C5C5'}`}}/>
                    </div>
                  </div>
                </div>
                <div></div>
              </div>
              <div className="home-unilever-profesional-inner-2-2-container">
                {
                  listView ? (
                      <div className="home-unilever-profesional-inner-2-2-container-inner">
                        {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20].map((item, index) => (
                          <UnileverMentorCardHomeList key={index}/>
                        ))}
                      </div>
                    )
                    :
                    (
                      <div className="home-unilever-profesional-inner-2-2-container-grid">
                        {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20].map((item, index) => (
                          <UnileverMentorCardHomeGrid key={index}/>
                        ))}
                      </div>
                    )
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HomeUnileverProfessional;