import React, {useEffect} from 'react';
import FormControlLabel from '@mui/material/FormControlLabel';
import CircleIcon from '@mui/icons-material/Circle';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Typography from '@mui/material/Typography';
import './CrearOportunidadNew.css'
import {useDispatch} from "react-redux";
import { saveOportunityForm } from '../../actions';

export default function Modalidad(props) {
  const { form, handleStep, handleBackStep, step, lang, oportunityForm, handleForm} = props;
  const [button, setButton] = React.useState(true);
  const dispatch = useDispatch();
  useEffect(() => {
    if (oportunityForm?.modalidadDeContratacion ? oportunityForm?.modalidadDeContratacion : form?.modalidadDeContratacion) {
      setButton(false);
    }
    return () => {
      setButton(true);
    }
    //eslint-disable-next-line
  }, [form.modalidadDeContratacion]);

  const handleChange = (e) => {
    handleForm({...form, modalidadDeContratacion: e.target.value});
    dispatch(saveOportunityForm({modalidadDeContratacion: e.target.value}, step));
    setButton(false);
  }

  return (
    <>
      <div className='contenedorModalidad'>
        <h3 style={{
          fontFamily: 'Poppins',
          fontWeight: '700',
          fontSize: '14px',
          marginBottom: '20px',
          marginLeft: '-10px'
        }}>{lang === 'es' ? '¿Qué modalidad de contratación deseas?' : 'What type of contract would you like?'} </h3>
        <RadioGroup
          aria-labelledby="demo-controlled-radio-buttons-group"
          name="tipoDeServicioDeseado"
          value={oportunityForm?.modalidadDeContratacion ? oportunityForm?.modalidadDeContratacion : form?.modalidadDeContratacion}
        >
          <div className='modalidadRadio'>
            <div style={{display: 'flex', flexDirection: 'column'}}>
              <FormControlLabel name="modalidadDeContratacion" value="Termino indefinido (tiempo completo)"
                                control={<Radio icon={<CircleIcon className='radio_unchecked_icon'/>}
                                                checkedIcon={<CircleIcon className='radio_checked_icon'/>}/>}
                                onChange={handleChange}
                                label={<Typography sx={{fontSize: '14px', fontFamily: 'Poppins', fontWeight: '400'}}>
                                  {lang === 'es' ? 'Término indefinido (tiempo completo)' : 'Indefinite term (full time)'}
                                </Typography>}/>

              <FormControlLabel value="Termino indefinido (tiempo parcial)" name="modalidadDeContratacion"
                                onChange={handleChange}
                                control={<Radio icon={<CircleIcon className='radio_unchecked_icon'/>}
                                                checkedIcon={<CircleIcon className='radio_checked_icon'/>}/>}
                                label={<Typography sx={{fontSize: '14px', fontFamily: 'Poppins', fontWeight: '400'}}>
                                  {lang === 'es' ? 'Término indefinido (tiempo parcial)' : 'Indefinite term (part time)'}
                                </Typography>}/>

              <FormControlLabel value="Termino fijo (tiempo completo)" name="modalidadDeContratacion"
                                onChange={handleChange}
                                control={<Radio icon={<CircleIcon className='radio_unchecked_icon'/>}
                                                checkedIcon={<CircleIcon className='radio_checked_icon'/>}/>}
                                label={<Typography sx={{fontSize: '14px', fontFamily: 'Poppins', fontWeight: '400'}}>
                                  {lang === 'es' ? 'Término fijo (tiempo completo)' : 'Fixed term (full time)'}
                                </Typography>}/>

              <FormControlLabel name="modalidadDeContratacion" value="Termino fijo (tiempo parcial)"
                                control={<Radio icon={<CircleIcon className='radio_unchecked_icon'/>}
                                                checkedIcon={<CircleIcon className='radio_checked_icon'/>}/>}
                                onChange={handleChange}
                                label={<Typography sx={{fontSize: '14px', fontFamily: 'Poppins', fontWeight: '400'}}>
                                  {lang === 'es' ? 'Término fijo (tiempo parcial)' : 'Fixed term (part time)'}
                                </Typography>}/>
            </div>
            <div style={{display: 'flex', flexDirection: 'column'}}>
              <FormControlLabel value="Proyecto" name="modalidadDeContratacion" onChange={handleChange}
                                control={<Radio icon={<CircleIcon className='radio_unchecked_icon'/>}
                                                checkedIcon={<CircleIcon className='radio_checked_icon'/>}/>}
                                label={<Typography sx={{fontSize: '14px', fontFamily: 'Poppins', fontWeight: '400'}}>
                                  {lang === 'es' ? 'Proyecto' : 'Project'}
                                </Typography>}/>

              <FormControlLabel value="Contratista prestación de servicios" name="modalidadDeContratacion"
                                onChange={handleChange}
                                control={<Radio icon={<CircleIcon className='radio_unchecked_icon'/>}
                                                checkedIcon={<CircleIcon className='radio_checked_icon'/>}/>}
                                label={<Typography sx={{fontSize: '14px', fontFamily: 'Poppins', fontWeight: '400'}}>
                                  {lang === 'es' ? 'Prestación de servicios' : 'Contractor provision of services'}
                                </Typography>}/>

              <FormControlLabel value="Contrato por hora o labor" name="modalidadDeContratacion" onChange={handleChange}
                                control={<Radio icon={<CircleIcon className='radio_unchecked_icon'/>}
                                                checkedIcon={<CircleIcon className='radio_checked_icon'/>}/>}
                                label={<Typography sx={{fontSize: '14px', fontFamily: 'Poppins', fontWeight: '400'}}>
                                  {lang === 'es' ? 'Contrato por hora o labor' : 'Contract by hour or labor'}
                                </Typography>}/>
            </div>
          </div>
        </RadioGroup>
      </div>
      <div
        style={{
          display: "flex",
          width: "100%",
          justifyContent: "space-between",
          padding: "1rem 3rem 0rem 3rem",
          alignItems: "center",
          // marginBottom: '-50px'
        }}
      >
        <button className='button_siguiente_create_B'
                onClick={handleBackStep}>{lang === 'es' ? 'Anterior' : 'Previous'}</button>
        <h3 className='stepCounter'>{`${step}/14`}</h3>
        <button className='button_siguiente_create'
                onClick={() => handleStep('modalidadDeContratacion')}
                disabled={button}>{lang === 'es' ? 'Siguiente' : 'Next'}</button>
      </div>
    </>
  )
}
