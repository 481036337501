/* eslint-disable */
import React, { useState, useEffect } from "react";
import SearchIcon from "@mui/icons-material/Search";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import proyectoimagen from "../oportunidad/proyectos.webp";
import {
  getPostedProjects,
  ConsultorData,
  loginReq,
  tourStatus,
  tourViewedStatus,
} from "../../actions";
import Navbar from "../../components/Navbar/Navbar";
import "./homeCardv1.css";
import Loader from "../Loader/LoaderHome";
import * as PropTypes from "prop-types";
import axios from "axios";
import counterpart from "counterpart";
import en from "../../lang/en-US";
import es from "../../lang/es-COL";
import "./HomeprofesionalV2.css";
import NewCardOportunidadGrid from "./CardOportunidadProfesional/NewCardOportunidadGrid";
import NewCardOportunidadList from "./CardOportunidadProfesional/NewCardOportunidadList";
import { Dialog } from "@mui/material";
import Chip from "@mui/material/Chip";
import CloseIcon from "@mui/icons-material/Close";
import { styled } from "@mui/material/styles";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import WindowIcon from "@mui/icons-material/Window";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import Sidebar from "../../components/Sidebar/Sidebar";
import UseTour from "./useTour";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import HomeCarousel from "./HomeCarousel";
import EastOutlinedIcon from "@mui/icons-material/EastOutlined";
import AliadosSlider from "../../components/AliadosSlider/AliadosSlider";
import TestsInfo from "./TestCard/TestsInfo";

function ExploreOutlinedIcon() {
  return null;
}

ExploreOutlinedIcon.propTypes = { color: PropTypes.string };

function ListOutlinedIcon() {
  return null;
}

ListOutlinedIcon.propTypes = { color: PropTypes.string };
const HomeprofesionalV2 = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  /*const [mounted, setMounted] = useState(false);*/
  let { cv, user, projects, tourViewed } = useSelector((state) => state);
  const [filtro, setfiltro] = useState("");
  const [projectosFiltrados, setProjectosFiltrados] = useState("");
  const [proyectosMl, setProyectosMl] = useState();
  const [loading, setLoading] = useState(true);
  /* const [popUp, setPopUp] = useState(false);
  const [cvError, setCvError] = useState(false);*/
  const { lang } = useSelector((state) => state);
  //eslint-disable-next-line
  /*  const [popupPersonalidad, setPopUpPersonalidad] = useState(false);*/
  const [isOpen, setIsOpen] = useState(false);
  /*  const [resultadoTest, setResultadoTest] = useState("");
  const [isOpenTestPersonalidad, setOpenTestPersonalidad] = useState(false);
  const [openTestPersonalidadResultado, setOpenTestPersonalidadResultado] =
    useState(false);*/
  /*const handleClosePersonalidad = () => setOpenTestPersonalidad(false);
  const handleOpenPersonalidadResultado = (result) => {
    setOpenTestPersonalidad(false);
    setResultadoTest(result);
    setOpenTestPersonalidadResultado(true);
  };*/
  /*const handleCloseResultado = () => setOpenTestPersonalidadResultado(false);*/
  /*useEffect(() => {
    setTimeout(() => {
      setMounted(true);
    }, 1000);
  }, []);*/
  const [orderProyectos, setOrderProyectos] = useState("grid");
  /*const [showMore, setShowMore] = useState(false);*/
  const [width, setWidth] = useState(window.innerWidth);
  const [height, setHeight] = useState(window.innerHeight);
  const updateWidthAndHeight = () => {
    setWidth(window.innerWidth);
    setHeight(window.innerHeight);
  };

  useEffect(() => {
    window.addEventListener("resize", updateWidthAndHeight);
    if (width < 650) {
      setOrderProyectos("grid");
    }
    return () => window.removeEventListener("resize", updateWidthAndHeight);
  }, [width, height]);

  /*  const mandarpersonalizad = (e) => {
    e.preventDefault();
    let nuevadata = user;
    nuevadata.calificaion_aptitudes = resultadoTest;
    axios
      .put("https://findally-backend.herokuapp.com/update/user/" + user.id, {
        calificaion_aptitudes: resultadoTest,
      })
      .then((r) => {
        localStorage.setItem("user", JSON.stringify(nuevadata));
        dispatch(loginReq(nuevadata));
        setPopUpPersonalidad(false);
      });
  };*/

  //pagination
  const [page, setPage] = useState({
    currentPage: 0,
    totalPage: 0,
    totalElements: 0,
  });
  const cancel = new AbortController();
  useEffect(() => {
    if (!user.id) {
      return history.push("/");
    }
    axios
      .get(
        `https://findally-backend.herokuapp.com/api/Project?limit=&status=En+espera`
      )
      .then((r) => {
        setPage({
          currentPage: r.data.page,
          totalPage: r.data.TotalPage,
          totalElements: r.data.total,
        });
      })
      .catch((r) => {
        if (r.name === "AbortError") return console.log("fetch aborted");
      });
    return () => {
      cancel.abort();
    };
    //eslint-disable-next-line
  }, [page.totalPage]);

  /*  const handlePageClick = (e) => {
    page.currentPage = e.selected;
    setPage({
      ...page,
      currentPage: page.currentPage,
    });
  };*/

  const arrayfiltrado = async () => {
    const h = await projects.filter((e) => e.status === "En espera");
    setProjectosFiltrados(h.reverse());
    setLoading(false);
  };

  const redirect = () => {
    if (!user.id) {
      return history.push("/");
    }
    history.push("/company/home");
  };

  useEffect(() => {
    if (!user.id) {
      return history.push("/");
    }
    const getData = () => {
      dispatch(ConsultorData(user.id));
    };
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // eslint-disable-next-line
  useEffect(() => {
    dispatch(getPostedProjects(page.currentPage));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  useEffect(() => {
    if (projects && !cv && !proyectosMl) {
      arrayfiltrado();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projects]);

  /*  const [selectedFile, setSelectedFile] = useState();
  const [isFilePicked, setIsFilePicked] = useState(false);*/

  /* const changeHandler = (e) => {
    setCvError(false);
    setSelectedFile(e.target.files[0]);
    const fileData = new FormData();
    fileData.append("upload_file", e.target.files[0]);
    fileData.append("usuario", user.id);
    axios
      .post(
        `https://findally-backend.herokuapp.com/api/user/validateCV?lang=${lang}`,
        fileData
      )
      .then((res) => {
        if (res.status === 200) {
          toast.success(res.data.message, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          return setIsFilePicked(true);
        }
      })
      .catch((err) => {
        if (err.response.status === 400) {
          setSelectedFile(null);
          return setCvError(true);
        }
      });
  };*/

  //eslint-disable-next-line
  /*  const Orden = (e) => {
    e.preventDefault();
    if (e.target.value === "asc") {
      const h = projects.slice().sort((a, b) => a.presupuesto - b.presupuesto);
      return setProjectosFiltrados(h.reverse());
    }
    if (e.target.value === "desc") {
      const h = projects.slice().sort((a, b) => a.presupuesto - b.presupuesto);
      return setProjectosFiltrados(h);
    }
    if (e.target.value === "def") {
      return setProjectosFiltrados(projects);
    }
  };*/

  if (!localStorage.getItem("langlocal")) {
    localStorage.setItem("langlocal", "es");
  }

  /* const changePopUp = (e) => {
    e.preventDefault();
    return setPopUp(!popUp);
  };*/

  counterpart.registerTranslations("en", en);
  counterpart.registerTranslations("es", es);
  counterpart.setLocale(localStorage.getItem("langlocal"));
  /*

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
  };
  //popup de test
  const style1 = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: width > 600 ? 772 : "90%",
    height: width > 600 ? 721 : "80vh",
    bgcolor: "#FFFFFF",
    border: "2px solid #000",
    borderRadius: "8px",
    boxShadow: 24,
    overflow: "auto",
  };

  //popup resultado
  const styles2 = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: width > 600 ? 772 : "90%",
    height: width > 600 ? 603 : "80vh",
    bgcolor: "#FFFFFF",
    border: "2px solid #000",
    borderRadius: "8px",
    boxShadow: 24,
    overflow: "auto",
  };
*/

  //filter

  const handleOnClick = (e) => {
    axios
      .post(`https://findally-backend.herokuapp.com/api/Project/text`, {
        text: filtro,
      })
      .then((res) => {
        setProjectosFiltrados(res.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      axios
        .post(`https://findally-backend.herokuapp.com/api/Project/text`, {
          text: filtro,
        })
        .then((res) => {
          setProjectosFiltrados(res.data);
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const infoExperiencia = cv?.experiencia?.map((e) => {
    // return {
    //   nombre_cargo: e.cargos[0].nombrecargo,
    //   duracion: e.cargos[0].duracion ? e.cargos[0].duracion : '',
    //   ubicacion: e.cargos[0].ubicacion,
    //   descripcion: e.cargos[0].descripcion
    // }
    return {
      nombre_cargo: "",
      duracion: "",
      ubicacion: "",
      descripcion: "",
    };
  });

  useEffect(() => {
    if (Cv) {
      axios
        .post(
          "https://resume-matching-deploy.herokuapp.com/n-mejores-empleos",
          {
            n: 4,
            ID: cv._id,
            Descripcion: cv?.extracto || "",
            Experiencia: infoExperiencia || [
              {
                nombre_cargo: "",
                duracion: "",
                ubicacion: "",
                descripcion: "",
              },
            ],
            Estudios: cv.educacion[0]?.Titulo_Certificacion || "",
            Habilidades_tecnicas: "",
            Habilidades_Blandas:
              cv?.aptitudes_principales
                ?.map((habilidad) => habilidad)
                .join(", ") || "",
            Logros: "",
            Trabajo_Social: "",
            Universidad: "",
            Idiomas:
              cv?.languages?.map((idioma) => idioma.Language).join(", ") || "",
            Salario: cv?.aspiracion_salarial || "",
            Tipo_de_empleo: "",
            Modalidad: "",
          }
        )
        .then((res) => {
          const { response } = res.data;
          const resFormat = JSON.parse(response)
          console.log(resFormat, 'holis')
          const result = {};
          if (Object.keys(resFormat).length === 0 && projects) {
            arrayfiltrado();
          } else {
            for (const key in resFormat) {
              const value = resFormat[key];
              if (typeof value === "object" && value !== null) {
                const fitKey = "fit" + key;
                if (fitKey in resFormat) {
                  value.fit = resFormat[fitKey];
                }
                result[key] = value;
              }
            }
            setProyectosMl(result);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [Cv, projects]);

  const proyectos =
    proyectosMl && Object.values(proyectosMl).map((proyecto) => proyecto.ID);

  useEffect(() => {
    if (proyectos) {
      Promise.all(
        proyectos.map((id) =>
          axios.get(`https://findally-backend.herokuapp.com/api/Project/${id}`)
        )
      )
        .then((res) => {
          //sumar el fit a cada proyecto
          const proyectosConFit = res.map((proyecto, index) => {
            const indexProyecto = index + 1;
            return {
              ...proyecto.data,
              fit: proyectosMl[indexProyecto]?.fit,
            };
          });
          setProjectosFiltrados(proyectosConFit);
          // setLoading(false)
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [proyectosMl, filtro]);

  // useEffect(() => {
  //   if (!filtro) {
  //     setProjectosFiltrados(projects);
  //   }
  // }, [filtro])

  /*  function ChipsArray() {
    const handleDelete = () => {
      setfiltro("");
    };
    return (
      <Chip
        sx={{
          background: "#E5DCF7",
          borderRadius: "5px",
          marginLeft: "20px",
          fontWeight: 400,
          fontSize: "14px",
          lineHeight: "20px",
          color: "#202256",
          flexDirection: "row-reverse",
          padding: "10px",
        }}
        label={filtro}
        deleteIcon={<CloseIcon style={{ margin: "0px", color: "#202256" }} />}
        onDelete={handleDelete}
      />
    );
  }*/

  //progress bar

  /*  const BorderLinearProgress = styled(LinearProgress)(() => ({
    height: 14,
    borderRadius: 5,
    width: 640,
    marginRight: "1em",
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: "#C5C5C5",
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: "#202256",
    },
  }));*/

  // Popups
  /*  const [open3, setOpen3] = useState(false);
  const [open2, setOpen2] = useState(false);
  const handleClose = () => setOpen2(false);
  const handleClose2 = () => setOpen3(false);
  const handleOpen = () => setOpen2(true);
  const handleOpen2 = () => setOpen3(true);*/

  /*  const stylePopUp = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "721px",
    height: "478px",
    bgcolor: "#FFFFFF",
    borderRadius: "8px",
  };*/

  /*  const [progreso, setProgreso] = useState(0); //progreso para la barra
  const [faltante, setFaltante] = useState(); // faltantes para resaltar debajo de la barra*/
  const [perfil, setPerfil] = useState(); //copia depurada de los elementos de un cv

  //con este uEffect se actualiza y copia el perfil
  /*  useEffect(() => {
    setPerfil({
      Aptitudes: cv?.aptitudes_principales,
      Certificaciones: cv?.certifications,
      Contacto: cv?.contacto,
      Educación: cv?.educacion,
      Experiencia: cv?.experiencia,
      Estracto: cv?.extracto,
      Lenguajes: cv?.languages,
      Info: cv?.info_personal,
    });
  }, [cv]);

  //con este uEffect se actualiza el progreso de la barra
  useEffect(() => {
    if (perfil) {
      let contador = 0;
      let faltantes = [];
      for (const key in perfil) {
        if (perfil[key]) {
          contador = contador + 12.5;
        } else {
          faltantes.push(key);
        }
      }
      setProgreso(contador);
      setFaltante(faltantes);
    }
  }, [perfil]);*/

  useEffect(() => {
    if (tourViewed === true) {
      return;
    }
    setTimeout(() => {
      dispatch(tourStatus({ run: true }));
    }, 1500);
  }, []);
  //este translator es para los campos de la barra de progreso!
  /* const translator = (word) => {
    if (lang === "es") {
      return word;
    } else {
      switch (word) {
        case "Aptitudes":
          return "Skills";
        case "Certificaciones":
          return "Certifications";
        case "Contacto":
          return "Contact";
        case "Educación":
          return "Education";
        case "Experiencia":
          return "Experience";
        case "Estracto":
          return "Extract";
        case "Lenguajes":
          return "Languages";
        case "Info":
          return "Info";
      }
    }
  };*/

  const handleTour = () => {
    localStorage.setItem("tourViewed", false);
    dispatch(tourViewedStatus(false));
    dispatch(tourStatus({ run: true }));
  };

  return (
    <div className={"contenedor-oportunidad"}>
      {user && user.roles === "coallier" ? (
        <div>
          <div className={"contenedor-tour"}>
            <UseTour />
          </div>
          <Sidebar location={"home"} />
          <div
            className="BodyWeb"
            style={{
              padding: "0rem 2rem",
              position: "absolute",
              right: "0",
            }}
          >
            <Navbar locate={"home"} />
            <div className="Profesional-Home-Contenedor-Global" id="home">
              <div className="new-empresa-home-banner2">
                <div className="new-empresa-home-banner-text">
                  <h4
                    style={{
                      fontFamily: "Poppins",
                      fontWeight: "400",
                      fontSize: "20px",
                      color: "#FFF",
                    }}
                  >
                    {lang === "es"
                      ? "Inteligencia artificial de"
                      : "artificial intelligence of"}
                  </h4>
                  <h1
                    style={{
                      fontFamily: "Roboto",
                      fontWeight: "700",
                      fontSize: "36px",
                      color: "#FFF",
                    }}
                  >
                    {lang === "es" ? "ChatGPT" : "ChatGPT"}
                  </h1>
                </div>

                <div className="new-empresa-home-banner-button2" id="pricing1">
                  <button
                    onClick={() => setIsOpen(true)}
                    style={{ color: "#FFF", background: "none" }}
                  >
                    {lang === "es" ? "Ver detalles" : "See details"}
                  </button>
                  <Dialog open={isOpen}>
                    <div style={{ padding: "20px" }}>
                      <button
                        onClick={() => setIsOpen(false)}
                        style={{
                          color: "#202256",
                          background: "none",
                          borderRadius: "5px",
                          border: "solid 1px #202256",
                          height: "20px",
                          width: "20px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        X
                      </button>
                      <h3
                        style={{
                          fontWeight: "700",
                          fontSize: "24px",
                          color: " #393939",
                          marginLeft: "25px",
                        }}
                      >
                        {lang === "es"
                          ? "¿En qué consiste ChatGPT?"
                          : "What is ChatGPT about?"}
                      </h3>
                      <hr />
                      <p
                        style={{
                          fontWeight: "400",
                          fontSize: "14px",
                          color: "#393939",
                        }}
                      >
                        {lang === "es"
                          ? "Coally integra la Inteligencia Artificial de ChatGPT para que tú puedas recibir indicaciones de dónde debes trabajar y en qué debes estudiar para cumplir tus objetivos."
                          : "Coally integrates the Artificial Intelligence of ChatGPT so that you can receive indications of where you should work and what you should study to achieve your goals."}
                      </p>
                      <Link to="/consultor/career/ia">
                        <button
                          style={{
                            color: "#FFF",
                            background: "#202256",
                            height: "35px",
                            borderRadius: "5px",
                          }}
                        >
                          {lang === "es" ? "Iniciar Chat" : "Start Chat"}
                        </button>
                      </Link>
                    </div>
                  </Dialog>
                </div>
              </div>

              <div className="Profesional-Home-container-inner">
                <div className="Profesional-Home-Contenedor-1-new">
                  <h1
                    style={{
                      fontSize: "20px",
                      fontWeight: "400",
                      color: "#393939",
                      textAlign: "center",
                    }}
                  >
                    {lang === "es"
                      ? "Es momento de buscar"
                      : "It´s time to look for"}
                  </h1>
                </div>

                <div className="Profesional-Home-Contenedor-2-new">
                  <h1
                    style={{
                      color: "#393939",
                      fontSize: "25px",
                      fontWeight: "700",
                      textAlign: "left",
                    }}
                  >
                    {lang === "es"
                      ? "nuevas oportunidades laborales"
                      : "new job opportunities"}
                  </h1>
                </div>
                <div className="Profesional-Home-Contenedor-3-new">
                  <div className="Profesional-Home-Contenedor-3-inner-1">
                    <div style={{ width: "100%" }}>
                      <SearchIcon className={"lupa"} />
                      <input
                        className={"Profesional-Home-Search"}
                        style={{
                          border: "none",
                          marginRight: 0,
                        }}
                        placeholder={
                          lang === "es"
                            ? "Buscar oportunidades"
                            : "Search opportunities"
                        }
                        onChange={(e) => setfiltro(e.target.value)}
                        onKeyPress={handleKeyPress}
                      />
                    </div>
                    <button
                      style={{
                        backgroundColor: "#202256",
                        height: "33px",
                        width: "118px",
                        borderRadius: "5px",
                        color: "#FFFFFF",
                        textAlign: "center",
                      }}
                      onClick={handleOnClick}
                    >
                      {lang === "es" ? "Buscar" : "Search"}
                    </button>
                  </div>
                  {width >= 650 ? (
                    <div className="Profesional-Home-Contenedor-3-inner-2">
                      <div
                        className={`${
                          orderProyectos === "list"
                            ? "Profesional-Home-Contenedor-3-inner-2-1-active"
                            : "Profesional-Home-Contenedor-3-inner-2-1"
                        }`}
                        onClick={() => {
                          setOrderProyectos("list");
                        }}
                      >
                        <FormatListBulletedIcon
                          sx={{
                            fontSize: 24,
                            color: `${
                              orderProyectos === "list" ? "#FFFFFF" : "#C5C5C5"
                            }`,
                          }}
                        />
                      </div>
                      <div
                        className={`${
                          orderProyectos === "grid"
                            ? "Profesional-Home-Contenedor-3-inner-2-2-active"
                            : "Profesional-Home-Contenedor-3-inner-2-2"
                        }`}
                        onClick={() => {
                          setOrderProyectos("grid");
                        }}
                      >
                        <WindowIcon
                          sx={{
                            fontSize: 24,
                            color: `${
                              orderProyectos === "grid" ? "#FFFFFF" : "#C5C5C5"
                            }`,
                          }}
                        />
                      </div>
                    </div>
                  ) : null}
                </div>

                <div
                  className={
                    orderProyectos === "list"
                      ? "Profesional-Home-Contenedor-4-new"
                      : "Profesional-Home-Contenedor-4-new-grid"
                  }
                >
                  {!projectosFiltrados?.length && (
                    <div>
                      <Loader
                        render={loading}
                        array={projectosFiltrados}
                        altura={15}
                      />
                    </div>
                  )}
                  {orderProyectos === "grid" &&
                    projectosFiltrados &&
                    projectosFiltrados.slice(0, 4).map((p, index) => {
                      return (
                        <NewCardOportunidadGrid
                          key={index}
                          descripcion={
                            p.empleos_alternativos[0]
                              ? p.empleos_alternativos[0]
                              : ""
                          }
                          empresa={p.NombreEmpresa}
                          titulo={p.NombreOportunidad}
                          titulo2={p.nombre}
                          salario={p.presupuesto}
                          dir={p.link}
                          id={p._id}
                          lenguaje={lang}
                          imagen={p.imageUrl ? p.imageUrl : proyectoimagen}
                          Anonimo={p.Anonimo ? p.Anonimo : null}
                          ciudad={p.municipio ? p.municipio : null}
                          order={orderProyectos}
                          profesionales={p.profesionales}
                          status={p.status}
                          modalidadDeTrabajo={p.modalidadDeTrabajo}
                          porcentaje={p.fit}
                        />
                      );
                    })}
                  {orderProyectos === "list" &&
                    projectosFiltrados &&
                    projectosFiltrados.slice(0, 4).map((p, index) => {
                      return (
                        <NewCardOportunidadList
                          key={index}
                          width={width}
                          empresa={p.NombreEmpresa}
                          titulo={p.NombreOportunidad}
                          titulo2={p.nombre}
                          salario={p.presupuesto}
                          dir={p.link}
                          id={p._id}
                          lenguaje={lang}
                          imagen={p.imageUrl ? p.imageUrl : proyectoimagen}
                          Anonimo={p.Anonimo ? p.Anonimo : null}
                          ciudad={p.ciudad ? p.ciudad : null}
                          fecha={p.fecha_inicio.slice(0, 10)}
                          order={orderProyectos}
                          profesionales={p.profesionales}
                          descripcion={
                            p.empleos_alternativos[0]
                              ? p.empleos_alternativos[0]
                              : ""
                          }
                          porcentaje={p.fit}
                        />
                      );
                    })}
                </div>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    textAlign: "center",
                    marginTop: "50px",
                  }}
                >
                  <Link
                    to={"/consultor/projects"}
                    style={{
                      border: "none",
                      backgroundColor: "transparent",
                      color: "#5D5D5D",
                      fontSize: "16px",
                      fontWeight: "400",
                    }}
                  >
                    {lang === "es" ? "Ver más " : "See more"}
                  </Link>
                </div>
                <div
                  style={{ width: "100%", height: "100%", marginTop: "50px" }}
                >
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "flex-start",
                      height: "100%",
                    }}
                  >
                    <h2
                      className={"Profesional-Home-SubTitle-2"}
                      style={{ margin: 0 }}
                    >
                      Tests
                    </h2>
                    {/* <div style={{
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'flex-end',
                  justifyContent: 'flex-end',
                  marginLeft: '30px'
                }}>
                <Whisper placement="right" trigger="hover" controlId="control-id-hover" speaker={<Popover title={`${lang === 'es' ? '¡Importante!' : 'Important!'}`}>
        <p>{lang === 'es' ? 'Con esta información serán más efectivas las oportunidades que tenemos para ofrecerte y serás priorizado en las ofertas de elección.' : 'With this information, the opportunities we have to offer you will be more effective and you will be prioritized in the offers of your choice.'}</p>
      </Popover>}>
                  <WarningAmberOutlinedIcon sx={{ fontSize: 24, color: '#B0B0B0', marginLeft: '10px', borderRadius: '5px' }} />
                </Whisper>
                </div> */}
                  </div>
                  <div className={"Home-Profesional-Test-Container"}>
                    <TestsInfo lang={lang} width={width} />
                  </div>
                </div>

                <div className="new-home-profesiona-bottom-container">
                  <div className="new-home-profesional-bottom-container-carousel">
                    <div className="new-home-profesional-bottom-container-carousel-title">
                      <h2
                        style={{
                          fontSize: "24px",
                          fontWeight: "700",
                          color: "#393939",
                        }}
                      >
                        {lang === "es"
                          ? "¡Fórmate con nosotros!"
                          : "Train with us!"}
                      </h2>
                    </div>
                    <div className="new-home-profesional-bottom-container-carousel-container">
                      <HomeCarousel lang={lang} />
                    </div>
                  </div>
                  <div className="new-home-profesional-container-tour">
                    <h1
                      style={{
                        fontSize: "30px",
                        fontWeight: "700",
                        fontFamily: "Roboto",
                        color: "#202256",
                      }}
                    >
                      {lang === "es"
                        ? "¿Aún no sabes cómo se usa la plataforma?"
                        : "Don't you know how to use the platform yet?"}
                    </h1>
                    <button
                      onClick={() => handleTour()}
                      className="tour-button"
                    >
                      {lang === "es" ? "¡Haz el tour!" : "Take the tour!"}
                      <EastOutlinedIcon style={{ marginLeft: "10px" }} />
                    </button>
                    <div className="tour-circle"></div>
                  </div>
                </div>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    overflow: "auto",
                  }}
                >
                  <h2
                    className={"Profesional-Home-SubTitle-2"}
                    style={{ margin: 0 }}
                  >
                    Aliados
                  </h2>
                  <div>
                    <AliadosSlider />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        redirect()
      )}
    </div>
  );
};
export default HomeprofesionalV2;
