//@ts-nocheck
/* eslint-disable */
import axios from "axios";
import React, { useEffect, useState, useContext } from "react";
import { useParams, useHistory, Link } from "react-router-dom";
import proyectoimagen from "../../../../../profesionalpag/detalleoportunidad/proyectos.webp";
import { connect } from "react-redux";
import "../../../../../profesionalpag/detalleoportunidad/ConsultorForm.css";
import {
  ConsultorProjects,
  loginUser,
  loginReq,
  loginSocial,
  ProjectApply,
  getUserInfoWithoutReload,
} from "../../../../../actions";
import en from "../../../../../lang/en-US";
import es from "../../../../../lang/es-COL";
import counterpart from "counterpart";
import Popup from "../../../../../profesionalpag/detalleoportunidad/popUp/Modal.js";
import Translate from "react-translate-component";
import Button from "@mui/material/Button";
import { useDispatch, useSelector } from "react-redux";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import {
  getPostedProjects,
  ConsultorData,
  getUserProjects,
  tourStatus,
} from "../../../../../actions";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Spinner from "react-bootstrap/Spinner";
// import Headertop from "../headertop/Headertop";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import CardOportunidadProfesional from "../../../../../profesionalpag/home/CardOportunidadProfesional/CardOportuinidadProfesional";
import Sidebar from "../../../../components/sidebar/Sidebar";
import TourProfessional from "../../../../components/tour/TourProfessional";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Navbar from "../../../../components/navbar/Navbar";
import upload from "../../../../../assets/icons/Upload.svg";
import file from "../../../../../assets/icons/file.svg";
import { ApplyButton } from "../../../../components/buttons/ButtonStyles";
import { SchneiderContext } from "../../../../context/SchneiderContext";
import { CustomizableTitle } from "../../../../styles/TitleStyles";
import { CircularProgress } from "@mui/material";

function Opportunity(props) {
  const { schneider } = useContext(SchneiderContext);
  const dispatch = useDispatch();
  const history = useHistory();
  const { id } = useParams();
  const [motivate, setMotivate] = useState(false);
  const [stepToUploadCV, setStepToUploadCV] = useState(false);
  const [cargando, setcargando] = useState(false);
  const [aplicado, setaplicado] = useState();
  const [aplicable, setaplicable] = useState(false);
  const [readyFilterForm, setReadyFilterForm] = useState(false);
  const projectsUser = useSelector((state) => state.userProjects);
  const { lang, tourViewed } = useSelector((state) => state);
  const { user, projects } = useSelector((state) => state);
  const cancel = new AbortController();

  //TODO: later, when backend is ready, change this to false
  const [nextStep, setNextStep] = useState(true);

  useEffect(() => {
    axios
      .get(`https://findally-backend.herokuapp.com/api/filterForm/${user.id}`)
      .then((r) => {
        if (r.data.data !== null) {
          setNextStep(false);
          return setReadyFilterForm(true);
        }
        return setReadyFilterForm(true);
      })
      .catch((e) => {
        console.log(e);
      });
  }, [user]);

  const [form, setValues] = useState({
    role: [],
    email: "",
    password: "",
    url: "no",
  });
  const handleInput = (event) => {
    setValues({
      ...form,
      [event.target.name]: event.target.value,
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setcargando(true);
    if (form.email !== "" && form.password !== "") {
      props.loginUser(form, "/consultor/home");
      axios
        .post(
          "https://backend-production-1bc8.up.railway.app/api/auth/signin",
          form.email.includes("@")
            ? form
            : {
              username: form.email,
              password: form.password,
            }
        )
        .catch((r) => {
          toast.error(
            "El Correo/Usuario no esta registrado o la contraseña no coincide",
            {
              position: "top-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            }
          );
          setcargando(false);
        });
    }
  };

  // useEffect(() => {
  //   setaplicado(false)
  //   return () => {
  //   }
  // }, [id])
  useEffect(() => {
    if (props.user.id) {
      setlogin(false);
      axios
        .get("https://findally-backend.herokuapp.com/user/" + props.user.id, {
          signal: cancel.signal,
        })
        .then((respuesta) => {
          if (respuesta.data.cv_file) {
            setaplicable(true);
          }
        })
        .catch((r) => {
          if (r.name === "AbortError") return console.log("fetch aborted");
        });
    }
    if (!props.user.id) {
      setlogin(true);
    }
    handleClose1();
    handleClose();
    handleClose2();
    return () => {
      cancel.abort();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.user.id]);

  useEffect(() => {
    // eslint-disable-next-line
    dispatch(getUserProjects(user.id));
    return () => { };
    // eslint-disable-next-line
  }, [id]);
  useEffect(() => {
    if (Object.entries(user).length === 0) {
      setaplicado(false);
    }
    axios
      .get(
        `https://findally-backend.herokuapp.com/api/user/projects/${user.id}`
      )
      .then((respuesta) => {
        if (respuesta.data.projects.length >= 0) {
          let a = respuesta.data.projects.filter((p) => p._id === id);
          if (a.length > 0) {
            setaplicado(true);
          } else {
            setaplicado(false);
          }
        }
      });
    return () => { };
    // eslint-disable-next-line
  }, [projectsUser.length, id]);
  //register

  const [checked2, setChecked2] = useState(true);
  const [form2, setValues2] = useState({
    username: "",
    email: "",
    status: "",
    password: "",
    roles: ["coallier"],
  });
  const handleCheck2 = (event) => {
    setChecked2({
      [event.target.name]: event.target.checked,
    });
  };
  const handleInput2 = (event) => {
    setValues2({
      ...form2,
      [event.target.name]: event.target.value,
    });
  };
  const handleSubmit2 = (event) => {
    event.preventDefault();
    axios
      .post("https://findally-backend.herokuapp.com/api/auth/signup", form2)
      .then((res) => {
        handleClose2();
      });
  };

  //codigo

  const [buttonPopup, setButtonPopup] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [open1, setOpen1] = React.useState(false);
  const [open2, setOpen2] = React.useState(false);
  const [open3, setOpen3] = React.useState(false);
  const [open4, setOpen4] = React.useState(false);
  const [loadingCvPersonal, setLoadingCvPersonal] = useState(false);
  const [selectedFileCvGeneral, setSelectedFileCvGeneral] = useState(null);
  const [isFilePickedCvGeneral, setIsFilePickedCvGeneral] = useState(false);
  const [scroll, setScroll] = React.useState("paper");

  const handleClickOpen = (scrollType) => () => {
    setOpen(true);
    setScroll(scrollType);
  };
  const handleClickOpen1 = (scrollType) => () => {
    setOpen1(true);
    setScroll(scrollType);
  };
  const handleClickOpen2 = (scrollType) => () => {
    setOpen2(true);
    setScroll(scrollType);
  };
  const handleClickOpen3 = (scrollType) => () => {
    setOpen3(true);
    setScroll(scrollType);
  };
  const handleClickOpen4 = (scrollType) => () => {
    setOpen4(true);
    setScroll(scrollType);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleClose1 = () => {
    setOpen1(false);
  };
  const handleClose3 = () => {
    setOpen3(false);
  };

  const handleClose2 = () => {
    setOpen2(false);
  };
  const handleClose4 = () => {
    setOpen4(false);
  };
  const [proyecto, setproyecto] = useState("");
  useEffect(() => {
    axios
      .get("https://findally-backend.herokuapp.com/api/Project/" + id, {
        signal: cancel.signal,
      })
      .then((r) => {

        setproyecto(r.data);
        window.scrollTo(0, 0);
      })
      .catch((r) => {
        if (r.name === "AbortError") return console.log("fetch aborted");
      });
    return () => {
      cancel.abort();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);
  const descriptionElementRef = React.useRef(null);

  const [login, setlogin] = useState(false);
  counterpart.registerTranslations("en", en);
  counterpart.registerTranslations("es", es);
  counterpart.setLocale(localStorage.getItem("langlocal"));

  const placeholderCorreo = counterpart.translate("Ingresamail");
  const placeholderPass = counterpart.translate("contraseña");
  const placeholderNombre = counterpart.translate("nombre");
  const [
    descripcionform,
    // , setdescripcionform
  ] = useState("");
  const [pasionform, setpasionform] = useState("");
  const [fileform, setfileform] = useState("");
  const [fileform2, setfileform2] = useState(false);

  const insertarArchivo = async (e) => {
    setfileform(e.target.files[0]);
    vercv(e);
  };
  const vercv = (e) => {
    const f3 = new FormData();
    f3.append("upload_file", e.target.files[0]);
    f3.append("usuario", props.user.id);
    axios
      .post(
        `https://findally-backend.herokuapp.com/api/user/validateCV?lang=${lang}`,
        f3
      )
      .then((respuesta) => {
        setfileform2(true);
      })
      .catch((g) => {
        setfileform2(false);
        toast.error("CV invalido", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  };
  const enviarform = () => {
    const f2 = new FormData();
    f2.append("upload_file", fileform);
    f2.append("pasion", pasionform);
    f2.append("descripcion", descripcionform);
    f2.append("usuario", props.user.id);
    axios
      .post("https://findally-backend.herokuapp.com/api/user/readCV", f2)
      .then((respuesta) => {
        setaplicable(true);
        handleClose3();
        toast.success("CV enviado", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        window.location.reload();
      });
  };
  const [checked, setChecked] = useState(false);
  const [projectfiltrados, setprojectfiltrados] = useState("");

  const aplicarOportunidad = async () => {
    if (pasionform === "") {
      return lang === "es"
        ? toast.error("Por favor ingresa tu motivación", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        })
        : toast.error("Please enter your motivation", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
    }

    const data = {
      usuario: user.id,
      proyecto: id,
      pasion: pasionform,
      form: true,
    };

    sendFormularioBeforeMotivation(data);

    // toast.promise(axios.post('https://findally-backend.herokuapp.com/api/motivation/gpt', {
    //   motivacion: pasionform
    // }), {
    //   pending: "Estamos calculando tu motivación...",
    //   success: "Motivación generada correctamente",
    //   error: "Error al generar motivación"
    // }).then(() =>{
    //     sendFormularioBeforeMotivation(data)
    // })
  };
  const sendFormularioBeforeMotivation = async (data) => {
    await axios
      .post("https://findally-backend.herokuapp.com/api/applicants", data)
      .then((r) => {
        if (r.status === 200) {
          setaplicado(true);
          setMotivate(true);
        }
      })
      .catch((e) => {
        console.log(e);
        toast.error("ocurrio un error", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  };

  const arrayfiltrado = async () => {
    const h = await projects.filter((e) => e.status === "En espera");
    setprojectfiltrados(h.reverse());
  };
  useEffect(() => {
    const getData = () => {
      if (user.id) {
        dispatch(ConsultorData(user.id));
        dispatch(getPostedProjects());
      }
      if (!user.id) {
        dispatch(getPostedProjects());
      }
    };
    getData(); // eslint-disable-next-line
    return () => { };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // eslint-disable-next-line
  useEffect(() => {
    if (projects) {
      arrayfiltrado();
    }
    return () => { };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projects]);

  /**
   * Date time Ago
   * @param {} date
   * @returns  5 min ago
   */
  const timeSince = (date) => {
    let seconds = Math.floor((new Date() - date) / 1000);
    let interval = seconds / 31536000;
    if (interval > 1) {
      return Math.floor(interval) + (lang === "es" ? " años" : " years");
    }
    interval = seconds / 2592000;
    if (interval > 1) {
      return Math.floor(interval) + (lang === "es" ? " meses" : " months");
    }
    interval = seconds / 86400;
    if (interval > 1) {
      return Math.floor(interval) + (lang === "es" ? " días" : " days");
    }
    interval = seconds / 3600;
    if (interval > 1) {
      return Math.floor(interval) + (lang === "es" ? " horas" : " hours");
    }
    interval = seconds / 60;
    if (interval > 1) {
      return Math.floor(interval) + (lang === "es" ? " minutos" : " minutes");
    }
    return Math.floor(seconds) + (lang === "es" ? " segundos" : " seconds");
  };

  // const formatterPeso = new Intl.NumberFormat('es-CO', {
  //   style: 'currency',
  //   currency: 'COP',
  //   minimumFractionDigits: 0
  // })

  useEffect(() => {
    if (tourViewed || Object.entries(user).length === 0) {
      return;
    }
    setTimeout(() => {
      dispatch(tourStatus({ run: true, stepIndex: 3 }));
    }, 500);
  }, [dispatch, tourViewed]);

  useEffect(() => {
    if (!user?.id) {
      localStorage.setItem("redirect", history.location.pathname);
    } else {
      localStorage.removeItem("redirect");
    }
  }, []);

  const changeHandler2 = (e) => {
    setSelectedFileCvGeneral(e.target.files[0]);
    setIsFilePickedCvGeneral(true);
  };

  const handleCancel2 = (e) => {
    e.preventDefault();
    setSelectedFileCvGeneral(null);
    setIsFilePickedCvGeneral(false);
  };

  const handleUploadCvGeneral = async (e) => {
    setLoadingCvPersonal(true);
    const fd2 = new FormData();
    fd2.append("upload_file", selectedFileCvGeneral);
    fd2.append("usuario", user.id);
    axios
      .post(
        "https://findally-backend.herokuapp.com/api/user/readCvPersonalized",
        fd2
      )
      .then((res) => {
        setLoadingCvPersonal(false);
        toast.success("Cv personalizado subido correctamente", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        dispatch(getUserInfoWithoutReload(user.id));
      });
  };

  return (
    <div className="contenedor-oportunidad">
      {/* <ToastContainer /> */}
      {!user.id ? null : (
        // : <Header></Header>
        <Sidebar location={"oportunidades"} />
      )}
      <div
        className="BodyWeb"
        style={{ padding: "0rem 4rem", position: "absolute", right: "0" }}
      >
        <div className="contenedor-tour">
          <TourProfessional />
        </div>
        {/* <Headertop
          sitio={proyecto && (proyecto.nombre && proyecto.nombre !== "Unnamed Oportunity") ? proyecto.nombre : (proyecto.nombreProyecto && proyecto.nombreProyecto !== "Unnamed Oportunity") ? proyecto.nombreProyecto : (proyecto.NombreOportunidad && proyecto.NombreOportunidad !== "Unnamed Oportunity") ? proyecto.NombreOportunidad : ""}></Headertop> */}
        <Navbar locate={"Oportunidades"} />
        {/* <Header></Header> */}
        <div className="titulooportunidadesdetalle">
          {!user.id ? null : (
            <ArrowBackIosIcon
              onClick={() => history.goBack()}
              style={{
                cursor: "pointer",
                display: "flex",
                alignItems: "center",
              }}
            />
          )}
          <CustomizableTitle
            color={schneider.color.primary}
            size={"40px"}
            weight={"700"}
          >
            {proyecto.nombre && proyecto.nombre !== "Unnamed Oportunity"
              ? proyecto.nombre
              : proyecto.nombreProyecto &&
                proyecto.nombreProyecto !== "Unnamed Oportunity"
                ? proyecto.nombreProyecto
                : proyecto.NombreOportunidad &&
                  proyecto.NombreOportunidad !== "Unnamed Oportunity"
                  ? proyecto.NombreOportunidad
                  : ""}
          </CustomizableTitle>
        </div>
        {buttonPopup && (
          <Popup trigger={buttonPopup} setTrigger={setButtonPopup}>
            <h2 className="tittle-popup-applied">
              {
                <Translate
                  content="Aplicaciónrealizadaconexito"
                  component="span"
                />
              }
            </h2>
          </Popup>
        )}
        <div
          style={{
            width: "100%",
            flexDirection: "column",
            alignItems: "center",
            display: "flex",
            justifyContent: "center",
          }}
        >
          {proyecto && (
            <div style={{ width: "100%", minWidth: "280px" }}>
              <div className="contenedor-primero-aplicar">
                <img
                  src={proyecto?.imageUrl ? proyecto?.imageUrl : proyectoimagen}
                  alt=""
                  className="img-primero-aplicar"
                />

                <div className="ifo-primero-aplicar" id="applyProject">
                  <div
                    style={{
                      marginBottom: "1em",
                      lineHeight: "20px",
                      fontFamily: "Poppins",
                      fontStyle: "normal",
                    }}
                  >
                    <div>
                      {proyecto?.Anonimo === "No" && (
                        <h5 style={{ fontWeight: "normal" }}>
                          {proyecto?.NombreEmpresa
                            ? `${proyecto?.NombreEmpresa},`
                            : ""}
                        </h5>
                      )}
                      <p>{proyecto?.ciudad ? `${proyecto?.ciudad}` : ""}</p>
                      <p style={{ fontWeight: "400" }}>
                        {lang === "es" ? "Publicada hace" : "Posted ago"}{" "}
                        {proyecto?.fecha_inicio
                          ? timeSince(Date.parse(proyecto?.fecha_inicio))
                          : ""}{" "}
                        -{" "}
                        <span
                          style={{
                            color: "#F07130",
                            fontWeight: "normal",
                          }}
                        >
                          {proyecto?.aplicantes
                            ? proyecto?.aplicantes.length
                            : 0}{" "}
                          {lang === "es" ? "solicitudes" : "requests"}
                        </span>
                      </p>
                    </div>
                    <div>
                      <h5
                        style={{
                          color: "#0B27E6",
                          fontWeight: "normal",
                        }}
                      >
                        {lang === "es" ? "SALARIO" : "SALARY"}{" "}
                        {proyecto?.presupuesto?.length > 1
                          ? `$ ${proyecto?.presupuesto}`
                          : "N/A"}
                      </h5>
                      <p>
                        {lang === "es"
                          ? "Modalidad de contratación: "
                          : "Modality of contracting: "}
                        {proyecto?.modalidadDeContratacion
                          ? proyecto?.modalidadDeContratacion
                          : ""}
                      </p>
                      <p>
                        {lang === "es"
                          ? "Experiencia requerida: "
                          : "Required Experience: "}
                        {proyecto?.experienciaAnos && lang === "es"
                          ? proyecto?.experienciaAnos + " " + "años"
                          : proyecto?.experienciaAnos && lang === "en"
                            ? proyecto?.experienciaAnos + " " + "years"
                            : "N/A"}
                      </p>
                    </div>
                  </div>
                  {aplicado && nextStep && readyFilterForm ? (
                    <Link to={`/${schneider.route}/complete/form`}>
                      <ApplyButton
                        color={schneider.color.primary}
                        width={"200px"}
                      >
                        {lang === "es" ? "Ir al siguiente paso" : "Next step"}
                      </ApplyButton>
                    </Link>
                  ) : null}
                  {typeof(aplicado) === 'boolean' ?
                  aplicado && !nextStep ? (
                    <ApplyButton color={schneider.color.primary} disabled>
                      <Translate
                        className="mediumfont"
                        content="Yaaplicastev1"
                        component="span"
                      />
                    </ApplyButton>
                  ) : !aplicado ? (
                    <div>
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "start",
                          marginTop: "10px",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            width: "max-content",
                            alignItems: "center",
                            cursor: "pointer",
                          }}
                          onClick={handleClickOpen4("paper")}
                        >
                          <input
                            type="checkbox"
                            name="status"
                            checked={checked}
                          />
                          <Translate
                            content="tyc"
                            component="span"
                            style={{ marginLeft: "0.5em", fontSize: "14px" }}
                          />
                        </div>
                      </div>
                      <div style={{ marginTop: "15px" }}>
                        {!checked ? (
                          <ApplyButton color={schneider.color.primary} disabled>
                            {<Translate content="Aplicar" component="span" />}
                          </ApplyButton>
                        ) : !user.id ? (
                          <ApplyButton
                            onClick={() => {
                              history.push("/");
                            }}
                            color={schneider.color.primary}
                          >
                            {<Translate content="Aplicar" component="span" />}
                          </ApplyButton>
                        ) : (
                          aplicable &&
                          user.id && (
                            <ApplyButton
                              onClick={handleClickOpen("paper")}
                              color={schneider.color.primary}
                            >
                              {<Translate content="Aplicar" component="span" />}
                            </ApplyButton>
                          )
                        )}
                      </div>
                    </div>
                  ) : null
                : <CircularProgress sx={{ color: schneider.color.primary , marginLeft: '40px' }} />
                }
                  {!aplicable && !login && checked && !aplicado && (
                    <ApplyButton
                      onClick={handleClickOpen3("paper")}
                      color={schneider.color.primary}
                      width={"150px"}
                    >
                      <Translate
                        className="mediumfont"
                        content="llenarformulariov1"
                        component="span"
                      />
                    </ApplyButton>
                  )}
                </div>
                <Dialog
                  open={open}
                  onClose={handleClose}
                  scroll={scroll}
                  aria-labelledby="scroll-dialog-title"
                  aria-describedby="scroll-dialog-description"
                >
                  <DialogTitle id="scroll-dialog-title">
                    <Translate content="Aplicara" component="span" />
                  </DialogTitle>
                  <DialogContent dividers={scroll === "paper"}>
                    <DialogContentText
                      id="scroll-dialog-description"
                      ref={descriptionElementRef}
                      tabIndex={-1}
                    >
                      {login && (
                        <div style={{ display: "flex", padding: "1rem" }}>
                          <button
                            onClick={handleClickOpen1("paper")}
                            className="project__card-actions-apply buttondetalleoportunidad mediumfont"
                            style={{
                              color: "white",
                              margin: "1rem",
                              borderRadius: "10px",
                            }}
                          >
                            <Translate
                              className="mediumfont"
                              content="iniciarsesion"
                              component="span"
                            />
                          </button>
                          <button
                            onClick={handleClickOpen2("paper")}
                            className="project__card-actions-apply buttondetalleoportunidad mediumfont"
                            style={{
                              color: "white",
                              margin: "1rem",
                              borderRadius: "10px",
                            }}
                          >
                            <Translate
                              className="mediumfont"
                              content="registrarse"
                              component="span"
                            />
                          </button>
                        </div>
                      )}
                      {!login && !motivate && !aplicado && !stepToUploadCV && (
                        <>
                          <div>
                            <h3>
                              <Translate
                                className="mediumfont"
                                content="Queteapasionadetutrabajov1"
                                component="span"
                              />
                            </h3>
                            <textarea
                              type="text"
                              onChange={(e) => setpasionform(e.target.value)}
                              rows="5"
                              cols="50"
                              style={{
                                width: "100%",
                                border: "0.5px solid #C5C5C5",
                              }}
                            />
                          </div>
                        </>
                      )}
                      {!login &&
                        !aplicado &&
                        !props.user.CVPersonalized &&
                        stepToUploadCV && (
                          <>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              <label
                                className={"perfil-cv-input-contenedor"}
                                style={{ margin: "0" }}
                              >
                                <div
                                  className={"perfil-cv-input-subcontenedor"}
                                >
                                  {!loadingCvPersonal ? (
                                    <img src={upload} alt={"img-upload"} />
                                  ) : (
                                    <Spinner
                                      animation="border"
                                      variant="light"
                                    />
                                  )}
                                </div>
                                <div>
                                  {!loadingCvPersonal ? (
                                    <>
                                      <h3 className={"perfil-cv-input-title"}>
                                        {lang === "es"
                                          ? "Subir CV general"
                                          : "Upload general CV"}
                                      </h3>
                                      <h3
                                        className={"perfil-cv-input-subtitle"}
                                      >
                                        PDF
                                      </h3>{" "}
                                    </>
                                  ) : (
                                    <>
                                      <h3
                                        className={"perfil-cv-input-title"}
                                        style={{ marginLeft: "-40px" }}
                                      >
                                        {lang === "es"
                                          ? "Leyendo CV ..."
                                          : "Reading resume..."}
                                      </h3>
                                      <h3
                                        className={"perfil-cv-input-subtitle"}
                                        style={{ marginLeft: "-40px" }}
                                      >
                                        PDF
                                      </h3>
                                    </>
                                  )}
                                </div>
                                <input
                                  onChange={(e) => changeHandler2(e)}
                                  style={{ display: "none" }}
                                  type={"file"}
                                />
                              </label>
                              {isFilePickedCvGeneral ? (
                                <div className={"perfil-cv-input-contenedor2"}>
                                  <div
                                    className={"perfil-cv-input-subcontenedor2"}
                                  >
                                    <img src={file} alt={"img-upload"} />
                                  </div>
                                  <div>
                                    <h3 className={"perfil-cv-input-title2"}>
                                      {selectedFileCvGeneral.name}
                                    </h3>
                                    <h3 className={"perfil-cv-input-subtitle2"}>
                                      {selectedFileCvGeneral?.size}KB
                                    </h3>
                                  </div>
                                  <div>
                                    <button
                                      style={{
                                        backgroundColor: "#fff",
                                        color: "#202256",
                                        position: "absolute",
                                        marginTop: "-50px",
                                        fontWeight: 600,
                                      }}
                                      onClick={(e) => handleCancel2(e)}
                                    >
                                      X
                                    </button>
                                  </div>
                                </div>
                              ) : null}
                            </div>
                          </>
                        )}
                      {!login && !aplicado && props.user.CVPersonalized && (
                        <>
                          <Translate
                            content="Quieresaplicara"
                            component="span"
                          />
                          {proyecto &&
                            proyecto.nombre &&
                            proyecto.nombre !== "Unnamed Oportunity"
                            ? proyecto.nombre
                            : proyecto.nombreProyecto &&
                              proyecto.nombreProyecto !== "Unnamed Oportunity"
                              ? proyecto.nombreProyecto
                              : proyecto.NombreOportunidad &&
                                proyecto.NombreOportunidad !==
                                "Unnamed Oportunity"
                                ? proyecto.NombreOportunidad
                                : ""}
                          ?
                        </>
                      )}
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={handleClose}>
                      <Translate content="Cancelar" component="span" />
                    </Button>
                    {!login &&
                      pasionform !== "" &&
                      !props.user.CVPersonalized &&
                      !stepToUploadCV ? (
                      <Button onClick={() => setStepToUploadCV(true)}>
                        <Translate content="Confirmar" component="span" />
                      </Button>
                    ) : null}
                    {!login &&
                      pasionform !== "" &&
                      !props.user.CVPersonalized &&
                      stepToUploadCV &&
                      isFilePickedCvGeneral ? (
                      <Button onClick={() => handleUploadCvGeneral()}>
                        {lang === "es" ? "Subir CV" : "Upload CV"}
                      </Button>
                    ) : null}
                    {props.user &&
                      props.user.CVPersonalized &&
                      pasionform !== "" ? (
                      <Button
                        onClick={() => {
                          setButtonPopup(true);
                          aplicarOportunidad();
                          handleClose();
                        }}
                      >
                        <Translate content="Confirmar" component="span" />
                      </Button>
                    ) : null}
                  </DialogActions>
                </Dialog>
                <Dialog
                  open={open1}
                  onClose={handleClose1}
                  scroll={scroll}
                  aria-labelledby="scroll-dialog-title"
                  aria-describedby="scroll-dialog-description"
                >
                  <DialogTitle id="scroll-dialog-title">
                    <Translate
                      className="mediumfont"
                      content="iniciarsesion"
                      component="span"
                    />
                  </DialogTitle>
                  <DialogContent dividers={scroll === "paper"}>
                    <div className="justify-content-center">
                      <div className="d-flex flex-row mb-3">
                        <input
                          type="text"
                          className="input-lofo"
                          placeholder="Email o Usuario"
                          name="email"
                          onChange={handleInput}
                        />
                      </div>
                      <div className="d-flex flex-row mb-4">
                        <input
                          type="password"
                          className="input-lofo"
                          placeholder="Contraseña"
                          name="password"
                          onChange={handleInput}
                        />
                      </div>

                      <div className="d-flex flex-row">
                        {form.email && form.password ? (
                          <button
                            onClick={(e) => handleSubmit(e)}
                            className="btn btn-secondary btn-lofo1 mb-3"
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            {cargando ? (
                              <>
                                <div style={{ color: "transparent" }}>
                                  <Translate
                                    className="mediumfont"
                                    content="iniciarsesion"
                                    component="span"
                                  />
                                </div>
                                <Spinner
                                  style={{ position: "absolute" }}
                                  animation="border"
                                  size="sm"
                                />
                              </>
                            ) : (
                              <Translate
                                className="mediumfont"
                                content="iniciarsesion"
                                component="span"
                              />
                            )}
                          </button>
                        ) : (
                          <button
                            onClick={(e) => handleSubmit(e)}
                            className="btn btn-secondary btn-lofo mb-3"
                          >
                            <Translate
                              className="mediumfont"
                              content="iniciarsesion"
                              component="span"
                            />
                          </button>
                        )}
                      </div>
                    </div>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={handleClose1}>
                      <Translate content="Cancelar" component="span" />
                    </Button>
                  </DialogActions>
                </Dialog>
                <Dialog
                  open={open3}
                  onClose={handleClose3}
                  scroll={scroll}
                  aria-labelledby="scroll-dialog-title"
                  aria-describedby="scroll-dialog-description"
                >
                  <DialogTitle id="scroll-dialog-title">
                    <Translate
                      className="mediumfont"
                      content="llenarformulariov1"
                      component="span"
                    />
                  </DialogTitle>
                  <DialogContent dividers={scroll === "paper"}>
                    <div>
                      <h3>
                        <Translate
                          className="mediumfont"
                          content="Queteapasionadetutrabajov1"
                          component="span"
                        />
                      </h3>
                      <textarea
                        type="text"
                        onChange={(e) => setpasionform(e.target.value)}
                        rows="5"
                        cols="50"
                      />
                    </div>
                    <br />
                    <div>
                      <h3>
                        <Translate
                          className="mediumfont"
                          content="SubirCVpdfdescargadodelinkedinv1"
                          component="span"
                        />
                      </h3>
                      <input
                        type="file"
                        name="files"
                        onChange={(e) => insertarArchivo(e)}
                      />
                    </div>
                    <br />
                    <div>
                      <h3>O</h3>
                    </div>
                    <br />
                    <div>
                      <h3>
                        <Translate
                          className="mediumfont"
                          content="Llenamanualmenteelformulario"
                          component="span"
                        />
                      </h3>
                      <Link to={`/${schneider.route}/cv`}>
                        <ApplyButton color={schneider.color.primary}>
                          <Translate
                            className="mediumfont"
                            content="Continuarv1"
                            component="span"
                          />
                        </ApplyButton>
                      </Link>
                    </div>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={handleClose3}>
                      <Translate content="Cancelar" component="span" />
                    </Button>
                    {fileform2 && (
                      <Button
                        onClick={() => {
                          enviarform();
                        }}
                      >
                        <Translate content="Confirmar" component="span" />
                      </Button>
                    )}
                  </DialogActions>
                </Dialog>

                <Dialog
                  open={open2}
                  onClose={handleClose2}
                  scroll={scroll}
                  aria-labelledby="scroll-dialog-title"
                  aria-describedby="scroll-dialog-description"
                >
                  <DialogTitle id="scroll-dialog-title">
                    <Translate
                      className="mediumfont"
                      content="registrate"
                      component="span"
                    />
                  </DialogTitle>
                  <DialogContent dividers={scroll === "paper"}>
                    <div className="register__container-form">
                      <input
                        className="input-register"
                        type="text"
                        placeholder=/*"Username" */ {placeholderNombre}
                        name="username"
                        onChange={handleInput2}
                      />
                      <input
                        className="input-register"
                        type="text"
                        placeholder=/*"Email"*/ {placeholderCorreo}
                        name="email"
                        onChange={handleInput2}
                      />
                      <input
                        className="input-register"
                        type="password"
                        placeholder=/*"Password"*/ {placeholderPass}
                        name="password"
                        onChange={handleInput2}
                      />
                      <label className="register__form-conditions">
                        <input
                          type="checkbox"
                          name="status"
                          defaultChecked={checked2}
                          onChange={(e) => {
                            handleCheck2(e);
                          }}
                          onClick={() => {
                            setChecked2(false);
                          }}
                        />
                        {/*I accept the terms and conditions */}
                        <Translate content="tyc" component="span" />
                      </label>

                      <button
                        onClick={handleSubmit2}
                        className="register__form__button"
                        name="Registrate"
                      >
                        {/*Sign in*/}
                        <Translate content="registrate" component="span" />
                      </button>
                    </div>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={handleClose2}>
                      <Translate content="Cancelar" component="span" />
                    </Button>
                  </DialogActions>
                </Dialog>
              </div>

              <Dialog
                open={open4}
                onClose={handleClose4}
                scroll={scroll}
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
              >
                <DialogTitle id="scroll-dialog-title">
                  {<Translate content="tyc" component="span" />}{" "}
                </DialogTitle>
                <DialogContent dividers={scroll === "paper"}>
                  <div className="section">
                    <p className="content">
                      {<Translate content="thisdoc" component="span" />}
                    </p>

                    <p className="content">
                      {<Translate content="carring" component="span" />}
                    </p>

                    <p className="content">
                      {<Translate content="comousted" component="span" />}
                    </p>

                    <p className="content">
                      {<Translate content="cuertoparr" component="span" />}
                    </p>
                  </div>

                  <div className="section">
                    <h3 className="title">
                      {<Translate content="servicio" component="span" />}
                    </h3>
                    <p className="content">
                      {<Translate content="esunaplataforma" component="span" />}
                    </p>

                    <p className="sub-content">
                      {<Translate content="siustedescon" component="span" />}
                    </p>

                    <p className="sub-content">
                      {<Translate content="adicional" component="span" />}
                    </p>

                    <p className="sub-content">
                      {<Translate content="siustedes" component="span" />}{" "}
                    </p>

                    <p className="content">
                      {<Translate content="portanto" component="span" />}{" "}
                    </p>

                    <p className="content">
                      {<Translate content="adicionalment" component="span" />}{" "}
                    </p>
                  </div>

                  <div className="section">
                    <h3 className="title">
                      {<Translate content="alcanceyusu" component="span" />}{" "}
                    </h3>
                    <p className="content">
                      {<Translate content="paraquellos" component="span" />}
                    </p>

                    <p className="content">
                      {<Translate content="paragold" component="span" />}
                    </p>

                    <p className="content"></p>

                    <p className="content">
                      {<Translate content="paraclientes" component="span" />}
                    </p>
                  </div>

                  <div className="section">
                    <h3 className="title">
                      {<Translate content="requisitosuso" component="span" />}
                    </h3>
                    <p className="content">
                      {<Translate content="elusuariodeber" component="span" />}
                    </p>

                    <p className="content">
                      {<Translate content="paraacceder" component="span" />}
                    </p>
                  </div>

                  <div className="section">
                    <h3 className="title">
                      {
                        <Translate
                          content="obligacionesusuario"
                          component="span"
                        />
                      }
                    </h3>
                    <p className="content">
                      {<Translate content="seobligaa" component="span" />}
                    </p>

                    <p className="sub-content">
                      {
                        <Translate
                          content="Utilizarlosconteni"
                          component="span"
                        />
                      }
                    </p>
                  </div>

                  <div className="section">
                    <h3 className="title">
                      {<Translate content="condiparacli" component="span" />}
                    </h3>
                    <p className="content">
                      {<Translate content="alentrarcon" component="span" />}
                    </p>

                    <p className="content">
                      {<Translate content="ionalmentesiust" component="span" />}
                    </p>
                  </div>

                  <div className="section">
                    <h3 className="title">
                      {<Translate content="condiconsulto" component="span" />}
                    </h3>
                    <p className="content">
                      {<Translate content="alentraralaplat" component="span" />}
                    </p>

                    <p className="content">
                      {
                        <Translate
                          content="Adicionalmentealaceptares"
                          component="span"
                        />
                      }
                    </p>

                    <p className="content">
                      {
                        <Translate
                          content="SiustedesConsultoresta"
                          component="span"
                        />
                      }
                    </p>

                    <p className="content">
                      {<Translate content="Seguirlaguiaque" component="span" />}{" "}
                    </p>
                  </div>

                  <div className="section">
                    <h3 className="title">
                      {<Translate content="condigold" component="span" />}
                    </h3>
                    <p className="content">
                      {
                        <Translate
                          content="Alaceptarestostérmi"
                          component="span"
                        />
                      }
                    </p>

                    <p className="content">
                      {
                        <Translate
                          content="Adicionalmentesuste"
                          component="span"
                        />
                      }
                    </p>
                  </div>

                  <div className="section">
                    <h3 className="title">
                      {<Translate content="tyc1" component="span" />}
                    </h3>
                    <p className="content">
                      {<Translate content="tyc2" component="span" />}
                    </p>

                    <p className="content">
                      {<Translate content="tyc3" component="span" />}
                    </p>

                    <p className="content">
                      {<Translate content="tyc4" component="span" />}
                    </p>
                  </div>

                  <div className="section">
                    <h3 className="title">
                      {<Translate content="tyc5" component="span" />}
                    </h3>
                    <p className="content">
                      {<Translate content="tyc6" component="span" />}
                    </p>

                    <p className="content">
                      {<Translate content="tyc7" component="span" />}
                    </p>

                    <p className="content">
                      {<Translate content="tyc8" component="span" />}
                    </p>

                    <p className="content">
                      {<Translate content="tyc9" component="span" />}
                    </p>

                    <p className="content">
                      {<Translate content="tyc10" component="span" />}
                    </p>
                  </div>

                  <div className="section">
                    <h3 className="title">
                      {<Translate content="tyc11" component="span" />}
                    </h3>
                    <p className="content">
                      {<Translate content="tyc12" component="span" />}
                    </p>

                    <p className="content">
                      {<Translate content="tyc13" component="span" />}
                    </p>

                    <p className="content">
                      {<Translate content="tyc14" component="span" />}
                    </p>

                    <p className="content">
                      {<Translate content="tyc15" component="span" />}
                    </p>
                  </div>

                  <div className="section">
                    <h3 className="title">
                      {<Translate content="tyc16" component="span" />}
                    </h3>
                    <p className="content">
                      {<Translate content="tyc17" component="span" />}
                    </p>
                  </div>

                  <div className="section">
                    <h3 className="title">
                      {<Translate content="tyc18" component="span" />}
                    </h3>
                    <p className="content">
                      {<Translate content="tyc19" component="span" />}
                    </p>

                    <p className="content">
                      {<Translate content="tyc20" component="span" />}
                    </p>

                    <p className="content">
                      {<Translate content="tyc21" component="span" />}
                    </p>

                    <p className="content">
                      {<Translate content="tyc22" component="span" />}
                    </p>
                  </div>

                  <div className="section">
                    <h3 className="title">
                      {<Translate content="tyc23" component="span" />}
                    </h3>
                    <p className="content">
                      {<Translate content="tyc24" component="span" />}
                    </p>
                  </div>

                  <div className="section">
                    <h3 className="title">
                      {<Translate content="tyc25" component="span" />}
                    </h3>
                    <p className="content">
                      {<Translate content="tyc26" component="span" />}
                    </p>
                  </div>

                  <div className="section">
                    <h3 className="title">
                      {<Translate content="tyc27" component="span" />}
                    </h3>
                    <p className="content">
                      {<Translate content="tyc28" component="span" />}
                    </p>

                    <p className="content">
                      {<Translate content="tyc29" component="span" />}
                    </p>
                  </div>
                </DialogContent>
                <DialogActions>
                  <Button
                    onClick={() => {
                      setChecked(false);
                      handleClose4();
                    }}
                  >
                    <Translate content="Cancelar" component="span" />
                  </Button>
                  <Button
                    onClick={() => {
                      setChecked(true);
                      handleClose4();
                    }}
                  >
                    <Translate content="Confirmar" component="span" />
                  </Button>
                </DialogActions>
              </Dialog>
              <div className="infoOportunity">
                <div className="infoOportunity-left">
                  <div
                    style={{
                      background: "white",
                      padding: "2em",
                      marginBottom: "2em",
                      borderRadius: "15px",
                    }}
                  >
                    <h3>
                      {lang === "es"
                        ? "DESCRIPCIÓN DEL PROYECTO"
                        : "PROJECT DESCRIPTION"}
                    </h3>
                    <p
                      style={{
                        color: "#5D5D5D",
                        lineHeight: "20px",
                        fontFamily: "Poppins",
                        fontStyle: "normal",
                        fontWeight: "400",
                        textAlign: "justify",
                      }}
                    >
                      {proyecto?.DescribeProyecto
                        ? proyecto?.DescribeProyecto
                        : ""}
                    </p>
                  </div>
                  {proyecto?.responsabilidadYfunciones ? (
                    <div
                      style={{
                        background: "white",
                        padding: "2em",
                        marginBottom: "2em",
                        borderRadius: "15px",
                      }}
                    >
                      <h3>
                        {lang === "es"
                          ? "RESPONSABILIDADES Y FUNCIONES"
                          : "RESPONSIBILITIES AND FUNCTIONS"}
                      </h3>
                      <p
                        style={{
                          color: "#5D5D5D",
                          lineHeight: "20px",
                          fontFamily: "Poppins",
                          fontStyle: "normal",
                          fontWeight: "400",
                          textAlign: "left",
                        }}
                      >
                        {Array.isArray(proyecto?.responsabilidadYfunciones)
                          ? proyecto?.responsabilidadYfunciones?.map(
                            (item, index) => {
                              return (
                                <li
                                  key={index}
                                  style={{
                                    listStyle: "disc",
                                  }}
                                >
                                  {item.charAt(0).toUpperCase() +
                                    item.slice(1).toLowerCase()}
                                </li>
                              );
                            }
                          )
                          : proyecto?.responsabilidadYfunciones}
                      </p>
                    </div>
                  ) : null}

                  <div
                    style={{
                      background: "white",
                      padding: "2em",
                      borderRadius: "15px",
                    }}
                  >
                    <h3>{lang === "es" ? "IDIOMAS" : "LANGUAGES"}</h3>
                    <p
                      style={{
                        color: "#5D5D5D",
                        lineHeight: "20px",
                        fontFamily: "Poppins",
                        fontStyle: "normal",
                        fontWeight: "400",
                      }}
                    >
                      {proyecto?.Lenguaje?.map((e, index) => {
                        return (
                          <li
                            key={index}
                            style={{
                              listStyle: "disc",
                            }}
                          >
                            {e}
                          </li>
                        );
                      })}
                    </p>
                  </div>
                  {proyecto?.descripcionComision ? (
                    <div
                      style={{
                        background: "white",
                        padding: "2em",
                        borderRadius: "15px",
                      }}
                    >
                      <h3>
                        {lang === "es"
                          ? "DESCRIPCIÓN DE LA COMISIÓN"
                          : "COMMISSION DESCRIPTION"}
                      </h3>
                      <p
                        style={{
                          color: "#5D5D5D",
                          lineHeight: "20px",
                          fontFamily: "Poppins",
                          fontStyle: "normal",
                          fontWeight: "400",
                        }}
                      >
                        {proyecto?.descripcionComision}
                      </p>
                    </div>
                  ) : null}
                </div>
                <div className="infoOportunity-ritgh">
                  <div
                    style={{
                      background: "white",
                      padding: "2em",
                      marginBottom: "2em",
                      borderRadius: "15px",
                    }}
                  >
                    <h3>
                      {lang === "es"
                        ? "REQUERIMIENTOS PROFESIONALES"
                        : "PROFESSIONAL REQUIREMENTS"}
                    </h3>
                    <p
                      style={{
                        color: "#5D5D5D",
                        lineHeight: "20px",
                        fontFamily: "Poppins",
                        fontStyle: "normal",
                        fontWeight: "400",
                      }}
                    >
                      {proyecto?.Niveldeconocimiento?.map((e, index) => {
                        return (
                          <li
                            key={index}
                            style={{
                              listStyle: "disc",
                            }}
                          >
                            {e}
                          </li>
                        );
                      })}
                    </p>
                  </div>
                  <div
                    style={{
                      background: "white",
                      padding: "2em",
                      borderRadius: "15px",
                      marginBottom: "2em",
                    }}
                  >
                    {proyecto?.habilihabilidadesTecnicas?.length > 0 && (
                      <h3>
                        {lang === "es"
                          ? "HABILIDADES TÉCNICAS"
                          : "TECHNICAL SKILLS"}
                      </h3>
                    )}
                    <p
                      style={{
                        color: "#5D5D5D",
                        lineHeight: "20px",
                        fontFamily: "Poppins",
                        fontStyle: "normal",
                        fontWeight: "400",
                      }}
                    >
                      {proyecto?.habilidadesTecnicas?.map((e, index) => {
                        return (
                          <li
                            key={index}
                            style={{
                              listStyle: "disc",
                            }}
                          >
                            {e.charAt(0).toUpperCase() +
                              e.slice(1).toLowerCase()}
                          </li>
                        );
                      })}
                    </p>
                  </div>
                  <div
                    style={{
                      background: "white",
                      padding: "2em",
                      borderRadius: "15px",
                      marginBottom: "2em",
                    }}
                  >
                    <h3>
                      {lang === "es" ? "HABILIDADES BLANDAS" : "SOFT SKILLS"}
                    </h3>
                    <p
                      style={{
                        color: "#5D5D5D",
                        lineHeight: "20px",
                        fontFamily: "Poppins",
                        fontStyle: "normal",
                        fontWeight: "400",
                      }}
                    >
                      {proyecto?.habilidadesBlandas?.map((e, index) => {
                        return (
                          <li
                            key={index}
                            style={{
                              listStyle: "disc",
                            }}
                          >
                            {e}
                          </li>
                        );
                      })}
                    </p>
                  </div>
                  {proyecto?.listadoBeneficios?.length ? (
                    <div
                      style={{
                        background: "white",
                        padding: "2em",
                        marginBottom: "2em",
                        borderRadius: "15px",
                      }}
                    >
                      <h3>{lang === "es" ? "BENEFICIOS" : "BENEFITS"}</h3>
                      <p
                        style={{
                          color: "#5D5D5D",
                          lineHeight: "20px",
                          fontFamily: "Poppins",
                          fontStyle: "normal",
                          fontWeight: "400",
                        }}
                      >
                        {proyecto?.listadoBeneficios?.map((e, index) => {
                          return (
                            <li
                              key={index}
                              style={{
                                listStyle: "disc",
                              }}
                            >
                              {e.charAt(0).toUpperCase() +
                                e.slice(1).toLowerCase()}
                            </li>
                          );
                        })}
                      </p>
                    </div>
                  ) : null}
                  {proyecto?.descripcionBeneficios ? (
                    <div
                      style={{
                        background: "white",
                        padding: "2em",
                        marginBottom: "2em",
                        borderRadius: "15px",
                      }}
                    >
                      <h3>
                        {lang === "es"
                          ? "DESCRIPCIÓN DE BENEFICIOS"
                          : "BENEFITS DESCRIPTION"}
                      </h3>
                      <p
                        style={{
                          color: "#5D5D5D",
                          lineHeight: "20px",
                          fontFamily: "Poppins",
                          fontStyle: "normal",
                          fontWeight: "400",
                        }}
                      >
                        {proyecto?.descripcionBeneficios}
                      </p>
                    </div>
                  ) : null}
                </div>
              </div>
              <hr />
              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  justifyContent: "space-around",
                }}
              ></div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    project: state.projects,
    userProjects: state.user.projects,
    user: state.user,
    userform: state.userform,
  };
}

const mapDispatchToProps = {
  loginUser,
  loginReq,
  loginSocial,
  ConsultorProjects,
  ProjectApply,
};

export default connect(mapStateToProps, mapDispatchToProps)(Opportunity);
