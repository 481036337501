//@ts-nocheck
import React, { useEffect, useState, useContext } from 'react'
import Sidebar from '../../../../components/sidebar/Sidebar'
import Navbar from '../../../../components/navbar/Navbar'
import { Link, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { faltainfoinitial, filterSelectionProcess, mirarfilterSelectionProcess } from "../../../../../actions";
import axios from "axios";
import larrow from "../../../../../assets/icons/l-arrow.svg";
import ProfileCard from "../../../../../empresa/professionalprofile/profileCard/ProfileCard";
import OportunidadIdiomaCard from "../../../../../profesionalpag/PerfilProfesional/sideCards/oportunidadIdiomaCard";
import download from "../../../../../assets/icons/download-icon.svg";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { CVdocument } from "../../../../../utils/CvCreator";
import work from "../../../../../assets/icons/work-image.svg";
import { ProfileDownloadImageContainer } from "../../../professional/profile/ProfileStyles";
import { SchneiderContext } from "../../../../context/SchneiderContext";
import { useLocation } from "react-router-dom";
import CircularFrameAbility from '../../../../../empresa/professionalprofile/circularframe/CircularFrameAbility';

export default function ApplicantProfile() {
  const { schneider } = useContext(SchneiderContext)
  const { id } = useParams<any>();
  const location = useLocation();
  const params = new URLSearchParams(location.search)
  const op = params.get('op')
  const dispatch = useDispatch();
  // const plan = useSelector((state: any) => state.userPlan);
  const candidatestate = useSelector((state: any) => state.filterSelectionProcess);
  const [candidate, setcandidate] = useState("");
  const [testResult, setTestResult] = useState<any>({})
  const info = useSelector((state: any) => state.faltainformacion);
  const user = useSelector((state: any) => state.user);
  const lang = useSelector((state: any) => state.lang);
  const [data, setData] = useState("");
  const [applicantData, setApplicantData] = useState("");
  const [readyForUse, setReadyForUse] = useState(false);

  useEffect(() => {
    setcandidate(candidatestate);
    return () => {
      setcandidate("");
    }
  }, [candidatestate]);

  useEffect(() => {
    dispatch(faltainfoinitial() as any);
    dispatch(mirarfilterSelectionProcess() as any);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, id]);


  useEffect(() => {
    if (info === "si") {
      axios
        .get("https://findally-backend.herokuapp.com/user/CVprofile/" + id)
        .then((e: any) => {
          setcandidate(e?.data?.cv_file);
          if (e?.data?.cv_file?.usuario) {
            axios.get(`https://findally-backend.herokuapp.com/user/${e?.data?.cv_file?.usuario}`)
              .then(res => {
                setApplicantData(res.data)
                return setReadyForUse(true)
              }).catch(err => {
                console.log(err)
                return setReadyForUse(true)
              })
          }
          return setReadyForUse(true)
        });
    }
    if (info === "no") {
      dispatch(filterSelectionProcess(id) as any);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [info]);

  if (!localStorage.getItem('langlocal')) {
    localStorage.setItem('langlocal', 'es')
  }

  useEffect(() => {
    axios.get(`https://findally-backend.herokuapp.com/user/${id}`)
      .then(res => {
        return setData(res.data)
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    axios.get(`https://resume-matching-deploy.herokuapp.com/personality/${op}/${id}`)
      .then(res => {
        console.log(res.data, 'fortinaity')
        return setTestResult(res.data)
      })
      .catch(err => {
        console.log(err)
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  // @ts-ignore
  return (
    <>
      {/* <ToastContainer/> */}
      <div className={"contenedor-oportunidad"}>
        <Sidebar location={'home'} />
        <div className="BodyWeb"
          style={{
            padding: "0rem 2rem", position: "absolute", right: "0",
          }}>
          <Navbar locate={'home'} />
          {/*head perfil: los (links under the hood son un div)*/}
          <Link className={'perfil-prof-header'} to={`/${schneider.route}/company/dashboard/${op}`}>
            <img src={larrow} alt={''} />
            <h1 className={'perfil-prof-titulo'} style={{ color: schneider.color.primary }}>{lang === 'es' ? 'Perfil' : 'Profile'}</h1>
          </Link>
          {/*body perfil*/}
          <div className={'perfil-prof-body'}>
            <div className={'perfil-prof-body-izq'}>
              {/*acá van tus componentes y sections @nahuel*/}
              <ProfileCard user={data} cv={candidate} lang={lang} />
              <OportunidadIdiomaCard user={user} cv={candidate} lang={lang} />
              {/*<PortfolioCard user={user} cv={cv} lang={lang}/>*/}
            </div>
            <div className={'perfil-prof-body-der'}>
              <div>
                <h1
                  className={'perfil-prof-nombre'}>{candidate?.info_personal?.nombre ? candidate?.info_personal?.nombre : ''}</h1>
                <h2
                  className={'perfil-prof-empleo'}>{candidate?.info_personal?.profesion_actual ? candidate?.info_personal?.profesion_actual : ''}</h2>
              </div>
              <div className={'perfil-prof-sub-cont'}>
                <h2 className={'perfil-prof-sub-subtitulo'}>{lang === 'es' ? 'Acerca de' : 'About'}</h2>
                <p
                  className={'perfil-prof-textos'}>{candidate?.extracto ? candidate?.extracto : ''}</p>
                <hr />
                <h2 className={'perfil-prof-sub-subtitulo'}>{lang === 'es' ? 'Hoja de vida' : 'Resume'}</h2>
                <div className={'perfil-cv-input-contenedor-global'}>
                  {readyForUse && applicantData?.urlCVPersonalized ?
                    <a href={applicantData.urlCVPersonalized} target={"_blank"} rel={"noopener noreferrer"}>
                      <label className={'perfil-cv-input-contenedor'}>
                        <ProfileDownloadImageContainer color={schneider.color.primary}>
                          <img src={download} alt={'img-upload'} />
                        </ProfileDownloadImageContainer>
                        <div>
                          <h3
                            className={'perfil-cv-input-title'} style={{ marginLeft: '5px' }}>{lang === 'es' ? 'Descargar hoja de vida' : 'Download resume'}</h3>
                          <h3 className={'perfil-cv-input-subtitle'} style={{ marginLeft: '5px' }}>PDF</h3>
                        </div>
                      </label></a> :
                    <label className={'perfil-cv-input-contenedor'} onClick={() => { document.getElementById('download').click() }}>
                      <ProfileDownloadImageContainer color={schneider.color.primary}>
                        <img src={download} alt={'img-upload'} />
                      </ProfileDownloadImageContainer>
                      <div>
                        <h3
                          className={'perfil-cv-input-title'} style={{ marginLeft: '5px' }}>{lang === 'es' ? 'Descargar hoja de vida' : 'Download resume'}</h3>
                        <h3 className={'perfil-cv-input-subtitle'} style={{ marginLeft: '5px' }}>PDF</h3>
                      </div>
                    </label>}
                  <div style={{ display: 'none' }}>
                    <PDFDownloadLink
                      document={<CVdocument user={{ cv_file: candidate }} />}
                      fileName={`Cv-${candidate?.info_personal?.nombre}.pdf`}
                    >
                      <button
                        id="download"
                        style={{
                          backgroundColor: "transparent",
                          height: "35px",
                          borderRadius: "5px",
                          borderStyle: "solid",
                          borderWidth: "2px",
                        }}
                      ></button>
                    </PDFDownloadLink>
                  </div>
                </div>
              </div>
              <div className={'perfil-prof-sub-cont'}>
                <h2 className={'perfil-prof-sub-subtitulo'}>{lang === 'es' ? 'Educación' : 'Education'}</h2>
                {candidate?.educacion && candidate.educacion.length > 0 && candidate.educacion[0].NombreInstitucion !== "" ? candidate?.educacion?.map((e, i) => {
                  return (<div className={'perfil-edu-container'} key={i}>
                    {e.NombreInstitucion ? <h3 className={'perfil-prof-sub-subtitulo-edu-trabajo'}>{e.NombreInstitucion}</h3> : null}
                    {e.Titulo_Certificacion ? <h3 className={'perfil-prof-sub-subtitulo-edu-trabajo2'}>{e.Titulo_Certificacion}</h3> : null}
                    {e.TipoEduacion ? <h3 className={'perfil-prof-sub-subtitulo-edu-trabajo2'}>{e.TipoEduacion}</h3> : null}
                    {e.fecha_finalizacion ? <h3 className={'perfil-prof-sub-subtitulo-edu-trabajo2'}>{e.fecha_finalizacion}</h3> : null}
                    {e.duracion ? <h3 className={'perfil-prof-sub-subtitulo-edu-trabajo3'}>{e.duracion}</h3> : null}
                    {(i + 1) < candidate?.experiencia?.length ? <hr /> : null}
                  </div>)
                }) : null}
                <h2
                  className={'perfil-prof-sub-subtitulo'}>{lang === 'es' ? 'Experiencia laboral' : 'Work experience'}</h2>
                {candidate?.experiencia && candidate.experiencia.length > 0 && candidate.experiencia[0].Nombreempresa !== "" ? candidate?.experiencia?.map((e, i) => {
                  return (<div key={i}>
                    <div className={'perfil-trab-container'}>
                      <div>
                        <img src={work} alt={'asd'} />
                      </div>
                      <div>
                        {e.Nombreempresa ? <h3 className={'perfil-prof-sub-subtitulo-edu-trabajo'}>{e.Nombreempresa}</h3> : null}
                        {e?.cargos?.length ? e.cargos.map((c, i) => {
                          return (<div key={i}>
                            {c.nombrecargo ? <h3
                              className={'perfil-prof-sub-subtitulo-edu-trabajo2'}>{lang === 'es' ? `Cargo ${i + 1}: ` : `Charge ${i + 1}: `} {c.nombrecargo}</h3> : null}

                            {c.duracion ? <h3
                              className={'perfil-prof-sub-subtitulo-edu-trabajo3'}>{c.duracion.replace(/\s*\(.*?\)\s*/g, '')}</h3> : null}
                            <h3
                              className={'perfil-prof-sub-subtitulo-edu-trabajo2'}>{lang === 'es' ? `Descripción: ` : `Description: `} {c.descripcion ? c.descripcion : 'N/A'}</h3>
                          </div>)
                        }) : null}
                        {/* <h3
                          className={'perfil-prof-sub-subtitulo-edu-trabajo3'}>{lang === 'es' ? 'Duración' : 'Duration'} {e.totalDuracion}</h3> */}
                      </div>
                    </div>
                    {(i + 1) < candidate?.experiencia?.length ? <hr /> : null}
                  </div>)
                }) : <p
                  className={'perfil-prof-textos'}>{lang === 'es' ? 'Sin experiencia laboral' : 'No work experience'}</p>}
              </div>
            </div>
          </div>
          <div className="container-test-result-profile">
            <h1 className={'perfil-prof-nombre'}>
              {lang === 'es' ? 'Resultado test de personalidad' : 'Personality test result'}
            </h1>
            <div className="subcontainer-test-result-profile">
              {
                Object.keys(testResult).length > 0 ? Object.entries(testResult).map(([key, value]) => (
                  <>
                    <CircularFrameAbility title={key} percentage={value} color={schneider.color.primary} />
                  </>
                ))
                  : lang === 'es' ? <p>No se ha realizado el test</p> : <p>The test has not been done</p>
              }
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
