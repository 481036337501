import React, {useState} from 'react'
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css'
import {useSelector, useDispatch} from 'react-redux';
import './RegisterBootcamp.css'
import Select from 'react-select'
import {useHistory} from 'react-router';
import axios from 'axios';
import {toast} from 'react-toastify';
import {getUserInfo} from "../../../actions";
import LogoCoally from '../../../assets/logos/Logo-coally-oscuro.png'

const customStyles = {
  option: (provided, state) => ({
    ...provided,
    color: state.isSelected ? 'white' : 'black',
    backgroundColor: state.isSelected ? '#202256' : 'white',
    cursor: 'pointer',
    padding: 10,

  }),
  control: (provided, state) => ({
    ...provided,
    display: 'flex',
    alignItems: 'flex-start',
    padding: '0',
    justifyContent: 'center',
    borderRadius: '8px',
    backgroundColor: state.isSelected ? '#202256' : 'white',
    color: 'white',
    marginTop: '-1em',
  }),
  menu: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? '#202256' : 'white',
    color: 'white',
    padding: '0',
    marginTop: '-1em',
  }),

  //change the color of the select when selected

}

const RegisterBootcamp = () => {
  const dispatch = useDispatch()
  const {lang} = useSelector(state => state)
  const navigate = useHistory();
  const {user} = useSelector(state => state)
  const [registerBootcamp, setRegisterBootcamp] = useState({
    name: '',
    lastName: '',
    phone: '',
    email: '',
    isProfessional: false,
    focus: '',
    bootcamp_Id: '63b9b25b69caba0d7ce044cb',
    anio_graduacion: '',
    user_Id: user.id
  })
  const [password, setPassword] = useState('')

  const handleOnChange = (e) => {
    setRegisterBootcamp({
      ...registerBootcamp,
      [e.target.name]: e.target.value
    })
  }

  const handleOnChangeSelect = (e) => {
    setRegisterBootcamp({
      ...registerBootcamp,
      focus: e === null ? '' : e.value
    })
  }

  const handleOnSubmit = (e) => {
    e.preventDefault()
    if (!registerBootcamp.email.includes('@') && !registerBootcamp.email.includes('.com')) {
      return toast.error(lang === 'es' ? 'Email invalido' : 'Invalid email', {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
    } else {

      const newUser = {
        username: registerBootcamp.name + ' ' + registerBootcamp.lastName,
        email: registerBootcamp.email.toLowerCase(),
        password: password,
        phoneNumber: registerBootcamp.phone,
        roles: ['coallier'],
        verifyEmail: 'este es de registro',
        num_id: registerBootcamp.num_id ? registerBootcamp.num_id : '',
      }

      console.log(newUser, 'newUser')

      axios.post(`https://findally-backend.herokuapp.com/api/auth/signup?lang=${lang}`, newUser)
        .then(res => {
          console.log(res.data.data, 'res')
          const registerBootcamp2 = {
            name: registerBootcamp.name,
            lastName: registerBootcamp.lastName,
            phone: registerBootcamp.phone,
            email: registerBootcamp.email,
            isProfessional: registerBootcamp.isProfessional,
            focus: registerBootcamp.focus,
            bootcamp_Id: '63b9b25b69caba0d7ce044cb',
            anio_graduacion: registerBootcamp.anio_graduacion,
            user_Id: res.data.data._id
          }
          console.log(registerBootcamp2, 'registerBootcamp2')
          axios.post('https://findally-backend.herokuapp.com/api/users/bootcamp', registerBootcamp2)
            .then(res => {
              console.log(res, 'res')
              toast.success(lang === 'es' ? 'Registro exitoso, será contactado por nuestro equipo para brindarle más información' : 'Successful registration, you will be contacted by our team to provide you with more information', {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              })
              dispatch(getUserInfo(user.id))
              setTimeout(() => {
                navigate.push("/consultor/home")
              }, 1500)
            })
            .catch(err => {
              console.log(err, 'err')
              toast.error(lang === 'es' ? 'Registro al bootcamp fallido' : 'Sign up failed', {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              })
            })
        })
        .catch(err => {
          console.log(err, 'err')
          toast.error(lang === 'es' ? 'Registro fallido, email ya utilizado' : 'Sign up failed, email already in use', {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          })
        })
    }
  }

  const genders = [
    {value: 'Bachiller', label: 'Bachiller'},
    {value: 'Técnico', label: 'Técnico'},
    {value: 'Tecnólogo', label: 'Tecnólogo'},
    {value: 'Profesional', label: 'Profesional'},
    {value: 'Posgrado', label: 'Posgrado'},
    {value: 'Otro', label: 'Otro'},
  ]
  // const redirect = () => {
  //   toast.error(lang === 'es' ? 'Debe iniciar sesión para acceder a esta página, incia sesión y vuelve a ingresar a este link, serás redirigido.' : 'You must log in to access this page, log in and return to this link, you will be redirected.', {
  //     position: "top-center",
  //     autoClose: 5000,
  //     hideProgressBar: false,
  //     closeOnClick: true,
  //     pauseOnHover: true,
  //     draggable: true,
  //     progress: undefined,
  //   })
  //   setTimeout(() => {
  //     navigate.push("/")
  //   }, 5000)
  // }

  return (
    <>
      {/*{Object.entries(user).length > 0 ?*/}
        <div className='container-global-bootcamp'>
          <div className='container-div-bootcamp'>
            <form style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              width: '100%',
              alignItems: 'center',
            }}
                  onSubmit={handleOnSubmit}>

              <div style={{display: 'flex', alignItems: 'flex-start', width: '100%'}}>
                <button style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  color: '#202256',
                  borderRadius: '5px',
                  width: '20px',
                  height: '20px',
                  marginTop: '9px',
                  marginLeft: '11px',
                  backgroundColor: "transparent",
                  border: '2px solid #202256'
                }} onClick={navigate.goBack}
                >
                  x
                </button>
              </div>
              <div>
                <img src={LogoCoally} alt={'coally-logo'} style={{height: '150px'}}/>
              </div>
              <div className='div-title-form-bootcamp'>
                <div style={{
                  display: 'flex',
                  flexDirection: 'column',
                  marginTop: '5%'
                }}>
                  <h2 style={{
                    color: '#393939',
                    fontSize: '24px',
                    fontWeight: 700
                  }}>{lang === 'es' ? 'Regístrate' : 'Sign up'} </h2>
                  <hr/>
                  <div>
                    <label>{lang === 'es' ? 'Nombre' : 'Firstname'}</label>
                    <input type="text"
                           style={{width: '100%', marginBottom: '1em', border: '1px solid #C5C5C5'}}
                           name='name'
                           onChange={handleOnChange}
                    />

                    <label>{lang === 'es' ? 'Apellido' : 'Lastname'}</label>
                    <input type="text"
                           style={{width: '100%', marginBottom: '1em', border: '1px solid #C5C5C5'}}
                           name='lastName'
                           onChange={handleOnChange}/>

                    <div style={{marginBottom: '1em'}}>
                      <label>{lang === 'es' ? 'Número de Celular' : 'Cell Phone Number'}</label>
                      <PhoneInput
                        country={'us'}
                        regions={'america'}
                        enableLongNumbers={true}
                        placeholder={lang === "es" ? 'Teléfono' : 'Phone number'}
                        inputStyle={{
                          border: '1px solid #C5C5C5',
                          borderRadius: '0px',
                          width: '100%',
                          fontstyle: 'normal',
                          fontWeight: '400',
                          fontSize: '16px',
                          height: '30px',
                        }}
                        onChange={
                          (phone, country) => {
                            setRegisterBootcamp({
                              ...registerBootcamp,
                              phone: phone,
                            })
                          }
                        }/>
                    </div>


                    <label>{lang === 'es' ? 'Correo electrónico' : 'Email'}</label>
                    <input type="text"
                           style={{width: '100%', border: '1px solid #C5C5C5', marginBottom: '1em'}}
                           name='email'
                           onChange={handleOnChange}
                    />

                    <label>{lang === 'es' ? 'Contraseña' : 'Password'}</label>
                    <input type="text"
                           style={{width: '100%', border: '1px solid #C5C5C5', marginBottom: '1em'}}
                           name='password'
                           onChange={(e) => {
                             e.preventDefault()
                             setPassword(e.target.value)
                           }}
                    />

                    <label>{lang === 'es' ? '¿Actualmente estas trabajando?' : 'Are you currently working?'}</label>
                    <div className='checkbox-bootcamp'>
                      <label>
                        <input type="radio" name="isProfessional" value='true' id='si'
                               style={{display: 'revert', marginRight: '5px'}}
                               onChange={handleOnChange}/>
                        {lang === 'es' ? 'Si' : 'Yes'}

                      </label>
                      <label>
                        <input type="radio" name="isProfessional" value='false' id='no'
                               style={{display: 'revert', marginRight: '5px'}}
                               onChange={handleOnChange}/>
                        {lang === 'es' ? 'No' : 'No'}
                      </label>
                    </div>

                    {/*<label>{lang === 'es' ? 'Año de graduación en administración de empresas' : 'Graduation year in business administration'}</label>*/}
                    {/*<input type="text"*/}
                    {/*       style={{width: '100%', marginBottom: '1em', border: '1px solid #C5C5C5'}}*/}
                    {/*       name='anio_graduacion'*/}
                    {/*       maxLength={4}*/}
                    {/*       onChange={handleOnChange}*/}
                    {/*       onKeyPress={(e) => {*/}
                    {/*         if (!/[0-9]/.test(e.key)) {*/}
                    {/*           e.preventDefault();*/}
                    {/*         }*/}
                    {/*       }}*/}
                    {/*/>*/}


                    <label>{lang === 'es' ? 'Nivel de Estudios' : 'Education level'}</label>
                    <Select
                      options={genders}
                      placeholder={'Seleccione su nivel de estudios'}
                      isSearchable={true}
                      isClearable={true}
                      styles={customStyles}
                      onChange={handleOnChangeSelect}
                      name='focus'
                    />

                  </div>
                  <div>
                    <button type='submit' className="button-submit-bootcamp"
                            disabled={!registerBootcamp.lastName || !registerBootcamp.name || !registerBootcamp.phone || !registerBootcamp.email || !registerBootcamp.isProfessional || !registerBootcamp.focus}>
                                            <span style={{fontSize: '14px', display: 'flex', justifyContent: 'center'}}>
                                                {lang === 'es' ? 'Regístrate' : 'Sign up'}
                                            </span></button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
        {/*: redirect()}*/}
    </>
  )
}

export default RegisterBootcamp
