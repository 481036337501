import React, {useState} from "react";
import {logoutUser, changeview, changeLang} from "../../actions";
import {connect, useDispatch, useSelector} from "react-redux";
import en from "../../lang/en-US";
import es from "../../lang/es-COL"
import counterpart from "counterpart";
import profile from '../../assets/icons/profile.png'
import "./Headertop.css";
import {Avatar} from "@mui/material";
// import lápiz from '../../assets/icons/lapiz.svg'
import MenuIcon from '@mui/icons-material/Menu';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import SideBarEmpresaResponsive from "../header/SideBarEmpresaResponsive";
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import {Link} from "react-router-dom";

const Headertop = (props) => {
  
  const dispatch = useDispatch()

  if (!localStorage.getItem('langlocal')) {
    localStorage.setItem('langlocal', 'es')
  }
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });
  const [locallang, setLocallang] = useState(localStorage.getItem('langlocal'))
  const {lang} = useSelector(state => state)
  const cambiarlanges = (camlang) => {
    dispatch(changeLang(camlang))
    localStorage.setItem('langlocal', camlang)
    setLocallang(localStorage.getItem('langlocal'))
  }

  counterpart.registerTranslations('en', en)
  counterpart.registerTranslations('es', es)
  counterpart.setLocale(localStorage.getItem('langlocal'));

  const [buttonStyle, setButtonStyle] = useState('profilebutton')
  const [popUpProfile, setPopUpProfile] = useState(false)

  const handleClick = (e) => {
    e.preventDefault();
    if (buttonStyle === 'profilebutton') {
      setButtonStyle('profilebutton-active')
    } else {
      setButtonStyle('profilebutton')
    }
    setPopUpProfile(!popUpProfile)
  }
  const { user } = useSelector(state => state)
  
  
  const toggleDrawer =
    (anchor, open) =>
      (event) => {
        if (
          event.type === 'keydown' &&
          ((event).key === 'Tab' ||
            (event).key === 'Shift')
        ) {
          return;
        }

        setState({...state, [anchor]: open});
      };
  const list = (anchor) => (
    <Box
      sx={{width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250}}
      role="presentation"
    >
      <SideBarEmpresaResponsive></SideBarEmpresaResponsive>
    </Box>
  );
  return (
    <>
      {locallang ? null : null}
      <div
        style={{
          display: "flex",
          width: "100%",
          height: "80px",
          alignItems: "center",
          justifyContent: "end",
          marginBottom: "-10px",
        }}
      >
        <div className="Header-responsive" onClick={toggleDrawer("left", true)}
             style={{position: 'absolute', left: '35px', cursor: 'pointer'}}><MenuIcon sx={{fontSize: "2.5rem"}}/></div>

        <Drawer
          anchor={"left"}
          open={state["left"]}
          onClose={toggleDrawer("left", false)}
        >{list("left")}</Drawer>
        <div
          style={{
            display: "flex",
            color: "#C5C5C5",
            alignItems: "center",
            justifyContent: "center",
            marginRight: "17px",
            marginTop: "10px",
          }}
        >
          <h3 style={localStorage.getItem("langlocal") === "en"
                ? {
                  fontSize: "20px",
                  fontFamily: "Poppins",
                  cursor: "pointer",
                  color: "#202256",
                  fontWeight: "700",
                  userSelect: "none",
                  width: "40px",
                }
                : {cursor: "pointer", width: "40px"}
            }
            onClick={() => cambiarlanges("en")}
          >
            EN
          </h3>
          <h3
            style={
              localStorage.getItem("langlocal") === "es"
                ? {
                  fontSize: "20px",
                  fontFamily: "Poppins",
                  cursor: "pointer",
                  color: "#202256",
                  fontWeight: "700",
                  userSelect: "none",
                  width: "40px",
                }
                : {cursor: "pointer", width: "40px"}
            }
            onClick={() => cambiarlanges("es")}
          >
            ES
          </h3>
        </div>
        <button className={buttonStyle} onClick={(e) => handleClick(e)}>
          <img
            src={profile}
            alt="profile"
            style={{width: "25px", height: "25px"}}
          />
        </button>
        <hr/>
        {popUpProfile ? (
          <div style={{display: 'flex', justifyContent: 'end'}}>
            <div style={{
              backgroundColor: 'white',
              zIndex: '2',
              boxShadow: '0px 7px 32px rgba(0, 0, 0, 0.1)',
              height: '90vh',
              width: '395px',
              position: 'absolute',
              marginTop: '-15px'
            }} className='profilePopup'>
              <div>
                <button style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  color: '#202256',
                  borderRadius: '5px',
                  width: '20px',
                  height: '20px',
                  marginTop: '9px',
                  marginLeft: '11px',
                  backgroundColor: "transparent",
                  border: '2px solid #202256'
                }} onClick={(e) => handleClick(e)}>
                  x
                </button>
              </div>
              <div style={{display: "flex", justifyContent: "center", alignItems: "center", flexDirection: 'column'}}>
                <Avatar src={user?.avatar ? user?.avatar : null} style={{width: '156px', height: '156px'}}/>
                <div>
                  <Link to='/company/profile'>
                    <button
                      style={{
                        backgroundColor: "transparent",
                        height: "35px",
                        marginTop: "2em",
                        display: "flex",
                        justifyContent: 'space-between',
                        width: '131px',
                      }}
                    >
                      {
                        lang === "es" ? "Ver perfil" : "View profile"
                      }
                      <VisibilityOutlinedIcon/>
                    </button>
                  </Link>
                </div>
                {/**
                 <button
                 style={{
                  display: 'flex',
                  flexDirection: 'row',
                  marginTop: '19px',
                  textAlign: 'center',
                  marginBottom: '27px',
                  height: '20px',
                  alignItems: 'center',
                  backgroundColor: 'transparent',
                }}>
                 <img src={lápiz} alt={''} style={{width: '25px', height: '25px', marginRight: '10px'}}/>
                 <h2
                 style={{
                    marginTop: '10px',
                    fontSize: '16px',
                    color: '#202256',
                    fontWeight: '400',
                  }}
                 >{lang === 'es' ? 'Editar perfil' : 'Edit profile'}</h2>
                 </button>
                 */}
                <h2
                  style={{
                    fontSize: '16px',
                    fontWeight: '700',
                  }}
                >{user?.name}</h2>
                {/*>Descripción de la empresa en dos líneas.</h3>*/}
                <hr style={{
                  backgroundColor: '#D6D6D6',
                  width: '280px',
                  height: '1px',
                }}/>
                {/*UBICACIÓN*/}
                <div>
                  <h2
                    style={{
                      fontSize: '16px',
                      fontWeight: '700',
                      color: '#202256'
                    }}
                  >Email</h2>
                  <h3
                    style={{
                      fontSize: '14px',
                      fontWeight: '300',
                      color: '#5D5D5D',
                    }}
                  >{user?.email}</h3>
                  <hr style={{
                    backgroundColor: '#D6D6D6',
                    width: '280px',
                    height: '1px',
                  }}/>
                </div>
                {/*PAGINA*/}
                <div>
                  <h2
                    style={{
                      fontSize: '16px',
                      fontWeight: '700',
                      color: '#202256'
                    }}
                  >{lang === 'es' ? 'Oportunidades Publicadas:' : 'Published Opportunities:'}</h2>
                  <h3
                    style={{
                      fontSize: '14px',
                      fontWeight: '300',
                      color: '#5D5D5D',

                    }}
                  >{user?.projects?.length}</h3>
                  <hr style={{
                    backgroundColor: '#D6D6D6',
                    width: '280px',
                    height: '1px',
                  }}/>
                </div>
                {/*TAMAÑO*/}
                <div>
                  <h2
                    style={{
                      fontSize: '16px',
                      fontWeight: '700',
                      color: '#202256'
                    }}
                  >{lang === 'es' ? 'Oportunidades en Espera:' : 'Opportunities on Hold:'}</h2>
                  <h3
                    style={{
                      fontSize: '14px',
                      fontWeight: '300',
                      color: '#5D5D5D',

                    }}
                  >{
                      user?.projects?.filter(project => project.status === 'En Espera').length
                    }</h3>
                  <hr style={{
                    backgroundColor: '#D6D6D6',
                    width: '280px',
                    height: '1px',
                  }}/>
                </div>
                {/*SECTOR*/}
                <div>
                  <h2
                    style={{
                      fontSize: '16px',
                      fontWeight: '700',
                      color: '#202256'
                    }}
                  >{lang === 'es' ? 'Oportunidades Finalizados:' : 'Finished Opportunities:'}</h2>
                  <h3
                    style={{
                      fontSize: '14px',
                      fontWeight: '300',
                      color: '#5D5D5D',
                    }}
                  >{
                      
                      user?.projects?.filter(project => project.status === 'Finalizado').length
                    }</h3>
                  <hr style={{
                    backgroundColor: '#D6D6D6',
                    width: '280px',
                    height: '1px',
                  }}/>
                </div>
              </div>
            </div>
          </div>
        ) : null}
      </div>
      <hr/>
    </>
  );
};
const mapDispatchToProps = {
  logoutUser,
  changeview,
};
export default connect(null, mapDispatchToProps)(Headertop);
