import React, {useEffect, useState} from "react";
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import HorizontalRuleIcon from '@mui/icons-material/HorizontalRule';

export default function ScrollIndicator({scrollTop, scrollMiddle, scrollBottom}) {
  const [scroll, setScroll] = useState(0);
  const [scrollHeight, setScrollHeight] = useState(0);
  const [clientHeight, setClientHeight] = useState(0);
  const [scrollPercentage, setScrollPercentage] = useState(0);

  useEffect(() => {
    function onScroll() {
      setScroll(document.documentElement.scrollTop);
      setScrollHeight(document.documentElement.scrollHeight);
      setClientHeight(document.documentElement.clientHeight);
      setScrollPercentage(
        (document.documentElement.scrollTop / (document.documentElement.scrollHeight - document.documentElement.clientHeight)) * 100
      );
    }
    window.addEventListener("scroll", onScroll);
    return () => window.removeEventListener("scroll", onScroll);
  }, [scroll, scrollHeight, clientHeight, scrollPercentage]);

  return (
    <div className="scroll-indicator">
      <div><FiberManualRecordIcon style={{color: '#FFFFFF', fontSize: '15px', cursor: 'pointer'}} onClick={()=>scrollTop()}/></div>
      <div><HorizontalRuleIcon style={{color: scrollPercentage >= 8 ? '#FFFFFF' : 'rgba(255, 255, 255, 0.3)', fontSize: '15px'}}/></div>
      <div><HorizontalRuleIcon style={{color: scrollPercentage >= 16 ? '#FFFFFF' : 'rgba(255, 255, 255, 0.3)', fontSize: '15px'}}/></div>
      <div><HorizontalRuleIcon style={{color: scrollPercentage >= 20 ? '#FFFFFF' : 'rgba(255, 255, 255, 0.3)', fontSize: '15px'}}/></div>
      <div><FiberManualRecordIcon style={{color: scrollPercentage >= 20 ? '#FFFFFF' : 'rgba(255, 255, 255, 0.3)', fontSize: '15px', cursor: 'pointer'}} onClick={()=>scrollMiddle()}/></div>
      <div><HorizontalRuleIcon style={{color: scrollPercentage >= 35 ? '#FFFFFF' : 'rgba(255, 255, 255, 0.3)', fontSize: '15px'}}/></div>
      <div><HorizontalRuleIcon style={{color: scrollPercentage >= 40 ? '#FFFFFF' : 'rgba(255, 255, 255, 0.3)', fontSize: '15px'}}/></div>
      <div><HorizontalRuleIcon style={{color: scrollPercentage >= 40 ? '#FFFFFF' : 'rgba(255, 255, 255, 0.3)', fontSize: '15px'}}/></div>
      <div><FiberManualRecordIcon style={{color: scrollPercentage >= 60 ? '#FFFFFF' : 'rgba(255, 255, 255, 0.3)', fontSize: '15px', cursor: 'pointer'}} onClick={()=>scrollBottom()}/></div>
    </div>
  )
}

