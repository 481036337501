import React, {useContext} from "react";
import CircularProgress from "@mui/material/CircularProgress";
import userImg from "../../../../assets/icons/user.svg";
import { ReduxStateInterface } from "../../../types/reduxInterface";
import { CircularFrameContainer, AvatarContainer } from "./CircularFrameStyles";
import useCircularProgress from "./utils/useCircularProgress";
import {SchneiderContext} from "../../../context/SchneiderContext";

const CircularFrameNav = ({ user }: ReduxStateInterface) => {
  const {schneider} = useContext(SchneiderContext)
  const progress = useCircularProgress();
  return (
    <CircularFrameContainer color={schneider.color.primary}>
      <CircularProgress
        thickness={2}
        variant="determinate"
        value={100}
        id="second-circle-progress"
      />
      <CircularProgress
        thickness={1.5}
        variant="determinate"
        value={progress}
      />

      <AvatarContainer>
        <img src={user.avatar ? user.avatar : userImg} alt={"profile-pic"} />
      </AvatarContainer>
    </CircularFrameContainer>
  );
};

export default CircularFrameNav;
