import React , { useEffect,useState } from 'react';
import './Styles/LegalDocs.css'
import { connect } from "react-redux";
import Firma from "../../components/firma/Firma";
import { ConsultorProjects,ConsultorData, resetReq , projecTryOut, getProject } from "../../actions/index";
import Header from "../../components/Header/Header";
import { Link } from 'react-router-dom';
import en from "../../lang/en-US";
import es from "../../lang/es-COL"
import counterpart from "counterpart";
import Translate from "react-translate-component";
import CertificadoGenerator from '../Legal/Certificado'
import ConfidencialidadContrato from '../Legal/Confidencialidad'
import KpsGenerator from '../Legal/kps'

const LegalDocs = (props) => {
  const [estadodocumento, setestadodocumento] = useState('certificado')
    const {
        match: { params },
      } = props;
      const getMethodology = () => {
        if (!props.projectDetailsDone) {
          
          props.getProject(params.id);
        }
      };
    // const [flex,setFlex]=useState('flex')
      useEffect(() => {
        // const getUserProjects = () => {
        //   if (!props.done) {
        //     props.ConsultorProjects(props.user.projects);
        //   }
        // };
        const getUser = () => {
          props.ConsultorData(props.user.id);
        };
        
        // eslint-disable-next-line
        // getUserProjects();
        // eslint-disable-next-line
        getUser();// eslint-disable-next-line
        getMethodology(); // eslint-disable-next-line
      }, []);
      // const filtrarcarp=(e)=>{
      //   setFlex(e.target.value)
      // }
      counterpart.registerTranslations('en',en) 
      counterpart.registerTranslations('es',es)  
      counterpart.setLocale(localStorage.getItem('langlocal'));

    return (
        <>
        <Header /><div style={{textTransform:'capitalize',fontWeight:'900',fontSize:'15px'}}>
        <Link className="volver-leg-icon" to="/consultor/projects" style={{display:'flex',alignItems:'center',paddingTop:'2em',paddingBottom:'2em'}}>

&#x276e;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<p style={{display:'flex',margin:'0',fontSize:'23px',fontWeight:'900'}}>{<Translate content="DocumentosLegalesCoallyy" component="span"/>}</p>

</Link>
      </div>
      <div style={{display:'flex',width:'80%',marginLeft:'10%',justifyContent:'space-evenly'}}>
        <div style={{cursor:'pointer'}} onClick={()=>setestadodocumento('certificado')}>{<Translate style={{cursor:'pointer'}}  content="Certificadodeproyecto" component="span"/>}</div>
        <div style={{cursor:'pointer'}} onClick={()=>setestadodocumento('contrato')}>{<Translate style={{cursor:'pointer'}}  content="Contratodeconfidencialidad" component="span"/>}</div>
        <div style={{cursor:'pointer'}} onClick={()=>setestadodocumento('kps')}>KPS</div>
      </div>
      {/* <div className="legal-tittle-ini">
        <p className="legal-tittle-content">
          Documentos Legales Coally
        </p>
      </div> */}
   {/*     <div className="userprojects">

        {props.userProjects ? (
          <div className="userprojects__list">
            <div className="botones_filter_legal">
            <div >
            <div  className="ch-card-txt-legal">
                      <button value="cer" onClick={(e)=>filtrarcarp(e)} className="ch-card-txt-legal-docs" style={flex==='cer'?{borderBottom:'solid 4px #212156',zIndex:'10',position:'relative'}:{borderBottom:'0.2em solid #afafaf',zIndex:'10',position:'relative'}}>{<Translate style={{zIndex:'-1',position:'relative'}} content="Certificadodeproyecto" component="span"/>}</button>
                    </div>
                  </div>
                  <div>
                    <div className="ch-card-txt-legal">
                      <button value="contr" onClick={(e)=>filtrarcarp(e)} className="ch-card-txt-legal-docs" style={flex==='contr'?{borderBottom:'solid 4px #212156',zIndex:'10',position:'relative'}:{borderBottom:'0.2em solid #afafaf',zIndex:'10',position:'relative'}}>{<Translate style={{zIndex:'-1',position:'relative'}}  content="Contratodeconfidencialidad" component="span"/>}</button>
                    </div>
                  </div>
                  <div>
                    <div className="ch-card-txt-legal">
                        <button value="kps" onClick={(e)=>filtrarcarp(e)} className="ch-card-txt-legal-docs" style={flex==='kps'?{borderBottom:'solid 4px #212156'}:{borderBottom:'0.2em solid #afafaf'}}>KPS</button>
                        </div>
                  </div>
                 <div>
                    <div className="ch-card-txt-legal">
                    <button value="segu"onClick={(e)=>filtrarcarp(e)}className="ch-card-txt-legal-docs" style={flex==='segu'?{borderBottom:'solid 4px #212156'}:{borderBottom:'0.2em solid #afafaf'}}>{<Translate content="Seguridadsocial" component="span"/>}</button>
                    </div>
                  </div> */}
        {/*    </div>
            <div className="carpetas_filter_legal">
            <Link to={`/legal/certificate/${props.actual._id}` } style={flex==='cer'||flex==='flex'?{display:'flex'}:{display:'none'}} className="img-feed-comp3">
                        <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                          width="100" height="100"
                          viewBox="0 0 226 226"
                          style={{fill:'#000000'}}><g fill="none" fill-rule="nonzero" stroke="none" stroke-width="1" stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0" font-family="none" font-weight="none" font-size="none" text-anchor="none" style={{mixBlendMode:"normal"}}><path d="M0,226v-226h226v226z" fill="none"></path><g><path d="M7.0625,201.9875v-177.975h60.15273l16.95,16.95h123.47228v161.025z" fill="#ffffff"></path><path d="M66.63045,25.425l16.12228,16.12228l0.82773,0.82772h1.16955h121.475v158.2h-197.75v-175.15h58.15545M67.8,22.6h-62.15v180.8h203.4v-163.85h-124.3l-16.95,-16.95z" fill="#000000"></path><g><path d="M7.26873,201.9875l16.5658,-121.475h61.34205l16.95,-11.3h122.27447l-16.59687,132.775z" fill="#cccccc"></path><path d="M222.79928,70.625l-16.24375,129.95h-197.66807l16.17878,-118.65h59.68378h0.85598l0.7119,-0.4746l16.2381,-10.8254h120.2433M226,67.8h-124.3l-16.95,11.3h-62.15l-16.95,124.3h203.4l16.95,-135.6z" fill="#000000"></path></g></g></g></svg>
              <p className="ch-card-txt-legal-docs2">{<Translate content="Certificadodeproyecto" component="span"/>}</p>
            </Link>
            <Link to={`/legal/contract/confidentiality/${props.actual._id}` } style={flex==='contr'||flex==='flex'?{display:'flex'}:{display:'none'}} className="img-feed-comp3">
                        <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                          width="100" height="100"
                          viewBox="0 0 226 226"
                          style={{fill:'#000000'}}><g fill="none" fill-rule="nonzero" stroke="none" stroke-width="1" stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0" font-family="none" font-weight="none" font-size="none" text-anchor="none" style={{mixBlendMode:"normal"}}><path d="M0,226v-226h226v226z" fill="none"></path><g><path d="M7.0625,201.9875v-177.975h60.15273l16.95,16.95h123.47228v161.025z" fill="#ffffff"></path><path d="M66.63045,25.425l16.12228,16.12228l0.82773,0.82772h1.16955h121.475v158.2h-197.75v-175.15h58.15545M67.8,22.6h-62.15v180.8h203.4v-163.85h-124.3l-16.95,-16.95z" fill="#000000"></path><g><path d="M7.26873,201.9875l16.5658,-121.475h61.34205l16.95,-11.3h122.27447l-16.59687,132.775z" fill="#cccccc"></path><path d="M222.79928,70.625l-16.24375,129.95h-197.66807l16.17878,-118.65h59.68378h0.85598l0.7119,-0.4746l16.2381,-10.8254h120.2433M226,67.8h-124.3l-16.95,11.3h-62.15l-16.95,124.3h203.4l16.95,-135.6z" fill="#000000"></path></g></g></g></svg>
              <p className="ch-card-txt-legal-docs2">{<Translate content="Contratodeconfidencialidad" component="span"/>}</p>
            </Link>
            <Link to={`/legal/contract/Kps/${props.actual._id}` } style={flex==='kps'||flex==='flex'?{display:'flex'}:{display:'none'}} className="img-feed-comp3">
                        <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                          width="100" height="100"
                          viewBox="0 0 226 226"
                          style={{fill:'#000000'}}><g fill="none" fill-rule="nonzero" stroke="none" stroke-width="1" stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0" font-family="none" font-weight="none" font-size="none" text-anchor="none" style={{mixBlendMode:"normal"}}><path d="M0,226v-226h226v226z" fill="none"></path><g><path d="M7.0625,201.9875v-177.975h60.15273l16.95,16.95h123.47228v161.025z" fill="#ffffff"></path><path d="M66.63045,25.425l16.12228,16.12228l0.82773,0.82772h1.16955h121.475v158.2h-197.75v-175.15h58.15545M67.8,22.6h-62.15v180.8h203.4v-163.85h-124.3l-16.95,-16.95z" fill="#000000"></path><g><path d="M7.26873,201.9875l16.5658,-121.475h61.34205l16.95,-11.3h122.27447l-16.59687,132.775z" fill="#cccccc"></path><path d="M222.79928,70.625l-16.24375,129.95h-197.66807l16.17878,-118.65h59.68378h0.85598l0.7119,-0.4746l16.2381,-10.8254h120.2433M226,67.8h-124.3l-16.95,11.3h-62.15l-16.95,124.3h203.4l16.95,-135.6z" fill="#000000"></path></g></g></g></svg>
              <p className="ch-card-txt-legal-docs2">{<Translate content="Contratodeprestacióndeservicios" component="span"/>}</p>
            </Link>
             <Link to={`/legal/Contract/${props.actual._id}` } style={flex==='segu'||flex==='flex'?{display:'flex'}:{display:'none'}} className="img-feed-comp3">
                        <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                          width="100" height="100"
                          viewBox="0 0 226 226"
                          style={{fill:'#000000'}}><g fill="none" fill-rule="nonzero" stroke="none" stroke-width="1" stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0" font-family="none" font-weight="none" font-size="none" text-anchor="none" style={{mixBlendMode:"normal"}}><path d="M0,226v-226h226v226z" fill="none"></path><g><path d="M7.0625,201.9875v-177.975h60.15273l16.95,16.95h123.47228v161.025z" fill="#ffffff"></path><path d="M66.63045,25.425l16.12228,16.12228l0.82773,0.82772h1.16955h121.475v158.2h-197.75v-175.15h58.15545M67.8,22.6h-62.15v180.8h203.4v-163.85h-124.3l-16.95,-16.95z" fill="#000000"></path><g><path d="M7.26873,201.9875l16.5658,-121.475h61.34205l16.95,-11.3h122.27447l-16.59687,132.775z" fill="#cccccc"></path><path d="M222.79928,70.625l-16.24375,129.95h-197.66807l16.17878,-118.65h59.68378h0.85598l0.7119,-0.4746l16.2381,-10.8254h120.2433M226,67.8h-124.3l-16.95,11.3h-62.15l-16.95,124.3h203.4l16.95,-135.6z" fill="#000000"></path></g></g></g></svg>
              <p className="ch-card-txt-legal-docs2">{<Translate content="Seguridadsocial" component="span"/>}</p>
            </Link> 
            </div>*/}
            {/* <div>
              <div className="ch-cards-container inline-block">
                <div className="ch-card">
                  <Link to={`/legal/certificate/${props.actual._id}`}>
                    <img className="img-feed-comp" src={legal1} alt="" />
                    <div className="ch-card-txt-legal">
                      <p className="ch-card-txt-legal-docs">Certificado de proyecto</p>
                    </div>
                  </Link>
                </div>

                <div className="ch-card">
                  <Link to={`/legal/contract/confidentiality/${props.actual._id}`}>
                    <img className="img-feed-comp" src={legal3} alt="" />
                    <div className="ch-card-txt-legal">
                      <p className="ch-card-txt-legal-docs">Contrato de confidencialidad</p>
                    </div>
                  </Link>
                </div>

                <div className="ch-card">
                <Link to={`/legal/contract/Kps/${props.actual._id}`}>
                {/* <img className="img-feed-comp" src={legal2} alt="" />  
                <div className="ch-card-txt-legal">
                <button className="ch-card-txt-legal-docs3">KPS</button>
                <div className="img-feed-comp2" style={{display:'none'}}>
                <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                width="40" height="40"
                viewBox="0 0 226 226"
                style={{fill:'#000000'}}><g fill="none" fill-rule="nonzero" stroke="none" stroke-width="1" stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0" font-family="none" font-weight="none" font-size="none" text-anchor="none" style={{mixBlendMode:"normal"}}><path d="M0,226v-226h226v226z" fill="none"></path><g><path d="M7.0625,201.9875v-177.975h60.15273l16.95,16.95h123.47228v161.025z" fill="#ffffff"></path><path d="M66.63045,25.425l16.12228,16.12228l0.82773,0.82772h1.16955h121.475v158.2h-197.75v-175.15h58.15545M67.8,22.6h-62.15v180.8h203.4v-163.85h-124.3l-16.95,-16.95z" fill="#000000"></path><g><path d="M7.26873,201.9875l16.5658,-121.475h61.34205l16.95,-11.3h122.27447l-16.59687,132.775z" fill="#cccccc"></path><path d="M222.79928,70.625l-16.24375,129.95h-197.66807l16.17878,-118.65h59.68378h0.85598l0.7119,-0.4746l16.2381,-10.8254h120.2433M226,67.8h-124.3l-16.95,11.3h-62.15l-16.95,124.3h203.4l16.95,-135.6z" fill="#000000"></path></g></g></g></svg>
                <p className="ch-card-txt-legal-docs2">Contrato de prestación de servicios</p>
                </div>
                </div>
                </Link>
                </div>
                
                <div className="ch-card">
                <Link to={`/legal/Contract/${props.actual._id}`}>
                {/* <img className="img-feed-comp" src="https://coally-images.s3.amazonaws.com/seguridad_social.png" alt="" /> 
                <div className="ch-card-txt-legal">
                <button className="ch-card-txt-legal-docs3">Seguridad social</button>
                <div className="img-feed-comp2">
                <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                width="40" height="40"
                viewBox="0 0 226 226"
                style={{fill:'#000000'}}><g fill="none" fill-rule="nonzero" stroke="none" stroke-width="1" stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0" font-family="none" font-weight="none" font-size="none" text-anchor="none" style={{mixBlendMode:"normal"}}><path d="M0,226v-226h226v226z" fill="none"></path><g><path d="M7.0625,201.9875v-177.975h60.15273l16.95,16.95h123.47228v161.025z" fill="#ffffff"></path><path d="M66.63045,25.425l16.12228,16.12228l0.82773,0.82772h1.16955h121.475v158.2h-197.75v-175.15h58.15545M67.8,22.6h-62.15v180.8h203.4v-163.85h-124.3l-16.95,-16.95z" fill="#000000"></path><g><path d="M7.26873,201.9875l16.5658,-121.475h61.34205l16.95,-11.3h122.27447l-16.59687,132.775z" fill="#cccccc"></path><path d="M222.79928,70.625l-16.24375,129.95h-197.66807l16.17878,-118.65h59.68378h0.85598l0.7119,-0.4746l16.2381,-10.8254h120.2433M226,67.8h-124.3l-16.95,11.3h-62.15l-16.95,124.3h203.4l16.95,-135.6z" fill="#000000"></path></g></g></g></svg>
                <p className="ch-card-txt-legal-docs2">Seguridad social</p>
                </div>
                </div>
                </Link>
                </div>
                
                </div> 
                
            </div>*/}
            {/*                 <Link className="services-btn" to={`/legal/certificate/${props.actual._id}`}>Certificado</Link>
                <Link className="services-btn" to={`/legal/contract/confidentiality/${props.actual._id}`}>confidencialidad</Link>
              <Link className="services-btn" to={`/legal/contract/Kps/${props.actual._id}`}>Kps</Link> 
          </div>
        ) : (
          <div className="n-projects">
            <h1>{<Translate content="Aunnotienesproyectosenproceso" component="span"/>}</h1>

          </div>
        )}*/}
        {estadodocumento==="certificado"?<CertificadoGenerator></CertificadoGenerator>:null}
        {estadodocumento==="contrato"?<ConfidencialidadContrato></ConfidencialidadContrato>:null}
        {estadodocumento==="kps"?<KpsGenerator></KpsGenerator>:null}
        <div className="contenedor-firmas">
        <p style={{color:'black'}}>{<Translate content="Añadeunafirmaestaserautilizadaenlosdocumentoslegaless" component="span"/>}</p>
        {/* <div style={{ background:'white', maxWidth: "400px", minWidth: "400px", maxHeight: "100px" , minHeight: "100px" ,position:'absolute',zIndex:'-1',color:'rgb(151, 239, 255)',overflow:'hidden'}}>marcadeagua-marcadeagua-marcadeagua-marcadeagua-marcadeagua-marcadeagua-marcadeagua-marcadeagua-marcadeagua-marcadeagua-marcadeagua-marcadeagua-marcadeagua-marcadeagua-marcadeagua-marcadeagua-
        </div>  */}
        <div style={{ border: "1px solid #F07130;", maxWidth: "400px", minWidth: "400px", maxHeight: "100px",background:'rgba(255, 255, 255)',borderRadius:'10px'}}>
          <Firma idUsuario={props.user.id}/>


        </div>

      </div>
      {/* </div> */}
    </>
    );
}

function mapStateToProps(state) {
    return {
      user: state.user,
      actual: state.ActualProject,
      project: state.projectsConsultor,
      userProjects: state.userProjects,
      done: state.doneUserProject,
      userform: state.userform,
    };
  }
  const mapDispatchToProps = {
    ConsultorProjects,
    ConsultorData,
    resetReq,
    projecTryOut,
    getProject
  };
  export default connect(mapStateToProps, mapDispatchToProps)(LegalDocs);
  