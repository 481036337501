import React, { useState, useEffect, useContext } from "react";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import { videoSwitcher, video } from "./utils/videosGenerator";
import Sidebar from '../../../components/sidebar/Sidebar';
import Navbar from '../../../components/navbar/Navbar'
import { useSelector } from "react-redux";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AccordionDetails from "@mui/material/AccordionDetails";
import SnakeProgress from "./snakeprogress/SnakeProgress";
import { BodyWebPapa, ComponentWrapper } from "../../../styles/GlobalStyles";
import { Alarm, StyledEducationSubcontainer } from "./EducationStyle";
import { CustomizableTitle } from "../../../styles/TitleStyles";
import { SchneiderContext } from "../../../context/SchneiderContext";
import { StyledCoursesSubcontainer, StyledSubtitleCourses , StyledAccordion, StyledImage} from "./CoursesStyle";


const CoursesPage = () => {
  const { sector } = useParams<{ sector: string }>();
  const [videos, setVideos] = useState<video[]>([]);
  const lang = useSelector((state: any) => state.lang);
  const { schneider } = useContext(SchneiderContext);
  useEffect(() => {
    if (sector) {
      const video = videoSwitcher(sector);
      setVideos(Array.isArray(video) ? video : [video]);
    }

  }, [sector]);


  return (
    <ComponentWrapper>
      <Sidebar location={'formación' as any} />
      <BodyWebPapa>
        <Navbar locate={'formación'} />
        <StyledEducationSubcontainer>
          {videos?.length &&
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Link to={'/schneider/formacion'}>
                <div style={{ cursor: "pointer", color: schneider.color.primary }}>
                  <ArrowBackIosIcon />
                </div>
              </Link>
              <CustomizableTitle size={'40px'}>
                {lang === 'es' ? videos[0].title : videos[0].title_en}
              </CustomizableTitle>
            </div>
          }
          <StyledCoursesSubcontainer>
            {videos?.map((video, index) => {
              return (<div key={video.title}>
                <div style={{ width: '100%' }}>
                  <StyledAccordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <StyledImage
                        src={'https://d3puay5pkxu9s4.cloudfront.net/pages/edutin-isotype-positive.svg'}
                        alt={'logo'} />
                      <div>
                        <CustomizableTitle color={'#393939'}>
                          {lang === 'es' ? video.title : video.title_en}
                        </CustomizableTitle>
                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                          <Alarm color={schneider.color.primary} />
                          <StyledSubtitleCourses>
                            {video.duration}
                          </StyledSubtitleCourses>
                        </div>
                      </div>
                    </AccordionSummary>
                    <AccordionDetails>
                      <div style={{ marginTop: '45px' }}>
                        <SnakeProgress data={video.subModules} />
                      </div>
                    </AccordionDetails>
                  </StyledAccordion>
                </div>
                {
                  video.subModules2 && <div style={{ width: '100%' }}>
                    <StyledAccordion                  >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <StyledImage
                          src={'https://d3puay5pkxu9s4.cloudfront.net/pages/edutin-isotype-positive.svg'}
                          alt={'logo'} />
                        <div>
                          <CustomizableTitle color={'#393939'}>
                            {lang === 'es' ? video.title2 : video.title2_en}
                          </CustomizableTitle>
                          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                            <Alarm color={schneider.color.primary} />
                            <StyledSubtitleCourses>
                              {video.duration2}
                            </StyledSubtitleCourses>
                          </div>
                        </div>
                      </AccordionSummary>
                      <AccordionDetails>
                        <div style={{ marginTop: '45px' }}>
                          <SnakeProgress data={video.subModules2} />
                        </div>
                      </AccordionDetails>
                    </StyledAccordion>
                  </div>
                }
              </div>
              )
            })}
          </StyledCoursesSubcontainer>
        </StyledEducationSubcontainer>
      </BodyWebPapa>
    </ComponentWrapper>
  )
}

export default CoursesPage