import React, {useEffect, useState} from 'react';
import clsx from 'clsx';
import {makeStyles} from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Badge from '@material-ui/core/Badge';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
//import Link from '@material-ui/core/Link';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import {mainListItems} from './lisItems';
import Chart from './Chart';
import PieChartUsers from './PieChart';

import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import GroupIcon from '@material-ui/icons/Group';
//import AccountTreeIcon from '@material-ui/icons/AccountTree';
//import AddIcon from '@material-ui/icons/Add';
import "./Styles/styles.css";
import en from "../../lang/en-US";
import es from "../../lang/es-COL"
import counterpart from "counterpart";
import Translate from "react-translate-component";
import Registers from './Registers';
import axios from "axios";
import {NavLink} from 'react-router-dom';
import LineChartGrap from './LineChart';
//nico
import TextField from '@mui/material/TextField';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {DatePicker} from '@mui/x-date-pickers/DatePicker';
import Button from '@mui/material/Button';
import {CSVLink} from "react-csv";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  toolbar: {
    paddingRight: 24,
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: 'none',
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4)
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 320,
  },
  fixedHeight2: {
    height: 350,
  }
}));

export default function Dashboard(props) {

  const [data, setData] = useState([]);
  const [data2, setData2] = useState([]);

  const headers = [
    {label: "id", key: "_id"},
    {label: "Nombre", key: "username"},
    {label: "Email", key: "email"},
    {label: "Fecha_Registro", key: "fecha_registro"}
  ]
  const headers2 = [
    {label: "id", key: "_id"},
    {label: "Nombre_Oportunidad", key: "nombre"},
    {label: "Estatus", key: "status"},
    {label: "Fecha_Inicio", key: "fecha_inicio"}
  ]
  const csvReport = {
    filename: 'Reporte_Usuarios.csv',
    headers: headers,
    data: data
  }
  const csvReport2 = {
    filename: 'Reporte_Empleos.csv',
    headers: headers2,
    data: data2
  }
  useEffect(() => {
    axios.get('https://findally-backend.herokuapp.com/users/export')
      .then(res => {
        setData(res.data);
      })
      .catch(err => {
        console.log(err);
      })
    //eslint-disable-next-line
  }, [])

  useEffect(() => {
    axios.get('https://findally-backend.herokuapp.com/api/Project/export')
      .then(res => {
        setData2(res.data);
        console.log('datita', res.data)
      })
      .catch(err => {
        console.log(err);
      })
    //eslint-disable-next-line
  }, [])

  const [value, setValue] = React.useState({
    start: null,
    end: null
  });
  const setTimes = () => {
    let startdate = value.start.$y + "/" + Number(value.start.$M + 1) + "/" + value.start.$D
    let enddate = value.end.$y + "/" + Number(value.end.$M + 1) + "/" + value.end.$D
    axios.post(`https://findally-backend.herokuapp.com/api/statistics/timelaps/`, {"start": startdate, "end": enddate})
      .then((e) => {
        // console.log(e.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }
  const classes = useStyles();
  const [open, setOpen] = React.useState(true);
  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };
  const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);
  const fixedHeightPaper2 = clsx(classes.paper, classes.fixedHeight2);
  const [statistics, setStatistics] = useState({})

  useEffect(() => {
    axios.get(`https://findally-backend.herokuapp.com/api/statistics/`)
      .then((e) => {
        console.log(e.data, 'statistics')
        setStatistics(e.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  counterpart.registerTranslations('en', en)
  counterpart.registerTranslations('es', es)
  counterpart.setLocale(localStorage.getItem('langlocal'));
  return (
    <div className={classes.root}>
      <CssBaseline/>
      <AppBar position="absolute" className={clsx(classes.appBar, open && classes.appBarShift)}>
        <Toolbar className={classes.toolbar}>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            className={clsx(classes.menuButton, open && classes.menuButtonHidden)}
          >
            <MenuIcon/>
          </IconButton>
          <Typography component="h1" variant="h6" color="inherit" noWrap className={classes.title}>
            {<Translate content="Dashboard" component="span"/>}
          </Typography>
          <IconButton color="inherit">
            <Badge color="secondary">
              <li className="header__icons__group-li">
                <GroupIcon style={{color: '#FFFFFF'}}/>
                <p>{<Translate content="admin" component="span"/>}</p>
                <ul className="header__display-menu_admin">
                  <li className="header__display__menu-perfil-ver-perfil">
                    <AccountCircleIcon style={{color: '#540E87', fontSize: '40px'}}/>
                    <div className="header__display__menu__perfil-data">
                      <p className="header-display-name-coallier">ADMIN</p>
                    </div>
                  </li>
                  <NavLink to="/">
                    <li className="header__display__menu-item-cerrar">
                      <ExitToAppIcon style={{color: '#540E87', fontSize: '20px'}}/>
                      <p className="menu-text">Cerrar Sesion</p>
                    </li>
                  </NavLink>
                  <p className="header__display_copy">© 2021 CoAlly | Powered by CoAlly</p>
                </ul>
              </li>
            </Badge>
          </IconButton>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        classes={{
          paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
        }}
        open={open}
      >
        <div className={classes.toolbarIcon}>
          <IconButton onClick={handleDrawerClose}>
            <ChevronLeftIcon/>
          </IconButton>
        </div>
        <Divider/>
        <List>{mainListItems}</List>
        <Divider/>
      </Drawer>
      <main className={classes.content}>
        <div className={classes.appBarSpacer}/>
        {/*Grafica*/}
        <Container maxWidth="lg" className={classes.container}>
          <Grid container spacing={3}>
            {/* Chart */}
            <Grid item xs={12} md={8} lg={9}>
              <Paper className={fixedHeightPaper}>
                <Chart Wait_projects={statistics.Wait_projects} Development_projects={statistics.Development_projects}
                       Finished_projects={statistics.Finished_projects}/>
              </Paper>
            </Grid>
            <Grid item xs={12} md={4} lg={3}>
              <Paper className={fixedHeightPaper2}>
                <Registers Total_users={statistics.Total_users} Total_projects={statistics.Total_projects}
                           Total_professionals={statistics.Total_profesional}
                           Total_companies={statistics.Total_company}/>
              </Paper>
            </Grid>
          </Grid>
        </Container>
        {/*Grafica*/}
        <Container maxWidth="lg" className={classes.container}>
          <div style={{display: 'flex', flexDirection: 'column'}}>
            <label style={{
              backgroundColor: '#F07130',
              borderRadius: '5px',
              color: 'white',
              fontSize: '14px',
              fontWeight: 400,
              height: '33px',
              width: '250px',
              marginBottom: '20px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              textAlign: 'center'
            }}>
              <CSVLink {...csvReport} style={{textDecoration: 'none', color: 'white'}}>
                Descargar lista de usuarios
              </CSVLink>
            </label>
            <label style={{
              backgroundColor: '#F07130',
              borderRadius: '5px',
              color: 'white',
              fontSize: '14px',
              fontWeight: 400,
              height: '50px',
              width: '250px',
              marginBottom: '20px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              textAlign: 'center'
            }}>
              <CSVLink  {...csvReport2} style={{textDecoration: 'none', color: 'white'}}>
                Exportación de datos de colocación de empleo
              </CSVLink>
            </label>
          </div>
          <Grid container spacing={3}>
            {/* Chart */}
            <Grid item xs={12} md={8} lg={9}>
              <Paper className={fixedHeightPaper}>
                <PieChartUsers Total_profesional={(statistics.Total_profesional * 100) / statistics.Total_users}
                               Total_company={(statistics.Total_company * 100) / statistics.Total_users}
                               Empty_roles={(statistics.Empty_roles * 100) / statistics.Total_users}/>
              </Paper>
            </Grid>
          </Grid>
        </Container>
        {/*Grafica*/
        }
        <Container maxWidth="lg" className={classes.container}>
          <Grid container spacing={3}>
            {/* Chart */}
            <Grid item xs={12} md={8} lg={9}>
              <Paper className={fixedHeightPaper}>
                <LineChartGrap data={statistics?.UsersLastSixMonth ? statistics?.UsersLastSixMonth : []}
                               title={"Users Last Six Months"} type={true}/>
              </Paper>
            </Grid>
          </Grid>
        </Container>
        {/*Grafica*/
        }
        <Container maxWidth="lg" className={classes.container}>
          <Grid container spacing={3}>
            {/* Chart */}
            <Grid item xs={12} md={8} lg={9}>
              <Paper className={fixedHeightPaper}>
                <LineChartGrap data={statistics?.ProjectsLastSixMonth ? statistics?.ProjectsLastSixMonth : []}
                               title={"Projects Last Six Months"}/>
              </Paper>
            </Grid>
          </Grid>
        </Container>

        <Container maxWidth="lg" className={classes.container}>
          <Grid container spacing={3}>
            {/* Chart */}
            <Grid item xs={12} md={8} lg={9}>
              <div style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label="Start Date"
                    value={value.start}
                    onChange={(start) => {
                      setValue({...value, start});
                    }}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </LocalizationProvider>

                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label="End Date"
                    value={value.end}
                    onChange={(end) => {
                      setValue({...value, end});
                    }}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </LocalizationProvider>
                <Button variant="outlined" onClick={setTimes}>Search</Button>
              </div>
            </Grid>
          </Grid>
        </Container>
      </main>

    </div>
  )
    ;
}