import styled from "styled-components";

const CustomizableTitle = styled.h1.attrs({
  "data-testid": "CustomizableTitle",
})<{ weight?: string; size?: string; color?: string ; margin?:string; align?: string}>`
  font-weight: ${(props) => (props.weight ? props.weight : "700")};
  font-size: ${(props) => (props.size ? props.size : "24px")};
  color: ${(props) => (props.color ? props.color : "")};
  font-family: "Poppins", sans-serif;
  text-align: ${(props) => (props.align ? props.align : "center")};
  margin: ${(props) => (props.margin ? props.margin : "0px")};
`;

const SubtitleText = styled.h2``;

export { CustomizableTitle, SubtitleText };
