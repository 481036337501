/*eslint-disable*/
import React, { useContext, useEffect, useState } from 'react';
import { UniversityContext } from "../context/UniversitiesContext";
import UniversitiesNavbar from "../components/navbar/UniversitiesNavbar";
import UniversitiesSideBar from "../components/sidebar/UniversitiesSideBar";
import DashboardCard from "../components/dashboardCard/DashboardCard";
import Chart1, { BarChart, Chart2, PaginatedBarChart } from "../components/charts/chart1";
import ColaboratorInvite from "../components/colaboratorInvitation/ColaboratorInvite";
// import { useSelector } from 'react-redux';
import axios from 'axios';
import Select from "react-select"
import CardInfoDashboard from '../components/CardInfoDashboard/CardInfoDashboard';
import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined';
import PermContactCalendarOutlinedIcon from '@mui/icons-material/PermContactCalendarOutlined';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import GroupOutlinedIcon from '@mui/icons-material/GroupOutlined';
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import 'react-calendar/dist/Calendar.css';
import UserRegister from './tablas/UserRegister';
import { useSelector } from 'react-redux';
import UserAplication from './tablas/UserAplication';

const UniversitiesAdmin = () => {
  const { university } = useContext(UniversityContext);
  const [applicants, setApplicants] = useState();
  const [students, setStudents] = useState();
  const [contacted, setContacted] = useState();
  const [professions, setProfessions] = useState();
  const [graduated, setGraduated] = useState();
  const [careers, setCareers] = useState();
  const [filter, setFilter] = useState()
  const [stages, setStages] = useState()
  const [value, onChange] = useState([new Date(
    new Date().getFullYear(),
    new Date().getMonth(),
    new Date().getDate() - 7
  ), new Date()]);
  const [aplicantesFecha, setAplicantesFecha] = useState()
  const [options, setOptions] = useState([])
  const { lang } = useSelector(state => state)
  
  const formatDate = (date) => {
    return date.toISOString().slice(0, 10);
  }

  const fecha = formatDate(value[0]) + ' ' + formatDate(value[1])

  //carga de carreras
  // useEffect(() => {
  //   axios.get(`https://findally-backend.herokuapp.com/profesions`)
  //     .then(res => {
  //       console.log(res.data)
  //       setProfessions(res.data);
  //     })
  //     .catch(err => console.log(err))
  // }, []);

  useEffect(() => {
    axios.get(`https://findally-backend.herokuapp.com/api/university/careers?university=${university.name}`)
    .then(res => {
            const options = res?.data?.data?.map((option) => {
              return option
            })
            setOptions(options)
            setProfessions(options)
          })
      .catch(err => console.log(err))
  }, [university.name])
    // if (university.route === 'umariana') {
    //   axios.get(`https://findally-backend.herokuapp.com/api/university/unimariana`)
    //     .then(res => {
    //       const options = res?.data?.data?.map((option) => {
    //         return option.name
    //       })
    //       setOptions(options)
    //     })
    // } else {
    //   setOptions(['Administración', 'Marketing', 'Programación', 'Diseño', 'Finanzas'])
    // }


  //carga de info para cards de aplicantes, estudiantes y contactados
  useEffect(() => {
    if (university.name) {
      axios.get(`https://findally-backend.herokuapp.com/api/university/new/statistics?university=${university.name}`)
        .then(res => {
          console.log(res.data)
          setApplicants(res.data.applicantCount);
          setStudents(res.data.studentCount);
          setContacted(res.data.hiredCount);
          setGraduated(res.data.graduatedCount);
        })
        .catch(err => console.log(err))
      axios.get(`https://findally-backend.herokuapp.com/api/university/stages?university=${university.name}`)
        .then(res => {
          setStages(res.data)
        })
        .catch(err => console.log(err))
    };
  }, [university.name]);

  //carga de info para grafico de barras



  // usando las options hacer un post por cada una y guardar el resultado en un array el count de cada uno


  useEffect(() => {
    if (!filter && university.name) {
      Promise.all(
        options.map(option => {
          return axios.post(
            'https://findally-backend.herokuapp.com/api/university/careers',
            { name: university.name, career: option.toLocaleUpperCase() }
          );
        })
      ).then(responses => {
        const careersData = responses.map(response => response.data.count);
        setCareers(careersData);
      }).catch(error => {
        console.error(error);
      });
    }
  }, [filter, university.name , options ]);


  useEffect(() => {
    if (university.name) {
      axios.post(`https://findally-backend.herokuapp.com/api/university/applicantByDate`, {
        startDate: formatDate(value[0]),
        endDate: formatDate(value[1]),
        university: university.name
      })
        .then(res => {
          console.log(res.data)
          setAplicantesFecha(res.data)
        }
        )
        .catch(err => console.log(err))
    }
  }, [value, university.name])



  const handleOnChange = (e) => {
    if (e !== null) {
      setFilter(e.value);
    } else {
      setFilter('');
    }
  }

  // const handleOnClick = (e) => {
  //   axios.post(`https://findally-backend.herokuapp.com/api/university/careers`, {
  //     name: university.name,
  //     career: filter
  //   })
  //     .then(res => {
  //       setCareers(res.data);
  //       setGole(true)
  //     })
  //     .catch(err => console.log(err));
  // }



  return (
    <>
      {university && university.color && (
        <div>
          <UniversitiesSideBar location={'universitie admin'} />
          <div className="BodyWeb"
            style={{
              padding: "0rem 2rem", position: "absolute", right: "0",
            }}>
            <UniversitiesNavbar locate={'home'} />
            <div style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              width: '100%'
            }}>

              <div style={{
                display: 'flex',
                justifyContent: 'space-evenly',
                width: '100%',
              }}>
                <CardInfoDashboard title={lang === 'es' ? 'Estudiantes inscritos' : 'Enrolled students'} color={'#1BD084'} value={students} Icon={CalendarTodayOutlinedIcon} />
                <CardInfoDashboard title={lang === 'es' ? 'Graduados inscritos' : 'Enrolled graduates'} color={'#9974ea'} value={graduated} Icon={GroupOutlinedIcon} />
                <CardInfoDashboard title={lang === 'es' ? 'Aplicantes' : 'Applicants'} color={'#4E36E2'} value={applicants} Icon={PersonOutlineOutlinedIcon} />
                <CardInfoDashboard title={lang === 'es' ? 'Personas Contactadas' : 'People Contacted'} color={'#8BC740'} value={contacted} Icon={PermContactCalendarOutlinedIcon} />
              </div>
              <div style={{ width: '80%' }}>
                <DashboardCard title={lang === 'es' ? 'Usuarios Registrados' : 'Registered Users'}>
                  <UserRegister university={university.name} />
                </DashboardCard>
              </div>
              <div style={{ width: '80%' }}>
                <DashboardCard title={lang === 'es' ? 'Usuarios que aplicaron' : 'Users who applied'}>
                  <UserAplication university={university.name} />
                </DashboardCard>
              </div>
              <div style={{
                display: 'flex',
                width: '100%',
                alignItems: 'center',
                justifyContent: 'space-evenly',
              }}>
                <label style={{
                  width: '35%',
                }}>
                  <Select
                    placeholder={lang === 'es' ? 'Selecciona un programa académico' : 'Select an academic program'}
                    isDisabled={false}
                    isLoading={false}
                    isClearable={true}
                    isRtl={false}
                    isSearchable={true}
                    name="categoria"
                    options={professions
                      ? professions.map((career) => {
                        return { value: career, label: career.charAt(0).toUpperCase() + career.slice(1).toLowerCase() }
                      })
                      : []
                    }
                    onChange={handleOnChange}
                  />
                  {/* <input
                    style={{
                      width: '100%',
                      height: '40px',
                      borderRadius: '5px',
                      border: '1px solid #ccc',
                      paddingLeft: '10px',
                      fontSize: '16px',
                      outline: 'none',
                      color: '#555'
                    }}
                    type="text"
                    placeholder={lang === 'es' ? 'Escribe un programa académico' : 'write an academic program'}
                    onChange={handleOnChange}
                  />
                  <button
                    style={{
                      width: '100%',
                      height: '40px',
                      borderRadius: '5px',
                      border: '1px solid #ccc',
                      paddingLeft: '10px',
                      fontSize: '16px',
                      outline: 'none',
                      backgroundColor: university.color.primary,
                      color: 'white'
                    }}
                    onClick={handleOnClick}
                  >{lang === 'es' ? 'Buscar' : 'Search'} </button> */}

                </label>
              </div>
              <div style={{ width: '80%' }}>
                {/*TODO: LABURAR ACA*/}

                <DashboardCard title={lang === 'es' ? 'Aplicantes por carrera' : 'Applicants by career'}>
                  <BarChart name={filter ? filter : options} careers={careers} />
                </DashboardCard>
                <DashboardCard title={lang === 'es' ? 'Aplicantes por etapa de proceso' : 'Applicants by process stage'}>
                  <Chart1 stages={stages} />
                </DashboardCard>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <DateRangePicker
                    onChange={onChange}
                    value={value}
                    format={"dd/MM/yyyy"}
                    calendarAriaLabel={"Calendario"}
                    dayAriaLabel={"Día"}
                    monthAriaLabel={"Mes"}
                    yearAriaLabel={"Año"}
                    showLeadingZeros={true}
                  />
                </div>
                <DashboardCard title={lang === 'es' ? 'Oportunidades que han aplicado por fecha' : 'Opportunities that have applied by date'}>
                  <Chart2 fecha={fecha} cant={aplicantesFecha} />
                </DashboardCard>
                <DashboardCard title={lang === 'es' ? 'Agregar colaborador de admin' : 'Add admin collaborator'}>
                  <ColaboratorInvite color={university.color} />
                </DashboardCard>
              </div>

            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default UniversitiesAdmin;
