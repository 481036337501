import React from "react";
import { useHistory } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.css";
import { connect } from "react-redux";
import { useSelector } from "react-redux";
import ButtonP from "./ButtonP";

function ModalSubscription(props) {
  const history = useHistory();
  const closeForm = () => {
    props.setSubsActivate(false);
    document.getElementsByTagName("body")[0].style.overflow = "auto";
    history.push("/company/home");
  };
 const planes = useSelector(state => state.planes)
 const user = useSelector(state => state.user)
  console.log(user.id)
  return (
    <>
    {props.user && props.user.roles === "company" ?  null : history.push("/consultor/home") }
      <div className='popupproyecto'>
        <div className='popupproyectofondo' onClick={() => {
          props.setSubsActivate(false);
          document.getElementsByTagName("body")[0].style.overflow = "auto"
        }}></div>
        <div style={{width: '772px', position: 'relative', zIndex: '3',}}>
          <h3 className="botoncerrarpopuppro"  onClick={() => {closeForm()}}>x</h3></div>
        <div className='popupproyectocartel2'>
          <div>
            <h1>Plan Basico </h1>
            <ButtonP type={planes[0]} user={user.id}/>
          </div>
          <div>
          <h1>Plan Empresa </h1>
            <ButtonP type={planes[3]} user={user.id}/>
          </div>
        </div>
      </div>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
    projects : state.projects,
  };
};

export default connect(mapStateToProps, null)(ModalSubscription);
//export default ;