import React, { createContext, useContext, useState } from "react";

interface Popup {
  id?: string;
  content?: React.ReactNode;
  onClose?: () => void;
  isClosing?: boolean;
}

interface PopupContextValue {
  popups?: Popup[];
  addPopup?: (popup: Popup) => void;
  removePopup?: (popup: Popup) => void;
  removeAllPopups?: () => void;
}

const PopupContext = createContext<PopupContextValue>({
  popups: [],
  addPopup: () => {},
  removePopup: () => {},
  removeAllPopups: () => {},
});

export const usePopupContext = () => useContext(PopupContext);

export const PopupProvider: React.FC = ({ children }) => {
  const [popups, setPopups] = useState<Popup[]>([]);

  const addPopup = (popup: Popup) => {
    setPopups([...popups, popup]);
  };

  const removePopup = (popup: Popup) => {
    setPopups(popups.filter((p) => p.id !== popup.id));
  };

  const removeAllPopups = () => {
    setPopups([]);
  };

  return (
    <PopupContext.Provider
      value={{ popups, addPopup, removePopup, removeAllPopups }}
    >
      {children}
    </PopupContext.Provider>
  );
};
