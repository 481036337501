import React, {useEffect, useState} from "react";
import Header from "../header/Headerprofesional"
import Headertop from "../headertop/Headertop";
import Board from 'react-trello'

export default function Trello() {
  const [data, setData] = useState()

  useEffect(() => {
    setData(
      {
        lanes: [
          {
            id: 'lane1',
            title: 'Planned',
            label: null,
            cards: []
          },
          {
            id: 'lane2',
            title: 'In Progress',
            label: null,
            disallowAddingCard: true,
            cards: []
          },
          {
            id: 'lane3',
            title: 'Done',
            label: null,
            disallowAddingCard: true,
            cards: []
          }
        ]
      }
    )
  }, [])

  const onData = (data) => {
    setData(data)
    return (data) => {
      setData({
        ...data,
        lanes: [
          {
            ...data.lanes[0],
            label: data.lanes[0].cards.length.toString()
          },
          {
            ...data.lanes[1],
            label: data.lanes[1].cards.length.toString()
          },
          {
            ...data.lanes[2],
            label: data.lanes[2].cards.length.toString()
          }
        ]
      })
    }

  }

  data && console.log(data)

  return (
    <div className="contenedor-oportunidad">
      <div>
        <Header></Header>
        <div style={{padding: "0rem 2rem", width: "calc(99vw - 237px)", position: "absolute", right: "0"}}>
          <Headertop sitio="Bienvenido a Coally!"></Headertop>
          <h1 style={{
            margin: '0',
            fontSize: "40px",
            fontFamily: 'Poppins',
            fontWeight: '700',
            color: '#202256',
            marginBottom: '27px'
          }}>Task Manager</h1>
          {data &&
            <div>
              <Board
                data={data}
                style={{
                  width: '100%',
                  height: '100%',
                  minHeight: '500px',
                  backgroundColor: 'white',
                  borderRadius: '8px',
                  boxShadow: '0px 7px 32px rgba(0, 0, 0, 0.1)'
                }}
                draggable
                editable
                onDataChange={data => onData(data)}
                canAddLanes={true}
              />
            </div>
          }
        </div>
      </div>
    </div>
  )
}