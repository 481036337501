import React, { useState, useContext, useEffect } from "react";
import useNavbarData from "./utils/useNavbarData";
import {
  NavbarContainer,
  NavbarSideBarContainer,
  NavbarSideBarBackground,
  NavBarUserInfoContainer,
  NavBarUserInfo,
  NavBarElementsContainer,
  NavBarHamburgerContainer,
  HamburgerTile,
  NavBarLangLogoContainer,
  NavBarLangContainer,
  NavBarProfileContainer,
  NavBarProfileSeparator,
  CompanyProfileImageContainer,
  NavbarLinea,
} from "./NavbarStyles";
import { CloseButton } from "../sidebar/SidebarStyles";
import {
  ActiveLangButton,
  InactiveLangButton,
  ProfileButton,
} from "../buttons/ButtonStyles";
import UserInfo from "./userinfo/UserInfo";
import UserImageHolder from "../../../assets/icons/user.svg";
import CircularFrameNav from "./circularframe/CircularFrameNav";
import Sidebar from "../sidebar/Sidebar";
import { IoIosClose } from "react-icons/io";
import { SchneiderContext } from "../../context/SchneiderContext";

const Navbar = ({ locate }: { locate?: string }) => {
  const { schneider } = useContext(SchneiderContext);
  const {
    dataUser,
    setSidebar,
    showInfo,
    setShowInfo,
    lang,
    switchLanguages,
    user,
    cv,
  } = useNavbarData();
  const [UserInfoIsClosing, setUserInfoIsClosing] = useState(false);
  const [sidebarIsClosing, setSidebarIsClosing] = useState(false);
  const [showSidebar, setShowSidebar] = useState(false);

  useEffect(() => {
    function handleResize() {
      if (window.innerWidth > 1300) {
        setShowSidebar(false);
        setSidebar(false);
      }
    }

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [setSidebar]);

  const handleShowUserInfo = () => {
    if (!cv && user.roles !== "company") {
      return;
    }
    if (showInfo) {
      setUserInfoIsClosing(true);
      setTimeout(() => {
        setShowInfo(false);
        setUserInfoIsClosing(false);
      }, 400);
    } else {
      setShowInfo(true);
    }
  };

  const handleShowSidebar = () => {
    if (showSidebar) {
      setSidebarIsClosing(true);
      setTimeout(() => {
        setShowSidebar(false);
        setSidebar(false);
        setSidebarIsClosing(false);
      }, 400);
    } else {
      setShowInfo(false);
      setShowSidebar(true);
      setSidebar(true);
    }
  };

  return (
    <NavbarContainer>
      {showSidebar ? (
        <NavbarSideBarContainer isClosing={sidebarIsClosing}>
          <div>
            <Sidebar
              location={locate}
              isClosing={sidebarIsClosing}
              responsive={showSidebar}
            />
            <CloseButton
              onClick={() => handleShowSidebar()}
              isClosing={sidebarIsClosing}
            >
              <IoIosClose style={{ color: "#5d5d5d" }} />
            </CloseButton>
          </div>
          <NavbarSideBarBackground />
        </NavbarSideBarContainer>
      ) : null}
      {showInfo ? (
        <NavBarUserInfoContainer onClick={() => handleShowUserInfo()}>
          <NavBarUserInfo>
            <UserInfo
              UserInfoIsClosing={UserInfoIsClosing}
              dataUser={dataUser}
            />
          </NavBarUserInfo>
        </NavBarUserInfoContainer>
      ) : null}
      <NavBarElementsContainer>
        <NavBarHamburgerContainer onClick={() => handleShowSidebar()}>
          <HamburgerTile background={schneider.color.primary} />
          <HamburgerTile background={schneider.color.primary} />
          <HamburgerTile background={schneider.color.primary} />
        </NavBarHamburgerContainer>
        <NavBarLangLogoContainer>
          <NavBarLangContainer>
            {lang === "es" ? (
              <ActiveLangButton onClick={() => switchLanguages("es")}>
                ES
              </ActiveLangButton>
            ) : (
              <InactiveLangButton onClick={() => switchLanguages("es")}>
                ES
              </InactiveLangButton>
            )}
            {lang === "en" ? (
              <ActiveLangButton onClick={() => switchLanguages("en")}>
                EN
              </ActiveLangButton>
            ) : (
              <InactiveLangButton onClick={() => switchLanguages("en")}>
                EN
              </InactiveLangButton>
            )}
          </NavBarLangContainer>

          <NavBarProfileContainer>
            <NavBarProfileSeparator />
            <ProfileButton
              onClick={() => handleShowUserInfo()}
              background={schneider.color.primary}
            >
              {user.roles === "company" ? (
                <CompanyProfileImageContainer>
                  <img
                    src={user.avatar ? user.avatar : UserImageHolder}
                    alt={"user icon"}
                  />
                </CompanyProfileImageContainer>
              ) : (
                <CircularFrameNav user={user} cv={cv} />
              )}
            </ProfileButton>
          </NavBarProfileContainer>
        </NavBarLangLogoContainer>
      </NavBarElementsContainer>
      <NavbarLinea />
    </NavbarContainer>
  );
};

export default Navbar;
