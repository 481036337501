/* eslint-disable */
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, Link } from "react-router-dom";
import './newLogin.css';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import Spinner from 'react-bootstrap/Spinner'
import { changeLang, newLoginUser } from "../../../actions";
import Wave from "../../../assets/imagenes/Vector385.svg";
import Wave2 from "../../../assets/imagenes/Vector 384.svg";
import Creativity from "../../../assets/imagenes/CREATIVIDAD.svg";
import ImgCodigo from "../../../assets/imagenes/CODIGO.svg";
import Edit from "../../../assets/imagenes/edit.svg";
import Logo from "../../../assets/imagenes/nuevo_logo.svg";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


export default function NewLogin() {
  const [userF, setUser] = useState({
    email: "",
    password: "",
  });
  const history = useHistory();
  const user = useSelector((state) => state.user);
  const Error = useSelector((state) => state.Error);
  const [locallang, setLocallang] = useState(localStorage.getItem("langlocal"));
  const [cargando, setcargando] = useState(false)
  const [width, setWidth] = useState(window.innerWidth);
  const [height, setHeight] = useState(window.innerHeight);
  const [path, setPath] = useState()


  const updateWidthAndHeight = () => {
    setWidth(window.innerWidth);
    setHeight(window.innerHeight);

  }
  useEffect(() => {
    window.addEventListener("resize", updateWidthAndHeight);
    return () => window.removeEventListener("resize", updateWidthAndHeight);
  }, [width, height]);



  useEffect(() => {
    if (user.roles === "company") {
      history.push("/company/home");
      dispatch(changeLang('en'))
      localStorage.setItem("langlocal", 'en');
    }
    if (user.unilever) {
      history.push("/unilever/home");
    }
    if (user.roles === "coallier" && !user.unilever) {
      localStorage.getItem('redirect') ? history.push(localStorage.getItem('redirect')) : history.push("/consultor/home");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user.id]);
  useEffect(() => {
    if (Error.message) {
      toast.error(locallang === 'es' ? 'Email o contraseña incorrectos.' : 'Invalid email or password.', {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      setcargando(false);
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [Error]);
  const dispatch = useDispatch();
  const [passwordShown, setPasswordShown] = useState(false);
  const handleSubmit = (event) => {
    event.preventDefault();
    setcargando(true);
    if (userF.email !== "" && userF.password !== "") {
      dispatch(newLoginUser(userF, setcargando));
    }
  };



  useEffect(() => {
    //on key press enter submit form
    const handleKeyPress = (event) => {
      if (event.key === "Enter") {
        event.preventDefault();
        handleSubmit(event);
      }
    }
    document.addEventListener("keydown", handleKeyPress);
    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    }
  }, [userF]);

  const togglePassword = () => {
    setPasswordShown(!passwordShown);
  }
  const cambiarlanges = (camlang) => {
    dispatch(changeLang(camlang))
    localStorage.setItem("langlocal", camlang);
    setLocallang(localStorage.getItem("langlocal"));
  };

  return (


    <div style={{ backgroundColor: '#F5F5F5', display: 'flex', justifyContent: 'space-evenly', alignItems: 'center', width: '100%', height: '100vh', flexDirection: 'column' }}>
      {/* <ToastContainer/> */}
      <div style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', height: '80%' }}>
        {
          width > 768 ? <div style={{
            width: '25%',
            height: '80%', display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-start'
          }}>
            <img src={Creativity} alt="logo" style={{ width: '235px', height: '235px' }} />
          </div> : null
        }
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: width > 768 ? '33%' : '100%', }}>
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '442px', width: '365px', borderRadius: '8px', zIndex: '2' }}>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', height: '100%', flexDirection: 'column' }}>
              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
                <img src={Logo} alt="logo" style={{ width: '234px', height: '84px', marginBottom: '5%' }} />
              </div>
              <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-start', width: '100%', paddingBottom: '1em' }}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "flex-end",
                    justifyContent: 'flex-end',
                    color: "#C5C5C5",
                    width: '100%',
                    paddingRight: '30px'
                  }}
                >
                  <h3
                    style={
                      localStorage.getItem("langlocal") === "es"
                        ? {
                          margin: "0.3rem",
                          fontSize: "18px",
                          fontFamily: "Poppins",
                          cursor: "pointer",
                          color: "#202256",
                          fontWeight: "700",
                          userSelect: "none",
                        }
                        : { margin: "0.3rem", cursor: "pointer", fontWeight: '300', fontSize: '18px' }
                    }
                    onClick={() => cambiarlanges("es")}
                  >
                    SP
                  </h3>
                  <h3
                    style={
                      localStorage.getItem("langlocal") === "en"
                        ? {
                          margin: "0.3rem",
                          fontSize: "18px",
                          fontFamily: "Poppins",
                          cursor: "pointer",
                          color: "#202256",
                          fontWeight: "700",
                          userSelect: "none",
                        }
                        : { margin: "0.3rem", cursor: "pointer", fontWeight: '300', fontSize: '18px' }
                    }
                    onClick={() => cambiarlanges("en")}
                  >
                    EN
                  </h3>
                </div>
              </div>
              <div style={{ width: '100%' }}>
                <h1 style={{ fontFamily: 'Poppins', fontSize: '24px', fontWeight: '700', color: '#393939' }}>{
                  locallang === 'es' ? "¡Bienvenido de nuevo!" : "Welcome back!"
                }</h1>
              </div>

              <div style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'center', flexDirection: 'column', width: '100%' }}>
                <div>
                  <p style={{ color: '#5D5D5D', fontWeight: '400', fontSize: '14px', paddingTop: "1em" }}>
                    {
                      locallang === 'es' ? 'Correo electrónico' : 'Email'
                    }
                  </p>
                </div>
                <div style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'center',
                  alignItems: 'center',
                  width: '100%',
                  height: '41px',
                }}>
                  <input type='text' style={{
                    background: '#F5F5F5',
                    fontstyle: 'normal',
                    fontWeight: '400',
                    width: '100%',
                    height: '41px',
                    paddingLeft: '22px',
                    fontSize: '16px',
                    border: '1px solid #C5C5C5',
                  }} placeholder={
                    locallang === 'es' ? 'nombre@correo.com' : 'name@email.com'
                  } onChange={
                    (e) => {
                      setUser({
                        ...userF,
                        email: e.target.value
                      })
                    }
                  } />
                  <div style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    alignItems: 'flex-end',
                    width: '0%'
                  }}>
                    <img src={Edit} alt="editar" />
                  </div>
                </div>
              </div>
              <div style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'center', flexDirection: 'column', marginTop: '4%', width: '100%' }}>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                  <p style={{ color: '#5D5D5D', fontWeight: '400', fontSize: '14px' }}>
                    {
                      locallang === 'es' ? 'Contraseña' : 'Password'
                    }
                  </p>
                </div>
                <div style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'center',
                  alignItems: 'center',
                  width: '100%',
                  height: '41px',
                }}>
                  <input style={{
                    background: '#F5F5F5',
                    fontstyle: 'normal',
                    fontWeight: '400',
                    width: '100%',
                    height: '41px',
                    paddingLeft: '22px',
                    fontSize: '16px',
                    border: '1px solid #C5C5C5',
                  }} type={passwordShown ? "text" : "password"} placeholder={
                    locallang === 'es' ? 'Introduce tu contraseña' : 'Password'
                  } onChange={
                    (e) => {
                      setUser({
                        ...userF,
                        password: e.target.value
                      })
                    }
                  } />
                  <div style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    alignItems: 'flex-end',
                    width: '0%'
                  }}>
                    <button style={{ background: 'transparent', borderRadius: '0px 1.5rem 1.5rem 0px' }} onClick={() => togglePassword()}>{passwordShown ? <VisibilityOffIcon /> : <VisibilityIcon />}</button>
                  </div>

                </div>
                <div>
                  <Link to='/auth/consultor/recoverypassword'>
                    <p className='login-forgot-pass'>
                      {
                        locallang === 'es' ? '¿Olvidaste tu contraseña?' : 'Forgot your password?'
                      }
                    </p>
                  </Link>
                </div>

              </div>

              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', marginTop: '4%', width: '100%' }}>
                {
                  userF.email.includes('@') && userF.email.includes('.') && userF.password ? (
                    <div style={{ width: '100%' }}>
                      <button className='register-button-new' onClick={(e) => handleSubmit(e)}>
                        {cargando ? (
                          <Spinner animation="border" size="sm" />
                        ) :
                          <span>
                            {
                              locallang === 'es' ? 'Iniciar sesión' : 'Sign in'
                            }
                          </span>}
                      </button>
                    </div>
                  ) : (
                    <div style={{ width: '100%' }}>
                      <button className='register-button-new' disabled>
                        {
                          locallang === 'es' ? 'Iniciar sesión' : 'Sign in'
                        }
                      </button>
                    </div>
                  )
                }
              </div>
              <hr style={{
                width: '80%',
                height: '1px',
                backgroundColor: '#D6D6D6',
                border: 'none',
                marginTop: '4%'
              }} />
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'row', marginTop: '4%', width: '100%' }}>
                <div>
                  <p style={{
                    color: '#5D5D5D',
                    fontWeight: '400',
                    fontSize: '14px',
                    textAlign: 'center'

                  }}>
                    {
                      locallang === 'es' ? '¿Es la primera vez que usas Coally?' : 'Is this your first time using Coally?'
                    }
                  </p>
                </div>
                <div style={{ width: '20%' }}>
                  <div>
                    <Link to='/auth/consultor/register'>
                      <p className="login-register-new">
                        {
                          locallang === 'es' ? 'Regístrate' : 'Sign up'
                        }
                      </p>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {
          width > 768 ? <div style={{
            width: '25%',
            height: '85%', display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-end', zIndex: '1'
          }}>
            <img src={ImgCodigo} alt="logo" style={{ width: '235px', height: '235px' }} />
          </div> : null
        }
      </div>
      <div style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        alignItems: 'flex-end',
        width: '100%',
        height: '20%',
        backgroundColor: '#F5F5F5'
      }}>
        <div style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%',
        }}>
          <img src={Wave2} alt="wave 2" style={{
            width: '100%',
            height: 'auto',
          }} />
          <img src={Wave} alt="wave" style={{
            width: '100%',
            height: 'auto',
            position: 'absolute',
            bottom: '0'
          }} />
        </div>
      </div>
    </div>
  );
}
