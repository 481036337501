import styled from "styled-components";
import DialogTitle from "@mui/material/DialogTitle";

const StyledPopupTitle = styled(DialogTitle)`
  color: #393939 !important;
  font-size: 24px !important;
  font-weight: 700 !important;
  padding: 20px !important;
  text-align: center !important;
  font-family: "Poppins", sans-serif !important;
`;

const StyledPopupDescription = styled.p`
color: #5D5D5D;
font-size: 16px;
text-align: justify;
margin-bottom: 20px;
`;

const StyledPopupButton = styled.button<{ border?: string }>`
  border: 1px solid ${props => props.border ? props.border : "#F5F5F5"};
  border-radius: 5px;
  color: #393939;; 
  padding: 10px ; 
  cursor: pointer;
  margin-left: 10px;
  font-size: 16px; 
  background-color: transparent; 
`;

const StyledAuraGreen = styled.div<{ background?: string }>`
background-color: ${props => props.background ? props.background : "#F5F5F5"};
display: flex;
align-items: center;
justify-content: center;
flex-direction: row;
width: 40px;
height: 40px;
text-align: center;
border-radius: 50%;
margin-right: 10px;
`;

const StyledAuraGrey = styled.div`
background-color: #F5F5F5;
border-radius: 50%;
width: 34px;
height: 34px;
display: flex;
align-items: center;
justify-content: center;
flex-direction: row;
`;

const StyledIndexCourse = styled.h2`
text-align: center;
color: #393939;
font-weight: 700;
font-size: 16px;
margin: 0;
`;


export {
  StyledPopupDescription,
  StyledPopupButton,
  StyledPopupTitle,
  StyledAuraGreen,
  StyledAuraGrey,
  StyledIndexCourse
};