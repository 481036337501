/* eslint-disable */
import React, { useState, useEffect, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getPostedProjects, ConsultorData, tourStatus } from "../../../actions";
import { Link, useHistory, useLocation } from "react-router-dom";
import axios from "axios";
import Loader from "../../../empresa/Loader/Loader";
import ReactPaginate from "react-paginate";
// @ts-ignore
import CardOportunidadEmpresas from "../../components/cardOportunidad/CardOportunidadEmpresas";
import "./homeEmpresav2.css";
/* import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import TestCardEmpresa from "./TestCard/TestCardEmpresa"; */
import notFound from '../../../assets/icons/notFound.svg';
import {
  BarChart,
  Bar,
  XAxis,
  Tooltip,
  Legend, YAxis
} from "recharts";
import NavigateBeforeOutlinedIcon from '@mui/icons-material/NavigateBeforeOutlined';
import NavigateNextOutlinedIcon from '@mui/icons-material/NavigateNextOutlined';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import TestCompanyCultural from "../../../empresa/CompanyProfile/TestCompanyCultural";
import TestCompanyCulturalResult from "../../../empresa/CompanyProfile/TestCompanyCulturalResult";
import Video1 from "../../../assets/imagenes/video1.svg";
import Video2 from "../../../assets/imagenes/video2.png";
import Video3 from "../../../assets/imagenes/Video4.png";
import UniversitiesSideBar from "../../components/sidebar/UniversitiesSideBar";
import UseTourCom from "./useTourCom";
import PopUpPayInfo from '../../../empresa/popUpInfos/PopUpPayInfo';
import { CSVLink, CSVDownload } from "react-csv";
import UniversitiesNavbar from "../../components/navbar/UniversitiesNavbar";
import {UniversityContext} from "../../context/UniversitiesContext";

const tests = [
  {
    id: 1,
    name: 'Test de cultura organizacional',
    description: 'Realice este test gratuito para saber más sobre sí mismo y sus puntos fuertes.',
  },
  {
    id: 2,
    name: 'Test de personalidad preferida',
    description: 'Realice este test gratuito para saber más sobre sí mismo y sus puntos fuertes.',
  },
  {
    id: 3,
    name: 'Test de aptitudes',
    description: 'Realice este test gratuito para saber más sobre sí mismo y sus puntos fuertes.',
  }
]

const testsENG = [
  {
    id: 1,
    name: 'Organizational culture test',
    description: 'Take this free trial to learn more about yourself and your strengths.',
  },
  {
    id: 2,
    name: 'Preferred personality test',
    description: 'Take this free trial to learn more about yourself and your strengths.',
  },
  {
    id: 3,
    name: 'Test of aptitudes',
    description: 'Take this free trial to learn more about yourself and your strengths.',
  }
]


export default function EmpresaCustomHome() {
  const {university} = useContext(UniversityContext)
  const [popupInfoPay, setpopupInfoPay] = React.useState(false)
  const dispatch = useDispatch();
  const history = useHistory();
  const tourViewedCompany = useSelector((state) => state.tourViewedCompany);
  const user = useSelector((state) => state.user);
  const lang = useSelector((state) => state.lang);
  const [statistics, setStatistics] = useState([]);
  const [OpenTestOrganizacional, setOpenTestOrganizacional] = useState(false);
  const [dataEpayco , setDataEpayco] = useState()

  const [page, setPage] = useState({
    currentPage: 0,
    totalPage: 0,
  });
  const [loading, setLoading] = useState(true);
  const [proyectosdeluser, setproyectosdeluser] = useState([]);
  const [proyectosCsv, setproyectosCsv] = useState([]);
  const [openTestCulturalResult, setOpenTestCulturalResult] = useState(false);
  const [openVideo1, setOpenVideo1] = useState(false);
  const [openVideo2, setOpenVideo2] = useState(false);
  const [openVideo3, setOpenVideo3] = useState(false);
  const handleCloseOrganizacional = () => {
    setOpenTestOrganizacional(false);
  }
  const handleCloseTestCulturalResult = () => {
    setOpenTestCulturalResult(false);
  }
  const handleOpenTestCulturalResult = () => {
    setOpenTestCulturalResult(true);
  }

  const [width, setWidth] = useState(window.innerWidth);
  const [height, setHeight] = useState(window.innerHeight);
  const params = new URLSearchParams(useLocation().search);

  const updateWidthAndHeight = () => {
    setWidth(window.innerWidth);
    setHeight(window.innerHeight);
  }

  const popUpInfopayClose = () => {
    setpopupInfoPay(false)
    history.push('/company/home')
  }

  const location = useLocation();
  useEffect(() => {
    window.addEventListener("resize", updateWidthAndHeight);
    setpopupInfoPay(location.search.includes('ref_payco'))
    return () => window.removeEventListener("resize", updateWidthAndHeight);
  }, [width, height]);

  useEffect(() => {
    const refPayco = params.get("ref_payco")
    axios.get(`https://secure.epayco.co/validation/v1/reference/${refPayco}`)
    .then((res) => {
      console.log(res.data.data.x_response)
      setDataEpayco(res.data.data.x_response)
    })
    .catch((err) => {
      console.log(err)
    })
  }, [])


  const services = localStorage.getItem('additionals')? JSON.parse(localStorage.getItem('additionals')) : []

  useEffect(() => {
    if(dataEpayco === 'Aceptada'){
      axios.post('https://findally-backend.herokuapp.com/api/premium_payment/add/additional-service', {
      userId: user.id,
      services: services
      })
      .then((res) => {
        console.log(res)
        localStorage.removeItem('additionals')
      }
      )
      .catch((err) => {
        console.log(err)
      })
    }
  }, [dataEpayco])


  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: width > 600 ? 400 : '90%',
  };

  const styleTestCultural = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: width > 600 ? 600 : '90%',
    height: '85vh',
    bgcolor: '#FFFFFF',
    borderRadius: '8px',
    overflow: 'auto',
  }
  const styleTestCulturalResult = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: width > 600 ? 600 : '90%',
    height: '90vh',
    bgcolor: '#FFFFFF',
    border: '2px solid #000',
    borderRadius: '8px',
    boxShadow: 24,
    overflow: 'auto'
  }

  const headers = [
    { label: "Nombre", key: "NombreOportunidad" },
    { label: "Estado", key: "status"},
    { label: "Tipo de servicio deseado", key: "tipoDeServicioDeseado" },
    { label: "Modalidad de contratación", key: "modalidadDeContratacion " },
    { label: "Anonimo", key: "Anonimo" },
    { label: "Profesionales", key: "profesionales" },
    { label: "Carrera", key: "SeleccionaCarrera"},
    { label: "Duración", key: "estimadoDuración" },
    { label: "Ciudad", key: "ciudad" },
    { label: "Fecha de publicación", key: "fecha_inicio" },
    { label: "Nivel de inglés", key: "nivel_ingles" },
    { label: "Presupuesto", key: "presupuesto" },
    {label: "Descripcion", key: "DescribeProyecto"},
    {label: "Area de conocimiento", key: "empleos_alternativos"},
    {label: "Habilidades", key: "habilidadesTecnicas"},
    {label: "Habilidades blandas", key: "habilidadesBlandas"},
    {label: "Responsabilidades", key: "responsabilidadYfunciones"},
  ]

  const csvReport = {
    filename: 'Oportunidades.csv',
    headers: headers,
    data: proyectosCsv
  }

  useEffect(() => {
    axios.get(`https://findally-backend.herokuapp.com/api/user/projects/Company/${user.id}`)
      .then((res) => {
        setproyectosCsv(res.data.projects)
      })
  }, []);

  useEffect(() => {
    if (!user.id) {
      return history.push("/");
    }
    dispatch(ConsultorData(user.id));
    dispatch(getPostedProjects());
    axios.get("https://findally-backend.herokuapp.com/api/user/projects/Company/" + user.id + `?skip=${page.currentPage}`)
      .then((e) => {
        setproyectosdeluser(e.data.projects);
        //order by aplicantes length
        setStatistics(e.data?.projects?.map((e) => {
          return {
            nombre: e.nombre && e.nombre !== "Unnamed Oportunity"
              ? e.nombre
              : e.nombreProyecto &&
                e.nombreProyecto !== "Unnamed Oportunity"
                ? e.nombreProyecto
                : e.NombreOportunidad &&
                  e.NombreOportunidad !==
                  "Unnamed Oportunity"
                  ? e.NombreOportunidad
                  : "",
            aplicantes: e.aplicantes.length ? e.aplicantes.length : 0
          }
        }))
        setPage({ ...page, totalPage: e.data.totalPag });
      })
      .finally(() => setLoading(false));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, user.id]);

  const handlePageClick = (evento) => {
    if (!user.id) {
      return history.push("/");
    }
    setproyectosdeluser([]);
    axios.get("https://findally-backend.herokuapp.com/api/user/projects/Company/" + user.id + `?skip=${evento.selected}`).then((el) => {
      setproyectosdeluser(el.data.projects);
      setPage({
        ...page,
        currentPage: page.currentPage + 1,
        totalPage: el.data.totalPag,
      });
    });
  };


  const redirect = () => {
    if (!user.id) {
      return history.push("/")
    }
    history.push("/consultor/home");
  };

  useEffect(() => {
    if (tourViewedCompany === true) {
      return
    }
    setTimeout(() => {
      dispatch(tourStatus({run: true}))
    }, 1500);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch,tourViewedCompany]);


  const renderCustomBarLabel = ({ payload, x, y, width, height, value }) => {
    return <text x={x + width / 2} y={y} fill="#666" textAnchor="middle" dy={-6}>{`${value}`}</text>;
  }

  const paginationActualStyles = document.createElement('style')
  paginationActualStyles.innerHTML = `.pageActUniversitie {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: ${university?.color && university?.color?.primary};
        width: 40px;
        border-radius: 100%;
        color: ${university?.color && university?.color?.secondary};
    }`
  document.head.appendChild(paginationActualStyles)

  const paginationPrevStyles = document.createElement('style')
  paginationPrevStyles.innerHTML = `.previBUniversitie {
        display: flex;
        justify-content: center;
        align-items: center;
        color: ${university?.color && university?.color?.secondary};
    }`
  document.head.appendChild(paginationPrevStyles)

  return (
    <div className="contenedor-oportunidad">
      {
        user && user.roles === "company" ? (
          university && university.color && <div>
                {popupInfoPay &&
                    <PopUpPayInfo open={popupInfoPay} handleClose={popUpInfopayClose} handleFinish={popUpInfopayClose} />
                }
            <div className={"contenedor-tour"}>
              <UseTourCom />
            </div>
            {/*<Header></Header>*/}
            <UniversitiesSideBar location={'home'}/>
            <div className="BodyWeb"
              style={{
                padding: "0rem 2rem",
                position: "absolute",
                right: "0",
              }}>
              {/*<HeadertopV2 projects={proyectosdeluser}></HeadertopV2>*/}
              <UniversitiesNavbar locate={'home'}/>
              {
                <Modal open={openVideo1}
                  onClose={() => setOpenVideo1(false)}
                >
                  <Box style={style}>
                    <iframe width="560" height="315" src="https://www.youtube.com/embed/l1lqPBxANFY" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                  </Box>
                </Modal>
              }
              {
                <Modal open={openVideo2} onClick={() => setOpenVideo2(false)}>
                  <Box style={style}>
                    <iframe width="560" height="315" src="https://www.youtube.com/embed/pQXAyVr11EM" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                  </Box>
                </Modal>
              }
              {
                <Modal open={openVideo3} onClick={() => setOpenVideo3(false)}>
                  <Box style={style}>
                  <iframe width="560" height="315" src="https://www.youtube.com/embed/gOFStsk4zJE" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                  </Box>
                </Modal>
              }
              <h1 id='home' style={{
                marginBottom: "2rem",
                marginLeft: "0",
                fontFamily: "Poppins",
                fontWeight: "700",
                fontSize: "40px",
                color: university && university.color ? university.color.secondary : "#000",
              }}>
                {lang === "es" ? "¡Bienvenido a The Fund!" : "Welcome to The Fund!"}
              </h1>
              <div className="new-empresa-home-container">
                {/*banner*/}
                <div className="new-empresa-home-banner">
                  <div className="new-empresa-home-banner-text">
                    <h4 style={{
                      fontFamily: "Poppins",
                      fontWeight: "400",
                      fontSize: "20px",
                      color: "#FFF",
                    }}
                    >{lang === 'es' ? '¿Ya haces parte del plan Bussiness?' : 'Are you already part of the Business plan?'}</h4>
                    <h1 style={{
                      fontFamily: "Roboto",
                      fontWeight: "700",
                      fontSize: "36px",
                      color: "#FFF",
                    }}>
                      {lang === 'es' ? "¡Cámbiate ya!" : "Change now!"}
                    </h1>
                  </div>
                  <div className="new-empresa-home-banner-button" id='pricing1'>
                    <Link to='/company/pricing'>
                    <button style={{ color: '#FFF', background: 'none' }} >
                      {lang === 'es' ? 'Ver detalles' : 'See details'}
                    </button>
                    </Link>
                  </div>
                </div>
                <div className="new-empresa-home-body">
                  <div className="new-empresa-home-recommendation">
                    <Accordion style={{ width: '100%', boxShadow: 'none', borderBottom: '1px solid #393939', borderRadius: '0px' }}>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <h1 style={{
                          fontFamily: "Poppins",
                          fontWeight: "700",
                          fontSize: "18px",
                          color: "#5D5D5D"
                        }}>
                          {lang === 'es' ? 'Recomendados Coally' : 'Coally Recommended'}
                        </h1>
                      </AccordionSummary>
                      <AccordionDetails>
                        <div className="new-home-empresa-videos-container">
                          <div onClick={() => setOpenVideo1(true)} style={{ cursor: 'pointer' }}>
                            <img src={Video1} style={{ borderRadius: "10px", maxWidth: '298px', height: '213px', objectFit: 'cover' }} alt={'video de bienvenida'} />
                          </div>
                          <div onClick={() => setOpenVideo2(true)} style={{ cursor: 'pointer' }}>
                            <img style={{ borderRadius: "10px", maxWidth: '298px', height: '213px', objectFit: 'cover' }} src={Video2} alt={'video de bienvenida'} />
                          </div>
                          <div onClick={() => setOpenVideo3(true)} style={{ cursor: 'pointer' }}>
                            <img style={{ borderRadius: "10px", maxWidth: '298px', height: '213px', objectFit: 'cover' }} src={Video3} alt={'video de bienvenida'} />
                          </div>
                        </div>
                      </AccordionDetails>
                    </Accordion>
                  </div>
                  {/* TESTS HOME EMPRESA COMENTADOS PARA LA PRESENTACION */}
                  {/* <div className="new-empresa-home-test-title">
                          <div style={{height: '100%'}}>
                          <h2 style={{
                            fontFamily: "Poppins",
                            fontWeight: "700",
                            fontSize: "24px",
                            color: "#393939",
                          }}>
                            Test
                          </h2>
                          </div>
                          <div style={{height: '100%', marginLeft: '10px'}}>
                            <WarningAmberIcon style={{color: '#B0B0B0', strokeWidth: '0.7px'}}/>
                          </div>
                        </div>
                    <div className="new-empresa-home-body-tests">
                    {lang === 'es'
                  ? tests.map((test, i) => {
                    return (
                      <TestCardEmpresa id={test.id} name={test.name} description={test.description} user={user} key={i} setOpenTestOrganizacional={setOpenTestOrganizacional}/>
                    )
                  })
                  : testsENG.map((test, i) => {
                    return (
                      <TestCardEmpresa id={test.id} name={test.name} description={test.description} user={user} key={i} setOpenTestOrganizacional={setOpenTestOrganizacional} />
                    )
                  })
                }
                    </div> */}
                  <>
                    <Modal
                      open={OpenTestOrganizacional}
                      onClose={handleCloseOrganizacional}

                    >
                      <Box sx={styleTestCultural}>
                        <TestCompanyCultural handleOpenTestCulturalResult={handleOpenTestCulturalResult} />
                      </Box>
                    </Modal>
                  </>
                  <>
                    <Modal
                      open={openTestCulturalResult}
                      onClose={handleCloseTestCulturalResult}
                    >
                      <Box sx={styleTestCulturalResult}>
                        <TestCompanyCulturalResult handleCloseTestCulturalResult={handleCloseTestCulturalResult} />
                      </Box>
                    </Modal>
                  </>
                  <div
                    className="contenedor-formacion-oportunidades-new"
                  >
                    <div className='new-home-published-opportunities'>
                      <div>
                        <hr />
                        <h2
                          style={{
                            fontSize: "24px",
                            fontWeight: "700",
                            fontFamily: "Poppins",
                            paddingTop: "5px",
                          }}
                        >
                          {lang === "es" ? "Oportunidades Publicadas" : "Posted Opportunities"}
                        </h2>
                      </div>
                      {
                        <div
                          style={{
                            width: "100%",
                            paddingRight: "15px",
                          }} id='projects'
                        >
                          {!proyectosdeluser.length && (
                            <div style={{
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                              width: '100%',
                              marginTop: '5%'
                            }}>
                              <Loader
                                render={loading}
                                array={proyectosdeluser}
                                altura={15}
                              />
                            </div>
                          )}
                          {proyectosdeluser &&
                            proyectosdeluser.map((e, i) => {
                              return (
                                <div key={i + 'c'}>
                                  <CardOportunidadEmpresas
                                    id={e._id}
                                    titulo={e.nombre && e.nombre !== "Unnamed Oportunity"
                                      ? e.nombre
                                      : e.nombreProyecto &&
                                        e.nombreProyecto !== "Unnamed Oportunity"
                                        ? e.nombreProyecto
                                        : e.NombreOportunidad &&
                                          e.NombreOportunidad !==
                                          "Unnamed Oportunity"
                                          ? e.NombreOportunidad
                                          : ""}
                                    imagen={e.imageUrl}
                                    aplicantes={e.aplicantes}
                                    habilidades={e.habilidades_requeridas ? e.habilidades_requeridas.slice(0, 1) : e.habilidadesRequeridas.slice(0, 1)}
                                  />
                                </div>
                              );
                            })}
                        </div>
                      }
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          width: "100%",
                          height: "10%",
                        }}
                      >
                        <div>
                          <ReactPaginate
                            className="paginateStyle"
                            breakLabel="..."
                            nextLabel=">"
                            onPageChange={handlePageClick}
                            pageRangeDisplayed={3}
                            pageCount={page.totalPage}
                            previousLabel="<"
                            marginPagesDisplayed={1}
                            activeClassName='pageActUniversitie'
                            pageClassName="pageB"
                            nextClassName="nextB"
                            previousClassName='previBUniversitie'
                          />
                        </div>
                      </div>
                    </div>
                    <div className="new-home-empresa-statistics-container">
                      <div className="new-home-empresa-statistics-title">
                        <h2 style={{
                          fontFamily: "Poppins",
                          fontWeight: "700",
                          fontSize: "14px",
                          color:  university && university.color ? university.color.secondary : "#000"
                        }}>
                          {lang === 'es' ? 'Estadísticas de los aplicantes' : 'Applicants statistics'}
                        </h2>
                      </div>
                      <div className="new-home-empresa-statistics-body">
                        {
                          statistics.length ? (
                            <>
                              <div className="new-home-empresa-statistics-body-title">
                                <h2 style={{
                                  fontFamily: "Poppins",
                                  fontWeight: "700",
                                  fontSize: "14px",
                                  color: "#151515FC"
                                }}>

                                </h2>
                                {/*<div className="new-home-empresa-statistics-arrows">
                                  <div>
                                    <NavigateBeforeOutlinedIcon className='navigate-arrow-statistics' style={{color: university && university.color ? university.color.secondary : "#000"}} />
                                  </div>
                                  <div>
                                    <NavigateNextOutlinedIcon className='navigate-arrow-statistics' style={{color: university && university.color ? university.color.secondary : "#000"}} />
                                  </div>
                                </div>*/}
                              </div>

                              <div className="new-home-empresa-statistics-body-chart">
                                <BarChart
                                  width={250}
                                  height={300}
                                  data={statistics}
                                  margin={{
                                    top: 5,
                                    right: 20,
                                    left: 10,
                                    bottom: 5
                                  }}
                                >
                                  <XAxis dataKey="nombre" tick={{transform: 'rotate(90deg)'}}/>
                                  {/*<YAxis />*/}
                                  <Tooltip />
                                  <Legend />
                                  <Bar dataKey="aplicantes" fill={university && university.color ? university.color.secondary : "#202256"} label={renderCustomBarLabel}/>
                                </BarChart>
                              </div>
                            </>
                          ) :
                            statistics.length === 0 && (
                              <div style={{ display: "flex", justifyContent: "center", alignItems: "center", width: "100%", height: "100%", flexDirection: 'column' }}>
                                <img src={notFound} alt="no encontrado" />
                                <h2 style={{ fontFamily: "Poppins", fontWeight: "700", fontSize: "14px", color: "#151515FC" }}>
                                  {lang === 'es' ? 'No hay datos para mostrar' : 'No data to show'}
                                </h2>
                              </div>
                            )
                        }

                      </div>
                      <div style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "100%",
                        paddingTop: '20%'
                      }}>
                        {
                          proyectosCsv?.length > 0 ? (
                            <label className="new-home-company-save-op-button">
                          <CSVLink {...csvReport} style={{ textDecoration: 'none', color: 'white', textAlign: 'center' }}>
                            {lang === 'es' ? 'Descargar oportunidades' : 'Download opportunities'}
                          </CSVLink>
                            </label>
                          ) : null
                        }
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : redirect()
      }
    </div>
  )
}
