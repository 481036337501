import React from "react";
import './adminSidebar.css';
import logoCoally from '../../assets/logos/Logo-Unilever.png'
import { Link } from "react-router-dom";
import {HiOutlineHome} from 'react-icons/hi'
import { useDispatch} from "react-redux";
import { logoutUser } from "../../actions";
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import InsightsIcon from '@mui/icons-material/Insights';
export default function SideBarAdmin({location, style}){
  const dispatch = useDispatch()
  const handleLogout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    localStorage.removeItem("redirect");
    dispatch(logoutUser({}, "/unilever/admin/login"))
  }
    return (
      <div className={style ? 'Sidebar-Cont-admin-2': 'Sidebar-Cont-admin'}>
      <div className={'Sidebar-Sub-Cont-1-admin'}>
        <Link  to={'/unilever/admin'}>
          <img className={'Sidebar-Logo-admin'} src={logoCoally} alt={'Logo Coally'}/>
        </Link>
        <Link to={'/unilever/admin'}
              className={location === 'admin' ? 'Sidebar-Sub-Cont-But-active' : 'Sidebar-Sub-Cont-But'} style={location === 'admin' ? {color: '#fe7c7c'} : {color: '#ffffff'}}>
          {location === 'admin' ? <HiOutlineHome className={'Sidebar-Sub-Cont-Img-admin'} style={{color: '#fe7c7c', fontSize: '20px'}}/> : <HiOutlineHome className={'Sidebar-Sub-Cont-Img-admin'} style={{color: 'white', fontSize: '20px'}}/>}
          Inicio
        </Link>
        <Link to={'/unilever/admin/dashboard'} className={location === 'dashboard' ? 'Sidebar-Sub-Cont-But-active' : 'Sidebar-Sub-Cont-But'} style={location === 'dashboard' ? {color: '#fe7c7c', backgroundColor: '#dde9f5'} : {color: '#ffffff'}}>
          {
            location === 'dashboard' ? <InsightsIcon className={'Sidebar-Sub-Cont-Img-admin'} style={{color: '#fe7c7c', fontSize: '20px'}}/> : <InsightsIcon className={'Sidebar-Sub-Cont-Img-admin'} style={{color: 'white', fontSize: '20px'}}/>
          }
            Dashboard
        </Link>
      </div>
      <div className={'Sidebar-Sub-Cont-2-admin'}>
        <Link to={'#'} onClick={() => handleLogout()} className={'Sidebar-Sub-Cont-But-2-admin'} style={{color: 'white'}}>
          <LogoutOutlinedIcon className={'Sidebar-Sub-Cont-Img-admin'} style={{
            color: 'white',
            fontSize: '20px'
          }}/>
          Cerrar sesión
        </Link>
      </div>
    </div>
    )
}
