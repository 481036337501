import React, { useEffect, useState } from "react";
import { useSwipeable } from "react-swipeable";
import logoFab from '../../assets/logos/logo-fab-marca.png';
import logoFruco from '../../assets/logos/logo-fruco-marca.png';
import logoDove from '../../assets/logos/logo-dove-marca.png';
import logoAxe from '../../assets/logos/logo-axe-marca.png';
import logoMaizena from '../../assets/logos/logo-maizena-marca.png';
import logoRexona from '../../assets/logos/logo-rexona-marca.png';

import './CarrouselAliados.css'


export default function CarrouselMarcas({ lang }) {


    const sliderData = [
        (
            <div className="Container-Logos-Aliados2">               
                <img src={logoFab} alt='Coally' />                
                <img src={logoFruco} alt='Disruptia' />                
                <img src={logoDove} alt='Enel' />                              
            </div>
        ),
        (
            <div className="Container-Logos-Aliados2">
                <img src={logoAxe} alt='Talent' />
                <img src={logoMaizena} alt='Compromiso'/>    
                <img src={logoRexona} alt='Globant' />
            </div>
        )
    ]

    const [current, setCurrent] = useState(0);
    const [paused, setPaused] = useState(false);
    const length = sliderData.length;

    useEffect(() => {
        const interval = setInterval(() => {
            if (!paused) {
                setCurrent(current === length - 1 ? 0 : current + 1);
            }
        }, 3000);
        return () => clearInterval(interval);
    }, [current, length, paused]);


    const nextSlide = () => {
        setCurrent(current === length - 1 ? 0 : current + 1);
    }

    const prevSlide = () => {
        setCurrent(current === 0 ? length - 1 : current - 1);
    }

    const handlers = useSwipeable({
        onSwipedLeft: () => nextSlide(),
        onSwipedRight: () => prevSlide(),
    });


    return (
        <div
            {...handlers}
            onMouseEnter={() => setPaused(true)}
            onMouseLeave={() => setPaused(false)}
            className="container-carrousel"
        >
            {
                sliderData.map((slide, index) => {
                    return (
                        <div key={index} className={
                            index === current ? 'slide-home active' : 'slide-home'
                        }>
                            {index === current && (
                                slide
                            )}
                        </div>
                    )
                })
            }
        </div>

    )
}