import React, { useState } from "react";
import { registerFeedback, DeleteProjectsAdmin } from "../actions";
import HeaderEmpresa from "../components/HeaderEmpresa/HeaderEmpresa";
import './styles/Feedback.css';
import { connect, useSelector } from "react-redux";
import { Link } from 'react-router-dom';

import en from "../lang/en-US";
import es from "../lang/es-COL"
import counterpart from "counterpart";
import Translate from "react-translate-component";

function FormFeedback(props) {

  const [form, setValues] = useState({
    proyecto: props.project._id,
    calificacion: "",
    continuarias: "",
    comentario: "",
    usuario: props.user.id,
    empresa: '',
    satisfaccion: '',
    motivo: '',
    nivel_satisfaccion: '',
    interaccion: '',
    nivel_interaccion: '',
    calidad_precio: '',
    retorno: '',
    motivo_retorno: '',
    solucion: '',
    satisfaccion_tiempo: '',
    opcion: '',
    porque_opcion: '',
    calificacion_final:'',
    palabra_servicio: '',
    contratar: '',
    motivo_contratar: '',
    recomendacion: '',
    mensaje: '',
  });

  const handleInput = (event) => {
    setValues({
      ...form,
      [event.target.name]: event.target.value,
    });
  };
 const actualizar = (event)=>{
   setValues({
     ...form,
     proyecto: props.project._id,
     [event.target.name]: event.target.value,
     usuario: props.user.id
   })
 }
  const handleSubmit = (event) => {
    event.preventDefault();
    console.log(form)
    props.registerFeedback(form);
  };


  //const lang = useSelector(state => state.lang)

const palabraservicio=function(evento){
  if(evento.target.checked){
      setValues({
               ...form,
                palabra_servicio:[...form.palabra_servicio,evento.target.value]
              })
  }else{
      setValues({
          ...form,
           palabra_servicio:form.palabra_servicio.filter((ge) => ge !== evento.target.value)
         })
  }
}

  counterpart.registerTranslations('en', en)
  counterpart.registerTranslations('es', es)
  counterpart.setLocale(localStorage.getItem('langlocal'));

console.log(props)

const idioma = useSelector(state => state.lang)
  return (
    <>
      <HeaderEmpresa />
      {/* <select className="Header-lang" value={lang} onChange={(e)=>dispatch(changeLang(e.target.value))}>
        <option value="en">en-US</option>
        <option value="es">es-COL</option>
      </select> */}
      <div className="volver-legal">
        <Link className="volver-leg-icon" to="company/home">
          {" "}
          🠸 {<Translate content="ATRÁS" component="span" />}
        </Link>
      </div>
      <div className="feedback_content">
        <form className="feedback_form" onSubmit={handleSubmit}>
          <h2 className="title_form">{<Translate content="Hemosllegado" component="span" />}</h2>
          <p>{<Translate content="estamosfelices" component="span" />}</p>
          <label style={{ marginTop: "1em" }}>{<Translate content="califica" component="span" />}</label><br></br>
          <hr style={{width:'100%',color:'#21204a'}}/>
          <div style={{width:'100%'}}>
            <p style={{textAlign:'start'}}>{<Translate content="Nombredelaempresa" component="span" />}</p>
            <input onChange={(e)=>actualizar(e)} name="empresa"  type="text" className="input_mensaje_popup" placeholder={idioma==='en'?'Your answer':'Tu respuesta'} style={{ width: "100%", height: '50%', borderTop: '0', borderRight: '0', borderLeft: '0' }} />
          </div>
          <hr style={{width:'100%',color:'#21204a'}}/>
          <div style={{width:'100%'}}>
            <p style={{textAlign:'start'}}>{<Translate content="QuétansatisfechoestásconlaconsultoríallevadaacaboporCoally" component="span" />}</p>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <input onClick={(e)=>handleInput(e)} name="satisfaccion" value="Muy insatisfecho" type="radio" style={{ display: 'flex' }} />
              <p style={{ margin: '0' }}>{<Translate content="Muyinsatisfecho" component="span" />}</p>
            </div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <input onClick={(e)=>handleInput(e)} name="satisfaccion" value="Insatisfecho" type="radio" style={{ display: 'flex' }} />
              <p style={{ margin: '0' }}>{<Translate content="Insatisfecho" component="span" />}</p>
            </div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <input onClick={(e)=>handleInput(e)} name="satisfaccion" value="Satisfecho" type="radio" style={{ display: 'flex' }} />
              <p style={{ margin: '0' }}>{<Translate content="Satisfecho" component="span" />} </p>
            </div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <input onClick={(e)=>handleInput(e)} name="satisfaccion" value="Muy satisfecho" type="radio" style={{ display: 'flex' }} />
              <p style={{ margin: '0' }}>{<Translate content="Muysatisfecho" component="span" />}</p>
            </div>
          </div>
          <hr style={{width:'100%',color:'#21204a'}}/>
          <div style={{width:'100%'}}>
            <p style={{textAlign:'start'}}>{<Translate content="SinoestássatisfechoconeltrabajorealizadoquéconsiderasquepudieramejorarsiturespuestaesMuysatisfechoponNA" component="span" />}</p>
            <input onChange={(e)=>handleInput(e)} name="motivo"  type="text" className="input_mensaje_popup" placeholder={idioma==='en'?'Your answer':'Tu respuesta'} style={{ width: "100%", height: '50%', borderTop: '0', borderRight: '0', borderLeft: '0' }} />
          </div>
          <hr style={{width:'100%',color:'#21204a'}}/>
          <div style={{width:'100%'}}>
            <p style={{textAlign:'start'}}>{<Translate content="Quétansatisfechoestásconeldesempeñoquetuvoelequipodetrabajo" component="span" />}</p>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <input onClick={(e)=>handleInput(e)} name="nivel_satisfaccion" value="Muy insatisfecho" type="radio" style={{ display: 'flex' }} />
              <p style={{ margin: '0' }}>{<Translate content="Muyinsatisfecho" component="span" />}</p>
            </div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <input onClick={(e)=>handleInput(e)} name="nivel_satisfaccion" value="Insatisfecho" type="radio" style={{ display: 'flex' }} />
              <p style={{ margin: '0' }}>{<Translate content="Insatisfecho" component="span" />}</p>
            </div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <input onClick={(e)=>handleInput(e)} name="nivel_satisfaccion" value="Satisfecho" type="radio" style={{ display: 'flex' }} />
              <p style={{ margin: '0' }}>{<Translate content="Satisfecho" component="span" />} </p>
            </div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <input onClick={(e)=>handleInput(e)} name="nivel_satisfaccion" value="Muy satisfecho" type="radio" style={{ display: 'flex' }} />
              <p style={{ margin: '0' }}>{<Translate content="Muysatisfecho" component="span" />}</p>
            </div>
          </div>
          <hr style={{width:'100%',color:'#21204a'}}/>
          <div style={{width:'100%'}}>
            <p style={{textAlign:'start'}}>{<Translate content="Quéniveldeinteraccióntuvoconlosconsultores" component="span" />}</p>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <input onClick={(e)=>handleInput(e)} name="interaccion" value="Nunca" type="radio" style={{ display: 'flex' }} />
              <p style={{ margin: '0' }}>{<Translate content="Nunca" component="span" />}</p>
            </div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <input onClick={(e)=>handleInput(e)} name="interaccion" value="Pocas veces" type="radio" style={{ display: 'flex' }} />
              <p style={{ margin: '0' }}>{<Translate content="Pocasveces" component="span" />}</p>
            </div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <input onClick={(e)=>handleInput(e)} name="interaccion" value="La mayoria de las veces" type="radio" style={{ display: 'flex' }} />
              <p style={{ margin: '0' }}>{<Translate content="Lamayoriadelasveces" component="span" />} </p>
            </div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <input onClick={(e)=>handleInput(e)} name="interaccion" value="Siempre" type="radio" style={{ display: 'flex' }} />
              <p style={{ margin: '0' }}>{<Translate content="Siempre" component="span" />}</p>
            </div>
          </div>
          <hr style={{width:'100%',color:'#21204a'}}/>
          <div style={{width:'100%'}}>
            <p style={{textAlign:'start'}}>{<Translate content="Quétansatisfechoestásconestainteracción" component="span" />}</p>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <input onClick={(e)=>handleInput(e)} name="nivel_interaccion" value="Muy insatisfecho" type="radio" style={{ display: 'flex' }} />
              <p style={{ margin: '0' }}>{<Translate content="Muyinsatisfecho" component="span" />}</p>
            </div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <input onClick={(e)=>handleInput(e)} name="nivel_interaccion" value="Insatisfecho" type="radio" style={{ display: 'flex' }} />
              <p style={{ margin: '0' }}>{<Translate content="Insatisfecho" component="span" />}</p>
            </div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <input onClick={(e)=>handleInput(e)} name="nivel_interaccion" value="Satisfecho" type="radio" style={{ display: 'flex' }} />
              <p style={{ margin: '0' }}>{<Translate content="Satisfecho" component="span" />}</p>
            </div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <input onClick={(e)=>handleInput(e)} name="nivel_interaccion" value="Muy satisfecho" type="radio" style={{ display: 'flex' }} />
              <p style={{ margin: '0' }}>{<Translate content="Muysatisfecho" component="span" />}</p>
            </div>
          </div>
          <hr style={{width:'100%',color:'#21204a'}}/>
          <div style={{width:'100%'}}>
            <p style={{textAlign:'start'}}>{<Translate content="Conrespectoalacalidadpreciodelaconsultoríaconsiderasestar" component="span" />}</p>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <input onClick={(e)=>handleInput(e)} name="calidad_precio" value="Muy insatisfecho" type="radio" style={{ display: 'flex' }} />
              <p style={{ margin: '0' }}>{<Translate content="Muyinsatisfecho" component="span" />}</p>
            </div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <input onClick={(e)=>handleInput(e)} name="calidad_precio" value="Insatisfecho" type="radio" style={{ display: 'flex' }} />
              <p style={{ margin: '0' }}>{<Translate content="Insatisfecho" component="span" />}</p>
            </div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <input onClick={(e)=>handleInput(e)} name="calidad_precio" value="Satisfecho" type="radio" style={{ display: 'flex' }} />
              <p style={{ margin: '0' }}>{<Translate content="Satisfecho" component="span" />}</p>
            </div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <input onClick={(e)=>handleInput(e)} name="calidad_precio" value="Muy satisfecho" type="radio" style={{ display: 'flex' }} />
              <p style={{ margin: '0' }}>{<Translate content="Muysatisfecho" component="span" />}</p>
            </div>
          </div>
          <hr style={{width:'100%',color:'#21204a'}}/>
          <div style={{width:'100%'}}>
            <p style={{textAlign:'start'}}>{<Translate content="Volveríasausarnuestrosservicios" component="span" />}</p>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <input onClick={(e)=>handleInput(e)} name="retorno" value="si" type="radio" style={{ display: 'flex' }} />
              <p style={{ margin: '0' }}>{<Translate content="si" component="span" />}</p>
            </div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <input onClick={(e)=>handleInput(e)} name="retorno" value="no" type="radio" style={{ display: 'flex' }} />
              <p style={{ margin: '0' }}>no</p>
            </div>            
          </div>
          <hr style={{width:'100%',color:'#21204a'}}/>
          <div style={{width:'100%'}}>
            <p style={{textAlign:'start'}}>{<Translate content="Porque" component="span" />}</p>
            <input onChange={(e)=>handleInput(e)} name="motivo_retorno"  type="text" className="input_mensaje_popup" placeholder={idioma==='en'?'Your answer':'Tu respuesta'} style={{ width: "100%", height: '50%', borderTop: '0', borderRight: '0', borderLeft: '0' }} />
          </div>
          <hr style={{width:'100%',color:'#21204a'}}/>
          <div style={{width:'100%'}}>
            <p style={{textAlign:'start'}}>{<Translate content="Considerasquelaconsultoríarespondióatusnecesidades" component="span" />}</p>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <input onClick={(e)=>handleInput(e)} name="solucion" value="si" type="radio" style={{ display: 'flex' }} />
              <p style={{ margin: '0' }}>{<Translate content="si" component="span" />}</p>
            </div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <input onClick={(e)=>handleInput(e)} name="solucion" value="no" type="radio" style={{ display: 'flex' }} />
              <p style={{ margin: '0' }}>no</p>
            </div>            
          </div>
           
          <hr style={{width:'100%',color:'#21204a'}}/>
          <div style={{width:'100%'}}>
            <p style={{textAlign:'start'}}>{<Translate content="Quétansatisfechoestásconladuracióndeldesarrollodelproyecto" component="span" />}</p>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <input onClick={(e)=>handleInput(e)} name="satisfaccion_tiempo" value="Muy insatisfecho" type="radio" style={{ display: 'flex' }} />
              <p style={{ margin: '0' }}>{<Translate content="Muyinsatisfecho" component="span" />}</p>
            </div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <input onClick={(e)=>handleInput(e)} name="satisfaccion_tiempo" value="Insatisfecho" type="radio" style={{ display: 'flex' }} />
              <p style={{ margin: '0' }}>{<Translate content="Insatisfecho" component="span" />}</p>
            </div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <input onClick={(e)=>handleInput(e)} name="satisfaccion_tiempo" value="Satisfecho" type="radio" style={{ display: 'flex' }} />
              <p style={{ margin: '0' }}>{<Translate content="Satisfecho" component="span" />}</p>
            </div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <input onClick={(e)=>handleInput(e)} name="satisfaccion_tiempo" value="Muy satisfecho" type="radio" style={{ display: 'flex' }} />
              <p style={{ margin: '0' }}>{<Translate content="Muysatisfecho" component="span" />}</p>
            </div>
          </div>
         
          <hr style={{width:'100%',color:'#21204a'}}/>
          <div style={{width:'100%'}}>
            <p style={{textAlign:'start'}}>{<Translate content="AntesdeescogeraCoallyparallevaracaboelproyectotuvisteotraopción" component="span" />}</p>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <input onClick={(e)=>handleInput(e)} name="opcion" value="si" type="radio" style={{ display: 'flex' }} />
              <p style={{ margin: '0' }}>{<Translate content="si" component="span" />}</p>
            </div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <input onClick={(e)=>handleInput(e)} name="opcion" value="no" type="radio" style={{ display: 'flex' }} />
              <p style={{ margin: '0' }}>no</p>
            </div>            
          </div>
          <hr style={{width:'100%',color:'#21204a'}}/>
          <div style={{width:'100%'}}>
            <p style={{textAlign:'start'}}>{<Translate content="Silarespuestaesafirmativabajoquécriteriotomasteladecisión" component="span" />}</p>
            <input onChange={(e)=>handleInput(e)} name="porque_opcion" type="text" className="input_mensaje_popup" placeholder={idioma==='en'?'Your answer':'Tu respuesta'} style={{ width: "100%", height: '50%', borderTop: '0', borderRight: '0', borderLeft: '0' }} />
          </div>
          <hr style={{width:'100%',color:'#21204a'}}/>
          <div style={{width:'100%'}}>
            <p style={{textAlign:'start'}}>{<Translate content="Siendo1lacalificaciónmásbajay4lamásaltacuáleselimpactopositivoquecreesquevasaobtenerdeestaconsultoría" component="span" />}</p>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <input onClick={(e)=>handleInput(e)} name="calificacion_final" value="1" type="radio" style={{ display: 'flex' }} />
              <p style={{ margin: '0' }}>1</p>
            </div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <input onClick={(e)=>handleInput(e)} name="calificacion_final" value="2" type="radio" style={{ display: 'flex' }} />
              <p style={{ margin: '0' }}>2</p>
            </div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <input onClick={(e)=>handleInput(e)} name="calificacion_final" value="3" type="radio" style={{ display: 'flex' }} />
              <p style={{ margin: '0' }}>3 </p>
            </div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <input onClick={(e)=>handleInput(e)} name="calificacion_final" value="4" type="radio" style={{ display: 'flex' }} />
              <p style={{ margin: '0' }}>4</p>
            </div>
          </div>
          <hr style={{width:'100%',color:'#21204a'}}/>
          <div style={{width:'100%'}}>
            <p style={{textAlign:'start'}}>{<Translate content="CuáldelassiguientespalabrasutilizaríasparadescribirnuestroservicioSeleccionatodaslasopcionesqueconsideres" component="span" />}</p>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <input onClick={(e)=>palabraservicio(e)} name="palabra_servicio" value="Confiable" type="checkbox" style={{ display: 'flex' }} />
              <p style={{ margin: '0' }}>{<Translate content="Confiable" component="span" />}</p>
            </div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <input onClick={(e)=>palabraservicio(e)} name="palabra_servicio" value="Innovador" type="checkbox" style={{ display: 'flex' }} />
              <p style={{ margin: '0' }}>{<Translate content="Innovador" component="span" />}</p>
            </div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <input onClick={(e)=>palabraservicio(e)} name="palabra_servicio" value="Ineficaz" type="checkbox" style={{ display: 'flex' }} />
              <p style={{ margin: '0' }}>{<Translate content="Ineficaz" component="span" />}</p>
            </div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <input onClick={(e)=>palabraservicio(e)} name="palabra_servicio" value="Poco fiable" type="checkbox" style={{ display: 'flex' }} />
              <p style={{ margin: '0' }}>{<Translate content="Pocofiable" component="span" />}</p>
            </div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <input onClick={(e)=>palabraservicio(e)} name="palabra_servicio" value="Poco innovador" type="checkbox" style={{ display: 'flex' }} />
              <p style={{ margin: '0' }}>{<Translate content="Pocoinnovador" component="span" />}</p>
            </div>
          </div>
          <hr style={{width:'100%',color:'#21204a'}}/>
          <div style={{width:'100%'}}>
            <p style={{textAlign:'start'}}>{<Translate content="Leinteresaríacontrataraalgúnmiembrodelequipoquehizopartedeesteproyecto" component="span" />}</p>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <input onClick={(e)=>handleInput(e)} name="contratar" value="si" type="radio" style={{ display: 'flex' }} />
              <p style={{ margin: '0' }}>{<Translate content="si" component="span" />}</p>
            </div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <input onClick={(e)=>handleInput(e)} name="contratar" value="no" type="radio" style={{ display: 'flex' }} />
              <p style={{ margin: '0' }}>no</p>
            </div>            
          </div>
          <hr style={{width:'100%',color:'#21204a'}}/>
          <div style={{width:'100%'}}>
            <p style={{textAlign:'start'}}>{<Translate content="Sisurespuestaesafirmativaaquiénleinteresaríavincular" component="span" />}</p>
            <input onChange={(e)=>handleInput(e)} name="motivo_contratar" type="text" className="input_mensaje_popup" placeholder={idioma==='en'?'Your answer':'Tu respuesta'} style={{ width: "100%", height: '50%', borderTop: '0', borderRight: '0', borderLeft: '0' }} />
          </div>
          <hr style={{width:'100%',color:'#21204a'}}/>
          <div style={{width:'100%'}}>
            <p style={{textAlign:'start'}}>{<Translate content="RecomendaríasCoallyatuscolegasuotrasempresas" component="span" />}</p>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <input onClick={(e)=>handleInput(e)} name="recomendacion" value="si" type="radio" style={{ display: 'flex' }} />
              <p style={{ margin: '0' }}>{<Translate content="si" component="span" />}</p>
            </div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <input onClick={(e)=>handleInput(e)} name="recomendacion" value="no" type="radio" style={{ display: 'flex' }} />
              <p style={{ margin: '0' }}>no</p>
            </div>            
          </div> 
          <hr style={{width:'100%',color:'#21204a'}}/>
          <div style={{width:'100%'}}>
            <p style={{textAlign:'start'}}>{<Translate content="EnestecasoQuemensajeledejaríasafuturosclientesqueentrenanuestrapágina" component="span" />}</p>
            <input onChange={(e)=>handleInput(e)} name="mensaje" type="text" className="input_mensaje_popup" placeholder={idioma==='en'?'Your answer':'Tu respuesta'} style={{ width: "100%", height: '50%', borderTop: '0', borderRight: '0', borderLeft: '0' }} />
          </div>         
          <hr style={{width:'100%',color:'#21204a'}}/>
          <label style={{ marginTop: "1em" }}>{<Translate content="califica" component="span" />}</label><br></br>
          <div className="section_feedback clasificacion">
            <label className="perfil__score-form-label2" style={{ display: 'none' }} htmlFor="radio0">
              ★
            </label>
            <input 
              className="perfil__score-form-input "
              id="radio0"
              type="radio"
              name="calificacion"
              value="5"
              onChange={handleInput}
            />
            <label className="perfil__score-form-label2" htmlFor="radio0">
              ★
            </label>
            <input 
              className="perfil__score-form-input "
              id="radio1"
              type="radio"
              name="calificacion"
              value="4"
              onChange={handleInput}
            />
            <label className="perfil__score-form-label2 " htmlFor="radio1">
              ★
            </label>
            <input 
              className="perfil__score-form-input "
              id="radio2"
              type="radio"
              name="calificacion"
              value="3"
              onChange={handleInput}
            />
            <label className="perfil__score-form-label2 " htmlFor="radio2">
              ★
            </label>
            <input 
              className="perfil__score-form-input "
              id="radio3"
              type="radio"
              name="calificacion"
              value="2"
              onChange={handleInput}
            />
            <label className="perfil__score-form-label2 " htmlFor="radio3">
              ★
            </label>
            <input 
              className="perfil__score-form-input "
              id="radio4"
              type="radio"
              name="calificacion"
              value="1"
              onChange={handleInput}
            />
            <label className="perfil__score-form-label2" htmlFor="radio4">
              ★
            </label>
            <input 
              className="perfil__score-form-input "
              id="radio5"
              type="radio"
              name="calificacion"
              value="1"
              onChange={handleInput}
            />
          </div>
          <div className="section_feedback">
            <label>{<Translate content="continuarioas" component="span" />}</label><br></br>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <div >
                <input onClick={(e)=>handleInput(e)} type="radio" id="si" className="radio-button" name="continuarias" onChange={handleInput}></input>
                <label htmlFor="si" className="label-radio">{<Translate content="si" component="span" />}</label>
                <input onClick={(e)=>handleInput(e)} type="radio" id="no" className="radio-button" name="continuarias" onChange={handleInput}></input>
                <label htmlFor="no" className="label-radio">No</label>
              </div></div></div>
          <div className="section_feedback">
            <label className="comment-label">
              {<Translate content="tienescomentario" component="span" />}<br />
              <textarea
                className="textarea2" placeholder={idioma==='en'?'Comment':'Comentario'} name="comentario" onChange={handleInput} />
            </label>
          </div>
          <button className="button_feedback" onSubmit={handleSubmit}>{<Translate content="ENVIAR" component="span" />}</button>
        </form>
      </div>
    </>
  );
}


const mapDispatchToProps = {
  registerFeedback,
  DeleteProjectsAdmin,
};

const mapStateToProps = (state) => {
  return {
    user: state.user,
    project: state.ActualProject,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FormFeedback);
