/* eslint-disable */
import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import './OportunidadesNewLinked.css'
import UniversitiesSideBar from "../components/sidebar/UniversitiesSideBar";
import UniversitiesNavbar from "../components/navbar/UniversitiesNavbar";
import { useSelector, useDispatch } from "react-redux";
import ReactPaginate from "react-paginate";
import { useHistory } from "react-router-dom";
import SearchIcon from "@mui/icons-material/Search";
import SmallCardLinked from "./cards/SmallCardLinked";
import BigCardLinked from "./cards/BigCardLinked";
import { Link } from "react-router-dom";
import Spinner from 'react-bootstrap/Spinner'
import { getUserProjects, tourStatus } from "../../actions";
import Loader from "../../profesionalpag/Loader/LoaderHome";
import { UniversityContext } from "../context/UniversitiesContext";
import UseTour from "../components/tour/UseTour";


const UniversitiesOportunidades = () => {
  const { university } = useContext(UniversityContext);
  const [ready, setReady] = useState(false);
  const [loading, setLoading] = useState(false);
  const { lang, user, cv , tourViewed} = useSelector(state => state)
  const [aplicable, setaplicable] = useState(false)
  const [refresh, setRefresh] = useState(false)
  const [projects, setProjects] = useState([]);
  const projectsUser = useSelector(state => state.userProjects)
  const [selectedOportunity, setSelectedOportunity] = useState(null);
  const [width, setWidth] = useState(window.innerWidth);
  const [height, setHeight] = useState(window.innerHeight);
  const [filtro, setfiltro] = useState("");
  const [searching, setSearching] = useState(false);
  const cancel = new AbortController()
  const [page, setPage] = useState({
    currentPage: 0,
    totalPage: 0,
  });
  const history = useHistory();
  const dispatch = useDispatch();
  const redirect = () => {
    if (!user.id) {
      return history.push("/");
    }
    history.push("/company/home");
  };

  const updateWidthAndHeight = () => {
    setWidth(window.innerWidth);
    setHeight(window.innerHeight);
  }

  useEffect(() => {
    window.addEventListener("resize", updateWidthAndHeight);
    return () => window.removeEventListener("resize", updateWidthAndHeight);
  }, [width, height]);

  useEffect(() => {
    setLoading(true)
    axios.get((`https://findally-backend.herokuapp.com/api/Project?skip=${page.currentPage}&limit=5&status=En+espera`))
      .then(r => {
        setProjects(r.data.elements)
        setSelectedOportunity(r.data.elements[0] ? r.data.elements[0] : null)
        if (cv && r.data.elements[0]) {
          const empleoData = {
            ID: r.data.elements[0]._id,
            Descripcion: r.data.elements[0]?.DescribeProyecto || '',
            Experiencia: r.data.elements[0]?.experienciaAnos ? `${r.data.elements[0].experienciaAnos} años` : '',
            Habilidades_tecnicas: r.data.elements[0]?.habilidadesTecnicas?.join(', ') || '',
            Habilidades_Blandas: r.data.elements[0]?.habilidadesBlandas?.join(', ') || '',
            Idiomas: r.data.elements[0]?.Lenguaje?.join(', ') || '',
            Salario: r.data.elements[0]?.presupuesto || '',
            Beneficios: r.data.elements[0]?.listadoBeneficios?.join(', ') || '',
            Tipo_de_empleo: r.data.elements[0]?.modalidadDeContratacion || '',
            Modalidad: r.data.elements[0]?.modalidadDeTrabajo || '',
          };
          const aplicantesData = {
            ID: cv._id,
            Descripcion: cv.extracto || '',
            Experiencia: cv.experiencia?.flatMap(exp => {
              return exp.cargos?.map(cargo => {
                return {
                  nombre_cargo: cargo.nombrecargo ? cargo.nombrecargo : '',
                  duracion: cargo.duracion ? cargo.duracion : '',
                  ubicacion: cargo.ubicacion ? cargo.ubicacion : '',
                  descripcion: cargo.descripcion ? cargo.descripcion : '',
                };
              }) || []
            }) || [],
            Estudios: cv.educacion?.map((edu) => edu.Titulo_Certificacion).join(", ") || '',
            Habilidades_tecnicas: cv.aptitudes_principales?.join(', ') || '',
            Habilidades_Blandas: '',
            Logros: '',
            Trabajo_Social: '',
            Universidad: '',
            Idiomas: cv.languages?.map((idioma) => idioma.Language).join(", ") || '',
            Salario: cv.aspiracion_salarial || '',
            Tipo_de_empleo: '',
            Modalidad: '',
            Puntaje_hr: 0
          }
          const postData = { empleo: empleoData, aplicantes: [aplicantesData] };
          axios.post('https://resume-matching-deploy.herokuapp.com/aplicantes-empleo', postData, { timeout: 3 * 60 * 1000 })
            .then(res => {
              const { fit1 } = res.data.response
              setSelectedOportunity({ ...r.data.elements[0], fit: fit1 })
              console.log(fit1)
            }).catch(err => {
              console.log(err)
            })
        }
        setLoading(false)
      }).catch((r) => {
        if (r.name === 'AbortError') return console.log('fetch aborted');
        setLoading(false)
      })
    return () => {
      cancel.abort()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cv])

  useEffect(() => {
    axios.get((`https://findally-backend.herokuapp.com/api/Project?limit=&status=En+espera`))
      .then(r => {
        setPage({ totalPage: Math.ceil(r.data?.elements?.length / 5) })
      }).catch((r) => {
        if (r.name === 'AbortError') return console.log('fetch aborted');
        console.log(r)
      })
    return () => {
      cancel.abort()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page.totalPage])

  useEffect(() => { // eslint-disable-next-line
    dispatch(getUserProjects(user.id))
    return () => {
    }
    // eslint-disable-next-line
  }, [user.id, refresh])

  useEffect(() => {
    if (projectsUser.length >= 0 && selectedOportunity) {
      projectsUser.filter(
        (project) => project._id === selectedOportunity._id
      ).length > 0
        ? setaplicable(true)
        : setaplicable(false);
      setReady(true)
    } else {
      setReady(true)
    }
    return () => {
    }
    // eslint-disable-next-line
  }, [projectsUser, selectedOportunity?._id, refresh])

  useEffect(() => {
    if (user?.id) {
      axios.get("https://findally-backend.herokuapp.com/user/" + user.id, {
        signal: cancel.signal
      }).then(respuesta => {
        if (respuesta.data.cv_file) {
          setaplicable(true)
        }
      }).catch((r) => {
        if (r.name === 'AbortError') return console.log('fetch aborted');
      })
    }
    return () => {
      cancel.abort();
    }
  }, [user.id]);

  const handlePageClick = (e) => {
    setProjects([])
    setSelectedOportunity(null)
    axios(`https://findally-backend.herokuapp.com/api/Project?skip=${e.selected}&limit=5&status=En+espera`)
      .then(r => {
        setProjects(r.data.elements)
        setSelectedOportunity(r.data.elements[0] ? r.data.elements[0] : null)
        if (cv && r.data.elements[0]) {
          const empleoData = {
            ID: r.data.elements[0]._id,
            Descripcion: r.data.elements[0]?.DescribeProyecto || '',
            Experiencia: r.data.elements[0]?.experienciaAnos ? `${r.data.elements[0].experienciaAnos} años` : '',
            Habilidades_tecnicas: r.data.elements[0]?.habilidadesTecnicas?.join(', ') || '',
            Habilidades_Blandas: r.data.elements[0]?.habilidadesBlandas?.join(', ') || '',
            Idiomas: r.data.elements[0]?.Lenguaje?.join(', ') || '',
            Salario: r.data.elements[0]?.presupuesto || '',
            Beneficios: r.data.elements[0]?.listadoBeneficios?.join(', ') || '',
            Tipo_de_empleo: r.data.elements[0]?.modalidadDeContratacion || '',
            Modalidad: r.data.elements[0]?.modalidadDeTrabajo || '',
          };
          const aplicantesData = {
            ID: cv._id,
            Descripcion: cv.extracto || '',
            Experiencia: cv.experiencia?.flatMap(exp => {
              return exp.cargos?.map(cargo => {
                return {
                  nombre_cargo: cargo.nombrecargo ? cargo.nombrecargo : '',
                  duracion: cargo.duracion ? cargo.duracion : '',
                  ubicacion: cargo.ubicacion ? cargo.ubicacion : '',
                  descripcion: cargo.descripcion ? cargo.descripcion : '',
                };
              }) || []
            }) || [],
            Estudios: cv.educacion?.map((edu) => edu.Titulo_Certificacion).join(", ") || '',
            Habilidades_tecnicas: cv.aptitudes_principales?.join(', ') || '',
            Habilidades_Blandas: '',
            Logros: '',
            Trabajo_Social: '',
            Universidad: '',
            Idiomas: cv.languages?.map((idioma) => idioma.Language).join(", ") || '',
            Salario: cv.aspiracion_salarial || '',
            Tipo_de_empleo: '',
            Modalidad: '',
          }
          const postData = { empleo: empleoData, aplicantes: [aplicantesData] };
          axios.post('https://resume-matching-deploy.herokuapp.com/aplicantes-empleo', postData, { timeout: 3 * 60 * 1000 })
            .then(res => {
              const { fit1 } = res.data.response
              setSelectedOportunity({ ...r.data.elements[0], fit: fit1 })
              console.log(fit1)
            }).catch(err => {
              console.log(err)
            })
          return setPage({
            ...page,
            currentPage: page.currentPage + 1,
          })
        }
        setPage({
          ...page,
          currentPage: page.currentPage + 1,
        })
      })
  }

  const handleClickOportunity = (oportunity) => {
    if (cv) {
      setSelectedOportunity(oportunity);
      const empleoData = {
        ID: oportunity._id,
        Descripcion: oportunity?.DescribeProyecto || '',
        Experiencia: oportunity?.experienciaAnos ? `${oportunity.experienciaAnos} años` : '',
        Habilidades_tecnicas: oportunity?.habilidadesTecnicas?.join(', ') || '',
        Habilidades_Blandas: oportunity?.habilidadesBlandas?.join(', ') || '',
        Idiomas: oportunity?.Lenguaje?.join(', ') || '',
        Salario: oportunity?.presupuesto || '',
        Beneficios: oportunity?.listadoBeneficios?.join(', ') || '',
        Tipo_de_empleo: oportunity?.modalidadDeContratacion || '',
        Modalidad: oportunity?.modalidadDeTrabajo || '',
      };
      const aplicantesData = {
        ID: cv._id,
        Descripcion: cv.extracto || '',
        Experiencia: cv.experiencia?.flatMap(exp => {
          return exp.cargos?.map(cargo => {
            return {
              nombre_cargo: cargo.nombrecargo ? cargo.nombrecargo : '',
              duracion: cargo.duracion ? cargo.duracion : '',
              ubicacion: cargo.ubicacion ? cargo.ubicacion : '',
              descripcion: cargo.descripcion ? cargo.descripcion : '',
            };
          }) || []
        }) || [],
        Estudios: cv.educacion?.map((edu) => edu.Titulo_Certificacion).join(", ") || '',
        Habilidades_tecnicas: cv.aptitudes_principales?.join(', ') || '',
        Habilidades_Blandas: '',
        Logros: '',
        Trabajo_Social: '',
        Universidad: '',
        Idiomas: cv.languages?.map((idioma) => idioma.Language).join(", ") || '',
        Salario: cv.aspiracion_salarial || '',
        Tipo_de_empleo: '',
        Modalidad: '',
      }
      const postData = { empleo: empleoData, aplicantes: [aplicantesData] };
      axios.post('https://resume-matching-deploy.herokuapp.com/aplicantes-empleo', postData, { timeout: 3 * 60 * 1000 })
        .then(res => {
          const { fit1 } = res.data.response
          setSelectedOportunity({ ...oportunity, fit: fit1 })
          console.log(fit1)
        }).catch(err => {
          console.log(err)
        })
      return
    }
    setSelectedOportunity(oportunity)
  }

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      if (filtro?.trim() === "") {
        return axios.get(`https://findally-backend.herokuapp.com/api/Project?skip=${page.currentPage}&limit=5&status=En+espera`)
          .then((res) => {
            setProjects(res.data.elements)
            setSelectedOportunity(res.data.elements[0] ? res.data.elements[0] : null)
          })
          .catch((err) => {
            console.log(err)
          })
      }
      setSearching(true)
      axios.post(`https://findally-backend.herokuapp.com/api/Project/text`, { text: filtro })
        .then((res) => {
          setProjects(res.data)
          setSelectedOportunity(res.data[0] ? res.data[0] : null)
          setSearching(false)
        })
        .catch((err) => {
          setSearching(false)
          console.log(err)
        })
    }
  }

  const handleOnClickSearch = (e) => {
    if (filtro?.trim() === "") {
      return axios.get(`https://findally-backend.herokuapp.com/api/Project?skip=${page.currentPage}&limit=5&status=En+espera`)
        .then((res) => {
          setProjects(res.data.elements)
          setSelectedOportunity(res.data.elements[0] ? res.data.elements[0] : null)
        })
        .catch((err) => {
          console.log(err)
        })
    }
    setSearching(true)
    axios.post(`https://findally-backend.herokuapp.com/api/Project/text`, { text: filtro })
      .then((res) => {
        setProjects(res.data)
        setSelectedOportunity(res.data[0] ? res.data[0] : null)
        setSearching(false)
      })
      .catch((err) => {
        setSearching(false)
        console.log(err)
      })
  }




  const paginationActualStyles = document.createElement('style')
  paginationActualStyles.innerHTML = `.pageActUniversitie {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: ${university?.color && university?.color?.primary};
        width: 40px;
        border-radius: 100%;
        color: ${university?.color && university?.color?.secondary};
    }`
  document.head.appendChild(paginationActualStyles)

  const paginationPrevStyles = document.createElement('style')
  paginationPrevStyles.innerHTML = `.previBUniversitie {
        display: flex;
        justify-content: center;
        align-items: center;
        color: ${university?.color && university?.color?.secondary};
    }`
  document.head.appendChild(paginationPrevStyles)


  useEffect(() => {
    if (tourViewed === true) {
      return
    }
    setTimeout(() => {
      dispatch(tourStatus({ run: true, stepIndex: 2 }))
    }, 500);
  }, [dispatch, tourViewed])

  return (
    <>
      {university && university.color && <div className={"contenedor-oportunidad"}>
        {user && user.roles === "coallier" ? (
          <div>
            <UniversitiesSideBar location={"oportunidades"} />
            <div
              className="BodyWeb"
              style={{
                padding: "0rem 2rem",
                position: "absolute",
                right: "0",
              }}>
              <UniversitiesNavbar locate={'oportunidades'} />
              <div className={"contenedor-tour"}>
              <UseTour />
            </div>
              <div className="oportunidades-linked-global-container" id='projects'>
                <div className="Profesional-Home-container-inner">
                  <div className="Profesional-Home-Contenedor-1-new">
                    <h1 style={{
                      fontSize: '20px',
                      fontWeight: '400',
                      color: '#393939',
                      textAlign: 'center'
                    }}>
                      {lang === 'es' ? 'Es momento de buscar' : 'It´s time to look for'}
                    </h1>
                  </div>
                  <div className="Profesional-Home-Contenedor-2-new">
                    <h1 style={{
                      color: '#393939',
                      fontSize: '40px',
                      fontWeight: '700',
                      textAlign: 'left'
                    }}>
                      {lang === 'es' ? 'nuevas oportunidades laborales' : 'new job opportunities'}
                    </h1>
                  </div>
                  <div className="Profesional-Home-Contenedor-3-new" style={{
                    paddingBottom: '2rem'
                  }}>
                    <div className="container-search-oportunities">
                      <SearchIcon className={'lupa'} />
                      <input
                        className={'search-oportunities-input'}
                        style={{
                          border: 'none',
                          marginRight: 0
                        }}
                        placeholder={lang === 'es' ? 'Buscar oportunidades' : 'Search opportunities'}
                        onChange={(e) => setfiltro(e.target.value)}
                        onKeyPress={handleKeyPress}
                      />

                      <button style={{
                        backgroundColor: university?.color && university?.color?.primary,
                        height: '33px',
                        width: '118px',
                        borderRadius: '5px',
                        color: university?.color && university?.color?.secondary,
                        textAlign: 'center',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                        onClick={handleOnClickSearch}
                      >
                        {searching ? (
                          <Spinner animation="border" size="sm" />
                        ) :
                          <span>
                            {
                              lang === 'es' ? 'Buscar' : 'Search'
                            }
                          </span>}
                      </button>
                    </div>
                  </div>
                  <div className="oportunidades-cards-container-linked">
                    <div className="oportunidades-small-cards-container-linked">
                      {
                        projects && projects?.map((e, i) => {
                          if (width < 951) {
                            return (
                              <Link to={`/consultor/project/${e._id}`} style={{ width: '100%' }}
                                key={i}>
                                <SmallCardLinked
                                  place={e?.municipio ? e.municipio : ''}
                                  image={e?.usuario.avatar ? e.usuario.avatar : e.imageUrl ? e.imageUrl : ''}
                                  title={e?.NombreOportunidad ? e.NombreOportunidad : e?.nombre === 'Unnamed Opportunity' ? 'Oportunidad sin nombre' : e.nombre}
                                  active={'small-card-linked-container'}
                                  company={e?.NombreEmpresa ? e.NombreEmpresa : ''}
                                  date={e?.fecha_inicio ? e.fecha_inicio : ''}
                                  width={width} />
                              </Link>
                            )
                          } else {
                            return (
                              <div onClick={() => handleClickOportunity(e)} key={i}>
                                <SmallCardLinked
                                  place={e?.municipio ? e.municipio : ''}
                                  image={e?.usuario.avatar ? e.usuario.avatar : e.imageUrl ? e.imageUrl : ''}
                                  title={e?.NombreOportunidad ? e.NombreOportunidad : e?.nombre === 'Unnamed Opportunity' ? 'Oportunidad sin nombre' : e.nombre}
                                  date={e?.fecha_inicio ? e.fecha_inicio : ''}
                                  active={e?._id === selectedOportunity?._id ? 'small-card-linked-container-active' : 'small-card-linked-container'}
                                  width={width}
                                  handleClickOportunity={handleClickOportunity}
                                  oportunity={e}
                                  selectedOportunity={selectedOportunity}
                                />
                              </div>
                            )
                          }
                        })
                      }
                      <div className='paginate-oportunities-new-linked'>
                        {/*si llega a fallar sacar la condicion del loader y dejar unicamente el projects && */}
                        {projects ? <ReactPaginate
                          className="paginateStyle"
                          breakLabel="..."
                          nextLabel=">"
                          onPageChange={handlePageClick}
                          pageRangeDisplayed={3}
                          pageCount={page.totalPage}
                          previousLabel="<"
                          marginPagesDisplayed={1}
                          activeClassName='pageActUniversitie'
                          pageClassName="pageB"
                          nextClassName="nextB"
                          previousClassName='previBUniversitie'
                        /> : <div style={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          width: '100%'
                        }}>
                          <Loader render={loading} array={projects} altura={15} />
                        </div>}
                      </div>
                    </div>
                    <div className="oportunidades-big-cards-container-linked">
                      {
                        selectedOportunity && ready &&
                        <BigCardLinked
                          setaplicable={setaplicable}
                          place={selectedOportunity?.municipio ? selectedOportunity.municipio : ''}
                          idProyect={selectedOportunity?._id ? selectedOportunity._id : ''}
                          applied={aplicable}
                          setRefresh={setRefresh}
                          habilities={selectedOportunity?.habilidadesTecnicas || selectedOportunity?.habilidadesRequeridas ? selectedOportunity.habilidadesTecnicas : ''}
                          image={selectedOportunity?.usuario.avatar ? selectedOportunity.usuario.avatar : selectedOportunity.imageUrl ? selectedOportunity.imageUrl : ''}
                          title={selectedOportunity?.NombreOportunidad ? selectedOportunity.NombreOportunidad : selectedOportunity?.nombre === 'Unnamed Opportunity' ? 'Oportunidad sin nombre' : selectedOportunity.nombre}
                          company={selectedOportunity?.NombreEmpresa ? selectedOportunity.NombreEmpresa : ''}
                          description={selectedOportunity?.DescribeProyecto ? selectedOportunity.DescribeProyecto : ''}
                          responsabilities={selectedOportunity?.responsabilidadYfunciones ? selectedOportunity.responsabilidadYfunciones : ''}
                          date={selectedOportunity?.fecha_inicio ? selectedOportunity.fecha_inicio : ''}
                          language={selectedOportunity?.Lenguaje ? selectedOportunity.Lenguaje : ''}
                          contract={selectedOportunity?.modalidadDeContratacion ? selectedOportunity.modalidadDeContratacion : ''}
                          salary={selectedOportunity?.presupuesto ? selectedOportunity.presupuesto : ''}
                          experience={selectedOportunity?.experienciaAnos ? selectedOportunity.experienciaAnos : ''}
                          aplicantes={selectedOportunity?.aplicantes ? selectedOportunity.aplicantes.length : ''}
                          listadoBeneficios={selectedOportunity?.listadoBeneficios ? selectedOportunity.listadoBeneficios : ''}
                          descripcionComisiones={selectedOportunity?.descripcionComision ? selectedOportunity.descripcionComision : ''}
                          descripcionBeneficios={selectedOportunity?.descripcionBeneficios ? selectedOportunity.descripcionBeneficios : ''}
                          fit={selectedOportunity?.fit ? selectedOportunity.fit : ''}
                        />
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : redirect()}
      </div>}
    </>
  )
}

export default UniversitiesOportunidades;
