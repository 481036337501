/* eslint-disable */
import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import './OportunidadesNewLinked.css'
import Sidebar from "../../components/Sidebar/Sidebar";
import UseTour from "../home/useTour";
import Navbar from "../../components/Navbar/Navbar";
import { useSelector, useDispatch } from "react-redux";
import ReactPaginate from "react-paginate";
import { useHistory } from "react-router-dom";
import SearchIcon from "@mui/icons-material/Search";
import SmallCardLinked from "./cards/SmallCardLinked";
import BigCardLinked from "./cards/BigCardLinked";
import { Link } from "react-router-dom";
import Spinner from 'react-bootstrap/Spinner'
import { getUserProjects, tourStatus } from "../../actions";
import Loader from "../Loader/LoaderHome";
import FiltersOportunities from "./filters/FiltersOportunities";
import SearchOportunity from "./filters/SearchOportunity";

const OportunidadesNewLinked = () => {
  const [ready, setReady] = useState(false);
  const [loading, setLoading] = useState(false);
  const { lang, user, tourViewed, cv } = useSelector(state => state)
  const [aplicable, setaplicable] = useState(false)
  const [refresh, setRefresh] = useState(false)
  const [projects, setProjects] = useState([]);
  const projectsUser = useSelector(state => state.userProjects)
  const [selectedOportunity, setSelectedOportunity] = useState(null);
  const [width, setWidth] = useState(window.innerWidth);
  const [height, setHeight] = useState(window.innerHeight);
  const [filtro, setfiltro] = useState();
  const cancel = new AbortController()
  const [page, setPage] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const history = useHistory();
  const dispatch = useDispatch();
  const redirect = () => {
    if (!user.id) {
      return history.push("/");
    }
    history.push("/company/home");
  };

  const updateWidthAndHeight = () => {
    setWidth(window.innerWidth);
    setHeight(window.innerHeight);
  }

  useEffect(() => {
    const currentQuery = new URLSearchParams(window.location.search);
    const modalidadDefault = currentQuery.get('modalidad') || '';
    const jornadaDefault = currentQuery.get('jornada') || '';
    const contratoDefault = currentQuery.get('contrato') || '';
    const profesionsDefault = currentQuery.get('profesion') || '';
    const lenguajeDefault = currentQuery.get('lenguaje') || '';
    const searchDefault = currentQuery.get('search') || '';
    setfiltro({
      modalidadDeTrabajo: modalidadDefault,
      modalidadDeJornada: jornadaDefault,
      modalidadDeContratacion: contratoDefault,
      empleos_alternativos: profesionsDefault,
      Lenguaje: lenguajeDefault,
      key: searchDefault,
    });
    setCurrentPage(0)
  }, [window.location.search]);


  useEffect(() => {
    window.addEventListener("resize", updateWidthAndHeight);
    return () => window.removeEventListener("resize", updateWidthAndHeight);
  }, [width, height]);

  useEffect(() => {
    setLoading(true)
    axios.post((`https://findally-backend.herokuapp.com/api/Project/filters/?skip=${currentPage}&limit=5`),
      filtro)
      .then(r => {
        setProjects(r.data.data)
        setPage(r.data.maxPage)
        setSelectedOportunity(r.data.data[0] ? r.data.data[0] : null)
        if (cv && r.data.data[0]) {
          const empleoData = {
            ID: r.data.data[0]._id,
            Descripcion: r.data.data[0]?.DescribeProyecto || '',
            Descripcion_empresa: r.data.data[0]?.descripcionEmpresa || '',
            Experiencia: r.data.data[0]?.experienciaAnos ? `${r.data.data[0].experienciaAnos} años` : '',
            Habilidades_tecnicas: r.data.data[0]?.habilidadesTecnicas?.join(', ') || '',
            Habilidades_Blandas: r.data.data[0]?.habilidadesBlandas?.join(', ') || '',
            Idiomas: r.data.data[0]?.Lenguaje?.join(', ') || '',
            Salario: r.data.data[0]?.presupuesto || '',
            Beneficios: r.data.data[0]?.listadoBeneficios?.join(', ') || '',
            Tipo_de_empleo: r.data.data[0]?.modalidadDeContratacion || '',
            Modalidad: r.data.data[0]?.modalidadDeTrabajo || '',
          };
          const aplicantesData = {
            ID: cv._id,
            Descripcion: cv.extracto || '',
            Experiencia: cv.experiencia?.flatMap(exp => {
              return exp.cargos?.map(cargo => {
                return {
                  nombre_cargo: cargo.nombrecargo ? cargo.nombrecargo : '',
                  duracion: cargo.duracion ? cargo.duracion : '',
                  ubicacion: cargo.ubicacion ? cargo.ubicacion : '',
                  descripcion: cargo.descripcion ? cargo.descripcion : '',
                };
              }) || []
            }) || [],
            Estudios: cv.educacion?.map((edu) => edu.Titulo_Certificacion).join(", ") || '',
            Habilidades_tecnicas: cv.aptitudes_principales?.join(', ') || '',
            Habilidades_Blandas: '',
            Logros: '',
            Trabajo_Social: '',
            Universidad: '',
            Idiomas: cv.languages?.map((idioma) => idioma.Language).join(", ") || '',
            Salario: cv.aspiracion_salarial || '',
            Tipo_de_empleo: '',
            Modalidad: '',
            Puntaje_hr: 0
          }
          const postData = { empleo: empleoData, aplicantes: [aplicantesData] };
          axios.post('https://resume-matching-deploy.herokuapp.com/aplicantes-empleo', postData, { timeout: 3 * 60 * 1000 })
            .then(res => {
              const { fit1 } = res.data.response
              setSelectedOportunity({ ...r.data.data[0], fit: fit1 })
              console.log(fit1)
            }).catch(err => {
              console.log(err)
            })
        }
        setLoading(false)
      }).catch((r) => {
        if (r.name === 'AbortError') return console.log('fetch aborted');
        setLoading(false)
      })
    return () => {
      cancel.abort()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cv, filtro, currentPage])



  useEffect(() => { // eslint-disable-next-line
    dispatch(getUserProjects(user.id))
    return () => {
    }
    // eslint-disable-next-line
  }, [user.id, refresh])

  useEffect(() => {
    if (projectsUser.length >= 0 && selectedOportunity) {
      projectsUser.filter(
        (project) => project._id === selectedOportunity._id
      ).length > 0
        ? setaplicable(true)
        : setaplicable(false);
      setReady(true)
    } else {
      setReady(true)
    }
    return () => {
    }
    // eslint-disable-next-line
  }, [projectsUser, selectedOportunity?._id, refresh])

  useEffect(() => {
    if (user?.id) {
      axios.get("https://findally-backend.herokuapp.com/user/" + user.id, {
        signal: cancel.signal
      }).then(respuesta => {
        if (respuesta.data.cv_file) {
          setaplicable(true)
        }
      }).catch((r) => {
        if (r.name === 'AbortError') return console.log('fetch aborted');
      })
    }
    return () => {
      cancel.abort();
    }
  }, [user.id]);

  const handlePageClick = (data) => {
    const selectedPage = data.selected;
    setCurrentPage(selectedPage);
  };

  const handleClickOportunity = (oportunity) => {
    if (cv) {
      setSelectedOportunity(oportunity);
      const empleoData = {
        ID: oportunity._id,
        Descripcion: oportunity?.DescribeProyecto || '',
        Descripcion_empresa: oportunity?.descripcionEmpresa || '',
        Experiencia: oportunity?.experienciaAnos ? `${oportunity.experienciaAnos} años` : '',
        Habilidades_tecnicas: oportunity?.habilidadesTecnicas?.join(', ') || '',
        Habilidades_Blandas: oportunity?.habilidadesBlandas?.join(', ') || '',
        Idiomas: oportunity?.Lenguaje?.join(', ') || '',
        Salario: oportunity?.presupuesto || '',
        Beneficios: oportunity?.listadoBeneficios?.join(', ') || '',
        Tipo_de_empleo: oportunity?.modalidadDeContratacion || '',
        Modalidad: oportunity?.modalidadDeTrabajo || '',
      };
      const aplicantesData = {
        ID: cv._id,
        Descripcion: cv.extracto || '',
        Experiencia: cv.experiencia?.flatMap(exp => {
          return exp.cargos?.map(cargo => {
            return {
              nombre_cargo: cargo.nombrecargo ? cargo.nombrecargo : '',
              duracion: cargo.duracion ? cargo.duracion : '',
              ubicacion: cargo.ubicacion ? cargo.ubicacion : '',
              descripcion: cargo.descripcion ? cargo.descripcion : '',
            };
          }) || []
        }) || [],
        Estudios: cv.educacion?.map((edu) => edu.Titulo_Certificacion).join(", ") || '',
        Habilidades_tecnicas: cv.aptitudes_principales?.join(', ') || '',
        Habilidades_Blandas: '',
        Logros: '',
        Trabajo_Social: '',
        Universidad: '',
        Idiomas: cv.languages?.map((idioma) => idioma.Language).join(", ") || '',
        Salario: cv.aspiracion_salarial || '',
        Tipo_de_empleo: '',
        Modalidad: '',
        Puntaje_hr: 0
      }
      const postData = { empleo: empleoData, aplicantes: [aplicantesData] };
      axios.post('https://resume-matching-deploy.herokuapp.com/aplicantes-empleo', postData, { timeout: 3 * 60 * 1000 })
        .then(res => {
          const { fit1 } = res.data.response
          setSelectedOportunity({ ...oportunity, fit: fit1 })
          console.log(fit1)
        }).catch(err => {
          console.log(err)
        })
      return
    }
    setSelectedOportunity(oportunity)
  }

  useEffect(() => {
    if (tourViewed === true) {
      return
    }
    setTimeout(() => {
      dispatch(tourStatus({ run: true, stepIndex: 2 }))
    }, 500);
  }, [dispatch, tourViewed])

  return (
    <div className={"contenedor-oportunidad"}>
      {user && user.roles === "coallier" ? (
        <div>
          <Sidebar location={"oportunidades"} />
          <div
            className="BodyWeb"
            style={{
              padding: "0rem 2rem",
              position: "absolute",
              right: "0",
            }}>
            <Navbar locate={'oportunidades'} />
            <div className={"contenedor-tour"}>
              <UseTour />
            </div>
            <div className="oportunidades-linked-global-container-coally" id='projects'>
              <div className="Profesional-Home-container-inner">
                <div className="Profesional-Home-Contenedor-1-new">
                  <h1 style={{
                    fontSize: '20px',
                    fontWeight: '400',
                    color: '#393939',
                    textAlign: 'center'
                  }}>
                    {lang === 'es' ? 'Es momento de buscar' : 'It´s time to look for'}
                  </h1>
                </div>
                <div className="Profesional-Home-Contenedor-2-new">
                  <h1 style={{
                    color: '#393939',
                    fontSize: '40px',
                    fontWeight: '700',
                    textAlign: 'left'
                  }}>
                    {lang === 'es' ? 'nuevas oportunidades laborales' : 'new job opportunities'}
                  </h1>
                </div>
                <div className="Profesional-Home-Contenedor-3-new" style={{
                  paddingBottom: '1rem'
                }}>
                  <SearchOportunity />
                </div>
                <div className="container-filters-oportunities">
                  <h3 className="text-filters-oportunities">
                    {lang === 'es' ? 'Filtrar por :' : 'Filter by :'}
                  </h3>
                  <FiltersOportunities />
                </div>
                <div className="oportunidades-cards-container-linked-coally">
                  <div className="oportunidades-small-cards-container-linked">
                    {
                      projects && projects?.map((e, i) => {
                        if (width < 951) {
                          return (
                            <Link to={`/consultor/project/${e._id}`} style={{ width: '100%' }} key={i}>
                              <SmallCardLinked
                                place={e?.municipio ? e.municipio : ''}
                                image={e?.usuario.avatar ? e.usuario.avatar : e.imageUrl ? e.imageUrl : ''}
                                aplicantes={e?.aplicantes ? e.aplicantes.length : ''}
                                title={e?.NombreOportunidad ? e.NombreOportunidad : e?.nombre === 'Unnamed Opportunity' ? 'Oportunidad sin nombre' : e.nombre}
                                active={'small-card-linked-container'}
                                company={e?.NombreEmpresa ? e.NombreEmpresa : ''}
                                date={e?.fecha_inicio ? e.fecha_inicio : ''}
                                modalidad={e?.modalidadDeTrabajo ? e.modalidadDeTrabajo : ''}
                                width={width} />
                            </Link>
                          )
                        } else {
                          return (
                            <div onClick={() => handleClickOportunity(e)} key={i}>
                              <SmallCardLinked
                                place={e?.municipio ? e.municipio : ''}
                                aplicantes={e?.aplicantes ? e.aplicantes.length : ''}
                                image={e?.usuario.avatar ? e.usuario.avatar : e.imageUrl ? e.imageUrl : ''}
                                title={e?.NombreOportunidad ? e.NombreOportunidad : e?.nombre === 'Unnamed Opportunity' ? 'Oportunidad sin nombre' : e.nombre}
                                date={e?.fecha_inicio ? e.fecha_inicio : ''}
                                company={e?.NombreEmpresa ? e.NombreEmpresa : ''}
                                active={e?._id === selectedOportunity?._id ? 'small-card-linked-container-active' : 'small-card-linked-container'}
                                width={width}
                                handleClickOportunity={handleClickOportunity}
                                oportunity={e}
                                selectedOportunity={selectedOportunity}
                                modalidad={e?.modalidadDeTrabajo ? e.modalidadDeTrabajo : ''}
                              />
                            </div>
                          )
                        }
                      })
                    }
                    <div className='paginate-oportunities-new-linked-coally'>
                      {/*si llega a fallar sacar la condicion del loader y dejar unicamente el projects && */}
                      {
                        projects.length ? <ReactPaginate
                          className="paginateStyleDashboard"
                          breakLabel="..."
                          nextLabel=">"
                          onPageChange={handlePageClick}
                          pageRangeDisplayed={1}
                          pageCount={page}
                          previousLabel="<"
                          marginPagesDisplayed={1}
                          activeClassName="pageAct"
                          pageClassName="pageB"
                          nextClassName="nextB"
                          previousClassName="previB"
                        />
                          :
                          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
                            <Loader render={loading} array={projects} altura={15} />
                          </div>
                      }
                    </div>
                  </div>
                  <div className="oportunidades-big-cards-container-linked">
                    {
                      selectedOportunity && ready &&
                      <BigCardLinked
                        setaplicable={setaplicable}
                        place={selectedOportunity?.municipio ? selectedOportunity.municipio : ''}
                        idProyect={selectedOportunity?._id ? selectedOportunity._id : ''}
                        applied={aplicable}
                        setRefresh={setRefresh}
                        habilities={selectedOportunity?.habilidadesTecnicas || selectedOportunity?.habilidadesRequeridas ? selectedOportunity.habilidadesTecnicas : ''}
                        image={selectedOportunity?.usuario.avatar ? selectedOportunity.usuario.avatar : selectedOportunity.imageUrl ? selectedOportunity.imageUrl : ''}
                        title={selectedOportunity?.NombreOportunidad ? selectedOportunity.NombreOportunidad : selectedOportunity?.nombre === 'Unnamed Opportunity' ? 'Oportunidad sin nombre' : selectedOportunity.nombre}
                        company={selectedOportunity?.NombreEmpresa ? selectedOportunity.NombreEmpresa : ''}
                        description={selectedOportunity?.DescribeProyecto ? selectedOportunity.DescribeProyecto : ''}
                        companyDescription={selectedOportunity?.descripcionEmpresa ? selectedOportunity.descripcionEmpresa : ''}
                        responsabilities={selectedOportunity?.responsabilidadYfunciones ? selectedOportunity.responsabilidadYfunciones : ''}
                        date={selectedOportunity?.fecha_inicio ? selectedOportunity.fecha_inicio : ''}
                        language={selectedOportunity?.Lenguaje ? selectedOportunity.Lenguaje : ''}
                        nivelIngles={selectedOportunity?.nivel_ingles ? selectedOportunity.nivel_ingles : ''}
                        contract={selectedOportunity?.modalidadDeContratacion ? selectedOportunity.modalidadDeContratacion : ''}
                        salary={selectedOportunity?.presupuesto ? selectedOportunity.presupuesto : ''}
                        experience={selectedOportunity?.experienciaAnos ? selectedOportunity.experienciaAnos : ''}
                        aplicantes={selectedOportunity?.aplicantes ? selectedOportunity.aplicantes.length : ''}
                        listadoBeneficios={selectedOportunity?.listadoBeneficios ? selectedOportunity.listadoBeneficios : ''}
                        descripcionComisiones={selectedOportunity?.descripcionComision ? selectedOportunity.descripcionComision : ''}
                        descripcionBeneficios={selectedOportunity?.descripcionBeneficios ? selectedOportunity.descripcionBeneficios : ''}
                        modalidad={selectedOportunity?.modalidadDeTrabajo ? selectedOportunity?.modalidadDeTrabajo : ''}
                        fit={selectedOportunity?.fit ? selectedOportunity.fit : ''}
                        userId={selectedOportunity?.usuario ? selectedOportunity.usuario : ''}
                      />
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : redirect()}
    </div>
  )
}

export default OportunidadesNewLinked;
