import React, {useContext, useState} from 'react';
import './UserInfo.css';
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
// import eye from '../../../assets/icons/eye.svg';
// import userImg from '../../../assets/icons/user.svg';
import CircularFrame from '../../../../components/CircularFrame/CircularFrame';
// import UserData from '../../../pages/Admin/components/UserData';
import CardConsultor from './UserConsultor/cosultor';
import Us from '../../../../assets/icons/user.svg';
import {UniversityContext} from "../../../context/UniversitiesContext";

export default function UserInfo({dataUser, route}) {
  const {university} = useContext(UniversityContext);
  const { lang, user, userPlan, cv } = useSelector(state => state)
  const [isHovered, setIsHovered] = useState(false)

  const styles = {
    button : {
      backgroundColor: 'transparent',
      color: university && university.color && university.color.quaternary ? university.color.quaternary : university.color.secondary,
      border: university && university.color && '0.5px solid ' + university.color.quaternary ? university.color.quaternary : university.color.secondary,
    },
    buttonHover : {
      backgroundColor: university && university.color && university.color.secondary,
      border: university && university.color && '0.5px solid ' + university.color.secondary,
      color: 'white'
    }
  }
  const planLanguage = (plan) => {
    return plan === 'Plan empresarial' ? 'Business plan' : plan === 'Plan Basico' ? 'Standard plan' : 'Free plan'
  }
  return (
    <div className={'UserInfo-global-container'}>
      <div className={'UserInfo-image-container'}>
        {user.roles === 'company' ? <img className='img-company-perfil' src={user.avatar ? user.avatar : Us} alt={'user icon'} /> : <CircularFrame user={user} cv={cv} />}
      </div>
      <Link to={user?.roles === 'company' ? `/${university.route}/company/profile` : `/${route}/profile/${user?.id}`} className={'container-viewProfile'}>
        <span style={{paddingRight:'5px'}}>{lang === 'es' ? 'Ver perfil' : 'View profile'}</span>
        {/* <img style={{ marginLeft: '5px' }} alt={'eye'} src={eye} /> */}
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M22 12C22 12 19 18 12 18C5 18 2 12 2 12C2 12 5 6 12 6C19 6 22 12 22 12Z"
          stroke-linecap="round"/>
    <circle cx="12" cy="12" r="3" stroke-linecap="round"/>
</svg>

      </Link>
      <h2 className={'UserInfo-name'}>{dataUser?.nombre_empresa ? dataUser?.nombre_empresa : user?.name}</h2>
      {user?.roles === 'company'
        ?
        <div style={{
          width: '90%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center'
        }}>
          <div className={'UserInfo-separator'}></div>
          <h3 className={'UserInfo-plan'}>{planLanguage(userPlan?.nombre_paquete)}</h3>
          <Link className={'UserInfo-button-pricing'}
            to={'/company/pricing'} style={
            isHovered ? {...styles.button, ...styles.buttonHover} : {...styles.button}
          } onMouseEnter={()=>setIsHovered(true)} onMouseLeave={()=>setIsHovered(false)}>{lang === 'es' ? 'Mejora tu plan' : 'Upgrade your plan'}</Link>
        </div>
        : null}

      {
        cv ?
          <CardConsultor
            nombre={cv?.info_personal?.nombre ? cv?.info_personal?.nombre : 'Nombre'}
            profesion={cv?.info_personal?.profesion_actual}
            idiomas={cv?.languages}
            habilidades={cv?.aptitudes_principales}
            experiencia={cv?.experiencia}
            language={lang}
          />
          : null
      }


    </div>
  )
}
