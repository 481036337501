/*eslint-disable*/
import React, { useState} from 'react';
import {useSelector} from "react-redux";
import './CvModalButton.css'
import {Modal, Box} from "@mui/material";
import arrowbot from '../../../assets/icons/d-arrow.svg'
import downarrow from '../../../assets/icons/download-arrow.svg'
import check from '../../../assets/icons/check2.svg'
import {Link, useParams} from "react-router-dom";
import axios from "axios";
import * as PropTypes from "prop-types";
import {CVdocument, CVdocumentFree} from "../../../utils/CvCreator";
import {PDFDownloadLink} from "@react-pdf/renderer";
import close from '../../../assets/icons/close.svg'
//import CvTemplate from "../../../utils/CvTemplate";

PDFDownloadLink.propTypes = {
  fileName: PropTypes.string,
  document: PropTypes.element,
  children: PropTypes.node
};
export default function CvModalButton({idUser, LinkedInCv, generalCv}) {  
  const {id} = useParams()
  const {lang, userPlan} = useSelector(state => state)
  const [open, setOpen] = useState(false);
  const [advise, setAdvise] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [open3, setOpen3] = useState(false);
  const [msg, setMsg] = useState(null);
  const [userData, setUserData] = useState(null);
  // console.log('LICV', LinkedInCv)

  const traerData = async () => {
    await axios.get(`https://findally-backend.herokuapp.com/user/CVprofile/${idUser}`).then(res => {
      setUserData(res?.data?.cv_file)
    }).catch(err => {
      console.log(err)
    })
  }

  const clickPopUp = (e) => {
    e.preventDefault()
    traerData().then(() => {
      setOpen(true)
    })
  }
  const closePopUp = (e) => {
    e.preventDefault()
    return setOpen(false)
  }
  const closePopUp2 = (e) => {
    e.preventDefault()
    return setOpen2(false)
  }
//eslint-disable-next-line
  const handleConectar = async (e) => {
    e.preventDefault()
    await axios.post("https://findally-backend.herokuapp.com/api/applicants/contact/coallier", {
      proyecto: id,
      usuarios: [idUser]
    })
  }

  const clickDownload = (e, tipo) => {
    e.preventDefault()
    /*    if (userPlan.nombre_paquete === 'Paquete Free') {
          return setAdvise(true)
        } else {*/
    if (tipo === 'linked') {
      setOpen(false)
      setMsg('LinkedIn')
      setOpen2(true)
      return setTimeout(() => {
        setOpen2(false)
        setMsg(null)
      }, 2000)
    } else {
      if (userPlan.nombre_paquete === 'Plan Basico') {
        document.getElementById('download456').click()
        setOpen(false)
        setMsg('CV')
        setOpen2(true)
        return setTimeout(() => {
          setOpen2(false)
          setMsg(null)
        }, 2000)
      } else {
        document.getElementById('download123').click()
        setOpen(false)
        setMsg('CV')
        setOpen2(true)
        return setTimeout(() => {
          setOpen2(false)
          setMsg(null)
        }, 2000)
      }
    }
  /*}*/
  }

  const linkedInCvDownload = (e) => {
    e.preventDefault()
    /*if (userPlan.nombre_paquete === 'Paquete Free') {
      return setAdvise(true)
    } else {*/
      if (LinkedInCv.length) {
        document.getElementById('download789').click()
        setOpen(false)
        setMsg('LinkedIn')
        setOpen2(true)
        return setTimeout(() => {
          setOpen2(false)
          setMsg(null)
        }, 2000)
      }
      else{
        setOpen(false)
        setOpen3(true)
        return setTimeout(() => {
          setOpen3(false)
          setMsg(null)
        }, 2000)
      }
    /*}*/
  }

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '100%',
    maxWidth: '722px',
    maxHeight: '672px',
    bgcolor: '#FFFFFF',
    borderRadius: '8px',
    borderColor: '#FFFFFF',
    boxShadow: '0px 7px 32px rgba(0, 0, 0, 0.1)',
    transition: 'all 0.3s ease',
    overflow: 'auto',
    margin: 0,
    padding: 0,
  };

  return (
      <>
        <Modal
            open={open}
            onClose={closePopUp}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <div style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'space-between',
              width: '100%',
              // padding: '45px 60px 45px 60px',
              flexDirection: 'column',
            }}>
              <div className={'cv-modal-button-cont'}>
                <button style={{backgroundColor: 'transparent'}} onClick={() => setOpen(false)}>
                  <img src={close} alt="close"/>
                </button>
                <div style={{
                  padding: '45px 60px 45px 60px',
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center"
                }}>
                  <button onClick={(e) => linkedInCvDownload(e)} className={'cv-modal-linkedin-button'}>
                    {lang === 'es' ? 'CV LinkedIn' : 'LinkedIn CV'}
                    <img src={arrowbot} alt={'arrowbot'}/>
                  </button>
                  {generalCv ? <a href={generalCv} target={"_blank"} rel={"noopener noreferrer"}>
                    <button className={'cv-modal-cvgeneral-button'}>
                    {lang === 'es' ? 'CV General' : 'General CV'}
                    <img src={downarrow} alt={'arrowbot'}/>
                  </button> </a>: <button
                    onClick={(e) => clickDownload(e, 'normal')}
                    // onClick={(e) => document.getElementById('download').click()}
                    className={'cv-modal-cvgeneral-button'}>
                    {lang === 'es' ? 'CV General' : 'General CV'}
                    <img src={downarrow} alt={'arrowbot'}/>
                  </button>}
                </div>
                <div style={{display: "none"}}>
                  {/*download amazon s3 pdf*/}
                  <a id="download789" href={LinkedInCv} target={"_blank"} download rel={"noopener noreferrer"}>
                    Download linkedin
                  </a>
                  <PDFDownloadLink
                      document={<CVdocument user={{cv_file: userData}}/>}
                      fileName={`Cv-${userData?.info_personal?.nombre}.pdf`}
                  >
                    <button
                        id="download123"
                        style={{
                          backgroundColor: "transparent",
                          height: "35px",
                          borderRadius: "5px",
                          borderStyle: "solid",
                          borderWidth: "2px",
                        }}
                    ></button>
                  </PDFDownloadLink>
                  <PDFDownloadLink
                      document={<CVdocumentFree user={{cv_file: userData}}/>}
                      fileName={`Cv-Aplicante.pdf`}
                  >
                    <button
                        id="download456"
                        style={{
                          backgroundColor: "transparent",
                          height: "35px",
                          borderRadius: "5px",
                          borderStyle: "solid",
                          borderWidth: "2px",
                        }}
                    ></button>
                  </PDFDownloadLink>
                </div>
              </div>
              {
                advise
                    ? <div style={{marginTop: '35px'}}>
                      <hr/>
                      <div className={'cv-modal-button-cont2'}>
                        <h3 className={'cv-modal-error'}>{lang === 'es' ? 'Error de descarga' : 'Download failed'}</h3>
                        {lang === 'es' ? <h3 className={'cv-modal-message'}>No puedes descargar la CV general. <Link
                                className={'cv-modal-message-link '} to={''}>Adquiere plan Standard.</Link></h3> :
                            <h3 className={'cv-modal-message'}>You cannot download the general CV. <Link
                                className={'cv-modal-message-link '} to={''}>Get Standard plan.</Link></h3>}
                      </div>
                    </div>
                    : null
              }
            </div>
          </Box>
        </Modal>
        <Modal
            open={open2}
            onClose={closePopUp2}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <div style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
              backgroundColor: '#24D054',
              color: '#FFFFFF',
              fontWeight: '400',
              fontSize: '14px',
              paddingTop: '10px',
              paddingBottom: '10px',
            }}>
              <img src={check} alt={'check'}/>
              {lang === 'es' ? <h3 style={{marginTop: '10px'}}>Descargaste con éxito el {msg}</h3> :
                  <h3 style={{marginTop: '10px'}}>{msg} Downloaded!</h3>}
            </div>
          </Box>
        </Modal>
        <Modal
            open={open3}
            onClose={closePopUp2}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <div style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
              backgroundColor: 'red',
              color: '#FFFFFF',
              fontWeight: '400',
              fontSize: '14px',
              paddingTop: '10px',
              paddingBottom: '10px',
            }}>
              {lang === 'es' ? <h3 style={{marginTop: '10px'}}>El usuario no tiene Cv de LinkedIn</h3> :
                  <h3 style={{marginTop: '10px'}}>The user does not have a LinkedIn CV</h3>}
            </div>
          </Box>
        </Modal>
        <button onClick={(e) => clickPopUp(e)} className={'cv-modal-button'}>
          {lang === 'es' ? 'Descargar CV' : 'Download CV' }
        </button>

      </>
  )
}
