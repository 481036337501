import React from 'react'
import './CardInfoDashboard.css'

interface Props {
    title: string;
    value: string;
    Icon: React.ElementType;
    color: string;
}


const CardInfoDashboard = ({ title, value, Icon, color }: Props) => { 
    return (
        <div className='card-info-dashboard'
            style={{ background: color }}>
            <div className='card-info-dashboard-Icon'>
                <Icon sx={{ fontSize: '65px' }} />
            </div>
            <div className='card-info-dashboard-container'>
                <div>
                    <h1 className='card-info-dashboard-title'>
                        {title}
                    </h1>
                </div>
                <div className='card-info-dashboard-container-2'>
                    <h1 className='card-info-dashboard-value'>
                        {value}
                    </h1>
                </div>

            </div>
        </div>
    )
}

export default CardInfoDashboard