/* eslint-disable */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./SidebarOp.css";
import CrearOportunidad from "../../../../empresa/CrearOportunidad/CrearOportunidad";
import PopupCreator from "../../../../pages/Companies/PopupCompany/PopupCreator";
import PopUpForm from "../../../../pages/Companies/PopupCompany/PopUpForm";
import { getUserPlan } from "../../../../actions";
import axios from "axios";
import { Link } from "react-router-dom";
import img from "../../../../empresa/header/footer.png";
import Lock from "../../../../assets/icons/lock-alt-Bold.svg";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ReduxStateInterface } from "../../../types/reduxInterface";
import { DefaultButton, DisabledButton } from "../../buttons/ButtonStyles";

interface ValidationResponse {
  status: number;
  message: string;
  numOfertasDisponibles: string;
}

export default function SidebarOp({ color }: { color?: string }) {
  const { lang, user, plan_premium } = useSelector(
    (state: ReduxStateInterface) => state
  );
  const [open, setOpen] = useState<boolean>(false);
  const [proyectoActivate, setProyectoActivate] = useState<boolean>(false);
  const [block, setBlock] = useState<boolean>(false);
  const dispatch = useDispatch();

  const Button = () => {
    setProyectoActivate(true);
  };

  const handleClose = () => {
    setOpen(true);
    window.location.reload();
  };

  const handleCloseCreateProject = () => {
    setProyectoActivate(false);
  };

  const handleFinishCreateProject = () => {
    setOpen(true);
    setProyectoActivate(false);
  };

  useEffect(() => {
    dispatch(getUserPlan(user.id) as any);
    axios
      .get<ValidationResponse>(
        "https://findally-backend.herokuapp.com/api/user/validatePremiumProps/" +
          user.id
      )
      .then((res) => {
        if (res.data.numOfertasDisponibles === "agotado") {
          setBlock(true);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, [user.id, block, dispatch]);

  useEffect(() => {
    if (plan_premium === "El plan ha expirado") {
      lang === "es"
        ? toast.warning(
            "Tu plan ha expirado!, por favor actualizalo para seguir disfrutando de los beneficios.",
            {
              position: "top-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            }
          )
        : toast.warning(
            "Your plan has expired!, please update it to continue enjoying the benefits.",
            {
              position: "top-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            }
          );
    }
  }, [plan_premium, lang]);
  return (
    <>
      {/* <ToastContainer/> */}
      {proyectoActivate && (
        <CrearOportunidad
          open={proyectoActivate}
          handleClose={handleCloseCreateProject}
          handleFinish={handleFinishCreateProject}
        />
      )}
      {open && (
        <PopupCreator open={open} handleClose={handleClose}>
          <PopUpForm handleClose={handleClose} />
        </PopupCreator>
      )}
      {block && (
        <Link style={{ marginBottom: "30px" }} to={"/company/pricing"}>
          <span style={{ paddingTop: "20px", color: "red", fontWeight: 700 }}>
            {lang === "es" ? "Actualiza tu plan" : "Update plan"}
          </span>
          <img style={{ marginBottom: "5px" }} src={Lock} alt="lock" />
        </Link>
      )}
      {!block && plan_premium !== "El plan ha expirado" ? (
        <Link to={`/schneider/company/create/new`}>
          <DefaultButton background={color}>
            {lang === "es" ? "Publicar oportunidad" : "Post opportunity"}
          </DefaultButton>
        </Link>
      ) : (
        <DisabledButton>
          {lang === "es" ? "Publicar oportunidad" : "Post opportunity"}
        </DisabledButton>
      )}
      {plan_premium === "El plan ha expirado" && (
        <Link style={{ marginBottom: "30px" }} to={"/company/pricing"}>
          <span style={{ paddingTop: "20px", color: "red", fontWeight: 700 }}>
            {lang === "es" ? "Tu plan ha expirado" : "Your plan has expired"}
          </span>
          <img style={{ marginBottom: "5px" }} src={Lock} alt="lock" />
        </Link>
      )}
    </>
  );
}
