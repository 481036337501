import styled from 'styled-components';

export const AddButtonOpportunity = styled.button<{color?: string}>`
  background: ${props => props.color ? props.color : '#FF8A00'};
  border-radius: 5px;
  font-weight: 400;
  font-size: 14px;
  color: #FFFFFF;
  height: 40px;
  width: 170px;
`
