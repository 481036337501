import React, {useEffect, useState} from 'react';
import "./TestCardBootcamp.css";
import Test1 from '../../../assets/imagenes/test1.svg';
import Test2 from '../../../assets/imagenes/test2.svg';
import Test3 from '../../../assets/imagenes/test3.svg';
import OK from '../../../assets/imagenes/circle-ok.svg';
import {Box, Modal} from "@mui/material";
import {useSelector} from "react-redux";
import axios from 'axios';
import {toast} from 'react-toastify';


const TestCardEmpresa = ({id, name, description, user}) => {
  const {lang} = useSelector(state => state);
  const [test, setTest] = useState();
  const [testName, setTestName] = useState(null);
  const [testResult, setTestResult] = useState(null);
  const [userBootcamp, setUserBootcamp] = useState()


  useEffect(() => {
    axios.get(`https://findally-backend.herokuapp.com/api/userBootcamp/${user.id}`)
      .then(r => {
        setUserBootcamp(r.data.data)
      })
  }, [user.id, test])

  function testForm(e, name) {
    if ((name === 'Test de CI' || name === 'IQ test') && userBootcamp?.result_test_CI?.length === 0) {
      setTest(true)
      setTestName(name)
      return
    }
    if ((name === 'Test de personalidad' || name === 'Personality test') && userBootcamp?.result_test_Personality?.length === 0) {
      setTest(true)
      setTestName(name)
      return
    }
    if ((name === 'Test de inteligencia' || name === 'Intelligence test') && userBootcamp?.result_test_Inteligencia?.length === 0) {
      setTest(true)
      setTestName(name)
      return
    }
    if ((name === 'Test vocacional' || name === 'Vocational test') && userBootcamp?.result_test_vocacional?.length === 0) {
      setTest(true)
      setTestName(name)
      return
    }
    if ((name === 'Test de orientación profesional' || name === 'Professional orientation test') && userBootcamp?.result_test_Orientation?.length === 0) {
      setTest(true)
      setTestName(name)
      return
    } else toast.error("Ya has enviado este test", {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    })
  }


  const style = {
    backgroundColor: "white",
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    height: 250,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    borderRadius: '10px',
  };

  const enviar = (e, nombreTest) => {
    e.preventDefault()
    const typeTest = nombreTest === ('Test de CI' || 'IQ test')
      ? 'CI'
      : nombreTest === ('Test de personalidad' || 'Personality test')
        ? 'Personality'
        : nombreTest === ('Test de orientación profesional' || 'Professional orientation test')
          ? 'Orientation'
          : nombreTest === ('Test vocacional' || 'Vocational test')
            ? 'Vocacional'
            : nombreTest === ('Test de inteligencia' || 'Intelligence test')
              ? 'Inteligencia'
              : null
    if (testResult === null) {
      return toast.error("Debes insertar el resultado del test", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
    }
    axios.post('https://findally-backend.herokuapp.com/api/userBootcamp/SaveTest', {
      id: user.id,
      type_test: typeTest,
      test_result: testResult,
    }).then(res => {
      console.log(res)
      toast.success("Test enviado correctamente", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
      setTest(false);
    }).catch(err => {
      toast.error("Error al enviar el test", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
    })
  }


  return (
    <div>
      {test ?
        <Modal open={test} onClose={() => setTest(false)}>
          <Box style={style}>
            {testName === 'Test de CI' || testName === 'IQ test' || testName === 'Test de inteligencia' || testName === 'Intelligence test' ?
              <>
                <h3 style={{textAlign: 'center'}}>
                  {lang === 'es' ? `Envíanos la puntuación o percentil del resultado de tu ${testName}` : `Send us the score of your ${testName} result`}
                </h3>

                <input onChange={e => setTestResult(e.target.value)} type="text" name={testName}
                       placeholder={lang === 'es' ? 'Puntuación del resultado...' : 'Result score...'}
                       style={{width: '90%', border: 'solid 1px #D3D3D3'}}/>
              </>
              :
              <>
                <h3
                  style={{textAlign: 'center'}}>{lang === 'es' ? `Envíanos la url del resultado de tu ${testName}` : `Send us the url of your ${testName} result`}</h3>

                <input onChange={e => setTestResult(e.target.value)} type="text" name={testName}
                       placeholder={lang === 'es' ? 'Url del resultado...' : 'Result url...'}
                       style={{width: '90%', border: 'solid 1px #D3D3D3'}}/>
              </>

            }
            <button onClick={(e) => enviar(e, testName)} style={{
              marginTop: '15px',
              width: '90%',
              backgroundColor: '#F07130',
              color: 'white',
              borderRadius: '5px'
            }}>{lang === 'es' ? 'Enviar' : 'Send'}</button>
          </Box>
        </Modal>
        : null}
      {/*eslint-disable-next-line */}
      <a href={(name === 'Test de CI' || name === 'IQ test') && userBootcamp?.result_test_CI?.length === 0
        ? 'https://www.123test.com/es/test-de-ci/'
        : (name === 'Test de personalidad' || name === 'Personality test') && userBootcamp?.result_test_Personality?.length === 0
          ? 'https://www.123test.com/es/test-de-personalidad/'
          : (name === 'Test de orientación profesional' || name === 'Professional orientation test') && userBootcamp?.result_test_Orientation?.length === 0
            ? 'https://www.123test.com/es/test-de-orientacion-profesional/'
            : (name === 'Test de inteligencia' || name === 'Intelligence test') ? 'http://www.avlisad.com.ar/test/'
              : (name === 'Test vocacional' || name === 'Vocational test') ? 'https://www.testgratis.net/psicologicos/test-vocacional-chaside'
                : null}
         target={'_blank'}
         rel={"noopener noreferrer"}
      >
        <div className={'Bootcamp-Test-Card-container'} onClick={e => testForm(e, name)}>
          <div>
            <div style={{
              marginTop: '-10px',
              marginLeft: '-8px'
            }}>
              {id === 1 && userBootcamp?.result_test_CI?.length > 0 ? <img src={OK} alt={OK}/> : null}
              {id === 2 && userBootcamp?.result_test_Personality?.length > 0 ? <img src={OK} alt={OK}/> : null}
              {id === 3 && userBootcamp?.result_test_Orientation?.length > 0 ? <img src={OK} alt={OK}/> : null}
              {id === 4 && userBootcamp?.result_test_Orientation?.length > 0 ? <img src={OK} alt={OK}/> : null}
              {id === 5 && userBootcamp?.result_test_Orientation?.length > 0 ? <img src={OK} alt={OK}/> : null}
            </div>
            <div className={'Profesional-Test-Card-image-container'}>
              {id === 1 ? <img src={Test3} alt={Test3}/> : null}
              {id === 2 ? <img src={Test1} alt={Test1}/> : null}
              {id === 3 ? <img src={Test2} alt={Test2}/> : null}
              {id === 4 ? <img src={Test1} alt={Test1}/> : null}
              {id === 5 ? <img src={Test2} alt={Test2}/> : null}
            </div>
          </div>
          <div className={'Bootcamp-Test-Card-sub-container'}>
            <h3 className={'Bootcamp-Test-Card-Title'}>{name}</h3>
            <p className={'Bootcamp-Test-Card-P'}>{description}</p>
          </div>
        </div>
      </a>
    </div>
  );
}

export default TestCardEmpresa;