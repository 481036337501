/* eslint-disable */
import React, { useState, useContext } from "react";
import { Link, useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { changeLang } from "../../../../actions";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { SchneiderContext } from "../../../context/SchneiderContext";
import Edit from "../../../../assets/imagenes/edit.svg";
import Spinner from "react-bootstrap/Spinner";
import axios from "axios";

import {
  AuthGlobalContainer,
  AuthFormContainer,
  AuthButton,
  AuthButtonContainer,
  AuthButtonDisabled,
  AuthFooterContainer,
  AuthLink,
  AuthFooterText,
  AuthLogo,
  LanguageContainer,
  LanguageButton,
  AuthTitle,
  AuthInputContainer,
  AuthInput,
  AuthInputSubContainer,
  AuthInputIconContainer,
} from "../AuthStyles";

export default function RecoverPassword() {
  const { schneider } = useContext(SchneiderContext);
  const [user, setUser] = useState({
    email: "",
  });

  const dispatch = useDispatch();
  const history = useHistory();
  const [localLang, setLocalLang] = useState(localStorage.getItem("langlocal"));
  const [cargando, setCargando] = useState(false);

  const changeLanguages = (lang: string) => {
    dispatch(changeLang(lang));
    localStorage.setItem("langlocal", lang);
    setLocalLang(localStorage.getItem("langlocal"));
  };

  const handleSubmit = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.preventDefault();
    try {
      setCargando(true);
      let response: any = await axios.post(
        `https://findally-backend.herokuapp.com/api/auth/verifyemail?lang=${localLang}`,
        {
          email: user.email,
          Schneider: true,
        }
      );
      if (response.data.status !== 200) {
        history.push(`/schneider/change/password?email=${user.email}`);
      } else {
        localLang === "es"
          ? toast.error("Error, Email no encontrado", {
              position: "top-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            })
          : toast.error("Error, Email not found.", {
              position: "top-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
        setCargando(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const changeUser = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    setUser({
      ...user,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <>
      {schneider && schneider?.color && (
        <AuthGlobalContainer>
          <AuthFormContainer>
            <AuthLogo src={schneider.logo} alt="logo" />
            <LanguageContainer>
              <LanguageButton
                onClick={() => changeLanguages("es")}
                color={localLang === "es" ? schneider.color.primary : "#000000"}
                weight={localLang === "es" ? 700 : 300}
              >
                SP
              </LanguageButton>
              <LanguageButton
                onClick={() => changeLanguages("en")}
                color={localLang === "en" ? schneider.color.primary : "#000000"}
                weight={localLang === "en" ? 700 : 300}
              >
                EN
              </LanguageButton>
            </LanguageContainer>

            <div style={{ width: "100%" }}>
              <AuthTitle color={schneider.color.primary}>
                {localLang === "es"
                  ? "¡Recuperar contraseña!"
                  : "Recover password!"}
              </AuthTitle>
            </div>

            <AuthInputContainer>
              <p>{localLang === "es" ? "Correo electrónico" : "Email"}</p>
              <AuthInputSubContainer>
                <AuthInput
                  type="text"
                  name={"email"}
                  autoComplete={"username"}
                  placeholder={
                    localLang === "es" ? "nombre@correo.com" : "name@email.com"
                  }
                  onChange={(e) => changeUser(e)}
                />
                <AuthInputIconContainer>
                  <img src={Edit} alt="editar" />
                </AuthInputIconContainer>
              </AuthInputSubContainer>
            </AuthInputContainer>

            <AuthButtonContainer>
              {user.email ? (
                <AuthButton
                  background={schneider.color.primary}
                  onClick={(e) => handleSubmit(e)}
                >
                  {cargando ? (
                    <Spinner animation="border" size="sm" />
                  ) : localLang === "es" ? (
                    "Recuperar contraseña"
                  ) : (
                    "Recover password"
                  )}
                </AuthButton>
              ) : (
                <AuthButtonDisabled>
                  {localLang === "es"
                    ? "Recuperar contraseña"
                    : "Recover password"}
                </AuthButtonDisabled>
              )}
            </AuthButtonContainer>

            <AuthFooterContainer>
              <AuthFooterText>
                {localLang === "es"
                  ? "Si ya tienes cuenta puedes"
                  : "If you already have an account, "}
              </AuthFooterText>
              <Link to={`/schneider/login`}>
                <AuthLink color={schneider.color.primary} weight={600}>
                  {localLang === "es" ? "Iniciar sesión" : "Login"}
                </AuthLink>
              </Link>
            </AuthFooterContainer>
          </AuthFormContainer>
        </AuthGlobalContainer>
      )}
    </>
  );
}
