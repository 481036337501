/* eslint-disable */
import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.css";
import {
  CreateProjectWhithoutPayment,
  CreateProject,
  CreateUnregisterProject,
} from "../../actions";
import { connect } from "react-redux";
import "../../pages/styles/ContactUs.css";
import en from "../../lang/en-US";
import es from "../../lang/es-COL";
import counterpart from "counterpart";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Translate from "react-translate-component";
import axios from "axios";
import ScriptTag from "react-script-tag";
import { useParams, useLocation, useHistory } from "react-router-dom";
import MicIcon from "@mui/icons-material/Mic";
import StopIcon from "@mui/icons-material/Stop";
import DeleteIcon from "@mui/icons-material/Delete";
import GraphicEqIcon from "@mui/icons-material/GraphicEq";
import QRCode from "react-qr-code";
import StepLabel from "@mui/material/StepLabel";
import Step from "@mui/material/Step";
import Stepper from "@mui/material/Stepper";
import { StepConnector } from "@mui/material";
import './companynewCreateProject2.css';
import ChipInput from "material-ui-chip-input";
import { useSelector } from "react-redux";

const MicRecorder = require("mic-recorder-to-mp3");
const mapId = [50, 51, 52, 53, 54];

const abilities = {
  "Agronomía, Veterinaria y afines": [
    "",
    "Desarrollar labores de campo",
    "Relacionarse con el sector agrícola",
    "Sistemas de producción agrícola y pecuaria",
    "Diseñar y dirigir procesos",
    "Conocimiento en plaguicidas de uso ambiental",
    "Manejo  de recursos naturales y agropecuarios",
    "Evaluación de suelos",
    "Gestión de proyectos",
    "Preparación del suelo",
    "Planificación de cultivos",
    "Conocimiento ecológico y biológico",
    "Manejo de instrumental científico y maquinaria",
    "Control del funcionamiento",
    "Pasión y aprecio hacia todos los animales",
    "Resolver problemas relacionados con la salud y la producción animal",
    "Utilización y manejo de equipo veterinario",
    "Capacidad de comunicación",
    "Gestión del tiempo",
    "Gestión financiera",
    "Pensamiento crítico",
    "Atención al detalle",
    "Planificación",
    "flexibilidad y adaptabilidad",
    "Toma de decisiones",
    "Liderazgo y trabajo en equipo",
    "Resolución de problemas",
    "Comunicación asertiva",
    "Manejo de la frustración",
    "Responsabilidad",
    "Trabajo en equipo",
  ],
  "Agronomía, Veterinaria y afines EN": [
    "",
    "Developing field work",
    "Relationship with the agricultural sector",
    "Agricultural and veterinary systems",
    "Design and direct production processes",
    "Knowledge in ambiental pesticides",
    "Natural and agropecuarial resources management",
    "Soil evaluation",
    "Project management",
    "Soil preparation",
    "Cultivation planning",
    "Ecological and biologic knowledge",
    "Scientific and machine tools management",
    "Control of operation",
    "Passion and appreciation for all animals",
    "Solving problems related to health and animal production",
    "Use and management of veterinary equipment",
    "Communication ability",
    "Time management",
    "Financial management",
    "Critical thinking",
    "Detail attention",
    "Planning",
    "Flexibility and adaptability",
    "Decision making",
    "Leadership and team work",
    "Resolution of problems",
    "Assertive communication",
    "Handling of frustration",
    "Responsibility",
    "Team work",
  ],
  "Bellas Artes": [
    "",
    "Creatividad",
    "Imaginación",
    "Perfeccionismo",
    "Pragmatismo",
    "Concentración",
    "Composición",
    "Conocimientos informáticos",
    "Comprensión de la perspectiva",
    "Dibujo realista",
    "Dibujo constructivo",
    "Capacidad de comunicación",
    "Gestión del tiempo",
    "Gestión financiera",
    "Pensamiento crítico",
    "Atención al detalle",
    "Planificación",
    "Flexibilidad y adaptabilidad",
    "Toma de decisiones",
    "Liderazgo y trabajo en equipo",
    "Resolución de problemas",
    "Comunicación asertiva",
    "Manejo de la frustración",
    "Responsabilidad",
    "Trabajo en equipo",
  ],
  "Bellas Artes EN": [
    "",
    "Creativity",
    "Imagination",
    "Perfectionism",
    "Pragmatism",
    "Concentration",
    "Composition",
    "Computer knowledge",
    "Comprehension of the perspective",
    "Realist drawing",
    "Constructive drawing",
    "Communication ability",
    "Time management",
    "Financial management",
    "Critical thinking",
    "Detail attention",
    "Planning",
    "Flexibility and adaptability",
    "Decision making",
    "Leadership and team work",
    "Resolution of problems",
    "Assertive communication",
    "Handling of frustration",
    "Responsibility",
    "Team work",
  ],
  "Ciencias de la educación": [
    "",
    "Escucha activa",
    "Desarrollo de proyectos ",
    "Uso de material tecnológico ",
    "Manejo de herramientas digitales",
    "Enseñanza didáctica",
    "Comunicación asertiva",
    "Capacidad de comunicación",
    "Gestión del tiempo",
    "Gestión financiera",
    "Pensamiento crítico",
    "Atención al detalle",
    "Planificación",
    "Flexibilidad y adaptabilidad",
    "Toma de decisiones",
    "Liderazgo y trabajo en equipo",
    "Resolución de problemas",
    "Comunicación asertiva",
    "Manejo de la frustración",
    "Responsabilidad",
    "Trabajo en equipo",
  ],
  "Ciencias de la educación EN": [
    "",
    "Active listening",
    "Project development",
    "Use of technological materials",
    "Digital tools management",
    "Educational teaching",
    "Assertive communication",
    "Communication ability",
    "Time management",
    "Financial management",
    "Critical thinking",
    "Detail attention",
    "Planning",
    "Flexibility and adaptability",
    "Decision making",
    "Leadership and team work",
    "Resolution of problems",
    "Assertive communication",
    "Handling of frustration",
    "Responsibility",
    "Team work",
  ],
  "Ciencias de la Salud": [
    "",
    "Capacidad para trabajar en equipo",
    "Conocimiento de terminología y procedimientos médicos",
    "Apoyo al paciente",
    "Vocación de servicio",
    "Inquietud por aprender",
    "Capacidad de comunicación",
    "Gestión del tiempo",
    "Gestión financiera",
    "Pensamiento crítico",
    "Atención al detalle",
    "Planificación",
    "Flexibilidad y adaptabilidad",
    "Toma de decisiones",
    "Liderazgo y trabajo en equipo",
    "Resolución de problemas",
    "Comunicación asertiva",
    "Manejo de la frustración",
    "Responsabilidad",
    "Trabajo en equipo",
  ],
  "Ciencias de la Salud EN": [
    "",
    "Teamwork",
    "Knowledge of terminology and medical procedures",
    "Support to the patient",
    "Vocation of service",
    "Inquietud for learning",
    "Communication ability",
    "Time management",
    "Financial management",
    "Critical thinking",
    "Detail attention",
    "Planning",
    "Flexibility and adaptability",
    "Decision making",
    "Leadership and team work",
    "Resolution of problems",
    "Assertive communication",
    "Handling of frustration",
    "Responsibility",
  ],
  "Ciencias Sociales y humanas": [
    "",
    "Orientación a resultados ",
    "Habilidad optima comunicación oral y escrita",
    "Razonamiento de datos",
    "Atención al detalle",
    "Capacidad de comunicación",
    "Gestión del tiempo",
    "Gestión financiera",
    "Pensamiento crítico",
    "Atención al detalle",
    "Planificación",
    "Flexibilidad y adaptabilidad",
    "Toma de decisiones",
    "Liderazgo y trabajo en equipo",
    "Resolución de problemas",
    "Comunicación asertiva",
    "Manejo de la frustración",
    "Responsabilidad",
    "Trabajo en equipo",
  ],
  "Ciencias Sociales y humanas EN": [
    "",
    "Orientation to results",
    "Optimal communication oral and written",
    "Reasoning of data",
    "Detail attention",
    "Communication ability",
    "Time management",
    "Financial management",
    "Critical thinking",
    "Detail attention",
    "Planning",
    "Flexibility and adaptability",
    "Decision making",
    "Leadership and team work",
    "Resolution of problems",
    "Assertive communication",
    "Handling of frustration",
    "Responsibility",
    "Team work",
  ],
  "Economia, Administracion, Contaduria y afines": [
    "",
    "Pago y elaboración de impuestos",
    "Gestión de ingresos",
    "Análisis y creación de balance",
    "Organización",
    "Administración de cuentas",
    "Análisis financiero",
    "Gestión del pago",
    "Declaración",
    "Habilidades escritas",
    "Negociación",
    "Solución de problemas complejos",
    "Capacidad de comunicación",
    "Gestión del tiempo",
    "Gestión financiera",
    "Pensamiento crítico",
    "Atención al detalle",
    "Planificación",
    "Flexibilidad y adaptabilidad",
    "Toma de decisiones",
    "Liderazgo y trabajo en equipo",
    "Resolución de problemas",
    "Comunicación asertiva",
    "Manejo de la frustración",
    "Responsabilidad",
    "Trabajo en equipo",
  ],
  "Economia, Administracion, Contaduria y afines EN": [
    "",
    "Tax payment and elaboration of taxes",
    "Income management",
    "Analysis and creation of balance",
    "Organization",
    "Accounting",
    "Financial analysis",
    "Payment management",
    "Declaration",
    "Written skills",
    "Negotiation",
    "Complex problem solving",
    "Communication ability",
    "Time management",
    "Financial management",
    "Critical thinking",
    "Detail attention",
    "Planning",
    "Flexibility and adaptability",
    "Decision making",
    "Leadership and team work",
    "Resolution of problems",
    "Assertive communication",
    "Handling of frustration",
    "Responsibility",
    "Team work",
  ],
  "Ingeniería, Arquitectura, urbanismo y afines": [
    "",
    "Pensamiento lógico",
    "Visión espacial",
    "Habilidad estética",
    "Amplio criterio",
    "Autoaprendizaje",
    "Gestión de riesgos, cambios y crisis",
    "Atención al detalle",
    "Pensamiento estratégico",
    "Planificación urbana estratégica",
    "Planificación maestra",
    "Capacidad de comunicación",
    "Gestión del tiempo",
    "Gestión financiera",
    "Pensamiento crítico",
    "Atención al detalle",
    "Planificación",
    "Flexibilidad y adaptabilidad",
    "Toma de decisiones",
    "Liderazgo y trabajo en equipo",
    "Resolución de problemas",
    "Comunicación asertiva",
    "Manejo de la frustración",
    "Responsabilidad",
    "Trabajo en equipo",
  ],
  "Ingeniería, Arquitectura, urbanismo y afines EN": [
    "",
    "Logic thinking",
    "Spatial vision",
    "Asthetics",
    "Wide criteria",
    "Self-learning",
    "Risk management, changes and crises",
    "Detail attention",
    "Strategic thinking",
    "Urban strategic planning",
    "Master planning",
    "Communication ability",
    "Time management",
    "Financial management",
    "Critical thinking",
    "Detail attention",
    "Planning",
    "Flexibility and adaptability",
    "Decision making",
    "Leadership and team work",
    "Resolution of problems",
    "Assertive communication",
    "Handling of frustration",
    "Responsibility",
    "Team work",
  ],
  "Matemáticas y ciencias naturales": [
    "",
    "Resolver problemas",
    "Argumentar y comunicar",
    "Modelar",
    "Habilidades digitales",
    "Medición",
    "Datos y probabilidades",
    "Trabajo ordenado y metódico",
    "Analizar",
    "Clasificar",
    "Usar instrumentos",
    "Investigar",
    "Observar",
    "Capacidad de comunicación",
    "Gestión del tiempo",
    "Gestión financiera",
    "Pensamiento crítico",
    "Atención al detalle",
    "Planificación",
    "Flexibilidad y adaptabilidad",
    "Toma de decisiones",
    "Liderazgo y trabajo en equipo",
    "Resolución de problemas",
    "Comunicación asertiva",
    "Manejo de la frustración",
    "Responsabilidad",
    "Trabajo en equipo",
  ],
  "Matemáticas y ciencias naturales EN": [
    "",
    "Solving problems",
    "Argumentation and communication",
    "Modeling",
    "Digital skills",
    "Measurement",
    "Data and probabilities",
    "Ordered work and methodological",
    "Analyze",
    "Classify",
    "Use instruments",
    "Investigate",
    "Observe",
    "Communication ability",
    "Time management",
    "Financial management",
    "Critical thinking",
    "Detail attention",
    "Planning",
    "Flexibility and adaptability",
    "Decision making",
    "Leadership and team work",
    "Resolution of problems",
    "Assertive communication",
    "Handling of frustration",
    "Responsibility",
    "Team work",
  ],
  Otro: [
    "",
    "Capacidad de comunicación",
    "Gestión del tiempo",
    "Gestión financiera",
    "Pensamiento crítico",
    "Atención al detalle",
    "Planificación",
    "Flexibilidad y adaptabilidad",
    "Toma de decisiones",
    "Liderazgo y trabajo en equipo",
    "Resolución de problemas",
    "Comunicación asertiva",
    "Manejo de la frustración",
    "Responsabilidad",
    "Trabajo en equipo",
  ],
  "Otro EN": [
    "",
    "Communication ability",
    "Time management",
    "Financial management",
    "Critical thinking",
    "Detail attention",
    "Planning",
    "Flexibility and adaptability",
    "Decision making",
    "Leadership and team work",
    "Resolution of problems",
    "Assertive communication",
    "Handling of frustration",
    "Responsibility",
    "Team work",
  ]
};

function FormCompanyNewCreateProject(props) {
  const { idp } = useParams();
  const location = useLocation();
  const history = useHistory();
  const user = useSelector(state => state.user)
  console.log(user.paquete_premium)
  console.log(location.pathname);
  const recorder = new MicRecorder({
    bitRate: 128,
  });
  const [form3, setform3] = useState({
    tipoDeServicioDeseado: "",
    modalidadDeContratacion: "",
    Anonimo: "",
    NombreEmpresa: props.user.name,
    Email: "",
    Oportunidad: "",
    Datalle: "",
    NombreOportunidad: "",
    DescribeProyecto: "",
    DescribeProyectoAudio: "",
    SeleccionaCarrera: "",
    Niveldeconocimiento: "",
    Lenguaje: "",
    habilidadesRequeridas: [],
    estimadoDuración: "",
    idrut: "",
    profesionales: "",
    modalidadPago: "",
    presupuesto: "",
    codigoPromocional: "",
    modalidaddepago: "",
    link: "",
    empleos_alternativos: [],
  });
  const [otroInput, setOtroInput] = useState(false);
  const [usuario, setUsuario] = useState({})

  useEffect(() => {
    axios('https://findally-backend.herokuapp.com/user/' + user.id)
      .then(res => {
        setUsuario(res.data?.paquete_premium)
      }).catch(error => {
        console.log(error)
      })
  }, [])

  useEffect(() => {
    if (idp) {
      let filteredProject =
        props.projectUser.length > 0
          ? props.projectUser.filter((project) => project._id === idp)
          : [];
      if (filteredProject.length > 0) {
        setform3({
          ...form3,
          tipoDeServicioDeseado: filteredProject[0].tipoDeServicioDeseado
            ? filteredProject[0].tipoDeServicioDeseado
            : "",
          modalidadDeContratacion: filteredProject[0].modalidadDeContratacion
            ? filteredProject[0].modalidadDeContratacion
            : "",
          Anonimo: filteredProject[0].Anonimo ? filteredProject[0].Anonimo : "",
          NombreEmpresa: filteredProject[0].NombreEmpresa
            ? filteredProject[0].NombreEmpresa
            : "",
          Email: filteredProject[0].Email ? filteredProject[0].Email : "",
          Oportunidad: filteredProject[0].Oportunidad
            ? filteredProject[0].Oportunidad
            : "",
          Datalle: filteredProject[0].Datalle ? filteredProject[0].Datalle : "",
          NombreOportunidad: filteredProject[0].NombreOportunidad
            ? filteredProject[0].nombre
            : console.log("no hay nombre"),
          DescribeProyecto: filteredProject[0].DescribeProyecto
            ? filteredProject[0].DescribeProyecto
            : "",
          DescribeProyectoAudio: filteredProject[0].DescribeProyectoAudio
            ? filteredProject[0].DescribeProyectoAudio
            : "",
          SeleccionaCarrera: filteredProject[0].SeleccionaCarrera
            ? filteredProject[0].SeleccionaCarrera
            : "",
          Niveldeconocimiento: filteredProject[0].Niveldeconocimiento
            ? filteredProject[0].Niveldeconocimiento
            : "",
          Lenguaje: filteredProject[0].Lenguaje
            ? filteredProject[0].Lenguaje
            : "",
          habilidadesRequeridas: filteredProject[0].habilidadesRequeridas
            ? filteredProject[0].habilidadesRequeridas
            : "",
          estimadoDuración: filteredProject[0].estimadoDuración
            ? filteredProject[0].estimadoDuración
            : "",
          idrut: filteredProject[0].idrut ? filteredProject[0].idrut : "",
          profesionales: filteredProject[0].profesionales
            ? filteredProject[0].profesionales
            : "",
          modalidadPago: filteredProject[0].modalidadPago
            ? filteredProject[0].modalidadPago
            : "",
          presupuesto: filteredProject[0].presupuesto
            ? filteredProject[0].presupuesto
            : "",
          codigoPromocional: filteredProject[0].codigoPromocional
            ? filteredProject[0].codigoPromocional
            : "",
          modalidaddepago: filteredProject[0].modalidaddepago
            ? filteredProject[0].modalidaddepago
            : "",
          link: filteredProject[0].link ? filteredProject[0].link : "",
        });
      }
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idp]);

  function startRecording(id) {
    recorder
      .start()
      .then(() => {
        document.getElementById(`${id}a`).style.display = "none";
        document.getElementById(`${id}b`).style.display = "flex";
      })
      .catch((e) => {
        console.error(e);
      });
  }

  const toBase64 = (file) => {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        resolve(reader.result);
      };
    });
  };

  const stopRecording = async (id) => {
    recorder
      .stop()
      .getMp3()
      .then(([buffer, blob]) => {
        console.log(buffer, blob);
        const file = new File(buffer, "music.mp3", {
          type: blob.type,
          lastModified: Date.now(),
        });

        const li = document.createElement("li");
        const player = new Audio(URL.createObjectURL(file));
        player.controls = true;
        li.appendChild(player);
        const mandarf = async (file) => {
          let h = await toBase64(file);
          console.log(h);
          document.getElementById(`mirar${id}`).src = h + "";
        };
        mandarf(file);
        console.log(id);
        document.getElementById(`${id}b`).style.display = "none";
        document.getElementById(`${id}a`).style.display = "flex";
      })
      .catch((e) => {
        console.error(e);
      });
  };

  const [cargando, setCargando] = useState(false);
  const [statusProyecto, setstatusProyecto] = useState(false);
  const [otroCausa, setotroCausa] = useState("");
  const [select, setSelect] = useState('')
  let type = null;
  let options = null;
  const steps0 = ["Que tipo de servicio deseas"];
  const steps0En = ["What type of service do you want"];
  const steps1 = [
    "Que tipo de servicio deseas",
    "Modalidad de contratación",
    "Cliente",
    "Oportunidad",
    "Detalle",
    "Financiero",
  ];
  const steps1En = [
    "What type of service do you want",
    "Modality of contract",
    "Client",
    "Opportunity",
    "Detail",
    "Financial",
  ]
  const steps2 = [
    "Que tipo de servicio deseas",
    "Modalidad de contratación",
    "Cliente",
    "Oportunidad",
    "Detalle",
    "Financiero",
  ];
  const steps2En = [
    "What type of service do you want",
    "Modality of contract",
    "Client",
    "Opportunity",
    "Detail",
    "Financial",
  ]
  const steps3 = [
    "Que tipo de servicio deseas",
    "Cliente",
    "Oportunidad",
    "Detalle",
    "Financiero",
  ];
  const steps3En = [
    "What type of service do you want",
    "Client",
    "Opportunity",
    "Detail",
    "Financial",
  ]
  const [form] = useState({
    confidencial: [],
    presupuesto: "",
    empresa: "",
    nombre: "",
    email: "",
    comentario: "",
    idioma: "",
    Nivel: "",
    ciudad: "",
    metricas: "",
    lenguajes_prog: "",
    problema_empresa: "",
    causa_empresa: "",
    modalidad: "",
    linea_negocio: "",
    codigo_promo: "",
    Paso1text: "vacio",
    Paso2text: "vacio",
    Paso3text: "vacio",
    Paso4text: "vacio",
    Paso5text: "vacio",
    Paso6text: "vacio",
    Paso7text: "vacio",
    Paso8text: "vacio",
    Paso9text: "vacio",
    Paso10text: "vacio",
    habilidades_requeridas: [],
    campo: {},
    error: {},
    enviado: false,
  });
  const [moneda, setMoneda] = useState('')

  const handleChangeMoneda = (e) => {
    setMoneda(e.target.value)

  }
 

  const [presupuesto, setPresupuesto] = useState({
    pres_min: '',
    pres_max: '',
  })
  const [error, setError] = useState({
    pres_min: '',
    pres_max: '',
    isOkay: false
  })

  const handlePres = (e) => {
    setPresupuesto({
      ...presupuesto,
      [e.target.name]: e.target.value
    })
    setError(validation({
      ...presupuesto,
      [e.target.name]: e.target.value
    }))
  }


  const validation = (presupuesto) => {
    const error = {}
    error.isOkay = true

    if (presupuesto.pres_min !== '' && presupuesto.pres_max !== '' && (presupuesto.pres_max < presupuesto.pres_min)) {
      error.max = lang === 'es' ? 'El mínimo debe ser menor que el máximo' : 'Minimum must be less than maximum'
      error.isOkay = false
    }
    if (!presupuesto.pres_min && !presupuesto.pres_max) {
      error.isOkay = false
    }
    return error
  }

  const formatterPeso = new Intl.NumberFormat('es-CO', {   
    minimumFractionDigits: 0
  })

  useEffect(() => {
    setform3({
      ...form3,
      presupuesto: `${moneda}${formatterPeso.format(presupuesto.pres_min)} - ${moneda}${formatterPeso.format(presupuesto.pres_max)}`
    })
  }, [presupuesto, moneda])
  const cambioform3 = (e) => {
    setform3({
      ...form3,
      [e.target.name]: e.target.value,
    });
  };
  const setPlan = (e) => {
    setform3({
      ...form3,
      modalidaddepago: e
    })
    setSelect(e)
  }

  const setEmail = (value) => {
    console.log(value);
    setform3({
      ...form3,
      Email: value,
    });
  };
  const emailRegex = (e) => {
    const re =
      /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (re.test(e.target.value)) setEmail(e.target.value);
  };
  const cambioFormCarrera = (e) => {
    setform3({
      ...form3,
      SeleccionaCarrera: e.target.value,
    });
    console.log(form3)
    if (e.target.value === "Otro") {
      setOtroInput(true)
    } else {
      setOtroInput(false)
    }
  };
  const [
    ypagaurl,
    // , setypagaurl
  ] = useState("");
  const [
    ypagatoken,
    // , setypagatoken
  ] = useState("");
  const comprobarEstadoTpaga = async () => {
    await axios
      .get(
        "https://stag.wallet.tpaga.co/merchants/api/v1/payment_requests/" +
        ypagatoken +
        "/info"
      )
      .then((response) => {
        if (response.data.status === "delivered") {
          handleSubmitCreate();
        }
      });
  };

  const handleChange = (chip) => {
    setform3({
      ...form3,
      empleos_alternativos: chip,
    });
    console.log(form3.empleos_alternativos);
  }
  const handleDeleteChip = (chipToDelete) => {
    setform3({
      ...form3,
      empleos_alternativos: form3.empleos_alternativos.filter((chip) => chip.key !== chipToDelete.key),
    });
    console.log(form3.empleos_alternativos)
    console.log('seg', form3)
  }

  const handleSkillsChange = (chip) => {
    let resultado = new Set(form3.habilidadesRequeridas.concat(chip));
    if (resultado.size >= 5) {
      return alert('Solo puedes seleccionar 5 habilidades')
    } else {
      setform3({
        ...form3,
        habilidadesRequeridas: [
          ...resultado
        ],
      })
    }
    console.log(form3.habilidadesRequeridas)
  }

  const handleSkillsDeleteChip = (chipToDelete) => {
    setform3({
      ...form3,
      habilidadesRequeridas: form3.habilidadesRequeridas.filter((chip) => chip.key !== chipToDelete.key),
    });
    console.log(form3.habilidadesRequeridas)
  }

  useEffect(() => {
    setform3({ ...form3, Email: props?.user?.email });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.user]);


  const handleSubmitCreatewenjoy = async (event) => {
    if (
      window.location.href.split("/").includes("localhost:3001") ||
      props.user.email === "daniel.gomez.12345@hotmail.com" ||
      props.user.email === "winnyfferortega@coally.com"
    ) {
      document
        .getElementById("wenjoy-checkout-button")
        .setAttribute("data-api-key", "CJfQSTomskzGtG58WshzzN60lLbBnZN6");
      document
        .getElementById("wenjoy-checkout-button")
        .setAttribute("data-test", "true");
    }
    event.preventDefault();
    if (props.user.email) {
      if (
        props.user.email === "daniel.gomez.12345@hotmail.com" ||
        props.user.email === "winnyfferortega@coally.com"
      ) {
        document
          .getElementById("wenjoy-checkout-button")
          .setAttribute("data-owner-email", props.user.email);
      } else {
        document
          .getElementById("wenjoy-checkout-button")
          .setAttribute("data-owner-email", props.user.email);
      }
    }
    if (props.user.email) {
      if (
        props.user.email !== "daniel.gomez.12345@hotmail.com" &&
        props.user.email !== "winnyfferortega@coally.com"
      ) {
        setform3({ ...form3, Email: props.user.email });
      }
    }
    if (!props.user.email) {
      document
        .getElementById("wenjoy-checkout-button")
        .setAttribute("data-owner-email", form3.Email);
    }
    console.log(form3);
    let audio = "";

    if (document.getElementById(`mirar1`)) {
      audio = await document.getElementById(`mirar1`).src;
    }
    setform3({
      ...form3,
      modalidaddepago: "Pagar con wenjoy",
    });

    if (props.user.email && usercreado) {
      axios
        .post(
          "https://findally-backend.herokuapp.com/api/Project/user/projecttemp",
          {
            ...form3,
            DescribeProyectoAudio: audio,
          }
        )
        .then((response) => {
          //  console.log('si')
          // window.location.href = "/company/profile";
          document
            .getElementById("wenjoy-checkout-button")
            .setAttribute(
              "data-confirmation-url",
              "https://findally-backend.herokuapp.com/api/Project/create/wenjoy/" +
              response.data
            );
          document
            .getElementById("wenjoy-checkout-button")
            .setAttribute(
              "data-total-value",
              Math.round(form3.presupuesto / 10)
            );
          setstatusProyecto("wenjoy");
        })
        .catch((err) => setCargando(false));
    }
    if (
      (props.user.email === "daniel.gomez.12345@hotmail.com" ||
        props.user.email === "winnyfferortega@coally.com") &&
      !usercreado
    ) {
      if (
        !form3.Email.includes(" ") &&
        (form3.Email.includes("@") || props.user.email.includes("@"))
      ) {
        setCargando(true);
        //  props.CreateUnregisterProject(form2);

        axios
          .post(
            "https://findally-backend.herokuapp.com/api/Project/user/projecttemp",
            {
              ...form3,
              DescribeProyectoAudio: audio,
            }
          )
          .then((response) => {
            //  console.log('si')
            // window.location.href = "/company/profile";
            document
              .getElementById("wenjoy-checkout-button")
              .setAttribute(
                "data-confirmation-url",
                "https://findally-backend.herokuapp.com/api/Project/create/user/wenjoy/" +
                response.data
              );
            document
              .getElementById("wenjoy-checkout-button")
              .setAttribute(
                "data-total-value",
                Math.round(form3.presupuesto / 10)
              );
            setstatusProyecto("wenjoy");
          })
          .catch((err) => setCargando(false));
      }
    }
    if (form3.Anonimo === "No" && !props.user.email) {
      if (
        !form3.Email.includes(" ") &&
        (form3.Email.includes("@") || props.user.email.includes("@")) &&
        form3.NombreEmpresa !== "vacio"
      ) {
        setCargando(true);
        //  props.CreateUnregisterProject(form2);
        axios
          .post(
            "https://findally-backend.herokuapp.com/api/Project/user/projecttemp",
            {
              ...form3,
              DescribeProyectoAudio: audio,
            }
          )
          .then((response) => {
            //  console.log('si')
            // window.location.href = "/company/profile";
            document
              .getElementById("wenjoy-checkout-button")
              .setAttribute(
                "data-confirmation-url",
                "https://findally-backend.herokuapp.com/api/Project/create/user/wenjoy/" +
                response.data
              );
            document
              .getElementById("wenjoy-checkout-button")
              .setAttribute(
                "data-total-value",
                Math.round(form3.presupuesto / 10)
              );
            setstatusProyecto("wenjoy");
          })
          .catch((err) => setCargando(false));
      }
    }

    if (form3.Anonimo === "Si" && !props.user.email) {
      if (
        !form3.Email.includes(" ") &&
        (form3.Email.includes("@") || props.user.email.includes("@"))
      ) {
        setCargando(true);
        //  props.CreateUnregisterProject(form2);

        axios
          .post(
            "https://findally-backend.herokuapp.com/api/Project/user/projecttemp",
            {
              ...form3,
              DescribeProyectoAudio: audio,
            }
          )
          .then((response) => {
            //  console.log('si')
            // window.location.href = "/company/profile";
            document
              .getElementById("wenjoy-checkout-button")
              .setAttribute(
                "data-confirmation-url",
                "https://findally-backend.herokuapp.com/api/Project/create/user/wenjoy/" +
                response.data
              );
            document
              .getElementById("wenjoy-checkout-button")
              .setAttribute(
                "data-total-value",
                Math.round(form3.presupuesto / 10)
              );
            setstatusProyecto("wenjoy");
          })
          .catch((err) => setCargando(false));
      }
    }
  };
  const closeForm = () => {
    props.setproyectoactivate(false);
    document.getElementsByTagName("body")[0].style.overflow = "auto";
    if (idp) {
      history.push("/company/project");
    }
  };
  const handleSubmitCreate = async (event) => {
    event.preventDefault();
    if (props.user.email) {
      if (
        props.user.email !== "daniel.gomez.12345@hotmail.com" &&
        props.user.email !== "winnyfferortega@coally.com"
      ) {
        setform3({ ...form3, Email: props.user.email });
      }
    }
    console.log(form3);
    let audio = "";
    let Paso1voice = "";
    let Paso2voice = "";
    let Paso3voice = "";
    let Paso4voice = "";
    let Paso5voice = "";
    let Paso6voice = "";
    let Paso7voice = "";
    let Paso8voice = "";
    let Paso9voice = "";
    let Paso10voice = "";

    if (document.getElementById(`mirar1`)) {
      audio = await document.getElementById(`mirar1`).src;
    }
    if (document.getElementById(`mirar2`)) {
      Paso1voice = await document.getElementById(`mirar2`).src;
    }
    if (document.getElementById(`mirar3`)) {
      Paso2voice = await document.getElementById(`mirar3`).src;
    }
    if (document.getElementById(`mirar4`)) {
      Paso3voice = await document.getElementById(`mirar4`).src;
    }
    if (document.getElementById(`mirar5`)) {
      Paso4voice = await document.getElementById(`mirar5`).src;
    }
    if (document.getElementById(`mirar6`)) {
      Paso5voice = await document.getElementById(`mirar6`).src;
    }
    if (document.getElementById(`mirar7`)) {
      Paso6voice = await document.getElementById(`mirar7`).src;
    }
    if (document.getElementById(`mirar8`)) {
      Paso7voice = await document.getElementById(`mirar8`).src;
    }
    if (document.getElementById(`mirar9`)) {
      Paso8voice = await document.getElementById(`mirar9`).src;
    }
    if (document.getElementById(`mirar10`)) {
      Paso9voice = await document.getElementById(`mirar10`).src;
    }
    if (document.getElementById(`mirar11`)) {
      Paso10voice = await document.getElementById(`mirar11`).src;
    }
    setform3({
      ...form3,
      DescribeProyectoAudio: audio,
    });
    if (idp) {
      axios
        .put(`https://findally-backend.herokuapp.com/api/Project/${idp}`, form3)
        .then((res) => {
          console.log(res);
        })
        .catch((err) => {
          console.log(err);
        });
    }
    //EN ESTA LINEA SE IMPLEMENTÓ EL CONTROL SOBRE LOS PLANES DEL USUARIO Y LA CANTIDAD QUE PUEDE CREAR
    if (props.user.email) {
      axios.post("https://findally-backend.herokuapp.com/api/user/createOffer/" + props.user.id)
        .then((res) => {
          console.log(res);
          if (res.data.status === "success") {
            console.log(res.data)
            return axios.post("https://findally-backend.herokuapp.com/api/Project", form3)
              .then((response) => {
                if (select !== 'gratis' && select !== '') {
                  return window.location.href = "/company/pricing"
                }
                props.handleCloseCreateProject()
              })
              .catch((err) => setCargando(false));
          } else if (res.data.status === "limit excedeed") {
            return alert("Limite de ofertas alcanzado, por favor mejore su plan!")
          } else if (res.data.status === "error") {
            return alert("hubo un error en el servidor!")
          }
        }).catch((err) => {
          console.log(err)
        })
    }
    if (!props.user.email) {
      axios
        .post(
          "https://findally-backend.herokuapp.com/api/Project/user/project",
          form3
        )
        .then((response) => {
          props.handleCloseCreateProject()
        })
        .catch((err) => setCargando(false));
    }
  };
  if (type) {
    options = type.map((el) => <option key={el}>{el}</option>);
  }
  const lang = localStorage.getItem("langlocal");
  console.log(lang, 'che')
  if (!localStorage.getItem("langlocal")) {
    localStorage.setItem("langlocal", "es");
  }
  counterpart.registerTranslations("en", en);
  counterpart.registerTranslations("es", es);
  counterpart.setLocale(localStorage.getItem("langlocal"));
  const [otrosele, setOtrosele] = useState(false);
  if (otrosele === false && form.metricas === "Otro") {
    setOtrosele(true);
  }
  const [topform, settopform] = useState("0");
  const [detalle, setdetalle] = useState(0);
  const [estimado, setestimado] = useState("");
  const [modalidadotro, setmodalidadotro] = useState("");

  const [estadoactual, setestadoactual] = useState(0);
  const [usercreado, setusercreado] = useState(true);

  const [emailvalidado, setemailvalidado] = useState(false);
  return (
    <>
      {props.user && props.user.roles === "company" ? null : history.push("/consultor/home")}
      {options && null}
      <div className='popupproyecto'>
        <div className='popupproyectofondo' onClick={() => {
          props.setproyectoactivate(false);
          document.getElementsByTagName("body")[0].style.overflow = "auto"
        }}></div>
        <div className="contenedorcompanynewcreate"><h3 className="botoncerrarpopuppro"
          onClick={() => {
            closeForm()
          }}>x</h3></div>
        <div className='popupproyectocartel'>

          <div className="steppernewproject">
            <Stepper activeStep={
              !form3.tipoDeServicioDeseado  && (estadoactual === 0) ? 0 :
              form3.tipoDeServicioDeseado === "Contratación" && (estadoactual === 0) ? 0 :
                form3.tipoDeServicioDeseado === "Contratación" && (estadoactual) <= (3 + (form3.Anonimo === "No" ? 1 : 0) + (!props.user.email ? 1 : 0)) ? 1 :
                  form3.tipoDeServicioDeseado === "Contratación" && (estadoactual) <= (9 + (form3.Anonimo === "No" ? 1 : 0) + (!props.user.email ? 1 : 0)) ? 2 :
                    form3.tipoDeServicioDeseado === "Contratación" && (estadoactual) <= (13 + (form3.Anonimo === "No" ? 1 : 0) + (!props.user.email ? 1 : 0) + detalle) ? 3 :
                      form3.tipoDeServicioDeseado === "Contratación" && (estadoactual) > (13 + (form3.Anonimo === "No" ? 1 : 0) + (!props.user.email ? 1 : 0) + detalle) ? 4 :
                        form3.tipoDeServicioDeseado === "Outsourcing (BPO)- Personalized services" && (estadoactual === 0) ? 0 :
                          form3.tipoDeServicioDeseado === "Outsourcing (BPO)- Personalized services" && (estadoactual) <= (3 + (form3.Anonimo === "No" ? 1 : 0) + (!props.user.email ? 1 : 0)) ? 1 :
                            form3.tipoDeServicioDeseado === "Outsourcing (BPO)- Personalized services" && (estadoactual) <= (9 + (form3.Anonimo === "No" ? 1 : 0) + (!props.user.email ? 1 : 0)) ? 2 :
                              form3.tipoDeServicioDeseado === "Outsourcing (BPO)- Personalized services" && (estadoactual) <= (13 + (form3.Anonimo === "No" ? 1 : 0) + (!props.user.email ? 1 : 0) + detalle) ? 3 :
                                form3.tipoDeServicioDeseado === "Outsourcing (BPO)- Personalized services" && (estadoactual) > (13 + (form3.Anonimo === "No" ? 1 : 0) + (!props.user.email ? 1 : 0) + detalle) ? 4 :
                                  form3.tipoDeServicioDeseado === "Onboarding/Handover" && (estadoactual === 0) ? 0 :
                                    form3.tipoDeServicioDeseado === "Onboarding/Handover" && (estadoactual) <= (2 + (form3.Anonimo === "No" ? 1 : 0) + (!props.user.email ? 1 : 0)) ? 1 :
                                      form3.tipoDeServicioDeseado === "Onboarding/Handover" && (estadoactual) <= (6 + (form3.Anonimo === "No" ? 1 : 0) + (!props.user.email ? 1 : 0)) ? 2 :
                                        form3.tipoDeServicioDeseado === "Onboarding/Handover" && (estadoactual) <= (9 + (form3.Anonimo === "No" ? 1 : 0) + (!props.user.email ? 1 : 0) + detalle) ? 3 : 4

            } alternativeLabel className="MuiStepper-root-offi"
              connector={<StepConnector className={'MuiStepConnector-horizontal'} />}>
              {/* {estadoactual === 0 && form3.tipoDeServicioDeseado === "" && lang === 'en' ? steps0En.map((label) => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              )) : estadoactual === 0 && form3.tipoDeServicioDeseado === "" && lang === 'es' ? steps0.map((label) => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              )) : null} */}
              {estadoactual === 0&&!form3.tipoDeServicioDeseado? steps1.map((label, index) => (
                <Step className={'MuiStep-horizontal'} key={label}>
                  <StepLabel className={'MuiStepLabel-root'}>{ 
                  (estadoactual === 0) && index === 0 ?label
                  
                  :null}</StepLabel>
                </Step>
              ))               
              : null}
              {form3.tipoDeServicioDeseado === "Contratación" && lang === 'en' ? steps1En.map((label, index) => (
                <Step className={'MuiStep-horizontal'} key={label}>
                  <StepLabel className={'MuiStepLabel-root'}>{ 
                  (estadoactual === 0) && index === 0 ?label
                  :((estadoactual) <= (3 + (form3.Anonimo === "No" ? 1 : 0) + (!props.user.email ? 1 : 0))&&(estadoactual > 0) && index === 1 )?label
                  :(estadoactual) <= (9 + (form3.Anonimo === "No" ? 1 : 0) + (!props.user.email ? 1 : 0))&&((estadoactual) > (3 + (form3.Anonimo === "No" ? 1 : 0) + (!props.user.email ? 1 : 0))) && index === 2 ?label
                  :(estadoactual) <= (13 + (form3.Anonimo === "No" ? 1 : 0) + (!props.user.email ? 1 : 0) + detalle)&&((estadoactual) > (9 + (form3.Anonimo === "No" ? 1 : 0) + (!props.user.email ? 1 : 0))) && index === 3 ?label
                  :(estadoactual) > (13 + (form3.Anonimo === "No" ? 1 : 0) + (!props.user.email ? 1 : 0) + detalle)&&((estadoactual) > (13 + (form3.Anonimo === "No" ? 1 : 0) + (!props.user.email ? 1 : 0) + detalle)) && index === 4 ?label
                  :null}</StepLabel>
                </Step>
              )) 
              : form3.tipoDeServicioDeseado === "Contratación" && lang === 'es' ? steps1.map((label, index) => (
                <Step className={'MuiStep-horizontal'} key={label}>
                  <StepLabel className={'MuiStepLabel-root'}>{ 
                  (estadoactual === 0) && index === 0 ?label
                  :((estadoactual) <= (3 + (form3.Anonimo === "No" ? 1 : 0) + (!props.user.email ? 1 : 0))&&(estadoactual > 0) && index === 1 )?label
                  :(estadoactual) <= (9 + (form3.Anonimo === "No" ? 1 : 0) + (!props.user.email ? 1 : 0))&&((estadoactual) > (3 + (form3.Anonimo === "No" ? 1 : 0) + (!props.user.email ? 1 : 0))) && index === 2 ?label
                  :(estadoactual) <= (13 + (form3.Anonimo === "No" ? 1 : 0) + (!props.user.email ? 1 : 0) + detalle)&&((estadoactual) > (9 + (form3.Anonimo === "No" ? 1 : 0) + (!props.user.email ? 1 : 0))) && index === 3 ?label
                  :(estadoactual) > (13 + (form3.Anonimo === "No" ? 1 : 0) + (!props.user.email ? 1 : 0) + detalle)&&((estadoactual) > (13 + (form3.Anonimo === "No" ? 1 : 0) + (!props.user.email ? 1 : 0) + detalle)) && index === 4 ?label
                  :null}</StepLabel>
                </Step>
              ))               
              : null}
              {form3.tipoDeServicioDeseado === "Outsourcing (BPO)- Personalized services" && lang === 'en' ? steps2En.map((label, index) => (
                <Step className={'MuiStep-horizontal'} key={label}>
                  <StepLabel className={'MuiStepLabel-root'}>{
                  (estadoactual === 0)&& index === 0 ?label
                  :(estadoactual) <= (3 + (form3.Anonimo === "No" ? 1 : 0) + (!props.user.email ? 1 : 0))&&(estadoactual > 0) && index === 1  ?label
                  :(estadoactual) <= (9 + (form3.Anonimo === "No" ? 1 : 0) + (!props.user.email ? 1 : 0))&&((estadoactual) > (3 + (form3.Anonimo === "No" ? 1 : 0) + (!props.user.email ? 1 : 0))) && index === 2  ?label
:(estadoactual) <= (13 + (form3.Anonimo === "No" ? 1 : 0) + (!props.user.email ? 1 : 0) + detalle)&&((estadoactual) > (9 + (form3.Anonimo === "No" ? 1 : 0) + (!props.user.email ? 1 : 0))) && index === 3  ?label
                  :(estadoactual) > (13 + (form3.Anonimo === "No" ? 1 : 0) + (!props.user.email ? 1 : 0) + detalle)&&((estadoactual) > (13 + (form3.Anonimo === "No" ? 1 : 0) + (!props.user.email ? 1 : 0) + detalle)) && index === 4  ?label
                  : null}</StepLabel>
                </Step>
              )) : form3.tipoDeServicioDeseado === "Outsourcing (BPO)- Personalized services" && lang === 'es' ? steps2.map((label, index) => (
                <Step className={'MuiStep-horizontal'} key={label}>
                  <StepLabel className={'MuiStepLabel-root'}>{
                  (estadoactual === 0)&& index === 0 ?label
                  :(estadoactual) <= (3 + (form3.Anonimo === "No" ? 1 : 0) + (!props.user.email ? 1 : 0))&&(estadoactual > 0) && index === 1  ?label
                  :(estadoactual) <= (9 + (form3.Anonimo === "No" ? 1 : 0) + (!props.user.email ? 1 : 0))&&((estadoactual) > (3 + (form3.Anonimo === "No" ? 1 : 0) + (!props.user.email ? 1 : 0))) && index === 2  ?label
:(estadoactual) <= (13 + (form3.Anonimo === "No" ? 1 : 0) + (!props.user.email ? 1 : 0) + detalle)&&((estadoactual) > (9 + (form3.Anonimo === "No" ? 1 : 0) + (!props.user.email ? 1 : 0))) && index === 3  ?label
                  :(estadoactual) > (13 + (form3.Anonimo === "No" ? 1 : 0) + (!props.user.email ? 1 : 0) + detalle)&&((estadoactual) > (13 + (form3.Anonimo === "No" ? 1 : 0) + (!props.user.email ? 1 : 0) + detalle)) && index === 4  ?label
                  : null}</StepLabel>
                </Step>
              )) : null}
              
              {form3.tipoDeServicioDeseado === "Onboarding/Handover" && lang === 'en' ? steps3En.map((label, index) => (
                <Step className={'MuiStep-horizontal'} key={label}>
                  <StepLabel
                    className={'MuiStepLabel-root'}>{
                      (estadoactual === 0)&& index === 0 ?label
                      :(estadoactual) <= (2 + (form3.Anonimo === "No" ? 1 : 0) + (!props.user.email ? 1 : 0))&&(estadoactual > 0) && index === 1  ?label
                      :(estadoactual) <= (6 + (form3.Anonimo === "No" ? 1 : 0) + (!props.user.email ? 1 : 0))&&((estadoactual) > (2 + (form3.Anonimo === "No" ? 1 : 0) + (!props.user.email ? 1 : 0))) && index === 2  ?label
                      :(estadoactual) <= (9 + (form3.Anonimo === "No" ? 1 : 0) + (!props.user.email ? 1 : 0) + detalle)&&((estadoactual) > (6 + (form3.Anonimo === "No" ? 1 : 0) + (!props.user.email ? 1 : 0))) && index === 3  ?label
                    : null}</StepLabel>
                </Step>
              )) : form3.tipoDeServicioDeseado === "Onboarding/Handover" && lang === 'es' ? steps3.map((label, index) => (
                <Step className={'MuiStep-horizontal'} key={label}>
                  <StepLabel
                    className={'MuiStepLabel-root'}>{
                      (estadoactual === 0)&& index === 0 ?label
                      :(estadoactual) <= (2 + (form3.Anonimo === "No" ? 1 : 0) + (!props.user.email ? 1 : 0))&&(estadoactual > 0) && index === 1  ?label
                      :(estadoactual) <= (6 + (form3.Anonimo === "No" ? 1 : 0) + (!props.user.email ? 1 : 0))&&((estadoactual) > (2 + (form3.Anonimo === "No" ? 1 : 0) + (!props.user.email ? 1 : 0))) && index === 2  ?label
                      :(estadoactual) <= (9 + (form3.Anonimo === "No" ? 1 : 0) + (!props.user.email ? 1 : 0) + detalle)&&((estadoactual) > (6 + (form3.Anonimo === "No" ? 1 : 0) + (!props.user.email ? 1 : 0))) && index === 3  ?label
                    : null}</StepLabel>
                </Step>
              )) : null}
            </Stepper>
            
          </div>
          <div className="create-company-form">
            <div style={{ width: "100%", overflow: "hidden",height:'100%' }}>
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  position: "relative",
                  transition: "all 1s ease-in-out",
                  top: `${topform}%`,
                }}
              >
                <div style={{ width: "100%", height: "100%" }}>
                  <div
                    style={{
                      width: "100%",
                      height: "85%",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <div className="col-two-create-project">
                      <div className="tittle-section-question">
                        {
                          lang === 'en' ? <h4 className="tittle-question-create-project">
                            What type of service do you want to offer?
                          </h4>
                            : <h4 className="tittle-question-create-project">
                              ¿Qué tipo de servicio deseas?
                            </h4>}
                      </div>
                      <div className="formulariocrear">
                        <Select
                          value={
                            form3.tipoDeServicioDeseado !== ""
                              ? form3.tipoDeServicioDeseado
                              : "3"
                          }
                          style={{ marginTop: "9% !important" }}
                          name="tipoDeServicioDeseado"
                          label="hola"
                          className="select-create-project"
                          onChange={(e) => {
                            cambioform3(e);
                          }}
                        >
                          <MenuItem disabled="true" value="3">
                            {
                              lang === 'en' ? <em className="mediumfont">Choose an option</em> :
                                <em className="mediumfont">Elige tu respuesta</em>
                            }

                          </MenuItem>
                          {
                            lang === 'en' ? <MenuItem value="Contratación" className="mediumfont">
                              Contract
                            </MenuItem> : <MenuItem value="Contratación" className="mediumfont">
                              Contratación
                            </MenuItem>
                          }
                          <MenuItem
                            value="Outsourcing (BPO)- Personalized services"
                            className="mediumfont"
                          >
                            Outsourcing (BPO)- Personalized services
                          </MenuItem>
                          {/**<MenuItem
                           value="Onboarding/Handover"
                           className="mediumfont"
                           >
                           Onboarding/Handover{" "}
                           </MenuItem>*/}
                        </Select>
                      </div>
                    </div>
                  </div>
                      <Navegador topform={topform} disable={form3.tipoDeServicioDeseado.length>0?false:true} props={props} lang={lang} estadoactual={estadoactual} form3={form3} detalle={detalle} setestadoactual={setestadoactual} emailvalidado={emailvalidado} settopform={settopform}></Navegador>
                </div>
                {form3.tipoDeServicioDeseado === "Contratación" && (
                  <>
                    {/* //    ¿Que modalidad de contratación deseas? */}
                    <div style={{ width: "100%", height: "100%" }}>
                      <div
                        style={{
                          width: "100%",
                          height: "85%",
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        {console.log('j')}
                        <div className="col-two-create-project">
                          <div className="tittle-section-question">
                            {
                              lang === 'en' ?
                                <h4 className="tittle-question-create-project"> What type of contract would you
                                  like?</h4>
                                : <h4 className="tittle-question-create-project">
                                  ¿Qué modalidad de contratación deseas?
                                </h4>}
                          </div>
                          <div className="formulariocrear">
                            <>
                              {modalidadotro === "Otro" ? (
                                <input
                                  className="input_create_project_new_form mediumfont"
                                  style={{ border: "solid 1px #c4c4c4" }}
                                  type="text"
                                  name="modalidadDeContratacion"
                                  onChange={cambioform3}
                                  placeholder="Otro..."
                                />
                              ) : (
                                <Select
                                  value={
                                    form3.modalidadDeContratacion !== ""
                                      ? form3.modalidadDeContratacion
                                      : "3"
                                  }
                                  style={{ marginTop: "9% !important" }}
                                  name="modalidadDeContratacion"
                                  className="select-create-project"
                                  onChange={(e) => {
                                    cambioform3(e);
                                    e.target.value === "Otro" &&
                                      setmodalidadotro(e.target.value);
                                  }}
                                >
                                  <MenuItem disabled="true" value="3">
                                    {
                                      lang === 'en' ? <em className="mediumfont">Choose an option</em> :
                                        <em className="mediumfont">Elige tu respuesta</em>
                                    }
                                  </MenuItem>
                                  {/**<MenuItem
                                   value="Tiempo completo"
                                   className="mediumfont"
                                   >
                                   {lang === 'en' ? "Full time" : "Tiempo completo"}
                                   </MenuItem>
                                   <MenuItem
                                   value="Contratista"
                                   className="mediumfont"
                                   >
                                   {lang === 'en' ? "Contractor" : "Contratista"}
                                   </MenuItem>
                                   <MenuItem
                                   value="Por proyecto"
                                   className="mediumfont"
                                   >
                                   {lang === 'en' ? 'By project' : 'Por proyecto'}
                                   </MenuItem>
                                   <MenuItem
                                   value="Por hora"
                                   className="mediumfont"
                                   >
                                   {lang === 'en' ? 'Per hour' : 'Por hora'}
                                   </MenuItem>
                                   <MenuItem
                                   value="Tiempo parcial"
                                   className="mediumfont"
                                   >
                                   {lang === 'en' ? 'Part time' : 'Tiempo parcial'}
                                   </MenuItem>
                                   <MenuItem value="Otro" className="mediumfont">
                                   {lang === 'en' ? 'Other' : 'Otro'}
                                   </MenuItem> */}
                                  <MenuItem
                                    value="Término indefinido (tiempo completo)"
                                    className="mediumfont"
                                  >
                                    {lang === 'en' ? "Indefinite term (full time)" : "Término indefinido (tiempo completo)"}
                                  </MenuItem>
                                  <MenuItem
                                    value="Término indefinido (tiempo Parcial)"
                                    className="mediumfont"
                                  >
                                    {lang === 'en' ? "Indefinite term (Part time)" : "Término indefinido (tiempo Parcial)"}
                                  </MenuItem>
                                  <MenuItem
                                    value="Término Fijo  (tiempo completo)"
                                    className="mediumfont"
                                  >
                                    {lang === 'en' ? 'Fixed Term (full time)' : 'Término Fijo  (tiempo completo)'}
                                  </MenuItem>
                                  <MenuItem
                                    value="Término Fijo  (tiempo parcial)"
                                    className="mediumfont"
                                  >
                                    {lang === 'en' ? 'Fixed Term (part time)' : 'Término Fijo  (tiempo parcial)'}
                                  </MenuItem>
                                  <MenuItem
                                    value="Proyecto"
                                    className="mediumfont"
                                  >
                                    {lang === 'en' ? 'Project' : 'Proyecto'}
                                  </MenuItem>
                                  <MenuItem value="Contratista prestación de servicios" className="mediumfont">
                                    {lang === 'en' ? 'Contractor provision of services' : 'Contratista prestación de servicios'}
                                  </MenuItem>
                                  <MenuItem value="Contrato por Hora o labor" className="mediumfont">
                                    {lang === 'en' ? 'Hourly contract or work' : 'Contrato por Hora o labor'}
                                  </MenuItem>
                                </Select>
                              )}
                            </>
                          </div>
                        </div>
                      </div>
                      <Navegador topform={topform} disable={form3.modalidadDeContratacion.length>0?false:true} props={props} lang={lang} estadoactual={estadoactual} form3={form3} detalle={detalle} setestadoactual={setestadoactual} emailvalidado={emailvalidado} settopform={settopform}></Navegador>
                    </div>
                    {/* //    Deseas publicar de manera anónima */}
                    <div style={{ width: "100%", height: "100%" }}>
                      <div
                        style={{
                          width: "100%",
                          height: "85%",
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <div className="col-two-create-project">
                          <div className="tittle-section-question">
                            {
                              lang === 'en' ? <h4 className="tittle-question-create-project"> Do you want to publish
                                anonymously?</h4> : <h4 className="tittle-question-create-project">
                                ¿Deseas publicar de manera anónima?
                              </h4>
                            }
                          </div>
                          <div className="formulariocrear">
                            <Select
                              value={form3.Anonimo !== "" ? form3.Anonimo : "3"}
                              style={{ marginTop: "9% !important" }}
                              name="Anonimo"
                              className="select-create-project"
                              onChange={cambioform3}
                            >
                              <MenuItem disabled="true" value="3">
                                {
                                  lang === 'en' ? <em className="mediumfont">Choose an option</em> :
                                    <em className="mediumfont">Elige tu respuesta</em>
                                }
                              </MenuItem>
                              <MenuItem value="Si" className="mediumfont">
                                {lang === 'en' ? 'Yes' : 'Si'}
                              </MenuItem>
                              <MenuItem value="No" className="mediumfont">
                                No
                              </MenuItem>
                            </Select>
                          </div>
                        </div>
                      </div>
                      <Navegador topform={topform} disable={false} props={props} lang={lang} estadoactual={estadoactual} form3={form3} detalle={detalle} setestadoactual={setestadoactual} emailvalidado={emailvalidado} settopform={settopform}></Navegador>

                    </div>

                    {/*//     Ingrese nombre de la empresa */}
                    {/* {form3.Anonimo === "No" && (
                      <div style={{ width: "100%", height: "100%" }}>
                        <div
                          style={{
                            width: "100%",
                            height: "100%",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <div className="col-two-create-project">
                            <div className="tittle-section-question">
                              {
                                lang === 'en' ?
                                  <h4 className="tittle-question-create-project">Enter the name of the company</h4> :
                                  <h4 className="tittle-question-create-project"> Ingrese el nombre de la empresa</h4>
                              }
                            </div>
                            <div className="formulariocrear">
                              {
                                <>
                                  <input
                                    className="input_create_project_new_form mediumfont"
                                    style={{ border: "solid 1px #c4c4c4" }}
                                    type="text"
                                    name="NombreEmpresa"
                                    onChange={cambioform3}
                                  />
                                </>
                              }
                            </div>
                          </div>
                        </div>
                      </div>
                    )} */}
                    {/* Ingresa tu Email              */}
                    {!props.user.email && (
                      <div style={{ width: "100%", height: "100%" }}>
                        <div
                          style={{
                            width: "100%",
                            height: "85%",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <div className="col-two-create-project">
                            <div className="tittle-section-question">
                              {
                                lang === 'en' ? <h4 className="tittle-question-create-project">Enter your email</h4> :
                                  <h4 className="tittle-question-create-project">Ingresa tu email</h4>
                              }
                            </div>
                            <div className="formulariocrear">
                              {
                                <>
                                  <input
                                    className="input_create_project_new_form mediumfont"
                                    style={{ border: "solid 1px #c4c4c4" }}
                                    type="text"
                                    name="Email"
                                    onChange={(event) => emailRegex(event)}
                                  />
                                </>
                              }
                            </div>
                          </div>
                        </div>
                        <Navegador topform={topform} disable={false} props={props} lang={lang} estadoactual={estadoactual} form3={form3} detalle={detalle} setestadoactual={setestadoactual} emailvalidado={emailvalidado} settopform={settopform}></Navegador>
</div>
                    )}
                    {/* Ingresa tu Email              */}
                    {(props.user.email === "winnyfferortega@coally.com" ||
                      props.user.email ===
                      "daniel.gomez.12345@hotmail.com") && (
                        <div style={{ width: "100%", height: "100%" }}>
                          <div
                            style={{
                              width: "100%",
                              height: "85%",
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <div className="col-two-create-project">
                              <div className="tittle-section-question">
                                {
                                  lang === 'en' ? <h4 className="tittle-question-create-project">Enter your email</h4> :
                                    <h4 className="tittle-question-create-project">Ingresa tu email</h4>
                                }
                              </div>
                              <div className="formulariocrear">
                                {
                                  <>
                                    <input
                                      className="input_create_project_new_form mediumfont"
                                      style={{ border: "solid 1px #c4c4c4" }}
                                      type="text"
                                      name="Email"
                                      onChange={(event) => emailRegex(event)}
                                    />
                                    {usercreado ? (
                                      <>
                                        {
                                          lang === 'en' ? <button
                                            className="button_create_projectform"
                                            style={{
                                              marginRight: "1rem",
                                              background: "rgb(240, 113, 48)",
                                            }}
                                          >
                                            Registered
                                          </button> :
                                            <button
                                              className="button_create_projectform"
                                              style={{
                                                marginRight: "1rem",
                                                background: "rgb(240, 113, 48)",
                                              }}
                                            >
                                              Registrado
                                            </button>
                                        }
                                        <button
                                          className="button_create_projectform"
                                          onClick={() => setusercreado(false)}
                                        >
                                          Registrar
                                        </button>
                                      </>
                                    ) : (
                                      <>
                                        <button
                                          className="button_create_projectform"
                                          onClick={() => setusercreado(true)}
                                        >
                                          Registrado
                                        </button>
                                        <button
                                          className="button_create_projectform"
                                          style={{
                                            marginLeft: "1rem",
                                            background: "rgb(240, 113, 48)",
                                          }}
                                        >
                                          Registrar
                                        </button>
                                      </>
                                    )}
                                  </>
                                }
                              </div>
                            </div>
                          </div>
                          <Navegador topform={topform} disable={false} props={props} lang={lang} estadoactual={estadoactual} form3={form3} detalle={detalle} setestadoactual={setestadoactual} emailvalidado={emailvalidado} settopform={settopform}></Navegador>
                        </div>
                      )}
                    <div style={{ width: "100%", height: "100%" }}>
                      <div
                        style={{
                          width: "100%",
                          height: "85%",
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <div className="col-two-create-project">
                          <div className="tittle-section-question">
                            {
                              lang === 'en' ?
                                <h4 className="tittle-question-create-project">Name of opportunity, position or
                                  project</h4> :
                                <h4 className="tittle-question-create-project">Nombre de la oportunidad, cargo o
                                  proyecto</h4>
                            }
                          </div>
                          <div className="formulariocrear">
                            {
                              <>
                                <input
                                  className="input_create_project_new_form mediumfont"
                                  style={{ border: "solid 1px #c4c4c4" }}
                                  type="text"
                                  name="NombreOportunidad"
                                  onChange={cambioform3}
                                />
                              </>
                            }
                          </div>
                        </div>
                      </div>
                      <Navegador topform={topform} disable={form3.NombreOportunidad.length>0?false:true} props={props} lang={lang} estadoactual={estadoactual} form3={form3} detalle={detalle} setestadoactual={setestadoactual} emailvalidado={emailvalidado} settopform={settopform}></Navegador>
                    </div>
                    {/* Cuantos profesionales deseas contratar?             */}
                    <div style={{ width: "100%", height: "100%" }}>
                      <div
                        style={{
                          width: "100%",
                          height: "85%",
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <div className="col-two-create-project">
                          <div className="tittle-section-question">
                            {
                              lang === 'en' ?
                                <h4 className="tittle-question-create-project">How many professionals do you want to
                                  hire?</h4> :
                                <h4 className="tittle-question-create-project">Cuantos profesionales deseas
                                  contratar?</h4>
                            }
                          </div>
                          <div className="formulariocrear">
                            {
                              <>
                                <input
                                  className="input_create_project_new_form mediumfont"
                                  style={{ border: "solid 1px #c4c4c4" }}
                                  type="number"                                
                                  min={1}
                                  onKeyPress={(e) => {
                                    if (!/[0-9]/.test(e.key)) {
                                      e.preventDefault();
                                    }
                                  }}
                                  name="profesionales"
                                  onChange={cambioform3}
                                />
                              </>
                            }
                          </div>
                        </div>
                      </div>
                      <Navegador topform={topform} disable={false} props={props} lang={lang} estadoactual={estadoactual} form3={form3} detalle={detalle} setestadoactual={setestadoactual} emailvalidado={emailvalidado} settopform={settopform}></Navegador>
                    </div>
                    {/* Describe el proyecto/rol: Describe el detalle de la oportunidad/rol/proyecto. Si lo deseas puedes enviar una nota de voz con los requerimientos */}
                    <div style={{ width: "100%", height: "100%" }}>
                      <div
                        style={{
                          width: "100%",
                          height: "85%",
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <div
                          className="col-two-create-project"
                          style={{ width: "80%" }}
                        >
                          <div className="tittle-section-question">
                            {
                              lang === 'en' ?
                                <h4 className="tittle-question-create-project">Describe the project/role: Describe the
                                  detail of the opportunity/role/project. If you want you can send a voice note with the
                                  requirements</h4> :
                                <h4 className="tittle-question-create-project">Describe el proyecto/rol: Describe el
                                  detalle de la oportunidad/rol/proyecto. Si lo deseas puedes enviar una nota de voz con
                                  los requerimientos</h4>
                            }
                          </div>
                          <div
                            className="formulariocrear"
                            style={{ height: "100%" }}
                          >
                            {
                              <div style={{ display: "flex", height: "100%" }}>
                                <textarea
                                  className="input_create_project_new_form23 mediumfont"
                                  style={{
                                    border: "solid 1px #c4c4c4",
                                    width: "60%",
                                    resize: "none",
                                  }}
                                  type="text"
                                  name="DescribeProyecto"
                                  onChange={cambioform3}
                                />
                                <div
                                  style={{
                                    width: "50%",
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "center",
                                    marginLeft: "1%",
                                  }}
                                >
                                  <div className="formulariocrear">

                                    <button
                                      id="1a"
                                      style={{
                                        display: "flex",
                                        width: "100%",
                                        background: "#e8e9eb",
                                        border: "none",
                                        color: "#696a7c",
                                        cursor: "pointer",
                                        justifyContent: "right",
                                      }}
                                      onClick={() => startRecording(1)}
                                    >
                                      <p
                                        style={{ cursor: "pointer" }}
                                        className="mediumfont"
                                      >
                                        {lang === 'en' ? 'Record voice note' : 'Grabar Audio'}
                                      </p>
                                      <MicIcon
                                        style={{ cursor: "pointer" }}
                                        className="mediumfont"
                                      ></MicIcon>
                                    </button>

                                    <button
                                      id="1b"
                                      style={{
                                        display: "none",
                                        width: "100%",
                                        position: "relative",
                                        background: "#e8e9eb",
                                        border: "none",
                                        justifyContent: "right",
                                      }}
                                      onClick={() => stopRecording(1)}
                                      className="mediumfont"
                                    >
                                      <div className="audioclase">
                                        <p
                                          style={{
                                            color: "#f33e16",
                                            cursor: "pointer",
                                          }}
                                          className="mediumfont"
                                        >
                                          {lang === 'en' ? 'Recording voice note' : 'Grabando Audio'}
                                        </p>
                                        <GraphicEqIcon
                                          style={{
                                            color: "#f33e16",
                                            cursor: "pointer",
                                          }}
                                          className="mediumfont"
                                        ></GraphicEqIcon>
                                      </div>
                                      <div className="audioclasehover">
                                        <p
                                          style={{
                                            color: "#1bb96d",
                                            cursor: "pointer",
                                          }}
                                          className="mediumfont"
                                        >
                                          {lang === 'en' ? 'End voice note' : 'Terminar Audio'}
                                        </p>
                                        <StopIcon
                                          style={{
                                            color: "#1bb96d",
                                            cursor: "pointer",
                                          }}
                                          className="mediumfont"
                                        ></StopIcon>
                                      </div>
                                    </button>
                                  </div>
                                  <li
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <audio
                                      id="mirar1"
                                      src=""
                                      autoplay
                                      preload="auto"
                                      controls
                                      className="mediumfont audioo"
                                    ></audio>
                                    <DeleteIcon
                                      className="mediumfont"
                                      onClick={() =>
                                      (document.getElementById("mirar1").src =
                                        "")
                                      }
                                      style={{ margin: "0", cursor: "pointer" }}
                                    ></DeleteIcon>
                                  </li>
                                </div>
                              </div>
                            }
                          </div>
                        </div>
                      </div>
                      <Navegador topform={topform} disable={form3.DescribeProyecto.length>0?false:true} props={props} lang={lang} estadoactual={estadoactual} form3={form3} detalle={detalle} setestadoactual={setestadoactual} emailvalidado={emailvalidado} settopform={settopform}></Navegador>
                    </div>

                    {/* Selecciona la carrera del profesional/área de conocimiento  */}
                    <div style={{ width: "100%", height: "100%" }}>
                      <div
                        style={{
                          width: "100%",
                          height: "85%",
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <div className="col-one-create-project">
                          <div className="tittle-section-question">
                            <h4 className="tittle-question-create-project">
                              {
                                <Translate
                                  className="mediumfont"
                                  content="Seleccioneelareadeconocimientorequeridasegunsusrequerimientos"
                                  component="span"
                                />
                              }
                            </h4>
                          </div>

                          {otroCausa !== "otro" ? (
                            <div className="formulariocrear">
                              <Select
                                value={form3.SeleccionaCarrera}
                                name="SeleccionaCarrera"
                                onChange={(e) => cambioFormCarrera(e)}
                              >

                                <MenuItem value="Agronomía, Veterinaria y afines">
                                  {
                                    <Translate
                                      className="mediumfont"
                                      content="AgronomíaVeterinariayafines"
                                      component="span"
                                    />
                                  }
                                </MenuItem>
                                <MenuItem value="Bellas Artes">
                                  {
                                    <Translate
                                      className="mediumfont"
                                      content="BellasArtes"
                                      component="span"
                                    />
                                  }
                                </MenuItem>
                                <MenuItem value="Ciencias de la Educación">
                                  {
                                    <Translate
                                      className="mediumfont"
                                      content="CienciasdelaEducación"
                                      component="span"
                                    />
                                  }
                                </MenuItem>
                                <MenuItem value="Ciencias de la Salud">
                                  {
                                    <Translate
                                      className="mediumfont"
                                      content="CienciasdelaSalud"
                                      component="span"
                                    />
                                  }
                                </MenuItem>
                                <MenuItem value="Ciencias Sociales y Humanas">
                                  {
                                    <Translate
                                      className="mediumfont"
                                      content="CienciasSocialesyHumanas"
                                      component="span"
                                    />
                                  }
                                </MenuItem>
                                <MenuItem value="Economía, Administración, Contaduría y afines">
                                  {
                                    <Translate
                                      className="mediumfont"
                                      content="EconomíaAdministraciónContaduríayafines"
                                      component="span"
                                    />
                                  }
                                </MenuItem>
                                <MenuItem value="Ingeniería, Arquitectura, Urbanismo y afines">
                                  {
                                    <Translate
                                      className="mediumfont"
                                      content="IngenieríaArquitecturaUrbanismoyafines"
                                      component="span"
                                    />
                                  }
                                </MenuItem>
                                <MenuItem value="Matemáticas y Ciencias Naturales">
                                  {
                                    <Translate
                                      className="mediumfont"
                                      content="MatemáticasyCienciasNaturales"
                                      component="span"
                                    />
                                  }
                                </MenuItem>
                                <MenuItem value="Otro">
                                  {
                                    <Translate
                                      className="mediumfont"
                                      content="Otro"
                                      component="span"
                                    />
                                  }
                                </MenuItem>
                                <MenuItem value="NA" className="mediumfont">
                                  NA
                                </MenuItem>
                              </Select>
                            </div>
                          ) : (
                            <input
                              className="input_create_project_new_form"
                              type="text"
                              name="SeleccionaCarrera"
                              onChange={cambioform3}
                            />
                          )}
                          {otroInput ? <div style={{ width: '105%' }}>
                            <ChipInput onChange={(chips) => handleChange(chips)}
                              onDelete={(chip, index) => handleDeleteChip(chip, index)}
                              style={{ width: '100%' }}
                              placeholder={lang === 'es' ? 'introduce una profesión y luego enter!' : 'introduce a profession and then enter!'}
                              fullWidthInput={true} disableUnderline={true} alwaysShowPlaceholder={false}
                              allowDuplicates={false}
                              defaultValue={lang === 'es' ? ['abogado', 'contador', 'letrado', 'procurador'] : ['lawyer', 'accountant', 'lawyer', 'solicitor']} />
                          </div> : null}
                        </div>
                      </div>
                      <Navegador topform={topform} disable={form3.SeleccionaCarrera.length>0?false:true} props={props} lang={lang} estadoactual={estadoactual} form3={form3} detalle={detalle} setestadoactual={setestadoactual} emailvalidado={emailvalidado} settopform={settopform}></Navegador>
                    </div>
                    {/* Nivel de conocimiento (selección multiple) */}
                    <div style={{ width: "100%", height: "100%" }}>
                      <div
                        style={{
                          width: "100%",
                          height: "85%",
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <div className="col-two-create-project">
                          <div className="tittle-section-question">
                            {
                              lang === 'en' ? <h4 className="tittle-question-create-project">
                                Knowledge level (multiple choice)
                              </h4> : <h4 className="tittle-question-create-project">
                                Nivel de conocimiento (selección multiple)
                              </h4>
                            }
                          </div>
                          <div
                            className="formulariocrear"
                            style={{
                              display: "flex",
                              justifyContent: "space-around",
                            }}
                          >
                            {
                              <>
                                {form3.Niveldeconocimiento.includes(
                                  "Profesional"
                                ) ? (
                                  <button
                                    className="button_create_projectform2"
                                    style={{
                                      background: "#F07130",
                                      color: "white",
                                      margin: "0.3rem",
                                      border: "none",
                                    }}
                                    name="Niveldeconocimiento"
                                    value="Profesional"
                                    onClick={() =>
                                      setform3({
                                        ...form3,
                                        Niveldeconocimiento:
                                          form3.Niveldeconocimiento.filter(
                                            (e) => !e.includes("Profesional")
                                          ),
                                      })
                                    }
                                  >
                                    {lang === 'en' ? 'Professional' : 'Profesional'}
                                  </button>
                                ) : (
                                  <button
                                    className="button_create_projectform2"
                                    style={{ margin: "0.3rem" }}
                                    name="Niveldeconocimiento"
                                    value="Profesional"
                                    onClick={() =>
                                      setform3({
                                        ...form3,
                                        Niveldeconocimiento: [
                                          ...form3.Niveldeconocimiento,
                                          "Profesional",
                                        ],
                                      })
                                    }
                                  >
                                    {lang === 'en' ? 'Professional' : 'Profesional'}
                                  </button>
                                )}
                                {form3.Niveldeconocimiento.includes(
                                  "Técnico/tecnólogo"
                                ) ? (
                                  <button
                                    className="button_create_projectform2"
                                    style={{
                                      background: "#F07130",
                                      color: "white",
                                      margin: "0.3rem",
                                      width: "fit-content",
                                      border: "none",
                                    }}
                                    name="Niveldeconocimiento"
                                    value="Técnico/tecnólogo"
                                    onClick={() =>
                                      setform3({
                                        ...form3,
                                        Niveldeconocimiento:
                                          form3.Niveldeconocimiento.filter(
                                            (e) =>
                                              !e.includes("Técnico/tecnólogo")
                                          ),
                                      })
                                    }
                                  >
                                    {lang === 'en' ? 'Technical' : 'Técnico/tecnólogo'}
                                  </button>
                                ) : (
                                  <button
                                    className="button_create_projectform2"
                                    style={{
                                      margin: "0.3rem",
                                      width: "fit-content",
                                    }}
                                    name="Niveldeconocimiento"
                                    value="Técnico/tecnólogo"
                                    onClick={() =>
                                      setform3({
                                        ...form3,
                                        Niveldeconocimiento: [
                                          ...form3.Niveldeconocimiento,
                                          "Técnico/tecnólogo",
                                        ],
                                      })
                                    }
                                  >
                                    {lang === 'en' ? 'Technical' : 'Técnico/tecnólogo'}
                                  </button>
                                )}
                              </>
                            }
                          </div>
                        </div>
                      </div>
                      <Navegador topform={topform} disable={form3.Niveldeconocimiento.length>0?false:true } props={props} lang={lang} estadoactual={estadoactual} form3={form3} detalle={detalle} setestadoactual={setestadoactual} emailvalidado={emailvalidado} settopform={settopform}></Navegador>
                    </div>
                    {/* Lenguaje (selección multiple) */}
                    <div style={{ width: "100%", height: "100%" }}>
                      <div
                        style={{
                          width: "100%",
                          height: "85%",
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <div className="col-two-create-project">
                          <div className="tittle-section-question">
                            {
                              lang === 'en' ?
                                <h4 className="tittle-question-create-project"> Language (multiple choice) </h4> :
                                <h4 className="tittle-question-create-project"> Lenguaje (selección multiple) </h4>
                            }
                          </div>
                          <div
                            className="formulariocrear"
                            style={{
                              display: "flex",
                              justifyContent: "space-around",
                            }}
                          >
                            {
                              <>
                                {form3.Lenguaje.includes("Ingles") ? (
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <div
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                      }}
                                    >
                                      {
                                        lang === 'en' ?
                                          <button
                                            className="button_create_projectform2"
                                            style={{
                                              background: "#F07130",
                                              color: "white",
                                              margin: "0.3rem",
                                              border: "none",
                                            }}
                                            name="Lenguaje"
                                            value="Ingles"
                                            onClick={() =>
                                              setform3({
                                                ...form3,
                                                Lenguaje: form3.Lenguaje.filter(
                                                  (e) => !e.includes("Ingles")
                                                ),
                                              })
                                            }
                                          >
                                            English
                                          </button> : <button
                                            className="button_create_projectform2"
                                            style={{
                                              background: "#F07130",
                                              color: "white",
                                              margin: "0.3rem",
                                              border: "none",
                                            }}
                                            name="Lenguaje"
                                            value="Ingles"
                                            onClick={() =>
                                              setform3({
                                                ...form3,
                                                Lenguaje: form3.Lenguaje.filter(
                                                  (e) => !e.includes("Ingles")
                                                ),
                                              })
                                            }
                                          >
                                            Ingles
                                          </button>
                                      }

                                    </div>
                                    <div
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                      }}
                                    >
                                      <select
                                        className="select_create_projectform"
                                        name="Nivel"
                                        defaultValue="Nivel"
                                        onChange={(e) =>
                                          setform3({
                                            ...form3,
                                            Nivel: e.target.value,
                                          })
                                        }
                                      >
                                        <option value="Nivel" disabled>
                                          {lang === 'en' ? 'Category' : 'Nivel'}
                                        </option>
                                        <option value="A1">A1</option>
                                        <option value="A2">A2</option>
                                        <option value="B2">B1</option>
                                        <option value="B1">B2</option>
                                        <option value="C1">C1</option>
                                        <option value="C2">C2</option>
                                      </select>
                                    </div>
                                  </div>
                                ) : (
                                  <button
                                    className="button_create_projectform2"
                                    style={{ margin: "0.3rem" }}
                                    name="Lenguaje"
                                    value="Ingles"
                                    onClick={() =>
                                      setform3({
                                        ...form3,
                                        Lenguaje: [...form3.Lenguaje, "Ingles"],
                                      })
                                    }
                                  >
                                    {lang === 'en' ? 'English' : 'Inglés'}
                                  </button>
                                )}
                                {form3.Lenguaje.includes("Español") ? (
                                  <button
                                    className="button_create_projectform2"
                                    style={{
                                      background: "#F07130",
                                      color: "white",
                                      margin: "0.3rem",
                                      border: "none",
                                    }}
                                    name="Lenguaje"
                                    value="Español"
                                    onClick={() =>
                                      setform3({
                                        ...form3,
                                        Lenguaje: form3.Lenguaje.filter(
                                          (e) => !e.includes("Español")
                                        ),
                                      })
                                    }
                                  >
                                    {lang === 'en' ? 'Spanish' : 'Español'}
                                  </button>
                                ) : (
                                  <button
                                    className="button_create_projectform2"
                                    style={{ margin: "0.3rem" }}
                                    name="Lenguaje"
                                    value="Español"
                                    onClick={() =>
                                      setform3({
                                        ...form3,
                                        Lenguaje: [
                                          ...form3.Lenguaje,
                                          "Español",
                                        ],
                                      })
                                    }
                                  >
                                    {lang === 'en' ? 'Spanish' : 'Español'}
                                  </button>
                                )}
                              </>
                            }
                          </div>
                        </div>
                      </div>
                      <Navegador topform={topform} disable={form3.Lenguaje.length>0?false:true} props={props} lang={lang} estadoactual={estadoactual} form3={form3} detalle={detalle} setestadoactual={setestadoactual} emailvalidado={emailvalidado} settopform={settopform}></Navegador>
                    </div>
                    <div style={{ width: "100%", height: "100%" }}>
                      <div
                        style={{
                          width: "100%",
                          height: "85%",
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <div className="col-two-create-project">
                          <div className="tittle-section-question">
                            <h4 className="tittle-question-create-project">
                              {lang === 'en' ?
                                'What skills do you need for the opportunity (max 5)' : 'Cuales habilidades requieres para la oportunidad (max 5)'}
                            </h4>
                          </div>
                          <div className="formulariocrear">
                            {
                              <>
                                {form3.SeleccionaCarrera ===
                                  "Agronomía, Veterinaria y afines" ? (
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "column",
                                      justifyContent: "flex-start",
                                      alignItems: "center",
                                    }}
                                  >
                                    {mapId.map((elem) => (
                                      <div
                                        style={{ marginBottom: "1%" }}
                                        key={elem}
                                      >
                                        <select
                                          name="Agronomía, Veterinaria y afines"
                                          defaultValue=""
                                          onChange={(e) => {
                                            if (form3.habilidadesRequeridas.includes(e.target.value)) {
                                              e.target.value = ""
                                              return alert("Ya seleccionaste esta habilidad")
                                            } else {
                                              return setform3({
                                                ...form3,
                                                habilidadesRequeridas: [
                                                  ...form3.habilidadesRequeridas,
                                                  e.target.value,
                                                ],
                                              })
                                            }
                                          }
                                          }
                                        >
                                          {abilities[
                                            lang === 'en' ? "Agronomía, Veterinaria y afines EN" : "Agronomía, Veterinaria y afines"
                                          ].map((e, i) => {
                                            return (
                                              <option value={e} key={i}>
                                                {e}
                                              </option>
                                            );
                                          })}
                                        </select>
                                      </div>
                                    ))}
                                  </div>
                                ) : form3.SeleccionaCarrera ===
                                  "Bellas Artes" ? (
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "column",
                                      justifyContent: "flex-start",
                                      alignItems: "center",
                                    }}
                                  >
                                    {mapId.map((elem) => (
                                      <div
                                        style={{ marginBottom: "1%" }}
                                        key={elem}
                                      >
                                        <select
                                          name="Bellas Artes"
                                          defaultValue=""
                                          onChange={(e) => {
                                            if (form3.habilidadesRequeridas.includes(e.target.value)) {
                                              e.target.value = ""
                                              return alert("Ya seleccionaste esta habilidad")
                                            } else {
                                              return setform3({
                                                ...form3,
                                                habilidadesRequeridas: [
                                                  ...form3.habilidadesRequeridas,
                                                  e.target.value,
                                                ],
                                              })
                                            }
                                          }
                                          }
                                        >
                                          {abilities["Bellas Artes"].map(
                                            (e, i) => {
                                              return (
                                                <option value={e} key={i}>
                                                  {e}
                                                </option>
                                              );
                                            }
                                          )}
                                        </select>
                                      </div>
                                    ))}
                                  </div>
                                ) : form3.SeleccionaCarrera ===
                                  "Ciencias de la Educación" ? (
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "column",
                                      justifyContent: "flex-start",
                                      alignItems: "center",
                                    }}
                                  >
                                    {mapId.map((elem) => (
                                      <div
                                        style={{ marginBottom: "1%" }}
                                        key={elem}
                                      >
                                        <select
                                          name="Ciencias de la Educación"
                                          defaultValue=""
                                          onChange={(e) => {
                                            if (form3.habilidadesRequeridas.includes(e.target.value)) {
                                              e.target.value = ""
                                              return alert("Ya seleccionaste esta habilidad")
                                            } else {
                                              return setform3({
                                                ...form3,
                                                habilidadesRequeridas: [
                                                  ...form3.habilidadesRequeridas,
                                                  e.target.value,
                                                ],
                                              })
                                            }
                                          }
                                          }
                                        >
                                          {abilities[
                                            lang === 'en' ? "Ciencias de la educación EN" : "Ciencias de la educación"
                                          ].map((e, i) => {
                                            return (
                                              <option value={e} key={i}>
                                                {e}
                                              </option>
                                            );
                                          })}
                                        </select>
                                      </div>
                                    ))}
                                  </div>
                                ) : form3.SeleccionaCarrera ===
                                  "Ciencias de la Salud" ? (
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "column",
                                      justifyContent: "flex-start",
                                      alignItems: "center",
                                    }}
                                  >
                                    {mapId.map((elem) => (
                                      <div
                                        style={{ marginBottom: "1%" }}
                                        key={elem}
                                      >
                                        <select
                                          name="Ciencias de la Salud"
                                          defaultValue=""
                                          onChange={(e) => {
                                            if (form3.habilidadesRequeridas.includes(e.target.value)) {
                                              e.target.value = ""
                                              return alert("Ya seleccionaste esta habilidad")
                                            } else {
                                              return setform3({
                                                ...form3,
                                                habilidadesRequeridas: [
                                                  ...form3.habilidadesRequeridas,
                                                  e.target.value,
                                                ],
                                              })
                                            }
                                          }
                                          }
                                        >
                                          {abilities[
                                            lang === 'en' ? "Ciencias de la Salud EN" : "Ciencias de la Salud"
                                          ].map((e, i) => {
                                            return (
                                              <option value={e} key={i}>
                                                {e}
                                              </option>
                                            );
                                          })}
                                        </select>
                                      </div>
                                    ))}
                                  </div>
                                ) : form3.SeleccionaCarrera ===
                                  "Ciencias Sociales y Humanas" ? (
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "column",
                                      justifyContent: "flex-start",
                                      alignItems: "center",
                                    }}
                                  >
                                    {mapId.map((elem) => (
                                      <div
                                        style={{ marginBottom: "1%" }}
                                        key={elem}
                                      >
                                        <select
                                          name="Ciencias Sociales y Humanas"
                                          defaultValue=""
                                          onChange={(e) => {
                                            if (form3.habilidadesRequeridas.includes(e.target.value)) {
                                              e.target.value = ""
                                              return alert("Ya seleccionaste esta habilidad")
                                            } else {
                                              return setform3({
                                                ...form3,
                                                habilidadesRequeridas: [
                                                  ...form3.habilidadesRequeridas,
                                                  e.target.value,
                                                ],
                                              })
                                            }
                                          }
                                          }
                                        >
                                          {abilities[
                                            lang === 'en' ? "Ciencias Sociales y humanas EN" : "Ciencias Sociales y humanas"
                                          ].map((e, i) => {
                                            return (
                                              <option value={e} key={i}>
                                                {e}
                                              </option>
                                            );
                                          })}
                                        </select>
                                      </div>
                                    ))}
                                  </div>
                                ) : form3.SeleccionaCarrera ===
                                  "Economía, Administración, Contaduría y afines" ? (
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "column",
                                      justifyContent: "flex-start",
                                      alignItems: "center",
                                    }}
                                  >
                                    {mapId.map((elem) => (
                                      <div
                                        style={{ marginBottom: "1%" }}
                                        key={elem}
                                      >
                                        <select
                                          name="Economía, Administración, Contaduría y afines"
                                          defaultValue=""
                                          onChange={(e) => {
                                            if (form3.habilidadesRequeridas.includes(e.target.value)) {
                                              e.target.value = ""
                                              return alert("Ya seleccionaste esta habilidad")
                                            } else {
                                              return setform3({
                                                ...form3,
                                                habilidadesRequeridas: [
                                                  ...form3.habilidadesRequeridas,
                                                  e.target.value,
                                                ],
                                              })
                                            }
                                          }
                                          }
                                        >
                                          {abilities[
                                            lang === 'en' ? "Economía, Administración, Contaduría y afines EN" : "Economia, Administracion, Contaduria y afines"
                                          ].map((e, i) => {
                                            return (
                                              <option value={e} key={i}>
                                                {e}
                                              </option>
                                            );
                                          })}
                                        </select>
                                      </div>
                                    ))}
                                  </div>
                                ) : form3.SeleccionaCarrera ===
                                  "Ingeniería, Arquitectura, Urbanismo y afines" ? (
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "column",
                                      justifyContent: "flex-start",
                                      alignItems: "center",
                                    }}
                                  >
                                    {mapId.map((elem) => (
                                      <div
                                        style={{ marginBottom: "1%" }}
                                        key={elem}
                                      >
                                        <select
                                          name="Ingeniería, Arquitectura, Urbanismo y afines"
                                          defaultValue=""
                                          onChange={(e) => {
                                            if (form3.habilidadesRequeridas.includes(e.target.value)) {
                                              e.target.value = ""
                                              return alert("Ya seleccionaste esta habilidad")
                                            } else {
                                              return setform3({
                                                ...form3,
                                                habilidadesRequeridas: [
                                                  ...form3.habilidadesRequeridas,
                                                  e.target.value,
                                                ],
                                              })
                                            }
                                          }
                                          }
                                        >
                                          {abilities[
                                            lang === 'en' ? "Ingeniería, Arquitectura, Urbanismo y afines EN" : "Ingeniería, Arquitectura, urbanismo y afines"
                                          ].map((e, i) => {
                                            return (
                                              <option value={e} key={i}>
                                                {e}
                                              </option>
                                            );
                                          })}
                                        </select>
                                      </div>
                                    ))}
                                  </div>
                                ) : form3.SeleccionaCarrera ===
                                  "Matemáticas y Ciencias Naturales" ? (
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "column",
                                      justifyContent: "flex-start",
                                      alignItems: "center",
                                    }}
                                  >
                                    {mapId.map((elem) => (
                                      <div
                                        style={{ marginBottom: "1%" }}
                                        key={elem}
                                      >
                                        <select
                                          name="Matemáticas y Ciencias Naturales"
                                          defaultValue=""
                                          onChange={(e) => {
                                            if (form3.habilidadesRequeridas.includes(e.target.value)) {
                                              e.target.value = ""
                                              return alert("Ya seleccionaste esta habilidad")
                                            } else {
                                              return setform3({
                                                ...form3,
                                                habilidadesRequeridas: [
                                                  ...form3.habilidadesRequeridas,
                                                  e.target.value,
                                                ],
                                              })
                                            }
                                          }
                                          }
                                        >
                                          {abilities[
                                            lang === 'en' ? "Matemáticas y Ciencias Naturales EN" : "Matemáticas y ciencias naturales"
                                          ].map((e, i) => {
                                            return (
                                              <option value={e} key={i}>
                                                {e}
                                              </option>
                                            );
                                          })}
                                        </select>
                                      </div>
                                    ))}
                                  </div>
                                ) : form3.SeleccionaCarrera === "Otro" ? (
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "column",
                                      justifyContent: "flex-start",
                                      alignItems: "center",
                                    }}
                                  >
                                    {mapId.map((elem) => (
                                      <div
                                        style={{ marginBottom: "1%" }}
                                        key={elem}
                                      >
                                        <select
                                          name="Otro"
                                          defaultValue=""
                                          onChange={(e) => {
                                            if (form3.habilidadesRequeridas.includes(e.target.value)) {
                                              e.target.value = ""
                                              return alert("Ya seleccionaste esta habilidad")
                                            } else {
                                              return setform3({
                                                ...form3,
                                                habilidadesRequeridas: [
                                                  ...form3.habilidadesRequeridas,
                                                  e.target.value,
                                                ],
                                              })
                                            }
                                          }
                                          }
                                        >
                                          {abilities["Otro"].map((e, i) => {
                                            return (
                                              <option value={e} key={i}>
                                                {e}
                                              </option>
                                            );
                                          })}
                                        </select>
                                      </div>
                                    ))}
                                  </div>
                                ) : null}
                              </>
                            }
                            <ChipInput onChange={(chips) => handleSkillsChange(chips)}
                              onDelete={(chip, index) => handleSkillsDeleteChip(chip, index)}
                              style={{ width: '100%' }}
                              placeholder={lang === 'es' ? 'introduce una habilidad y luego enter!' : 'introduce a skill and then enter!'}
                              fullWidthInput={true} disableUnderline={false} alwaysShowPlaceholder={false}
                              allowDuplicates={false}
                              disabled={form3.habilidadesRequeridas.length >= 5 ? true : false}
                              defaultValue={lang === 'es' ? ['Trabajo en equipo', 'Gestión', 'Oratoria', 'Eficiencia'] : ['Teamwork', 'Management', 'Speaking', 'Efficiency']} />
                          </div>
                        </div>
                      </div>
                      <Navegador topform={topform} disable={form3.habilidadesRequeridas.length>0?false:true} props={props} lang={lang} estadoactual={estadoactual} form3={form3} detalle={detalle} setestadoactual={setestadoactual} emailvalidado={emailvalidado} settopform={settopform}></Navegador>

                    </div>

                    {/* //    ¿Que modalidad de contratación deseas? */}
                    <div style={{ width: "100%", height: "100%" }}>
                      <div
                        style={{
                          width: "100%",
                          height: "85%",
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <div className="col-two-create-project">
                          <div className="tittle-section-question">
                            <h4 className="tittle-question-create-project">
                              {lang === 'en' ? "What is your estimate for the duration of the opportunity?" : 'Cuál es tu estimado de duración de la oportunidad'}
                            </h4>
                          </div>
                          <div className="formulariocrear">
                            <>
                              {estimado === "Otro" ? (
                                <input
                                  className="input_create_project_new_form mediumfont"
                                  style={{ border: "solid 1px #c4c4c4" }}
                                  type="text"
                                  name="estimadoDuración"
                                  onChange={cambioform3}
                                  placeholder="Otro..."
                                />
                              ) : (
                                <Select
                                  value={
                                    form3.estimadoDuración !== ""
                                      ? form3.estimadoDuración
                                      : "3"
                                  }
                                  style={{ marginTop: "9% !important" }}
                                  name="estimadoDuración"
                                  className="select-create-project"
                                  onChange={(e) => {
                                    cambioform3(e);
                                    e.target.value === "Otro" &&
                                      setestimado("Otro");
                                  }}
                                >
                                  <MenuItem disabled="true" value="3">
                                    {
                                      lang === 'en' ? <em className="mediumfont">Choose an option</em> :
                                        <em className="mediumfont">Elige tu respuesta</em>
                                    }
                                  </MenuItem>
                                  <MenuItem
                                    value="Indefinida"
                                    className="mediumfont"
                                  >
                                    {lang === 'en' ? "Indefinite" : "Indefinida"}
                                  </MenuItem>
                                  <MenuItem
                                    value="Un mes"
                                    className="mediumfont"
                                  >
                                    {lang === 'en' ? "1 month" : "1 mes"}
                                  </MenuItem>
                                  <MenuItem value="Otro" className="mediumfont">
                                    {lang === 'en' ? "Other" : "Otro"}
                                  </MenuItem>
                                </Select>
                              )}
                            </>
                          </div>
                        </div>
                      </div>
                      <Navegador topform={topform} disable={false} props={props} lang={lang} estadoactual={estadoactual} form3={form3} detalle={detalle} setestadoactual={setestadoactual} emailvalidado={emailvalidado} settopform={settopform}></Navegador>

                    </div>
                    {/* link              */}
                    <div style={{ width: "100%", height: "100%" }}>
                      <div
                        style={{
                          width: "100%",
                          height: "85%",
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <div className="col-two-create-project">
                          <div className="tittle-section-question">
                            <h4 className="tittle-question-create-project">

                              {lang === 'en' ? "If your opportunity rests on an external platform put the link here" : 'Si tu oportunidad reposa en una plataforma externa pon el link aquí'}
                            </h4>
                          </div>
                          <div className="formulariocrear">
                            {
                              <>
                                <input
                                  className="input_create_project_new_form mediumfont"
                                  style={{ border: "solid 1px #c4c4c4" }}
                                  type="text"
                                  name="link"
                                  placeholder="NA"
                                  onChange={cambioform3}
                                />
                              </>
                            }
                          </div>
                        </div>
                      </div>
                      <Navegador topform={topform} disable={false} props={props} lang={lang} estadoactual={estadoactual} form3={form3} detalle={detalle} setestadoactual={setestadoactual} emailvalidado={emailvalidado} settopform={settopform}></Navegador>

                    </div>
                    {/* //    Que modalidad de pago prefieres */}
                    {form3.modalidadDeContratacion === "Por proyecto" && (
                      <div style={{ width: "100%", height: "100%" }}>
                        <div
                          style={{
                            width: "100%",
                            height: "85%",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <div className="col-two-create-project">
                            <div className="tittle-section-question">
                              <h4 className="tittle-question-create-project">
                                {lang === 'en' ? 'Which payment method do you prefer' : 'Que modalidad de pago prefieres'}
                              </h4>
                            </div>
                            <div className="formulariocrear">
                              <Select
                                value={
                                  form3.modalidadPago !== ""
                                    ? form3.modalidadPago
                                    : "3"
                                }
                                style={{ marginTop: "9% !important" }}
                                name="modalidadPago"
                                className="select-create-project"
                                onChange={cambioform3}
                              >
                                <MenuItem disabled="true" value="3">
                                  <em className="mediumfont">
                                    {lang === 'en' ? 'Choose and option' : 'Elige tu respuesta'}
                                  </em>
                                </MenuItem>
                                <MenuItem
                                  value="Por horas"
                                  className="mediumfont"
                                >
                                  Por horas
                                </MenuItem>
                                <MenuItem
                                  value="Un solo pago"
                                  className="mediumfont"
                                >
                                  Un solo pago
                                </MenuItem>
                              </Select>
                            </div>
                          </div>
                        </div>       
                                       <Navegador topform={topform} disable={false} props={props} lang={lang} estadoactual={estadoactual} form3={form3} detalle={detalle} setestadoactual={setestadoactual} emailvalidado={emailvalidado} settopform={settopform}></Navegador>

                      </div>
                    )}
                    {/* Escribe el presupuesto (mínimo) que tienes para el proyecto/ rol?             */}
                    <div style={{ width: "100%", height: "100%" }}>
                      <div
                        style={{
                          width: "100%",
                          height: "85%",
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <div className="col-two-create-project">
                          <div className="tittle-section-question">
                            <h4 className="tittle-question-create-project">
                              {
                                lang === 'es' ? 'Presupuesto para tu oportunidad' : 'Budget for your opportunity'
                              }
                            </h4>
                          </div>
                          <div className="formulariocrear">
                            {

                              <>
                                <select style={{ margin: '1em', fontSize: '14px' }} onClick={e => handleChangeMoneda(e)}>
                                  <option>Moneda</option>
                                  <option value={"u$s"}>USD</option>
                                  <option value={'$'}>COP</option>
                                </select>
                                <input
                                  className="input_create_project_new_form mediumfont"
                                  style={{ border: "solid 1px #c4c4c4" }}
                                  type="text"
                                  onKeyPress={(e) => {
                                    if (!/[0-9]/.test(e.key)) {
                                      e.preventDefault();
                                    }
                                  }}
                                  value={presupuesto.pres_min}
                                  placeholder="min:10.000"
                                  name="pres_min"
                                  onChange={(e) => {
                                    handlePres(e);
                                  }}
                                />
                                <input
                                  className="input_create_project_new_form mediumfont"
                                  style={{ border: "solid 1px #c4c4c4" }}
                                  type="text"
                                  onKeyPress={(e) => {
                                    if (!/[0-9]/.test(e.key)) {
                                      e.preventDefault();
                                    }
                                  }}
                                  value={presupuesto.pres_max}
                                  placeholder="max:400.000.000"
                                  name="pres_max"
                                  onChange={(e) => {
                                    handlePres(e);
                                  }}
                                />
                                <div style={{ fontSize: '12px', color: 'red', margin: '1em' }}>
                                  {error.max && <span >{error.max}</span>}
                                </div>
                              </>
                            }
                          </div>
                        </div>
                      </div>
                      <Navegador topform={topform} disable={false} props={props} lang={lang} estadoactual={estadoactual} form3={form3} detalle={detalle} setestadoactual={setestadoactual} emailvalidado={emailvalidado} settopform={settopform}></Navegador>
</div>

                    {/* Modalidad de pago  */}
                    <div style={{ width: "100%", height: "100%", "paddingTop": "40px" }}>
                      <div
                        style={{
                          width: "100%",
                          height: "85%",
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        {usuario.nombre_paquete === "Paquete Free" && <h4 className="tittle-question-create-project">
                          {
                            lang === 'en' ? 'Select the payment method' : 'Selecciona la modalidad de pago'
                          }
                        </h4>}
                        {usuario.nombre_paquete === "Paquete Free" && <div style={{
                          width: "100%",
                          height: "100%",
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "center",
                          alignItems: "center",
                        }}>
                          <div style={{ width: '200px', height: '200px', backgroundColor: '#f6f6f6' }}>
                            <div style={{ height: '20%', backgroundColor: '#202256', display: 'flex', alignItems: 'center' }}>
                              <h3 style={{ color: 'white', fontFamily: 'Poppins', marginLeft: '70px' }}>Gratis </h3>
                            </div>
                            <button className="button_create_project" onClick={() => setPlan('gratis')} style={{ backgroundColor: select === 'gratis' && 'blue', marginTop: '4em' }}>seleccionar</button>
                          </div>
                          <div style={{ width: '200px', height: '200px', backgroundColor: '#f6f6f6', marginLeft: '20px', marginRight: '20px' }}>
                            <div style={{ height: '20%', backgroundColor: '#202256', display: 'flex', alignItems: 'center' }}>
                              <h3 style={{ color: 'white', fontFamily: 'Poppins', marginLeft: '40px' }}>Pago Básico </h3>
                            </div>
                            <button className="button_create_project" onClick={() => setPlan('basico')} style={{ backgroundColor: select === 'basico' && 'blue', marginTop: '4em' }}>seleccionar</button>
                          </div>
                          <div style={{ width: '200px', height: '200px', backgroundColor: '#f6f6f6' }}>
                            <div style={{ height: '20%', backgroundColor: '#202256', display: 'flex', alignItems: 'center' }}>
                              <h3 style={{ color: 'white', fontFamily: 'Poppins', marginLeft: '30px' }}>Pago Premium </h3>
                            </div>
                            <button className="button_create_project" onClick={() => setPlan('premium')} style={{ backgroundColor: select === 'premium' && 'blue', marginTop: '4em' }}>seleccionar</button>
                          </div>
                        </div>}
                        {
                          <div className="button_div_create_project">
                            {usuario.nombre_paquete !== 'Paquete Free' ?
                              <button
                                className="button_create_project"
                                onClick={(e) => {
                                  handleSubmitCreate(e);
                                }}
                              >
                                {<span>Crear Oportunidad</span>}
                              </button> :
                              select !== '' ? (
                                <button
                                  className="button_create_project"
                                  onClick={(e) => {
                                    handleSubmitCreate(e);
                                  }}
                                >
                                  {<span>Crear Oportunidad</span>}
                                </button>
                              ) : (
                                <button
                                  className="button_create_project"
                                  style={{ background: "#888c88" }}
                                  disabled
                                >
                                  {<span>Crear Oportunidad</span>}
                                </button>
                              )}
                          </div>
                        }
                        {/* <div className="col-one-create-project">
                          {!idp && (
                            <>
                              <div className="tittle-section-question">
                                <h4 className="tittle-question-create-project">
                                  {
                                    lang === 'en' ? 'Payment method' : 'Modalidad de pago'
                                  }
                                </h4>
                              </div>
                              <Select
                                value={form3.modalidaddepago}
                                onChange={cambioform3}
                                name="modalidaddepago"
                              >
                                <MenuItem
                                  value="personalizada"
                                  onClick={() =>
                                    setstatusProyecto("personalizada")
                                  }
                                >
                                  {
                                    <Translate
                                      content="Crearproyectoahoraypagardespués"
                                      component="span"
                                    />
                                  }
                                </MenuItem>
                                <MenuItem
                                  value={"Pagar con wenjoy"}
                                  onClick={(e) => {
                                    handleSubmitCreatewenjoy(e);
                                  }}
                                >
                                  {
                                    <Translate
                                      content="Pagarconwenjoy"
                                      component="span"
                                    />
                                  }
                                </MenuItem>
                              </Select>
                            </>
                          )}
                          {statusProyecto === "personalizada" ? (
                            <div className="button_div_create_project">
                              {!cargando ? (
                                <button
                                  className="button_create_project"
                                  onClick={(e) => {
                                    handleSubmitCreate(e);
                                  }}
                                >
                                  {
                                    <Translate
                                      content="Crearproyecto"
                                      component="span"
                                    />
                                  }
                                </button>
                              ) : (
                                <button
                                  className="button_create_project"
                                  style={{ background: "#888c88" }}
                                  disabled
                                >
                                  {
                                    <Translate
                                      content="Crearproyecto"
                                      component="span"
                                    />
                                  }
                                </button>
                              )}
                            </div>
                          ) : idp ? (
                            <div className="button_div_create_project">
                              {!cargando ? (
                                <button
                                  className="button_create_project"
                                  onClick={(e) => {
                                    handleSubmitCreate(e);
                                  }}
                                >
                                  {<span>Modificar Proyecto</span>}
                                </button>
                              ) : (
                                <button
                                  className="button_create_project"
                                  style={{ background: "#888c88" }}
                                  disabled
                                >
                                  {<span>Modificar Proyecto</span>}
                                </button>
                              )}
                            </div>
                          ) : null}
                        </div> */}
                      </div>
                      <Navegador topform={topform} disable={form3.modalidaddepago ==="Pagar con wenjoy"?false:true} props={props} lang={lang} estadoactual={estadoactual} form3={form3} detalle={detalle} setestadoactual={setestadoactual} emailvalidado={emailvalidado} settopform={settopform}></Navegador>
</div>
                    {/* Modalidad de pago  */}
                    <div
                      style={
                        statusProyecto === "wenjoy"
                          ? {
                            position: "relative",
                            display: "flex",
                            justifyContent: "center",
                            flexDirection: "column",
                            width: "100%",
                            height: "100%",
                          }
                          : {
                            position: "absolute",
                            zIndex: "-1",
                            opacity: "0",
                            top: "0",
                          }
                      }
                    >
                      <div
                        style={{
                          width: "100%",
                          height: "85%",
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <div
                          className="select-area-create-project"
                          style={{
                            display: "flex",
                            justifyContent: "space-around",
                            alignItems: "center",
                          }}
                        >
                          <div className="col-one-create-project">
                            <div className="tittle-section-question">
                              <h4 className="tittle-question-create-project">
                                {
                                  lang === 'en' ? 'Enter your name' : 'Ingresa tu nombre'
                                }
                              </h4>
                            </div>

                            <input
                              className="input_create_project_new_form"
                              type="text"
                              placeholder={"Nombre"}
                              name="comentario"
                              onChange={(w) =>
                                document
                                  .getElementById("wenjoy-checkout-button")
                                  .setAttribute(
                                    "data-owner-first-name",
                                    w.target.value
                                  )
                              }
                            />
                          </div>
                          <div className="col-two-create-project">
                            <div className="tittle-section-question">
                              <h4 className="tittle-question-create-project">
                                Ingresa tu apellido
                              </h4>
                            </div>

                            <input
                              className="input_create_project_new_form"
                              type="text"
                              placeholder={"Apellido"}
                              name="comentario"
                              onChange={(w) =>
                                document
                                  .getElementById("wenjoy-checkout-button")
                                  .setAttribute(
                                    "data-owner-last-name",
                                    w.target.value
                                  )
                              }
                            />
                          </div>
                        </div>
                      </div>
                      <Navegador topform={topform} disable={false} props={props} lang={lang} estadoactual={estadoactual} form3={form3} detalle={detalle} setestadoactual={setestadoactual} emailvalidado={emailvalidado} settopform={settopform}></Navegador>
</div>
                    {/* Modalidad de pago  */}
                    <div
                      style={
                        statusProyecto === "wenjoy"
                          ? {
                            position: "relative",
                            display: "flex",
                            justifyContent: "center",
                            flexDirection: "column",
                            width: "100%",
                            height: "100%",
                          }
                          : {
                            position: "absolute",
                            zIndex: "-1",
                            opacity: "0",
                            top: "0",
                          }
                      }
                    >
                      <div
                        style={{
                          width: "100%",
                          height: "85%",
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <div
                          className="select-area-create-project"
                          style={{
                            display: "flex",
                            justifyContent: "space-around",
                            alignItems: "center",
                          }}
                        >
                          <div className="col-one-create-project">
                            <div className="tittle-section-question">
                              <h4 className="tittle-question-create-project">
                                {
                                  lang === 'en' ? 'Enter your ID number' : 'Ingresa tu Cédula de Ciudadanía'
                                }
                              </h4>
                            </div>

                            <input
                              className="input_create_project_new_form"
                              type="text"
                              placeholder={"Cédula de Ciudadanía"}
                              name="comentario"
                              onChange={(w) =>
                                document
                                  .getElementById("wenjoy-checkout-button")
                                  .setAttribute(
                                    "data-owner-document",
                                    w.target.value
                                  )
                              }
                            />
                          </div>
                          <div className="col-two-create-project">
                            <div className="tittle-section-question">
                              <h4 className="tittle-question-create-project">
                                {
                                  lang === 'en' ? 'Enter your phone number' : 'Ingresa tu número de teléfono'
                                }
                              </h4>
                            </div>

                            <input
                              className="input_create_project_new_form"
                              type="text"
                              placeholder={"Telefono"}
                              name="comentario"
                              onChange={(w) =>
                                document
                                  .getElementById("wenjoy-checkout-button")
                                  .setAttribute(
                                    "data-owner-cellphone",
                                    w.target.value
                                  )
                              }
                            />
                          </div>
                        </div>
                      </div>
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <div style={{ position: "relative" }}>
                          {
                            <button
                              className="button_create_project"
                              style={{ margin: "0", cursor: "pointer" }}
                            >
                              {
                                lang === 'en' ? 'Pay with Wenjoy' : 'Pagar con Wenjoy'
                              }
                            </button>
                          }
                          <form
                            id="idform"
                            style={{
                              opacity: "0",
                              position: "absolute",
                              height: "100%",
                              width: "100%",
                              top: "0",
                            }}
                          >
                            <ScriptTag
                              id="wenjoy-checkout-button"
                              src="https://s3.amazonaws.com/wenjoy/wenjoy-checkout/wenjoy-checkout.min.js"
                              data-total-value="25000"
                              data-description="coally"
                              data-api-key="vPMHVpCKus3VCdPfFTx88KLhRMPy2OpI"
                              data-verify="false"
                              data-test="false"
                              data-confirmation-url="https://findally-backend.herokuapp.com/api/Project/create/user/wenjoy/"
                              data-owner-email="cambiar@gmail.com"
                              data-owner-first-name="cambiar"
                              data-owner-last-name="cambiar"
                              data-owner-cellphone="1234567891"
                              data-owner-document="11"
                              data-owner-document-type="1"
                              data-minutes="60"
                              data-response-url="http://findally.com.co.s3-website-us-east-1.amazonaws.com/company/project"
                            ></ScriptTag>
                          </form>
                        </div>
                      </div>
                    </div>

                    {form3.modalidaddepago === "Pagar con Tpaga" && (
                      <div
                        style={{
                          width: "100%",
                          marginLeft: "5%",
                          flexDirection: "column",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <QRCode fgColor="#2a2b70" value={ypagaurl} />

                        <button
                          onClick={() => comprobarEstadoTpaga()}
                          className="project__card-actions-apply btn-more-pro mediumfont"
                          style={{ marginTop: "2%", color: "white" }}
                        >
                          Confirmar pago
                        </button>
                      </div>
                    )}
                  </>
                )}
                {form3.tipoDeServicioDeseado ===
                  "Outsourcing (BPO)- Personalized services" && (
                    <>
                      {/* // ¿Que modalidad de contratación deseas? */}
                      <div style={{ width: "100%", height: "100%" }}>
                        <div
                          style={{
                            width: "100%",
                            height: "85%",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <div className="col-two-create-project">
                            <div className="tittle-section-question">
                              <h4 className="tittle-question-create-project">
                                {lang === 'en' ? 'What type of contract would you like?' : '¿Qué modalidad de contratación deseas?'}
                              </h4>
                            </div>
                            <div className="formulariocrear">
                              <>
                                {modalidadotro === "Otro" ? (
                                  <input
                                    className="input_create_project_new_form mediumfont"
                                    style={{ border: "solid 1px #c4c4c4" }}
                                    type="text"
                                    name="modalidadDeContratacion"
                                    onChange={cambioform3}
                                    placeholder="Otro..."
                                  />
                                ) : (
                                  <Select
                                    value={
                                      form3.modalidadDeContratacion !== ""
                                        ? form3.modalidadDeContratacion
                                        : "3"
                                    }
                                    style={{ marginTop: "9% !important" }}
                                    name="modalidadDeContratacion"
                                    className="select-create-project"
                                    onChange={(e) => {
                                      cambioform3(e);
                                      e.target.value === "Otro" &&
                                        setmodalidadotro(e.target.value);
                                    }}
                                  >
                                    <MenuItem
                                      value="Término indefinido (tiempo completo)"
                                      className="mediumfont"
                                    >
                                      {lang === 'en' ? "Indefinite term (full time)" : "Término indefinido (tiempo completo)"}
                                    </MenuItem>
                                    <MenuItem
                                      value="Término indefinido (tiempo Parcial)"
                                      className="mediumfont"
                                    >
                                      {lang === 'en' ? "Indefinite term (Part time)" : "Término indefinido (tiempo Parcial)"}
                                    </MenuItem>
                                    <MenuItem
                                      value="Término Fijo  (tiempo completo)"
                                      className="mediumfont"
                                    >
                                      {lang === 'en' ? 'Fixed Term (full time)' : 'Término Fijo  (tiempo completo)'}
                                    </MenuItem>
                                    <MenuItem
                                      value="Término Fijo  (tiempo parcial)"
                                      className="mediumfont"
                                    >
                                      {lang === 'en' ? 'Fixed Term (part time)' : 'Término Fijo  (tiempo parcial)'}
                                    </MenuItem>
                                    <MenuItem
                                      value="Proyecto"
                                      className="mediumfont"
                                    >
                                      {lang === 'en' ? 'Project' : 'Proyecto'}
                                    </MenuItem>
                                    <MenuItem value="Contratista prestación de servicios" className="mediumfont">
                                      {lang === 'en' ? 'Contractor provision of services' : 'Contratista prestación de servicios'}
                                    </MenuItem>
                                    <MenuItem value="Contrato por Hora o labor" className="mediumfont">
                                      {lang === 'en' ? 'Hourly contract or work' : 'Contrato por Hora o labor'}
                                    </MenuItem>
                                  </Select>
                                )}
                              </>
                            </div>
                          </div>
                        </div>
                        <Navegador topform={topform} disable={form3.modalidadDeContratacion.length>0?false:true} props={props} lang={lang} estadoactual={estadoactual} form3={form3} detalle={detalle} setestadoactual={setestadoactual} emailvalidado={emailvalidado} settopform={settopform}></Navegador>
</div>
                      {/* //    Deseas publicar de manera anónima */}
                      <div style={{ width: "100%", height: "100%" }}>
                        <div
                          style={{
                            width: "100%",
                            height: "85%",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <div className="col-two-create-project">
                            <div className="tittle-section-question">
                              <h4 className="tittle-question-create-project">
                                {lang === 'en' ? 'Do you want to publish anonymously?' : '¿Deseas publicar de manera anónima?'}
                              </h4>
                            </div>
                            <div className="formulariocrear">
                              <Select
                                value={form3.Anonimo !== "" ? form3.Anonimo : "3"}
                                style={{ marginTop: "9% !important" }}
                                name="Anonimo"
                                className="select-create-project"
                                onChange={cambioform3}
                              >
                                <MenuItem disabled="true" value="3">
                                  {
                                    lang === 'en' ? <em className="mediumfont">Choose an option</em> :
                                      <em className="mediumfont">Elige tu respuesta</em>
                                  }
                                </MenuItem>
                                <MenuItem value="Si" className="mediumfont">
                                  {lang === 'en' ? 'Yes' : 'Si'}
                                </MenuItem>
                                <MenuItem value="No" className="mediumfont">
                                  No
                                </MenuItem>
                              </Select>
                            </div>
                          </div>
                        </div>
                        <Navegador topform={topform} disable={false} props={props} lang={lang} estadoactual={estadoactual} form3={form3} detalle={detalle} setestadoactual={setestadoactual} emailvalidado={emailvalidado} settopform={settopform}></Navegador>
</div>

                      {/*//     Ingrese nombre de la empresa */}
                      {/* {form3.Anonimo === "No" && (
                        <div style={{ width: "100%", height: "100%" }}>
                          <div
                            style={{
                              width: "100%",
                              height: "100%",
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <div className="col-two-create-project">
                              <div className="tittle-section-question">
                                <h4 className="tittle-question-create-project">
                                  {
                                    lang === 'en' ? 'Enter the name of the company' : 'Ingrese el nombre de la empresa'
                                  }
                                </h4>
                              </div>
                              <div className="formulariocrear">
                                {
                                  <>
                                    <input
                                      className="input_create_project_new_form mediumfont"
                                      style={{ border: "solid 1px #c4c4c4" }}
                                      type="text"
                                      name="NombreEmpresa"
                                      onChange={cambioform3}
                                    />
                                  </>
                                }
                              </div>
                            </div>
                          </div>
                        </div>
                      )} */}
                      {/* Ingresa tu Email              */}
                      {!props.user.email && (
                        <div style={{ width: "100%", height: "100%" }}>
                          <div
                            style={{
                              width: "100%",
                              height: "85%",
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <div className="col-two-create-project">
                              <div className="tittle-section-question">
                                <h4 className="tittle-question-create-project">
                                  {
                                    lang === 'en' ? 'Enter your email' : 'Ingresa tu email'
                                  }
                                </h4>
                              </div>
                              <div className="formulariocrear">
                                {
                                  <>
                                    <input
                                      className="input_create_project_new_form mediumfont"
                                      style={{ border: "solid 1px #c4c4c4" }}
                                      type="text"
                                      name="Email"
                                      onChange={cambioform3}
                                    />
                                  </>
                                }
                              </div>
                            </div>
                          </div>
                          <Navegador topform={topform} disable={false} props={props} lang={lang} estadoactual={estadoactual} form3={form3} detalle={detalle} setestadoactual={setestadoactual} emailvalidado={emailvalidado} settopform={settopform}></Navegador>
</div>
                      )}
                      {/* Suba su ID/RUT              */}
                      {/* Nombre de la oportunidad, cargo o proyecto */}
                      <div style={{ width: "100%", height: "100%" }}>
                        <div
                          style={{
                            width: "100%",
                            height: "85%",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <div className="col-two-create-project">
                            <div className="tittle-section-question">
                              {
                                lang === 'en' ?
                                  <h4 className="tittle-question-create-project">Name of opportunity, position or
                                    project</h4> :
                                  <h4 className="tittle-question-create-project">Nombre de la oportunidad, cargo o
                                    proyecto</h4>
                              }
                            </div>
                            <div className="formulariocrear">
                              {
                                <>
                                  <input
                                    className="input_create_project_new_form mediumfont"
                                    style={{ border: "solid 1px #c4c4c4" }}
                                    type="text"
                                    name="NombreOportunidad"
                                    onChange={cambioform3}
                                  />
                                </>
                              }
                            </div>
                          </div>
                        </div>
                        <Navegador topform={topform} disable={false} props={props} lang={lang} estadoactual={estadoactual} form3={form3} detalle={detalle} setestadoactual={setestadoactual} emailvalidado={emailvalidado} settopform={settopform}></Navegador>
</div>
                      {/* Cuantos profesionales deseas contratar?             */}
                      <div style={{ width: "100%", height: "100%" }}>
                        <div
                          style={{
                            width: "100%",
                            height: "85%",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <div className="col-two-create-project">
                            <div className="tittle-section-question">
                              {
                                lang === 'en' ?
                                  <h4 className="tittle-question-create-project">How many professionals do you want to
                                    hire?</h4> :
                                  <h4 className="tittle-question-create-project">Cuantos profesionales deseas
                                    contratar?</h4>
                              }
                            </div>
                            <div className="formulariocrear">
                              {
                                <>
                                  <input
                                    className="input_create_project_new_form mediumfont"
                                    style={{ border: "solid 1px #c4c4c4" }}
                                    type="text"
                                    name="profesionales"
                                    onChange={cambioform3}
                                  />
                                </>
                              }
                            </div>
                          </div>
                        </div>
                        <Navegador topform={topform} disable={false} props={props} lang={lang} estadoactual={estadoactual} form3={form3} detalle={detalle} setestadoactual={setestadoactual} emailvalidado={emailvalidado} settopform={settopform}></Navegador>
</div>
                      {/* Describe el proyecto/rol: Describe el detalle de la oportunidad/rol/proyecto. Si lo deseas puedes enviar una nota de voz con los requerimientos */}
                      <div style={{ width: "100%", height: "100%" }}>
                        <div
                          style={{
                            width: "100%",
                            height: "85%",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <div
                            className="col-two-create-project"
                            style={{ width: "80%" }}
                          >
                            <div className="tittle-section-question">
                              {
                                lang === 'en' ?
                                  <h4 className="tittle-question-create-project">Describe the project/role: Describe the
                                    detail of the opportunity/role/project. If you want you can send a voice note with
                                    the requirements</h4> :
                                  <h4 className="tittle-question-create-project">Describe el proyecto/rol: Describe el
                                    detalle de la oportunidad/rol/proyecto. Si lo deseas puedes enviar una nota de voz
                                    con los requerimientos</h4>
                              }
                            </div>
                            <div
                              className="formulariocrear"
                              style={{ height: "100%" }}
                            >
                              {
                                <div style={{ display: "flex", height: "100%" }}>
                                  <textarea
                                    className="input_create_project_new_form23 mediumfont"
                                    style={{
                                      border: "solid 1px #c4c4c4",
                                      width: "60%",
                                      resize: "none",
                                    }}
                                    type="text"
                                    name="DescribeProyecto"
                                    onChange={cambioform3}
                                  />
                                  <div
                                    style={{
                                      width: "50%",
                                      display: "flex",
                                      flexDirection: "column",
                                      justifyContent: "center",
                                      marginLeft: "1%",
                                    }}
                                  >
                                    <div className="formulariocrear">
                                      <button
                                        id="1a"
                                        style={{
                                          display: "flex",
                                          width: "100%",
                                          background: "#e8e9eb",
                                          border: "none",
                                          color: "#696a7c",
                                          cursor: "pointer",
                                          justifyContent: "right",
                                        }}
                                        onClick={() => startRecording(1)}
                                      >
                                        <p
                                          style={{ cursor: "pointer" }}
                                          className="mediumfont"
                                        >

                                          {lang === 'en' ? 'Record voice note' : 'Grabar Audio'}
                                        </p>
                                        <MicIcon
                                          style={{ cursor: "pointer" }}
                                          className="mediumfont"
                                        ></MicIcon>
                                      </button>
                                      <button
                                        id="1b"
                                        style={{
                                          display: "none",
                                          width: "100%",
                                          position: "relative",
                                          background: "#e8e9eb",
                                          border: "none",
                                          justifyContent: "right",
                                        }}
                                        onClick={() => stopRecording(1)}
                                        className="mediumfont"
                                      >
                                        <div className="audioclase">
                                          <p
                                            style={{
                                              color: "#f33e16",
                                              cursor: "pointer",
                                            }}
                                            className="mediumfont"
                                          >
                                            {lang === 'en' ? 'Recording voice note' : 'Grabando Audio'}
                                          </p>
                                          <GraphicEqIcon
                                            style={{
                                              color: "#f33e16",
                                              cursor: "pointer",
                                            }}
                                            className="mediumfont"
                                          ></GraphicEqIcon>
                                        </div>
                                        <div className="audioclasehover">
                                          <p
                                            style={{
                                              color: "#1bb96d",
                                              cursor: "pointer",
                                            }}
                                            className="mediumfont"
                                          >
                                            {lang === 'en' ? 'End voice note' : 'Terminar Audio'}
                                          </p>
                                          <StopIcon
                                            style={{
                                              color: "#1bb96d",
                                              cursor: "pointer",
                                            }}
                                            className="mediumfont"
                                          ></StopIcon>
                                        </div>
                                      </button>
                                    </div>
                                    <li
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                      }}
                                    >
                                      <audio
                                        id="mirar1"
                                        src=""
                                        autoplay
                                        preload="auto"
                                        controls
                                        className="mediumfont audioo"
                                      ></audio>
                                      <DeleteIcon
                                        className="mediumfont"
                                        onClick={() =>
                                        (document.getElementById("mirar1").src =
                                          "")
                                        }
                                        style={{ margin: "0", cursor: "pointer" }}
                                      ></DeleteIcon>
                                    </li>
                                  </div>
                                </div>
                              }
                            </div>
                          </div>
                        </div>
                        <Navegador topform={topform} disable={form3.DescribeProyecto.length>0?false:true} props={props} lang={lang} estadoactual={estadoactual} form3={form3} detalle={detalle} setestadoactual={setestadoactual} emailvalidado={emailvalidado} settopform={settopform}></Navegador>
</div>

                      {/* Selecciona la carrera del profesional/área de conocimiento  */}
                      <div style={{ width: "100%", height: "100%" }}>
                        <div
                          style={{
                            width: "100%",
                            height: "85%",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <div className="col-one-create-project">
                            <div className="tittle-section-question">
                              <h4 className="tittle-question-create-project">
                                {
                                  <Translate
                                    className="mediumfont"
                                    content="Seleccioneelareadeconocimientorequeridasegunsusrequerimientos"
                                    component="span"
                                  />
                                }
                              </h4>
                            </div>

                            {otroCausa !== "otro" ? (
                              <div className="formulariocrear">
                                <Select
                                  value={form3.SeleccionaCarrera}
                                  onChange={cambioform3}
                                  name="SeleccionaCarrera"
                                >
                                  <MenuItem value="Agronomía, Veterinaria y afines">
                                    {
                                      <Translate
                                        className="mediumfont"
                                        content="AgronomíaVeterinariayafines"
                                        component="span"
                                      />
                                    }
                                  </MenuItem>
                                  <MenuItem value="Bellas Artes">
                                    {
                                      <Translate
                                        className="mediumfont"
                                        content="BellasArtes"
                                        component="span"
                                      />
                                    }
                                  </MenuItem>
                                  <MenuItem value="Ciencias de la Educación">
                                    {
                                      <Translate
                                        className="mediumfont"
                                        content="CienciasdelaEducación"
                                        component="span"
                                      />
                                    }
                                  </MenuItem>
                                  <MenuItem value="Ciencias de la Salud">
                                    {
                                      <Translate
                                        className="mediumfont"
                                        content="CienciasdelaSalud"
                                        component="span"
                                      />
                                    }
                                  </MenuItem>
                                  <MenuItem value="Ciencias Sociales y Humanas">
                                    {
                                      <Translate
                                        className="mediumfont"
                                        content="CienciasSocialesyHumanas"
                                        component="span"
                                      />
                                    }
                                  </MenuItem>
                                  <MenuItem value="Economía, Administración, Contaduría y afines">
                                    {
                                      <Translate
                                        className="mediumfont"
                                        content="EconomíaAdministraciónContaduríayafines"
                                        component="span"
                                      />
                                    }
                                  </MenuItem>
                                  <MenuItem value="Ingeniería, Arquitectura, Urbanismo y afines">
                                    {
                                      <Translate
                                        className="mediumfont"
                                        content="IngenieríaArquitecturaUrbanismoyafines"
                                        component="span"
                                      />
                                    }
                                  </MenuItem>
                                  <MenuItem value="Matemáticas y Ciencias Naturales">
                                    {
                                      <Translate
                                        className="mediumfont"
                                        content="MatemáticasyCienciasNaturales"
                                        component="span"
                                      />
                                    }
                                  </MenuItem>
                                  <MenuItem
                                    value={otroCausa}
                                    onClick={() => setotroCausa("otro")}
                                  >
                                    {
                                      <Translate
                                        className="mediumfont"
                                        content="Otro"
                                        component="span"
                                      />
                                    }
                                  </MenuItem>
                                  <MenuItem value="NA" className="mediumfont">
                                    NA
                                  </MenuItem>
                                </Select>
                              </div>
                            ) : (
                              // <input
                              //   className="input_create_project_new_form"
                              //   type="text"
                              //   name="SeleccionaCarrera"
                              //   onChange={cambioform3}
                              // />
                              <ChipInput onChange={(chips) => handleChange(chips)}
                                onDelete={(chip, index) => handleDeleteChip(chip, index)}
                                style={{ width: '100%' }}
                                placeholder={lang === 'es' ? 'introduce una profesión y luego enter!' : 'introduce a profession and then enter!'}
                                fullWidthInput={true} disableUnderline={true} alwaysShowPlaceholder={false}
                                allowDuplicates={false}
                                defaultValue={lang === 'es' ? ['abogado', 'contador', 'letrado', 'procurador'] : ['lawyer', 'accountant', 'lawyer', 'solicitor']} />
                            )}
                          </div>
                        </div>
                        <Navegador topform={topform} disable={form3.SeleccionaCarrera.length>0?false:true} props={props} lang={lang} estadoactual={estadoactual} form3={form3} detalle={detalle} setestadoactual={setestadoactual} emailvalidado={emailvalidado} settopform={settopform}></Navegador>
 </div>
                      {/* Nivel de conocimiento (selección multiple) */}
                      <div style={{ width: "100%", height: "100%" }}>
                        <div
                          style={{
                            width: "100%",
                            height: "85%",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <div className="col-two-create-project">
                            <div className="tittle-section-question">
                              <h4 className="tittle-question-create-project">
                                {
                                  lang === 'en' ? <h4 className="tittle-question-create-project">
                                    Knowledge level (multiple choice)
                                  </h4> : <h4 className="tittle-question-create-project">
                                    Nivel de conocimiento (selección multiple)
                                  </h4>
                                }
                              </h4>
                            </div>
                            <div
                              className="formulariocrear"
                              style={{
                                display: "flex",
                                justifyContent: "space-around",
                              }}
                            >
                              {
                                <>
                                  {form3.Niveldeconocimiento.includes(
                                    "Profesional"
                                  ) ? (
                                    <button
                                      className="button_create_projectform2"
                                      style={{
                                        background: "#F07130",
                                        color: "white",
                                        margin: "0.3rem",
                                        border: "none",
                                      }}
                                      name="Niveldeconocimiento"
                                      value="Profesional"
                                      onClick={() =>
                                        setform3({
                                          ...form3,
                                          Niveldeconocimiento:
                                            form3.Niveldeconocimiento.filter(
                                              (e) => !e.includes("Profesional")
                                            ),
                                        })
                                      }
                                    >
                                      {lang === 'en' ? 'Professional' : 'Profesional'}
                                    </button>
                                  ) : (
                                    <button
                                      className="button_create_projectform2"
                                      style={{ margin: "0.3rem" }}
                                      name="Niveldeconocimiento"
                                      value="Profesional"
                                      onClick={() =>
                                        setform3({
                                          ...form3,
                                          Niveldeconocimiento: [
                                            ...form3.Niveldeconocimiento,
                                            "Profesional",
                                          ],
                                        })
                                      }
                                    >
                                      {lang === "en" ? "Professional" : "Profesional"}
                                    </button>
                                  )}
                                  {form3.Niveldeconocimiento.includes(
                                    "Técnico/tecnólogo"
                                  ) ? (
                                    <button
                                      className="button_create_projectform2"
                                      style={{
                                        background: "#F07130",
                                        color: "white",
                                        margin: "0.3rem",
                                        width: "fit-content",
                                        border: "none",
                                      }}
                                      name="Niveldeconocimiento"
                                      value="Técnico/tecnólogo"
                                      onClick={() =>
                                        setform3({
                                          ...form3,
                                          Niveldeconocimiento:
                                            form3.Niveldeconocimiento.filter(
                                              (e) =>
                                                !e.includes("Técnico/tecnólogo")
                                            ),
                                        })
                                      }
                                    >
                                      {lang === "en" ? "Technical" : "Técnico/tecnologo"}
                                    </button>
                                  ) : (
                                    <button
                                      className="button_create_projectform2"
                                      style={{
                                        margin: "0.3rem",
                                        width: "fit-content",
                                      }}
                                      name="Niveldeconocimiento"
                                      value="Técnico/tecnólogo"
                                      onClick={() =>
                                        setform3({
                                          ...form3,
                                          Niveldeconocimiento: [
                                            ...form3.Niveldeconocimiento,
                                            "Técnico/tecnólogo",
                                          ],
                                        })
                                      }
                                    >
                                      {lang === "en" ? "Technical" : "Técnico/tecnologo"}
                                    </button>
                                  )}
                                </>
                              }
                            </div>
                          </div>
                        </div>
                        <Navegador topform={topform} disable={form3.Niveldeconocimiento.length>0?false:true } props={props} lang={lang} estadoactual={estadoactual} form3={form3} detalle={detalle} setestadoactual={setestadoactual} emailvalidado={emailvalidado} settopform={settopform}></Navegador>
</div>
                      {/* Lenguaje (selección multiple) */}
                      <div style={{ width: "100%", height: "100%" }}>
                        <div
                          style={{
                            width: "100%",
                            height: "85%",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <div className="col-two-create-project">
                            <div className="tittle-section-question">
                              {
                                lang === 'en' ? <h4 className="tittle-question-create-project">
                                  Language (multiple choice)
                                </h4> : <h4 className="tittle-question-create-project">
                                  Lenguaje (selección multiple)</h4>
                              }
                            </div>
                            <div
                              className="formulariocrear"
                              style={{
                                display: "flex",
                                justifyContent: "space-around",
                              }}
                            >
                              {
                                <>
                                  {form3.Lenguaje.includes("Ingles") ? (
                                    <div
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                      }}
                                    >
                                      <div
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                        }}
                                      >
                                        <button
                                          className="button_create_projectform2"
                                          style={{
                                            background: "#F07130",
                                            color: "white",
                                            margin: "0.3rem",
                                            border: "none",
                                          }}
                                          name="Lenguaje"
                                          value="Ingles"
                                          onClick={() =>
                                            setform3({
                                              ...form3,
                                              Lenguaje: form3.Lenguaje.filter(
                                                (e) => !e.includes("Ingles")
                                              ),
                                            })
                                          }
                                        >
                                          {lang === "en" ? "English" : "Ingles"}
                                        </button>
                                      </div>
                                      <div
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                        }}
                                      >
                                        <select
                                          className="select_create_projectform"
                                          name="Nivel"
                                          defaultValue="Nivel"
                                          onChange={(e) =>
                                            setform3({
                                              ...form3,
                                              Nivel: e.target.value,
                                            })
                                          }
                                        >
                                          <option value="Nivel" disabled>
                                            {lang === "en" ? "Level" : "Nivel"}
                                          </option>
                                          <option value="A1">A1</option>
                                          <option value="A2">A2</option>
                                          <option value="B2">B1</option>
                                          <option value="B1">B2</option>
                                          <option value="C1">C1</option>
                                          <option value="C2">C2</option>
                                        </select>
                                      </div>
                                    </div>
                                  ) : (
                                    <button
                                      className="button_create_projectform2"
                                      style={{ margin: "0.3rem" }}
                                      name="Lenguaje"
                                      value="Ingles"
                                      onClick={() =>
                                        setform3({
                                          ...form3,
                                          Lenguaje: [...form3.Lenguaje, "Ingles"],
                                        })
                                      }
                                    >
                                      {lang === "en" ? "English" : "Ingles"}
                                    </button>
                                  )}
                                  {form3.Lenguaje.includes("Español") ? (
                                    <button
                                      className="button_create_projectform2"
                                      style={{
                                        background: "#F07130",
                                        color: "white",
                                        margin: "0.3rem",
                                        border: "none",
                                      }}
                                      name="Lenguaje"
                                      value="Español"
                                      onClick={() =>
                                        setform3({
                                          ...form3,
                                          Lenguaje: form3.Lenguaje.filter(
                                            (e) => !e.includes("Español")
                                          ),
                                        })
                                      }
                                    >
                                      {lang === "en" ? "Spanish" : "Español"}
                                    </button>
                                  ) : (
                                    <button
                                      className="button_create_projectform2"
                                      style={{ margin: "0.3rem" }}
                                      name="Lenguaje"
                                      value="Español"
                                      onClick={() =>
                                        setform3({
                                          ...form3,
                                          Lenguaje: [
                                            ...form3.Lenguaje,
                                            "Español",
                                          ],
                                        })
                                      }
                                    >
                                      {lang === "en" ? "Spanish" : "Español"}
                                    </button>
                                  )}
                                </>
                              }
                            </div>
                          </div>
                        </div>
                        <Navegador topform={topform} disable={form3.Lenguaje.length>0?false:true} props={props} lang={lang} estadoactual={estadoactual} form3={form3} detalle={detalle} setestadoactual={setestadoactual} emailvalidado={emailvalidado} settopform={settopform}></Navegador>
</div>

                      {/* //    Cuales habilidades requieres para la oportunidad (max 5) */}
                      <div style={{ width: "100%", height: "100%" }}>
                        <div
                          style={{
                            width: "100%",
                            height: "85%",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <div className="col-two-create-project">
                            <div className="tittle-section-question">
                              <h4 className="tittle-question-create-project">
                                {
                                  lang === 'en' ? 'What skills do you require for the opportunity (max 5)' : '¿Qué habilidades requieres para la oportunidad (max 5)'
                                }
                              </h4>
                            </div>
                            <div className="formulariocrear">
                              {
                                <>
                                  {form3.SeleccionaCarrera ===
                                    "Agronomía, Veterinaria y afines" ? (
                                    <div
                                      style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "flex-start",
                                        alignItems: "center",
                                      }}
                                    >
                                      {mapId.map((elem) => (
                                        <div
                                          style={{ marginBottom: "1%" }}
                                          key={elem}
                                        >
                                          <select
                                            name="Agronomía, Veterinaria y afines"
                                            onChange={(e) => {
                                              if (form3.habilidadesRequeridas.includes(e.target.value)) {
                                                e.target.value = ""
                                                return alert("Ya seleccionaste esta habilidad")
                                              } else {
                                                return setform3({
                                                  ...form3,
                                                  habilidadesRequeridas: [
                                                    ...form3.habilidadesRequeridas,
                                                    e.target.value,
                                                  ],
                                                })
                                              }
                                            }
                                            }
                                            defaultValue=""
                                          >
                                            {abilities[
                                              "Agronomía, Veterinaria y afines"
                                            ].map((e, i) => {
                                              return (
                                                <option value={e} key={i}>
                                                  {e}
                                                </option>
                                              );
                                            })}
                                          </select>
                                        </div>
                                      ))}
                                    </div>
                                  ) : form3.SeleccionaCarrera ===
                                    "Bellas Artes" ? (
                                    <div
                                      style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "flex-start",
                                        alignItems: "center",
                                      }}
                                    >
                                      {mapId.map((elem) => (
                                        <div
                                          style={{ marginBottom: "1%" }}
                                          key={elem}
                                        >
                                          <select
                                            name="Bellas Artes"
                                            defaultValue=""
                                            onChange={(e) => {
                                              if (form3.habilidadesRequeridas.includes(e.target.value)) {
                                                e.target.value = ""
                                                return alert("Ya seleccionaste esta habilidad")
                                              } else {
                                                return setform3({
                                                  ...form3,
                                                  habilidadesRequeridas: [
                                                    ...form3.habilidadesRequeridas,
                                                    e.target.value,
                                                  ],
                                                })
                                              }
                                            }
                                            }
                                          >
                                            {abilities["Bellas Artes"].map(
                                              (e, i) => {
                                                return (
                                                  <option value={e} key={i}>
                                                    {e}
                                                  </option>
                                                );
                                              }
                                            )}
                                          </select>
                                        </div>
                                      ))}
                                    </div>
                                  ) : form3.SeleccionaCarrera ===
                                    "Ciencias de la Educación" ? (
                                    <div
                                      style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "flex-start",
                                        alignItems: "center",
                                      }}
                                    >
                                      {mapId.map((elem) => (
                                        <div
                                          style={{ marginBottom: "1%" }}
                                          key={elem}
                                        >
                                          <select
                                            name="Ciencias de la Educación"
                                            defaultValue=""
                                            onChange={(e) => {
                                              if (form3.habilidadesRequeridas.includes(e.target.value)) {
                                                e.target.value = ""
                                                return alert("Ya seleccionaste esta habilidad")
                                              } else {
                                                return setform3({
                                                  ...form3,
                                                  habilidadesRequeridas: [
                                                    ...form3.habilidadesRequeridas,
                                                    e.target.value,
                                                  ],
                                                })
                                              }
                                            }
                                            }
                                          >
                                            {abilities[
                                              "Ciencias de la educación"
                                            ].map((e, i) => {
                                              return (
                                                <option value={e} key={i}>
                                                  {e}
                                                </option>
                                              );
                                            })}
                                          </select>
                                        </div>
                                      ))}
                                    </div>
                                  ) : form3.SeleccionaCarrera ===
                                    "Ciencias de la Salud" ? (
                                    <div
                                      style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "flex-start",
                                        alignItems: "center",
                                      }}
                                    >
                                      {mapId.map((elem) => (
                                        <div
                                          style={{ marginBottom: "1%" }}
                                          key={elem}
                                        >
                                          <select
                                            name="Ciencias de la Salud"
                                            defaultValue=""
                                            onChange={(e) => {
                                              if (form3.habilidadesRequeridas.includes(e.target.value)) {
                                                e.target.value = ""
                                                return alert("Ya seleccionaste esta habilidad")
                                              } else {
                                                return setform3({
                                                  ...form3,
                                                  habilidadesRequeridas: [
                                                    ...form3.habilidadesRequeridas,
                                                    e.target.value,
                                                  ],
                                                })
                                              }
                                            }
                                            }
                                          >
                                            {abilities[
                                              "Ciencias de la Salud"
                                            ].map((e, i) => {
                                              return (
                                                <option value={e} key={i}>
                                                  {e}
                                                </option>
                                              );
                                            })}
                                          </select>
                                        </div>
                                      ))}
                                    </div>
                                  ) : form3.SeleccionaCarrera ===
                                    "Ciencias Sociales y Humanas" ? (
                                    <div
                                      style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "flex-start",
                                        alignItems: "center",
                                      }}
                                    >
                                      {mapId.map((elem) => (
                                        <div
                                          style={{ marginBottom: "1%" }}
                                          key={elem}
                                        >
                                          <select
                                            name="Ciencias Sociales y Humanas"
                                            defaultValue=""
                                            onChange={(e) => {
                                              if (form3.habilidadesRequeridas.includes(e.target.value)) {
                                                e.target.value = ""
                                                return alert("Ya seleccionaste esta habilidad")
                                              } else {
                                                return setform3({
                                                  ...form3,
                                                  habilidadesRequeridas: [
                                                    ...form3.habilidadesRequeridas,
                                                    e.target.value,
                                                  ],
                                                })
                                              }
                                            }
                                            }
                                          >
                                            {abilities[
                                              "Ciencias Sociales y humanas"
                                            ].map((e, i) => {
                                              return (
                                                <option value={e} key={i}>
                                                  {e}
                                                </option>
                                              );
                                            })}
                                          </select>
                                        </div>
                                      ))}
                                    </div>
                                  ) : form3.SeleccionaCarrera ===
                                    "Economía, Administración, Contaduría y afines" ? (
                                    <div
                                      style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "flex-start",
                                        alignItems: "center",
                                      }}
                                    >
                                      {mapId.map((elem) => (
                                        <div
                                          style={{ marginBottom: "1%" }}
                                          key={elem}
                                        >
                                          <select
                                            name="Economía, Administración, Contaduría y afines"
                                            defaultValue=""
                                            onChange={(e) => {
                                              if (form3.habilidadesRequeridas.includes(e.target.value)) {
                                                e.target.value = ""
                                                return alert("Ya seleccionaste esta habilidad")
                                              } else {
                                                return setform3({
                                                  ...form3,
                                                  habilidadesRequeridas: [
                                                    ...form3.habilidadesRequeridas,
                                                    e.target.value,
                                                  ],
                                                })
                                              }
                                            }
                                            }
                                          >
                                            {abilities[
                                              "Economia, Administracion, Contaduria y afines"
                                            ].map((e, i) => {
                                              return (
                                                <option value={e} key={i}>
                                                  {e}
                                                </option>
                                              );
                                            })}
                                          </select>
                                        </div>
                                      ))}
                                    </div>
                                  ) : form3.SeleccionaCarrera ===
                                    "Ingeniería, Arquitectura, Urbanismo y afines" ? (
                                    <div
                                      style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "flex-start",
                                        alignItems: "center",
                                      }}
                                    >
                                      {mapId.map((elem) => (
                                        <div
                                          style={{ marginBottom: "1%" }}
                                          key={elem}
                                        >
                                          <select
                                            name="Ingeniería, Arquitectura, Urbanismo y afines"
                                            defaultValue=""
                                            onChange={(e) => {
                                              if (form3.habilidadesRequeridas.includes(e.target.value)) {
                                                e.target.value = ""
                                                return alert("Ya seleccionaste esta habilidad")
                                              } else {
                                                return setform3({
                                                  ...form3,
                                                  habilidadesRequeridas: [
                                                    ...form3.habilidadesRequeridas,
                                                    e.target.value,
                                                  ],
                                                })
                                              }
                                            }
                                            }
                                          >
                                            {abilities[
                                              "Ingeniería, Arquitectura, urbanismo y afines"
                                            ].map((e, i) => {
                                              return (
                                                <option value={e} key={i}>
                                                  {e}
                                                </option>
                                              );
                                            })}
                                          </select>
                                        </div>
                                      ))}
                                    </div>
                                  ) : form3.SeleccionaCarrera ===
                                    "Matemáticas y Ciencias Naturales" ? (
                                    <div
                                      style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "flex-start",
                                        alignItems: "center",
                                      }}
                                    >
                                      {mapId.map((elem) => (
                                        <div
                                          style={{ marginBottom: "1%" }}
                                          key={elem}
                                        >
                                          <select
                                            name="Matemáticas y Ciencias Naturales"
                                            defaultValue=""
                                            onChange={(e) => {
                                              if (form3.habilidadesRequeridas.includes(e.target.value)) {
                                                e.target.value = ""
                                                return alert("Ya seleccionaste esta habilidad")
                                              } else {
                                                return setform3({
                                                  ...form3,
                                                  habilidadesRequeridas: [
                                                    ...form3.habilidadesRequeridas,
                                                    e.target.value,
                                                  ],
                                                })
                                              }
                                            }
                                            }
                                          >
                                            {abilities[
                                              "Matemáticas y ciencias naturales"
                                            ].map((e, i) => {
                                              return (
                                                <option value={e} key={i}>
                                                  {e}
                                                </option>
                                              );
                                            })}
                                          </select>
                                        </div>
                                      ))}
                                    </div>
                                  ) : null}
                                </>
                              }
                              {cargando && <></>}
                              <ChipInput onChange={(chips) => handleSkillsChange(chips)}
                                onDelete={(chip, index) => handleSkillsDeleteChip(chip, index)}
                                style={{ width: '100%' }}
                                placeholder={lang === 'es' ? 'introduce una habilidad y luego enter!' : 'introduce a skill and then enter!'}
                                fullWidthInput={true} disableUnderline={false} alwaysShowPlaceholder={false}
                                allowDuplicates={false}
                                disabled={form3.habilidadesRequeridas.length >= 5 ? true : false}
                                defaultValue={lang === 'es' ? ['Trabajo en equipo', 'Gestión', 'Oratoria', 'Eficiencia'] : ['Teamwork', 'Management', 'Speaking', 'Efficiency']} />
                            </div>
                          </div>
                        </div>
                        <Navegador topform={topform} disable={form3.habilidadesRequeridas.length>0?false:true} props={props} lang={lang} estadoactual={estadoactual} form3={form3} detalle={detalle} setestadoactual={setestadoactual} emailvalidado={emailvalidado} settopform={settopform}></Navegador>
</div>

                      {/* //    ¿Que modalidad de contratación deseas? */}
                      <div style={{ width: "100%", height: "100%" }}>
                        <div
                          style={{
                            width: "100%",
                            height: "85%",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <div className="col-two-create-project">
                            <div className="tittle-section-question">
                              <h4 className="tittle-question-create-project">
                                {lang === 'en' ? 'What is your estimate for the duration of the opportunity?' : '¿Cuál es tu estimado para la duración de la oportunidad?'}
                              </h4>
                            </div>
                            <div className="formulariocrear">
                              <>
                                {form3.estimadoDuración === "Otro" ? (
                                  <input
                                    className="input_create_project_new_form mediumfont"
                                    style={{ border: "solid 1px #c4c4c4" }}
                                    type="text"
                                    name="estimadoDuración"
                                    onChange={cambioform3}
                                    placeholder="Otro..."
                                  />
                                ) : (
                                  <Select
                                    value={
                                      form3.estimadoDuración !== ""
                                        ? form3.estimadoDuración
                                        : "3"
                                    }
                                    style={{ marginTop: "9% !important" }}
                                    name="estimadoDuración"
                                    className="select-create-project"
                                    onChange={(e) => {
                                      cambioform3(e);
                                      e.target.value === "Otro" &&
                                        setestimado("Otro");
                                    }}
                                  >
                                    <MenuItem disabled="true" value="3">
                                      <em className="mediumfont">
                                        {lang === 'en' ? 'Choose and option' : 'Elige tu respuesta'}
                                      </em>
                                    </MenuItem>
                                    <MenuItem
                                      value="Indefinida"
                                      className="mediumfont"
                                    >
                                      {lang === 'en' ? 'Indefinite' : 'Indefinida'}
                                    </MenuItem>
                                    <MenuItem
                                      value="Un mes"
                                      className="mediumfont"
                                    >
                                      {lang === 'en' ? '1 month' : '1 mes'}
                                    </MenuItem>
                                    <MenuItem value="Otro" className="mediumfont">
                                      {lang === 'en' ? 'Other' : 'Otro'}
                                    </MenuItem>
                                  </Select>
                                )}
                              </>
                            </div>
                          </div>
                        </div>
                        <Navegador topform={topform} disable={false} props={props} lang={lang} estadoactual={estadoactual} form3={form3} detalle={detalle} setestadoactual={setestadoactual} emailvalidado={emailvalidado} settopform={settopform}></Navegador>
 </div>
                      {/* link              */}
                      <div style={{ width: "100%", height: "100%" }}>
                        <div
                          style={{
                            width: "100%",
                            height: "85%",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <div className="col-two-create-project">
                            <div className="tittle-section-question">
                              <h4 className="tittle-question-create-project">
                                {
                                  lang === 'en' ? 'Step 11' : 'Paso 11'
                                }
                                Si tu oportunidad reposa en una plataforma externa pon el link aquí
                              </h4>
                            </div>
                            <div className="formulariocrear">
                              {
                                <>
                                  <input
                                    className="input_create_project_new_form mediumfont"
                                    style={{ border: "solid 1px #c4c4c4" }}
                                    type="text"
                                    placeholder="NA"
                                    name="link"
                                    onChange={cambioform3}
                                  />
                                </>
                              }
                            </div>
                          </div>
                        </div>
                        <Navegador topform={topform} disable={false} props={props} lang={lang} estadoactual={estadoactual} form3={form3} detalle={detalle} setestadoactual={setestadoactual} emailvalidado={emailvalidado} settopform={settopform}></Navegador>
 </div>
                      {/* //    Que modalidad de pago prefieres */}
                      {form3.modalidadDeContratacion === "Por proyecto" && (
                        <div style={{ width: "100%", height: "100%" }}>
                          <div
                            style={{
                              width: "100%",
                              height: "85%",
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <div className="col-two-create-project">
                              <div className="tittle-section-question">
                                <h4 className="tittle-question-create-project">
                                  {
                                    lang === 'en' ? 'Which payment method do you prefer' : 'Que modalidad de pago prefieres'
                                  }
                                </h4>
                              </div>
                              <div className="formulariocrear">
                                <Select
                                  value={
                                    form3.modalidadPago !== ""
                                      ? form3.modalidadPago
                                      : "3"
                                  }
                                  style={{ marginTop: "9% !important" }}
                                  name="modalidadPago"
                                  className="select-create-project"
                                  onChange={cambioform3}
                                >
                                  <MenuItem disabled="true" value="3">
                                    <em className="mediumfont">
                                      {lang === 'en' ? 'Choose and option' : 'Elige tu respuesta'}
                                    </em>
                                  </MenuItem>
                                  <MenuItem
                                    value="Por horas"
                                    className="mediumfont"
                                  >
                                    {lang === 'en' ? 'By hours' : 'Por horas'}
                                  </MenuItem>
                                  <MenuItem
                                    value="Un solo pago"
                                    className="mediumfont"
                                  >
                                    {lang === 'en' ? 'One payment' : 'Un solo pago'}
                                  </MenuItem>
                                </Select>
                              </div>
                            </div>
                          </div>
                          <Navegador topform={topform} disable={false} props={props} lang={lang} estadoactual={estadoactual} form3={form3} detalle={detalle} setestadoactual={setestadoactual} emailvalidado={emailvalidado} settopform={settopform}></Navegador>
 </div>
                      )}
                      {/* Escribe el presupuesto (mínimo) que tienes para el proyecto/ rol?             */}
                      <div style={{ width: "100%", height: "100%" }}>
                        <div
                          style={{
                            width: "100%",
                            height: "85%",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <div className="col-two-create-project">
                            <div className="tittle-section-question">
                              <h4 className="tittle-question-create-project">
                                {
                                  lang === 'es' ? 'Presupuesto para tu oportunidad' : 'Budget for your opportunity'
                                }
                              </h4>
                              <h4 className="tittle-question-create-project">
                                {
                                  lang === 'en' ? `For the total number of : ${form3.professionals} professionals` : `Para la cantidad total de : ${form3.profesionales} profesionales`
                                }
                              </h4>
                            </div>
                            <div className="formulariocrear">
                              {
                                <>
                                  <input
                                    className="input_create_project_new_form mediumfont"
                                    style={{ border: "solid 1px #c4c4c4" }}
                                    type="text"
                                    pattern="[0-9]{0,10}"
                                    value={presupuesto.pres_min}
                                    placeholder="min:COP:10.000"
                                    name="pres_min"
                                    onChange={(e) => {
                                      handlePres(e);
                                    }}
                                  />
                                  <input
                                    className="input_create_project_new_form mediumfont"
                                    style={{ border: "solid 1px #c4c4c4" }}
                                    type="text"
                                    pattern="[0-9]{0,10}"
                                    value={presupuesto.pres_max}
                                    placeholder="max:COP:400.000.000"
                                    name="pres_min"
                                    onChange={(e) => {
                                      handlePres(e);
                                    }}
                                  />
                                </>
                              }
                            </div>
                          </div>
                        </div>
                        <Navegador topform={topform} disable={false} props={props} lang={lang} estadoactual={estadoactual} form3={form3} detalle={detalle} setestadoactual={setestadoactual} emailvalidado={emailvalidado} settopform={settopform}></Navegador>
</div>

                      {/* Modalidad de pago  */}
                      <div style={{ width: "100%", height: "100%" }}>
                        <div
                          style={{
                            width: "100%",
                            height: "85%",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          {usuario.nombre_paquete === "Paquete Free" && <h4 className="tittle-question-create-project">
                            {
                              lang === 'en' ? 'Select the payment method' : 'Selecciona la modalidad de pago'
                            }
                          </h4>}
                          {usuario.nombre_paquete === "Paquete Free" && <div style={{
                            width: "100%",
                            height: "100%",
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "center",
                            alignItems: "center",
                          }}>
                            <div style={{ width: '200px', height: '200px', backgroundColor: '#f6f6f6' }}>
                              <div style={{ height: '20%', backgroundColor: '#202256', display: 'flex', alignItems: 'center' }}>
                                <h3 style={{ color: 'white', fontFamily: 'Poppins', marginLeft: '70px' }}>Gratis </h3>
                              </div>
                              <button className="button_create_project" onClick={() => setPlan('gratis')} style={{ backgroundColor: select === 'gratis' && 'blue', marginTop: '4em' }}>seleccionar</button>
                            </div>
                            <div style={{ width: '200px', height: '200px', backgroundColor: '#f6f6f6', marginLeft: '20px', marginRight: '20px' }}>
                              <div style={{ height: '20%', backgroundColor: '#202256', display: 'flex', alignItems: 'center' }}>
                                <h3 style={{ color: 'white', fontFamily: 'Poppins', marginLeft: '40px' }}>Pago Básico </h3>
                              </div>
                              <button className="button_create_project" onClick={() => setPlan('basico')} style={{ backgroundColor: select === 'basico' && 'blue', marginTop: '4em' }}>seleccionar</button>
                            </div>
                            <div style={{ width: '200px', height: '200px', backgroundColor: '#f6f6f6' }}>
                              <div style={{ height: '20%', backgroundColor: '#202256', display: 'flex', alignItems: 'center' }}>
                                <h3 style={{ color: 'white', fontFamily: 'Poppins', marginLeft: '30px' }}>Pago Premium </h3>
                              </div>
                              <button className="button_create_project" onClick={() => setPlan('premium')} style={{ backgroundColor: select === 'premium' && 'blue', marginTop: '4em' }}>seleccionar</button>
                            </div>
                          </div>}
                          {
                            <div className="button_div_create_project">
                              {usuario.nombre_paquete !== 'Paquete Free' ?
                                <button
                                  className="button_create_project"
                                  onClick={(e) => {
                                    handleSubmitCreate(e);
                                  }}
                                >
                                  {<span>Crear Oportunidad</span>}
                                </button> :
                                select !== '' ? (
                                  <button
                                    className="button_create_project"
                                    onClick={(e) => {
                                      handleSubmitCreate(e);
                                    }}
                                  >
                                    {<span>Crear Oportunidad</span>}
                                  </button>
                                ) : (
                                  <button
                                    className="button_create_project"
                                    style={{ background: "#888c88" }}
                                    disabled
                                  >
                                    {<span>Crear Oportunidad</span>}
                                  </button>
                                )}
                            </div>
                          }
                          {/* <div className="col-one-create-project">
                            {!idp ? (
                              <>
                                <div className="tittle-section-question">
                                  <h4 className="tittle-question-create-project">
                                    {
                                      lang === 'en' ? 'Select the payment method' : 'Selecciona la modalidad de pago'
                                    }
                                  </h4>
                                </div>
                                <Select
                                  value={form3.modalidaddepago}
                                  onChange={cambioform3}
                                  name="modalidaddepago"
                                >
                                  <MenuItem
                                    value={"Pagar con wenjoy"}
                                    onClick={(e) => handleSubmitCreatewenjoy(e)}
                                  >
                                    {
                                      <Translate
                                        content="Pagarconwenjoy"
                                        component="span"
                                      />
                                    }
                                  </MenuItem>
                                </Select>
                              </>
                            ) : (
                              <div className="button_div_create_project">
                                {!cargando ? (
                                  <button
                                    className="button_create_project"
                                    onClick={(e) => {
                                      handleSubmitCreate(e);
                                    }}
                                  >
                                    {<span>Modificar Proyecto</span>}
                                  </button>
                                ) : (
                                  <button
                                    className="button_create_project"
                                    style={{ background: "#888c88" }}
                                    disabled
                                  >
                                    {<span>Modificar Proyecto</span>}
                                  </button>
                                )}
                              </div>
                            )} 
                          </div> */}
                        </div>
                        <Navegador topform={topform} disable={form3.modalidaddepago ==="Pagar con wenjoy"?false:true} props={props} lang={lang} estadoactual={estadoactual} form3={form3} detalle={detalle} setestadoactual={setestadoactual} emailvalidado={emailvalidado} settopform={settopform}></Navegador>
</div>
                      {/* Modalidad de pago  */}
                      <div
                        style={
                          statusProyecto === "wenjoy"
                            ? {
                              position: "relative",
                              display: "flex",
                              justifyContent: "center",
                              flexDirection: "column",
                              width: "100%",
                              height: "100%",
                            }
                            : {
                              position: "absolute",
                              zIndex: "-1",
                              opacity: "0",
                              top: "0",
                            }
                        }
                      >
                        <div
                          style={{
                            width: "100%",
                            height: "85%",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <div
                            className="select-area-create-project"
                            style={{
                              display: "flex",
                              justifyContent: "space-around",
                              alignItems: "center",
                            }}
                          >
                            <div className="col-one-create-project">
                              <div className="tittle-section-question">
                                <h4 className="tittle-question-create-project">
                                  {
                                    lang === 'en' ? 'Enter your name' : 'Ingresa tu nombre'
                                  }
                                </h4>
                              </div>

                              <input
                                className="input_create_project_new_form"
                                type="text"
                                placeholder={"Nombre"}
                                name="comentario"
                                onChange={(w) =>
                                  document
                                    .getElementById("wenjoy-checkout-button")
                                    .setAttribute(
                                      "data-owner-first-name",
                                      w.target.value
                                    )
                                }
                              />
                            </div>
                            <div className="col-two-create-project">
                              <div className="tittle-section-question">
                                <h4 className="tittle-question-create-project">
                                  {
                                    lang === 'en' ? 'Enter your last name' : 'Ingresa tu apellido'
                                  }
                                </h4>
                              </div>

                              <input
                                className="input_create_project_new_form"
                                type="text"
                                placeholder={"Apellido"}
                                name="comentario"
                                onChange={(w) =>
                                  document
                                    .getElementById("wenjoy-checkout-button")
                                    .setAttribute(
                                      "data-owner-last-name",
                                      w.target.value
                                    )
                                }
                              />
                            </div>
                          </div>
                        </div>
                        <Navegador topform={topform} disable={false} props={props} lang={lang} estadoactual={estadoactual} form3={form3} detalle={detalle} setestadoactual={setestadoactual} emailvalidado={emailvalidado} settopform={settopform}></Navegador>
</div>
                      {/* Modalidad de pago  */}
                      <div
                        style={
                          statusProyecto === "wenjoy"
                            ? {
                              position: "relative",
                              display: "flex",
                              justifyContent: "center",
                              flexDirection: "column",
                              width: "100%",
                              height: "100%",
                            }
                            : {
                              position: "absolute",
                              zIndex: "-1",
                              opacity: "0",
                              top: "0",
                            }
                        }
                      >
                        <div
                          style={{
                            width: "100%",
                            height: "85%",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <div
                            className="select-area-create-project"
                            style={{
                              display: "flex",
                              justifyContent: "space-around",
                              alignItems: "center",
                            }}
                          >
                            <div className="col-one-create-project">
                              <div className="tittle-section-question">
                                <h4 className="tittle-question-create-project">
                                  {
                                    lang === 'en' ? 'Enter your ID number' : 'Ingresa tu Cédula de Ciudadanía'
                                  }
                                </h4>
                              </div>

                              <input
                                className="input_create_project_new_form"
                                type="text"
                                placeholder={"Cédula de Ciudadanía"}
                                name="comentario"
                                onChange={(w) =>
                                  document
                                    .getElementById("wenjoy-checkout-button")
                                    .setAttribute(
                                      "data-owner-document",
                                      w.target.value
                                    )
                                }
                              />
                            </div>
                            <div className="col-two-create-project">
                              <div className="tittle-section-question">
                                <h4 className="tittle-question-create-project">
                                  {
                                    lang === 'en' ? 'Enter your phone number' : 'Ingresa tu número de teléfono'
                                  }
                                </h4>
                              </div>

                              <input
                                className="input_create_project_new_form"
                                type="text"
                                placeholder={"Telefono"}
                                name="comentario"
                                onChange={(w) =>
                                  document
                                    .getElementById("wenjoy-checkout-button")
                                    .setAttribute(
                                      "data-owner-cellphone",
                                      w.target.value
                                    )
                                }
                              />
                            </div>
                          </div>
                        </div>
                        <div
                          style={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          <div style={{ position: "relative" }}>
                            {
                              <button
                                className="button_create_project"
                                style={{ margin: "0", cursor: "pointer" }}
                              >
                                {
                                  lang === 'en' ? 'Pay with Wenjoy' : 'Pagar con Wenjoy'
                                }
                              </button>
                            }
                            <form
                              id="idform"
                              style={{
                                opacity: "0",
                                position: "absolute",
                                height: "100%",
                                width: "100%",
                                top: "0",
                              }}
                            >
                              <ScriptTag
                                id="wenjoy-checkout-button"
                                src="https://s3.amazonaws.com/wenjoy/wenjoy-checkout/wenjoy-checkout.min.js"
                                data-total-value="25000"
                                data-description="coally"
                                data-api-key="vPMHVpCKus3VCdPfFTx88KLhRMPy2OpI"
                                data-verify="false"
                                data-test="false"
                                data-confirmation-url="https://findally-backend.herokuapp.com/api/Project/create/user/wenjoy/"
                                data-owner-email="cambiar@gmail.com"
                                data-owner-first-name="cambiar"
                                data-owner-last-name="cambiar"
                                data-owner-cellphone="1234567891"
                                data-owner-document="11"
                                data-owner-document-type="1"
                                data-minutes="60"
                                data-response-url="http://findally.com.co.s3-website-us-east-1.amazonaws.com/company/project"
                              ></ScriptTag>
                            </form>
                          </div>
                        </div>
                      </div>
                      {form3.modalidaddepago === "Pagar con Tpaga" && (
                        <div
                          style={{
                            width: "100%",
                            marginLeft: "5%",
                            flexDirection: "column",
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <QRCode fgColor="#2a2b70" value={ypagaurl} />

                          <button
                            onClick={() => comprobarEstadoTpaga()}
                            className="project__card-actions-apply btn-more-pro mediumfont"
                            style={{ marginTop: "2%", color: "white" }}
                          >
                            {
                              lang === 'en' ? 'Confirm payment' : 'Confirmar pago'
                            }
                          </button>
                        </div>
                      )}
                    </>
                  )}
                {form3.tipoDeServicioDeseado === "Onboarding/Handover" && (
                  <>
                    <div style={{ width: "100%", height: "100%" }}>
                      <div
                        style={{
                          width: "100%",
                          height: "85%",
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <div className="col-two-create-project">
                          <div className="tittle-section-question">
                            <h4 className="tittle-question-create-project">
                              {lang === 'en' ? 'Do you want to publish anonymously?' : '¿Deseas publicar de manera anónima?'}
                            </h4>
                          </div>
                          <div className="formulariocrear">
                            <Select
                              value={form3.Anonimo !== "" ? form3.Anonimo : "3"}
                              style={{ marginTop: "9% !important" }}
                              name="Anonimo"
                              className="select-create-project"
                              onChange={cambioform3}
                            >
                              <MenuItem disabled="true" value="3">
                                {
                                  lang === 'en' ? <em className="mediumfont">Choose an option</em> :
                                    <em className="mediumfont">Elige tu respuesta</em>
                                }
                              </MenuItem>
                              <MenuItem value="Si" className="mediumfont">
                                {lang === 'en' ? 'Yes' : 'Si'}
                              </MenuItem>
                              <MenuItem value="No" className="mediumfont">
                                No
                              </MenuItem>
                            </Select>
                          </div>
                        </div>
                      </div>
                      <Navegador topform={topform} disable={false} props={props} lang={lang} estadoactual={estadoactual} form3={form3} detalle={detalle} setestadoactual={setestadoactual} emailvalidado={emailvalidado} settopform={settopform}></Navegador>
</div>

                    {/*//     Ingrese nombre de la empresa */}
                    {/* {form3.Anonimo === "No" && (
                      <div style={{ width: "100%", height: "100%" }}>
                        <div
                          style={{
                            width: "100%",
                            height: "100%",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <div className="col-two-create-project">
                            <div className="tittle-section-question">
                              <h4 className="tittle-question-create-project">
                                {
                                  lang === 'en' ? 'Enter the name of the company' : 'Ingrese el nombre de la empresa'
                                }
                              </h4>
                            </div>
                            <div className="formulariocrear">
                              {
                                <>
                                  <input
                                    className="input_create_project_new_form mediumfont"
                                    style={{ border: "solid 1px #c4c4c4" }}
                                    type="text"
                                    name="NombreEmpresa"
                                    onChange={cambioform3}
                                  />
                                </>
                              }
                            </div>
                          </div>
                        </div>
                      </div>
                    )} */}
                    {/* Ingresa tu Email              */}
                    {!props.user.email && (
                      <div style={{ width: "100%", height: "100%" }}>
                        <div
                          style={{
                            width: "100%",
                            height: "85%",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <div className="col-two-create-project">
                            <div className="tittle-section-question">
                              <h4 className="tittle-question-create-project">
                                {
                                  lang === 'en' ? 'Enter your email' : 'Ingresa tu correo electrónico'
                                }
                              </h4>
                            </div>
                            <div className="formulariocrear">
                              {
                                <>
                                  <input
                                    className="input_create_project_new_form mediumfont"
                                    style={{ border: "solid 1px #c4c4c4" }}
                                    type="text"
                                    name="Email"
                                    onChange={cambioform3}
                                  />
                                </>
                              }
                            </div>
                          </div>
                        </div>
                        <Navegador topform={topform} disable={false} props={props} lang={lang} estadoactual={estadoactual} form3={form3} detalle={detalle} setestadoactual={setestadoactual} emailvalidado={emailvalidado} settopform={settopform}></Navegador>
</div>
                    )}
                    {/* Suba su ID/RUT              */}
                    {/* Nombre de la oportunidad, cargo o proyecto */}
                    <div style={{ width: "100%", height: "100%" }}>
                      <div
                        style={{
                          width: "100%",
                          height: "85%",
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <div className="col-two-create-project">
                          <div className="tittle-section-question">
                            {
                              lang === 'en' ?
                                <h4 className="tittle-question-create-project">Name of opportunity, position or
                                  project</h4> :
                                <h4 className="tittle-question-create-project">Nombre de la oportunidad, cargo o
                                  proyecto</h4>
                            }
                          </div>
                          <div className="formulariocrear">
                            {
                              <>
                                <input
                                  className="input_create_project_new_form mediumfont"
                                  style={{ border: "solid 1px #c4c4c4" }}
                                  type="text"
                                  name="NombreOportunidad"
                                  onChange={cambioform3}
                                />
                              </>
                            }
                          </div>
                        </div>
                        </div>
                        <Navegador topform={topform} disable={form3.NombreOportunidad.length>0?false:true} props={props} lang={lang} estadoactual={estadoactual} form3={form3} detalle={detalle} setestadoactual={setestadoactual} emailvalidado={emailvalidado} settopform={settopform}></Navegador>
</div>
                    {/* Describe el proyecto/rol: Describe el detalle de la oportunidad/rol/proyecto. Si lo deseas puedes enviar una nota de voz con los requerimientos */}
                    <div style={{ width: "100%", height: "100%" }}>
                      <div
                        style={{
                          width: "100%",
                          height: "85%",
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <div
                          className="col-two-create-project"
                          style={{ width: "80%" }}
                        >
                          <div className="tittle-section-question">
                            {
                              lang === 'en' ?
                                <h4 className="tittle-question-create-project">Describe the project/role: Describe the
                                  detail of the opportunity/role/project. If you want you can send a voice note with the
                                  requirements</h4> :
                                <h4 className="tittle-question-create-project">Describe el proyecto/rol: Describe el
                                  detalle de la oportunidad/rol/proyecto. Si lo deseas puedes enviar una nota de voz con
                                  los requerimientos</h4>
                            }
                          </div>
                          <div
                            className="formulariocrear"
                            style={{ height: "100%" }}
                          >
                            {
                              <div style={{ display: "flex", height: "100%" }}>
                                <textarea
                                  className="input_create_project_new_form23 mediumfont"
                                  style={{
                                    border: "solid 1px #c4c4c4",
                                    width: "60%",
                                    resize: "none",
                                  }}
                                  type="text"
                                  name="DescribeProyecto"
                                  onChange={cambioform3}
                                />
                                <div
                                  style={{
                                    width: "50%",
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "center",
                                    marginLeft: "1%",
                                  }}
                                >
                                  <div className="formulariocrear">
                                    <button
                                      id="1a"
                                      style={{
                                        display: "flex",
                                        width: "100%",
                                        background: "#e8e9eb",
                                        border: "none",
                                        color: "#696a7c",
                                        cursor: "pointer",
                                        justifyContent: "right",
                                      }}
                                      onClick={() => startRecording(1)}
                                    >
                                      <p
                                        style={{ cursor: "pointer" }}
                                        className="mediumfont"
                                      >
                                        {lang === 'en' ? 'Record voice note' : 'Grabar Audio'}
                                      </p>
                                      <MicIcon
                                        style={{ cursor: "pointer" }}
                                        className="mediumfont"
                                      ></MicIcon>
                                    </button>
                                    <button
                                      id="1b"
                                      style={{
                                        display: "none",
                                        width: "100%",
                                        position: "relative",
                                        background: "#e8e9eb",
                                        border: "none",
                                        justifyContent: "right",
                                      }}
                                      onClick={() => stopRecording(1)}
                                      className="mediumfont"
                                    >
                                      <div className="audioclase">
                                        <p
                                          style={{
                                            color: "#f33e16",
                                            cursor: "pointer",
                                          }}
                                          className="mediumfont"
                                        >
                                          {lang === 'en' ? 'Recording voice note' : 'Grabando Audio'}
                                        </p>
                                        <GraphicEqIcon
                                          style={{
                                            color: "#f33e16",
                                            cursor: "pointer",
                                          }}
                                          className="mediumfont"
                                        ></GraphicEqIcon>
                                      </div>
                                      <div className="audioclasehover">
                                        <p
                                          style={{
                                            color: "#1bb96d",
                                            cursor: "pointer",
                                          }}
                                          className="mediumfont"
                                        >
                                          {lang === 'en' ? 'End voice note' : 'Terminar Audio'}
                                        </p>
                                        <StopIcon
                                          style={{
                                            color: "#1bb96d",
                                            cursor: "pointer",
                                          }}
                                          className="mediumfont"
                                        ></StopIcon>
                                      </div>
                                    </button>
                                  </div>
                                  <li
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <audio
                                      id="mirar1"
                                      src=""
                                      autoplay
                                      preload="auto"
                                      controls
                                      className="mediumfont audioo"
                                    ></audio>
                                    <DeleteIcon
                                      className="mediumfont"
                                      onClick={() =>
                                      (document.getElementById("mirar1").src =
                                        "")
                                      }
                                      style={{ margin: "0", cursor: "pointer" }}
                                    ></DeleteIcon>
                                  </li>
                                </div>
                              </div>
                            }
                          </div>
                        </div>
                      </div>
                      <Navegador topform={topform} disable={form3.DescribeProyecto.length>0?false:true} props={props} lang={lang} estadoactual={estadoactual} form3={form3} detalle={detalle} setestadoactual={setestadoactual} emailvalidado={emailvalidado} settopform={settopform}></Navegador>
</div>

                    {/* Nivel de conocimiento (selección multiple) */}
                    <div style={{ width: "100%", height: "100%" }}>
                      <div
                        style={{
                          width: "100%",
                          height: "85%",
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <div className="col-two-create-project">
                          <div className="tittle-section-question">
                            <h4
                              className="tittle-question-create-project"
                              style={{ marginBottom: "25px" }}
                            >
                              {
                                lang === 'en' ? <h4 className="tittle-question-create-project">
                                  Knowledge level (multiple choice)
                                </h4> : <h4 className="tittle-question-create-project">
                                  Nivel de conocimiento (selección multiple)
                                </h4>
                              }
                            </h4>
                          </div>
                          <div
                            className="formulariocrear"
                            style={{
                              display: "flex",
                              justifyContent: "space-around",
                            }}
                          >
                            {
                              <>
                                {form3.Niveldeconocimiento.includes(
                                  "Profesional"
                                ) ? (
                                  <button
                                    className="button_create_projectform2"
                                    style={{
                                      background: "#F07130",
                                      color: "white",
                                      margin: "0.3rem",
                                      border: "none",
                                    }}
                                    name="Niveldeconocimiento"
                                    value="Profesional"
                                    onClick={() =>
                                      setform3({
                                        ...form3,
                                        Niveldeconocimiento:
                                          form3.Niveldeconocimiento.filter(
                                            (e) => !e.includes("Profesional")
                                          ),
                                      })
                                    }
                                  >
                                    {lang === 'en' ? 'Professional' : 'Profesional'}
                                  </button>
                                ) : (
                                  <button
                                    className="button_create_projectform2"
                                    style={{ margin: "0.3rem" }}
                                    name="Niveldeconocimiento"
                                    value="Profesional"
                                    onClick={() =>
                                      setform3({
                                        ...form3,
                                        Niveldeconocimiento: [
                                          ...form3.Niveldeconocimiento,
                                          "Profesional",
                                        ],
                                      })
                                    }
                                  >
                                    {lang === 'en' ? 'Professional' : 'Profesional'}
                                  </button>
                                )}
                                {form3.Niveldeconocimiento.includes(
                                  "Técnico/tecnólogo"
                                ) ? (
                                  <button
                                    className="button_create_projectform2"
                                    style={{
                                      background: "#F07130",
                                      color: "white",
                                      margin: "0.3rem",
                                      width: "fit-content",
                                      border: "none",
                                    }}
                                    name="Niveldeconocimiento"
                                    value="Técnico/tecnólogo"
                                    onClick={() =>
                                      setform3({
                                        ...form3,
                                        Niveldeconocimiento:
                                          form3.Niveldeconocimiento.filter(
                                            (e) =>
                                              !e.includes("Técnico/tecnólogo")
                                          ),
                                      })
                                    }
                                  >
                                    {lang === 'en' ? 'Technical' : 'Técnico/tecnólogo'}
                                  </button>
                                ) : (
                                  <button
                                    className="button_create_projectform2"
                                    style={{
                                      margin: "0.3rem",
                                      width: "fit-content",
                                    }}
                                    name="Niveldeconocimiento"
                                    value="Técnico/tecnólogo"
                                    onClick={() =>
                                      setform3({
                                        ...form3,
                                        Niveldeconocimiento: [
                                          ...form3.Niveldeconocimiento,
                                          "Técnico/tecnólogo",
                                        ],
                                      })
                                    }
                                  >
                                    {lang === 'en' ? 'Technical' : 'Técnico/tecnólogo'}
                                  </button>
                                )}
                              </>
                            }
                          </div>
                        </div>
                      </div>
                      <Navegador topform={topform} disable={form3.Niveldeconocimiento.length>0?false:true } props={props} lang={lang} estadoactual={estadoactual} form3={form3} detalle={detalle} setestadoactual={setestadoactual} emailvalidado={emailvalidado} settopform={settopform}></Navegador>
</div>
                    {/* Lenguaje (selección multiple) */}
                    <div style={{ width: "100%", height: "100%" }}>
                      <div
                        style={{
                          width: "100%",
                          height: "85%",
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <div className="col-two-create-project">
                          <div className="tittle-section-question">
                            <h4
                              className="tittle-question-create-project "
                              style={{ marginBottom: "25px" }}
                            >
                              {
                                lang === 'en' ? <h4 className="tittle-question-create-project">
                                  Language (multiple choice)
                                </h4> : <h4 className="tittle-question-create-project">
                                  Lenguaje (selección multiple)</h4>
                              }
                            </h4>
                          </div>
                          <div
                            className="formulariocrear"
                            style={{
                              display: "flex",
                              justifyContent: "space-around",
                            }}
                          >
                            {
                              <>
                                {form3.Lenguaje.includes("Ingles") ? (
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <div
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                      }}
                                    >
                                      <button
                                        className="button_create_projectform2"
                                        style={{
                                          background: "#F07130",
                                          color: "white",
                                          margin: "0.3rem",
                                          border: "none",
                                        }}
                                        name="Lenguaje"
                                        value="Ingles"
                                        onClick={() =>
                                          setform3({
                                            ...form3,
                                            Lenguaje: form3.Lenguaje.filter(
                                              (e) => !e.includes("Ingles")
                                            ),
                                          })
                                        }
                                      >
                                        {lang === 'en' ? 'English' : 'Inglés'}
                                      </button>
                                    </div>
                                    <div
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                      }}
                                    >
                                      <select
                                        className="select_create_projectform"
                                        name="Nivel"
                                        defaultValue="Nivel"
                                        onChange={(e) =>
                                          setform3({
                                            ...form3,
                                            Nivel: e.target.value,
                                          })
                                        }
                                      >
                                        <option value="Nivel" disabled>
                                          {lang === 'en' ? 'Level' : 'Nivel'}
                                        </option>
                                        <option value="A1">A1</option>
                                        <option value="A2">A2</option>
                                        <option value="B2">B1</option>
                                        <option value="B1">B2</option>
                                        <option value="C1">C1</option>
                                        <option value="C2">C2</option>
                                      </select>
                                    </div>
                                  </div>
                                ) : (
                                  <button
                                    className="button_create_projectform2"
                                    style={{ margin: "0.3rem" }}
                                    name="Lenguaje"
                                    value="Ingles"
                                    onClick={() =>
                                      setform3({
                                        ...form3,
                                        Lenguaje: [...form3.Lenguaje, "Ingles"],
                                      })
                                    }
                                  >
                                    {lang === 'en' ? 'English' : 'Inglés'}
                                  </button>
                                )}
                                {form3.Lenguaje.includes("Español") ? (
                                  <button
                                    className="button_create_projectform2"
                                    style={{
                                      background: "#F07130",
                                      color: "white",
                                      margin: "0.3rem",
                                      border: "none",
                                    }}
                                    name="Lenguaje"
                                    value="Español"
                                    onClick={() =>
                                      setform3({
                                        ...form3,
                                        Lenguaje: form3.Lenguaje.filter(
                                          (e) => !e.includes("Español")
                                        ),
                                      })
                                    }
                                  >
                                    {lang === 'en' ? 'Spanish' : 'Español'}
                                  </button>
                                ) : (
                                  <button
                                    className="button_create_projectform2"
                                    style={{ margin: "0.3rem" }}
                                    name="Lenguaje"
                                    value="Español"
                                    onClick={() =>
                                      setform3({
                                        ...form3,
                                        Lenguaje: [
                                          ...form3.Lenguaje,
                                          "Español",
                                        ],
                                      })
                                    }
                                  >
                                    {lang === 'en' ? 'Spanish' : 'Español'}
                                  </button>
                                )}
                              </>
                            }
                          </div>
                        </div>
                      </div>
                      <Navegador topform={topform} disable={form3.Lenguaje.length>0?false:true} props={props} lang={lang} estadoactual={estadoactual} form3={form3} detalle={detalle} setestadoactual={setestadoactual} emailvalidado={emailvalidado} settopform={settopform}></Navegador>
</div>

                    {/* //    ¿Que modalidad de contratación deseas? */}
                    <div style={{ width: "100%", height: "100%" }}>
                      <div
                        style={{
                          width: "100%",
                          height: "85%",
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <div className="col-two-create-project">
                          <div className="tittle-section-question">
                            <h4 className="tittle-question-create-project">
                              {lang === 'en' ? 'What is your estimate for the duration of the opportunity?' : 'Cuál es tu estimado de duración de la oportunidad'}
                            </h4>
                          </div>
                          <div className="formulariocrear">
                            <>
                              {form3.estimadoDuración === "Otro" ? (
                                <input
                                  className="input_create_project_new_form mediumfont"
                                  style={{ border: "solid 1px #c4c4c4" }}
                                  type="text"
                                  name="estimadoDuración"
                                  onChange={cambioform3}
                                  placeholder="Otro..."
                                />
                              ) : (
                                <Select
                                  value={
                                    form3.estimadoDuración !== ""
                                      ? form3.estimadoDuración
                                      : "3"
                                  }
                                  style={{ marginTop: "9% !important" }}
                                  name="estimadoDuración"
                                  className="select-create-project"
                                  onChange={(e) => {
                                    cambioform3(e);
                                    e.target.value === "Otro" &&
                                      setestimado("Otro");
                                  }}
                                >
                                  <MenuItem disabled="true" value="3">
                                    <em className="mediumfont">
                                      {lang === 'en' ? 'Choose and option' : 'Elige tu respuesta'}
                                    </em>
                                  </MenuItem>
                                  <MenuItem
                                    value="Indefinida"
                                    className="mediumfont"
                                  >
                                    {lang === 'en' ? 'Indefinite' : 'Indefinida'}
                                  </MenuItem>
                                  <MenuItem
                                    value="Un mes"
                                    className="mediumfont"
                                  >
                                    {lang === 'en' ? '1 month' : '1 mes'}
                                  </MenuItem>
                                  <MenuItem value="Otro" className="mediumfont">
                                    {lang === 'en' ? 'Other' : 'Otro'}
                                  </MenuItem>
                                </Select>
                              )}
                            </>
                          </div>
                        </div>
                      </div>
                      <Navegador topform={topform} disable={false} props={props} lang={lang} estadoactual={estadoactual} form3={form3} detalle={detalle} setestadoactual={setestadoactual} emailvalidado={emailvalidado} settopform={settopform}></Navegador>
</div>

                    {/* link              */}
                    <div style={{ width: "100%", height: "100%" }}>
                      <div
                        style={{
                          width: "100%",
                          height: "85%",
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <div className="col-two-create-project">
                          <div className="tittle-section-question">
                            <h4 className="tittle-question-create-project">

                              {lang === 'en' ? "If your opportunity rests on an external platform put the link here" : 'Si tu oportunidad reposa en una plataforma externa pon el link aquí'}
                            </h4>
                          </div>
                          <div className="formulariocrear">
                            {
                              <>
                                <input
                                  className="input_create_project_new_form mediumfont"
                                  style={{ border: "solid 1px #c4c4c4" }}
                                  type="text"
                                  placeholder="NA"
                                  name="link"
                                  onChange={cambioform3}
                                />
                              </>
                            }
                          </div>
                        </div>
                      </div>
                      <Navegador topform={topform} disable={false} props={props} lang={lang} estadoactual={estadoactual} form3={form3} detalle={detalle} setestadoactual={setestadoactual} emailvalidado={emailvalidado} settopform={settopform}></Navegador>
</div>
                    {/* Modalidad de pago  */}
                    {!idp && (
                      <div style={{ width: "100%", height: "100%" }}>
                        <div
                          style={{
                            width: "100%",
                            height: "85%",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <div className="col-one-create-project">
                            <div className="tittle-section-question">
                              <h4 className="tittle-question-create-project">
                                {
                                  lang === 'en' ? 'Payment method' : 'Modalidad de pago'
                                }
                              </h4>
                            </div>
                            <h3 className="tittle-question-create-project">
                              {
                                lang === 'en' ? 'The price for 5 roles is 30 USD/month incorporating your payment method.' : 'El precio para 5 roles es 30 USD/mes incluyendo tu modalidad de pago.'
                              }
                            </h3>

                            <Select
                              value={form3.modalidaddepago}
                              onChange={cambioform3}
                              name="modalidaddepago"
                            >
                              <MenuItem
                                value={"Pagar con wenjoy"}
                                onClick={(e) => {
                                  setform3({
                                    ...form3,
                                    modalidaddepago: "Pagar con wenjoy",
                                  });
                                  handleSubmitCreatewenjoy(e);
                                }}
                              >
                                {
                                  <Translate
                                    content="Pagarconwenjoy"
                                    component="span"
                                  />
                                }
                              </MenuItem>
                            </Select>
                          </div>
                        </div>
                        <Navegador topform={topform} disable={form3.modalidaddepago ==="Pagar con wenjoy"?false:true} props={props} lang={lang} estadoactual={estadoactual} form3={form3} detalle={detalle} setestadoactual={setestadoactual} emailvalidado={emailvalidado} settopform={settopform}></Navegador>
</div>
                    )}
                    {/* Modalidad de pago  */}
                    <div
                      style={
                        statusProyecto === "wenjoy"
                          ? {
                            position: "relative",
                            display: "flex",
                            justifyContent: "center",
                            flexDirection: "column",
                            width: "100%",
                            height: "100%",
                          }
                          : {
                            position: "absolute",
                            zIndex: "-1",
                            opacity: "0",
                            top: "0",
                          }
                      }
                    >
                      <div
                        style={{
                          width: "100%",
                          height: "85%",
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <div
                          className="select-area-create-project"
                          style={{
                            display: "flex",
                            justifyContent: "space-around",
                            alignItems: "center",
                          }}
                        >
                          <div className="col-one-create-project">
                            <div className="tittle-section-question">
                              <h4 className="tittle-question-create-project">
                                {
                                  lang === 'en' ? 'Enter your name' : 'Ingresa tu nombre'
                                }
                              </h4>
                            </div>

                            <input
                              className="input_create_project_new_form"
                              type="text"
                              placeholder={"Nombre"}
                              name="comentario"
                              onChange={(w) =>
                                document
                                  .getElementById("wenjoy-checkout-button")
                                  .setAttribute(
                                    "data-owner-first-name",
                                    w.target.value
                                  )
                              }
                            />
                          </div>
                          <div className="col-two-create-project">
                            <div className="tittle-section-question">
                              <h4 className="tittle-question-create-project">
                                {
                                  lang === 'en' ? 'Enter your last name' : 'Ingresa tu apellido'
                                }
                              </h4>
                            </div>

                            <input
                              className="input_create_project_new_form"
                              type="text"
                              placeholder={"Apellido"}
                              name="comentario"
                              onChange={(w) =>
                                document
                                  .getElementById("wenjoy-checkout-button")
                                  .setAttribute(
                                    "data-owner-last-name",
                                    w.target.value
                                  )
                              }
                            />
                          </div>
                        </div>
                      </div>
                      <Navegador topform={topform} disable={false} props={props} lang={lang} estadoactual={estadoactual} form3={form3} detalle={detalle} setestadoactual={setestadoactual} emailvalidado={emailvalidado} settopform={settopform}></Navegador>
</div>
                    {/* Modalidad de pago  */}
                    <div
                      style={
                        statusProyecto === "wenjoy"
                          ? {
                            position: "relative",
                            display: "flex",
                            justifyContent: "center",
                            flexDirection: "column",
                            width: "100%",
                            height: "100%",
                          }
                          : {
                            position: "absolute",
                            zIndex: "-1",
                            opacity: "0",
                            top: "0",
                          }
                      }
                    >
                      <div
                        style={{
                          width: "100%",
                          height: "85%",
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <div
                          className="select-area-create-project"
                          style={{
                            display: "flex",
                            justifyContent: "space-around",
                            alignItems: "center",
                          }}
                        >
                          <div className="col-one-create-project">
                            <div className="tittle-section-question">
                              <h4 className="tittle-question-create-project">
                                {
                                  lang === 'en' ? 'Enter your ID number' : 'Ingresa tu Cédula de Ciudadanía'
                                }
                              </h4>
                            </div>

                            <input
                              className="input_create_project_new_form"
                              type="text"
                              placeholder={"Cédula de Ciudadanía"}
                              name="comentario"
                              onChange={(w) =>
                                document
                                  .getElementById("wenjoy-checkout-button")
                                  .setAttribute(
                                    "data-owner-document",
                                    w.target.value
                                  )
                              }
                            />
                          </div>
                          <div className="col-two-create-project">
                            <div className="tittle-section-question">
                              <h4 className="tittle-question-create-project">
                                {
                                  lang === 'en' ? 'Enter your phone number' : 'Ingresa tu número de teléfono'
                                }
                              </h4>
                            </div>

                            <input
                              className="input_create_project_new_form"
                              type="text"
                              placeholder={"Telefono"}
                              name="comentario"
                              onChange={(w) =>
                                document
                                  .getElementById("wenjoy-checkout-button")
                                  .setAttribute(
                                    "data-owner-cellphone",
                                    w.target.value
                                  )
                              }
                            />
                          </div>
                        </div>
                      </div>
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <div style={{ position: "relative" }}>
                          {
                            <button
                              className="button_create_project"
                              style={{ margin: "0", cursor: "pointer" }}
                            >
                              {
                                lang === 'en' ? 'Pay with Wenjoy' : 'Pagar con Wenjoy'
                              }
                            </button>
                          }
                          <form
                            id="idform"
                            style={{
                              opacity: "0",
                              position: "absolute",
                              height: "100%",
                              width: "100%",
                              top: "0",
                            }}
                          >
                            <ScriptTag
                              id="wenjoy-checkout-button"
                              src="https://s3.amazonaws.com/wenjoy/wenjoy-checkout/wenjoy-checkout.min.js"
                              data-total-value="25000"
                              data-description="coally"
                              data-api-key="vPMHVpCKus3VCdPfFTx88KLhRMPy2OpI"
                              data-verify="false"
                              data-test="false"
                              data-confirmation-url="https://findally-backend.herokuapp.com/api/Project/create/user/wenjoy/"
                              data-owner-email="cambiar@gmail.com"
                              data-owner-first-name="cambiar"
                              data-owner-last-name="cambiar"
                              data-owner-cellphone="1234567891"
                              data-owner-document="11"
                              data-owner-document-type="1"
                              data-minutes="60"
                              data-response-url="http://findally.com.co.s3-website-us-east-1.amazonaws.com/company/project"
                            ></ScriptTag>
                          </form>
                        </div>
                      </div>
                    </div>
                    {form3.modalidaddepago === "Pagar con Tpaga" && (
                      <div
                        style={{
                          width: "100%",
                          marginLeft: "5%",
                          flexDirection: "column",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <QRCode fgColor="#2a2b70" value={ypagaurl} />

                        <button
                          onClick={() => comprobarEstadoTpaga()}
                          className="project__card-actions-apply btn-more-pro mediumfont"
                          style={{ marginTop: "2%", color: "white" }}
                        >
                          {
                            lang === 'en' ? 'Confirm payment' : 'Confirmar pago'
                          }
                        </button>
                      </div>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
          
        </div>
      </div>
    </>
  );
}

function Navegador({topform,lang,estadoactual,form3,emailvalidado,settopform,props,detalle,setestadoactual,disable}){
  return (
    <div
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "space-between",
              padding: "1rem 3rem 0rem 3rem",
              alignItems: "center",
            }}
          >
            {topform === 0 || topform === "0" ? (
              <button className="button_create_projectform2">Anterior</button>
            ) : (
              <button
                className="button_create_projectform2"
                style={{}}
                onClick={() => {
                  settopform(topform + 100);
                  setestadoactual(estadoactual - 1);
                }}
              >
                {
                  lang === 'en' ? 'Previous' : 'Anterior'
                }
              </button>
            )}
            <h3 className="contadordepreguntas">
              {estadoactual}/
              {form3.tipoDeServicioDeseado === "Contratación"
                ? 13 +
                (form3.modalidadDeContratacion === "Por proyecto" ? 1 : 0) +
                (form3.Anonimo === "No" ? 1 : 0) +
                (!props.user.email ? 1 : 0) +
                detalle +
                (form3.modalidaddepago === "Pagar con wenjoy" ? 2 : 0) +
                (props.user.email === "winnyfferortega@coally.com" ||
                  props.user.email === "daniel.gomez.12345@hotmail.com"
                  ? 1
                  : 0)
                : form3.tipoDeServicioDeseado ===
                  "Outsourcing (BPO)- Personalized services"
                  ? 13 +
                  (form3.modalidadDeContratacion === "Por proyecto" ? 1 : 0) +
                  (form3.Anonimo === "No" ? 1 : 0) +
                  (!props.user.email ? 1 : 0) +
                  detalle +
                  (form3.modalidaddepago === "Pagar con wenjoy" ? 2 : 0)
                  : form3.tipoDeServicioDeseado === "Onboarding/Handover"
                    ? 10 +
                    (form3.modalidadDeContratacion === "Por proyecto" ? 1 : 0) +
                    (form3.Anonimo === "No" ? 1 : 0) +
                    (!props.user.email ? 1 : 0) +
                    detalle +
                    (form3.modalidaddepago === "Pagar con wenjoy" ? 2 : 0)
                    : 0}
            </h3>
            {estadoactual ===
              (form3.tipoDeServicioDeseado === "Contratación" &&
                13 +
                (form3.modalidadDeContratacion === "Por proyecto" ? 1 : 0) +
                (form3.Anonimo === "No" ? 1 : 0) +
                (!props.user.email ? 1 : 0) +
                detalle +
                (form3.modalidaddepago === "Pagar con wenjoy" ? 2 : 0) +
                (props.user.email === "winnyfferortega@coally.com" ||
                  props.user.email === "daniel.gomez.12345@hotmail.com"
                  ? 1
                  : 0)) ||
              estadoactual ===
              (form3.tipoDeServicioDeseado ===
                "Outsourcing (BPO)- Personalized services" &&
                13 +
                (form3.modalidadDeContratacion === "Por proyecto" ? 1 : 0) +
                (form3.Anonimo === "No" ? 1 : 0) +
                (!props.user.email ? 1 : 0) +
                detalle +
                (form3.modalidaddepago === "Pagar con wenjoy" ? 2 : 0)) ||
              estadoactual ===
              (form3.tipoDeServicioDeseado === "Onboarding/Handover" &&
                10 +
                (form3.modalidadDeContratacion === "Por proyecto" ? 1 : 0) +
                (form3.Anonimo === "No" ? 1 : 0) +
                (!props.user.email ? 1 : 0) +
                detalle +
                (form3.modalidaddepago === "Pagar con wenjoy" ? 2 : 0)) ? (
              <button
                style={{ marginLeft: "0", background: "#b7b7b7" }}
                className="button_create_projectform"
               disabled={disable}>
                Siguiente
              </button>
            ) : (
              <button
              disabled={disable}
                className="button_create_projectform"
                onClick={() => {
                  (emailvalidado || !form3.Email || props.user.email) &&
                    settopform(topform - 100);
                  (emailvalidado || !form3.Email || props.user.email) &&
                    setestadoactual(estadoactual + 1);
                  form3.Email &&
                    !props.user.email &&
                    axios
                      .post(
                        "https://findally-backend.herokuapp.com/api/auth/validate/duplicate",
                        { email: form3.Email }
                      )
                      .then((r) => {
                        setemailvalidado(true);
                        settopform(topform - 100);
                        setestadoactual(estadoactual + 1);
                      })
                      .catch((e) => alert("Intente otro correo"));
                }}
              >
                {
                  lang === 'en' ? 'Next' : 'Siguiente'
                }
              </button>
            )}
          </div>
  )
}
const mapDispatchToProps = {
  CreateUnregisterProject,
  CreateProjectWhithoutPayment,
  CreateProject,
};
const mapStateToProps = (state) => {
  return {
    user: state.user,
    projectUser: state.userProjects,
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FormCompanyNewCreateProject);
