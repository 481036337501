import React from 'react'
import './CarruselCards.css'
/*MENTORES FILES */
import mentorToolkit from '../files/mentor toolkit.pdf'
import guiaMentor from '../files/guia sesion.pdf'
// --------------- //
/*ESTUDIANTES FILES */
import mapaSuenos from '../files/mapa sueños.pdf'
import tallerSuenos from '../files/taller sueños.png'
import liderazgo from '../files/Descubriendo tu Liderazgo.pdf'
// ----------------- //
import ReactPlayer from "react-player";
const CarruselCards = ({image, title, desc, rol, video, link}) => {

    const downloadFile = (rol, title) => {
        if(rol === "MENTOR"){
            switch (title) {
                case "Toolkit de mentoría":
                return window.open(mentorToolkit)
                case "Tu equipaje como mentor":
                return window.open(guiaMentor)
                default:
                return
            }
        } else if(rol === "ESTUDIANTE"){
            switch (title) {
                case "Mapa de los sueños":
                return window.open(mapaSuenos)
                case "Taller de los sueños":
                return window.open(tallerSuenos)
                case "Descubriendo tu liderazgo":
                return window.open(liderazgo)
                default:
                return
            }
        }
    }

  return (
    <div className={'CarruselCards-global-container'} style={{cursor: 'pointer'}} onClick={()=>{
       return video ? null : downloadFile(rol, title)
    }}>

        {image ?<> <div className={'CarruselCards-image-container'} style={{
            backgroundImage: `url(${image})`,
        }}>
        </div>
            <div className={'CarruselCards-sub-container'}>
            <h1 className={'CarruselCards-global-title'}>{title}</h1>
            <p className={'CarruselCards-global-desc'}>{desc}</p>
            </div> </> :
            <div className={'CarruselCards-video-container'}>
                <ReactPlayer url={link} controls={true} width={'100%'} height={'100%'}/>
            </div>
        }
    </div>
  )
}

export default CarruselCards
