import React from "react";
import {
  ProfessionalCardContainer,
  DataUserContainer,
  NameText,
  InternText,
  SectionContainer,
  SectionSeparator,
  TitleSection,
} from "./ProfessionalCardStyles";
import { languages, experiencia } from "../../../../types/cvInterface";

interface ProfessionalCardProps {
  nombre: string;
  profesion: string;
  idiomas: languages[];
  habilidades: string[];
  experiencia: experiencia[];
  language: string;
}

const ProfessionalCard = ({
  nombre,
  profesion,
  idiomas,
  habilidades,
  experiencia,
  language,
}: ProfessionalCardProps) => {
  return (
    <ProfessionalCardContainer>
      <DataUserContainer>
        <NameText>{nombre}</NameText>
        <InternText>{profesion}</InternText>
      </DataUserContainer>

      <SectionContainer>
        <TitleSection>
          {language === "es" ? "Idiomas" : "Languages"}
        </TitleSection>
        {idiomas.map((idioma, index) => (
          <InternText key={index}>{idioma.Language}</InternText>
        ))}
        <SectionSeparator />
      </SectionContainer>

      <SectionContainer>
        <TitleSection>
          {language === "es" ? "Habilidades" : "Skills"}
        </TitleSection>
        {habilidades.map((habilidad, index) => (
          <InternText key={index}>{habilidad}</InternText>
        ))}
        <SectionSeparator />
      </SectionContainer>

      <SectionContainer>
        <TitleSection>
          {language === "es" ? "Experiencia" : "Experience"}
        </TitleSection>
        {experiencia.map((experiencia, index) => (
          <InternText key={index}>{experiencia.Nombreempresa}</InternText>
        ))}
        <SectionSeparator />
      </SectionContainer>
    </ProfessionalCardContainer>
  );
};

export default ProfessionalCard;
