/* eslint-disable  */
import React, { useEffect, useState } from 'react'
import './DashboardTabs.css'
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
// import Eye from "../../../assets/icons/eye.svg";
import Check from "../../../assets/icons/check.svg";
import axios from "axios";
import IconButton from "@mui/material/IconButton";
import trashcan from "../../../assets/logos/trashcan.png";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import MissTest from './MissTest';
import PorcentajeAplicante from './PorcentajeAplicante';

export default function DashboardTabs({ entrevista, enPruebas, opId, seleccionados, oportunidad }) {
  const [tab, setTab] = useState(1)
  const { lang, userPlan } = useSelector(state => state)
  const tabChanger = (tab) => {
    setTab(tab)
  }
  useEffect(() => {
    if (enPruebas?.length >= 1) {
      setTab(2)
    }
  }, [tab])
  const deleteApplicant = (idUser) => {
    axios.post(`https://findally-backend.herokuapp.com/api/Project/deleteContact/${idUser}`,
      { idProject: opId })
      .then(r => {
        console.log(r)
        lang === 'es' ? toast.success('Candidato eliminado', {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }) : toast.success('Applicant deleted', {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        })
        setTimeout(() => {
          window.location.reload()
        }, 1500)
      })
      .catch(e => console.log(e))
  }

  const selectApplicant = async (idUser) => {
    await axios.post('https://findally-backend.herokuapp.com/api/applicants/selection',
      { idProject: opId, idUser: idUser })
      .then(r => {
        console.log(r)
        return window.location.reload()

      }).catch(e => console.log(e))
  }
  const aproveApplicant = async (idUser) => {
    await axios.post('https://findally-backend.herokuapp.com/api/applicants/hire',
      { idProject: opId, idUser: idUser })
      .then(r => {
        console.log('apliucants', r)
        return window.location.reload()
      }).catch(e => console.log('apliucants2', e))
  }

  const redondeo = (num) => {
    return Math.round(num * 100) / 5
  }

  return (
    <div>
      <div className={'dashop-line-opt-cont'}>
        <h3 onClick={() => tabChanger(1)}
          className={tab === 1 ? 'dashop-tab-active' : 'dashop-tab-inactive '}>{lang === 'es' ? 'En entrevista' : 'On Interview'}
          <span
            className={'dashop-tab-number'}>({entrevista?.contacted_users?.length})</span></h3>
        <h3 onClick={() => tabChanger(2)} className={tab === 2 ? 'dashop-tab-active' : 'dashop-tab-inactive '}>
          {lang === 'es' ? 'En pruebas' : 'In tests'} <span
            className={'dashop-tab-number'}>({enPruebas?.data?.length})</span></h3>
        <h3 onClick={() => tabChanger(3)} className={tab === 3 ? 'dashop-tab-active' : 'dashop-tab-inactive '}>
          {lang === 'es' ? 'En selección' : 'In selection'} <span
            className={'dashop-tab-number'}>({seleccionados?.data?.length})</span></h3>
      </div>
      {
        tab === 1 ?
          <div className={'dashop-tab-cont'}>
            {
              entrevista && entrevista?.contacted_users?.map((user, index) => {
                let person = Math.floor(Math.random() * 78)
                let gender = Math.floor(Math.random() * 2)
                return (
                  <div className={'dashop-tab-user-cont'}>
                    <div className={'dashop-tab-sub-cont'}>
                      <div
                        className={/*userPlan?.nombre_paquete === 'Paquete Free' ? 'dashop-tab-img-filtro' : */'dashop-tab-img-filtro-2'}>
                        <img className={'dashop-tab-img'}
                          src={user?.avatar ? user.avatar : 'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png'}
                          alt={'profile-pic'} />
                      </div>
                      <div className={'dashop-tab-sub-sub-cont'}>
                        <h3
                          className={'dashop-tab-name'}>{/*userPlan?.nombre_paquete !== 'Paquete Free' ? */user?.cv_file?.info_personal?.nombre /*: lang === 'es' ? `Aplicante` : `Applicant`*/}</h3>
                        <h3 className={'dashop-tab-job'}>{user?.cv_file?.info_personal?.profesion_actual}</h3>
                        {/*<h3
                          className={'dashop-entrev-compat'}>{lang === 'es' ? `${redondeo(user.stars)}% Compatibilidad` : `${redondeo(user.stars)}% Compatibility`}</h3>*/}
                      </div>
                    </div>
                    <div className='info-cont-test-select'>
                      <PorcentajeAplicante lang={lang} user={user} oportunidad={oportunidad} />
                      <div>
                        <MissTest user={user} lang={lang} />
                      </div>
                    </div>
                    <div className={'buton-cont-2-cont'}>
                      <Link to={`/company/profile/aplicant/${user.id}?op=${oportunidad?._id}`}
                        className={'aplic-card-ver-perfil'}>
                        <span style={{ paddingRight: '5px' }}>{lang === 'es' ? 'Ver perfil' : 'View profile'}</span>
                        {/* <img style={{ marginLeft: '5px' }} alt={'eye'} src={eye} /> */}
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M22 12C22 12 19 18 12 18C5 18 2 12 2 12C2 12 5 6 12 6C19 6 22 12 22 12Z"
                            stroke-linecap="round" />
                          <circle cx="12" cy="12" r="3" stroke-linecap="round" />
                        </svg>
                      </Link>
                      <button className={'aplic-card-ver-selec'} onClick={() => selectApplicant(user?.cv_file?.usuario)}>
                        {lang === 'es' ? 'Seleccionar' : 'Select'}
                      </button>
                      <IconButton aria-label="delete" sx={{ color: '#202256' }}
                        onClick={() => deleteApplicant(user?.cv_file?.usuario)}>
                        <img style={{ height: '20px' }} src={trashcan} alt={trashcan} />
                      </IconButton>
                    </div>
                  </div>)
              })
            }
          </div> : null
      }
      {
        tab === 2 ?
          <div className={'dashop-tab-cont'}>
            {
              enPruebas && enPruebas?.data?.map((user, index) => {
                let person = Math.floor(Math.random() * 78)
                let gender = Math.floor(Math.random() * 2)
                return (
                  <div className={'dashop-tab-user-cont'}>
                    <div className={'dashop-tab-sub-cont'}>
                      <div
                        className={/*userPlan?.nombre_paquete === 'Paquete Free' ? 'dashop-tab-img-filtro' : */'dashop-tab-img-filtro-2'}>
                        <img className={'dashop-tab-img'}
                          src={user?.avatar ? user.avatar : 'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png'}
                          alt={'profile-pic'} />
                      </div>
                      <div className={'dashop-tab-sub-sub-cont'}>
                        <h3
                          className={'dashop-tab-name'}>{/*userPlan?.nombre_paquete !== 'Paquete Free' ? */user?.cv_file?.info_personal?.nombre /*: lang === 'es' ? `Aplicante` : `Applicant`*/}</h3>
                        <h3 className={'dashop-tab-job'}>{user?.cv_file?.info_personal?.profesion_actual}</h3>
                        <h3
                          className={'dashop-entrev'}>{lang === 'es' ? 'En espera de pruebas técnicas' : 'Waiting for technical tests'}</h3>
                      </div>
                    </div>
                    <div className={'dashop-tab-sub-sub-cont2'}>
                      <div style={{ width: '170px' }}>
                        <button onClick={() => aproveApplicant(user?._id)} className={'dashop-selection-buton'}>{lang === 'es' ? 'Seleccionar' : 'Select'}</button>
                      </div>
                      <div style={{ width: '170px' }}>
                        <Link to={`/company/profile/aplicant/${user.id}?op=${oportunidad._id}`}
                          className={'aplic-card-ver-perfil'}>
                          <span style={{ paddingRight: '5px' }}>{lang === 'es' ? 'Ver perfil' : 'View profile'}</span>
                          {/* <img style={{ marginLeft: '5px' }} alt={'eye'} src={eye} /> */}
                          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M22 12C22 12 19 18 12 18C5 18 2 12 2 12C2 12 5 6 12 6C19 6 22 12 22 12Z"
                              stroke-linecap="round" />
                            <circle cx="12" cy="12" r="3" stroke-linecap="round" />
                          </svg>
                        </Link>
                      </div>
                    </div>
                  </div>)
              })
            }
          </div> : null
      }
      {
        tab === 3 ?
          <div className={'dashop-tab-cont'}>
            {
              seleccionados && seleccionados?.data?.map((user, index) => {
                let person = Math.floor(Math.random() * 78)
                let gender = Math.floor(Math.random() * 2)
                return (
                  <div className={'dashop-tab-user-cont'}>
                    <div className={'dashop-tab-sub-cont'}>
                      <div
                        className={/*userPlan?.nombre_paquete === 'Paquete Free' ? 'dashop-tab-img-filtro' : */'dashop-tab-img-filtro-2'}>
                        <img className={'dashop-tab-img'}
                          src={user?.avatar ? user.avatar : 'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png'}
                          alt={'profile-pic'} />
                      </div>
                      <div className={'dashop-tab-sub-sub-cont'}>
                        <h3
                          className={'dashop-tab-name'}>{/*userPlan?.nombre_paquete !== 'Paquete Free' ? */user?.cv_file?.info_personal?.nombre/* : lang === 'es' ? `Aplicante` : `Applicant`*/}</h3>
                        <h3 className={'dashop-tab-job'}>{user?.cv_file?.info_personal?.profesion_actual}</h3>
                        <div className={'dashop-entrev-subcont'}>
                          <img style={{ marginTop: "-6px", marginRight: '10px' }} src={Check} alt={'check'} />
                          <h3
                            className={'dashop-selected'}>{lang === 'es' ? 'Profesional seleccionado' : 'Selected professional'}</h3>
                        </div>
                      </div>
                    </div>
                    <div style={{ width: '170px' }}>
                      <Link to={`/company/profile/aplicant/${user.id}?op=${oportunidad._id}`}
                        className={'aplic-card-ver-perfil'}>
                        <span style={{ paddingRight: '5px' }}>{lang === 'es' ? 'Ver perfil' : 'View profile'}</span>
                        {/* <img style={{ marginLeft: '5px' }} alt={'eye'} src={eye} /> */}
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M22 12C22 12 19 18 12 18C5 18 2 12 2 12C2 12 5 6 12 6C19 6 22 12 22 12Z"
                            stroke-linecap="round" />
                          <circle cx="12" cy="12" r="3" stroke-linecap="round" />
                        </svg>
                      </Link>
                    </div>
                  </div>)
              })
            }
          </div> : null
      }
    </div>
  )
}
