import React from 'react'
import '../Styles/CardDescripcion.css'

const CardDescripcion = ({ dataUser, lang }) => {
  return (
    <div className='Container-CardDescripcion'>
      <div className='SubContainer-CardDescripcion'>
        <h1 className='Title-Card-CardDescripcion'>
          {lang === 'es' ? 'Acerca de' : 'About us'}
        </h1>
        <h3 style={{
          fontSize: '14px',
          color: '#5D5D5D',
        }}>
          {dataUser?.descripcion ? dataUser?.descripcion : '-'}
          {dataUser?.descripcion ? dataUser?.descripcion : '-'}
        </h3>
      </div>

    </div>
  )
}

export default CardDescripcion
