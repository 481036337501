import styled from "styled-components";

const ProfileDownloadImageContainer = styled.div<{ color?: string }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: ${(props) => (props.color ? props.color : "#F2F2F2")};
  border-radius: 14px;
  width: 55px;
  height: 55px;

  @media (max-width: 500px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 14px;
    width: 55px;
    height: 55px;
    margin-right: 20px;
  }
`;

const FileDetailsContainer = styled.div<{color?: string}>`
  width: 265px;
  height: 85px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 15px;
  background: transparent;
  border: 1px solid ${(props)=> props.color ? props.color : '#F2F2F2'};
  border-radius: 14px;
  
@media (max-width: 1550px) {
  width: 265px;
  height: 85px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 15px;
  background: transparent;
  border-radius: 14px;
}
  
@media (max-width: 950px) {
  width: 100%;
  height: 85px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 15px;
  background: transparent;
  border-radius: 14px;
}
`

const UploadFileProfileButton = styled.button<{color?: string}>`
  margin: 5px;
  background: ${(props)=> props.color ? props.color : '#F2F2F2'};
  border-radius: 5px;
  height: 30px;
  width: 170px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-weight: 400;
  font-size: 14px;
  color: #FFFFFF;
`

export { ProfileDownloadImageContainer, FileDetailsContainer, UploadFileProfileButton};
