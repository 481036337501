import React from 'react';
import Avatar from "@mui/material/Avatar";
import phone from '../../../../assets/icons/phone.svg'
import mail from '../../../../assets/icons/mail.svg'
import '../../../../profesionalpag/PerfilProfesional/sideCards/cards.css'
import { User} from "./ProfileInterfaces";


export default function ProfileCard({user}: {user: User}) {


    return(
        <div className='Perfil-container-cards'>

            <div className='porcentaje-editar-container'>
                {/* <div className='editar-foto-perfil'>
              <button onClick={()=>handleAvatarSubmit()} >
                <img src={camera} alt={'camera'}/>
              </button>
            </div> */}
                <div className='Avatar-camara-perfil'>
                        <Avatar
                            className="avatar-element-perfil"
                            src={user.avatar ? user.avatar : "/broken-image.jpg"}
                            sx={{width: "164px", height: "164px" }}
                        />
                </div>
               {/* <div className='porcentaje-complete'>
                    <p className='porcentaje-p'>{progress}%</p>
                </div>*/}
                <hr className='herre'/>
            </div>
            <div className='contact-data'>
                <p><img src={phone} alt='phone' className='icon-cards'/>{user.phoneNumber ? user.phoneNumber : ''}</p>
                <p><img src={mail} alt='mail' className='icon-cards'/>{user.email}</p>
            </div>
        </div>
    )
}
