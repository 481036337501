import React, { useState } from 'react'
import './styles/Config.css'
import Translate from "react-translate-component";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";


function ConfigFaq() {
    const [isOpen, setIsOpen] = useState({
        isOpen1: false,
        isOpen2: false,
        isOpen3: false,
        isOpen4: false,
        isOpen5: false,
        isOpen6: false,
    })


    function handleClick(number) {
        switch (number) {
            case "1":
                setIsOpen({ ...isOpen, isOpen1: !isOpen.isOpen1 })
                break;
            case "2":
                setIsOpen({ ...isOpen, isOpen2: !isOpen.isOpen2 })
                break;
            case "3":
                setIsOpen({ ...isOpen, isOpen3: !isOpen.isOpen3 })
                break;
            case "4":
                setIsOpen({ ...isOpen, isOpen4: !isOpen.isOpen4 })
                break;
            case "5":
                setIsOpen({ ...isOpen, isOpen5: !isOpen.isOpen5 })
                break;
            case "6":
                setIsOpen({ ...isOpen, isOpen6: !isOpen.isOpen6 })
                break;
            default:
                break;
        }
    }


    return (
        <div>



            <div className="container-dropdown">
                <div className="box-item">
                    <button onClick={() => handleClick("1")}>{<Translate content="Cómopagamos" component="span" />}<KeyboardArrowDownIcon /></button>
                    <hr />
                    {isOpen.isOpen1 ?
                        <div>
                            <ul >
                                <li>{<Translate content="Somoscompletamente" component="span" />}</li>
                            </ul>
                            <hr style={{ marginLeft: '8%' }} />
                        </div>
                        : null}
                </div>
                <div className="box-item">
                    <button onClick={() => handleClick("2")}>{<Translate content="Cómofuncionalacontratación" component="span" />}<KeyboardArrowDownIcon /></button>
                    <hr />
                    {isOpen.isOpen2 ?
                        <div>
                            <ul >
                                <li>{<Translate content="Contratoporprestacióndeservicios" component="span" />}</li>
                            </ul>
                            <hr style={{ marginLeft: '8%' }} />
                        </div>
                        : null}
                </div>
                <div className="box-item">
                    <button onClick={() => handleClick("3")} >{<Translate content="Recibimosproyectosinternacionales" component="span" />}<KeyboardArrowDownIcon /></button>
                    <hr />
                    {isOpen.isOpen3 ?
                        <div>
                            <ul >
                                <li>{<Translate content="Sirecibimospagosporpaypal" component="span" />}</li>
                            </ul>
                            <hr style={{ marginLeft: '8%' }} />
                        </div> : null}
                </div>
                <div className="box-item">
                    <button onClick={() => handleClick("4")}>{<Translate content="Decuantotiemposonlosproyectos" component="span" />}<KeyboardArrowDownIcon /></button>
                    <hr />
                    {isOpen.isOpen4 ?
                        <div>
                            <ul >
                                <li>{<Translate content="Nuestrosproyectossuelen" component="span" />}</li>
                            </ul>
                            <hr style={{ marginLeft: '8%' }} />
                        </div> : null}
                </div>
                <div className="box-item">
                    <button onClick={() => handleClick("5")}>{<Translate content="Cómohacemoslaselección" component="span" />}<KeyboardArrowDownIcon /></button>
                    <hr />
                    {isOpen.isOpen5 ?
                        <div>
                            <ul >
                                <li>{<Translate content="Coallydesarrollaunproceso" component="span" />}</li>
                            </ul>
                            <hr style={{ marginLeft: '8%' }} />
                        </div> : null}
                </div>
                <div className="box-item">
                    <button onClick={() => handleClick("6")}>{<Translate content="Enquémomentodebopagar" component="span" />}<KeyboardArrowDownIcon /></button>
                    <hr />
                    {isOpen.isOpen6 ?
                        <div>
                            <ul >
                                <li>{<Translate content="Elpagoserealizaantesdepublicartuproyecto" component="span" />}</li>
                            </ul>
                            <hr style={{ marginLeft: '8%' }} />
                        </div> : null}
                </div>
            </div>


        </div>
    )
}

export default ConfigFaq