import React, {useEffect} from 'react';
import FormControlLabel from '@mui/material/FormControlLabel';
import CircleIcon from '@mui/icons-material/Circle';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Typography from '@mui/material/Typography';
import './CrearOportunidadNew.css'
import {useDispatch} from "react-redux";
import { saveOportunityForm } from '../../actions';

export default function ChooseType(props) {
  const {form, step, handleStep, lang, oportunityForm, handleForm} = props;
  const [button, setButton] = React.useState(true);
  const dispatch = useDispatch();
  useEffect(() => {
    if (oportunityForm?.tipoDeServicioDeseado ? oportunityForm?.tipoDeServicioDeseado : form?.tipoDeServicioDeseado) {
      setButton(false);
    }
    return () => {
      setButton(true);
    }
    //eslint-disable-next-line
  }, [form?.tipoDeServicioDeseado]);

  const handleChange = (e) => {
    handleForm({...form, tipoDeServicioDeseado: e.target.value});
    dispatch(saveOportunityForm({tipoDeServicioDeseado: e.target.value}, step));
    setButton(false);
  }
  
  return (
    <>
      <div className='contenedorChooseType'>
        <h3
          className='contenedorChooseType_title'>{lang === 'es' ? '¿Qué tipo de servicio deseas?' : 'What type of service do you want to offer?'}</h3>
        <div>
          <RadioGroup
            aria-labelledby="demo-controlled-radio-buttons-group"
            name="tipoDeServicioDeseado"
            className='chooseTypeRadio'
            value={oportunityForm?.tipoDeServicioDeseado ? oportunityForm?.tipoDeServicioDeseado : form?.tipoDeServicioDeseado}
          >
            <FormControlLabel name="tipoDeServicioDeseado" value="Contratación"
                              control={<Radio icon={<CircleIcon className='radio_unchecked_icon'/>}
                                              checkedIcon={<CircleIcon className='radio_checked_icon'/>}/>}
                              onChange={handleChange}
                              label={<Typography sx={{fontSize: '14px', fontFamily: 'Poppins', fontWeight: '400'}}>
                                {lang === 'es' ? 'Contratación' : 'Contract'}
                              </Typography>}/>

            <FormControlLabel value="Outsourcing (BPO) – Personalized services" name="tipoDeServicioDeseado"
                              onChange={handleChange}
                              control={<Radio icon={<CircleIcon className='radio_unchecked_icon'/>}
                                              checkedIcon={<CircleIcon className='radio_checked_icon'/>}/>}
                              label={<Typography sx={{fontSize: '14px', fontFamily: 'Poppins', fontWeight: '400'}}>
                                Outsourcing (BPO) – Personalized services
                              </Typography>}/>
          </RadioGroup>
        </div>
      </div>
      <div
        className='chooseTypeButton'
      >
        <div className='button_siguiente_create_C'></div>
        <h3 style={{fontFamily: 'Poppins', fontWeight: '400', fontSize: '12px'}}>{`${step}/14`}</h3>
        {<button className='button_siguiente_create'
                 disabled={button}
                 onClick={() => handleStep()}>{lang === 'es' ? 'Siguiente' : 'Next'}</button>}
      </div>
    </>
  )
}
